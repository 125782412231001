import {
  colorWhite,
  colorPrimary,
  shadowButtons,
  backgroundButtons,
  colorSecondaryGrayLight,
} from 'assets/styles/variables';
import { get } from 'lodash';

import { getIsExpired, getIsExpireSoon } from 'utils/helpers/date';

export const getButtonsStyles = ({ isLocked } = {}) => `
  && {
    display: block;
    margin-left: 2.4rem;
    padding: .8rem 1.7rem;
    border: 1px solid ${colorPrimary};
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6rem;
    letter-spacing: 0.125rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    color: ${colorPrimary};
    background-color: initial;
    outline: none;
    cursor: pointer;
    &:active,
    &:hover {
      border-color: ${colorPrimary};
      color: ${colorWhite};
      background: ${backgroundButtons};
      box-shadow: ${shadowButtons};
    }
    ${isLocked ? `
      color: ${colorSecondaryGrayLight};
      border-color: ${colorSecondaryGrayLight};
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.6;
    ` : ''}
  }
`;

export const getTOStatus = ({ endDate, isActive, isManuallyDeactivated, isSigned }) => {
  if (isManuallyDeactivated) {
    return 'Deactivated';
  }

  if (getIsExpired(endDate)) {
    return 'Expired';
  }

  if (isActive) {
    return 'Active';
  }

  if (isSigned) {
    return 'Signed';
  }

  return 'Not yet active';
};

export const getIsRatesObsolete = (taskOrderRates) => {
  const year = get(taskOrderRates, 'year', '');

  if (year) {
    if (+year + 1 <= new Date().getFullYear()) {
      return true;
    }
  }

  return false;
};

export const getRowBackground = ({ isExpireSoon, isRatesObsolete }) => {
  if (isExpireSoon) {
    return 'rgba(214,31,38, 0.08)';
  }

  if (isRatesObsolete) {
    return 'rgba(187,189,192, 0.25);';
  }

  return 'transparent';
};

export const getProjectType = ({ projectType, isActive }) => isActive && projectType === 'PTM' ? 'PTM/Open' : projectType || '';
export const getActiveObsoleteRates = ({ isActive, isManuallyDeactivated, taskOrderRates }) => isActive && !isManuallyDeactivated && getIsRatesObsolete(taskOrderRates);
export const getRowsThatExpireSoon = ({ employees, isActive, isManuallyDeactivated, endDate }) => {
  const employeeDateTo = get(employees, '[0].dateTo');

  return isActive && !isManuallyDeactivated && (getIsExpireSoon(endDate) || getIsExpireSoon(employeeDateTo));
};
