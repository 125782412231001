import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { usePrevious } from 'react-use';

export const ListIntersectionContext = createContext({});

export const useListIntersectionProps = (listRef) => {
  const [visibleChildren, setVisibleChildren] = useState();
  const observer = new IntersectionObserver(
    (entries) => {
      setVisibleChildren((previousVisibleChildren) => {
        let isUpdated = false;
        const children = new Set(previousVisibleChildren);
        entries.forEach(({ target, isIntersecting }) => {
          const hasTarget = children.has(target);
          if (isIntersecting) {
            isUpdated = isUpdated || !hasTarget;
            children.add(target);
          } else {
            isUpdated = isUpdated || hasTarget;
            children.delete(target);
          }
        });

        return isUpdated ? children : previousVisibleChildren;
      });
    },
    {
      root: listRef.current,
      rootMargin: '50px 0px 20px 0px',
      threshold: [0, 0.25, 0.5, 0.75, 1],
    },
  );

  // Disconnecting previous observer.
  const prevObserver = usePrevious(observer);
  useEffect(() => {
    if (prevObserver) {
      prevObserver.disconnect();
    }
  }, [prevObserver]);

  // Disconnecting current observer on unmount.
  useEffect(
    () => () => {
      if (observer) {
        observer.disconnect();
      }
    },
    [],
  );

  return useMemo(
    () => ({
      observer,
      visibleChildren,
    }),
    [observer, visibleChildren],
  );
};

export const useIsListChildVisible = (childRef) => {
  const { observer, visibleChildren } = useContext(ListIntersectionContext);

  useEffect(() => {
    const { current: child } = childRef;
    if (observer && child) {
      observer.observe(child);
    }
    return () => {
      if (observer && child) {
        observer.unobserve(child);
      }
    };
  }, [observer, childRef]);

  return useMemo(() => {
    const { current: child } = childRef;
    if (visibleChildren && child) {
      return visibleChildren.has(child);
    }
    return false;
  }, [visibleChildren, childRef]);
};
