import {
  appStorage,
} from 'core/storage';
import { getCommittedHoursVisibleValue } from 'core/task-orders/utils';
import update from 'immutability-helper';
import {
  createReducer,
} from 'redux-create-reducer';

import {
  taskOrdersActionsTypes,
} from './actions';

import {
  ENTITY_NAME,
  STORE_KEYS,
} from './constants';

const initialState = {
  entityName: ENTITY_NAME,
  errors: {},
  isFetching: false,
  isFiltering: false,
  isFetchingDetails: false,
  isFetchingCommittedHours: false,
  isFetchingFiles: false,
  isFormSubmitted: false,
  requestsCounter: 0,
  taskOrdersData: [],
  taskOrdersDetails: {},
  availableCommittedHours: [],
  favoriteTaskOrders: appStorage.getFavoriteTaskOrders() || {},
  error: null,

  orderRules: {
    taskOrdersList: {
      orderBy: 'TO',
      defaultOrderBy: 'TO',
      isReversed: false,
      isSeparatedByFavorites: true,
    },

    toDocuments: {
      orderBy: 'Upload Date',
      isReversed: false,
    },

    assignedClientCategories: {
      orderBy: 'Category Key',
      isReversed: false,
    },
  },

  filters: {
    [STORE_KEYS.IS_EXPIRE_SOON]: {
      isActive: false,
      type: 'toggle',
    },
    [STORE_KEYS.IS_RATES_OBSOLETE]: {
      isActive: false,
      type: 'toggle',
    },
    [STORE_KEYS.IS_FAVORED]: {
      isActive: false,
      type: 'toggle',
    },
    [STORE_KEYS.PROJECT_ID]: {
      isActive: false,
      type: 'search',
      selected: '',
    },
    [STORE_KEYS.TITLE]: {
      isActive: false,
      type: 'search',
      selected: '',
    },
    [STORE_KEYS.CLIENT_NAME]: {
      isActive: false,
      type: 'search',
      selected: '',
    },
    [STORE_KEYS.COUNTRY]: {
      isActive: false,
      type: 'select',
      selected: [],
    },
    [STORE_KEYS.BILLING_AGENT]: {
      isActive: false,
      type: 'select',
      selected: [],
    },
    [STORE_KEYS.PROJECT_TYPE]: {
      isActive: false,
      type: 'select',
      selected: [],
    },
    [STORE_KEYS.EFFECTIVE_DATE]: {
      isActive: false,
      type: 'date',
      selected: '',
    },
    [STORE_KEYS.END_DATE]: {
      isActive: false,
      type: 'date',
      selected: '',
    },
    [STORE_KEYS.STATUS]: {
      isActive: false,
      type: 'select',
      selected: [],
    },
    [STORE_KEYS.CATEGORIES_KEYS]: {
      isActive: false,
      type: 'search',
      selected: '',
    },
  },
};

export default createReducer(initialState, {
  [taskOrdersActionsTypes.GET_COMMITTED_HOURS_BY_CLIENT_ID](state) {
    return update(state, {
      isFetchingCommittedHours: {
        $set: true,
      },
    });
  },

  [taskOrdersActionsTypes.GET_COMMITTED_HOURS_BY_CLIENT_ID_SUCCESS](state, {
    payload: {
      availableCommittedHours,
    },
  }) {
    return update(state, {
      isFetchingCommittedHours: {
        $set: false,
      },
      availableCommittedHours: {
        $set: availableCommittedHours.map(({ hours, discount, committedHoursId }) => ({
          value: committedHoursId,
          label: getCommittedHoursVisibleValue(hours, discount),
        })),
      },
      errors: {
        $unset: ['getCommittedHoursByClientIdError'],
      },
    });
  },

  [taskOrdersActionsTypes.GET_COMMITTED_HOURS_BY_CLIENT_ID_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [taskOrdersActionsTypes.RESET_AVAILABLE_COMMITTED_HOURS](state) {
    return update(state, {
      availableCommittedHours: {
        $set: initialState.availableCommittedHours,
      },
    });
  },
  [taskOrdersActionsTypes.GET_TASK_ORDERS_LIST](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },

  [taskOrdersActionsTypes.GET_TASK_ORDERS_LIST_SUCCESS](state, {
    payload: {
      taskOrdersList,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      taskOrdersData: {
        $set: taskOrdersList,
      },
      errors: {
        $unset: ['getTaskOrdersListError'],
      },
    });
  },

  [taskOrdersActionsTypes.GET_TASK_ORDERS_LIST_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [taskOrdersActionsTypes.GET_TASK_ORDERS_DETAILS](state, {
    meta: {
      withReset,
    },
  }) {
    const stateUpdates = withReset ? {
      taskOrdersDetails: {
        $set: {},
      },
      isFetchingDetails: {
        $set: true,
      },
    } : {
      isFetchingDetails: {
        $set: true,
      },
    };

    return update(state, stateUpdates);
  },

  [taskOrdersActionsTypes.GET_TASK_ORDERS_DETAILS_SUCCESS](state, {
    payload: {
      taskOrdersDetails,
    },
  }) {
    return update(state, {
      isFetchingFiles: {
        $set: false,
      },
      isFetchingDetails: {
        $set: false,
      },
      taskOrdersDetails: {
        $set: taskOrdersDetails,
      },
      errors: {
        $unset: ['getTaskOrdersDetailsError'],
      },
    });
  },

  [taskOrdersActionsTypes.GET_TASK_ORDERS_DETAILS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetchingFiles: {
        $set: false,
      },
      isFetchingDetails: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [taskOrdersActionsTypes.SET_ORDER_RULES](state, {
    payload: {
      tableName,
      orderRules,
    },
  }) {
    return update(state, {
      orderRules: {
        [tableName]: {
          $set: orderRules,
        },
      },
    });
  },

  [taskOrdersActionsTypes.UPDATE_FAVORITES](state, {
    payload,
  }) {
    return update(state, {
      favoriteTaskOrders: {
        $set: payload,
      },
    });
  },

  [taskOrdersActionsTypes.CHANGE_FILTER](state) {
    return update(state, {
      isFiltering: {
        $set: true,
      },
    });
  },

  [taskOrdersActionsTypes.CHANGE_FILTER_SUCCESS](state, {
    payload: {
      storeKey,
      config,
    },
  }) {
    return update(state, {
      isFiltering: {
        $set: false,
      },
      filters: {
        [storeKey]: {
          $merge: config,
        },
      },
    });
  },

  [taskOrdersActionsTypes.RESET_FILTERS](state) {
    return update(state, {
      isFiltering: {
        $set: false,
      },
      filters: {
        $merge: initialState.filters,
      },
    });
  },

  [taskOrdersActionsTypes.CREATE_TASK_ORDER](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [taskOrdersActionsTypes.CREATE_TASK_ORDER_SUCCESS](state, {
    payload: {
      taskOrdersDetails,
    },
  }) {
    return update(state, {
      taskOrdersDetails: {
        $merge: taskOrdersDetails,
      },
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $unset: ['createTaskOrderError'],
      },
    });
  },

  [taskOrdersActionsTypes.CREATE_TASK_ORDER_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [taskOrdersActionsTypes.UPDATE_TASK_ORDER](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [taskOrdersActionsTypes.UPDATE_TASK_ORDER_SUCCESS](state, {
    payload: {
      fields,
      projectId,
    },
  }) {
    const {
      taskOrdersDetails,
    } = state;

    if (+taskOrdersDetails.projectId === +projectId) {
      return update(state, {
        isFormSubmitted: {
          $set: false,
        },
        taskOrdersDetails: {
          $merge: fields,
        },
        errors: {
          $unset: ['updateTaskOrderError'],
        },
      });
    }

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $unset: ['updateTaskOrderError'],
      },
    });
  },

  [taskOrdersActionsTypes.UPDATE_TASK_ORDER_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [taskOrdersActionsTypes.DELETE_DOCUMENT](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [taskOrdersActionsTypes.DELETE_DOCUMENT_SUCCESS](state, {
    payload: {
      fileId: id,
    },
  }) {
    const {
      taskOrdersDetails,
    } = state;
    const {
      files = [],
    } = taskOrdersDetails;
    const numberOfFiles = files.length;

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $unset: ['deleteDocumentError'],
      },
      taskOrdersDetails: {
        numberOfFiles: {
          $set: numberOfFiles - 1,
        },
        files: {
          $apply: () => files.filter(({
            fileId,
          }) => fileId !== id),
        },
      },
    });
  },

  [taskOrdersActionsTypes.DELETE_DOCUMENT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [taskOrdersActionsTypes.GENERATE_DOCUMENT](state) {
    return update(state, {
      isFetchingFiles: {
        $set: true,
      },
    });
  },

  [taskOrdersActionsTypes.GENERATE_DOCUMENT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateDocumentError'],
      },
    });
  },

  [taskOrdersActionsTypes.GENERATE_DOCUMENT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetchingFiles: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [taskOrdersActionsTypes.GET_UPLOADED_FILE](state) {
    const {
      requestsCounter,
    } = state;

    return update(state, {
      isFetchingFiles: {
        $set: true,
      },
      requestsCounter: {
        $set: requestsCounter + 1,
      },
    });
  },

  [taskOrdersActionsTypes.GET_UPLOADED_FILE_SUCCESS](state, {
    payload: {
      file,
    },
  }) {
    const {
      taskOrdersDetails = {},
    } = state;
    const {
      files = [], isSigned,
    } = taskOrdersDetails;
    const numberOfFiles = files.length;
    const {
      isSigned: isFileSigned,
    } = file;

    return update(state, {
      isFetchingFiles: {
        $set: false,
      },
      errors: {
        $unset: ['getUploadedFileError'],
      },
      requestsCounter: {
        $set: 0,
      },
      taskOrdersDetails: {
        isSigned: {
          $set: isSigned || isFileSigned,
        },
        numberOfFiles: {
          $set: numberOfFiles + 1,
        },
        files: {
          $push: [file],
        },
      },
    });
  },

  [taskOrdersActionsTypes.GET_UPLOADED_FILE_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetchingFiles: {
        $set: false,
      },
      requestsCounter: {
        $set: 0,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [taskOrdersActionsTypes.SIGN_DOCUMENT_SUCCESS](state, {
    payload: {
      fileId: id,
    },
  }) {
    const {
      taskOrdersDetails: {
        files = [],
      },
    } = state;
    const fileIndex = files.findIndex(({
      fileId,
    }) => fileId === id);

    if (fileIndex !== -1) {
      return update(state, {
        errors: {
          $unset: ['signDocumentError'],
        },
        isFormSubmitted: {
          $set: false,
        },
        taskOrdersDetails: {
          files: {
            [fileIndex]: {
              $merge: {
                isRequestToSignSent: true,
              },
            },
          },
        },
      });
    }

    return state;
  },

  [taskOrdersActionsTypes.SIGN_DOCUMENT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
});
