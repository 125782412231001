import hash from 'hash-sum';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import {
  call,
  fork,
  take,
} from 'redux-saga/effects';

export const takeFirst = (patternOrChannel, saga, ...args) => fork(function* takeFirstWatcher() {
  while (true) {
    const action = yield take(patternOrChannel);
    yield call(saga, ...args.concat(action));
  }
});

export const dismissErrorsById = (errors, id) => get(errors, id, []).forEach((error) => toast.dismiss(hash(error)));
