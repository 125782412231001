import gql from 'graphql-tag';

export const queryConfig = {
  uploadFile: (variables) => JSON.stringify({
    query: `mutation uploadNewFile($clientFile: UploadClientFileInput!, $taskOrderFile: UploadTaskOrderFileInput, $emailFile: UploadEmailFileInput, $channelPartnerFile: UploadChannelPartnerFileInput) {
        uploadFile(clientFile: $clientFile, taskOrderFile: $taskOrderFile, emailFile: $emailFile, channelPartnerFile: $channelPartnerFile)
          {
            ok
            file {
              id
              fileId
            }
            clientFileUploadData
            taskOrderUploadData
            emailFileUploadData
            channelPartnerFileUploadData
          }
      }`,
    variables,
  }),
  generateAnnualPayrollReport: gql`
    mutation generateAnnualPayrollReport(
      $fields: AnnualPayrollPdfReportInput!
    ) {
      generateAnnualPayrollReport(fields: $fields) {
        url
      }
    }
  `,
  generateAnnualPayrollExcelReport: gql`
    mutation generateAnnualPayrollExcelReport(
      $fields: AnnualPayrollExcelReportInput!
    ) {
      generateAnnualPayrollExcelReport(fields: $fields) {
        url
      }
    }
  `,
  generateAnnualPayrollSalaryReport: gql`
    mutation generatePayrollReport(
      $year: Int!
      $deliveryCenterIds: [Int]
      $deliveryOnly: String
      $currencies: [String]
    ) {
      generatePayrollReport(
        year: $year
        deliveryCenterIds: $deliveryCenterIds
        deliveryOnly: $deliveryOnly
        currencies: $currencies
      ) {
        ok
        url
      }
    }
  `,
  generateProjectLeadCommissionReport: gql`
    mutation generatePlCommissionReport($month: Int!, $year: Int!) {
      generatePlCommissionReport(month: $month, year: $year) {
        ok
        url
      }
    }
  `,
  generateProjectLeadCommissionPDFReport: gql`
    query plCommissionPdfReport($month: Int!, $year: Int!, $plName: String) {
      plCommissionPdfReport(month: $month, year: $year, plName: $plName) {
        url
      }
    }
  `,
  generateBillingProjectReport: gql`
    mutation generateBillingReport(
      $devcenterIds: [Int]
      $month: Int!
      $year: Int!
      $reportType: String!
      $taskOrderId: Int!
      $channelPartnerReport: Boolean
    ) {
      generateBillingReport(
        month: $month
        year: $year
        reportType: $reportType
        taskOrderId: $taskOrderId
        devcenterIds: $devcenterIds
        channelPartnerReport: $channelPartnerReport
      ) {
        ok
        url
      }
    }
  `,
  generateBillingProjectExcelReport: gql`
    mutation generateSummaryReport(
      $month: Int!
      $year: Int!
      $deliveryCenterId: Int!
    ) {
      generateSummaryReport(
        month: $month
        year: $year
        deliveryCenterId: $deliveryCenterId
      ) {
        ok
        url
      }
    }
  `,
  generateBillingProjectToExcelReport: gql`
    mutation generateBillingReport(
      $month: Int!
      $year: Int!
      $taskOrderId: Int!
      $channelPartnerReport: Boolean
    ) {
      generateBillingReportExcel(
        month: $month
        year: $year
        taskOrderId: $taskOrderId
        channelPartnerReport: $channelPartnerReport
      ) {
        ok
        url
      }
    }
  `,
  generateUtilizationReport: gql`
    mutation generateUtilizationReport(
      $fields: GenerateUtilizationReportInput!
    ) {
      generateUtilizationReport(fields: $fields) {
        url
      }
    }
  `,
  todayCfrReport: gql`
    query todayCfrReport($month: Int!, $year: Int!) {
      todayCfrReport(
        month: $month
        year: $year
      ) {
        url
      }
    }
  `,
  generateCurrentPayrollReport: gql`
    mutation generateCurrentPayrollReport($fields: CurrentPayrollReportInput!) {
      generateCurrentPayrollReport(fields: $fields) {
        url
      }
    }
  `,
  generateCurrentPayrollExcelReport: gql`
    mutation generateCurrentPayrollExcelReport(
      $fields: CurrentPayrollReportInput!
    ) {
      generateCurrentPayrollExcelReport(fields: $fields) {
        url
      }
    }
  `,
  generatePtoReport: gql`
    mutation {
      generatePtoReport {
        url
      }
    }
  `,
  generateTodayBillingReport: gql`
    query todayBillingReport($dateOfReport: Date!, $devcenterId: [Int]) {
      todayBillingReport(
        dateOfReport: $dateOfReport
        devcenterId: $devcenterId
      ) {
        url
      }
    }
  `,

  deleteFile: (variables) => JSON.stringify({
    query: `mutation deleteFile($fileId: Int!) {
          deleteFile(fileId: $fileId)
            {
              ok
            }
        }`,
    variables,
  }),

  generateFile: (variables) => JSON.stringify({
    query: `mutation generateDocument($fields: ClientFileInput!, $fieldsTO: TaskOrderFileInput) {
        generateFile(clientFileFields: $fields, taskOrderFileFields: $fieldsTO)
          {
            ok
            fileId
          }
      }`,
    variables,
  }),

  getUploadedFile: (fileId) => `{
    node(id: "File:${fileId}") {
      ... on File   {
        isRequestToSignSent
        title
        fileId
        fileName
        fileType
        uploadedDate
        effectiveDate
        downloadUrl
        downloadSignedUrl
        isSigned
        isGenerated
        versionOfMsaTemplate
      }
    }
  }`,

  signDocument: (variables) => JSON.stringify({
    query: `mutation signDocument($fields: SignInput!) {
        signDocument(fields: $fields)
          {
            ok
          }
      }`,
    variables,
  }),

  generateReconciliationReport: gql`
    mutation generateReconciliationReport($fields: ReconciliationReportInput!) {
      generateReconciliationReport(fields: $fields) {
        url
      }
    }
  `,
  generateReconciliationSecondReport: gql`
    mutation generateReconciliationSecondReport(
      $fields: SecondReconciliationReportInput!
    ) {
      generateReconciliationSecondReport(fields: $fields) {
        url
      }
    }
  `,
  generateUtilizationForYearReport: gql`
    mutation generateUtilizationForYearReport(
      $fields: UtilizationForYearPdfReportInput!
    ) {
      generateUtilizationForYearReport(fields: $fields) {
        url
      }
    }
  `,
  generateUtilizationForMonthReport: gql`
    mutation generateUtilizationForMonthReport(
      $fields: UtilizationForMonthPdfReportInput!
    ) {
      generateUtilizationForMonthReport(fields: $fields) {
        url
      }
    }
  `,
  generateBookingReport: gql`
    mutation GenerateBookingReport($fields: GenerateBookingReportInput!) {
      generateBookingReport(fields: $fields) {
        url
      }
    }
  `,
  generateInternalToExcelReport: gql`
    mutation generateInternalToReport(
      $month: Int!
      $year: Int!
      $fields: InternalToInput!
    ) {
      generateInternalToReport(month: $month, year: $year, fields: $fields) {
        ok
        url
      }
    }
  `,
  generateMentoringCompensationExcelReport: gql`
    mutation generateMentoringCompensationReport($month: Int!, $year: Int!) {
      generateMentoringCompensationReport(month: $month, year: $year) {
        ok
        url
      }
    }
  `,
};
