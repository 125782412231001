import React from 'react';

import PropTypes from 'prop-types';

import classes from 'forms/user-management-form/table.module.scss';

const loginHistoryTableHeaders = ['Date', 'IP Address', 'Message'];

export const LoginHistoryTable = ({ loginLogsHistory }) => (
  <table className={classes.table}>
    <thead>
      <tr>
        {loginHistoryTableHeaders.map((headerName) => (
          <td className={classes.tableHeader} key={headerName}>
            {headerName}
          </td>
        ))}
      </tr>
    </thead>
    <tbody>
      {loginLogsHistory.map((loginLog) => (
        <tr key={loginLog.loginDate}>
          <td>
            {loginLog.loginDate}
          </td>
          <td>
            {loginLog.ip}
          </td>
          <td>
            {loginLog.message}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

LoginHistoryTable.propTypes = {
  loginLogsHistory: PropTypes.arrayOf(PropTypes.shape({
    loginDate: PropTypes.string.isRequired,
    ip: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  })),
};

LoginHistoryTable.defaultProps = {
  loginLogsHistory: [],
};
