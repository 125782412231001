import base64url from 'base64url';

export const TOKEN_EXPIRATION_OFFSET_SECONDS = 120;

export const getHasPermissions = (role, guaranteedAccessRoles) => guaranteedAccessRoles.includes(role);

export const getTokenPayload = (token) => JSON.parse(base64url.decode(token.split('.')[1]));

export const parseJWT = (token) => {
  const payload = getTokenPayload(token);

  return {
    userId: payload.userID,
    userGroup: payload.extended,
  };
};

export const getIsTokenExpire = (token) => {
  let hasExpired = false;
  let remainingTime = 0;
  try {
    const tokenPayload = getTokenPayload(token);
    const currentTime = Date.now();
    const expirationDate = (tokenPayload.exp - TOKEN_EXPIRATION_OFFSET_SECONDS) * 1000;
    hasExpired = expirationDate ? currentTime > expirationDate : true;
    remainingTime = expirationDate - currentTime;
  } catch (err) {
    hasExpired = true;
  }

  return {
    hasExpired,
    remainingTime,
  };
};

export const minutesToMilliseconds = (minutes) => minutes * 60 * 1000;
export const millisecondsToMinutes = (milliseconds) => Math.floor(milliseconds / 60000);
