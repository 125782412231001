import { contractManagementActions } from 'core/delivery/contractManagement/actions';
import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import {
  ENTITY_NAME,
} from './constants';

export const deliveryActionsTypes = createTypes([
  ...async('CHANGE_FILTER'),
  ...async('GET_ASSIGNED_PLS'),
  ...async('DELETE_PLA_RECORD'),
  ...async('GET_PROJECTS_LIST'),
  ...async('GET_EMPLOYEES_LIST'),
  ...async('GENERATE_PL_APPROVALS'),
  ...async('GET_EMPLOYEES_DETAILS'),
  ...async('CREATE_EMPLOYEE'),
  ...async('UPDATE_PL_ASSIGNMENTS'),
  ...async('DELETE_WORKBOOK_RECORD'),
  ...async('CREATE_WORKBOOK_RECORD'),
  ...async('UPDATE_WORKBOOK_RECORD'),
  ...async('DELETE_EMPLOYEES_RECORD'),
  ...async('DELETE_MANAGE_PTO_RECORD'),
  ...async('UPDATE_EMPLOYEES_DETAILS'),
  ...async('CREATE_ONBOARDING_HISTORY'),
  ...async('UPDATE_ONBOARDING_HISTORY'),
  ...async('DELETE_PTO_REQUEST_RECORD'),
  ...async('CREATE_PTO_REQUEST_RECORD'),
  ...async('UPDATE_PTO_REQUEST_RECORD'),
  ...async('CHECK_UPDATES_EMPLOYEES_DETAILS'),
  ...async('CREATE_VACATION_MANAGEMENT_RECORD'),
  ...async('DELETE_VACATION_MANAGEMENT_RECORD'),
  ...async('CHECK_EMPLOYEE_EMAIL'),
  ...async('GENERATE_RESOURCE_BILLING_REPORT'),
  'RESET_FILTERS',
  'SET_ORDER_RULES',
  'SET_EMPLOYEE_DETAILS',
  'UPDATE_FAVORITES',
  'TOGGLE_FAVORITES',
  'ADD_WORKBOOK_TEMPLATE_RECORD',
  'TOGGLE_WORK_BOOK_RECORD_STATE',
  'UPDATE_FAVORITES_EMPLOYEES_LIST',
  'TOGGLE_PTO_REQUEST_RECORD_STATE',
  'ADD_PTO_REQUEST_TEMPLATE_RECORD',
  'REMOVE_WORKBOOK_TEMPLATE_RECORD',
  'REMOVE_PTO_REQUEST_TEMPLATE_RECORD',
  'CLEAR_IS_EMPLOYEE_EMAIL_AVAILABLE',
], ENTITY_NAME);

export const deliveryActions = {
  ...contractManagementActions,
  deleteEmployeesRecord: createAction(
    deliveryActionsTypes.DELETE_EMPLOYEES_RECORD,
    ({ devstaffId }) => ({ devstaffId })
  ),
  deleteEmployeesRecordSuccess: createAction(
    deliveryActionsTypes.DELETE_EMPLOYEES_RECORD_SUCCESS,
    () => null,
    () => ({
      modalConductor: { nextModal: null },
    })
  ),
  deleteEmployeesRecordFail: createAction(
    deliveryActionsTypes.DELETE_EMPLOYEES_RECORD_FAIL,
    ({ error }) => ({ error })
  ),

  deletePLARecord: createAction(
    deliveryActionsTypes.DELETE_PLA_RECORD,
    ({
      recordId,
      projectKey,
      mutationName,
    }) => ({
      recordId,
      projectKey,
      mutationName,
    }),
    () => ({
      entityName: 'confirmActionModal',
    })
  ),

  deletePLARecordSuccess: createAction(
    deliveryActionsTypes.DELETE_PLA_RECORD_SUCCESS,
    ({
      updatedProject,
      projectKey,
    }) => ({
      updatedProject,
      projectKey,
    }),
  ),

  deletePLARecordFail: createAction(
    deliveryActionsTypes.DELETE_PLA_RECORD_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),
  updatePLAssignments: createAction(
    deliveryActionsTypes.UPDATE_PL_ASSIGNMENTS,
    ({
      fields,
      initialValues,
    }) => ({
      fields,
      initialValues,
    }),
  ),

  updatePLAssignmentsSuccess: createAction(
    deliveryActionsTypes.UPDATE_PL_ASSIGNMENTS_SUCCESS,
    ({ internalCategoryKey: projectKey, updatedProject }) => ({ projectKey, updatedProject }),
  ),

  updatePLAssignmentsFail: createAction(
    deliveryActionsTypes.UPDATE_PL_ASSIGNMENTS_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  getAssignedPLs: createAction(
    deliveryActionsTypes.GET_ASSIGNED_PLS,
    ({ internalCategoryKey: projectKey }) => ({ projectKey }),
  ),

  getAssignedPLsSuccess: createAction(
    deliveryActionsTypes.GET_ASSIGNED_PLS_SUCCESS,
    ({ projectLeads }) => ({ projectLeads }),
  ),

  getAssignedPLsFail: createAction(
    deliveryActionsTypes.GET_ASSIGNED_PLS_FAIL,
    ({
      error,
    }) => ({
      error,
    }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  deleteVacationManagementRecord: createAction(
    deliveryActionsTypes.DELETE_VACATION_MANAGEMENT_RECORD,
    ({
      vacationManagementId,
      devstaffId,
    }) => ({
      vacationManagementId,
      devstaffId,
    }),
  ),

  deleteVacationManagementRecordSuccess: createAction(
    deliveryActionsTypes.DELETE_VACATION_MANAGEMENT_RECORD_SUCCESS,
    () => ({}),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  deleteVacationManagementRecordFail: createAction(
    deliveryActionsTypes.DELETE_VACATION_MANAGEMENT_RECORD_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  createVacationManagementRecord: createAction(
    deliveryActionsTypes.CREATE_VACATION_MANAGEMENT_RECORD,
    ({
      fields,
      vacationByYearId,
      initialValues,
      devstaffId,
    }) => ({
      fields,
      vacationByYearId,
      initialValues,
      devstaffId,
    }),
  ),

  createVacationManagementRecordSuccess: createAction(
    deliveryActionsTypes.CREATE_VACATION_MANAGEMENT_RECORD_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  createVacationManagementRecordFail: createAction(
    deliveryActionsTypes.CREATE_VACATION_MANAGEMENT_RECORD_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  checkUpdatesEmployeesDetails: createAction(
    deliveryActionsTypes.CHECK_UPDATES_EMPLOYEES_DETAILS,
    ({
      devstaffId,
    }) => ({
      devstaffId,
    })
  ),

  checkUpdatesEmployeesDetailsSuccess: createAction(
    deliveryActionsTypes.CHECK_UPDATES_EMPLOYEES_DETAILS_SUCCESS,
    ({
      employeeDetails,
    }) => ({
      employeeDetails,
    }),
  ),

  checkUpdatesEmployeesDetailsFail: createAction(
    deliveryActionsTypes.CHECK_UPDATES_EMPLOYEES_DETAILS_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  createEmployee: createAction(
    deliveryActionsTypes.CREATE_EMPLOYEE,
    ({ fields }) => ({
      fields,
    }),
  ),

  createEmployeeSuccess: createAction(
    deliveryActionsTypes.CREATE_EMPLOYEE_SUCCESS,
    ({ devstaffId }) => ({
      devstaffId,
    }),
  ),

  createEmployeeFail: createAction(
    deliveryActionsTypes.CREATE_EMPLOYEE_FAIL,
    ({ error }) => ({
      error,
    }),
  ),

  updateWorkbookRecord: createAction(
    deliveryActionsTypes.UPDATE_WORKBOOK_RECORD,
    ({
      fields,
      recordId,
      initialValues,
      devstaffId,
    }) => ({
      fields,
      recordId,
      initialValues,
      devstaffId,
    }),
    ({
      entityName = ENTITY_NAME,
    }) => ({
      entityName,
    }),
  ),

  updateWorkbookRecordSuccess: createAction(
    deliveryActionsTypes.UPDATE_WORKBOOK_RECORD_SUCCESS,
    ({
      recordId,
      fields,
      devCentersBySystemRole,
      systemRole,
      entityName,
    }) => ({
      recordId,
      fields,
      devCentersBySystemRole,
      systemRole,
      entityName,
    }),
    ({
      withModal,
    }) => withModal ? {
      modalConductor: {
        nextModal: null,
      },
    } : null
  ),

  updateWorkbookRecordFail: createAction(
    deliveryActionsTypes.UPDATE_WORKBOOK_RECORD_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  createWorkbookRecord: createAction(
    deliveryActionsTypes.CREATE_WORKBOOK_RECORD,
    ({
      devstaffId,
      fields,
    }) => ({
      fields,
      devstaffId,
    })
  ),

  createWorkbookRecordSuccess: createAction(
    deliveryActionsTypes.CREATE_WORKBOOK_RECORD_SUCCESS,
    ({
      fields,
    }) => ({
      fields,
    })
  ),

  createWorkbookRecordFail: createAction(
    deliveryActionsTypes.CREATE_WORKBOOK_RECORD_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  deleteWorkbookRecord: createAction(
    deliveryActionsTypes.DELETE_WORKBOOK_RECORD,
    ({
      devstaffId,
      recordId,
    }) => ({
      recordId,
      devstaffId,
    })
  ),

  deleteWorkbookRecordSuccess: createAction(
    deliveryActionsTypes.DELETE_WORKBOOK_RECORD_SUCCESS,
    ({
      recordId,
    }) => ({
      recordId,
    }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  deleteWorkbookRecordFail: createAction(
    deliveryActionsTypes.DELETE_WORKBOOK_RECORD_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  addWorkBookTemplateRecord: createAction(deliveryActionsTypes.ADD_WORKBOOK_TEMPLATE_RECORD),

  removeWorkBookTemplateRecord: createAction(deliveryActionsTypes.REMOVE_WORKBOOK_TEMPLATE_RECORD),

  toggleWorkBookRecordState: createAction(
    deliveryActionsTypes.TOGGLE_WORK_BOOK_RECORD_STATE,
    ({
      recordId,
    }) => ({
      recordId,
    }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  updateOnboardingHistory: createAction(
    deliveryActionsTypes.UPDATE_ONBOARDING_HISTORY,
    ({
      historyId,
      devstaffId,
      fields,
      initialValues,
    }) => ({
      historyId,
      devstaffId,
      fields,
      initialValues,
    }),
    ({
      isIntermediateStage = false,
    }) => ({
      isIntermediateStage,
    })
  ),

  updateOnboardingHistorySuccess: createAction(
    deliveryActionsTypes.UPDATE_ONBOARDING_HISTORY_SUCCESS,
    ({
      historyId,
      fields,
    }) => ({
      historyId,
      fields,
    })
  ),

  updateOnboardingHistoryFail: createAction(
    deliveryActionsTypes.UPDATE_ONBOARDING_HISTORY_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  createOnboardingHistory: createAction(
    deliveryActionsTypes.CREATE_ONBOARDING_HISTORY,
    ({
      devstaffId,
      fields,
      initialValues,
    }) => ({
      devstaffId,
      fields,
      initialValues,
    })
  ),

  createOnboardingHistorySuccess: createAction(
    deliveryActionsTypes.CREATE_ONBOARDING_HISTORY_SUCCESS,
    ({
      fields,
    }) => ({
      fields,
    })
  ),

  createOnboardingHistoryFail: createAction(
    deliveryActionsTypes.CREATE_ONBOARDING_HISTORY_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  updateEmployeeDetails: createAction(
    deliveryActionsTypes.UPDATE_EMPLOYEES_DETAILS,
    ({
      devstaffId,
      fields = {},
      initialValues = {},
    }) => ({
      devstaffId,
      fields,
      initialValues,
    })
  ),

  updateEmployeeDetailsSuccess: createAction(
    deliveryActionsTypes.UPDATE_EMPLOYEES_DETAILS_SUCCESS,
    ({
      devstaffId,
      fields,
    }) => ({
      devstaffId,
      fields,
    })
  ),

  updateEmployeeDetailsFail: createAction(
    deliveryActionsTypes.UPDATE_EMPLOYEES_DETAILS_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  getEmployeeDetails: createAction(
    deliveryActionsTypes.GET_EMPLOYEES_DETAILS,
    ({
      employeeId,
      isCreating,
    }) => ({
      employeeId,
      isCreating,
    }),
    ({
      withReset = false,
    }) => ({
      withReset,
    })
  ),

  getEmployeeDetailSuccess: createAction(
    deliveryActionsTypes.GET_EMPLOYEES_DETAILS_SUCCESS,
    ({
      employeeDetails,
      employeeDetailsTabs,
    }) => ({
      employeeDetails,
      employeeDetailsTabs,
    })
  ),

  getEmployeeDetailFail: createAction(
    deliveryActionsTypes.GET_EMPLOYEES_DETAILS_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),

  getEmployeesList: createAction(deliveryActionsTypes.GET_EMPLOYEES_LIST),

  getEmployeesListSuccess: createAction(
    deliveryActionsTypes.GET_EMPLOYEES_LIST_SUCCESS,
    ({
      employeesList,
    }) => ({
      employeesList,
    })
  ),

  getEmployeesListFail: createAction(
    deliveryActionsTypes.GET_EMPLOYEES_LIST_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),

  getProjectsList: createAction(deliveryActionsTypes.GET_PROJECTS_LIST),

  getProjectsListSuccess: createAction(
    deliveryActionsTypes.GET_PROJECTS_LIST_SUCCESS,
    ({
      projectsList,
      plList,
    }) => ({
      projectsList,
      plList,
    })
  ),

  getProjectsListFail: createAction(
    deliveryActionsTypes.GET_PROJECTS_LIST_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),

  changeFilter: createAction(
    deliveryActionsTypes.CHANGE_FILTER,
    ({
      currentFilter,
      storeKey,
      selected = null,
      type,
    }) => ({
      currentFilter,
      storeKey,
      selected,
      type,
    }),
    () => ({
      entityName: ENTITY_NAME,
    })
  ),

  changeFilterSuccess: createAction(
    deliveryActionsTypes.CHANGE_FILTER_SUCCESS,
    ({
      currentFilter,
      storeKey,
      config,
    }) => ({
      currentFilter,
      storeKey,
      config,
    })
  ),

  resetFilters: createAction(
    deliveryActionsTypes.RESET_FILTERS,
    ({
      currentFilter,
    }) => ({
      currentFilter,
    })
  ),

  toggleFavorite: createAction(
    deliveryActionsTypes.TOGGLE_FAVORITES,
    ({ id }) => ({ id }),
    ({ entityName }) => ({ entityName })
  ),

  updateFavoritesEmployeesList: createAction(
    deliveryActionsTypes.UPDATE_FAVORITES_EMPLOYEES_LIST,
    ({ employeesList }) => ({ employeesList })
  ),

  updateFavorites: createAction(
    deliveryActionsTypes.UPDATE_FAVORITES,
    ({ updatedFavorites }) => ({ updatedFavorites }),
    ({ entityName }) => ({
      entityName,
    })
  ),

  setOrderRules: createAction(
    deliveryActionsTypes.SET_ORDER_RULES,
    ({
      tableName,
      orderRules,
    }) => ({
      tableName,
      orderRules,
    })
  ),

  setEmployeeDetails: createAction(deliveryActionsTypes.SET_EMPLOYEE_DETAILS),

  addPtoRequestTemplateRecord: createAction(
    deliveryActionsTypes.ADD_PTO_REQUEST_TEMPLATE_RECORD,
    ({
      year,
      permanentId,
    }) => ({
      year,
      permanentId,
    }),
  ),
  removePtoRequestTemplateRecord: createAction(
    deliveryActionsTypes.REMOVE_PTO_REQUEST_TEMPLATE_RECORD,
    ({
      year,
      permanentId,
    }) => ({
      year,
      permanentId,
    }),
  ),

  togglePtoRequestRecordState: createAction(
    deliveryActionsTypes.TOGGLE_PTO_REQUEST_RECORD_STATE,
    ({
      recordId,
    }) => ({
      recordId,
    }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),

  deletePtoRequestRecord: createAction(
    deliveryActionsTypes.DELETE_PTO_REQUEST_RECORD,
    ({
      devstaffId,
      recordId,
    }) => ({
      recordId,
      devstaffId,
    }),
  ),

  deletePtoRequestRecordSuccess: createAction(
    deliveryActionsTypes.DELETE_PTO_REQUEST_RECORD_SUCCESS,
    ({
      recordId,
      devstaffId,
    }) => ({
      recordId,
      devstaffId,
    }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  deletePtoRequestRecordFail: createAction(
    deliveryActionsTypes.DELETE_PTO_REQUEST_RECORD_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  deleteManagePtoRecord: createAction(
    deliveryActionsTypes.DELETE_MANAGE_PTO_RECORD,
    ({
      devstaffId,
      recordId,
    }) => ({
      recordId,
      devstaffId,
    }),
  ),

  createPtoRequestRecord: createAction(
    deliveryActionsTypes.CREATE_PTO_REQUEST_RECORD,
    ({
      devstaffId,
      fields,
      permanentId,
    }) => ({
      fields,
      devstaffId,
      permanentId,
    }),
  ),

  createPtoRequestRecordSuccess: createAction(
    deliveryActionsTypes.CREATE_PTO_REQUEST_RECORD_SUCCESS,
    ({
      fields,
      permanentId,
    }) => ({
      fields,
      permanentId,
    })
  ),

  createPtoRequestRecordFail: createAction(
    deliveryActionsTypes.CREATE_PTO_REQUEST_RECORD_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  updatePtoRequestRecord: createAction(
    deliveryActionsTypes.UPDATE_PTO_REQUEST_RECORD,
    ({
      fields,
      recordId,
      initialValues,
      devstaffId,
    }) => ({
      fields,
      recordId,
      initialValues,
      devstaffId,
    }),
    ({
      entityName = ENTITY_NAME,
    }) => ({
      entityName,
    }),
  ),

  updatePtoRequestRecordSuccess: createAction(
    deliveryActionsTypes.UPDATE_PTO_REQUEST_RECORD_SUCCESS,
    ({
      recordId,
      fields,
    }) => ({
      recordId,
      fields,
    }),
    ({
      withModal,
    }) => withModal ? {
      modalConductor: {
        nextModal: null,
      },
    } : null
  ),

  updatePtoRequestRecordFail: createAction(
    deliveryActionsTypes.UPDATE_PTO_REQUEST_RECORD_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  generatePlApprovals: createAction(
    deliveryActionsTypes.GENERATE_PL_APPROVALS,
    ({
      devstaffId,
      recordId,
    }) => ({
      recordId,
      devstaffId,
    }),
  ),

  generatePlApprovalsSuccess: createAction(
    deliveryActionsTypes.GENERATE_PL_APPROVALS_SUCCESS,
    ({
      fields,
      recordId,
    }) => ({
      fields,
      recordId,
    })
  ),

  generatePlApprovalsFail: createAction(
    deliveryActionsTypes.GENERATE_PL_APPROVALS_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  checkEmployeeEmailSuccess: createAction(deliveryActionsTypes.CHECK_EMPLOYEE_EMAIL_SUCCESS),
  checkEmployeeEmailFail: createAction(deliveryActionsTypes.CHECK_EMPLOYEE_EMAIL_FAIL),
  clearIsEmployeeEmailAvailable: createAction(deliveryActionsTypes.CLEAR_IS_EMPLOYEE_EMAIL_AVAILABLE),
  generateResourceBillingReport: createAction(deliveryActionsTypes.GENERATE_RESOURCE_BILLING_REPORT),
  generateResourceBillingReportSuccess: createAction(deliveryActionsTypes.GENERATE_RESOURCE_BILLING_REPORT_SUCCESS),
  generateResourceBillingReportFail: createAction(deliveryActionsTypes.GENERATE_RESOURCE_BILLING_REPORT_FAIL),
};
