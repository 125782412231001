export const ENGLISH_LEVELS = [
  {
    value: 'A0',
    label: 'A0 - Basic',
  },
  {
    value: 'A1',
    label: 'A1 - Elementary',
  },
  {
    value: 'A2',
    label: 'A2 - Pre-Intermediate',
  },
  {
    value: 'B1',
    label: 'B1 - Intermediate',
  },
  {
    value: 'B2',
    label: 'B2 - Upper-Intermediate',
  },
  {
    value: 'C1',
    label: 'C1 - Advanced',
  },
  {
    value: 'C2',
    label: 'C2 - Proficiency',
  },
];
