import { colorSecondaryGrayLight } from 'assets/styles/variables';
import { STORE_KEYS } from 'core/billing-reports/constants';
import { FILTERS_TYPES } from 'core/constants';
import {
  get,
  intersection,
} from 'lodash';
import moment from 'moment';

import { getMonthIndexByShortName } from 'utils/helpers/date';
import { checkIsSelected } from 'utils/helpers/filters';

import { isDisabled } from './utils';

const selectUnitCss = `
  && {
    .filter-select__menu {
      min-width: 100%;
    }
    .filter-select__placeholder {
      font-size: 1.3rem;
      color: ${colorSecondaryGrayLight};
    }
  }
`;

const isValidItem = (item, filters) => {
  const commonFilter = get(filters, 'annualReportCommonFilter', {});

  const selectedDevcenterIds = get(commonFilter, 'devcenter.selected', []);
  if (selectedDevcenterIds.length !== 0 && intersection(item.devcenterIds, selectedDevcenterIds).length === 0) {
    return false;
  }

  const selectedStaff = get(commonFilter, 'staff.selected', null);
  // eslint-disable-next-line no-bitwise
  if (selectedStaff != null && (selectedStaff === 'admin' ^ item.isAdminStaff)) {
    return false;
  }

  const currencies = get(commonFilter, 'currency.selected', null);
  if (currencies != null && currencies.length !== 0) {
    return intersection(item.employeeCurrenciesForYear, currencies).length !== 0;
  }

  return true;
};

const getMonthsData = (currentRow, filters) => {
  const selectedFilters = filters.reduce(
    (result, [name, filter]) => filter.isActive ?
      ({
        ...result,
        [name]: filter.selected,
      }) :
      name,
    {},
  );

  const originalMonthsData = get(currentRow, 'monthsData', []);
  if (originalMonthsData.cachedFilters === filters && originalMonthsData.cachedData != null) {
    return originalMonthsData.cachedData;
  }

  let monthsData = originalMonthsData;
  const selectedMonths = selectedFilters[STORE_KEYS.MONTH];
  if (selectedMonths != null) {
    const monthsIndexes = selectedMonths.map(getMonthIndexByShortName);
    monthsData = monthsIndexes.map((monthsIndex) => monthsData[monthsIndex]);
  }

  const selectedSeniorities = selectedFilters[STORE_KEYS.SENIORITY];
  if (selectedSeniorities != null) {
    monthsData = monthsData.filter((monthData) => (
      selectedSeniorities.some((seniority) => get(monthData, 'seniority') === seniority)
    ));
  }

  const currencies = get(currentRow, 'currencyFilterItems', [])
    .map(({ value }) => value);

  const selectedHasSalaryRise = selectedFilters[STORE_KEYS.HAS_SALARY_RISE];
  const selectedCurrencies = selectedFilters[STORE_KEYS.CURRENCY] || currencies;

  if (selectedHasSalaryRise !== undefined) {
    monthsData = monthsData.filter((monthData) => (
      selectedCurrencies.some((currency) => get(monthData, `[${currency}].hasRise`, false))
    ));
  }

  monthsData = monthsData.filter((monthData) => (
    selectedCurrencies.some((currency) => !isDisabled(get(monthData, `[${currency}].rate`)))
  ));

  originalMonthsData.cachedFilters = filters;
  originalMonthsData.cachedData = monthsData;

  return monthsData;
};

const devstaffName = {
  type: FILTERS_TYPES.SELECT,
  storeKey: STORE_KEYS.DEVSTAFF_NAME,
  componentProps: {
    placeholder: 'All Resources',
    cssRules: selectUnitCss,
  },

  valueGetter: (item, filters) => isValidItem(item, filters) ?
    item.devstaffName :
    null,

  checkFilter: ({ currentRow, selected }) => {
    const value = get(currentRow, 'devstaffName', '');
    return checkIsSelected({ selected, value });
  },
};

const seniority = {
  type: FILTERS_TYPES.SELECT,
  storeKey: STORE_KEYS.SENIORITY,
  componentProps: {
    placeholder: 'Seniority',
    cssRules: selectUnitCss,
  },
  valueGetter: (item, filters) => {
    if (!isValidItem(item, filters)) {
      return null;
    }

    const items = new Set([]);

    item.monthsData.forEach((monthData) => {
      if (monthData) {
        items.add(monthData.seniority);
      }
    });

    return Array.from(items);
  },

  checkFilter: ({ currentRow }, filters) => getMonthsData(currentRow, filters).length === 0,
};

const month = {
  type: FILTERS_TYPES.SELECT,
  storeKey: STORE_KEYS.MONTH,
  componentProps: {
    placeholder: 'All Months',
    cssRules: selectUnitCss,
  },
  valueGetter: () => moment.monthsShort(),
  sortingComparator: () => 0,
  checkFilter: ({ currentRow }, filters) => getMonthsData(currentRow, filters).length === 0,
};

const hasSalaryRise = {
  type: FILTERS_TYPES.CHECKBOX,
  storeKey: STORE_KEYS.HAS_SALARY_RISE,
  componentProps: {
    title: 'Display only salary raises',
    cssRules: `
      && {
        margin-left: 1rem;
        font-size: 1.4rem;
        color: ${colorSecondaryGrayLight};
      }
    `,
  },
  valueGetter: ({ task }) => task,
  checkFilter: ({ currentRow }, filters) => getMonthsData(currentRow, filters).length === 0,
};

export const filtersTemplates = [{
  name: STORE_KEYS.ANNUAL_REPORT_COMMON_FILTER,
  filtersTemplate: [{
    storeKey: STORE_KEYS.DEVCENTER,
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'devcenterIds', '');

      return checkIsSelected({ selected, value });
    },
  }, {
    storeKey: STORE_KEYS.CURRENCY,
    checkFilter: ({ currentRow }, filters) => getMonthsData(currentRow, filters).length === 0,
  }, {
    storeKey: STORE_KEYS.STAFF,
    checkFilter: ({ currentRow, selected }) => {
      const isAdminStaff = get(currentRow, 'isAdminStaff', false);
      const isAdminSelected = selected === STORE_KEYS.ADMIN_STAFF;

      return !(isAdminSelected ? isAdminStaff : !isAdminStaff);
    },
  }],
}, {
  name: STORE_KEYS.ANNUAL_REPORT_ANALYSIS_FILTER,
  filtersTemplate: [{
    type: FILTERS_TYPES.CHECKBOX,
    storeKey: STORE_KEYS.IN_DEPTH_STUDY,
    checkFilter: ({ currentRow }) => {
      const isSingleCurrency = get(currentRow, 'isSingleCurrency', false);

      return isSingleCurrency ? get(currentRow, 'isTotal', false) : !get(currentRow, 'isTotal', false);
    },
  }],
}, {
  name: STORE_KEYS.ANNUAL_REPORT_SUMMARY_FILTER,
  filtersTemplate: [{
    type: FILTERS_TYPES.CHECKBOX,
    storeKey: STORE_KEYS.IN_DEPTH_STUDY,
    checkFilter: ({ currentRow }) => !get(currentRow, 'isTotal', false),
  }],
}, {
  name: STORE_KEYS.ANNUAL_REPORT_STAFF_FILTER,
  storeKey: 'annualReportStaffDataRows', // used to get items list for select-component
  filtersTemplate: [
    devstaffName,
    seniority,
    month,
    hasSalaryRise,
  ],
}];
