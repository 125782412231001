import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import Loader from 'components/loader';

import './styles.scss';

export const SubmitButton = ({ hasFormChanges, isFormSubmitted, buttonTitle, acceptActionTitle }) => (
  <button
    className={
      classNames(
        'form__button',
        { 'form__button--accept': hasFormChanges },
        { 'form__button--disabled': !hasFormChanges },
        { 'form__button--with-loader': isFormSubmitted }
      )
    }
    type="submit"
    disabled={!hasFormChanges}
  >
    <Loader isLoaded={!isFormSubmitted}>
      {acceptActionTitle || buttonTitle}
    </Loader>
  </button>
);

SubmitButton.propTypes = {
  hasFormChanges: PropTypes.bool,
  isFormSubmitted: PropTypes.bool,
  buttonTitle: PropTypes.string.isRequired,
  acceptActionTitle: PropTypes.string,
};

SubmitButton.defaultProps = {
  hasFormChanges: false,
  isFormSubmitted: false,
  acceptActionTitle: '',
};

