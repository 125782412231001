import React from 'react';

import PropTypes from 'prop-types';

import { PlusIcon } from 'assets/svgComponents';
import classNames from 'classnames';
import { ActionButton } from 'components/buttons';

import { useDropzone } from 'react-dropzone';

import styles from './styles.module.scss';

const TEXTS = {
  ADD_FILE: 'Add File',
};

export const Dropzone = ({
  accept,
  setFile,
  maxSize,
  isAllowedForEdit,
  errorClassName,
  formError,
  setError,
}) => {
  const handleOnDrop = (acceptedFiles) => {
    if (acceptedFiles[0]) {
      setError('file', null);
      setFile(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleOnDrop,
    onDropRejected: () => {
      setError('file', {
        type: 'custom',
        message: `File is larger than ${maxSize}MB`,
      });
    },
    maxSize: maxSize * 1024 * 1024,
    accept,
  });

  return (
    <div data-id={TEXTS.ADD_FILE} {...getRootProps()}>
      <input
        accept={accept}
        autoComplete="off"
        {...getInputProps()}
      />
      {
        <ActionButton
          title={TEXTS.ADD_FILE}
          dataId={TEXTS.ADD_FILE}
          content={TEXTS.ADD_FILE}
          className={styles.addFileButton}
          disabled={!isAllowedForEdit}
        >
          <PlusIcon />
          {TEXTS.ADD_FILE}
        </ActionButton>
      }
      {formError && <p className={classNames(styles.error, errorClassName)}>{formError}</p>}
    </div>
  );
};

Dropzone.propTypes = {
  accept: PropTypes.string,
  setFile: PropTypes.func.isRequired,
  isAllowedForEdit: PropTypes.bool.isRequired,
  maxSize: PropTypes.number,
  errorClassName: PropTypes.string,
  formError: PropTypes.string,
  setError: PropTypes.func.isRequired,
};

Dropzone.defaultProps = {
  accept: 'application/pdf',
  maxSize: 2,
  errorClassName: '',
  formError: '',
};
