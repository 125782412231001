import {
  COLUMN_TYPES,
} from 'core/constants';

import {
  formatDateMMDDYYYY,
  getUnixTime,
} from 'utils/helpers/date';

export default [
  {
    columnName: 'Date',
    valueGetter: ({
      logDate,
    }) => ({
      type: COLUMN_TYPES.TEXT,
      isDate: true,
      unixValue: getUnixTime(logDate),
      value: logDate ? formatDateMMDDYYYY(logDate) : '',
    }),
  },
  {
    columnName: 'Project',
    valueGetter: ({ projectName }) => ({
      value: projectName,
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data: projectName,
      },
    }),
  },
  {
    columnName: 'TO',
    valueGetter: ({ taskOrder, isActive, projectId, billType }, userGroup) => {
      const isRM = userGroup === 'hr';
      const isActiveTO = isActive ? taskOrder : 'NYA';
      const billTypeList = ['OOO-PTO', 'OOO-SL', 'OOO-NP', 'INT'];
      const TOvalue = billTypeList.includes(billType) ? 'INT' : isActiveTO;
      return ({
        value: TOvalue,
        type: isActive && !isRM ? 'link' : 'text',
        componentProps: {
          data: TOvalue,
          pathname: `/task-orders/${projectId}/details`,
        },
      });
    },
  },
  {
    columnName: 'Contract Type',
    valueGetter: ({ projectType }) => ({
      type: COLUMN_TYPES.TEXT,
      value: projectType,
    }),
  },
  {
    columnName: 'Worklog Type',
    valueGetter: ({ billType }) => ({
      type: COLUMN_TYPES.TEXT,
      value: billType,
    }),
  },
  {
    columnName: 'Hours',
    headerProps: {
      cssRules: `
      padding-right: 1rem;
      margin-left: auto;
      margin-right: 0;
      text-align: right;
    `,
    },
    valueGetter: ({ logHours }) => ({
      type: COLUMN_TYPES.TEXT,
      value: `${logHours ? logHours.toFixed(2) : '0.00'} h`,
      componentProps: {
        data: `${logHours ? logHours.toFixed(2) : '0.00'} h`,
        cssRules: `
          box-sizing: border-box;
          text-align: right;
        `,
        isMultiline: true,
      },
    }),
  },
];
