import { colorPrimary } from 'assets/styles/variables';
import { ADMIN_STAFF_ID, CUSTOMER_ID } from 'core/employees-reviews/constants';
import { get } from 'lodash';

export const optionStyles = {
  color: colorPrimary,
  borderBottomColor: `${colorPrimary}!important`,
  '&:hover': {
    color: colorPrimary,
  },
};

export const parseReview = (storedReview) => {
  const review = {
    ...storedReview,
  };
  const isCustomerReview = get(review, 'isCustomerReview', false);
  const isAdminStaffReview = get(review, 'isAdminStaffReview', false);

  if (isCustomerReview) {
    review.reviewbyId = CUSTOMER_ID;
  }

  if (isAdminStaffReview) {
    review.reviewbyId = ADMIN_STAFF_ID;
  }

  return review;
};

export const generateInitialTeamAndMap = () => {
  const customerRecord = {
    value: CUSTOMER_ID,
    label: 'Customer',
    styles: optionStyles,
  };
  const adminStaffRecord = {
    value: ADMIN_STAFF_ID,
    label: 'Admin Staff',
    styles: optionStyles,
  };

  return {
    initialTeam: [customerRecord, adminStaffRecord],
    initialTeamMap: {
      [CUSTOMER_ID]: customerRecord,
      [ADMIN_STAFF_ID]: adminStaffRecord,
    },
  };
};

export const generateTeamAndMap = (groupedReviewsList, groupedEmployeeByCategory, {
  plDevstaffId,
  plName,
  projectName,
}, index) => {
  const { initialTeam, initialTeamMap } = generateInitialTeamAndMap();
  const teamManagement = get(
    groupedReviewsList,
    `[${index}].teamManagement`,
    [
      ...initialTeam, Boolean(plName) && {
        value: plDevstaffId,
        label: `${plName}`,
        styles: optionStyles,
      },
    ]
  );
  const teamMap = get(groupedReviewsList, `[${index}].teamMap`, {
    ...initialTeamMap,
    ...(Boolean(plName) && { [plDevstaffId]: {
      value: plDevstaffId,
      label: `${plName}`,
      styles: optionStyles,
    } }),
  });

  groupedEmployeeByCategory.get(projectName).forEach((employee) => {
    if (!teamMap[employee.value]) {
      teamMap[employee.value] = employee;
      teamManagement.push(employee);
    }
  });

  return {
    teamManagement,
    teamMap,
  };
};
