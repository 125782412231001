/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import Calendar from 'components/calendar';
import Select from 'components/selectNew';
import { selectFormState } from 'core/delivery/contractManagement/selectors';
import {
  ControlsContainer,
} from 'layouts/employee-details/components/resource-tabs/components';

import { get } from 'lodash';
import moment from 'moment';

import styles from './styles.module.scss';

export const useListColumns = ({ technicalMentorsList, technicalMentorsMap, isCPS }) => useMemo(() => [
  {
    Header: 'Start Date',
    accessor: 'startDate',
    type: 'form',
    Field: ({
      field,
      fieldState,
      item,
    }) => (
      <div data-id="Calendar Container" className={styles.calendar}>
        <Calendar
          name={field.name}
          value={field.value && moment.parseZone(field.value, 'YYYY-MM-DD')}
          outputFormatting={({ date }) => (
            moment.isMoment(date) ?
              date.format('YYYY-MM-DD') :
              date
          )}
          onChange={(val) => {
            field.onChange(val);
          }}
          error={fieldState.error}
          withError={fieldState.invalid}
          isLocked={!item.allowedForEdit}
        />
        <div data-id="Calendar Field Error" className={styles.fieldError}>
          {get(fieldState, 'error.message', '')}
        </div>
      </div>
    ),
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    type: 'form',
    isOptional: true,
    Field: ({
      field,
      fieldState,
    }) => (
      <div data-id="Calendar Container" className={styles.calendar}>
        <Calendar
          name={field.name}
          value={field.value && moment.parseZone(field.value, 'YYYY-MM-DD')}
          outputFormatting={({ date }) => (
            moment.isMoment(date) ?
              date.format('YYYY-MM-DD') :
              date
          )}
          onChange={(val) => {
            field.onChange(val);
          }}
          error={fieldState.error}
          withError={fieldState.invalid}
          isLocked={isCPS}
          withClear={!!field.value}
        />
        <div data-id="Calendar Field Error" className={styles.fieldError}>
          {get(fieldState, 'error.message', '')}
        </div>
      </div>
    ),
  },
  {
    Header: 'Technical Mentor',
    accessor: 'technicalMentorId',
    type: 'form',
    Field: ({
      field,
      fieldState,
    }) => {
      const technicalMentor = technicalMentorsMap[field.value];
      const selected = {
        value: field.value,
        label: technicalMentor ? technicalMentor.label : '',
      };
      const fieldData = {
        selected,
        items: technicalMentorsList,
      };

      return (
        <Select
          name={field.name}
          fieldData={fieldData}
          onChange={(selectedData) => {
            field.onChange(selectedData);
          }}
          error={get(fieldState, 'error.message', '')}
          withError={fieldState.invalid}
        />
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'actions',
    type: 'action',
    Field: (props) => (
      <ControlsContainer
        {...props}
        formStateSelector={selectFormState}
      />
    ),
  },
], []);
