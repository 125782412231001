import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import ToastContainer from 'components/toast';
import { commonActions } from 'core/common/actions';

import { dashboardActions } from 'core/dashboard/actions';
import {
  selectOrderRules,
  selectEntityName,
  selectedDashboardData,
  selectDashboardDataModel,
  selectIsFetchingDashboardData,
} from 'core/dashboard/selectors';
import { deliveryActions } from 'core/delivery/actions';
import { selectIsFormSubmitted } from 'core/delivery/selectors';
import DashboardContent from 'layouts/dashboard';
import compose from 'lodash/fp/compose';
import { Switch, Route, Redirect, withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

const Dashboard = (props) => {
  const { entityName, setEntityName } = props;

  useEffect(() => {
    setEntityName(entityName);
  }, [entityName]);

  return (
    <>
      <ToastContainer containerId={entityName} />

      <Switch>
        <Route
          exact
          path="/home"
          render={() => <DashboardContent {...props} />}
        />

        <Redirect to="/reports/project-lead-commission" />

        <Redirect to="/clients" />
      </Switch>
    </>
  );
};

Dashboard.propTypes = {
  userGroup: PropTypes.string,
  entityName: PropTypes.string.isRequired,
  setEntityName: PropTypes.func.isRequired,
  guaranteedAccessRoles: PropTypes.arrayOf(PropTypes.string),
};

Dashboard.defaultProps = {
  userGroup: '',
  guaranteedAccessRoles: [],
};

const mapStateToProps = createStructuredSelector({
  orderRules: selectOrderRules,
  entityName: selectEntityName,
  dashboardData: selectedDashboardData,
  isFormSubmitted: selectIsFormSubmitted,
  dashboardDataModel: selectDashboardDataModel,
  isFetchingDashboardData: selectIsFetchingDashboardData,
});

const mapDispatchToProps = {
  getDashboardData: dashboardActions.getDashboardData,
  toggleWorkBookRecordState: dashboardActions.toggleWorkBookRecordState,
  updateWorkbookRecord: deliveryActions.updateWorkbookRecord,
  updatePtoRequestRecord: deliveryActions.updatePtoRequestRecord,
  setEntityName: commonActions.setEntityName,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);
