import React, { useEffect, useState, useMemo } from 'react';

import PropTypes from 'prop-types';

import Controls, { ExpandSection } from 'components/details-controls';
import NavigationTabs from 'components/details-tabs';
import { reviewsLinkAccess } from 'core/auth/guaranteedAccessRoles';
import { ENTITY_NAME_EMPLOYEES } from 'core/delivery/constants';
import StyledLink from 'elements/link';
import { ResourceTabs } from 'layouts/employee-details/components/resource-tabs';
import { get } from 'lodash';
import { withRouter } from 'react-router';

import { useHistory } from 'react-router-dom';
import { getHasPermissions } from 'utils/auth';
import { updateState } from 'utils/helpers/history';
import { autoScrollToTop } from 'utils/react';

const tabIndexToPath = ['details', 'work-book', 'pto-requests', 'project-lead-assignments'];

const EmployeeDetails = ({
  location,
  tabIndex,
  orderRules,
  employeeId,
  entityName,
  changeOrder,
  toggleFavorite,
  employeeDetails,
  isFormSubmitted,
  isFavoredEmployee,
  isCreating,
  isFetchingDetails,
  getEmployeeDetails,
  changeCurrentModal,
  generatePlApprovals,
  createWorkbookRecord,
  updateWorkbookRecord,
  updateEmployeeDetails,
  createPtoRequestRecord,
  updatePtoRequestRecord,
  createOnboardingHistory,
  updateOnboardingHistory,
  setEmployeeDetails,
  clearIsEmployeeEmailAvailable,
  createEmployee,
  addWorkBookTemplateRecord,
  toggleWorkBookRecordState,
  addPtoRequestTemplateRecord,
  togglePtoRequestRecordState,
  removeWorkBookTemplateRecord,
  removePtoRequestTemplateRecord,
  hasPermissionsToCreateResource,
  userGroup,
  isEmployeeEmailAvailable,
}) => {
  autoScrollToTop(location);
  const history = useHistory();
  const [indexOfActiveTab, setIndexOfActiveTab] = useState(tabIndex);
  const {
    tabData,
    tabHeadings,
    employeeStatus,
    reviewLinkCssRules,
    tabStylesTemplates,
    fullname = 'employee',
    allowbill,
    devstaffTechs,
    englishLevel,
    fullnameCyrillic,
    email,
    internshipStartDate,
    dateHired,
    isDelivery,
    technologiesList,
  } = employeeDetails;
  const isViewReviews = getHasPermissions(userGroup, reviewsLinkAccess);
  const isEditDetails = indexOfActiveTab === 0 && Boolean(get(employeeDetails, 'devstaffId', false));

  useEffect(() => {
    const isTabLocked = get(tabHeadings, `[${indexOfActiveTab}].isLocked`, false);
    if (isTabLocked) {
      setTabIndex(0);
    }
  }, [isFetchingDetails]);

  useEffect(() => {
    if (isCreating && !hasPermissionsToCreateResource) {
      history.push('/delivery/employees-list');
    } else {
      getEmployeeDetails({ employeeId, withReset: true });
    }
  }, [employeeId]);

  const setTabIndex = (index) => {
    if (!isFetchingDetails) {
      updateState({ tabIndex: index }, tabIndexToPath[index]);
      setIndexOfActiveTab(index);
    }
  };

  useEffect(() => {
    if (isEmployeeEmailAvailable) {
      clearIsEmployeeEmailAvailable();
      setTabIndex(1);
    }
  }, [isEmployeeEmailAvailable]);

  const setDetailsTabHandler = (extendedFieldsData) => {
    const { devstaffTechs: devstaffTechsFromForm, isDelivery: isDeliveryFromForm, ...rest } = extendedFieldsData;
    const techsFromList = technologiesList.filter((item) => devstaffTechsFromForm.includes(item.technologyId));
    setEmployeeDetails({
      devstaffTechs: techsFromList,
      isDelivery: Number(isDeliveryFromForm),
      ...rest,
    });
  };

  const onSubmit = ({ fields, initialValues, onSubmitAction, additionalFields }) => {
    const { historyId, recordId, permanentId, ...other } = additionalFields;
    const extendedFieldsData = {
      ...fields,
      ...other,
    };
    switch (onSubmitAction) {
      case 'createEmployee':
        createEmployee({
          fields: {
            internshipStartDate,
            fullname,
            englishLevel,
            fullnameCyrillic,
            email,
            isDelivery,
            allowBilling: allowbill,
            dateFirst: dateHired,
            devstaffTechs: devstaffTechs.map((item) => item.technologyId),
            compensation: {
              ...fields,
            },
          },
        });
        break;
      case 'updateWorkbookRecord':
        updateWorkbookRecord({ devstaffId: employeeId, fields: extendedFieldsData, recordId, initialValues });
        break;
      case 'createWorkbookRecord':
        createWorkbookRecord({ devstaffId: employeeId, fields: extendedFieldsData, initialValues });
        break;
      case 'createHistory':
        createOnboardingHistory({ devstaffId: employeeId, fields: extendedFieldsData, initialValues });
        break;
      case 'updateHistory':
        updateOnboardingHistory({ devstaffId: employeeId, fields, historyId, initialValues });
        break;
      case 'createPtoRequestRecord':
        createPtoRequestRecord({ devstaffId: employeeId, fields: extendedFieldsData, permanentId }); // TODO
        break;
      case 'updatePtoRequestRecord':
        updatePtoRequestRecord({ devstaffId: employeeId, fields: extendedFieldsData, recordId, initialValues }); // TODO
        break;
      case 'setEmployeeDetails':
        setDetailsTabHandler(extendedFieldsData);
        break;
      default:
        updateEmployeeDetails({ devstaffId: employeeId, fields: extendedFieldsData, initialValues });
    }
  };

  const additionalActions = {
    onSubmit,
    log: () => null,
    generatePlApprovals,
    updateWorkbookRecord,
    updatePtoRequestRecord,
    toggleWorkBookRecordState,
    addWorkBookTemplateRecord,
    addPtoRequestTemplateRecord,
    togglePtoRequestRecordState,
    removeWorkBookTemplateRecord,
    removePtoRequestTemplateRecord,
    openModal: ({ currentModal, ...args }) => changeCurrentModal({ currentModal, params: { ...args } }),
  };

  const data = useMemo(() => tabData.reduce((acc, item, currentIndex) => {
    acc[currentIndex] = {
      body: item,
      title: tabHeadings[currentIndex].title,
    };

    return acc;
  }, {}), [employeeDetails]);

  return (
    <>
      <Controls
        toggleFavorite={() => toggleFavorite({ entityName: ENTITY_NAME_EMPLOYEES, id: employeeId })}
        isCreatMode={isCreating}
        isFavored={isFavoredEmployee}
        isFetchingDetails={isFetchingDetails}
        controlsHeading={fullname}
        goBackLinkPath="/delivery/employees-list"
        goBackLinkText="back to delivery center staff list"
      >
        <ExpandSection
          hasWarning
          title={employeeStatus}
        />
        { isViewReviews && (
          <StyledLink
            data="View Review"
            pathname={`/devstaff-reviews/report/${employeeId}`}
            withBlank
            wrapperCssRules={reviewLinkCssRules}
          />
        ) }
      </Controls>

      <NavigationTabs
        indexOfActiveTab={indexOfActiveTab}
        setIndexOfActiveTab={setTabIndex}
        tabHeadings={tabHeadings}
      />

      <ResourceTabs
        name={tabHeadings[indexOfActiveTab].title}
        tabStylesTemplate={tabStylesTemplates[indexOfActiveTab]}
        body={get(data, `[${indexOfActiveTab}].body`, [])}
        additionalActions={additionalActions}
        orderRules={orderRules}
        changeOrder={changeOrder}
        entityName={entityName}
        isFormSubmitted={isFormSubmitted}
        changeCurrentModal={changeCurrentModal}
        isFetchingDetails={isFetchingDetails}
        onSubmit={onSubmit}
        devstaffId={get(employeeDetails, 'devstaffId', '')}
        isEditDetails={isEditDetails}
        resourceTabIndex={indexOfActiveTab}
        userGroup={userGroup}
      />
    </>
  );
};

EmployeeDetails.propTypes = {
  location: PropTypes.object.isRequired,
  entityName: PropTypes.string.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  changeOrder: PropTypes.func.isRequired,
  updateWorkbookRecord: PropTypes.func.isRequired,
  createWorkbookRecord: PropTypes.func.isRequired,
  toggleWorkBookRecordState: PropTypes.func.isRequired,
  removeWorkBookTemplateRecord: PropTypes.func.isRequired,
  addWorkBookTemplateRecord: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  updateOnboardingHistory: PropTypes.func.isRequired,
  createOnboardingHistory: PropTypes.func.isRequired,
  isCreating: PropTypes.bool,
  isFormSubmitted: PropTypes.bool.isRequired,
  updateEmployeeDetails: PropTypes.func.isRequired,
  setEmployeeDetails: PropTypes.func.isRequired,
  clearIsEmployeeEmailAvailable: PropTypes.func.isRequired,
  createEmployee: PropTypes.func.isRequired,
  employeeId: PropTypes.string || null,
  tabIndex: PropTypes.number,
  toggleFavorite: PropTypes.func.isRequired,
  isFetchingDetails: PropTypes.bool.isRequired,
  employeeDetails: PropTypes.shape({}),
  getEmployeeDetails: PropTypes.func.isRequired,
  isFavoredEmployee: PropTypes.bool.isRequired,
  addPtoRequestTemplateRecord: PropTypes.func.isRequired,
  togglePtoRequestRecordState: PropTypes.func.isRequired,
  updatePtoRequestRecord: PropTypes.func.isRequired,
  removePtoRequestTemplateRecord: PropTypes.func.isRequired,
  createPtoRequestRecord: PropTypes.func.isRequired,
  generatePlApprovals: PropTypes.func.isRequired,
  hasPermissionsToCreateResource: PropTypes.bool.isRequired,
  userGroup: PropTypes.string.isRequired,
  isEmployeeEmailAvailable: PropTypes.bool.isRequired,
};

EmployeeDetails.defaultProps = {
  employeeId: null,
  tabIndex: 0,
  employeeDetails: {},
  isCreating: false,
};

export default withRouter(EmployeeDetails);
