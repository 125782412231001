import { createAction } from 'redux-actions';

import { createTypes, async } from 'utils/helpers/actions';

import { ENTITY_NAME } from './constants';

export const dashboardActionsTypes = createTypes([
  ...async('GET_DASHBOARD_DATA'),
  ...async('DELETE_WORKBOOK_RECORD'),
  'SET_ORDER_RULES',
  'TOGGLE_WORK_BOOK_RECORD_STATE',
], ENTITY_NAME);

export const dashboardActions = {
  toggleWorkBookRecordState: createAction(
    dashboardActionsTypes.TOGGLE_WORK_BOOK_RECORD_STATE,
    ({ recordId }) => ({ recordId }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  getDashboardData: createAction(dashboardActionsTypes.GET_DASHBOARD_DATA),

  getDashboardDataSuccess: createAction(
    dashboardActionsTypes.GET_DASHBOARD_DATA_SUCCESS,
    ({ dashboardData }) => ({ dashboardData })
  ),

  getDashboardDataFail: createAction(
    dashboardActionsTypes.GET_DASHBOARD_DATA_FAIL,
    ({ error = {} }) => ({ error })
  ),

  setOrderRules: createAction(
    dashboardActionsTypes.SET_ORDER_RULES,
    ({ tableName, orderRules }) => ({ tableName, orderRules })
  ),

  deleteWorkbookRecord: createAction(
    dashboardActionsTypes.DELETE_WORKBOOK_RECORD,
    ({
      devstaffId,
      recordId,
    }) => ({
      recordId,
      devstaffId,
    })
  ),

  deleteWorkbookRecordSuccess: createAction(
    dashboardActionsTypes.DELETE_WORKBOOK_RECORD_SUCCESS,
    ({
      recordId,
    }) => ({
      recordId,
    }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  deleteWorkbookRecordFail: createAction(
    dashboardActionsTypes.DELETE_WORKBOOK_RECORD_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),
};
