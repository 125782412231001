import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import Wrapper from 'elements/wrapper';
import Title from 'elements/title';
import StarIcon from 'elements/star';
import StyledWrapper from 'elements/styled-wrapper';

import ExpandButton from './expand-button';

import './styles.scss';


const DetailsControls = ({
  toggleFavorite,
  isCreatMode,
  isFavored,
  isFetchingDetails,
  controlsHeading,
  children,
  goBackLinkPath,
  goBackLinkText,
  cssRules,
  wrapperCssRules,
}) => (
  <StyledWrapper
    className={
      classNames(
        'client-details-controls',
        { 'client-details-controls--new-client': isCreatMode }
      )
    }
    cssRules={wrapperCssRules}
  >
    <Wrapper>
      {
        goBackLinkPath &&
        <div className="go-back-link__container">

          <span className="go-back-link__arrow">&lt;  </span>

          <NavLink
            to={goBackLinkPath}
            className="go-back-link"
          >
            {goBackLinkText}
          </NavLink>

        </div>
      }

      <StyledWrapper
        className="controls-wrapper"
        cssRules={cssRules}
      >
        <Title isActiveColor={isCreatMode}>
          {controlsHeading}
        </Title>

        {
          !isCreatMode && !isFetchingDetails && (
            <Fragment>

              {children}

              {
                !!toggleFavorite &&
                <StarIcon
                  onClick={toggleFavorite}
                  isFavored={isFavored}
                />
              }

            </Fragment>
          )
        }
      </StyledWrapper>

    </Wrapper>
  </StyledWrapper>
);


DetailsControls.propTypes = {
  children: PropTypes.any,
  isFavored: PropTypes.bool,
  cssRules: PropTypes.string,
  isCreatMode: PropTypes.bool,
  toggleFavorite: PropTypes.func,
  goBackLinkPath: PropTypes.string,
  goBackLinkText: PropTypes.string,
  wrapperCssRules: PropTypes.string,
  controlsHeading: PropTypes.string,
  isFetchingDetails: PropTypes.bool,
};

DetailsControls.defaultProps = {
  cssRules: '',
  children: null,
  isFavored: false,
  goBackLinkPath: '',
  goBackLinkText: '',
  isCreatMode: false,
  wrapperCssRules: '',
  toggleFavorite: null,
  controlsHeading: 'Client',
  isFetchingDetails: false,
};


export default DetailsControls;
export const ExpandSection = ExpandButton;
