import React from 'react';

import PropTypes, { oneOfType } from 'prop-types';

import DropdownIcon from 'assets/icons/sort.svg';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import { Transition } from 'react-spring/renderprops';
import styled, { css } from 'styled-components';

import './styles.scss';

const StyledButton = styled.button`
  padding: 0;
  text-align: left;
  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;

const OrderButton = ({
  data,
  onClick,
  cssRules,
  isActive,
  inArrears,
  isReversed,
  orderRules,
  withTransition,
  isButtonDisabled,
  icon,
}) => {
  const getIsActive = () => {
    const { orderBy, isReversed: isReversedState } = orderRules;
    const isActiveState = orderBy && orderBy.toLowerCase() === data.toLowerCase();

    return {
      isCurrentButtonActive: isActiveState,
      isCurrentButtonReversed: isActiveState && isReversedState,
    };
  };
  const { isCurrentButtonActive, isCurrentButtonReversed } = orderRules ?
    getIsActive() :
    { isCurrentButtonActive: isActive, isCurrentButtonReversed: isReversed };

  return (
    <StyledButton
      className={
        classNames(
          'order-button',
          { 'order-button--active': isCurrentButtonActive },
          { 'order-button--reversed': isCurrentButtonReversed },
          { 'order-button--arrears': inArrears },
          { 'order-button--disabled': isButtonDisabled },
        )
      }
      type="button"
      onClick={onClick}
      cssRules={cssRules}
    >
      {
        withTransition ? (
          <Transition
            items={data}
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ position: 'absolute', opacity: 0 }}
          >
            {(item) => (props) => <div style={props}>{item}</div>}
          </Transition>
        ) :
          <div>{data}</div>
      }

      {!isButtonDisabled && (
        <SVG
          className={
            classNames(
              'order-button__icon',
              { 'order-button__icon--active': isCurrentButtonActive },
              { 'order-button__icon--reversed': isCurrentButtonReversed },
            )
          }
          src={icon}
        />
      )}

    </StyledButton>
  );
};

OrderButton.propTypes = {
  withTransition: PropTypes.bool,
  orderRules: PropTypes.shape({}),
  inArrears: PropTypes.bool,
  data: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isReversed: PropTypes.bool,
  isActive: PropTypes.bool,
  isButtonDisabled: PropTypes.bool,
  cssRules: PropTypes.string,
  icon: oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

OrderButton.defaultProps = {
  withTransition: false, // TODO find where needed transition
  orderRules: null,
  onClick: null,
  isReversed: false,
  isActive: false,
  inArrears: false,
  isButtonDisabled: false,
  cssRules: '',
  icon: DropdownIcon,
};

export default OrderButton;
