import React from 'react';

import PropTypes from 'prop-types';

import TableHeading from 'components/form-controls/form-table-heading';
import { isEmpty } from 'lodash';

import FormUnit from './form-unit';

import { StyledTemplate } from './styled-components';

const FormHeaders = ({
  form,
  isVisible,
  orderRules,
  filterRules,
  headersConfig: {
    data,
    stylesTemplate,
    isHidden: isHeaderHidden,
  },
}) => {
  const { cssRules, ...otherProps } = stylesTemplate || {};

  if (isHeaderHidden) {
    return null;
  }

  return (
    <StyledTemplate
      stylesTemplate={otherProps}
      cssRules={cssRules}
    >
      {
        data.map((headerUnit, headersConfigIndex) => {
          const { value, isHidden, type } = headerUnit;
          if (isHidden) {
            return null;
          }

          if (!isEmpty(form) && type) {
            return (
              <FormUnit
                form={form}
                unit={{
                  ...headerUnit,
                  orderRules,
                  filterRules,
                }}
                isVisible={isVisible}
                key={headersConfigIndex} // eslint-disable-line react/no-array-index-key
                index={headersConfigIndex}
              />
            );
          }

          return (
            <TableHeading
              key={headersConfigIndex} // eslint-disable-line react/no-array-index-key
              fieldData={value}
            />
          );
        })
      }
    </StyledTemplate>
  );
};

FormHeaders.propTypes = {
  headersConfig: PropTypes.shape({
    data: PropTypes.array,
    isHidden: PropTypes.bool,
  }),
  orderRules: PropTypes.shape({
    isReversed: PropTypes.bool,
  }),
  form: PropTypes.shape({}),
  isVisible: PropTypes.bool,
  filterRules: PropTypes.shape({}),
};

FormHeaders.defaultProps = {
  headersConfig: {
    data: [],
  },
  orderRules: {},
  isVisible: true,
  form: {},
  filterRules: {},
};

export default FormHeaders;
