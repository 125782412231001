import React from 'react';


const TooltipContent = () => (
  <p>MSA#&nbsp;should&nbsp;be&nbsp;entered&nbsp;in&nbsp;the
    following&nbsp;format:&nbsp;MSA-MMYYYY-NN01 where&nbsp;MM&nbsp;-&nbsp;month&apos;s number,
    YYYY&nbsp;-&nbsp;year,&nbsp;NN&nbsp;-&nbsp;first&nbsp;two&nbsp;capital&nbsp;letters
    of&nbsp;Client&nbsp;name,&nbsp;01&nbsp;-&nbsp;serial&nbsp;number.
  </p>
);

export default TooltipContent();
