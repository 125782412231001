import React, { useEffect, Children, cloneElement, memo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import classnames from 'classnames';

import { get } from 'lodash';

import styles from './style.module.scss';

const Filter = memo(({ filterItem, filterState, className }) => {
  const { render, ...restProps } = filterItem;
  const isActiveFilter = get(filterState, 'isActive', false);

  return (
    <div
      data-testid={filterItem.name}
      className={classnames(styles.filter, {
        [styles.active]: isActiveFilter,
      }, className)}
    >
      {render({ ...restProps, isActiveFilter })}
    </div>
  );
});

const Filters = ({ children, className, clearFiltersAction, withEmptyField, selector }) => {
  const filtersState = useSelector(selector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearFiltersAction());
  }, []);

  return (
    <div data-id="Filters" className={classnames(styles.container, className)}>
      {
        Children.map(children, (child) => cloneElement(
          child,
          {
            ...child.props,
            filterState: filtersState[child.key],
          }
        ))
      }
      {withEmptyField && (
        <div />
      )}
    </div>
  );
};

Filters.Filter = Filter;

Filters.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  clearFiltersAction: PropTypes.func.isRequired,
  selector: PropTypes.func.isRequired,
  withEmptyField: PropTypes.bool,
};

Filters.defaultProps = {
  className: '',
  withEmptyField: false,
};

Filter.propTypes = {
  filterItem: PropTypes.shape({
    name: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
  }).isRequired,
  filterState: PropTypes.shape({}),
  className: PropTypes.string,
};

Filter.defaultProps = {
  filterState: {
    isActive: false,
    value: null,
  },
  className: '',
};

export default Filters;
