import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import ToastContainer from 'components/toast';
import {
  confExchangeRatesManagementAccess,
  confCommissionRatesAccess,
  confRateCardManagementAccess,
  confEmailTemplateManagementAccess,
  confEmailNotificationsAccess,
  confNoneWorkingDaysManagementAccess,
  confRateChangeNotificationsAccess,
  confHiqoContractsManagementAccess,
  confCategoryManagement,
  confDiscountSurchargeManagementAccess, confUserManagementAccess,
} from 'core/auth/guaranteedAccessRoles';
import { selectDevCenters } from 'core/auth/selectors';
import { commonActions } from 'core/common/actions';
import reducer from 'core/configurations/reducer';
import saga from 'core/configurations/sagas';
import {
  selectIsFetching,
  selectIsFormSubmitted,
  selectEntityName,
  selectOrderRules,
} from 'core/configurations/selectors';
import {
  selectUploadingProgress,
  selectIsFileUploaded,
} from 'core/files/selectors';
import { useCheckPermissions } from 'hooks';
import { CategoryManagement } from 'layouts/category-management';
import CommissionRates from 'layouts/commission-rates';
import DiscountSurchargeManagement from 'layouts/discount-surcharge-management';
import EmailTemplateManagement from 'layouts/email-template-management';
import EmailTemplateNotifications from 'layouts/email-template-notifications';
import ExchangeRatesManagement from 'layouts/exchange-rates-management';
import HiqoContractsManagement from 'layouts/hiqo-contracts';
import NoneWorkingDaysManagement from 'layouts/none-working-days-management';
import RateCardManagement from 'layouts/rate-card-management';
import RateChangeNotifications from 'layouts/rate-change-notifications';
import { UserManagement } from 'layouts/user-management';
import compose from 'lodash/fp/compose';

import { Switch, Route, Redirect } from 'react-router';
import { createStructuredSelector } from 'reselect';

import { injectReducer, injectSaga } from 'utils/helpers/injectors';

const Configurations = (props) => {
  const {
    entityName,
    setEntityName,
    ...restProps
  } = props;
  const { isAuthorizedToRoute } = useCheckPermissions(restProps);

  useEffect(() => {
    setEntityName(entityName);
  }, [entityName]);

  return (
    <>
      <ToastContainer containerId={entityName} />
      <Switch>
        <Route
          path={`/${confCommissionRatesAccess.route}`}
          render={() => isAuthorizedToRoute ? <CommissionRates {...props} /> : null}
        />

        <Route
          path={`/${confExchangeRatesManagementAccess.route}`}
          render={() => isAuthorizedToRoute ? <ExchangeRatesManagement {...props} /> : null}
        />

        <Route
          path={`/${confRateCardManagementAccess.route}`}
          render={() => isAuthorizedToRoute ? <RateCardManagement {...props} /> : null}
        />

        <Route
          path={`/${confEmailTemplateManagementAccess.route}`}
          render={() => isAuthorizedToRoute ? <EmailTemplateManagement {...props} /> : null}
        />

        <Route
          path={`/${confEmailNotificationsAccess.route}`}
          render={() => isAuthorizedToRoute ? (
            <EmailTemplateNotifications {...props} />
          ) : null}
        />

        <Route
          path={`/${confRateChangeNotificationsAccess.route}`}
          render={() => isAuthorizedToRoute ? <RateChangeNotifications {...props} /> : null}
        />

        <Route
          path={`/${confNoneWorkingDaysManagementAccess.route}`}
          render={() => isAuthorizedToRoute ? (
            <NoneWorkingDaysManagement {...props} />
          ) : null}
        />

        <Route
          path={`/${confHiqoContractsManagementAccess.route}`}
          render={() => isAuthorizedToRoute ? <HiqoContractsManagement {...props} /> : null}
        />

        <Route
          path={`/${confUserManagementAccess.route}`}
          render={() => isAuthorizedToRoute ? <UserManagement {...props} /> : null}
        />

        <Route
          path={[
            `/${confCategoryManagement.route}`,
            `/${confCategoryManagement.childrenRoutes.client}/:clientId`,
          ]}
          render={() => isAuthorizedToRoute ? <CategoryManagement {...props} /> : null}
        />
        <Route
          path={[`/${confDiscountSurchargeManagementAccess.route}`]}
          render={() => isAuthorizedToRoute ? (
            <DiscountSurchargeManagement {...props} />
          ) : null}
        />

        <Redirect to="/home" />
      </Switch>
    </>
  );
};

Configurations.propTypes = {
  entityName: PropTypes.string.isRequired,
  userGroup: PropTypes.string,
  setEntityName: PropTypes.func.isRequired,
  guaranteedAccessRoles: PropTypes.arrayOf(PropTypes.string),
  guaranteedAccessRolesForSubRoutes: PropTypes.shape(),
};

Configurations.defaultProps = {
  userGroup: '',
  guaranteedAccessRoles: [],
  guaranteedAccessRolesForSubRoutes: null,
};

const mapStateToProps = createStructuredSelector({
  deliveryCenters: selectDevCenters,
  entityName: selectEntityName,
  isFetching: selectIsFetching,
  isFormSubmitted: selectIsFormSubmitted,
  orderRules: selectOrderRules,
  uploadingProgress: selectUploadingProgress,
  isFileUploaded: selectIsFileUploaded,
});

const mapDispatchToProps = {
  setEntityName: commonActions.setEntityName,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'configurations', reducer });
const withSaga = injectSaga({ key: 'configurationsSagas', saga });

export default compose(withReducer, withSaga, withConnect)(Configurations);
