import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import Loader from 'components/loader';

import styles from './styles.module.scss';

const Container = ({
  children,
  className,
  isFetching,
  dataId,
}) => {
  if (isFetching) {
    return (
      <div
        data-id="Loader"
        className={styles.loader}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div data-id={dataId} className={classNames(styles.container, className)}>
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isFetching: PropTypes.bool,
  dataId: PropTypes.string,
};

Container.defaultProps = {
  className: '',
  isFetching: false,
  dataId: 'Container',
};

export default Container;
