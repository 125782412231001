import { waitForAuthorization } from 'core/auth/sagas';
import { get } from 'lodash';
import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';

import {
  executeQuery,
  parseError,
} from 'utils/request';

import {
  userActionsTypes,
  userActions,
} from './actions';
import { queryConfig } from './queries';
import {
  selectErrors,
  selectEntityName,
} from './selectors';

function* getUserDataWorker() {
  try {
    const userId = yield select(({ auth }) => auth.userId);
    const query = queryConfig.getUserData;
    const options = {
      query,
      variables: {
        userId,
      },
    };
    const {
      userData,
    } = yield call(executeQuery, options);

    return yield put(userActions.getUserDataSuccess({
      userData,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getUserDataError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getUserDataError = yield call(parseError, error, options);

    return yield put(userActions.getUserDataFail({
      error: {
        getUserDataError,
      },
    }));
  }
}

function* getUserDataWatcher() {
  yield takeLatest(userActionsTypes.GET_USER_DATA, waitForAuthorization(getUserDataWorker));
}

export default [
  getUserDataWatcher,
];
