import React from 'react';

import { Controller } from 'react-hook-form';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import styled, { css } from 'styled-components';

import './styles.scss';

const StyledRoot = styled.div`
  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;

const Switcher = ({
  placeholder,
  isLocked,
  cssRules,
  onClick,
  control,
  index,
  isDisabled,
}) => (
  <Controller
    control={control}
    type="text"
    name={`categories.${index}.isActive`}
    render={
      ({ field: { value, onChange, ...fieldData } }) => {
        const handleClick = (e) => {
          e.stopPropagation();
          onClick();
        };

        return (
          <StyledRoot
            className={
              classNames(
                'client-details-form__switcher',
                'form-switcher',
                { 'form-switcher--locked': isLocked }
              )}
            cssRules={cssRules}
          >

            <input
              {...fieldData}
              id={`categories.${index}.isActive`}
              className="form-switcher__input"
              type="checkbox"
              defaultChecked={value}
            />

            <label
              htmlFor={`categories.${index}.isActive`}
              className="form-switcher__label"
              role="presentation"
              onKeyUp={handleClick}
              onClick={handleClick}
            >

              <span className="form-switcher__label-title">{placeholder}</span>

              <span className="form-switcher__icon-wrapper">

                <span
                  className={
                    classNames(
                      'form-switcher__icon-track',
                      {
                        'form-switcher__icon-track--checked': value,
                        'form-switcher__icon-track--disabled': isDisabled,
                      },
                    )
                  }
                >

                  <span
                    className={
                      classNames(
                        'form-switcher__icon-knob',
                        {
                          'form-switcher__icon-knob--checked': value,
                          'form-switcher__icon-knob--disabled': isDisabled,
                        }
                      )
                    }
                  />

                </span>

              </span>

              {
                placeholder &&
                <span
                  className={
                    classNames(
                      'form-switcher__status-title',
                      { 'form-switcher__status-title--checked': value }
                    )
                  }
                >
                  {
                    value ?
                      'Yes' :
                      'No'
                  }
                </span>
              }

            </label>

          </StyledRoot>

        );
      }
    }
  />
);


Switcher.propTypes = {
  isLocked: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  cssRules: PropTypes.string,
  onClick: PropTypes.func,
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
};

Switcher.defaultProps = {
  isLocked: false,
  placeholder: '',
  cssRules: '',
  isDisabled: false,
  onClick: () => null,
};


export default Switcher;
