import Calendar from 'components/calendar';
import Dropdown from 'components/dropdown';
import GroupController from 'components/expansion-panel/group-control-button';
import RangeFilter from 'components/filter-range';
import Select from 'components/filter-select';
import Switcher from 'components/form-controls/form-switcher';
import Search from 'components/search';
import ActionButton from 'elements/action-button';
import Checkbox from 'elements/checkbox';
import CustomLink from 'elements/link';
import OrderButton from 'elements/order-button';
import Text from 'elements/plain-text';
import StarIcon from 'elements/star';
import StartsRating from 'elements/stars-rating';

export default {
  groupController: GroupController,
  orderButton: OrderButton,
  action: ActionButton,
  rating: StartsRating,
  dropdown: Dropdown,
  switcher: Switcher,
  favorite: StarIcon,
  checkbox: Checkbox,
  range: RangeFilter,
  toggle: StarIcon,
  link: CustomLink,
  select: Select,
  search: Search,
  date: Calendar,
  text: Text,
};
