import { ALL_ITEMS_SELECTED } from 'components/form-controls/constants';
import { get } from 'lodash';

export const onInputValueChange = ({
  validationRules,
  calculatedValue,
  fieldData,
  setHasChanges,
  inputValue,
}) => {
  const {
    isRates,
  } = validationRules;
  if (isRates) {
    const {
      currentRateCardRate,
      clientRateCardRate,
      ratecardId,
    } = fieldData;
    const currentRatecardId = calculatedValue ? calculatedValue.currentRatecardId : null;

    if (currentRatecardId && currentRatecardId !== ratecardId) { // if it has parent field, check diff between the calculated  value and current form value
      const {
        value,
      } = calculatedValue;

      const isRatesChangedManually = !!value && Number(value) !== Number(inputValue);

      setHasChanges(isRatesChangedManually);
    } else { // if it has no parent field, check diff between initial value and current form value, and
      const isCurrentRatesChanged = !!currentRateCardRate && Number(currentRateCardRate) !== Number(inputValue);
      const isClientRatesChanged = !!clientRateCardRate && Number(clientRateCardRate) !== Number(inputValue);
      setHasChanges(isClientRatesChanged || isCurrentRatesChanged);
    }
  }
};

export const updateInput = ({
  currentValue,
  validationRules,
  isLocked,
  inputValue,
  setInputValue,
  onChange,
  calculatedValue,
  setIsManuallyUpdated,
}) => {
  const {
    maxLength,
  } = validationRules;

  if (!isLocked) {
    if ((maxLength &&
        currentValue.length > maxLength &&
        currentValue.length >= inputValue.length)) {
      return null;
    }

    if (calculatedValue) {
      const {
        isManualUpdatesSaves,
      } = calculatedValue.props || {};
      setIsManuallyUpdated(isManualUpdatesSaves);
    }

    if (!currentValue.length && calculatedValue) {
      const {
        value,
        isNullable,
      } = calculatedValue;

      if (!isNullable) {
        setIsManuallyUpdated(false);

        setInputValue(value);

        return onChange(value);
      }
    }

    setInputValue(currentValue);

    return onChange(currentValue);
  }

  return null;
};

export const getSelectItemsMap = (items) => {
  const itemsMap = {
    0: ALL_ITEMS_SELECTED[0],
  };

  items.forEach((item) => {
    const { value } = item;

    itemsMap[value] = item;
  });

  return itemsMap;
};

export const emptySelectOption = {
  value: '',
  label: '',
};

export const findSelectedOption = (formValue, { itemsMap, items }, asyncOptions) => {
  if (asyncOptions && asyncOptions.length > 0) {
    return asyncOptions.find(({ value }) => `${formValue}` === `${value}`);
  }

  return itemsMap ?
    itemsMap[formValue] :
    items.find(({ value }) => `${formValue}` === `${value}`);
};

export const isSomeParentTouched = (touched, parentFieldName) => parentFieldName.some((name) => get(touched, name));
