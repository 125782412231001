import { fullAccess } from 'core/auth/guaranteedAccessRoles';

export default [
  {
    name: 'userSettings',
    route: 'user-settings',
    guaranteedAccessRoles: fullAccess,
  },
  {
    name: 'notFound',
    route: 'not-found',
    guaranteedAccessRoles: fullAccess,
  },
];
