import React, { useEffect, useState } from 'react';

import { scrollToTop, SCROLL } from 'utils/helpers/scrolling';

import './styles.scss';


const GoTop = () => {
  const [isButtonHidden, toggleButtonStatus] = useState(true);

  const handleScroll = () => {
    const { pageYOffset } = window;

    if (pageYOffset > SCROLL.TOP_POSITION && isButtonHidden) {
      toggleButtonStatus(false);
    }

    if (pageYOffset === SCROLL.TOP_POSITION) {
      toggleButtonStatus(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    !isButtonHidden &&
    <button
      className="go-top-button"
      onClick={scrollToTop}
    >
      <svg width="16" height="12" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.40625 0.578125L6 5.17188L10.5938 0.578125L12 1.98438L6 7.98438L0 1.98438L1.40625 0.578125Z" fill="#ffffff" />
      </svg>

    </button>
  );
};

export default GoTop;
