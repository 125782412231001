import {
  colorWhite,
  colorPrimary,
  shadowButtons,
  backgroundButtons,
} from 'assets/styles/variables';

export const filterSelectCssRules = `
&& {
  font-size: 1.4rem;
  width: unset;
  margin-right: 3.2rem;
  color: ${colorPrimary};
  .filter-select {
    width: auto;
    height: auto;
    &__multi-value {
      margin-top: 0;
    }
    &__multi-value__label {
      color: ${colorPrimary};
    }
    &__placeholder {
      padding-left: 0;
      color: ${colorPrimary};
    }
    &__control--is-focused {
      outline: none !important;
    }
    &__dropdown-indicator {
      path {
        fill: ${colorPrimary};
      }
    }
  }
}
`;

export const controlsCssRules = `
.controls__main-controls {
  display: flex;
  width: 100%;
  .title {
    margin-right: auto;
  }
}
.controls__additional-controls {
  display: flex;
  align-items: center;
  .select {
    margin-left: 3rem;
  }
}
.controls__clear-filter {
  display: none;
}
`;

export const yearPickerCssRules = `
  && {
    border: none;
    padding-top: unset;
  }
    & path {
    fill: ${colorPrimary};
  }
  .calendar__buttons {
    margin-right: 1.6rem;
    .calendar-date-picker__header-control {
      margin-left: 0;
      margin-right: 0.8rem;
    }
  }
  .calendar__toggle-button {
    padding: 0.3rem 0.8rem 0 0.8rem;
  }
`;

export const calendarItemCssRules = `
  padding-top: 0.3rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.7rem;
  color:  ${colorPrimary};
`;

export const rateChangeNotificationsWrapperStyles = `
  display: flex;
  flex-direction: column;
  grid-column-gap: 4rem;
  min-height: 60vh;
  .table__content-wrapper {
    padding-bottom:0;
  }
`;

export const addButtonStyles = `&& {
  display: block;
  margin-left: 2.4rem;
  padding: .8rem 1.7rem;
  border: 1px solid ${colorPrimary};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  color: ${colorPrimary};
  background-color: initial;
  outline: none;
  cursor: pointer;

  &:active,
  &:hover {
    border-color: ${colorPrimary};
    color: ${colorWhite};
    background: ${backgroundButtons};
    box-shadow: ${shadowButtons};
  };
`;
