import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './styles.scss';

const DateInputs = ({
  day,
  year,
  month,
  isLocked,
  useOnlyYear,
  onDayChange,
  onDayKeyDown,
  onYearChange,
  useOnlyMonth,
  onMonthChange,
  onYearKeyDown,
  onMonthKeyDown,
  registerInputs,
}) => {
  const isEmpty = !day && !year && !month;
  const dayPickerRef = useRef(null);
  const monthPickerRef = useRef(null);
  const yearPickerRef = useRef(null);

  useEffect(() => {
    registerInputs({
      dayPickerRef,
      yearPickerRef,
      monthPickerRef,
    });
  }, []);

  const tabIndex = isLocked ? -1 : 0;

  return (
    <div className={classNames('date-inputs', { 'date-inputs__empty': isEmpty })}>
      {
        !useOnlyYear && (
          <input
            className="date-inputs__item date-inputs__date-input--month"
            placeholder=".."
            autoComplete="off"
            value={month}
            onChange={onMonthChange}
            onKeyDown={onMonthKeyDown}
            ref={monthPickerRef}
            tabIndex={tabIndex}
          />
        )
      }

      {
        !(useOnlyMonth || useOnlyYear) && (
          <>
            <span className="date-inputs__item-divider">/</span>
            <input
              className="date-inputs__item date-inputs__item--day"
              placeholder=".."
              autoComplete="off"
              value={day}
              onChange={onDayChange}
              onKeyDown={onDayKeyDown}
              ref={dayPickerRef}
              tabIndex={tabIndex}
            />
          </>
        )
      }
      {
        !useOnlyYear &&
        <span className="date-inputs__item-divider">/</span>
      }
      <input
        className="date-inputs__item date-inputs__item--year"
        placeholder="...."
        autoComplete="off"
        value={year}
        onChange={onYearChange}
        onKeyDown={onYearKeyDown}
        ref={yearPickerRef}
        tabIndex={tabIndex}
      />
    </div>
  );
};

DateInputs.propTypes = {
  day: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  year: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  month: PropTypes.string,
  useOnlyYear: PropTypes.bool,
  useOnlyMonth: PropTypes.bool,
  isLocked: PropTypes.bool.isRequired,
  onDayChange: PropTypes.func.isRequired,
  onYearChange: PropTypes.func.isRequired,
  onDayKeyDown: PropTypes.func.isRequired,
  onMonthChange: PropTypes.func.isRequired,
  onYearKeyDown: PropTypes.func.isRequired,
  onMonthKeyDown: PropTypes.func.isRequired,
  registerInputs: PropTypes.func.isRequired,
};

DateInputs.defaultProps = {
  day: '',
  year: '',
  month: '',
  useOnlyYear: false,
  useOnlyMonth: false,
};

export default DateInputs;
