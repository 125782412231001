import { useEffect } from 'react';
const AuthWrapper = ({ children }) => {
  useEffect(() => {
    document.body.classList.add('unauthorized');
    return () => {
      document.body.classList.remove('unauthorized');
    };
  }, []);

  return children;
};

export default AuthWrapper;
