import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { children as childrenType } from 'utils/validation/prop-types';

import './styles.scss';

const propTypes = {
  className: PropTypes.string,
  children: childrenType.isRequired,
};

const defaultProps = {
  className: '',
};

const WrapperNg = ({
  className,
  children,
}) => (
  <div className={classNames(className, 'wrapper-ng')}>
    {children}
  </div>
);

WrapperNg.propTypes = propTypes;
WrapperNg.defaultProps = defaultProps;

export default WrapperNg;
