import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import WarninngIcon from 'assets/img/icon-warning.svg';
import {
  colorSecondaryGrayLight,
  colorSecondaryGrayLight04,
  colorSecondaryGrayLight015,
  colorSecondaryText,
  colorPrimary,
} from 'assets/styles/variables';
import { isEqual, get } from 'lodash';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';


import './styles.scss';

const StyledViewbox = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  max-height: 4.3rem;
  border-bottom: 1px solid ${colorSecondaryGrayLight};
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: ${colorSecondaryText};
  height: 100%;

  ${({ withIcon }) => withIcon && css`
    font-size: 1.4rem;
  `}

  ${({ isLocked }) => isLocked && css`
    padding: 0 0.8rem;
    border-bottom: 1px solid ${colorSecondaryGrayLight04};
    background-color: ${colorSecondaryGrayLight015};
  `}

  ${({ hasDependency, calculatedValue, fieldData: { isDefault } }) => {
    const isDefaultStylesApplied = (hasDependency && calculatedValue.isDefault) || isDefault;
    return isDefaultStylesApplied && css`
      color: ${colorSecondaryGrayLight};

      & path {
        fill: ${colorSecondaryGrayLight};
      }
    `;
  }}

  ${({ hasDependency, calculatedValue, fieldData: { isActive } }) => {
    const isActiveStylesApplied = (hasDependency && calculatedValue.isActive) || isActive;
    return isActiveStylesApplied && css`
      color: ${colorPrimary};
    `;
  }}

  ${({ cssRules }) => css`${cssRules}`}
`;

// TODO: consider reuse CustomLink component here
// eslint-disable-next-line react/prop-types
const renderLink = ({ path, value, href, withBlank, hasDependency, calculatedValue }) => !href ?
  (
    <Link
      className="viewbox__viewbox-link"
      to={hasDependency ? calculatedValue.path : path}
    >
      {hasDependency ? calculatedValue.value : value}
    </Link>
  ) : (
    <a
      href={hasDependency ? calculatedValue.href : href}
      target={withBlank ? '_blank' : '_self'}
      rel={withBlank ? 'noopener noreferrer' : undefined}
      className="viewbox__viewbox-link"
    >
      {hasDependency ? calculatedValue.value : value}
    </a>
  );


const Viewbox = ({
  fieldData,
  isLocked,
  cssRules,
  className,
  formValues,
  parentField,
  parentFieldsData,
  touched,
}) => {
  const { value, path, withIcon, href, withBlank, title } = fieldData;
  const hasDependency = !!parentField;
  const [calculatedValue, setCalculatedValue] = useState(fieldData);

  useEffect(() => {
    if (parentField) {
      const isSomeParentTouched = parentField.name.some((parentFieldName) => get(touched, parentFieldName));

      const { valueGetter, strictDependence } = parentField;

      if (isSomeParentTouched || strictDependence) {
        const updatedValue = valueGetter(formValues, fieldData);

        if (updatedValue && !isEqual(calculatedValue, updatedValue)) {
          setCalculatedValue(updatedValue);
        }
      }
    }
  }, parentFieldsData);

  return (
    (path || href) ?
      <StyledViewbox
        fieldData={fieldData}
        hasDependency={hasDependency}
        calculatedValue={calculatedValue}
        isLocked={isLocked}
        cssRules={cssRules}
        className={className}
        title={title}
      >
        {renderLink({ path, value, href, withBlank, hasDependency, calculatedValue })}
      </StyledViewbox> :
      <StyledViewbox
        fieldData={fieldData}
        hasDependency={hasDependency}
        calculatedValue={calculatedValue}
        isLocked={isLocked}
        cssRules={cssRules}
        className={className}
        title={title}
      >
        {
          withIcon &&
            <SVG
              className="viewbox__icon"
              src={WarninngIcon}
            />
        }

        {hasDependency ? calculatedValue.value : value}
      </StyledViewbox>
  );
};


Viewbox.propTypes = {
  touched: PropTypes.any,
  isLocked: PropTypes.bool,
  fieldData: PropTypes.shape({
    isActive: PropTypes.bool,
    isDefault: PropTypes.bool,
    path: PropTypes.string,
    title: PropTypes.string,
    withIcon: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  cssRules: PropTypes.string,
  className: PropTypes.string,
  formValues: PropTypes.any,
  parentField: PropTypes.shape({

  }),
  parentFieldsData: PropTypes.arrayOf(PropTypes.any),
};

Viewbox.defaultProps = {
  touched: null,
  parentField: null,
  parentFieldsData: null,
  formValues: '',
  isLocked: false,
  fieldData: {
    isActive: false,
    isDefault: false,
    path: '',
    withIcon: false,
    value: '',
    title: '',
  },
  cssRules: '',
  className: '',
};


export default Viewbox;
