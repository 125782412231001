import { updateWBRecordsOnDashboard } from 'core/auth/guaranteedAccessRoles';
import {
  selectUserGroup,
  selectUserData,
  selectDevCenters,
  selectDevCentersBySystemRoleDD,
} from 'core/auth/selectors';
import { dashboardDataModelGetter } from 'layouts/dashboard/model';
import { get } from 'lodash';
import { createSelector } from 'reselect';

import { getHasPermissions } from 'utils/auth';
import { parseDetails } from 'utils/helpers/models';

const selectDashboardData = (state) => state.dashboard.dashboardData;

const selectEmployeeData = createSelector(
  selectDashboardData,
  (dashboardData) => {
    const byName = {};
    const byId = {};
    const devstaffList = get(dashboardData, 'devstaffv2List', []);

    devstaffList.forEach(({
      name,
      fullname,
      devstaffId,
      ...rest
    }) => {
      byName[name] = {
        fullname,
        devstaffId,
      };

      byId[devstaffId] = {
        fullname,
        ...rest,
      };
    });

    return {
      byName,
      byId,
    };
  }
);

const selectFilteredDashboardData = createSelector(
  selectDashboardData,
  selectUserGroup,
  selectEmployeeData,
  (dashboardData, userGroup, employeeData) => {
    const {
      byId,
    } = employeeData;
    const hasPermissionsToUpdate = getHasPermissions(userGroup, updateWBRecordsOnDashboard);
    const taskOrders = get(dashboardData, 'closeToExpireEmployees', []);
    const ptoRequestRecords = get(dashboardData, 'dashboardPtoRequestRecords', [])
      .filter(({
        isApproved,
        isCancelled,
      }) => !isApproved && !isCancelled);
    const dDWorkBookRecords = [];
    const ceoWorkBookRecords = [];

    if (hasPermissionsToUpdate) {
      get(dashboardData, 'dashboardWorkBookRecords', [])
        .forEach((record) => {
          const {
            approvedDd,
            approvedCeo,
          } = record;

          if (approvedDd) {
            if (!approvedCeo) {
              ceoWorkBookRecords.push(record);
            }
          } else {
            dDWorkBookRecords.push(record);
          }
        });
    }

    return {
      ...dashboardData,
      ptoRequestRecords,
      dDWorkBookRecords,
      ceoWorkBookRecords,
      taskOrders,
      employeeDataById: byId,
    };
  }
);

export const selectEntityName = (state) => state.dashboard.entityName;

export const selectIsFetchingDashboardData = (state) => state.dashboard.isFetching;

export const selectErrors = (state) => state.dashboard.errors;

export const selectOrderRules = (state) => state.dashboard.orderRules;

export const selectAllDevCenters = (state) => state.dashboard.dashboardData.devcentersAll;

export const selectDashboardDataModel = createSelector(
  selectUserGroup,
  selectUserData,
  selectFilteredDashboardData,
  selectDevCenters,
  selectDevCentersBySystemRoleDD,
  selectAllDevCenters,
  (userGroup, userData, dashboardData, devcenters, devCentersBySystemRole, devcentersAll) => dashboardDataModelGetter(userGroup, userData, dashboardData, devcenters, devCentersBySystemRole, devcentersAll)
);

export const selectedDashboardData = createSelector(
  selectFilteredDashboardData,
  selectOrderRules,
  selectDashboardDataModel,
  (dashboardData, orderRules, dashboardDataModel) => parseDetails(dashboardData, dashboardDataModel, orderRules)
);
