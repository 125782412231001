/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import classNames from 'classnames';
import { Checkbox } from 'components/buttons';
import Select from 'components/selectNew';
import { TextField, InputField } from 'forms/components';
import { get } from 'lodash';

import { MESSAGE_PROCESSING_STATUS } from 'utils/constants';

import styles from './styles.module.scss';

const TEXTS = {
  SALUTATION: 'Salutation',
  EMAILS: 'Emails',
  RATE_CARD: 'Select Rate Card',
  EMAIL_STATUS: {
    YES: 'Yes',
    NO: 'No',
  },
  MESSAGE_PROCESSING_STATUS,
  PROCESSING: {
    manual: 'manual',
  },
};

export const useListColumns = ({
  billingAgentIds,
  rateCards,
  notificationTypes,
  isEditable,
}) => useMemo(() => [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'text',
    isSortable: true,
    sortType: 'alphabetical',
    Field: ({
      data,
      item,
      key,
    }) => (
      <TextField
        key={key}
        title={data}
        content={data}
        className={classNames(styles.textField, styles.name)}
        link={`/clients/${item.clientId}/details`}
        withLink
      />
    ),
  },
  {
    Header: 'Country',
    accessor: 'country',
    type: 'text',
    isSortable: true,
    sortType: 'alphabetical',
    Field: ({
      data,
      key,
    }) => (
      <TextField
        key={key}
        content={data}
        className={classNames(styles.textField, styles.country)}
      />
    ),
  },
  {
    Header: 'Billing Agent',
    accessor: 'billingAgent',
    sortType: 'alphabetical',
    type: 'text',
    isSortable: true,
    Field: ({ data, key }) => {
      const billingAgent = billingAgentIds[data];

      return (
        <TextField
          key={key}
          content={billingAgent ? billingAgent.label : ''}
          className={classNames(styles.textField, styles.billingAgent)}
        />
      );
    },
  },
  {
    Header: 'Rate Card',
    accessor: 'rateCard',
    type: 'form',
    sortType: 'alphabetical',
    isSortable: true,
    Field: ({
      field,
      fieldState,
      item,
      isDisabled,
      menuPlacement,
    }) => {
      const items = rateCards[item.billingAgent] || [];
      const isEmptyItems = items.length === 0;
      const selected = items.find((rateCard) => rateCard.value === field.value);
      const fieldData = useMemo(() => ({
        selected,
        items,
      }), [field, selected, items]);
      const isLocked = isDisabled || isEmptyItems;
      const title = selected ? selected.label : '';

      return (
        <div
          data-id="selectFieldWrapper"
          className={classNames(styles.rateCard, {
            [styles.viewOnly]: !isEditable,
          })}
          data-disabled={isLocked}
        >
          <Select
            title={title}
            name={field.name}
            fieldData={fieldData}
            onChange={(selectedData) => {
              field.onChange(selectedData);
            }}
            error={get(fieldState, 'error.message', '')}
            withError={fieldState.invalid}
            {...(!selected) && {
              placeholder: TEXTS.RATE_CARD,
            }}
            isLocked={isLocked}
            menuPlacement={menuPlacement}
          />
        </div>
      );
    },
  },
  {
    Header: 'Notification',
    accessor: 'notification',
    type: 'form',
    sortType: 'alphabetical',
    isSortable: true,
    Field: ({
      field,
      fieldState,
      item,
      isDisabled,
      menuPlacement,
    }) => {
      const selected = notificationTypes[item.notification];
      const fieldData = {
        selected,
        items: Object.values(notificationTypes),
      };

      return (
        <div
          data-id="selectFieldWrapper"
          className={classNames(styles.notification, {
            [styles.viewOnly]: !isEditable,
          })}
          data-disabled={isDisabled}
        >
          <Select
            name={field.name}
            fieldData={fieldData}
            onChange={(selectedData) => {
              field.onChange(selectedData);
            }}
            error={get(fieldState, 'error.message', '')}
            withError={fieldState.invalid}
            isLocked={isDisabled}
            title={selected ? selected.label : ''}
            menuPlacement={menuPlacement}
          />
        </div>
      );
    },
  },
  {
    Header: 'Salutation',
    accessor: 'salutation',
    type: 'form',
    isSortable: true,
    sortType: 'alphabetical',
    Field: ({
      field,
      fieldState,
      isDisabled,
    }) => (
      <InputField
        title={field.value}
        maxLength={100}
        placeholder={TEXTS.SALUTATION}
        className={classNames(
          styles.salutation,
          {
            [styles.inputError]: get(fieldState, 'error'),
            [styles.viewOnly]: !isEditable,
          }
        )}
        isLocked={!get(fieldState, 'error') && isDisabled}
        {...field}
      />
    ),
  },
  {
    Header: 'Emails',
    accessor: 'email',
    type: 'form',
    isSortable: true,
    sortType: 'alphabetical',
    Field: ({
      field,
      fieldState,
      isDisabled,
    }) => (
      <InputField
        title={field.value}
        maxLength={500}
        placeholder={TEXTS.EMAILS}
        className={classNames(styles.email, {
          [styles.inputError]: get(fieldState, 'error'),
          [styles.viewOnly]: !isEditable,
        })}
        isLocked={!get(fieldState, 'error') && isDisabled}
        {...field}
        onChange={(event) => {
          const value = event.target.value;

          field.onChange(value.trimStart());
        }}
      />
    ),
  },
  {
    Header: 'Proc.',
    accessor: 'processed',
    type: 'form',
    isSortable: true,
    sortType: 'byProcessed',
    Field: ({
      field,
      fieldState,
      notificationType,
    }) => {
      const isDisabled =
        notificationTypes[notificationType].label !== TEXTS.PROCESSING.manual;

      return (
        <div
          data-id="Field Wrapper"
          className={classNames(styles.processed, {
            [styles.viewOnly]: !isEditable,
          })}
        >
          <Checkbox
            name={field.name}
            isActive={field.value}
            onClick={() => {
              field.onChange(!field.value);
            }}
            error={get(fieldState, 'error')}
            visibleIndication={false}
            disabled={isDisabled}
          />
        </div>
      );
    },
  },
  {
    Header: 'Emailed',
    accessor: 'emailed',
    type: 'text',
    isSortable: true,
    sortType: 'alphabetical',
    Field: ({
      data,
      key,
    }) => (
      <TextField
        key={key}
        className={classNames(styles.textField, styles.emailed)}
        content={data ? TEXTS.EMAIL_STATUS.YES : TEXTS.EMAIL_STATUS.NO}
      />
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'text',
    isSortable: true,
    sortType: 'alphabetical',
    Field: ({
      data,
      key,
    }) => (
      <TextField
        key={key}
        content={TEXTS.MESSAGE_PROCESSING_STATUS[data] || 'N/A'}
        className={
          classNames(styles.textField, styles.status)
        }
      />
    ),
  },
], [billingAgentIds, rateCards]);
