import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { get, isEqual, toNumber } from 'lodash';

import GoTop from 'elements/go-top-button';
import Calendar from 'components/calendar';
import Checkbox from 'elements/checkbox';
import ToastContainer from 'components/toast';
import Controls from 'components/details-controls';
import LayoutBodyContent from 'layouts/layout-body';
import FilterSelect from 'components/filter-select';
import StyledWrapper from 'elements/styled-wrapper';
import ScreenPlaceholder from 'elements/screen-placeholder';
import ActionButton from 'elements/action-button';

import { deliveryReviewsReportAccess } from 'core/auth/guaranteedAccessRoles';
import { STORE_KEYS } from 'core/employees-reviews/constants';
import { updateState } from 'utils/helpers/history';
import { autoScrollToTop } from 'utils/react';


const EmployeesReviewsReport = ({
  location,
  filters,
  isFetching,
  employeeId,
  entityName,
  orderRules,
  changeOrder,
  changeFilter,
  getPdfReviews,
  isFilterActive,
  selectEmployee,
  selectedEmployee,
  resetReviewReport,
  reviewsReportData,
  changeCurrentModal,
  updateColumnsState,
  employeesListForReport,
  reviewsReportsCommonData,
  isFetchingCommonReviewsData,
  title: controlsHeading,
}) => {
  autoScrollToTop(location);

  const {
    lastReviewDate,
    firstReviewDate,
    lastEvaluationDate,
  } = reviewsReportsCommonData;
  const [selectedStaff, updateSelectedStaff] = useState();
  const {
    body,
    controls,
    controlsCssRules,
    checkboxCssRules,
    clearButtonCssRules,
    filterOptionsStyles,
    filterSelectCssRules,
    toMonthPickerCssRules,
    filtersWrapperCssRules,
    filters: selectFilters,
    fromMonthPickerCssRules,
    controlsWrapperCssRules,
  } = reviewsReportData;
  const dateFrom = get(filters, `${STORE_KEYS.DATE_FROM}`, null);
  const dateTo = get(filters, `${STORE_KEYS.DATE_TO}`, null);
  const isActiveFilterByEvaluationDate = get(filters, `${STORE_KEYS.BY_EVALUATION_DATE}`, false);
  const onToggleCheckbox = useCallback(() => {
    changeFilter({
      [STORE_KEYS.DATE_FROM]: isActiveFilterByEvaluationDate ? firstReviewDate : lastEvaluationDate,
      [STORE_KEYS.DATE_TO]: lastReviewDate,
      [STORE_KEYS.BY_EVALUATION_DATE]: !isActiveFilterByEvaluationDate,
    });
  }, [isActiveFilterByEvaluationDate, reviewsReportsCommonData]);
  const resetFilters = useCallback(() => {
    changeFilter({
      [STORE_KEYS.DATE_FROM]: firstReviewDate,
      [STORE_KEYS.DATE_TO]: lastReviewDate,
      [STORE_KEYS.BY_EVALUATION_DATE]: false,
    });
  }, [reviewsReportsCommonData]);
  const actions = {
    nope: () => null,
    updateColumnsState,
    getPdfReviews: (actionArguments) => getPdfReviews(actionArguments),
    changeCurrentModal: ({ currentModal, ...rest }) => changeCurrentModal({ currentModal, params: { entityName, ...rest } }),
  };

  useEffect(() => {
    const initialSelected = employeesListForReport.find(({ value }) => toNumber(employeeId) === value);
    if (!isEqual(initialSelected, selectedStaff)) {
      updateSelectedStaff(initialSelected);
    }
  }, [employeesListForReport]);

  useEffect(() => {
    if (employeeId) {
      selectEmployee({ employeeId });
    }
    return resetReviewReport;
  }, []);

  const onSelectEmployee = ({ value, label }) => {
    updateState({}, `/${deliveryReviewsReportAccess.route}/${value}`);
    selectEmployee({
      employeeId: value,
    });
    updateSelectedStaff({ value, label });
  };

  useEffect(() => () => {
    // We are going to reset filters on layout unmount.
    resetFilters();
  }, []);

  const layoutProps = {
    changeOrder,
    orderRules,
    entityName,
    isFetching,
    unitActions: actions,
    wrapperCssRules: 'padding-bottom: 20rem;',
  };
  return (
    <>
      <ToastContainer
        containerId={entityName}
      />

      <Controls
        controlsHeading={controlsHeading}
        cssRules={controlsCssRules}
        wrapperCssRules={controlsWrapperCssRules}
      >
        {
          controls ?
          <>
            {
              controls.map(({
                title: buttonTitle,
                actionArguments,
                actionName = 'nope',
              }) => (
                <button
                  key={buttonTitle}
                  className="controls__create-button button button--cancel client-details-controls__button--fixed-size"
                  onClick={() => actions[actionName](actionArguments)}
                >
                  {buttonTitle}
                </button>
              ))
            }
          </> :
          <>
            <div />
            <div />
          </>

        }

        <StyledWrapper
          cssRules={filtersWrapperCssRules}
        >
          <FilterSelect
            selected={selectedStaff}
            styles={filterOptionsStyles}
            filters={selectFilters}
            placeholderLength="12.5rem"
            placeholder="Select Staff"
            onChange={onSelectEmployee}
            items={employeesListForReport}
            cssRules={filterSelectCssRules}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            isFetchingData={isFetchingCommonReviewsData}
          />
          {
            !!selectedEmployee && dateTo && dateFrom && !isFetching && (
            <>
              <Calendar
                value={dateFrom}
                onChange={(selected) => changeFilter({
                  [STORE_KEYS.DATE_FROM]: selected,
                })}
                cssRules={fromMonthPickerCssRules}
              />
              <Calendar
                value={dateTo}
                onChange={(selected) => changeFilter({
                  [STORE_KEYS.DATE_TO]: selected,
                })}
                cssRules={toMonthPickerCssRules}
              />
              <Checkbox
                title="Display only reviews since last evaluation"
                onClick={onToggleCheckbox}
                cssRules={checkboxCssRules}
                isActive={isActiveFilterByEvaluationDate}
              />

            </>

            )
          }
        </StyledWrapper>
        {
          isFilterActive && (
            <ActionButton
              data="Clear filters"
              cssRules={clearButtonCssRules}
              onClick={resetFilters}
            />
          )
        }
      </Controls>
      {
        selectedEmployee ? (
          <LayoutBodyContent
            body={body}
            withErrorBox
            additionalActions={actions}
            {...layoutProps}
          />
        ) : (
          <ScreenPlaceholder
            title="No results yet"
            description="Please select a Staff."
          />
        )
      }
      <GoTop />
    </>

  );
};


EmployeesReviewsReport.propTypes = {
  location: PropTypes.object.isRequired,
  tab: PropTypes.string,
  reportType: PropTypes.string,
  isFormSubmitted: PropTypes.bool,
  userId: PropTypes.number.isRequired,
  changeOrder: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  getPdfReviews: PropTypes.func.isRequired,
  resetReviewReport: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
};

EmployeesReviewsReport.defaultProps = {
  tab: '',
  reportType: '',
  isFormSubmitted: false,
};


export default withRouter(EmployeesReviewsReport);
