import { createSelector } from 'reselect';

export const selectUserData = (state) => state.user;
export const selectIsFetchingUserData = (state) => state.user.isFetching;
export const selectErrors = (state) => state.user.errors;
export const selectEntityName = (state) => state.user.entityName;

export const selectFormattedUserData = createSelector(
  selectUserData,
  ({ userData }) => {
    const {
      fullName,
      title,
      email,
      entityname,
      lastLogin,
      loginIp,
      phone,
    } = userData;
    return {
      'Full Name': fullName,
      'Last Login': lastLogin,
      Phone: phone,
      'Billing Agent': entityname,
      Email: email,
      'Login IP': loginIp,
      Title: title,
    };
  }
);
