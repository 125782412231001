import { formViewboxCss, rateCardInputFieldCSS } from 'assets/styles/config';
import { getRatesStatus, isUnitLocked } from 'layouts/task-order-details/utils';
import { pickBy } from 'lodash';
import moment from 'moment';
import { array, number, object } from 'yup';
// import { seniorityValidation } from 'utils/validation';

export default ({
  isCEO,
  isCTO,
  isCSO,
  clientData = {},
  isTaskOrderActive,
  isExpired,
  isSigned,
  isManuallyDeactivated,
  templateTaskOrderRatecard,
  ratecards: currentRateCards,
}) => [
  {
    type: 'form',
    stylesTemplate: {
      cssRules: `
        grid-template-columns: repeat(5, 1fr);
        grid-template-areas: "rateSelect rateCard rateCard rateCard rateCard";
      `,
      formStyles: `
        .Toastify { margin: 2rem 1.6rem 0; }
      `,
      formControlsCss: `
        grid-template-columns: repeat(2, calc((136rem - 3rem * 4 - 3.2rem) / 5));
        grid-column-gap: 3rem;
      `,
    },
    withoutFormControls:
      (isManuallyDeactivated && !isCEO) || isTaskOrderActive || isCTO,
    content: [
      {
        title: 'Rate Card',
        stylesTemplate: {
          columns: '1fr',
          gridArea: 'rateSelect',
          gridRowGap: '0',
          padding: '0 0 0 1.6rem',
          marginTop: '0',
        },
        formData: [
          {
            type: 'tableHeading',
            value: {
              title: 'Client Rate Card Info',
              cssRules: 'border-bottom: 1px solid transparent;',
            },
          },
          {
            type: 'select',
            name: 'ratecardId',
            getIsUnitLocked: () => isUnitLocked(
              isSigned,
              isTaskOrderActive,
              isManuallyDeactivated,
              isExpired,
              isCEO,
              isCSO
            ),
            valueGetter: ({ taskOrderRates, ratecards = [], client }) => {
              const billingAgentId = client ? client.billingAgentId : null;
              const filteredRates = ratecards.filter(
                (rate) => rate.billingAgentId === billingAgentId &&
                  rate.year >= moment().year()
              );
              const ratecardsItems = filteredRates.map((rate) => ({
                label: rate.name,
                value: rate.ratecardId,
              }));
              const currentRates = {
                value:
                  taskOrderRates && taskOrderRates.name ?
                    taskOrderRates.name || 'No Rate Card' :
                    'No Rate Card',
                label:
                  taskOrderRates && taskOrderRates.name ?
                    taskOrderRates.name || 'No Rate Card' :
                    'No Rate Card',
              };
              return {
                selected: currentRates,
                items: ratecardsItems.length ? ratecardsItems : [],
              };
            },
            validationRules: {
              isRequired: true,
            },
            cssRules: `
              min-height: 4.5rem;
              max-height: 4.5rem;
              & .select-item__value-container {
                padding: 0 8px !important;
              }
              & .select-item__indicators {
                margin-bottom: 2rem;
              }
            `,
          },
          {
            type: 'viewBox',
            getIsUnitLocked: () => true,
            valueGetter: () => ({
              withIcon: true,
            }),
            parentField: {
              name: ['taskOrderRates'],
              strictDependence: true,
              valueGetter: ({ taskOrderRates }) => {
                const {
                  message,
                  hasCurrentRCCustomized,
                  hasClientRCCustomized,
                } = getRatesStatus({
                  client: clientData,
                  rateCard: taskOrderRates,
                  templateTaskOrderRatecard,
                });
                const hasRatesChanged =
                  hasCurrentRCCustomized || hasClientRCCustomized;
                return {
                  value: message,
                  isActive: hasRatesChanged,
                  isDefault: !hasRatesChanged,
                };
              },
            },
            validationRules: {},
            cssRules: `
              font-size: 1.4rem;
              line-height: 1.8rem;
              min-height: 4.5rem;
              max-height: 4.5rem;
            `,
          },
          {
            type: 'viewBox',
            getIsUnitLocked: () => true,
            valueGetter: ({ isPlatinum }) => ({
              value: isPlatinum ? 'Platinum' : 'Not Platinum',
              isDefault: !isPlatinum,
            }),
            cssRules: `
              min-height: 4.5rem;
              max-height: 4.5rem;
            `,
          },
          {
            type: 'viewBox',
            getIsUnitLocked: () => true,
            valueGetter: ({ client = {} }) => ({
              value: 'View Client Rate Card Details',
              path:
                client && client.clientId ?
                  `/clients/${client.clientId}/billing-info` :
                  '/clients',
            }),
            cssRules: `
              min-height: 4.5rem;
              max-height: 4.5rem;
            `,
          },
        ],
      },
      {
        stylesTemplate: {
          gridColumnGap: '1.6rem',
          gridRowGap: '0',
          marginTop: '2.4rem',
          gridArea: 'rateCard',
        },
        formData: [
          {
            isFieldArray: true,
            name: 'taskOrderRates',
            parentField: {
              name: ['ratecardId'],
              valueGetter: ({ ratecardId }) => {
                const selectedRatecard = currentRateCards.find(
                  (rate) => rate.ratecardId === ratecardId
                );
                return selectedRatecard ? selectedRatecard.rates : [];
              },
              valueSetter: (setFieldValue, selectedClientRatecardRates) => {
                if (selectedClientRatecardRates) {
                  setFieldValue('taskOrderRates', selectedClientRatecardRates);
                }
              },
            },
            valueGetter: ({ devcenters, taskOrderRates }) => {
              const rates = taskOrderRates ?
                taskOrderRates.rates.map((rate) => {
                  const filteredRates = pickBy(
                    rate,
                    (value, key) => !key.includes('Init')
                  );
                  filteredRates.devcenter = rate.devcenter;
                  filteredRates.seniority = rate.seniority;
                  return filteredRates;
                }, []) :
                [];
              const taskOrderInitRates = taskOrderRates ?
                taskOrderRates.rates :
                [];
              const vendorById = {};
              if (devcenters) {
                devcenters.forEach((devcenter) => {
                  vendorById[devcenter.value] = devcenter.label;
                });
              }

              const clietnTemplateRateCard = clientData.templateRatecard ?
                clientData.templateRatecard.rates :
                [];
              return {
                value: rates,
                lineGetter: ({ index, lineValues }) => {
                  const {
                    seniority,
                    devcenter,
                    ratePtm,
                    rateHr,
                    rateDisc,
                    ratePlatinum,
                    rateMo,
                    rateMoPlatinum,
                  } = lineValues || {};
                  return {
                    lineId: `line_${index}`,
                    line: [
                      {
                        type: 'viewBox',
                        name: `taskOrderRates[${index}].seniority`,
                        fieldData: {
                          value: seniority,
                        },
                        cssRules: `
                      ${formViewboxCss}
                      height: 4.2rem;
                      font-size: 1.4rem;
                      text-transform: uppercase;
                      justify-content: flex-start;
                    `,
                      },
                      {
                        type: 'viewBox',
                        name: `taskOrderRates[${index}].devcenter`,
                        fieldData: {
                          value: vendorById[devcenter],
                        },
                        cssRules: `
                      ${formViewboxCss}
                      height: 4.2rem;
                      font-size: 1.4rem;
                      justify-content: flex-start;
                    `,
                      },
                      {
                        type: 'text',
                        name: `taskOrderRates[${index}].ratePtm`,
                        label: 'ratePtm',
                        fieldData: {
                          placeholder: '00.00',
                          value: Number(ratePtm).toFixed(2),
                          currentRateCardRate: templateTaskOrderRatecard[index] ?
                            Number(
                              templateTaskOrderRatecard[index].ratePtm
                            ).toFixed(2) :
                            '',
                          clientRateCardRate: templateTaskOrderRatecard[index] ?
                            Number(
                              taskOrderInitRates[index].ratePtmInit
                            ).toFixed(2) :
                            '',
                          isDefaultRatesChanged:
                            clietnTemplateRateCard[index] &&
                            taskOrderInitRates[index] ?
                              Number(
                                clietnTemplateRateCard[index].ratePtm
                              ).toFixed(2) !==
                                Number(
                                  taskOrderInitRates[index].ratePtmInit
                                ).toFixed(2) :
                              false,
                        },
                        isLocked: isUnitLocked(
                          isSigned,
                          isTaskOrderActive,
                          isManuallyDeactivated,
                          isExpired,
                          isCEO,
                          isCSO
                        ),
                        validationRules: {
                          isRates: true,
                          decimalScale: 2,
                          isNumeric: true,
                          isRequired: true,
                          maxValue: 99999.99,
                        },
                        cssRules: rateCardInputFieldCSS,
                      },
                      {
                        type: 'text',
                        name: `taskOrderRates[${index}].rateHr`,
                        label: 'rateHr',
                        fieldData: {
                          placeholder: '00.00',
                          value: Number(rateHr).toFixed(2),
                          currentRateCardRate: templateTaskOrderRatecard[index] ?
                            Number(
                              templateTaskOrderRatecard[index].rateHr
                            ).toFixed(2) :
                            '',
                          clientRateCardRate: templateTaskOrderRatecard[index] ?
                            Number(
                              taskOrderInitRates[index].rateHrInit
                            ).toFixed(2) :
                            '',
                          isDefaultRatesChanged:
                            clietnTemplateRateCard[index] &&
                            taskOrderInitRates[index] ?
                              Number(
                                clietnTemplateRateCard[index].rateHr
                              ).toFixed(2) !==
                                Number(
                                  taskOrderInitRates[index].rateHrInit
                                ).toFixed(2) :
                              false,
                        },
                        isLocked: isUnitLocked(
                          isSigned,
                          isTaskOrderActive,
                          isManuallyDeactivated,
                          isExpired,
                          isCEO,
                          isCSO
                        ),
                        validationRules: {
                          isRates: true,
                          isNumeric: true,
                          maxValue: 99999.99,
                        },
                        cssRules: rateCardInputFieldCSS,
                      },
                      {
                        type: 'text',
                        name: `taskOrderRates[${index}].rateDisc`,
                        label: 'rateDisc',
                        fieldData: {
                          placeholder: '00.00',
                          value: Number(rateDisc).toFixed(2),
                          currentRateCardRate: templateTaskOrderRatecard[index] ?
                            Number(
                              templateTaskOrderRatecard[index].rateDisc
                            ).toFixed(2) :
                            '',
                          clientRateCardRate: templateTaskOrderRatecard[index] ?
                            Number(
                              taskOrderInitRates[index].rateDiscInit
                            ).toFixed(2) :
                            '',
                          isDefaultRatesChanged:
                            clietnTemplateRateCard[index] &&
                            taskOrderInitRates[index] ?
                              Number(
                                clietnTemplateRateCard[index].rateDisc
                              ).toFixed(2) !==
                                Number(
                                  taskOrderInitRates[index].rateDiscInit
                                ).toFixed(2) :
                              false,
                        },
                        isLocked: isUnitLocked(
                          isSigned,
                          isTaskOrderActive,
                          isManuallyDeactivated,
                          isExpired,
                          isCEO,
                          isCSO
                        ),
                        validationRules: {
                          isRates: true,
                          isNumeric: true,
                          maxValue: 99999.99,
                        },
                        cssRules: rateCardInputFieldCSS,
                      },
                      {
                        type: 'text',
                        name: `taskOrderRates[${index}].ratePlatinum`,
                        label: 'ratePlatinum',
                        fieldData: {
                          placeholder: '00.00',
                          value: Number(ratePlatinum).toFixed(2),
                          currentRateCardRate: templateTaskOrderRatecard[index] ?
                            Number(
                              templateTaskOrderRatecard[index].ratePlatinum
                            ).toFixed(2) :
                            '',
                          clientRateCardRate: templateTaskOrderRatecard[index] ?
                            Number(
                              taskOrderInitRates[index].ratePlatinumInit
                            ).toFixed(2) :
                            '',
                          isDefaultRatesChanged:
                            clietnTemplateRateCard[index] &&
                            taskOrderInitRates[index] ?
                              Number(
                                clietnTemplateRateCard[index].ratePlatinum
                              ).toFixed(2) !==
                                Number(
                                  taskOrderInitRates[index].ratePlatinumInit
                                ).toFixed(2) :
                              false,
                        },
                        isLocked: isUnitLocked(
                          isSigned,
                          isTaskOrderActive,
                          isManuallyDeactivated,
                          isExpired,
                          isCEO,
                          isCSO
                        ),
                        validationRules: {
                          isRates: true,
                          isNumeric: true,
                          maxValue: 99999.99,
                        },
                        cssRules: rateCardInputFieldCSS,
                      },
                      {
                        type: 'text',
                        name: `taskOrderRates[${index}].rateMo`,
                        label: 'rateMo',
                        fieldData: {
                          placeholder: '00.00',
                          value: Number(rateMo).toFixed(2),
                          currentRateCardRate: templateTaskOrderRatecard[index] ?
                            Number(
                              templateTaskOrderRatecard[index].rateMo
                            ).toFixed(2) :
                            '',
                          clientRateCardRate: templateTaskOrderRatecard[index] ?
                            Number(
                              taskOrderInitRates[index].rateMoInit
                            ).toFixed(2) :
                            '',
                          isDefaultRatesChanged:
                            clietnTemplateRateCard[index] &&
                            taskOrderInitRates[index] ?
                              Number(
                                clietnTemplateRateCard[index].rateMo
                              ).toFixed(2) !==
                                Number(
                                  taskOrderInitRates[index].rateMoInit
                                ).toFixed(2) :
                              false,
                        },
                        isLocked: isUnitLocked(
                          isSigned,
                          isTaskOrderActive,
                          isManuallyDeactivated,
                          isExpired,
                          isCEO,
                          isCSO
                        ),
                        validationRules: {
                          isRates: true,
                          isNumeric: true,
                          maxValue: 99999.99,
                        },
                        cssRules: rateCardInputFieldCSS,
                      },
                      {
                        type: 'text',
                        name: `taskOrderRates[${index}].rateMoPlatinum`,
                        label: 'rateMoPlatinum',
                        fieldData: {
                          placeholder: '00.00',
                          value: Number(rateMoPlatinum).toFixed(2),
                          currentRateCardRate: templateTaskOrderRatecard[index] ?
                            Number(
                              templateTaskOrderRatecard[index].rateMoPlatinum
                            ).toFixed(2) :
                            '',
                          clientRateCardRate: templateTaskOrderRatecard[index] ?
                            Number(
                              taskOrderInitRates[index].rateMoPlatinumInit
                            ).toFixed(2) :
                            '',
                          isDefaultRatesChanged:
                            clietnTemplateRateCard[index] &&
                            taskOrderInitRates[index] ?
                              Number(
                                clietnTemplateRateCard[index].rateMoPlatinum
                              ).toFixed(2) !==
                                Number(
                                  taskOrderInitRates[index].rateMoPlatinumInit
                                ).toFixed(2) :
                              false,
                        },
                        isLocked: isUnitLocked(
                          isSigned,
                          isTaskOrderActive,
                          isManuallyDeactivated,
                          isExpired,
                          isCEO,
                          isCSO
                        ),
                        validationRules: {
                          isRates: true,
                          isNumeric: true,
                          maxValue: 99999.99,
                        },
                        cssRules: rateCardInputFieldCSS,
                      },
                    ],
                    stylesTemplate: {
                      cssRules: `
                    grid-template-columns: minmax(9rem, 0.8fr) minmax(9rem, 1.2fr) repeat(6, minmax(12rem, 1fr));
                    grid-column-gap: 0.8rem;
                    grid-row-gap: 0.4rem;
                    margin-top: 0;
                    padding: 0.4rem 0 0;
                    `,
                    },
                  };
                },
                headersConfig: {
                  stylesTemplate: {
                    cssRules: `
                    && {
                      grid-template-columns: minmax(9rem, 0.8fr) minmax(9rem, 1.2fr) repeat(6, minmax(12rem, 1fr));
                      padding: 0;
                      grid-column-gap: 0.8rem;
                      margin-top: 0;
                    }
                  `,
                  },
                  data: [
                    {
                      value: {
                        title: 'Level',
                      },
                    },
                    {
                      value: {
                        title: 'DevCenter',
                      },
                    },
                    {
                      value: {
                        title: 'PTM rate/hr',
                        cssRules: 'text-align: right;',
                      },
                    },
                    {
                      value: {
                        title: 'TTM rate/hr',
                        cssRules: 'text-align: right;',
                      },
                    },
                    {
                      value: {
                        title: 'TTM preferred/hr',
                        cssRules: 'text-align: right;',
                      },
                    },
                    {
                      value: {
                        title: 'TTM platinum/hr',
                        cssRules: 'text-align: right;',
                      },
                    },
                    {
                      value: {
                        title: 'ODC/mo',
                        cssRules: 'text-align: right;',
                      },
                    },
                    {
                      value: {
                        title: 'ODC platinum/mo',
                        cssRules: 'text-align: right;',
                      },
                    },
                  ],
                },
              };
            },
            validationRules: {
              schemaGetter: () => array().of(
                object().shape({
                  ratePtm: number().nullable().required('Required field'),
                  rateHr: number().nullable().required('Required field'),
                  rateDisc: number().nullable().required('Required field'),
                  ratePlatinum: number()
                    .nullable()
                    .required('Required field'),
                  rateMo: number().nullable().required('Required field'),
                  rateMoPlatinum: number()
                    .nullable()
                    .required('Required field'),
                })
              ),
            },
          },
        ],
      },
    ],
  },
];
