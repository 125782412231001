import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import Search from 'components/search';
import Checkbox from 'elements/checkbox';
import Select from 'components/filter-select';
import ActionButton from 'elements/action-button';
import './styles.scss';


const StyledWrapper = styled.div`
  max-height: 3.4rem;
  min-height: 3.4rem;
  ${({ cssRules }) => css`${cssRules}`}`;

const units = {
  select: Select,
  search: Search,
  checkbox: Checkbox,
  actionButton: ActionButton,
  empty: () => <div />,
};

const FormFilter = ({
  actions,
  filterType,
  cssRules,
  fieldData,
  filterConfig,
  data,
  isActive,
  currentFilters,
  filterValue,
  name,
}) => {
  const { changeFilter, clearFilter } = actions;
  const isClearFilterButton = filterType === 'actionButton';
  let isFilterActive = false;

  const handleChange = (value) => changeFilter(name, value);
  const handleClick = () => {
    if (filterType === 'actionButton') {
      return clearFilter();
    }
    return false;
  };

  if (filterType === 'actionButton') {
    isFilterActive = !!(Object.values(currentFilters).filter((item) => item.length).length);
  }

  return (
    <StyledWrapper
      className={
        classNames(
          '',
          { 'grid-unit grid-unit--filter': filterType !== 'actionButton' },
          { 'grid-unit--filter-button': filterType === 'actionButton' },
          { active: (filterType === 'actionButton') && (isFilterActive) },
          { 'grid-unit--active': isActive },
        )
      }
      isClearFilterButton={isClearFilterButton}
      isFilterActive={isFilterActive}
    >
      {
        React.createElement(
          units[filterType],
          {
            onChange: handleChange,
            onClick: handleClick,
            clearFilter,
            fieldData,
            cssRules,
            data,
            ...filterConfig,
            term: filterValue,
            selected: filterValue,
          }
        )
      }
    </StyledWrapper>

  );
};


FormFilter.propTypes = {
  data: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  cssRules: PropTypes.string,
  actions: PropTypes.object,
  filterType: PropTypes.string,
  fieldData: PropTypes.string,
  filterConfig: PropTypes.object,
  currentFilters: PropTypes.object,
  filterValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  name: PropTypes.string,
};

FormFilter.defaultProps = {
  data: '',
  isActive: false,
  cssRules: '',
  actions: {},
  filterType: '',
  fieldData: '',
  filterConfig: {},
  currentFilters: {},
  filterValue: '',
  name: '',
};


export default FormFilter;
