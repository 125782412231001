import { getIsExpired } from 'utils/helpers/date';

const FILE_TYPES = {
  MSA: 'MSA',
  NDA: 'NDA',
};

export const onSubmitUploadedFile = ({ values, onSubmit, clientId, file, entityName }) => {
  const {
    uploadType,
    uploadTitle,
    uploadDate,
    uploadMsa,
    uploadStatus,
  } = values;

  onSubmit({
    clientId,
    fileName: uploadType === FILE_TYPES.MSA ? uploadMsa : Date.now(),
    fileType: uploadType,
    title: uploadTitle,
    effectiveDate: uploadDate,
    isSigned: uploadStatus,
    file,
    entityName,
  });
};

export const rateCardsParentFieldValueGetter = (
  { ratecardId: currentRatecardId },
  { seniorityRates, ratesIndex, name, devcenters }
) => {
  const seniorityRate = seniorityRates.find((rate) => rate.ratecardId === Math.abs(currentRatecardId)).rates[ratesIndex];
  let currentRate = seniorityRate ? seniorityRate[name] : '';
  if (devcenters) {
    currentRate = devcenters[currentRate];
  }
  return {
    value: currentRatecardId ? currentRate : '',
    currentRatecardId,
  };
};

export const getTOStatus = ({ endDate, isActive, isManuallyDeactivated, isSigned }) => {
  if (isManuallyDeactivated) {
    return 'Deactivated';
  }
  if (getIsExpired(endDate)) {
    return 'Expired';
  }
  if (!isActive && isSigned) {
    return 'Signed';
  }

  return isActive ? 'Active' : 'Not yet active';
};
