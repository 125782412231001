export const getOnboardingHistory = (onboardingHistory) => {
  const initialHistory = {
    dateLast: null,
    rehireDate: null,
    vacationStartDate: null,
    vacationEndDate: null,
    archivedRecords: [],
  };

  return onboardingHistory && Array.isArray(onboardingHistory) ?
    onboardingHistory
      .reduce((acc, record) => {
        const { isDismiss, isRehire, onLeave, dateStart, endDate, hasPassed, historyId } = record;
        const { dateLast, vacationStartDate, vacationEndDate, archivedRecords, rehireDate } = acc;

        return {
          ...acc,
          ...(hasPassed ?
            { archivedRecords: [...archivedRecords, record] } :
            {
              dateLast: isDismiss ? {
                value: dateStart,
                historyId,
              } : dateLast,
              vacationStartDate: onLeave ? {
                value: dateStart,
                historyId,
              } : vacationStartDate,
              vacationEndDate: onLeave ? {
                value: endDate,
                historyId,
              } : vacationEndDate,
              rehireDate: isRehire ? {
                value: dateStart,
                historyId,
              } : rehireDate,
            }),
        };
      }, initialHistory) :
    initialHistory;
};
