import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import cn from 'classnames';

import { get } from 'lodash';

import styles from './styles.module.scss';

const Error = ({ error }) => (
  <div data-id="Text Input Error" className={styles.error}>
    {get(error, 'message', '')}
  </div>
);

Error.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};

const TextInput = forwardRef(
  ({ className, type, error, isLocked, ...props }, ref) => (
    <div data-id="Text Input Container">
      <input
        aria-required={!!error}
        type={type}
        className={cn(
          styles.textInput,
          className,
          {
            [styles.isLocked]: isLocked,
          }
        )}
        ref={ref}
        {...props}
      />
      {error && <Error error={error} />}
    </div>
  )
);

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  isLocked: PropTypes.bool,
};

TextInput.defaultProps = {
  className: '',
  type: 'text',
  error: null,
  isLocked: false,
};

export { TextInput };
