import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import ToastContainer from 'components/toast';

import {
  selectHasPermissionsToPayrollReports,
  selectHasPermissionsToCreateResource,
} from 'core/auth/selectors';
import { commonActions } from 'core/common/actions';

import { deliveryActions } from 'core/delivery/actions';
import {
  selectOrderRules,
  selectEntityName,
  selectHasProjectsFilters,
  selectEmployeesListModel,
  selectEmployeeListFilterConfig,
  selectProjectsListFilterConfig,
  selectHasEmployeeActiveFilters,
  selectProjectForm,
  selectedEmployeesData,
  selectedProjectsData,
  selectIsFetchingEmployeesList,
  selectEmployeesCount,
  selectEmployeeDetailsModel,
  selectEmployeeDetails,
  selectIsFetchingDetails,
  selectIsFavoredEmployee,
  selectIsFiltering,
  selectProjectListModel,
  selectIsFormSubmitted,
  selectFilterByAssignmentsValidPl,
  selectFilterByAssignmentsPl,
  selectProjectsCount,
  selectSelectedProject,
  selectIsEmployeeEmailAvailable,
} from 'core/delivery/selectors';
import { selectCurrentModal } from 'core/modal-conductor/selectors';
import { useCheckPermissions } from 'hooks';
import EmployeeDetails from 'layouts/employee-details';
import EmployeesList from 'layouts/employees-list';
import ProjectLeadsManagement from 'layouts/project-leads-management';
import compose from 'lodash/fp/compose';
import { Switch, Route, Redirect, withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

const pathToTabIndex = {
  details: 0,
  'work-book': 1,
  'pto-requests': 2,
  'project-lead-assignments': 3,
};

const Delivery = (props) => {
  const { entityName, setEntityName, ...restProps } = props;

  useEffect(() => {
    setEntityName(entityName);
  }, [entityName]);

  useCheckPermissions(restProps);

  return (
    <>
      <ToastContainer containerId={entityName} />

      <Switch>
        <Route
          exact
          path="/delivery/employees-list"
          render={() => <EmployeesList {...props} />}
        />

        <Route
          exact
          path={[
            '/delivery/project-leads-management',
            '/delivery/project-leads-management/:internalCategoryKey',
          ]}
          render={({ location: { pathname } }) => {
            const [internalCategoryKey] = pathname
              .replace('/delivery/project-leads-management/', '')
              .split('/');

            return (
              <ProjectLeadsManagement
                internalCategoryKey={internalCategoryKey}
                {...props}
              />
            );
          }}
        />

        <Route
          path="/delivery/employee/add/:tab"
          render={() => {
            const tabIndex = 0;
            return (
              <EmployeeDetails tabIndex={tabIndex} {...props} isCreating />
            );
          }}
        />

        <Route
          path="/delivery/employee/:id/:tab"
          render={({ match: { params } }) => {
            const { id, tab } = params;
            const tabIndex = pathToTabIndex[tab] || 0;
            return (
              <EmployeeDetails employeeId={id} tabIndex={tabIndex} {...props} />
            );
          }}
        />

        <Redirect to="/clients" />
      </Switch>
    </>
  );
};

Delivery.propTypes = {
  entityName: PropTypes.string.isRequired,
  userGroup: PropTypes.string,
  guaranteedAccessRoles: PropTypes.arrayOf(PropTypes.string),
  guaranteedAccessRolesForSubRoutes: PropTypes.shape({}),
  setEntityName: PropTypes.func.isRequired,
};

Delivery.defaultProps = {
  userGroup: '',
  guaranteedAccessRoles: [],
  guaranteedAccessRolesForSubRoutes: null,
};

const mapStateToProps = createStructuredSelector({
  entityName: selectEntityName,
  employeesListModel: selectEmployeesListModel,
  employeeListFilterConfig: selectEmployeeListFilterConfig,
  projectsListFilterConfig: selectProjectsListFilterConfig,
  hasEmployeeActiveFilters: selectHasEmployeeActiveFilters,
  hasActiveFilters: selectHasProjectsFilters,
  orderRules: selectOrderRules,
  employeesData: selectedEmployeesData,
  projectsData: selectedProjectsData,
  projectsCount: selectProjectsCount,
  isFetchingEmployeesList: selectIsFetchingEmployeesList,
  isFetchingProjectsList: selectIsFetchingEmployeesList,
  employeesCount: selectEmployeesCount,
  currentModal: selectCurrentModal,
  employeeDetails: selectEmployeeDetails,
  employeeDetailsModel: selectEmployeeDetailsModel,
  isFetchingDetails: selectIsFetchingDetails,
  isFavoredEmployee: selectIsFavoredEmployee,
  isFiltering: selectIsFiltering,
  projectListModel: selectProjectListModel,
  isFormSubmitted: selectIsFormSubmitted,
  hasPermissionsToCreateResource: selectHasPermissionsToCreateResource,
  hasPermissionsToPayrollReports: selectHasPermissionsToPayrollReports,
  filterByAssignmentsValidPl: selectFilterByAssignmentsValidPl,
  filterByAssignmentsPl: selectFilterByAssignmentsPl,
  projectForm: selectProjectForm,
  selectedProject: selectSelectedProject,
  isEmployeeEmailAvailable: selectIsEmployeeEmailAvailable,
});

const mapDispatchToProps = {
  resetFilters: deliveryActions.resetFilters,
  changeFilter: deliveryActions.changeFilter,
  toggleFavorite: deliveryActions.toggleFavorite,
  getProjectsList: deliveryActions.getProjectsList,
  getEmployeesList: deliveryActions.getEmployeesList,
  getEmployeeDetails: deliveryActions.getEmployeeDetails,
  updateEmployeeDetails: deliveryActions.updateEmployeeDetails,
  createOnboardingHistory: deliveryActions.createOnboardingHistory,
  updateOnboardingHistory: deliveryActions.updateOnboardingHistory,
  addWorkBookTemplateRecord: deliveryActions.addWorkBookTemplateRecord,
  removeWorkBookTemplateRecord: deliveryActions.removeWorkBookTemplateRecord,
  toggleWorkBookRecordState: deliveryActions.toggleWorkBookRecordState,
  createWorkbookRecord: deliveryActions.createWorkbookRecord,
  updateWorkbookRecord: deliveryActions.updateWorkbookRecord,
  createEmployee: deliveryActions.createEmployee,
  setEmployeeDetails: deliveryActions.setEmployeeDetails,
  clearIsEmployeeEmailAvailable: deliveryActions.clearIsEmployeeEmailAvailable,
  addPtoRequestTemplateRecord: deliveryActions.addPtoRequestTemplateRecord,
  removePtoRequestTemplateRecord:
    deliveryActions.removePtoRequestTemplateRecord,
  togglePtoRequestRecordState: deliveryActions.togglePtoRequestRecordState,
  createPtoRequestRecord: deliveryActions.createPtoRequestRecord,
  updatePtoRequestRecord: deliveryActions.updatePtoRequestRecord,
  generatePlApprovals: deliveryActions.generatePlApprovals,
  getAssignedPLs: deliveryActions.getAssignedPLs,
  updatePLAssignments: deliveryActions.updatePLAssignments,
  setEntityName: commonActions.setEntityName,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Delivery);
