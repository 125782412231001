// import moment from 'moment';

export default [
  {
    columnName: 'Total',
    valueGetter: ({ name }) => ({
      value: name,
      type: 'text',
    }),
    cssRules: '',
    headerProps: {},
    withoutControls: true,
  },
  {
    columnName: 'Description',
    valueGetter: ({ description }) => ({
      value: description,
      type: 'text',
    }),
    cssRules: '',
    headerProps: {},
    withoutControls: true,
  },
  {
    columnName: 'Hours',
    valueGetter: ({ hours }) => ({
      value: `${hours.toFixed(2)} h`,
      type: 'text',
    }),
    cssRules: '',
    headerProps: {},
    withoutControls: true,
  },
  {
    columnName: '% of logged',
    valueGetter: ({ percentOfLogged }) => ({
      value: `${percentOfLogged ? percentOfLogged.toFixed(2) : '0.00'} %`,
      type: 'text',
    }),
    cssRules: '',
    headerProps: {},
    withoutControls: true,
  },
  {
    columnName: '% of month',
    valueGetter: ({ percentOfMonth }) => ({
      value: `${percentOfMonth ? percentOfMonth.toFixed(2) : '0.00'} %`,
      type: 'text',
    }),
    cssRules: '',
    headerProps: {},
    withoutControls: true,
  },
];
