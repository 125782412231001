export const ENTITY_NAME = 'CONTRACT_MANAGEMENT';

export const EMPOYMENT_TYPES = {
  EMPLOYEE: 'Employee',
  CONTRACTOR: 'Contractor',
  UNKNOWN: 'Unknown',
};

export const pathToContractManagementRecords = ['employeeDetailsTabs', 'contractManagement', 'contractManagementRecords'];
export const recordIdName = 'contractRecordId';
