/* eslint-disable no-useless-catch */
import { get } from 'lodash';

import { config } from './config';
import { appStorage } from './storage';

class GraphqlClient {
  constructor({ apiUrl, setHeaders }) {
    this.apiUrl = apiUrl;
    this.defaultHeaders = {
      'Content-Type': 'application/json',
    };
    this.setHeaders = setHeaders;
  }

  async query({ query = {}, variables = {} }) {
    const requestBody = {
      query: get(query, 'loc.source.body', ''),
      variables,
    };
    try {
      return await this._fetchData(this.apiUrl, requestBody);
    } catch (error) {
      throw error;
    }
  }

  async mutate({ mutation = {}, variables = {} }) {
    const requestBody = {
      query: get(mutation, 'loc.source.body', ''),
      variables,
    };
    try {
      return await this._fetchData(this.apiUrl, requestBody);
    } catch (error) {
      throw error;
    }
  }

  async _fetchData(url, requestBody) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          ...this.defaultHeaders,
          ...this.setHeaders(),
        },
        body: JSON.stringify(requestBody),
      });

      return await response.json();
    } catch (error) {
      throw error;
    }
  }
}

const graphqlClient = new GraphqlClient({
  apiUrl: `${config.apiUrl}/graphql`,
  setHeaders: () => ({
    Authorization: `Bearer ${appStorage.getAccessToken()}`,
  }),
});

const unauthorizedGraphqlClient = new GraphqlClient({
  apiUrl: `${config.apiUrl}/auth`,
  setHeaders: () => {},
});

export { graphqlClient, unauthorizedGraphqlClient };
