import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Field } from 'formik';

import Inputs from 'components/inputs';
import ErrorMessage from 'elements/error-message';

import { onInputValueChange, updateInput } from './utils';

import './styles.scss';

const MsaTextInput = ({
  placeholder,
  name,
  fieldData,
  disabled,
  onChange,
  validationRules,
  error,
  isTouched,
  tooltipContent,
  isLocked,
  isMultiline,
  formValue,
  cssRules,
  withErrorBox,
  parentField,
  getMsaTemplate,
}) => {
  const { isRates, isRequired } = validationRules;
  const initialValue = get(fieldData, 'value');
  const hasDependency = !!parentField;
  const [inputValue, setInputValue] = useState(initialValue);
  const [isManuallyUpdated, setIsManuallyUpdated] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [menuData, setMenuData] = useState(false);

  useEffect(() => {
    onInputValueChange({ validationRules, fieldData, setHasChanges, inputValue });
  }, [inputValue]); // set specific params on input value change


  useEffect(() => {
    setInputValue(inputValue);
  }, [fieldData]); // update current value if initial data changed


  useEffect(() => {
    if (formValue !== inputValue) {
      if (isRates) {
        setInputValue(Number(formValue) ? Number(formValue).toFixed(2) : '');
      } else {
        setInputValue(formValue);

        if (!formValue && isManuallyUpdated) {
          setIsManuallyUpdated(false);
        }
      }
    }
  }, [formValue]); // listen formik changes (need for reset)

  const changeInputValue = (currentValue) => updateInput({
    currentValue,
    validationRules,
    isLocked,
    inputValue,
    setInputValue,
    onChange,
    setIsManuallyUpdated,
  });

  const editText = () => {
    setShowMenu(false);
    onChange(menuData);
  };

  const cancelEdit = () => {
    setShowMenu(false);
  };

  const selectHandler = (event) => {
    const currentValue = get(event, 'target.value', '');
    const selectedValue = window.getSelection().toString().trim();
    const { actualDefaultMsaTemplate: msaSections, msaTemplate } = getMsaTemplate();
    const templateText = msaTemplate.split('\n').map((sectionName) => sectionName.trim()).filter((item) => item.length);
    const currentSection = msaSections.find((section) => section.sectionTitle === selectedValue);
    if (templateText.includes(selectedValue) && selectedValue.length && currentSection) {
      const position = {
        x: get(event, 'nativeEvent.clientX', ''),
        y: get(event, 'nativeEvent.clientY', '') + 10,
      };
      const currentSectionText = `[${currentSection.sectionText}]`;
      const newValue = [currentValue.slice(0, currentValue.indexOf(selectedValue)), currentSectionText, currentValue.slice(currentValue.indexOf(selectedValue) + selectedValue.length)];
      setShowMenu(position);
      setMenuData(newValue.join(''));
      return;
    }
    setShowMenu(false);
  };

  const withError = !!(error && isTouched);
  const withLabel = !isRates;
  const hasManuallyUpdates = hasDependency && isManuallyUpdated && !!inputValue;
  const withWarning = isRates && fieldData.isDefaultRatesChanged;

  return (

    <Field
      type="text"
      name={name}
      render={() => (<>
        <Inputs.TextInput
          name={name}
          isLocked={isLocked || (!inputValue && !isRates)}
          hasChanges={hasChanges}
          inputValue={inputValue}
          isDisabled={disabled}
          onChange={changeInputValue}
          onSelect={selectHandler}
          placeholder={get(fieldData, 'placeholder', '')}
          label={placeholder}
          withLabel={withLabel}
          isRequired={isRequired || false}
          hasManuallyUpdates={hasManuallyUpdates}
          error={error}
          withError={withError}
          tooltipContent={tooltipContent}
          isMultiline={isMultiline}
          withWarning={withWarning}
          cssRules={cssRules}
          withErrorBox={withErrorBox}
        >
          {
            !withError && hasManuallyUpdates && !isRates &&
            <ErrorMessage visible>
              {`${placeholder} is customized`}
            </ErrorMessage>
          }
        </Inputs.TextInput>
        {showMenu &&
        <div
          className="pop-up-menu"
          style={{
            top: showMenu.y,
            left: showMenu.x,
          }}
        >
          <button onClick={editText}>Edit</button>
          <button onClick={cancelEdit}>Cancel</button>
        </div>
        }
        </>
      )
      }
    />

  );
};


MsaTextInput.propTypes = {
  withErrorBox: PropTypes.bool,
  parentField: PropTypes.shape({}),
  formValue: PropTypes.any,
  isMultiline: PropTypes.bool,
  isLocked: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  error: PropTypes.string,
  isTouched: PropTypes.bool,
  cssRules: PropTypes.string,
  validationRules: PropTypes.shape({
    isNumeric: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  fieldData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  getMsaTemplate: PropTypes.func,
};

MsaTextInput.defaultProps = {
  withErrorBox: false,
  parentField: null,
  cssRules: '',
  formValue: '',
  isMultiline: false,
  isLocked: false,
  tooltipContent: '',
  validationRules: {},
  name: null,
  placeholder: null,
  fieldData: null,
  disabled: false,
  onChange: () => null,
  error: '',
  isTouched: false,
  getMsaTemplate: () => null,
};


export default memo(MsaTextInput, (prevProps, currentProps) => {
  const { isTouched, formValue, error, disabled, parentFieldsData, fieldData, isLocked } = prevProps;

  if (isTouched !== currentProps.isTouched ||
    formValue !== currentProps.formValue ||
    error !== currentProps.error ||
    disabled !== currentProps.disabled ||
    fieldData !== currentProps.fieldData ||
    isLocked !== currentProps.isLocked ||
    parentFieldsData !== currentProps.parentFieldsData) {
    return false;
  }

  return true;
});
