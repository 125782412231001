import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import {
  backgroundButtons,
  colorPrimary,
  colorSecondaryGrayLight,
  colorWhite,
  shadowButtons,
} from 'assets/styles/variables';
import Calendar from 'components/calendar';
import Controls from 'components/details-controls';
import GroupController from 'components/expansion-panel/group-control-button';
import FilterSelect from 'components/filter-select';
import Loader from 'components/loader';
import ToastContainer from 'components/toast';
import { USERS_GROUPS } from 'core/auth/constants';
import Checkbox from 'elements/checkbox';
import GoTop from 'elements/go-top-button';
import RemoteControllerButton from 'elements/remote-controller-button';
import StyledWrapper from 'elements/styled-wrapper';
import WeekWithRange from 'elements/week-with-range';
import MultiFormsWrapper from 'layouts/multi-forms-wrapper';
import moment from 'moment';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { getHasPermissions } from 'utils/auth';
import { format } from 'utils/date';
import ExpandingSectionStore from 'utils/expanding-sections-store';

import { timeout } from 'utils/helpers/events';
import { RemoteFormsController } from 'utils/helpers/forms';
import { autoScrollToTop } from 'utils/react';

const StyledFilter = styled.div`
  ${({ isRender }) => (isRender ? 'filter: blur(5px);' : '')}
`;

const { CPS } = USERS_GROUPS;

const EmployeesReviews = ({
  location,
  setDate,
  isFetching,
  entityName,
  orderRules,
  changeOrder,
  changeFilter,
  resetFilters,
  setOnlyCategoriesWithReviews,
  submitReviews,
  isFormSubmitted,
  notifyProjectLead,
  changeCurrentModal,
  reviewsManagementData,
  reviewsFormFilterOptions,
  isOnlyCategoriesWithReviews,
  userGroup,
  date,
}) => {
  autoScrollToTop(location);

  const isCPS = getHasPermissions(userGroup, [CPS]);

  const {
    body,
    onSubmit,
    wrapperCssRules,
    controlsCssRules,
    singleRowCssRules,
    monthPickerCssRules,
    filterSelectCssRules,
    styledWrapperCssRules,
    loaderWrapperCssRules,
    controlsWrapperCssRules,
  } = reviewsManagementData;

  const formsController = useMemo(() => new RemoteFormsController(({ formsState, controllerBag }) => onSubmit({
    controllerBag,
    formsState,
    changeCurrentModal,
    submitReviews,
  })));
  const bodyMap = useMemo(
    () => body.reduce((acc, form) => ({ ...acc, [form.formId]: form }), {}),
    [body]
  );
  const [isRender, setIsRender] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [renderedBody, updateRenderedBody] = useState(body);

  useEffect(() => {
    setDate(moment());
  }, []);

  useEffect(() => {
    const currentRenderedBody = isOnlyCategoriesWithReviews ?
      body.filter(({ hasRequiredReviews }) => hasRequiredReviews) :
      body;

    updateRenderedBody(currentRenderedBody);
  }, [body, isOnlyCategoriesWithReviews]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (selectedProjects.length) {
      const updatedBody = selectedProjects.map(({ value }) => bodyMap[value]);

      return updateRenderedBody(updatedBody);
    }
  }, [body]);

  const setSelectedDate = (selectedDate) => {
    setSelectedProjects([]);
    setDate(selectedDate);
  };
  const onSelectProject = async (projects) => {
    setSelectedProjects(projects);
    if (!projects.length) {
      setIsRender(true);
      await timeout(300);
      return updateRenderedBody(body);
    }
    const updatedBody = projects.map(({ value }) => bodyMap[value]);

    return updateRenderedBody(updatedBody);
  };

  const remoteControl = {
    registerForm: formsController.registerForm,
    handleChanges: formsController.handleChanges,
    revalidateToastIds: formsController.revalidateToastIds,
  };
  const layoutProps = {
    changeOrder,
    orderRules,
    entityName,
    isFetching,
    resetFilters,
    changeFilter,
    remoteControl,
    wrapperCssRules,
  };
  const actions = {
    onSelectProject,
    notifyProjectLead,
    stopSpinner: () => setIsRender(true),
  };
  const toggleCheckbox = useCallback(() => {
    setOnlyCategoriesWithReviews(!isOnlyCategoriesWithReviews);
    setSelectedProjects([]);

    if (isOnlyCategoriesWithReviews) {
      ExpandingSectionStore.setGroupState({
        state: true,
        groupId: 'reviews',
      });
    }
  }, [isOnlyCategoriesWithReviews]);

  return (
    <>
      <ToastContainer containerId={entityName} uniqueKey={format(date).toString()} />
      <Controls
        controlsHeading="Delivery Staff Reviews"
        cssRules={controlsCssRules}
        wrapperCssRules={controlsWrapperCssRules}
      >
        <StyledWrapper cssRules={styledWrapperCssRules}>
          <Calendar
            isWeekPicker
            withStepControls
            onChange={setSelectedDate}
            cssRules={monthPickerCssRules}
            stepControlsConfig={{
              step: 1,
              unitName: 'week',
            }}
          >
            <WeekWithRange />
          </Calendar>
          {reviewsFormFilterOptions.length > 1 ? (
            <FilterSelect
              isMultiple
              withChips={false}
              selected={selectedProjects}
              placeholderLength="12rem"
              placeholder="All categories"
              loadingPlaceholder={isRender ? 'Filtering' : 'Loading'}
              onChange={onSelectProject}
              isFetchingData={isFetching || isRender}
              cssRules={filterSelectCssRules}
              items={reviewsFormFilterOptions}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
            />
          ) : (
            <div />
          )}
          {!isCPS && (
            <RemoteControllerButton
              isFormSubmitted={isFormSubmitted}
              getControlsState={formsController.getControlsState}
              registerControl={formsController.registerControl}
              configGetter={({
                isFormSubmitted: isSubmitted = false,
                hasFormChanges = false,
                defaultConfig,
                currentConfig,
              }) => {
                const defaultCssRules = defaultConfig.cssRules;
                const activeCssRules = `
                ${defaultCssRules}
                border-color: ${isSubmitted ? 'transparent' : colorPrimary};
                color: ${colorWhite};
                background-color: ${isSubmitted ? 'transparent' : colorPrimary};
                pointer-events: all;
                opacity: 1;
                ${
            isSubmitted ?
              `
                  cursor: initial;
                ` :
              `
                  &:hover {
                    border-color: ${colorPrimary};
                    color: ${colorWhite};
                    background: ${backgroundButtons};
                    box-shadow: ${shadowButtons};
                  }
                `
            }
              `;

                return {
                  ...currentConfig,
                  cssRules:
                    hasFormChanges || isSubmitted ?
                      activeCssRules :
                      defaultCssRules,
                  onClick:
                    hasFormChanges && !isSubmitted ?
                      formsController.submitForms :
                      () => null,
                };
              }}
              defaultConfig={{
                title: 'Save changes',
                cssRules: `
                min-width: 16.8rem;
                max-height: 3.2rem;
                margin-top: 0.6rem;
                padding: 0.7rem 2.4rem;
                border-color: ${colorSecondaryGrayLight};
                color: #fff;
                background-color: ${colorSecondaryGrayLight};
                opacity: 0.6;
                pointer-events: none;
              `,
              }}
            />
          )}
          {!isFormSubmitted && !isCPS && (
            <RemoteControllerButton
              isFormSubmitted={isFormSubmitted}
              getControlsState={formsController.getControlsState}
              registerControl={formsController.registerControl}
              configGetter={({
                hasFormChanges = false,
                defaultConfig,
                currentConfig,
              }) => {
                const defaultCssRules = defaultConfig.cssRules;
                const activeCssRules = `
                    ${defaultCssRules}
                    border-color: ${colorPrimary};
                    color: ${colorPrimary};
                    pointer-events: all;
                    opacity: 1;
                  `;
                return {
                  ...currentConfig,
                  cssRules: hasFormChanges ? activeCssRules : defaultCssRules,
                  onClick: hasFormChanges ?
                    formsController.resetForm :
                    () => null,
                };
              }}
              defaultConfig={{
                title: 'Cancel',
                cssRules: `
                    min-width: 16.8rem;
                    max-height: 3.2rem;
                    margin-top: 0.6rem;
                    margin-left: 1.4rem;
                    padding: 0.7rem 2.4rem;
                    border-color: ${colorSecondaryGrayLight};
                    color: ${colorSecondaryGrayLight};
                    background-color: transparent;
                    opacity: 0.6;
                    pointer-events: none;
                  `,
              }}
            />
          )}
        </StyledWrapper>
      </Controls>
      {isRender && (
        <StyledWrapper cssRules={loaderWrapperCssRules}>
          <Loader />
        </StyledWrapper>
      )}
      <StyledFilter isRender={isRender}>
        <MultiFormsWrapper
          withErrorBox
          body={renderedBody}
          layoutEffect={() => {
            setIsRender(false);
          }}
          additionalActions={actions}
          layoutTriggers={[renderedBody]}
          {...layoutProps}
        >
          {!(isFetching || selectedProjects.length) && (
            <StyledWrapper cssRules={singleRowCssRules}>
              <Checkbox
                title="Display only categories with reviews required"
                isActive={isOnlyCategoriesWithReviews}
                onClick={toggleCheckbox}
              />
              {Boolean(renderedBody.length) && (
                <GroupController
                  groupId="reviews"
                  className="employees_reviews__group_control"
                />
              )}
            </StyledWrapper>
          )}
        </MultiFormsWrapper>
      </StyledFilter>

      <GoTop />
    </>
  );
};

EmployeesReviews.propTypes = {
  location: PropTypes.object.isRequired,
  tab: PropTypes.string,
  reportType: PropTypes.string,
  isFormSubmitted: PropTypes.bool,
  userId: PropTypes.number.isRequired,
  changeOrder: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  orderRules: PropTypes.object.isRequired,
  changeFilter: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  setOnlyCategoriesWithReviews: PropTypes.func.isRequired,
  submitReviews: PropTypes.func.isRequired,
  notifyProjectLead: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  reviewsManagementData: PropTypes.object.isRequired,
  reviewsFormFilterOptions: PropTypes.array.isRequired,
  isOnlyCategoriesWithReviews: PropTypes.bool.isRequired,
  userGroup: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
};

EmployeesReviews.defaultProps = {
  tab: '',
  reportType: '',
  isFormSubmitted: false,
};

export default withRouter(EmployeesReviews);
