import React, { useState } from 'react';

import PropTypes from 'prop-types';

import className from 'classnames';
import Loader from 'components/loader';

import Textarea from 'components/textarea';
import ToastContainer from 'components/toast';
import Overlay from 'elements/modal-overlay';
import { withRouter } from 'react-router';

import './styles.scss';

const ConfirmActionModal = ({
  clearModal,
  isProcessed,
  params: {
    acceptActionName,
    rejectActionName,
    acceptActionTitle,
    rejectActionTitle,
    acceptActionArguments,
    rejectActionArguments,
    content = [],
    acceptFunction,
    getRemark,
    withRemarks,
  },
  ...rest
}) => {
  const [remark, updateRemark] = useState('');
  const onChange = ({ target }) => updateRemark(target.value);
  const onAccept = (...args) => {
    if (acceptActionName && rest[acceptActionName]) {
      const totalArgs = withRemarks ?
        getRemark({ args, remark }) :
        {
          ...args,
          ...acceptActionArguments,
        };
      return rest[acceptActionName](totalArgs);
    }
    if (acceptFunction !== undefined) {
      acceptFunction();
    }

    return clearModal();
  };

  const onReject = (...args) => {
    if (rejectActionName) {
      rest[rejectActionName]({ ...args, ...rejectActionArguments });
    }
    clearModal();
  };

  return (
    <Overlay clearModal={clearModal}>
      <ToastContainer containerId="confirmActionModal" />

      <div className="confirm-action-modal__wrapper">
        {content.map(({ data, type }, index) => (
          <p
            key={index} // eslint-disable-line react/no-array-index-key
            className={`confirm-action-modal__${type}`}
          >
            {data}
          </p>
        ))}

        {withRemarks && (
          <Textarea
            name="confirmActionModalRemark"
            label="Remark"
            inputValue={remark}
            onChange={onChange}
          />
        )}
      </div>

      <div className="confirm-action-modal__controls">
        <button
          type="button"
          className={className(
            'confirm-action-modal__button  ',
            'button',
            'button--accept',
            { 'button--disabled': isProcessed || (withRemarks && !remark) },
          )}
          onClick={onAccept}
        >
          <Loader isLoaded={!isProcessed} size="sm">{acceptActionTitle || 'Save'}</Loader>
        </button>

        <button
          type="button"
          className={className(
            'confirm-action-modal__button',
            'button',
            'button--cancel',
            { 'button--disabled': isProcessed }
          )}
          onClick={onReject}
        >
          {rejectActionTitle || 'Cancel'}
        </button>
      </div>
    </Overlay>
  );
};

ConfirmActionModal.propTypes = {
  isProcessed: PropTypes.bool,
  clearModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    actionName: PropTypes.string,
    actionTitle: PropTypes.string,
    actionArguments: PropTypes.shape({}),
    content: PropTypes.arrayOf(PropTypes.shape({
      data: PropTypes.string,
      type: PropTypes.string,
    })),
    acceptFunction: PropTypes.func,
    getRemark: PropTypes.func,
    withRemarks: PropTypes.bool,
    rejectActionName: PropTypes.string,
    rejectActionTitle: PropTypes.string,
    rejectActionArguments: PropTypes.shape({}),
    acceptActionName: PropTypes.string,
    acceptActionTitle: PropTypes.string,
    acceptActionArguments: PropTypes.shape({}),
  }).isRequired,
};

ConfirmActionModal.defaultProps = {
  isProcessed: false,
};

export default withRouter(ConfirmActionModal);
