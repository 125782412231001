import {
  FILTERS_TYPES,
} from 'core/constants';
import { get } from 'lodash';

import {
  checkIsInclude,
  checkIsSelected,
} from 'utils/helpers/filters';

export const filtersTemplates = [{
  name: 'salesCommission',
  storeKey: ['ptmFpReportRows', 'ttmReportRows', 'odcReportRows', 'overtimeReportRows'],
  filtersTemplate: [{
    type: FILTERS_TYPES.SEARCH,
    storeKey: 'projectName',
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'projectName', '');
      return checkIsInclude({ selected, value });
    },
    componentProps: {
      placeholder: 'Project',
    },
  },
  {
    type: FILTERS_TYPES.SEARCH,
    storeKey: 'taskOrder',
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'taskOrder', '');
      return checkIsInclude({ selected, value });
    },
    componentProps: {
      placeholder: 'TO',
    },
  },
  {
    type: FILTERS_TYPES.SEARCH,
    storeKey: 'resource',
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'resource', '');
      return checkIsInclude({ selected, value });
    },
    componentProps: {
      placeholder: 'Resource',
    },
  },
  {
    type: FILTERS_TYPES.SELECT,
    storeKey: 'seniority',
    valueGetter: ({
      seniority,
    }) => seniority,
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'seniority', '');
      return checkIsSelected({ selected, value });
    },
  },
  {
    type: 'empty',
  },
  ],
},
];
