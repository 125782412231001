import React, { useCallback, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';

import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';

import { recordIdName } from 'core/delivery/commentManagement/constants';
import {
  removeEmptyProperties,
} from 'forms/helpers';
import { newComment } from 'layouts/employee-details/components/resource-tabs/comments/constants';
import { get } from 'lodash';
import * as yup from 'yup';

import styles from './styles.module.scss';

const validationSchema = yup.object().shape({
  comment: yup.string().required('Required field'),
});

export const CommentForm = ({
  item,
  columns,
  onSubmitHandler,
  onRemoveRecord,
  setEditedRecordId,
  editedRecordId,
  onDeleteHandler,
}) => {
  const commentId = get(item, 'commentId');
  const newRecord = newComment({});
  const defaultValues = {
    comment: get(item, 'text', ''),
  };
  const isCreateMode = Boolean(commentId === newRecord[recordIdName]);
  const isDisabled = !isCreateMode && editedRecordId === newRecord[recordIdName];

  const {
    handleSubmit,
    control,
    formState: {
      isDirty,
      isValid,
    },
    reset,
  } = useForm({
    values: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback((formData) => {
    if (isDirty && isValid) {
      onSubmitHandler({
        formData: removeEmptyProperties(formData),
        commentId,
        isCreateMode,
      });
    }
  }, [
    isDirty,
    isValid,
    onSubmitHandler,
    commentId,
    isCreateMode,
  ]);

  const renderField = useCallback(({
    column,
  }) => {
    if (column.type === 'action') {
      return column.Field({
        onRemoveRecord,
        isFormDirty: isDirty,
        resetForm: reset,
        isCreateMode,
        recordId: commentId,
        setEditedRecordId,
        isAllowedForEdit: get(item, 'allowedToEdit', false),
        onDeleteHandler,
      });
    }

    if (column.type === 'text') {
      return column.Field({
        item,
      });
    }

    return (
      <Controller
        name={column.accessor}
        control={control}
        render={({ field, fieldState }) => column.Field({
          field,
          fieldState,
          item,
          isCreateMode,
          reset,
        })}
      />
    );
  }, [
    isDirty,
    isCreateMode,
    control,
    onDeleteHandler,
    item,
  ]);

  useEffect(() => {
    if (editedRecordId !== commentId) {
      reset(defaultValues);
    }
  }, [editedRecordId]);

  useEffect(() => {
    if (isDirty) {
      setEditedRecordId(commentId);
    }
  }, [isDirty]);

  return (
    <form
      key={commentId}
      onSubmit={handleSubmit(onSubmit)}
      className={classNames({
        [styles.disabledForm]: isDisabled,
      })}
    >
      {
        <ul
          className={classNames(styles.bodyStyles, {
            [styles.disabledList]: isDisabled,
          })}
        >
          {columns.map((column, columnIndex) => {
            const { accessor } = column;

            return (
              <li
                key={`${accessor}Body`}
                className={classNames(styles[column.accessor])}
              >
                {renderField({ column, columnIndex })}
              </li>
            );
          })}
        </ul>
      }
    </form>
  );
};

CommentForm.propTypes = {
  item: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  onRemoveRecord: PropTypes.func.isRequired,
  setEditedRecordId: PropTypes.func.isRequired,
  editedRecordId: PropTypes.number,
  onDeleteHandler: PropTypes.func.isRequired,
};

CommentForm.defaultProps = {
  editedRecordId: undefined,
};
