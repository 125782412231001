import { colorSecondaryText } from 'assets/styles/variables';
import { addThousandSeparator } from 'utils/helpers/numbers';

export const PTMColumns = (data, options) => {
  const { hours, totalValue } = data;
  const { isDTOReport } = options;

  const columns = [{
    type: 'text',
    componentProps: {
      data: totalValue ?
        `${addThousandSeparator(hours.toFixed(2))} h` :
        '0 h',
      cssRules: ` && {
        padding-right: 0;
        font-size: 1.8rem;
        line-height: 2.4rem;
        color: ${colorSecondaryText};
      }`,
    },
  }];

  if (isDTOReport) {
    columns.unshift({
      type: 'text',
      name: 'Dev Center',
    });
    columns.push({
      type: 'text',
      name: 'Discount/\nSurcharge',
    });
  }

  return [
    {
      type: 'text',
      name: 'seniority',
    },
    ...columns,
    {
      type: 'text',
      name: 'rate',
    },
  ];
};

export const ODCColumns = (data, options) => {
  const { isHRM, isDTOReport } = options;

  const columns = [
    {
      type: 'text',
      name: 'Seniority',
    },
    {
      type: 'text',
      name: 'Booking Period',
    },
  ];

  if (isDTOReport) {
    columns.unshift({
      type: 'text',
      name: 'Dev Center',
    });

    if (!isHRM) {
      columns.push({
        type: 'text',
        name: 'Discount/\nSurcharge',
      });
    }
  }

  return columns;
};

export const TTMColumns = (data, options) => {
  const { isDTOReport } = options;

  const columns = [
    {
      type: 'text',
      name: 'Seniority',
    },
    {
      type: 'text',
      name: 'Booking Period',
    },
    {
      type: 'text',
      name: 'Util.',
    },
    {
      type: 'text',
      name: 'OOO',
    },
    {
      type: 'text',
      name: 'Hrs in Period',
    },
  ];

  if (isDTOReport) {
    columns.unshift({
      type: 'text',
      name: 'devCenter',
    });
  }

  return columns.reduce((acc, column) => {
    const isUtilColumn = column.name === 'Util.';

    if (isDTOReport && isUtilColumn) {
      acc.push({
        type: 'text',
        name: 'Discount/\nSurcharge',
      });
    }

    if (!isDTOReport && isUtilColumn) {
      acc.push({
        type: 'text',
        name: 'Hrl Rate',
      });
    }

    acc.push(column);

    return acc;
  }, []);
};
