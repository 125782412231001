import {
  colorWhite,
  colorPrimary,
  shadowButtons,
  backgroundButtons,
} from 'assets/styles/variables';

export const loader = 'z-index: inherit';

export const tableWrapperCssRules =
  {
    position: 'relative',
  };

export const filtersWrapperCssRules = `
  display: flex;
`;

export const addButtonStyles = `&& {
  display: block;
  margin-left: 2.4rem;
  padding: .8rem 1.7rem;
  border: 1px solid ${colorPrimary};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  color: ${colorPrimary};
  background-color: initial;
  outline: none;
  cursor: pointer;
  &:active,
  &:hover {
    border-color: ${colorPrimary};
    color: ${colorWhite};
    background: ${backgroundButtons};
    box-shadow: ${shadowButtons};
}`;

export const controlsCssRules = `
  & .controls__main-controls {
    width: 100%;
  }
`;

export const calendarCssRules = `
  && {
    display: flex;
    min-width: unset;
    align-items: center;
    margin-right: 1.6rem;
    padding-top: 0;
    border: none;
    & path {
      fill: ${colorPrimary};
    }
  }
  && .calendar-date-picker__header-control-image {
    width: 1.9rem;
    height: 1.9rem;
  }
  && .calendar-date-picker__header-control--arrow {
    width: 1.9rem;
    height: 1.9rem;
  }
  && .calendar__date-wrapper {
    color: ${colorPrimary};
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

export const calendarItemCssRules = `
  padding-top: 0.3rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.7rem;
  color:  ${colorPrimary};
`;

export const filterSelectCssRules = `
&& {
  font-size: 1.4rem;
  width: unset;
  margin-right: 3.2rem;
  color: ${colorPrimary};
  .filter-select {
    width: auto;
    height: auto;
    &__multi-value {
      margin-top: 0;
    }
    &__multi-value__label {
      color: ${colorPrimary};
    }
    &__placeholder {
      padding-left: 0;
      color: ${colorPrimary};
    }
    &__control--is-focused {
      outline: none !important;
    }
    &__dropdown-indicator {
      path {
        fill: ${colorPrimary};
      }
    }
  }
}
`;

export const wrapperCssRules = `
  padding-bottom: 10rem;
  && .table {
    padding-bottom: 0rem;
  }
  & .table__content-wrapper {
    padding-bottom: 0;
  }
  && .row-wrapper {
    & > .grid-unit:first-child {
      padding-left: 1.6rem;
    }
    & > .grid-unit:last-child {
      padding-right: 1.6rem;
    }
  }

  && .details-table__title {
    font-size: 2.2rem;
    line-height: 2.6rem;
    padding-right: 1.6rem;
  }

  && .details-table__subtitle {
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding: 1.2rem 1.6rem;
  }
  && .total-bottom {
    min-height: 4rem;
    font-size: 1.6rem;
    border-bottom: 1px solid #bbbdc0;
    background-color: rgba(187, 189, 192, 0.15);
    color: #414042;
    display: grid;
    grid-template-columns: minmax(auto, 7fr) minmax(auto, 1fr);
    padding: 0px 1.8rem 0px 1.6rem;
    align-items: center;
    text-align: end;
    margin-top: 1rem;
  }
`;

export const formWrapperStyles = {
  // formWrapperStyles: `background-color: red;`,
  cssRules: '',
  formControlsCss: `
    display: flex;
    grid-column-gap: 4.5rem;

    & .button {
      width: 18rem;
      height: 3.2rem;
    }
  `,
};
