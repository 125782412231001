import React, { memo } from 'react';

import TextIcon from 'assets/icons/text.svg';
import WorkSpaceIcon from 'assets/icons/workspace.svg';

import styles from './illustrations.module.scss';

const Illustrations = memo(() => (
  <div className={styles.illustrations}>
    <div className={styles.item}>
      <img className={styles.image} src={WorkSpaceIcon} alt="Workspace" />
    </div>
    <div className={styles.item}>
      <div className={styles.line} />
    </div>
    <div className={styles.item}>
      <img className={styles.image} src={TextIcon} alt="Welcome" />
    </div>
  </div>
));

export default Illustrations;
