import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import FlipMove from 'react-flip-move';

import DetailsForm from 'components/details-form';
import FormHeaders from 'components/details-form/form-headers';
import InfoCard from 'components/info-card';

import './styles.scss';


const StyledRoot = styled.div`
  ${({ cssRules }) => css`${cssRules}`}
`;

const StyledWrapper = styled.div`
  ${({ cssRules }) => css`${cssRules}`}
`;

const StyledButton = styled.button`
  ${({ cssRules }) => css`${cssRules}`}
`;


const DataList = ({ title, noDataMessage, controls, additionalActions, isFormSubmitted, headersConfig, stylesTemplate: { cssRules: rootCssRules, listItemStylesTemplate = {} }, content }) => {
  const { formWrapperCssRules } = listItemStylesTemplate;

  return (
    <StyledRoot className="data-list" cssRules={rootCssRules}>

      <div className="data-list__form-title-controls-wrapper">

        {
          title && (
            <p className="data-list__title">
              {title}
            </p>
          )
        }

        {
          !controls.length && noDataMessage &&
          <p className="data-list__no-data-message">
            {noDataMessage}
          </p>
        }

        {
          controls.map(({ title: controlTitle, getIsHidden, cssRules, onClick, withIcon, icon }, controlIndex) => (
            (getIsHidden ? !getIsHidden() : true) && (
              <StyledButton
                key={controlIndex} // eslint-disable-line react/no-array-index-key
                className="button button--cancel data-list__button"
                type="button"
                onClick={() => onClick({ actions: additionalActions })}
                cssRules={cssRules}
              >
                {withIcon && icon}
                {controlTitle}
              </StyledButton>
            )
          ))
        }
      </div>

      <FormHeaders
        headersConfig={headersConfig}
        cssRules={formWrapperCssRules}
      />

      <FlipMove>
        {
          content.map(({ listData, recordId, permanentId, isInfoCard, ...rest }) => (
            <StyledWrapper
              key={recordId ? `${recordId}` : `${permanentId}`}
              className="data-list__form-wrapper"
            >
              {
                !isInfoCard &&
                <DetailsForm
                  buttonTitle="Save"
                  onSubmit={additionalActions.onSubmit}
                  isFormSubmitted={isFormSubmitted}
                  isFetchingDetails={false}
                  additionalActions={additionalActions}
                  content={listData}
                  formId={recordId}
                  {
                  ...rest
                  }
                />
              }

              {
                isInfoCard &&
                <InfoCard
                  data={listData}
                  actions={additionalActions}
                  {
                  ...rest
                  }
                />
              }

              <div className="data-list__controls-wrapper">

              </div>

            </StyledWrapper>

          ))
        }
      </FlipMove>

    </StyledRoot>
  );
};


DataList.propTypes = {
  noDataMessage: PropTypes.string,
  isFormSubmitted: PropTypes.bool.isRequired,
  controls: PropTypes.arrayOf(PropTypes.shape({})),
  content: PropTypes.arrayOf(PropTypes.shape({
    form: PropTypes.shape({
      formData: PropTypes.arrayOf(PropTypes.shape({})),
      initialValues: PropTypes.shape({}).isRequired,
      validationSchema: PropTypes.shape({}),
    }),
  })),
  additionalActions: PropTypes.shape({}),
  title: PropTypes.string,
  stylesTemplate: PropTypes.shape({
    listItemStylesTemplate: PropTypes.shape({}),
  }),
  headersConfig: PropTypes.shape({
    data: PropTypes.array,
    isHidden: PropTypes.bool,
  }),
};

DataList.defaultProps = {
  noDataMessage: '',
  controls: [],
  content: [],
  additionalActions: null,
  title: '',
  stylesTemplate: {
    listItemStylesTemplate: {},
  },
  headersConfig: {
    data: [],
  },
};


export default DataList;
