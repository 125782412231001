import React, { useEffect, useState, Fragment } from 'react';

import PropTypes from 'prop-types';

import Controls, { ExpandSection } from 'components/details-controls';
import DetailsForm from 'components/details-form';
import DetailsTable from 'components/details-table';
import NavigationTabs from 'components/details-tabs';
import DocumentsControls from 'components/documents-controls';
import { USERS_GROUPS } from 'core/auth/constants';
import Wrapper from 'elements/wrapper';
import { get } from 'lodash';
import { withRouter } from 'react-router';

import { getHasPermissions } from 'utils/auth';
import { updateState } from 'utils/helpers/history';
import { autoScrollToTop } from 'utils/react';

import { onSubmitUploadedFile } from './utils';

const tabIndexToPath = [
  'details',
  'rate-card',
  'documents',
  'assigned-client-categories',
  'staffing',
];

const pathToTabIndex = {
  details: 0,
  'rate-card': 1,
  documents: 2,
  'assigned-client-categories': 3,
  staffing: 4,
};

const { CEO, HRM, CSO, SALES, OFFICE_ADMIN, CPS } = USERS_GROUPS;

const TaskOrderDetails = ({
  userGroup,
  location,
  tab,
  entityName,
  uploadFile,
  projectId,
  orderRules,
  changeOrder,
  isNewTaskOrder,
  toggleFavorite,
  isFileUploaded,
  createTaskOrder,
  updateTaskOrder,
  isFetchingFiles,
  generateDocument,
  uploadingProgress,
  isFetchingDetails,
  taskOrdersDetails,
  isFavoredTaskOrder,
  changeCurrentModal,
  getTaskOrdersDetails,
  hasPermissionDocumentUpload,
  hasPermissionDocumentGenerate,
  isTaskOrderDetailsFormSubmitted,
  hasPermissionTaskOrderDetailsHeadingActions,
}) => {
  autoScrollToTop(location);
  const hasPermission = (deactivated) => {
    const isCEO =
      getHasPermissions(userGroup, [CEO]) ||
      getHasPermissions(userGroup, [HRM]);
    const isCSO = getHasPermissions(userGroup, [CSO]);
    const isOFFICEADMIN = getHasPermissions(userGroup, [OFFICE_ADMIN]);
    const isUserSales = getHasPermissions(userGroup, [CSO, SALES]);
    if (isCEO) {
      return true;
    }
    if (!deactivated && (isCSO || isUserSales || isOFFICEADMIN)) {
      return true;
    }
    return false;
  };
  const {
    isPTM,
    status,
    tabData,
    files = [],
    pocMsaName,
    tabHeadings,
    pocMsaEmail,
    effectiveDate,
    taskOrderToCopyId,
    tabStylesTemplates,
  } = taskOrdersDetails;
  const initialTab = get(pathToTabIndex, tab, 0);
  const [indexOfActiveTab, setIndexOfActiveTab] = useState(initialTab);
  const {
    title,
    isExpired,
    actionTitle,
    taskOrderId,
    notYetActive,
    onStatusChange,
    sectionContent,
    additionalContent,
    isManuallyDeactivated,
  } = status;
  const numberOfFiles = files.length;
  const onSubmit = ({ fields, initialValues }) => {
    if (isNewTaskOrder) {
      const { clientId, ...rest } = fields;

      return createTaskOrder({
        clientId,
        taskOrderToCopyId,
        fields: rest,
        initialValues,
      });
    }
    const ratecardId = fields.ratecardId;
    if (ratecardId && Number(ratecardId)) {
      return changeCurrentModal({
        currentModal: 'CONFIRM_ACTION_MODAL',
        params: {
          acceptActionName: 'updateTaskOrder',
          acceptActionTitle: 'Change',
          acceptActionArguments: { projectId, fields, initialValues },
          content: [
            {
              type: 'title',
              data: 'Do you want to change the Rate Card?',
            },
            {
              type: 'description',
              data: 'Changing of this parameter will reset all changes of the current Customized Project Rate Card to the default version.',
            },
          ],
          acceptFunction: () => updateTaskOrder({ projectId, fields, initialValues }),
        },
      });
    }

    const employees = fields.employees;
    if (employees) {
      fields.employees.map((item) => {
        const employee = item;
        const employeeKeys = Object.keys(employee);
        for (let i = 0; i < employeeKeys.length; i++) {
          const employeeKey = employeeKeys[i];
          const employeeKeyValue = employee[employeeKey];
          employee[employeeKey] =
            employeeKeyValue === '' ? null : employeeKeyValue;
        }
        return employee;
      });
    }

    return updateTaskOrder({ projectId, fields, initialValues });
  };
  const onGenerate = ({ title: uploadedFileTitle }) => generateDocument({
    projectId,
    title: uploadedFileTitle,
    effectiveDate,
  });
  const onSubmitFile = ({ ...restFileData }) => onSubmitUploadedFile({
    ...restFileData,
    effectiveDate,
    entityName,
    entityId: projectId,
    onSubmit: uploadFile,
  });
  const buttonTitle = projectId ? 'Save changes' : 'Create';

  useEffect(() => {
    getTaskOrdersDetails({ projectId, withReset: true });
  }, []);

  const setTabIndex = (index) => {
    if (!isFetchingDetails) {
      updateState({}, tabIndexToPath[index]);
      setIndexOfActiveTab(index);
    }
  };

  const changeStatus = () => {
    onStatusChange({ onSubmit });
  };

  const unitActions = {
    changeCurrentModal: ({ currentModal, ...rest }) => changeCurrentModal({
      currentModal,
      params: {
        fileType: 'TO Document',
        pocMsaName,
        pocMsaEmail,
        entityName,
        ...rest,
      },
    }),
  };
  return (
    <>
      <Controls
        toggleFavorite={() => toggleFavorite({ id: projectId })}
        isCreatMode={isNewTaskOrder}
        isFavored={isFavoredTaskOrder}
        isFetchingDetails={isFetchingDetails}
        controlsHeading={
          isNewTaskOrder ? 'Create Task Order' : `TO ${taskOrderId}`
        }
        goBackLinkPath="/task-orders"
        goBackLinkText="back to task orders list"
      >
        <ExpandSection
          hasWarning={notYetActive}
          dismissed={isExpired || isManuallyDeactivated}
          title={title}
          actionTitle={actionTitle}
          isButtonDisabled={!hasPermissionTaskOrderDetailsHeadingActions}
          onClick={changeStatus}
        >
          {sectionContent && (
            <p className="client-details-controls__expand-section-title">
              {sectionContent}
            </p>
          )}

          {additionalContent && (
            <p className="client-details-controls__expand-section-title">
              {additionalContent}
            </p>
          )}
        </ExpandSection>

        {isPTM && !notYetActive && !isExpired && !isManuallyDeactivated && (
          <ExpandSection title="OPEN PTM" />
        )}
      </Controls>

      <NavigationTabs
        isCreatMode={isNewTaskOrder}
        indexOfActiveTab={indexOfActiveTab}
        setIndexOfActiveTab={setTabIndex}
        tabHeadings={tabHeadings}
        currentStep={0}
      />

      {tabData.map((body, index) => {
        const isVisible = index === indexOfActiveTab;
        const tabStylesTemplate = tabStylesTemplates[index];

        return (
          <Wrapper
            key={index} // eslint-disable-line react/no-array-index-key
            isGrow
            isVisible={isVisible}
            cssRules={tabStylesTemplate.wrapperCssRules}
          >
            {body.map(({ type, withUploading, withSwitcher, ...rest }, bodyIndex) => (
              <Fragment
                key={bodyIndex} // eslint-disable-line react/no-array-index-key
              >
                {' '}
                {type === 'form' && (
                  <DetailsForm
                    buttonTitle={buttonTitle}
                    onSubmit={onSubmit}
                    isFormSubmitted={isTaskOrderDetailsFormSubmitted}
                    isFetchingDetails={isFetchingDetails}
                    orderRules={orderRules}
                    changeOrder={changeOrder}
                    entityName={entityName}
                    withErrorBox
                    hasPermission={hasPermission(isManuallyDeactivated)}
                    withoutFormControls={getHasPermissions(userGroup, [CPS])}
                    defaultControlsHidden={getHasPermissions(userGroup, [
                      CPS,
                    ])}
                    {...rest}
                  />
                )}
                {type === 'table' && (
                  <DetailsTable
                    orderRules={orderRules}
                    changeOrder={changeOrder}
                    unitActions={unitActions}
                    entityName={entityName}
                    isFetchingDetails={isFetchingDetails}
                    {...rest}
                  />
                )}
                {withUploading && (
                  <DocumentsControls
                    withDocumentGenerate={hasPermissionDocumentGenerate}
                    withDocumentUpload={hasPermissionDocumentUpload}
                    keyPrefix={numberOfFiles}
                    isFetchingFiles={isFetchingFiles}
                    onGenerate={onGenerate}
                    onSubmitUploadedFile={onSubmitFile}
                    isFileUploaded={isFileUploaded}
                    uploadingProgress={uploadingProgress}
                    {...rest}
                  />
                )}
              </Fragment>
            ))}
          </Wrapper>
        );
      })}
    </>
  );
};

TaskOrderDetails.propTypes = {
  location: PropTypes.object.isRequired,
  isFetchingFiles: PropTypes.bool.isRequired,
  tab: PropTypes.string,
  entityName: PropTypes.string.isRequired,
  updateTaskOrder: PropTypes.func.isRequired,
  isTaskOrderDetailsFormSubmitted: PropTypes.bool.isRequired,
  createTaskOrder: PropTypes.func.isRequired,
  isFavoredTaskOrder: PropTypes.bool.isRequired,
  getTaskOrdersDetails: PropTypes.func.isRequired,
  isFetchingDetails: PropTypes.bool.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  isNewTaskOrder: PropTypes.bool,
  projectId: PropTypes.string,
  taskOrdersDetails: PropTypes.shape({
    tabHeadings: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
    })),
  }).isRequired,
  hasPermissionDocumentGenerate: PropTypes.bool,
  hasPermissionDocumentUpload: PropTypes.bool,
  orderRules: PropTypes.shape({}).isRequired,
  changeOrder: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  isFileUploaded: PropTypes.bool.isRequired,
  uploadingProgress: PropTypes.number.isRequired,
  uploadFile: PropTypes.func.isRequired,
  generateDocument: PropTypes.func.isRequired,
  hasPermissionTaskOrderDetailsHeadingActions: PropTypes.bool.isRequired,
  userGroup: PropTypes.bool,
};

TaskOrderDetails.defaultProps = {
  userGroup: '',
  projectId: '',
  tab: 'details',
  isNewTaskOrder: false,
  hasPermissionDocumentGenerate: false,
  hasPermissionDocumentUpload: false,
};

export default withRouter(TaskOrderDetails);
