export const getActions = ({
  invitationalInProgress,
  isActive,
  isDeactivated,
  actionsList,
}) => {
  switch (true) {
    case isDeactivated:
      return [{
        text: 'Reactivate',
        action: actionsList.onActivate,
      }];

    case isActive:
      return [{
        text: 'Deactivate',
        action: actionsList.onDeactivate,
      }];

    case !invitationalInProgress:
      return [{
        text: 'Send Invite',
        action: actionsList.onInvite,
        hasLoader: true,
      },
      {
        text: 'Delete',
        action: actionsList.onDelete,
      }];

    case invitationalInProgress && !isActive:
      return [{
        text: 'Resend Invite',
        action: actionsList.onInvite,
        hasLoader: true,
      }, {
        text: 'Delete',
        action: actionsList.onDelete,
      }];
    default:
      return [];
  }
};
