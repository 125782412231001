export const ENTITY_NAME = 'BILLING_REPORTS';

export const REPORTS_TYPES = {
  DTO: 'DTO',
  TO: 'TO',
  GM: 'GM',
  SALES_REPORT: 'sales-reports',
  ANNUAL_REPORT: 'annual-reports',
  PL_COMMISSION: 'project-lead-commission',
};

export const PROJECTS_TYPES = {
  TTM: 'TTM',
  PTM: 'PTM',
  ODC: 'ODC',
  FP: 'FP',
  OVERTIME: 'OVERTIME',
};

export const BILLING_PROJECT_TABLE_NAMES = {
  CLIENT_CATEGORIES: 'clientCategories',
};

export const DEFAULT_ORDER = {
  PTM_TABLE: 'Client',
  PROMO_TABLE: 'Project this month',
  TTM_TABLE: 'Client',
  ODC_TABLE: 'Client',
  OVERTIME_TABLE: 'Client',
  NON_BILLABLE_ACTIVITIES_TABLE: 'Project this month',
  DETAILED_WORKLOG_TABLE: 'Date',
  CLIENT_CATEGORIES: 'Client Category Key',
  PROJECT_ROWS: 'Full name',
  SALES_COMMISSION_TABLE: 'Project this month',
  PLC_TABLE: 'Project',
  ANNUAL_REPORT_STAFF_TABLE: 'Resource',
  INTERNAL_TO_REPORT: 'Project Name',
};

export const PROJECT_DEFAULT_ORDER = {
  PTM_TABLE: 'Full name',
  TTM_TABLE: 'Full name',
  ODC_TABLE: 'Full name',
  OVERTIME_TABLE: 'Full name',
};

export const AVERAGE_EXCHANGE_POSTFIX = 'ExchangeRate';

export const ANNUAL_REPORT_SUMMARY_COUNT_PREFIX = 'countOf';

export const ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX = 'averageFor';

export const ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX = 'totalFor';

export const EXCHANGE_POSTFIX = 'Exchange';

export const SENIORITY = {
  JUNIOR: 'J',
  JUNIOR_FULL: 'Junior',
  STAFF: 'St',
  STAFF_FULL: 'Staff',
  SENIOR: 'S',
  SENIOR_FULL: 'Senior',
};
export const SENIORITY_SHORT = {
  JUNIOR: SENIORITY.JUNIOR,
  STAFF: SENIORITY.STAFF,
  SENIOR: SENIORITY.SENIOR,
};
export const SENIORITY_FULL = {
  JUNIOR: SENIORITY.JUNIOR_FULL,
  STAFF: SENIORITY.STAFF_FULL,
  SENIOR: SENIORITY.SENIOR_FULL,
};
export const SENIORITY_LEVELS = {
  0: 0,
  1: 1,
  2: 2,
};

export const STORE_KEYS = {
  USD: 'usd',
  RUR: 'rur',
  PLN: 'pln',
  STAFF: 'staff',
  MONTH: 'month',
  DEV_STAFF: 'dev',
  ADMIN_STAFF: 'admin',
  CURRENCY: 'currency',
  SENIORITY: 'seniority',
  PROJECT_NAME: 'projectName',
  DEVCENTER: 'devcenter',
  CUSTOMER: 'customer',
  HIQO_TO: 'internalTo',
  HIQO_CONTRACT: 'internalContract',
  EFFECTIVE_DATE: 'effectiveDate',
  PROJECT_MODEL: 'projectModel',
  IN_DEPTH_STUDY: 'inDepthStudy',
  DEVSTAFF_NAME: 'devstaffName',
  HAS_SALARY_RISE: 'hasSalaryRise',
  ANNUAL_REPORT_STAFF_FILTER: 'annualReportStaffFilter',
  ANNUAL_REPORT_COMMON_FILTER: 'annualReportCommonFilter',
  ANNUAL_REPORT_SUMMARY_FILTER: 'annualReportSummaryFilter',
  ANNUAL_REPORT_ANALYSIS_FILTER: 'annualReportAnalysisFilter',
  CURRENT_PAYROLL_REPORT_ANALYSIS_FILTER: 'currentPayrollReportCommonFilter',
};
export const STORE_CURRENCY_KEYS = {
  USD: STORE_KEYS.USD,
  RUR: STORE_KEYS.RUR,
  PLN: STORE_KEYS.PLN,
};
