import gql from 'graphql-tag';

export const queryConfig = {
  getDashboardData: ({
    hasAccessToWBDataOnDashboard,
    hasAccessToPTODataOnDashboard,
    hasAccessToTaskOrderTableOnDashboard,
  }) => {
    const taskOrderQuery = `
      closeToExpireEmployees {
        employeeId
        projectId
        taskOrderId
        projectTitle
        projectEndDate
        fullname
        seniority
        projectEndDate
        staffingEndDate
        clientAccountManagerId
        clientName
        clientId
      }`;
    const additionalDataQuery = `
      titlesFromDevstaffCompensation {
        value: title
        label: title
      }
      users {
        userId
        fullName
      }
      devstaffv2List {
        fullname
        devstaffId
        devcenterShortname
      }`;
    const workBookDataQuery = `
      dashboardWorkBookRecords {
        recordId
        devstaffId
        validFrom
        devcenter
        title
        seniority
        seniorityInt
        plLevel
        hrsDay
        probation
        endOfProbation
        salUsd
        salRur
        salPln
        approvedCeo
        approvedDd
        approvedHrm
        rejectedCeo
        rejectedDd
        approvedDdDate
        approvedCeoDate
        rejectedDdDate
        rejectedCeoDate
        remarks
        templateOwnerId
        isRelocated
        employeeDetails {
          name
          fullname
          dateHired
          dateLast
          internshipStartDate
          onboardingHistory {
            id
            historyId
            devstaffId
            dateStart
            endDate
            isDismiss
            isRehire
            onLeave
            hasPassed
          }
        }
      }`;
    const ptoDataQuery = `
      dashboardPtoRequestRecords {
        recordId
        devstaffId
        requestDate
        description
        fromDate
        throughDate
        hrs
        workingDays
        isUnpaid
        remark
        isApproved
        isRejected
        approvedDate
        rejectedDate
        isPlApprovalInProgress
        isCancelled
        templateOwnerId
        lastModifiedDate
        plApprovals {
         internalCategoryKey
         internalCategoryName
         plName
         isApproved
         plApprovalId
        }
      }`;

    const devcentersAll = `
      devcentersAll {
        devcenterId
        name
        shortname
        active
      }
    `;

    const resourceSeniorityUpdate = `
        resourceSeniorityUpdate {
          devstaffId
          fullname
          seniority
          seniorityInt
          validFrom
          affectedTo
          {
              projectId
              taskOrderId
              seniority
          }
        }
    `;

    return gql`{
      ${additionalDataQuery}
      ${hasAccessToTaskOrderTableOnDashboard ? taskOrderQuery : ''}
      ${hasAccessToWBDataOnDashboard ? workBookDataQuery : ''}
      ${hasAccessToPTODataOnDashboard ? ptoDataQuery : ''}
      ${resourceSeniorityUpdate}
      ${devcentersAll}
    }`;
  },

  deleteWorkbookRecord: ({
    devstaffId,
    recordId,
  }) => gql`mutation {
    deleteWorkBookRecord(devstaffId: ${devstaffId}, recordId: ${recordId})
    {
      ok
    }
  }`,
};
