import React, { Fragment, useCallback } from 'react';

import PropTypes from 'prop-types';

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as ReachTabs,
  TabsOrientation,
} from '@reach/tabs';
import cx from 'classnames';
import '@reach/tabs/styles.css';
import { EmptyTabItem } from 'components/tabs/components/empty-tab-item';

import './styles.scss';

export const Tabs = ({
  items,
  vertical,
  keyboardActivation,
  ...rest
}) => {
  const renderTabItem = useCallback(
    (item, { selected }) => (
      <Fragment key={item.id}>
        <Tab data-id={`${item.id}-tab`} className={cx('tab', { 'tab--selected': selected })}>
          <div className={cx('tab__title', { 'tab__title--selected': selected })} data-text={item.title}>
            <span>{item.title}</span>
            {item.withCounter && <span className="tab__counter">{item.counter}</span>}
          </div>
        </Tab>
        {vertical && selected && !!item.length && (
          <ul>
            {item.map(({ title, id, ...restParam }) => (
              <li key={id}>
                <a {...restParam}>
                  {title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </Fragment>
    ),
    [vertical, items],
  );

  const renderTabPanel = useCallback((selectedIndex) => items.map((item, index) => {
    const { panel: Component, id, ...itemData } = item;
    return (
      <TabPanel className="tab__panel" key={id} data-id={`${id}-panel`}>
        {selectedIndex === index && <Component {...itemData} id={id} />}
      </TabPanel>
    );
  }), [items]);

  return (
    <ReachTabs
      orientation={vertical ? TabsOrientation.Vertical : TabsOrientation.Horizontal}
      keyboardActivation={keyboardActivation}
      className="tabs"
      {...rest}
    >
      {({ focusedIndex, selectedIndex }) => (
        <>
          <TabList className="tabs__list">
            {items.map((item, index) =>
              renderTabItem(item, { selected: selectedIndex === index, focused: focusedIndex === index }))}
          </TabList>
          <TabPanels className="tab__panels" as="div">
            {renderTabPanel(selectedIndex)}
          </TabPanels>
        </>
      )}
    </ReachTabs>
  );
};

Tabs.defaultProps = {
  keyboardActivation: 'manual',
  vertical: false,
  items: [{ title: 'Tab 1', id: 'tab-1', tabData: [], panel: EmptyTabItem }],
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  vertical: PropTypes.bool,
  keyboardActivation: PropTypes.oneOf(['auto', 'manual']),
};

