import { emailRegex } from 'utils/email';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  records: yup.array().of(yup.object().shape({
    billingAgent: yup.number(),
    clientId: yup.number(),
    country: yup.string(),
    emails: yup.string().test('Emails Validation', '', (data) => {
      const emails = data.split(',');

      return emails.every((email) => emailRegex.test(email.trim()));
    }).test('Check Duplicates', '', (data) => {
      const emails = data.split(',').map((email) => email.trim());

      return emails.length === new Set(emails).size;
    }).required(),
    emailed: yup.boolean().nullable(),
    id: yup.string(),
    name: yup.string(),
    notification: yup.number(),
    processed: yup.boolean().nullable(),
    emailTemplateId: yup.number().nullable(),
    salutation: yup.string(),
    status: yup.string().nullable(),
  })),
});
