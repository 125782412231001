export const ENTITY_NAME = 'TASK_ORDERS';

export const STORE_KEYS = {
  TITLE: 'title',
  STATUS: 'status',
  COUNTRY: 'country',
  END_DATE: 'endDate',
  IS_FAVORED: 'isFavored',
  PROJECT_ID: 'projectId',
  CLIENT_NAME: 'clientName',
  CATEGORIES_KEYS: 'categoryKeys',
  PROJECT_TYPE: 'projectType',
  BILLING_AGENT: 'billingAgent',
  IS_EXPIRE_SOON: 'isExpireSoon',
  EFFECTIVE_DATE: 'effectiveDate',
  IS_RATES_OBSOLETE: 'isRatesObsolete',
};

export const COLUMN_TYPES = {
  LINK: 'link',
  ACTION: 'action',
  BOOLEAN: 'boolean',
  TEXT: 'text',
  STATUS: 'status',
  COUNTER: 'counter',
  SELECT: 'select',
};

export const TASK_ORDER_STATUSES = {
  IS_ACTIVE: 'Active',
  IS_EXPIRED: 'Inactive',
  IS_NOT_YET_ACTIVE: 'Not yet active',
  IS_SIGNED: 'Signed',
  IS_DEACTIVATED: 'Deactivated',
};

export const FILTERS_TYPES = {
  ALPHABETICAL: 'startFrom',
  TOGGLE: 'toggle',
  SEARCH: 'search',
  SELECT: 'select',
  DATE: 'date',
};
