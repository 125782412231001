import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import {
  deliveryReviewsReportAccess,
  deliveryReviewsManagementAccess,
} from 'core/auth/guaranteedAccessRoles';
import { selectUserId } from 'core/auth/selectors';
import { commonActions } from 'core/common/actions';
import { employeesReviewsActions } from 'core/employees-reviews/actions';
import reducer from 'core/employees-reviews/reducer';
import saga from 'core/employees-reviews/sagas';
import {
  selectFilters,
  selectIsFetching,
  selectEntityName,
  selectOrderRules,
  selectGroupedReviews,
  selectIsFormSubmitted,
  selectIsFiltersActive,
  selectSelectedEmployee,
  selectIsFetchingReviews,
  selectedReviewsReportData,
  selectEmployeesListForReport,
  selectedReviewsManagementData,
  selectReviewsReportsCommonData,
  selectReviewsFormFilterOptions,
  selectIsFetchingCommonReviewsData,
  selectIsOnlyCategoriesWithReviews,
  selectSelectedDate,
} from 'core/employees-reviews/selectors';
import { filesActions } from 'core/files/actions';
import { useCheckPermissions } from 'hooks';
import EmployeesReviewsManagement from 'layouts/employees-reviews-management';
import EmployeesReviewsReport from 'layouts/employees-reviews-report';
import compose from 'lodash/fp/compose';
import { Switch, Route, Redirect } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { injectReducer, injectSaga } from 'utils/helpers/injectors';

const Reviews = (props) => {
  const {
    entityName,
    setEntityName,
    getReviewsCommonData,
    ...restProps
  } = props;

  const { isAuthorizedToRoute } = useCheckPermissions(restProps);

  useEffect(() => {
    setEntityName(entityName);
  }, [entityName]);

  useEffect(() => {
    isAuthorizedToRoute && getReviewsCommonData();
  }, [isAuthorizedToRoute]);

  return (
    <Switch>
      <Route
        path={`/${deliveryReviewsManagementAccess.route}`}
        render={() => isAuthorizedToRoute ? (
          <EmployeesReviewsManagement {...props} />
        ) : null}
      />
      <Route
        path={`/${deliveryReviewsReportAccess.route}/:employeeId?`}
        render={({
          match: {
            params: { employeeId },
          },
        }) => isAuthorizedToRoute ? (
          <EmployeesReviewsReport
            employeeId={employeeId}
            title="Staff Reviews Report"
            {...props}
          />
        ) : null}
      />

      <Redirect to="/home" />
    </Switch>
  );
};

Reviews.propTypes = {
  userGroup: PropTypes.string,
  entityName: PropTypes.string.isRequired,
  setEntityName: PropTypes.func.isRequired,
  getReviewsCommonData: PropTypes.func.isRequired,
  guaranteedAccessRolesForSubRoutes: PropTypes.shape(),
  guaranteedAccessRoles: PropTypes.arrayOf(PropTypes.string),
};

Reviews.defaultProps = {
  userGroup: '',
  guaranteedAccessRoles: [],
  guaranteedAccessRolesForSubRoutes: null,
};

const mapStateToProps = createStructuredSelector({
  userId: selectUserId,
  filters: selectFilters,
  isFetching: selectIsFetching,
  entityName: selectEntityName,
  orderRules: selectOrderRules,
  date: selectSelectedDate,
  groupedReviews: selectGroupedReviews,
  isFilterActive: selectIsFiltersActive,
  isFormSubmitted: selectIsFormSubmitted,
  selectedEmployee: selectSelectedEmployee,
  isFetchingReviews: selectIsFetchingReviews,
  reviewsReportData: selectedReviewsReportData,
  employeesListForReport: selectEmployeesListForReport,
  reviewsManagementData: selectedReviewsManagementData,
  reviewsReportsCommonData: selectReviewsReportsCommonData,
  reviewsFormFilterOptions: selectReviewsFormFilterOptions,
  isFetchingCommonReviewsData: selectIsFetchingCommonReviewsData,
  isOnlyCategoriesWithReviews: selectIsOnlyCategoriesWithReviews,
});
const mapDispatchToProps = {
  setOnlyCategoriesWithReviews: employeesReviewsActions.setOnlyCategoriesWithReviews,
  setDate: employeesReviewsActions.setDate,
  setEntityName: commonActions.setEntityName,
  getReviews: employeesReviewsActions.getReviews,
  changeFilter: employeesReviewsActions.changeFilter,
  submitReviews: employeesReviewsActions.submitReviews,
  getPdfReviews: employeesReviewsActions.getPdfReviews,
  getReconciliationReport: filesActions.generateReconciliationReport,
  selectEmployee: employeesReviewsActions.selectEmployee,
  selectProjects: employeesReviewsActions.selectProjects,
  resetReviewReport: employeesReviewsActions.resetReviewReport,
  notifyProjectLead: employeesReviewsActions.notifyProjectLead,
  updateColumnsState: employeesReviewsActions.updateColumnsState,
  getReviewsCommonData: employeesReviewsActions.getReviewsCommonData,
  resetFilters: employeesReviewsActions.resetFilters,
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'employeesReviews', reducer });
const withSaga = injectSaga({ key: 'employeesReviews', saga });

export default compose(withReducer, withSaga, withConnect)(Reviews);
