import {
  colorPrimary,
  colorBackgroundDark,
  colorSecondaryBackground,
} from 'assets/styles/variables';

export const filtersWrapperCssRules = `
  display: flex;
`;

export const controlsCssRules = `
  &&& {
    display: grid;
    padding-top: 0.8rem;
    padding-bottom: 1.6rem;
    font-size: 1.5rem;
    grid-template-columns: 1fr minmax(auto,15.2rem);
    grid-column-gap: 2.4rem;
    grid-row-gap: 0.8rem;
    grid-auto-rows: minmax(3.2rem,auto);
  }
`;

export const calendarCssRules = `
  && {
    display: flex;
    min-width: unset;
    align-items: center;
    margin-right: 1.6rem;
    padding-top: 0;
    border: none;
    & path {
      fill: ${colorPrimary};
    }
  }
  && .calendar-date-picker__header-control-image {
    width: 1.9rem;
    height: 1.9rem;
  }
  && .calendar-date-picker__header-control--arrow {
    width: 1.9rem;
    height: 1.9rem;
  }
  && .calendar__date-wrapper {
    color: ${colorPrimary};
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

export const controlsWrapperCssRules = `
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const calendarItemCssRules = `
  padding-top: 0.3rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.7rem;
  color:  ${colorPrimary};
`;

export const filterSelectCssRules = `
&& {
  width: unset;
  margin-right: 3.2rem;
  color: ${colorPrimary};
  .filter-select {
    width: auto;
    height: auto;
    &__multi-value {
      margin-top: 0;
    }
    &__multi-value__label {
      color: ${colorPrimary};
    }
    &__placeholder {
      padding-left: 0;
      color: ${colorPrimary};
    }
    &__control--is-focused {
      outline: none !important;
    }
    &__dropdown-indicator {
      path {
        fill: ${colorPrimary};
      }
    }
  }
}
`;

export const wrapperCssRules = `
  padding-bottom: 10rem;
  && .table {
    padding-bottom: 0rem;
  }
  && .row-wrapper {
    & > .grid-unit:first-child {
      padding-left: 1.6rem;
    }
  }

  && .details-table__title {
    font-size: 2.2rem;
    line-height: 2.6rem;
    padding-right: 1.6rem;
  }

  && .details-table__subtitle {
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding: 1.2rem 1.6rem;
  }
  && .total-bottom {
    min-height: 4rem;
    font-size: 1.6rem;
    border-bottom: 1px solid #bbbdc0;
    background-color: rgba(187, 189, 192, 0.15);
    color: #414042;
    display: grid;
    grid-template-columns: minmax(auto, 7fr) minmax(auto, 1fr);
    padding: 0px 1.8rem 0px 1.6rem;
    align-items: center;
    margin-top: 1rem;
    text-align: right;
  }
`;

const getFiltersStyles = ({
  columnWidthMap,
  columns,
}) => {
  const columnToWidth = (column) => columnWidthMap[column] || 'auto';

  return {
    clearButtonCssRules: `&& {
      grid-column: 3;
      margin-top: 0.8rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: ${colorPrimary};
      white-space: nowrap;
      text-align: right;
    }`,
    wrapperCssRules: `
      padding-top: 0.6rem;
      background-color: transparent;
    `,
    gridTemplateConfig: `
      margin-bottom: 1rem;
      grid-template-columns: ${columns.map(columnToWidth).join(' ')};

      && {
        .grid-unit--filter {
          background-color: ${colorSecondaryBackground};
        }
        .grid-unit--active {
          background-color: ${colorBackgroundDark};
        }
      }
    `,
  };
};

export const resourceRowsCssRules = getFiltersStyles({
  columns: ['logDate', 'projectName', 'taskOrder', 'projectType', 'billType', 'hours'],
  columnWidthMap: {
    logDate: 'minmax(auto, 100fr)',
    projectName: 'minmax(auto, 200fr)',
    taskOrder: 'minmax(auto, 100fr)',
    projectType: 'minmax(auto, 100fr)',
    billType: 'minmax(auto, 100fr)',
    hours: 'minmax(auto, 100fr)',
  },
});

export const filterColumnCssRules = getFiltersStyles({
  columns: ['resources', 'taskOrder', 'projectType', 'billType', 'empty'],
  columnWidthMap: {
    resources: 'minmax(auto, 300fr)',
    taskOrder: 'minmax(auto, 100fr)',
    projectType: 'minmax(auto, 100fr)',
    billType: 'minmax(auto, 100fr)',
    empty: 'minmax(auto, 100fr)',
  },
});

export const summaryRowsCssRules = getFiltersStyles({
  columns: ['name', 'description', 'hours', 'percentOfLogged', 'percentOfMonth'],
  columnWidthMap: {
    name: 'minmax(auto, 100fr)',
    description: 'minmax(auto, 200fr)',
    hours: 'minmax(auto, 100fr)',
    percentOfLogged: 'minmax(auto, 100fr)',
    percentOfMonth: 'minmax(auto, 100fr)',
  },
});

export const tableCssRules = (isExpanded) => `
  && .table {
    padding-bottom: 3.2rem;
  }
  && .row-wrapper {

  }
  .expansion-summary__root {
    min-height: 4.8rem;
    font-size: 1.6rem;
    border-top: 1px solid rgb(187, 189, 192);
    background-color: rgba(187, 189, 192, 0.15);
    color: rgb(65, 64, 66);
    display: grid;
    grid-template-columns: repeat(2,minmax(auto,1fr));
    padding: 0px 1.8rem 0px 1.6rem;
    align-items: center;
    text-align: end;
    margin-top: ${isExpanded ? '1rem' : '0rem'};
  }
`;
