import update from 'immutability-helper';
import moment from 'moment';
import {
  createReducer,
} from 'redux-create-reducer';

import {
  deliveryCenterUtilizationByMonthActionsTypes,
} from './actions';

import {
  ENTITY_NAME,
  STORE_KEYS,
} from './constants';

const initialState = {
  errors: {},
  entityName: ENTITY_NAME,
  isFetching: false,
  resourceRows: [],
  summaryRows: [],
  orderRules: {
    detailReport: {
      orderBy: 'Date',
      defaultOrderBy: 'Date',
      isReversed: false,
      isSeparatedByFavorites: true,
    },
  },
  mainFilters: {
    [STORE_KEYS.DEVCENTER]: null,
    [STORE_KEYS.MONTH]: moment().startOf('month'),
  },
  filters: {
    resource: {
      isActive: false,
      type: 'select',
      selected: [],
    },
    taskOrder: {
      isActive: false,
      type: 'select',
      selected: '',
    },
    projectType: {
      isActive: false,
      type: 'select',
      selected: [],
    },
    billType: {
      isActive: false,
      type: 'select',
      selected: [],
    },
  },
  isFiltering: false,
};

export default createReducer(initialState, {
  [deliveryCenterUtilizationByMonthActionsTypes.CHANGE_FILTER](state) {
    return update(state, {
      isFiltering: {
        $set: true,
      },
    });
  },

  [deliveryCenterUtilizationByMonthActionsTypes.CHANGE_FILTER_SUCCESS](state, {
    payload: {
      storeKey,
      config,
    },
  }) {
    return update(state, {
      isFiltering: {
        $set: false,
      },
      filters: {
        [storeKey]: {
          $merge: config,
        },
      },
    });
  },

  [deliveryCenterUtilizationByMonthActionsTypes.CHANGE_MAIN_FILTER](state, {
    payload,
  }) {
    return update(state, {
      mainFilters: {
        $merge: payload,
      },
    });
  },

  [deliveryCenterUtilizationByMonthActionsTypes.RESET_FILTERS](state) {
    return update(state, {
      isFiltering: {
        $set: false,
      },
      filters: {
        $merge: initialState.filters,
      },
    });
  },

  [deliveryCenterUtilizationByMonthActionsTypes.GET_UTILIZATION_REPORT](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },

  [deliveryCenterUtilizationByMonthActionsTypes.GET_UTILIZATION_REPORT_SUCCESS](state, {
    payload: {
      resourceRows,
      summaryRows,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      resourceRows: {
        $set: resourceRows,
      },
      summaryRows: {
        $set: summaryRows,
      },
      errors: {
        $unset: ['getUtilizationReportError'],
      },
    });
  },

  [deliveryCenterUtilizationByMonthActionsTypes.GET_UTILIZATION_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [deliveryCenterUtilizationByMonthActionsTypes.SET_ORDER_RULES](state, {
    payload: {
      tableName,
      orderRules,
    },
  }) {
    return update(state, {
      orderRules: {
        [tableName]: {
          $set: orderRules,
        },
      },
    });
  },
});
