import {
  USERS_GROUPS,
} from 'core/auth/constants';
import {
  dashboardAccess,
  clientsModuleAccess,
  taskOrderModuleAccess, channelPartnersModuleAccess,
} from 'core/auth/guaranteedAccessRoles';

import additionalLinks from './additional-links';

const {
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
  ACC_MSQ,
  ACC_SPB,
  HR,
  ADMIN,
} = USERS_GROUPS;

const rootHost = 'https://hbs.hiqo-solutions.us';
const apiUrl = 'https://v2-api.hbs.hiqo-solutions.us:8090';

export default {
  rootHost,
  apiUrl,

  LINKS: [{
    name: 'dashboard',
    route: 'home',
    guaranteedAccessRoles: dashboardAccess,
  },
  {
    name: 'clients',
    route: 'clients',
    guaranteedAccessRoles: clientsModuleAccess,
  },
  {
    name: 'task orders',
    route: 'task-orders',
    guaranteedAccessRoles: taskOrderModuleAccess,
  },
  {
    name: 'channel partners',
    route: 'channel-partners',
    guaranteedAccessRoles: channelPartnersModuleAccess,
  },
  ...additionalLinks,
  ],
  redirectPaths: {
    // [RM]: '/delivery/employees-list',
    [ACC_SPB]: '/reports/annual-report/staff',
    [ACC_MSQ]: '/reports/annual-report/staff',
    [HR]: '/delivery/employees-list',
    [DELIVERY_OFFICE_ADMIN_BY]: '/reports/billing-reports/summary/DTO/billable-activities',
    [DELIVERY_OFFICE_ADMIN_SPB]: '/reports/billing-reports/summary/DTO/billable-activities',
    [DELIVERY_OFFICE_ADMIN_WAW]: '/reports/billing-reports/summary/DTO/billable-activities',
    [DELIVERY_OFFICE_ADMIN_TBS]: '/reports/billing-reports/summary/DTO/billable-activities',
    [DELIVERY_OFFICE_ADMIN_KZH]: '/reports/billing-reports/summary/DTO/billable-activities',
    [ADMIN]: '/configurations/user-management',
  },
};
