import React from 'react';

import PropTypes from 'prop-types';

import { colorBackground } from 'assets/styles/variables';
import { ToastContainer } from 'react-toastify';
import styled, { css } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

const StyledToastContainer = styled(ToastContainer)`
  background-color: ${colorBackground};
  ${({ cssRules }) => css`${cssRules}`}
  ${({ isHidden }) => isHidden ? 'display: none;' : ''}
`;

export const Toast = ({ containerId, cssRules, isHidden }) => (
  <StyledToastContainer
    type="default"
    hideProgressBar
    newestOnTop={false}
    autoClose={false}
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    draggable={false}
    pauseOnHover={false}
    closeButton={false}
    enableMultiContainer
    containerId={containerId}
    cssRules={cssRules}
    isHidden={isHidden}
  />
);

Toast.propTypes = {
  isHidden: PropTypes.bool,
  cssRules: PropTypes.string,
  containerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

Toast.defaultProps = {
  cssRules: '',
  isHidden: false,
};
