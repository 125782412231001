import moment from 'moment';

export const FORMATS = {
  YYYYMMDD: 'YYYY-MM-DD',
  MMDDYYYY: 'MM/DD/YYYY',
  YYYYMM: 'YYYY-MM',
  MMYYYY: 'MMYYYY',
  MMMMYYYY: 'MMMM YYYY',
  YYYY: 'YYYY',
};

export const DEFAULT_FORMAT = FORMATS.YYYYMMDD;

export const parse = (value, formatValue = DEFAULT_FORMAT) => moment(value, formatValue);

export const format = (date, formatValue = DEFAULT_FORMAT) => date.format(formatValue);
