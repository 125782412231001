import { createAction } from 'redux-actions';

import { createTypes } from 'utils/helpers/actions';

export const modalConductorActionsTypes = createTypes([
  'CHANGE_CURRENT_MODAL',
  'CLEAR_MODAL',
], 'MODAL_CONDUCTOR');

export const modalConductorActions = {

  changeCurrentModal: createAction(
    modalConductorActionsTypes.CHANGE_CURRENT_MODAL,
    ({ currentModal, params }) => ({ currentModal, params }),
  ),

  clearModal: createAction(modalConductorActionsTypes.CLEAR_MODAL),
};
