import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import InfoIcon from 'assets/img/icon-info.svg';

import './styles.scss';

const Tooltip = ({ description, name }) => (
  <div className="tooltip">

    <div className="tooltip__icon-wrapper">

      <img
        className="tooltip__icon"
        data-tip
        data-for={name}
        src={InfoIcon}
        alt="info icon"
        data-event="click focus"
      />

    </div>

    <ReactTooltip
      id={name}
      effect="solid"
      place="bottom"
      globalEventOff="click"
      offset={{ left: 135 }}
      clickable
      tabIndex={-1}
    >

      <span className="tooltip__description">
        {description}
      </span>

    </ReactTooltip>

  </div>
);

Tooltip.propTypes = {
  description: PropTypes.shape({}),
  name: PropTypes.string,
};

Tooltip.defaultProps = {
  description: null,
  name: null,
};

export default Tooltip;
