export const colorPrimary = '#d61f26';
export const colorPrimary003 = 'rgba(214, 31, 38, 0.03)';
export const colorPrimaryRedLight = '#fbe9ea';
export const colorWhite = '#fff';
export const colorSecondaryText = 'rgba(0, 0, 0, 0.87)';
export const colorButtonBackground = 'rgba(0, 0, 0, 0.6)';
export const colorSecondaryGrayDark = '#414042';
export const colorSecondaryGray = '#58595b';
export const colorSecondaryGrayLight = '#bbbdc0';
export const colorBackgroundDark = '#efefef';
export const colorBackground = '#f2f2f2';
export const colorMainBackground = '#e5e5e5';
export const colorMainBackground04 = 'rgba(229,229,229, 0.4)';
export const colorSecondaryBackground = '#f2f2f2';
export const colorSecondaryBackgroundLight = '#fbfbfb';
export const colorSecondaryBackgroundLightDarken10 = '#dedede';
export const colorSecondaryBackgroundGrayDark = '#797a7c';
export const colorBackgroundLight = '#fbfbfb';
export const colorOverlayBackground = 'rgba(0, 0, 0, 0.5)';
export const colorBackgroundToggleButton = '#ccc';
export const colorBackgroundTooltip = '#55565a';
export const colorBorderGrayLight = 'rgba(88, 89, 91, 0.6)';
export const colorGrayLight08 = 'rgba(88, 89, 91, 0.8)';
export const colorBackgroundGoTopButton = '#ddd';
export const colorSecondaryGrayLight01 = 'rgba(187,189,192, 0.1)';
export const colorSecondaryGrayLight015 = 'rgba(187,189,192, 0.15)';
export const colorSecondaryGrayLight020 = 'rgba(187,189,192, 0.20)';
export const colorSecondaryGrayLight030 = 'rgba(187,189,192, 0.30)';
export const colorSecondaryGrayLight04 = 'rgba(187,189,192, 0.4)';
export const colorSecondaryGrayLight06 = 'rgba(187,189,192, 0.6)';
export const colorSecondaryGrayLight07 = 'rgba(187,189,192, 0.7)';
export const colorTextSalaryRaiseLow = '#6FCF97';
export const colorTextSalaryRaiseMedium = '#219653';
export const colorTextSalaryRaiseHight = '#F2B94C';
export const colorTextSecondaryButton = '#C11C22';

export const shadowStar = '1px 1px 0px rgba(0, 0, 0, .3)';
export const shadowBlock = '0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 3px 3px rgba(0, 0, 0, 0.14)';
export const shadowButtons = '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)';
export const shadowScrollbar = 'inset 0px 0px 10px rgba(0, 0, 0, 0.17)';
export const shadowSelectOptions = '0px 4px 4px rgba(0, 0, 0, 0.25)';

export const backgroundButtons = 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #d61f26';

export const fontMain = '"Etelka", Arial, sans-serif';
