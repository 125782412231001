export const locales = {
  resourceManagementReport: 'Resource Management',
  filterPlaceholder: 'All Delivery Centers',
  deliveryCenter: 'Delivery Center',
  resource: 'Resource',
  resources: 'Resources',
  employmentType: 'Employment Type',
  onboardingRows: {
    title: 'Onboarding',
    date: 'Onboarding Date',
    status: 'Onboarding Status',
  },
  endOfProbationRows: {
    title: 'End of Probation',
  },
  contractExpirationRows: {
    title: 'Contract Expiration',
    date: 'Contract Expiration Date',
  },
  offboardingRows: {
    title: 'Offboarding',
    date: 'Offboarding Date',
    status: 'Offboarding Status',
  },
};
