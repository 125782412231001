export const ENTITY_NAME = 'CONFIGURATIONS';

export const CLIENT_RATE_CARD_FILTER_STATE = {
  name: {
    value: '',
    isActive: false,
  },
  country: {
    value: '',
    isActive: false,
  },
  billingAgent: {
    value: [],
    isActive: false,
  },
  rateCard: {
    value: [],
    isActive: false,
  },
  notification: {
    value: [],
    isActive: false,
  },
  salutation: {
    value: '',
    isActive: false,
  },
  email: {
    value: '',
    isActive: false,
  },
  emailed: {
    value: [],
    isActive: false,
  },
  status: {
    value: [],
    isActive: false,
  },
};

export const EMAIL_TEMPLATES_FILTER_STATE = {
  name: {
    value: '',
    isActive: false,
  },
  country: {
    value: '',
    isActive: false,
  },
  billingAgent: {
    value: [],
    isActive: false,
  },
  emailTemplateId: {
    value: [],
    isActive: false,
  },
  notification: {
    value: [],
    isActive: false,
  },
  salutation: {
    value: '',
    isActive: false,
  },
  emails: {
    value: '',
    isActive: false,
  },
  emailed: {
    value: [],
    isActive: false,
  },
  status: {
    value: [],
    isActive: false,
  },
};

export const EMAILED_FILTER = [
  {
    emailedId: 0,
    emailed: 'Yes',
  },
  {
    emailedId: 1,
    emailed: 'No',
  },
];
