import { USERS_GROUPS } from 'core/auth/constants';
import { get } from 'lodash';
import { getHasPermissions } from 'utils/auth';
import { getIsExpired } from 'utils/helpers/date';

import billingInfoTabBodyGetter from './billing-info-tab';
import clientDocumentsTabBodyGetter from './client-documents-tab';
import detailsTabBodyGetter from './details-tab';
import taskOrdersTabGetter from './task-orders-tab';

const { CTO, HRM, DELIVERY_OFFICE_ADMIN, DELIVERY_OFFICE_ADMIN_BY } =
  USERS_GROUPS;

export const channelPartnersModelGetter = (
  userGroup,
  accountId,
  channelPartnerDetails,
) => {
  const { channelPartnerId } = channelPartnerDetails;

  const isCreatingForm = !channelPartnerId;
  const isCTO = getHasPermissions(userGroup, [CTO]);
  const isHRM = getHasPermissions(userGroup, [HRM]);
  const isDOA =
    getHasPermissions(userGroup, [DELIVERY_OFFICE_ADMIN]) ||
    getHasPermissions(userGroup, [DELIVERY_OFFICE_ADMIN_BY]);

  return {
    dataTemplate: [
      {
        tabHeader: {
          isLocked: isHRM || isDOA,
          title: 'Details',
          labels: [
            {
              valueGetter: ({ country }) => ({
                value: country || '-',
                isEmpty: !country,
              }),
            },
          ],
        },

        tabBody: detailsTabBodyGetter({
          isDOA,
          userGroup,
          isHRM,
        }),
      },
      {
        tabHeader: {
          isLocked: isHRM || isDOA,
          title: 'Billing info',
          labels: [
            {
              // TODO back to BA name after updates on endpoint
              valueGetter: ({ billingAgentId, billingAgents = [] }) => {
                const currentItem = billingAgents.find(
                  (item) => item.billingAgentId === billingAgentId
                );
                return {
                  value:
                    currentItem && currentItem.name ? currentItem.name : '-',
                  isEmpty: !currentItem || !currentItem.name,
                };
              },
            },
          ],
        },
        tabBody: billingInfoTabBodyGetter({
          isHRM,
          isDOA,
          userGroup,
          accountId,
          isCreatingForm,
        }),
      },
      {
        tabHeader: {
          isLocked: isDOA,
          title: 'Documents',
          labels: [
            {
              valueGetter: ({ files }) => {
                const fileCount = get(files, 'length', 0);
                return {
                  value: `${fileCount} document${fileCount === 1 ? '' : 's'}`,
                  isEmpty: !fileCount,
                };
              }
              ,
            },
          ],
        },
        stylesTemplate: {
          wrapperCssRules: `
            grid-column-gap: 2rem;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr minmax(32.8rem, 1fr);
            grid-template-areas: "detailsTable detailsTable detailsTable documentControl";
          `,
        },

        tabBody: clientDocumentsTabBodyGetter({
          isDOA,
          userGroup,
          channelPartnerId,
        }),
      },
      {
        tabHeader: {
          isLocked: isDOA,
          title: 'Task Orders',
          labels: [
            {
              valueGetter: ({ taskOrders = [] }) => {
                const changedTOLength =
                  taskOrders && taskOrders.filter ?
                    taskOrders.filter(
                      ({ isSelfRatecardChanged, isClientRatecardChanged }) => isClientRatecardChanged || isSelfRatecardChanged
                    ).length :
                    0;

                const length =
                  taskOrders && taskOrders.length ? taskOrders.length : 0;

                return {
                  value: `${length} task order${length === 1 ? '' : 's'} ${
                    changedTOLength ?
                      `/ ${changedTOLength} with customized rates` :
                      ''
                  } `,
                  isEmpty: !length,
                };
              },
            },
            {
              valueGetter: ({ taskOrders = [] }) => {
                const length =
                  taskOrders && taskOrders.filter ?
                    taskOrders.filter(
                      ({ isActive, endDate }) => isActive && !getIsExpired(endDate)
                    ).length :
                    0;

                const hasOpenPTM =
                  taskOrders &&
                  taskOrders.some(
                    ({ isActive, projectType, endDate }) => isActive &&
                      !getIsExpired(endDate) &&
                      projectType &&
                      projectType.toLowerCase() === 'ptm'
                  );

                return {
                  value: `${length} active${hasOpenPTM ? ' , Open PTM' : ''}`,
                  isEmpty: !length,
                };
              },
            },
          ],
        },

        tabBody: taskOrdersTabGetter({
          isCTO,
          isDOA,
        }),
      },
    ],
  };
};
