import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loader from 'components/loader';

import { StyledControlsWrapper, StyledButton } from './styled-components';


const FormMainControls = ({
  cssRules,
  hasFormChanges,
  isFormSubmitted,
  buttonTitle,
  handleReset,
  actions,
  mainControlsConfig,
  ptoModal,
  clearModal,
  additionalSubmit,
}) => {
  const {
    acceptActionTitle,
    cssRules: formControlsCss,
    onReject,
    getResetButtonConfig,
  } = mainControlsConfig;

  const onReset = () =>
    onReject ? onReject({ handleReset, actions, hasFormChanges }) : handleReset;

  const {
    isResetVisible,
    rejectActionTitle,
    rejectButtonCssRules,
  } = getResetButtonConfig ?
    getResetButtonConfig({ hasFormChanges, isFormSubmitted }) :
    {
      isResetVisible: hasFormChanges && !isFormSubmitted,
      rejectActionTitle: 'Cancel',
    };

  const cancelButton = () => ptoModal ?
    <StyledButton
      className="details-form__button button button--cancel"
      type="reset"
      onClick={() => clearModal()}
    >
      {rejectActionTitle}
    </StyledButton> :
    isResetVisible &&
    <StyledButton
      className="details-form__button button button--cancel"
      type="reset"
      onClick={onReset}
      cssRules={rejectButtonCssRules || ''}
    >
      {rejectActionTitle}
    </StyledButton>;

  const additionalSubmitButton = () => (
    <StyledButton
      className={
        classNames(
          'details-form__button button button--additional',
          { 'button--accept': !hasFormChanges },
          { 'button--disabled': hasFormChanges },
          { 'button--with-loader': isFormSubmitted }
        )
      }
      type="button"
      onClick={actions.additionalSubmitAction}
      cssRules={cssRules}
    >
      Send Emails
    </StyledButton>
  );

  return (
    <StyledControlsWrapper cssRules={formControlsCss || cssRules}>
      <button
        className={
          classNames(
            'details-form__button button',
            { 'button--accept': hasFormChanges },
            { 'button--disabled': !hasFormChanges },
            { 'button--with-loader': isFormSubmitted }
          )
        }
        type="submit"
        disabled={!hasFormChanges}
      >
        <Loader isLoaded={!isFormSubmitted}>
          {acceptActionTitle || buttonTitle}
        </Loader>
      </button>

      {cancelButton()}
      {additionalSubmit && additionalSubmitButton()}
    </StyledControlsWrapper>
  );
};

FormMainControls.propTypes = {
  cssRules: PropTypes.string, // deprecated will be removed with adding in to form components a 'mainControlsConfig'
  hasFormChanges: PropTypes.bool.isRequired,
  buttonTitle: PropTypes.string, // deprecated will be removed with adding in to form components a 'mainControlsConfig'
  handleReset: PropTypes.func.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  mainControlsConfig: PropTypes.shape({
    cssRules: PropTypes.string,
    acceptActionTitle: PropTypes.string,
    onReject: PropTypes.func,
    getResetButtonConfig: PropTypes.func,
  }).isRequired,
  actions: PropTypes.shape({}),
  ptoModal: PropTypes.bool,
  clearModal: PropTypes.func,
  additionalSubmit: PropTypes.bool,
};

FormMainControls.defaultProps = {
  cssRules: '',
  buttonTitle: 'Save changes',
  actions: {},
  ptoModal: false,
  clearModal: () => {},
  additionalSubmit: false,
};

export default FormMainControls;
