import { get } from 'lodash';

export const getRowBackground = ({
  ifFirstCondition,
  isSecondCondition,
}) => {
  if (ifFirstCondition) {
    return 'rgba(214,31,38, 0.08)';
  }

  if (isSecondCondition) {
    return 'rgba(187,189,192, 0.25);';
  }

  return 'transparent';
};

export const convertPLL = (pll) => {
  const dictionary = {
    '-1': '0',
    1: '1',
    2: '2',

  };
  return get(dictionary, pll, '-');
};
