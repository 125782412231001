import React from 'react';

import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import { Toast } from '../toast-notifications';

const StyledNotificationsContainer = styled.div`
  margin-top: 1rem;
  ${({ cssRules }) => css`${cssRules}`}
`;

export const Notifications = (props) => {
  const {
    isHidden,
    containerId,
  } = props;

  return (
    <StyledNotificationsContainer>
      <Toast
        isHidden={isHidden}
        containerId={containerId}
      />
    </StyledNotificationsContainer>
  );
};

Notifications.propTypes = {
  isHidden: PropTypes.bool,
  containerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

Notifications.defaultProps = {
  isHidden: true,
};
