import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import { ENTITY_NAME } from './constants';

export const attachmentsTypes = createTypes([
  ...async('GET_ATTACHMENTS_RECORDS'),
  ...async('CREATE_ATTACHMENT_RECORD'),
  ...async('UPLOAD_ATTACHMENT_FILE'),
  ...async('DELETE_ATTACHMENT_RECORD'),
  ...async('UPDATE_ATTACHMENT_RECORD'),
  'ADD_ATTACHMENT_RECORD',
  'REMOVE_ATTACHMENT_RECORD',
], ENTITY_NAME);

export const attachmentsActions = {
  getDevstaffAttachmentsRecords: createAction(attachmentsTypes.GET_ATTACHMENTS_RECORDS),
  getDevstaffAttachmentsRecordsSuccess: createAction(attachmentsTypes.GET_ATTACHMENTS_RECORDS_SUCCESS),
  getDevstaffAttachmentsRecordsFail: createAction(attachmentsTypes.GET_ATTACHMENTS_RECORDS_FAIL),

  createAttachmentRecord: createAction(attachmentsTypes.CREATE_ATTACHMENT_RECORD),
  createAttachmentRecordSuccess: createAction(attachmentsTypes.CREATE_ATTACHMENT_RECORD_SUCCESS),
  createAttachmentRecordFail: createAction(attachmentsTypes.CREATE_ATTACHMENT_RECORD_FAIL),

  deleteAttachmentRecord: createAction(attachmentsTypes.DELETE_ATTACHMENT_RECORD),
  deleteAttachmentRecordSuccess: createAction(attachmentsTypes.DELETE_ATTACHMENT_RECORD_SUCCESS),
  deleteAttachmentRecordFail: createAction(attachmentsTypes.DELETE_ATTACHMENT_RECORD_FAIL),

  updateAttachmentRecord: createAction(attachmentsTypes.UPDATE_ATTACHMENT_RECORD),
  updateAttachmentRecordSuccess: createAction(attachmentsTypes.UPDATE_ATTACHMENT_RECORD_SUCCESS),
  updateAttachmentRecordFail: createAction(attachmentsTypes.UPDATE_ATTACHMENT_RECORD_FAIL),

  addAttachmentRecord: createAction(attachmentsTypes.ADD_ATTACHMENT_RECORD),
  removeAttachmentRecord: createAction(attachmentsTypes.REMOVE_ATTACHMENT_RECORD),
};
