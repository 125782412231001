/* eslint-disable no-useless-escape */
import {
  updateChannelPartnersDetails,
} from 'core/auth/guaranteedAccessRoles';
import {
  getHasPermissions,
} from 'utils/auth';
import { emailRegex } from 'utils/email';
import {
  string,
} from 'yup';

export default ({
  isHRM,
  isDOA,
  userGroup,
}) => isHRM || isDOA ? [] : [{
  type: 'form',
  withoutFormControls: !getHasPermissions(userGroup, updateChannelPartnersDetails),
  stylesTemplate: {
    cssRules: 'grid-template-columns: repeat(4, 1fr); grid-template-areas: "msa msa poc poc";',
    formControlsCss: 'grid-template-columns: repeat(4, calc((136rem - 4.8rem * 3 - 3.2rem) / 4));',
  },
  content: [{
    title: 'Channel Partner Info',
    stylesTemplate: {
      gridArea: 'msa',
      marginConfig: '2n',
      cssRules: `
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        padding-bottom: 2.4rem;
      `,
    },
    formData: [
      {
        type: 'text',
        withLabel: true,
        name: 'name',
        placeholder: 'Channel Partner Name',

        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
        valueGetter: ({
          name,
        }) => ({
          value: name || '',
        }),

        validationRules: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
          schemaGetter: () => (
            string()
              .min(2, 'Channel Partner Name must have at least 2 characters')
              .max(50, 'Channel Partner Name max length is 50')
              .transform((value) => value.trim())
              .test('isTwoCharacters', 'Channel Partner Name must have at least 2 characters', (value) => value && (value.replace(/\d/g, '').length > 1))
              .required('Required field')
          ),
        },
      },
      {
        type: 'select',
        name: 'stateIncorporated',
        placeholder: 'Incorporated In',
        validationRules: {
          isRequired: true,
          schemaGetter: () => string().required('Required field'),
        },
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),

        valueGetter: ({
          stateIncorporated,
          states = [],
        }) => {
          const currentItem = states.find((item) => item.code === stateIncorporated);

          return {
            selected: {
              value: currentItem ? currentItem.code : '--',
              label: currentItem ? currentItem.name.replace(/^-{2,3}$/, 'n/a') : 'n/a',
            },

            items: states.map(({
              code,
              name,
            }) => ({
              value: code,
              label: name.replace(/^-{2,3}$/, 'n/a'),
            })),
          };
        },
        parentField: {
          name: ['country'],
          strictDependence: true,
          valueGetter: ({
            country,
          }, fieldData) => country === 'USA' ?
            fieldData : {
              selected: {
                label: '',
                value: '--',
              },
              items: [],
            },
        },
      },
      {
        type: 'select',
        name: 'country',
        placeholder: 'Country',
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),

        valueGetter: ({
          country,
          countries = [],
        }) => {
          const currentItem = countries.find((item) => item.name === country);

          return {
            selected: {
              value: currentItem ? currentItem.name : '',
              label: currentItem ? currentItem.name : '',
            },

            items: countries.map(({
              name,
            }) => ({
              value: name,
              label: name,
            })),
          };
        },
        validationRules: {
          isRequired: true,
          schemaGetter: () => string().required('Required field'),
        },
      },
      {
        type: 'text',
        withLabel: true,
        name: 'address2',
        placeholder: 'Address 2',

        valueGetter: ({
          address2,
        }) => ({
          value: address2 || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
        validationRules: {
          maxLength: 50,
          schemaGetter: () => string().transform((value) => value.trim()).max(50, 'Address max length is 50'),
        },
      },
      {
        type: 'text',
        withLabel: true,
        name: 'address1',
        placeholder: 'Address 1',

        valueGetter: ({
          address1,
        }) => ({
          value: address1 || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
        validationRules: {
          maxLength: 50,
          isRequired: true,
          schemaGetter: () => string().max(50, 'Address max length is 50').required('Required field').transform((value) => value.trim()),
        },
      },
      {
        isMultiple: true,
        formData: [{
          type: 'select',
          name: 'state',
          placeholder: 'State',
          pathInAPIResponse: ['state'],
          selectItems: 'states',
          validationRules: {
            isRequired: true,
            schemaGetter: () => string().required('Required field'),
          },
          getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
          valueGetter: ({
            state,
            states = [],
          }) => {
            const currentItem = states.find((item) => item.code === state);

            return {
              selected: {
                value: currentItem ? currentItem.code : '--',
                label: currentItem ? currentItem.code.replace(/^-{2,3}$/, 'n/a') : 'n/a',
              },

              items: states.map(({
                code,
              }) => ({
                value: code,
                label: code.replace(/^-{2,3}$/, 'n/a'),
              })),
            };
          },
          parentField: {
            name: ['country'],
            strictDependence: true,
            valueGetter: ({
              country,
            }, fieldData) => country === 'USA' ?
              fieldData : {
                selected: {
                  label: '',
                  value: '--',
                },
                items: [],
              },
          },
        },
        {
          type: 'text',
          withLabel: true,
          name: 'zip',
          placeholder: 'ZIP',

          valueGetter: ({
            zip,
          }) => ({
            value: zip || '',
          }),
          getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
          validationRules: {
            isRequired: true,
            maxLength: 10,
            schemaGetter: () => string().transform((value) => value.trim()).max(10, 'ZIP max length is 10').required('Required field'),
          },
        },
        ],
      },
      {
        type: 'text',
        withLabel: true,
        name: 'city',
        placeholder: 'City',

        valueGetter: ({
          city,
        }) => ({
          value: city || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
        validationRules: {
          isRequired: true,
          maxLength: 50,
          schemaGetter: () => string().transform((value) => value.trim()).max(50, 'City max length is 50').required('Required field'),
        },
      },
    ],
  },
  {
    title: 'POC',
    stylesTemplate: {
      columns: '1fr 1fr',
      gridArea: 'poc',
    },
    formData: [
      {
        type: 'text',
        withLabel: true,
        name: 'pocAccountName',
        placeholder: 'POC Name',

        valueGetter: ({
          pocAccountName,
        }) => ({
          value: pocAccountName || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
        validationRules: {
          isRequired: true,
          maxLength: 50,
          schemaGetter: () => string().max(50, 'POC Name max length is 50').required('Required field'),
        },
      },
      {
        type: 'text',
        withLabel: true,
        name: 'pocAccountTitle',
        placeholder: 'POC Title',
        pathInAPIResponse: ['pocAccountTitle'],
        valueGetter: ({
          pocAccountTitle,
        }) => ({
          value: pocAccountTitle || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
        validationRules: {
          isRequired: true,
          maxLength: 50,
          schemaGetter: () => string().max(50, 'POC Title max length is 50').required('Required field'),
        },
      },
      {
        type: 'text',
        withLabel: true,
        name: 'pocAccountEmail',
        placeholder: 'Email',
        pathInAPIResponse: ['pocAccountEmail'],
        valueGetter: ({
          pocAccountEmail,
        }) => ({
          value: pocAccountEmail || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
        validationRules: {
          isEmail: true,
          isRequired: true,
          maxLength: 50,
          schemaGetter: () => (
            string()
              .max(50, 'Email max length is 50')
              .matches(
                emailRegex,
                'Invalid email address'
              )
              .transform((value) => value.trim())
              .required('Required field')
          ),
        },
      },
      {
        type: 'text',
        withLabel: true,
        name: 'pocAccountFax',
        placeholder: 'Fax',
        pathInAPIResponse: ['pocAccountFax'],
        valueGetter: ({
          pocAccountFax,
        }) => ({
          value: pocAccountFax || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
        validationRules: {
          maxLength: 30,
          schemaGetter: () => string().transform((value) => value.trim()).max(30, 'Fax max length is 30'),
        },
      },
      {
        type: 'text',
        withLabel: true,
        name: 'pocAccountAdr1',
        placeholder: 'Address 1',
        pathInAPIResponse: ['pocAccountAdr1'],
        valueGetter: ({
          pocAccountAdr1,
        }) => ({
          value: pocAccountAdr1 || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
        validationRules: {
          isRequired: true,
          maxLength: 50,
          schemaGetter: () => string().transform((value) => value.trim()).max(50, 'Address max length is 50').required('Required field'),
        },
      },
      {
        type: 'text',
        withLabel: true,
        name: 'pocAccountAdr2',
        placeholder: 'Address 2',
        pathInAPIResponse: ['pocAccountAdr2'],
        valueGetter: ({
          pocAccountAdr2,
        }) => ({
          value: pocAccountAdr2 || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
        validationRules: {
          maxLength: 50,
          schemaGetter: () => string().transform((value) => value.trim()).max(50, 'Address max length is 50'),
        },
      },
      {
        type: 'text',
        withLabel: true,
        name: 'pocAccountCity',
        placeholder: 'City',
        pathInAPIResponse: ['pocAccountCity'],
        valueGetter: ({
          pocAccountCity,
        }) => ({
          value: pocAccountCity || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
        validationRules: {
          isRequired: true,
          maxLength: 50,
          schemaGetter: () => string().transform((value) => value.trim()).max(50, 'City max length is 50').required('Required field'),
        },
      },
      {
        isMultiple: true,

        cssRules: 'grid-template-columns: 1fr 1fr;',

        formData: [{
          type: 'select',
          name: 'pocAccountState',
          placeholder: 'State',
          validationRules: {
            isRequired: true,
            schemaGetter: () => string().required('Required field'),
          },
          getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
          valueGetter: ({
            pocAccountState,
            states = [],
          }) => {
            const currentItem = states.find((item) => item.code === pocAccountState);

            return {
              selected: {
                value: currentItem ? currentItem.code : '--',
                label: currentItem ? currentItem.code.replace(/^-{2,3}$/, 'n/a') : 'n/a',
              },

              items: states.map(({
                code,
              }) => ({
                value: code,
                label: code.replace(/^-{2,3}$/, 'n/a'),
              })),
            };
          },
          parentField: {
            name: ['country'],
            strictDependence: true,
            valueGetter: ({
              country,
            }, fieldData) => country === 'USA' ?
              fieldData : {
                selected: {
                  label: '',
                  value: '--',
                },
                items: [],
              },
          },
        },
        {
          type: 'text',
          withLabel: true,
          name: 'pocAccountZip',
          placeholder: 'ZIP',
          pathInAPIResponse: ['pocAccountZip'],
          valueGetter: ({
            pocAccountZip,
          }) => ({
            value: pocAccountZip || '',
          }),
          getIsUnitLocked: () => !getHasPermissions(userGroup, updateChannelPartnersDetails),
          validationRules: {
            isRequired: true,
            maxLength: 10,
            schemaGetter: () => string().transform((value) => value.trim()).max(10, 'ZIP max length is 10').required('Required field'),
          },
        },
        ],
      },
    ],
  },
  ],
}];
