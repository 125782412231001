import React from 'react';

import PropTypes from 'prop-types';

import { ActionButton } from 'components/buttons';

import styles from './styles.module.scss';

export const Controls = ({
  onRemoveRecord,
  resetForm,
  isCreateMode,
  isEditMode,
  setEditedRecordId,
  onDeleteClick,
  isSubmitting,
  isAllowedForEdit,
  isAllowedForDelete,
}) => {
  const isDisabled = isSubmitting || !isAllowedForEdit || !isAllowedForDelete;
  const handleCancelClick = () => {
    if (isCreateMode) {
      onRemoveRecord();
      setEditedRecordId();
    } else {
      resetForm();
    }
  };

  if (isEditMode) {
    return (
      <ActionButton
        title="Delete Button"
        dataId="deleteButton"
        type="button"
        onClick={onDeleteClick}
        className={styles.tertiaryButton}
        disabled={isDisabled}
      >
        {isAllowedForDelete ? 'Delete' : ''}
      </ActionButton>
    );
  }

  return (
    <>
      <ActionButton
        title="Save Button"
        dataId="saveButton"
        type="submit"
        className={styles.primaryButton}
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        Save
      </ActionButton>
      <ActionButton
        title="Cancel Button"
        dataId="cancelButton"
        type="button"
        onClick={handleCancelClick}
        className={styles.secondaryButton}
      >
        Cancel
      </ActionButton>
    </>
  );
};

Controls.propTypes = {
  onRemoveRecord: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  isCreateMode: PropTypes.bool.isRequired,
  setEditedRecordId: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isAllowedForEdit: PropTypes.bool.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  isAllowedForDelete: PropTypes.bool.isRequired,
};
