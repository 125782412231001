export const sortRates = (rates) => {
  const sortedRates = {};
  rates.forEach((rate) => {
    if (!sortedRates[rate.devcenter]) {
      sortedRates[rate.devcenter] = [];
    }
    if (rate.seniority.toLowerCase() === 'junior') {
      sortedRates[rate.devcenter][2] = rate;
    } else if (rate.seniority.toLowerCase() === 'staff') {
      sortedRates[rate.devcenter][1] = rate;
    } else if (rate.seniority.toLowerCase() === 'senior') {
      sortedRates[rate.devcenter][0] = rate;
    }
  });

  return Object.values(sortedRates).flat();
};
