/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Wrapper from "elements/wrapper";
import GoTop from "elements/go-top-button";
import Controls from "components/list-controls";
import DetailsForm from "components/details-form";
import SingleTable from "components/details-table/single-table";
import Loader from "components/loader";
import ExpansionPanelSingle from "components/expansion-panel-single";
import {
  selectCommissionRatesManagementFormContent,
  selectCommissionRatesSorted,
  selectCommissionUsers,
} from "core/configurations/selectors";
import { USERS_GROUPS } from "core/auth/constants";
import { getHasPermissions } from "utils/auth";

import { parseCommissionFormData } from "utils/helpers/models";
import { toNumber } from "lodash";

import { configurationsActions } from "core/configurations/actions";
import { autoScrollToTop } from "utils/react";

import { commonActions } from "core/common/actions";
import { wrapperCssRules, formTemplate, summaryRowsCssRule } from "./styles";

import {
  commissionRatesManagementFormTemplate,
  commissionRatesTableHeaderModel,
} from "./model";

const { CPS } = USERS_GROUPS;

const CommissionRates = ({
  location,
  entityName,
  isFetching,
  isFormSubmitted,
  orderRules,
  changeOrder,
  commissionRates,
  getCommissionRates,
  createCommissionRecord,
  updateCommissionRecord,
  removeCommissionRecord,
  commissionRatesManagementFormContent,
  changeCurrentModal,
  commissionUsers,
  userGroup,
}) => {
  const summaryRowsControls = [];
  autoScrollToTop(location);

  const isCPS = getHasPermissions(userGroup, [CPS]);

  const [commissionRatesModel, setCommissionRatesModel] = useState(
    commissionRatesManagementFormContent
  );
  const [activePanel, setActivePanel] = useState("");

  const changeActivePanel = (a) => setActivePanel(a);

  const getCommissionRatesModel = (rates) =>
    parseCommissionFormData({
      content: commissionRatesManagementFormTemplate(),
      details: rates,
    });

  useEffect(() => {
    getCommissionRates();
  }, []);

  useEffect(() => {
    // TODO
    setCommissionRatesModel(getCommissionRatesModel(commissionRates));
  }, [commissionRates]);
  useEffect(() => {
    // TODO
    setCommissionRatesModel(getCommissionRatesModel(commissionRates));
  }, [orderRules]);

  const summaryContentArr = (row) => {
    const { commissions, reportsToId, baseSal, stage1, stage2 } =
      row.records[row.records.length - 1];

    const getValues = (obj) => {
      const isDays = (key) => {
        if (key === "stage1Days" || key === "stage2Days") {
          return true;
        }
        return false;
      };
      const objValues = [];
      // eslint-disable-next-line prefer-const
      for (let key in obj) {
        objValues.push({
          data: isDays(key) ? obj[key] : toNumber(obj[key]).toFixed(1),
          cssRules: isDays(key)
            ? { justifyContent: "center" }
            : { justifyContent: "flex-end", padding: "0 2px" },
        });

        if (!isDays(key)) {
          objValues.push({
            data: "%",
            cssRules: { justifyContent: "flex-start" },
          });
        }
      }
      return objValues;
    };

    const latestRates = [
      { data: commissionUsers[reportsToId] },
      {
        data: toNumber(baseSal).toFixed(2),
        cssRules: {
          justifyContent: "flex-end",
          width: "70%",
          marginRight: "auto",
        },
      },
      ...getValues(commissions),
      ...getValues(stage1),
      ...getValues(stage2),
      { data: "" },
    ];
    return latestRates.map(({ data, cssRules }, index) => (
      <span
        // eslint-disable-next-line react/no-array-index-key
        key={`summary_config_${index}`}
        style={cssRules}
        className="expansion-summary-content-item"
      >
        {data}
      </span>
    ));
  };

  const onSubmit = ({ fields, initialValues }) => {
    const fieldsArray = fields.commissionRates;
    const initialValuesArray = initialValues.commissionRates;
    const splitObject = (obj) => {
      const splittedObj = {};
      const stage1 = obj.stage1;
      const stage2 = obj.stage2;
      const commissions = obj.commissions;
      // eslint-disable-next-line prefer-const
      for (let key in obj) {
        if (key === "commissions") {
          // eslint-disable-next-line prefer-const
          for (let k in commissions) {
            splittedObj[k] = commissions[k];
          }
        } else if (key === "stage1") {
          // eslint-disable-next-line prefer-const
          for (let k in stage1) {
            splittedObj[k] = stage1[k];
          }
        } else if (key === "stage2") {
          // eslint-disable-next-line prefer-const
          for (let k in stage2) {
            splittedObj[k] = stage2[k];
          }
        } else {
          splittedObj[key] = obj[key];
        }
      }

      return splittedObj;
    };

    let userId;
    const updateData = fieldsArray
      .map((item, index) => {
        const vals = splitObject(initialValuesArray[index]);
        const fieldsVals = splitObject(item);
        const difference = {};

        // eslint-disable-next-line prefer-const
        for (let key in fieldsVals) {
          if (fieldsVals[key] !== vals[key]) {
            difference[key] = fieldsVals[key];
          }
        }
        userId = item.userId;
        return {
          recordId: item.recordId,
          ...difference,
        };
      })
      .filter((item) => Object.keys(item).length > 1);

    updateCommissionRecord({ userId, fields: updateData });
  };

  const additionalActions = {
    createCommissionRecord,
    updateCommissionRecord,
    removeCommissionRecord,
    openModal: ({ currentModal, ...args }) =>
      changeCurrentModal({ currentModal, params: { ...args } }),
  };

  return (
    <Fragment>
      <Controls title="Commission Rates" />
      <Wrapper cssRules={wrapperCssRules}>
        <Loader isLoaded={!isFetching}>
          <SingleTable
            entityName={entityName}
            data={[
              {
                id: 1,
                tableData: [],
                stylesTemplate: {
                  rowStyles: `
                    &&{
                      margin:0;
                      border: none;
                      display: none;
                    }
                  `,
                },
              },
            ]}
            model={commissionRatesTableHeaderModel}
            orderRules={orderRules}
            changeOrder={changeOrder}
            tableName="commissionRates"
            isFetching={isFetching}
            withoutLoader
            controls={summaryRowsControls}
          />
          {commissionRates.map((row, index) => {
            const summaryConfig = {
              label: row.fullName,
              cssRules: summaryRowsCssRule,
              isDisabled: isCPS ? true : false,
            };
            const summaryContent = summaryContentArr(row);
            return (
              <ExpansionPanelSingle
                key={`${row.fullName}`}
                summaryConfig={summaryConfig}
                summaryContent={summaryContent}
                setActive={changeActivePanel}
                activePanelName={activePanel}
              >
                <DetailsForm
                  key={`commissionRatesForm_for_${row.fullName}}`}
                  withErrorBox
                  isFormSubmitted={isFormSubmitted}
                  onSubmit={onSubmit}
                  content={commissionRatesModel[index]}
                  stylesTemplate={formTemplate}
                  additionalActions={additionalActions}
                  isFetchingDetails={false}
                />
              </ExpansionPanelSingle>
            );
          })}
        </Loader>
      </Wrapper>
      <GoTop />
    </Fragment>
  );
};

CommissionRates.propTypes = {
  location: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  changeOrder: PropTypes.func.isRequired,
  getCommissionRates: PropTypes.func.isRequired,
  createCommissionRecord: PropTypes.func.isRequired,
  updateCommissionRecord: PropTypes.func.isRequired,
  removeCommissionRecord: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  commissionRates: PropTypes.array.isRequired,
  commissionRatesManagementFormContent: PropTypes.array.isRequired,
  commissionUsers: PropTypes.shape({}).isRequired,
};

const mapStateToProps = createStructuredSelector({
  commissionRates: selectCommissionRatesSorted,
  commissionRatesManagementFormContent:
    selectCommissionRatesManagementFormContent,
  commissionUsers: selectCommissionUsers,
});

const mapDispatchToProps = {
  getCommissionRates: configurationsActions.getCommissionRates,
  createCommissionRecord: configurationsActions.createCommissionRecord,
  removeCommissionRecord: configurationsActions.removeCommissionRecord,
  updateCommissionRecord: configurationsActions.updateCommissionRecord,
  changeOrder: commonActions.changeOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommissionRates);
