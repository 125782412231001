import { ENTITY_NAME } from 'core/configurations/constants';
import {
  emailTemplateNotificationsActions,
} from 'core/configurations/email-template-notifications/actions';
import {
  selectEmailTemplateNotificationsVersionsList,
  selectSendingEmailsProgress,
} from 'core/configurations/email-template-notifications/selectors';
import { generateVersion, getPayloadWithActualVersion } from 'core/configurations/helpers';
import { selectEntityName, selectErrors } from 'core/configurations/selectors';
import { modalConductorActions } from 'core/modal-conductor/actions';
import { TITLE } from 'layouts/rate-change-notifications/constants';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { put, call, select, all, delay } from 'redux-saga/effects';
import { executeMutation, executeQuery, parseError } from 'utils/request';

import { queryConfig } from './queries';

export function* getEmailTemplateNotifications({ payload }) {
  const { year, version } = payload;
  try {
    const options = {
      query: queryConfig.getEmailTemplateNotifications,
      variables: {
        year,
        version,
      },
    };

    const { emails } = yield call(executeQuery, options);

    return yield put(emailTemplateNotificationsActions.getEmailTemplateNotificationsSuccess({
      ...emails,
      version: generateVersion(version, emails.versions),
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getEmailTemplateNotificationsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getEmailTemplateNotificationsError = yield call(
      parseError,
      error,
      options
    );

    return yield put(emailTemplateNotificationsActions.getEmailTemplateNotificationsFail({
      error: {
        getEmailTemplateNotificationsError,
      },
    }));
  }
}

export function* updateEmailTemplateNotifications({ payload: { fields } }) {
  try {
    const mutation = queryConfig.updateEmailTemplateNotifications;
    const variables = { fields };
    const options = {
      mutation,
      variables,
    };
    const { updateEmails } = yield call(executeMutation, options);

    return yield put(emailTemplateNotificationsActions.updateEmailTemplateNotificationsSuccess({
      emailTemplateNotificationsData: updateEmails.emails,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(
      errors,
      'updateEmailTemplateNotificationsError',
      []
    );
    const options = {
      entityName,
      storedErrors,
    };
    const updateEmailTemplateNotificationsError = yield call(
      parseError,
      error,
      options
    );

    return yield put(emailTemplateNotificationsActions.updateEmailTemplateNotificationsFail({
      error: {
        updateEmailTemplateNotificationsError,
      },
    }));
  }
}
export function* createEmailTemplateNotificationsVersion({ payload: { fields } }) {
  try {
    const mutation = queryConfig.createEmailTemplateNotificationsVersion;
    const variables = {
      fields,
    };
    const options = {
      mutation,
      variables,
    };
    const { createEmailsVersion } = yield call(executeMutation, options);

    return yield put(emailTemplateNotificationsActions.createEmailTemplateNotificationsVersionSuccess({
      emailTemplateNotificationsData: {
        ...createEmailsVersion.emails,
        version: createEmailsVersion.emails.versions[createEmailsVersion.emails.versions.length - 1],
      },
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(
      errors,
      'createEmailTemplateNotificationsVersionError',
      []
    );
    const options = {
      entityName,
      storedErrors,
    };
    const createEmailTemplateNotificationsVersionError = yield call(
      parseError,
      error,
      options
    );

    return yield put(emailTemplateNotificationsActions.createEmailTemplateNotificationsVersionFail({
      error: {
        createEmailTemplateNotificationsVersionError,
      },
    }));
  }
}

export function* deleteEmailTemplateNotificationsVersion({ payload }) {
  try {
    const mutation = queryConfig.deleteEmailTemplateNotificationsVersion;
    const variables = {
      fields: {
        versionId: payload.version,
        year: payload.year,
      },
    };
    const options = {
      mutation,
      variables,
    };
    yield call(executeMutation, options);

    const emailTemplateNotificationsVersions = yield select(selectEmailTemplateNotificationsVersionsList);

    return yield all([
      yield put(emailTemplateNotificationsActions.deleteEmailTemplateNotificationsVersionSuccess()),
      yield put(emailTemplateNotificationsActions.getEmailTemplateNotifications(getPayloadWithActualVersion(emailTemplateNotificationsVersions, payload))),
    ]);
  } catch (error) {
    const message = get(error, 'extensions.detailed', null);
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(
      errors,
      'deleteEmailTemplateNotificationsError',
      []
    );
    const options = {
      entityName,
      storedErrors,
    };
    const currentError = message ? {
      message,
    } : error;

    const deleteEmailTemplateNotificationsError = yield call(
      parseError,
      currentError,
      options,
    );

    return yield put(emailTemplateNotificationsActions.deleteEmailTemplateNotificationsVersionFail({
      error: {
        deleteEmailTemplateNotificationsError,
      },
    }));
  }
}

export function* checkEmailsNotificationsStatus({
  payload,
}) {
  try {
    while (true) {
      const data = yield select(selectSendingEmailsProgress);
      if (!payload) return;

      const { emailsNotificationsStatus } = yield call(executeQuery, {
        query: queryConfig.emailsNotificationsStatus,
        variables: payload,
      });

      const recordsInProgress = get(emailsNotificationsStatus, 'recordsInProgress');
      const recordsInProgressFromState = get(data, 'recordsInProgress');

      if (recordsInProgress !== 0 && recordsInProgress === recordsInProgressFromState) {
        yield put(modalConductorActions.clearModal());

        toast.error('Processing Email Notifications has been interrupted. Please start over to finish processing the rest.', {
          containerId: ENTITY_NAME,
          toastId: TITLE,
        });

        yield put(emailTemplateNotificationsActions.updateEmailsNotificationsStatus(payload));
        return;
      }

      if (!get(emailsNotificationsStatus, 'inProgress')) {
        yield put(modalConductorActions.clearModal());
        yield put(emailTemplateNotificationsActions.getEmailTemplateNotifications(payload));
        return;
      }

      if (recordsInProgress) {
        yield put(modalConductorActions.changeCurrentModal({
          currentModal: 'PREVIEW_MODAL',
          params: {
            content: [
              {
                type: 'title',
                data: 'Processing Email Notifications',
              },
              {
                type: 'description',
                data: `${get(emailsNotificationsStatus, 'recordsInProgress')} Email Notifications are left to be sent.`,
              },
            ],
            parentFieldsData: [],
            withCloseButton: false,
          },
        }));
      }

      yield put(emailTemplateNotificationsActions.checkEmailsNotificationsStatusSuccess({
        ...emailsNotificationsStatus,
      }));

      yield delay(5000);
    }
  } catch (error) {
    yield put(modalConductorActions.clearModal());

    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'emailsNotificationsStatusError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const emailsNotificationsStatusError = yield call(
      parseError,
      error,
      options
    );

    // eslint-disable-next-line consistent-return
    return yield put(emailTemplateNotificationsActions.checkEmailsNotificationsStatusFail({
      error: {
        emailsNotificationsStatusError,
      },
    }));
  }
}

export function* sendEmailTemplateNotificationsEmails({ payload: { year, version } }) {
  try {
    const mutation = queryConfig.sendEmailTemplateNotificationsEmails;
    const variables = {
      fields: {
        year,
        version,
      },
    };
    const options = {
      mutation,
      variables,
    };

    yield call(executeMutation, options);

    return yield put(emailTemplateNotificationsActions.checkEmailsNotificationsStatus({
      year,
      version,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(
      errors,
      'sendEmailTemplateNotificationsEmailsError',
      []
    );
    const options = {
      entityName,
      storedErrors,
    };
    const sendEmailTemplateNotificationsEmailsError = yield call(
      parseError,
      error,
      options
    );

    return yield put(emailTemplateNotificationsActions.sendEmailTemplateNotificationsEmailsFail({
      error: {
        sendEmailTemplateNotificationsEmailsError,
      },
    }));
  }
}

export function* updateEmailsNotificationsStatus({ payload: { year, version } }) {
  try {
    const mutation = queryConfig.updateEmailsStatus;
    const variables = {
      fields: {
        year,
        versionId: version,
      },
    };
    const options = {
      mutation,
      variables,
    };
    yield call(executeMutation, options);

    return yield put(emailTemplateNotificationsActions.getEmailTemplateNotifications({ year, version }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'updateEmailsNotificationsStatusError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const updateEmailsNotificationsStatusError = yield call(
      parseError,
      error,
      options
    );

    return yield put(emailTemplateNotificationsActions.updateEmailsNotificationsStatusFail({
      error: {
        updateEmailsNotificationsStatusError,
      },
    }));
  }
}
