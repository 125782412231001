import update from 'immutability-helper';
import { get } from 'lodash';
import moment from 'moment';
import { createReducer } from 'redux-create-reducer';

import {
  billingReportsActionsTypes,
} from './actions';

import {
  STORE_KEYS,
  ENTITY_NAME,
  DEFAULT_ORDER,
  REPORTS_TYPES,
} from './constants';

const initialReportData = {
  devcenterIds: [],
  devcenters: [],
  billingAgentIds: [],
  selectedDate: moment(),
};
const initialAnnualReportData = {
  devcenters: [],
  currency: [],
  staff: null,
};
const initialCurrentPayrollReportData = {
  devcenter: [],
  staff: null,
};
const initialAnnualReportFilterState = {
  [STORE_KEYS.DEVCENTER]: {
    isActive: false,
    type: 'select',
    selected: [],
  },
  [STORE_KEYS.STAFF]: {
    isActive: false,
    type: 'select',
    selected: null,
  },
  [STORE_KEYS.CURRENCY]: {
    isActive: false,
    type: 'select',
    selected: [],
  },
};
const internalToFilter = {
  [STORE_KEYS.PROJECT_NAME]: {
    isActive: false,
    type: 'search',
    selected: '',
  },
  [STORE_KEYS.CUSTOMER]: {
    isActive: false,
    type: 'select',
    selected: [],
  },
  [STORE_KEYS.DEVCENTER]: {
    isActive: false,
    type: 'select',
    selected: [],
  },
  [STORE_KEYS.HIQO_TO]: {
    isActive: false,
    type: 'search',
    selected: '',
  },
  [STORE_KEYS.HIQO_CONTRACT]: {
    isActive: false,
    type: 'search',
    selected: '',
  },
  [STORE_KEYS.PROJECT_MODEL]: {
    isActive: false,
    type: 'select',
    selected: [],
  },
  // [STORE_KEYS.EFFECTIVE_DATE]: {
  //   isActive: false,
  //   type: 'date',
  //   selected: '',
  // },
};

const initialState = {
  entityName: ENTITY_NAME,
  errors: {},
  isFiltering: false,
  isFetching: false,
  isFetchingTaskOrdersList: false,
  isFetchingSalesList: false,
  isFetchingHolidaysList: false,
  taskOrderDetails: {},
  summaryReport: {},
  otherReportsData: {},
  billingProjectReport: {},
  currentTaskOrder: null,
  taskOrdersList: [],
  salesList: [],
  summaryReportType: '',
  projectReportType: '',
  salesId: null,
  holidaysList: [],
  reportData: initialReportData,
  annualReportData: initialAnnualReportData,
  currentPayrollReportData: initialCurrentPayrollReportData,
  internalContractors: {},
  internalToList: [],
  contracts: {},
  currentFilter: '',
  devCenters: new Set(),
  orderRules: {
    annualReportStaff: {
      orderBy: DEFAULT_ORDER.ANNUAL_REPORT_STAFF_TABLE,
    },
    plc: {
      orderBy: DEFAULT_ORDER.PLC_TABLE,
    },
    ptm: {
      orderBy: DEFAULT_ORDER.PTM_TABLE,
    },
    promo: {
      orderBy: DEFAULT_ORDER.PROMO_TABLE,
    },
    ttm: {
      orderBy: DEFAULT_ORDER.TTM_TABLE,
    },
    odc: {
      orderBy: DEFAULT_ORDER.ODC_TABLE,
    },
    overtime: {
      orderBy: DEFAULT_ORDER.OVERTIME_TABLE,
    },
    nonBillableActivities: {
      orderBy: DEFAULT_ORDER.NON_BILLABLE_ACTIVITIES_TABLE,
    },
    worklogReportRows: {
      orderBy: DEFAULT_ORDER.DETAILED_WORKLOG_TABLE,
    },
    nonBillableWorklogReportRows: {
      orderBy: DEFAULT_ORDER.DETAILED_WORKLOG_TABLE,
    },
    clientCategories: {
      orderBy: DEFAULT_ORDER.CLIENT_CATEGORIES,
    },
    projectRows: {
      orderBy: DEFAULT_ORDER.PROJECT_ROWS,
    },
    projectRowsNotInTO: {
      orderBy: DEFAULT_ORDER.PROJECT_ROWS,
    },
    overtimeProjectRows: {
      orderBy: DEFAULT_ORDER.PROJECT_ROWS,
    },
    overtimeProjectRowsNotInTo: {
      orderBy: DEFAULT_ORDER.PROJECT_ROWS,
    },
    salesCommissionTTM: {
      orderBy: DEFAULT_ORDER.SALES_COMMISSION_TABLE,
    },
    salesCommissionPTM: {
      orderBy: DEFAULT_ORDER.SALES_COMMISSION_TABLE,
    },
    salesCommissionOvertime: {
      orderBy: DEFAULT_ORDER.SALES_COMMISSION_TABLE,
    },
    internalToReport: {
      orderBy: DEFAULT_ORDER.INTERNAL_TO_REPORT,
    },
  },
  filters: {
    projectLeadCommissions: {
      plFullname: {
        isActive: false,
        type: 'select',
        selected: [],
      },
    },
    [STORE_KEYS.ANNUAL_REPORT_COMMON_FILTER]: initialAnnualReportFilterState,
    [STORE_KEYS.ANNUAL_REPORT_ANALYSIS_FILTER]: {
      [STORE_KEYS.IN_DEPTH_STUDY]: {
        isActive: false,
        type: 'toggle',
      },
    },
    [STORE_KEYS.ANNUAL_REPORT_SUMMARY_FILTER]: {
      [STORE_KEYS.IN_DEPTH_STUDY]: {
        isActive: false,
        type: 'toggle',
      },
    },
    [STORE_KEYS.ANNUAL_REPORT_STAFF_FILTER]: {
      [STORE_KEYS.DEVSTAFF_NAME]: {
        isActive: false,
        type: 'select',
        selected: [],
      },
      [STORE_KEYS.SENIORITY]: {
        isActive: false,
        type: 'select',
        selected: [],
      },
      [STORE_KEYS.MONTH]: {
        isActive: false,
        type: 'select',
        selected: [],
      },
      [STORE_KEYS.HAS_SALARY_RISE]: {
        isActive: false,
        type: 'toggle',
      },
    },
    internalToFilter,
    salesCommission: {
      projectName: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      taskOrder: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      resource: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      seniority: {
        isActive: false,
        type: 'select',
        selected: [],
      },
    },
    summaryBillableActivities: {
      client: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      project: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      taskOrder: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      resource: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      seniority: {
        isActive: false,
        type: 'select',
        selected: [],
      },
    },
    dtoSummaryNonBillableActivities: {
      project: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      date: {
        isActive: false,
        type: 'date',
        selected: '',
      },
      resource: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      clientCategoryName: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      internalCategoryName: {
        isActive: false,
        type: 'select',
        selected: [],
      },
    },
  },
};

export default createReducer(initialState, {
  [billingReportsActionsTypes.SET_SALES_ID](state, {
    payload: {
      salesId,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      salesId: {
        $set: salesId,
      },
      summaryReport: {
        $set: {},
      },
      otherReportsData: {
        $set: {},
      },
    });
  },
  [billingReportsActionsTypes.SET_SUMMARY_REPORT_TYPE](state, {
    payload: {
      reportType,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      summaryReportType: {
        $set: reportType,
      },
      projectReportType: {
        $set: null,
      },
      summaryReport: {
        $set: {},
      },
      otherReportsData: {
        $set: {},
      },
      reportData: {
        $set: initialReportData,
      },
      annualReportData: {
        $set: initialAnnualReportData,
      },
    });
  },

  [billingReportsActionsTypes.SET_PROJECT_REPORT_TYPE](state, {
    payload: {
      reportType,
      hasCurrentTo,
    },
  }) {
    const currentReportData = get(state, 'reportData', initialReportData);

    return update(state, {
      isFetching: {
        $set: true,
      },
      currentTaskOrder: {
        $set: null,
      },
      summaryReportType: {
        $set: null,
      },
      projectReportType: {
        $set: reportType,
      },
      summaryReport: {
        $set: {},
      },
      otherReportsData: {
        $set: {},
      },
      reportData: {
        $set: hasCurrentTo ? currentReportData : initialReportData,
      },
    });
  },

  [billingReportsActionsTypes.GET_TASK_ORDER_DETAILS](state, {
    payload: {
      taskOrder,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      currentTaskOrder: {
        $set: taskOrder,
      },
      taskOrderDetails: {
        $set: {},
      },
    });
  },

  [billingReportsActionsTypes.GET_TASK_ORDER_DETAILS_SUCCESS](state, {
    payload: {
      taskOrderDetails,
    },
  }) {
    const billingProjectReport = get(state, 'billingProjectReport', null);

    return update(state, {
      isFetching: {
        $set: !!billingProjectReport,
      },
      taskOrderDetails: {
        $set: taskOrderDetails,
      },
      errors: {
        $unset: ['getTaskOrdersDetailsError'],
      },
    });
  },

  [billingReportsActionsTypes.GET_TASK_ORDER_DETAILS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [billingReportsActionsTypes.GET_SALES_LIST](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      isFetchingSalesList: {
        $set: true,
      },
    });
  },

  [billingReportsActionsTypes.GET_SALES_LIST_SUCCESS](state, {
    payload: {
      salesList,
    },
  }) {
    return update(state, {
      isFetchingSalesList: {
        $set: false,
      },
      salesList: {
        $set: salesList,
      },
      errors: {
        $unset: ['getSalesListError'],
      },
    });
  },

  [billingReportsActionsTypes.GET_SALES_LIST_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      isFetchingSalesList: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [billingReportsActionsTypes.GET_TASK_ORDERS_LIST](state) {
    return update(state, {
      isFetchingTaskOrdersList: {
        $set: true,
      },
    });
  },

  [billingReportsActionsTypes.GET_TASK_ORDERS_LIST_SUCCESS](state, {
    payload: {
      taskOrdersList,
    },
  }) {
    return update(state, {
      isFetchingTaskOrdersList: {
        $set: false,
      },
      taskOrdersList: {
        $set: taskOrdersList,
      },
      errors: {
        $unset: ['getTaskOrdersListError'],
      },
    });
  },

  [billingReportsActionsTypes.GET_TASK_ORDERS_LIST_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetchingTaskOrdersList: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [billingReportsActionsTypes.GET_HOLIDAYS_LIST](state) {
    return update(state, {
      isFetchingHolidaysList: {
        $set: true,
      },
    });
  },

  [billingReportsActionsTypes.GET_HOLIDAYS_LIST_SUCCESS](state, {
    payload: {
      holidaysList,
    },
  }) {
    return update(state, {
      isFetchingHolidaysList: {
        $set: false,
      },
      holidaysList: {
        $set: holidaysList,
      },
      errors: {
        $unset: ['getHolidaysListError'],
      },
    });
  },

  [billingReportsActionsTypes.GET_HOLIDAYS_LIST_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetchingHolidaysList: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [billingReportsActionsTypes.SET_BILLING_PROJECT_REPORT_DATA](state, {
    payload: {
      billingProjectReportData,
    },
  }) {
    const currentTaskOrder = get(state, 'currentTaskOrder', null);

    return update(state, {
      isFetching: {
        $set: !!currentTaskOrder,
      },
      reportData: {
        $merge: billingProjectReportData,
      },
      billingProjectReport: {
        $set: {},
      },
    });
  },

  [billingReportsActionsTypes.GET_BILLING_PROJECT_REPORT](state, {
    payload: {
      reportType,
    },
  }) {
    const currentDevcenterIds = get(state, 'reportData.devcenterIds', []);

    return update(state, {
      isFetching: {
        $set: true,
      },
      billingProjectReport: {
        $set: {},
      },
      reportData: {
        reportType: {
          $set: reportType,
        },
        devcenterIds: {
          $set: reportType === REPORTS_TYPES.TO ? [] : currentDevcenterIds,
        },
      },
    });
  },

  [billingReportsActionsTypes.GET_BILLING_PROJECT_REPORT_SUCCESS](state, {
    payload: {
      billingReport,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      billingProjectReport: {
        $set: billingReport,
      },
      devCenters: {
        $set: new Set(billingReport.projectRows.reduce((currentDevCenterList, { devcenterShortname }) => [...currentDevCenterList, devcenterShortname], [])),
      },
      errors: {
        $unset: ['getBillingProjectReportError'],
      },
    });
  },

  [billingReportsActionsTypes.GET_BILLING_PROJECT_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [billingReportsActionsTypes.SET_BILLING_SUMMARY_REPORT_DATA](state, {
    payload: {
      billingSummaryReportData,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      reportData: {
        $merge: billingSummaryReportData,
      },
      summaryReport: {
        $set: {},
      },
      otherReportsData: {
        $set: {},
      },
    });
  },

  [billingReportsActionsTypes.SET_ANNUAL_REPORT_DATA](state, {
    payload,
  }) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      annualReportData: {
        $merge: payload,
      },
    });
  },

  [billingReportsActionsTypes.SET_CURRENT_PAYROLL_REPORT_DATA](state, {
    payload,
  }) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      currentPayrollReportData: {
        $merge: payload,
      },
    });
  },

  [billingReportsActionsTypes.GET_BILLING_SUMMARY_REPORT_SUCCESS](state, {
    payload: {
      otherData,
      summaryReport,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      summaryReport: {
        $set: summaryReport,
      },
      otherReportsData: {
        $set: otherData,
      },
      errors: {
        $unset: ['getBillingSummaryReportError'],
      },
    });
  },

  [billingReportsActionsTypes.GET_BILLING_SUMMARY_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [billingReportsActionsTypes.GET_INTERNAL_TO_REPORT](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },

  [billingReportsActionsTypes.GET_INTERNAL_TO_REPORT_SUCCESS](state, {
    payload: {
      internalToList,
      contractList,
    },
  }) {
    const contracts = {};
    contractList.forEach(({ id, name }) => {
      contracts[id] = name;
    });
    return update(state, {
      isFetching: {
        $set: false,
      },
      internalToList: {
        $set: internalToList,
      },
      contracts: {
        $set: contracts,
      },
      currentFilter: {
        $set: 'internalToFilter',
      },
      errors: {
        $unset: ['getBillingProjectReportError'],
      },
    });
  },

  [billingReportsActionsTypes.GET_INTERNAL_TO_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [billingReportsActionsTypes.SET_ORDER_RULES](state, {
    payload: {
      tableName,
      orderRules,
    },
  }) {
    return update(state, {
      orderRules: {
        [tableName]: {
          $set: orderRules,
        },
      },
    });
  },

  [billingReportsActionsTypes.SET_PROJECT_ORDER_RULES](state, {
    payload: {
      tableName,
      orderRules,
    },
  }) {
    return update(state, {
      projectOrderRules: {
        [tableName]: {
          $set: orderRules,
        },
      },
    });
  },

  [billingReportsActionsTypes.CHANGE_FILTER](state) {
    return update(state, {
      isFiltering: {
        $set: true,
      },
    });
  },

  [billingReportsActionsTypes.CHANGE_FILTER_SUCCESS](state, {
    payload: {
      currentFilter,
      storeKey,
      config,
    },
  }) {
    return update(state, {
      isFiltering: {
        $set: false,
      },
      filters: {
        [currentFilter]: {
          [storeKey]: {
            $merge: config,
          },
        },
      },
    });
  },

  [billingReportsActionsTypes.RESET_FILTERS](state, {
    payload: {
      currentFilter,
    },
  }) {
    const initialFiltersState = get(initialState, `filters.${currentFilter}`);
    return update(state, {
      isFiltering: {
        $set: false,
      },
      filters: {
        [currentFilter]: {
          $merge: initialFiltersState,
        },
      },
    });
  },
});
