import React, { useCallback, useRef } from 'react';

import { useForm, FormProvider, useFieldArray } from 'react-hook-form';

import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import Loader from 'components/loader';
import { ENTITY_NAME } from 'core/configurations/constants';
import { List } from 'forms/components';
import { defaultErrorMessage } from 'forms/constants';
import { Header, ListItem, FormActions } from 'forms/email-notifications-forms/components';
import { validationSchema } from 'forms/email-notifications-forms/email-template-notifications-form/utils';
import styles from 'forms/email-notifications-forms/styles.module.scss';
import { useSortBy } from 'forms/email-notifications-forms/useSortBy';
import { dirtyValues } from 'forms/helpers';
import { useInfiniteScroller } from 'forms/hooks';

import hash from 'hash-sum';
import EmailTemplateNotificationsTableFilters from 'layouts/email-template-notifications/filter';
import commonStyles from 'layouts/email-template-notifications/styles.module.scss';
import { toast } from 'react-toastify';

const EmailTemplateNotificationForm = ({
  data,
  isFetching,
  defaultCounter,
  columns,
  onSubmitHandler,
  sendEmailsHandler,
  sortBy: initialSortBy,
  isEditable,
  isControlsHidden,
}) => {
  const toastRef = useRef(null);
  const { records, onSortHandler, sortBy } = useSortBy(data, initialSortBy);

  const isEmptyData = !data.length;
  const methods = useForm({
    values: {
      records,
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields } = useFieldArray({
    name: 'records',
    control: methods.control,
  });

  const {
    formFields,
    counter,
    ref,
  } = useInfiniteScroller(fields, defaultCounter);

  const onSubmit = (formData) => {
    toast.dismiss();

    const dirtyRecords = methods.formState.dirtyFields.records;
    const normalizedRecords = dirtyValues(dirtyRecords, [...formData.records]);

    if (!normalizedRecords) return;
    onSubmitHandler(normalizedRecords);
  };

  const onSubmitErrors = useCallback((errors) => {
    const errorsIndexes = Object.keys(errors.records);
    const recordsWithEmailError = records.filter((_, index) => errorsIndexes.includes(index.toString()));
    const clients = recordsWithEmailError.map(({ name }) => name).join(', ');

    if (recordsWithEmailError.length) {
      toastRef.current = hash(JSON.stringify(recordsWithEmailError));

      if (!toast.isActive(toastRef.current)) {
        toast.dismiss();
      }

      toast.error(`${defaultErrorMessage} ${clients}`, { containerId: ENTITY_NAME, toastId: toastRef.current });
    }
  }, [records]);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitErrors)}>
      <EmailTemplateNotificationsTableFilters />
      <Header
        columns={columns}
        onSortHandler={onSortHandler}
        sortBy={sortBy}
        commonStyles={commonStyles}
      />
      <FormProvider {...methods}>
        <List isEmpty={isEmptyData}>
          {formFields.map((field, index) => (
            <ListItem
              key={`list-item-${field.id}`}
              defaultCounter={defaultCounter}
              ref={index === counter ? ref : null}
              index={index}
              field={field}
              columns={columns}
              isEditable={isEditable}
            />
          ))}
        </List>
        {isEditable && (
          <div data-id="Form Actions Wrapper" className={styles.formActionWrapper}>
            <FormActions
              sendEmailsHandler={sendEmailsHandler}
              defaultValues={{ records }}
              isControlsHidden={isControlsHidden}
              toastId={toastRef.current}
            />
          </div>
        )}
      </FormProvider>
    </form>
  );
};

EmailTemplateNotificationForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultCounter: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  sendEmailsHandler: PropTypes.func.isRequired,
  sortBy: PropTypes.shape({
    accessor: PropTypes.string,
    direction: PropTypes.string,
  }).isRequired,
  isEditable: PropTypes.bool.isRequired,
  isControlsHidden: PropTypes.bool.isRequired,
};

export default EmailTemplateNotificationForm;
