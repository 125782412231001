export const ENTITY_NAME = 'CATEGORY_MANAGEMENT';
export const NOTIFICATIONS_FAILED_REQUESTS = 'cm-failed-requests-notifications';
export const NOTIFICATIONS_CATEGORIES_FORM_ID = 'cm-form-notifications';

export const STORE_KEYS = {
  CATEGORY_NAME: 'categoryName',
  CLIENT_NAME: 'clientName',
  IS_ACTIVE_CLIENT: 'isActiveClient',
};

export const CLIENT_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const FILTER_TYPES = {
  CLIENTS_FILTER: 'clientsFilter',
  CATEGORIES_FILTER: 'categoriesFilter',
};
