import gql from 'graphql-tag';

export const queryConfig = {
  resourceManagementCommentsTab: gql`
    query resourceManagementCommentsTab($devstaffId: Int!) {
      resourceManagementCommentsTab(devstaffId: $devstaffId)
      {
        commentId
        commentedByUserId
        commentedByUserFullname
        text
        date
        allowedToEdit,
      }
    }
  `,
  createResourceManagementComment: gql`
    mutation createDevstaffComment($fields: AddCommentInput!) {
      createDevstaffComment(fields: $fields)
      {
        ok
        comments {
          commentId
          commentedByUserId
          commentedByUserFullname
          text
          date
          allowedToEdit,
        }
      }
    }
  `,
  updateResourceManagementComment: gql`
    mutation updateDevstaffComment($fields: UpdateCommentInput!) {
      updateDevstaffComment(fields: $fields)
      {
        ok
        comments {
          commentId
          commentedByUserId
          commentedByUserFullname
          text
          date
          allowedToEdit,
        }
      }
    }
  `,
  deleteResourceManagementComment: gql`
    mutation deleteDevstaffComment($fields: DeleteCommentInput!) {
      deleteDevstaffComment(fields: $fields)
      {
        ok
        comments {
          commentId
          commentedByUserId
          commentedByUserFullname
          text
          date
        }
      }
    }
  `,
};
