import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Loader from 'components/loader';


const StyledButton = styled.button`
  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;

const RemoteControllerButton = ({
  configGetter,
  defaultConfig,
  isFormSubmitted,
  registerControl,
  getControlsState,
}) => {
  const [buttonConfig, setButtonConfig] = useState(defaultConfig);
  const { title, onClick, cssRules } = buttonConfig;
  const updateConfig = (props) => {
    setButtonConfig((currentConfig) => configGetter({ currentConfig, defaultConfig, ...props }));
  };

  useEffect(() => registerControl({ updateConfig }));

  useEffect(() => {
    const hasFormChanges = getControlsState();

    updateConfig({ isFormSubmitted, hasFormChanges });
  }, [isFormSubmitted]);

  return (
    <StyledButton
      type="button"
      className="button remote-controller-button"
      onClick={onClick}
      cssRules={cssRules}
    >
      <Loader
        isLoaded={!isFormSubmitted}
      >
        {title}
      </Loader>

    </StyledButton>);
};


RemoteControllerButton.propTypes = {
  isFormSubmitted: PropTypes.bool,
  configGetter: PropTypes.func.isRequired,
  getControlsState: PropTypes.func.isRequired,
  registerControl: PropTypes.func.isRequired,
  defaultConfig: PropTypes.shape({
    onClick: PropTypes.func,
    cssRules: PropTypes.string,
    title: PropTypes.string,
  }),
};

RemoteControllerButton.defaultProps = {
  defaultConfig: {
    onClick: () => null,
    title: 'Action',
  },
  isFormSubmitted: false,
};

export default RemoteControllerButton;
