export const ENTITY_NAME = 'DELIVERY';
export const ENTITY_NAME_PROJECTS = 'Projects';
export const ENTITY_NAME_EMPLOYEES = 'Employees';

export const STORE_KEYS = {
  TITLE: 'title',
  IS_FAVORED: 'isFavored',
  STATUS: 'status',
  NAME: 'name',
  FULLNAME: 'fullname',
  SENIORITY: 'seniority',
  DEVCENTER: 'devcenter',
  TYPE: 'type',
  USD: 'usd',
  RUR: 'rur',
  PLN: 'pln',
  VACATION_DAYS_TOTAL: 'vacationDaysTotal',
  VACATION_DAYS_LEFT: 'vacationDaysLeft',
  VACATION_DAYS_SPENT: 'vacationDaysSpent',
  DATE_HIRED: 'dateHired',
  DATE_LAST: 'dateLast',
  INTERNAL_CATEGORY_KEY: 'internalCategoryKey',
  INTERNAL_CATEGORY_NAME: 'internalCategoryName',
  IS_NO_VALID_PL: 'isNoValidPl',
  IS_NO_PL: 'isNoPl',
};
