import { colorPrimary } from 'assets/styles/variables';
import { getIsExpired, formatDateMMDDYYYY } from 'utils/helpers/date';

const baseDescriptionCssRules = `
    min-height: 4rem;
    padding: 0;
    font-size: 1.3rem;
    line-height: 4rem;
    letter-spacing: -0.025rem;
    border-bottom: 1px solid #bbbdc0;
    color: rgba(0, 0, 0, 0.87);`;

const headingsDescriptionCssRules = `
    ${baseDescriptionCssRules}
    font-weight: 500;`;

const headingsWithPaddingDescriptionCssRules = `
    ${headingsDescriptionCssRules}
    padding-left: 1.6rem;`;

const headingsWithMarginDescriptionCssRules = `
    ${baseDescriptionCssRules}
    width: calc(100% - 5rem);`;

const headingsWithMarginLinkCssRules = `
    ${baseDescriptionCssRules}
    display: block;
    width: calc(100% - 5rem);`;

const baseLinkCssRules = `
    ${baseDescriptionCssRules}
    display: block;`;

export default ({
  isCTO,
  isHRM,
  title,
  msaName,
  endDate,
  clientId,
  clientPm,
  issueDate,
  projectId,
  clientName,
  taskOrderId,
  projectType,
  emailReports,
  effectiveDate,
  hasPermissionsTOClientModule,
  hasPermissionsTOTaskOrderModule,
}) => ({
  data: [
    {
      type: 'description',
      data: 'Project Details',
      cssRules: `
      grid-area: heading;
      margin: 0 0 1.6rem 1.6rem;
      font-size: 2.2rem;
      line-height: 2.6rem;
      color: rgba(0, 0, 0, 0.87);
    `,
    },
    {
      data: [
        {
          type: 'description',
          data: 'MSA #:',
          cssRules: `
        ${headingsWithPaddingDescriptionCssRules}
        border-top: 1px solid #bbbdc0;
      `,
        },
        {
          type: 'description',
          data: msaName,
          cssRules: `
        ${headingsWithMarginDescriptionCssRules}
        border-top: 1px solid #bbbdc0;
      `,
        },
        {
          type: 'description',
          data: 'Client Name:',
          cssRules: headingsWithPaddingDescriptionCssRules,
        },
        {
          type:
            hasPermissionsTOClientModule && clientId ? 'link' : 'description',
          data: clientName || 'n/a',
          pathname: `/clients/${clientId}/${
            isCTO || isHRM ? 'task-orders' : 'details'
          }`,
          cssRules: headingsWithMarginLinkCssRules,
        },
        {
          type: 'description',
          data: 'Project Name:',
          cssRules: headingsWithPaddingDescriptionCssRules,
        },
        {
          type:
            hasPermissionsTOTaskOrderModule && projectId ?
              'link' :
              'description',
          data: `${title}`,
          pathname: `/task-orders/${projectId}/${
            isCTO ? 'staffing' : 'details'
          }`,
          cssRules: headingsWithMarginLinkCssRules,
        },
        {
          type: 'description',
          data: 'Client PM:',
          cssRules: headingsWithPaddingDescriptionCssRules,
        },
        {
          type: 'description',
          data: clientPm || 'n/a',
          cssRules: headingsWithMarginDescriptionCssRules,
        },
        {
          type: 'description',
          data: 'Email reports to:',
          cssRules: headingsWithPaddingDescriptionCssRules,
        },
        {
          type: emailReports ? 'link' : 'description',
          data: emailReports || 'n/a',
          href: `mailto:${emailReports}`,
          cssRules: headingsWithMarginLinkCssRules,
        },
      ],
      cssRules: `
      display: grid;
      grid-area: left-part;
      grid-template-columns: minmax(auto, 1fr) minmax(auto, 2fr);
      `,
    },
    {
      data: [
        {
          type: 'description',
          data: 'Task Order #:',
          cssRules: `
        ${headingsDescriptionCssRules}
        border-top: 1px solid #bbbdc0;
      `,
        },
        {
          type:
            hasPermissionsTOTaskOrderModule && projectId ?
              'link' :
              'description',
          data: taskOrderId,
          pathname: `/task-orders/${projectId}/${
            isCTO ? 'staffing' : 'details'
          }`,
          cssRules: `
        ${baseLinkCssRules}
        border-top: 1px solid #bbbdc0;
      `,
        },
        {
          type: 'description',
          data: 'Contract Type:',
          cssRules: headingsDescriptionCssRules,
        },
        {
          type: 'description',
          data: projectType,
          cssRules: baseDescriptionCssRules,
        },
        {
          type: 'description',
          data: 'Issue Date:',
          cssRules: headingsDescriptionCssRules,
        },
        {
          type: 'description',
          data: formatDateMMDDYYYY(issueDate),
          cssRules: baseDescriptionCssRules,
        },
        {
          type: 'description',
          data: 'Effective Date:',
          cssRules: headingsDescriptionCssRules,
        },
        {
          type: 'description',
          data: formatDateMMDDYYYY(effectiveDate),
          cssRules: baseDescriptionCssRules,
        },
      ],
      cssRules: `
      display: grid;
      grid-template-columns: minmax(auto, 1fr) minmax(auto, 2fr);
      grid-area: right-part-top;
      grid-auto-rows: min-content;
     `,
    },
    {
      data: [
        {
          type: 'description',
          data: getIsExpired(endDate) ?
            `This TO has already expired on ${formatDateMMDDYYYY(endDate)}.` :
            '',
          cssRules: `
          ${baseDescriptionCssRules}
          color: ${colorPrimary}`,
        },
      ],
      cssRules: 'grid-area: right-part-bottom;',
    },
  ],
});
