/* eslint-disable consistent-return */
import { waitForAuthorization } from 'core/auth/sagas';
import { queryConfig } from 'core/delivery/contactManagement/queries';
import { selectDevstaffId, selectErrors, selectEntityName } from 'core/delivery/selectors';
import { get } from 'lodash';
import { select, call, put, takeLatest } from 'redux-saga/effects';
import { parseError, executeQuery, executeMutation } from 'utils/request';

import {
  contactDataManagementActions,
  GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS,
  CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA,
  UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA,
  DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA,
} from './actions';

function* getContactDataManagementRecords() {
  try {
    const devstaffId = yield select(selectDevstaffId);
    const query = queryConfig.devstaffContacts;
    const options = {
      query,
      variables: {
        devstaffId,
      },
    };
    const { devstaffContacts } = yield call(executeQuery, options);

    return yield put(contactDataManagementActions.getResourceManagementContactDataRecordsSuccess({
      records: devstaffContacts,
      memoDevstaffId: devstaffId,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getContactDataManagementRecordsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getContactDataManagementRecordsError = yield call(parseError, error, options);

    yield put(contactDataManagementActions.getResourceManagementContactDataRecordsSuccess({
      error: {
        getContactDataManagementRecordsError,
      },
    }));
  }
}

function* createContactDataManagementRecord({ payload }) {
  try {
    const devstaffId = yield select(selectDevstaffId);
    const mutation = queryConfig.createDevstaffContact;
    const options = {
      mutation,
      variables: {
        fields: {
          devstaffId,
          contactInfo: get(payload, 'contactInfo', ''),
        },
      },
    };
    const { createDevstaffContact } = yield call(executeMutation, options);

    return yield put(contactDataManagementActions.createResourceManagementContactDataSuccess({
      record: get(createDevstaffContact, 'contactInfo', null),
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'createResourceManagementContactDataError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const createResourceManagementContactDataError = yield call(parseError, error, options);

    yield put(contactDataManagementActions.createResourceManagementContactDataSuccess({
      error: {
        createResourceManagementContactDataError,
      },
    }));
  }
}

function* updateContactDataManagementRecord({ payload }) {
  try {
    const contactId = get(payload, 'contactDataId');
    const mutation = queryConfig.updateDevstaffContact;
    const options = {
      mutation,
      variables: {
        fields: {
          contactInfo: get(payload, 'contactInfo', ''),
          contactId,
        },
      },
    };

    const { updateDevstaffContact } = yield call(executeMutation, options);

    return yield put(contactDataManagementActions.updateResourceManagementContactDataSuccess({
      record: get(updateDevstaffContact, 'contact', null),
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'updateResourceManagementContactDataError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const updateResourceManagementContactDataError = yield call(parseError, error, options);

    yield put(contactDataManagementActions.updateResourceManagementContactDataFail({
      error: {
        updateResourceManagementContactDataError,
      },
    }));
  }
}

function* deleteContactDataManagementRecord({ payload }) {
  try {
    const contactDataId = get(payload, 'contactDataId');
    const mutation = queryConfig.deleteDevstaffContact;
    const options = {
      mutation,
      variables: {
        contactId: contactDataId,
      },
    };

    yield call(executeMutation, options);

    return yield put(contactDataManagementActions.deleteResourceManagementContactDataSuccess(contactDataId));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'deleteResourceManagementContactDataError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const deleteResourceManagementContactDataError = yield call(parseError, error, options);

    yield put(contactDataManagementActions.deleteResourceManagementContactDataSuccess({
      error: {
        deleteResourceManagementContactDataError,
      },
    }));
  }
}

function* getContactDataManagementRecordsWatcher() {
  yield takeLatest(GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS, waitForAuthorization(getContactDataManagementRecords));
}

function* createContactDataManagementRecordWatcher() {
  yield takeLatest(CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA, waitForAuthorization(createContactDataManagementRecord));
}

function* updateContactDataManagementRecordWatcher() {
  yield takeLatest(UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA, waitForAuthorization(updateContactDataManagementRecord));
}

function* deleteContactDataManagementRecordWatcher() {
  yield takeLatest(DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA, waitForAuthorization(deleteContactDataManagementRecord));
}

export default [
  getContactDataManagementRecordsWatcher,
  createContactDataManagementRecordWatcher,
  updateContactDataManagementRecordWatcher,
  deleteContactDataManagementRecordWatcher,
];
