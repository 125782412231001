import React, { memo, useMemo } from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { USERS_GROUPS } from 'core/auth/constants';
import { selectBillingAgents, selectRoles, selectUser } from 'core/auth/selectors';
import { USER_ID } from 'core/configurations/user-management';
import EmptyMessage from 'elements/empty-message';
import { UserManagementForm } from 'forms/user-management-form';
import update from 'immutability-helper';
import { getActions } from 'layouts/user-management/getActions';
import { useActions } from 'layouts/user-management/hooks';
import classes from 'layouts/user-management/index.module.scss';
import { get, noop } from 'lodash';

export const MultipleForms = memo(({
  userRecords,
  onRemoveNew,
  isCreateMode,
  isFiltersActive,
  userGroup,
}) => {
  const { defaultActions, actionsList } = useActions(onRemoveNew);

  const activeUser = useSelector(selectUser);
  const billingAgents = useSelector(selectBillingAgents);
  const roles = useSelector(selectRoles);

  const records = useMemo(() => userRecords.map((record) => {
    const isDisabled = isCreateMode && record.userId !== USER_ID;
    const isUser = get(activeUser, 'userId', null) === record.userId;
    return {
      ...record,
      recordState: update(record.recordState, {
        isDisabled: {
          $set: isDisabled,
        },
        isUser: {
          $set: isUser,
        },
      }),
    };
  }), [userRecords, isCreateMode, activeUser]);

  if (isFiltersActive && !userRecords.length) {
    return (
      <EmptyMessage
        className={classes.placeholder}
        description="Sorry, no content matched your criteria. Please, change the selected filters."
      />
    );
  }

  if (!userRecords.length) {
    return (
      <EmptyMessage
        className={classes.placeholder}
        description="This section currently contains no data."
      />
    );
  }

  return (
    <div>
      {records.map((
        {
          userId,
          loginLogsHistory,
          recordState,
          formState,
          ...defaultValues
        }
      ) => (
        <UserManagementForm
          key={userId}
          userId={userId}
          formState={formState}
          recordState={recordState}
          defaultValues={defaultValues}
          loginLogsHistory={loginLogsHistory}
          billingAgents={billingAgents}
          roles={roles}
          actions={getActions({
            ...recordState,
            actionsList,
          })}
          isAdminRole={userGroup === USERS_GROUPS.ADMIN}
          defaultActions={defaultActions}
        />
      ))}
    </div>
  );
});

MultipleForms.propTypes = {
  userRecords: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  onRemoveNew: PropTypes.func,
  isCreateMode: PropTypes.bool,
  isFiltersActive: PropTypes.bool,
  userGroup: PropTypes.bool,
};

MultipleForms.defaultProps = {
  userRecords: [],
  onRemoveNew: noop,
  isCreateMode: false,
  isFiltersActive: false,
  userGroup: false,
};
