import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import Calendar from 'components/calendar';
import FilterSelect from 'components/filter-select';
import PageTitle from 'components/page-title';
import { filesActions } from 'core/files/actions';
import GoTop from 'elements/go-top-button';
import StyledWrapper from 'elements/styled-wrapper';
import WrapperNg from 'elements/wrapper-ng';
import moment from 'moment';
import {
  format as formatDate,
  FORMATS,
} from 'utils/date';
import { autoScrollToTop } from 'utils/react';

import {
  yearPickerCssRules,
  calendarItemCssRules,
  selectCssRules,
} from './styles';
import './styles.scss';

const mapDispatchToProps = {
  generateTodayBillingReport: filesActions.generateTodayBillingReport,
};

const propTypes = {
  location: PropTypes.object.isRequired,
  devCenters: PropTypes.shape({
    forSelect: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })),
  }).isRequired,
  generateTodayBillingReport: PropTypes.func.isRequired,
};

const calendarPopperProps = {
  placement: 'bottom-start',
};

const getOptionValue = ({ value }) => value;
const getOptionLabel = ({ label }) => label;

const calendarStepControlsConfig = {
  step: 1,
  unitName: 'day',
};

const TodayBillingReport = ({
  location,
  devCenters: deliveryCenters,
  generateTodayBillingReport,
}) => {
  autoScrollToTop(location);

  const [date, setDate] = useState(moment());

  const [deliveryCenter, setDeliveryCenter] = useState([]);
  useEffect(
    () => setDeliveryCenter([]),
    [deliveryCenters]
  );

  const clickPDF = useCallback(
    () => {
      if (deliveryCenter != null) {
        generateTodayBillingReport({
          dateOfReport: formatDate(date),
          devcenterId: deliveryCenter.map((devcenter) => devcenter.value),
        });
      }
    },
    [generateTodayBillingReport, date, deliveryCenter]
  );

  const isPdfButtonActive = date.format('MM-DD-YYYY') > moment().format('MM-DD-YYYY') ? 'button--disabled' : 'button--cancel';

  const pdfButton = (
    <button
      type="button"
      className={`controls__create-button button ${isPdfButtonActive}`}
      onClick={clickPDF}
    >
      PDF
    </button>
  );

  return (
    <>
      <PageTitle
        className="today-billing-report__title"
        text="Daily Resource Billings Report"
      >
        {pdfButton}
      </PageTitle>

      <div className="today-billing-report__filters">
        <WrapperNg>
          <Calendar
            withStepControls
            withMonthSelecting
            minDetails="month"
            onChange={setDate}
            value={date}
            popperProps={calendarPopperProps}
            cssRules={yearPickerCssRules}
            stepControlsConfig={calendarStepControlsConfig}
          >
            <StyledWrapper cssRules={calendarItemCssRules}>
              {formatDate(date, FORMATS.MMDDYYYY)}
            </StyledWrapper>
          </Calendar>

          <FilterSelect
            items={deliveryCenters.forSelect}
            selected={deliveryCenter}
            onChange={setDeliveryCenter}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            placeholder="All Delivery Centers"
            placeholderLength="0"
            withChips={false}
            cssRules={selectCssRules}
            isMultiple
          />
        </WrapperNg>
      </div>

      <div className="today-billing-report__content">
        <h2>
          Please select Date and Delivery center and then generate a report.
        </h2>
        <p>
          Please note, the Date cannot be greater than today&apos;s Date.
        </p>
        {pdfButton}
      </div>

      <GoTop />
    </>
  );
};

TodayBillingReport.propTypes = propTypes;

export default connect(
  null,
  mapDispatchToProps
)(TodayBillingReport);
