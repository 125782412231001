import React from 'react';
import PropTypes from 'prop-types';

import Calendar from 'components/form-controls/form-calendar';

import './styles.scss';


const GeneratorCalendar = ({ fieldConfig, onChange }) => (
  <Calendar
    onChange={onChange}
    {...fieldConfig}
  />
);


GeneratorCalendar.propTypes = {
  onChange: PropTypes.func.isRequired,
  fieldConfig: PropTypes.shape({
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    cssRules: PropTypes.string,
  }).isRequired,
};


export default GeneratorCalendar;
