import {
  colorSecondaryGrayLight01,
  colorSecondaryGrayLight,
} from 'assets/styles/variables';

export const wrapperCssRules = `{
  min-height: 60vh;
  .table__content-wrapper {
    padding-bottom:0;
  }
}`;

export const formWrapperStylesTemplate = {
  formWrapperStyles: 'padding: 0;',
};

export const tableHeaderCssRules = {
  position: 'sticky',
  top: '6.6rem',
  zIndex: 1,
  backgroundColor: '#f2f2f2',
};

export const formHeadingCssRules = `
{
  grid-template-columns: minmax(0,105fr) minmax(0,140fr) repeat(11,minmax(0,85fr)) minmax(0,90fr);
  grid-column-gap: 0.8rem;
  grid-auto-rows: minmax(4.4rem,auto);
  box-sizing: border-box;
  min-width: auto;
  margin-top: 2rem;
  padding: 0 1.6rem;
  grid-row-gap: 0;
  margin-top: 0;
  .grid-unit:nth-last-child(1) {
    padding-right: 10px;
  }
}
`;

export const monthsFormHeadingCssRules = `{
  &.grid-unit .plain-text {
    text-align: right;
  }
}`;

export const formMainControlsConfig = {
  cssRules: `
    width: 100%;
    grid-template-columns: minmax(0, 205fr) minmax(0, 205fr) 770fr;
    margin-top: 3.5rem;
  `,
};

export const viewBoxCssRules = `
  display: block;
  margin: 1.5rem 0 1.5rem;
  margin-bottom: 1.4rem;
  border-bottom: 0;
  font-size: 1.2rem;
  line-height: 1.2rem;
  letter-spacing: -0.0025rem;
`;

export const multiCellWrapperCssRules = `
  display: flex;
  flex-direction: column;
`;

export const getFormRowWrapperCssRules = ({ index }) => `
grid-template-columns: minmax(0,105fr) minmax(0,140fr) repeat(11,minmax(0,85fr)) minmax(0,90fr);
  grid-column-gap: 0.8rem;
  grid-row-gap: 0.4rem;
  margin-top: 0;
  padding: 0.4rem 0 0;
  border-bottom: 1px solid ${colorSecondaryGrayLight};
  ${index % 2 !== 0 ? `background-color: ${colorSecondaryGrayLight01};` : ''}
  .details-form__units-wrapper:nth-last-child(1) {
    .text-input {
      padding-right: 5px;
    }
  }
`;

export const rateInputCssRules = `
  &&::after {
    height: 4.05rem;
  }
  && {
    align-items: flex-end;
    font-size: 1.2rem;
    line-height: 1.8rem;
    letter-spacing: -0.0025rem;
    & .text-input__input-field {
      max-width: 7.5rem;
      margin-top: 1.1rem;
      text-align: right;
    }
  }
`;
