import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
  ${({ cssRules }) => css`${cssRules}`}`;

export const StyledForm = styled.div`
  grid-area: detailsForm;
  padding: 1.6rem 0;
  ${({ cssRules }) => css`${cssRules}`}`;

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-areas: ${({ gridTemplateAreas }) => gridTemplateAreas};
  grid-column-gap: ${({ gridColumnGap }) => gridColumnGap || '2.8rem'};
  grid-row-gap: ${({ gridRowGap }) => gridRowGap || '0'};
  width: 100%;

  ${({ cssRules }) => css`${cssRules}`}
`;

export const StyledGridUnit = styled.div`
  grid-area: ${({ stylesTemplate: { gridArea } }) => gridArea};
`;

export const StyledTemplate = styled.div`
  display: grid;
  grid-template-columns: ${({ stylesTemplate: { columns } }) => columns};
  grid-template-rows: ${({ stylesTemplate: { rows } }) => rows};
  grid-column-gap: ${({ stylesTemplate: { gridColumnGap } }) => gridColumnGap || '4.8rem'};
  grid-row-gap: ${({ stylesTemplate: { gridRowGap } }) => gridRowGap || '0.8rem'};
  grid-auto-rows: ${({ stylesTemplate: { gridAutoRows } }) => gridAutoRows || 'minmax(4.4rem, auto)'};
  box-sizing: border-box;
  min-width:  ${({ minWidth }) => minWidth || 'auto'};
  margin-top: ${({ stylesTemplate: { marginTop } }) => marginTop || '2rem'};
  padding: ${({ stylesTemplate: { padding } }) => padding || '0 1.6rem'};

  & > *:nth-child(${({ stylesTemplate: { marginConfig } }) => marginConfig}) {
    margin-right: 1.6rem;
  }

  ${({ cssRules }) => css`${cssRules}`}
`;

export const StyledFormUnit = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
  margin: ${({ marginConfig }) => marginConfig || 0};
`;

export const SubGridTemplate = styled.div`
  display: grid;
  grid-template-columns: ${({ columnsNumber }) => '1fr '.repeat(columnsNumber).trim()};
  grid-column-gap: 1.6rem;
  box-sizing: border-box;
  outline: none;

  ${({ cssRules }) => css`${cssRules}`}
`;

export const StyledMainFormWrapper = styled(StyledWrapper)`
  grid-column-gap: 1.6rem;
  grid-row-gap: 4.1rem;

  ${({ cssRules }) => css`${cssRules}`}
`;

export const StyledControlsWrapper = styled(StyledWrapper)`
  width: auto;
  margin: 5.6rem 1.6rem 4rem;
  grid-column-gap: 4.8rem;
  font-size: 1.4rem;

  ${({ cssRules }) => css`${cssRules}`}
`;

export const StyledMultiColumnWrapper = styled.div`
  display: grid;
  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;

export const StyledTitleControlsWrapper = styled.div`
  padding-left: 1.6rem;

  ${({ cssRules }) => cssRules && css`${cssRules}`}`;
