import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import { ENTITY_NAME } from '../constants';

export const {
  GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS,
  GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS_SUCCESS,
  GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS_FAIL,
  CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA,
  CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA_SUCCESS,
  CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA_FAIL,
  UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA,
  UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA_SUCCESS,
  UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA_FAIL,
  DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA,
  DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA_SUCCESS,
  DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA_FAIL,
  ADD_RESOURCE_MANAGEMENT_CONTACT_DATA,
  REMOVE_RESOURCE_MANAGEMENT_CONTACT_DATA,
} = createTypes([
  ...async('GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS'),
  ...async('CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA'),
  ...async('UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA'),
  ...async('DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA'),
  'ADD_RESOURCE_MANAGEMENT_CONTACT_DATA',
  'REMOVE_RESOURCE_MANAGEMENT_CONTACT_DATA',
], ENTITY_NAME);

export const contactDataManagementActions = {
  getResourceManagementContactDataRecords: createAction(GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS),
  getResourceManagementContactDataRecordsSuccess: createAction(GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS_SUCCESS),
  getResourceManagementContactDataRecordsFail: createAction(GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS_FAIL, (errors) => ({ errors })),

  createResourceManagementContactData: createAction(CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA),
  createResourceManagementContactDataSuccess: createAction(CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA_SUCCESS),
  createResourceManagementContactDataFail: createAction(CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA_FAIL, (errors) => ({ errors })),

  updateResourceManagementContactData: createAction(UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA),
  updateResourceManagementContactDataSuccess: createAction(UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA_SUCCESS),
  updateResourceManagementContactDataFail: createAction(UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA_FAIL, (errors) => ({ errors })),

  deleteResourceManagementContactData: createAction(DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA),
  deleteResourceManagementContactDataSuccess: createAction(DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA_SUCCESS),
  deleteResourceManagementContactDataFail: createAction(DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA_FAIL, (errors) => ({ errors })),

  addResourceManagementContactData: createAction(ADD_RESOURCE_MANAGEMENT_CONTACT_DATA),
  removeResourceManagementContactData: createAction(REMOVE_RESOURCE_MANAGEMENT_CONTACT_DATA),
};
