import { ENTITY_NAME } from 'core/configurations/constants';
import { createAction } from 'redux-actions';
import { async, createTypes } from 'utils/helpers/actions';

export const clientRateCardUpdateNotificationsTypes = createTypes([
  ...async('GET_RATECARD_NOTIFICATIONS'),
  ...async('CHECK_RATECARD_NOTIFICATIONS_STATUS'),
  ...async('UPDATE_RATECARD_NOTIFICATIONS'),
  ...async('CREATE_RATECARD_NOTIFICATIONS_VERSION'),
  ...async('DELETE_RATECARD_NOTIFICATIONS_VERSION'),
  ...async('SEND_RATECARD_NOTIFICATIONS'),
  ...async('CLEAR_RATECARD_NOTIFICATIONS'),
  ...async('UPDATE_RATECARD_NOTIFICATIONS_STATUS'),
  'CHANGE_RATE_CARD_NOTIFICATIONS_FILTER',
  'STOP_CHECK_RATECARD_NOTIFICATIONS_STATUS',
], ENTITY_NAME);

export const clientRateCardUpdateNotifications = {
  sendRatecardNotificationsEmails: createAction(
    clientRateCardUpdateNotificationsTypes.SEND_RATECARD_NOTIFICATIONS,
  ),
  sendRatecardNotificationsEmailsSuccess: createAction(
    clientRateCardUpdateNotificationsTypes.SEND_RATECARD_NOTIFICATIONS_SUCCESS,
    (payload) => payload
  ),
  sendRatecardNotificationsEmailsFail: createAction(
    clientRateCardUpdateNotificationsTypes.SEND_RATECARD_NOTIFICATIONS_FAIL,
    ({ error }) => ({ error })
  ),
  getRateCardNotifications: createAction(
    clientRateCardUpdateNotificationsTypes.GET_RATECARD_NOTIFICATIONS,
    ({ year, version }) => ({ year, version })
  ),
  getRateCardNotificationsSuccess: createAction(
    clientRateCardUpdateNotificationsTypes.GET_RATECARD_NOTIFICATIONS_SUCCESS,
  ),
  getRateCardNotificationsFail: createAction(
    clientRateCardUpdateNotificationsTypes.GET_RATECARD_NOTIFICATIONS_FAIL,
    ({ error }) => ({ error })
  ),
  checkRateCardNotificationsStatus: createAction(
    clientRateCardUpdateNotificationsTypes.CHECK_RATECARD_NOTIFICATIONS_STATUS,
  ),
  checkRateCardNotificationsStatusSuccess: createAction(
    clientRateCardUpdateNotificationsTypes.CHECK_RATECARD_NOTIFICATIONS_STATUS_SUCCESS,
  ),
  checkRateCardNotificationsStatusFail: createAction(
    clientRateCardUpdateNotificationsTypes.CHECK_RATECARD_NOTIFICATIONS_STATUS_FAIL,
    ({ error }) => ({ error })
  ),
  updateRateCardNotificationsStatus: createAction(
    clientRateCardUpdateNotificationsTypes.UPDATE_RATECARD_NOTIFICATIONS_STATUS,
  ),
  updateRateCardNotificationsStatusFail: createAction(
    clientRateCardUpdateNotificationsTypes.UPDATE_RATECARD_NOTIFICATIONS_STATUS_FAIL,
  ),
  updateRateCardNotifications: createAction(
    clientRateCardUpdateNotificationsTypes.UPDATE_RATECARD_NOTIFICATIONS,
    ({ fields }) => ({ fields })
  ),
  updateRateCardNotificationsSuccess: createAction(
    clientRateCardUpdateNotificationsTypes.UPDATE_RATECARD_NOTIFICATIONS_SUCCESS,
    ({ rateCardNotificationsData }) => ({ rateCardNotificationsData })
  ),
  updateRateCardNotificationsFail: createAction(
    clientRateCardUpdateNotificationsTypes.UPDATE_RATECARD_NOTIFICATIONS_FAIL,
    ({ error }) => ({ error })
  ),
  // version
  createRatecardNotificationsVersion: createAction(
    clientRateCardUpdateNotificationsTypes.CREATE_RATECARD_NOTIFICATIONS_VERSION,
    ({ fields }) => ({ fields })
  ),
  createRatecardNotificationsVersionSuccess: createAction(
    clientRateCardUpdateNotificationsTypes.CREATE_RATECARD_NOTIFICATIONS_VERSION_SUCCESS,
    ({ rateCardNotificationsData, versions }) => ({
      rateCardNotificationsData,
      versions,
    })
  ),
  createRatecardNotificationsVersionFail: createAction(
    clientRateCardUpdateNotificationsTypes.CREATE_RATECARD_NOTIFICATIONS_VERSION_FAIL,
    ({ error }) => ({ error })
  ),
  deleteRatecardNotificationsVersion: createAction(clientRateCardUpdateNotificationsTypes.DELETE_RATECARD_NOTIFICATIONS_VERSION),
  deleteRatecardNotificationsVersionSuccess: createAction(clientRateCardUpdateNotificationsTypes.DELETE_RATECARD_NOTIFICATIONS_VERSION_SUCCESS),
  deleteRatecardNotificationsVersionFail: createAction(clientRateCardUpdateNotificationsTypes.DELETE_RATECARD_NOTIFICATIONS_VERSION_FAIL),
  clearRatecardNotifications: createAction(clientRateCardUpdateNotificationsTypes.CLEAR_RATECARD_NOTIFICATIONS),
  changeRateCardNotificationsFilter: createAction(clientRateCardUpdateNotificationsTypes.CHANGE_RATE_CARD_NOTIFICATIONS_FILTER),
  stopCheckRateCardNotificationsStatus: createAction(
    clientRateCardUpdateNotificationsTypes.STOP_CHECK_RATECARD_NOTIFICATIONS_STATUS,
  ),
};
