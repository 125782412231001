import gql from 'graphql-tag';

export const queryConfig = {
  devstaffContractManagementRecords: gql`
    query devstaffContractManagementRecords($devstaffId: Int!) {
      devstaffContractManagementRecords(devstaffId: $devstaffId){
        contractRecordId
        employmentType
        deliveryCenterId
        startDate
        expirationDate
        terminationDate
        fileName
        downloadUrl
        allowedForEdit
        allowedForDelete
      }
    }
  `,
  createContractManagementRecord: gql`
    mutation createContractManagementRecord(
      $fields: CreateDevstaffContractManagementRecordInput!, $devstaffId: Int!
    ) {
      createContractManagementRecord(fields: $fields, devstaffId: $devstaffId) {
        ok
        fileUploadData
        contractManagementRecord{
          contractRecordId
          employmentType
          deliveryCenterId
          startDate
          expirationDate
          terminationDate
          fileName
          downloadUrl
          allowedForEdit
          allowedForDelete
        }
      }
    }
  `,
  updateContractManagementRecord: gql`
    mutation updateContractManagementRecord($contractManagementRecordId: Int!,
      $fields: UpdateDevstaffContractManagementRecordInput!){
      updateContractManagementRecord(
        fields: $fields,
        contractManagementRecordId: $contractManagementRecordId
      ){
        ok,
        fileUploadData
        contractManagementRecord{
          contractRecordId
          employmentType
          deliveryCenterId
          startDate
          expirationDate
          terminationDate
          fileName
          downloadUrl
          allowedForEdit
          allowedForDelete
        }
      }
    }
  `,
  deleteContractManagementRecord: gql`
    mutation deleteContractManagementRecord($contractManagementRecordId: Int!){
      deleteContractManagementRecord(
        contractManagementRecordId: $contractManagementRecordId
      ){
        ok
      }
    }
  `,
  uploadContractManagementFile: gql`
    mutation uploadContractManagementFile($contractManagementRecordId: Int!){
      uploadContractManagementFile(
        contractManagementRecordId: $contractManagementRecordId
      ){
        ok
        contractManagementRecord {
          contractRecordId
        }
      }
    }
  `,
};
