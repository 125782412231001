/* eslint-disable no-tabs */
import gql from 'graphql-tag';

export const queryConfig = {
  getExchangeRates: gql`
    query exchangeRates {
      exchangeRates {
        year
        monthData {
          month
          usdRur
          usdPln
        }
      }
    }
  `,
  getCommissionRates: gql`
    query commissionRates {
      commissionRates {
        userId
        fullName
        records {
          recordId
          userId
          date
          reportsTo
          reportsToId
          baseSal
          commissions {
            commDirect
            commDirect1
            commDirect2
            commAm
          }
          stage1 {
            stage1Days
            stage1Perc
          }
          stage2 {
            stage2Days
            stage2Perc
          }
        }
      }
    }
  `,

  removeCommissionRecord: ({ recordId }) => `mutation {
		deleteCommissionRatesRecord(recordId: ${recordId}){
			ok,
		}
	}`,

  createCommissionRecord: ({ userId }) => `mutation {
		createCommissionRatesRecord(userId: ${userId}){
			ok,
			commissionRatesRecord{
				userId,
				fullName,
				records{
					recordId,
					userId,
					date,
					reportsTo,
					reportsToId,
					baseSal,
					commissions{
						commDirect,
						commDirect1,
						commDirect2,
						commAm,
					}
					stage1{
						stage1Days,
						stage1Perc,
					}
					stage2{
						stage2Days,
						stage2Perc,
					}
				},
			}
		}
	}`,
  getHolidays: gql`
    query getNonWorkingDays($year: Int) {
      nonWorkingDays(year: $year) {
        recordId
        devcenter
        date
        description
      }
    }
  `,
  createNonWorkingDaysRecord: gql`
    mutation createNonWorkingDaysRecord($fields: CreateNonWorkingDaysInput!) {
      createNonWorkingDaysRecord(fields: $fields) {
        ok
        nonWorkingDaysRecords {
          recordId
          devcenter
          date
          description
        }
      }
    }
  `,
  removeHolidaysRecord: ({ recordId }) => gql`mutation {
		deleteNonWorkingDaysRecord(recordId: ${recordId}){
			ok,
		}
	}`,
  getRateCard: (ratecardId) => gql`{
    node(id: "RateCardManagement: ${ratecardId}") {
      ... on RateCardManagement {
        year,
        recordId,
        rateCardName,
        billingAgent,
        effectiveDate,
        emailContent,
        attachment {
          title
          downloadUrl
        },
        rates {
          seniority,
          ratecardId,
          devcenter,
          rateId,
          ratePtm,
          rateHr,
          rateDisc,
          ratePlatinum,
          rateMo,
          rateMoPlatinum
        }
      }
    }
  }`,
  getRateCards: gql`
    query getRateCardsManagement($year: Int) {
      rateCardsManagementData(year: $year) {
        rateCardsManagement {
          year
          recordId
          rateCardName
          billingAgent
          effectiveDate
          isActive
          emailContent
          attachment {
            title
            downloadUrl
          }
          devcenters {
            value: id
            label: shortname
            active
          }
          rates {
            seniority
            devcenter
            ratePtm
            rateHr
            rateDisc
            ratePlatinum
            rateMo
            rateMoPlatinum
          }
        }
        lastRatesVersion {
          devcenter
          rateDisc
          rateHr
          rateMo
          rateMoPlatinum
          ratePlatinum
          ratePtm
          seniority
        }
      }
    }
  `,

  getEmailTemplates: gql`
    query ($ids: [Int], $year: Int) {
      emailTemplates(ids: $ids, year: $year) {
        emailTemplateId
        subject
        name
        year
        content
        fileId
          file {
            id
            title
            fileName
            downloadUrl
          }
      }
    }
  `,
  createEmailTemplateRecord: gql`
    mutation createEmailTemplate($fields: CreateEmailTemplateInput!) {
      createEmailTemplate(fields: $fields) {
        emailTemplate {
          emailTemplateId
          subject
          name
          year
          content
          fileId
          file {
            id
            title
            fileName
            downloadUrl
          }
        }
        ok
      }
    }
  `,
  updateEmailTemplateRecord: gql`
    mutation updateEmailTemplate(
      $emailTemplateId: Int!
      $fields: UpdateEmailTemplateInput!
    ) {
      updateEmailTemplate(emailTemplateId: $emailTemplateId, fields: $fields) {
        emailTemplate {
          emailTemplateId
          name
          subject
          content
          year
          fileId
          file {
            id
            title
            fileName
            downloadUrl
          }
        }
      }
    }
  `,
  deleteEmailTemplateRecord: gql`
    mutation deleteEmailTemplate($emailTemplateId: Int!) {
      deleteEmailTemplate(emailTemplateId: $emailTemplateId) {
        ok
      }
    }
  `,

  getCommonData: gql`
    {
      devcenters {
        value: devcenterId
        label: shortname
        active
      }
    }
  `,
  createRateCardRecord: gql`
    mutation createRateCardRecord(
      $rateCard: CreateRateCardManagementInput!
      $year: Int!
    ) {
      createRateCardRecord(ratecard: $rateCard, year: $year) {
        ok
        rateCardsRecords {
          rateCardsManagement {
            year
            recordId
            rateCardName
            billingAgent
            effectiveDate
            emailContent
            isActive
            attachment {
              title
              downloadUrl
            }
            rates {
              seniority
              devcenter
              ratePtm
              rateHr
              rateDisc
              ratePlatinum
              rateMo
              rateMoPlatinum
            }
          }
          lastRatesVersion {
            devcenter
            rateDisc
            rateHr
            rateMo
            rateMoPlatinum
            ratePlatinum
            ratePtm
            seniority
          }
        }
      }
    }
  `,
  updateRateCardRecord: gql`
    mutation updateRateCardRecord(
      $rateCard: UpdateRateCardManagementInput!
      $year: Int!
    ) {
      updateRateCardRecord(ratecard: $rateCard, year: $year) {
        ok
        updatedRateCards {
          year
          recordId
          rateCardName
          billingAgent
          effectiveDate
          isActive
          emailContent
          attachment {
            title
            downloadUrl
          }
          rates {
            seniority
            devcenter
            ratePtm
            rateHr
            rateDisc
            ratePlatinum
            rateMo
            rateMoPlatinum
          }
        }
        lastRatesVersion {
          devcenter
          rateDisc
          rateHr
          rateMo
          rateMoPlatinum
          ratePlatinum
          ratePtm
          seniority
        }
      }
    }
  `,
  deleteRateCardRecord: ({ recordId, year }) => gql`mutation {
		deleteRateCardRecord(recordId: ${recordId}, year: ${year}){
			ok,
      lastRatesVersion {
        devcenter
        rateDisc
        rateHr
        rateMo
        rateMoPlatinum
        ratePlatinum
        ratePtm
        seniority
      }
		}
	}`,
  rateCardFileUpload: (variables) => JSON.stringify({
    query: `mutation rateCardFileUpload ($fields: UploadRateCardFileInput!) {
      rateCardFileUpload(ratecardFile: $fields){
        rateCardFileUploadData
        ok
        file {
          bucketKey
          downloadUrl
          fileName
          id
          isUploaded
          ratecardId
          title
          uploadedDate
        }
      }
    }`,
    variables,
  }),
  uploadFile: (variables) => JSON.stringify({
    query: `mutation uploadFile(
        $clientFile: UploadClientFileInput
        $taskOrderFile: UploadTaskOrderFileInput
        $emailFile: UploadEmailFileInput
        $channelPartnerFile: UploadChannelPartnerFileInput
    ) {
        uploadFile(
            clientFile: $clientFile
            taskOrderFile: $taskOrderFile
            emailFile: $emailFile
            channelPartnerFile: $channelPartnerFile
        ) {
            ok
            file {
                id
                isUploaded
                fileName
                downloadUrl
            }
            emailFileUploadData
        }
    }`,
    variables,
  }),
  getHiqoContracts: gql`
    query hiqoContracts {
      hiqoContracts {
        id
        name
        devcenter
        customer
        dateFrom
      }
    }
  `,
  createHiqContractsRecord: gql`
    mutation createHiqoContracts($fields: CreateHiqoContractInput!) {
      createHiqoContractsRecord(fields: $fields) {
        hiqoContractRecords {
          id
          name
          devcenter
          customer
          dateFrom
        }
        ok
      }
    }
  `,
  removeHiqoContractsRecord: ({ recordId }) => gql`mutation{
		deleteHiqoContractsRecord(recordId: ${recordId}){
			ok,
		}
	}`,

  getDiscountSurchargeRates: gql`
    query discountsAndSurcharges(
      $year: Int
      $month: Int
      $deliveryCenterIds: [Int]
    ) {
      discountsAndSurcharges: discountsAndSurcharges(
        year: $year
        month: $month
        deliveryCenterIds: $deliveryCenterIds
      ) {
        billLock
        discountAndSurchargesRecords {
          discountsAndSurchargesRecordId
          year
          month
          deliveryCenterId
          percentage
        }
      }
    }
  `,

  updateDiscountSurchargeRates: gql`
    mutation UpdateDiscountsAndSurchargesRecord(
      $fields: UpdateDiscountAndSurchargesInput!
    ) {
      updateDiscountsAndSurchargesRecord(fields: $fields) {
        ok
        discountsAndSurchargesData {
          billLock
          discountAndSurchargesRecords {
            discountsAndSurchargesRecordId
            year
            month
            deliveryCenterId
            percentage
          }
        }
      }
    }
  `,
};
