import gql from 'graphql-tag';
export const queryConfig = {
  getRateCardNotifications: gql`
    query rateCardNotifications($year: Int, $version: String) {
      rateCardNotifications(year: $year, version: $version) {
        versions {
          versionId
          allowedForDelete
        }
        billingAgentIds {
          billingAgentId
          shortName
        }
        notifications {
          notificationId
          notificationTitle
        }
        rateCards {
          ratecardId
          name
          billingAgentId
        }
        rateCardNotificationsData {
          name
          clientId
          country
          billingAgent
          rateCard
          notification
          salutation
          email
          processed
          emailed
          status
          accountManagerId
          id
          lineId
          version
          emailDate
        }
        statuses {
          id
          status
        }
      }
    }
  `,
  sendRatecardNotificationsEmails: gql`
    mutation sendRatecardNotificationsEmails(
      $fields: RCNotificationsEmailsInput!
    ) {
      sendRatecardNotificationsEmails(fields: $fields) {
        ok
      }
    }
  `,
  updateRateCardNotifications: gql`
    mutation updateRateCardNotifications(
      $fields: UpdateRateCardNotificationsInput!
    ) {
      updateRatecardNotifications(fields: $fields) {
        ok
        ratecardNotifications {
          rateCardNotificationsData {
            name
            clientId
            country
            billingAgent
            rateCard
            notification
            salutation
            email
            processed
            emailed
            status
            accountManagerId
            id
            lineId
            version
            emailDate
          }
        }
      }
    }
  `,
  createRatecardNotificationsVersion: gql`
    mutation CreateRatecardNotificationsVersion(
      $fields: CreateRateCardNotificationsInput!
    ) {
      createRatecardNotificationsVersion(fields: $fields) {
        ok
        ratecardNotifications {
          rateCardNotificationsData {
            name
            clientId
            country
            billingAgent
            rateCard
            notification
            salutation
            email
            processed
            emailed
            status
            accountManagerId
            id
            lineId
            version
            emailDate
          }
          versions{
            versionId
            allowedForDelete
          }
        }
      }
    }
  `,
  deleteRateCardNotificationsVersion: gql`
    mutation deleteRatecardNotificationsVersion($fields: DeleteRateCardNotificationsInput!) {
      deleteRatecardNotificationsVersion(fields: $fields)
      {
        ok
      }
    }
  `,
  rateCardNotificationsStatus: gql`
    query rateCardNotificationsStatus($year: Int, $version: String) {
      rateCardNotificationsStatus(year: $year, version: $version) {
        recordsInProgress,
        inProgress
      }
    }
  `,
  updateRateCardNotificationsStatus: gql`
    mutation updateRatecardNotificationsStatus($fields: UpdateRateCardNotificationsStatusInput!) {
      updateRatecardNotificationsStatus(fields: $fields)
      {
        ok
      }
    }
  `,
};
