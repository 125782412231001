import { get, isEmpty, isEqual, unset } from 'lodash';
import { toast } from 'react-toastify';
import { generatePaths } from 'utils/generatePath';

export const getParsedValues = (fields, initialValues) => (
  Object.entries(fields).reduce((acc, [key, value]) => (
    initialValues && initialValues[key] === value ?
      acc :
      {
        ...acc,
        [key]: value,
      }
  ), {})
);

export const removeEmptyValues = (fields) => (
  Object.entries(fields).reduce((acc, [key, value]) => (
    !value ?
      acc :
      {
        ...acc,
        [key]: value,
      }
  ), {})
);

export class RemoteFormsController {
  static forms = {};

  static formContainers = {};

  static controllers = {};

  static hasFormChanges = false;

  static formsAdditionalFields = {};

  static formsWithChanges = new Set();

  static toastIds = new Map();

  constructor(onSubmit) {
    this.onSubmit = onSubmit;
  }

  getControlsState = () => RemoteFormsController.hasFormChanges

  checkControlsState = () => {
    const hasFormChanges = !!RemoteFormsController.formsWithChanges.size;

    if (RemoteFormsController.hasFormChanges !== hasFormChanges) {
      RemoteFormsController.hasFormChanges = hasFormChanges;
      Object.values(RemoteFormsController.controllers).forEach((updateConfig) => {
        updateConfig({ hasFormChanges });
      });
    }
  }

  registerControl = ({ updateConfig }) => {
    const controllerId = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();

    RemoteFormsController.controllers[controllerId] = updateConfig;

    return () => unset(RemoteFormsController.controllers, controllerId);
  }

  registerForm = ({
    form,
    formId,
    formContainerRef,
    additionalFields,
  }) => {
    RemoteFormsController.forms[formId] = form;
    RemoteFormsController.formContainers[formId] = formContainerRef;
    RemoteFormsController.formsAdditionalFields[formId] = additionalFields || {};

    return () => {
      unset(RemoteFormsController.formContainers, formId);
      RemoteFormsController.formsWithChanges.delete(formId);
      this.checkControlsState();
    };
  };

  revalidateToastIds = (errors) => {
    const errorsContainerKey = Object.entries(errors)[0];
    const result = Object.entries(errors).flatMap(([key, value]) => generatePaths(value, `${key}`));
    const toastIdsForCurrentForm = RemoteFormsController.toastIds.get(errorsContainerKey[0]);

    if (isEqual(result, toastIdsForCurrentForm)) {
      return;
    }

    if (RemoteFormsController.toastIds.get(errorsContainerKey[0])) {
      const dismissIds = toastIdsForCurrentForm.filter((toastId) => !result.includes(toastId));

      dismissIds.forEach((toastId) => toast.dismiss(toastId));
    }

    RemoteFormsController.toastIds.set(errorsContainerKey[0], result);
  };

  handleChanges = ({ formId, hasFormChanges }) => {
    if (RemoteFormsController.forms[formId]) {
      const isChangesDetected = RemoteFormsController.formsWithChanges.has(formId);

      if (hasFormChanges) {
        RemoteFormsController.formsWithChanges.add(formId);
      } else if (isChangesDetected) {
        RemoteFormsController.formsWithChanges.delete(formId);
      }
    }

    this.checkControlsState();
  };

  resetForm = () => {
    RemoteFormsController.formsWithChanges.forEach((formId) => {
      RemoteFormsController.forms[formId].resetForm();
    });
  }

  validateForm = (formId) => RemoteFormsController.forms[formId].validateForm();

  submitForms = () => {
    const validators = [];
    const formsState = [];

    RemoteFormsController.formsWithChanges.forEach((formId) => {
      const form = get(RemoteFormsController, `forms[${formId}]`);
      if (form) {
        const {
          validateForm,
          initialValues,
          state: { values },
          setSubmitting,
        } = form;
        const additionalFields = get(RemoteFormsController, `formsAdditionalFields[${formId}]`);

        formsState.push({ initialValues, values, additionalFields });
        validators.push(validateForm());
        setSubmitting(true);
      }
    });

    Promise
      .all(validators)
      .then((validationResults) => {
        let hasErrors = false;
        let currentFormContainer = null;

        validationResults.forEach((result) => {
          if (!isEmpty(result)) {
            hasErrors = true;
            const formId = Object.keys(result)[0];

            currentFormContainer = get(
              RemoteFormsController,
              `formContainers[${formId}].current`
            );
          }
        });

        if (!hasErrors) {
          this.onSubmit({ formsState, controllerBag: this });
        } else if (currentFormContainer) {
          const { bottom } = currentFormContainer.getBoundingClientRect();
          const { scrollY, scrollTo, innerHeight } = window;
          scrollTo({
            top: (scrollY + bottom) - (innerHeight - 90),
            behavior: 'smooth',
          });
        }
      });
  }
}
