export const MODALS = {
  LOADER: 'LOADER',
  MANAGE_PTO_MODAL: 'MANAGE_PTO_MODAL',
  SEND_REVIEW_MODAL: 'SEND_REVIEW_MODAL',
  UPDATE_REVIEWS_MODAL: 'UPDATE_REVIEWS_MODAL',
  CONFIRM_ACTION_MODAL: 'CONFIRM_ACTION_MODAL',
  SEND_FOR_SIGNATURE_MODAL: 'SEND_FOR_SIGNATURE_MODAL',
  EDIT_MSA_MODAL: 'EDIT_MSA_MODAL',
  PREVIEW_MODAL: 'PREVIEW_MODAL',
};
