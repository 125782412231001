import React from 'react';

import PropTypes from 'prop-types';

const Counter = ({ data: { total, selected, percentage }, className, contentClassName }) => (
  <div data-id="Counter Filtered Items" className={className}>
    <div className={contentClassName}>
      {
        `All items: ${total} / Selected: ${selected} (${percentage}%)`
      }
    </div>
  </div>
);

Counter.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number.isRequired,
    selected: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }).isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

Counter.defaultProps = {
  className: '',
  contentClassName: '',
};

export default Counter;
