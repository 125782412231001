import React from 'react';

import PropTypes from 'prop-types';

import { ToastContainer } from 'react-toastify';
import styled, { css } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

import './styles.scss';

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast:first-child {
    margin-top: 2.4rem;
  }

  ${({ cssRules }) => css`${cssRules}`}
  ${({ isHidden }) => isHidden ? 'display: none;' : ''}
`;

const Toast = ({ containerId, cssRules, isHidden, uniqueKey }) => (
  <StyledToastContainer
    type="default"
    hideProgressBar
    newestOnTop={false}
    autoClose={false}
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    draggable={false}
    pauseOnHover={false}
    closeButton={false}
    enableMultiContainer
    containerId={containerId}
    cssRules={cssRules}
    isHidden={isHidden}
    key={uniqueKey}
    icon={false}
  />
);

Toast.propTypes = {
  isHidden: PropTypes.bool,
  cssRules: PropTypes.string,
  containerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  uniqueKey: PropTypes.string,
};

Toast.defaultProps = {
  cssRules: '',
  isHidden: false,
  uniqueKey: '',
};

export default Toast;
