import React, { useState, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';


import Calendar from 'components/calendar';
import Controls from 'components/details-controls';
import FilterSelect from 'components/filter-select';
import { selectUserId as userIdSelector } from 'core/auth/selectors';
import { billingReportsActions } from 'core/billing-reports/actions';
import {
  selectSalesData,
  selectSalesReportDetails,
  selectIsFetchingSalesList,
} from 'core/billing-reports/selectors';
import GoTop from 'elements/go-top-button';
import StyledWrapper from 'elements/styled-wrapper';
import LayoutBodyContent from 'layouts/layout-body';
import { get } from 'lodash';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { createStructuredSelector } from 'reselect';
import { autoScrollToTop } from 'utils/react';


const SalesReport = ({
  location,
  userId,
  salesData,
  isFetching,
  entityName,
  orderRules,
  setSalesId,
  changeOrder,
  changeFilter,
  resetFilters,
  getSalesList,
  selectedReportDate,
  isFetchingSalesList,
  setBillingSummaryReportData,
  salesReportDetails,
}) => {
  autoScrollToTop(location);
  const [selectedUserId, selectUserId] = useState(userId);
  const displayedDate = useMemo(() => selectedReportDate.format('MMMM YYYY'), [selectedReportDate]);
  const { map, list } = salesData;
  const {
    body,
    controls,
    selectCssRules,
    controlsHeading,
    controlsCssRules,
    calendarCssRules,
    calendarItemCssRules,
    controlsWrapperCssRules,
  } = salesReportDetails;
  const actions = {
    resetFilters,
  };
  const wrapperCssRules = `
    padding-bottom: 10rem;
  `;

  useEffect(() => {
    getSalesList();
  }, []);

  const onSelectSales = ({ value }) => {
    selectUserId(value);
    setSalesId(value);
  };

  const setDate = (selectedDate) => {
    setBillingSummaryReportData({
      selectedDate,
    });
  };

  const layoutProps = {
    changeOrder,
    orderRules,
    entityName,
    isFetching,
    resetFilters,
    changeFilter,
  };
  const selectedSales = get(map, selectedUserId, list[0]);
  const selectedSalesName = get(selectedSales, 'label', '');

  return (
    <>
      <Controls
        controlsHeading={controlsHeading(selectedSalesName)}
        cssRules={controlsCssRules}
      >
        {
          controls &&
          <>
            {
              controls.map(({ title: buttonTitle, actionName }) => (
                <button
                  key={buttonTitle}
                  className="controls__create-button button button--cancel client-details-controls__button--fixed-size"
                  onClick={() => actions[actionName]()}
                >
                  {buttonTitle}
                </button>
              ))
            }
          </>
        }

        <StyledWrapper
          cssRules={controlsWrapperCssRules}
        >
          <Calendar
            withStepControls
            withMonthSelecting
            minDetails="year"
            onChange={setDate}
            stepControlsConfig={{
              step: 1,
              unitName: 'month',
            }}
            cssRules={calendarCssRules}
            value={selectedReportDate}
            popperProps={{
              placement: 'bottom-start',
            }}
          >
            <StyledWrapper
              cssRules={calendarItemCssRules}
            >
              {displayedDate}
            </StyledWrapper>
          </Calendar>
          <FilterSelect
            cssRules={selectCssRules}
            withChips={false}
            items={list}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={onSelectSales}
            placeholder="Select sales"
            isFetchingData={isFetchingSalesList}
            placeholderLength="12ch"
            selected={selectedSales}
          />
        </StyledWrapper>
      </Controls>

      <LayoutBodyContent
        wrapperCssRules={wrapperCssRules}
        body={body}
        unitActions={actions}
        {...layoutProps}
      />

      <GoTop />

    </>

  );
};


SalesReport.propTypes = {
  location: PropTypes.object.isRequired,
  selectedReportDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]).isRequired,
  setBillingSummaryReportData: PropTypes.func.isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  entityName: PropTypes.string.isRequired,
  getSalesList: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

SalesReport.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  userId: userIdSelector,
  salesData: selectSalesData,
  salesReportDetails: selectSalesReportDetails,
  isFetchingSalesList: selectIsFetchingSalesList,
});

const mapDispatchToProps = {
  setSalesId: billingReportsActions.setSalesId,
  getSalesList: billingReportsActions.getSalesList,
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesReport);
