import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Magnifier from 'assets/img/icon-magnifier.svg';

import './styles.scss';

const Search = ({ term, onChange, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState(term);

  useEffect(() => {
    if (searchTerm !== term && term === '') {
      setSearchTerm('');
    }
  }, [term]);

  // This is default behavior of clear button - leave field empty
  const onClear = useCallback(() => {
    onValueChange({ target: { value: '' } });
  }, []);

  // This is behavior when need to restore initial values
  // const onReset = useCallback(() => {
  //   onValueChange({ target: { value: term } });
  // }, [term]);

  const onValueChange = useCallback(({ target }) => {
    setSearchTerm(target.value);
    onChange(target.value);
  }, []);

  return (
    <div className="search">

      <img
        className="search__icon"
        src={Magnifier}
        alt="Magnifier icon"
      />

      <input
        className={classNames(
          'search__input',
          { 'search__input--with-term': !!searchTerm }
        )}
        value={searchTerm}
        placeholder={placeholder}
        onChange={onValueChange}
      />

      {
        !!searchTerm &&
        <button
          className="search__button-clear button button--close"
          onClick={onClear}
        />
      }
    </div>
  );
};


Search.propTypes = {
  term: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

Search.defaultProps = {
  placeholder: 'Search',
  term: '',
  onChange: () => null,
};


export default Search;
