import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as DeleteSVG } from 'assets/img/icon-delete.svg';
import {
  colorSecondaryGrayLight,
  colorWhite,
} from 'assets/styles/variables';
import classnames from 'classnames';
import styled, { css } from 'styled-components';

// TODO: Prefer JSS over SCSS
import './styles.scss';

const StyledDeleteButton = styled.button`
  text-align: left;
  text-decoration: ${({ withBody }) => withBody ? 'none' : 'underline'};

  &[isDisabled] {
    color: ${colorWhite};
    background: ${colorSecondaryGrayLight};
    border-color: ${colorSecondaryGrayLight};
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6;
  }

  ${({ cssRules }) => css`${cssRules}`}
`;

export const DeleteButton = ({
  withIcon,
  onClick,
  isActive,
  withBody,
  isHidden,
  className,
  cssRules,
  withStopPropagation,
  isDisabled,
}) => {
  const handleClick = (e) => {
    if (withStopPropagation) {
      e.stopPropagation();
    }
    onClick();
  };

  return (
    <StyledDeleteButton // button button--cancel
      className={
        classnames(
          'delete-button',
          { button: withBody },
          { 'button--cancel': withBody },
          { 'delete-button--active': isActive },
          { 'delete-button--hidden': isHidden },
          { 'delete-button--with-body': withBody },
          { 'delete-button--disabled': isDisabled },
          className,
        )
      }
      type="button"
      onClick={handleClick}
      cssRules={cssRules}
      withBody={withBody}
      isDisabled={isDisabled}
    >
      {
        withIcon &&
        <DeleteSVG
          className="delete-button__icon"
        />
      }
    </StyledDeleteButton>
  );
};

DeleteButton.propTypes = {
  withIcon: PropTypes.bool,
  cssRules: PropTypes.string,
  withBody: PropTypes.bool,
  isActive: PropTypes.bool,
  isHidden: PropTypes.bool,
  isDisabled: PropTypes.bool,
  withStopPropagation: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

DeleteButton.defaultProps = {
  withStopPropagation: false,
  isActive: false,
  withBody: false,
  isHidden: false,
  isDisabled: false,
  className: '',
  cssRules: '',
  withIcon: false,
};
