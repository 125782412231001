import React, { useMemo, useRef } from 'react';

import { useDispatch } from 'react-redux';

import { useForm } from 'react-hook-form';

import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';

import classNames from 'classnames';
import { Dialog } from 'components/dialog';

import { userManagementActions } from 'core/configurations/user-management';
import { validationSchema } from 'forms/user-management-form/utils/validation';
import { useEffectOnlyOnUpdate } from 'hooks';
import { fieldHeadings } from 'layouts/user-management/constants';

import { isString, get } from 'lodash';

import { InputController } from './components/controllers/input';
import { SelectController } from './components/controllers/select';
import { Controls } from './components/controls';
import { LoginHistoryTable } from './components/login-history-table';
import classes from './form.module.scss';
import { FORM_ID } from './utils/constants';

export const UserManagementForm = ({
  userId,
  defaultValues,
  billingAgents,
  roles,
  loginLogsHistory,
  actions,
  defaultActions,
  formState,
  recordState,
  isAdminRole,
}) => {
  const dispatch = useDispatch();
  const dialogRef = useRef(null);
  const optionsData = useMemo(() => ({
    role: roles,
    billingAgent: billingAgents,
  }), [billingAgents, roles]);

  const {
    handleSubmit,
    control,
    reset,
    formState: {
      dirtyFields,
      isDirty,
    },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const isEditMode = isString(userId) || isDirty;

  const handleSelectChange = (field, selectedData) => {
    const options = optionsData[field.name];
    if (!options) {
      return {
        value: null,
        label: null,
      };
    }
    const selectedOption = options.find((option) => option.value === selectedData);
    return field.onChange(selectedOption);
  };

  useEffectOnlyOnUpdate(() => {
    if (formState.isFormSubmitted) {
      reset(defaultValues);

      dispatch(userManagementActions.clearFormStateByUserId({
        userId,
      }));
    }
  }, [formState]);

  return (
    <form
      data-id={FORM_ID}
      className={classNames({
        [classes.disabledForm]: recordState.isDisabled,
      })}
    >
      <ul
        className={classNames(classes.list, {
          [classes.disabledList]: recordState.isDisabled,
          [classes.deactivatedUser]: recordState.isDeactivated,
          [classes.activeUser]: recordState.isActive,
          [classes.isAdmin]: isAdminRole && recordState.isUser,
          [classes.controlBlock]: !isEditMode && recordState.isUser,
        })}
      >
        {Object.keys(defaultValues).map((value) => (
          <li
            key={`${value}_${userId}`}
            data-id={fieldHeadings[value]}
          >
            {
              optionsData[value] ? (
                <SelectController
                  handleSelectChange={handleSelectChange}
                  options={optionsData[value]}
                  value={value}
                  control={control}
                  isLocked={
                    recordState.isDisabled ||
                    recordState.isDeactivated ||
                    (isAdminRole && recordState.isUser && value === 'role')
                  }
                />
              ) : (
                <InputController
                  dialogRef={dialogRef}
                  value={value}
                  control={control}
                  showLoginHistory={Boolean(loginLogsHistory.length)}
                />
              )
            }
          </li>
        ))}
        <Controls
          reset={reset}
          userId={userId}
          formState={formState}
          actions={isEditMode ? defaultActions : actions}
          handleSubmit={handleSubmit}
          dirtyFields={dirtyFields}
          email={get(defaultValues, 'email', '')}
        />
      </ul>
      <Dialog key={userId} ref={dialogRef} containerId={FORM_ID} title="Login Log History">
        <LoginHistoryTable loginLogsHistory={loginLogsHistory} />
      </Dialog>
    </form>
  );
};

UserManagementForm.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  defaultValues: PropTypes.shape({}).isRequired,
  billingAgents: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  })).isRequired,
  defaultActions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  })).isRequired,
  recordState: PropTypes.shape({
    isActive: PropTypes.bool.isRequired,
    isDeactivated: PropTypes.bool.isRequired,
    invitationalInProgress: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isUser: PropTypes.bool.isRequired,
  }).isRequired,
  formState: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
    isFormSubmitted: PropTypes.bool.isRequired,
    isFormError: PropTypes.bool.isRequired,
  }).isRequired,
  loginLogsHistory: PropTypes.arrayOf(PropTypes.shape({
    loginDate: PropTypes.string.isRequired,
    ip: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  })),
  isAdminRole: PropTypes.bool.isRequired,
};

UserManagementForm.defaultProps = {
  loginLogsHistory: [],
};
