import { ENTITY_NAME } from 'core/configurations/user-management/constants';
import { createAction } from 'redux-actions';
import { async, createTypes } from 'utils/helpers/actions';

export const actionTypes = createTypes([
  'CHANGE_FILTER_BY_COLUMNS',
  'CLEAR_FILTER_BY_COLUMNS',
  'CHANGE_SORTING_BY_COLUMNS',
  'TOGGLE_DISPLAY_ACTIVE_USERS',
  'CLEAR_CONTROL_FILTERS',
  'ADD_NEW_USER_RECORD',
  'REMOVE_NEW_USER_RECORD',
  'CLEAR_FORM_STATE_BY_USER_ID',
  'CLEAR_USER_MANAGEMENT_STATE',
  ...async('FETCH_USER_RECORDS'),
  ...async('CREATE_USER_RECORD'),
  ...async('UPDATE_USER_RECORD'),
  ...async('DELETE_USER_RECORD'),
], ENTITY_NAME);

const {
  CHANGE_FILTER_BY_COLUMNS,
  CHANGE_SORTING_BY_COLUMNS,
  CLEAR_FILTER_BY_COLUMNS,
  TOGGLE_DISPLAY_ACTIVE_USERS,
  CLEAR_CONTROL_FILTERS,
  FETCH_USER_RECORDS,
  FETCH_USER_RECORDS_SUCCESS,
  FETCH_USER_RECORDS_FAIL,
  CREATE_USER_RECORD,
  CREATE_USER_RECORD_SUCCESS,
  CREATE_USER_RECORD_FAIL,
  UPDATE_USER_RECORD,
  UPDATE_USER_RECORD_SUCCESS,
  UPDATE_USER_RECORD_FAIL,
  DELETE_USER_RECORD,
  DELETE_USER_RECORD_SUCCESS,
  DELETE_USER_RECORD_FAIL,
  ADD_NEW_USER_RECORD,
  REMOVE_NEW_USER_RECORD,
  CLEAR_FORM_STATE_BY_USER_ID,
  CLEAR_USER_MANAGEMENT_STATE,
} = actionTypes;

export const userManagementActions = {
  fetchUserRecords: createAction(FETCH_USER_RECORDS),
  fetchUserRecordsSuccess: createAction(FETCH_USER_RECORDS_SUCCESS),
  fetchUserRecordsFail: createAction(FETCH_USER_RECORDS_FAIL),
  createUserRecord: createAction(CREATE_USER_RECORD),
  createUserRecordSuccess: createAction(CREATE_USER_RECORD_SUCCESS),
  createUserRecordFail: createAction(CREATE_USER_RECORD_FAIL),
  updateUserRecord: createAction(UPDATE_USER_RECORD),
  updateUserRecordSuccess: createAction(UPDATE_USER_RECORD_SUCCESS),
  updateUserRecordFail: createAction(UPDATE_USER_RECORD_FAIL),
  deleteUserRecord: createAction(DELETE_USER_RECORD),
  deleteUserRecordSuccess: createAction(DELETE_USER_RECORD_SUCCESS),
  deleteUserRecordFail: createAction(DELETE_USER_RECORD_FAIL),
  changeFilterByColumns: createAction(CHANGE_FILTER_BY_COLUMNS),
  clearFilterByColumns: createAction(CLEAR_FILTER_BY_COLUMNS),
  toggleDisplayActiveUsers: createAction(TOGGLE_DISPLAY_ACTIVE_USERS),
  clearControlsFilters: createAction(CLEAR_CONTROL_FILTERS),
  addNewUserRecord: createAction(ADD_NEW_USER_RECORD),
  removeNewUserRecord: createAction(REMOVE_NEW_USER_RECORD),
  clearFormStateByUserId: createAction(CLEAR_FORM_STATE_BY_USER_ID),
  changeSortingByColumns: createAction(CHANGE_SORTING_BY_COLUMNS),
  clearUserManagementState: createAction(CLEAR_USER_MANAGEMENT_STATE),
};
