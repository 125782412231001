import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { get, isEmpty } from 'lodash';

import styles from './styles.module.scss';

const TEXTS = {
  OPTIONAL_HEADER_LABEL: '(Optional)',
};

export const ListOfForms = ({
  title,
  columns,
  data,
  renderAddButton,
  renderForm,
  onRemoveRecord,
  onAddRecord,
  isFormSubmitted,
  newRecord,
  onDeleteHandler,
  commonStyles,
  recordIdName,
}) => {
  const [listState, setListState] = useState([]);
  const [editedRecordId, setEditedRecordId] = useState();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  const handleRemoveRecord = useCallback(() => {
    setIsAddButtonDisabled(false);
    onRemoveRecord();
  }, [data]);

  const handleAddRecord = useCallback(() => {
    onAddRecord(newRecord);
    setEditedRecordId(newRecord[recordIdName]);
    setIsAddButtonDisabled(true);
  }, [data]);

  const renderListOfForms = useMemo(
    () => (
      <div data-id={`${title}List`}>
        <ul className={classNames(styles.header)}>
          {columns.map((column) => get(column, 'isHidden', false) ? null : (
            <li key={`${column.accessor}Header`} className={classNames(commonStyles[column.accessor], styles.listItem)}>
              <div data-id="Header Content" className={styles.headerContent}>
                <div>{column.Header}</div>
                {
                  column.isOptional && (
                    <div data-id="Optional Header Label">
                      {TEXTS.OPTIONAL_HEADER_LABEL}
                    </div>
                  )
                }
              </div>
            </li>
          ))}
        </ul>
        <div className={styles.formsContainer}>
          {listState.map((item) => renderForm({
            item,
            onRemoveRecord: handleRemoveRecord,
            onDeleteHandler,
            setEditedRecordId,
            editedRecordId,
          }))}
        </div>
      </div>
    ),
    [listState, editedRecordId, isAddButtonDisabled]
  );

  useEffect(() => {
    if (isFormSubmitted) {
      setIsAddButtonDisabled(false);
      setEditedRecordId();
    }
  }, [isFormSubmitted]);

  useEffect(() => {
    setListState(data);
  }, [data]);

  return (
    <>
      {renderAddButton({
        onAddRecordClick: handleAddRecord,
        isAddButtonDisabled,
      })}
      {!isEmpty(listState) && renderListOfForms}
    </>
  );
};

ListOfForms.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.array.isRequired,
  renderAddButton: PropTypes.func.isRequired,
  renderForm: PropTypes.func.isRequired,
  onRemoveRecord: PropTypes.func.isRequired,
  onAddRecord: PropTypes.func.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  newRecord: PropTypes.shape({}).isRequired,
  onDeleteHandler: PropTypes.func.isRequired,
  commonStyles: PropTypes.shape({}).isRequired,
  recordIdName: PropTypes.string.isRequired,
};
