import { showFormCss } from 'assets/styles/config';
import { colorSecondaryText } from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import { updateEmployeeDetails } from 'core/auth/guaranteedAccessRoles';
import { get, flatten } from 'lodash';
import moment from 'moment';
import { getHasPermissions } from 'utils/auth';
import { getIsExpired } from 'utils/helpers/date';
import { string, boolean } from 'yup';

import { ENGLISH_LEVELS } from './config/utils/details';

const { HRM, CPS, RM, HR } = USERS_GROUPS;
const outputFormatting = ({ date }) => (
  moment.isMoment(date) ?
    date.format('YYYY-MM-DD') :
    date
);

const calendarCssRules = `
  align-items: center;
  max-height: unset;
  font-size: 1.5rem;
  color: ${colorSecondaryText};
`;

const devStaffDetailsStatusesDictionary = {
  chiefs: {
    dismissalInfo: {
      onNewRecord: 'The record can be deleted.\nPlease click on the button below in order to delete it.',
      isDismissed: 'The employee has been dismissed. Please click on the button below in order to rehire them.',
      isOnboarded: 'The employee is currently onboarded in HiQo Team.',
    },
    careerBreakInfo: {
      onNewRecord: 'The employee’s record is new and cannot be paused for the leave. Only currently employed staff can make long-term leave requests.',
      isIntern: 'The employee\'s record has "Intern" status and cannot be paused for the leave. Only currently employed staff can make long-term leave requests.',
      inPendingDismissal: 'The employee will be dismissed.',
      isOnboarded: 'The employee is currently onboarded in HiQo Team.',
    },
  },
  resourcesPersonal: {
    dismissalInfo: {
      onNewRecord: 'The employee’s record is new. Only currently employed staff can be dismissed.',
      isOnboarded: 'The employee is currently onboarded in HiQo Team. Please contact CTO or Dev Center Director to edit Dismissal Info.',
      inPending: 'The employee will be dismissed. Please contact CTO or Dev Center Director to cancel dismissal.',
      isDismissed: 'The employee has been dismissed. Please contact CTO or Dev Center Director to restore their record in the system',
      onLeave: 'The employee is currently on leave. Please contact CTO or Dev Center Director to edit Dismissal Info.',
    },
    careerBreakInfo: {
      onNewRecord: 'The employee’s record is new and cannot be paused for the leave. Only currently employed staff can make long-term leave requests.',
      isOnboarded: 'The employee is currently onboarded in HiQo Team. Please contact CTO or Dev Center Director to change their status into “On leave”.',
      inPendingDismissal: 'The employee will be dismissed. Please contact CTO or Dev Center Director to change their status into “On leave”.',
      onLeave: 'The employee is currently on leave. Please contact CTO or Dev Center Director to reonboard the employee.',
      isDismissed: 'The employee has been dismissed and their record cannot be paused for the leave. Only currently employed staff can make long-term leave requests.',
    },
  },
};

export default ({
  userGroup,
  history,
  employeeDetails,
}) => {
  const {
    fullname,
    internshipStartDate,
    dateHired,
    fullnameCyrillic,
    email,
    devstaffTechs = [],
    technologiesList = [],
    isDelivery = true,
    allowbill = true,
    isNew: isNewRecord,
    isDismissed,
    isIntern,
    devstaffId,
    englishLevel,
  } = employeeDetails;

  const isHRM = getHasPermissions(userGroup, [HRM]);
  const isResourceManagers = getHasPermissions(userGroup, [RM]);
  const isCPS = getHasPermissions(userGroup, [CPS]);
  const isHr = getHasPermissions(userGroup, [HR]);
  const hasPermissionsToUpdate = getHasPermissions(userGroup, updateEmployeeDetails);
  const hasComeInternshipStartDate = internshipStartDate && getIsExpired(internshipStartDate);
  const hasComeDateHired = dateHired && getIsExpired(dateHired);
  const technologiesSelectItems = [];
  const technologiesSelectItemsMap = {};
  const internshipStartDateHidden = (!!(devstaffId) && (!internshipStartDate && !isNewRecord)) || isResourceManagers;

  technologiesList.forEach(({
    technologyId,
    title,
  }) => {
    const item = {
      label: title,
      value: technologyId,
    };

    technologiesSelectItems.push(item);
    technologiesSelectItemsMap[technologyId] = item;
  });

  const {
    dateLast,
    vacationStartDate,
    vacationEndDate,
    archivedRecords,
    rehireDate,
  } = history;

  const archivedDismissalDate = get(archivedRecords.findLast((archivedDate) => archivedDate.isDismiss), 'dateStart', null);
  const archivedRehireDate = get(archivedRecords.findLast((archivedDate) => archivedDate.isRehire), 'dateStart', null);
  const archivedVacationStartDate = get(archivedRecords.findLast((archivedDate) => archivedDate.onLeave), 'dateStart', null);
  const archivedVacationEndDate = get(archivedRecords.findLast((archivedDate) => archivedDate.onLeave), 'endDate', null);

  const vacationStartDateWithArchived = get(vacationStartDate, 'value', null) || archivedVacationStartDate;
  const vacationEndDateWithArchived = get(vacationEndDate, 'value', null) || archivedVacationEndDate;

  const dismissedOrRehireDate = isDismissed ? get(rehireDate, 'value', null) : get(dateLast, 'value', null);

  const addinationalBlocks = [
    {
      type: 'form',
      onSubmitAction: vacationStartDate ?
        'updateHistory' : 'createHistory',
      additionalFields: vacationStartDate ? {
        historyId: get(vacationStartDate, 'historyId'),
      } : {
        onLeave: true,
        isDismiss: false,
        isRehire: false,
      },
      stylesTemplate: {
        cssRules: 'grid-template-columns: minmax(0, 1fr);',
        formControlsCss: `
        grid-row-gap: 0.8rem;
        margin: 1.3rem 0 0;
      `,
        formWrapperStyles: 'grid-area: careerBreakInfo;',
      },
      defaultControlsHidden: !vacationStartDate || !hasPermissionsToUpdate,
      content: [
        {
          title: 'Career Break Info',
          stylesTemplate: {
            cssRules: 'padding: 0;',
            marginConfig: 'n',
            formControlsCss: `
              display: grid;
              grid-template-columns: auto;
              grid-template-rows: auto 1fr;
              grid-row-gap: 1.6rem;
              margin: 1.3rem 0 0;
              width: 100%;
            `,
          },
          formData: [{
            type: 'virtualField',
            name: '_serviceFields.formId',
            value: Date.now(),
          }, {
            type: 'calendar',
            name: 'dateStart',
            placeholder: 'Vacation Start Date',
            withClear: true,
            value: get(vacationStartDate, 'value', null),
            outputFormatting,
            validationRules: {
              schemaGetter: () => string()
                .nullable()
                .test('earlierThanDateOfHire', 'Please, check entered dates', (value) => value ? (!dateHired || moment(dateHired).isBefore(value)) : true)
                .test('laterThanDateOfDismiss', 'Please, check entered dates', (value) => value ? (!dateLast || moment(dateLast.value).isAfter(value)) : true)
                .test('earlierThanDateOfDismissArchived', 'Please, check entered dates', (value) => value ? (!archivedDismissalDate || moment(archivedDismissalDate).isBefore(value)) : true)
                .test('betweenArchivedDismissalDateAndArchivedRehireDate', 'Please, check entered dates', (value) => value ? ((!archivedDismissalDate || !archivedRehireDate) || !moment(value).isBetween(archivedDismissalDate, archivedRehireDate)) : true)
                .test('isRequired', 'Required field', (value) => !(!value && !vacationStartDate)),
            },
            onFormChange: ({
              values: {
                _serviceFields: {
                  isCareerBreakFormVisible,
                } = {},
              } = {},
            }) => ({
              isHidden: !vacationStartDate && !isCareerBreakFormVisible,
            }),
            getIsUnitLocked: () => !hasPermissionsToUpdate,
            cssRules: calendarCssRules,
          }, {
            type: 'calendar',
            name: 'endDate',
            placeholder: 'Vacation End Date',
            onFormChange: ({
              values: {
                _serviceFields: {
                  isCareerBreakFormVisible,
                } = {},
              } = {},
            }) => ({
              isHidden: !vacationStartDate && !isCareerBreakFormVisible,
            }),
            withClear: true,
            value: get(vacationEndDate, 'value', null),
            getIsUnitLocked: () => !hasPermissionsToUpdate,
            validationRules: {
              schemaGetter: () => string()
                .nullable()
                .when(
                  'dateStart',
                  (dateStart, schema) => schema
                    .test(
                      'hasStartDate',
                      'Please, check entered dates',
                      (value) => !dateStart || moment(dateStart).isBefore(value),
                    )
                )
                .test('earlierThanDateOfHire', 'Please, check entered dates', (value) => value ? (!dateHired || moment(dateHired).isBefore(value)) : true)
                .test('laterThanDateOfDismiss', 'Please, check entered dates', (value) => value ? (!dateLast || moment(dateLast.value).isAfter(value)) : true)
                .test('earlierThanDateOfDismissArchived', 'Please, check entered dates', (value) => value ? (!archivedDismissalDate || moment(archivedDismissalDate).isBefore(value)) : true)
                .test('betweenArchivedDismissalDateAndArchivedRehireDate', 'Please, check entered dates', (value) => value ? ((!archivedDismissalDate || !archivedRehireDate) || !moment(value).isBetween(archivedDismissalDate, archivedRehireDate)) : true)
                .test('isRequired', 'Required field', (value) => !(!value && !vacationEndDate)),
            },
            outputFormatting,
            cssRules: calendarCssRules,
          }, {
            type: 'description',
            data: (() => {
              if (isDismissed) {
                return devStaffDetailsStatusesDictionary.resourcesPersonal.careerBreakInfo.isDismissed;
              }

              if (isIntern) {
                return devStaffDetailsStatusesDictionary.chiefs.careerBreakInfo.isIntern;
              }

              if (dateLast) {
                return hasPermissionsToUpdate ? devStaffDetailsStatusesDictionary.chiefs.careerBreakInfo.inPendingDismissal : devStaffDetailsStatusesDictionary.resourcesPersonal.careerBreakInfo.inPendingDismissal;
              }

              if (isNewRecord) {
                return devStaffDetailsStatusesDictionary.resourcesPersonal.careerBreakInfo.onNewRecord;
              }

              if (vacationStartDate) {
                return devStaffDetailsStatusesDictionary.resourcesPersonal.careerBreakInfo.onLeave;
              }

              return hasPermissionsToUpdate ? devStaffDetailsStatusesDictionary.chiefs.careerBreakInfo.isOnboarded : devStaffDetailsStatusesDictionary.resourcesPersonal.careerBreakInfo.isOnboarded;
            })(),
            onFormChange: ({
              values: {
                _serviceFields: {
                  isCareerBreakFormVisible,
                } = {},
              } = {},
            }) => ({
              isHidden: ((vacationStartDate && hasPermissionsToUpdate) && !isDismissed) || isCareerBreakFormVisible,
            }),
            isMultiline: true,
            cssRules: `
          display: inline-block;
          width: calc(100% - 1.6rem);
          padding-top: 0.8rem;
          padding-right: 0;
          font-size: 1.3rem;
          line-height: 1.6rem;
          color: ${!isNewRecord && hasPermissionsToUpdate && !isDismissed ? '#414042' : '#bbbdc0'};
        `,
          }, {
            type: 'description',
            data: 'Please click the button below in order to add new long-term leave to the employee’s profile.',
            onFormChange: ({
              values: {
                _serviceFields: {
                  isCareerBreakFormVisible,
                } = {},
              } = {},
            }) => ({
              isHidden: isNewRecord || isIntern || !hasPermissionsToUpdate || isDismissed || ((vacationStartDate && hasPermissionsToUpdate) && !isDismissed) || isCareerBreakFormVisible,
            }),
            isMultiline: true,
            cssRules: `
          width: calc(100% - 1.6rem);
          display: inline-block;
          padding-right: 0;
          font-size: 1.3rem;
          line-height: 1.6rem;
          color: #414042;
        `,
          }, {
            type: 'actionButton',
            data: 'Extended Leave',
            name: '_serviceFields.isCareerBreakFormVisible',
            targetName: '_serviceFields.isCareerBreakFormVisible',
            getTargetValue: () => true,
            onFormChange: ({
              values: {
                _serviceFields: {
                  isCareerBreakFormVisible,
                } = {},
              } = {},
            }) => ({
              isHidden: isNewRecord || isIntern || isDismissed || !hasPermissionsToUpdate || vacationStartDate || isCareerBreakFormVisible,
            }),
            valueGetter: () => false,
            withBody: true,
          }],
        }],
    },
    {
      type: 'form',
      onSubmitAction: (!isDismissed && dateLast) || (isDismissed && rehireDate) ? 'updateHistory' : 'createHistory',
      additionalFields: (!isDismissed && dateLast) || (isDismissed && rehireDate) ? {
        historyId: isDismissed ? get(rehireDate, 'historyId') : get(dateLast, 'historyId'),
      } : {
        isDismiss: !isDismissed,
        isRehire: isDismissed,
      },
      stylesTemplate: {
        cssRules: 'grid-template-columns: 1fr;',
        formControlsCss: `
        grid-row-gap: 0.8rem;
        margin: 1.3rem 0 0;
      `,
        formWrapperStyles: 'grid-area: dismissalInfo;',
      },
      defaultControlsHidden: isNewRecord || !hasPermissionsToUpdate || !((!isDismissed && dateLast) || (isDismissed && rehireDate)),
      buttonTitle: (() => {
        if (rehireDate || dateLast) {
          return 'Save changes';
        }
        return isDismissed ? 'Rehire' : 'Dismiss';
      })(),
      content: isNewRecord ? [
        {
          title: hasPermissionsToUpdate && !isHr ? 'Delete info' : 'Dismissal Info',
          stylesTemplate: {
            marginConfig: 'n',
            cssRules: isDismissed ? 'grid-auto-rows: minmax(3.2rem,auto);' : '',
          },
          formData: [
            {
              type: 'description',
              data: hasPermissionsToUpdate || isHr ?
                devStaffDetailsStatusesDictionary.chiefs.dismissalInfo.onNewRecord :
                devStaffDetailsStatusesDictionary.resourcesPersonal.dismissalInfo.onNewRecord,
              isMultiline: true,
              cssRules: `
              display: inline-block;
              width: calc(100% - 1.6rem);
              padding-top: 0.8rem;
              padding-right: 0;
              font-size: 1.3rem;
              line-height: 1.6rem;
              color: ${hasPermissionsToUpdate || isHr ? '#414042' : '#bbbdc0'};
            `,
            },
            {
              type: 'actionButton',
              data: 'Delete',
              isHidden: !hasPermissionsToUpdate && !isHr,
              actionName: 'openModal',
              actionArguments: {
                currentModal: 'CONFIRM_ACTION_MODAL',
                content: [{
                  type: 'title',
                  data: 'Delete this account?',
                }, {
                  type: 'description',
                  data: 'Once deleted, it cannot be restored.',
                }],
                acceptActionName: 'deleteEmployeesRecord',
                acceptActionTitle: 'Delete',
                acceptActionArguments: {
                  devstaffId,
                },
              },
              withBody: true,
              cssRules: `
            ${showFormCss}
            && {
              ${isDismissed ? 'margin-top: 2.4rem;' : ''}
            }
          `,
            },
          ],
        },
      ] : [{
        title: 'Dismissal Info',
        stylesTemplate: {
          marginConfig: 'n',
          cssRules: `
            margin: 0;
            padding: 0;
            ${isDismissed ? 'grid-auto-rows: minmax(3.2rem,auto);' : ''}
          `,
        },
        formData: [{
          type: 'virtualField',
          name: '_serviceFields.formId',
          value: Date.now(),
        },
        {
          type: 'calendar',
          name: 'dateStart',
          withClear: true,
          placeholder: isDismissed ? 'Date of Rehire' : 'Date of Dismissal',
          value: dismissedOrRehireDate,
          validationRules: {
            schemaGetter: () => string()
              .nullable()
              .test('earlierThanDateOfHire', 'Please, check entered dates', (value) => value ? (!dateHired || moment(dateHired).isBefore(value)) : true)
              .test('laterThanDateOfDismissal', 'Please, check entered dates', (value) => value ? (!archivedDismissalDate || moment(archivedDismissalDate).isBefore(value)) : true)
              .test('earlierThanRehireDate', 'Please, check entered dates', (value) => value ? (!archivedRehireDate || moment(archivedRehireDate).isBefore(value)) : true)
              .test('earlierThanVacationStartDate', 'Please, check entered dates', (value) => value ? (!vacationStartDateWithArchived || moment(vacationStartDateWithArchived).isBefore(value)) : true)
              .test('earlierThanVacationEndDate', 'Please, check entered dates', (value) => value ? (!vacationEndDateWithArchived || moment(vacationEndDateWithArchived).isSameOrBefore(value)) : true)
              .test('isRequired', 'Required field', (value) => !(!value && !dismissedOrRehireDate)),
          },
          onFormChange: ({
            values: {
              _serviceFields: {
                isDismissalFormVisible,
              } = {},
            } = {},
          }) => ({
            isHidden: isNewRecord || ((!isDismissed && dateLast) || (isDismissed && rehireDate) ? false : !(isDismissalFormVisible)),
          }),
          getIsUnitLocked: () => !hasPermissionsToUpdate,
          outputFormatting,
          cssRules: calendarCssRules,
        },
        {
          type: 'description',
          data: (() => {
            if (isDismissed) {
              return hasPermissionsToUpdate ? devStaffDetailsStatusesDictionary.chiefs.dismissalInfo.isDismissed : devStaffDetailsStatusesDictionary.resourcesPersonal.dismissalInfo.isDismissed;
            }

            if (dateLast) {
              return hasPermissionsToUpdate ? '' : devStaffDetailsStatusesDictionary.resourcesPersonal.dismissalInfo.inPending;
            }

            if (isNewRecord) {
              return devStaffDetailsStatusesDictionary.resourcesPersonal.dismissalInfo.onNewRecord;
            }

            if (vacationStartDate) {
              return devStaffDetailsStatusesDictionary.resourcesPersonal.dismissalInfo.onLeave;
            }

            return hasPermissionsToUpdate ? devStaffDetailsStatusesDictionary.chiefs.dismissalInfo.isOnboarded : devStaffDetailsStatusesDictionary.resourcesPersonal.dismissalInfo.isOnboarded;
          })(),
          onFormChange: ({
            values: {
              _serviceFields: {
                isDismissalFormVisible,
              } = {},
            } = {},
          }) => ({
            isHidden: (hasPermissionsToUpdate ? !isNewRecord && ((!isDismissed && dateLast) || (isDismissed && rehireDate)) : false) || isDismissalFormVisible,
          }),
          isMultiline: true,
          cssRules: `
          display: inline-block;
          width: calc(100% - 1.6rem);
          padding-top: 0.8rem;
          padding-right: 0;
          font-size: 1.3rem;
          line-height: 1.6rem;
          color: ${!isNewRecord && hasPermissionsToUpdate ? '#414042' : '#bbbdc0'};
        `,
        },
        {
          type: 'description',
          data: `Please click on the button below in order to ${isDismissed ? 'rehire them.' : 'add dismissal date and edit Dismissal Info.'}`,
          onFormChange: ({
            values: {
              _serviceFields: {
                isDismissalFormVisible,
              } = {},
            } = {},
          }) => ({
            isHidden: (isNewRecord || !hasPermissionsToUpdate || isDismissed || ((!isDismissed && dateLast) || (isDismissed && rehireDate))) || isDismissalFormVisible,
          }),
          isMultiline: true,
          cssRules: `
          display: inline-block;
          width: calc(100% - 1.6rem);
          padding-right: 0;
          font-size: 1.3rem;
          line-height: 1.6rem;
          color: #414042;
        `,
        },
        {
          type: 'actionButton',
          data: isDismissed ? 'Rehire' : 'Dismiss',
          name: '_serviceFields.isDismissalFormVisible',
          targetName: '_serviceFields.isDismissalFormVisible',
          getTargetValue: () => true,
          onFormChange: ({
            values: {
              _serviceFields: {
                isDismissalFormVisible,
              } = {},
            } = {},
          }) => ({
            isHidden: ((isNewRecord && !isDismissed) || !hasPermissionsToUpdate || ((!isDismissed && dateLast) || (isDismissed && rehireDate))) || isDismissalFormVisible,
          }),
          valueGetter: () => false,
          withBody: true,
          cssRules: `
          && {
            ${isDismissed ? 'margin-top: 2.4rem;' : ''}
          }
        `,
        },
        ],
      }],
    },
  ];

  return [
    {
      name: 'Details',
      type: 'form',
      stylesTemplate: {
        cssRules: 'grid-template-columns: repeat(2, 1fr); grid-column-gap: 4.8rem;',
        formControlsCss: `
          grid-column-gap: 4.8rem;
          grid-template-columns: repeat(2, 1fr);
          margin: 3.2rem 0 0;
        `,
        formWrapperStyles: 'grid-area: generalInfo;',
      },
      onSubmitAction: !devstaffId ? 'setEmployeeDetails' : '',
      content: [{
        title: 'General Info',
        stylesTemplate: {
          cssRules: 'padding: 0;',
          marginConfig: 'n',
        },
        formData: [{
          type: 'virtualField',
          name: '_serviceFields.formId',
          value: Date.now(),
        },
        {
          type: 'text',
          name: 'fullname',
          title: fullname || '',
          placeholder: 'Full Name (EN)',
          withLabel: true,
          getIsUnitLocked: () => ((!hasPermissionsToUpdate) && isDismissed) || isCPS,
          value: fullname || '',
          validationRules: {
            isRequired: true,
            minLength: 2,
            maxLength: 50,
            schemaGetter: () => (
              string()
                .transform((value) => value.trim())
                .matches(/^[a-zA-Z]+ [a-zA-Z]+$/, 'Please enter first and last name')
                .required('Required field')
            ),
          },
        },
        {
          type: 'text',
          name: 'fullnameCyrillic',
          title: fullnameCyrillic || '',
          withLabel: true,
          placeholder: 'Full Name (national language of Dev Center)',
          getIsUnitLocked: () => ((!hasPermissionsToUpdate) && isDismissed) || isCPS,
          value: fullnameCyrillic || '',
          validationRules: {
            isRequired: true,
            minLength: 2,
            maxLength: 50,
            schemaGetter: () => (
              string()
                .transform((value) => value.trim())
                .required('Required field')
            ),
          },
        },
        {
          type: 'text',
          name: 'email',
          placeholder: 'Email',
          withLabel: true,
          title: email || '',
          value: email || '',
          parentField: {
            name: ['fullname'],
            valueGetter: ({ email: storedEmail, fullname: storedFullName }) => {
              if (devstaffId) {
                const isLocked = devstaffId ? true : undefined;
                return {
                  isLocked,
                  value: storedEmail,
                };
              }

              const dottedName = storedFullName.toLowerCase().replace(/\s+/g, '.');
              const generatedMail = storedFullName ? `${dottedName}@hiqo-solutions.com` : '';

              return {
                value: generatedMail,
                title: storedEmail,
              };
            },
          },
          getIsUnitLocked: () => Boolean(devstaffId),
          validationRules: {
            isRequired: true,
            minLength: 2,
            maxLength: 50,
            schemaGetter: () => string()
              .transform((value) => value.trim())
              .required('Required field')
              .test('formatValue', 'Email must be name.surname@hiqo-solutions.com', (value) => {
                const emailRegex = /^[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}@hiqo-solutions\.com+$/;

                return emailRegex.test(value);
              }),
          },
        },
        {
          type: 'select',
          name: 'isDelivery',
          title: 'Type',
          placeholder: 'Type',
          getIsUnitLocked: () => ((!hasPermissionsToUpdate) && isDismissed) || isCPS,
          value: {
            selected: {
              value: !!isDelivery,
              label: isDelivery ? 'Developer' : 'Administration',
            },
            items: [{
              value: true,
              label: 'Developer',
            }, {
              value: false,
              label: 'Administration',
            }],
          },
          validationRules: {
            isRequired: true,
            schemaGetter: () => (
              boolean()
                .required('Required field')
            ),
          },
        },
        {
          type: 'select',
          name: 'devstaffTechs',
          placeholder: 'Technology',
          getIsUnitLocked: () => ((!hasPermissionsToUpdate) && isDismissed) || isCPS,
          isMulti: true,
          value: {
            selected: devstaffTechs.map(({
              technologyId,
              title,
            }) => ({
              value: technologyId,
              label: title,
            })),
            items: technologiesSelectItems,
            itemsMap: technologiesSelectItemsMap,
          },
        },
        {
          type: 'select',
          name: 'englishLevel',
          placeholder: 'English Level',
          getIsUnitLocked: () => ((!hasPermissionsToUpdate) && isDismissed) || isCPS,
          value: {
            selected: ENGLISH_LEVELS.find((item) => item.value === englishLevel) || {
              value: null,
              label: null,
            },
            items: ENGLISH_LEVELS,
          },
          validationRules: {
            isRequired: true,
            schemaGetter: () => string().nullable().required('Required field'),
          },
        },
        {
          type: 'toggle',
          name: 'allowbill',
          placeholder: 'Allow billing',
          value: devstaffId ? Boolean(allowbill) : isDelivery,
          parentField: {
            name: ['isDelivery'],
            valueGetter: ({ isDelivery: storedIsDelivery }) => storedIsDelivery,
          },
          getIsUnitLocked: () => !hasPermissionsToUpdate || isHr,
        }],
      },
      {
        title: 'Onboarding History',
        stylesTemplate: {
          cssRules: 'padding: 0;',
          marginConfig: 'n',
        },
        formData: [{
          type: 'calendar',
          name: 'internshipStartDate',
          placeholder: 'Internship Start Date',
          value: internshipStartDate || null,
          withClear: hasPermissionsToUpdate || isHRM,
          isHidden: internshipStartDateHidden,
          outputFormatting,
          // eslint-disable-next-line no-mixed-operators
          getIsUnitLocked: () => !(hasPermissionsToUpdate || isHRM || isHr) && (isDismissed || hasComeInternshipStartDate) || isCPS,
          cssRules: calendarCssRules,
        }, {
          type: 'calendar',
          name: 'dateHired',
          placeholder: 'Date of Hire',
          value: dateHired,
          withClear: hasPermissionsToUpdate || isHRM || isHr,
          outputFormatting,
          // eslint-disable-next-line no-mixed-operators
          getIsUnitLocked: () => !((hasPermissionsToUpdate || isHRM) || (hasPermissionsToUpdate || isHr)) && (isDismissed || hasComeDateHired) || isCPS || isResourceManagers,
          validationRules: {
            schemaGetter: () => string()
              .nullable()
              .when(
                'internshipStartDate',
                (internshipDate, schema) => internshipDate ?
                  schema :
                  schema.required('Required field')
              ),
          },
          cssRules: calendarCssRules,
        },
        ...flatten(archivedRecords
          .map(({
            isDismiss,
            isRehire,
            dateStart,
            endDate,
            historyId,
            onLeave,
          }, index) => [{
            type: 'virtualField',
            name: `passedOnboardingHistories[${index}].historyId`,
            value: historyId,
          },
          onLeave ? {
            isMultiple: true,
            cssRules: `
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            grid-column-gap: 3.2rem;`,
            formData: [{
              type: 'calendar',
              name: `passedOnboardingHistories[${index}].dateStart`,
              placeholder: 'Was on Leave',
              value: dateStart || null,
              outputFormatting,
              getIsUnitLocked: () => !hasPermissionsToUpdate,
              cssRules: calendarCssRules,
            }, {
              type: 'calendar',
              name: `passedOnboardingHistories[${index}].endDate`,
              value: endDate || null,
              outputFormatting,
              getIsUnitLocked: () => !hasPermissionsToUpdate,
              cssRules: `
              ${calendarCssRules}
              position: relative;
              &:before {
                position: absolute;
                top: 3rem;
                right: calc(100% + 1.3rem);
                display: block;
                width: 0.7rem;
                height: 0.1rem;
                background: #414042;
                content: '';
              }
              && {
                .calendar__expand-section {
                  left: unset!important;
                  right: 0!important;
                }
              }
            `,
            }],
          } : {
            type: 'calendar',
            name: `passedOnboardingHistories[${index}].dateStart`,
            placeholder: (() => {
              if (isDismiss) {
                return 'Date of Dismissal';
              }

              if (isRehire) {
                return 'Date of Rehire';
              }

              return 'Date';
            })(),
            value: dateStart || null,
            outputFormatting,
            getIsUnitLocked: () => !hasPermissionsToUpdate,
            cssRules: calendarCssRules,
          }])),
        ],
      }],
    },
    ...(devstaffId ? addinationalBlocks : []),
  ];
};
