import React, { memo } from 'react';

export const RemoveFileIcon = memo(({
  onClick,
  className,
}) => (
  <svg data-id="Attachment Remove" className={className} onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 4.00519L11.9929 2.99805L8 6.9909L4.00714 2.99805L3 4.00519L6.99286 7.99805L3 11.9909L4.00714 12.998L8 9.00519L11.9929 12.998L13 11.9909L9.00714 7.99805L13 4.00519Z" fill="#8C8C8C" />
  </svg>
));

RemoveFileIcon.displayName = 'RemoveFileIcon';
