import React from 'react';

import { StyledEmptyTabItemContainer as EmptyTabItemContainer } from '../empty-tab-item/styled';

import './styles.scss';

export const EmptyTabItem = () => (
  <EmptyTabItemContainer>
    <p className="empty-tab__title">Sorry, no categories yet.</p>
  </EmptyTabItemContainer>
);
