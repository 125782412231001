import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import './styles.scss';

const today = moment();
const yearNow = today.year();
const monthNow = today.month();
const weekdaysShortNames = moment.weekdaysShort();


const DaysOffCalendar = ({
  selectedMonth,
  selectedYear,
  holidaysList,
}) => {
  const [calendarDays, setCalendarDays] = useState([]);
  const generateCalendar = () => {
    const now = moment({ year: selectedYear, month: selectedMonth });
    const startDay = now.clone().startOf('month').startOf('week');
    const endDay = now.clone().endOf('month').endOf('week');
    const tempDate = startDay.clone().subtract(1, 'day');
    const calendar = [];

    while (tempDate.isBefore(endDay, 'day')) {
      const value = tempDate.add(1, 'day').clone();
      const date = value.date();
      const weekDay = value.day();
      const isWeekend = (weekDay === 6) || (weekDay === 0);
      const isOutOfMount = !now.isSame(value, 'month');
      const isHoliday = holidaysList.some((holiday) => moment(holiday).isSame(value, 'date'));

      calendar.push({
        date,
        isHoliday,
        isWeekend,
        isOutOfMount,
      });
    }
    setCalendarDays(calendar);
  };

  useEffect(() => {
    generateCalendar();
  }, [selectedMonth, selectedYear]);

  return (
    <div className="days-off-calendar">
      {
        weekdaysShortNames.map((weekdayShortName) => (
          <div
            key={weekdayShortName}
            className="days-off-calendar__weeks-names"
          >
            {weekdayShortName}
          </div>
        ))
      }
      {
        calendarDays.map(({ date, isWeekend, isOutOfMount, isHoliday }, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${date}/${index}`}
            className={classNames(
              'days-off-calendar__day',
              { 'days-off-calendar__day--holiday': isHoliday },
              { 'days-off-calendar__day--weekend': isWeekend },
              { 'days-off-calendar__day--out-of-mount': isOutOfMount }
            )}
          >
            {date}
          </div>
        ))
      }
    </div>
  );
};


DaysOffCalendar.propTypes = {
  selectedMonth: PropTypes.number,
  selectedYear: PropTypes.number,
  holidaysList: PropTypes.arrayOf(PropTypes.string),
};

DaysOffCalendar.defaultProps = {
  selectedMonth: monthNow,
  selectedYear: yearNow,
  holidaysList: [],
};


export default DaysOffCalendar;
