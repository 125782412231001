export const showResourceLinkByDevCenter =
  (
    isCTO = false,
    hasPermissions = false,
    availableDevCenters = [],
    currentDevCenter = null,
    futureDevCenter = null
  ) => {
    if (hasPermissions) {
      const accessForLink = isCTO || !availableDevCenters;
      return accessForLink || availableDevCenters.includes(currentDevCenter) || availableDevCenters.includes(futureDevCenter);
    }
    return false;
  };
