import React, { memo, useCallback } from 'react';

import { Controller } from 'react-hook-form';

import PropTypes from 'prop-types';

import Select from 'components/selectNew';
import { fieldHeadings } from 'layouts/user-management/constants';
import { get } from 'lodash';

export const SelectController = memo(({
  options,
  handleSelectChange,
  value,
  control,
  isLocked,
}) => {
  const generateSelectFieldData = useCallback(
    (field, opts) => {
      const items = opts.map((option) => ({
        value: option.value,
        label: option.label,
      }));

      return ({
        items,
        selected: field.value,
        isLink: false,
        path: '',
      });
    },
    [],
  );

  return (
    <Controller
      name={value}
      control={control}
      render={({
        field,
        fieldState,
      }) => (
        <Select
          name={field.name}
          fieldData={generateSelectFieldData(field, options)}
          onChange={(selectedData) => {
            handleSelectChange(field, selectedData);
          }}
          withLabel={!get(field.value, 'label', false)}
          placeholder={fieldHeadings[field.name]}
          error={get(fieldState, 'error.message', '')}
          withError={fieldState.invalid}
          isLocked={isLocked}
          isSingleValueComponent
          menuPlacement="auto"
          hidePlaceholderOnMenuOpen
        />
      )}
    />
  );
});

SelectController.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  handleSelectChange: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired,
};

SelectController.defaultProps = {
  options: [],
};
