import { waitForAuthorization } from 'core/auth/sagas';
import {
  get,
} from 'lodash';
import {
  put,
  takeLatest,
  select,
  call,
} from 'redux-saga/effects';
import {
  executeQuery, parseError,
} from 'utils/request';

import {
  resourceManagementReportActions,
  resourceManagementReportTypes,
} from './actions';
import { queries } from './queries';
import { selectEntityName, selectErrors } from './selectors';
function* getResourceManagementReport({ payload }) {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'resourceManagementReportError', []);
  const entityName = yield select(selectEntityName);
  const errorOptions = {
    entityName,
    storedErrors,
  };
  try {
    const query = queries.getResourceManagementReport;
    const options = {
      query,
      variables: {
        ...payload,
      },
    };
    const data = yield call(executeQuery, options, storedErrors);
    yield put(resourceManagementReportActions.getResourceManagementReportSuccess({
      data: get(data, 'resourceManagementReport', {}),
    }));
  } catch (error) {
    const resourceManagementReportError = yield call(parseError, error, errorOptions);
    yield put(resourceManagementReportActions.getResourceManagementReportFail({
      error: {
        resourceManagementReportError,
      },
    }));
  }
}

function* getResourceManagementReportWatcher() {
  yield takeLatest(resourceManagementReportTypes.GET_REPORT, waitForAuthorization(getResourceManagementReport));
}
export default [
  getResourceManagementReportWatcher,
];
