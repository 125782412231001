import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import { CleanSmallIcon } from 'assets/svgComponents';
import classNames from 'classnames';
import { ActionButton } from 'components/buttons';

import styles from './styles.module.scss';
import useAutosizeTextArea from './useAutosizeTextArea';

const TextareaError = ({ error }) => (
  <div data-id="Textarea Error" className={styles.textareaError}>
    {error.message}
  </div>
);

TextareaError.propTypes = {
  error: PropTypes.shape({}).isRequired,
};

export default function Textarea({
  name,
  formValue,
  placeholder,
  error,
  isError,
  rows,
  maxLength,
  onChange,
  isDirty,
  initialValue,
  reset,
  isAllowedForEdit,
}) {
  const textAreaRef = useRef(null);

  useAutosizeTextArea(textAreaRef.current, formValue);
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <>
      <div
        title="Textarea Container"
        aria-required={isError}
        className={classNames(styles.textareaContainer, {
          [styles.viewMode]: !isAllowedForEdit,
        })}
        data-disabled={!isAllowedForEdit}
      >
        <textarea
          title="Textarea"
          name={name}
          data-id="Textarea"
          onChange={handleChange}
          placeholder={placeholder}
          ref={textAreaRef}
          rows={rows}
          value={formValue}
          maxLength={maxLength}
        />
        {isDirty && (
          <ActionButton
            title="Remove Button"
            className={styles.closeIcon}
            dataId="removeButton"
            onClick={() => reset(initialValue)}
          >
            <CleanSmallIcon />
          </ActionButton>
        )}
      </div>
      {isError && (
        <TextareaError error={error} />
      )}
    </>
  );
}

Textarea.propTypes = {
  placeholder: PropTypes.string,
  formValue: PropTypes.string.isRequired,
  error: PropTypes.shape({}),
  isError: PropTypes.bool,
  rows: PropTypes.number.isRequired,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isDirty: PropTypes.bool.isRequired,
};

Textarea.defaultProps = {
  placeholder: '',
  error: {},
  isError: false,
  maxLength: undefined,
};
