import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import ToastContainer from 'components/toast';
import { commonActions } from 'core/common/actions';
import {
  selectEntityName,
} from 'core/user/selectors';
import UserSettingsContent from 'layouts/user-settings';
import compose from 'lodash/fp/compose';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

const UserSettings = (props) => {
  const {
    entityName,
    setEntityName,
  } = props;

  useEffect(() => {
    setEntityName(entityName);
  }, [entityName]);

  return (
    <>
      <ToastContainer
        containerId={entityName}
      />
      <UserSettingsContent {...props} />
    </>
  );
};

UserSettings.propTypes = {
  entityName: PropTypes.string.isRequired,
  setEntityName: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  entityName: selectEntityName,
});

const mapDispatchToProps = {
  setEntityName: commonActions.setEntityName,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(UserSettings);
