export const hasProperty = (obj, property) => {
  if (!obj || !property) return null;
  return Object.prototype.hasOwnProperty.call(obj, property);
};
export const replaceValues = (data, replacements, path) => data.map((item) => ({
  ...item,
  [path]: replacements[item[path]] || item[path],
}));

export const convertToArrayForSelect = (obj) => Object.values(obj).map((value, index) => ({
  value: index + 1,
  label: value,
}));
