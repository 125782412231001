/* eslint-disable no-unused-vars */
import { USERS_GROUPS } from 'core/auth/constants';
import {
  channelPartnersModuleAccess,
  clientsModuleAccess,
  dashboardAccess,
  taskOrderModuleAccess,
} from 'core/auth/guaranteedAccessRoles';

import additionalLinks from './additional-links';

const {
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
  ACC_SPB,
  ACC_MSQ,
  HR,
  ADMIN,
} = USERS_GROUPS;

// Please be aware that staging api is available from internal HIQO ip addresses only.
// You can use HIQO vpn or any https reverse proxy (apache, nginx, etc) for accessing api from home.
const rootHost = 'https://dev.hbs.stg.hiqo-solutions.com';
// const stgApiUrl = 'https://v2-api.hbs.stg.hiqo-solutions.com:8090';
const apiUrl = 'https://v2-api.dev.hbs.stg.hiqo-solutions.com:8090';
// const LOCAL_API_URL = 'http://127.0.0.1:5000';
const ceo =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfY2VvIiwiaXNzIjoiaGJzMmFwaSIsImF1ZCI6ImhiczJhcGkiLCJleHRlbmRlZCI6ImNlbyIsInVzZXJJRCI6MiwidG9rZW5fdXNlIjoiaWQiLCJpYXQiOjE1NzE2NTQ0NzcsImV4cCI6MjE3MTY1NDQ3N30.O1RSMPxa2pJxuqBWZ5ukbO5639jrfj0KID-T6UFglgg';
// const ceo_new = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfY2VvX25ldyIsImlzcyI6ImhiczJhcGkiLCJhdWQiOiJoYnMyYXBpIiwiZXh0ZW5kZWQiOiJjZW8iLCJ1c2VySUQiOjI2LCJ0b2tlbl91c2UiOiJyZWZyZXNoIiwiaWF0IjoxNjUyNDQ0MDM0LCJleHAiOjE2NTI0NDc2MzR9.hTBW42UqNAlXaoIUh_VGQb4Lap6Fdg22_qnyplVc8DM';
const LOCAL_CEO = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfY3RvIiwiaXNzIjoiaGJzMmFwaSIsImF1ZCI6ImhiczJhcGkiLCJleHRlbmRlZCI6ImN0byIsInVzZXJJRCI6NywidG9rZW5fdXNlIjoiaWQiLCJpYXQiOjE1NzE2NTQ0NjQsImV4cCI6MjE3MTY1NDQ2NH0.NRuX8bv5zBMOQhxvFNKEo3YIdXnvQgmYBe6aedxeaAw';
const sales5 =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYWxlczUiLCJpc3MiOiJoYnMyYXBpIiwiYXVkIjoiaGJzMmFwaSIsImV4dGVuZGVkIjoic2FsZXMiLCJ1c2VySUQiOjIwLCJ0b2tlbl91c2UiOiJpZCIsImlhdCI6MTU3MTY1NDQ0MiwiZXhwIjoyMTcxNjU0NDQyfQ.mrRZXOKNzAO-u6Vv6_5jpkBHac9n8o7fEk-I1PbB1yY';
const hrm =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfaHJtIiwiaXNzIjoiaGJzMmFwaSIsImF1ZCI6ImhiczJhcGkiLCJleHRlbmRlZCI6ImhybSIsInVzZXJJRCI6MjQsInRva2VuX3VzZSI6ImlkIiwiaWF0IjoxNTcxOTExMzQxLCJleHAiOjIxNzE5MTEzNDF9.Lht-HR3KvHfQfGgsb78aIyoMba0OTleYstVKrywHk-w';
const rm = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfcm0iLCJpc3MiOiJoYnMyYXBpIiwiYXVkIjoiaGJzMmFwaSIsImV4dGVuZGVkIjoicm0iLCJ1c2VySUQiOjksInRva2VuX3VzZSI6ImlkIiwiaWF0IjoxNzA2NzAyMDc0LCJleHAiOjc3MDY3MDIwNzR9.V_eRbH1oaZ25kdCUhBAzQlFsIesZmXw3_1cfaBdgBcg';
const officeAdm =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfb2ZmaWNlYWRtIiwiaXNzIjoiaGJzMmFwaSIsImF1ZCI6ImhiczJhcGkiLCJleHRlbmRlZCI6Im9mZmljZWFkbWluIiwidXNlcklEIjozLCJ0b2tlbl91c2UiOiJpZCIsImlhdCI6MTU3MjAwMTI4MCwiZXhwIjoyMTcyMDAxMjgwfQ.b1lXIT9EFxVpjOecvskv8tWp6ojg8x8Hkm4NQaNiiL8';
const cso =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfY3NvIiwiaXNzIjoiaGJzMmFwaSIsImF1ZCI6ImhiczJhcGkiLCJleHRlbmRlZCI6ImNzbyIsInVzZXJJRCI6NCwidG9rZW5fdXNlIjoiaWQiLCJpYXQiOjE1NzIyNTg3ODEsImV4cCI6MjE3MjI1ODc4MX0.6a5hXCcF_wYITYpanGuVU0BJExL07HRZUQDePIBfAXg';
const deliveryOfficeAdmin =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfZGV2b2ZmaWNlYWRtIiwiaXNzIjoiaGJzMmFwaSIsImF1ZCI6ImhiczJhcGkiLCJleHRlbmRlZCI6ImRldm9mZmljZWFkbWluIiwidXNlcklEIjoyNSwidG9rZW5fdXNlIjoiaWQiLCJpYXQiOjE1NzQ4NDU4NzEsImV4cCI6MjE3NDg0NTg3MX0.UPWfzZqDifn_5oWkheem3mAZZFfcudQkvtMLFrw3Reo';
const deliveryOfficeAdminBy =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfZGV2YnkiLCJpc3MiOiJoYnMyYXBpIiwiYXVkIjoiaGJzMmFwaSIsImV4dGVuZGVkIjoiZGV2b2ZmaWNlYWRtaW5ieSIsInVzZXJJRCI6MjgsInRva2VuX3VzZSI6ImlkIiwiaWF0IjoxNjU5NDQ2MjgzLCJleHAiOjE3MTk0NDYyODN9.9OZd2hoe-_3i15WBlgPzH7ePL3iwRM_G_pqIAXSOYI0';
const deliveryOfficeAdminSpb =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfZGV2c3BiIiwiaXNzIjoiaGJzMmFwaSIsImF1ZCI6ImhiczJhcGkiLCJleHRlbmRlZCI6ImRldm9mZmljZWFkbWluc3BiIiwidXNlcklEIjoxMDYsInRva2VuX3VzZSI6ImlkIiwiaWF0IjoxNjU5NDQ2MzM0LCJleHAiOjE3MTk0NDYzMzR9.Admuiz-8Mym9oXglsdxGdBwThv0aLTeM3vbyeO2H7UA';
const deliveryOfficeAdminTbs =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfZGV2dGJzIiwiaXNzIjoiaGJzMmFwaSIsImF1ZCI6ImhiczJhcGkiLCJleHRlbmRlZCI6ImRldm9mZmljZWFkbWludGJzIiwidXNlcklEIjoxMDcsInRva2VuX3VzZSI6ImlkIiwiaWF0IjoxNjU5NDQ2MzcyLCJleHAiOjE3MTk0NDYzNzJ9.MGHLBeFZ7LTiPAs-y0xUzNbM_lLHqcy96ccZSLoTvkY';
const deliveryOfficeAdminWaw =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfZGV2a3poIiwiaXNzIjoiaGJzMmFwaSIsImF1ZCI6ImhiczJhcGkiLCJleHRlbmRlZCI6ImRldm9mZmljZWFkbWlua3poIiwidXNlcklEIjoxMTUsInRva2VuX3VzZSI6ImlkIiwiaWF0IjoxNjU5NDQ2NDI2LCJleHAiOjE3MTk0NDY0MjZ9.Azf4lCTxsBglxEtmrSnlHWRdrqw0bsAxj9ZACn54Ayc';
const accountantMsq =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhY2NvdW50YW50X21zcSIsImlzcyI6ImhiczJhcGkiLCJhdWQiOiJoYnMyYXBpIiwiZXh0ZW5kZWQiOiJhY2NvdW50YW50X21zcSIsInVzZXJJRCI6MTAwLCJ0b2tlbl91c2UiOiJpZCIsImlhdCI6MTY1OTQ0NjQ1NSwiZXhwIjoxNzE5NDQ2NDU1fQ.WZZGc26naZPOfFMX7UDAGv4I4xX5_c7L_NHFJXVbX7M';
const accountantTbs =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhY2NvdW50YW50X3RicyIsImlzcyI6ImhiczJhcGkiLCJhdWQiOiJoYnMyYXBpIiwiZXh0ZW5kZWQiOiJhY2NvdW50YW50X3RicyIsInVzZXJJRCI6MTAxLCJ0b2tlbl91c2UiOiJpZCIsImlhdCI6MTcyMTcxOTkzNiwiZXhwIjoyMzIxNzE5OTM2fQ.Zd03a3w85F3KUoo4pd8LnKnMGvqKNF0o0bKxJDFmWsA';
const accountantWaw =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhY2NvdW50YW50X3dhdyIsImlzcyI6ImhiczJhcGkiLCJhdWQiOiJoYnMyYXBpIiwiZXh0ZW5kZWQiOiJhY2NvdW50YW50X3dhdyIsInVzZXJJRCI6MTAyLCJ0b2tlbl91c2UiOiJpZCIsImlhdCI6MTY1OTQ0NjQ4MCwiZXhwIjoxNzE5NDQ2NDgwfQ.qi2CTHXLDuCVawlKzfT8TyyIzvPM_Zmo7AU5wA9xIuw';
const accountantSpb = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhY2NvdW50YW50X3NwYiIsImlzcyI6ImhiczJhcGkiLCJhdWQiOiJoYnMyYXBpIiwiZXh0ZW5kZWQiOiJhY2NvdW50YW50X3NwYiIsInVzZXJJRCI6MTE2LCJ0b2tlbl91c2UiOiJpZCIsImlhdCI6MTY4MjY2ODIzMCwiZXhwIjoxNzQyNjY4MjMwfQ.fMhMnN0zKTdCB9pWbrVyoyPJEz_m8H5B0x9m9xelBwo';
const cps = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfY3BzIiwiaXNzIjoiaGJzMmFwaSIsImF1ZCI6ImhiczJhcGkiLCJleHRlbmRlZCI6ImNwcyIsInVzZXJJRCI6MSwidG9rZW5fdXNlIjoiaWQiLCJpYXQiOjE2ODYzMTU1OTYsImV4cCI6MTc0NjMxNTU5Nn0.3ln1-TVYwPxnhBetg4fLFGNPpSi41SKrx1dtS82G1pQ';
const hr = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfaHIiLCJpc3MiOiJoYnMyYXBpIiwiYXVkIjoiaGJzMmFwaSIsImV4dGVuZGVkIjoiaHIiLCJ1c2VySUQiOjExOCwidG9rZW5fdXNlIjoiaWQiLCJpYXQiOjE3MDc3MzcwMTQsImV4cCI6MTc2NzczNzAxNH0.4aU9KsCGcmfQ0uDNIpsTjO_80O3M1QpOy6kObjT6DA0';
const accountantNqz = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhY2NvdW50YW50X25xeiIsImlzcyI6ImhiczJhcGkiLCJhdWQiOiJoYnMyYXBpIiwiZXh0ZW5kZWQiOiJhY2NvdW50YW50X25xeiIsInVzZXJJRCI6MTIwLCJ0b2tlbl91c2UiOiJpZCIsImlhdCI6MTcwNjY4ODgzOSwiZXhwIjoxNzY2Njg4ODM5fQ.tR-bfQ87y0tv7E9mBwWiW40Wa_laagBIeriEAsMHdL8';
const sales = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfc2FsZXMiLCJpc3MiOiJoYnMyYXBpIiwiYXVkIjoiaGJzMmFwaSIsImV4dGVuZGVkIjoic2FsZXMiLCJ1c2VySUQiOjE2LCJ0b2tlbl91c2UiOiJpZCIsImlhdCI6MTcxMDMyOTEyOCwiZXhwIjoyMzEwMzI5MTI4fQ.adf8DQ_iTv2LLQzYTtDyEmRdi34HNuCstaL-nR9IZwo';
const admin = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfeXVyIiwiaXNzIjoiaGJzMmFwaSIsImF1ZCI6ImhiczJhcGkiLCJleHRlbmRlZCI6ImFkbWluIiwidXNlcklEIjoxNjUsInRva2VuX3VzZSI6ImlkIiwiaWF0IjoxNzIzODA0NjUwLCJleHAiOjIzMjM4MDQ2NTB9.p8UOvEmL3h3foDtR_qDNgKAL85C0kK1MJRr1ov1Zb2s';

const stgCeo = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfY2VvIiwiaXNzIjoiaGJzMmFwaSIsImF1ZCI6ImhiczJhcGkiLCJleHRlbmRlZCI6ImNlbyIsInVzZXJJRCI6MiwidG9rZW5fdXNlIjoiaWQiLCJpYXQiOjE2OTc3MjM3NTQsImV4cCI6MjI5NzcyMzc1NH0.qIiAz8fN_8H7xDI10ySDtYtIP7LjURRYEO1jWABeJ5k';
const stgRm = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfcm0iLCJpc3MiOiJoYnMyYXBpIiwiYXVkIjoiaGJzMmFwaSIsImV4dGVuZGVkIjoicm0iLCJ1c2VySUQiOjksInRva2VuX3VzZSI6ImlkIiwiaWF0IjoxNzIxODIxMDU5LCJleHAiOjIzMjE4MjEwNTl9.CT_NOYHLnulmrd1FShB8HsNNZODcjowp9smGNim9Q9o';
const stgAdmin = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJoYnNfYWRtaW4iLCJpc3MiOiJoYnMyYXBpIiwiYXVkIjoiaGJzMmFwaSIsImV4dGVuZGVkIjoiYWRtaW4iLCJ1c2VySUQiOjE1OCwidG9rZW5fdXNlIjoiaWQiLCJpYXQiOjE3MjYwNTAwMTAsImV4cCI6MjMyNjA1MDAxMH0.tBjvZQOltL0JPbMR7pdKubAGjkK2-vWhWUc-o8rnT9U';

export default {
  rootHost,
  apiUrl,
  tokens: {
    idToken: null,
  },
  LINKS: [
    {
      name: 'dashboard',
      route: 'home',
      guaranteedAccessRoles: dashboardAccess,
    },
    {
      name: 'clients',
      route: 'clients',
      guaranteedAccessRoles: clientsModuleAccess,
    },
    {
      name: 'task orders',
      route: 'task-orders',
      guaranteedAccessRoles: taskOrderModuleAccess,
    },
    {
      name: 'channel partners',
      route: 'channel-partners',
      guaranteedAccessRoles: channelPartnersModuleAccess,
    },
    ...additionalLinks,
  ],
  redirectPaths: {
    [ACC_SPB]: '/reports/annual-report/staff',
    [ACC_MSQ]: '/reports/annual-report/staff',
    [HR]: '/delivery/employees-list',
    [DELIVERY_OFFICE_ADMIN_BY]: '/reports/billing-reports/summary/DTO/billable-activities',
    [DELIVERY_OFFICE_ADMIN_SPB]: '/reports/billing-reports/summary/DTO/billable-activities',
    [DELIVERY_OFFICE_ADMIN_WAW]: '/reports/billing-reports/summary/DTO/billable-activities',
    [DELIVERY_OFFICE_ADMIN_TBS]: '/reports/billing-reports/summary/DTO/billable-activities',
    [DELIVERY_OFFICE_ADMIN_KZH]: '/reports/billing-reports/summary/DTO/billable-activities',
    [ADMIN]: '/configurations/user-management',
  },
};
