import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { billingReportsActions } from 'core/billing-reports/actions';
import { filesActions } from 'core/files/actions';

import Calendar from 'components/calendar';
import GoTop from 'elements/go-top-button';
import Wrapper from 'elements/wrapper';
import Filter from 'components/filter';
import Table from 'components/table';
import Controls from 'components/list-controls';
import StyledWrapper from 'elements/styled-wrapper';
import { autoScrollToTop } from 'utils/react';

import { commonActions } from 'core/common/actions';
import {
  selectInternalToData,
  selectInternalToListModel,
  selectOrderRules,
  selectFilterConfig,
  selectHasInternalToFilters,
} from 'core/billing-reports/selectors';

import {
  format as formatDate,
  FORMATS,
} from 'utils/date';

import {
  calendarItemCssRules,
  calendarCssRules,
  controlsCssRules,
} from './styles';

const InternalToReport = ({
  location,
  changeOrder,
  orderRules,
  isFetching,
  changeCurrentModal,
  changeFilter,
  filterConfig,
  hasActiveFilters,
  resetFilters,
  entityName,
  getInternalToReport,
  generateInternalToExcelReport,
  internalToData,
  model,
}) => {
  autoScrollToTop(location);
  const [currentDate, setCurrentDate] = useState(moment());
  const { rules: { css }, tableName, noDataMessage } = model;
  const currentOrderRules = orderRules[tableName];
  const setOrder = ({ orderBy }) => {
    changeOrder({ orderBy, tableName, entityName });
  };

  const setDate = (selectedDate) => {
    setCurrentDate(selectedDate);
    getInternalToReport({
      month: selectedDate.month() + 1,
      year: selectedDate.year(),
    });
  };

  useEffect(() => {
    getInternalToReport({
      month: moment().month() + 1,
      year: moment().year(),
    });

    return () => {
      // We are going to reset filters on layout unmount.
      resetFilters({ currentFilter: 'internalToFilter' });
    };
  }, []);

  const unitActions = {
    changeCurrentModal,
    generateInternalToExcelReport,
  };

  return (
    <Fragment>

      <Controls
        title="HiQo Task Orders"
        hasActiveFilters={hasActiveFilters}
        resetFilters={() => resetFilters({ currentFilter: 'internalToFilter' })}
        cssRules={controlsCssRules}
      >
        <Calendar
          withStepControls
          withMonthSelecting
          minDetails="year"
          onChange={setDate}
          currentDate={moment()}
          stepControlsConfig={{
            step: 1,
            unitName: 'month',
          }}
          cssRules={calendarCssRules}
          value={currentDate}
          popperProps={{
            placement: 'bottom-start',
          }}
        >
          <StyledWrapper
            cssRules={calendarItemCssRules}
          >
            {formatDate(currentDate, FORMATS.MMMMYYYY)}
          </StyledWrapper>
        </Calendar>
        <Filter
          cssRules={css}
          filterConfig={filterConfig}
          changeFilter={changeFilter}
        />
      </Controls>

      <Wrapper
        isGrow
      >
        <Table
          withInfiniteScroll
          data={internalToData}
          changeOrder={setOrder}
          model={model}
          unitActions={unitActions}
          noDataMessage={noDataMessage}
          withFilter={hasActiveFilters}
          orderRules={currentOrderRules}
          isLoaded={!isFetching}
        />

      </Wrapper>

      <GoTop />

    </Fragment>
  );
};


InternalToReport.propTypes = {
  location: PropTypes.object.isRequired,
  entityName: PropTypes.string.isRequired,
  resetFilters: PropTypes.func.isRequired,
  hasActiveFilters: PropTypes.bool,
  changeFilter: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.object.isRequired,
  filterConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  getInternalToReport: PropTypes.func.isRequired,
  internalToData: PropTypes.array.isRequired,
  model: PropTypes.array.isRequired,
};

InternalToReport.defaultProps = {
  hasActiveFilters: false,
};

const mapStateToProps = createStructuredSelector({
  internalToData: selectInternalToData,
  orderRules: selectOrderRules,
  model: selectInternalToListModel,
  filterConfig: selectFilterConfig,
  hasActiveFilters: selectHasInternalToFilters,
});

const mapDispatchToProps = {
  getInternalToReport: billingReportsActions.getInternalToReport,
  generateInternalToExcelReport: filesActions.generateInternalToExcelReport,
  changeOrder: commonActions.changeOrder,
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalToReport);
