import {
  formViewboxCss,
  removeButtonCss,
  addButtonCss,
  formHeaderCss,
} from 'assets/styles/config';
import {
  statusData,
  statusDataForSales,
} from 'layouts/task-order-details/config';
import { formatDateMMDDYYYY, getUnixTime } from 'utils/helpers/date';
import { getFileStatus } from 'utils/helpers/files';
import { requiredValidator } from 'utils/validation';
import { object, array, string } from 'yup';

const ratesBySeniority = {
  Senior: 'Senior',
  Staff: 'Staff',
  Junior: 'Junior',
};

export default ({
  isCEO,
  isPTM,
  isCTO,
  isExpired,
  hasEfforts,
  isUserSales,
  taskOrderTitle,
  taskOrderEndDate,
  isTaskOrderActive,
  isManuallyDeactivated,
  hasPermissionsToDelete,
  hasPermissionsToADDFiles,
  hasPermissionsTosSendForSignature,
  devcenters,
}) => {
  const filedArrayRootName = 'effortLines';
  return [
    {
      type: 'table',
      withUploading:
        hasPermissionsToADDFiles && (isCEO || !isManuallyDeactivated),
      noDataMessage: 'This section currently contains no TO documents.',
      tableCssRules: 'min-height: calc(50vh - 30rem);',
      fileGeneratorConfig: {
        title: 'Generate new TO document',
        fields: [
          {
            type: 'text',
            name: 'title',
            label: 'Title',
          },
        ],
        validationSchema: object().shape({
          title: requiredValidator(),
        }),
        initialValues: {
          title: taskOrderTitle,
        },
      },

      fileLoaderConfig: {
        title: 'Upload new TO document',
        fields: [
          {
            type: 'text',
            name: 'documentTitle',
            label: 'Title',
          },
          {
            type: 'select',
            name: 'documentStatus',
            label: 'Status',
            ...statusData,
            ...(isUserSales && statusDataForSales),
          },
        ],
        validationSchema: object().shape({
          documentTitle: requiredValidator().max(50, 'Title max length is 50'),
        }),
        initialValues: {
          documentTitle: taskOrderTitle,
          documentStatus: false,
        },
      },

      content: [
        {
          title: 'Documents',
          idKey: 'fileId',
          dataKey: 'files',

          rowStatusGetter: ({ taskOrderId, title }) => ({
            // TODO
            id: taskOrderId,
            isActive: !isExpired,
            hasWarning: false,
            name: title,
          }),

          rules: {
            css: {
              gridTemplateConfig: hasPermissionsTosSendForSignature ?
                `grid-template-columns: minmax(auto, 39fr) minmax(auto, 12fr) minmax(auto, 12fr) minmax(auto, 15fr) minmax(auto, ${
                  isManuallyDeactivated ? 55 : 27
                }fr);` :
                'grid-template-columns: minmax(auto, 30fr) minmax(auto, 12fr) minmax(auto, 12fr) minmax(auto, 37fr);',
            },
          },
          tableName: 'toDocuments',
          dataTemplate: [
            {
              columnName: 'Title',
              storeKey: '',
              type: 'text',
              valueGetter: ({ title, downloadUrl, downloadSignedUrl }) => ({
                // TODO
                value: title,
                type: downloadSignedUrl || downloadUrl ? 'link' : 'text',
                componentProps: {
                  data: title,
                  href: downloadSignedUrl || downloadUrl,
                  withBlank: true,
                },
              }),
            },
            {
              columnName: 'Upload Date',
              headerProps: {
                cssRules: 'margin-right: 3rem;',
              },
              valueGetter: ({ uploadedDate }) => ({
                isDate: true,
                unixValue: getUnixTime(uploadedDate),
                type: 'text',
                componentProps: {
                  data: uploadedDate ? formatDateMMDDYYYY(uploadedDate) : 'N/A',
                  isEmpty: !uploadedDate,
                },
              }),
            },
            {
              columnName: 'Effective Date',
              headerProps: {
                cssRules: 'margin-right: 3rem;',
              },
              valueGetter: ({ effectiveDate }) => ({
                isDate: true,
                unixValue: getUnixTime(effectiveDate),
                type: 'text',
                componentProps: {
                  data: effectiveDate ?
                    formatDateMMDDYYYY(effectiveDate) :
                    'N/A',
                  isEmpty: !effectiveDate,
                },
              }),
            },
            {
              columnName: 'status',
              valueGetter: ({ isSigned, isGenerated }) => {
                const status = getFileStatus({
                  isSigned,
                  isGenerated,
                  effectiveDate: taskOrderEndDate,
                });

                return {
                  value: status,
                  type: 'text',
                };
              },
            },
            {
              columnName: 'action',
              withoutControls: true,
              shouldntBeRendered: !hasPermissionsTosSendForSignature,
              valueGetter: ({
                isSigned,
                title,
                fileId,
                isRequestToSignSent,
              }) => {
                const message = {
                  value: 'Sent for Signature',
                  type: 'text',
                  shouldntBeRendered: !hasPermissionsTosSendForSignature,
                };

                const sendForSignatureConfig = {
                  value: 'Send for signature',
                  type: 'action',
                  shouldntBeRendered: !hasPermissionsTosSendForSignature,

                  componentProps: {
                    data: 'Send for signature',
                    actionName: 'changeCurrentModal',
                    withBody: true,
                    isHidden: false,
                    actionArguments: {
                      currentModal: 'SEND_FOR_SIGNATURE_MODAL',
                      fileId,
                    },
                  },
                };

                const deleteButton = {
                  value: 'Delete',
                  type: 'action',
                  cssRules: 'margin-left: auto;',
                  componentProps: {
                    isHidden: isSigned || (!isCEO && isManuallyDeactivated),
                    data: 'Delete',
                    actionName: 'changeCurrentModal',
                    actionArguments: {
                      currentModal: 'CONFIRM_ACTION_MODAL',
                      content: [
                        {
                          type: 'title',
                          data: `Delete “${title}”?`,
                        },
                        {
                          type: 'description',
                          data: 'This file will be permanently deleted and cannot be restored.',
                        },
                      ],
                      acceptActionName: 'deleteTaskOrderDocument',
                      acceptActionTitle: 'Delete',
                      acceptActionArguments: {
                        fileId,
                      },
                    },
                  },
                };

                const noComponent = {
                  shouldntBeRendered: true,
                };
                const getIsNeedSign = (unitsIsTrue, unitsIsFalse) => isSigned || isManuallyDeactivated ?
                  unitsIsTrue :
                  unitsIsFalse;

                return hasPermissionsToDelete ?
                  getIsNeedSign(
                    [deleteButton],
                    [
                      isRequestToSignSent ? message : sendForSignatureConfig,
                      deleteButton,
                    ]
                  ) :
                  getIsNeedSign(
                    noComponent,
                    isRequestToSignSent ? message : sendForSignatureConfig
                  );
              },
            },
          ],
        },
      ],
    },

    {
      type: 'form',
      stylesTemplate: {
        cssRules:
          'grid-template-columns: 1fr; grid-area: detailsForm; padding: 0;',
        formControlsCss:
          'grid-template-columns: repeat(2, calc((136rem - 4.8rem * 4 - 3.2rem) / 5));',
        withoutSpinner: true,
      },

      withoutFormControls: isTaskOrderActive || isCTO || isManuallyDeactivated,
      isHidden: !isPTM || (isManuallyDeactivated && !hasEfforts),

      content: [
        {
          title: 'Estimated Efforts',
          stylesTemplate: {
            columns: '1fr',
            gridRowGap: '0',
            marginTop: '0',
            padding: '0',
          },

          formData: [
            {
              isFieldArray: true,
              name: filedArrayRootName,

              valueGetter: ({ effortLines = [], taskOrderRates = {} }) => {
                const getSeniorityRatePTM = (seniority, devcenter) => {
                  if (devcenter) {
                    const rates = taskOrderRates ? taskOrderRates.rates : [];
                    if (rates.length < 9) {
                      const seniorityRates = rates.filter(
                        (rate) => rate.seniority === seniority
                      )[0];
                      return seniorityRates ? seniorityRates.ratePtm || 0 : 0;
                    }
                    const taskOrderRatesForSelectedDevcenter = rates.filter(
                      (rate) => rate.devcenter === devcenter
                    );
                    const seniorityRates =
                      taskOrderRatesForSelectedDevcenter.filter(
                        (rate) => rate.seniority === seniority
                      )[0];
                    return seniorityRates ? seniorityRates.ratePtm || 0 : 0;
                  }
                  return 0;
                };

                const getTotal = (lines) => lines.reduce(
                  (acc, { hrsEstimate = 0, seniority, devcenter }) => {
                    const {
                      totalHours: accTotalHours,
                      totalEstimate: accTotalEstimate,
                    } = acc;
                    const rate = getSeniorityRatePTM(seniority, devcenter);

                    return {
                      totalHours: accTotalHours + hrsEstimate,
                      totalEstimate: accTotalEstimate + rate * hrsEstimate,
                    };
                  },
                  {
                    totalHours: 0,
                    totalEstimate: 0,
                  }
                );

                const { totalHours, totalEstimate } = getTotal(effortLines);

                const defaultLineValues = {
                  item: '',
                  seniority: 'Staff',
                  hrsEstimate: '0.0',
                };

                const onPushLine = ({
                  values = [],
                  addLine,
                  setFieldError,
                  setFieldTouched,
                }) => {
                  const emptyLines = values.findIndex(({ item }) => !item);

                  if (emptyLines !== -1) {
                    setFieldError(
                      `${filedArrayRootName}[${emptyLines}].item`,
                      'Please select position first'
                    );
                    setFieldTouched(
                      `${filedArrayRootName}[${emptyLines}].item`,
                      true
                    );
                  } else {
                    addLine(defaultLineValues);
                  }
                };

                return {
                  onPushLine,
                  value: effortLines, // initialValue
                  title: 'Estimated Efforts',
                  lineGetter: ({ index, lineValues }) => {
                    const { item, hrsEstimate, seniority, devcenter } =
                      lineValues || {};

                    const seniorityItems = Object.keys(ratesBySeniority).map(
                      (seniorityItem) => ({
                        value: seniorityItem,
                        label: seniorityItem,
                      })
                    );
                    const seniorityRatePTM = getSeniorityRatePTM(
                      seniority,
                      devcenter
                    );

                    return {
                      lineId: `${index}`,
                      line: [
                        {
                          type:
                            isTaskOrderActive || isCTO || isManuallyDeactivated ?
                              'viewBox' :
                              'text',
                          name: `${filedArrayRootName}[${index}].item`,
                          fieldData: {
                            value: item,
                            placeholder: 'Position',
                          },
                          isLocked:
                            isTaskOrderActive || isCTO || isManuallyDeactivated,
                          validationRules: {
                            isRequired: true,
                          },
                          cssRules:
                            isTaskOrderActive || isCTO || isManuallyDeactivated ?
                              `${formViewboxCss}
                   justify-content: flex-start;
                   padding-left: 1.6rem;
                   font-size: 1.3rem;
                  ` :
                              `
                    width: calc(100% - 1.6rem);
                    margin-left: 1.6rem;
                    font-size: 1.3rem;
                  `,
                        },
                        {
                          type:
                            isTaskOrderActive || isCTO || isManuallyDeactivated ?
                              'viewBox' :
                              'select',
                          name: `${filedArrayRootName}[${index}].devcenter`,
                          fieldData: {
                            value: devcenter,
                            selected: {
                              value: devcenter,
                            },
                            items: devcenters,
                          },
                          validationRules: {
                            isRequired: true,
                          },
                          isHidden: !isPTM,
                          isLocked:
                            isTaskOrderActive || isCTO || isManuallyDeactivated,
                          cssRules: `
                  ${
  (isTaskOrderActive || isCTO || isManuallyDeactivated) &&
                    formViewboxCss
};
                  justify-content: flex-start;
                  font-size: 1.3rem;
                `,
                        },
                        {
                          type:
                            isTaskOrderActive || isCTO || isManuallyDeactivated ?
                              'viewBox' :
                              'select',
                          name: `${filedArrayRootName}[${index}].seniority`,
                          fieldData:
                            isTaskOrderActive || isCTO || isManuallyDeactivated ?
                              {
                                value: seniority,
                              } :
                              {
                                selected: {
                                  value: seniority,
                                  label: seniority,
                                },
                                items:
                                    isTaskOrderActive ||
                                    isCTO ||
                                    isManuallyDeactivated ?
                                      [] :
                                      seniorityItems,
                              },
                          isLocked:
                            isTaskOrderActive || isCTO || isManuallyDeactivated,
                          validationRules: {
                            isRequired: true,
                          },
                          cssRules: `
                  ${
  (isTaskOrderActive || isCTO || isManuallyDeactivated) &&
                    formViewboxCss
};
                  justify-content: flex-start;
                  font-size: 1.3rem;
                `,
                        },
                        {
                          type: 'viewBox',
                          fieldData: {
                            value: Number(seniorityRatePTM).toFixed(2),
                          },
                          isLocked: true,
                          validationRules: {
                            isRequired: true,
                          },
                          cssRules: formViewboxCss,
                        },
                        {
                          type:
                            isTaskOrderActive || isCTO || isManuallyDeactivated ?
                              'viewBox' :
                              'text',
                          name: `${filedArrayRootName}[${index}].hrsEstimate`,
                          fieldData: {
                            value: hrsEstimate || 0,
                          },
                          isLocked:
                            isTaskOrderActive || isCTO || isManuallyDeactivated,

                          validationRules: {
                            isRequired: true,
                            isNumeric: true,
                            maxValue: 99999999.99,
                          },
                          cssRules:
                            isTaskOrderActive || isCTO || isManuallyDeactivated ?
                              `
                    font-size: 1.3rem;
                    text-align: right;
                    padding-right: 0.8rem;
                    padding-left: 0.8rem;
                    ${formViewboxCss}
                  ` :
                              `
                    font-size: 1.3rem;
                    text-align: right;
                    padding-right: 0.8rem;
                    padding-left: 0.8rem;
                  `,
                        },
                        {
                          type: 'viewBox',
                          fieldData: {
                            value: Number(
                              seniorityRatePTM * hrsEstimate
                            ).toFixed(2),
                          },
                          isLocked: true,
                          validationRules: {
                            isRequired: true,
                          },
                          cssRules: formViewboxCss,
                        },
                        {
                          type: 'actionButton',
                          data: 'Delete',
                          actionName: `${filedArrayRootName}_deleteLine`,
                          actionArguments: {
                            index,
                          },
                          cssRules: removeButtonCss,
                          isHidden:
                            isTaskOrderActive || isCTO || isManuallyDeactivated,
                        },
                      ],
                      stylesTemplate: {
                        cssRules: `
                  grid-template-columns: ${
  isTaskOrderActive || isCTO || isManuallyDeactivated ?
    '244fr 200fr 100fr 110fr 83fr 164fr' :
    '244fr 200fr 100fr 110fr 83fr 164fr 69fr'
};
                  grid-column-gap: 0.8rem;
                  grid-row-gap: 0;
                  margin-top: 0;
                  padding: 0;
                `,
                      },
                    };
                  },

                  headersConfig: {
                    stylesTemplate: {
                      cssRules: `
                grid-template-columns: ${
  isTaskOrderActive || isCTO || isManuallyDeactivated ?
    '244fr 200fr 100fr 110fr 83fr 164fr' :
    '244fr 200fr 100fr 110fr 83fr 164fr 69fr'
};
                grid-column-gap: 0.8rem;
                grid-row-gap: 0;
                margin-top: 0;
                padding: 0;
              `,
                    },
                    data: [
                      {
                        value: {
                          title: 'Position',
                          cssRules: `
                  ${formHeaderCss}
                  text-align: left;
                  padding-left: 1.6rem;
                `,
                        },
                      },
                      {
                        value: {
                          title: 'Devcenter',
                          cssRules: `
                  ${formHeaderCss}
                  text-align: left;
                  `,
                        },
                      },
                      {
                        value: {
                          title: 'Seniority',
                          cssRules: `
                  ${formHeaderCss}
                  text-align: left;
                `,
                        },
                      },
                      {
                        value: {
                          title: 'Rate/hr',
                          cssRules: formHeaderCss,
                        },
                      },
                      {
                        value: {
                          title: 'Est. hrs',
                          cssRules: formHeaderCss,
                        },
                      },
                      {
                        value: {
                          title: 'Est. total',
                          cssRules: formHeaderCss,
                        },
                      },
                      {
                        value: {
                          title: '',
                        },
                        isHidden:
                          isTaskOrderActive || isCTO || isManuallyDeactivated,
                      },
                    ],
                  },

                  footerConfig: {
                    stylesTemplate: {
                      cssRules: `
                grid-template-columns: ${
  isTaskOrderActive || isCTO || isManuallyDeactivated ?
    '244fr 200fr 100fr 110fr 83fr 164fr' :
    '244fr 200fr 85fr 110fr 83fr 164fr 69fr'
};
                grid-column-gap: 0.8rem;
                grid-row-gap: 0;
                margin-top: 2rem;
                grid-auto-rows: minmax(3.2rem, auto);
                padding: 0;
              `,
                    },
                    data: [
                      {
                        type:
                          isTaskOrderActive || isCTO || isManuallyDeactivated ?
                            'empty' :
                            'actionButton',
                        data: 'ADD',
                        actionName: `${filedArrayRootName}_addLine`,
                        withBody: true,
                        cssRules: addButtonCss,
                      },
                      {
                        type: 'empty',
                      },
                      {
                        type: 'empty',
                      },
                      {
                        type: 'viewBox',
                        fieldData: {
                          value: 'Total:',
                        },
                        cssRules: `
                ${formViewboxCss}
                align-items: center;
                font-weight: 500;
                border-bottom-width: 0;
                margin-top: 0;
                max-height: 3.2rem;
                padding: 0 0.8rem;
                font-size: 1.3rem;
              `,
                      },
                      {
                        type: 'viewBox',
                        fieldData: {
                          value: totalHours,
                        },
                        isLocked: true,
                        parentField: {
                          name: [filedArrayRootName],
                          strictDependence: true,
                          valueGetter: ({ effortLines: formEffortLines }) => ({
                            value: formEffortLines.reduce(
                              (acc, { hrsEstimate = 0 }) => acc + Number(hrsEstimate),
                              0
                            ),
                          }),
                        },
                        cssRules: `
                ${formViewboxCss}
                align-items: center;
                font-size: 1.5rem;
                font-weight: 500;
                border-bottom: none;
                margin-top: 0;
                max-height: 3.2rem;
              `,
                      },
                      {
                        type: 'viewBox',
                        fieldData: {
                          value: totalEstimate,
                        },
                        isLocked: true,
                        parentField: {
                          name: [filedArrayRootName],
                          strictDependence: true,
                          valueGetter: ({ effortLines: formEffortLines }) => {
                            const { totalEstimate: totalEstimateForAllLines } =
                              getTotal(formEffortLines);

                            return {
                              value: Number(totalEstimateForAllLines).toFixed(
                                2
                              ),
                            };
                          },
                        },
                        cssRules: `
                ${formViewboxCss}
                align-items: center;
                font-size: 1.5rem;
                font-weight: 500;
                border-bottom: none;
                margin-top: 0;
                max-height: 3.2rem;
              `,
                      },
                    ],
                  },
                };
              },
              validationRules: {
                schemaGetter: () => array().of(
                  object().shape({
                    seniority: string().required('Required field'),
                    hrsEstimate: string()
                      .nullable()
                      .max(11, 'Max value is 99999999.99')
                      .required('Required field'),
                    item: string().required('Required field'),
                  })
                ),
              },
            },
          ],
        },
      ],
    },
  ];
};
