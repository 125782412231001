import {
  get,
} from 'lodash';

import {
  splitColumnsConfigGetter,
} from 'utils/helpers/models';

export default ({
  isCEO,
  isManuallyDeactivated,
  assignedClientCategories = [],
  hasPermissionsAssignedProjects,
}) => {
  // 530 - height of the content other than table body, 42 - height of the table row
  const isMultiColumnPredicate = () => assignedClientCategories.length > 1 &&
    Math.floor((document.documentElement.clientHeight - 530) / 42) < assignedClientCategories.length;
  const splitColumnsConfig = splitColumnsConfigGetter({
    isMultiColumnPredicate,
    columnsNumber: 2,
    cssRules: `
      grid-column-gap: 8rem;
    `,
  });
  return [{
    type: (isManuallyDeactivated && !isCEO) || !hasPermissionsAssignedProjects ? 'table' : 'form-with-table',
    stylesTemplate: {
      formControlsCss: 'grid-template-columns: repeat(5, calc((136rem - 4.8rem * 4 - 3.2rem) / 5));',
    },
    content: [{
      title: 'Assigned Client Categories',
      idKey: 'projectId',
      dataKey: 'clientCategories',
      rowStatusGetter: ({
        clientCategoryId,
        isAssigned,
      }, index) => ({
        id: clientCategoryId,
        isActive: true,
        hasWarning: false,
        initialValueName: `clientCategories[${index}]`,
        initialValue: {
          clientCategoryId,
          isAssigned,
        },
      }),

      rules: {
        css: {
          gridTemplateConfig: get(splitColumnsConfig, 'columnsNumber') === 2 ?
            'grid-template-columns: minmax(auto, 2fr) minmax(auto, 6fr) minmax(auto, 1fr);' :
            'grid-template-columns: minmax(auto, 2fr) minmax(auto, 6fr) minmax(auto, 11fr);',
        },
      },
      tableName: 'assignedClientCategories',
      splitColumnsConfig,
      dataTemplate: [{
        columnName: 'Category Key',
        type: 'link',
        valueGetter: ({
          key,
        }) => ({
          type: 'text',
          value: key,
        }),
      },
      {
        columnName: 'Client Category Name',
        type: 'link',
        valueGetter: ({
          name,
        }) => ({
          type: 'text',
          value: name,
        }),
      },
      {
        columnName: 'Assigned',
        type: 'text',
        withoutControls: true,
        valueGetter: ({
          isAssigned,
        }, index) => ({
          type: (isManuallyDeactivated && !isCEO) || !hasPermissionsAssignedProjects ? 'text' : 'switcher',
          value: isAssigned ? 'Yes' : 'No',
          componentProps: {
            data: isAssigned ? 'Yes' : 'No',
            name: `clientCategories[${index}].isAssigned`,
          },
        }),
      },
      ],
    }],
  }];
};
