import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import styled, { css } from 'styled-components';

import { colorPrimary } from 'assets/styles/variables';


const StyledContainer = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colorPrimary};
  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;

const StyledContent = styled.span`
  font-weight: 500;
`;


const WeekWithRange = ({
  date,
  cssRules,
}) => {
  const weekNumber = (date || moment()).clone().endOf('week').week();
  const startOfWeek = (date || moment()).clone().startOf('week').format('MM/DD/YYYY');
  const endOfWeek = (date || moment()).clone().endOf('week').format('MM/DD/YYYY');

  return (
    <StyledContainer
      cssRules={cssRules}
    >
      <StyledContent>
        {`Week ${weekNumber}: `}
      </StyledContent>

      {startOfWeek} - {endOfWeek}

    </StyledContainer>
  );
};


WeekWithRange.propTypes = {
  cssRules: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]),
};

WeekWithRange.defaultProps = {
  cssRules: '',
  date: moment(),
};


export default WeekWithRange;
