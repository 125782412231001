import {
  colorBackgroundDark,
  colorSecondaryGrayLight,
  colorSecondaryBackground,
  colorSecondaryGrayLight020,
} from 'assets/styles/variables';

import { getHours, getRevenue } from 'core/billing-reports/utils';
import hash from 'hash-sum';
import { get, toNumber } from 'lodash';
import { roundValue } from 'utils/helpers/numbers';

export const getRequiredTotals = ({
  isGMReport,
  hasAccessToRevenue = true,
  withHrs = true,
}) => [
  isGMReport && {
    property: 'cop',
  },
  isGMReport && {
    property: 'cos',
  },
  withHrs && {
    property: 'hours',
    valueGetter: getHours,
  },
  isGMReport && {
    property: 'revenue',
    valueGetter: getRevenue,
  },
  hasAccessToRevenue && {
    property: 'total',
    valueGetter: getRevenue,
  },
];

export const groupBy = ({
  row,
  groups,
  sortBy,
  orderBy,
  rowData,
  setGroups,
  defaultOrder,
  requiredTotals = [],
  isGMReport,
}) => {
  const isDefaultOrder = orderBy === defaultOrder;

  if (!isDefaultOrder) {
    return null;
  }

  const clientName = get(row, 'client');
  const clientId = get(row, 'clientId');
  const taskOrderId = get(row, 'taskOrderId');
  const key = hash(`${clientId}/${taskOrderId}`);
  const { rows, subtotalRow } = get(groups, key, {
    rows: [],
    subtotalRow: {},
  });
  const totals = requiredTotals.reduce((acc, { property }) => {
    const subtotal = get(subtotalRow, property, 0);
    const currentValue = get(row, property, 0);

    return {
      ...acc,
      [property]: subtotal + toNumber(isGMReport ? currentValue : roundValue(currentValue, 2)),
    };
  }, {});
  rows.push(rowData);

  return setGroups({
    ...groups,
    [key]: {
      rows,
      sortBy: get(row, sortBy, clientName),
      subtotalRow: {
        ...row,
        isSubTotal: true,
        ...totals,
      },
    },
  });
};

const columnsClPrToRe =
  'minmax(auto, 200fr) minmax(auto, 275fr) minmax(auto, 80fr) minmax(auto, 100fr)';
const getRowStyles = ({ isGrandTotal, isSubTotal, isGMReport }) => `&& {
    border-top: 1px solid ${
  isSubTotal || (isGrandTotal && isGMReport) ?
    colorSecondaryGrayLight :
    'transparent'
};
    border-bottom: 1px solid ${
  isSubTotal ? colorSecondaryGrayLight : 'transparent'
};
  }`;

export const getFiltersStyles = ({ isHRM, isGMReport }) => {
  const gridTemplateColumns = isGMReport ?
    'grid-template-columns: minmax(auto, 75fr) minmax(auto, 95fr) minmax(auto, 30fr) minmax(auto, 45fr) minmax(auto, 30fr) minmax(auto, 325fr);' :
    `grid-template-columns: ${columnsClPrToRe} minmax(auto, 80fr) ${
      isHRM ? 'minmax(auto, 419fr)' : 'minmax(auto, 588fr)'
    };`;

  return {
    clearButtonCssRules: 'display: none;',
    wrapperCssRules: `
      padding-top: 1.5rem;
      background-color: transparent;
    `,
    gridTemplateConfig: `
      margin-bottom: 1rem;
      && {
        .grid-unit--filter {
          background-color: ${colorSecondaryBackground};
        }
        .grid-unit--active {
          background-color: ${colorBackgroundDark};
        }
      }
      ${gridTemplateColumns}
    `,
  };
};

export const getPromoTableStyles = ({ isHRM }) => ({
  summaryWrapperCss: `
    display: grid;
    grid-template-columns: minmax(auto, 398fr) ${
  isHRM ? '' : 'minmax(auto, 160fr)'
  } minmax(auto, 80fr);
    background-color: ${colorSecondaryGrayLight020};
    && .expansion-summary__item {
        margin-right: 0;
    }`,
  commonGridTemplateConfig: `grid-template-columns: minmax(auto, 473fr) minmax(auto, 183fr) minmax(auto, 254fr) ${
    isHRM ?
      'minmax(auto, 160fr)' :
      'minmax(auto, 160fr) minmax(auto, 160fr) minmax(auto, 80fr)'
  };`,
  tableTotalsStylesTemplateGetter: ({ isSubTotal, isGrandTotal }) => {
    if (!isSubTotal && !isGrandTotal) {
      return null;
    }

    return {
      gridTemplateConfig:
        isSubTotal || isGrandTotal ?
          `grid-template-columns: ${
            isGrandTotal ?
              'minmax(auto, 125fr) minmax(auto, 738fr)' :
              'minmax(auto, 828fr) minmax(auto, 0)'
          } minmax(auto, 0) minmax(auto, 0) minmax(auto, 0)  minmax(auto, 0) minmax(auto, 160fr) ${
            isHRM ? '' : 'minmax(auto, 160fr)'
          };` :
          undefined,
      rowStyles:
        isSubTotal || isGrandTotal ?
          getRowStyles({
            isGrandTotal,
            isSubTotal,
          }) :
          '',
    };
  },
});

export const getOvertimeTableStyles = ({ isHRM, isGMReport, isDTOReport }) => {
  if (isGMReport) {
    return {
      commonGridTemplateConfig:
        'grid-template-columns: minmax(auto, 167fr) minmax(auto, 208fr) minmax(auto, 60fr) minmax(auto, 98fr) minmax(auto, 200fr)  minmax(auto, 80fr) minmax(auto, 80fr) minmax(auto, 80fr) minmax(auto, 78fr)  minmax(auto, 78fr) minmax(auto, 78fr) minmax(auto, 78fr);',
      summaryWrapperCss: `
        display: grid;
        grid-template-columns: minmax(auto,751fr) minmax(auto,270fr) minmax(auto,189fr) minmax(auto,283fr);
        padding-right: 1.8rem;
        background-color: ${colorSecondaryGrayLight020};
        && .expansion-summary__item {
          margin-right: 0;
        }`,
      tableTotalsStylesTemplateGetter: ({ isSubTotal, isGrandTotal }) => {
        if (!isSubTotal && !isGrandTotal) {
          return null;
        }

        return {
          gridTemplateConfig: `grid-template-columns: ${
            isGrandTotal ?
              'minmax(auto, 130fr) minmax(auto, 553fr)' :
              'minmax(auto, 681fr) minmax(auto, 0fr)'
          }  minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 80fr) minmax(auto, 78fr) minmax(auto, 78fr)  minmax(auto, 78fr) minmax(auto, 78fr) minmax(auto, 78fr);`,
          rowStyles: getRowStyles({
            isGrandTotal,
            isSubTotal,
            isGMReport,
          }),
        };
      },
    };
  }

  return {
    commonGridTemplateConfig: `grid-template-columns: ${columnsClPrToRe} ${
      isHRM ?
        'minmax(auto, 333fr)  minmax(auto, 160fr)' :
        `minmax(auto, 150fr)  minmax(auto, 160fr) ${
          isDTOReport ? 'minmax(auto, 160fr)' : ''
        } minmax(auto, 170fr) minmax(auto, 170fr)`
    };`,
    summaryWrapperCss: `
        display: grid;
        grid-template-columns: minmax(auto, 398fr) ${
  isHRM ? '' : 'minmax(auto, 160fr)'
} minmax(auto, 80fr);
        padding-right: 1.8rem;
        background-color: ${colorSecondaryGrayLight020};
        && .expansion-summary__item {
          margin-right: 0;
        }`,
    tableTotalsStylesTemplateGetter: ({ isSubTotal, isGrandTotal }) => {
      if (!isSubTotal && !isGrandTotal) {
        return null;
      }

      const gridTemplateConfig = isDTOReport && isHRM ?
        'grid-template-columns: repeat(7, 1fr); grid-template-rows: minmax(3.4rem, auto);' :
        `grid-template-columns: ${
          isGrandTotal ?
            'minmax(auto, 140fr) minmax(auto, 720fr)' :
            'minmax(auto, 827fr) minmax(auto, 0)'
        } minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) ${
          isDTOReport ? 'minmax(auto, 0)' : ''
        }  minmax(auto, 160fr) ${isHRM ? '' : 'minmax(auto, 160fr)'};`;

      return {
        gridTemplateConfig,
        rowStyles: getRowStyles({
          isGrandTotal,
          isSubTotal,
          isGMReport,
        }),
      };
    },
  };
};

export const getODCTableStyles = ({ isHRM, isGMReport, isDTOReport }) => {
  if (isGMReport) {
    return {
      commonGridTemplateConfig:
        'grid-template-columns: minmax(auto, 165fr) minmax(auto, 207fr) minmax(auto, 60fr) minmax(auto, 98fr) minmax(auto, 85fr) minmax(auto, 358fr)  minmax(auto, 78fr) minmax(auto, 78fr) minmax(auto, 78fr) minmax(auto, 78fr);',
      summaryWrapperCss: `
        display: grid;
        grid-template-columns: minmax(auto, 980fr) minmax(auto, 220fr) minmax(auto, 279fr);
        padding-right: 1.8rem;
        background-color: ${colorSecondaryGrayLight020};
        && .expansion-summary__item {
          margin-right: 0;
      }`,
      tableTotalsStylesTemplateGetter: ({ isSubTotal, isGrandTotal }) => {
        if (!isSubTotal && !isGrandTotal) {
          return null;
        }

        return {
          gridTemplateConfig: `grid-template-columns: ${
            isGrandTotal ?
              'minmax(auto, 97fr) minmax(auto, 753fr)' :
              'minmax(auto, 681fr) minmax(auto, 0fr)'
          }  minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 78fr) minmax(auto, 78fr) minmax(auto, 78fr) minmax(auto, 78fr);`,
          rowStyles: getRowStyles({
            isGrandTotal,
            isSubTotal,
            isGMReport,
          }),
        };
      },
    };
  }

  return {
    commonGridTemplateConfig: `grid-template-columns: ${columnsClPrToRe} minmax(auto, 80fr) ${
      isHRM ?
        'minmax(auto, 332fr)' :
        `minmax(auto, 417fr) minmax(auto, 80fr) ${
          isDTOReport ? 'minmax(auto, 80fr)' : ''
        }`
    };`,
    summaryWrapperCss: `
      display: grid;
      padding-right: 1.8rem;
      background-color: ${colorSecondaryGrayLight020};
      grid-template-columns: minmax(auto, 433fr) minmax(auto, 95fr);`,
    tableTotalsStylesTemplateGetter: ({ isSubTotal, isGrandTotal }) => {
      if (!isSubTotal && !isGrandTotal) {
        return null;
      }

      return {
        gridTemplateConfig: `grid-template-columns: ${
          isGrandTotal ?
            'minmax(auto, 97fr) minmax(auto, 795fr)' :
            'minmax(auto, 753fr) minmax(auto, 0)'
        }   minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) ${
          isDTOReport ? 'minmax(auto, 0)' : ''
        } minmax(auto, 160fr) minmax(auto, 160fr);`,
        rowStyles: getRowStyles({
          isGrandTotal,
          isSubTotal,
          isGMReport,
        }),
      };
    },
  };
};

export const getTTMTableStyles = ({ isHRM, isGMReport, isDTOReport }) => {
  if (isGMReport) {
    return {
      commonGridTemplateConfig:
        'grid-template-columns: minmax(auto, 192fr) minmax(auto, 235fr) minmax(auto, 70fr) minmax(auto, 113fr) minmax(auto, 80fr) minmax(auto, 169fr) minmax(auto, 70fr) minmax(auto, 60fr) minmax(auto, 70fr) minmax(auto, 60fr) minmax(auto, 90fr) minmax(auto, 90fr) minmax(auto, 90fr) minmax(auto, 90fr);',
      summaryWrapperCss: `
        display: grid;
        grid-template-columns: minmax(auto, 980fr) minmax(auto, 220fr) minmax(auto, 279fr);
        padding-right: 1.8rem;
        background-color: ${colorSecondaryGrayLight020};
        && .expansion-summary__item {
          margin-right: 0;
      }`,
      tableTotalsStylesTemplateGetter: ({ isSubTotal, isGrandTotal }) => {
        if (!isSubTotal && !isGrandTotal) {
          return null;
        }

        return {
          gridTemplateConfig:
            'grid-template-columns: minmax(auto, 124fr) minmax(auto, 592fr) minmax(auto, 10fr) minmax(auto, 0) minmax(auto, 0) minmax(auto, 8fr) minmax(auto, 60fr)  minmax(auto, 60fr) minmax(auto, 70fr) minmax(auto, 60fr) minmax(auto, 60fr) minmax(auto, 96fr)  minmax(auto, 96fr) minmax(auto, 96fr) minmax(auto, 96fr);',
          rowStyles: getRowStyles({
            isGrandTotal,
            isSubTotal,
            isGMReport,
            isDTOReport,
          }),
        };
      },
    };
  }

  return {
    commonGridTemplateConfig: `grid-template-columns: ${columnsClPrToRe} minmax(auto, 80fr) minmax(auto, 165fr) minmax(auto, 80fr) minmax(auto, 80fr) minmax(auto, 80fr)  ${
      isDTOReport && !isHRM ? 'minmax(auto, 80fr)' : ''
    }  ${isHRM ? '' : 'minmax(auto, 80fr) minmax(auto, 80fr)'};`,
    summaryWrapperCss: `
      display: grid;
      grid-template-columns: minmax(auto, 439fr) minmax(auto, 100fr);
      padding-right: 1.8rem;
      background-color: ${colorSecondaryGrayLight020};
      && .expansion-summary__item {
          margin-right: 0;
      }`,
    tableTotalsStylesTemplateGetter: ({ isSubTotal, isGrandTotal }) => {
      if (!isSubTotal && !isGrandTotal) {
        return null;
      }

      return {
        gridTemplateConfig: `grid-template-columns: ${
          isGrandTotal ?
            'minmax(auto, 124fr) minmax(auto, 899fr)' :
            'minmax(auto, 982fr) minmax(auto, 0)'
        } minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 0)  minmax(auto, 160fr) minmax(auto, 0) minmax(auto, 160fr);`,
        rowStyles: getRowStyles({
          isGrandTotal,
          isSubTotal,
          isGMReport,
        }),
      };
    },
  };
};

export const getPTMTableStyles = ({ isHRM, isGMReport, isDTOReport }) => {
  if (isGMReport) {
    return {
      tableTotalsStylesTemplateGetter: ({ isSubTotal, isGrandTotal }) => {
        if (!isSubTotal && !isGrandTotal) {
          return null;
        }

        return {
          gridTemplateConfig:
            'grid-template-columns: minmax(auto, 125fr) minmax(auto, 464fr) minmax(auto, 10fr) minmax(auto, 8fr) minmax(auto, 80fr)  minmax(auto, 80fr) minmax(auto, 80fr) minmax(auto, 80fr) minmax(auto, 78fr)  minmax(auto, 78fr) minmax(auto, 78fr) minmax(auto, 78fr);',
          rowStyles: getRowStyles({
            isGrandTotal,
            isSubTotal,
            isGMReport,
          }),
        };
      },
      commonGridTemplateConfig:
        'grid-template-columns:  minmax(auto, 190fr) minmax(auto, 240fr) minmax(auto, 70fr) minmax(auto, 251fr) minmax(auto, 90fr)  minmax(auto, 90fr) minmax(auto, 90fr) minmax(auto, 90fr) minmax(auto, 90fr)  minmax(auto, 90fr) minmax(auto, 90fr) minmax(auto, 90fr);',
      summaryWrapperCss: `
        display: grid;
        grid-template-columns: minmax(auto,751fr) minmax(auto,270fr) minmax(auto,189fr) minmax(auto,283fr);
        padding-right: 1.8rem;
        background-color: ${colorSecondaryGrayLight020};
        && .expansion-summary__item {
          margin-right: 0;
        }`,
    };
  }

  return {
    tableTotalsStylesTemplateGetter: ({ isSubTotal, isGrandTotal }) => {
      if (!isSubTotal && !isGrandTotal) {
        return null;
      }

      const gridTemplateConfig = isDTOReport && isHRM ?
        'grid-template-columns: repeat(7, 1fr); grid-template-rows: minmax(3.4rem, auto);' :
        `grid-template-columns: ${
          isGrandTotal ?
            'minmax(auto, 125fr) minmax(auto, 740fr)' :
            'minmax(auto, 827fr) minmax(auto, 0)'
        } minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) ${
          isDTOReport ? 'minmax(auto, 0)' : ''
        } minmax(auto, 0) minmax(auto, 160fr) ${
          isHRM ? '' : 'minmax(auto, 160fr)'
        };`;

      return {
        gridTemplateConfig,
        rowStyles: getRowStyles({
          isGrandTotal,
          isSubTotal,
          isGMReport,
        }),
      };
    },
    commonGridTemplateConfig: `grid-template-columns: ${columnsClPrToRe} ${
      isHRM ?
        'minmax(auto, 333fr)  minmax(auto, 160fr)' :
        `minmax(auto, 150fr)  minmax(auto, 160fr) ${
          isDTOReport ? 'minmax(auto, 160fr)' : ''
        } minmax(auto, 170fr) minmax(auto, 170fr)`
    };`,
    summaryWrapperCss: `
      display: grid;
      grid-template-columns: minmax(auto, 398fr) ${
  isHRM ? '' : 'minmax(auto, 160fr)'
} minmax(auto, 80fr);
      padding-right: 1.8rem;
      background-color: ${colorSecondaryGrayLight020};
      && .expansion-summary__item {
        margin-right: 0;
      }`,
  };
};

export const gridTemplateColumns = () => 'grid-template-columns: minmax(auto, 1fr) minmax(auto, 0.45fr) repeat(3, minmax(auto, .7fr)) minmax(auto, .3fr) minmax(auto, 1fr);';

export const getDevCenterId = (entity, value) => Number(Object.keys(entity).find((key) => entity[key] === value));
