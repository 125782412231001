/* eslint-disable */
import { sortRates } from 'utils/sortRates';

export const parseResponse = ({
  node,
  deliveryRates,
  client,
  ratecards,
  ...rest
}) => {
  if (node) {
    if (node.client.templateRatecard && node.taskOrderRates) {
      const taskOrderRates = node.taskOrderRates.rates;
      const clientRates = node.client.templateRatecard.rates;

      node.taskOrderRates.rates = sortRates(taskOrderRates);
      node.client.templateRatecard.rates = sortRates(clientRates);
    }
    //
  }
  let sortedRatecards;
  if (ratecards) {
    sortedRatecards = ratecards.map((ratecard) => {
      const { rates, ...restValues } = ratecard;
      const sortedRates = sortRates(ratecard.rates);
      return {
        ...restValues,
        rates: sortedRates,
      };
    });
  }

  if (!deliveryRates) {
    return {
      ...node,
      ratecards: sortedRatecards,
      ...rest,
    };
  }
  const rates = deliveryRates.reduce((acc, {
    deliveryCenterId,
    ...reducerRest
  }) => ({
    ...acc,
    [deliveryCenterId]: {
      ...reducerRest,
    },
  }), {});
  return {
    ratecards: sortedRatecards,
    ...node,
    ...rest,
    deliveryRates: rates,
  };
};

export const getCommittedHoursVisibleValue = (hours, discount) => !discount || !hours ? '' : `${hours}h - ${Math.round(discount * 10000) / 100}% discount`;
