export const getFileNameWithExt = (inputString) => {
  if (!inputString) {
    return {};
  }
  const name = inputString;
  const lastDot = name.lastIndexOf('.');
  return {
    fileName: name.substring(0, lastDot),
    extension: name.substring(lastDot + 1),
  };
};
