import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import TableHeading from 'components/form-controls/form-table-heading';
import Checkbox from 'elements/checkbox';
import Text from 'elements/plain-text';

import FormUnit from './form-unit';

import {
  StyledMainFormWrapper,
  StyledGridUnit,
  StyledTemplate,
  StyledTitleControlsWrapper,
} from './styled-components';

import './styles.scss';

const FormContent = ({
  gridTemplateAreas,
  gridColumnGap,
  cssRules,
  formData,
  form,
  isVisible,
  selectedName,
  additionalComponents,
}) => (
  <StyledMainFormWrapper
    gridTemplateAreas={gridTemplateAreas}
    gridColumnGap={gridColumnGap}
    cssRules={cssRules}
  >
    {additionalComponents}
    {formData.map(
      (
        {
          title,
          data,
          stylesTemplate = {},
          controls = [],
          headersConfig,
          isTableView,
        },
        configIndex
      ) => (
        <StyledGridUnit
          key={`${title}/${configIndex}`} // eslint-disable-line react/no-array-index-key
          className={classNames('details-form__form-wrapper', {
            'details-form__form-wrapper--table-view':
              headersConfig || isTableView,
          })}
          stylesTemplate={stylesTemplate}
        >
          {title && (
            <StyledTitleControlsWrapper
              className="details-form__form-title-controls-wrapper"
              cssRules={stylesTemplate.titleControlsCss}
            >
              {title && (
                <p className="details-form__title">
                  {selectedName ? `${title} ${selectedName}` : title}
                </p>
              )}

              {controls.map(
                (
                  {
                    title: controlTitle,
                    isHidden,
                    onFormChange,
                    onClick,
                    isTextContent,
                    isCheckbox,
                    cssRules: checkboxCssRules,
                  },
                  controlIndex
                ) => {
                  const {
                    isActive,
                    isHidden: isUnitHidden,
                    ...unitProps
                  } = onFormChange ? onFormChange(form) : {};

                  const isElementRendered = !(isHidden || isUnitHidden);

                  if (isCheckbox && isElementRendered) {
                    return (
                      <Checkbox
                        key={controlIndex} // eslint-disable-line react/no-array-index-key
                        title={controlTitle}
                        isActive={isActive}
                        onClick={() => onClick(form)}
                        cssRules={checkboxCssRules}
                        {...unitProps}
                      />
                    );
                  }

                  if (isTextContent && isElementRendered) {
                    return (
                      <Text
                        key={controlIndex} // eslint-disable-line react/no-array-index-key
                        {...unitProps}
                      />
                    );
                  }

                  return (
                    isElementRendered && (
                      <button
                        key={controlIndex} // eslint-disable-line react/no-array-index-key
                        className={classNames(
                          'details-form__button',
                          'details-form__button--set-default',
                          'button'
                        )}
                        type="button"
                        onClick={() => onClick(form)}
                        {...unitProps}
                      >
                        {controlTitle}
                      </button>
                    )
                  );
                }
              )}
            </StyledTitleControlsWrapper>
          )}

          <StyledTemplate
            className={classNames('details-form__form-template', {
              'details-form__form-template--table-view':
                  headersConfig || isTableView,
            })}
            stylesTemplate={stylesTemplate}
            cssRules={stylesTemplate.cssRules}
          >
            {headersConfig &&
                headersConfig.data.map(
                  ({ value, isHidden }, headersConfigIndex) => isHidden ? null : (
                    <TableHeading
                      key={headersConfigIndex} // eslint-disable-line react/no-array-index-key
                      fieldData={value}
                    />
                  )
                )}
            {data.map((unit, index) => (
              <FormUnit
                key={`${title}/${index}`} // eslint-disable-line react/no-array-index-key
                unit={unit}
                index={index}
                form={form}
                isVisible={isVisible}
              />
            ))}
          </StyledTemplate>
        </StyledGridUnit>
      )
    )}
  </StyledMainFormWrapper>
);

FormContent.propTypes = {
  selectedName: PropTypes.string,
  form: PropTypes.shape({}).isRequired,
  formData: PropTypes.arrayOf(PropTypes.shape({})),
  cssRules: PropTypes.string,
  gridColumnGap: PropTypes.string,
  gridTemplateAreas: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  additionalComponents: PropTypes.element,
};

FormContent.defaultProps = {
  additionalComponents: null,
  formData: [],
  cssRules: '',
  selectedName: '',
  gridColumnGap: '',
  gridTemplateAreas: '',
};

export default FormContent;
