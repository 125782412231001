import { useLayoutEffect, useState, useRef } from 'react';

export const useElementHeight = () => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [ref]);

  return [ref, height];
};
