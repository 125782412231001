import { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { userManagementActions } from 'core/configurations/user-management';
import { modalConductorActions } from 'core/modal-conductor/actions';
import { get, has, isString } from 'lodash';
import { removeEmptyValues } from 'utils/helpers/forms';

export const useUserManagement = (users) => {
  const dispatch = useDispatch();

  const onAddNew = useCallback(() => {
    dispatch(userManagementActions.addNewUserRecord());
  }, []);

  const onRemoveNew = useCallback(() => {
    dispatch(userManagementActions.removeNewUserRecord());
  }, []);

  return { data: users, onAddNew, onRemoveNew };
};

export const useActions = (onRemoveNew) => {
  const dispatch = useDispatch();

  const onSave = useCallback((userId, formData, userState) => {
    const fields = removeEmptyValues({
      ...formData,
      billingAgent: get(formData, 'billingAgent.value', null),
      role: get(formData, 'role.label', null),
    });

    if (isString(userId)) {
      dispatch(userManagementActions.createUserRecord({
        fields,
      }));

      return;
    }

    dispatch(userManagementActions.updateUserRecord({
      userId,
      fields: {
        ...fields,
        invitationalInProgress: userState.invitationalInProgress,
        ...has(formData, 'phone') && {
          phone: formData.phone,
        },
      },
    }));
  }, []);

  const onInvite = useCallback((userId) => {
    dispatch(userManagementActions.updateUserRecord({
      userId,
      fields: {
        invitationalInProgress: true,
      },
    }));
  }, []);

  const onActivate = useCallback((userId, payload) => {
    dispatch(modalConductorActions.changeCurrentModal({
      currentModal: 'CONFIRM_ACTION_MODAL',
      params: {
        content: [
          {
            type: 'title',
            data: `Reactivate user record with ${payload.email}?`,
          },
          {
            type: 'description',
            data: 'Once user record is reactivated, please select "Send Invite" button and user will receive email for login into the system.',
          },
        ],
        acceptActionName: 'reactivateUser',
        acceptActionTitle: 'Reactivate',
        acceptFunction: () => {
          dispatch(userManagementActions.updateUserRecord({
            userId,
            fields: {
              invitationalInProgress: false,
              isDeactivated: false,
            },
          }));
        },
      },
    }));
  }, []);

  const onDeactivate = useCallback((userId, payload) => {
    const { email } = payload;

    dispatch(modalConductorActions.changeCurrentModal({
      currentModal: 'CONFIRM_ACTION_MODAL',
      params: {
        content: [
          {
            type: 'title',
            data: `Deactivate user record with ${email}?`,
          },
          {
            type: 'description',
            data: 'Once the user record becomes inactive, user will have no access to the system.',
          },
        ],
        acceptActionName: 'deactivateUser',
        acceptActionTitle: 'Deactivate',
        acceptFunction: () => {
          dispatch(userManagementActions.updateUserRecord({
            userId,
            fields: {
              isActive: false,
              isDeactivated: true,
            },
          }));
        },
      },
    }));
  }, []);

  const onDelete = useCallback((userId, payload) => {
    const { email } = payload;

    dispatch(modalConductorActions.changeCurrentModal({
      currentModal: 'CONFIRM_ACTION_MODAL',
      params: {
        content: [
          {
            type: 'title',
            data: `Delete user record with ${email}?`,
          },
          {
            type: 'description',
            data: 'Once deleted, it cannot be restored.',
          },
        ],
        acceptActionName: 'deleteUser',
        acceptActionTitle: 'Delete',
        acceptFunction: () => {
          dispatch(userManagementActions.deleteUserRecord({
            userId,
          }));
        },
      },
    }));
  }, []);

  const defaultActions = useMemo(() => [
    {
      text: 'Save',
      action: onSave,
      hasLoader: true,
    },
    {
      text: 'Cancel',
      action: onRemoveNew,
      hasLoader: false,
    },
  ], []);

  return useMemo(() => ({
    actionsList: {
      onRemoveNew,
      onActivate,
      onSave,
      onInvite,
      onDeactivate,
      onDelete,
    },
    defaultActions,
  }), []);
};
