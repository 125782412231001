import React, { useState, useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';

import ActionButton from 'elements/action-button';

import { useEffectOnlyOnUpdate } from 'hooks/useEffectOnlyOnUpdate';
import ExpandingSectionStore from 'utils/expanding-sections-store';

const GroupController = ({
  groupId,
  expandSettings,
  getGroupControllerConfig,
  ...rest
}) => {
  const { isALLOpened } = ExpandingSectionStore.getGroupState({ groupId });
  const expandFromProps = expandSettings ? expandSettings.defaultExpand : true;
  const [isExpanded, setIsExpanded] = useState(expandFromProps);
  const onClick = useCallback(() => {
    ExpandingSectionStore.setGroupState({
      groupId,
      state: !isExpanded,
    });
    if (getGroupControllerConfig) {
      getGroupControllerConfig(!isExpanded);
    }
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    if (isExpanded !== expandFromProps) {
      setIsExpanded(expandFromProps);
    }
  }, [expandSettings]);

  useEffect(() => {
    ExpandingSectionStore.subscribeRemoteController({
      groupId,
      setIsExpanded,
    });
  }, []);

  useEffectOnlyOnUpdate(() => {
    if (isALLOpened) {
      setIsExpanded(true);
    }
  }, [isALLOpened]);

  return (
    <ActionButton
      {...rest}
      data={isExpanded ? 'Hide All' : 'Show All'}
      onClick={onClick}
    />
  );
};

GroupController.propTypes = {
  groupId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isDefaultExpanded: PropTypes.bool,
  expandSettings: PropTypes.shape({
    defaultExpand: PropTypes.bool,
  }),
  getGroupControllerConfig: PropTypes.any,
};

GroupController.defaultProps = {
  isDefaultExpanded: true,
  expandSettings: {
    defaultExpand: true,
  },
  getGroupControllerConfig: null,
};


export default GroupController;
