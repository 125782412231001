import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';


const Subheading = ({ fieldData }) => (
  <p
    className="subheading"
  >
    {fieldData.value}
  </p>
);


Subheading.propTypes = {
  fieldData: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }).isRequired,

};

Subheading.defaultProps = {

};


export default Subheading;
