import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import FullSwitcher from 'components/full-switcher';
import Table from 'components/table';
import { get } from 'lodash';

import { getChunkedData } from 'utils/helpers/models';

import { StyledMultiColumnWrapper } from './styled-components';

import './styles.scss';

const TableForm = ({ tableData, orderRules, changeOrder, entityName, unitActions, form, hasPermission }) => (
  tableData.map(({ model, title, data, tableName, splitColumnsConfig }, index) => {
    const [isAllActive, setIsAllActive] = useState(false);
    const { setFieldValue, values } = form;
    const columnsNumber = get(splitColumnsConfig, 'columnsNumber', 1);
    const currentOrderRules = orderRules[tableName];
    const setOrder = ({ orderBy }) => changeOrder({ orderBy, tableName, entityName });

    const clickHandler = () => {
      for (let i = 0; i < values.clientCategories.length; i++) {
        setFieldValue(`clientCategories[${i}].isAssigned`, !isAllActive);
      }
    };
    useEffect(() => {
      if (values.clientCategories) {
        const isActive = values.clientCategories.filter((project) => project.isAssigned).length === values.clientCategories.length;
        setIsAllActive(isActive);
      }
    }, [form]);
    // TODO: refactor switcher, remove from table
    return (
      <div
        key={`${title}/${index}`} // eslint-disable-line react/no-array-index-key
        className="details-form__table"
      >
        {
          (values.clientCategories && hasPermission) &&
          <FullSwitcher
            placeholder="Assign All"
            name="assignAll"
            isAssignedAll={isAllActive}
            onClick={clickHandler}
          />
        }
        <div className="details-form__title-wrapper">

          <p className="details-form__title">
            {title}
          </p>

        </div>

        <StyledMultiColumnWrapper
          cssRules={get(splitColumnsConfig, 'cssRules')}
        >
          {
            Array.from({ length: columnsNumber }).map((_, columnIndex) => (
              <Table
                unitActions={unitActions}
                key={columnIndex} // eslint-disable-line react/no-array-index-key
                model={model}
                isLoaded
                withoutAnimation
                data={getChunkedData({ data, chunksNumber: columnsNumber, chunkIndex: columnIndex })}
                orderRules={currentOrderRules}
                changeOrder={setOrder}
              />
            ))
          }

        </StyledMultiColumnWrapper>
      </div>
    );
  })
);


TableForm.propTypes = {
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  entityName: PropTypes.string.isRequired,
};

TableForm.defaultProps = {
  tableData: [],
};


export default TableForm;
