import {
  appStorage,
} from 'core/storage';
import update from 'immutability-helper';
import moment from 'moment';
import {
  createReducer,
} from 'redux-create-reducer';

import {
  reconciliationActionsTypes,
} from './actions';

import {
  ENTITY_NAME,
  STORE_KEYS,
} from './constants';

const initialState = {
  errors: {},
  entityName: ENTITY_NAME,
  isFetching: false,
  clientNamesData: [],
  favoritesClients: appStorage.getFavoritesClients() || {},
  reportCommonData: null,

  filters: {
    [STORE_KEYS.CLIENT_ID]: null,
    [STORE_KEYS.YEAR]: moment(),
  },
};

export default createReducer(initialState, {
  [reconciliationActionsTypes.CHANGE_REPORT_FILTER](state, {
    payload,
  }) {
    return update(state, {
      filters: { $merge: payload },
    });
  },

  [reconciliationActionsTypes.RESET_REVIEW_REPORT](state) {
    return update(state, {
      filters: { $set: initialState.filtersState },
      reportCommonData: { $set: initialState.reportCommonData },
    });
  },

  [reconciliationActionsTypes.GET_CLIENT_NAMES_LIST](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },

  [reconciliationActionsTypes.GET_CLIENT_NAMES_LIST_SUCCESS](state, {
    payload: {
      clientNamesList,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      clientNamesData: {
        $set: clientNamesList,
      },
      errors: {
        $unset: ['getClientNamesListError'],
      },
    });
  },

  [reconciliationActionsTypes.GET_CLIENT_NAMES_LIST_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
});
