import { getFormattedDate } from '../helpers';
import { getContentCss } from '../styles';

const { contentCss, dataCss } = getContentCss;

const calculateData = (record) => {
  const { isDismiss, isRehire, onLeave, hasPassed, dateStart, endDate } = record;

  if (isDismiss) return `Dismissed since ${getFormattedDate(dateStart)}`;
  if (isRehire) return `Rehired since ${getFormattedDate(dateStart)}`;
  if (onLeave) {
    const dateRange = endDate ?
      `from ${getFormattedDate(dateStart)} to ${getFormattedDate(endDate)}` :
      `since ${getFormattedDate(dateStart)}`;

    return `${hasPassed ? 'Was on' : 'On'} long-term leave ${dateRange}`;
  }

  return 'Unknown action';
};

export const generateHistoryRecord = (record) => {
  const { historyId } = record;

  const data = calculateData(record);

  return {
    isInfoCard: true,
    recordId: historyId,
    cssRules: '', // This should ideally be classnames or be handled by a CSS-in-JS library like styled-components or @emotion/styled
    content: [
      {
        data: [
          {
            data, // This appears to be a placeholder, replace with actual data
            type: 'description',
            cssRules: dataCss, // Replace with actual value or constant
          },
        ],
        cssRules: contentCss, // Replace with actual value or constant
      },
    ],
  };
};
