export const onSubmitUploadedFile = ({ values, onSubmit, channelPartnerId, file, entityName }) => {
  const {
    uploadTitle,
    uploadDate,
  } = values;

  onSubmit({
    channelPartnerId,
    title: uploadTitle,
    effectiveDate: uploadDate,
    file,
    entityName,
  });
};
