import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import classNames from 'classnames';


const propTypes = {
  currentDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]).isRequired,
  selectedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]),
  displayedDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]).isRequired,
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
  onSelectYear: PropTypes.func.isRequired,
};

const defaultProps = {
  selectedDate: '',
  minYear: undefined,
  maxYear: undefined,
};

const Decade = ({
  currentDate,
  selectedDate,
  displayedDate,
  minYear,
  maxYear,
  onSelectYear,
}) => {
  const currentYear = currentDate.year();
  const displayedYear = displayedDate.year();
  const selectedYear = selectedDate ? selectedDate.year() : null;

  return (
    <div className="calendar-date-picker__decade">
      {
        Array.from(new Array(9)).map((_, index) => {
          const year = (displayedYear - 4) + index;
          const isNow = year === currentYear;
          const isSelected = year === selectedYear;
          const isDisabled = (
            (minYear != null && year < minYear) ||
            (maxYear != null && year > maxYear)
          );
          const onClick = isDisabled ?
            undefined :
            () => onSelectYear(year);

          return (
            <button
              key={index} // eslint-disable-line react/no-array-index-key
              className={classNames(
                'calendar-date-picker__cell',
                'calendar-date-picker__cell--year',
                {
                  'calendar-date-picker__cell--now': isNow,
                  'calendar-date-picker__cell--selected': isSelected,
                  'calendar-date-picker__cell--inactive': isDisabled,
                  'calendar-date-picker__cell--without-hover': isDisabled,
                },
              )}
              type="button"
              onClick={onClick}
            >
              {year}
            </button>
          );
        })
      }
    </div>
  );
};

Decade.propTypes = propTypes;
Decade.defaultProps = defaultProps;

export default Decade;
