import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';

import WarninngIcon from 'assets/img/icon-warning.svg';

import './styles.scss';

const FormErrorMessage = ({ children, isVisible, withIcon }) => (
  <div
    className={
      classNames(
        'error-message',
        { 'error-message--visible': isVisible },
      )
    }
  >
    {
      withIcon &&
        <SVG
          className="error-message__icon"
          src={WarninngIcon}
        />
    }


    <span className="error-message__title">
      {children}
    </span>


  </div>
);


FormErrorMessage.propTypes = {
  children: PropTypes.node,
  isVisible: PropTypes.bool,
  withIcon: PropTypes.bool,
};

FormErrorMessage.defaultProps = {
  children: '',
  isVisible: true,
  withIcon: false,
};

export default FormErrorMessage;
