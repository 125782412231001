import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import TextInput from 'components/inputs/text';
import Controls from 'components/list-controls';
import Loader from 'components/loader';
import { userActions } from 'core/user/actions';
import {
  selectFormattedUserData,
  selectIsFetchingUserData,
} from 'core/user/selectors';
import GoTop from 'elements/go-top-button';
import Wrapper from 'elements/wrapper';
import { createStructuredSelector } from 'reselect';
import { autoScrollToTop } from 'utils/react';

import './styles.scss';

const UserSettingsContent = ({
  location,
  isFetching,
  getUserData,
  userData,
}) => {
  autoScrollToTop(location);

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <Controls
        title="User Settings"
      />
      <Loader isLoaded={!isFetching}>
        <Wrapper className="user-settings">
          <div className="user-data">
            <h4 className="user-data__title">User Details</h4>
            <div className="user-data__details">
              {Object.keys(userData).map((key) => (
                <TextInput
                  name={key}
                  key={key}
                  withLabel
                  inputValue={userData[key]}
                  label={key}
                  cssRules={`grid-area: ${key.toLowerCase().replace(' ', '')}`}
                  isDisabled
                />
              ))}
            </div>
          </div>
        </Wrapper>
      </Loader>
      <GoTop />
    </>
  );
};

UserSettingsContent.propTypes = {
  location: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  getUserData: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
};

UserSettingsContent.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  userData: selectFormattedUserData,
  isFetching: selectIsFetchingUserData,
});

const mapDispatchToProps = {
  getUserData: userActions.getUserData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettingsContent);
