import { merge } from 'lodash';

export const sortBy = {
  accessor: 'name',
  direction: 'asc',
  type: 'alphabetical',
};

export const calendarStepControlsConfig = {
  step: 1,
  unitName: 'year',
};

export const calendarPopperProps = {
  placement: 'bottom-start',
};

export function mergeItemsById({
  initialListItems,
  listItems,
}) {
  const ids = [...listItems].map((item) => item.id);
  const initialItemsById = [...initialListItems]
    .filter((item) => ids.includes(item.id.toString()));
  const mappedItems = initialItemsById.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});

  return listItems.map((item) => merge(mappedItems[item.id.toString()], item));
}
