export const ENTITY_NAME = 'CLIENTS';

export const STORE_KEYS = {
  NAME: 'name',
  STATUS: 'status',
  COUNTRY: 'country',
  START_FROM: 'startFrom',
  IS_FAVORED: 'isFavored',
  USER_FULL_NAME: 'userFullname',
  RATE_CARD_NAME: 'rateCardName',
  IN_ARREARS: 'inArrears',
  IS_PLATINUM_RATES: 'isPlatinumRates',
  BILLING_AGENT_NAME: 'billingAgentName',
};

export const CLIENT_STATUSES = {
  IS_ACTIVE: 'Active',
  IS_INACTIVE: 'Inactive',
  IS_NOT_YET_ACTIVE: 'Not yet active',
  IS_DISMISSED: 'Dismissed',
};

export const CLIENT_ACTION_BY_STATUS = {
  IS_ACTIVE: {
    actionTitle: 'Deactivate',
    description: 'Once the client becomes inactive, adding new task orders will be forbidden.',
  },
  IS_INACTIVE: {
    actionTitle: 'Reactivate',
    description: 'Once the client becomes active, you\'ll be able to add new task orders.',
  },
  IS_NOT_YET_ACTIVE: {
    actionTitle: 'Deactivate. No MSA',
    description: 'The client can be reactivated at any time.',
  },
  IS_DISMISSED: {
    actionTitle: 'Reopen',
    description: 'The system will still need a signed MSA in order to activate the client.',
  },
};

export const CLIENT_ACTION_BY_STATUS_FOR_BUTTONS = {
  IS_ACTIVE: 'Deactivate',
  IS_INACTIVE: 'Reactivate',
  IS_NOT_YET_ACTIVE: 'Deactivate',
  IS_DISMISSED: 'Reopen',
};
