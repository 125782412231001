import { useEffect } from 'react';

export const autoScrollToTop = (location) => {
  useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    [location],
  );
};
