import gql from 'graphql-tag';

export const queryConfig = {
  getUserData: gql`
    query userData($userId: Int) {
      userData(userId: $userId) {
        userId
        fullName
        title
        email
        phone
        entityname
        lastLogin
        loginIp
      }
    }
  `,
};
