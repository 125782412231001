import { USERS_GROUPS } from 'core/auth/constants';

import { isAudit } from './isAudit';

export const combineBySystemRoles = (devCenters) => {
  const devCentersForDd = [];
  const devCentersForAuditors = [];
  devCenters.forEach((item) => {
    if (isAudit(USERS_GROUPS[item.role])) {
      devCentersForAuditors.push(item);
    } else {
      devCentersForDd.push(item);
    }
  });

  return {
    devCentersForDd,
    devCentersForAuditors,
  };
};
