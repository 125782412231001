import React, { useCallback } from 'react';

import { useForm, useFieldArray, FormProvider } from 'react-hook-form';

import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import Loader from 'components/loader';
import ActionButton from 'elements/action-button';
import { uniqueNameValidator, uniqueKeyValidator } from 'forms/categories-form/validations';
import { useInfiniteScroller } from 'forms/hooks';
import {
  StyledFormContainer,
  StyledFormHeaderBox,
  StyledForm,
  StyledFormControls,
  StyledEmptyTemplate,
} from 'forms/styled-components';
import { useEffectOnlyOnUpdate } from 'hooks';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { SubmitButton } from './components/buttons';
import { FieldArrayItem } from './components/field-array-item';
import FormHeading from './components/form-heading';
import { Notifications } from './components/notifications';
import { useNotifications } from './hooks';
import { actionButtonsStyles, addButtonStyles } from './styles';
import { getDirtyCategories } from './utils';

const validationSchema = Yup.object({
  categories: Yup.array(Yup.object({
    key: Yup.string().transform((value) => value.trim()).required('Min 1 character is required.').test('key', 'key', uniqueKeyValidator),
    name: Yup.string().transform((value) => value.trim()).required('Min 1 character is required.').test('name', 'name', uniqueNameValidator),
    isCreated: Yup.boolean(),
    isActive: Yup.boolean(),
    categoryId: Yup.number(),
    isDisabled: Yup.boolean(),
    isDeleteIconHidden: Yup.boolean(),
  })),
});

export const Form = ({
  type,
  formData: initialCategories,
  formHeaders,
  onUpdateCategories: updateCategories,
  onDeleteCategory: deleteCategory,
  categoriesKeys,
  categoriesNames,
  hiqoCategoriesNames,
  emptyFormTemplate,
  emptyFormMessage,
  isSubmitting,
  errors,
  isFailed,
  hasUpdatePermissions,
}) => {
  const methods =
    useForm({
      defaultValues: { categories: initialCategories },
      resolver: (values, context, options) => yupResolver(validationSchema, {
        context: { onlyDirty: true },
      })(values, {
        categories: values.categories,
        ...context,
      }, options),
      context: {
        initialCategories,
        categoriesKeys,
        hiqoCategoriesNames,
        categoriesNames,
      },
    });

  const {
    handleSubmit,
    control,
    reset,
    formState:
    { isDirty, dirtyFields, errors: formErrors },
  } = methods;

  const { containerId, isHidden } = useNotifications(errors, formErrors, { isFailed, isDirty });
  const { fields, append, remove } = useFieldArray({
    name: 'categories',
    control,
  });

  // infinite scroll
  const {
    formFields,
    counter,
    ref,
    isLoading,
    setCounterFields,
  } = useInfiniteScroller(fields, initialCategories.length);

  // handlers
  const handleAddCategory = useCallback(() => {
    append(emptyFormTemplate);
    setCounterFields((prevState) => prevState + 1);
  }, []);

  const handleCancelCategory = useCallback(() => {
    toast.dismiss();
    reset();
  }, [reset]);

  // onSubmit
  const onSubmit = useCallback((formData) => {
    const categories = getDirtyCategories(
      dirtyFields.categories,
      formData.categories
    );

    updateCategories(categories, type);
  }, [
    initialCategories,
    isDirty,
    dirtyFields,
    formErrors,
  ]);

  useEffectOnlyOnUpdate(() => {
    reset({ categories: initialCategories });
  }, [initialCategories]);

  return (
    <FormProvider {...methods}>
      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
      >
        <StyledFormHeaderBox>
          {formHeaders.map((header) => (<FormHeading key={header.id} {...header} />))}
        </StyledFormHeaderBox>
        <StyledFormContainer>
          {
            fields.length ?
              formFields.map((field, index) => (
                <FieldArrayItem
                  key={field.id}
                  field={field}
                  index={index}
                  control={control}
                  ref={ref}
                  counter={counter}
                  removeAction={remove}
                  onDelete={deleteCategory}
                  hasUpdatePermissions={hasUpdatePermissions}
                />
              )) :
              <StyledEmptyTemplate>{emptyFormMessage}</StyledEmptyTemplate>
          }
        </StyledFormContainer>
        <Loader isLoaded={!isLoading} />
        <Notifications
          key={`${containerId}${isHidden}`}
          containerId={containerId}
          isHidden={isHidden}
        />
        <>
          <ActionButton
            data="Add Category"
            cssRules={addButtonStyles}
            initialCategories="Add Category"
            onClick={handleAddCategory}
            disabled={!hasUpdatePermissions}
          />
          <StyledFormControls data-id="controls">
            <SubmitButton
              type="submit"
              cssRules={actionButtonsStyles}
              buttonTitle="Save Changes"
              hasFormChanges={hasUpdatePermissions && isDirty}
              isFormSubmitted={isSubmitting}
            />
            {hasUpdatePermissions && isDirty && (
              <ActionButton
                cssRules={actionButtonsStyles}
                data="Cancel"
                onClick={handleCancelCategory}
              />
            )}
          </StyledFormControls>
        </>
      </StyledForm>
    </FormProvider>
  );
};

Form.propTypes = {
  formData: PropTypes.arrayOf(PropTypes.shape({})),
  categoriesNames: PropTypes.arrayOf(PropTypes.string),
  formHeaders: PropTypes.arrayOf(PropTypes.shape({})),
  emptyFormMessage: PropTypes.string,
  emptyFormTemplate: PropTypes.shape({}),
  type: PropTypes.string.isRequired,
  onUpdateCategories: PropTypes.func.isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isFailed: PropTypes.bool,
  categoriesKeys: PropTypes.arrayOf(PropTypes.string),
  hiqoCategoriesNames: PropTypes.arrayOf(PropTypes.string),
  hasUpdatePermissions: PropTypes.bool,
  errors: PropTypes.shape({}),
};

Form.defaultProps = {
  formData: undefined,
  categoriesNames: [],
  formHeaders: [],
  emptyFormMessage: 'This section currently contains no data.',
  emptyFormTemplate: undefined,
  isSubmitting: false,
  isFailed: false,
  hasUpdatePermissions: true,
  categoriesKeys: [],
  hiqoCategoriesNames: [],
  errors: {},
};

export default Form;
