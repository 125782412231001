import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import Tooltip from 'components/tooltip';
import { REGEX } from 'core/constants/validation';
import ErrorMessage from 'elements/error-message';
import { get } from 'lodash';
import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  font-size: 1.5rem;
  line-height: 2.4rem;

  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;

const TextInput = ({
  title,
  name,
  withError,
  isLocked,
  hasChanges,
  inputValue,
  isDisabled,
  onChange,
  onSelect,
  onBlur,
  placeholder,
  label,
  withLabel,
  isRequired,
  hasManuallyUpdates,
  error,
  children,
  tooltipContent,
  isMultiline,
  withWarning,
  cssRules,
  withErrorBox,
  isPassword,
  maxLength,
}) => {
  const isLabelVisible = !!inputValue || inputValue === 0 || inputValue === '0';

  const tabIndex = isLocked || isDisabled ? -1 : 0;

  const onBlurHandler = () => {
    if (onBlur) {
      onBlur();
    } else {
      const stringifyInputValue = String(inputValue);

      if (stringifyInputValue.match(REGEX.EXTRA_SPACE)) {
        onChange(stringifyInputValue.trim());
      }
    }
  };

  const changeValue = (event) => {
    const currentValue = get(event, 'target.value', '');

    onChange(currentValue);
  };

  return (
    <StyledContainer
      className={
        classNames(
          'text-input',
          { 'text-input--with-error': withError },
          { 'text-input--has-changes': hasChanges },
          { 'text-input--textarea': isMultiline },
          { 'text-input--locked': isLocked },
          { 'text-input--has-changes-locked': isLocked && hasChanges },
          { 'text-input--with-warning-locked': isLocked && withWarning },
          { 'text-input--locked-textarea': isLocked && isMultiline },
        )
      }
      cssRules={cssRules}
      title={inputValue || title}
    >
      {
        isMultiline ? (
          <TextareaAutosize
            id={name}
            name={name}
            className={
              classNames(
                'text-input__input-field',
                'text-input__input-field--textarea',
                { 'text-input__input-field--with-error': withError },
                { 'text-input__input-field--with-tooltip': tooltipContent },
                { 'text-input__input-field--with-changes': hasChanges },
                { 'text-input__input-field--with-warning': withWarning }
              )
            }
            minRows={1}
            value={inputValue}
            disabled={isDisabled}
            onChange={changeValue}
            onSelect={onSelect}
            placeholder={withLabel ? '' : placeholder}
            onBlur={onBlurHandler}
            autoComplete="off"
            tabIndex={tabIndex}
          />
        ) : (
          <input
            id={name}
            name={name}
            type={isPassword ? 'password' : 'text'}
            className={
              classNames(
                'text-input__input-field',
                { 'text-input__input-field--with-error': withError },
                { 'text-input__input-field--with-tooltip': tooltipContent },
                { 'text-input__input-field--with-changes': hasChanges },
                { 'text-input__input-field--with-warning': withWarning }
              )
            }
            value={inputValue}
            disabled={isDisabled}
            onChange={changeValue}
            placeholder={withLabel ? '' : placeholder}
            onBlur={onBlurHandler}
            autoComplete="off"
            tabIndex={tabIndex}
            {...(maxLength && { maxLength })}
          />
        )
      }

      {
        tooltipContent && !isLocked && (
          <Tooltip
            name={name}
            description={tooltipContent}
          />
        )
      }
      {
        withLabel && (
          <label
            className={
              classNames(
                'text-input__label',
                { 'text-input__label--visible': isLabelVisible },
                { 'text-input__label--with-error': withError || hasManuallyUpdates }
              )
            }
            htmlFor={name}
          >
            {label}
            {
              !isRequired && (
                <span className="text-input__optional-label">
                &nbsp;(optional)
                </span>
              )
            }
          </label>
        )
      }

      {
        withError && !isLocked && !withErrorBox && (
          <ErrorMessage>
            {error}
          </ErrorMessage>
        )
      }

      {
        children
      }

    </StyledContainer>
  );
};

TextInput.propTypes = {
  withErrorBox: PropTypes.bool,
  cssRules: PropTypes.string,
  withWarning: PropTypes.bool,
  isMultiline: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.shape({}),
  ]),
  name: PropTypes.string.isRequired,
  withError: PropTypes.bool,
  isLocked: PropTypes.bool,
  hasChanges: PropTypes.bool,
  inputValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  withLabel: PropTypes.bool,
  isRequired: PropTypes.bool,
  hasManuallyUpdates: PropTypes.bool,
  error: PropTypes.string,
  children: PropTypes.node,
  isPassword: PropTypes.bool,
  maxLength: PropTypes.number,
  title: PropTypes.string,
};

TextInput.defaultProps = {
  withErrorBox: false,
  cssRules: '',
  withWarning: false,
  isMultiline: false,
  tooltipContent: '',
  withError: false,
  isLocked: false,
  hasChanges: false,
  isDisabled: false,
  inputValue: '',
  onChange: () => null,
  onSelect: () => null,
  onBlur: null,
  placeholder: '',
  label: '',
  withLabel: true,
  isRequired: true,
  hasManuallyUpdates: false,
  error: '',
  children: null,
  isPassword: false,
  maxLength: null,
  title: '',
};

export default TextInput;
