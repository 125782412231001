import {
  colorPrimary,
  colorSecondaryText,
  colorSecondaryGrayLight,
} from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import {
  clientsModuleAccess,
  taskOrderModuleAccess,
  deliveryRootModuleAccess,
} from 'core/auth/guaranteedAccessRoles';
import { REPORTS_TYPES, PROJECTS_TYPES, BILLING_PROJECT_TABLE_NAMES } from 'core/billing-reports/constants';
import { getHours, getRevenue } from 'core/billing-reports/utils';
import { UNITS_TYPES } from 'core/constants';

import { getExcludedColumns } from 'layouts/billing-project-reports/model/utils';
import { NOT_IN_TO_TYPES, TOTALS_COLUMN_NAMES } from 'layouts/billing-project-reports/model/utils/constants';
import { get } from 'lodash';
import { getHasPermissions } from 'utils/auth';

import infoCardCalendarGetter from './calendar-getter';
import clientCategoriesTableConfigGetter from './config/client-categories-table-config';
import notInTOTableConfigGetter from './config/not-in-to-table-config';
import projectODCTableConfigGetter from './config/odc-table-config';
import projectOvertimeTableConfigGetter from './config/overtime-table-config';
import projectsDetailsInfoCardConfig from './config/project-details-info-card-config';
import projectPTMTableConfigGetter from './config/ptm-table-config';
import projectTTMTableConfigGetter from './config/ttm-table-config';
import infoCardRowsModelGetter from './info-card-rows-model-getter';
import singleRowGetter from './single-row-getter';
import tableRowsModelGetter from './table-rows-model-getter';
import workLogsRowsModelGetter from './work-logs-rows-model-getter';

const { RM, HRM, CEO, CTO, CPS } = USERS_GROUPS;

const getFieldName = (isDTOReport) => {
  if (isDTOReport) {
    return 'totalWithSurcharge';
  }
  return 'total';
};

const getRequiredTotals = ({
  withHrs = true,
  withTotal = true,
  isDTOReport = true,
  isHRM = false,
  columnNames = [],
  projectType,
} = {}) => {
  const columnsForTotals = columnNames.length ?
    columnNames.slice(1) : [];

  const excludedColumns = getExcludedColumns(projectType, { isDTOReport, isHRM });

  const includedColumns =
    excludedColumns.length ?
      columnsForTotals.filter((columnName) => excludedColumns.indexOf(columnName) === -1) :
      columnsForTotals;

  return includedColumns.map((columnName) => {
    const getTotalByField = TOTALS_COLUMN_NAMES.includes(columnName) &&
      withTotal;

    if (columnName === 'Hours' && withHrs) {
      return {
        property: 'hours',
        valueGetter: getHours,
      };
    }
    if (getTotalByField) {
      return {
        property: getFieldName(isDTOReport),
        valueGetter: getRevenue,
      };
    }
    return {
      property: columnName,
      valueGetter: () => 'empty',
    };
  });
};

export const billingProjectReportsDataModelGetter = (
  userGroup,
  {
    title,
    client,
    endDate,
    msaName,
    clientPm,
    issueDate,
    projectId,
    taskOrderId,
    emailReports,
    effectiveDate,
    projectType = 'TTM',
  },
  {
    reportType,
    hasReportRows,
    worklogReportRows,
    nonBillableWorklogReportRows,
  },
  holidaysList,
  availableDevCentersForDd
) => {
  const groupId = 'billingSummary';
  const { month, year } = holidaysList;
  const clientName = get(client, 'name', '');
  const clientId = get(client, 'clientId', '');
  const isHRM =
    getHasPermissions(userGroup, [HRM]) || getHasPermissions(userGroup, [RM]); // HRM & RM have same permissions for this report
  const isCTO = getHasPermissions(userGroup, [CTO]);
  const isCPS = getHasPermissions(userGroup, [CPS]);
  const isDTOReport = reportType === REPORTS_TYPES.DTO;
  const isChefs = getHasPermissions(userGroup, [CTO, CEO]);
  const hasPermissionsTODeliveryModule = getHasPermissions(
    userGroup,
    deliveryRootModuleAccess
  );
  const isPtmReport =
    projectType === PROJECTS_TYPES.FP || projectType === PROJECTS_TYPES.PTM;
  const hasPermissionsTOClientModule = getHasPermissions(
    userGroup,
    clientsModuleAccess
  );
  const hasPermissionsTOTaskOrderModule = getHasPermissions(
    userGroup,
    taskOrderModuleAccess
  );

  const overtimeRequiredTotals = getRequiredTotals({
    withTotal: !isHRM,
    isDTOReport,
    isHRM,
    columnNames: projectOvertimeTableConfigGetter({
      isCTO,
      isHRM,
      isChefs,
      isPtmReport,
      hasPermissionsTODeliveryModule,
      isDTOReport,
      projectType,
      availableDevCentersForDd,
    }).map(({ columnName }) => columnName),
    projectType,
  });

  let report;
  let requiredTotals = [];
  switch (projectType) {
    case PROJECTS_TYPES.FP:
    case PROJECTS_TYPES.PTM:
      requiredTotals = getRequiredTotals({
        withTotal: !isHRM,
        isDTOReport,
        isHRM,
        columnNames: projectPTMTableConfigGetter({
          isCTO,
          isHRM,
          isChefs,
          hasPermissionsTODeliveryModule,
          isDTOReport,
          availableDevCentersForDd,
        }).map(({ columnName }) => columnName),
        projectType,
      });
      report = projectPTMTableConfigGetter({
        isCTO,
        isHRM,
        isChefs,
        hasPermissionsTODeliveryModule,
        isDTOReport,
        availableDevCentersForDd,
      });
      break;
    case PROJECTS_TYPES.ODC:
      requiredTotals = getRequiredTotals({
        withHrs: false,
        withTotal: !isHRM,
        isDTOReport,
        isODC: true,
        isHRM,
        columnNames: projectODCTableConfigGetter({
          isCTO,
          isHRM,
          isChefs,
          hasPermissionsTODeliveryModule,
          isDTOReport,
          availableDevCentersForDd,
        }).map(({ columnName }) => columnName),
        projectType,
      });
      report = projectODCTableConfigGetter({
        isCTO,
        isHRM,
        isChefs,
        hasPermissionsTODeliveryModule,
        isDTOReport,
        availableDevCentersForDd,
      });
      break;
    case PROJECTS_TYPES.TTM:
      requiredTotals = getRequiredTotals({
        withHrs: false,
        withTotal: !isHRM,
        isHRM,
        isDTOReport,
        columnNames: projectTTMTableConfigGetter({
          isCTO,
          isHRM,
          isChefs,
          hasPermissionsTODeliveryModule,
          isDTOReport,
          availableDevCentersForDd,
        }).map(({ columnName }) => columnName),
        projectType,
      });
      report = projectTTMTableConfigGetter({
        isCTO,
        isHRM,
        isChefs,
        hasPermissionsTODeliveryModule,
        isDTOReport,
        availableDevCentersForDd,
      });
      break;
    default:
      break;
  }

  const hasRequiredTotals = !!requiredTotals.length;
  const hasOvertimeRequiredTotals = !!overtimeRequiredTotals.length;
  const infoCard = infoCardRowsModelGetter({
    cssRules: `
      display: grid;
      grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr) minmax(auto, 1fr) minmax(auto, 1fr);
      grid-template-areas: "heading heading heading heading" "left-part left-part left-part right-part-top" "left-part left-part left-part right-part-bottom";
      margin: 2.4rem 0;
    `,
    content: projectsDetailsInfoCardConfig({
      isHRM,
      isCTO,
      title,
      endDate,
      msaName,
      clientPm,
      clientId,
      issueDate,
      projectId,
      clientName,
      projectType,
      taskOrderId,
      emailReports,
      effectiveDate,
      tableTitle: 'Project Details',
      hasPermissionsTOClientModule,
      hasPermissionsTOTaskOrderModule,
    }),
  });
  const tableClientCategories = tableRowsModelGetter({
    isHRM,
    isCPS,
    groupId,
    dataKey: 'clientCategories',
    tableName: BILLING_PROJECT_TABLE_NAMES.CLIENT_CATEGORIES,
    subTitle: 'Assigned Client Categories',
    dataTemplate: clientCategoriesTableConfigGetter({
      isCTO,
      isCPS,
      isChefs,
      projectId,
      hasPermissionsTODeliveryModule,
      hasPermissionsTOTaskOrderModule,
    }),
  });
  const tableBillingSummary = tableRowsModelGetter({
    tableName: 'projectRows',
    title: 'Billing summary',
    shouldTitleBeRendered: true,
    dataKey: isPtmReport ? 'projectRowsInTO' : 'projectRows',
    projectType,
    preventScrolling: true,
    dataTemplate: report,
    label: projectType,
    requiredTotals,
    hasRequiredTotals,
    isPtmReport,
    groupId,
    isHRM,
    isDTOReport,
  });

  const tablePtmNotInTo = tableRowsModelGetter({
    tableName: 'projectRowsNotInTO',
    title: 'Billing summary',
    shouldTitleBeRendered: false,
    dataKey: 'projectRowsNotInTO',
    preventScrolling: true,
    dataTemplate: notInTOTableConfigGetter({
      isCTO,
      isHRM,
      hasPermissionsTODeliveryModule,
      availableDevCentersForDd,
    }),
    label: NOT_IN_TO_TYPES.PTM,
    isHRM,
    requiredTotals: getRequiredTotals({
      withTotal: false,
      isDTOReport,
      isHRM,
      columnNames: notInTOTableConfigGetter({
        isCTO,
        isHRM,
        hasPermissionsTODeliveryModule,
        availableDevCentersForDd,
      }).map(({ columnName }) => columnName),
      projectType: NOT_IN_TO_TYPES.PTM,
    }),
    groupId,
    hasRequiredTotals,
    projectType: NOT_IN_TO_TYPES.PTM,
    isDTOReport,
  });

  const tableOvertime = tableRowsModelGetter({
    tableName: 'overtimeProjectRows',
    dataKey: isPtmReport ? 'overtimeProjectRowsInTO' : 'overtimeProjectRows',
    preventScrolling: true,
    dataTemplate: projectOvertimeTableConfigGetter({
      isCTO,
      isHRM,
      isDTOReport,
      isChefs,
      hasPermissionsTODeliveryModule,
      projectType,
      availableDevCentersForDd,
    }),
    isDTOReport,
    isHRM,
    groupId,
    label: 'Overtime',
    requiredTotals: overtimeRequiredTotals,
    hasRequiredTotals: hasOvertimeRequiredTotals,
    projectType,
  });

  const tableOvertimeNotInTo = tableRowsModelGetter({
    tableName: 'overtimeProjectRowsNotInTo',
    dataKey: 'overtimeProjectRowsNotInTo',
    preventScrolling: true,
    dataTemplate: notInTOTableConfigGetter({
      isCTO,
      isHRM,
      isChefs,
      isPtmReport,
      hasPermissionsTODeliveryModule,
      isDTOReport,
      availableDevCentersForDd,
    }),
    isHRM,
    isDTOReport,
    groupId,
    label: NOT_IN_TO_TYPES.OVERTIME,
    requiredTotals: getRequiredTotals({ withTotal: false,
      isDTOReport,
      isHRM,
      columnNames: notInTOTableConfigGetter({
        isCTO,
        isHRM,
        hasPermissionsTODeliveryModule,
        availableDevCentersForDd,
      }).map(({ columnName }) => columnName),
      projectType: NOT_IN_TO_TYPES.OVERTIME }),
    hasRequiredTotals,
    projectType: NOT_IN_TO_TYPES.OVERTIME,
  });
  const singleRow = singleRowGetter({ projectType, isPtmReport, isDTOReport });
  const workLogsTables = workLogsRowsModelGetter({
    isCTO,
    isHRM,
    isBillable: true,
    title: 'Detailed Worklog',
    hasPermissionsTODeliveryModule,
    hasPermissionsTOClientModule,
    tableName: 'worklogReportRows',
    groupedRows: worklogReportRows,
    availableDevCentersForDd,
  });
  const nonBillableWorkLogsTables = workLogsRowsModelGetter({
    isCTO,
    hasPermissionsTOClientModule,
    tableName: 'nonBillableWorklogReportRows',
    groupedRows: nonBillableWorklogReportRows,
    title: 'Detailed Worklog for Unbillable Hours',
    availableDevCentersForDd,
  });
  const calendarInfoCard = infoCardCalendarGetter({
    cssRules: `
      display: grid;
      grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr);
      grid-row-gap: 1.2rem;
      grid-column-gap: 5rem;
      margin: 2.4rem 0;
      && .plain-text {
        grid-column: span 2;
      }
    `,
    content: holidaysList,
    selectedMonth: month - 1,
    selectedYear: year,
    title: 'Non Working Days',
    subTitle: 'HiQo Solutions',
  });

  return {
    rules: {
      wrapperCssRules: `
        padding-bottom: 10rem;
        & .details-table {
          &__title {
            font-size: 2.2rem;
            line-height: 2.6rem;
          }

          & .table {
            min-height: 100%;
            padding-bottom: 0;
          }
        }
      `,
    },
    additionalDataGetter: () => ({
      filters: [
        {
          isActive: true,
          name: 'Activity',
          onChange: (array) => array.filter((item) => item.isActive),
          title: 'Display only Active TO',
        },
      ],
      calendarItemCssRules: `
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.4rem;
        white-space: nowrap;
        color:  ${colorPrimary};
      `,
      calendarCssRules: `
        && {
          display: flex;
          min-width: unset;
          align-items: center;
          margin-right: 1.6rem;
          padding-top: 0;
          border: none;
          & path {
            fill: ${colorPrimary};
          }
        }
        && .calendar__toggle-button {
          padding-top: 0.3rem;
        }
        && .calendar__date-wrapper {
          padding-top: 0.4rem;
        }
      `,
      controlsCssRules: `
        &&& {
          display: grid;
          grid-template-columns: 1fr repeat(2,minmax(auto,15.2rem));
          grid-row-gap: 0.8rem;
          grid-column-gap: 2.4rem;
          grid-auto-rows: minmax(3.2rem, auto);
          flex-direction: column;
          padding: 0.8rem 0 1.2rem;
          font-size: 1.5rem;
        }
      `,
      controlsWrapperCssRules: 'display: flex;',
      selectCssRules: `
         && {
           width: auto;
           color: ${colorPrimary};

          .filter-select {
            width: auto;
            height: auto;
            &__multi-value__label {
              color: ${colorPrimary};
            }
            &__placeholder {
              padding-left: 0;
              color: ${colorSecondaryGrayLight};
            }
            &__control--is-focused {
              outline: none !important;
            }
            &__dropdown-indicator {
              path {
                fill: ${colorPrimary};
              }
            }
          }

          .filter-select__option {
            font-size: 1.3rem;
            line-height: 3.4rem;
            padding: 0 2.5rem 0 1.9rem;
          }
        }
      `,
      selectCurrentTOCssRules: `
         && {
          width: auto;
          margin-left: 3.2rem;
          color: ${colorPrimary};

          .filter-select {
            width: auto;
            &__single-value,
            &__multi-value__label {
              color: ${colorPrimary};
            }
            &__placeholder {
              padding-left: 0;
              color: ${colorPrimary};
            }
            &__control--is-focused {
              outline: none !important;
            }
            &__dropdown-indicator {
              path {
                fill: ${colorPrimary};
              }
            }
          }
        }
      `,
    }),
    isSingleTab: true,
    dataTemplate: [
      infoCard,
      tableClientCategories,
      {
        type: UNITS_TYPES.SINGLE_ROW,
        cssRules: `
          display: flex;
          justify-content: space-between;
          margin: 4.8rem 0 1.2rem;
          padding: 0 1.6rem;
          font-size: 2.2rem;
          & .grid-unit {
            padding: 0;
          }
        `,
        rowData: [
          {
            type: 'text',
            componentProps: {
              data: 'Billing Summary',
              cssRules: `
              font-size: 2.2rem;
              line-height: 2.6rem;
              color: ${colorSecondaryText};
            `,
            },
          },
          hasReportRows ?
            {
              type: 'groupController',
              componentProps: {
                groupId,
              },
            } :
            {},
        ],
      },
      tableBillingSummary,
      isPtmReport && tablePtmNotInTo,
      tableOvertime,
      isPtmReport && tableOvertimeNotInTo,
      !isHRM && singleRow,
      ...workLogsTables,
      ...nonBillableWorkLogsTables,
      !isPtmReport && calendarInfoCard,
    ],
  };
};
