import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";
import ExpansionSummary from "components/expansion-summary";

import "components/expansion-panel/styles.scss";

/**
 * WARN: Due to optimization reasons, child elements will disappear immediately when sliding up.
 * Remember that, when try to increase the animation duration time!
 */

const ExpansionPanelSingle = ({
  actions,
  children,
  summaryContent,
  summaryConfig,
  setActive,
  activePanelName,
}) => {
  const { isDisabled, items = [], label = "", cssRules = "" } = summaryConfig;

  const expanded = activePanelName === label;
  const [isExpanded, setIsExpanded] = useState(expanded);

  const toggleState = () => {
    setActive(activePanelName === label ? "" : label);
    const expand = activePanelName !== label && !isExpanded;
    setIsExpanded(expand);
  };

  useEffect(() => {
    setIsExpanded(activePanelName === label);
  }, [activePanelName]);

  return (
    <Fragment>
      <ExpansionSummary
        label={label}
        items={items}
        actions={actions}
        cssRules={cssRules}
        isDisabled={isDisabled}
        isExpanded={isExpanded}
        onChange={toggleState}
      >
        {summaryContent}
      </ExpansionSummary>

      <AnimateHeight
        className="expansion-panel__animated-content"
        height={isExpanded ? "auto" : 0}
        duration={500}
      >
        {/* Do not render children when it not visible */}
        {children}
      </AnimateHeight>
    </Fragment>
  );
};

ExpansionPanelSingle.propTypes = {
  children: PropTypes.any.isRequired,
  summaryContent: PropTypes.any,
  actions: PropTypes.shape({}),
  summaryConfig: PropTypes.shape({
    isDefaultExpanded: PropTypes.bool,
    isDisabled: PropTypes.bool,
    cssRules: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setActive: PropTypes.any,
  activePanelName: PropTypes.string,
};

ExpansionPanelSingle.defaultProps = {
  actions: {},
  summaryConfig: {},
  summaryContent: null,
  setActive: null,
  activePanelName: "",
};

export default ExpansionPanelSingle;
