import { ENTITY_NAME } from 'core/configurations/category-management/constants';
import { createAction } from 'redux-actions';
import { createTypes, async } from 'utils/helpers/actions';

export const {
  GET_CLIENTS_WITH_CATEGORIES,
  GET_CLIENTS_WITH_CATEGORIES_SUCCESS,
  GET_CLIENTS_WITH_CATEGORIES_FAIL,
  CHANGE_FILTER,
  CHANGE_FILTER_SUCCESS,
  RESET_FILTERS,
  RESET_CLIENT,
  SET_ORDER_RULES,
  SET_CLIENT_CATEGORIES,
  UPDATE_CATEGORIES,
  UPDATE_CATEGORIES_SUCCESS,
  UPDATE_CATEGORIES_FAIL,
  DELETE_CATEGORIES,
  DELETE_CATEGORIES_SUCCESS,
  DELETE_CATEGORIES_FAIL,
  SET_CATEGORY_TYPE,
  CHANGE_CATEGORIES_FILTER,
  CHANGE_CATEGORIES_FILTER_SUCCESS,
  RESET_CATEGORIES_FILTER,
  RESET_ERRORS,
} = createTypes([
  ...async('GET_CLIENTS_LIST'),
  ...async('CHANGE_FILTER'),
  ...async('CHANGE_CATEGORIES_FILTER'),
  ...async('GET_CLIENTS_WITH_CATEGORIES'),
  ...async('GET_HIQO_CATEGORIES'),
  ...async('UPDATE_CATEGORIES'),
  ...async('DELETE_CATEGORIES'),
  'RESET_ERRORS',
  'RESET_CLIENT',
  'RESET_FILTERS',
  'SET_ORDER_RULES',
  'SET_CLIENT_CATEGORIES',
  'SET_CATEGORY_TYPE',
  'SET_HIQO_CATEGORIES',
  'RESET_CATEGORIES_FILTER',
], ENTITY_NAME);

export const clientsActions = {
  changeFilter: createAction(
    CHANGE_FILTER,
    ({
      currentFilter,
      storeKey,
      selected = null,
      type,
    }) => ({
      currentFilter,
      storeKey,
      selected,
      type,
    }),
    () => ({
      entityName: ENTITY_NAME,
    })
  ),

  changeFilterSuccess: createAction(
    CHANGE_FILTER_SUCCESS,
    ({
      currentFilter,
      storeKey,
      config,
    }) => ({
      currentFilter,
      storeKey,
      config,
    })
  ),

  setOrderRules: createAction(
    SET_ORDER_RULES,
    ({
      tableName,
      orderRules,
    }) => ({
      tableName,
      orderRules,
    })
  ),
  // resets
  resetFilters: createAction(RESET_FILTERS),
  resetClient: createAction(RESET_CLIENT),
};

export const categoriesActions = {
  updateCategories: createAction(UPDATE_CATEGORIES),
  updateCategoriesSuccess: createAction(UPDATE_CATEGORIES_SUCCESS, ({ categories, categoryType }) => ({ categories, categoryType })),
  updateCategoriesFail: createAction(UPDATE_CATEGORIES_FAIL, ({ errors = {} }) => ({ errors })),

  deleteCategories: createAction(DELETE_CATEGORIES),
  deleteCategoriesSuccess: createAction(DELETE_CATEGORIES_SUCCESS, ({ categories, categoryType }) => ({ categories, categoryType })),
  deleteCategoriesFail: createAction(DELETE_CATEGORIES_FAIL, ({ errors = {} }) => ({ errors })),

  getClientsWithCategories: createAction(GET_CLIENTS_WITH_CATEGORIES),
  getClientsWithCategoriesSuccess: createAction(GET_CLIENTS_WITH_CATEGORIES_SUCCESS, ({ clientsWithCategoriesList, keys }) => ({ clientsWithCategoriesList, keys })),
  getClientsWithCategoriesFail: createAction(GET_CLIENTS_WITH_CATEGORIES_FAIL, ({ error = {} }) => ({ error })),
  setClientCategories: createAction(SET_CLIENT_CATEGORIES, ({ client }) => ({ client })),
  setOrderRules: createAction(
    SET_ORDER_RULES,
    ({
      tableName,
      orderRules,
    }) => ({
      tableName,
      orderRules,
    })
  ),
  changeCategoriesFilter: createAction(
    CHANGE_CATEGORIES_FILTER,
    ({
      search,
    }) => ({
      search,
    }),
    () => ({
      entityName: ENTITY_NAME,
    })
  ),

  changeCategoriesFilterSuccess: createAction(
    CHANGE_CATEGORIES_FILTER_SUCCESS,
    ({
      search,
    }) => ({
      search,
    })
  ),
  resetErrors: createAction(RESET_ERRORS),
  resetCategoriesFilter: createAction(RESET_CATEGORIES_FILTER),
  setCategoryType: createAction(SET_CATEGORY_TYPE, ({ categoryType }) => ({ categoryType })),
};
