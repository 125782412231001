import { waitForAuthorization } from 'core/auth/sagas';
import {
  categoriesActions,
  GET_CLIENTS_WITH_CATEGORIES,
  UPDATE_CATEGORIES,
  CHANGE_CATEGORIES_FILTER,
  DELETE_CATEGORIES,
} from 'core/configurations/category-management/actions';
import { NOTIFICATIONS_FAILED_REQUESTS } from 'core/configurations/category-management/constants';
import { queryConfig } from 'core/configurations/category-management/queries';
import { selectErrors, selectEntityName, selectClient, selectCategoryType } from 'core/configurations/category-management/selectors';

import { getTypeCategoryId, getCategoriesList } from 'core/configurations/category-management/utils';
import { get } from 'lodash';
import { put, call, select, takeLatest, delay } from 'redux-saga/effects';
import { executeQuery, executeMutation, parseError } from 'utils/request';

// eslint-disable-next-line consistent-return
function* getClientsWithCategories() {
  try {
    const query = queryConfig.getClientsWithCategories;
    const options = {
      query,
    };
    const { categoryManagementData } = yield call(executeQuery, options);

    const {
      clientData,
      hiqoData,
      existingClientCategoryKeys,
      existingInternalCategoryKeys,
    } = categoryManagementData[0];

    return yield put(categoriesActions.getClientsWithCategoriesSuccess({
      clientsWithCategoriesList: [...hiqoData, ...clientData],
      keys: {
        existingClientCategoryKeys,
        existingInternalCategoryKeys,
      },
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getClientWithCategoriesError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getClientWithCategoriesError = yield call(parseError, error, options);

    yield put(categoriesActions.getClientsWithCategoriesFail({
      error: {
        getClientWithCategoriesError,
      },
    }));
  }
}

// eslint-disable-next-line consistent-return
function* updateCategories({ payload: { categories: { updateCategoriesData, createCategoriesData }, categoryType } }) {
  const { clientId } = yield select(selectClient);
  const type = yield select(selectCategoryType);
  const categoriesOptions = {
    clientId,
    type,
  };
  const updateCategoriesList = getCategoriesList(updateCategoriesData, categoriesOptions);
  const createCategoriesList = getCategoriesList(createCategoriesData, categoriesOptions);

  const typeCategoryId = getTypeCategoryId(type);

  const updateMutation = queryConfig.updateCategory(typeCategoryId);
  const createMutation = queryConfig.createCategory(typeCategoryId);

  const updateCategoriesOptions = {
    mutation: updateMutation,
    variables: {
      categoryType,
      fields: {
        category: updateCategoriesList,
      },
      clientId,
    },
  };

  const createCategoriesOptions = {
    mutation: createMutation,
    variables: {
      categoryType,
      fields: {
        category: createCategoriesList,
      },
    },
  };

  try {
    if (updateCategoriesList && createCategoriesList) {
      yield call(executeMutation, updateCategoriesOptions);
      const { createCategory: { categoryManagement: categories } } = yield call(executeMutation, createCategoriesOptions);

      return yield put(categoriesActions.updateCategoriesSuccess({
        categories,
        categoryType: type,
      }));
    }

    if (updateCategoriesList) {
      const { updateCategory: { categoryManagement: categories } } = yield call(executeMutation, updateCategoriesOptions);

      return yield put(categoriesActions.updateCategoriesSuccess({
        categories,
        categoryType: type,
      }));
    }

    const { createCategory: { categoryManagement: categories } } = yield call(executeMutation, createCategoriesOptions);

    yield put(categoriesActions.updateCategoriesSuccess({
      categories,
      categoryType: type,
    }));
  } catch (error) {
    const reduxErrors = yield select(selectErrors);
    const storedErrors = get(reduxErrors, 'updateCategoriesErrors', []);

    const updateCategoriesErrors = yield call(parseError, error, {
      containerId: NOTIFICATIONS_FAILED_REQUESTS,
      storedErrors,
    });

    return yield put(categoriesActions.updateCategoriesFail({
      errors: {
        updateCategoriesErrors,
      },
    }));
  }
}

function* deleteCategories({ payload: { categories: deleteCategoriesData, categoryType } }) {
  const { clientId } = yield select(selectClient);
  const type = yield select(selectCategoryType);

  const deleteCategoriesList = getCategoriesList(deleteCategoriesData, { clientId, type });
  const typeCategoryId = getTypeCategoryId(type);
  const deleteCategoriesOptions = {
    mutation: queryConfig.deleteCategory(typeCategoryId),
    variables: {
      categoryType,
      fields: {
        category: deleteCategoriesList,
      },
      clientId,
    },
  };

  try {
    const data = yield call(executeMutation, deleteCategoriesOptions);

    const { deleteCategory: { categoryManagement: categories } } = data;

    return yield put(categoriesActions.deleteCategoriesSuccess({
      categories,
      categoryType: type,
    }));
  } catch (error) {
    const reduxErrors = yield select(selectErrors);

    const storedErrors = get(reduxErrors, 'deleteCategoriesErrors', []);

    const deleteCategoriesErrors = yield call(parseError, error, {
      containerId: NOTIFICATIONS_FAILED_REQUESTS,
      storedErrors,
    });

    return yield put(categoriesActions.deleteCategoriesFail({ errors: { deleteCategoriesErrors } }));
  }
}

function* changeCategoriesFilter({ payload }) {
  yield delay(500);
  yield put(categoriesActions.changeCategoriesFilterSuccess(payload));
}
function* getClientsWithCategoriesWatcher() {
  yield takeLatest(GET_CLIENTS_WITH_CATEGORIES, waitForAuthorization(getClientsWithCategories));
}
function* updateCategoriesWatcher() {
  yield takeLatest(UPDATE_CATEGORIES, waitForAuthorization(updateCategories));
}
function* changeCategoriesFilterWatcher() {
  yield takeLatest(CHANGE_CATEGORIES_FILTER, waitForAuthorization(changeCategoriesFilter));
}
function* deleteCategoriesWatcher() {
  yield takeLatest(DELETE_CATEGORIES, waitForAuthorization(deleteCategories));
}
export default [
  getClientsWithCategoriesWatcher,
  updateCategoriesWatcher,
  changeCategoriesFilterWatcher,
  deleteCategoriesWatcher,
];
