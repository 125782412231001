import React from 'react';
import PropTypes from 'prop-types';

import StyledWrapper from 'elements/styled-wrapper';

import './styles.scss';


const ScreenPlaceholder = ({ title, description, cssRules, content }) => (
  <StyledWrapper className="screen-placeholder" cssRules={cssRules}>
    {title && <p className="screen-placeholder__title">{title}</p>}

    <p className="screen-placeholder__description">{description}</p>

    {content && <p className="screen-placeholder__controls">{content}</p>}
  </StyledWrapper>
);


ScreenPlaceholder.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cssRules: PropTypes.string,
  content: PropTypes.any,
};

ScreenPlaceholder.defaultProps = {
  title: '',
  cssRules: '',
  description: '',
  content: '',
};


export default ScreenPlaceholder;
