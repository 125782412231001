import React, { useMemo } from 'react';

import { Controller } from 'react-hook-form';

import PropTypes from 'prop-types';

import { KeyInput } from 'forms/categories-form/components/category-key/KeyInput';

export const CategoryKey = ({ control, index, ...props }) => {
  const rules = useMemo(() => ({
    required: true,
    minLength: 3,
  }), []);

  return (
    <Controller
      control={control}
      name={`categories.${index}.key`}
      rules={rules}
      render={({ field, fieldState }) => (
        <KeyInput
          {...field}
          {...props}
          control={control}
          ref={null}
          index={index}
          error={fieldState.error}
        />
      )}
    />
  );
};


CategoryKey.propTypes = {
  cssRules: PropTypes.string,
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  viewMode: PropTypes.bool,
  isMultiline: PropTypes.bool,
};

CategoryKey.defaultProps = {
  isMultiline: false,
  cssRules: undefined,
  viewMode: false,
};
