export const emptyCategoryTemplate = {
  key: '',
  name: '',
  isActive: true,
  isCreated: false,
  categoryId: -1,
  isDisabled: false,
};

export const formHeaders = [
  {
    id: '1-k',
    title: 'Category Key',
    cssRules: `
      grid-area: key;
    `,
  },
  {
    id: '2-n',
    title: 'Category Name',
    cssRules: `
      grid-area: name;
    `,
  },
  {
    id: '3-a',
    title: 'Actions',
    cssRules: `
      grid-area: action;
    `,
  },
];
