import { useEffectOnlyOnUpdate } from 'hooks';
import { scrollToBottom, scrollToTop } from 'utils/helpers/scrolling';

export const useHandleScroll = ({
  scrollUp,
  scrollDown,
}) => {
  useEffectOnlyOnUpdate(() => {
    if (scrollUp) {
      scrollToTop();
      return () => clearTimeout(scrollToTop);
    }

    if (scrollDown) {
      scrollToBottom();
    }

    return () => clearTimeout(scrollToBottom);
  }, [scrollUp, scrollDown]);
};
