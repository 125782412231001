import { toNumber } from 'lodash';
import { showResourceLinkByDevCenter } from 'utils/helpers/link';

import { addThousandSeparator } from 'utils/helpers/numbers';

export default ({
  isCTO,
  isHRM,
  isChefs,
  hasPermissionsTODeliveryModule,
  availableDevCentersForDd,
}) => [
  {
    columnName: 'Full name',
    headerProps: {
      cssRules: 'min-height: 2.8rem;',
    },
    valueGetter: ({
      fullname,
      devstaffId,
      resource,
      isGrandTotal,
      project,
      devcenterId,
    }) => {
      if (isGrandTotal) {
        return {
          type: 'text',
          componentProps: {
            data: `Total ${project}`,
            cssRules: `
              font-size: 1.5rem;
              font-weight: 500;`,
          },
        };
      }
      return {
        type:
            devstaffId && showResourceLinkByDevCenter(isCTO, hasPermissionsTODeliveryModule, availableDevCentersForDd, devcenterId) ?
              'link' :
              'text',
        componentProps: {
          data: fullname || resource || 'n/a',
          pathname: `/delivery/employee/${devstaffId}/${
            isChefs ? 'work-book' : 'pto-requests'
          }`,
          cssRules: 'font-size: 1.2rem;',
          isMultiline: true,
        },
      };
    },
  },
  {
    columnName: 'Hours',
    headerProps: {
      cssRules: `
      padding-right: 1rem;
      ${isHRM ? 'margin-left: auto;' : ''}`,
    },
    valueGetter: ({ hours, isGrandTotal }) => {
      const value = addThousandSeparator(toNumber(hours || 0).toFixed(2));

      const grandTotalStyles =
          'font-size: 1.2rem; font-weight: 500;';

      return {
        value,
        type: 'text',
        cssRules: `
            &&& {
              ${isGrandTotal ? grandTotalStyles : ''}
              ${isHRM ? 'text-align: right; padding-right: 1.0rem;' : 'text-align: left; padding-right: 0;'};
            }
          `
        ,
      };
    },
  },
];
