import React from 'react';
import PropTypes from 'prop-types';

import Overlay from 'elements/modal-overlay';
import DetailsForm from 'components/details-form';
import ToastContainer from 'components/toast';

import './styles.scss';


const ManagePtoModal = ({
  clearModal,
  params,
  createVacationManagementRecord,
  isFormSubmitted,
}) => {
  const {
    content,
    stylesTemplate = {},
    year,
    vacationByYearId,
    devstaffId,
  } = params;
  const { modalCss } = stylesTemplate;
  return (
    <Overlay
      clearModal={clearModal}
      cssRules={modalCss}
    >
      <ToastContainer
        containerId="managePtoModal"
      />

      <div className="manage-pto-modal">
        <p className="manage-pto-modal__title">
          {`Manage Remaining PTO Days - ${year}`}
        </p>

        <DetailsForm
          clearModal={clearModal}
          formTitle="ptoDays"
          content={content}
          onSubmit={({ fields }) => createVacationManagementRecord({ fields, vacationByYearId, devstaffId })} // TODO: add handler eslint-disable-line
          isFormSubmitted={isFormSubmitted}
          stylesTemplate={stylesTemplate}
          isFetchingDetails={false}
          ptoModal
        />

      </div>

    </Overlay>
  );
};


ManagePtoModal.propTypes = {
  isFormSubmitted: PropTypes.bool.isRequired,
  createVacationManagementRecord: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  params: PropTypes.shape({}).isRequired,
};

export default ManagePtoModal;
