/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import { selectFormState } from 'core/delivery/commentManagement/selectors';
import { TextField } from 'forms/components';

import {
  ControlsContainer,
} from 'layouts/employee-details/components/resource-tabs/components';

import { get } from 'lodash';

import moment from 'moment';

import { Attachment } from './compoments/attachment';
import { TextInput } from './compoments/text-input';
import styles from './styles.module.scss';

const TEXTS = {
  INPUT_PLACEHOLDER: 'Description',
};

export const useListColumns = ({
  hasPermissionToViewOnly,
}) => useMemo(() => [
  {
    Header: 'Attached by',
    accessor: 'attachedByUserFullname',
    type: 'text',
    Field: ({
      item,
    }) => (
      <>
        <TextField content={`${item.attachedByUserFullname},`} />
        <TextField content={moment(item.createdAtDate).format('MM/DD/YYYY')} />
      </>
    ),
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'form',
    isOptional: true,
    Field: ({
      field,
      fieldState,
      item,
    }) => (
      <TextInput
        maxLength={50}
        placeholder={TEXTS.INPUT_PLACEHOLDER}
        error={get(fieldState, 'error')}
        isLocked={
          !get(item, 'allowedForEdit', true) ||
          hasPermissionToViewOnly
        }
        {...field}
      />
    ),
  },
  {
    Header: 'Attachment',
    accessor: 'file',
    type: 'form',
    Field: ({
      item,
      fieldState,
      field: {
        onChange,
        value,
      },
      setError,
      isCreateMode,
    }) => (
      <Attachment
        file={value}
        onChange={onChange}
        form={item}
        error={get(fieldState, 'error')}
        setError={setError}
        errorClassName={styles.fieldError}
        isAllowedForEdit={get(item, 'allowedForEdit', true)}
        isAllowedForDelete={isCreateMode}
      />
    ),
  },
  {
    Header: 'Action',
    accessor: 'actions',
    type: 'action',
    Field: (props) => (
      <ControlsContainer
        {...props}
        formStateSelector={selectFormState}
        isAllowedForDelete={
          get(props, 'isAllowedForDelete', true) &&
          !hasPermissionToViewOnly
        }
      />
    ),
  },
], []);
