import { isSameOrAfterCurrentDate } from 'layouts/task-order-details/model/utils';
import { get } from 'lodash';

const getLastWbRecord = (wbRecords, dataFrom) => {
  let result = wbRecords[wbRecords.length - 1];
  wbRecords.forEach((wbRecord) => {
    if (isSameOrAfterCurrentDate(wbRecord.validFrom, dataFrom)) {
      result = wbRecord;
    }
  });
  return result;
};

export const getPotentialWbRecord = ({
  formEmployees,
  id,
  devstaffPotentialWbRecords,
}) => {
  const dataFrom = get(formEmployees[id], 'dateFrom');
  return getLastWbRecord(devstaffPotentialWbRecords, dataFrom);
};

export const generateSelectedDevCenter = ({
  potentialWbRecord,
  devcenters,
  employeeVendorId,
  employeeVendorName,
}) => ({
  selected: {
    value: potentialWbRecord ? get(potentialWbRecord, 'devcenterId') : employeeVendorId,
    label: potentialWbRecord ? devcenters.find((item) => item.value === get(potentialWbRecord, 'devcenterId')).label : employeeVendorName,
  },
  items: devcenters,
});

export const generateSelectedSeniority = ({
  potentialWbRecord,
  employeeSeniority,
  seniorityItems,
}) => ({
  selected: {
    value: potentialWbRecord ? get(potentialWbRecord, 'seniority') : employeeSeniority,
    label: potentialWbRecord ? get(potentialWbRecord, 'seniority') : employeeSeniority,
  },
  items: seniorityItems,
});
