import {
  createNewClient,
  createNewTaskOrder,
  taskOrdersControls,
  clientCardHeader,
  documentGenerate,
  documentUpload,
  taskOrderDetailsHeadingActions,
  taskOrdersDocumentGenerate,
  payrollReports,
  createResourceAccess,
  createNewChannelPartner,
} from 'core/auth/guaranteedAccessRoles';
import {
  get,
} from 'lodash';
import {
  createSelector,
} from 'reselect';

import {
  getHasPermissions,
} from 'utils/auth';

import {
  USERS_GROUPS,
} from './constants';

const {
  SALES,
} = USERS_GROUPS;

export const selectUserGroup = (state) => state.auth.userGroup;
export const selectUserFullName = (state) => get(state.auth, 'activeUser.fullName', '');
export const selectUserId = (state) => state.auth.userId;
export const selectUsersList = (state) => state.auth.usersList;
export const selectDevCenters = (state) => state.auth.devcenters;
export const selectUserLogin = (state) => state.auth.userLogin;
export const selectUserLoginLoading = (state) => state.auth.userLoginLoading;
export const selectUserVerification = (state) => state.auth.userVerification;
export const selectDevCentersBySystemRoleDD = (state) => state.auth.devcenters.devCentersBySystemRoleDD;
export const selectDevCentersBySystemRoleAuditor = (state) => state.auth.devcenters.devCentersBySystemRoleAuditors;

export const selectDevCentersByUserGroup =
  createSelector(selectDevCentersBySystemRoleDD, selectUserGroup, (devCentersBySystemRole, userGroup) => devCentersBySystemRole.find(({ role }) => USERS_GROUPS[role] === userGroup) || null);
export const selectCustomers = (state) => state.auth.customers;
export const selectBillingAgents = (state) => state.auth.billingAgents;
export const selectRoles = (state) => state.auth.roles;
export const selectErrors = (state) => state.auth.errors;
export const selectEntityName = (state) => state.common.currentEntityName;
export const selectResetVerificationStatus = (state) => state.auth.resetVerificationStatus;
export const selectResetVerificationLoading = (state) => state.auth.resetVerificationLoading;
export const selectUser = (state) => state.auth.activeUser;

export const selectUsersById = createSelector(
  selectUsersList,
  (usersList) => usersList.reduce((acc, {
    userId,
    ...rest
  }) => ({
    ...acc,
    [userId]: {
      userId,
      ...rest,
    },
  }), {})
);

export const selectUserData = createSelector(
  selectUserFullName,
  selectUserId,
  selectUsersById,
  (userFullName, userId, usersList) => get(usersList, userId, {
    userFullName,
    userId,
  })
);

export const selectHasPermissionsCreateClients = createSelector(
  selectUserGroup,
  (group) => getHasPermissions(group, createNewClient)
);

export const selectHasPermissionsCreateChannelPartner = createSelector(
  selectUserGroup,
  (group) => getHasPermissions(group, createNewChannelPartner)
);

export const selectHasPermissionsCreateTaskOrder = createSelector(
  selectUserGroup,
  (group) => getHasPermissions(group, createNewTaskOrder)
);

export const selectHasPermissionsTaskOrdersControls = createSelector(
  selectUserGroup,
  (group) => getHasPermissions(group, taskOrdersControls)
);

export const selectHasPermissionClientCardHeader = createSelector(
  selectUserGroup,
  (group) => getHasPermissions(group, clientCardHeader)
);

export const selectHasPermissionTaskOrderDetailsHeadingActions = createSelector(
  selectUserGroup,
  (group) => getHasPermissions(group, taskOrderDetailsHeadingActions)
);

export const selectHasPermissionDocumentGenerate = createSelector(
  selectUserGroup,
  (group) => getHasPermissions(group, documentGenerate)
);

export const selectHasPermissionTaskOrdersDocumentGenerate = createSelector(
  selectUserGroup,
  (group) => getHasPermissions(group, taskOrdersDocumentGenerate)
);

export const selectHasPermissionDocumentUpload = createSelector(
  selectUserGroup,
  (group) => getHasPermissions(group, documentUpload)
);

export const selectIsSales = createSelector(
  selectUserGroup,
  (group) => group === SALES
);

export const selectHasPermissionsToPayrollReports = createSelector(
  selectUserGroup,
  (group) => getHasPermissions(group, payrollReports)
);

export const selectHasPermissionsToCreateResource = createSelector(
  selectUserGroup,
  (group) => getHasPermissions(group, createResourceAccess.roles)
);

export const selectAvailableDevCentersForDd = createSelector(
  selectUserGroup,
  selectDevCentersBySystemRoleDD,
  (group, roles = []) => {
    let result = null;
    roles.forEach((role) => {
      const currentGroup = USERS_GROUPS[role.role];
      if (currentGroup === group) {
        result = role.devcenterList;
      }
    });
    return result;
  }
);
