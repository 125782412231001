import {
  colorSecondaryGrayLight,
  colorSecondaryGrayLight015,
  colorSecondaryText,
} from 'assets/styles/variables';

import { getGrandTotalColumns, getGridTemplate } from 'layouts/billing-project-reports/model/utils';
import { legendTitles } from 'utils/constants';
import { addThousandSeparator } from 'utils/helpers/numbers';

export default ({ projectType, isPtmReport, isDTOReport }) => ({
  type: 'single-row',
  cssRules: `
    display: grid;
    ${getGridTemplate(projectType, { isPtmReport, isDTOReport })}
    margin-top: 1.6rem;
    border-top: 1px solid ${colorSecondaryGrayLight};
    background-color: ${colorSecondaryGrayLight015};
    && .grid-unit:last-child {
      text-align: right;
      padding-right: 1rem;
    }
    && .grid-unit:first-child {
      padding-left: 16px;
    }
    `,
  useAccumulator: ({
    accumulator: {
      totalWithSurcharge,
      total,
      rateMo,
      rateMoWithSurcharge,
      hours,
      hasActiveFilters,
    },
  }) => {
    const totalValue = isDTOReport ?
      (totalWithSurcharge || 0) + (rateMoWithSurcharge || 0) :
      (total || 0) + (rateMo || 0);

    return [
      {
        type: 'text',
        componentProps: {
          data: hasActiveFilters ? 'Total with filters' : 'Grand Total',
          cssRules: ` && {
        font-size: 1.8rem;
        line-height: 2.6rem;
        font-weight: 500;
        color: ${colorSecondaryText};
        padding-right: 0;
      }`,
        },
        ...(!isDTOReport && { name: 'Grand Total', legend: legendTitles.billingSummaryTOReport }),
      },
      ...getGrandTotalColumns(projectType, { hours, totalValue }, { isDTOReport }),
      {
        type: 'text',
        componentProps: {
          data: totalValue ?
            `$ ${addThousandSeparator(totalValue.toFixed(2))}` :
            '$ 0',
          cssRules: ` && {
        padding-right: 0;
        font-size: 1.8rem;
        line-height: 2.4rem;
        color: ${colorSecondaryText};
      }`,
        },
      },
    ];
  },
});
