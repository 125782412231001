import React, { useCallback, useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import PdfIcon from 'assets/img/icon-pdf.svg';
import classNames from 'classnames';
import ActionButton from 'elements/action-button';
import { useDropzone } from 'react-dropzone';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import './styles.scss';

const icons = {
  'application/pdf': PdfIcon,
};

const SpanWithProgressBar = styled.span`
  border-image:  ${({ percents = 0 }) => (
    `linear-gradient(
      to right,
      #D61F26,
      #D61F26 ${percents}%,
      rgba(187,189,192, 0.5) ${percents}%,
      rgba(187,189,192, 0.5)
      ) 100% 1;`
  )};
`;

const Dropzone = ({ isMultiple, accept, onUpload, uploadingProgress, isUploadingCompleted, isUploadingInProgress, maxSize }) => {
  const [files, setFiles] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState(null);

  const cleareFile = () => {
    setFiles([]);
    onUpload(null);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    setIsFocused(false);
    setError(null);

    acceptedFiles.forEach((file) => {
      onUpload(file);
    });
  }, []);

  const { getRootProps, getInputProps, rootRef } = useDropzone({
    onDrop,
    onDropRejected: () => {
      setError(`File is larger than ${maxSize}MB`);
    },
    maxSize: maxSize * 1024 * 1024,
    accept,
  });

  const isFilePreloaded = files.length;

  const setIsDragover = () => {
    if (!isFocused) {
      setIsFocused(true);
    }
  };

  const setIsDragleave = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    rootRef.current.addEventListener('dragover', setIsDragover);
    rootRef.current.addEventListener('dragleave', setIsDragleave);

    return () => {
      rootRef.current.removeEventListener('dragover', setIsDragover);
      rootRef.current.removeEventListener('dragleave', setIsDragleave);
    };
  }, []);

  return (
    <div className="dropzone">
      <div
        className={
          classNames(
            'dropzone__wrapper',
            { 'dropzone__wrapper--focused': isFocused }
          )
        }
        {...getRootProps()}
      >
        <input
          className="dropzone__input"
          {...getInputProps()}
          accept={accept}
          multiple={isMultiple}
          autoComplete="off"
        />

        {
          isFilePreloaded ?
            files.map(({ type, name }) => (
              <div
                key={name}
                className="dropzone__file-title"
              >
                <SVG
                  className="dropzone__icon"
                  src={icons[type]}
                />

                <SpanWithProgressBar
                  className="dropzone__file-name"
                  percents={uploadingProgress}
                >
                  {name}
                </SpanWithProgressBar>

                <div className="dropzone__title-additional-content">
                  {
                    isUploadingInProgress ? (
                      <p>
                        {
                          isUploadingCompleted ?
                            'Completed' :
                            `${uploadingProgress} done`
                        }
                      </p>
                    ) : (
                      <ActionButton
                        data="Remove"
                        withStopPropagation
                        onClick={cleareFile}
                      />
                    )
                  }

                </div>

              </div>
            )) : (
              <div className="dropzone__placeholder">

                <p className="dropzone__placeholder-title">
                  Drag file here or
                </p>

                <p className="dropzone__placeholder-title dropzone__placeholder-title--with-border">
                  Browse
                </p>
              </div>
            )
        }

      </div>

      {error && <p className="dropzone__error">{error}</p>}

    </div>
  );
};

Dropzone.propTypes = {
  isUploadingInProgress: PropTypes.bool.isRequired,
  isUploadingCompleted: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool,
  accept: PropTypes.string,
  uploadingProgress: PropTypes.number,
  maxSize: PropTypes.number,
};

Dropzone.defaultProps = {
  isMultiple: false,
  isUploadingCompleted: false,
  accept: '',
  uploadingProgress: 0,
  maxSize: 90,
};

export default Dropzone;
