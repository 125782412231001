import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import {
  ENTITY_NAME,
} from './constants';

export const taskOrdersActionsTypes = createTypes([
  ...async('GET_TASK_ORDERS_LIST'),
  ...async('GET_TASK_ORDERS_DETAILS'),
  ...async('CHANGE_FILTER'),
  ...async('CREATE_TASK_ORDER'),
  ...async('UPDATE_TASK_ORDER'),
  ...async('GET_COMMITTED_HOURS_BY_CLIENT_ID'),
  'SET_ORDER_RULES',
  'TOGGLE_FAVORITES',
  'UPDATE_FAVORITES',
  'TOGGLE_SEPARATING_BY_FAVORITE',
  'RESET_FILTERS',
  'SET_ASSIGNED_ALL',
  'SET_ASSIGNED_ALL_ACTIVE',
  'RESET_AVAILABLE_COMMITTED_HOURS',
  ...async('DELETE_DOCUMENT'),
  ...async('GENERATE_DOCUMENT'),
  ...async('GET_UPLOADED_FILE'),
  ...async('SIGN_DOCUMENT'),
], ENTITY_NAME);

export const taskOrdersActions = {
  getCommittedHoursByClientId: createAction(taskOrdersActionsTypes.GET_COMMITTED_HOURS_BY_CLIENT_ID),

  getCommittedHoursByClientIdSuccess: createAction(taskOrdersActionsTypes.GET_COMMITTED_HOURS_BY_CLIENT_ID_SUCCESS, ({
    availableCommittedHours,
  }) => ({ availableCommittedHours })),

  getCommittedHoursByClientIdFail: createAction(taskOrdersActionsTypes.GET_COMMITTED_HOURS_BY_CLIENT_ID_FAIL, ({
    error,
  }) => ({ error })),

  resetCommittedHoursByClientId: createAction(taskOrdersActionsTypes.RESET_AVAILABLE_COMMITTED_HOURS),

  getTaskOrdersList: createAction(taskOrdersActionsTypes.GET_TASK_ORDERS_LIST),

  getTaskOrdersListSuccess: createAction(
    taskOrdersActionsTypes.GET_TASK_ORDERS_LIST_SUCCESS,
    ({
      taskOrdersList,
    }) => ({
      taskOrdersList,
    })
  ),

  getTaskOrdersListFail: createAction(
    taskOrdersActionsTypes.GET_TASK_ORDERS_LIST_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  getTaskOrdersDetails: createAction(
    taskOrdersActionsTypes.GET_TASK_ORDERS_DETAILS,
    ({
      projectId,
      devCenters,
    }) => ({
      projectId,
      devCenters,
    }),
    ({
      withReset = false,
    }) => ({
      withReset,
    })
  ),

  getTaskOrdersDetailsSuccess: createAction(
    taskOrdersActionsTypes.GET_TASK_ORDERS_DETAILS_SUCCESS,
    ({
      taskOrdersDetails,
    }) => ({
      taskOrdersDetails,
    })
  ),

  getTaskOrdersDetailsFail: createAction(
    taskOrdersActionsTypes.GET_TASK_ORDERS_DETAILS_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  createTaskOrder: createAction(
    taskOrdersActionsTypes.CREATE_TASK_ORDER,
    ({
      fields,
      clientId,
      taskOrderToCopyId,
    }) => ({
      fields,
      clientId,
      taskOrderToCopyId,
    })
  ),

  createTaskOrderSuccess: createAction(
    taskOrdersActionsTypes.CREATE_TASK_ORDER_SUCCESS,
    ({
      taskOrdersDetails,
    }) => ({
      taskOrdersDetails,
    }),
  ),

  createTaskOrderFail: createAction(
    taskOrdersActionsTypes.CREATE_TASK_ORDER_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  updateTaskOrder: createAction(
    taskOrdersActionsTypes.UPDATE_TASK_ORDER,
    ({
      projectId,
      fields,
      initialValues,
    }) => ({
      projectId,
      fields,
      initialValues,
    })
  ),

  updateTaskOrderSuccess: createAction(
    taskOrdersActionsTypes.UPDATE_TASK_ORDER_SUCCESS,
    ({
      projectId,
      fields,
    }) => ({
      projectId,
      fields,
    })
  ),

  updateTaskOrderFail: createAction(
    taskOrdersActionsTypes.UPDATE_TASK_ORDER_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  setOrderRules: createAction(
    taskOrdersActionsTypes.SET_ORDER_RULES,
    ({
      tableName,
      orderRules,
    }) => ({
      tableName,
      orderRules,
    })
  ),

  toggleFavorite: createAction(
    taskOrdersActionsTypes.TOGGLE_FAVORITES,
    ({
      id,
    }) => ({
      id,
    }),
    () => ({
      entityName: ENTITY_NAME,
    }),
  ),

  updateFavorites: createAction(
    taskOrdersActionsTypes.UPDATE_FAVORITES,
    ({ updatedFavorites }) => updatedFavorites
  ),

  changeFilter: createAction(
    taskOrdersActionsTypes.CHANGE_FILTER,
    ({
      storeKey,
      selected = null,
      type,
    }) => ({
      storeKey,
      selected,
      type,
    }),
    () => ({
      entityName: ENTITY_NAME,
    })
  ),

  changeFilterSuccess: createAction(
    taskOrdersActionsTypes.CHANGE_FILTER_SUCCESS,
    ({
      storeKey,
      config,
    }) => ({
      storeKey,
      config,
    })
  ),

  resetFilters: createAction(taskOrdersActionsTypes.RESET_FILTERS),

  deleteDocument: createAction(
    taskOrdersActionsTypes.DELETE_DOCUMENT,
    ({
      fileId,
    }) => ({
      fileId,
    }),
    () => ({
      entityName: 'TASK_ORDERS',
    })
  ),

  deleteDocumentSuccess: createAction(
    taskOrdersActionsTypes.DELETE_DOCUMENT_SUCCESS,
    ({
      fileId,
    }) => ({
      fileId,
    })
  ),

  deleteDocumentFail: createAction(
    taskOrdersActionsTypes.DELETE_DOCUMENT_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  generateDocument: createAction(
    taskOrdersActionsTypes.GENERATE_DOCUMENT,
    ({
      clientId,
      projectId,
      msaNumber,
      title,
      effectiveDate,
    }) => ({
      clientId,
      projectId,
      msaNumber,
      title,
      effectiveDate,
    }),
    () => ({
      entityName: 'TASK_ORDERS',
    })
  ),

  generateDocumentSuccess: createAction(taskOrdersActionsTypes.GENERATE_DOCUMENT_SUCCESS),

  generateDocumentFail: createAction(
    taskOrdersActionsTypes.GENERATE_DOCUMENT_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  getUploadedFile: createAction(
    taskOrdersActionsTypes.GET_UPLOADED_FILE,
    ({
      fileId,
    }) => ({
      fileId,
    }),
    ({
      entityName,
    }) => ({
      entityName,
    }),
  ),

  getUploadedFileSuccess: createAction(
    taskOrdersActionsTypes.GET_UPLOADED_FILE_SUCCESS,
    ({
      file,
    }) => ({ file })
  ),

  getUploadedFileFail: createAction(
    taskOrdersActionsTypes.GET_UPLOADED_FILE_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  signDocument: createAction(
    taskOrdersActionsTypes.SIGN_DOCUMENT,
    ({
      fileId,
      pocEmail,
      pocName,
      message,
      isTaskOrder,
    }) => ({
      fileId,
      pocEmail,
      pocName,
      message,
      isTaskOrder,
    }),
    () => ({
      entityName: 'TASK_ORDERS',
    })
  ),

  signDocumentSuccess: createAction(
    taskOrdersActionsTypes.SIGN_DOCUMENT_SUCCESS,
    ({
      fileId,
    }) => ({
      fileId,
    })
  ),

  signDocumentFail: createAction(
    taskOrdersActionsTypes.SIGN_DOCUMENT_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),
};
