import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import classnames from 'classnames';

import { get } from 'lodash';

import styles from './styles.module.scss';

const Error = ({ error }) => (
  <div data-id="Input Error" className={styles.error}>
    {get(error, 'message', '')}
  </div>
);

Error.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};

export const InputField = forwardRef(
  ({
    className,
    type,
    error,
    isLocked,
    title,
    withDialog,
    triggerDialog,
    ...props
  }, ref) => (
    <div
      data-id="Input Field"
      data-disabled={isLocked}
      className={className}
      title={title}
    >
      {withDialog && triggerDialog}
      <input
        aria-required={!!error}
        type={type}
        className={classnames(
          styles.inputField,
          {
            [styles.isLocked]: isLocked,
          },
        )}
        ref={ref}
        {...props}
      />
      {error && <Error error={error} />}
    </div>
  ),
);

InputField.displayName = 'InputField';

InputField.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  isLocked: PropTypes.bool,
  title: PropTypes.string,
  withDialog: PropTypes.bool,
  triggerDialog: PropTypes.node,
};

InputField.defaultProps = {
  className: '',
  type: 'text',
  title: '',
  error: null,
  isLocked: false,
  withDialog: false,
  triggerDialog: null,
};
