import { COLUMN_TYPES } from 'core/constants';

export default () => [
  {
    columnName: 'Client Category Key',
    headerProps: {
      cssRules: 'min-height: 2.8rem;',
    },
    valueGetter: ({ key }) => ({
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data: key,
        cssRules: 'font-size: 1.2rem;',
        isMultiline: true,
      },
    }),
  },
  {
    columnName: 'Client Category Name',
    valueGetter: ({ name }) => ({
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data: name,
        cssRules: 'font-size: 1.2rem;',
        isMultiline: true,
      },
    }),
  },
];
