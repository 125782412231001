import React, { useState, useEffect, useCallback } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import UploadedFile from '../../components/file-upload/file';
import Calendar from 'components/calendar';
import DetailsForm from 'components/details-form';
import Controls from 'components/list-controls';
import Loader from 'components/loader';
import UploadDocumentForm from 'components/upload-document-form';
import { configurationsActions } from 'core/configurations/actions';
import {
  selectEmailTemplateDetails,
  selectEmailTemplates,
  selectEmailTemplatesActiveIndex,
  selectCreateMode,
} from 'core/configurations/selectors';
import {
  selectUploadingProgress,
  selectIsFileUploaded,
} from 'core/files/selectors';
import ActionButton from 'elements/action-button';
import StyledWrapper from 'elements/styled-wrapper';
import Wrapper from 'elements/wrapper';
import WrapperNg from 'elements/wrapper-ng';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { format as formatDate, FORMATS } from 'utils/date';

import {
  yearPickerCssRules,
  calendarItemCssRules,
  controlsCssRules,
  controlsWrapperCssRules,
  emailTemplatesWrapperStyles,
  formWrapperStyles,
  addButtonStyles,
} from './styles';

const calendarStepControlsConfig = {
  step: 1,
  unitName: 'year',
};
const calendarPopperProps = {
  placement: 'bottom-start',
};

const EmailTemplatemanagement = ({
  isFetching,
  createMode,
  setCreateMode,
  changeCurrentModal,
  isFileUploaded,
  uploadingProgress,
  getEmailTemplates,
  emailTemplateDetails,
  emailTemplates,
  setEmailTemplatesActiveIndex,
  activeEmailTemplateIndex,
  createEmailTemplateRecord,
  deleteEmailTemplateRecord,
  updateEmailTemplateRecord,
  uploadFile,
}) => {
  const {
    initialValues: {
      emailTemplateId,
      file: emailTemplateFile,
    },
  } = emailTemplateDetails;

  const [date, setDate] = useState(moment());
  const [file, setFile] = useState(emailTemplateFile);

  useEffect(() => {
    setFile(emailTemplateFile);
  }, [emailTemplateId, emailTemplateFile]);

  useEffect(() => {
    getEmailTemplates({ date: moment().year() });
  }, []);

  const changeYear = (calendarValue) => {
    setDate(calendarValue);
    getEmailTemplates({ date: calendarValue.year() });
    setCreateMode({ createMode: false });
  };

  const onSubmit = ({ fields }) => {
    const emailTemplate = fields;
    const year = fields.year;
    return createMode ?
      createEmailTemplateRecord({ year, emailTemplate }) :
      updateEmailTemplateRecord({ year, emailTemplate });
  };

  const handleCreateTemplate = useCallback(() => {
    setCreateMode({ createMode: !createMode });
    setFile(null);
  }, [createMode]);

  const emailTemplatesList = (emailsList, activeIndex) => {
    const emails = emailsList.map((email, index) => {
      const isActive = index === activeIndex ? 'actve' : '';
      const handleClick = () => {
        if (createMode) {
          setCreateMode({ createMode: false });
        }
        setEmailTemplatesActiveIndex({ activeIndex: index });
      };
      return (
        <li
          key={email.emailTemplateId}
          className={`email-templates__list-item ${isActive}`}
          onClick={handleClick}
        >
          {email.name}
        </li>
      );
    });

    return (
      <>
        <ActionButton
          data="Create new"
          onClick={handleCreateTemplate}
          withBody
          isActive={createMode}
          cssRules={`
            position: absolute;
            top:10px;
            right:0;
            ${addButtonStyles}
          `}
          className={createMode ? 'active' : ''}
        />
        <ul className="email-templates__list">{emails}</ul>
      </>
    );
  };

  const additionalActions = {
    deleteEmailTemplateRecord,
    openModal: ({ currentModal, ...args }) => changeCurrentModal({ currentModal, params: { ...args } }),
  };

  const onSubmitUploadedFile = ({ file }) => {
    uploadFile({
      file,
      title: file.name,
      emailTemplateId,
    });
  };

  const fileLoaderConfig = {
    title: 'Attachment Upload',
    fields: [],
    initialValues: {},
  };

  return (
    <>
      <Controls title="Email Template Management" cssRules={controlsCssRules} />
      <StyledWrapper cssRules={controlsWrapperCssRules}>
        <WrapperNg>
          <Calendar
            withStepControls
            withYearSelecting
            minDetails="decade"
            onChange={changeYear}
            currentDate={moment()}
            value={date}
            popperProps={calendarPopperProps}
            cssRules={yearPickerCssRules}
            stepControlsConfig={calendarStepControlsConfig}
          >
            <StyledWrapper cssRules={calendarItemCssRules}>
              {formatDate(date, FORMATS.YYYY)}
            </StyledWrapper>
          </Calendar>
        </WrapperNg>
      </StyledWrapper>

      <Loader isLoaded={!isFetching}>
        <Wrapper cssRules={emailTemplatesWrapperStyles}>
          <div className="email-templates">
            <div className="details-form__title">Email Templates</div>
            {emailTemplatesList(emailTemplates, activeEmailTemplateIndex)}
          </div>

          <DetailsForm
            stylesTemplate={formWrapperStyles()}
            isFormSubmitted
            isFetchingDetails={false}
            content={emailTemplateDetails}
            onSubmit={onSubmit}
            additionalActions={additionalActions}
            withoutSpinner
          >
            <div className="emailTemplate-file-block">
              {emailTemplateFile != null && file == null && (
                <ActionButton
                  className="attachment__right-link"
                  data="Cancel"
                  onClick={() => setFile(emailTemplateFile)}
                />
              )}
              {file != null && (
                <ActionButton
                  className="attachment__right-link"
                  data="Upload new file"
                  onClick={() => setFile(null)}
                />
              )}
              {!createMode && file == null && (
                <UploadDocumentForm
                  className="uploader"
                  isFetchingFiles={isFetching}
                  onSubmit={onSubmitUploadedFile}
                  uploadingProgress={uploadingProgress}
                  isFileUploaded={isFileUploaded}
                  fileLoaderConfig={fileLoaderConfig}
                />
              )}
              {!createMode && file != null && (
                <div className="attachment_data">
                  <div className="attachment_data-title">Attachment</div>
                  <div className="attachment_links">
                    <UploadedFile file={file.downloadUrl} title={file.title} withBlank />
                  </div>
                </div>
              )}
            </div>
          </DetailsForm>
        </Wrapper>
      </Loader>
    </>
  );
};

EmailTemplatemanagement.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  createMode: PropTypes.bool.isRequired,
  setCreateMode: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  isFileUploaded: PropTypes.bool.isRequired,
  uploadingProgress: PropTypes.number.isRequired,
  getEmailTemplates: PropTypes.func.isRequired,
  emailTemplateDetails: PropTypes.object,
  emailTemplates: PropTypes.array,
  setEmailTemplatesActiveIndex: PropTypes.func.isRequired,
  activeEmailTemplateIndex: PropTypes.number.isRequired,
  createEmailTemplateRecord: PropTypes.func.isRequired,
  deleteEmailTemplateRecord: PropTypes.func.isRequired,
  updateEmailTemplateRecord: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
};

EmailTemplatemanagement.defaultProps = {
  emailTemplateDetails: {},
  emailTemplates: [],
};

const mapStateToProps = createStructuredSelector({
  emailTemplateDetails: selectEmailTemplateDetails,
  emailTemplates: selectEmailTemplates,
  activeEmailTemplateIndex: selectEmailTemplatesActiveIndex,
  createMode: selectCreateMode,
  uploadingProgress: selectUploadingProgress,
  isFileUploaded: selectIsFileUploaded,
});

const mapDispatchToProps = {
  getEmailTemplates: configurationsActions.getEmailTemplates,
  setEmailTemplatesActiveIndex: configurationsActions.setEmailTemplatesActiveIndex,
  setCreateMode: configurationsActions.setCreateMode,
  createEmailTemplateRecord: configurationsActions.createEmailTemplateRecord,
  deleteEmailTemplateRecord: configurationsActions.deleteEmailTemplateRecord,
  updateEmailTemplateRecord: configurationsActions.updateEmailTemplateRecord,
  uploadFile: configurationsActions.uploadEmailTemplateFile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTemplatemanagement);
