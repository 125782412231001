import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import ReactLoader from 'react-loader';
import styled, { css } from 'styled-components';

import { config } from './config';

import './styles.scss';

const StyledLoaderWrapper = styled.div`
  height: 100%;
  ${({ cssRules }) => css`${cssRules}`}
`;

const Loader = ({ isLoaded, children, withOverlay, cssRules, size }) => (
  <div
    className={
      classNames(
        'spinner-container',
        { 'spinner-container--withOverlay': withOverlay },
      )
    }
  >
    <ReactLoader
      loaded={isLoaded}
      options={config[size]}
    >

      <StyledLoaderWrapper
        cssRules={cssRules}
      >
        {children}
      </StyledLoaderWrapper>

    </ReactLoader>

  </div>
);

Loader.propTypes = {
  cssRules: PropTypes.string,
  isLoaded: PropTypes.bool,
  withOverlay: PropTypes.bool,
  children: PropTypes.any,
  size: PropTypes.string,
};

Loader.defaultProps = {
  cssRules: '',
  isLoaded: false,
  withOverlay: false,
  children: null,
  size: 'md',
};

export default Loader;
