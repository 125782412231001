import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import units from 'components/shared-units';
import Table from 'components/table';
import { get } from 'lodash';

const SingleTable = ({
  title,
  controls,
  tableName,
  orderRules,
  entityName,
  isFetching,
  changeOrder,
  unitActions,
  hasActiveFilters,
  preventScrolling,
  ...restProps
}) => {
  const handleChangeOrder = useCallback(({ orderBy }) => changeOrder({ entityName, orderBy, tableName }), [changeOrder, entityName, tableName]);

  return (
    <>
      {
        !!(title || (controls && controls.length)) && (
          <div className="details-table__title-wrapper">

            <p className="details-table__title">
              {title}
            </p>

            {
              controls.map(({ type, actionName, actionArguments = {}, ...rest }, index) => {
                const onClick = () => unitActions[actionName]({ ...actionArguments });
                return (
                  React.createElement(
                    units[type || 'link'],
                    {
                      key: index, // eslint-disable-line react/no-array-index-key
                      onClick,
                      ...rest,
                    },
                  )
                );
              })
            }
          </div>
        )
      }

      <Table
        withoutAnimation
        isLoaded={!isFetching}
        withFilter={hasActiveFilters}
        unitActions={unitActions}
        orderRules={get(orderRules, tableName, {})}
        changeOrder={handleChangeOrder}
        preventScrolling={preventScrolling}
        {...restProps}
      />
    </>
  );
};

SingleTable.propTypes = {
  unitActions: PropTypes.shape({}),
  isFiltering: PropTypes.bool,
  hasActiveFilters: PropTypes.bool,
  isFetching: PropTypes.bool,
  fileType: PropTypes.string,
  controls: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  entityName: PropTypes.string.isRequired,
  pocMsaName: PropTypes.string,
  pocMsaEmail: PropTypes.string,
  changeCurrentModal: PropTypes.func,
  orderRules: PropTypes.shape({}).isRequired,
  changeOrder: PropTypes.func,
  withoutLoader: PropTypes.bool,
  tableName: PropTypes.string,
  title: PropTypes.string,
  preventScrolling: PropTypes.bool,
};

SingleTable.defaultProps = {
  unitActions: {},
  isFiltering: false,
  hasActiveFilters: false,
  preventScrolling: false,
  isFetching: false,
  title: '',
  tableName: '',
  fileType: '',
  changeCurrentModal: () => null,
  changeOrder: () => null,
  pocMsaName: '',
  pocMsaEmail: '',
  link: {},
  withoutLoader: false,
};

export default SingleTable;
