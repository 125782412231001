import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
} from 'utils/helpers/actions';

import {
  ENTITY_NAME,
} from './constants';

export const commonActionsTypes = createTypes([
  'CHANGE_ORDER',
  'SET_ENTITY_NAME',
], ENTITY_NAME);

export const commonActions = {
  changeOrder: createAction(
    commonActionsTypes.CHANGE_ORDER,
    ({
      orderBy,
      tableName,
    }) => ({
      orderBy,
      tableName,
    }),
    ({
      entityName,
    }) => ({
      entityName,
    }),
  ),

  setEntityName: createAction(
    commonActionsTypes.SET_ENTITY_NAME,
    (entityName) => ({
      entityName,
    })
  ),
};
