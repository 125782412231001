import styles from './styles.module.scss';

export const generateWrapperClassesByTabName = (tabName) => {
  switch (tabName) {
    case 'Details':
      return {
        [styles.createEmployeeWrapper]: true,
        [styles.formControlsStyles]: true,
        [styles.formLayoutStyles]: true,
      };
    case 'Work Book':
      return {
        [styles.formControlsStyles]: true,
        [styles.workbookFormStyles]: true,
      };
    default:
      return '';
  }
};
