import React from 'react';

import PropTypes from 'prop-types';

import className from 'classnames';

import StartsRating from 'elements/stars-rating';
import { withRouter } from 'react-router';

const ReviewCard = ({
  isPl,
  role,
  rating,
  review,
  onClick,
  reviewby,
  buttonTitle,
  devstaffName,
  // hasRoleChanged,
  jiraProjectName,
}) => (
  <div className="update-reviews-modal__review-card">
    <StartsRating
      isLocked={!rating}
      rating={rating}
    />
    <div className="update-reviews-modal__review-card-content">
      <p className="update-reviews-modal__review-card-title">{jiraProjectName}</p>
      <p className="update-reviews-modal__review-card-about">
        <span
          className={className(
            'update-reviews-modal__devstaff-data',
            { 'update-reviews-modal__devstaff-data--pl': isPl }
          )}
        >
          {`${devstaffName}${isPl ? ' /  PL' : ''}`}
        </span>
        {`${role ? ` / ${role}` : ''}${reviewby ? ` / Review by ${reviewby}` : ''} `}
      </p>
      <p title={review} className="update-reviews-modal__review-card-text">{review}</p>
    </div>
    <div>
      <button
        type="button"
        className="update-reviews-modal__review-card-button button button--cancel"
        onClick={onClick}
      >
        {buttonTitle}
      </button>
    </div>
  </div>
);

ReviewCard.propTypes = {
  isPl: PropTypes.bool,
  role: PropTypes.string,
  rating: PropTypes.number,
  review: PropTypes.string,
  reviewby: PropTypes.string,
  devstaffName: PropTypes.string,
  jiraProjectName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
};

ReviewCard.defaultProps = {
  isPl: false,
  role: '',
  rating: 0,
  review: '',
  reviewby: '',
  devstaffName: '',
  jiraProjectName: '',
};

export default withRouter(ReviewCard);
