import {
  colorPrimary,
  colorSecondaryGray,
  colorSecondaryText,
  colorSecondaryGrayLight,
  colorSecondaryGrayLight030,
} from 'assets/styles/variables';
import { DEFAULT_ORDER } from 'core/billing-reports/constants';
import {
  calculateTotal,
  summaryTableDataGetter,
} from 'core/billing-reports/utils';
import { UNITS_TYPES } from 'core/constants';
import hash from 'hash-sum';
import { toNumber } from 'lodash';
import moment from 'moment/moment';
import { legendTitles } from 'utils/constants';
import { addThousandSeparator } from 'utils/helpers/numbers';

import summaryODCTableConfigGetter from './config/summary-odc-table-config';
import summaryOvertimeTableConfigGetter from './config/summary-overtime-table-config';
import summaryPTMTableConfigGetter from './config/summary-ptm-table-config';
import summaryTTMTableConfigGetter from './config/summary-ttm-table-config';

import {
  groupBy,
  getPromoTableStyles,
  getFiltersStyles,
  getPTMTableStyles,
  getTTMTableStyles,
  getODCTableStyles,
  getRequiredTotals,
  getOvertimeTableStyles,
} from './utils';

export default ({
  isCTO,
  isHRM,
  isChefs,
  isAudit,
  isGMReport,
  isDTOReport,
  isNoteForTab,
  selectedDate,
  reportCalculationDate,
  hasPermissionsTOClientModule,
  hasPermissionsTODeliveryModule,
  hasPermissionsTOTaskOrderModule,
  availableDevCentersForDd,
}) => {
  const groupId = 'summaryGroups';
  const hasAccessToRevenue = !isHRM;
  const promoTableStyles = getPromoTableStyles({ isHRM });
  const ptmTableStyles = getPTMTableStyles({ isHRM, isGMReport, isDTOReport });
  const ttmTableStyles = getTTMTableStyles({ isHRM, isGMReport, isDTOReport });
  const odcTableStyles = getODCTableStyles({ isHRM, isGMReport, isDTOReport });
  const overtimeTableStyles = getOvertimeTableStyles({
    isHRM,
    isGMReport,
    isDTOReport,
  });
  const filterStyles = getFiltersStyles({ isHRM, isGMReport, isNoteForTab });

  return [
    {
      type: UNITS_TYPES.FILTER,
      currentFilter: 'summaryBillableActivities',
      cssRules: filterStyles,
    },
    {
      type: UNITS_TYPES.TABLE,
      currentFilter: 'summaryBillableActivities',
      tableCssRules: `
        && .table {
          padding-bottom: 0;
        }
      `,
      summaryTableDataGetter: (data) => summaryTableDataGetter({
        project: 'PTM/FP',
        requiredTotals: getRequiredTotals({
          isGMReport,
          hasAccessToRevenue,
        }),
        ...data,
      }),
      groupBy:
        !isGMReport &&
        ((data) => groupBy({
          defaultOrder: DEFAULT_ORDER.PTM_TABLE,
          requiredTotals: getRequiredTotals({
            isGMReport,
            hasAccessToRevenue,
          }),
          ...data,
        })),
      useAccumulator: (data) => calculateTotal({
        groupId,
        isGMReport,
        label: 'PTM/FP',
        isDefaultExpanded: true,
        summaryWrapperCss: ptmTableStyles.summaryWrapperCss,
        requiredTotals: getRequiredTotals({
          isGMReport,
          hasAccessToRevenue,
        }),
        ...data,
      }),
      content: [
        {
          idKey: 'projectId',
          dataKey: 'ptmFpReportRows',
          tableName: 'ptm',
          preventScrolling: true,
          rowStatusGetter: (row) => {
            const {
              cop,
              revenue,
              isSubTotal,
              isGrandTotal,
              isDevstaffNotInTo,
              isDevstaffWithoutRates,
            } = row;
            const id = hash(row);
            const stylesTemplate =
              ptmTableStyles.tableTotalsStylesTemplateGetter({
                isSubTotal,
                isDTOReport,
                isGrandTotal,
              });

            return {
              id,
              hasWarning:
                !(isSubTotal || isGrandTotal) &&
                (isDevstaffNotInTo ||
                  (!isHRM &&
                    ((isGMReport && (!toNumber(revenue) || !toNumber(cop))) ||
                      isDevstaffWithoutRates))),
              stylesTemplate,
            };
          },
          rules: {
            css: {
              gridTemplateConfig: ptmTableStyles.commonGridTemplateConfig,
            },
          },
          dataTemplate: summaryPTMTableConfigGetter({
            isCTO,
            isHRM,
            isChefs,
            isAudit,
            isGMReport,
            isDTOReport,
            selectedDate,
            hasPermissionsTOClientModule,
            hasPermissionsTODeliveryModule,
            hasPermissionsTOTaskOrderModule,
            availableDevCentersForDd,
          }),
        },
      ],
    },
    isDTOReport && {
      type: UNITS_TYPES.TABLE,
      currentFilter: 'summaryBillableActivities',
      tableCssRules: `&& .table {
        padding-bottom: 0;
      }`,
      summaryTableDataGetter: (data) => summaryTableDataGetter({
        project: 'PROMO',
        withGrandTotal: !isGMReport,
        requiredTotals: getRequiredTotals({
          isGMReport,
          hasAccessToRevenue,
        }),
        ...data,
      }),
      groupBy:
        !isGMReport &&
        ((data) => groupBy({
          defaultOrder: DEFAULT_ORDER.PROMO_TABLE,
          requiredTotals: getRequiredTotals({
            isGMReport,
            hasAccessToRevenue,
          }),
          ...data,
        })),
      useAccumulator: (data) => calculateTotal({
        groupId,
        isGMReport,
        label: 'PROMO Work',
        isDefaultExpanded: true,
        summaryWrapperCss: promoTableStyles.summaryWrapperCss,
        requiredTotals: getRequiredTotals({
          isGMReport,
          hasAccessToRevenue,
        }),
        ...data,
      }),
      content: [
        {
          idKey: 'resource',
          dataKey: 'promoReportRows',
          tableName: 'promo',
          preventScrolling: true,
          rowStatusGetter: (row) => {
            const id = hash(row);
            const {
              isDevstaffNotInTo,
              isGrandTotal,
              isSubTotal,
              isDevstaffWithoutRates,
            } = row;
            const stylesTemplate =
              promoTableStyles.tableTotalsStylesTemplateGetter({
                isSubTotal,
                isGrandTotal,
                hasPermissionsTOClientModule,
                hasPermissionsTODeliveryModule,
                hasPermissionsTOTaskOrderModule,
              });

            return {
              id,
              hasWarning:
                (!(isSubTotal || isGrandTotal) && isDevstaffNotInTo) ||
                (isDevstaffWithoutRates && !isHRM),
              stylesTemplate,
            };
          },
          rules: {
            css: {
              gridTemplateConfig: promoTableStyles.commonGridTemplateConfig,
            },
          },
          dataTemplate: summaryPTMTableConfigGetter({
            isCTO,
            isHRM,
            isChefs,
            isAudit,
            selectedDate,
            isGMReport,
            isDTOReport,
            isPromo: true,
            hasPermissionsTOClientModule,
            hasPermissionsTODeliveryModule,
            hasPermissionsTOTaskOrderModule,
          }),
        },
      ],
    },
    {
      type: UNITS_TYPES.TABLE,
      currentFilter: 'summaryBillableActivities',
      tableCssRules: `&& .table {
        padding-bottom: 0;
      }`,
      summaryTableDataGetter: (data) => summaryTableDataGetter({
        project: 'TTM',
        withGrandTotal: !isHRM,
        requiredTotals: getRequiredTotals({
          isGMReport,
          withHrs: false,
          hasAccessToRevenue,
        }),
        ...data,
      }),
      groupBy:
        !(isGMReport || isHRM) &&
        ((data) => groupBy({
          defaultOrder: DEFAULT_ORDER.TTM_TABLE,
          requiredTotals: getRequiredTotals({
            isGMReport,
            withHrs: false,
            hasAccessToRevenue,
          }),
          ...data,
        })),
      useAccumulator: (data) => calculateTotal({
        groupId,
        isGMReport,
        label: 'TTM',
        withHours: false,
        isDefaultExpanded: true,
        summaryWrapperCss: ttmTableStyles.summaryWrapperCss,
        requiredTotals: getRequiredTotals({
          isGMReport,
          withHrs: false,
          hasAccessToRevenue,
        }),
        ...data,
      }),
      content: [
        {
          idKey: 'resource',
          dataKey: 'ttmReportRows',
          tableName: 'ttm',
          preventScrolling: true,
          rowStatusGetter: (row) => {
            const id = hash(row);
            const {
              isDevstaffNotInTo,
              isGrandTotal,
              isSubTotal,
              revenue,
              cop,
              rate,
              isDevstaffWithoutRates,
            } = row;
            const stylesTemplate =
              ttmTableStyles.tableTotalsStylesTemplateGetter({
                isSubTotal,
                isGrandTotal,
              });

            const hasWarning = !(isSubTotal || isGrandTotal || isHRM) &&
              (isDevstaffNotInTo ||
                (isGMReport && (!toNumber(revenue) || !toNumber(cop))) ||
                !toNumber(rate) ||
                isDevstaffWithoutRates);

            return {
              id,
              isGrandTotal,
              isSubTotal,
              hasWarning,
              stylesTemplate,
            };
          },
          rules: {
            css: {
              gridTemplateConfig: ttmTableStyles.commonGridTemplateConfig,
            },
          },
          dataTemplate: summaryTTMTableConfigGetter({
            isCTO,
            isHRM,
            isChefs,
            isAudit,
            isGMReport,
            isDTOReport,
            selectedDate,
            hasPermissionsTOClientModule,
            hasPermissionsTODeliveryModule,
            hasPermissionsTOTaskOrderModule,
            availableDevCentersForDd,
          }),
        },
      ],
    },
    {
      type: UNITS_TYPES.TABLE,
      currentFilter: 'summaryBillableActivities',
      tableCssRules: `&& .table {
        padding-bottom: 0;
      }`,
      summaryTableDataGetter: (data) => summaryTableDataGetter({
        project: 'ODC',
        withGrandTotal: !isHRM,
        requiredTotals: getRequiredTotals({
          isGMReport,
          withHrs: false,
          hasAccessToRevenue,
        }),
        ...data,
      }),
      groupBy:
        !(isGMReport || isHRM) &&
        ((data) => groupBy({
          defaultOrder: DEFAULT_ORDER.ODC_TABLE,
          requiredTotals: getRequiredTotals({
            isGMReport,
            withHrs: false,
            hasAccessToRevenue,
          }),
          ...data,
        })),
      useAccumulator: (data) => calculateTotal({
        groupId,
        isGMReport,
        label: 'ODC',
        isDefaultExpanded: true,
        summaryWrapperCss: odcTableStyles.summaryWrapperCss,
        requiredTotals: getRequiredTotals({
          isGMReport,
          withHrs: false,
          hasAccessToRevenue,
        }),
        ...data,
      }),
      content: [
        {
          idKey: 'resource',
          dataKey: 'odcReportRows',
          tableName: 'odc',
          preventScrolling: true,
          isRatePlatinum: true,
          rowStatusGetter: (row) => {
            const id = hash(row);
            const {
              isDevstaffNotInTo,
              isGrandTotal,
              isSubTotal,
              revenue,
              cop,
              total,
            } = row;
            const stylesTemplate =
              odcTableStyles.tableTotalsStylesTemplateGetter({
                isSubTotal,
                isGrandTotal,
              });

            return {
              id,
              hasWarning:
                !(isSubTotal || isGrandTotal || isHRM) &&
                (isDevstaffNotInTo ||
                  (isGMReport && (!toNumber(revenue) || !toNumber(cop))) ||
                  (isDTOReport && !toNumber(total)) ||
                  !toNumber(total)),
              isGrandTotal,
              isSubTotal,
              stylesTemplate,
            };
          },
          rules: {
            css: {
              gridTemplateConfig: odcTableStyles.commonGridTemplateConfig,
            },
          },
          dataTemplate: summaryODCTableConfigGetter({
            isCTO,
            isHRM,
            isChefs,
            isAudit,
            isGMReport,
            isDTOReport,
            selectedDate,
            hasPermissionsTOClientModule,
            hasPermissionsTODeliveryModule,
            hasPermissionsTOTaskOrderModule,
            availableDevCentersForDd,
          }),
        },
      ],
    },
    {
      type: UNITS_TYPES.TABLE,
      currentFilter: 'summaryBillableActivities',
      tableCssRules: `&& .table {
        padding-bottom: 0;
      }`,
      summaryTableDataGetter: (data) => summaryTableDataGetter({
        project: 'Overtime',
        requiredTotals: getRequiredTotals({
          isGMReport,
          hasAccessToRevenue,
        }),
        ...data,
      }),
      groupBy:
        !isGMReport &&
        ((data) => groupBy({
          defaultOrder: DEFAULT_ORDER.OVERTIME_TABLE,
          requiredTotals: getRequiredTotals({
            isGMReport,
            hasAccessToRevenue,
          }),
          ...data,
        })),
      useAccumulator: (data) => calculateTotal({
        groupId,
        isGMReport,
        label: 'Overtime',
        isDefaultExpanded: true,
        summaryWrapperCss: overtimeTableStyles.summaryWrapperCss,
        requiredTotals: getRequiredTotals({
          isGMReport,
          hasAccessToRevenue,
        }),
        ...data,
      }),
      content: [
        {
          idKey: 'resource',
          dataKey: 'overtimeReportRows',
          tableName: 'overtime',
          preventScrolling: true,
          rowStatusGetter: (row) => {
            const id = hash(row);
            const {
              isDevstaffNotInTo,
              isGrandTotal,
              isSubTotal,
              revenue,
              cop,
              isDevstaffWithoutRates,
            } = row;
            const stylesTemplate =
              overtimeTableStyles.tableTotalsStylesTemplateGetter({
                isSubTotal,
                isGrandTotal,
              });

            return {
              id,
              hasWarning:
                !(isSubTotal || isGrandTotal || isHRM) &&
                (isDevstaffNotInTo ||
                  (isGMReport && (!toNumber(revenue) || !toNumber(cop))) ||
                  isDevstaffWithoutRates),
              isGrandTotal,
              isSubTotal,
              stylesTemplate,
            };
          },
          rules: {
            css: {
              gridTemplateConfig: overtimeTableStyles.commonGridTemplateConfig,
            },
          },
          dataTemplate: summaryOvertimeTableConfigGetter({
            isCTO,
            isHRM,
            isChefs,
            isAudit,
            isGMReport,
            isDTOReport,
            selectedDate,
            hasPermissionsTOClientModule,
            hasPermissionsTODeliveryModule,
            hasPermissionsTOTaskOrderModule,
            availableDevCentersForDd,
          }),
        },
      ],
    },
    {
      type: UNITS_TYPES.SINGLE_ROW,
      cssRules: `
        grid-template-columns: auto max-content max-content;
        grid-column-gap: 3.8rem;
        grid-auto-rows: minmax(0rem,auto);
        margin-left: 1.6rem;
        order: -1;
      `,
      useAccumulator: ({
        accumulator: { hasActiveFilters, hasResultsContent },
      }) => [
        {
          type: 'text',
          cssRules: `
          grid-column: 1 / span 3;
          min-height: 3rem;
          padding: 0;
        `,
          componentProps: {
            data: 'Billable activities',
            cssRules: `
            && {
              font-size: 2.2rem;
              line-height: 2.6rem;
              color: ${colorSecondaryText};
              margin-bottom: 0.5rem;
            }
          `,
          },
        },
        {
          type: 'text',
          componentProps: {
            data: `Note: data current as of ${moment(
              reportCalculationDate,
              'YYYY-MM-DD LT'
            ).format('L LT')}`,
            wrapperCssRules: `
            display: flex;
            align-items: center;
          `,
            cssRules: `
            display: inline-block;
            font-size: 1.3rem;
            line-height: 1.8rem;
            color: ${colorSecondaryGray};
          `,
          },
          cssRules: `
          min-height: 0;
          padding: 0;
        `,
        },
        {
          type: 'action',
          componentProps: {
            data: 'Clear filters',
            isHidden: !hasActiveFilters,
            cssRules: `
            && {
              color: ${colorPrimary};
            }
          `,
            actionName: 'resetFilters',
            actionArguments: {
              currentFilter: 'summaryBillableActivities',
            },
          },
          cssRules: `
          min-height: 0;
          padding: 0;
          grid-area: ${hasResultsContent ? '' : '2 / 3 / 2 / 3'}
        `,
        },
        hasResultsContent ?
          {
            type: 'groupController',
            componentProps: {
              groupId,
              isDefaultExpanded: true,
            },
            cssRules: `
        && .action-button {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
        min-height: 0;
        padding: 0;
        `,
          } :
          {},
      ],
    },
    !isHRM && {
      type: 'single-row',
      cssRules: `
        display: grid;
        grid-template-columns: minmax(auto,980fr) ${
  isGMReport ? 'minmax(auto,220fr)' : ''
} minmax(auto,279fr);
        padding: 0 1.6rem;
        border-top: 1px solid ${colorSecondaryGrayLight};
        background-color: ${colorSecondaryGrayLight030};
      `,
      useAccumulator: ({
        accumulator: { total, revenue, hasActiveFilters },
      }) => [
        {
          type: 'text',
          componentProps: {
            data: hasActiveFilters ? 'Grand Total with filters' : 'Grand Total',
            cssRules: `
            && {
              font-size: 1.8rem;
              line-height: 3.15rem;
              font-weight: 500;
              color: ${colorSecondaryText};
            }
          `,
          },
          name: 'Grand Total',
          legend: legendTitles.billingSummaryTOReport,
        },
        {
          type: 'text',
          shouldntBeRendered: !isGMReport,
          componentProps: {
            data: revenue ?
              `$ ${addThousandSeparator(revenue.toFixed(2))}` :
              '$ 0',
            cssRules: `
            && {
              padding-right: 0;
              font-size: 1.8rem;
              font-weight: 500;
              line-height: 2.6rem;
              color: ${colorSecondaryText};
            }
          `,
          },
          cssRules: `
          && {
            width: unset;
            margin-left: auto;
          }
        `,
        },
        {
          type: 'text',
          componentProps: {
            data: total ? `$ ${addThousandSeparator(total.toFixed(2))}` : '$ 0',
            cssRules: `
            && {
              margin-left: auto;
              padding-right: 0;
              font-size: 1.8rem;
              font-weight: 500;
              line-height: 2.6rem;
              color: ${colorSecondaryText};
            }
          `,
          },
          cssRules: `
          && {
            width: unset;
            margin-left: auto;
          }
        `,
        },
      ],
    },
  ];
};
