import React from 'react';

import PropTypes from 'prop-types';

import iconCircle from 'assets/img/icon-circle.svg';
import iconCollapse from 'assets/img/icon-collapse.svg';
import iconExpand from 'assets/img/icon-expand.svg';
import { colorSecondaryGrayLight, colorPrimary } from 'assets/styles/variables';
import classNames from 'classnames';
import units from 'components/shared-units';
import { get } from 'lodash';
import styled, { css } from 'styled-components';

import './styles.scss';

const StyledRoot = styled.div`
  display: flex;

  font-size: 1.2rem;
  ${({ cssRules }) =>
    cssRules &&
    css`
      ${cssRules}
    `}

  ${({ isExpanded }) => (isExpanded ? `color: ${colorPrimary};` : '')}
  ${({ isDisabled }) =>
    isDisabled ? `color: ${colorSecondaryGrayLight};` : ''}
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child):after {
    content: "/";
    padding: 0 1ch;
    color: ${colorSecondaryGrayLight};
  }

  ${({ cssRules }) =>
    cssRules &&
    css`
      ${cssRules}
    `}
`;

const ExpansionSummary = ({
  isExpanded,
  isDisabled,
  onChange,
  children,
  cssRules,
  actions,
  label,
  items,
  key,
}) => (
  <StyledRoot
    className={classNames('expansion-summary__root', {
      'expansion-summary__root--expanded': isExpanded,
    })}
    cssRules={cssRules}
    isExpanded={isExpanded}
    isDisabled={isDisabled}
    key={key}
  >
    <button
      className={classNames('expansion-summary__control', 'action-button', {
        'expansion-summary__control--disabled': isDisabled,
      })}
      type="button"
      disabled={isDisabled}
      onClick={onChange}
    >
      {!isDisabled && (
        <img
          className="expansion-summary__icon"
          src={isExpanded ? iconCollapse : iconExpand}
          alt={isExpanded ? 'collapse button' : 'expand button'}
        />
      )}

      {isDisabled && (
        <img
          className="expansion-summary__icon"
          src={iconCircle}
          alt="button locked"
        />
      )}

      <span className="expansion-summary__label">{label}</span>
    </button>

    {children}
    {items.map((item, index) => {
      const { type } = item;

      if (type) {
        return React.createElement(units[type], {
          actions,
          ...item,
        });
      }
      const { data, cssRules: itemCssRules } = item;
      const itemLabel = get(data, 'label');
      const itemValue = get(data, 'value');

      return (
        <StyledItem
          key={`${itemLabel}-${itemValue}${index.toString()}`}
          className="expansion-summary__item"
          cssRules={itemCssRules}
        >
          {itemLabel && `${itemLabel}:\u00A0`}

          {itemValue != null && (
            <span
              className={classNames('expansion-summary__item-value', {
                'expansion-summary__item-value--dimmed': itemValue === 0,
                'expansion-summary__item-value--empty': itemValue === '',
              })}
            >
              {itemValue}
            </span>
          )}
        </StyledItem>
      );
    })}
  </StyledRoot>
);

ExpansionSummary.propTypes = {
  children: PropTypes.any,
  actions: PropTypes.shape({}),
  isDisabled: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onChange: PropTypes.func,
  cssRules: PropTypes.string,
  key: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(PropTypes.any),
};

ExpansionSummary.defaultProps = {
  children: null,
  isDisabled: false,
  isExpanded: false,
  onChange: () => null,
  cssRules: '',
  actions: {},
  label: '',
  items: [],
  key: '',
};

export default ExpansionSummary;
