import React, { useCallback, useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { AuthHeader } from 'components/authHeader';
import Illustrations from 'components/illustrations';
import ServerErrorMessage from 'components/serverErrorMessage';
import { authActions } from 'core/auth/actions';
import { selectErrors, selectUserLoginLoading } from 'core/auth/selectors';
import { appStorage } from 'core/storage';
import LoginForm from 'layouts/login';

import { get } from 'lodash';
import compose from 'lodash/fp/compose';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';

import { createStructuredSelector } from 'reselect';

import styles from './style.module.scss';

const Login = ({ userLoginAction, isLoading, errors, removeError }) => {
  const history = useHistory();
  const removeLoginError = useCallback(() => {
    removeError('login');
  }, []);

  useEffect(() => {
    if (appStorage.getAccessToken()) {
      history.push('/home');
    }
  }, []);

  return (
    <div id="login" className={styles.wrapper}>
      <div className={styles.content}>
        <AuthHeader heading="Welcome to HiQo Billing System" />
        {get(errors, 'login', null) && <ServerErrorMessage className={styles.error} onClick={removeLoginError} message={get(errors, 'login[0].message', null) || 'Internal error'} />}
        <LoginForm onSubmit={userLoginAction} isLoading={isLoading} removeLoginError={removeLoginError} />
        <Illustrations />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectUserLoginLoading,
  errors: selectErrors,
});

const mapDispatchToProps = {
  userLoginAction: authActions.userLogin,
  removeError: authActions.removeError,
};

Login.propTypes = {
  userLoginAction: PropTypes.func.isRequired,
  removeError: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    login: PropTypes.array,
  }),
};

Login.defaultProps = {
  errors: null,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
