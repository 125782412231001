import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Field } from 'formik';
import { useEffectOnlyOnUpdate } from 'hooks';
import styled, { css } from 'styled-components';

const StyledRoot = styled.div`
  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;
export const Toggle = ({
  isLocked,
  cssRules,
  name,
  value,
  placeholder,
  withStopPropagation,
  setFieldValue,
  setTouched,
  onClick,
  values,
  parentField,
  isPristine,
  touched,
}) => {
  const deps = parentField ? parentField.name.map((key) => values[key]) : [];
  const isParentFieldTouched = parentField ? parentField.name.some((currentName) => touched[currentName]) : false;

  const handleClick = (e) => {
    if (withStopPropagation) {
      e.stopPropagation();
      onClick();
    } else {
      setFieldValue(name, !value);
      setTouched(name, true);
    }
  };

  useEffectOnlyOnUpdate(() => {
    if (!parentField) return;
    if (isPristine && !isParentFieldTouched) return;

    const valueGetter = parentField.valueGetter;
    const result = valueGetter ? valueGetter(values, parentField) : value;

    setFieldValue(name, result);
  }, deps);

  return (
    <StyledRoot
      className={
        classNames(
          'client-details-form__switcher',
          'form-switcher',
          { 'form-switcher--locked': isLocked }
        )}
      cssRules={cssRules}
    >

      <input
        id={name}
        className="form-switcher__input"
        type="checkbox"
        defaultChecked={value}
      />

      <label
        htmlFor={name}
        className="form-switcher__label"
        role="presentation"
        onKeyUp={handleClick}
        onClick={handleClick}
      >

        <span className="form-switcher__label-title">{placeholder}</span>

        <span className="form-switcher__icon-wrapper">

          <span
            className={
              classNames(
                'form-switcher__icon-track',
                { 'form-switcher__icon-track--checked': value }
              )
            }
          >

            <span
              className={
                classNames(
                  'form-switcher__icon-knob',
                  { 'form-switcher__icon-knob--checked': value }
                )
              }
            />

          </span>

        </span>

        {
          placeholder &&
          <span
            className={
              classNames(
                'form-switcher__status-title',
                { 'form-switcher__status-title--checked': value }
              )
            }
          >
            {
              value ?
                'Yes' :
                'No'
            }
          </span>
        }

      </label>

    </StyledRoot>

  );
};
const Switcher = (props) => (
  <Field
    type="text"
    name={props.name}
    render={
      ({ field: { value }, form: { setFieldValue, setTouched, values, dirty, touched } }) =>
        (
          <Toggle
            setFieldValue={setFieldValue}
            setTouched={setTouched}
            {...props}
            {...props.parentField && {
              values,
              parentField: props.parentField,
            }}
            value={value}
            isPristine={!dirty}
            touched={touched}
          />
        )
    }
  />
);


Switcher.propTypes = {
  isLocked: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  cssRules: PropTypes.string,
  onClick: PropTypes.func,
  withStopPropagation: PropTypes.bool,
  parentField: PropTypes.shape({}),
};

Switcher.defaultProps = {
  isLocked: false,
  name: null,
  placeholder: '',
  cssRules: '',
  onClick: () => null,
  withStopPropagation: false,
  parentField: null,
};

Toggle.propTypes = {
  value: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  touched: PropTypes.shape({}),
  isLocked: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  cssRules: PropTypes.string,
  onClick: PropTypes.func,
  withStopPropagation: PropTypes.bool,
  values: PropTypes.shape({}),
  parentField: PropTypes.shape({}),
  isPristine: PropTypes.bool,
};

Toggle.defaultProps = {
  isLocked: false,
  name: null,
  placeholder: '',
  cssRules: '',
  onClick: () => null,
  withStopPropagation: false,
  values: null,
  parentField: null,
  isPristine: false,
  touched: {},
};


export default Switcher;
