import React, { memo } from 'react';

import PropTypes from 'prop-types';

export const DocxIcon = memo(({
  width = '2.4rem',
  height = '2.4rem',
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_14862_195591)">
      <path d="M21.6043 2.25H7.27854C7.16114 2.2499 7.04487 2.27293 6.93636 2.31777C6.82786 2.3626 6.72925 2.42837 6.64617 2.51132C6.56308 2.59426 6.49715 2.69276 6.45213 2.80119C6.40711 2.90962 6.38389 3.02585 6.38379 3.14325V7.125L14.6855 9.5625L22.4998 7.125V3.14325C22.4997 3.02579 22.4764 2.90949 22.4314 2.80102C22.3863 2.69254 22.3203 2.59401 22.2371 2.51105C22.154 2.4281 22.0553 2.36234 21.9467 2.31755C21.8381 2.27276 21.7218 2.2498 21.6043 2.25Z" fill="#41A5EE" />
      <path d="M22.4998 7.125H6.38379V12L14.6855 13.4625L22.4998 12V7.125Z" fill="#2B7CD3" />
      <path d="M6.38379 12V16.875L14.1973 17.85L22.4998 16.875V12H6.38379Z" fill="#185ABD" />
      <path d="M7.27854 21.75H21.6035C21.7211 21.7503 21.8375 21.7274 21.9462 21.6827C22.0549 21.6379 22.1536 21.5722 22.2369 21.4892C22.3201 21.4062 22.3862 21.3077 22.4313 21.1992C22.4764 21.0906 22.4997 20.9743 22.4998 20.8568V16.875H6.38379V20.8568C6.38389 20.9742 6.40711 21.0904 6.45213 21.1988C6.49715 21.3072 6.56308 21.4057 6.64617 21.4887C6.72925 21.5716 6.82786 21.6374 6.93636 21.6822C7.04487 21.7271 7.16114 21.7501 7.27854 21.75Z" fill="#103F91" />
      <path opacity="0.1" d="M12.3253 6.15039H6.38379V18.3379H12.3253C12.562 18.3367 12.7888 18.2423 12.9564 18.0751C13.124 17.9079 13.219 17.6814 13.2208 17.4446V7.04364C13.219 6.80689 13.124 6.58038 12.9564 6.41318C12.7888 6.24598 12.562 6.15157 12.3253 6.15039Z" fill="black" />
      <path opacity="0.2" d="M11.837 6.63672H6.38379V18.8242H11.837C12.0738 18.823 12.3005 18.7286 12.4682 18.5614C12.6358 18.3942 12.7308 18.1677 12.7325 17.931V7.52997C12.7308 7.29322 12.6358 7.06671 12.4682 6.89951C12.3005 6.73231 12.0738 6.6379 11.837 6.63672Z" fill="black" />
      <path opacity="0.2" d="M11.837 6.63672H6.38379V17.8492H11.837C12.0738 17.848 12.3005 17.7536 12.4682 17.5864C12.6358 17.4192 12.7308 17.1927 12.7325 16.956V7.52997C12.7308 7.29322 12.6358 7.06671 12.4682 6.89951C12.3005 6.73231 12.0738 6.6379 11.837 6.63672Z" fill="black" />
      <path opacity="0.2" d="M11.3488 6.63672H6.38379V17.8492H11.3488C11.5855 17.848 11.8123 17.7536 11.9799 17.5864C12.1475 17.4192 12.2425 17.1927 12.2443 16.956V7.52997C12.2425 7.29322 12.1475 7.06671 11.9799 6.89951C11.8123 6.73231 11.5855 6.6379 11.3488 6.63672Z" fill="black" />
      <path d="M2.3955 6.63672H11.349C11.5862 6.63652 11.8137 6.73049 11.9816 6.89799C12.1496 7.06549 12.2441 7.2928 12.2445 7.52997V16.4685C12.2441 16.7056 12.1496 16.933 11.9816 17.1004C11.8137 17.2679 11.5862 17.3619 11.349 17.3617H2.3955C2.27804 17.3619 2.16168 17.339 2.05309 17.2942C1.9445 17.2494 1.84581 17.1836 1.76264 17.1007C1.67948 17.0177 1.61348 16.9192 1.56841 16.8107C1.52334 16.7022 1.5001 16.5859 1.5 16.4685V7.52997C1.5001 7.41251 1.52334 7.29621 1.56841 7.18773C1.61348 7.07926 1.67948 6.98073 1.76264 6.89777C1.84581 6.81481 1.9445 6.74906 2.05309 6.70427C2.16168 6.65948 2.27804 6.63652 2.3955 6.63672Z" fill="url(#paint0_linear_14862_195591)" />
      <path d="M5.17508 13.4908C5.19233 13.6288 5.20433 13.7488 5.20958 13.8516H5.23058C5.23808 13.7541 5.25458 13.6363 5.27933 13.4991C5.30408 13.3618 5.32583 13.2456 5.34608 13.1503L6.28733 9.09509H7.50533L8.48033 13.0896C8.53712 13.3367 8.5777 13.5872 8.60183 13.8396H8.61833C8.63624 13.5939 8.67006 13.3497 8.71958 13.1083L9.49883 9.08984H10.6066L9.23858 14.9008H7.94333L7.01558 11.0563C6.98858 10.9453 6.95808 10.8008 6.92408 10.6228C6.89008 10.4448 6.86908 10.3148 6.86108 10.2328H6.84533C6.83483 10.3273 6.81383 10.4676 6.78233 10.6536C6.75083 10.8403 6.72608 10.9776 6.70733 11.0676L5.83508 14.9031H4.51808L3.14258 9.09509H4.26758L5.11583 13.1586C5.14114 13.2683 5.16092 13.3792 5.17508 13.4908Z" fill="white" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_14862_195591" x1="3.3705" y1="5.93472" x2="10.374" y2="18.0637" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2368C4" />
        <stop offset="0.5" stopColor="#1A5DBE" />
        <stop offset="1" stopColor="#1146AC" />
      </linearGradient>
      <clipPath id="clip0_14862_195591">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

DocxIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

DocxIcon.defaultProps = {
  width: '',
  height: '',
};

DocxIcon.displayName = 'DocxIcon';
