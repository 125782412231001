import {
  ADD_TECHNICAL_MENTOR_RECORD,
  CREATE_TECHNICAL_MENTOR_RECORD,
  CREATE_TECHNICAL_MENTOR_RECORD_FAIL,
  CREATE_TECHNICAL_MENTOR_RECORD_SUCCESS,
  DELETE_TECHNICAL_MENTOR_RECORD,
  DELETE_TECHNICAL_MENTOR_RECORD_FAIL,
  DELETE_TECHNICAL_MENTOR_RECORD_SUCCESS,
  GET_TECHNICAL_MENTOR_RECORDS,
  GET_TECHNICAL_MENTOR_RECORDS_FAIL,
  GET_TECHNICAL_MENTOR_RECORDS_SUCCESS, REMOVE_TECHNICAL_MENTOR_RECORD,
  UPDATE_TECHNICAL_MENTOR_RECORD,
  UPDATE_TECHNICAL_MENTOR_RECORD_FAIL,
  UPDATE_TECHNICAL_MENTOR_RECORD_SUCCESS,
} from 'core/delivery/technicalMentorManagement/actions';
import {
  pathToTechnicalMentorRecords, recordIdName,
} from 'core/delivery/technicalMentorManagement/constants';
import { FORM_STATE } from 'core/utils';
import update from 'immutability-helper';
import {
  newTechnicalMentorRecord,
} from 'layouts/employee-details/components/resource-tabs/technical-mentor/constants';
import { get } from 'lodash';

export const technicalMentorsReducerCases = {
  [GET_TECHNICAL_MENTOR_RECORDS](state) {
    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          isFetchingTechnicalMentorRecords: {
            $set: true,
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [GET_TECHNICAL_MENTOR_RECORDS_SUCCESS](state, { payload: {
    records,
    memoDevstaffId,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          isFetchingTechnicalMentorRecords: {
            $set: false,
          },
          length: {
            $set: records.length,
          },
          technicalMentorManagementRecords: {
            $set: records,
          },
          formState: {
            $set: {
              status: FORM_STATE.INITIAL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
          memoDevstaffId: {
            $set: memoDevstaffId,
          },
        },
      },
    });
  },
  [GET_TECHNICAL_MENTOR_RECORDS_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          isFetchingTechnicalMentorRecords: {
            $set: false,
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
  [CREATE_TECHNICAL_MENTOR_RECORD](state) {
    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [CREATE_TECHNICAL_MENTOR_RECORD_SUCCESS](state, {
    payload: record,
  }) {
    const technicalMentorManagementRecords =
      get(state, pathToTechnicalMentorRecords, []);

    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          technicalMentorManagementRecords: {
            $set: technicalMentorManagementRecords.map((currentRecord) => {
              if (currentRecord[recordIdName] === newTechnicalMentorRecord[recordIdName]) {
                return record;
              }

              return currentRecord;
            }),
          },
          length: {
            $set: technicalMentorManagementRecords.length,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [CREATE_TECHNICAL_MENTOR_RECORD_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
  [UPDATE_TECHNICAL_MENTOR_RECORD](state) {
    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [UPDATE_TECHNICAL_MENTOR_RECORD_SUCCESS](state, {
    payload: record,
  }) {
    const technicalMentorManagementRecords =
      get(state, pathToTechnicalMentorRecords, []);

    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          technicalMentorManagementRecords: {
            $set: technicalMentorManagementRecords.map((currentRecord) => {
              if (currentRecord[recordIdName] === record[recordIdName]) {
                return record;
              }

              return currentRecord;
            }),
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [UPDATE_TECHNICAL_MENTOR_RECORD_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
  [DELETE_TECHNICAL_MENTOR_RECORD](state) {
    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [DELETE_TECHNICAL_MENTOR_RECORD_SUCCESS](state, {
    payload: recordId,
  }) {
    const technicalMentorManagementRecords =
      get(state, pathToTechnicalMentorRecords, []).filter((record) => record.technicalMentorRecordId !== recordId);

    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          technicalMentorManagementRecords: {
            $set: technicalMentorManagementRecords,
          },
          length: {
            $set: technicalMentorManagementRecords.length,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [DELETE_TECHNICAL_MENTOR_RECORD_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
  [ADD_TECHNICAL_MENTOR_RECORD](state, { payload: {
    record,
  } }) {
    const technicalMentorManagementRecords = get(state, pathToTechnicalMentorRecords, []);

    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          technicalMentorManagementRecords: {
            $set: [record, ...technicalMentorManagementRecords],
          },
        },
      },
    });
  },
  [REMOVE_TECHNICAL_MENTOR_RECORD](state) {
    const technicalMentorManagementRecords = get(state, pathToTechnicalMentorRecords, []);

    return update(state, {
      employeeDetailsTabs: {
        technicalMentorManagement: {
          technicalMentorManagementRecords: {
            $set: technicalMentorManagementRecords.filter((record) => record.technicalMentorRecordId !== newTechnicalMentorRecord[recordIdName]),
          },
        },
      },
    });
  },
};
