export const selectCurrentEntityName = (state) => state.common.currentEntityName;
export const selectCurrentLocation = (state) => state.router.location;

export const selectCurrentIsFormSubmitted = (state) => {
  const entityName = selectCurrentEntityName(state);

  switch (entityName) {
    case 'DELIVERY':
      return state.delivery.isFormSubmitted;
    case 'CONFIGURATIONS':
      return state.configurations.isFormSubmitted;
    case 'CLIENTS':
      return state.clients.isFormSubmitted;
    case 'DASHBOARD':
      return state.dashboard.isFormSubmitted;
    case 'TASK_ORDERS':
      return state.taskOrders.isFormSubmitted;
    case 'CHANNEL_PARTNERS':
      return state.channelPartners.isFormSubmitted;
    default:
      return false;
  }
};
