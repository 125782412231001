import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Calendar from 'components/calendar';
import GoTop from 'elements/go-top-button';
import Controls from 'components/details-controls';
import StyledWrapper from 'elements/styled-wrapper';
import LayoutBodyContent from 'layouts/layout-body';

import { filesActions } from 'core/files/actions';
import { selectProjectLeadsCommissionDetails } from 'core/billing-reports/selectors';
import { REPORTS_TYPES } from 'core/billing-reports/constants';
import { autoScrollToTop } from 'utils/react';


const ProjectLeadCommissionReport = ({
  location,
  isFetching,
  entityName,
  orderRules,
  changeOrder,
  changeFilter,
  resetFilters,
  selectedReportDate,
  setSummaryReportType,
  setBillingSummaryReportData,
  projectLeadsCommissionDetails,
  generateProjectLeadCommissionReport,
  generateProjectLeadCommissionPDFReport,
}) => {
  autoScrollToTop(location);

  const {
    body,
    controls,
    controlsHeading,
    controlsCssRules,
    calendarCssRules,
    calendarItemCssRules,
    controlsWrapperCssRules,
  } = projectLeadsCommissionDetails;
  const actions = {
    resetFilters,
    generateProjectLeadCommissionReport,
    generateProjectLeadCommissionPDFReport,
  };
  const displayedDate = useMemo(() => selectedReportDate.format('MMMM YYYY'), [selectedReportDate]);

  useEffect(() => {
    setSummaryReportType(REPORTS_TYPES.PL_COMMISSION);
  }, []);

  const setDate = (selectedDate) => {
    setBillingSummaryReportData({
      selectedDate,
    });
  };

  const layoutProps = {
    changeOrder,
    orderRules,
    entityName,
    isFetching,
    resetFilters,
    changeFilter,
  };

  return (
    <>
      <Controls
        controlsHeading={controlsHeading}
        cssRules={controlsCssRules}
      >
        {
          controls &&
          <>
            {
              controls.map(({ title: buttonTitle, actionName }) => (
                <button
                  key={buttonTitle}
                  className="controls__create-button button button--cancel client-details-controls__button--fixed-size"
                  onClick={() => actions[actionName]()}
                >
                  {buttonTitle}
                </button>
              ))
            }
          </>
        }
        <StyledWrapper
          cssRules={controlsWrapperCssRules}
        >
          <Calendar
            withStepControls
            withMonthSelecting
            minDetails="year"
            onChange={setDate}
            stepControlsConfig={{
              step: 1,
              unitName: 'month',
            }}
            cssRules={calendarCssRules}
            value={selectedReportDate}
            popperProps={{
              placement: 'bottom-start',
            }}
          >
            <StyledWrapper
              cssRules={calendarItemCssRules}
            >
              {displayedDate}
            </StyledWrapper>
          </Calendar>

        </StyledWrapper>
      </Controls>

      <LayoutBodyContent
        body={body}
        wrapperCssRules=""
        unitActions={actions}
        {...layoutProps}
      />

      <GoTop />

    </>

  );
};


ProjectLeadCommissionReport.propTypes = {
  location: PropTypes.object.isRequired,
  generateProjectLeadCommissionReport: PropTypes.func.isRequired,
  selectedReportDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]).isRequired,
  reportType: PropTypes.string,
  setBillingSummaryReportData: PropTypes.func.isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  entityName: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  setSummaryReportType: PropTypes.func.isRequired,
};

ProjectLeadCommissionReport.defaultProps = {};


const mapStateToProps = createStructuredSelector({
  projectLeadsCommissionDetails: selectProjectLeadsCommissionDetails,
});

const mapDispatchToProps = {
  generateProjectLeadCommissionReport: filesActions.generateProjectLeadCommissionReport,
  generateProjectLeadCommissionPDFReport: filesActions.generateProjectLeadCommissionPDFReport,
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectLeadCommissionReport);
