import React from 'react';
import Loader from 'components/loader';

import './styles.scss';


const LoaderModal = () => (
  <div className="loader-modal">
    <Loader />
  </div>
);

export default LoaderModal;
