import React, { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

import DropdownIcon from 'assets/img/arrow-down.svg';
import classNames from 'classnames';

import OrderButton from 'elements/order-button';

import './styles.scss';

const ExpandSection = ({
  title,
  actionTitle,
  hasWarning,
  dismissed,
  isButtonDisabled,
  children,
  onClick,
}) => {
  const [isOpened, toggleIsOpened] = useState(false);
  const toggleButton = useRef(null);

  useEffect(() => window.removeEventListener('click', handleClick), []);

  const handleClick = ({ target }) => {
    const { current } = toggleButton;

    if (target.closest('.client-details-controls__drop-down-menu') !== current) {
      closeDropDownMenu();
    }
  };

  const closeDropDownMenu = () => {
    window.removeEventListener('click', handleClick);
    toggleIsOpened(false);
  };

  const toggleIsOpen = () => {
    if (isButtonDisabled || !children) {
      return null;
    }

    if (isOpened) {
      window.removeEventListener('click', handleClick);
    } else {
      window.addEventListener('click', handleClick);
    }

    return toggleIsOpened(!isOpened);
  };

  const onAction = () => {
    onClick();
    toggleIsOpened(false);
  };

  return (
    <div
      className={
        classNames(
          'client-details-controls__drop-down-menu',
          { 'client-details-controls__drop-down-menu--dismissed': dismissed },
          { 'client-details-controls__drop-down-menu--has-warning': hasWarning },
          { 'client-details-controls__drop-down-menu--opened': isOpened },
        )
      }
      role="button"
      tabIndex={-42}
      onKeyDown={toggleIsOpen}
      onClick={toggleIsOpen}
      ref={toggleButton}
    >

      <OrderButton
        data={title}
        isActive={isOpened}
        isReversed={isOpened}
        isButtonDisabled={isButtonDisabled || !children}
        icon={DropdownIcon}
      />

      {
        isOpened &&
        <div className="client-details-controls__button-cover" />
      }

      {
        children && (
          <div
            className={
              classNames(
                'client-details-controls__expand-section',
                { 'client-details-controls__expand-section--opened': isOpened }
              )
            }
          >

            {children}

            {
              actionTitle && (
                <button
                  className="client-details-controls__button button button--accept"
                  onClick={onAction}
                >
                  {actionTitle}
                </button>
              )
            }

          </div>
        )
      }

    </div>
  );
};

ExpandSection.propTypes = {
  hasWarning: PropTypes.bool,
  dismissed: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
  actionTitle: PropTypes.string,
  children: PropTypes.any,
  isButtonDisabled: PropTypes.bool,
};

ExpandSection.defaultProps = {
  title: '',
  dismissed: false,
  actionTitle: null,
  hasWarning: false,
  children: null,
  isButtonDisabled: false,
  onClick: () => null,
};

export default ExpandSection;
