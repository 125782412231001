import { getCounterValues } from './utils';

import { TabItem } from '../components/tab-item';
import { CATEGORY_TYPES, CATEGORY_TITLES, CATEGORY_CREATION_TYPES } from '../dictionaries';

export const tabsConfig = ({ clientId, categories }) => {
  const commonTabConfigProps = {
    panel: TabItem,
    withCounter: true,
  };

  const categoriesData = clientId ? [
    {
      id: `${clientId}-0`,
      title: CATEGORY_TITLES.CLIENT,
      tabData: categories[CATEGORY_TYPES.CLIENT],
      creationType: CATEGORY_CREATION_TYPES.CLIENT,
      type: CATEGORY_TYPES.CLIENT,
      counter: getCounterValues(categories[CATEGORY_TYPES.CLIENT]),
      categoriesNames: categories[CATEGORY_TYPES.CLIENT].map(({ name }) => name.toLowerCase()),
    },
    {
      id: `${clientId}-1`,
      title: CATEGORY_TITLES.BILLING,
      tabData: categories[CATEGORY_TYPES.INTERNAL],
      creationType: CATEGORY_CREATION_TYPES.INTERNAL,
      type: CATEGORY_TYPES.INTERNAL,
      counter: getCounterValues(categories[CATEGORY_TYPES.INTERNAL]),
      categoriesNames: categories[CATEGORY_TYPES.INTERNAL].map(({ name }) => name.toLowerCase()),
    },
  ] : [
    {
      id: `${clientId}-0`,
      title: CATEGORY_TITLES.HIQO_OOO,
      tabData: categories[CATEGORY_TYPES.HIQO_OOO],
      creationType: CATEGORY_CREATION_TYPES.INTERNAL,
      type: CATEGORY_TYPES.HIQO_OOO,
      counter: getCounterValues(categories[CATEGORY_TYPES.HIQO_OOO]),
    },
    {
      id: `${clientId}-1`,
      title: CATEGORY_TITLES.HIQO_PROJECTS,
      tabData: categories[CATEGORY_TYPES.INTERNAL],
      creationType: CATEGORY_CREATION_TYPES.INTERNAL,
      type: CATEGORY_TYPES.INTERNAL,
      counter: getCounterValues(categories[CATEGORY_TYPES.INTERNAL]),
    },
  ];

  return categoriesData.map((categoryProps) => ({
    ...commonTabConfigProps,
    ...categoryProps,
  }));
};
