import React, { useCallback, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';

import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';

import { FORM_MODES } from 'layouts/employee-details/components/resource-tabs/contracts/constants';
import { newContract } from 'layouts/employee-details/components/resource-tabs/contracts/utils';
import { get } from 'lodash';
import moment from 'moment';
import * as yup from 'yup';

import styles from './styles.module.scss';

const validationSchema = yup.object().shape({
  employmentType: yup.string().required('Required field'),
  deliveryCenterId: yup.string().required('Required field'),
  startDate: yup.string()
    .nullable()
    .test({
      name: 'startDateBeforeExpiration',
      exclusive: true,
      message: 'Must be earlier than Expiration Date',
      test(startDate) {
        const expirationDate = this.parent.expirationDate;
        return !startDate || !expirationDate || moment(startDate).isBefore(moment(expirationDate));
      },
    }),
  terminationDate: yup.string()
    .nullable()
    .test({
      name: 'terminationDateBeforeExpiration',
      exclusive: true,
      message: 'Must be earlier or equal than Expiration Date',
      test(terminationDate) {
        const expirationDate = this.parent.expirationDate;
        return !terminationDate || !expirationDate || moment(terminationDate).isSameOrBefore(moment(expirationDate));
      },
    })
    .test({
      name: 'terminationDateBeforeStart',
      exclusive: true,
      message: 'Must be later than Start Date',
      test(terminationDate) {
        const startDate = this.parent.startDate;
        return !startDate || !terminationDate || moment(startDate).isBefore(moment(terminationDate));
      },
    }),
});

export const ContractForm = ({
  item,
  columns,
  onSubmitHandler,
  onDeleteHandler,
  onRemoveRecord,
  setEditedRecordId,
  editedRecordId,
  recordIdName,
  isCPS,
}) => {
  const { contractRecordId, allowedForEdit, allowedForDelete, ...defaultValues } = item;
  const isCreateMode = Boolean(contractRecordId === newContract[recordIdName]);
  const isDisabled = !isCreateMode && editedRecordId === newContract[recordIdName];
  const isDeactivate = (item.expirationDate && moment().isSameOrAfter(item.expirationDate)) || (item.terminationDate && moment().isSameOrAfter(item.terminationDate));

  const {
    handleSubmit,
    control,
    formState: {
      isDirty,
      isValid,
      dirtyFields,
    },
    reset,
    setError,
  } = useForm({
    values: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback((formData) => {
    if (isDirty && isValid) {
      onSubmitHandler({
        formData,
        allowedForEdit,
        contractRecordId,
        isCreateMode,
        dirtyFields,
      });
    }
  }, [
    isDirty,
    isValid,
    onSubmitHandler,
    contractRecordId,
    isCreateMode,
  ]);

  const renderField = useCallback(({
    column,
  }) => {
    if (column.accessor === 'actions') {
      return column.Field({
        onDeleteHandler,
        onRemoveRecord,
        isFormDirty: isDirty,
        resetForm: reset,
        isCreateMode,
        recordId: contractRecordId,
        setEditedRecordId,
        isAllowedForEdit: allowedForEdit,
        isAllowedForDelete: allowedForDelete,
      });
    }

    return (
      <Controller
        name={column.accessor}
        control={control}
        render={({ field, fieldState }) => column.Field({
          field,
          fieldState,
          item,
          isCreateMode,
          setError,
        })}
      />
    );
  }, [
    isDirty,
    isCreateMode,
    control,
  ]);

  useEffect(() => {
    if (editedRecordId !== contractRecordId) {
      reset(defaultValues);
    }
  }, [editedRecordId]);

  useEffect(() => {
    if (isDirty) {
      setEditedRecordId(contractRecordId);
    }
  }, [isDirty]);

  return (
    <form
      key={get(item, recordIdName, newContract[recordIdName])}
      onSubmit={handleSubmit(onSubmit)}
      className={classNames({
        [styles.disabledForm]: isDisabled,
      })}
    >
      {
        <ul
          className={classNames(styles.bodyStyles, {
            [styles.disabledList]: isDisabled,
          })}
        >
          {columns.map((column, columnIndex) => get(column, 'isHidden', false) ? null : (
            <li
              data-mode={allowedForEdit ? FORM_MODES.EDIT : FORM_MODES.VIEW}
              /* eslint-disable-next-line react/no-array-index-key */
              key={columnIndex}
              className={classNames(styles[column.accessor], {
                [styles.deactivate]: isDeactivate,
                [styles.lock]: isCPS,
              })}
            >
              {renderField({
                column,
                currentItem: item,
              })}
            </li>
          ))}
        </ul>
      }
    </form>
  );
};

ContractForm.propTypes = {
  item: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  onRemoveRecord: PropTypes.func.isRequired,
  setEditedRecordId: PropTypes.func.isRequired,
  editedRecordId: PropTypes.number,
  onDeleteHandler: PropTypes.func.isRequired,
  recordIdName: PropTypes.string.isRequired,
  isCPS: PropTypes.bool.isRequired,
};

ContractForm.defaultProps = {
  editedRecordId: undefined,
};
