export const ENTITY_NAME = 'EMPLOYEES_REVIEWS';
export const ADMIN_STAFF_ID = -2;
export const CUSTOMER_ID = -1;
export const DEFAULT_ORDER = {
  REVIEWS_TABLE: 'week',
};
export const ADDITIONAL_COLUMNS_INITIAL_STATE = {
  reviewby: false,
  project: false,
  role: false,
};
export const ADDITIONAL_COLUMNS_NONE_ENABLED = ADDITIONAL_COLUMNS_INITIAL_STATE;
export const ADDITIONAL_COLUMNS_ALL_ENABLED = Object.keys(ADDITIONAL_COLUMNS_INITIAL_STATE).reduce(
  (result, key) => ({
    [key]: true,
    ...result,
  }),
  {},
);
export const STORE_KEYS = {
  BY_EVALUATION_DATE: 'byEvaluationDate',
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
};

export const CONTROL_KEYS = {
  ONLY_CATEGORIES_WITH_REVIEWS: 'onlyCategoriesWithReviews',
};
