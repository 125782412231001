import { push } from 'connected-react-router';

import { waitForAuthorization } from 'core/auth/sagas';
import { queryConfig } from 'core/task-orders/queries';
import { get, isEqual } from 'lodash';
import {
  takeLatest,
  put,
  call,
  select,
  all,
} from 'redux-saga/effects';
import {
  getParsedValues,
  removeEmptyValues,
} from 'utils/helpers/forms';
import {
  parseError,
  executeQuery,
  multiMutationGetter,
  executeMutation,
} from 'utils/request';

import { sortRates } from 'utils/sortRates';

import {
  taskOrdersActions,
  taskOrdersActionsTypes,
} from './actions';
import {
  selectErrors,
  selectEntityName,
} from './selectors';
import { parseResponse, getCommittedHoursVisibleValue } from './utils';

function* getTaskOrdersList() {
  try {
    const query = queryConfig.getTaskOrdersList;
    const options = { query };
    const { taskOrders } = yield call(executeQuery, options);

    return yield put(taskOrdersActions.getTaskOrdersListSuccess({
      taskOrdersList: taskOrders || [],
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getTaskOrdersListError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getTaskOrdersListError = yield call(parseError, error, options);

    return yield put(taskOrdersActions.getTaskOrdersListFail({
      error: { getTaskOrdersListError },
    }));
  }
}

function* getCommittedHoursByClientId({
  payload: { clientId },
}) {
  try {
    const query = queryConfig.getCommittedHoursByClientId;
    const options = {
      query,
      variables: {
        clientId,
      },
    };
    const data = yield call(executeQuery, options);

    const committedHours = get(data, 'committedHours', []);

    return yield put(taskOrdersActions.getCommittedHoursByClientIdSuccess({
      availableCommittedHours: committedHours,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getCommittedHoursByClientIdError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getCommittedHoursByClientIdError = yield call(parseError, error, options);

    return yield put(taskOrdersActions.getCommittedHoursByClientIdFail({
      error: { getCommittedHoursByClientIdError },
    }));
  }
}

function* getTaskOrdersDetails({
  payload: { projectId },
}) {
  try {
    const { taskOrderToCopyId, clientId } = yield call(get, window, 'history.state.state', {});
    const query = queryConfig.getTaskOrdersDetails({ taskOrderToCopyId, projectId });
    const options = { query };
    const data = yield call(executeQuery, options);
    const taskOrdersDetails = parseResponse(data);
    const { discount, committedHours: hours, ...details } = taskOrdersDetails;

    return yield put(taskOrdersActions.getTaskOrdersDetailsSuccess({
      taskOrdersDetails: {
        taskOrderToCopyId,
        client: {
          clientId,
        },
        committedHours: {
          label: getCommittedHoursVisibleValue(hours, discount),
          value: get(taskOrdersDetails, 'committedHoursId', ''),
        },
        ...details,
      },
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getTaskOrdersDetailsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getTaskOrdersDetailsError = yield call(parseError, error, options);

    return yield put(taskOrdersActions.getTaskOrdersDetailsFail({
      error: { getTaskOrdersDetailsError },
    }));
  }
}

function* createTaskOrderSaga({
  payload: {
    clientId,
    taskOrderToCopyId,
    fields,
  },
}) {
  try {
    const mutation = queryConfig.createTaskOrder;
    const variables = {
      clientId,
      fields: {
        taskOrderToCopyId,
        ...removeEmptyValues(fields),
      },
    };
    const options = {
      mutation,
      variables,
    };
    const data = yield call(executeMutation, options);
    const node = get(data, 'createTaskOrder.taskOrder', {});
    const ratecards = get(data, 'createTaskOrder.ratecards', {});
    const projectId = get(node, 'projectId');
    const taskOrdersDetails = parseResponse({
      node,
      ratecards,
    });

    const { discount, committedHours: hours, ...details } = taskOrdersDetails;

    return yield all([
      put(taskOrdersActions.createTaskOrderSuccess({
        taskOrdersDetails: { committedHours: {
          label: getCommittedHoursVisibleValue(hours, discount),
          value: get(taskOrdersDetails, 'committedHoursId', ''),
        },
        ...details },
      })),
      put(push({
        pathname: `${projectId}/${taskOrderToCopyId ? 'staffing' : 'details'}`,
      })),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'createTaskOrderError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const createTaskOrderError = yield call(parseError, error, options);

    return yield put(taskOrdersActions.createTaskOrderFail({
      error: {
        createTaskOrderError,
      },
    }));
  }
}

function* updateStaffing({
  employeesInitial,
  employees,
  projectId,
}) {
  const addedFields = [];
  const removedFields = [];
  const updatedFields = [];
  const fieldsMap = {};

  if (employees) {
    employees.forEach((record) => {
      if (record) {
        const {
          lineId,
          ...rest
        } = record;

        if (!lineId) {
          addedFields.push({
            ...record,
            projectId,
          });
        } else {
          fieldsMap[lineId] = rest;
        }
      }
    });

    employeesInitial.forEach((initialRecord) => {
      if (initialRecord) {
        const {
          lineId,
          ...rest
        } = initialRecord;
        const record = fieldsMap[lineId];

        if (lineId) {
          if (!record) {
            removedFields.push({
              lineId,
            });
          } else if (!isEqual(record, rest)) {
            updatedFields.push({
              lineId,
              ...record,
            });
          }
        }
      }
    });
  }

  if (addedFields.length) {
    const multiMutationOptions = multiMutationGetter({
      mutation: 'createStaffingRecord',
      idKeys: 'projectId',
      records: addedFields,
      inputType: 'EmployeeInput!',
    });

    yield call(executeMutation, multiMutationOptions);
  }

  if (updatedFields.length) {
    const multiMutationOptions = multiMutationGetter({
      mutation: 'updateStaffingRecord',
      idKeys: 'lineId',
      records: updatedFields,
      inputType: 'EmployeeInput!',
    });

    yield call(executeMutation, multiMutationOptions);
  }

  if (removedFields.length) {
    const multiMutationOptions = multiMutationGetter({
      mutation: 'deleteStaffingRecord',
      withoutFields: true,
      idKeys: ['lineId'],
      records: removedFields,
    });

    yield call(executeMutation, multiMutationOptions);
  }
}

function* updateTaskOrderSaga({
  payload: {
    projectId,
    fields,
    initialValues = {},
  },
}) {
  try {
    const { ratecardId } = initialValues;
    const employeesInitial = get(initialValues, 'employees');
    const {
      employees,
      ...rest
    } = getParsedValues(fields, initialValues);

    yield call(updateStaffing, {
      employeesInitial,
      employees,
      projectId,
    });

    const mutation = queryConfig.updateTaskOrder(projectId);
    const variables = {
      fields: rest,
    };
    const options = {
      mutation,
      variables,
    };
    const {
      updateTaskOrder: {
        taskOrder,
      },
    } = yield call(executeMutation, options);

    if (taskOrder && taskOrder.taskOrderRates) {
      const taskOrderRates = taskOrder.taskOrderRates.rates;
      const taskOrderRatesByDevCenter = {};// pls create sorting on server instead this

      taskOrderRates.forEach((rate) => {
        if (!taskOrderRatesByDevCenter[rate.devcenter]) {
          taskOrderRatesByDevCenter[rate.devcenter] = [];
        }
        if (rate.seniority.toLowerCase() === 'junior') {
          taskOrderRatesByDevCenter[rate.devcenter][2] = rate;
        } else if (rate.seniority.toLowerCase() === 'staff') {
          taskOrderRatesByDevCenter[rate.devcenter][1] = rate;
        } else if (rate.seniority.toLowerCase() === 'senior') {
          taskOrderRatesByDevCenter[rate.devcenter][0] = rate;
        }
      });
      taskOrder.taskOrderRates.rates = Object.values(taskOrderRatesByDevCenter).flat();
      const taskOrderRatesName = taskOrder.taskOrderRates.name;
      taskOrder.taskOrderRates.name = ratecardId === taskOrderRatesName ? `${taskOrderRatesName} ` : taskOrderRatesName;
      taskOrder.client.templateRatecard.rates = sortRates(taskOrder.client.templateRatecard.rates);
    }

    return yield all([
      put(taskOrdersActions.updateTaskOrderSuccess({
        projectId,
        fields: taskOrder,
      })),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'updateTaskOrderError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const updateTaskOrderError = yield call(parseError, error, options);

    return yield put(taskOrdersActions.updateTaskOrderFail({
      error: {
        updateTaskOrderError,
      },
    }));
  }
}

function* getTaskOrdersListWatcher() {
  yield takeLatest(taskOrdersActionsTypes.GET_TASK_ORDERS_LIST, waitForAuthorization(getTaskOrdersList));
}

function* getTaskOrdersDetailsWatcher() {
  yield takeLatest(taskOrdersActionsTypes.GET_TASK_ORDERS_DETAILS, waitForAuthorization(getTaskOrdersDetails));
}

function* createTaskOrderSagaWatcher() {
  yield takeLatest(taskOrdersActionsTypes.CREATE_TASK_ORDER, waitForAuthorization(createTaskOrderSaga));
}

function* updateTaskOrderSagaWatcher() {
  yield takeLatest(taskOrdersActionsTypes.UPDATE_TASK_ORDER, waitForAuthorization(updateTaskOrderSaga));
}

function* getCommittedHoursByClientIdWatcher() {
  yield takeLatest(taskOrdersActionsTypes.GET_COMMITTED_HOURS_BY_CLIENT_ID, waitForAuthorization(getCommittedHoursByClientId));
}

export default [
  updateTaskOrderSagaWatcher,
  createTaskOrderSagaWatcher,
  getTaskOrdersListWatcher,
  getTaskOrdersDetailsWatcher,
  getCommittedHoursByClientIdWatcher,
];
