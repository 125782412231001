import PdfFileIcon from 'assets/img/icon-pdf-file.svg';
import { colorSecondaryGrayLight } from 'assets/styles/variables';
import { COLUMN_TYPES } from 'core/constants';
import { toNumber } from 'lodash';
import { addThousandSeparator } from 'utils/helpers/numbers';

export default ({
  isCTO,
  hasPermissionsTOTaskOrderModule,
}) => [{
  columnName: 'Billing Category Key',
  headerProps: {
    cssRules: 'min-height: 2.8rem;',
  },
  valueGetter: ({
    plName,
    project,
    isSubTotal,
    projectKey,
    isGrandTotal,
  }) => {
    if (isSubTotal || isGrandTotal) {
      return {
        type: COLUMN_TYPES.ACTION,
        componentProps: {
          data: `Total for ${project}`,
          imgSrc: PdfFileIcon,
          actionName: 'generateProjectLeadCommissionPDFReport',
          actionArguments: { plName },
          cssRules: `
            && {
              display: flex;
              font-size: 1.4rem;
              line-height: 2.4rem;
              font-weight: 500;
              text-decoration: none;
            }
          `,
        },
      };
    }

    return {
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data: projectKey,
        isMultiline: true,
      },
    };
  },
},
{
  columnName: 'Billing Category Name',
  valueGetter: ({
    taskOrderId,
    isSubTotal,
    isGrandTotal,
    projectName,
  }) => {
    if (isSubTotal || isGrandTotal) {
      return {};
    }

    return {
      type: taskOrderId && hasPermissionsTOTaskOrderModule ? COLUMN_TYPES.LINK : COLUMN_TYPES.TEXT,
      componentProps: {
        data: projectName || 'n/a',
        pathname: `/task-orders/${taskOrderId}/${isCTO ? 'staffing' : 'details'}`,
        cssRules: 'font-size: 1.2rem;',
        isMultiline: true,
      },
    };
  },
},
{
  columnName: 'TO',
  valueGetter: ({
    taskOrder,
    taskOrderId,
    isSubTotal,
    isGrandTotal,
  }) => {
    if (isSubTotal || isGrandTotal) {
      return {};
    }
    return {
      type: taskOrderId && hasPermissionsTOTaskOrderModule ? COLUMN_TYPES.LINK : COLUMN_TYPES.TEXT,
      componentProps: {
        data: taskOrder || 'n/a',
        pathname: `/task-orders/${taskOrderId}/${isCTO ? 'staffing' : 'details'}`,
        cssRules: 'font-size: 1.2rem;',
        isMultiline: true,
      },
    };
  },
},
{
  columnName: 'Role',
  valueGetter: ({
    role,
    isSubTotal,
    isGrandTotal,
  }) => {
    if (isSubTotal || isGrandTotal) {
      return {};
    }
    return {
      type: COLUMN_TYPES.TEXT,
      value: role || 'n/a',
    };
  },
},
{
  columnName: '[%]',
  valueGetter: ({
    percentage,
    isSubTotal,
    isGrandTotal,
  }) => {
    if (isSubTotal || isGrandTotal) {
      return {};
    }
    return {
      type: COLUMN_TYPES.TEXT,
      value: percentage,
    };
  },
},
{
  columnName: 'Seniority',
  valueGetter: ({
    seniority,
    isSubTotal,
    isGrandTotal,
  }) => {
    if (isSubTotal || isGrandTotal) {
      return {};
    }
    return {
      type: COLUMN_TYPES.TEXT,
      value: seniority,
    };
  },
},
{
  columnName: 'Billed hrs',
  headerProps: {
    cssRules: 'margin-left: auto;',
  },
  valueGetter: ({
    billedHours,
    isSubTotal,
    isGrandTotal,
  }) => {
    const data = addThousandSeparator(toNumber(billedHours).toFixed(2));

    return {
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data,
        cssRules: `&& {
          padding-right: 0;
          overflow: visible;
          text-align: right;
          ${!billedHours ? `color: ${colorSecondaryGrayLight}` : ''}
          ${isSubTotal || isGrandTotal ? 'font-weight: 500;' : ''}
        }`,
      },
      cssRules: `
        && {
          padding-right: 1.8rem;
        }
        && .plain-text {
          overflow: visible;
        }
      `,
    };
  },
},
{
  columnName: 'Com. hrs',
  headerProps: {
    cssRules: 'margin-left: auto;',
  },
  valueGetter: ({
    comHours,
    isSubTotal,
    isGrandTotal,
  }) => {
    const data = addThousandSeparator(toNumber(comHours).toFixed(isSubTotal || isGrandTotal ? 2 : 4));

    return {
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data,
        cssRules: `&& {
          padding-right: 0;
          overflow: visible;
          text-align: right;
          ${!comHours ? `color: ${colorSecondaryGrayLight}` : ''}
          ${isSubTotal || isGrandTotal ? 'font-weight: 500;' : ''}
        }`,
      },
      cssRules: `
        && {
          padding-right: 1.8rem;
        }
        && .plain-text {
          overflow: visible;
        }
      `,
    };
  },
},
{
  columnName: 'Forfeited hrs',
  headerProps: {
    cssRules: 'margin-left: auto;',
  },
  valueGetter: ({
    forfeitedHours,
    isSubTotal,
    isGrandTotal,
  }) => {
    const data = addThousandSeparator(toNumber(forfeitedHours).toFixed(isSubTotal || isGrandTotal ? 2 : 4));

    return {
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data,
        cssRules: `&& {
          padding-right: 0;
          overflow: visible;
          text-align: right;
          ${!forfeitedHours ? `color: ${colorSecondaryGrayLight}` : ''}
          ${isSubTotal || isGrandTotal ? 'font-weight: 500;' : ''}
        }`,
      },
      cssRules: `
        && {
          padding-right: 1.8rem;
        }
        && .plain-text {
          overflow: visible;
        }
      `,
    };
  },
},
{
  columnName: 'Rate',
  headerProps: {
    cssRules: 'margin-left: auto;',
  },
  valueGetter: ({
    rate,
    isSubTotal,
    isGrandTotal,
  }) => {
    if (isSubTotal || isGrandTotal) {
      return {};
    }
    const data = addThousandSeparator(toNumber(rate).toFixed(2));

    return {
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data,
        cssRules: `&& {
          padding-right: 0;
          overflow: visible;
          text-align: right;
          ${!rate ? `color: ${colorSecondaryGrayLight}` : ''}
        }`,
      },
      cssRules: `
        && {
          padding-right: 1.8rem;
        }
        && .plain-text {
          overflow: visible;
        }
      `,
    };
  },
}, {
  columnName: 'Forfeited $',
  headerProps: {
    cssRules: 'margin-left: auto;',
  },
  valueGetter: ({
    forfeited,
    isSubTotal,
    isGrandTotal,
  }) => {
    const data = addThousandSeparator(toNumber(forfeited).toFixed(2));

    return {
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data,
        cssRules: `&& {
          padding-right: 0;
          overflow: visible;
          text-align: right;
          ${!forfeited ? `color: ${colorSecondaryGrayLight}` : ''}
          ${isSubTotal || isGrandTotal ? 'font-weight: 500;' : ''}
        }`,
      },
      cssRules: `
        && {
          padding-right: 1.8rem;
        }
        && .plain-text {
          overflow: visible;
        }
      `,
    };
  },
},
{
  columnName: 'Commission Paid',
  headerProps: {
    cssRules: 'margin-left: auto;',
  },
  valueGetter: ({
    commissionPaid,
    isSubTotal,
    isGrandTotal,
  }) => {
    const data = addThousandSeparator(toNumber(commissionPaid).toFixed(isSubTotal || isGrandTotal ? 2 : 4));

    return {
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data,
        cssRules: `&& {
          padding-right: 0;
          overflow: visible;
          text-align: right;
          ${!commissionPaid ? `color: ${colorSecondaryGrayLight}` : ''}
          ${isSubTotal || isGrandTotal ? 'font-weight: 500;' : ''}
        }`,
      },
      cssRules: `
        && {
          padding-right: 1.8rem;
        }
        && .plain-text {
          overflow: visible;
        }
      `,
    };
  },
},
];
