import React, { memo } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.module.scss';

export const Spinner = memo(({ className }) => (
  <div data-id="Spinner" className={classNames(styles.loader, className)}>
    <div className={styles.spinner}></div>
  </div>
));

Spinner.propTypes = {
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: '',
};

Spinner.displayName = 'Spinner';
