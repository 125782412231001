export function generatePaths(obj, currentPath = '') {
  let paths = [];
  let path = '';

  Object.entries(obj).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      path = `${currentPath}${key}`;

      paths = paths.concat(generatePaths(value, `${currentPath}[${key}]`));
    }

    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (item !== null && typeof item === 'object') {
          path = `${currentPath}.${key}[${index}]`;

          paths = paths.concat(generatePaths(item, path));
        }
      });
    }

    if (value && !Number.isNaN(key) && typeof value !== 'object') {
      path = `${currentPath}.${key}`;

      paths.push(path);
    }
  });

  return paths;
}
