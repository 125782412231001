import { selectUserGroup } from 'core/auth/selectors';
import { getContractManagementRecords, getContractsManagementPermissions } from 'core/delivery/contractManagement/utils';
import { selectDevCenters } from 'core/delivery/selectors';
import { createSelector } from 'reselect';
export const selectContractManagementRecords = (state) => state.delivery.employeeDetailsTabs.contractManagement.contractManagementRecords;
export const selectIsFetchingContractManagementRecords = (state) => state.delivery.employeeDetailsTabs.contractManagement.isFetchingContractManagementRecords;
export const selectFormState = (state) => state.delivery.employeeDetailsTabs.contractManagement.formState;
export const selectMemoDevstaffId = (state) => state.delivery.employeeDetailsTabs.contractManagement.memoDevstaffId;

export const selectContractLength = (state) => state.delivery.employeeDetailsTabs.contractManagement.length;

export const selectRecords = createSelector(
  selectContractManagementRecords,
  selectDevCenters,
  selectIsFetchingContractManagementRecords,
  selectFormState,
  selectUserGroup,
  selectMemoDevstaffId,
  (
    contractManagementRecords,
    devcenters,
    isFetchingContractManagementRecords,
    formState,
    userGroup,
    memoDevstaffId
  ) => {
    const {
      hasPermissionToEdit,
      hasPermissionToAddRecord,
      hasPermissionToViewAttachment,
    } = getContractsManagementPermissions({ userGroup });

    return ({
      memoDevstaffId,
      isFetchingContractManagementRecords,
      contractManagementRecords: getContractManagementRecords({
        records: contractManagementRecords,
        hasPermissionToEdit,
      }),
      formState,
      hasPermissionToAddRecord,
      hasPermissionToViewAttachment,
    });
  }
);

export const selectDevcentersListForSelect = createSelector(selectDevCenters, (devcenters) => Object.keys(devcenters.byIdShortName).map((key) => ({ value: key, label: devcenters.byIdShortName[key] })));
