export const ENTITY_NAME = 'DELIVERY_CENTER_UTILIZATION_BY_MONTH';

export const STORE_KEYS = {
  DEVCENTER: 'devcenter',
  MONTH: 'month',
  DATE: 'logDate',
  PROJECT: 'projectName',
  TO: 'taskOrder',
  TYPE: 'projectType',
  BILLABLE: 'billType',
  HOURS: 'logHours',
};

export const DEFAULT_ORDER = {
  PTM_TABLE: 'Client',
  PROMO_TABLE: 'Project this month',
  TTM_TABLE: 'Client',
  ODC_TABLE: 'Client',
  OVERTIME_TABLE: 'Client',
  NON_BILLABLE_ACTIVITIES_TABLE: 'Project this month',
  DETAILED_WORKLOG_TABLE: 'Date',
  PROJECT_ROWS: 'Full name',
  SALES_COMMISSION_TABLE: 'Project this month',
  PLC_TABLE: 'Project',
  ANNUAL_REPORT_STAFF_TABLE: 'Resource',
};

// TODO: Move to backend
export const BILL_TYPES = [
  'BILL',
  'OT',
  'NO-TO',
  'PROMO',
  'OOO-NP', 'OOO-PTO', 'OOO-SL', // OOO
  'COMP',
  'INT',
  'EXT',
  'NOBILL',
];

// TODO: Move to backend
export const PROJECTS_TYPES = [
  'TTM',
  'PTM',
  'ODC',
];
