import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { push } from 'connected-react-router';
import {
  selectHasPermissionsCreateChannelPartner,
  selectHasPermissionDocumentUpload,
} from 'core/auth/selectors';

import { channelPartnersActions } from 'core/channel-partners/actions';
import {
  selectedChannelPartnersData,
  selectOrderRules,
  selectIsFetchingChannelPartnersList,
  selectFilterConfig,
  selectHasFilters,
  selectIsFilteringChannelPartnersList,
  selectIsFetchingDetails,
  selectIsChannelPartnerDetailsFormSubmitted,
  selectChannelPartnersListModel,
  selectChannelPartnersCount,
  selectNumberOfChannelPartnerFiles,
  selectEntityName,
  selectIsFetchingFiles,
  selectChannelPartnerDetails,
} from 'core/channel-partners/selectors';
import { commonActions } from 'core/common/actions';
import { filesActions } from 'core/files/actions';
import {
  selectUploadingProgress,
  selectIsFileUploaded,
} from 'core/files/selectors';
import { selectCurrentModal } from 'core/modal-conductor/selectors';
import ChannelPartnersDetails from 'layouts/channel-partners-details';
import ChannelPartnersList from 'layouts/channel-partners-list';
import compose from 'lodash/fp/compose';
import { Switch, Route, Redirect, withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

const ChannelPartners = (props) => {
  const { entityName, setEntityName, hasPermissionsCreateChannelPartner } = props;
  useEffect(() => {
    setEntityName(entityName);
  }, [entityName]);

  return (
    <Switch>
      <Route
        exact
        path="/channel-partners"
        render={() => <ChannelPartnersList {...props} />}
      />

      <Route
        exact
        path="/channel-partners/add"
        render={() => hasPermissionsCreateChannelPartner ? (
          <ChannelPartnersDetails isNewChannelPartner {...props} />
        ) : (
          <Redirect to="/channel-partners" />
        )}
      />

      <Route
        path="/channel-partners/:id/:tab"
        render={({ match: { params } }) => {
          const { id, tab } = params;

          return <ChannelPartnersDetails channelPartnerId={id} tab={tab} {...props} />;
        }}
      />

      <Redirect to="/channel-partners" />
    </Switch>
  );
};

ChannelPartners.propTypes = {
  entityName: PropTypes.string.isRequired,
  getChannelPartnersList: PropTypes.func.isRequired,
  setEntityName: PropTypes.func.isRequired,
  hasPermissionsCreateChannelPartner: PropTypes.bool.isRequired,
  userGroup: PropTypes.string,
  guaranteedAccessRoles: PropTypes.arrayOf(PropTypes.string),
  clearErrors: PropTypes.func.isRequired,
};

ChannelPartners.defaultProps = {
  userGroup: '',
  guaranteedAccessRoles: [],
};

const mapStateToProps = createStructuredSelector({
  isFetchingFiles: selectIsFetchingFiles,
  entityName: selectEntityName,
  numberOfChannelPartnerFiles: selectNumberOfChannelPartnerFiles,
  hasPermissionsCreateChannelPartner: selectHasPermissionsCreateChannelPartner,
  hasPermissionDocumentUpload: selectHasPermissionDocumentUpload,
  isFileUploaded: selectIsFileUploaded,
  channelPartnersData: selectedChannelPartnersData,
  orderRules: selectOrderRules,
  isFetchingChannelPartnersList: selectIsFetchingChannelPartnersList,
  isFilteringChannelPartnersList: selectIsFilteringChannelPartnersList,
  filterConfig: selectFilterConfig,
  hasActiveFilters: selectHasFilters,
  channelPartnerDetails: selectChannelPartnerDetails,
  isFetchingDetails: selectIsFetchingDetails,
  isChannelPartnerDetailsFormSubmitted: selectIsChannelPartnerDetailsFormSubmitted,
  uploadingProgress: selectUploadingProgress,
  channelPartnersListModel: selectChannelPartnersListModel,
  currentModal: selectCurrentModal,
  channelPartnersCount: selectChannelPartnersCount,
});

const mapDispatchToProps = {
  push,
  uploadFile: filesActions.uploadFile,
  createChannelPartner: channelPartnersActions.createChannelPartner,
  resetFilters: channelPartnersActions.resetFilters,
  changeFilter: channelPartnersActions.changeFilter,
  setEntityName: commonActions.setEntityName,
  getChannelPartnersList: channelPartnersActions.getChannelPartnersList,
  getChannelPartnerDetails: channelPartnersActions.getChannelPartnerDetails,
  updateChannelPartnerDetails: channelPartnersActions.updateChannelPartnerDetails,
  clearErrors: channelPartnersActions.clearErrors,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChannelPartners);
