/* eslint-disable react/button-has-type */
import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Spinner } from 'components/buttons/ActionButton/spinner';

import styles from './styles.module.scss';

const BUTTON_TYPES = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  RESET: 'reset',
};

export const ActionButton = ({
  title,
  type,
  onClick,
  className,
  disabled,
  isLoading,
  dataId,
  children,
}) => {
  const isNotSubmitType = type !== BUTTON_TYPES.SUBMIT;

  return (
    <button
      title={title}
      data-id={dataId}
      className={classNames(styles.button, className, {
        [styles.disabled]: disabled,
      })}
      type={type}
      disabled={disabled}
      {...isNotSubmitType && {
        onClick,
      }}
    >
      {isLoading && <Spinner />}
      {children}
    </button>
  );
};

ActionButton.propTypes = {
  dataId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  title: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

ActionButton.defaultProps = {
  type: BUTTON_TYPES.BUTTON,
  className: '',
  onClick: () => null,
  disabled: false,
  isLoading: false,
  title: '',
};
