export const ENTITY_NAME = 'USER_MANAGEMENT';

export const USER_ID = 'userId';

export const USER_MANAGEMENT_FILTER_STATE = {
  fullname: {
    value: '',
    isActive: false,
  },
  email: {
    value: '',
    isActive: false,
  },
  role: {
    value: [],
    isActive: false,
  },
  title: {
    value: '',
    isActive: false,
  },
  billingAgent: {
    value: [],
    isActive: false,
  },
  phone: {
    value: '',
    isActive: false,
  },
};

export const FORM_STATE = {
  isSubmitting: false,
  isFormSubmitted: false,
  isFormError: false,
};
