import { resourceContractManagementAccess } from 'core/auth/guaranteedAccessRoles';
import { get } from 'lodash';

import { recordIdName } from './constants';

const { rolesToView, rolesToEdit, rolesToViewAttachment } = resourceContractManagementAccess;

export const getContractManagementRecords = ({
  records,
  hasPermissionToEdit,
}) => records.map((record) => {
  const { fileName, downloadUrl, ...currentRecord } = record;
  const file = {
    name: fileName,
    downloadUrl,
  };

  return {
    ...currentRecord,
    deliveryCenterId: String(record.deliveryCenterId),
    file,
    allowedForEdit: hasPermissionToEdit,
    allowedForDelete: get(record, 'allowedForDelete', true),
  };
});

export const getContractsManagementPermissions = ({
  userGroup,
}) => {
  const isRoleToEdit = rolesToEdit.includes(userGroup);
  const isRoleToViewAttachment = rolesToViewAttachment.includes(userGroup);

  return {
    hasPermissionToEdit: isRoleToEdit,
    hasPermissionToView: rolesToView.includes(userGroup),
    hasPermissionToAddRecord: isRoleToEdit,
    hasPermissionToViewAttachment: isRoleToViewAttachment,
  };
};

export const generateContractManagementRecord = (currentRecord, record) => {
  if (currentRecord[recordIdName] !== record[recordIdName]) return currentRecord;
  const fileName = get(record, 'fileName', false) ? `${record.fileName}.pdf` : null;
  return { ...record, fileName };
};
