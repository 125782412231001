import { USERS_GROUPS } from 'core/auth/constants';
import { customerTabAccess, documentGenerate } from 'core/auth/guaranteedAccessRoles';
import {
  CLIENT_ACTION_BY_STATUS_FOR_BUTTONS,
  CLIENT_STATUSES,
} from 'core/clients/constants';
import { getClientStatus } from 'core/clients/utils';
import { getHasPermissions } from 'utils/auth';
import { getIsExpired } from 'utils/helpers/date';

import billingInfoTabBodyGetter from './billing-info-tab';
import clientDocumentsTabBodyGetter from './client-documents-tab';
import customerTabGetter from './customer-tab';
import detailsTabBodyGetter from './details-tab';
import taskOrdersTabGetter from './task-orders-tab';

const { CTO, HRM, CEO, DELIVERY_OFFICE_ADMIN, DELIVERY_OFFICE_ADMIN_BY } =
  USERS_GROUPS;

export const clientDetailsModelGetter = (
  userGroup,
  accountId,
  customers,
  clientDetails,
  taskOrderToCopyId
) => {
  const {
    ratecardId,
    ratecards = [],
    msa: currentMsa,
    clientRatecardRates,
    isSignedMsaUploaded,
    name: currentClientName,
    filesMsa: currentMsaList,
    clientId: currentClientID,
    isActive: isClientActive = false,
    templateRatecard: currentTemplateRatecard,
    devcenters,
    msaTemplate,
    defaultMsaTemplate,
    nosec14,
    individualLiability,
  } = clientDetails;
  const isCreatingForm = !currentClientID;
  const isCTO = getHasPermissions(userGroup, [CTO]);
  const isCEO = getHasPermissions(userGroup, [CEO]);
  const isHRM = getHasPermissions(userGroup, [HRM]);
  const isDOA =
    getHasPermissions(userGroup, [DELIVERY_OFFICE_ADMIN]) ||
    getHasPermissions(userGroup, [DELIVERY_OFFICE_ADMIN_BY]);
  const hasPermissionsToADDFiles = getHasPermissions(
    userGroup,
    documentGenerate
  );
  const customersById = {};
  customers.forEach(({ value, label }) => {
    customersById[value] = label;
  });

  const isCustomerTabVisible = getHasPermissions(userGroup, customerTabAccess);
  const customerTab = {
    tabHeader: {
      title: 'HiQo Customer',
      labels: [
        {
          valueGetter: ({ customer }) => ({
            value: customer ? customersById[customer] : 'Not selected',
            isEmpty: !customer,
          }),
        },
      ],
    },
    tabBody: customerTabGetter({
      isCEO,
      customers,
    }),
  };

  const clientDetailsModel = {
    additionalDataGetter: ({ isActive }) => ({
      status: getClientStatus(isActive, isSignedMsaUploaded, CLIENT_STATUSES),
      actionTitle: getClientStatus(
        isActive,
        isSignedMsaUploaded,
        CLIENT_ACTION_BY_STATUS_FOR_BUTTONS
      ),
    }),
    dataTemplate: [
      {
        tabHeader: {
          isLocked: isHRM || isDOA,
          title: 'Details',
          labels: [
            {
              valueGetter: ({ msa }) => ({
                value: msa ? `MSA# ${msa}` : 'No MSA',
                isEmpty: !msa,
              }),
            },
            {
              valueGetter: ({ country }) => ({
                value: country || '-',
                isEmpty: !country,
              }),
            },
          ],
        },

        tabBody: detailsTabBodyGetter({
          isDOA,
          userGroup,
          currentMsa,
          isCreatingForm,
          isHRM,
        }),
      },

      {
        tabHeader: {
          isLocked: isHRM || isDOA,
          title: 'Billing info',
          labels: [
            {
              valueGetter: ({ templateRatecard, isPlatinumRates }) => ({
                value: `${
                  templateRatecard && templateRatecard.name ?
                    templateRatecard.name :
                    '-'
                } / ${
                  isPlatinumRates || isCreatingForm ?
                    'Platinum' :
                    'Not platinum'
                }`,
                isEmpty: !isPlatinumRates && !templateRatecard,
              }),
            },
            {
              // TODO back to BA name after updates on endpoint
              valueGetter: ({ billingAgentId, billingAgents = [] }) => {
                const currentItem = billingAgents.find(
                  (item) => item.billingAgentId === billingAgentId
                );
                return {
                  value:
                    currentItem && currentItem.name ? currentItem.name : '-',
                  isEmpty: !currentItem || !currentItem.name,
                };
              },
            },
          ],
        },
        tabBody: billingInfoTabBodyGetter({
          isHRM,
          isDOA,
          userGroup,
          accountId,
          currentTemplateRatecard,
          isCreatingForm,
          clientRatecardRates,
          ratecards,
          ratecardId,
          devcenters,
        }),
      },

      {
        tabHeader: {
          isLocked: isDOA,
          title: 'Client documents',
          labels: [
            {
              valueGetter: ({ files }) => ({
                value: `${files || 0} document${files === 1 ? '' : 's'}`,
                isEmpty: !files,
              }),
            },
            {
              pathInAPIResponse: ['filesMsa'],
              type: 'string',
              valueGetter: ({ filesMsa = {} }) => {
                const { length } = filesMsa;

                return {
                  value: length ? `${length} MSA` : 'No MSA',
                  isEmpty: !length,
                };
              },
            },
          ],
        },

        stylesTemplate: {
          wrapperCssRules: `
            grid-column-gap: 2rem;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr minmax(32.8rem, 1fr);
            grid-template-areas: "detailsTable detailsTable detailsTable ${
  isClientActive && hasPermissionsToADDFiles ?
    'documentControl' :
    'detailsTable'
}" "detailsForm detailsForm detailsForm ${
  isClientActive && hasPermissionsToADDFiles ?
    'documentControl' :
    'detailsForm'
}" "detailsFormButtons detailsFormButtons detailsFormButtons detailsFormButtons";
          `,
        },

        tabBody: clientDocumentsTabBodyGetter({
          isHRM,
          isDOA,
          userGroup,
          currentMsa,
          currentMsaList,
          isClientActive,
          currentClientName,
          currentClientID,
          msaTemplate,
          defaultMsaTemplate,
          nosec14,
          individualLiability,
        }),
      },

      {
        tabHeader: {
          isLocked: isDOA,
          title: 'Task Orders',
          labels: [
            {
              valueGetter: ({ taskOrders = [] }) => {
                const changedTOLength =
                  taskOrders && taskOrders.filter ?
                    taskOrders.filter(
                      ({ isSelfRatecardChanged, isClientRatecardChanged }) => isClientRatecardChanged || isSelfRatecardChanged
                    ).length :
                    0;

                const length =
                  taskOrders && taskOrders.length ? taskOrders.length : 0;

                return {
                  value: `${length} task order${length === 1 ? '' : 's'} ${
                    changedTOLength ?
                      `/ ${changedTOLength} with customized rates` :
                      ''
                  } `,
                  isEmpty: !length,
                };
              },
            },
            {
              valueGetter: ({ taskOrders = [] }) => {
                const length =
                  taskOrders && taskOrders.filter ?
                    taskOrders.filter(
                      ({ isActive, endDate }) => isActive && !getIsExpired(endDate)
                    ).length :
                    0;

                const hasOpenPTM =
                  taskOrders &&
                  taskOrders.some(
                    ({ isActive, projectType, endDate }) => isActive &&
                      !getIsExpired(endDate) &&
                      projectType &&
                      projectType.toLowerCase() === 'ptm'
                  );

                return {
                  value: `${length} active${hasOpenPTM ? ' , Open PTM' : ''}`,
                  isEmpty: !length,
                };
              },
            },
          ],
        },

        tabBody: taskOrdersTabGetter({
          isCTO,
          isDOA,
          userGroup,
          isClientActive,
          taskOrderToCopyId,
          isSignedMsaUploaded,
          clientId: currentClientID,
        }),
      },
    ],
  };

  let clientDetailsModelWithCustomerTab;

  if (isCustomerTabVisible) {
    clientDetailsModelWithCustomerTab = {
      ...clientDetailsModel,
      dataTemplate: [...clientDetailsModel.dataTemplate, customerTab],
    };

    return clientDetailsModelWithCustomerTab;
  }

  return clientDetailsModel;
};
