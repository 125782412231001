/* eslint-disable */
import {
  get,
} from 'lodash';
import { sortRates } from 'utils/sortRates';

// TODO: 'This "parseResponse" and "parseMutationResponse" is a mess. It needs to be refactored.'
export const getClientStatus = (isActive, isSignedMsaUploaded, {
  IS_ACTIVE,
  IS_INACTIVE,
  IS_NOT_YET_ACTIVE,
  IS_DISMISSED,
}) => {
  switch (isSignedMsaUploaded) {
    case true:
      return isActive ? IS_ACTIVE : IS_INACTIVE;
    case false:
      return isActive ? IS_NOT_YET_ACTIVE : IS_DISMISSED;
    default:
      break;
  }
  return null;
};

export const parseResponse = ({
  node,
  ratecards,
  defaultMsaTemplate,
  ...rest
}) => {
  const files = get(node, 'files', []);
  const filesMsa = [];
  const filesOthers = [];
  let clientRatecardRates = [];
  let templateRatecard = [];

  if (node) {
    clientRatecardRates = get(node, 'clientRatecard.rates', null);
    templateRatecard = get(node, 'templateRatecard.rates', null);
    if (clientRatecardRates) {
      node.clientRatecard.rates = sortRates(clientRatecardRates);
    }
    if (templateRatecard) {
      node.templateRatecard.rates = sortRates(templateRatecard);
    }
    node.defaultMsaTemplate = defaultMsaTemplate;
  }

  files.forEach((file) => (
    file.fileType && file.fileType.toLowerCase() === 'msa' ?
      filesMsa.push(file) :
      filesOthers.push(file)
  ));

  const response = {
    ...node,
    filesMsa,
    filesOthers,
    files: files.length,
    clientRatecardRates: clientRatecardRates ? sortRates(clientRatecardRates) : clientRatecardRates,
    ...rest,
  };
  if (ratecards) {
    const sortedRateCards = ratecards.map((ratecard) => {
      const rates = ratecard.rates;
      ratecard.rates = rates ? sortRates(rates) : [];
      return ratecard;
    });
    response.ratecards = sortedRateCards;
  }

  return response;
};

export const parseMutationResponse = ({
  updateClient,
}) => {
  const files = get(updateClient, 'client.files', []);
  const clientRatecardRates = get(updateClient, 'client.clientRatecard.rates', null);
  const templateRatecard = get(updateClient, 'client.templateRatecard.rates', null);
  const filesMsa = [];
  const filesOthers = [];

  updateClient.client.clientRatecard.rates = sortRates(clientRatecardRates);
  updateClient.client.templateRatecard.rates = sortRates(templateRatecard);
  files.forEach((file) => (
    file.fileType && file.fileType.toLowerCase() === 'msa' ?
      filesMsa.push(file) :
      filesOthers.push(file)
  ));

  return {
    ...updateClient.client,
    filesMsa,
    filesOthers,
    files: files.length,
    clientRatecardRates: sortRates(clientRatecardRates),
  };
};

export const getExcludedDevcenters = (devcenters, excludedDevcenters) => excludedDevcenters.includes(0) ?
  devcenters.map(({ value }) => ({ devcenterId: value })) :
  excludedDevcenters.map((devcenterId) => ({ devcenterId }));
