import update from 'immutability-helper';
import {
  createReducer,
} from 'redux-create-reducer';

import {
  userActionsTypes,
} from './actions';

import {
  ENTITY_NAME,
} from './constants';

const initialState = {
  errors: {},
  entityName: ENTITY_NAME,
  isFiltering: false,
  isFetching: false,
  isFormSubmitted: false,
  formData: {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  },
  userData: {
    userId: null,
    fullName: '',
    title: '',
    email: '',
    hiqoentity: '',
    lastLogin: '',
    loginIp: '',
    logs: [],
  },
};

export default createReducer(initialState, {
  [userActionsTypes.GET_USER_DATA](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },

  [userActionsTypes.GET_USER_DATA_SUCCESS](state, {
    payload: {
      userData,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      userData: {
        $set: userData,
      },
      errors: {
        $unset: ['getClientsListError'],
      },
    });
  },

  [userActionsTypes.GET_USER_DATA_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [userActionsTypes.REMOVE_USER_LOG_RECORD](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [userActionsTypes.REMOVE_USER_LOG_RECORD_SUCCESS](state, {
    payload: {
      index,
    },
  }) {
    const { userData } = state;
    const { logs } = userData;
    const updatedLogs = [...logs.slice(0, index), ...logs.slice(index + 1)];

    return update(state, {
      isFetching: {
        $set: false,
      },
      userData: {
        logs: {
          $set: updatedLogs,
        },
      },
      errors: {
        $unset: ['removeUserLogsRecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },
  [userActionsTypes.REMOVE_USER_LOG_RECORD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },
});
