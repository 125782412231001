export const combineDevCenters = (devCenters) => {
  const activeDevCenters = devCenters.filter((devcenter) => devcenter.active);

  const byId = activeDevCenters.reduce((accumulator, devcenter) => {
    // eslint-disable-next-line no-param-reassign
    accumulator[devcenter.devcenterId] = devcenter.name;
    return accumulator;
  }, {});

  const byShortName = activeDevCenters.reduce((accumulator, devcenter) => {
    // eslint-disable-next-line no-param-reassign
    accumulator[devcenter.shortname] = devcenter.devcenterId;
    return accumulator;
  }, {});

  const byIdShortName = activeDevCenters.reduce((accumulator, devcenter) => {
    // eslint-disable-next-line no-param-reassign
    accumulator[devcenter.devcenterId] = devcenter.shortname;
    return accumulator;
  }, {});

  const forSelectShortNames = activeDevCenters.map(({ shortname, devcenterId }) => ({
    label: shortname,
    value: devcenterId,
  }));

  const forSelect = activeDevCenters.map(({ name, devcenterId }) => ({
    label: name,
    value: devcenterId,
  }));

  return {
    byId,
    byShortName,
    byIdShortName,
    forSelectShortNames,
    forSelect,
  };
};
