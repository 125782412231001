export const statusData = {
  items: [
    {
      value: false,
      label: 'Unsigned',
    },
    {
      value: true,
      label: 'Signed',
    },
  ],
  selected: {
    value: false,
    label: 'Unsigned',
  },
};

export const statusDataForSales = {
  items: [],
  selected: {
    value: false,
    label: 'Unsigned',
  },
};

export const ratesBySeniority = {
  Senior: 'seniorRates',
  Staff: 'staffRates',
  Junior: 'juniorRates',
};
