import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import Filter from 'components/filter';
import Controls from 'components/list-controls';
import ListFooter from 'components/list-footer';
import ClientsTable from 'components/table';
import ToastContainer from 'components/toast';
import GoTop from 'elements/go-top-button';
import Wrapper from 'elements/wrapper';
import { withRouter } from 'react-router';
import { autoScrollToTop } from 'utils/react';

const ChannelPartnersList = ({
  location,
  channelPartnersData,
  changeOrder,
  orderRules,
  isFetchingChannelPartnersList,
  changeCurrentModal,
  changeFilter,
  filterConfig,
  hasActiveFilters,
  resetFilters,
  isFilteringChannelPartnersList,
  getChannelPartnersList,
  channelPartnersListModel,
  hasPermissionsCreateChannelPartner,
  channelPartnersCount,
  entityName,
}) => {
  autoScrollToTop(location);
  // eslint-disable-next-line react/prop-types
  const { rules: { css }, tableName, noDataMessage } = channelPartnersListModel;
  const currentOrderRules = orderRules[tableName];
  const setOrder = ({ orderBy }) => {
    changeOrder({ orderBy, tableName, entityName });
  };

  useEffect(() => {
    getChannelPartnersList();

    return () => {
      // We are going to reset filters on layout unmount.
      resetFilters();
    };
  }, []);

  const unitActions = {
    changeCurrentModal,
  };

  return (
    <>
      <ToastContainer containerId={entityName} />
      <Controls
        title="Channel Partners"
        hasActiveFilters={hasActiveFilters}
        changeFilter={changeFilter}
        resetFilters={resetFilters}
        controlsList={[
          {
            route: '/channel-partners/add',
            title: 'Payroll Report',
            hasPermissions: hasPermissionsCreateChannelPartner,
          },
        ]}
        className="clients_controls"
      >
        <Filter
          cssRules={css}
          filterConfig={filterConfig}
          changeFilter={changeFilter}
        />
      </Controls>

      <Wrapper
        isGrow
      >
        <ClientsTable
          withInfiniteScroll
          data={channelPartnersData}
          changeOrder={setOrder}
          model={channelPartnersListModel}
          unitActions={unitActions}
          noDataMessage={noDataMessage}
          withFilter={hasActiveFilters}
          orderRules={currentOrderRules}
          isLoaded={!isFetchingChannelPartnersList && !isFilteringChannelPartnersList}
        />

      </Wrapper>

      <ListFooter
        filteredItemsCount={channelPartnersData.length}
        itemsCount={channelPartnersCount}
        isFetchingItems={isFetchingChannelPartnersList}
      />

      <GoTop />

    </>
  );
};

ChannelPartnersList.propTypes = {
  location: PropTypes.object.isRequired,
  entityName: PropTypes.string.isRequired,
  hasPermissionsCreateChannelPartner: PropTypes.bool.isRequired,
  channelPartnersListModel: PropTypes.shape({}).isRequired,
  getChannelPartnersList: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  hasActiveFilters: PropTypes.bool.isRequired,
  changeFilter: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  isFetchingChannelPartnersList: PropTypes.bool.isRequired,
  isFilteringChannelPartnersList: PropTypes.bool.isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  channelPartnersData: PropTypes.array,
  filterConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  channelPartnersCount: PropTypes.number.isRequired,
};

ChannelPartnersList.defaultProps = {
  channelPartnersData: [],
};

export default withRouter(ChannelPartnersList);
