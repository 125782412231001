import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const Month = ({
  monthConfig,
  onSelectDate,
  showRestDays,
  isWeekPicker,
}) => {
  const { month, weekdaysShort } = monthConfig;

  return (
    <div className="calendar-date-picker__month">
      <div className="calendar-date-picker__week">
        {
          weekdaysShort.map((weekdayShort) => (
            <div
              key={weekdayShort}
              className="calendar-date-picker__week-title"
            >
              {weekdayShort}
            </div>
          ))
        }
      </div>
      {
        month.map(({ days, isWeekSelected }, index) => (
          <div
            key={index} // eslint-disable-line react/no-array-index-key
            className={classNames(
              'calendar-date-picker__week',
              { 'calendar-date-picker__week--with-hover': isWeekPicker },
              { 'calendar-date-picker__week--selected': isWeekPicker && isWeekSelected }
            )}
          >
            {
              days.map(({ value, isRestDay, isNow, isSelected, isNeighboringMonth }) => (
                <button
                  key={value}
                  className={classNames(
                    'calendar-date-picker__cell',
                    'calendar-date-picker__cell--day',
                    { 'calendar-date-picker__cell--now': isNow },
                    { 'calendar-date-picker__cell--without-hover': isWeekPicker },
                    { 'calendar-date-picker__cell--rest-day': showRestDays && isRestDay },
                    { 'calendar-date-picker__cell--selected': !isWeekPicker && isSelected },
                    { 'calendar-date-picker__cell--inactive': isNeighboringMonth },
                  )}
                  type="button"
                  onClick={() => onSelectDate(value)}
                >
                  {value.date()}
                </button>
              ))
            }
          </div>
        ))
      }
    </div>
  );
};

Month.propTypes = {
  monthConfig: PropTypes.shape({
    weekdayShort: PropTypes.arrayOf(PropTypes.string),
    month: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      isRestDay: PropTypes.bool,
      isNow: PropTypes.bool,
      isSelected: PropTypes.bool,
    })),
  }).isRequired,
  isWeekPicker: PropTypes.bool.isRequired,
  showRestDays: PropTypes.bool.isRequired,
  onSelectDate: PropTypes.func.isRequired,
};

export default Month;
