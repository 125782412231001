import { createAction } from 'redux-actions';

import { createTypes, async } from 'utils/helpers/actions';

import { clientRateCardUpdateNotifications } from './client-rate-card-update-notifications/actions';
import { ENTITY_NAME } from './constants';
import { emailTemplateNotificationsActions } from './email-template-notifications/actions';

export const configurationsActionsTypes = createTypes(
  [
    ...async('GET_RATE_CARDS'),
    ...async('GET_RATE_CARD'),
    ...async('CREATE_RATE_CARD_RECORD'),
    ...async('REMOVE_RATE_CARD_RECORD'),
    ...async('UPDATE_RATE_CARD_RECORD'),
    ...async('GET_EMAIL_TEMPLATES'),
    ...async('GET_EMAIL_TEMPLATE'),
    ...async('CREATE_EMAIL_TEMPLATE_RECORD'),
    ...async('REMOVE_EMAIL_TEMPLATE_RECORD'),
    ...async('UPDATE_EMAIL_TEMPLATE_RECORD'),
    ...async('GET_EXCHANGE_RATES'),
    ...async('UPDATE_EXCHANGE_RATES'),
    ...async('GET_COMMISSION_RATES'),
    ...async('REMOVE_COMMISSION_RECORD'),
    ...async('CREATE_COMMISSION_RECORD'),
    ...async('UPDATE_COMMISSION_RECORD'),
    ...async('GET_HOLIDAYS'),
    ...async('CREATE_HOLIDAYS_RECORD'),
    ...async('REMOVE_HOLIDAYS_RECORD'),
    ...async('GET_HIQO_CONTRACTS'),
    ...async('CREATE_HIQO_CONTRACTS_RECORD'),
    ...async('REMOVE_HIQO_CONTRACTS_RECORD'),
    ...async('UPLOAD_RATECARD_FILE'),
    ...async('UPLOAD_EMAIL_TEMPLATE_FILE'),
    ...async('UPLOAD_EMAIL_TEMPLATE_FILE'),
    ...async('GET_DISCOUNT_SURCHARGE_RATES'),
    ...async('UPDATE_DISCOUNT_SURCHARGE_RATES'),
    'SET_ORDER_RULES',
    'SET_RATE_CARDS_INDEX',
    'SET_EMAIL_TEMPLATES_INDEX',
    'SET_CREATE_MODE',
    'SET_DEVCENTER_FILTER',
    'CLEAR_NOTIFICATIONS_FILTER',
  ],
  ENTITY_NAME
);

export const configurationsActions = {
  getExchangeRates: createAction(configurationsActionsTypes.GET_EXCHANGE_RATES),

  getExchangeRatesSuccess: createAction(
    configurationsActionsTypes.GET_EXCHANGE_RATES_SUCCESS,
    ({ exchangeRates }) => ({ exchangeRates })
  ),

  getExchangeRatesFail: createAction(
    configurationsActionsTypes.GET_EXCHANGE_RATES_FAIL,
    ({ error = {} }) => ({ error })
  ),

  updateExchangeRates: createAction(
    configurationsActionsTypes.UPDATE_EXCHANGE_RATES,
    (payload) => payload
  ),

  updateExchangeRatesSuccess: createAction(
    configurationsActionsTypes.UPDATE_EXCHANGE_RATES_SUCCESS,
    (payload) => payload
  ),

  updateExchangeRatesFail: createAction(
    configurationsActionsTypes.UPDATE_EXCHANGE_RATES_FAIL,
    ({ error }) => ({
      error,
    })
  ),

  getCommissionRates: createAction(configurationsActionsTypes.GET_COMMISSION_RATES),

  getCommissionRatesSuccess: createAction(
    configurationsActionsTypes.GET_COMMISSION_RATES_SUCCESS,
    ({ commissionRates }) => ({ commissionRates })
  ),

  getCommissionRatesFail: createAction(
    configurationsActionsTypes.GET_COMMISSION_RATES_FAIL,
    ({ error = {} }) => ({ error })
  ),

  createCommissionRecord: createAction(
    configurationsActionsTypes.CREATE_COMMISSION_RECORD,
    (userId) => userId
  ),

  createCommissionRecordSuccess: createAction(
    configurationsActionsTypes.CREATE_COMMISSION_RECORD_SUCCESS,
    ({ userId, fields }) => ({
      userId,
      fields,
    })
  ),

  createCommissionRecordFail: createAction(
    configurationsActionsTypes.CREATE_COMMISSION_RECORD_FAIL,
    ({ error }) => ({
      error,
    })
  ),

  removeCommissionRecord: createAction(
    configurationsActionsTypes.REMOVE_COMMISSION_RECORD,
    ({ recordId, userId }) => ({ recordId, userId })
  ),

  removeCommissionRecordSuccess: createAction(
    configurationsActionsTypes.REMOVE_COMMISSION_RECORD_SUCCESS,
    ({ recordId, userId }) => ({ recordId, userId }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  removeCommissionRecordFail: createAction(
    configurationsActionsTypes.REMOVE_COMMISSION_RECORD_FAIL,
    ({ error }) => ({
      error,
    })
  ),

  updateCommissionRecord: createAction(
    configurationsActionsTypes.UPDATE_COMMISSION_RECORD,
    (updateData) => updateData
  ),

  updateCommissionRecordSuccess: createAction(
    configurationsActionsTypes.UPDATE_COMMISSION_RECORD_SUCCESS,
    ({ userId, userRecords }) => ({
      userId,
      userRecords,
    })
  ),

  updateCommissionRecordFail: createAction(
    configurationsActionsTypes.UPDATE_COMMISSION_RECORD_FAIL,
    ({ error }) => ({
      error,
    })
  ),

  setOrderRules: createAction(
    configurationsActionsTypes.SET_ORDER_RULES,
    ({ tableName, orderRules }) => ({
      tableName,
      orderRules,
    })
  ),

  getEmailTemplates: createAction(
    configurationsActionsTypes.GET_EMAIL_TEMPLATES,
    ({ date }) => ({ date })
  ),

  getEmailTemplatesSuccess: createAction(
    configurationsActionsTypes.GET_EMAIL_TEMPLATES_SUCCESS,
    ({ emailTemplates, date }) => ({ emailTemplates, date })
  ),

  getEmailTemplatesFail: createAction(
    configurationsActionsTypes.GET_EMAIL_TEMPLATES_FAIL,
    ({ error }) => ({ error })
  ),
  createEmailTemplateRecord: createAction(
    configurationsActionsTypes.CREATE_EMAIL_TEMPLATE_RECORD,
    (emailTemplate) => emailTemplate
  ),
  createEmailTemplateRecordSuccess: createAction(
    configurationsActionsTypes.CREATE_EMAIL_TEMPLATE_RECORD_SUCCESS,
    ({ emailTemplate }) => ({ emailTemplate })
  ),
  createEmailTemplateRecordFail: createAction(
    configurationsActionsTypes.CREATE_EMAIL_TEMPLATE_RECORD_FAIL,
    ({ error }) => ({ error })
  ),

  deleteEmailTemplateRecord: createAction(
    configurationsActionsTypes.REMOVE_EMAIL_TEMPLATE_RECORD,
    ({ emailTemplateId }) => ({ emailTemplateId })
  ),
  deleteEmailTemplateRecordSuccess: createAction(
    configurationsActionsTypes.REMOVE_EMAIL_TEMPLATE_RECORD_SUCCESS,
    ({ emailTemplateId }) => ({ emailTemplateId }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  deleteEmailTemplateRecordFail: createAction(
    configurationsActionsTypes.REMOVE_EMAIL_TEMPLATE_RECORD_FAIL,
    ({ error }) => ({
      error,
    })
  ),

  updateEmailTemplateRecord: createAction(
    configurationsActionsTypes.UPDATE_EMAIL_TEMPLATE_RECORD,
    (emailTemplate) => emailTemplate
  ),
  updateEmailTemplateRecordSuccess: createAction(
    configurationsActionsTypes.UPDATE_EMAIL_TEMPLATE_RECORD_SUCCESS,
    ({ emailTemplate }) => ({ emailTemplate })
  ),
  updateEmailTemplateRecordFail: createAction(
    configurationsActionsTypes.UPDATE_EMAIL_TEMPLATE_RECORD_FAIL,
    ({ error }) => ({ error })
  ),

  getRateCards: createAction(
    configurationsActionsTypes.GET_RATE_CARDS,
    ({ date, billingAgents }) => ({ date, billingAgents })
  ),

  getRateCardsSuccess: createAction(
    configurationsActionsTypes.GET_RATE_CARDS_SUCCESS,
    ({ rateCards, billingAgents, date, lastRates }) => ({
      rateCards,
      billingAgents,
      date,
      lastRates,
    })
  ),

  getRateCardsFail: createAction(
    configurationsActionsTypes.GET_RATE_CARDS_FAIL,
    ({ error }) => ({ error })
  ),
  getRateCard: createAction(
    configurationsActionsTypes.GET_RATE_CARD,
    ({ ratecardId }) => ({ ratecardId })
  ),

  getRateCardSuccess: createAction(
    configurationsActionsTypes.GET_RATE_CARD_SUCCESS,
    ({ rateCard }) => ({ rateCard })
  ),

  getRateCardFail: createAction(
    configurationsActionsTypes.GET_RATE_CARD_FAIL,
    ({ error }) => ({ error })
  ),
  createRateCardRecord: createAction(
    configurationsActionsTypes.CREATE_RATE_CARD_RECORD,
    ({ rateCard, rates, year }) => ({ rateCard, rates, year })
  ),
  createRateCardRecordSuccess: createAction(
    configurationsActionsTypes.CREATE_RATE_CARD_RECORD_SUCCESS,
    ({ rateCards, lastRatesVersion }) => ({ rateCards, lastRatesVersion })
  ),
  createRateCardRecordFail: createAction(
    configurationsActionsTypes.CREATE_RATE_CARD_RECORD_FAIL,
    ({ error }) => ({ error })
  ),

  deleteRateCardRecord: createAction(
    configurationsActionsTypes.REMOVE_RATE_CARD_RECORD,
    ({ recordId, isActive, year }) => ({ recordId, isActive, year })
  ),
  deleteRateCardRecordSuccess: createAction(
    configurationsActionsTypes.REMOVE_RATE_CARD_RECORD_SUCCESS,
    ({ recordId, lastRates }) => ({ recordId, lastRates }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  deleteRateCardRecordFail: createAction(
    configurationsActionsTypes.REMOVE_RATE_CARD_RECORD_FAIL,
    ({ error }) => ({
      error,
    })
  ),

  updateRateCardRecord: createAction(
    configurationsActionsTypes.UPDATE_RATE_CARD_RECORD,
    ({ rateCard, rates, year }) => ({ rateCard, rates, year })
  ),
  updateRateCardRecordSuccess: createAction(
    configurationsActionsTypes.UPDATE_RATE_CARD_RECORD_SUCCESS,
    ({ rateCards, lastRates }) => ({ rateCards, lastRates })
  ),
  updateRateCardRecordFail: createAction(
    configurationsActionsTypes.UPDATE_RATE_CARD_RECORD_FAIL,
    ({ error }) => ({ error })
  ),

  setRateCardsActiveIndex: createAction(
    configurationsActionsTypes.SET_RATE_CARDS_INDEX,
    ({ activeIndex }) => ({ activeIndex })
  ),
  setEmailTemplatesActiveIndex: createAction(
    configurationsActionsTypes.SET_EMAIL_TEMPLATES_INDEX,
    ({ activeIndex }) => ({ activeIndex })
  ),

  setCreateMode: createAction(
    configurationsActionsTypes.SET_CREATE_MODE,
    ({ createMode }) => ({ createMode })
  ),
  getHolidays: createAction(
    configurationsActionsTypes.GET_HOLIDAYS,
    ({ date, deliveryCenters }) => ({ date, deliveryCenters })
  ),
  getHolidaysSuccess: createAction(
    configurationsActionsTypes.GET_HOLIDAYS_SUCCESS,
    ({ holidays, date }) => ({ holidays, date })
  ),
  getHolidaysFail: createAction(
    configurationsActionsTypes.GET_HOLIDAYS_FAIL,
    ({ error }) => ({ error })
  ),
  setDevcenterFilter: createAction(
    configurationsActionsTypes.SET_DEVCENTER_FILTER,
    ({ devcenterList }) => ({ devcenterList })
  ),
  createHolidays: createAction(
    configurationsActionsTypes.CREATE_HOLIDAYS_RECORD,
    ({ fields }) => ({ fields })
  ),
  createHolidaysSuccess: createAction(
    configurationsActionsTypes.CREATE_HOLIDAYS_RECORD_SUCCESS,
    ({ holidays }) => ({ holidays })
  ),
  createHolidaysFail: createAction(
    configurationsActionsTypes.CREATE_HOLIDAYS_RECORD_FAIL,
    ({ error }) => ({ error })
  ),
  removeHolidaysRecord: createAction(
    configurationsActionsTypes.REMOVE_HOLIDAYS_RECORD,
    ({ recordId }) => ({ recordId })
  ),
  removeHolidaysRecordSuccess: createAction(
    configurationsActionsTypes.REMOVE_HOLIDAYS_RECORD_SUCCESS,
    ({ recordId }) => ({ recordId }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  removeHolidaysRecordFail: createAction(
    configurationsActionsTypes.REMOVE_HOLIDAYS_RECORD_FAIL,
    ({ error }) => ({
      error,
    })
  ),

  uploadRatecardFile: createAction(
    configurationsActionsTypes.UPLOAD_RATECARD_FILE,
    ({ file, ratecardId, title, year, billingAgents }) => ({
      file,
      ratecardId,
      title,
      year,
      billingAgents,
    }),
    ({ entityName }) => ({
      entityName,
    })
  ),
  uploadRatecardFileSuccess: createAction(configurationsActionsTypes.UPLOAD_RATECARD_FILE_SUCCESS),
  uploadRatecardFileFail: createAction(
    configurationsActionsTypes.UPLOAD_RATECARD_FILE_FAIL,
    ({ error }) => ({
      error,
    })
  ),

  uploadEmailTemplateFile: createAction(
    configurationsActionsTypes.UPLOAD_EMAIL_TEMPLATE_FILE,
    ({ file, title, emailTemplateId }) => ({
      file,
      title,
      emailTemplateId,
    }),
    ({ entityName }) => ({
      entityName,
    })
  ),
  uploadEmailTemplateFileSuccess: createAction(
    configurationsActionsTypes.UPLOAD_EMAIL_TEMPLATE_FILE_SUCCESS,
    ({ emailTemplateId, file, fileId }) => ({
      emailTemplateId,
      file,
      fileId,
    })
  ),
  uploadEmailTemplateFileFail: createAction(
    configurationsActionsTypes.UPLOAD_EMAIL_TEMPLATE_FILE_FAIL,
    ({ error }) => ({
      error,
    })
  ),
  getHiqoContracts: createAction(configurationsActionsTypes.GET_HIQO_CONTRACTS),
  getHiqoContractsSuccess: createAction(
    configurationsActionsTypes.GET_HIQO_CONTRACTS_SUCCESS,
    ({ hiqoContracts }) => ({ hiqoContracts })
  ),
  getHiqoContractsFail: createAction(
    configurationsActionsTypes.GET_HIQO_CONTRACTS_FAIL,
    ({ error }) => ({ error })
  ),
  removeHiqoContractsRecord: createAction(
    configurationsActionsTypes.REMOVE_HIQO_CONTRACTS_RECORD,
    ({ recordId }) => ({ recordId })
  ),
  removeHiqoContractsRecordSuccess: createAction(
    configurationsActionsTypes.REMOVE_HIQO_CONTRACTS_RECORD_SUCCESS,
    ({ recordId }) => ({ recordId })
  ),
  removeHiqoContractsRecordFail: createAction(
    configurationsActionsTypes.REMOVE_HIQO_CONTRACTS_RECORD_FAIL,
    ({ error }) => ({ error })
  ),
  createHiqContractsRecord: createAction(
    configurationsActionsTypes.CREATE_HIQO_CONTRACTS_RECORD,
    ({ fields }) => ({ fields })
  ),
  createHiqContractsRecordSuccess: createAction(
    configurationsActionsTypes.CREATE_HIQO_CONTRACTS_RECORD_SUCCESS,
    ({ hiqoContracts }) => ({ hiqoContracts })
  ),
  createHiqContractsRecordFail: createAction(
    configurationsActionsTypes.CREATE_HIQO_CONTRACTS_RECORD_FAIL,
    ({ error }) => ({ error })
  ),

  getDiscountSurchargeRates: createAction(configurationsActionsTypes.GET_DISCOUNT_SURCHARGE_RATES),

  getDiscountSurchargeRatesSuccess: createAction(
    configurationsActionsTypes.GET_DISCOUNT_SURCHARGE_RATES_SUCCESS,
    ({ discountSurchargeRates, billLockDate }) => ({
      discountSurchargeRates,
      billLockDate,
    })
  ),

  getDiscountSurchargeRatesFail: createAction(
    configurationsActionsTypes.GET_DISCOUNT_SURCHARGE_RATES_FAIL,
    ({ error = {} }) => ({ error })
  ),

  updateDiscountSurchargeRates: createAction(
    configurationsActionsTypes.UPDATE_DISCOUNT_SURCHARGE_RATES,
    (payload) => payload
  ),

  updateDiscountSurchargeRatesSuccess: createAction(
    configurationsActionsTypes.UPDATE_DISCOUNT_SURCHARGE_RATES_SUCCESS,
    ({ discountSurchargeRates, billLockDate }) => ({
      discountSurchargeRates,
      billLockDate,
    })
  ),

  updateDiscountSurchargeRatesFail: createAction(
    configurationsActionsTypes.UPDATE_DISCOUNT_SURCHARGE_RATES_FAIL,
    ({ error }) => ({
      error,
    })
  ),

  clearNotificationsFilter: createAction(configurationsActionsTypes.CLEAR_NOTIFICATIONS_FILTER),
  ...clientRateCardUpdateNotifications,
  ...emailTemplateNotificationsActions,
};
