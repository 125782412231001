export const newComment = ({
  userId,
  fullName,
}) => ({
  commentId: -1,
  commentedByUserId: userId,
  commentedByUserFullname: fullName,
  text: '',
  date: new Date(),
  allowedToEdit: true,
});
