import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import FormUnit from './form-unit';

import { SubGridTemplate } from './styled-components';


const MultiLine = ({
  title,
  form,
  isVisible,
  unit,
}) => (
  <SubGridTemplate
    className="details-form__units-wrapper"
    columnsNumber={get(unit, 'data.length', 1)}
    cssRules={unit.cssRules}
  >
    {
      unit.data.map((subunit, unitIndex) => (
        <FormUnit
          key={`${title}${unit.placeholder}/${unitIndex}`}// eslint-disable-line react/no-array-index-key
          unit={subunit}
          index={unitIndex}
          title={title}
          form={form}
          isVisible={isVisible}
        />
      ))
    }

  </SubGridTemplate>

);


MultiLine.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  unit: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
};

MultiLine.defaultProps = {
  title: '',
};


export default MultiLine;
