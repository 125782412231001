import {
  colorSecondaryText,
  colorSecondaryGrayLight07,
  colorPrimary,
} from 'assets/styles/variables';
import { STORE_KEYS } from 'core/billing-reports/constants';
import { UNITS_TYPES } from 'core/constants';
import hash from 'hash-sum';

import annualReportResourceAverageSalariesConfigGetter from './config/annual-report-average-salaries-table-config';
import annualReportResourceMatrixTableConfigGetter from './config/annual-report-resource-matrix-table-config';

const defaultCurrencyList = [STORE_KEYS.USD, STORE_KEYS.RUR, STORE_KEYS.PLN];

export default ({
  inDepthStudySummaryTab,
  currency,
  isEmpty,
}) => {
  if (isEmpty) {
    return [
      {
        type: UNITS_TYPES.SINGLE_ROW,
        cssRules: `
          display: flex;
          padding: 0 1.6rem;
          font-size: 2.2rem;
        `,
        rowData: [
          {
            type: 'text',
            componentProps: {
              data: 'Summary',
              cssRules: `
                font-size: 2.2rem;
                line-height: 2.4rem;
                color: ${colorSecondaryText};
              `,
            },
          },
        ],
      },
      {
        type: UNITS_TYPES.EMPTY_MESSAGE,
        title: 'Sorry, no content matched your criteria.',
        description: 'Please, change the selected filters.',
      },
    ];
  }

  return [{
    type: UNITS_TYPES.SINGLE_ROW,
    cssRules: `
      display: flex;
      padding: 0 1.6rem;
      font-size: 2.2rem;
      & .checkbox {
        font-family: "Etelka", Arial, sans-serif;
      }
      & .checkbox--active {
        color: ${colorPrimary};

        &:hover {
          color: ${colorPrimary};
        }
      }
    `,
    rowData: [{
      type: 'text',
      componentProps: {
        data: 'Summary',
        cssRules: `
          font-size: 2.2rem;
          line-height: 2.4rem;
          color: ${colorSecondaryText};
        `,
      },
    }, {
      type: 'checkbox',
      componentProps: {
        title: 'in-depth study',
        actionName: 'changeFilter',
        actionArguments: {
          currentFilter: STORE_KEYS.ANNUAL_REPORT_SUMMARY_FILTER,
          storeKey: STORE_KEYS.IN_DEPTH_STUDY,
          type: 'toggle',
        },
        isActive: inDepthStudySummaryTab,
      },
    }],
  }, {
    type: UNITS_TYPES.TABLE,
    content: [{
      idKey: 'id',
      title: 'Seniority Ratio',
      dataKey: 'annualReportSeniorityRatioDataRows',
      tableName: 'annualReportSeniorityRatio',
      preventScrolling: true,
      rowStatusGetter: (row) => {
        const id = hash(row);

        return ({
          id,
        });
      },
      rules: {
        css: {
          gridTemplateConfig: `
            grid-template-columns: minmax(auto, 115fr) repeat(12, minmax(auto, 85fr)) minmax(auto, 105fr);
            & .grid-unit--with-multi-items {
              display: grid;
              padding-right: 0;
              &:last-child {
                padding-right: 1.6rem;
              }
            }
          `,
        },
      },
      dataTemplate: annualReportResourceMatrixTableConfigGetter(),
    }],
  }, {
    type: UNITS_TYPES.TABLE,
    currentFilter: STORE_KEYS.ANNUAL_REPORT_SUMMARY_FILTER,
    content: [{
      idKey: 'id',
      title: 'Seniority Salaries',
      dataKey: 'annualReportSenioritySalariesDataRows',
      tableName: 'annualReportSenioritySalaries',
      preventScrolling: true,
      rowStatusGetter: (row) => {
        const { isTotal } = row;
        const id = hash(row);

        return ({
          id,
          isExtendedInformation: !isTotal,
          stylesTemplate: {
            rowStyles: isTotal && inDepthStudySummaryTab ? `
              && {
                border-color: ${colorSecondaryGrayLight07};
                border-style: solid;
                border-width: 1px 0 1px 0;
              }
            ` : '',
          },
        });
      },
      rules: {
        css: {
          gridTemplateConfig: `
            grid-template-columns: minmax(auto, 150fr) repeat(12, minmax(auto, 85fr)) minmax(auto, 105fr);
            & .grid-unit--with-multi-items {
              display: grid;
              padding-right: 0;
              &:last-child {
                padding-right: 1.6rem;
              }
            }
          `,
        },
      },
      dataTemplate: annualReportResourceAverageSalariesConfigGetter({
        inDepthStudy: inDepthStudySummaryTab,
      }),
    }],
  }, ...(currency.length ? currency : defaultCurrencyList).map((item) => ({
    type: UNITS_TYPES.TABLE,
    content: [{
      idKey: 'id',
      title: `${item.toUpperCase()} based resources`,
      dataKey: `annualReportResourceMatrixByCurrencyDataRows.${item}`,
      tableName: 'annualReportRURBasedResources',
      preventScrolling: true,
      rowStatusGetter: (row) => {
        const id = hash(row);

        return ({
          id,
        });
      },
      rules: {
        css: {
          gridTemplateConfig: `
            grid-template-columns: minmax(auto, 115fr) repeat(12, minmax(auto, 85fr)) minmax(auto, 105fr);
            & .grid-unit--with-multi-items {
              display: grid;
              padding-right: 0;
              &:last-child {
                padding-right: 1.6rem;
              }
            }
          `,
        },
      },
      dataTemplate: annualReportResourceMatrixTableConfigGetter(),
    }],
  }))];
};
