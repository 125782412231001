import React, { useState, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { CleanSmallIcon, MagnifierIcon } from 'assets/svgComponents';
import classNames from 'classnames';
import { ActionButton } from 'components/buttons';
import { useEffectOnlyOnUpdate } from 'hooks';
import {
  debounce,
} from 'lodash';

import styles from './styles.module.scss';

export const Search = ({ name, placeholder, changeFilter, delay, isActive }) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const handleChangeFilter = useCallback((query) => {
    dispatch(changeFilter({
      [name]: {
        value: query,
        isActive: !!query,
      },
    }));
  }, [name]);

  const debouncedInputChange = useCallback(debounce(handleChangeFilter, delay), []);

  const handleInputChange = (event) => {
    const query = event.target.value;

    setSearch(query);
    debouncedInputChange(query);
  };

  const handleInputClear = () => {
    setSearch('');
    handleChangeFilter('');
  };

  useEffectOnlyOnUpdate(() => {
    if (!isActive && search) {
      setSearch('');
    }
  }, [isActive]);

  return (
    <div className={styles.searchContainer}>
      <MagnifierIcon className={styles.magnifierIcon} />
      <input
        type="search"
        className={classNames(styles.searchInput)}
        value={search}
        placeholder={placeholder}
        onChange={handleInputChange}
      />
      {!!search && (
        <ActionButton
          title="Clear Button"
          className={styles.clearButton}
          dataId="clearButton"
          onClick={handleInputClear}
        >
          <CleanSmallIcon />
        </ActionButton>
      )}
    </div>
  );
};

Search.propTypes = {
  name: PropTypes.string.isRequired,
  changeFilter: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  delay: PropTypes.number,
  isActive: PropTypes.bool.isRequired,
};

Search.defaultProps = {
  placeholder: 'Search',
  delay: 200,
};
