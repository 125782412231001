import cyrillicToTranslit from 'cyrillic-to-translit-js';

import { getCurrentMMYYYY, getIsExpired } from './date';

export const generateMSA = (name) => (
  name && cyrillicToTranslit().transform(name).replace(/\W|\d/g, '').length > 1 ?
    `MSA-${getCurrentMMYYYY()}-${cyrillicToTranslit().transform(name).replace(/\W|\d/g, '').slice(0, 2).toUpperCase()}01` :
    ''
);

const incrementMSA = (msa) => {
  const leftPart = msa.replace(/([0-9]{1,2})$/, '');
  const rightPart = msa.replace(leftPart, '');

  const increment = Number(rightPart) + 1;

  if (rightPart) {
    return `${leftPart}${increment > 10 ? increment : `0${increment}`}`;
  }

  return `${leftPart}01`;
};

export const getUniqueMSA = (msa, msaList, name) => {
  const newMsa = !msa || !(/(^MSA-(0?0[1-9]|1[012])\d{4}-([A-Z]{2})(\d{1,37})$)/.test(msa)) ? generateMSA(name) : msa;

  const isUnique = !msaList.some(({ fileName }) => fileName === newMsa);

  return isUnique ? newMsa.replace(/[^\w|\-|!|(|)|'|*|.]/g, '') : getUniqueMSA(incrementMSA(newMsa), msaList);
};

export const getFileStatus = ({ isSigned, isGenerated, effectiveDate }) => {
  if (getIsExpired(effectiveDate)) {
    return `${isSigned ? 'Signed / ' : ''}Expired`;
  }

  if (isSigned) {
    return 'Signed';
  }

  return isGenerated ? 'Generated' : 'Unsigned';
};

const getUniqTitle = (files, prefix, version) => {
  const uniqTitle = `${prefix}-${version > 9 ? '' : '0'}${version}`;
  const isUniq = !files.some(({ title }) => title === uniqTitle);

  return isUniq ? uniqTitle : getUniqTitle(files, prefix, version + 1);
};

export const generateTaskOrderTitle = (files, projectId) => {
  const prefix = `TO-${projectId || '0000'}`;
  const version = 1;

  return getUniqTitle(files, prefix, version);
};

export const openPDF = (binaryData) => {
  const file = new Blob([binaryData], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);

  window.open(fileURL, '_blank');
};

export const compareMsa = (template, clientTemplate) => {
  if (template) {
    const defaultText = template.map((a) => a.sectionTitle).join('\n');
    return defaultText.trim() === clientTemplate.trim() ? 'Default' : 'Edited';
  }
  return 'N/A';
};
