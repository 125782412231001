import React, {
  Fragment,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Calendar from 'components/calendar';
import FilterSelect from 'components/filter-select';
import PageTitle from 'components/page-title';
import { filesActions } from 'core/files/actions';
import GoTop from 'elements/go-top-button';
import StyledWrapper from 'elements/styled-wrapper';
import WrapperNg from 'elements/wrapper-ng';
import { autoScrollToTop } from 'utils/react';
import {
  format as formatDate,
  FORMATS,
} from 'utils/date';

import {
  yearPickerCssRules,
  calendarItemCssRules,
  selectCssRules,
} from './styles';
import './styles.scss';

const getOptionValue = ({ value }) => value;
const getOptionLabel = ({ label }) => label;

const UtilizationForMonthReport = ({
  location,
  devCenters: deliveryCenters,
  generateUtilizationForMonthReport,
}) => {
  autoScrollToTop(location);

  const [date, setDate] = useState(moment());

  const [deliveryCenter, setDeliveryCenter] = useState(undefined);
  const clickPDF = useCallback(
    () => {
      generateUtilizationForMonthReport({
        year: date.year(),
        month: date.month() + 1,
        devcenterIds: deliveryCenter ? deliveryCenter.map((devcenter) => devcenter.value) : Object.keys(deliveryCenters.byId),
      });
    },
    [generateUtilizationForMonthReport, date, deliveryCenter, deliveryCenters]
  );

  const pdfButton = (
    <button
      className="controls__create-button button button--cancel"
      onClick={clickPDF}
    >
      PDF
    </button>
  );

  return (
    <Fragment>
      <PageTitle
        className="utilization-for-month-report__title"
        text="Utilization Report for Month"
      >
        {pdfButton}
      </PageTitle>

      <div className="utilization-for-month-report__filters">
        <WrapperNg>
          <Calendar
            withStepControls
            withMonthSelecting
            minDetails="year"
            onChange={setDate}
            // maxYear={moment().year()}
            // maxMonth={moment().month()}
            stepControlsConfig={{
              step: 1,
              unitName: 'month',
            }}
            cssRules={yearPickerCssRules}
            value={date}
            popperProps={{
              placement: 'bottom-start',
            }}
          >
            <StyledWrapper
              cssRules={calendarItemCssRules}
            >
              {formatDate(date, FORMATS.MMMMYYYY)}
            </StyledWrapper>
          </Calendar>
          <FilterSelect
            items={deliveryCenters.forSelect}
            selected={deliveryCenter}
            onChange={setDeliveryCenter}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            placeholder="All Delivery Centers"
            isMultiple
            placeholderLength="0"
            withChips={false}
            cssRules={selectCssRules}
          />
        </WrapperNg>
      </div>

      <div className="utilization-for-month-report__content">
        <h2>Please select Year and Month and then generate a report.</h2>

        {pdfButton}
      </div>

      <GoTop />
    </Fragment>
  );
};

UtilizationForMonthReport.propTypes = {
  devCenters: PropTypes.shape({}).isRequired,
  location: PropTypes.object.isRequired,
  generateUtilizationForMonthReport: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  generateUtilizationForMonthReport: filesActions.generateUtilizationForMonthReport,
};

export default connect(
  null,
  mapDispatchToProps
)(UtilizationForMonthReport);
