import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import { ENTITY_NAME } from '../constants';

export const {
  GET_TECHNICAL_MENTOR_RECORDS,
  GET_TECHNICAL_MENTOR_RECORDS_SUCCESS,
  GET_TECHNICAL_MENTOR_RECORDS_FAIL,
  CREATE_TECHNICAL_MENTOR_RECORD,
  CREATE_TECHNICAL_MENTOR_RECORD_SUCCESS,
  CREATE_TECHNICAL_MENTOR_RECORD_FAIL,
  DELETE_TECHNICAL_MENTOR_RECORD,
  DELETE_TECHNICAL_MENTOR_RECORD_SUCCESS,
  DELETE_TECHNICAL_MENTOR_RECORD_FAIL,
  ADD_TECHNICAL_MENTOR_RECORD,
  REMOVE_TECHNICAL_MENTOR_RECORD,
  UPDATE_TECHNICAL_MENTOR_RECORD,
  UPDATE_TECHNICAL_MENTOR_RECORD_SUCCESS,
  UPDATE_TECHNICAL_MENTOR_RECORD_FAIL,
} = createTypes([
  ...async('GET_TECHNICAL_MENTOR_RECORDS'),
  ...async('CREATE_TECHNICAL_MENTOR_RECORD'),
  ...async('DELETE_TECHNICAL_MENTOR_RECORD'),
  ...async('UPDATE_TECHNICAL_MENTOR_RECORD'),
  'ADD_TECHNICAL_MENTOR_RECORD',
  'REMOVE_TECHNICAL_MENTOR_RECORD',
], ENTITY_NAME);

export const technicalMentorsActions = {
  getTechnicalMentorRecords: createAction(GET_TECHNICAL_MENTOR_RECORDS),
  getTechnicalMentorRecordsSuccess: createAction(GET_TECHNICAL_MENTOR_RECORDS_SUCCESS),
  getTechnicalMentorRecordsFail: createAction(GET_TECHNICAL_MENTOR_RECORDS_FAIL, (errors) => ({ errors })),

  createTechnicalMentorRecord: createAction(CREATE_TECHNICAL_MENTOR_RECORD),
  createTechnicalMentorRecordSuccess: createAction(CREATE_TECHNICAL_MENTOR_RECORD_SUCCESS, (record) => (record)),
  createTechnicalMentorRecordFail: createAction(CREATE_TECHNICAL_MENTOR_RECORD_FAIL, (errors) => ({ errors })),

  deleteTechnicalMentorRecord: createAction(DELETE_TECHNICAL_MENTOR_RECORD),
  deleteTechnicalMentorRecordSuccess: createAction(DELETE_TECHNICAL_MENTOR_RECORD_SUCCESS, (recordId) => (recordId)),
  deleteTechnicalMentorRecordFail: createAction(DELETE_TECHNICAL_MENTOR_RECORD_FAIL, (errors) => ({ errors })),

  updateTechnicalMentorRecord: createAction(UPDATE_TECHNICAL_MENTOR_RECORD),
  updateTechnicalMentorRecordSuccess: createAction(UPDATE_TECHNICAL_MENTOR_RECORD_SUCCESS, (record) => (record)),
  updateTechnicalMentorRecordFail: createAction(UPDATE_TECHNICAL_MENTOR_RECORD_FAIL, (errors) => ({ errors })),

  removeTechnicalMentorRecord: createAction(REMOVE_TECHNICAL_MENTOR_RECORD),
  addTechnicalMentorRecord: createAction(ADD_TECHNICAL_MENTOR_RECORD, (record) => ({ record })),
};
