/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import Textarea from 'components/autosize-textarea';
import { selectFormState } from 'core/delivery/commentManagement/selectors';
import { TextField } from 'forms/components/text-field';
import {
  ControlsContainer,
} from 'layouts/employee-details/components/resource-tabs/components';

import { get } from 'lodash';

const TEXTS = {
  TEXTAREA_PLACEHOLDER: 'Please enter a contact information here...',
};

export const useListColumns = () => useMemo(() => [
  {
    Header: 'Created By',
    accessor: 'user',
    type: 'text',
    Field: ({
      item,
    }) => (
      <>
        <TextField content={`${item.createdByFullname},`} />
        <TextField content={item.date} />
      </>
    ),
  },
  {
    Header: 'Contact',
    accessor: 'contactInfo',
    type: 'form',
    Field: ({
      field,
      fieldState,
      item,
      reset,
    }) => {
      const error = get(fieldState, 'error');

      return (
        <Textarea
          initialValue={{
            [field.name]: get(item, 'contactInfo', ''),
          }}
          name={field.name}
          rows={1}
          maxLength={5000}
          formValue={field.value}
          placeholder={TEXTS.TEXTAREA_PLACEHOLDER}
          isError={Boolean(error)}
          error={error}
          onChange={field.onChange}
          isDirty={fieldState.isDirty}
          reset={reset}
          isAllowedForEdit={get(item, 'isAllowedToEdit', false)}
        />
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'actions',
    type: 'action',
    Field: ({
      recordId,
      onRemoveRecord,
      isFormDirty,
      resetForm,
      isCreateMode,
      isSubmitting,
      setEditedRecordId,
      isViewMode,
      isAllowedForEdit,
      onDeleteHandler,
    }) => (
      <ControlsContainer
        isAllowedForDelete={isAllowedForEdit}
        isAllowedForEdit={isAllowedForEdit}
        isViewMode={isViewMode}
        onDeleteHandler={onDeleteHandler}
        onRemoveRecord={onRemoveRecord}
        recordId={recordId}
        isFormDirty={isFormDirty}
        resetForm={resetForm}
        isCreateMode={isCreateMode}
        isSubmitting={isSubmitting}
        setEditedRecordId={setEditedRecordId}
        formStateSelector={selectFormState}
      />
    ),
  },
], []);
