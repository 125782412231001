import React from 'react';

import PropTypes from 'prop-types';

import PdfIcon from 'assets/img/icon-pdf.svg';
import ActionButton from 'elements/action-button';
import CustomLink from 'elements/link';

const UploadedFile = ({ file, title, removeFile, allowUpload, withBlank }) => (
  <>
    <CustomLink
      className="dropzone__file"
      href={file}
      data={title}
      imgSrc={PdfIcon}
      withBlank={withBlank}
    />
    {allowUpload && (
      <ActionButton
        data="Remove"
        withStopPropagation
        onClick={removeFile}
      />
    )}
  </>
);

UploadedFile.propTypes = {
  file: PropTypes.string.isRequired,
  title: PropTypes.string,
  removeFile: PropTypes.func,
  allowUpload: PropTypes.bool,
  withBlank: PropTypes.bool,
};

UploadedFile.defaultProps = {
  title: 'SCR.pdf',
  removeFile: () => {},
  allowUpload: false,
  withBlank: false,
};

export default UploadedFile;
