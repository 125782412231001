import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { push } from 'connected-react-router';
import {
  selectHasPermissionsCreateClients,
  selectHasPermissionsTaskOrdersControls,
  selectHasPermissionClientCardHeader,
  selectHasPermissionDocumentGenerate,
  selectHasPermissionDocumentUpload,
} from 'core/auth/selectors';
import { clientsActions } from 'core/clients/actions';
import {
  selectedClientsData,
  selectOrderRules,
  selectIsFetchingClientsList,
  selectFilterConfig,
  selectHasFilters,
  selectAlphabetFilter,
  selectIsFilteringClientsList,
  selectIsFavoredClient,
  selectClientDetails,
  selectIsFetchingDetails,
  selectIsClientDetailsFormSubmitted,
  selectClientsListModel,
  selectClientsCount,
  selectNumberOfClientsFiles,
  selectEntityName,
  selectIsFetchingFiles,
  selectMsaDate,
} from 'core/clients/selectors';
import { commonActions } from 'core/common/actions';
import { filesActions } from 'core/files/actions';
import {
  selectUploadingProgress,
  selectIsFileUploaded,
} from 'core/files/selectors';
import { selectCurrentModal } from 'core/modal-conductor/selectors';
import ClientDetails from 'layouts/client-details';
import ClientsList from 'layouts/clients-list';
import compose from 'lodash/fp/compose';
import { Switch, Route, Redirect, withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

const Clients = (props) => {
  const { entityName, setEntityName } = props;
  useEffect(() => {
    setEntityName(entityName);
  }, [entityName]);

  return (
    <Switch>
      <Route
        exact
        path="/clients"
        render={() => <ClientsList {...props} />}
      />

      <Route
        exact
        path="/clients/add"
        render={() => {
          const { hasPermissionsCreateClients } = props;

          return hasPermissionsCreateClients ? (
            <ClientDetails isNewClient {...props} />
          ) : (
            <Redirect to="/clients" />
          );
        }}
      />

      <Route
        path="/clients/:id/:tab"
        render={({ match: { params } }) => {
          const { id, tab } = params;

          return <ClientDetails clientId={id} tab={tab} {...props} />;
        }}
      />

      <Redirect to="/clients" />
    </Switch>
  );
};

Clients.propTypes = {
  entityName: PropTypes.string.isRequired,
  getClientsList: PropTypes.func.isRequired,
  setEntityName: PropTypes.func.isRequired,
  hasPermissionsCreateClients: PropTypes.bool.isRequired,
  userGroup: PropTypes.string,
  guaranteedAccessRoles: PropTypes.arrayOf(PropTypes.string),
  clearErrors: PropTypes.func.isRequired,
};

Clients.defaultProps = {
  userGroup: '',
  guaranteedAccessRoles: [],
};

const mapStateToProps = createStructuredSelector({
  isFetchingFiles: selectIsFetchingFiles,
  entityName: selectEntityName,
  numberOfClientsFiles: selectNumberOfClientsFiles,
  hasPermissionsCreateClients: selectHasPermissionsCreateClients,
  hasPermissionsTaskOrdersControls: selectHasPermissionsTaskOrdersControls,
  hasPermissionClientCardHeader: selectHasPermissionClientCardHeader,
  hasPermissionDocumentGenerate: selectHasPermissionDocumentGenerate,
  hasPermissionDocumentUpload: selectHasPermissionDocumentUpload,
  isFileUploaded: selectIsFileUploaded,
  alphabetFilter: selectAlphabetFilter,
  clientsData: selectedClientsData,
  orderRules: selectOrderRules,
  isFetchingClientsList: selectIsFetchingClientsList,
  isFilteringClientsList: selectIsFilteringClientsList,
  filterConfig: selectFilterConfig,
  hasActiveFilters: selectHasFilters,
  isFavoredClient: selectIsFavoredClient,
  clientDetails: selectClientDetails,
  isFetchingDetails: selectIsFetchingDetails,
  isClientDetailsFormSubmitted: selectIsClientDetailsFormSubmitted,
  uploadingProgress: selectUploadingProgress,
  clientsListModel: selectClientsListModel,
  currentModal: selectCurrentModal,
  clientsCount: selectClientsCount,
  msaDate: selectMsaDate,
});

const mapDispatchToProps = {
  push,
  uploadFile: filesActions.uploadFile,
  createClient: clientsActions.createClient,
  resetFilters: clientsActions.resetFilters,
  changeFilter: clientsActions.changeFilter,
  setEntityName: commonActions.setEntityName,
  toggleFavorite: clientsActions.toggleFavorite,
  getClientsList: clientsActions.getClientsList,
  generateDocument: clientsActions.generateDocument,
  getClientDetails: clientsActions.getClientDetails,
  updateClientDetails: clientsActions.updateClientDetails,
  selectTaskOrderToCopy: clientsActions.selectTaskOrderToCopy,
  setMsaDate: clientsActions.setMsaDate,
  clearErrors: clientsActions.clearErrors,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Clients);
