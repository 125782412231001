export const DEFAULT_SELECT_OPTION = { value: '', label: '--' };

export const CONFIRM_ACTION_MODAL = 'CONFIRM_ACTION_MODAL';

export const DEFAULT_HRS_DAY = {
  SIX: 6,
  EIGHT: 8,
};

export const DELETE = 'Delete';

export const WORKBOOK_ACTIONS = {
  CREATE: 'createWorkbookRecord',
  UPDATE: 'updateWorkbookRecord',
};

export const GRID_TEMPLATE_COLUMNS_PL_APPROVALS = 'grid-template-columns: minmax(13.4rem, 15rem) minmax(37rem, 40rem) minmax(20rem, 25rem) 8rem minmax(4rem, auto);';
