import { KEY_CODES } from 'core/constants';
import update from 'immutability-helper';
import get from 'lodash/get';
import moment from 'moment';

import buffer from 'utils/buffer';

export const initialStateGetter = ({
  value,
  useOnlyYear = false,
  useOnlyMonth = false,
}) => {
  let day = '';
  let month = '';
  let year = '';
  let momentDate = '';

  if (value) {
    momentDate = moment.isMoment(value) ? value : moment(value);
    day = `00${momentDate.date()}`.slice(-2);
    month = `00${momentDate.month() + 1}`.slice(-2);
    year = `${momentDate.year()}`.slice(-4);
  }

  return ({
    day,
    month,
    year,
    date: momentDate,
    useOnlyYear,
    useOnlyMonth,
    isCalendarOpen: false,
    inputsRefs: {
      dayPickerRef: null,
      yearPickerRef: null,
      monthPickerRef: null,
    },
  });
};

export const mapDispatchToProps = (dispatch) => ({
  setDate: (payload) => dispatch({ type: 'setDate', payload }),
  clearFields: () => dispatch({ type: 'clearFields' }),
  setDay: (payload) => dispatch({ type: 'setDay', payload }),
  setMonth: (payload) => dispatch({ type: 'setMonth', payload }),
  setYear: (payload) => dispatch({ type: 'setYear', payload }),
  setIsCalendarOpen: (payload) => dispatch({ type: 'setIsCalendarOpen', payload }),
  setUseOnlyMonth: (payload) => dispatch({ type: 'setUseOnlyMonth', payload }),
  setUseOnlyYear: (payload) => dispatch({ type: 'setUseOnlyYear', payload }),
  registerInputs: (payload) => dispatch({ type: 'setInputsRefs', payload }),
});

export function reducer(state, {
  type,
  payload,
}) {
  switch (type) {
    case 'setDay':
      return update(state, {
        day: {
          $set: `${payload}`.slice(-2),
        },
      });
    case 'setMonth':
      return update(state, {
        month: {
          $set: `${payload}`.slice(-2),
        },
      });
    case 'setYear':
      return update(state, {
        year: {
          $set: `${payload}`.slice(-4),
        },
      });
    case 'setDate':
      return update(state, {
        isCalendarOpen: {
          $set: false,
        },
        date: {
          $set: payload,
        },
        day: {
          $set: payload ? `00${payload.date()}`.slice(-2) : '',
        },
        month: {
          $set: payload ? `00${payload.month() + 1}`.slice(-2) : '',
        },
        year: {
          $set: payload ? payload.year() : '',
        },
      });
    case 'setIsCalendarOpen':
      return update(state, {
        isCalendarOpen: {
          $set: payload,
        },
      });
    case 'clearFields':
      // eslint-disable-next-line no-case-declarations
      const date = state.date ? moment(state.date) : null;

      return update(state, {
        day: {
          $set: date ? `00${date.date()}`.slice(-2) : '',
        },
        month: {
          $set: date ? `00${date.month() + 1}`.slice(-2) : '',
        },
        year: {
          $set: date ? date.year() : '',
        },
      });
    case 'setUseOnlyMonth':
      return update(state, {
        useOnlyMonth: {
          $set: payload,
        },
      });
    case 'setUseOnlyYear':
      return update(state, {
        useOnlyYear: {
          $set: payload,
        },
      });
    case 'setInputsRefs':
      return update(state, {
        inputsRefs: {
          $set: payload,
        },
      });
    default:
      return state;
  }
}

export const isValidDate = ({
  year, month, day,
}) => (
  (year && year > 0 && String(year).length === 4) &&
  (month && month > 0) &&
  (day && day > 0)
);

export const onInputChange = ({
  value,
  mutation,
  nextTarget,
  nextTargetMutation,
  maxLength,
  limit,
}) => {
  if (Number.isNaN(+value)) {
    return null;
  }

  if (!value) {
    return mutation(value);
  }

  if (value && value.length > maxLength) {
    if (nextTarget) {
      const {
        current,
      } = nextTarget;

      if (!current.value && nextTargetMutation) {
        nextTargetMutation(value.slice(maxLength));
      }

      return current.focus();
    }

    return null;
  }

  if (value && value.length > 1) {
    mutation(limit && value > limit ? limit : value);
    return nextTarget ? nextTarget.current.focus() : null;
  }

  return mutation(limit && value > limit ? limit : value);
};

export const onWrapperKeyDown = ({
  day,
  year,
  month,
  keyCode,
  metaKey,
  setDate,
  ctrlKey,
  closeCalendar,
}) => {
  const { C, V } = KEY_CODES;

  switch (keyCode) {
    case C:
      if (metaKey || ctrlKey) {
        if (isValidDate({ year, month, day })) {
          buffer.setBuffer('date', `${year}/${month}/${day}`);
          document.activeElement.blur();
          closeCalendar();
        }
      }
      break;
    case V:
      if (metaKey || ctrlKey) {
        const bufferedDate = buffer.getBuffer('date');
        if (bufferedDate) {
          setDate(moment(bufferedDate, 'YYYY/MM/DD'));
          document.activeElement.blur();
          closeCalendar();
        }
      }
      break;
    default:
      break;
  }
};

export const onKeyDown = ({
  limit,
  target,
  keyCode,
  mutation,
  nextTarget,
  prevTarget,
  closeCalendar,
}) => {
  const {
    UP,
    ESC,
    LEFT,
    DOWN,
    RIGHT,
    ENTER,
    BACKSPACE,
  } = KEY_CODES;

  switch (keyCode) {
    case ESC:
      mutation('');
      document.activeElement.blur();
      closeCalendar();
      break;
    case ENTER:
      document.activeElement.blur();
      closeCalendar();
      break;
    case RIGHT:
      if (target.value.length === target.selectionStart && nextTarget) {
        nextTarget.current.focus();
      }
      break;
    case LEFT:
      if (target.selectionStart === 0 && prevTarget) {
        prevTarget.current.focus();
      }
      break;
    case UP:
      if (mutation) {
        const value = Number(target.value);
        if (limit) {
          mutation(value < limit ? `0000${value + 1}` : `0000${value}`);
        } else {
          mutation(value ? `0000${value + 1}` : (new Date()).getFullYear());
        }
      }
      break;
    case DOWN:
      if (mutation) {
        const value = Number(target.value);
        mutation(value - 1 > 0 ? `0000${value - 1}` : `0000${value || 1}`);
      }
      break;
    case BACKSPACE:
      if (!target.value && prevTarget) {
        prevTarget.current.focus();
      }
      break;
    default:
      break;
  }
};

export const subscribeToCopyPast = (callback) => {
  document.addEventListener('keydown', callback);
};
export const unsubscribeFromCopyPast = (callback) => {
  document.removeEventListener('keydown', callback);
};

export const getMonthConfig = ({
  holydaysSet,
  currentDate,
  selectedDate,
  displayedDate,
  businessDaysSet,
}) => {
  const startDay = (displayedDate || selectedDate || currentDate)
    .clone()
    .startOf('month')
    .startOf('week');
  const current = startDay.clone();
  const weeks = [];
  const month = [];
  let weeksCounter = 0;

  while (weeksCounter < 6) {
    weeksCounter++;
    const week = {
      isSelected: false,
      number: current.week(),
      date: current.clone().add(1, 'day'),
    };
    let isWeekSelected = false;
    const days = [];

    for (let index = 0; index < 7; index++) {
      const value = current.clone();
      const isNow = currentDate.isSame(value, 'date');
      const isSelected = selectedDate && selectedDate.isSame(value, 'date');
      const isRestDay = !businessDaysSet.has(value.weekday()) || holydaysSet.has(value.format('YYYY-MM-DD'));
      const isNeighboringMonth = !displayedDate.isSame(value, 'month');

      if (isSelected && !isWeekSelected) {
        isWeekSelected = true;
        week.isSelected = true;
      }

      days.push({
        value,
        isNow,
        isRestDay,
        isSelected,
        isNeighboringMonth,

      });
      current.add(1, 'day');
    }
    weeks.push(week);
    month.push({
      week,
      days,
      isWeekSelected,
      weekNumber: current.week(),
    });
  }
  return {
    month,
    weeks,
    weekdaysShort: get(currentDate, '_locale._weekdaysShort'),
  };
};
