import moment from 'moment';

export const newAttachmentRecord = ({
  userId,
  fullName,
}) => ({
  attachmentRecordId: -1,
  attachedByUserId: userId,
  attachedByUserFullname: fullName,
  description: '',
  createdAtDate: moment().format('YYYY-MM-DD'),
  file: null,
  allowedToEdit: true,
  allowedToDelete: true,
});
