import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { Dropzone } from 'components/new-dropzone';
import { getFileNameWithExt } from 'core/delivery/attachmentManagement/utils';

import { get } from 'lodash';

import { AttachmentView } from './attachment-view';

export const Attachment = ({
  onChange,
  file: formFile,
  isAllowedForEdit,
  isAllowedForDelete,
  errorClassName,
  error,
  setError,
  maxFileSize,
}) => {
  const hasFileName = Boolean(get(formFile, 'name', false));

  const onFileChange = useCallback((data) => {
    if (!data) {
      onChange(null);
    } else {
      const { fileName, extension } = getFileNameWithExt(data.name);

      onChange({
        fileData: data,
        name: fileName,
        extension,
      });
    }
  }, [onChange]);

  const handleRemoveFile = () => {
    onFileChange(null);
  };

  if (hasFileName) {
    return (
      <AttachmentView
        hasFileName={hasFileName}
        file={{
          name: `${formFile.name}.${formFile.extension}`,
          downloadUrl: formFile.downloadUrl,
        }}
        extension={formFile.extension}
        onRemoveFile={handleRemoveFile}
        isAllowedForDelete={isAllowedForDelete}
      />
    );
  }

  return (
    <Dropzone
      accept="application/pdf,.docx"
      setFile={onFileChange}
      isAllowedForEdit={isAllowedForEdit}
      isAllowedForDelete={isAllowedForDelete}
      maxSize={maxFileSize}
      errorClassName={errorClassName}
      setError={setError}
      formError={get(error, 'message', '')}
    />
  );
};

Attachment.propTypes = {
  onChange: PropTypes.func.isRequired,
  file: PropTypes.shape({
    extension: PropTypes.string,
    name: PropTypes.string,
    downloadUrl: PropTypes.string,
  }),
  isAllowedForEdit: PropTypes.bool.isRequired,
  isAllowedForDelete: PropTypes.bool.isRequired,
  errorClassName: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
    ref: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  setError: PropTypes.func,
  maxFileSize: PropTypes.number,
};

Attachment.defaultProps = {
  file: null,
  errorClassName: '',
  error: null,
  maxFileSize: 90,
  setError: () => {},
};
