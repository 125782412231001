import React, {
  useCallback,
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as ArrowLeft } from 'assets/img/icon-arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/img/icon-arrow-right.svg';
import classNames from 'classnames';
import moment from 'moment';
import momentTZ from 'moment-timezone';

const propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]),
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
  config: PropTypes.shape({
    step: PropTypes.number.isRequired,
    unitName: PropTypes.string.isRequired,
  }).isRequired,
  setDate: PropTypes.func.isRequired,
};

const defaultProps = {
  date: '',
  minYear: undefined,
  maxYear: undefined,
};

const Buttons = ({
  date,
  minYear,
  maxYear,
  config,
  setDate,
}) => {
  const prevDate = useMemo(
    () => (date || moment()).clone().add(-config.step, config.unitName),
    [date, config],
  );
  const nextDate = useMemo(
    () => (date || moment()).clone().add(config.step, config.unitName),
    [date, config],
  );

  const isPrevDateDisabled = useMemo(
    () => minYear != null && prevDate.year() < minYear,
    [prevDate, minYear],
  );
  const isNextDateDisabled = useMemo(
    () => maxYear != null && nextDate.year() > maxYear,
    [nextDate, maxYear],
  );

  const onPrevClick = useCallback(
    () => {
      if (!isPrevDateDisabled) {
        setDate(prevDate);
      }
    },
    [prevDate, isPrevDateDisabled],
  );
  const onNextClick = useCallback(
    () => {
      if (!isNextDateDisabled) {
        setDate(nextDate);
      }
    },
    [nextDate, isNextDateDisabled],
  );

  return (
    <div className="calendar__buttons">
      <button
        aria-label="arrow-left-button"
        className={classNames(
          'calendar-date-picker__header-control',
          'calendar-date-picker__header-control--arrow',
          { 'calendar-date-picker__header-control--locked': isPrevDateDisabled }
        )}
        onClick={onPrevClick}
        type="button"
      >
        <ArrowLeft className="calendar-date-picker__header-control-image" />
      </button>

      <button
        aria-label="arrow-right-button"
        className={classNames(
          'calendar-date-picker__header-control',
          'calendar-date-picker__header-control--arrow',
          { 'calendar-date-picker__header-control--locked': isNextDateDisabled }
        )}
        onClick={onNextClick}
        type="button"
      >
        <ArrowRight className="calendar-date-picker__header-control-image" />
      </button>
    </div>
  );
};

Buttons.propTypes = propTypes;
Buttons.defaultProps = defaultProps;

export default Buttons;
