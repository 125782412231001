import React from 'react';

import PropTypes from 'prop-types';

import Wrapper from 'elements/wrapper';
import styled from 'styled-components';

import DetailsSingleTab from './single-tab';

import './styles.scss';

const SubGridTemplate = styled.div`
  display: grid;
  grid-template-columns: ${({ columnsNumber }) => '1fr '.repeat(columnsNumber).trim()};
  grid-column-gap: 1.6rem;
`;

const DetailsTabs = ({ indexOfActiveTab, setIndexOfActiveTab, tabHeadings, isCreatMode, currentStep }) => {
  const onClick = (index) => {
    if (isCreatMode) {
      return currentStep >= index ? setIndexOfActiveTab(index) : null;
    }

    return setIndexOfActiveTab(index);
  };

  return (
    <div className="details-tabs" >

      <Wrapper>
        <SubGridTemplate
          columnsNumber={tabHeadings.length}
        >

          {
            tabHeadings.map(({ title, labels, isLocked }, index) => (
              <DetailsSingleTab
                key={title}
                isActive={indexOfActiveTab === index}
                isDisable={isLocked || (isCreatMode && currentStep < index)}
                title={title}
                onClick={() => isLocked ? null : onClick(index)}
                labels={labels}
              />
            ))
          }

        </SubGridTemplate>

      </Wrapper>

    </div>
  );
};


DetailsTabs.propTypes = {
  indexOfActiveTab: PropTypes.number.isRequired,
  isCreatMode: PropTypes.bool,
  currentStep: PropTypes.number,
  setIndexOfActiveTab: PropTypes.func.isRequired,
  tabHeadings: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
    ])),
  })),
};

DetailsTabs.defaultProps = {
  tabHeadings: [],
  isCreatMode: false,
  currentStep: 0,
};


export default DetailsTabs;
