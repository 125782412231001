import update from 'immutability-helper';
import { createReducer } from 'redux-create-reducer';

import { resourceManagementReportTypes } from './actions';
import { ENTITY_NAME } from './constants';

const initialState = {
  errors: {},
  isFetching: true,
  data: null,
  entityName: ENTITY_NAME,
};

export default createReducer(initialState, {
  [resourceManagementReportTypes.GET_REPORT](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },

  [resourceManagementReportTypes.GET_REPORT_SUCCESS](state, { payload }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      data: {
        $set: payload.data,
      },
      errors: {
        $unset: ['resourceManagementReportError'],
      },
    });
  },

  [resourceManagementReportTypes.GET_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
      data: {
        $set: null,
      },
    });
  },
});
