import React, { Children, cloneElement } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.module.scss';

const placeholderText = 'This section currently contains no data. Please change the selected filters.';

export const List = ({
  children,
  isEmpty,
  placeholderStyles,
  listStyles,
}) => {
  if (isEmpty) {
    return (
      <div className={classNames(styles.placeholder, placeholderStyles)}>
        <span>{placeholderText}</span>
      </div>
    );
  }

  return (
    <div data-id="list" className={classNames(styles.list, listStyles)}>
      {Children.map(
        children,
        (
          child,
        ) => cloneElement(
          child,
          { ...child.props, ref: child.ref }
        )
      )}
    </div>
  );
};

List.propTypes = {
  children: PropTypes.node.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  placeholderStyles: PropTypes.string,
  listStyles: PropTypes.string,
};

List.defaultProps = {
  placeholderStyles: '',
  listStyles: '',
};
