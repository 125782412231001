import {
  selectAvailableDevCentersForDd,
  selectCustomers,
  selectDevCenters,
  selectDevCentersBySystemRoleAuditor,
  selectUserGroup,
} from 'core/auth/selectors';
import { internalToListModelGetter } from 'layouts/Internal-to-report/model';
import { annualReportModelGetter } from 'layouts/annual-report/model';
import { billingProjectReportsDataModelGetter } from 'layouts/billing-project-reports/model';
import { currentPayrollReportModelGetter } from 'layouts/current-payroll-report/model';
import { projectLeadsCommissionModelGetter } from 'layouts/project-leads-commission-report/model';
import { salesReportModelGetter } from 'layouts/sales-report/model';
import { summaryReportsModelGetter } from 'layouts/summary-reports/model';
import { flatMap, get, toNumber } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import { getIsExpired } from 'utils/helpers/date';
import { getBusinessRoles } from 'utils/helpers/getBusinessRoles';
import { isAudit } from 'utils/helpers/isAudit';
import { filtersConfigGetter, filtersItemsGetter, modelParser, parseDetails } from 'utils/helpers/models';
import { numberCompareFunction, stringCompareFunction } from 'utils/helpers/sorting';
import { capitalize } from 'utils/string';

import { SENIORITY_FULL, SENIORITY_LEVELS, SENIORITY_SHORT, STORE_CURRENCY_KEYS } from './constants';
import {
  getAnnualReportAverageSalariesDataRows,
  getAnnualReportNumberOfRaisesDataRows,
  getAnnualReportRaisesAverageSalaryDataRows,
  getAnnualReportRaisesDataRows,
  getAnnualReportRaisesPercentageDataRows,
  getAnnualReportResourceMatrixByCurrencyDataRows,
  getAnnualReportResourceMatrixDataRows,
  getAnnualReportSeniorityRatioDataRows,
  getAnnualReportSenioritySalariesDataRows,
  parseAnalysisTab,
} from './utils';

const selectTaskOrdersList = (state) => state.billingReports.taskOrdersList;
const selectLocalState = ({ billingReports = {} }) => billingReports;

export const selectErrors = (state) => state.billingReports.errors;
export const selectFilters = (state) => state.billingReports.filters;
export const selectOrderRules = (state) => state.billingReports.orderRules;
export const selectIsFetching = (state) => state.billingReports.isFetching;
export const selectEntityName = (state) => state.billingReports.entityName;
export const selectReportData = (state) => state.billingReports.reportData;
export const selectSummaryReportType = (state) => state.billingReports.summaryReportType;
export const selectProjectReportType = (state) => state.billingReports.projectReportType;
export const selectCurrentTaskOrder = (state) => state.billingReports.currentTaskOrder;
export const selectBillingSummaryReportsData = (state) => state.billingReports.summaryReport;
export const selectReportsOtherData = (state) => state.billingReports.otherReportsData;
export const selectHolidaysList = (state) => state.billingReports.holidaysList;
export const selectBillingProjectReportsData = (state) => state.billingReports.billingProjectReport;
export const selectIsFetchingTaskOrdersList = (state) => state.billingReports.isFetchingTaskOrdersList;
export const selectIsFetchingSalesList = (state) => state.billingReports.isFetchingSalesList;
export const selectSelectedSalesId = (state) => state.billingReports.salesId;
export const selectEmployeeDevCenters = (state) => state.billingReports.devCenters;
export const selectIsChannelPartnerVersion = (state) => get(state, 'billingReports.taskOrderDetails.client.channelPartnerId', false);

export const selectSelectedDevCenter = createSelector(
  selectReportData,
  ({ devcenters }) => devcenters.length ? devcenters : null,
);

export const selectSelectedReportDate = createSelector(
  selectReportData,
  ({ selectedDate }) => (selectedDate),
);

export const selectActiveFilters = createSelector(
  selectFilters,
  (filters) => Object.entries(filters).reduce((acc, [key, entity]) => {
    // eslint-disable-next-line no-unused-vars
    const activeFilters = Object.entries(entity).filter(([_, {
      isActive,
    }]) => isActive);
    return {
      ...acc,
      [key]: {
        activeFilters,
        hasActiveFilters: !!activeFilters.length,
      },
    };
  }, {})
);

const selectFiltersConfigs = ({
  dataSelector,
  modelSelector,
}) => createSelector(
  selectFilters,
  dataSelector,
  modelSelector,
  (filters, data, { filtersTemplates }) => filtersTemplates.reduce((acc, {
    name,
    storeKey,
    ...rest
  }) => {
    const options = {
      ...rest,
    };
    const currentData = Array.isArray(storeKey) ?
      storeKey.reduce((tablesData, key) => {
        const tableData = get(data, key, []);

        return [...tablesData, ...tableData];
      }, []) :
      get(data, storeKey, []);
    const currentFilter = get(filters, name, []);
    const filterItems = filtersItemsGetter(currentData, options, filters);
    const filterConfig = filtersConfigGetter(currentFilter, filterItems, options);

    return {
      ...acc,
      [name]: filterConfig,
    };
  }, {})
);

/* ************************ summary reports selectors ************************ */

export const selectBillingSummaryReportsModel = createSelector(
  selectUserGroup,
  selectBillingSummaryReportsData,
  selectSummaryReportType,
  selectSelectedReportDate,
  selectAvailableDevCentersForDd,
  summaryReportsModelGetter
);

export const selectBillingSummaryReportsDetails = createSelector(
  selectBillingSummaryReportsData,
  selectBillingSummaryReportsModel,
  selectOrderRules,
  selectFiltersConfigs({
    dataSelector: selectBillingSummaryReportsData,
    modelSelector: selectBillingSummaryReportsModel,
  }),
  selectActiveFilters,
  parseDetails,
);
/* *********************************************************************** */

/* ************************ annual report selectors ************************ */

const selectMonthToRenderForAnnualReport = createSelector(
  selectFilters,
  (filters) => {
    const { isActive, selected } = get(filters, 'annualReportStaffFilter.month');
    const mapCallback = (month) => {
      const momentDate = moment().month(month);

      return {
        name: month,
        number: momentDate.month(),
      };
    };
    return isActive ?
      selected.map(mapCallback).sort((left, right) => left.number - right.number) :
      moment.monthsShort().map(mapCallback);
  }
);

const selectInDepthStudyAnalysisTabInAnnualReport = createSelector(
  selectFilters,
  (filters) => !get(filters, 'annualReportAnalysisFilter.inDepthStudy.isActive')
);

export const selectAnnualReportData = createSelector(
  selectLocalState,
  ({ annualReportData = {} }) => annualReportData
);

const selectedCurrency = createSelector(
  selectAnnualReportData,
  ({ currency }) => currency
);

export const selectCurrentPayrollReportData = createSelector(
  selectLocalState,
  ({ currentPayrollReportData = {} }) => currentPayrollReportData
);

const selectInDepthStudySummaryTabInAnnualReport = createSelector(
  selectFilters,
  (filters) => !get(filters, 'annualReportSummaryFilter.inDepthStudy.isActive')
);

// We need to know whether analysis and summary tabs are empty.

const SENIORITY_WITH_LEVELS = flatMap(Object.values(SENIORITY_SHORT), (seniority) => Object.values(SENIORITY_LEVELS).map((level) => `${seniority}${level}`));

const selectCurrenciesWithDefaults = createSelector(
  selectedCurrency,
  (selectedCurrencies) => selectedCurrencies.length !== 0 ?
    selectedCurrencies :
    Object.values(STORE_CURRENCY_KEYS)
);

export const selectIsAnnualPayrollReportStaffEmpty = createSelector(
  selectReportsOtherData,
  (rootData) => get(rootData, 'annualReportStaffDataRows', []).length === 0
);

export const selectIsAnnualPayrollReportAnalysisEmpty = createSelector(
  selectReportsOtherData,
  selectCurrenciesWithDefaults,
  (rootData, currencies) => {
    const data = get(rootData, 'annualPayrollReportAnalysisTab', null);
    if (data == null) {
      return true;
    }

    const salaries = get(data, 'averageSalariesStats', []);
    const raises = get(data, 'raisesList', []);
    const resources = get(data, 'resourcesStats', []);

    const isSalariesEmpty = salaries.every((salary) => SENIORITY_WITH_LEVELS.every((seniority) => currencies.every((currency) => {
      const value = get(salary, `totalFor${seniority}${currency}`, 0);
      return Number(value) === 0;
    })));
    if (!isSalariesEmpty) {
      return false;
    }

    const isRaisesEmpty = raises.every((raise) => currencies.every((currency) => {
      const value = get(raise, `sumOfRaises${capitalize(currency)}`, 0);
      return Number(value) === 0;
    }));
    if (!isRaisesEmpty) {
      return false;
    }

    const isResourcesEmpty = resources.every((resource) => SENIORITY_WITH_LEVELS.every((seniority) => {
      const value = get(resource, `countOf${seniority}`, 0);
      return Number(value) === 0;
    }));
    if (!isResourcesEmpty) {
      return false;
    }

    return isSalariesEmpty && isRaisesEmpty && isResourcesEmpty;
  }
);

export const selectIsAnnualPayrollReportSummaryEmpty = createSelector(
  selectReportsOtherData,
  selectCurrenciesWithDefaults,
  (rootData, currencies) => {
    const data = get(rootData, 'annualPayrollReportSummaryTab', null);
    if (data == null) {
      return true;
    }

    const resources = get(data, 'currencyBasedResources', []);
    const salaries = get(data, 'senioritySalaries', []);

    const isResourcesEmpty = resources.every((resource) => SENIORITY_WITH_LEVELS.every((seniority) => currencies.every((currency) => {
      const value = get(resource, `countOf${seniority}${currency}`, 0);
      return Number(value) === 0;
    })));
    if (!isResourcesEmpty) {
      return false;
    }

    const isSalariesEmpty = salaries.every((salary) => Object.values(SENIORITY_FULL).every((seniority) => currencies.every((currency) => {
      const value = get(salary, `${seniority}${currency}`, 0);
      return Number(value) === 0;
    })));
    if (!isSalariesEmpty) {
      return false;
    }

    return isResourcesEmpty && isSalariesEmpty;
  }
);

// Common annual report model.

export const selectAnnualReportModel = createSelector(
  selectUserGroup,
  selectMonthToRenderForAnnualReport,
  selectInDepthStudyAnalysisTabInAnnualReport,
  selectInDepthStudySummaryTabInAnnualReport,
  selectedCurrency,
  selectIsAnnualPayrollReportStaffEmpty,
  selectIsAnnualPayrollReportAnalysisEmpty,
  selectIsAnnualPayrollReportSummaryEmpty,
  selectActiveFilters,
  selectAvailableDevCentersForDd,
  annualReportModelGetter,
);

const selectParsedAnnualReportsData = createSelector(
  selectReportsOtherData,
  selectedCurrency,
  (reportsOtherData, currencyList) => {
    const annualPayrollReportAnalysisTab = get(reportsOtherData, 'annualPayrollReportAnalysisTab', {});
    const annualPayrollReportSummaryTab = get(reportsOtherData, 'annualPayrollReportSummaryTab', {});
    const raisesList = get(annualPayrollReportAnalysisTab, 'raisesList', []);
    const resourcesStats = get(annualPayrollReportAnalysisTab, 'resourcesStats', []);
    const averageSalariesStats = get(annualPayrollReportAnalysisTab, 'averageSalariesStats', []);
    const seniorityRatio = get(annualPayrollReportSummaryTab, 'seniorityRatio', []);
    const senioritySalaries = get(annualPayrollReportSummaryTab, 'senioritySalaries', []);
    const currencyBasedResources = get(annualPayrollReportSummaryTab, 'currencyBasedResources', []);
    const parsedData = raisesList.reduce(parseAnalysisTab, {
      totalSumOfRaisesUsd: 0,
      totalSumOfRaisesRur: 0,
      totalSumOfRaisesPln: 0,
      sumOfRaisesUsdDataRows: [],
      sumOfRaisesRurDataRows: [],
      sumOfRaisesPlnDataRows: [],
      totalCountOfRaisesUsd: 0,
      totalCountOfRaisesRur: 0,
      totalCountOfRaisesPln: 0,
      countOfRaisesUsdDataRows: [],
      countOfRaisesRurDataRows: [],
      countOfRaisesPlnDataRows: [],
      raisesAverageSalaryUsdDataRows: [],
      raisesAverageSalaryRurDataRows: [],
      raisesAverageSalaryPlnDataRows: [],
      raisesAverageSalaryTotalDataRows: [],
      averageUsdRaisePercentageDataRows: [],
      averageRurRaisePercentageDataRows: [],
      averagePlnRaisePercentageDataRows: [],
    });
    const annualReportRaisesDataRows = raisesList.length ? getAnnualReportRaisesDataRows(parsedData, currencyList) : null;
    const annualReportNumberOfRaisesDataRows = raisesList.length ? getAnnualReportNumberOfRaisesDataRows(parsedData, currencyList) : null;
    const annualReportResourceMatrixDataRows = resourcesStats.length ? getAnnualReportResourceMatrixDataRows(resourcesStats, currencyList) : null;
    const annualReportRaisesPercentageDataRows = raisesList.length ? getAnnualReportRaisesPercentageDataRows(parsedData, currencyList) : null;
    const annualReportRaisesAverageSalaryDataRows = raisesList.length ? getAnnualReportRaisesAverageSalaryDataRows(parsedData, currencyList) : null;
    const annualReportAverageSalariesDataRows = averageSalariesStats.length ? getAnnualReportAverageSalariesDataRows(averageSalariesStats, currencyList) : null;
    const annualReportSeniorityRatioDataRows = seniorityRatio.length ? getAnnualReportSeniorityRatioDataRows(seniorityRatio) : null;
    const annualReportSenioritySalariesDataRows = senioritySalaries.length ? getAnnualReportSenioritySalariesDataRows(senioritySalaries, currencyList) : null;
    const annualReportResourceMatrixByCurrencyDataRows = senioritySalaries.length ? getAnnualReportResourceMatrixByCurrencyDataRows(currencyBasedResources, currencyList) : null;

    return {
      ...reportsOtherData,
      annualReportRaisesDataRows,
      annualReportNumberOfRaisesDataRows,
      annualReportSeniorityRatioDataRows,
      annualReportResourceMatrixDataRows,
      annualReportAverageSalariesDataRows,
      annualReportRaisesPercentageDataRows,
      annualReportSenioritySalariesDataRows,
      annualReportRaisesAverageSalaryDataRows,
      annualReportResourceMatrixByCurrencyDataRows,
    };
  }
);

export const selectCurrentPayrollReportModel = createSelector(
  selectUserGroup,
  selectMonthToRenderForAnnualReport,
  selectInDepthStudyAnalysisTabInAnnualReport,
  selectInDepthStudySummaryTabInAnnualReport,
  selectedCurrency,
  selectIsAnnualPayrollReportStaffEmpty,
  selectIsAnnualPayrollReportAnalysisEmpty,
  selectIsAnnualPayrollReportSummaryEmpty,
  selectActiveFilters,
  currentPayrollReportModelGetter
);

export const selectCurrentPayrollReportDetails = createSelector(
  selectParsedAnnualReportsData,
  selectCurrentPayrollReportModel,
  selectOrderRules,
  selectFiltersConfigs({
    dataSelector: selectReportsOtherData,
    modelSelector: selectCurrentPayrollReportModel,
  }),
  selectActiveFilters,
  parseDetails,
);
/* *********************** Currentpayroll selectors ********************** */

export const selectAnnualReportDetails = createSelector(
  selectParsedAnnualReportsData,
  selectAnnualReportModel,
  selectOrderRules,
  selectFiltersConfigs({
    dataSelector: selectReportsOtherData,
    modelSelector: selectAnnualReportModel,
  }),
  selectActiveFilters,
  parseDetails,
);

/* *********************************************************************** */

/* *********************************************************************** */

/* ************************ sales report selectors ************************ */

export const selectSalesReportModel = createSelector(
  selectUserGroup,
  salesReportModelGetter,
);

export const selectSalesReportDetails = createSelector(
  selectBillingSummaryReportsData,
  selectSalesReportModel,
  selectOrderRules,
  selectFiltersConfigs({
    dataSelector: selectBillingSummaryReportsData,
    modelSelector: selectSalesReportModel,
  }),
  selectActiveFilters,
  parseDetails,
);

/* *********************************************************************** */

/* ************************ project leads commission report selectors ************************ */
const selectParsedBillingSummaryReportsData = createSelector(
  selectBillingSummaryReportsData,
  (summaryReportsData) => {
    const plCommissionReportRows = get(summaryReportsData, 'plCommissionReportRows', []);
    const isEmptyRows = !plCommissionReportRows.length;
    const groupedPlCommissionReportRows = plCommissionReportRows.reduce((acc, row) => {
      const key = get(row, 'plName');
      const group = get(acc, key, {
        key,
        rows: [],
        label: get(row, 'plFullname'),
      });
      group.rows.push(row);
      return {
        ...acc,
        [key]: group,
      };
    }, {});

    return {
      ...summaryReportsData,
      groupedPlCommissionReportRows,
      isEmptyRows,
    };
  }
);

export const selectProjectLeadsCommissionReportModel = createSelector(
  selectUserGroup,
  selectParsedBillingSummaryReportsData,
  projectLeadsCommissionModelGetter,
);

export const selectProjectLeadsCommissionDetails = createSelector(
  selectParsedBillingSummaryReportsData,
  selectProjectLeadsCommissionReportModel,
  selectOrderRules,
  selectFiltersConfigs({
    dataSelector: selectParsedBillingSummaryReportsData,
    modelSelector: selectProjectLeadsCommissionReportModel,
  }),
  selectActiveFilters,
  parseDetails,
);

/* *********************************************************************** */

export const selectTaskOrderDetails = (state) => state.billingReports.taskOrderDetails;

export const selectParsedTaskOrderDetails = createSelector(
  selectTaskOrderDetails,
  ({
    clientCategories = [],
    ...rest
  }) => ({
    ...rest,
    clientCategories: clientCategories.filter(({
      isAssigned,
    }) => isAssigned),
  })
);

const selectParsedProjectReportsData = createSelector(
  selectBillingProjectReportsData,
  (billingProjectReportsData) => {
    const worklogReportRows = get(billingProjectReportsData, 'worklogReportRows', []);
    const nonBillableWorklogReportRows = get(billingProjectReportsData, 'nonBillableWorklogReportRows', []);
    const projectRows = get(billingProjectReportsData, 'projectRows', []);
    const overtimeProjectRows = get(billingProjectReportsData, 'overtimeProjectRows', []);

    const projectRowsInTO = [];
    const projectRowsNotInTO = [];
    const overtimeProjectRowsInTO = [];
    const overtimeProjectRowsNotInTo = [];

    projectRows.forEach((row) => {
      const {
        isDevstaffNotInTo,
      } = row;
      if (isDevstaffNotInTo) {
        projectRowsNotInTO.push(row);
      } else {
        projectRowsInTO.push(row);
      }
    });

    overtimeProjectRows.forEach((row) => {
      const {
        isDevstaffNotInTo,
      } = row;
      if (isDevstaffNotInTo) {
        overtimeProjectRowsNotInTo.push(row);
      } else {
        overtimeProjectRowsInTO.push(row);
      }
    });

    const groupWorkLogs = (rows) => {
      const results = {};

      rows.forEach((log) => {
        const {
          devstaffId,
          fullname,
          overtime,
          hours,
        } = log;
        const {
          worklogRows,
          overtime: storedOvertime,
          hours: storedHours,
          ...additional
        } = get(results, devstaffId, {
          worklogRows: [],
          label: fullname,
          overtime: 0,
          devstaffId,
          hours: 0,
        });

        results[devstaffId] = {
          ...additional,
          overtime: toNumber(overtime) + storedOvertime,
          hours: toNumber(hours) + storedHours,
          worklogRows: [...worklogRows, log],
        };
      });

      return Object.values(results)
        .sort((a, b) => stringCompareFunction(a.fullname, b.fullname));
    };
    const hasReportRows = !!(projectRows.length || overtimeProjectRows.length);

    return {
      ...billingProjectReportsData,
      worklogReportRows: groupWorkLogs(worklogReportRows),
      nonBillableWorklogReportRows: groupWorkLogs(nonBillableWorklogReportRows),
      projectRows,
      hasReportRows,
      projectRowsInTO,
      projectRowsNotInTO,
      overtimeProjectRows,
      overtimeProjectRowsInTO,
      overtimeProjectRowsNotInTo,
    };
  }
);

export const selectSortedHolidaysList = createSelector(
  selectHolidaysList,
  (holidays) => holidays.sort((a, b) => {
    const left = moment(a.date);
    const right = moment(b.date);

    return left.isBefore(right) ? -1 : 1;
  })
);

export const filteredHolidaysList = createSelector(
  selectSortedHolidaysList,
  selectReportData,
  selectDevCenters,
  selectEmployeeDevCenters,
  (holidays, reportData, devcenters, employeeDevcenters) => {
    const {
      selectedDate,
    } = reportData;

    const formattedSelectedDate = moment(selectedDate);
    const selectedYear = formattedSelectedDate.year();
    const selectedMonth = formattedSelectedDate.month();

    const employeeHolidays = holidays.filter(({ date, devcenter }) => {
      const holidayDate = moment(date);
      const isSameYear = holidayDate.year() === selectedYear;
      const isSameMonth = holidayDate.month() === selectedMonth;
      const devcenterString = devcenter.toString();

      return employeeDevcenters.has(devcenters.byIdShortName[devcenterString]) && isSameYear && isSameMonth;
    });

    const mappedHolidaysByDevcenter = employeeHolidays.reduce((acc, holiday) => {
      const {
        devcenter,
      } = holiday;
      const devcenterShortName = devcenters.byIdShortName[devcenter].toString();

      if (!acc.has(devcenterShortName)) {
        acc.set(devcenterShortName, []);
      }

      acc.get(devcenterShortName).push({ ...holiday, devcenterShortName });

      return acc;
    }, new Map());

    return {
      mappedHolidaysByDevcenter,
      month: selectedMonth + 1,
      year: selectedYear,
    };
  }
);

export const selectBillingProjectReportsDataModel = createSelector(
  selectUserGroup,
  selectTaskOrderDetails,
  selectParsedProjectReportsData,
  filteredHolidaysList,
  selectAvailableDevCentersForDd,
  (userGroup, taskOrderDetails, billingProjectReportsData, holidaysList, availableDevCentersForDd) => billingProjectReportsDataModelGetter(userGroup, taskOrderDetails, billingProjectReportsData, holidaysList, availableDevCentersForDd)
);

export const selectBillingProjectReportsDetails = createSelector(
  selectParsedTaskOrderDetails,
  selectParsedProjectReportsData,
  selectOrderRules,
  selectBillingProjectReportsDataModel,
  (taskOrderDetails, billingProjectReportsData, projectOrderRules, dashboardDataModel) => parseDetails({
    ...taskOrderDetails,
    ...billingProjectReportsData,
  }, dashboardDataModel, projectOrderRules)
);

const selectSalesList = (state) => state.billingReports.salesList;

export const selectSalesData = createSelector(
  selectSalesList,
  (salesList) => {
    const list = [];
    const map = {};

    if (Array.isArray(salesList)) {
      salesList
        .forEach(({
          label,
          value,
        }) => {
          list.push({
            label,
            value,
          });
          map[value] = {
            label,
            value,
          };
        });
    }
    return {
      list,
      map,
    };
  }
);

export const selectSortedTaskOrdersData = createSelector(
  selectTaskOrdersList,
  (taskOrdersList = []) => {
    const list = [];
    const map = {};
    if (Array.isArray(taskOrdersList)) {
      taskOrdersList
        .sort((a, b) => numberCompareFunction(a.taskOrderId, b.taskOrderId, true))
        .forEach(({
          isActive,
          taskOrderId,
          title,
          value,
          endDate,
          isManuallyDeactivated,
        }) => {
          if (isActive) {
            const label = `TO ${taskOrderId} - ${title}`;
            const isExpired = getIsExpired(endDate);

            list.push({
              label,
              value,
              isActive: !isManuallyDeactivated && !isExpired,
            });
            map[value] = label;
          }
        });
    }
    return {
      list,
      map,
    };
  }
);

const selectInternalToListData = (state) => state.billingReports.internalToList;
const selectContracts = (state) => state.billingReports.contracts;

export const selectInternalToListModel = createSelector(
  selectUserGroup,
  selectDevCenters,
  selectCustomers,
  selectContracts,
  (userGroup, devcenters, customers, contracts) => (internalToListModelGetter(userGroup, devcenters.byIdShortName, customers, contracts))
);

export const selectInternalToData = createSelector(
  selectUserGroup,
  selectInternalToListData,
  selectOrderRules,
  selectActiveFilters,
  selectInternalToListModel,
  selectDevCentersBySystemRoleAuditor,
  (
    userGroup,
    internalToData,
    orderRules,
    filters,
    {
      tableName,
      dataTemplate,
      filtersTemplate,
      rowStatusGetter,
    },
    devCentersBySystemRole
  ) => {
    const options = {
      orderRules: orderRules[tableName],
      filters: filters.internalToFilter.activeFilters,
      idKey: 'clientId',
      tableName,
      rowStatusGetter,
      filtersTemplate,
    };

    if (isAudit(userGroup)) {
      const internalToDataForBy = internalToData.filter(({ devcenter }) => getBusinessRoles(devCentersBySystemRole, devcenter).includes(userGroup));

      const {
        data,
      } = modelParser(internalToDataForBy, [...dataTemplate.slice(0, 2), ...dataTemplate.slice(3)], options);
      return data;
    }

    const {
      data,
    } = modelParser(internalToData, dataTemplate, options);

    return data;
  }
);

const selectInternalToReportFilters = createSelector(
  selectFilters,
  (filters) => get(filters, 'internalToFilter')
);

const selectFilterItems = createSelector(
  selectInternalToListData,
  selectInternalToListModel,
  selectInternalToReportFilters,
  filtersItemsGetter,
);

export const selectFilterConfig = createSelector(
  selectInternalToReportFilters,
  selectFilterItems,
  selectInternalToListModel,
  filtersConfigGetter,
);

export const selectHasInternalToFilters = createSelector(
  selectActiveFilters,
  (filters) => !!filters.internalToFilter.activeFilters.length
);
