import { resourceContactManagementAccess } from 'core/auth/guaranteedAccessRoles';
import moment from 'moment';

const { rolesToEdit, rolesToEditOnlyThreeDays, rolesToView } = resourceContactManagementAccess;

export const getContactDataManagementRecords = ({
  contactDataManagementRecords,
  hasPermissionToEdit,
  hasPermissionToEditOnlyThreeDays,
}) => contactDataManagementRecords.map((record) => ({
  ...record,
  date: moment(record.date).format('MM/DD/YYYY'),
  isAllowedToEdit: hasPermissionToEdit || (hasPermissionToEditOnlyThreeDays && record.isAllowedToEdit),
}));

export const getContactDataManagementPermissions = ({
  userGroup,
}) => {
  const isRoleToEdit = rolesToEdit.includes(userGroup);
  const isRoleToEditOnlyThreeDays = rolesToEditOnlyThreeDays.includes(userGroup);

  return {
    hasPermissionToEdit: isRoleToEdit,
    hasPermissionToView: rolesToView.includes(userGroup),
    hasPermissionToEditOnlyThreeDays: isRoleToEditOnlyThreeDays,
    hasPermissionToAddRecord: isRoleToEdit || isRoleToEditOnlyThreeDays,
  };
};
