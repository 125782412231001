import { emailRegex } from 'utils/email';
import { requiredValidator } from 'utils/validation';
import { object, boolean } from 'yup';

export const validationSchema = object().shape({
  pocName: requiredValidator(),
  pocEmail: requiredValidator()
    .matches( // eslint-disable-next-line no-useless-escape
      emailRegex,
      'Invalid email address'
    ),
  emailContent: requiredValidator(),
  isDataCorrect: boolean().test('isChecked', 'data not checked', (isDataCorrect) => isDataCorrect),
});
