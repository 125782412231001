import { USERS_GROUPS } from 'core/auth/constants';
import { COLUMN_TYPES, FILTERS_TYPES } from 'core/constants';
import { STORE_KEYS } from 'core/task-orders/constants';
import { get } from 'lodash';
import moment from 'moment';

import { formatDateMMDDYYYY, getIsExpired, getUnixTime } from 'utils/helpers/date';
import {
  checkIsInclude,
  checkIsSelected,
} from 'utils/helpers/filters';

import {
  getTOStatus,
  getProjectType,
  getRowBackground,
  getRowsThatExpireSoon,
  getActiveObsoleteRates,
} from './utils';

const gridTemplateConfig =
  'grid-template-columns: minmax(auto, 32fr) minmax(auto, 93fr) minmax(auto, 210fr) minmax(auto, 159fr) minmax(auto, 74fr) minmax(auto, 79fr) minmax(auto, 79fr) minmax(auto, 116fr) minmax(auto, 116fr) minmax(auto, 95fr) minmax(auto, 200fr) minmax(auto, 62fr);';

const { CTO, HRM } = USERS_GROUPS;

export const taskOrdersListModelGetter = (userGroup) => {
  const isCTO = userGroup === CTO;
  const isHRM = userGroup === HRM;

  return ({
    rules: {
      css: {
        filtersCssRules: {
          gridTemplateConfig,
          wrapperCssRules: 'padding-top: 1.9rem;',
        },
        closeToExpirationTOCheckBoxCssRules: `
          &::after {
            position: absolute;
            right: 0;
            top: 50%;
            display: block;
            width: 1.8rem;
            height: 1.8rem;
            border-radius: 50%;
            background: linear-gradient(to left, rgba(214,31,38, 0.08), rgba(214,31,38, 0.08)), #f2f2f2;
            content: '';
            transform: translateY(-50%);
            transition: opacity 0.3s;
          }
        `,
        obsoleteRatesTOCheckBoxCssRules: `
          &::after {
            position: absolute;
            right: 0;
            top: 50%;
            display: block;
            width: 1.8rem;
            height: 1.8rem;
            border-radius: 50%;
            background: linear-gradient(to left, rgba(187,189,192, 0.25), rgba(187,189,192, 0.25)), #f2f2f2;
            content: '';
            transform: translateY(-50%);
            transition: opacity 0.3s;
          }
        `,
        gridTemplateConfig: `
          ${gridTemplateConfig}
          top: 15.4rem;
        `,
      },
    },
    rowStatusGetter: ({
      client,
      endDate,
      isActive,
      projectId,
      clientId,
      isFavored,
      employees,
      taskOrderRates,
      isManuallyDeactivated,
    }) => {
      const msa = get(client, 'msa', false);
      const isClientActive = get(client, 'isActive', false);
      const isSignedMsaUploaded = get(client, 'isSignedMsaUploaded', false);
      const isExpired = getIsExpired(endDate);
      const isExpireSoon = getRowsThatExpireSoon({ employees, isActive, isManuallyDeactivated, endDate });
      const isRatesObsolete = getActiveObsoleteRates({ isActive, taskOrderRates, isManuallyDeactivated }); // isActive, isExpired, taskOrderRates
      return {
        clientId,
        isFavored,
        id: projectId,
        isClientActive,
        taskOrderToCopyId: projectId,
        isActive: isActive && !isExpired && !isManuallyDeactivated,
        hasWarning: !isExpired && !isActive && !isManuallyDeactivated,
        canCopyAndCreate: isClientActive && isSignedMsaUploaded && !!msa,
        stylesTemplate: {
          rowStyles: `background-color: ${getRowBackground({ isRatesObsolete, isExpireSoon, isActive })};`,
        },
      };
    },

    statusGetter: ({ isActive, endDate }) => isActive && !getIsExpired(endDate),
    tableName: 'taskOrdersList',
    noDataMessage: 'This section currently contains no TO records.',
    filtersTemplate: [{
      type: FILTERS_TYPES.TOGGLE,
      storeKey: STORE_KEYS.IS_EXPIRE_SOON,
      shouldntBeRendered: true,
      checkFilter: ({ currentRow }) => !getRowsThatExpireSoon(currentRow),
    }, {
      type: FILTERS_TYPES.TOGGLE,
      storeKey: STORE_KEYS.IS_RATES_OBSOLETE,
      shouldntBeRendered: true,
      checkFilter: ({ currentRow }) => !getActiveObsoleteRates(currentRow),
    }, {
      type: FILTERS_TYPES.TOGGLE,
      storeKey: STORE_KEYS.IS_FAVORED,
      checkFilter: ({ currentRow }) => !get(currentRow, 'isFavored', false),
    }, {
      type: FILTERS_TYPES.SEARCH,
      storeKey: STORE_KEYS.PROJECT_ID,

      checkFilter: ({ currentRow, selected }) => {
        const value = get(currentRow, 'taskOrderId', '');
        return checkIsInclude({ selected, value });
      },
    }, {
      type: FILTERS_TYPES.SEARCH,
      storeKey: STORE_KEYS.TITLE,
      checkFilter: ({ currentRow, selected }) => {
        const value = get(currentRow, 'title', '');
        return checkIsInclude({ selected, value });
      },
    }, {
      type: FILTERS_TYPES.SEARCH,
      storeKey: STORE_KEYS.CLIENT_NAME,
      checkFilter: ({ currentRow, selected }) => {
        const value = get(currentRow, 'client.name', '');
        return checkIsInclude({ selected, value });
      },
    }, {
      type: FILTERS_TYPES.SELECT,
      storeKey: STORE_KEYS.COUNTRY,
      valueGetter: (row) => get(row, 'client.country', ''),
      checkFilter: ({ currentRow, selected }) => {
        const value = get(currentRow, 'client.country', '');
        return checkIsSelected({ selected, value });
      },
    }, {
      type: FILTERS_TYPES.SELECT,
      storeKey: STORE_KEYS.BILLING_AGENT,
      valueGetter: (row) => get(row, 'client.billingAgent.shortName', ''),
      checkFilter: ({ currentRow, selected }) => {
        const value = get(currentRow, 'client.billingAgent.shortName', '');
        return checkIsSelected({ selected, value });
      },
    }, {
      type: FILTERS_TYPES.SELECT,
      storeKey: STORE_KEYS.PROJECT_TYPE,
      isReversed: true,
      valueGetter: getProjectType,
      checkFilter: ({ currentRow, selected }) => {
        const value = getProjectType(currentRow);
        return checkIsSelected({ selected, value });
      },
    }, {
      type: FILTERS_TYPES.DATE,
      storeKey: STORE_KEYS.EFFECTIVE_DATE,
      componentProps: {
        popperProps: {
          placement: 'bottom-start',
          modifiers: [{
            name: 'offset',
            enabled: true,
            options: {
              offset: [0, 4],
            },
          }],
        },
        withMonthSelecting: true,
        withYearSelecting: true,
        outputFormatting: ({ date, useOnlyYear, useOnlyMonth }) => !!date && ({
          value: date,
          useOnlyYear,
          useOnlyMonth,
          formattedDate: moment.isMoment(date) ?
            date.format(`YYYY${useOnlyYear ? '' : '-MM'}${useOnlyMonth || useOnlyYear ? '' : '-DD'}`) :
            date,
        }),
      },
      checkFilter: ({ selected, currentRow }) => {
        const formattedDate = get(selected, 'formattedDate');
        const value = get(currentRow, STORE_KEYS.EFFECTIVE_DATE);
        if (value && formattedDate) {
          return !value.match(formattedDate);
        }

        return true;
      },
    }, {
      type: FILTERS_TYPES.DATE,
      storeKey: STORE_KEYS.END_DATE,
      componentProps: {
        popperProps: {
          placement: 'bottom-start',
          modifiers: [{
            name: 'offset',
            enabled: true,
            options: {
              offset: [0, 4],
            },
          }],
        },
        withMonthSelecting: true,
        withYearSelecting: true,
        outputFormatting: ({ date, useOnlyYear, useOnlyMonth }) => !!date && ({
          value: date,
          useOnlyYear,
          useOnlyMonth,
          formattedDate: moment.isMoment(date) ?
            date.format(`YYYY${useOnlyYear ? '' : '-MM'}${useOnlyMonth || useOnlyYear ? '' : '-DD'}`) :
            date,
        }),
      },
      checkFilter: ({ selected, currentRow }) => {
        const formattedDate = get(selected, 'formattedDate');
        const value = get(currentRow, STORE_KEYS.END_DATE);
        if (value && formattedDate) {
          return !value.match(formattedDate);
        }

        return true;
      },
    }, {
      type: FILTERS_TYPES.SELECT,
      storeKey: STORE_KEYS.STATUS,
      sortingComparator: (left, right) => {
        const statusesMap = {
          'Not yet active': 0,
          Signed: 1,
          Active: 2,
          Expired: 3,
          Deactivated: 4,
        };

        const leftRepresentation = get(statusesMap, left, 5);
        const rightRepresentation = get(statusesMap, right, 5);

        return leftRepresentation - rightRepresentation;
      },
      valueGetter: getTOStatus,
      checkFilter: ({ currentRow, selected }) => {
        const value = getTOStatus(currentRow);
        return checkIsSelected({ selected, value });
      },
    }, {
      type: FILTERS_TYPES.SEARCH,
      storeKey: STORE_KEYS.CATEGORIES_KEYS,
      checkFilter: ({ currentRow, selected }) => {
        const categoryKeys = get(currentRow, 'categoryKeys', []);
        const value = categoryKeys.join(', ');

        return checkIsInclude({ selected, value });
      },
    }, {}],
    dataTemplate: [{
      withImage: true,
      valueGetter: ({ isFavored, projectId }) => ({
        type: COLUMN_TYPES.FAVORED,
        cssRules: 'padding: 0.7rem 0 0;',
        componentProps: {
          isFavored,
          actionName: 'toggleFavorite',
          actionArguments: {
            id: projectId,
          },
        },
      }),
    }, {
      columnName: 'TO',
      valueGetter: ({ projectId, taskOrderId }) => ({
        type: COLUMN_TYPES.LINK,
        componentProps: {
          data: taskOrderId,
          pathname: `task-orders/${projectId}/${isCTO ? 'staffing' : 'details'}`,
        },
      }),
    }, {
      columnName: 'Project',
      valueGetter: ({ title = '', projectId }) => ({
        type: COLUMN_TYPES.LINK,
        componentProps: {
          data: title,
          title,
          pathname: `task-orders/${projectId}/${isCTO ? 'staffing' : 'details'}`,
          cssRules: 'font-size: 1.2rem;',
        },
      }),
    }, {
      columnName: 'Client',
      valueGetter: (row) => ({
        type: COLUMN_TYPES.LINK,
        componentProps: {
          data: get(row, 'client.name', ''),
          title: get(row, 'client.name', ''),
          pathname: `clients/${get(row, 'clientId', '')}/${isCTO || isHRM ? 'task-orders' : 'details'}`,
          cssRules: 'font-size: 1.2rem;',
        },
      }),
    }, {
      columnName: 'Country',
      valueGetter: (row) => ({
        type: COLUMN_TYPES.TEXT,
        value: get(row, 'client.country', ''),
      }),
    }, {
      columnName: 'Agent',
      valueGetter: (row) => ({
        type: COLUMN_TYPES.TEXT,
        value: get(row, 'client.billingAgent.shortName', ''),
      }),
    }, {
      columnName: 'Type',
      valueGetter: (row) => ({
        type: COLUMN_TYPES.TEXT,
        value: getProjectType(row),
      }),
    }, {
      columnName: 'Effective Date',
      valueGetter: ({ effectiveDate }) => ({
        type: COLUMN_TYPES.TEXT,
        isDate: true,
        unixValue: getUnixTime(effectiveDate),
        value: effectiveDate ? formatDateMMDDYYYY(effectiveDate) : 'N/A',
      }),
    }, {
      columnName: 'Expiration Date',
      valueGetter: ({ endDate }) => ({
        type: COLUMN_TYPES.TEXT,
        isDate: true,
        unixValue: getUnixTime(endDate),
        value: endDate ? formatDateMMDDYYYY(endDate) : 'N/A',
      }),
    }, {
      columnName: 'Status',
      valueGetter: (row) => ({
        type: COLUMN_TYPES.TEXT,
        value: getTOStatus(row),
      }),
    }, {
      columnName: 'Client Category Assignments',
      valueGetter: ({ categoryKeys }) => ({
        type: COLUMN_TYPES.TEXT,
        componentProps: {
          data: categoryKeys ? categoryKeys.join(', ') : '',
          isMultiline: true,
        },
      }),
    }, {
      columnName: 'Staffing',
      withoutControls: true,
      valueGetter: ({ projectId }) => ({
        type: COLUMN_TYPES.LINK,
        componentProps: {
          data: 'Staffing',
          pathname: `task-orders/${projectId}/staffing`,
          cssRules: 'font-size: 1.2rem;',
        },
      }),
    }],
  });
};
