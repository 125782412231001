import React, {
  useCallback,
  useRef,
  useEffect,
  useReducer,
  useMemo,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as CalendarImg } from 'assets/img/icon-calendar.svg';
import { colorPrimary } from 'assets/styles/variables';
import classNames from 'classnames';
import DateInputs from 'components/date-picker-inputs';
import { isEqual } from 'lodash';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';

import {
  stopPropagation,
  unsubscribeFromActions,
  subscribeToActions,
} from 'utils/helpers/events';

import Buttons from './buttons';
import DatePicker from './date-picker';
import {
  reducer,
  onKeyDown,
  isValidDate,
  onInputChange,
  onWrapperKeyDown,
  initialStateGetter,
  mapDispatchToProps,
  subscribeToCopyPast,
  unsubscribeFromCopyPast,
} from './utils';

import './styles.scss';

const StyledContainer = styled.div`
  font-size: 1.5rem;
  max-height: 3.8rem;

  ${({ cssRules }) => css`${cssRules}`}
  ${({ withError, errorCssRules }) => withError ? errorCssRules : ''}
`;

const Calendar = ({
  value,
  currentDate,
  displayedDate,
  cssRules,
  isLocked,
  children,
  onChange,
  withClear,
  minDetails,
  maxDetails,
  withError,
  holydaysSet,
  isWeekPicker,
  showRestDays,
  errorCssRules,
  businessDaysSet,
  withStepControls,
  outputFormatting,
  customValidation,
  withYearSelecting,
  withMonthSelecting,
  stepControlsConfig,
  useOnlyYear: defaultUseOnlyYear,
  useOnlyMonth: defaultUseOnlyMonth,
  popperProps,
  minYear,
  maxYear,
  ...otherProps
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom',
      ...popperProps,
    }
  );
  const initialState = useMemo(() => initialStateGetter({
    value,
    useOnlyYear: defaultUseOnlyYear,
    useOnlyMonth: defaultUseOnlyMonth,
  }), []);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    day,
    year,
    date,
    month,
    inputsRefs: {
      dayPickerRef,
      yearPickerRef,
      monthPickerRef,
    },
    useOnlyYear,
    useOnlyMonth,
    isCalendarOpen,
  } = state;
  const {
    setDay,
    setDate,
    setYear,
    setMonth,
    clearFields,
    registerInputs,
    setUseOnlyYear,
    setUseOnlyMonth,
    setIsCalendarOpen,
  } = mapDispatchToProps(dispatch);
  const calendar = useRef(null);

  useEffect(() => () => {
    unsubscribeFromActions(onOutsideAction);
  }, []);

  useEffect(() => {
    if (!isCalendarOpen && !isEqual(state, initialState)) {
      if (isValidDate({ year, month, day })) {
        const newDate = moment(`${year}/${month}/${day}`, 'YYYY/MM/DD');
        const isSameDate = newDate.isSame(date);

        if (!isSameDate) {
          if (customValidation) {
            const isValid = customValidation(newDate);
            if (isValid) {
              setDate(newDate);
            } else {
              setDate('');
            }
          } else {
            setDate(newDate);
          }
        }
      } else if (!year && !month && !day) {
        setDate('');
        if (value !== null) {
          onChange(null);
        }
      } else {
        clearFields();
      }
    }
  }, [isCalendarOpen]);

  useEffect(() => {
    if ((!value && date) ||
    (value && !value.isSame(date, 'date'))) {
      setDate(value);
    }
    if (!value) {
      setUseOnlyYear(false);
      setUseOnlyMonth(false);
      setDate('');
    }
  }, [value]);

  useEffect(() => {
    if ((!date && value) ||
    (date && !date.isSame(value, 'date'))) {
      onChange(outputFormatting ?
        outputFormatting({
          date,
          useOnlyYear,
          useOnlyMonth,
        }) :
        date);
    }
  }, [date]);

  const onFocus = () => {
    if (!isLocked) {
      openCalendar();
      subscribeToActions(onOutsideAction);
    }
  };
  const onCopyPast = ({
    keyCode,
    ctrlKey,
    metaKey,
  }) => {
    onWrapperKeyDown({
      day,
      year,
      month,
      keyCode,
      metaKey,
      setDate,
      ctrlKey,
      closeCalendar,
    });
  };
  const openCalendar = () => {
    setIsCalendarOpen(true);
    subscribeToActions(onOutsideAction);
    subscribeToCopyPast(onCopyPast);
  };
  const closeCalendar = () => {
    setIsCalendarOpen(false);
    unsubscribeFromActions(onOutsideAction);
    unsubscribeFromCopyPast(onCopyPast);
  };
  const toggleCalendar = () => {
    if (isCalendarOpen) {
      closeCalendar();
    } else {
      openCalendar();
    }
  };
  const onOutsideAction = ({ target }) => {
    const { current } = calendar;

    if (current && !current.contains(target)) {
      closeCalendar();
    }
  };
  const onMonthClick = withMonthSelecting ?
    (selectedDate) => {
      setUseOnlyMonth(true);
      setDate(selectedDate);
    } :
    null;
  const onYearClick = withYearSelecting ?
    (selectedDate) => {
      setUseOnlyYear(true);
      setDate(selectedDate);
    } :
    null;
  const onDayChange = ({ target: { value: targetValue } }) => !isLocked ? onInputChange({
    value: targetValue,
    mutation: setDay,
    nextTarget: yearPickerRef,
    nextTargetMutation: setYear,
    maxLength: 2,
    limit: 31,
  }) : null;
  const onMonthChange = ({ target: { value: targetValue } }) => !isLocked ? onInputChange({
    value: targetValue,
    mutation: setMonth,
    nextTarget: useOnlyMonth ? yearPickerRef : dayPickerRef,
    nextTargetMutation: useOnlyMonth ? setYear : setDay,
    maxLength: 2,
    limit: 12,
  }) : null;
  const onYearChange = ({ target: { value: targetValue } }) => !isLocked ? onInputChange({
    value: targetValue,
    mutation: setYear,
    maxLength: 4,
  }) : null;
  const getKeyEvent = ({
    limit,
    mutation,
    nextTarget,
    prevTarget,
  }) => ({ target, keyCode }) => !isLocked ? onKeyDown({
    target,
    keyCode,
    closeCalendar,
    limit,
    mutation,
    nextTarget,
    prevTarget,
  }) : null;
  const onDayKeyDown = getKeyEvent({
    nextTarget: yearPickerRef,
    prevTarget: monthPickerRef,
    mutation: setDay,
    limit: 31,
  });
  const onMonthKeyDown = getKeyEvent({
    nextTarget: useOnlyMonth ? yearPickerRef : dayPickerRef,
    mutation: setMonth,
    limit: 12,
  });
  const onYearKeyDown = getKeyEvent({
    prevTarget: useOnlyMonth ? monthPickerRef : dayPickerRef,
    mutation: setYear,
  });
  const clearDate = () => {
    setDate('');
    onChange(null);
    setUseOnlyYear(false);
    setUseOnlyMonth(false);
  };
  const setDateForButtons = useCallback(
    (selectDate) => {
      setDate(selectDate);
      onChange(outputFormatting ? outputFormatting(selectDate) : selectDate);
    },
    [outputFormatting],
  );
  const childrenWithExtraProp = children ? (
    React.Children.map(children, (child) => React.cloneElement(child, {
      date: date || moment(),
      closeCalendar,
      isCalendarOpen,
    }))
  ) : (
    <DateInputs
      day={day}
      year={year}
      month={month}
      isLocked={isLocked}
      useOnlyYear={useOnlyYear}
      useOnlyMonth={useOnlyMonth}
      onMonthChange={onMonthChange}
      onMonthKeyDown={onMonthKeyDown}
      onYearKeyDown={onYearKeyDown}
      onYearChange={onYearChange}
      onDayKeyDown={onDayKeyDown}
      onDayChange={onDayChange}
      registerInputs={registerInputs}
    />
  );
  return (
    <div className="calendar__flex-wrapper" ref={calendar}>
      <StyledContainer
        ref={setReferenceElement}
        className={classNames(
          'calendar',
          { 'calendar--locked': isLocked },
        )}
        cssRules={cssRules}
        withError={withError}
        errorCssRules={errorCssRules}
      >
        {
          withStepControls && (
            <Buttons
              date={date}
              minYear={minYear}
              maxYear={maxYear}
              config={stepControlsConfig}
              setDate={setDateForButtons}
            />
          )
        }
        <div
          className={classNames(
            'calendar__date-wrapper',
            { 'calendar__date-wrapper--with-clear': withClear && date },
          )}
          role="presentation"
          tabIndex={-1}
          onClick={onFocus}
          onKeyUp={onFocus}

        >
          { childrenWithExtraProp }
        </div>
        <button
          className={classNames(
            'button',
            'button--close',
            'calendar__clear-button',
            { 'calendar__clear-button--visible': withClear && date },
          )}
          onClick={clearDate}
          onFocus={stopPropagation}
          type="button"
        />
        {
          !isLocked && (
            <button
              className={classNames(
                'calendar__toggle-button',
                { 'calendar__toggle-button--with-clear': withClear && date },
              )}
              onClick={toggleCalendar}
              onFocus={stopPropagation}
              type="button"
            >
              <CalendarImg
                className="calendar__icon calendar__icon--calendar"
              />
            </button>
          )
        }
        {
          isCalendarOpen && (
            <div
              className={classNames(
                'calendar__expand-section',
                { 'calendar__expand-section--opened': isCalendarOpen }
              )}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <DatePicker
                currentDate={currentDate}
                displayedDate={displayedDate}
                selectedDate={date}
                selectDate={setDate}
                minDetails={minDetails}
                maxDetails={maxDetails}
                holydaysSet={holydaysSet}
                onYearClick={onYearClick}
                onMonthClick={onMonthClick}
                isWeekPicker={isWeekPicker}
                showRestDays={showRestDays}
                businessDaysSet={businessDaysSet}
                minYear={minYear}
                maxYear={maxYear}
                setUseOnlyYear={setUseOnlyYear}
                setUseOnlyMonth={setUseOnlyMonth}
                {...otherProps}
              />

            </div>
          )
        }
      </StyledContainer>
    </div>
  );
};

Calendar.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
    PropTypes.string,
  ]),
  currentDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]),
  displayedDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]),
  children: PropTypes.any,
  isLocked: PropTypes.bool,
  withClear: PropTypes.bool,
  withError: PropTypes.bool,
  cssRules: PropTypes.string,
  useOnlyYear: PropTypes.bool,
  useOnlyMonth: PropTypes.bool,
  maxDetails: PropTypes.string,
  minDetails: PropTypes.string,
  showRestDays: PropTypes.bool,
  isWeekPicker: PropTypes.bool,
  errorCssRules: PropTypes.string,
  outputFormatting: PropTypes.func,
  withStepControls: PropTypes.bool,
  withYearSelecting: PropTypes.bool,
  withMonthSelecting: PropTypes.bool,
  customValidation: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  popperProps: PropTypes.shape({}),
  holydaysSet: PropTypes.instanceOf(Set),
  businessDaysSet: PropTypes.instanceOf(Set),
  stepControlsConfig: PropTypes.shape({
    step: PropTypes.number,
    unitName: PropTypes.string,
  }),
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
};

Calendar.defaultProps = {
  popperProps: {},
  value: '',
  currentDate: undefined,
  displayedDate: undefined,
  cssRules: '',
  maxDetails: '',
  minDetails: '',
  children: null,
  isLocked: false,
  withClear: false,
  withError: false,
  useOnlyYear: false,
  useOnlyMonth: false,
  isWeekPicker: false,
  showRestDays: false,
  withYearSelecting: false,
  withMonthSelecting: false,
  holydaysSet: new Set(),
  customValidation: null,
  outputFormatting: null,
  withStepControls: false,
  stepControlsConfig: {
    step: 1,
    unitName: 'month',
  },
  businessDaysSet: new Set([1, 2, 3, 4, 5]),
  errorCssRules: `
    && {
      color: ${colorPrimary};
      border-color: ${colorPrimary};

      .date-inputs__item::-webkit-input-placeholder,
      .date-picker__input-divider {
        color: ${colorPrimary};
      }
    }
  `,
  minYear: undefined,
  maxYear: undefined,
};

export default Calendar;
