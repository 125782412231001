import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import StartsRating from 'elements/stars-rating';
import { Field } from 'formik';
import { get } from 'lodash';
import styled, { css } from 'styled-components';

import './styles.scss';

const StyledDiv = styled.div`
  box-sizing: border-box;
  padding-top: 1.9rem;
  font-size: 1.4rem;

  ${({ cssRules }) => css`${cssRules}`}`;

const CheckboxInput = ({
  name,
  onChange,
  cssRules,
  isLocked,
  withError,
}) => (
  <Field
    name={name}
    render={({ field: { value }, form: { errors } }) => {
      const onClick = (rating) => {
        if (!isLocked) {
          onChange(rating);
        }
      };

      return (
        <StyledDiv
          className={
            classNames(
              'form-slider',
              { 'form-checkbox--with-error': withError },
            )
          }
          cssRules={cssRules}
        >
          <input
            name={name}
            className="form-slider__native-input-field"
            defaultValue={value}
          />
          <StartsRating
            onClick={onClick}
            isLocked={isLocked}
            rating={value}
            isError={Boolean(get(errors, name, false))}
          />
        </StyledDiv>
      );
    }}
  />
);

CheckboxInput.propTypes = {
  isLocked: PropTypes.bool,
  withError: PropTypes.bool,
  cssRules: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CheckboxInput.defaultProps = {
  cssRules: '',
  isLocked: false,
  withError: false,
};

export default CheckboxInput;
