import React from 'react';

import PropTypes from 'prop-types';

import iconCheckboxChecked from 'assets/img/icon-checkbox-checked.svg';
import iconCheckbox from 'assets/img/icon-checkbox.svg';
import { colorSecondaryText, colorSecondaryGrayLight } from 'assets/styles/variables';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
    padding: 0;
    font-family: "Etelka", Arial, sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    border: 0;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: ${colorSecondaryGrayLight};
    background-color: transparent;
    cursor: pointer;
    outline: none;
    transition: color 0.3s;

    &[disabled] {
      background-color: ${colorSecondaryGrayLight};
      opacity: 0.2;
      cursor: not-allowed;
      pointer-events: none;
    }

    &::before {
      content: '';
      width: 1.8rem;
      height: 1.8rem;
      display: block;
      background-image: url(${iconCheckbox});
      background-size: 100%;
      background-repeat: no-repeat;
      opacity: 0.3;
      transition: opacity 0.3s;
    }

    &:focus-within,
    &:hover {
      color: ${colorSecondaryText};

      &::before {
        opacity: 1;
      }
    }
    ${({ visibleIndication }) => visibleIndication && css`
      margin-right: 5rem;

      &::after {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        background: linear-gradient(to left, rgba(214,31,38, 0.08), rgba(214,31,38, 0.08)), #f2f2f2;
        content: '';
        transition: opacity 0.3s;
        border-radius: 50%;
      }
    `}
    ${({ isActive }) => isActive && css`
      color: ${colorSecondaryText};

      &::before {
        background-image: url(${iconCheckboxChecked});
        opacity: 1;
      }
    `}
    ${({ cssRules }) => css`${cssRules}`}
`;

export const Checkbox = ({ title, onClick, isActive, cssRules, visibleIndication, disabled }) => (
  <StyledButton
    onClick={() => onClick(isActive)}
    type="button"
    cssRules={cssRules}
    isActive={isActive}
    visibleIndication={visibleIndication}
    disabled={disabled}
  >
    {title}
  </StyledButton>
);

Checkbox.propTypes = {
  cssRules: PropTypes.string,
  isActive: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  visibleIndication: PropTypes.bool,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  cssRules: '',
  title: '',
  isActive: true,
  visibleIndication: true,
  disabled: false,
};
