import React, { memo, useMemo } from 'react';

import PropTypes from 'prop-types';

import NavigationTabs from 'components/details-tabs';

export const Navigation = memo(({ tabIndex, setTabIndex, data }) => {
  const tabs = useMemo(() => Object.keys(data).map((item) => ({
    labels: [
      {
        value: `${data[item].value} ${data[item].valuePrefix}`,
        isEmpty: data[item].value <= 0,
      },
    ],
    title: data[item].label,
  })), [data]);
  return (<NavigationTabs
    indexOfActiveTab={tabIndex}
    setIndexOfActiveTab={setTabIndex}
    tabHeadings={tabs}
  />);
});

Navigation.prototype = {
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};
