export const employeeStatusGetter = ({ isDismissed, isOnLeave, isNew, isIntern }) => {
  if (isDismissed) {
    return 'Dismissed';
  }

  if (isOnLeave) {
    return 'On Leave';
  }

  if (isNew) {
    return 'New';
  }

  if (isIntern) {
    return 'Intern';
  }

  return 'Employed';
};
