export const summaryRowsCssRule = `
  display: grid;
  grid-column-gap: 0;
  grid-template-columns:
  minmax(auto, 306fr)
  minmax(auto, 110fr)
  minmax(auto, 112fr)

  minmax(auto, 44fr)
  minmax(auto, 36.5fr)
  minmax(auto, 43fr)
  minmax(auto, 36.5fr)
  minmax(auto, 43fr)
  minmax(auto, 36.5fr)
  minmax(auto, 43fr)
  minmax(auto, 36.5fr)

  minmax(auto, 82fr)
  minmax(auto, 62fr)
  minmax(auto, 40fr)

  minmax(auto, 77fr)
  minmax(auto, 59fr)
  minmax(auto, 36fr)

  minmax(auto, 98fr);

  min-height: 4.8rem;
  font-size: 1.6rem;
  padding: 0 0 0 1.6rem;
  border-top: 1px solid #bbbdc0;
  background-color: rgba(187,189,192,0.15);
  color: #414042;
  transition: all 0.3s;
  &.expansion-summary__root--expanded {
    transition: all 0.3s;
    background-color: rgba(187, 189, 192, 0.3);
  }
`;

export const commissionRatesTableHeaderStyles = `
  padding: 0.6rem 0 0 1.6rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  margin-bottom: 0;
  grid-column-gap: 0;
  grid-template-columns:
    minmax(auto, 25fr) minmax(auto, 9fr) minmax(auto, 9fr)
    minmax(auto,6.5fr) minmax(auto,6.5fr) minmax(auto,6.5fr) minmax(auto,6.5fr)
    minmax(auto, 7.5fr) minmax(auto, 7.5fr)
    minmax(auto, 7fr) minmax(auto, 7fr)
    minmax(auto, 8fr);
  grid-template-areas:
  ". . . commission commission commission commission stage1 stage1 stage2 stage2 ."
  "fullName reportsTo baseSalary rate_1 rate_2 rate_3 rate_am stage_1_days stage_1_rate stage_2_days stage_2_rate button"
  ;
  && {
    .grid-unit--heading {
      border-bottom: none;
      display: flex;
      justify-content: center;
      &:nth-child(1){
        justify-content: flex-start;
      }
    }
  }
`;

export const formTextInputStyles = `
  box-sizing: border-box;
  padding: 0 0 0.3rem 0;
  .text-input__input-field {
    max-width: 3.8rem;
    height: 100%;
    text-align: right;
    font-size: 1.2rem;
    color: rgba(000,000,000,0.87);
    margin-left: auto;
    margin-top: 1.3rem;
  }
`;

export const formDescStyles = `
  padding: 0.9rem 0 0 0;
  font-size: 1.2rem;
  font-weight: 400;
  color: rgba(000,000,000,0.87);
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
`;

export const wrapperCssRules = `{
  min-height: 60vh;
  .table__content-wrapper {
    padding-bottom:0;
  }
}`;

export const formTemplate = {
  formWrapperStyles: `
    padding: 0;
  `,
  cssRules: `
    // background-color: green;
  `,
  formControlsCss: `{
    display: grid;
    margin: 1.6rem 5.6rem;
    grid-template-columns: minmax(auto,18rem) minmax(auto,18rem);
    .button {
      width: 18rem;
      height: 3.2rem;
      margin: 0;
    }
  }`,
};
