export const config = {
  md: {
    lines: 17,
    length: 0,
    width: 7,
    radius: 20,
    color: '#b7b8c4',
    opacity: 0.25,
  },
  sm: {
    lines: 17,
    length: 0,
    width: 5,
    radius: 15,
    color: '#b7b8c4',
    opacity: 0.25,
  },
};
