import React from 'react';

import { VERSION } from 'core/constants';

import './styles.scss';


const Version = () => (
  <p className="version">
    {VERSION}
  </p>
);


export default Version;
