import {
  addChannelPartnerDocuments,
  deleteChannelPartnerDocuments,
} from 'core/auth/guaranteedAccessRoles';
import hash from 'hash-sum';
import moment from 'moment';

import { getHasPermissions } from 'utils/auth';
import {
  getUnixTime,
  getIsExpired,
  formatDateMMDDYYYY,
} from 'utils/helpers/date';
import { requiredValidator } from 'utils/validation';
import { object } from 'yup';

export default ({
  isDOA,
  userGroup,
  channelPartnerId,
}) => {
  const hasPermissionsToDeleteFile = getHasPermissions(userGroup, deleteChannelPartnerDocuments);
  const hasPermissionsToAddFile = getHasPermissions(userGroup, addChannelPartnerDocuments);
  return isDOA ? [] : [
    {
      type: 'table',
      withUploading: hasPermissionsToAddFile,
      noDataMessage: 'This section currently contains no files.',
      tableCssRules: 'min-height: calc(100vh - 30rem);',
      fileLoaderConfig: {
        title: 'Upload Document',
        fields: [
          {
            type: 'text',
            name: 'uploadTitle',
            label: 'Title',
            cssRules: '&& {  margin-bottom: 1.1rem; }',
          },
          {
            type: 'calendar',
            name: 'uploadDate',
            placeholder: 'Effective date',
            outputFormatting: ({ date }) => (
              moment.isMoment(date) ?
                date.format('YYYY-MM-DD') :
                date
            ),
            cssRules: `
              max-height: unset;
              font-size: 1.4rem;
              line-height: 2.4rem;
              && {
                & .calendar-date-picker__views-wrapper {
                  min-width: unset;
                }
              }
            `,
          },
        ],
        validationSchema: object().shape(
          {
            uploadTitle: requiredValidator()
              .max(50, 'Title max length is 50'),
            uploadDate: requiredValidator(),
          }
        ),
        initialValues: {
          uploadTitle: '',
          uploadDate: '',
        },
      },
      content: [
        {
          title: 'Documents',
          idKey: 'fileId',
          dataKey: 'files',
          rowStatusGetter: ({
            title,
            fileId,
            uploadedDate,
            effectiveDate,
          }) => (
            {
              id: hash({
                fileId,
                uploadedDate,
              }),
              isActive: !getIsExpired(effectiveDate),
              hasWarning: false,
              name: title,
            }
          ),
          rules: {
            css: {
              gridTemplateConfig: `grid-template-columns: minmax(auto, 25fr) minmax(auto, 12fr) ${hasPermissionsToDeleteFile ? 'minmax(auto, 24fr) minmax(auto, 26fr)' : 'minmax(auto, 46fr)'};`,
            },
          },
          tableName: 'otherDocuments',
          dataTemplate: [
            {
              columnName: 'Title',
              valueGetter: ({
                title,
                downloadUrl,
              }) => ({
                value: title,
                type: downloadUrl && hasPermissionsToAddFile ? 'link' : 'text',
                componentProps: {
                  data: title,
                  href: downloadUrl,
                  withBlank: true,
                },
              }),
            },
            {
              columnName: 'Upload Date',
              valueGetter: ({
                uploadedDate,
              }) => ({
                isDate: true,
                unixValue: getUnixTime(uploadedDate),
                type: 'text',
                componentProps: {
                  data: uploadedDate ? formatDateMMDDYYYY(uploadedDate) : 'N/A',
                  isEmpty: !uploadedDate,
                },
              }),
            },
            {
              columnName: 'Effective Date',
              valueGetter: ({
                effectiveDate,
              }) => ({
                isDate: true,
                unixValue: getUnixTime(effectiveDate),
                type: 'text',
                componentProps: {
                  data: effectiveDate ? formatDateMMDDYYYY(effectiveDate) : 'N/A',
                  isEmpty: !effectiveDate,
                },
              }),
            },
            {
              columnName: 'action',
              withoutControls: true,
              shouldntBeRendered: !hasPermissionsToDeleteFile,
              valueGetter: ({
                title,
                fileId,
              }) => ({
                value: 'Delete',
                type: 'action',
                shouldntBeRendered: !hasPermissionsToDeleteFile,
                componentProps: {
                  data: 'Delete',
                  actionName: 'changeCurrentModal',
                  actionArguments: {
                    currentModal: 'CONFIRM_ACTION_MODAL',
                    content: [
                      {
                        type: 'title',
                        data: `Delete “${title}”?`,
                      }, {
                        type: 'description',
                        data: 'This file will be permanently deleted and cannot be restored.',
                      },
                    ],
                    acceptActionName: 'deleteChannelPartnerDocument',
                    acceptActionTitle: 'Delete',
                    acceptActionArguments: {
                      channelPartnerId,
                      fileId,
                    },
                  },
                },
              }),
            }],
        },
      ],
    }];
};
