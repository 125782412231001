import { USERS_GROUPS } from 'core/auth/constants';

import { getHasPermissions } from '../auth';

const {
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
} = USERS_GROUPS;
export const isAudit = (userGroup) => getHasPermissions(userGroup, [
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
]);
