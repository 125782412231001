import { USERS_GROUPS } from 'core/auth/constants';
import { clientsListActions } from 'core/auth/guaranteedAccessRoles';
import {
  CLIENT_ACTION_BY_STATUS,
  CLIENT_STATUSES,
  STORE_KEYS,
} from 'core/clients/constants';
import { getClientStatus } from 'core/clients/utils';
import { COLUMN_TYPES, FILTERS_TYPES } from 'core/constants';
import { get } from 'lodash';

import { getHasPermissions } from 'utils/auth';
import {
  checkStartFrom,
  checkIsInclude,
  checkIsSelected,
} from 'utils/helpers/filters';
import { getAccessTabLink } from 'utils/helpers/getAccessTabLink';

const { CTO, HRM, DELIVERY_OFFICE_ADMIN, DELIVERY_OFFICE_ADMIN_BY, CPS } =
  USERS_GROUPS;

// @returns: { rules: { css }, tableName, noDataMessage }
export const clientsListModelGetter = (userGroup) => {
  const isCTO = getHasPermissions(userGroup, [CTO]);
  const isCPS = getHasPermissions(userGroup, [CPS]);
  const isHRM = getHasPermissions(userGroup, [HRM]);
  const isDOA =
    getHasPermissions(userGroup, [DELIVERY_OFFICE_ADMIN]) ||
    getHasPermissions(userGroup, [DELIVERY_OFFICE_ADMIN_BY]);
  const hasPermissions = getHasPermissions(userGroup, clientsListActions);

  return {
    rules: {
      css: {
        gridTemplateConfig: `
          top: 11.8rem;
          grid-template-columns: minmax(auto, 5fr) minmax(auto, 56fr)  minmax(auto, 18fr) minmax(auto, 35fr) minmax(auto, 28fr) minmax(auto, 32fr) minmax(auto, 27fr) minmax(auto, 16fr) minmax(auto, 15fr) ${
  hasPermissions ? 'minmax(auto, 15fr)' : ''
};`,
      },
    },
    tableName: 'clientsList',
    noDataMessage: 'This section currently contains no Clients records.',
    rowStatusGetter: ({
      name,
      clientId,
      isActive,
      isFavored,
      isSignedMsaUploaded,
    }) => ({
      name,
      isActive,
      isFavored,
      id: clientId,
      isSignedMsaUploaded,
      hasWarning: !isSignedMsaUploaded && isActive,
    }),
    filtersTemplate: [
      {
        type: FILTERS_TYPES.ALPHABETICAL,
        storeKey: STORE_KEYS.START_FROM,
        shouldntBeRendered: true,
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'name', '');
          return checkStartFrom({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.TOGGLE,
        storeKey: STORE_KEYS.IS_FAVORED,
        checkFilter: ({ currentRow }) => !get(currentRow, 'isFavored', false),
      },
      {
        type: FILTERS_TYPES.SEARCH,
        storeKey: STORE_KEYS.NAME,
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'name', '');
          return checkIsInclude({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.COUNTRY,
        valueGetter: (row) => get(row, 'country', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'country', '');
          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.BILLING_AGENT_NAME,
        valueGetter: (row) => get(row, 'billingAgent.name', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'billingAgent.name', '');
          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.RATE_CARD_NAME,
        isReversed: true,
        valueGetter: (row) => get(row, 'templateRatecard.name', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'templateRatecard.name', '');
          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.USER_FULL_NAME,
        valueGetter: (row) => get(row, 'userFullname', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'userFullname', '');
          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.STATUS,
        sortingComparator: (left, right) => {
          const statusesMap = {
            'Not yet active': 0,
            Active: 1,
            Inactive: 2,
            Dismissed: 3,
          };
          const leftRepresentation = get(statusesMap, left, 4);
          const rightRepresentation = get(statusesMap, right, 4);

          return leftRepresentation - rightRepresentation;
        },
        valueGetter: ({ isActive, isSignedMsaUploaded }) => {
          const currentStatus = getClientStatus(
            isActive,
            isSignedMsaUploaded,
            CLIENT_STATUSES
          );

          return currentStatus;
        },
        checkFilter: ({
          currentRow: { isActive, isSignedMsaUploaded },
          selected,
        }) => {
          const value = getClientStatus(
            isActive,
            isSignedMsaUploaded,
            CLIENT_STATUSES
          );
          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.IS_PLATINUM_RATES,
        valueGetter: (row) => get(row, 'isPlatinumRates', false) ? 'Yes' : 'No',
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'isPlatinumRates', false) ?
            'Yes' :
            'No';

          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.IN_ARREARS,
        valueGetter: (row) => (get(row, 'inArrears', false) ? 'Yes' : 'No'),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'inArrears', false) ? 'Yes' : 'No';

          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.EMPTY,
        shouldntBeRendered: !hasPermissions,
      },
    ],
    dataTemplate: [
      {
        withImage: true,
        valueGetter: ({ clientId, isFavored }) => ({
          type: COLUMN_TYPES.FAVORED,
          cssRules: 'padding: 0.7rem 0 0;',
          componentProps: {
            isFavored,
            actionName: 'toggleFavorite',
            actionArguments: {
              id: clientId,
            },
          },
        }),
      },
      {
        columnName: 'name',
        valueGetter: ({ name, clientId }) => ({
          type: COLUMN_TYPES.LINK,
          componentProps: {
            title: name,
            data: name,
            pathname: `clients/${clientId}/${getAccessTabLink(
              isCTO,
              isHRM,
              isDOA
            )}`,
          },
        }),
      },
      {
        columnName: 'country',
        valueGetter: ({ country }) => ({
          type: COLUMN_TYPES.TEXT,
          value: country,
        }),
      },
      {
        columnName: 'billing agent',
        valueGetter: (row) => ({
          type: COLUMN_TYPES.TEXT,
          value: get(row, 'billingAgent.name', ''),
        }),
      },
      {
        columnName: 'rate card',
        valueGetter: (row) => ({
          title: get(row, 'templateRatecard.name', ''),
          type: COLUMN_TYPES.TEXT,
          value: get(row, 'templateRatecard.name', ''),
        }),
      },
      {
        columnName: 'account manager',
        valueGetter: (row) => ({
          type: COLUMN_TYPES.TEXT,
          value: get(row, 'userFullname', ''),
        }),
      },
      {
        columnName: 'status',
        valueGetter: ({ isActive, isSignedMsaUploaded }) => ({
          type: COLUMN_TYPES.TEXT,
          value: getClientStatus(
            isActive,
            isSignedMsaUploaded,
            CLIENT_STATUSES
          ),
        }),
      },
      {
        columnName: 'platinum',
        valueGetter: (row) => ({
          type: COLUMN_TYPES.TEXT,
          value: get(row, 'isPlatinumRates', false) ? 'Yes' : 'No',
        }),
      },
      {
        columnName: 'arrears',
        valueGetter: (row) => ({
          type: COLUMN_TYPES.TEXT,
          value: get(row, 'inArrears', false) ? 'Yes' : 'No',
        }),
      },
      {
        columnName: 'action',
        withoutControls: true,
        shouldntBeRendered: !hasPermissions,
        valueGetter: ({ clientId, name, isActive, isSignedMsaUploaded }) => {
          const clientStatus = getClientStatus(
            isActive,
            isSignedMsaUploaded,
            CLIENT_ACTION_BY_STATUS
          );

          if (!clientStatus || isCPS) {
            return null;
          }

          const { actionTitle, description } = clientStatus;

          return {
            type: COLUMN_TYPES.ACTION,
            shouldntBeRendered: !hasPermissions,
            componentProps: {
              data: actionTitle,
              actionName: 'changeCurrentModal',
              actionArguments: {
                currentModal: 'CONFIRM_ACTION_MODAL',
                params: {
                  content: [
                    {
                      type: 'title',
                      data: `${
                        isActive ? 'Deactivate' : actionTitle
                      } “${name}”?`,
                    },
                    {
                      type: 'description',
                      data: description,
                    },
                  ],
                  acceptActionArguments: {
                    clientId,
                    fields: {
                      isActive: !isActive,
                    },
                    withModal: true,
                  },
                  acceptActionName: 'updateClientDetails',
                  acceptActionTitle: isActive ? 'Deactivate' : actionTitle,
                },
              },
            },
          };
        },
      },
    ],
  };
};
