import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { useTimeout } from 'hooks/useTimeout';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import styles from './styles.module.scss';

export const delayAfterCopied = 2000;

const SecretToken = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false);

  useTimeout(
    () => {
      setIsCopied(false);
    },
    isCopied ? delayAfterCopied : null
  );

  const copiedText = () => {
    setIsCopied(true);
  };

  return (
    <>
      <div id="secretTokenTitle" className={styles.title}>
        Secret Token
      </div>
      <div id="secretTokenContent" className={styles.inputContainer}>
        <input className={styles.input} type="text" value={text} readOnly />
        <CopyToClipboard text={text} onCopy={copiedText}>
          <button
            id="copyButton"
            type="button"
            className={styles.copyToClipboardButton}
          >
            Copy
          </button>
        </CopyToClipboard>
      </div>
      {isCopied && (
        <div id="secretTokenNotification" className={styles.notification}>
          Secret Token successfully copied.
        </div>
      )}
    </>
  );
};

SecretToken.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SecretToken;
