import {
  selectUserGroup,
  selectUserId,
} from 'core/auth/selectors';
import { taskOrderDetailsModelGetter } from 'layouts/task-order-details/model';
import { taskOrdersListModelGetter } from 'layouts/task-orders-list/model';
import { get } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import {
  modelParser,
  parseDetails,
  filtersConfigGetter,
  filtersItemsGetter,
} from 'utils/helpers/models';

const selectTaskOrdersData = (state) => state.taskOrders.taskOrdersData;
const selectCurrentTaskorderDetails = (state) => state.taskOrders.taskOrdersDetails;

export const selectCommittedHoursByClientId = (state) => state.taskOrders.availableCommittedHours;
export const selectEntityName = (state) => state.taskOrders.entityName;
export const selectFilters = (state) => state.taskOrders.filters;
export const selectOrderRules = (state) => state.taskOrders.orderRules;
export const selectIsFetchingTaskOrdersList = (state) => state.taskOrders.isFetching;
export const selectIsFilteringTaskOrdersList = (state) => state.taskOrders.isFiltering;
export const selectIsFetchingDetails = (state) => state.taskOrders.isFetchingDetails;
export const selectIsFetchingFiles = (state) => state.taskOrders.isFetchingFiles;
export const selectIsTaskOrderDetailsFormSubmitted = (state) => state.taskOrders.isFormSubmitted;
export const selectFavoriteTaskOrders = (state) => state.taskOrders.favoriteTaskOrders;
export const selectErrors = (state) => state.taskOrders.errors;
export const selectRequestsCounter = (state) => state.taskOrders.requestsCounter;
export const selectFilterByExpiration = createSelector(
  selectFilters,
  (filters) => filters.isExpireSoon
);
export const selectFilterByObsoleteRates = createSelector(
  selectFilters,
  (filters) => filters.isRatesObsolete
);

export const selectIsFavoredTaskOrder = createSelector(
  selectFavoriteTaskOrders,
  selectCurrentTaskorderDetails,
  (favoritesTaskOrders, taskorderDetails) => taskorderDetails.projectId ? favoritesTaskOrders[taskorderDetails.projectId] || false : false
);

const selectActiveFilters = createSelector(
  selectFilters,
  (filters) => Object.entries(filters).filter(([_, value]) => value.isActive)
);

export const selectHasFilters = createSelector(
  selectActiveFilters,
  (filters) => !!filters.length
);

export const selectTaskOrdersListModel = createSelector(
  selectUserGroup,
  (group) => taskOrdersListModelGetter(group)
);

const selectFilterItems = createSelector(
  selectTaskOrdersData,
  selectTaskOrdersListModel,
  selectFilters,
  filtersItemsGetter,
);

export const selectFilterConfig = createSelector(
  selectFilters,
  selectFilterItems,
  selectTaskOrdersListModel,
  filtersConfigGetter,
);

export const selectedTaskOrdersData = createSelector(
  selectTaskOrdersData,
  selectFavoriteTaskOrders,
  selectActiveFilters,
  selectOrderRules,
  selectTaskOrdersListModel,
  (taskOrdersData, favoriteTaskOrders, filters, orderRules, {
    tableName,
    dataTemplate,
    rowStatusGetter,
    filtersTemplate,
  }) => {
    const options = {
      orderRules: orderRules[tableName],
      favorites: favoriteTaskOrders,
      filters,
      idKey: 'projectId',
      tableName,
      rowStatusGetter,
      filtersTemplate,
    };

    const {
      data,
    } = modelParser(taskOrdersData, dataTemplate, options);

    return data;
  }
);

export const selectTaskOrdersDetailsModel = createSelector(
  selectUserGroup,
  selectUserId,
  selectCurrentTaskorderDetails,
  (group, userId, taskorderDetails) => {
    const holidays = get(taskorderDetails, 'holidays', []);
    const holidaysByDevcenter = holidays.reduce((acc, {
      date,
      devcenter,
    }) => {
      const devcenterHolydays = get(acc, devcenter, []);
      devcenterHolydays.push(moment(date));
      return {
        ...acc,
        [devcenter]: devcenterHolydays,
      };
    }, {});

    return taskOrderDetailsModelGetter(
      group,
      userId,
      {
        ...taskorderDetails,
        holidays: holidaysByDevcenter,
        selectCommittedHoursByClientId,
      }
    );
  }
);

export const selectTaskOrdersDetails = createSelector(
  selectCurrentTaskorderDetails,
  selectOrderRules,
  selectTaskOrdersDetailsModel,
  (taskOrdersDetails, orderRules, clientDetailsModel) => parseDetails(taskOrdersDetails, clientDetailsModel, orderRules)
);

export const selectTaskOrdersCount = createSelector(
  selectTaskOrdersData,
  (taskOrders) => taskOrders.length,
);
