import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import Calendar from 'components/calendar';
import Controls from 'components/details-controls';
import NavigationTabs from 'components/details-tabs';
import FilterSelect from 'components/filter-select';
import units from 'components/shared-units';
import { selectDevCenters } from 'core/auth/selectors';
import { billingReportsActions } from 'core/billing-reports/actions';
import { REPORTS_TYPES, STORE_KEYS } from 'core/billing-reports/constants';
import {
  selectAnnualReportData,
  selectSelectedDevCenter,
  selectAnnualReportDetails,
} from 'core/billing-reports/selectors';
import pageSlugs from 'core/config/pages-slugs';
import { FILTERS_TYPES } from 'core/constants';
import { filesActions } from 'core/files/actions';
import GoTop from 'elements/go-top-button';
import StyledWrapper from 'elements/styled-wrapper';
import LayoutBodyContent from 'layouts/layout-body';
import { get } from 'lodash';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { createStructuredSelector } from 'reselect';


import { updateState } from 'utils/helpers/history';

import { autoScrollToTop } from 'utils/react';


const { getTabName, getIndex } = pageSlugs.annualReport;

const AnnualReport = ({
  location,
  tab,
  isFetching,
  devCenters,
  entityName,
  orderRules,
  changeOrder,
  changeFilter,
  resetFilters,
  selectedReportDate,
  setAnnualReportData,
  setSummaryReportType,
  setBillingSummaryReportData,

  annualReportData,
  annualReportDetails,
  generateAnnualPayrollReport,
  generateAnnualPayrollExcelReport,
  generateAnnualPayrollSalaryReport,
  // getTotalAnnualPayrollReport,
}) => {
  autoScrollToTop(location);

  const initialTab = getIndex(tab);
  const [indexOfActiveTab, setIndexOfActiveTab] = useState(initialTab);
  const {
    controls,
    tabHeadings,
    tabData = [],
    selectCssRules,
    controlsHeading,
    controlsCssRules,
    staffFilterItems,
    tabStylesTemplates,
    yearPickerCssRules,
    currencyFilterItems,
    calendarItemCssRules,
    controlsWrapperCssRules,
  } = annualReportDetails;

  const year = useMemo(
    () => selectedReportDate.year(),
    [selectedReportDate]
  );

  const annualPayrollPDFReportArguments = useMemo(() => {
    const { currency, devcenters, staff } = annualReportData;
    const actionArguments = {
      year,
    };
    if (currency.length) {
      actionArguments.currencies = currency.map((value) => `${value}`.toUpperCase());
    }
    if (devcenters.length) {
      actionArguments.devcenterIds = devcenters;
    } else {
      actionArguments.devcenterIds = devCenters.byShortName ? Object.values(devCenters.byShortName) : [];
    }
    if (staff) {
      actionArguments.isDelivery = staff === STORE_KEYS.DEV_STAFF;
    }
    return actionArguments;
  }, [annualReportData, year, devCenters]);

  const annualPayrollExcelReportArguments = annualPayrollPDFReportArguments;

  const generateSalaryReport = useCallback(
    () => {
      const { devcenters, currency } = annualReportData;
      let currencies = currency.map((item) => item === 'rur' ? 'rub'.toUpperCase() : item.toUpperCase());

      if (!currencies.length) {
        currencies = ['USD', 'RUB', 'PLN'];
      }

      const params = {
        year,
        currencies,
      };

      if (devcenters.length) {
        params.deliveryCenterIds = devcenters;
      } else {
        params.deliveryCenterIds = devCenters.byShortName ? Object.values(devCenters.byShortName) : [];
      }

      switch (annualReportData.staff) {
        case STORE_KEYS.DEV_STAFF:
          params.deliveryOnly = 'dev';
          break;
        case STORE_KEYS.ADMIN_STAFF:
          params.deliveryOnly = 'admin';
          break;
        default:
          params.deliveryOnly = 'all';
      }
      generateAnnualPayrollSalaryReport(params);
    },
    [annualReportData, year, devCenters]
  );

  const actions = {
    resetFilters,
    changeFilter,
    generateAnnualPayrollReport: () => generateAnnualPayrollReport(annualPayrollPDFReportArguments),
    generateAnnualPayrollExcelReport: () => generateAnnualPayrollExcelReport(annualPayrollExcelReportArguments),
    generateAnnualPayrollSalaryReport: generateSalaryReport,
  };

  useEffect(() => {
    setIndexOfActiveTab(initialTab);
    setSummaryReportType(REPORTS_TYPES.ANNUAL_REPORT);
  }, []);

  const setTabIndex = (index) => {
    if (!isFetching) {
      updateState({ tabIndex: index }, getTabName(index));
      setIndexOfActiveTab(index);
    }
  };

  const onSelectStaff = (selectedStaff) => {
    const selected = get(selectedStaff, 'value', null);

    setAnnualReportData({
      staff: selected,
    });
    changeFilter({
      type: FILTERS_TYPES.SELECT,
      selected,
      storeKey: STORE_KEYS.STAFF,
      currentFilter: STORE_KEYS.ANNUAL_REPORT_COMMON_FILTER,
    });
  };

  const onSelectDevcenter = (selectedDevcenters) => {
    const selected = selectedDevcenters.map(({ value }) => value);

    setAnnualReportData({
      devcenters: selected,
    });
    changeFilter({
      type: FILTERS_TYPES.SELECT,
      selected,
      storeKey: STORE_KEYS.DEVCENTER,
      currentFilter: STORE_KEYS.ANNUAL_REPORT_COMMON_FILTER,
    });
  };

  const onSelectCurrency = (selectedCurrency) => {
    const selected = selectedCurrency.map(({ value }) => value);

    setAnnualReportData({
      currency: selected,
    });
    changeFilter({
      type: FILTERS_TYPES.SELECT,
      selected,
      storeKey: STORE_KEYS.CURRENCY,
      currentFilter: STORE_KEYS.ANNUAL_REPORT_COMMON_FILTER,
    });
  };

  const setDate = (selectedDate) => {
    setBillingSummaryReportData({ selectedDate });
  };

  const layoutProps = {
    changeOrder,
    orderRules,
    entityName,
    isFetching,
    resetFilters,
    changeFilter,
  };

  return (
    <>
      <Controls
        controlsHeading={controlsHeading}
        cssRules={controlsCssRules}
      >
        {
          controls &&
          <>
            {
              controls.map((control, index) => {
                if (control) {
                  const { type, onClick, ...rest } = control;
                  const action = (actionArguments) => onClick({
                    actions,
                    actionArguments,
                  });
                  return (
                    React.createElement(
                      units[type],
                      {
                      // eslint-disable-next-line react/no-array-index-key
                        key: index,
                        onClick: action,
                        ...rest,
                      },
                    )
                  );
                }
                return null;
              })
            }
          </>
        }
        <StyledWrapper
          cssRules={controlsWrapperCssRules}
        >
          <Calendar
            withStepControls
            withYearSelecting
            onChange={setDate}
            minDetails="decade"
            currentDate={moment()}
            maxYear={moment().year() + 1}
            onYearClick={setDate}
            cssRules={yearPickerCssRules}
            value={moment(selectedReportDate)}
            stepControlsConfig={{
              step: 1,
              unitName: 'year',
            }}
            popperProps={{
              placement: 'bottom-start',
            }}
          >
            <StyledWrapper
              cssRules={calendarItemCssRules}
            >
              {year}
            </StyledWrapper>
          </Calendar>

          <FilterSelect
            cssRules={selectCssRules}
            isMultiple
            withChips={false}
            items={devCenters.forSelect}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={onSelectDevcenter}
            placeholder="All Delivery Centers"
            placeholderLength="17.8rem"
          />

          <FilterSelect
            cssRules={selectCssRules}
            // isMultiple
            withChips={false}
            items={staffFilterItems}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={onSelectStaff}
            placeholder="All Staff"
            placeholderLength="9.6rem"
          />

          <FilterSelect
            cssRules={selectCssRules}
            isMultiple
            withChips={false}
            items={currencyFilterItems}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={onSelectCurrency}
            placeholder="All Currencies"
            placeholderLength="13.6rem"
          />


        </StyledWrapper>
      </Controls>

      <NavigationTabs
        indexOfActiveTab={indexOfActiveTab}
        setIndexOfActiveTab={setTabIndex}
        tabHeadings={tabHeadings}
      />
      {
        tabData.map((body, index) => {
          const isVisible = index === indexOfActiveTab;
          const { wrapperCssRules } = tabStylesTemplates[index];

          return (
            <LayoutBodyContent
              key={index} // eslint-disable-line react/no-array-index-key
              body={body}
              isVisible={isVisible}
              wrapperCssRules={wrapperCssRules}
              unitActions={actions}
              {...layoutProps}
            />
          );
        })
      }
      <GoTop />
    </>
  );
};


AnnualReport.propTypes = {
  location: PropTypes.object.isRequired,
  selectedReportDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]).isRequired,
  setBillingSummaryReportData: PropTypes.func.isRequired,
  setAnnualReportData: PropTypes.func.isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  devCenters: PropTypes.shape({}).isRequired,
  entityName: PropTypes.string.isRequired,
  annualReportDetails: PropTypes.shape({
    tabStylesTemplates: PropTypes.array,
    tabHeadings: PropTypes.array,
    tabData: PropTypes.array,
  }).isRequired,
  tab: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  setSummaryReportType: PropTypes.func.isRequired,
  generateAnnualPayrollReport: PropTypes.func.isRequired,
  generateAnnualPayrollSalaryReport: PropTypes.func.isRequired,
};

AnnualReport.defaultProps = {
  tab: '',
};


const mapStateToProps = createStructuredSelector({
  devCenters: selectDevCenters,
  annualReportData: selectAnnualReportData,
  selectedDevCenter: selectSelectedDevCenter,
  annualReportDetails: selectAnnualReportDetails,
});

const mapDispatchToProps = {
  setAnnualReportData: billingReportsActions.setAnnualReportData,
  generateAnnualPayrollReport: filesActions.generateAnnualPayrollReport,
  generateAnnualPayrollExcelReport: filesActions.generateAnnualPayrollExcelReport,
  generateAnnualPayrollSalaryReport: filesActions.generateAnnualPayrollSalaryReport,
  getTotalAnnualPayrollReport: billingReportsActions.getTotalAnnualPayrollReport,
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnualReport);
