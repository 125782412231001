import { colorBackground, colorSecondaryGrayLight, colorSecondaryBackgroundLight, colorSecondaryBackgroundLightDarken10 } from 'assets/styles/variables';
import styled, { css } from 'styled-components';
export const StyledFormHeaderBox = styled.div`
  position: sticky;
  top: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-areas: 'key name';
  grid-template-columns: 10rem 1fr 8.3rem;
  grid-column-gap: 3.2rem;
  grid-row-gap: 0.8rem;
  background-color: ${colorBackground};
  z-index: 1;
  ${({ cssRules }) => css`${cssRules}`}
`;

export const StyledFormContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 4.8rem;
  grid-auto-rows: minmax(4.4rem, auto);
  min-width: auto;
  ${({ cssRules }) => css`${cssRules}`}
`;

export const StyledFormControls = styled.div`
  margin-top: 1.8rem;
  padding: 0 0 2.4rem;
  display: grid;
  grid-template-columns: 25.17rem 25.17rem;
  gap: 1.6rem 1.6rem;
  ${({ cssRules }) => css`${cssRules}`}
`;

export const StyledFormArrayBox = styled.div`
  padding: 0.4rem 0 0;
  border-bottom: 1px solid ${colorSecondaryGrayLight};
  box-sizing: border-box;
  display: grid;
  grid-template-areas: 'key name';
  grid-template-columns: 10rem 1fr 8.3rem;
  grid-auto-rows: minmax(4.4rem,auto);
  grid-column-gap: 3.2rem;
  grid-row-gap: 0.8rem;
  font-size: 1.3rem;
  min-width: auto;
  ${({ cssRules }) => css`${cssRules}`}
`;

export const StyledFieldArrayControls = styled.div`
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(2, 1fr);
  justify-items: ${({ isDeleteIconHidden }) => isDeleteIconHidden ? 'center' : 'start'};
  min-width: auto;
  ${({ cssRules }) => css`${cssRules}`}
`;

export const StyledForm = styled.form`
  box-sizing: border-box;
  display: grid;
  align-content: start;
  grid-column-gap: 4.8rem;
  height: 100%;
  overflow-y: auto;
  padding-right: 0.8rem;
  &::-webkit-scrollbar {
    position: absolute;
    width: 5px;

    &-track {
      background-color: ${colorSecondaryBackgroundLight};
      box-shadow: none;
    }

    &-thumb {
      min-height: auto;
      border-radius: 5px;
      background-color: ${colorSecondaryBackgroundLightDarken10};
    }
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  ${({ cssRules }) => css`${cssRules}`}
`;

export const StyledEmptyTemplate = styled.p`
  margin: 1rem 1.8rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colorSecondaryGrayLight};
`;
