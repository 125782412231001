import update from 'immutability-helper';
import {
  createReducer,
} from 'redux-create-reducer';

import {
  modalConductorActionsTypes,
} from './actions';

const initialState = {
  currentModal: null,
  params: null,
};

export default createReducer(initialState, {
  [modalConductorActionsTypes.CHANGE_CURRENT_MODAL](state, {
    payload,
  }) {
    return update(state, {
      currentModal: {
        $set: payload.currentModal,
      },
      params: {
        $set: payload.params,
      },
    });
  },

  [modalConductorActionsTypes.CLEAR_MODAL]() {
    return initialState;
  },
});
