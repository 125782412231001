import { colorPrimary } from 'assets/styles/variables';

export const yearPickerCssRules = `
  & path {
    fill: ${colorPrimary};
  }
  .calendar__buttons {
    margin-right: 1.6rem;
    .calendar-date-picker__header-control {
      margin-left: 0;
      margin-right: 0.8rem;
    }
  }
  .calendar__toggle-button {
    padding: 0.3rem 0.8rem 0 0.8rem;
  }
`;

export const calendarItemCssRules = `
  padding-top: 0.3rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.7rem;
  color:  ${colorPrimary};
`;

export const selectCssRules = `
  width: unset;
  margin-right: 3.2rem;
  color: ${colorPrimary};
  .filter-select {
    width: auto;
    height: auto;
    &__multi-value {
      margin-top: 0;
    }
    &__multi-value__label {
      color: ${colorPrimary};
    }
    &__placeholder {
      padding-left: 0;
      color: ${colorPrimary};
    }
    &__control--is-focused {
      outline: none !important;
    }
    &__dropdown-indicator {
      path {
        fill: ${colorPrimary};
      }
    }
  }
`;
