import React, { useCallback, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';

import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';

import { FORM_MODES } from 'forms/constants';
import { newTechnicalMentorRecord } from 'layouts/employee-details/components/resource-tabs/technical-mentor/constants';
import { get } from 'lodash';
import moment from 'moment';
import * as yup from 'yup';

import styles from './styles.module.scss';

const validationSchema = yup.object().shape({
  startDate: yup.string()
    .required('Required Field'),
  endDate: yup.string()
    .nullable()
    .test({
      name: 'endDateBeforeStartDate',
      exclusive: true,
      message: 'End Date must be later than Start Date',
      test(endDate) {
        const startDate = this.parent.startDate;
        return !startDate || !endDate || moment(startDate).isBefore(moment(endDate));
      },
    }),
  technicalMentorId: yup.string()
    .required('Required Field'),
});

export const TechnicalMentorForm = ({
  item,
  columns,
  onSubmitHandler,
  onDeleteHandler,
  onRemoveRecord,
  setEditedRecordId,
  editedRecordId,
  recordIdName,
  isCPS,
}) => {
  const { technicalMentorRecordId, allowedForEdit, ...defaultValues } = item;
  const isCreateMode = Boolean(technicalMentorRecordId === newTechnicalMentorRecord[recordIdName]);
  const isDisabled = !isCreateMode && editedRecordId === newTechnicalMentorRecord[recordIdName];

  const {
    handleSubmit,
    control,
    formState: {
      isDirty,
      isValid,
      dirtyFields,
    },
    reset,
  } = useForm({
    values: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback((formData) => {
    if (isDirty && isValid) {
      onSubmitHandler({
        formData,
        allowedForEdit,
        technicalMentorRecordId,
        isCreateMode,
        dirtyFields,
      });
    }
  }, [
    isDirty,
    isValid,
    onSubmitHandler,
    technicalMentorRecordId,
    isCreateMode,
  ]);

  const renderField = useCallback(({
    column,
  }) => {
    if (column.accessor === 'actions') {
      return column.Field({
        onDeleteHandler,
        onRemoveRecord,
        isFormDirty: isDirty,
        resetForm: reset,
        isCreateMode,
        recordId: technicalMentorRecordId,
        setEditedRecordId,
        isAllowedForEdit: allowedForEdit,
        isAllowedForDelete: allowedForEdit,
      });
    }

    return (
      <Controller
        name={column.accessor}
        control={control}
        render={({ field, fieldState }) => column.Field({
          field,
          fieldState,
          item,
          isCreateMode,
        })}
      />
    );
  }, [
    isDirty,
    isCreateMode,
    control,
  ]);

  useEffect(() => {
    if (editedRecordId !== technicalMentorRecordId) {
      reset(defaultValues);
    }
  }, [editedRecordId]);

  useEffect(() => {
    if (isDirty) {
      setEditedRecordId(technicalMentorRecordId);
    }
  }, [isDirty]);

  return (
    <form
      key={get(item, recordIdName, newTechnicalMentorRecord[recordIdName])}
      onSubmit={handleSubmit(onSubmit)}
      className={classNames({
        [styles.disabledForm]: isDisabled,
      })}
    >
      <ul
        className={classNames(styles.bodyStyles, {
          [styles.disabledList]: isDisabled,
        })}
      >
        {columns.map((column) => (
          <li
            key={`${column.accessor}_${technicalMentorRecordId}`}
            data-mode={allowedForEdit ? FORM_MODES.EDIT : FORM_MODES.VIEW}
            className={classNames(styles[column.accessor], {
              [styles.lock]: isCPS,
            })}
          >
            {renderField({
              column,
              currentItem: item,
            })}
          </li>
        ))}
      </ul>
    </form>
  );
};

TechnicalMentorForm.propTypes = {
  item: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  onRemoveRecord: PropTypes.func.isRequired,
  setEditedRecordId: PropTypes.func.isRequired,
  editedRecordId: PropTypes.number,
  onDeleteHandler: PropTypes.func.isRequired,
  recordIdName: PropTypes.string.isRequired,
  isCPS: PropTypes.bool.isRequired,
};

TechnicalMentorForm.defaultProps = {
  editedRecordId: undefined,
};
