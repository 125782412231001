import {
  clientRateCardUpdateNotifications,
} from 'core/configurations/client-rate-card-update-notifications/actions';
import {
  selectRateCardNotificationsVersions,
  selectSendingEmailsProgress,
} from 'core/configurations/client-rate-card-update-notifications/selectors';
import { ENTITY_NAME } from 'core/configurations/constants';
import {
  generateVersion,
  getPayloadWithActualVersion,
} from 'core/configurations/helpers';
import { selectEntityName, selectErrors } from 'core/configurations/selectors';
import { modalConductorActions } from 'core/modal-conductor/actions';
import { TITLE } from 'layouts/rate-change-notifications/constants';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { call, put, select, all, delay } from 'redux-saga/effects';
import { executeMutation, executeQuery, parseError } from 'utils/request';

import { queryConfig } from './queries';

export function* checkRateCardNotificationsStatus({
  payload,
}) {
  try {
    while (true) {
      const data = yield select(selectSendingEmailsProgress);
      if (!payload) return;

      const { rateCardNotificationsStatus } = yield call(executeQuery, {
        query: queryConfig.rateCardNotificationsStatus,
        variables: payload,
      });

      const recordsInProgress = get(rateCardNotificationsStatus, 'recordsInProgress');
      const recordsInProgressFromState = get(data, 'recordsInProgress');

      if (recordsInProgress !== 0 && recordsInProgress === recordsInProgressFromState) {
        yield put(modalConductorActions.clearModal());

        toast.error('Processing Rate Card Notifications has been interrupted. Please start over to finish processing the rest.', {
          containerId: ENTITY_NAME,
          toastId: TITLE,
        });

        yield put(clientRateCardUpdateNotifications.updateRateCardNotificationsStatus(payload));
        return;
      }

      if (!get(rateCardNotificationsStatus, 'inProgress')) {
        yield put(modalConductorActions.clearModal());

        yield put(clientRateCardUpdateNotifications.getRateCardNotifications(payload));
        return;
      }

      if (recordsInProgress) {
        yield put(modalConductorActions.changeCurrentModal({
          currentModal: 'PREVIEW_MODAL',
          params: {
            content: [
              {
                type: 'title',
                data: 'Processing Rate Card Notifications',
              },
              {
                type: 'description',
                data: `${get(rateCardNotificationsStatus, 'recordsInProgress')} Email Notifications are left to be sent.`,
              },
            ],
            parentFieldsData: [],
            withCloseButton: false,
          },
        }));
      }

      yield put(clientRateCardUpdateNotifications.checkRateCardNotificationsStatusSuccess({
        ...rateCardNotificationsStatus,
      }));

      yield delay(5000);
    }
  } catch (error) {
    yield put(modalConductorActions.clearModal());

    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'rateCardNotificationsStatusError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const rateCardNotificationsStatusError = yield call(
      parseError,
      error,
      options
    );

    // eslint-disable-next-line consistent-return
    return yield put(clientRateCardUpdateNotifications.checkRateCardNotificationsStatusFail({
      error: {
        rateCardNotificationsStatusError,
      },
    }));
  }
}

export function* getRateCardsNotifications({ payload }) {
  const { year, version } = payload;
  try {
    const variables = {
      year,
      version,
    };

    const options = {
      query: queryConfig.getRateCardNotifications,
      variables,
    };

    const { rateCardNotifications } = yield call(executeQuery, options);
    const {
      rateCardNotificationsData: notificationsData,
      rateCards: rateCardsForRatecardNotifications,
      notifications,
      billingAgentIds,
      versions,
      statuses,
    } = rateCardNotifications;

    return yield put(clientRateCardUpdateNotifications.getRateCardNotificationsSuccess({
      notificationsData,
      rateCardsForRatecardNotifications,
      notifications,
      billingAgentIds,
      versions,
      statuses,
      version: generateVersion(version, versions),
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getRateCardNotificationsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getRateCardNotificationsError = yield call(
      parseError,
      error,
      options
    );

    return yield put(clientRateCardUpdateNotifications.getRateCardNotificationsFail({
      error: { getRateCardNotificationsError },
    }));
  }
}

export function* sendRatecardNotificationsEmails({ payload: { year, version } }) {
  try {
    const mutation = queryConfig.sendRatecardNotificationsEmails;
    const variables = {
      fields: {
        year,
        version,
      },
    };
    const options = {
      mutation,
      variables,
    };

    yield call(executeMutation, options);

    return yield put(clientRateCardUpdateNotifications.checkRateCardNotificationsStatus({
      year,
      version,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(
      errors,
      'sendRatecardNotificationsEmailsError',
      []
    );
    const options = {
      entityName,
      storedErrors,
    };
    const sendRatecardNotificationsEmailsError = yield call(
      parseError,
      error,
      options
    );

    return yield put(clientRateCardUpdateNotifications.sendRatecardNotificationsEmailsFail({
      error: {
        sendRatecardNotificationsEmailsError,
      },
    }));
  }
}

export function* updateRateCardNotificationsStatus({ payload: { year, version } }) {
  try {
    const mutation = queryConfig.updateRateCardNotificationsStatus;
    const variables = {
      fields: {
        year,
        versionId: version,
      },
    };
    const options = {
      mutation,
      variables,
    };
    yield call(executeMutation, options);

    return yield put(clientRateCardUpdateNotifications.getRateCardNotifications({ year, version }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'updateRateCardNotificationsStatusError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const updateRateCardNotificationsStatusError = yield call(
      parseError,
      error,
      options
    );

    return yield put(clientRateCardUpdateNotifications.updateRateCardNotificationsStatusFail({
      error: {
        updateRateCardNotificationsStatusError,
      },
    }));
  }
}

export function* updateRateCardNotifications({ payload: { fields } }) {
  try {
    const mutation = queryConfig.updateRateCardNotifications;
    const variables = {
      fields,
    };
    const options = {
      mutation,
      variables,
    };
    yield call(executeMutation, options);

    const { rateCardNotifications } = yield call(executeQuery, {
      query: queryConfig.getRateCardNotifications,
      variables: {
        year: fields.year,
        version: fields.version,
      },
    });

    return yield put(clientRateCardUpdateNotifications.updateRateCardNotificationsSuccess({
      rateCardNotificationsData: get(rateCardNotifications, 'rateCardNotificationsData'),
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'updateRateCardNotificationsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const updateRateCardNotificationsError = yield call(
      parseError,
      error,
      options
    );

    return yield put(clientRateCardUpdateNotifications.updateRateCardNotificationsFail({
      error: {
        updateRateCardNotificationsError,
      },
    }));
  }
}

export function* createRatecardNotificationsVersion({ payload: { fields } }) {
  try {
    const mutation = queryConfig.createRatecardNotificationsVersion;
    const variables = {
      fields,
    };
    const options = {
      mutation,
      variables,
    };
    const response = yield call(executeMutation, options);
    const { rateCardNotificationsData, versions } =
      response.createRatecardNotificationsVersion.ratecardNotifications;

    return yield all([
      put(clientRateCardUpdateNotifications.createRatecardNotificationsVersionSuccess({
        rateCardNotificationsData,
        versions,
      })),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(
      errors,
      'createRatecardNotificationsVersionError',
      []
    );
    const options = {
      entityName,
      storedErrors,
    };
    const createRatecardNotificationsVersionError = yield call(
      parseError,
      error,
      options
    );

    return yield put(clientRateCardUpdateNotifications.createRatecardNotificationsVersionFail({
      error: {
        createRatecardNotificationsVersionError,
      },
    }));
  }
}

export function* deleteRatecardNotificationsVersion({ payload }) {
  try {
    const mutation = queryConfig.deleteRateCardNotificationsVersion;
    const variables = {
      fields: {
        versionId: payload.version,
        year: payload.year,
      },
    };
    const options = {
      mutation,
      variables,
    };
    yield call(executeMutation, options);

    const rateCardNotificationsVersions = yield select(selectRateCardNotificationsVersions);

    return yield all([
      yield put(clientRateCardUpdateNotifications.deleteRatecardNotificationsVersionSuccess()),
      yield put(clientRateCardUpdateNotifications.getRateCardNotifications(getPayloadWithActualVersion(rateCardNotificationsVersions, payload))),
    ]);
  } catch (error) {
    const message = get(error, 'extensions.detailed', null);
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(
      errors,
      'deleteRateCardNotificationsVersionError',
      []
    );
    const options = {
      entityName,
      storedErrors,
    };
    const currentError = message ? {
      message,
    } : error;

    const deleteRateCardNotificationsVersionError = yield call(
      parseError,
      currentError,
      options,
    );

    return yield put(clientRateCardUpdateNotifications.deleteRatecardNotificationsVersionFail({
      error: {
        deleteRateCardNotificationsVersionError,
      },
    }));
  }
}
