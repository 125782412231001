import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Unit from './unit';


const StyledDiv = styled.div`
  box-sizing: border-box;
  ${({ cssRules }) => css`${cssRules}`}
`;


const InfoCard = ({ cssRules, data, ...rest }) => (
  <StyledDiv
    cssRules={cssRules}
  >
    {
      data.map((unit, unitIndex) => (
        <Unit
          key={unitIndex} // eslint-disable-line react/no-array-index-key
          unit={unit}
          {
          ...rest
          }
        />
      ))
    }
  </StyledDiv>
);


InfoCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.bool,
  ])),
  cssRules: PropTypes.string,
};

InfoCard.defaultProps = {
  data: [],
  cssRules: '',
};


export default InfoCard;
