import React from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { fromBottomToTop } from 'assets/animations';
import ConfirmActionModal from 'components/confirm-action-modal';

import EditMsaModal from 'components/edit-msa-modal';
import Loader from 'components/loader-modal';
import ManagePtoModal from 'components/manage-pto-modal';
import PreviewModal from 'components/preview-modal';
import SendForSignature from 'components/send-for-signature-modal';
import SendReviewModal from 'components/send-review-modal';
import UpdateReviewsModal from 'components/update-reviews-modal';
import { channelPartnersActions } from 'core/channel-partners/actions';

import { clientsActions } from 'core/clients/actions';

import {
  selectOwnerEmail,
  selectIsClientsProcessed,
} from 'core/clients/selectors';
import { selectCurrentIsFormSubmitted } from 'core/common/selectors';
import { configurationsActions } from 'core/configurations/actions';
import { dashboardActions } from 'core/dashboard/actions';
import { deliveryActions } from 'core/delivery/actions';
import { employeesReviewsActions } from 'core/employees-reviews/actions';
import { selectIsFormSubmitted as selectIsEmployeeFormSubmitted } from 'core/employees-reviews/selectors';
import { filesActions } from 'core/files/actions';
import { modalConductorActions } from 'core/modal-conductor/actions';

import { MODALS } from 'core/modal-conductor/constants';
import {
  selectCurrentModal,
  selectParams,
} from 'core/modal-conductor/selectors';
import { taskOrdersActions } from 'core/task-orders/actions';
import { userActions } from 'core/user/actions';
import { Transition } from 'react-spring/renderprops';
import { createStructuredSelector } from 'reselect';

const {
  LOADER,
  MANAGE_PTO_MODAL,
  SEND_REVIEW_MODAL,
  UPDATE_REVIEWS_MODAL,
  CONFIRM_ACTION_MODAL,
  SEND_FOR_SIGNATURE_MODAL,
  PREVIEW_MODAL,
  EDIT_MSA_MODAL,
} = MODALS;
const modalWindows = {
  [LOADER]: Loader,
  [MANAGE_PTO_MODAL]: ManagePtoModal,
  [SEND_REVIEW_MODAL]: SendReviewModal,
  [UPDATE_REVIEWS_MODAL]: UpdateReviewsModal,
  [CONFIRM_ACTION_MODAL]: ConfirmActionModal,
  [SEND_FOR_SIGNATURE_MODAL]: SendForSignature,
  [PREVIEW_MODAL]: PreviewModal,
  [EDIT_MSA_MODAL]: EditMsaModal,
};

const ModalConductor = ({
  currentModal,
  isFormSubmitted,
  isClientsProcessed,
  ...props
}) => {
  const isProcessed = isClientsProcessed || isFormSubmitted;

  return (
    <Transition
      items={
        currentModal ?
          React.createElement(modalWindows[currentModal], {
            isProcessed,
            isFormSubmitted,
            ...props,
          }) :
          null
      }
      from={fromBottomToTop.from}
      enter={fromBottomToTop.enter}
      leave={fromBottomToTop.leave}
    >
      {/* eslint-disable-next-line react/no-unstable-nested-components */}
      {(item) => (stylesProps) => <div style={stylesProps}>{item}</div>}
    </Transition>
  );
};

ModalConductor.propTypes = {
  currentModal: PropTypes.string,
  isFormSubmitted: PropTypes.bool,
  isClientsProcessed: PropTypes.bool,
};

ModalConductor.defaultProps = {
  currentModal: '',
  isFormSubmitted: false,
  isClientsProcessed: false,
};

const mapStateToProps = createStructuredSelector({
  params: selectParams,
  ownerEmail: selectOwnerEmail,
  currentModal: selectCurrentModal,
  isFormSubmitted: selectCurrentIsFormSubmitted,
  isClientsProcessed: selectIsClientsProcessed,
  isEmployeeFormSubmitted: selectIsEmployeeFormSubmitted,
});

const mapDispatchToProps = {
  signDocument: filesActions.signDocument,
  clearModal: modalConductorActions.clearModal,
  deletePLARecord: deliveryActions.deletePLARecord,
  deleteClientDocument: clientsActions.deleteDocument,
  deleteChannelPartnerDocument: channelPartnersActions.deleteDocument,
  sendPdfReviews: employeesReviewsActions.sendPdfReviews,
  updateClientDetails: clientsActions.updateClientDetails,
  deleteTaskOrderDocument: taskOrdersActions.deleteDocument,
  deleteWorkbookRecordDelivery: deliveryActions.deleteWorkbookRecord,
  deleteWorkbookRecordDashboard: dashboardActions.deleteWorkbookRecord,
  updateWorkbookRecord: deliveryActions.updateWorkbookRecord,
  deleteEmployeesRecord: deliveryActions.deleteEmployeesRecord,
  deleteManagePtoRecord: deliveryActions.deleteManagePtoRecord,
  updatePtoRequestRecord: deliveryActions.updatePtoRequestRecord,
  deletePtoRequestRecord: deliveryActions.deletePtoRequestRecord,
  toggleWorkBookRecordState: deliveryActions.toggleWorkBookRecordState,
  togglePtoRequestRecordState: deliveryActions.togglePtoRequestRecordState,
  createVacationManagementRecord:
    deliveryActions.createVacationManagementRecord,
  deleteVacationManagementRecord:
    deliveryActions.deleteVacationManagementRecord,
  toggleWorkBookRecordStateOnDashboard:
    dashboardActions.toggleWorkBookRecordState,
  removeCommissionRecord: configurationsActions.removeCommissionRecord,
  removeHolidaysRecord: configurationsActions.removeHolidaysRecord,
  removeHiqoContractsRecord: configurationsActions.removeHiqoContractsRecord,
  deleteRateCardRecord: configurationsActions.deleteRateCardRecord,
  deleteEmailTemplateRecord: configurationsActions.deleteEmailTemplateRecord,
  removeUserLogsRecord: userActions.removeUserLogsRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalConductor);
