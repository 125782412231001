import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import WrapperNg from 'elements/wrapper-ng';
import { children as childrenType } from 'utils/validation/prop-types';

import './styles.scss';

const propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  children: childrenType,
};

const defaultProps = {
  className: '',
  children: undefined,
};

const PageTitle = ({
  className,
  text,
  children,
}) => (
  <div className={classNames(className, 'page-title')}>
    <WrapperNg>
      <h1>{text}</h1>
      {children}
    </WrapperNg>
  </div>
);

PageTitle.propTypes = propTypes;
PageTitle.defaultProps = defaultProps;

export default PageTitle;
