import {
  selectUserGroup,
  selectUserId,
  selectCustomers,
} from 'core/auth/selectors';
import { clientDetailsModelGetter } from 'layouts/client-details/model';
import { clientsListModelGetter } from 'layouts/clients-list/model';
import { createSelector } from 'reselect';

import {
  modelParser,
  parseDetails,
  filtersConfigGetter,
  filtersItemsGetter,
} from 'utils/helpers/models';

export const selectClientsData = (state) => state.clients.clientsData;
export const selectErrors = (state) => state.clients.errors;
export const selectEntityName = (state) => state.clients.entityName;
export const selectCurrentClientDetails = (state) => state.clients.clientDetails;
export const selectTaskOrderToCopyId = (state) => state.clients.taskOrderToCopyId;
export const selectNumberOfClientsFiles = (state) => state.clients.clientDetails.files;
export const selectRequestsCounter = (state) => state.clients.requestsCounter;
export const selectMsaDate = (state) => state.clients.msaDate;
export const selectDevcenters = (state) => state.clients.clientDetails.devcenters;
export const selectExcludedDevcenters = (state) => state.clients.clientDetails.excludedDevcenters;
export const selectUsersList = (state) => state.auth.usersList;

export const selectOwnerEmail = createSelector(
  selectUsersList,
  selectUserId,
  (users, userId) => {
    const user = users.find(({
      userId: id,
    }) => id === userId);

    return user && user.email;
  }
);
export const selectClientsListModel = createSelector(
  selectUserGroup,
  (group) => clientsListModelGetter(group)
);
export const selectClientDetailsModel = createSelector(
  selectUserGroup,
  selectUserId,
  selectCustomers,
  selectCurrentClientDetails,
  selectTaskOrderToCopyId,
  clientDetailsModelGetter
);
export const selectOrderRules = (state) => state.clients.orderRules;
export const selectClientDetails = createSelector(
  selectCurrentClientDetails,
  selectOrderRules,
  selectClientDetailsModel,
  selectMsaDate,
  selectUserGroup,
  (clientDetails, orderRules, clientDetailsModel, msaDate) => {
    const { dataTemplate } = clientDetailsModel;

    const updatedClientDetailsModel = {
      ...clientDetailsModel,
      dataTemplate,
    };

    return parseDetails(clientDetails, updatedClientDetailsModel, orderRules, msaDate);
  }
);
export const selectFavoritesClients = (state) => state.clients.favoritesClients;
export const selectIsFavoredClient = createSelector(
  selectFavoritesClients,
  selectClientDetails,
  (favoritesClients, clientDetails) => clientDetails.clientId ? favoritesClients[clientDetails.clientId] || false : false
);

export const selectFilters = (state) => state.clients.filters;
const selectFilterItems = createSelector(
  selectClientsData,
  selectClientsListModel,
  selectFilters,
  filtersItemsGetter,
);

export const selectIsFetchingClientsList = (state) => state.clients.isFetching;
export const selectIsFetchingDetails = (state) => state.clients.isFetchingDetails;
export const selectIsFetchingFiles = (state) => state.clients.isFetchingFiles;
export const selectIsFilteringClientsList = (state) => state.clients.isFiltering;
export const selectIsClientDetailsFormSubmitted = (state) => state.clients.isFormSubmitted;
export const selectIsClientsProcessed = createSelector(
  selectIsFetchingFiles,
  selectIsFetchingDetails,
  selectIsFetchingClientsList,
  selectIsFilteringClientsList,
  selectIsClientDetailsFormSubmitted,
  (isFetchingList, isFetchingDetails, isFetchingFiles, isFiltering, isClientDetailsFormSubmitted) => (
    isFetchingList || isFetchingDetails || isFetchingFiles || isFiltering || isClientDetailsFormSubmitted
  )
);
export const selectFilterConfig = createSelector(
  selectFilters,
  selectFilterItems,
  selectClientsListModel,
  filtersConfigGetter,
);

const selectActiveFilters = createSelector(
  selectFilters,
  (filters) => Object.entries(filters).filter(([_, value]) => value.isActive) // eslint-disable-line no-unused-vars
);

export const selectHasFilters = createSelector(
  selectActiveFilters,
  (filters) => !!filters.length
);
export const selectAlphabetFilter = createSelector(
  selectFilters,
  (filters) => filters.startFrom
);

export const selectedClientsData = createSelector(
  selectClientsData,
  selectFavoritesClients,
  selectOrderRules,
  selectActiveFilters,
  selectClientsListModel,
  (clientsData, favoritesClients, orderRules, filters, {
    tableName,
    dataTemplate,
    filtersTemplate,
    rowStatusGetter,
  }) => {
    const options = {
      favorites: favoritesClients,
      orderRules: orderRules[tableName],
      filters,
      idKey: 'clientId',
      tableName,
      rowStatusGetter,
      filtersTemplate,
    };

    const {
      data,
    } = modelParser(clientsData, dataTemplate, options);

    return data;
  }
);
export const selectClientsCount = createSelector(
  selectClientsData,
  (clients) => clients.length,
);
