import React, { useState, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { SELECT_ACTIONS } from 'components/form-controls/constants';
import ErrorMessage from 'elements/error-message';
import CustomLink from 'elements/link';
import { isEqual, get } from 'lodash';
import ReactSelect, { components, Creatable } from 'react-select';
import styled, { css } from 'styled-components';

import { hasProperty } from 'utils/helpers/objects';

import cssStyles from './styles.module.scss';

const getInput = (inputProps) => {
  const { autoComplete, type, ...rest } = inputProps;
  return (
    <components.Input
      autoComplete="new-password"
      // type="text"
      {...rest}
    />
  );
};

const getMenuList = (menuListProps) => {
  const { selectProps: { isMulti } } = menuListProps;

  return (
    <>
      <components.MenuList
        {...menuListProps}
      >
        {
          menuListProps.children
        }
      </components.MenuList>

      {
        isMulti && (
          <button
            className="button button--cancel select-item__button"
            onClick={() => window.document.activeElement.blur()}
            tabIndex={-1}
          >
            Ok
          </button>
        )
      }
    </>

  );
};

const getPlaceholder = (placeholderProps) => {
  const { selectProps: { required } } = placeholderProps;

  return (
    <components.Placeholder
      {...placeholderProps}
    >
      {placeholderProps.children}

      {
        !required && (
          <span className="text-input__optional-label">
            &nbsp;(optional)
          </span>
        )
      }
    </components.Placeholder>
  );
};

const getControl = (controlProps) => {
  const { hasValue, isFocused, selectProps: { placeholder, withLabel } } = controlProps;

  return (
    <components.Control
      {...controlProps}
    >
      {
        controlProps.children
      }

      {
        withLabel && (
          <p
            className={
              classNames(
                'select-item__label',
                { 'select-item__label--hidden': !hasValue && !isFocused }
              )
            }
          >
            {placeholder}
          </p>
        )
      }
    </components.Control>
  );
};

const getSingleValue = (singleValueProps) => {
  const { selectProps: { isLink, path, href } } = singleValueProps;

  return (
    <components.SingleValue
      {...singleValueProps}
    >
      {
        isLink ? (
          <CustomLink
            className="select-item__link"
            data={singleValueProps.children}
            pathname={path}
            href={href}
            withBlank
          />
        ) :
          singleValueProps.children
      }
    </components.SingleValue>
  );
};

const StyledDiv = styled.div`
  font-size: 1.5rem;

  .select-item__single-value {
    overflow: hidden;
  }

  ${({ cssRules }) => css`${cssRules}`}`;

const getCustomStyles = (styles) => ({
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  }),
  ...styles,
});

const Select = ({
  fieldData: {
    href,
    path,
    isLink,
    selected,
    styles = {},
    optionsFilter,
    items: options,
    hideSelectedOptions = false,
  },
  name,
  error,
  isMulti,
  onChange,
  cssRules,
  isLocked,
  withLabel,
  withError,
  placeholder,
  isCreatable,
  withWarning,
  withErrorBox,
  menuPosition,
  warningMessage,
  validationRules,
  defaultMenuIsOpen,
}) => {
  const customStyles = useMemo(() => getCustomStyles(styles), [styles]);
  const { isRequired, isNumeric } = validationRules;
  const [currentItem, setCurrentItem] = useState(selected || {});

  useEffect(() => {
    if (!isEqual(selected, currentItem)) {
      setCurrentItem(selected);
    }
  }, [selected]);

  const filterOption = ({ label, ...rest }, term) => {
    const subString = String(term).toLowerCase();
    const optionLabel = String(label).toLowerCase();

    return optionsFilter ?
      optionsFilter({ label, term, ...rest }) &&
      optionLabel.includes(subString) :
      optionLabel.includes(subString);
  };

  const updateSelect = (data, selectedItem) => {
    if (data.value === '*') {
      setCurrentItem(null);
      return onChange(null, currentItem);
    }

    if (isMulti) {
      const isExcludeAll = get(selectedItem.option, 'isExcludeAll', false);
      const action = get(selectedItem, 'action', SELECT_ACTIONS.DESELECT_OPTION);

      if (isExcludeAll && action === SELECT_ACTIONS.SELECT_OPTION) {
        const allItems = data.filter(({ label }) => label === 'All');
        setCurrentItem(allItems);
        return onChange(allItems.map(({ value }) => value), currentItem);
      }

      setCurrentItem(currentItem);
      return onChange(data.reduce((currentData, { value }) => value ? [...currentData, value] : currentData, []), currentItem);
    }

    setCurrentItem(data);
    return onChange(isNumeric ? Number(data.value) : data.value);
  };

  const hasItems = !!(options && options.length);

  const value = Array.isArray(currentItem) ||
  (hasProperty(currentItem, 'label') && (currentItem.label || currentItem.label === 0)) ?
    currentItem :
    null;
  const getOptionValue = ({ value: optionValue }) => optionValue || '';

  const onCreateOption = (option) => {
    setCurrentItem({
      label: option,
      value: option,
    });
    onChange(option);
  };

  return (
    <StyledDiv
      className={
        classNames(
          cssStyles.formSelect,
          { [cssStyles.error]: withError }
        )
      }
      cssRules={cssRules}
    >
      {
        React.createElement(
          isCreatable ?
            Creatable :
            ReactSelect,
          {
            name,
            path,
            href,
            value,
            isLink,
            isMulti,
            options,
            withLabel,
            placeholder,
            menuPosition,
            filterOption,
            inputId: name,
            onCreateOption,
            getOptionValue,
            isClearable: false,
            hideSelectedOptions,
            styles: customStyles,
            required: isRequired,
            tabSelectsValue: false,
            onChange: updateSelect,
            defaultMenuIsOpen,
            className: 'select-item',
            closeMenuOnSelect: !isMulti,
            classNamePrefix: 'select-item',
            error: withErrorBox ? '' : error,
            isDisabled: !hasItems || isLocked,
            components: {
              Placeholder: getPlaceholder,
              Control: getControl,
              SingleValue: getSingleValue,
              MenuList: getMenuList,
              Input: getInput,
            },
          }
        )
      }
      {
        withError && hasItems && !isLocked && !withErrorBox && (
          <ErrorMessage
            isVisible={withError}
          >
            {withError ? error : warningMessage}
          </ErrorMessage>
        )
      }

      {
        (!withError && !!withWarning) && hasItems && !isLocked && !withErrorBox && (
          <ErrorMessage
            isVisible={!!warningMessage}
            withIcon
          >
            {withError ? error : warningMessage}
          </ErrorMessage>
        )
      }

    </StyledDiv>
  );
};

Select.propTypes = {
  withErrorBox: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isLocked: PropTypes.bool,
  validationRules: PropTypes.shape({
    isNumeric: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
  warningMessage: PropTypes.string,
  withWarning: PropTypes.bool,
  error: PropTypes.string,
  withError: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  fieldData: PropTypes.shape({
    isLink: PropTypes.bool,
    path: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })),
    selected: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      })),
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ]),
  }),
  cssRules: PropTypes.string,
  withLabel: PropTypes.bool,
  menuPosition: PropTypes.string,
  defaultMenuIsOpen: PropTypes.bool,
};

Select.defaultProps = {
  isCreatable: false,
  withErrorBox: false,
  isMulti: false,
  styles: {},
  isLocked: false,
  warningMessage: '',
  withWarning: false,
  validationRules: {},
  fieldData: {
    selected: {
      value: '',
      label: '',
    },
    items: [],
    isLink: false,
    path: '',
  },
  onChange: () => null,
  error: '',
  withError: false,
  placeholder: '',
  cssRules: '',
  withLabel: true,
  menuPosition: 'absolute',
  defaultMenuIsOpen: false,
};

export default Select;
