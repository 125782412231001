/* eslint-disable no-restricted-syntax */
import {
  clientRateCardUpdateNotificationsReducers,
} from 'core/configurations/client-rate-card-update-notifications/reducers';
import {
  emailTemplateNotificationsReducers,
} from 'core/configurations/email-template-notifications/reducers';
import update from 'immutability-helper';
import { get, findIndex } from 'lodash';
import moment from 'moment';
import { createReducer } from 'redux-create-reducer';

import { configurationsActionsTypes } from './actions';

import {
  CLIENT_RATE_CARD_FILTER_STATE,
  EMAIL_TEMPLATES_FILTER_STATE,
  ENTITY_NAME,
} from './constants';

const initialState = {
  entityName: ENTITY_NAME,
  isFetching: true,
  isFormSubmitted: false,
  errors: {},
  exchangeRates: [],
  commissionRates: [],
  commissionRatesUsers: {},
  rateCards: [],
  emailTemplates: [],
  lastRates: [],
  rateCardsActiveIndex: 0,
  emailTemplatesActiveIndex: 0,
  emailsData: [],
  emailTemplateNotificationsVersion: null,
  emailTemplateNotificationsVersions: [],
  billingAgents: [],
  billingAgentIds: [],
  clientRateCardNotifications: {
    notificationsType: [],
    billingAgentIds: [],
    rateCardNotificationsStatuses: [],
    rateCardNotifications: [],
    rateCardsForRatecardNotifications: [],
    rateCardNotificationsVersion: null,
    rateCardNotificationsVersions: [],
  },
  emailTemplateNotifications: {
    notificationsType: [],
    billingAgentIds: [],
    emailTemplateNotificationsStatuses: [],
    emailTemplateNotificationsData: [],
    emailTemplates: [],
    emailTemplateNotificationsVersion: null,
    emailTemplateNotificationsVersions: [],
  },
  holidays: [],
  holidaysSelectedYear: moment().year(),
  hiqoContracts: [],
  discountSurchargeRates: [],
  billLockDate: null,
  rateCardsSelectedYear: moment().year(),
  emailTemplatesSelectedYear: moment().year(),
  filter: [],
  sendingEmailsProgress: {
    notifications: {
      clientRateCard: {
        recordsInProgress: 0,
        inProgress: false,
        isActive: false,
      },
      emailTemplate: {
        recordsInProgress: 0,
        inProgress: false,
        isActive: false,
      },
    },
  },
  filters: {
    notifications: {
      clientRateCard: CLIENT_RATE_CARD_FILTER_STATE,
      emailTemplate: EMAIL_TEMPLATES_FILTER_STATE,
    },
  },
  orderRules: {
    commissionRates: {
      orderBy: 'Full Name',
      defaultOrderBy: 'Full Name',
      isReversed: false,
      isSeparatedByFavorites: true,
    },
    exchangeRates: {
      orderBy: 'Years',
      defaultOrderBy: 'Years',
      isReversed: false,
      isSeparatedByFavorites: true,
    },
    rateCardNotifications: {
      orderBy: 'Names',
      defaultOrderBy: 'Names',
      isReversed: false,
      isSeparatedByFavorites: true,
    },
    holidays: {
      orderBy: 'Upload Date',
      defaultOrderBy: 'Upload Date',
      isReversed: false,
      isSeparatedByFavorites: true,
    },
    hiqoContracts: {
      orderBy: 'Name',
      defaultOrderBy: 'Name',
      isReversed: false,
      isSeparatedByFavorites: true,
    },
  },
  createMode: false,
};

export default createReducer(initialState, {
  [configurationsActionsTypes.GET_EXCHANGE_RATES](state) {
    return update(state, {
      isFetching: { $set: true },
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [configurationsActionsTypes.GET_EXCHANGE_RATES_SUCCESS](
    state,
    { payload: { exchangeRates } }
  ) {
    const isCurrentYearInRates = exchangeRates
      .map((item) => item.year)
      .includes(moment().year());
    if (!isCurrentYearInRates) {
      const currentYearPlaceholder = {
        year: moment().year(),
        monthData: [
          { month: 1, usdRur: null, usdPln: null },
          { month: 2, usdRur: null, usdPln: null },
          { month: 3, usdRur: null, usdPln: null },
          { month: 4, usdRur: null, usdPln: null },
          { month: 5, usdRur: null, usdPln: null },
          { month: 6, usdRur: null, usdPln: null },
          { month: 7, usdRur: null, usdPln: null },
          { month: 8, usdRur: null, usdPln: null },
          { month: 9, usdRur: null, usdPln: null },
          { month: 10, usdRur: null, usdPln: null },
          { month: 11, usdRur: null, usdPln: null },
          { month: 12, usdRur: null, usdPln: null },
        ],
      };
      exchangeRates.push(currentYearPlaceholder);
    }
    return update(state, {
      isFetching: { $set: false },
      isFormSubmitted: {
        $set: false,
      },
      exchangeRates: { $set: exchangeRates },
      errors: {
        $unset: ['getExchangeRatesError'],
      },
    });
  },

  [configurationsActionsTypes.GET_EXCHANGE_RATES_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: { $set: false },
      isFormSubmitted: {
        $set: false,
      },
      errors: { $merge: error },
    });
  },

  [configurationsActionsTypes.UPDATE_EXCHANGE_RATES](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [configurationsActionsTypes.UPDATE_EXCHANGE_RATES_SUCCESS](
    state,
    { payload }
  ) {
    const exchangeRates = get(state, 'exchangeRates', {});
    const changedYears = Object.keys(payload);
    const updatedRates = exchangeRates.map((item) => {
      const isChanged = changedYears.includes(item.year.toString());
      return isChanged ? payload[item.year] : item;
    });

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      exchangeRates: { $set: updatedRates },
      errors: {
        $unset: ['getExchangeRatesError'],
      },
    });
  },

  [configurationsActionsTypes.UPDATE_EXCHANGE_RATES_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: { $set: false },
      isFormSubmitted: {
        $set: false,
      },
      errors: { $merge: error },
    });
  },

  [configurationsActionsTypes.GET_COMMISSION_RATES](state) {
    return update(state, {
      isFetching: { $set: true },
    });
  },

  [configurationsActionsTypes.GET_COMMISSION_RATES_SUCCESS](
    state,
    { payload: { commissionRates } }
  ) {
    const allReporters = {};
    commissionRates.forEach((item) => {
      allReporters[item.userId] = item.fullName;
    });
    function compare(a, b) {
      return moment(a.date).isAfter(b.date) ? 1 : -1;
    }
    const commissionRatesWithSortedRecords = commissionRates.map((item) => {
      item.records.sort(compare);
      return item;
    });
    return update(state, {
      isFetching: { $set: false },
      errors: {
        $unset: ['getCmmissionRatesError'],
      },
      commissionRates: { $set: commissionRatesWithSortedRecords },
      commissionRatesUsers: { $set: allReporters },
    });
  },

  [configurationsActionsTypes.GET_COMMISSION_RATES_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: { $set: false },
      errors: { $merge: error },
    });
  },

  [configurationsActionsTypes.CREATE_COMMISSION_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [configurationsActionsTypes.CREATE_COMMISSION_RECORD_SUCCESS](
    state,
    { payload: { userId, fields } }
  ) {
    const commissionRates = get(state, 'commissionRates', {});
    const userIndex = findIndex(commissionRates, { userId });
    const records = get(commissionRates[userIndex], 'records', []);

    return update(state, {
      errors: {
        $unset: ['createCommissionRecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
      commissionRates: {
        [userIndex]: {
          $merge: {
            records: [fields.records[0], ...records],
          },
        },
      },
    });
  },

  [configurationsActionsTypes.CREATE_COMMISSION_RECORD_FAIL](
    state,
    { payload: { error } }
  ) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },
  [configurationsActionsTypes.REMOVE_COMMISSION_RECORD](
    state
  ) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.REMOVE_COMMISSION_RECORD_SUCCESS](
    state,
    { payload: { recordId, userId } }
  ) {
    const commissionRates = get(state, 'commissionRates', {});
    const userIndex = findIndex(commissionRates, { userId });
    const records = get(commissionRates[userIndex], 'records', []);
    const removedRecordIndex = findIndex(records, { recordId });

    return update(state, {
      errors: {
        $unset: ['removeCommissionRecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
      commissionRates: {
        [userIndex]: {
          $merge: {
            records: [
              ...records.slice(0, removedRecordIndex),
              ...records.slice(removedRecordIndex + 1),
            ],
          },
        },
      },
    });
  },
  [configurationsActionsTypes.REMOVE_COMMISSION_RECORD_FAIL](
    state,
    { payload: { error } }
  ) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [configurationsActionsTypes.UPDATE_COMMISSION_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [configurationsActionsTypes.UPDATE_COMMISSION_RECORD_SUCCESS](
    state,
    { payload: { userId, userRecords } }
  ) {
    const commissionRates = get(state, 'commissionRates', {});
    const userIndex = findIndex(
      commissionRates,
      (item) => item.userId === userId
    );
    const records = get(commissionRates[userIndex], 'records', []);

    const recordsToUpdate = records.map((item) => {
      let record = item;
      userRecords.forEach((userRecord) => {
        if (record.recordId === userRecord.recordId) {
          record = userRecord;
        }
      });
      return record;
    });
    return update(state, {
      errors: {
        $unset: ['updateCommissionRecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
      commissionRates: {
        [userIndex]: {
          records: {
            $merge: {
              ...recordsToUpdate,
            },
          },
        },
      },
    });
  },

  [configurationsActionsTypes.UPDATE_COMMISSION_RECORD_FAIL](
    state,
    { payload: { error } }
  ) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [configurationsActionsTypes.SET_ORDER_RULES](
    state,
    { payload: { tableName, orderRules } }
  ) {
    return update(state, {
      orderRules: {
        [tableName]: {
          $set: orderRules,
        },
      },
    });
  },

  [configurationsActionsTypes.GET_RATE_CARDS](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },

  [configurationsActionsTypes.GET_EMAIL_TEMPLATES_SUCCESS](
    state,
    { payload: { emailTemplates, date } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      emailTemplates: {
        $set: emailTemplates,
      },
      emailTemplatesActiveIndex: {
        $set: 0,
      },
      emailTemplatesSelectedYear: {
        $set: date,
      },
      errors: {
        $unset: ['getEmailTemplatesError'],
      },
    });
  },
  [configurationsActionsTypes.GET_EMAIL_TEMPLATES_FAIL](state) {
    return update(state, {
      isFetching: {
        $set: false,
      },
    });
  },

  [configurationsActionsTypes.CREATE_EMAIL_TEMPLATE_RECORD](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.CREATE_EMAIL_TEMPLATE_RECORD_SUCCESS](
    state,
    { payload: { emailTemplate } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      emailTemplates: {
        $set: [...state.emailTemplates, emailTemplate],
      },
      emailTemplatesActiveIndex: {
        $set: state.emailTemplates.length,
      },
      createMode: {
        $set: false,
      },
      errors: {
        $unset: ['getEmailTemplatesError'],
      },
    });
  },
  [configurationsActionsTypes.CREATE_EMAIL_TEMPLATE_RECORD_FAIL](
    state,
    { paylod: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [configurationsActionsTypes.UPDATE_EMAIL_TEMPLATE_RECORD](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.UPDATE_EMAIL_TEMPLATE_RECORD_SUCCESS](
    state,
    { payload: { emailTemplate } }
  ) {
    return update(state, {
      emailTemplates: {
        $set: state.emailTemplates.map((item) => item.emailTemplateId === emailTemplate.emailTemplateId ?
          emailTemplate :
          item),
      },
      isFetching: {
        $set: false,
      },
      createMode: {
        $set: false,
      },
      errors: {
        $unset: ['updateEmailTemplateRecordError'],
      },
    });
  },
  [configurationsActionsTypes.UPDATE_EMAIL_TEMPLATE_RECORD_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [configurationsActionsTypes.REMOVE_EMAIL_TEMPLATE_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.REMOVE_EMAIL_TEMPLATE_RECORD_SUCCESS](
    state,
    { payload: { emailTemplateId } }
  ) {
    const emailTemplates = state.emailTemplates;
    const removedRecordIndex = findIndex(emailTemplates, { emailTemplateId });
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      emailTemplates: {
        $set: [
          ...emailTemplates.slice(0, removedRecordIndex),
          ...emailTemplates.slice(removedRecordIndex + 1),
        ],
      },
      emailTemplatesActiveIndex: {
        $set: 0,
      },
      errors: {
        $unset: ['deleteRateCardRecordError'],
      },
    });
  },
  [configurationsActionsTypes.REMOVE_EMAIL_TEMPLATE_RECORD_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [configurationsActionsTypes.UPLOAD_EMAIL_TEMPLATE_FILE_SUCCESS](
    state,
    { payload: { emailTemplateId, file, fileId } }
  ) {
    return update(state, {
      emailTemplates: {
        $set: state.emailTemplates.map((emailTemplate) => emailTemplateId === emailTemplate.emailTemplateId ?
          {
            ...emailTemplate,
            file,
            fileId,
          } :
          emailTemplate),
      },
    });
  },
  [configurationsActionsTypes.UPLOAD_EMAIL_TEMPLATE_FILE_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [configurationsActionsTypes.CLEAR_EMAIL_NOTIFICATIONS](state) {
    return update(state, {
      emailTemplates: { $set: [] },
      emailTemplatesActiveIndex: { $set: 0 },
      emailTemplatesSelectedYear: { $set: moment().year() },
      billingAgentIds: { $set: [] },
    });
  },

  [configurationsActionsTypes.GET_RATE_CARDS_SUCCESS](
    state,
    { payload: { rateCards, billingAgents, date, lastRates } }
  ) {
    const agents = billingAgents.length ? billingAgents : state.billingAgents;
    return update(state, {
      isFetching: {
        $set: false,
      },
      lastRates: {
        $set: lastRates,
      },
      rateCards: {
        $set: rateCards,
      },
      rateCardsActiveIndex: {
        $set: 0,
      },
      rateCardsSelectedYear: {
        $set: date,
      },
      billingAgents: {
        $set: agents,
      },
      errors: {
        $unset: ['getRateCardsError'],
      },
    });
  },
  [configurationsActionsTypes.GET_RATE_CARDS_FAIL](
    state,
    { paylod: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [configurationsActionsTypes.GET_RATE_CARD](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.GET_RATE_CARD_SUCCESS](
    state,
    { payload: { rateCard } }
  ) {
    const rateCards = state.rateCards;
    const updatedRatecards = rateCards.map((item) => {
      if (item.recordId === rateCard.recordId) {
        return rateCard;
      }
      return item;
    });

    return update(state, {
      isFetching: {
        $set: false,
      },
      rateCards: {
        $set: updatedRatecards,
      },
      errors: {
        $unset: ['getRateCardsError'],
      },
    });
  },
  [configurationsActionsTypes.GET_RATE_CARDS_FAIL](
    state,
    { paylod: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [configurationsActionsTypes.CREATE_RATE_CARD_RECORD](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.CREATE_RATE_CARD_RECORD_SUCCESS](
    state,
    { payload: { rateCards, lastRatesVersion } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      rateCards: {
        $set: rateCards,
      },
      rateCardsActiveIndex: {
        $set: rateCards.length - 1,
      },
      lastRates: {
        $set: lastRatesVersion,
      },
      createMode: {
        $set: false,
      },
      errors: {
        $unset: ['getRateCardsError'],
      },
    });
  },
  [configurationsActionsTypes.CREATE_RATE_CARD_RECORD_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [configurationsActionsTypes.UPDATE_RATE_CARD_RECORD](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.UPDATE_RATE_CARD_RECORD_SUCCESS](
    state,
    { payload: { rateCards, lastRates } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      rateCards: {
        $set: rateCards,
      },
      lastRates: {
        $set: lastRates,
      },
      createMode: {
        $set: false,
      },
      errors: {
        $unset: ['updateRateCardRecordError'],
      },
    });
  },
  [configurationsActionsTypes.UPDATE_RATE_CARD_RECORD_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [configurationsActionsTypes.REMOVE_RATE_CARD_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.REMOVE_RATE_CARD_RECORD_SUCCESS](
    state,
    { payload: { recordId, lastRates } }
  ) {
    const rateCards = state.rateCards;
    const removedRecordIndex = findIndex(rateCards, { recordId });
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      rateCards: {
        $set: [
          ...rateCards.slice(0, removedRecordIndex),
          ...rateCards.slice(removedRecordIndex + 1),
        ],
      },
      rateCardsActiveIndex: {
        $set: 0,
      },
      lastRates: {
        $set: lastRates,
      },
      errors: {
        $unset: ['deleteRateCardRecordError'],
      },
    });
  },
  [configurationsActionsTypes.REMOVE_RATE_CARD_RECORD_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [configurationsActionsTypes.SET_EMAIL_TEMPLATES_INDEX](
    state,
    { payload: { activeIndex } }
  ) {
    return update(state, {
      emailTemplatesActiveIndex: {
        $set: activeIndex,
      },
    });
  },
  [configurationsActionsTypes.SET_RATE_CARDS_INDEX](
    state,
    { payload: { activeIndex } }
  ) {
    return update(state, {
      rateCardsActiveIndex: {
        $set: activeIndex,
      },
    });
  },
  [configurationsActionsTypes.SET_CREATE_MODE](
    state,
    { payload: { createMode } }
  ) {
    return update(state, {
      createMode: {
        $set: createMode,
      },
    });
  },
  [configurationsActionsTypes.GET_HOLIDAYS](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.GET_HOLIDAYS_SUCCESS](
    state,
    { payload: { holidays, date } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      holidays: {
        $set: holidays,
      },
      holidaysSelectedYear: {
        $set: date,
      },
      errors: {
        $unset: ['getHolidaysError'],
      },
    });
  },
  [configurationsActionsTypes.GET_HOLIDAYS_FAIL](state, { payload: { error } }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [configurationsActionsTypes.SET_DEVCENTER_FILTER](
    state,
    { payload: { devcenterList } }
  ) {
    const filter = devcenterList.length ?
      devcenterList.map((item) => item.value) :
      [];
    return update(state, {
      filter: {
        $set: filter,
      },
    });
  },
  [configurationsActionsTypes.CREATE_HOLIDAYS_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.CREATE_HOLIDAYS_RECORD_SUCCESS](
    state,
    { payload: { holidays } }
  ) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      holidays: {
        $set: holidays,
      },
      errors: {
        $unset: ['createHolidaysError'],
      },
      createMode: {
        $set: false,
      },
    });
  },
  [configurationsActionsTypes.CREATE_HOLIDAYS_RECORD_FAIL](
    state,
    { paylod: { error } }
  ) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [configurationsActionsTypes.REMOVE_HOLIDAYS_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.REMOVE_HOLIDAYS_RECORD_SUCCESS](
    state,
    { payload: { recordId } }
  ) {
    const holidays = state.holidays;
    const removedRecordIndex = findIndex(holidays, { recordId });

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      holidays: {
        $set: [
          ...holidays.slice(0, removedRecordIndex),
          ...holidays.slice(removedRecordIndex + 1),
        ],
      },
      errors: {
        $unset: ['removeHolidaysError'],
      },
    });
  },
  [configurationsActionsTypes.REMOVE_HOLIDAYS_RECORD_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [configurationsActionsTypes.GET_HIQO_CONTRACTS](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.GET_HIQO_CONTRACTS_SUCCESS](
    state,
    { payload: { hiqoContracts } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      hiqoContracts: {
        $set: hiqoContracts,
      },
      errors: {
        $unset: ['getHiqoContracts'],
      },
    });
  },
  [configurationsActionsTypes.GET_HIQO_CONTRACTS_FAIL](
    state,
    { paylod: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [configurationsActionsTypes.REMOVE_HIQO_CONTRACTS_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.REMOVE_HIQO_CONTRACTS_RECORD_SUCCESS](
    state,
    { payload: { recordId } }
  ) {
    const hiqoContracts = state.hiqoContracts;
    const removedRecordIndex = findIndex(hiqoContracts, { id: recordId });

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      hiqoContracts: {
        $set: [
          ...hiqoContracts.slice(0, removedRecordIndex),
          ...hiqoContracts.slice(removedRecordIndex + 1),
        ],
      },
      errors: {
        $unset: ['removeHiqoContractError'],
      },
    });
  },
  [configurationsActionsTypes.REMOVE_HIQO_CONTRACTS_RECORD_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [configurationsActionsTypes.CREATE_HIQO_CONTRACTS_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [configurationsActionsTypes.CREATE_HIQO_CONTRACTS_RECORD_SUCCESS](
    state,
    { payload: { hiqoContracts } }
  ) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      hiqoContracts: {
        $set: hiqoContracts,
      },
      errors: {
        $unset: ['createHiqContractsRecordError'],
      },
      createMode: {
        $set: false,
      },
    });
  },
  [configurationsActionsTypes.CREATE_HIQO_CONTRACTS_RECORD_FAIL](
    state,
    { paylod: { error } }
  ) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  // discount and surcharge
  [configurationsActionsTypes.GET_DISCOUNT_SURCHARGE_RATES](state) {
    return update(state, {
      isFetching: { $set: true },
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [configurationsActionsTypes.GET_DISCOUNT_SURCHARGE_RATES_SUCCESS](
    state,
    { payload: { discountSurchargeRates, billLockDate } }
  ) {
    return update(state, {
      isFetching: { $set: false },
      isFormSubmitted: {
        $set: false,
      },
      discountSurchargeRates: { $set: discountSurchargeRates },
      billLockDate: { $set: billLockDate },
      errors: {
        $unset: ['getDicountSurchargeRatesError'],
      },
    });
  },

  [configurationsActionsTypes.GET_DISCOUNT_SURCHARGE_RATES_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: { $set: false },
      isFormSubmitted: {
        $set: false,
      },
      errors: { $merge: error },
    });
  },

  [configurationsActionsTypes.UPDATE_DISCOUNT_SURCHARGE_RATES](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [configurationsActionsTypes.UPDATE_DISCOUNT_SURCHARGE_RATES_SUCCESS](
    state,
    { payload: { discountSurchargeRates, billLockDate } }
  ) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      discountSurchargeRates: { $set: discountSurchargeRates },
      billLockDate: { $set: billLockDate },
      errors: {
        $unset: ['getDiscountSurchargeRatesError'],
      },
    });
  },

  [configurationsActionsTypes.UPDATE_DISCOUNT_SURCHARGE_RATES_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: { $set: false },
      isFormSubmitted: {
        $set: false,
      },
      errors: { $merge: error },
    });
  },
  [configurationsActionsTypes.CLEAR_NOTIFICATIONS_FILTER](state) {
    return update(state, {
      filters: {
        notifications: {
          clientRateCard: {
            $set: CLIENT_RATE_CARD_FILTER_STATE,
          },
          emailTemplate: {
            $set: EMAIL_TEMPLATES_FILTER_STATE,
          },
        },
      },
    });
  },
  ...clientRateCardUpdateNotificationsReducers,
  ...emailTemplateNotificationsReducers,
});
