import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import Viewbox from 'elements/form-viewbox';
import { viewBoxStyles } from 'forms/categories-form/components/field-array-item/styles';
import { TextInput } from 'forms/categories-form/components/text-input';
import { createKey, transformName } from 'forms/categories-form/utils';
import { get } from 'lodash';

export const CategoryName = ({
  control,
  index,
  cssRules,
  isBlocked,
  viewMode,
  hasUpdatePermissions,
}) => {
  const formContext = useFormContext();

  return (
    <Controller
      control={control}
      name={`categories.${index}.name`}
      render={({ field, fieldState }) => {
        const errorMessage = get(fieldState.error, 'message', '');
        const { onChange, value } = field;
        const handleOnInputChange = (e) => {
          onChange(transformName(e.target.value));

          if (!isBlocked && !viewMode) {
            formContext.setValue(`categories.${index}.key`, createKey(e.target.value), { shouldDirty: true });
          }
        };

        if (!hasUpdatePermissions) {
          return <Viewbox cssRules={viewBoxStyles} fieldData={{ value }} />;
        }

        return (
          <TextInput
            {...field}
            ref={null}
            onChange={handleOnInputChange}
            cssRules={cssRules}
            withError={!!errorMessage}
            withLabel={false}
          />
        );
      }}
    />
  );
};

CategoryName.propTypes = {
  cssRules: PropTypes.string,
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  viewMode: PropTypes.bool,
  isBlocked: PropTypes.bool,
  hasUpdatePermissions: PropTypes.bool,
};

CategoryName.defaultProps = {
  cssRules: undefined,
  viewMode: false,
  isBlocked: false,
  hasUpdatePermissions: true,
};
