import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';

import Filter from 'components/filter';
import Controls from 'components/list-controls';
import ListFooter from 'components/list-footer';
import ClientsTable from 'components/table';
import ToastContainer from 'components/toast';
import GoTop from 'elements/go-top-button';
import Wrapper from 'elements/wrapper';
import { withRouter } from 'react-router';
import { autoScrollToTop } from 'utils/react';

const ClientsList = ({
  location,
  clientsData,
  toggleFavorite,
  changeOrder,
  orderRules,
  isFetchingClientsList,
  changeCurrentModal,
  changeFilter,
  filterConfig,
  hasActiveFilters,
  resetFilters,
  alphabetFilter,
  isFilteringClientsList,
  getClientsList,
  clientsListModel,
  hasPermissionsCreateClients,
  clientsCount,
  entityName,
}) => {
  autoScrollToTop(location);

  const { rules: { css }, tableName, noDataMessage } = clientsListModel;
  const currentOrderRules = orderRules[tableName];
  const setOrder = ({ orderBy }) => {
    changeOrder({ orderBy, tableName, entityName });
  };

  useEffect(() => {
    getClientsList();

    return () => {
      // We are going to reset filters on layout unmount.
      resetFilters();
    };
  }, []);

  const unitActions = {
    changeCurrentModal,
  };

  return (
    <Fragment>
      <ToastContainer containerId={entityName} />
      <Controls
        title="Clients"
        hasActiveFilters={hasActiveFilters}
        changeFilter={changeFilter}
        resetFilters={resetFilters}
        hasAlphabetFilter
        alphabetFilter={alphabetFilter}
        controlsList={[
          {
            route: '/clients/add',
            title: 'Payroll Report',
            hasPermissions: hasPermissionsCreateClients,
          },
        ]}
        className="clients_controls"
      >
        <Filter
          cssRules={css}
          filterConfig={filterConfig}
          changeFilter={changeFilter}
        />
      </Controls>

      <Wrapper
        isGrow
      >
        <ClientsTable
          withInfiniteScroll
          data={clientsData}
          changeOrder={setOrder}
          model={clientsListModel}
          unitActions={unitActions}
          noDataMessage={noDataMessage}
          withFilter={hasActiveFilters}
          orderRules={currentOrderRules}
          toggleFavorite={toggleFavorite}
          isLoaded={!isFetchingClientsList && !isFilteringClientsList}
        />

      </Wrapper>

      <ListFooter
        filteredItemsCount={clientsData.length}
        itemsCount={clientsCount}
        isFetchingItems={isFetchingClientsList}
      />

      <GoTop />

    </Fragment>
  );
};


ClientsList.propTypes = {
  location: PropTypes.object.isRequired,
  entityName: PropTypes.string.isRequired,
  hasPermissionsCreateClients: PropTypes.bool.isRequired,
  clientsListModel: PropTypes.shape({}).isRequired,
  getClientsList: PropTypes.func.isRequired,
  alphabetFilter: PropTypes.shape({
    isActive: PropTypes.bool,
    type: PropTypes.string,
    selected: PropTypes.string,
  }).isRequired,
  resetFilters: PropTypes.func.isRequired,
  hasActiveFilters: PropTypes.bool.isRequired,
  changeFilter: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  isFetchingClientsList: PropTypes.bool.isRequired,
  isFilteringClientsList: PropTypes.bool.isRequired,
  changeOrder: PropTypes.func.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({
    // orderBy: PropTypes.string.isRequired,
    // isReversed: PropTypes.bool.isRequired,
    // isSeparatedByFavorites: PropTypes.bool.isRequired,
  }).isRequired,
  clientsData: PropTypes.arrayOf(PropTypes.shape({
    tableData: PropTypes.arrayOf(PropTypes.object),
  })),
  filterConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  clientsCount: PropTypes.number.isRequired,
};

ClientsList.defaultProps = {
  clientsData: [],
};


export default withRouter(ClientsList);
