import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import DropZone from 'components/dropzone';
import Loader from 'components/loader';
import { Formik, Form } from 'formik';

import Calendar from './calendar';
import GeneratorInput from './input';
import GeneratorSelect from './select';

import './styles.scss';

const units = {
  calendar: Calendar,
  text: GeneratorInput,
  select: GeneratorSelect,
};

const UploadDocumentForm = ({
  onSubmit,
  uploadingProgress,
  isFetchingFiles,
  fileLoaderConfig,
  isFileUploaded,
}) => {
  const [file, setFile] = useState(null);
  const [isUploadingInProgress, setIsUploadingInProgress] = useState(false);

  const { initialValues, validationSchema, fields, title: componentTitle, additional } = fileLoaderConfig;

  useEffect(() => {
    if (isFileUploaded || isFetchingFiles) {
      setIsUploadingInProgress(false);
    }
  }, [isFetchingFiles, isFileUploaded]);

  const setPreLoadedFile = (preLoadedFile) => {
    setFile(preLoadedFile);
    setIsUploadingInProgress(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setIsUploadingInProgress(true);
        onSubmit({ values, file });
      }}

      render={(formProps) => {
        const { values, setFieldValue, isSubmitting } = formProps;
        return (
          <Form
            className={classNames(
              'client-upload-document-form',
              { 'client-upload-document-form--with-uploading': isUploadingInProgress }
            )}
          >

            {
              isFetchingFiles && isSubmitting &&
              <Loader withOverlay />
            }

            <div className="client-upload-document-form__title">
              {componentTitle}
            </div>

            {
              fields.map(({ type, parentField, ...fieldConfig }, index) => (
                React.createElement(
                  units[type],
                  {
                    key: index, // eslint-disable-line react/no-array-index-key
                    fieldConfig,
                    formProps,
                    parentFieldValue: parentField ? values[parentField.name] : null,
                    parentField,
                    setFieldValue,
                    onChange: (value) => setFieldValue(fieldConfig.name, value || ''),
                  },
                )
              ))
            }

            {
              additional && additional.getIsVisible(values) && (
                <div className="client-upload-document-form--isMsaType">

                  {
                    additional.fields.map(({ type, parentField, ...fieldConfig }, index) => (
                      React.createElement(
                        units[type],
                        {
                          key: index, // eslint-disable-line react/no-array-index-key
                          fieldConfig,
                          formProps,
                          parentField,
                          parentFieldValue: parentField ? values[parentField.name] : null,
                          setFieldValue,
                          onChange: (value) => setFieldValue(fieldConfig.name, value || ''),
                        },
                      )
                    ))
                  }

                </div>
              )
            }

            <DropZone
              accept="application/pdf"
              onUpload={setPreLoadedFile}
              uploadingProgress={uploadingProgress}
              isUploadingInProgress={isUploadingInProgress}
              maxSize={90}
            />

            <div className="client-upload-document-form__controls-wrapper">
              <button
                className={
                  classNames(
                    'client-upload-document-form__button button',
                    { 'button--accept': file },
                    { 'button--disabled': isUploadingInProgress || !file },
                  )
                }
                type="submit"
                disabled={isUploadingInProgress || !file}
              >
                Upload
              </button>

            </div>

          </Form>
        );
      }}
    />
  );
};

UploadDocumentForm.propTypes = {
  isFileUploaded: PropTypes.bool.isRequired,
  fileLoaderConfig: PropTypes.shape({
    title: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    validationSchema: PropTypes.shape({}),
    initialValues: PropTypes.shape({}),
  }).isRequired,
  uploadingProgress: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetchingFiles: PropTypes.bool.isRequired,
};

export default UploadDocumentForm;
