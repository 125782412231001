import React from 'react';

import PropTypes from 'prop-types';

import {
  colorSecondaryGrayLight,
  colorBackground,
} from 'assets/styles/variables';
import classNames from 'classnames';
import styled, { css } from 'styled-components';

import GridUnit from './grid-unit';

import './styles.scss';

const StyledTemplate = styled.div`
  display: grid;
  grid-auto-rows: ${({ isFilter }) =>
    isFilter ? '3.4rem' : 'minmax(3.4rem, auto)'};
  border: ${({ isFilter }) =>
    isFilter ? `1px solid ${colorSecondaryGrayLight}` : 'none'};
  grid-column-gap: ${({ isFilter }) => (isFilter ? '1px' : '0.3rem')};
  background-color: ${({ isFilter }) =>
    isFilter ? colorSecondaryGrayLight : 'transparent'};

  ${({ isHeader }) =>
    isHeader ?
      `
    position: sticky;
    top: 0;
    font-weight: 500;
    text-transform: capitalize;
    background-color: ${colorBackground};
    z-index: 1;
  ` : ''}

  ${({ cssRules }) =>
    css`
      ${cssRules}
    `}
`;

const GridTemplate = ({ rowData, cssRules, isFilter, isHeader, ...rest }) => (
  <StyledTemplate
    className={classNames('row-wrapper', { 'row-wrapper__header': isHeader })}
    cssRules={cssRules}
    isFilter={isFilter}
    isHeader={isHeader}
  >
    {rowData.map((cell, index) =>
      Array.isArray(cell) ? (
        <div
          key={index} // eslint-disable-line react/no-array-index-key
          className="grid-unit--with-multi-items"
        >
          {cell.map((item, itemIndex) => (
            <GridUnit
              key={itemIndex} // eslint-disable-line react/no-array-index-key
              isFilter={isFilter}
              isHeader={isHeader}
              {...rest}
              {...item}
            />
          ))}
        </div>
      ) : (
        <GridUnit
          key={index} // eslint-disable-line react/no-array-index-key
          isFilter={isFilter}
          isHeader={isHeader}
          {...rest}
          {...cell}
        />
      ))
    }
  </StyledTemplate>
);

GridTemplate.propTypes = {
  changeFilter: PropTypes.func,
  changeOrder: PropTypes.func,
  toggleFavorite: PropTypes.func,
  rowData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ])).isRequired,
  cssRules: PropTypes.string,
  isHeader: PropTypes.bool,
  isFilter: PropTypes.bool,
  isFavored: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  orderRules: PropTypes.shape({
    orderBy: PropTypes.string,
    isReversed: PropTypes.bool,
    isSeparatedByFavorites: PropTypes.bool,
  }),
};

GridTemplate.defaultProps = {
  changeFilter: () => null,
  changeOrder: () => null,
  toggleFavorite: () => null,
  isHeader: false,
  isFilter: false,
  isFavored: false,
  id: null,
  cssRules: '',
  orderRules: {},
};

export default GridTemplate;
