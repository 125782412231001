import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from 'elements/wrapper';

import './styles.scss';


const renderPercentage = (filteredItemsCount, itemsCount) => {
  const filteredItemsPercentage = Math.round((filteredItemsCount / itemsCount) * 100);

  if (Number.isNaN(filteredItemsPercentage)) {
    return '';
  }

  if (filteredItemsPercentage < 1) {
    return '(<1%)';
  }

  return `(${filteredItemsPercentage}%)`;
};


const ListFooter = ({ filteredItemsCount, itemsCount, isFetchingItems }) => (
  <div className="list-footer">
    <Wrapper>
      <div className="list-footer__text">
        {isFetchingItems ?
          'Fetching items ...' :
          `All items: ${itemsCount}   /   Selected: ${filteredItemsCount} ${renderPercentage(filteredItemsCount, itemsCount)}`
        }
      </div>
    </Wrapper>
  </div>
);


ListFooter.propTypes = {
  filteredItemsCount: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  isFetchingItems: PropTypes.bool.isRequired,
};


export default ListFooter;
