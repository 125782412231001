import React, { memo } from 'react';

export const MinusIcon = memo(() => (
  <div data-id="MinusIcon">
    <svg color="#D61F26" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.9834 9V11H15.9756V9H5.9834ZM10.9795 0C5.46381 0 0.987305 4.48 0.987305 10C0.987305 15.52 5.46381 20 10.9795 20C16.4952 20 20.9717 15.52 20.9717 10C20.9717 4.48 16.4952 0 10.9795 0ZM10.9795 18C6.57294 18 2.98574 14.41 2.98574 10C2.98574 5.59 6.57294 2 10.9795 2C15.3861 2 18.9733 5.59 18.9733 10C18.9733 14.41 15.3861 18 10.9795 18Z" fill="currentColor" />
    </svg>
  </div>
));

MinusIcon.displayName = 'MinusIcon';
