import {
  colorPrimary,
  colorSecondaryText,
  colorSecondaryGrayDark,
  colorSecondaryGrayLight,
  colorSecondaryGrayLight015,
} from 'assets/styles/variables';
import {
  get,
  set,
  toNumber,
} from 'lodash';

import { addThousandSeparator, roundValue } from 'utils/helpers/numbers';

import {
  SENIORITY,
  STORE_KEYS,
  AVERAGE_EXCHANGE_POSTFIX,
  ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX,
  ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX,
  EXCHANGE_POSTFIX,
} from './constants';

export const getHours = (data) => data ? `${addThousandSeparator(data.toFixed(2))} h` : '0.00 h';

export const getRevenue = (data) => data ? `$ ${addThousandSeparator(data.toFixed(2))}` : '$ 0.00';

export const getSummaryConfigCss = ({
  summaryWrapperCss,
  hasResultsContent,
  displayWhenNoContent = true,
}) => {
  const noContentStyles = displayWhenNoContent ? '' : 'display: none;';
  return `
   min-height: 4.8rem;
   font-size: 1.6rem;
   padding: 0 1rem 0 1.6rem;
   border-top: 1px solid ${colorSecondaryGrayLight};
   background-color: ${hasResultsContent ? colorSecondaryGrayLight015 : 'transparent'};
   color: ${colorSecondaryGrayDark};
   ${hasResultsContent ? summaryWrapperCss : noContentStyles}
  `;
};

export const summaryInfoGetter = ({
  label,
  groupId,
  hasInitialContent,
  hasResultsContent,
  summaryWrapperCss,
  isDefaultExpanded,
  requiredTotals = [],
  displayWhenNoContent,
  ...rest
}) => {
  const items = [];
  const isPtmNotInTo = label && `${label}`.includes('NOT IN TO!');

  const getItem = ({
    data,
    hasWarning,
    hasContent = true,
  }) => ({
    data,
    cssRules: `
      margin: 0 auto;
      font-size: ${hasContent ? '1.6' : '1.4'}rem;
      line-height: ${hasContent ? '2.4' : '1.8'}rem;
      color: ${hasWarning ? colorPrimary : colorSecondaryText};
      ${!hasContent ? `color: ${colorSecondaryGrayLight};` : ''}
      &:after {
        display: none;
      }
      &:last-child {
        margin-right: 0;
      }
    `,
  });

  const noValuesItem = getItem({
    data: {
      value: hasInitialContent && !hasResultsContent ?
        'Sorry, no content matched your criteria.' :
        'No work has been logged for this period.',
    },
    hasContent: false,
  });

  if (!hasResultsContent) {
    items.push(noValuesItem);
  }

  if (hasResultsContent) {
    requiredTotals.forEach(({
      property,
      valueGetter,
    }) => {
      const data = get(rest, property);
      const total = data && valueGetter ? valueGetter(data) : '$ 0.00';

      if (valueGetter) {
        const item = getItem({
          data: {
            value: valueGetter(data) === 'empty' ? '' : total,
          },
          hasContent: hasResultsContent,
          hasWarning: isPtmNotInTo,
        });
        items.push(item);
      }
    });
  }

  return {
    label,
    groupId,
    isDefaultExpanded,
    isDisabled: !hasResultsContent,
    cssRules: getSummaryConfigCss({
      hasResultsContent,
      summaryWrapperCss,
      displayWhenNoContent,
    }),
    items,
  };
};

export const calculateTotal = ({
  label,
  groupId,
  isGMReport,
  accumulator,
  parsedContent,
  requiredTotals = [],
  setAccumulator,
  hasActiveFilters,
  isDefaultExpanded,
  summaryWrapperCss,
  displayWhenNoContent,
}) => {
  const tableSummaryData = get(parsedContent, '0.tableSummaryData', {});
  const totals = requiredTotals.reduce((acc, {
    property,
  }) => {
    const subtotal = get(accumulator, property, 0);
    const currentValue = get(tableSummaryData, property, 0);

    return {
      ...acc,
      [property]: subtotal + toNumber(isGMReport ? currentValue : roundValue(currentValue, 2)),
    };
  }, {});
  const accumulatorHasInitialContent = get(accumulator, 'hasInitialContent', false);
  const accumulatorHasResultsContent = get(accumulator, 'hasResultsContent', false);
  const hasInitialContent = get(parsedContent, '0.tableSummaryData.hasInitialContent', false);
  const hasResultsContent = get(parsedContent, '0.tableSummaryData.hasResultsContent', false);

  set( // TODO rewrite on immutability helper
    parsedContent,
    '0.summaryConfig',
    summaryInfoGetter({
      ...tableSummaryData,
      label,
      groupId,
      isGMReport,
      requiredTotals,
      hasResultsContent,
      hasInitialContent,
      summaryWrapperCss,
      displayWhenNoContent,
      isDefaultExpanded: hasResultsContent && isDefaultExpanded,
    }),
  );
  setAccumulator({
    ...accumulator,
    ...totals,
    hasActiveFilters,
    hasInitialContent: accumulatorHasInitialContent || hasInitialContent,
    hasResultsContent: accumulatorHasResultsContent || hasResultsContent,
  });

  return parsedContent;
};

function addSimpleGrandTotal({
  model,
  idKey,
  resultsData,
  rowStatusGetter,
  tableDataParser,
  hasActiveFilters,
}) {
  const grandTotalRowData = tableDataParser({
    ...this,
    hasActiveFilters,
    isGrandTotal: true,
  }, {
    model,
    idKey,
    rowStatusGetter,
  });

  resultsData.push(grandTotalRowData);
}

export const summaryTableDataGetter = ({
  currentRow,
  tableSummaryData,
  setTableSummaryData,
  requiredTotals = [],
  withGrandTotal = true,
  isGMReport,
  ...rest
}) => {
  const hasTotals = requiredTotals.some((total) => total);

  if (hasTotals) {
    const totals = requiredTotals.reduce((acc, {
      property,
    }) => {
      const subtotal = get(tableSummaryData, property, 0);
      const currentValue = get(currentRow, property, 0);

      return {
        ...acc,
        [property]: subtotal + toNumber(isGMReport ? currentValue : roundValue(currentValue, 2)),
      };
    }, {});

    setTableSummaryData({
      ...tableSummaryData,
      ...totals,
      ...rest,
      withGrandTotal: withGrandTotal ? addSimpleGrandTotal : null,
    });
  }
};

const getDefaultSales = (salesData) => get(salesData, 'list[0].value', 2);

export const getReportFor = ({ salesId, salesData, userId }) => salesId || get(salesData, `map.${userId}.value`, getDefaultSales(salesData));

export const parseAnalysisTab = ({
  totalSumOfRaisesUsd,
  totalSumOfRaisesRur,
  totalSumOfRaisesPln,
  sumOfRaisesUsdDataRows,
  sumOfRaisesRurDataRows,
  sumOfRaisesPlnDataRows,
  totalCountOfRaisesUsd,
  totalCountOfRaisesRur,
  totalCountOfRaisesPln,
  countOfRaisesUsdDataRows,
  countOfRaisesRurDataRows,
  countOfRaisesPlnDataRows,
  raisesAverageSalaryUsdDataRows,
  raisesAverageSalaryRurDataRows,
  raisesAverageSalaryPlnDataRows,
  raisesAverageSalaryTotalDataRows,
  averageUsdRaisePercentageDataRows,
  averageRurRaisePercentageDataRows,
  averagePlnRaisePercentageDataRows,
}, {
  averagePlnRaisePercentage,
  averageRurRaisePercentage,
  averageUsdRaisePercentage,
  averageSalaryPln,
  averageSalaryPlnExchangeRate,
  averageSalaryRur,
  averageSalaryRurExchangeRate,
  averageSalaryUsd,
  countOfRaisesPln,
  countOfRaisesRur,
  countOfRaisesUsd,
  sumOfRaisesPln,
  sumOfRaisesRur,
  sumOfRaisesUsd,
  totalAverageSalaryUsd,
}) => ({
  raisesAverageSalaryUsdDataRows: [...raisesAverageSalaryUsdDataRows, {
    rate: averageSalaryUsd,
    rateUsd: averageSalaryUsd,
  }],
  raisesAverageSalaryRurDataRows: [...raisesAverageSalaryRurDataRows, {
    rate: averageSalaryRur,
    rateUsd: averageSalaryRurExchangeRate,
  }],
  raisesAverageSalaryPlnDataRows: [...raisesAverageSalaryPlnDataRows, {
    rate: averageSalaryPln,
    rateUsd: averageSalaryPlnExchangeRate,
  }],
  raisesAverageSalaryTotalDataRows: [...raisesAverageSalaryTotalDataRows, {
    rate: totalAverageSalaryUsd,
    rateUsd: totalAverageSalaryUsd,
  }],
  totalCountOfRaisesUsd: totalCountOfRaisesUsd + toNumber(countOfRaisesUsd),
  totalCountOfRaisesRur: totalCountOfRaisesRur + toNumber(countOfRaisesRur),
  totalCountOfRaisesPln: totalCountOfRaisesPln + toNumber(countOfRaisesPln),
  countOfRaisesUsdDataRows: [...countOfRaisesUsdDataRows, countOfRaisesUsd],
  countOfRaisesRurDataRows: [...countOfRaisesRurDataRows, countOfRaisesRur],
  countOfRaisesPlnDataRows: [...countOfRaisesPlnDataRows, countOfRaisesPln],
  totalSumOfRaisesUsd: totalSumOfRaisesUsd + toNumber(sumOfRaisesUsd),
  totalSumOfRaisesRur: totalSumOfRaisesRur + toNumber(sumOfRaisesRur),
  totalSumOfRaisesPln: totalSumOfRaisesPln + toNumber(sumOfRaisesPln),
  sumOfRaisesUsdDataRows: [...sumOfRaisesUsdDataRows, addThousandSeparator(toNumber(sumOfRaisesUsd).toFixed(2))],
  sumOfRaisesRurDataRows: [...sumOfRaisesRurDataRows, addThousandSeparator(toNumber(sumOfRaisesRur).toFixed(2))],
  sumOfRaisesPlnDataRows: [...sumOfRaisesPlnDataRows, addThousandSeparator(toNumber(sumOfRaisesPln).toFixed(2))],
  averageUsdRaisePercentageDataRows: [...averageUsdRaisePercentageDataRows, toNumber(averageUsdRaisePercentage).toFixed(2)],
  averageRurRaisePercentageDataRows: [...averageRurRaisePercentageDataRows, toNumber(averageRurRaisePercentage).toFixed(2)],
  averagePlnRaisePercentageDataRows: [...averagePlnRaisePercentageDataRows, toNumber(averagePlnRaisePercentage).toFixed(2)],
});

export const getAnnualReportRaisesAverageSalaryDataRows = ({
  raisesAverageSalaryUsdDataRows,
  raisesAverageSalaryRurDataRows,
  raisesAverageSalaryPlnDataRows,
  raisesAverageSalaryTotalDataRows,
}, currency) => {
  const usdRow = {
    title: 'Aver. Salary USD',
    isTotal: false,
    monthsData: raisesAverageSalaryUsdDataRows,
  };
  const rurRow = {
    title: 'Aver. Salary RUR',
    isTotal: false,
    monthsData: raisesAverageSalaryRurDataRows,
  };
  const plnRow = {
    title: 'Aver. Salary PLN',
    isTotal: false,
    monthsData: raisesAverageSalaryPlnDataRows,
  };
  const summaryRow = {
    isTotal: true,
    title: 'Total Aver. Salary [USD]',
    monthsData: raisesAverageSalaryTotalDataRows,
  };
  const totalsMap = {
    [STORE_KEYS.USD]: usdRow,
    [STORE_KEYS.PLN]: plnRow,
    [STORE_KEYS.RUR]: rurRow,
  };

  const rows = currency.length !== 0 ?
    currency.map((currentCurrency) => totalsMap[currentCurrency]) :
    [usdRow, rurRow, plnRow];

  if (rows.length > 1) {
    rows.push(summaryRow);
  }
  return rows;
};

export const getAnnualReportNumberOfRaisesDataRows = ({
  totalCountOfRaisesUsd,
  totalCountOfRaisesRur,
  totalCountOfRaisesPln,
  countOfRaisesUsdDataRows,
  countOfRaisesRurDataRows,
  countOfRaisesPlnDataRows,
}, currency) => {
  const usdRow = {
    title: '# of Raises USD',
    total: totalCountOfRaisesUsd,
    monthsData: countOfRaisesUsdDataRows,
  };
  const rurRow = {
    title: '# of Raises RUR',
    total: totalCountOfRaisesRur,
    monthsData: countOfRaisesRurDataRows,
  };
  const plnRow = {
    title: '# of Raises PLN',
    total: totalCountOfRaisesPln,
    monthsData: countOfRaisesPlnDataRows,
  };
  const totalsMap = {
    [STORE_KEYS.USD]: usdRow,
    [STORE_KEYS.PLN]: plnRow,
    [STORE_KEYS.RUR]: rurRow,
  };

  return currency.length !== 0 ?
    currency.map((currentCurrency) => totalsMap[currentCurrency]) :
    [usdRow, rurRow, plnRow];
};

export const getAnnualReportRaisesDataRows = ({
  totalSumOfRaisesUsd,
  totalSumOfRaisesRur,
  totalSumOfRaisesPln,
  sumOfRaisesUsdDataRows,
  sumOfRaisesRurDataRows,
  sumOfRaisesPlnDataRows,
}, currency) => {
  const usdRow = {
    title: 'Raises [USD]',
    total: addThousandSeparator(totalSumOfRaisesUsd.toFixed(2)),
    monthsData: sumOfRaisesUsdDataRows,
  };
  const rurRow = {
    title: 'Raises [RUR]',
    total: addThousandSeparator(totalSumOfRaisesRur.toFixed(2)),
    monthsData: sumOfRaisesRurDataRows,
  };
  const plnRow = {
    title: 'Raises [PLN]',
    total: addThousandSeparator(totalSumOfRaisesPln.toFixed(2)),
    monthsData: sumOfRaisesPlnDataRows,
  };
  const totalsMap = {
    [STORE_KEYS.USD]: usdRow,
    [STORE_KEYS.PLN]: plnRow,
    [STORE_KEYS.RUR]: rurRow,
  };

  return currency.length !== 0 ?
    currency.map((currentCurrency) => totalsMap[currentCurrency]) :
    [usdRow, rurRow, plnRow];
};

export const getAnnualReportRaisesPercentageDataRows = ({
  averageUsdRaisePercentageDataRows,
  averageRurRaisePercentageDataRows,
  averagePlnRaisePercentageDataRows,
}, currency) => {
  const usdRow = {
    title: 'Aver. USD Raise [%]',
    monthsData: averageUsdRaisePercentageDataRows,
  };
  const rurRow = {
    title: 'Aver. RUR Raise [%]',
    monthsData: averageRurRaisePercentageDataRows,
  };
  const plnRow = {
    title: 'Aver. PLN Raise [%]',
    monthsData: averagePlnRaisePercentageDataRows,
  };
  const totalsMap = {
    [STORE_KEYS.USD]: usdRow,
    [STORE_KEYS.PLN]: plnRow,
    [STORE_KEYS.RUR]: rurRow,
  };

  return currency.length !== 0 ?
    currency.map((currentCurrency) => totalsMap[currentCurrency]) :
    [usdRow, rurRow, plnRow];
};

export const getAnnualReportSenioritySalariesDataRows = (senioritySalaries, currencyList) => {
  const isSingleCurrency = currencyList.length === 1;
  const { JUNIOR_FULL, STAFF_FULL, SENIOR_FULL } = SENIORITY;
  const seniorityList = [JUNIOR_FULL, STAFF_FULL, SENIOR_FULL];
  const parsedData = {};

  senioritySalaries.forEach((data, statsIndex) => {
    seniorityList.forEach((seniority, seniorityIndex) => {
      const usdRow = {
        isTotal: false,
        title: `${seniority} USD`,
        rateKey: `${seniority}${STORE_KEYS.USD}`,
      };
      const rurRow = {
        isTotal: false,
        title: `${seniority} RUR`,
        rateKey: `${seniority}${STORE_KEYS.RUR}`,
        rateUsdKey: `${seniority}${STORE_KEYS.RUR}${STORE_KEYS.USD}${EXCHANGE_POSTFIX}`,
      };
      const plnRow = {
        isTotal: false,
        title: `${seniority} PLN`,
        rateKey: `${seniority}${STORE_KEYS.PLN}`,
        rateUsdKey: `${seniority}${STORE_KEYS.PLN}${STORE_KEYS.USD}${EXCHANGE_POSTFIX}`,
      };
      const totalRow = {
        isTotal: true,
        title: 'Aver. Salaries [USD]',
        rateKey: `${seniority}`,
        rateUsdKey: `${seniority}`,
      };
      const totalsMap = {
        [STORE_KEYS.USD]: usdRow,
        [STORE_KEYS.PLN]: plnRow,
        [STORE_KEYS.RUR]: rurRow,
      };
      const rows = currencyList.length ?
        currencyList.map((row) => totalsMap[row]) :
        [usdRow, rurRow, plnRow];

      if (!isSingleCurrency) {
        rows.push(totalRow);
      }
      rows.forEach(({
        title,
        rateKey,
        isTotal,
        rateUsdKey,
      }, keyIndex) => {
        const rowStats = get(parsedData, rateKey, {
          title,
          isTotal,
          monthsData: [],
          isSingleCurrency,
          index: toNumber(`${statsIndex}${seniorityIndex}${keyIndex}`),
        });
        const { monthsData } = rowStats;
        set(parsedData, rateKey, {
          ...rowStats,
          monthsData: [...monthsData, {
            rate: get(data, rateKey, 0),
            rateUsd: get(data, rateUsdKey, 0),
          }],
        });
      });
    });
  });

  return Object.values(parsedData).sort((a, b) => a.index - b.index);
};

export const getAnnualReportResourceMatrixDataRows = (resourcesStats) => {
  const matrix = [];
  const { JUNIOR, STAFF, SENIOR } = SENIORITY;
  const seniorityList = [JUNIOR, STAFF, SENIOR];
  const seniorityLevel = [0, 1, 2];

  seniorityList.forEach((seniority) => {
    seniorityLevel.forEach((level) => {
      matrix.push({
        title: `${seniority}${level} #`.toUpperCase(),
        selector: `countOf${seniority}${level}`,
        monthsData: [],
      });
    });
  });

  resourcesStats.forEach((stats) => {
    matrix.forEach(({ selector, monthsData }) => {
      const stat = get(stats, selector);
      monthsData.push(stat);
    });
  });

  return matrix;
};

export const getAnnualReportResourceMatrixByCurrencyDataRows = (resourcesStats) => {
  const matrixUsd = [];
  const matrixRur = [];
  const matrixPln = [];
  const { JUNIOR, STAFF, SENIOR } = SENIORITY;
  const seniorityList = [JUNIOR, STAFF, SENIOR];
  const seniorityLevel = [0, 1, 2];

  seniorityList.forEach((seniority) => {
    seniorityLevel.forEach((level) => {
      matrixUsd.push({
        title: `${seniority}${level} #`.toUpperCase(),
        selector: `countOf${seniority}${level}${STORE_KEYS.USD}`,
        monthsData: [],
      });
      matrixRur.push({
        title: `${seniority}${level} #`.toUpperCase(),
        selector: `countOf${seniority}${level}${STORE_KEYS.RUR}`,
        monthsData: [],
      });
      matrixPln.push({
        title: `${seniority}${level} #`.toUpperCase(),
        selector: `countOf${seniority}${level}${STORE_KEYS.PLN}`,
        monthsData: [],
      });
    });
  });

  resourcesStats.forEach((stats) => {
    matrixUsd.forEach(({ selector, monthsData }) => {
      const stat = get(stats, selector);
      monthsData.push(stat);
    });
    matrixRur.forEach(({ selector, monthsData }) => {
      const stat = get(stats, selector);
      monthsData.push(stat);
    });
    matrixPln.forEach(({ selector, monthsData }) => {
      const stat = get(stats, selector);
      monthsData.push(stat);
    });
  });

  return {
    [STORE_KEYS.USD]: matrixUsd,
    [STORE_KEYS.RUR]: matrixRur,
    [STORE_KEYS.PLN]: matrixPln,
  };
};

export const getAnnualReportSeniorityRatioDataRows = (seniorityRatio) => {
  const {
    juniorMonthsData,
    staffMonthsData,
    seniorMonthsData,
    proportionMonthData,
  } = seniorityRatio.reduce(({
    juniorMonthsData: storedJuniorMonthsData,
    staffMonthsData: storedStaffMonthsData,
    seniorMonthsData: storedSeniorMonthsData,
    proportionMonthData: storedProportionMonthData,
  }, {
    countOfJuniors,
    countOfSeniors,
    countOfStaffs,
    proportionSenStaJun,
  }) => ({
    juniorMonthsData: [...storedJuniorMonthsData, countOfJuniors],
    staffMonthsData: [...storedStaffMonthsData, countOfStaffs],
    seniorMonthsData: [...storedSeniorMonthsData, countOfSeniors],
    proportionMonthData: [...storedProportionMonthData, proportionSenStaJun],
  }), {
    juniorMonthsData: [],
    staffMonthsData: [],
    seniorMonthsData: [],
    proportionMonthData: [],
  });
  return [{
    title: 'Junior',
    monthsData: juniorMonthsData,

  }, {
    title: 'Staff',
    monthsData: staffMonthsData,
  }, {
    title: 'Senior',
    monthsData: seniorMonthsData,
  }, {
    title: 'SEN-STA-JUN',
    monthsData: proportionMonthData,
  }];
};

export const getAnnualReportAverageSalariesDataRows = (averageSalariesStats, currencyList) => {
  const PREFIX = ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX;
  const TOTAL_PREFIX = ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX;
  const isSingleCurrency = currencyList.length === 1;
  const { JUNIOR, STAFF, SENIOR } = SENIORITY;
  const seniorityList = [JUNIOR, STAFF, SENIOR];
  const seniorityLevel = [0, 1, 2];
  const parsedData = {};

  averageSalariesStats.forEach((data, statsIndex) => {
    seniorityList.forEach((seniority, seniorityIndex) => {
      seniorityLevel.forEach((level, levelIndex) => {
        const usdRow = {
          isTotal: false,
          title: `${seniority.toUpperCase()}${level} USD`,
          rateKey: `${PREFIX}${seniority}${level}${STORE_KEYS.USD}`,
          rateUsdKey: `${PREFIX}${seniority}${level}${STORE_KEYS.USD}`,
        };
        const rurRow = {
          isTotal: false,
          title: `${seniority.toUpperCase()}${level} RUR`,
          rateKey: `${PREFIX}${seniority}${level}${STORE_KEYS.RUR}`,
          rateUsdKey: `${PREFIX}${seniority}${level}${STORE_KEYS.RUR}${AVERAGE_EXCHANGE_POSTFIX}`,
        };
        const plnRow = {
          isTotal: false,
          title: `${seniority.toUpperCase()}${level} PLN`,
          rateKey: `${PREFIX}${seniority}${level}${STORE_KEYS.PLN}`,
          rateUsdKey: `${PREFIX}${seniority}${level}${STORE_KEYS.PLN}${AVERAGE_EXCHANGE_POSTFIX}`,
        };
        const totalRow = {
          isTotal: true,
          title: `Total Aver.${seniority.toUpperCase()}${level} [USD]`,
          rateKey: `${TOTAL_PREFIX}${seniority}${level}${STORE_KEYS.USD}`,
          rateUsdKey: `${TOTAL_PREFIX}${seniority}${level}${STORE_KEYS.USD}`,
        };
        const totalsMap = {
          [STORE_KEYS.USD]: usdRow,
          [STORE_KEYS.PLN]: plnRow,
          [STORE_KEYS.RUR]: rurRow,
        };
        const rows = currencyList.length ?
          currencyList.map((row) => totalsMap[row]) :
          [usdRow, rurRow, plnRow];

        rows.push(totalRow);
        rows.forEach(({
          title,
          rateKey,
          isTotal,
          rateUsdKey,
        }, keyIndex) => {
          const rowStats = get(parsedData, rateKey, {
            title,
            isTotal,
            monthsData: [],
            isSingleCurrency,
            index: toNumber(`${statsIndex}${seniorityIndex}${levelIndex}${keyIndex}`),
          });
          const { monthsData } = rowStats;

          const rate = get(data, rateKey, 0);
          const rateUsd = get(data, rateUsdKey, 0);
          set(parsedData, rateKey, {
            ...rowStats,
            monthsData: [...monthsData, {
              rate,
              rateUsd,
            }],
          });
        });
      });
    });
  });

  return Object.values(parsedData).sort((a, b) => a.index - b.index);
};
