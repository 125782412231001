import update from 'immutability-helper';

export function createEntityAdapter({
  key = 'id',
  entityParser = (entity) => entity,
}) {
  function addOne(state, entity) {
    return update(state, {
      $set: {
        ids: [entity[key], ...state.ids],
        entities: {
          [entity[key]]: entityParser(entity),
          ...state.entities,
        },
      },
    });
  }

  function setAll(state, entities) {
    return update(state, {
      $set: {
        ids: entities.map((entity) => entity[key]),
        entities: entities.reduce(
          (acc, entity) => ({
            ...acc,
            [entity[key]]: entityParser(entity),
          }),
          {},
        ),
      },
    });
  }

  function removeOne(state, id) {
    return update(state, {
      ids: {
        $apply: (ids) => ids.filter((currentId) => currentId !== id),
      },
      entities: {
        $unset: [id],
      },
    });
  }

  function selectTotal(state) {
    return state.ids.length;
  }

  function selectAll(state) {
    return state.ids.map((id) => state.entities[id]);
  }

  return {
    addOne,
    setAll,
    removeOne,
    selectAll,
    selectTotal,
  };
}
