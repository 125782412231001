import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { has } from 'lodash';
import styled, { css } from 'styled-components';

import './styles.scss';

const StyledSpan = styled.span`
  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;


const DetailsSingleTab = ({ isActive, title, labels, onClick, isDisable }) => (
  <button
    className={classNames(
      'details-tabs__single-tab',
      { 'details-tabs__single-tab--active': isActive },
      { 'details-tabs__single-tab--disabled': isDisable }
    )}
    onClick={onClick}
  >
    <p
      className={classNames(
        'details-tabs__wrapper',
        { 'details-tabs__wrapper--active': isActive },
        { 'details-tabs__wrapper--disabled': isDisable }
      )}
    >

      <span
        className={classNames(
          'details-tabs__title',
          { 'details-tabs__title--active': isActive },
          { 'details-tabs__title--disabled': isDisable }
        )}
      >
        {title}
      </span>

      {
        labels.map((label, index) => (
          <StyledSpan
            key={index} // eslint-disable-line react/no-array-index-key
            className={classNames(
              'details-tabs__label',
              { 'details-tabs__label--disabled': isDisable },
              { 'details-tabs__label--is-active': label.isActive },
              { 'details-tabs__label--isEmpty': label.isEmpty }
            )}
            cssRules={label.cssRules}
          >
            {
              has(label, 'value') ? label.value : label
            }
          </StyledSpan>
        ))
      }

    </p>

  </button>
);


DetailsSingleTab.propTypes = {
  isDisable: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ])),
  onClick: PropTypes.func.isRequired,
};

DetailsSingleTab.defaultProps = {
  labels: ['-', '-'],
  isActive: false,
};


export default DetailsSingleTab;
