import { createAction } from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import { ENTITY_NAME } from './constants';

export const resourceManagementReportTypes = createTypes([
  ...async('GET_REPORT'),
], ENTITY_NAME);

export const resourceManagementReportActions = {
  getResourceManagementReport: createAction(resourceManagementReportTypes.GET_REPORT),

  getResourceManagementReportSuccess: createAction(
    resourceManagementReportTypes.GET_REPORT_SUCCESS,
    ({ data }) => ({ data })
  ),
  getResourceManagementReportFail: createAction(resourceManagementReportTypes.GET_REPORT_FAIL, ({
    error = {},
  }) => ({
    error,
  })),
};
