export const VERSION = 'v2.2.1';

export const KEY_CODES = {
  BACKSPACE: 8,
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  ESC: 27,
  C: 67,
  V: 86,
};

export const FORM_UNITS_TYPES = {
  TEXT: 'text',
  EMPTY: 'empty',
  SELECT: 'select',
  TOGGLE: 'toggle',
  SLIDER: 'slider',
  DROPDOWN: 'dropdown',
  VIEW_BOX: 'viewBox',
  CHECKBOX: 'checkbox',
  SUBHEADING: 'subheading',
  DESCRIPTION: 'description',
  ACTION_BUTTON: 'actionButton',
  TABLE_HEADING: 'tableHeading',
};

export const UNITS_TYPES = {
  FORM: 'form',
  TABLE: 'table',
  FILTER: 'filter',
  HEADING: 'heading',
  INFO_CARD: 'info-card',
  DATA_LIST: 'data-list',
  SINGLE_ROW: 'single-row',
  SCREEN_PLACEHOLDER: 'screen-placeholder',
  EMPTY_MESSAGE: 'empty-message',
};

export const FILTERS_TYPES = {
  ALPHABETICAL: 'startFrom',
  CHECKBOX: 'checkbox',
  TOGGLE: 'toggle',
  SEARCH: 'search',
  SELECT: 'select',
  DATE: 'date',
  RANGE: 'range',
};

export const COLUMN_TYPES = {
  TEXT: 'text',
  LINK: 'link',
  STATUS: 'status',
  SELECT: 'select',
  ACTION: 'action',
  COUNTER: 'counter',
  BOOLEAN: 'boolean',
  FAVORED: 'favorite',
};
