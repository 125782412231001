import React, { useState, useEffect } from 'react';

import { useStore } from 'react-redux';

import hoistNonReactStatics from 'hoist-non-react-statics';

import getInjectors from './saga-injectors';

/* eslint-disable no-undef */

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.RESTART_ON_REMOUNT) the saga will be started on component mount and
 * cancelled with `task.cancel()` on component un-mount for improved performance. Another two options:
 *   - constants.DAEMON—starts the saga on component mount and never cancels it or starts again,
 *   - constants.ONCE_TILL_UNMOUNT—behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 * @param {array} [args] Arguments passed to the saga once called
 * By default your saga will receive
 *   - component props
 *   - action
 * If defined, the saga will receive those args instead of the component props
 */
export default ({ key, saga, mode, args }) => (WrappedComponent) => {
  const InjectSaga = (props) => {
    const injectors = getInjectors(useStore());

    const [mounted, setMounted] = useState(false);
    if (!mounted) {
      const injectedArgs = args || [props];
      injectors.injectSaga(key, { saga, mode }, ...injectedArgs);
    }

    useEffect(() => {
      setMounted(true);

      return () => {
        injectors.ejectSaga(key);
      };
    }, []);

    return <WrappedComponent {...props} />;
  };

  InjectSaga.WrappedComponent = WrappedComponent;
  InjectSaga.displayName = `withSaga(${(WrappedComponent.displayName || WrappedComponent.name || 'Component')})`;

  return hoistNonReactStatics(InjectSaga, WrappedComponent);
};
