import moment from 'moment';

import { fixedNumberValue } from 'utils/helpers/numbers';

import {
  formHeadingCssRules,
  monthsFormHeadingCssRules,
  tableHeaderCssRules,
  viewBoxCssRules,
  multiCellWrapperCssRules,
  getFormRowWrapperCssRules,
  rateInputCssRules,
} from './styles';

const filedArrayRootName = 'discountSurchargeRates';

export const discountSurchargeRatesTableHeaderModel = {
  rules: {
    css: {
      tableWrapperCssRules: tableHeaderCssRules,
      gridTemplateConfig: formHeadingCssRules,
    },
  },
  dataTemplate: [
    {
      columnName: 'Years',
      cssRules: `
            {
              &.grid-unit {
                padding-left: 1.6rem;
                justify-content: flex-start;
              }
          }
          `,
      withoutControls: true,
    },
    {
      columnName: 'DevCenter',
      cssRules: `{
            &.grid-unit {
              justify-content: flex-start;
            }
          }`,
      withoutControls: true,
    },
    ...moment.monthsShort().map((monthName) => ({
      columnName: monthName,
      cssRules: monthsFormHeadingCssRules,
      withoutControls: true,
    })),
  ],
};

export const discountSurchargeManagementFormTemplate = [
  {
    stylesTemplate: {
      cssRules: `
        grid-row-gap: 0;
        margin-top: 0;
      `,
    },
    formData: [
      {
        isFieldArray: true,
        name: filedArrayRootName,
        valueGetter: ({ dataForTemplate, devcentersData }) => {
          const currentYear = moment().year();

          const ratePlaceholder = '0.000000%';
          return {
            value: dataForTemplate,
            lineGetter: ({ index, lineValues }) => {
              const { year, monthData } = lineValues || {};
              return {
                lineId: year,
                line: [
                  {
                    type: 'viewBox',
                    fieldData: {
                      value: year,
                    },
                    cssRules: `
                        padding-left: 1.6rem;
                        font-weight: 500;
                        ${
  currentYear === year ?
    'color: #D61F26;' :
    'color: rgba(000,000,000,0.87);'
}
                        ${viewBoxCssRules}
                      `,
                  },
                  {
                    isMultiple: true,
                    cssRules: multiCellWrapperCssRules,
                    data: [
                      ...devcentersData.map((devcenter) => ({
                        type: 'viewBox',
                        fieldData: { value: devcenter },
                        cssRules: viewBoxCssRules,
                      })),
                    ],
                  },

                  ...monthData.map(({ monthRates }, monthDataIndex) => ({
                    isMultiple: true,
                    cssRules: multiCellWrapperCssRules,
                    data: [
                      ...monthRates.map(({
                        percentage,
                        discountsAndSurchargesRecordId,
                        isLocked,
                      }, monthRatesIndex) => ({
                        type: 'text',
                        name: `${filedArrayRootName}[${index}].monthData[${monthDataIndex}].monthRates[${monthRatesIndex}].percentage`,
                        label: `${discountsAndSurchargesRecordId}`,
                        isLocked,
                        fieldData: {
                          placeholder: percentage ?
                            `${fixedNumberValue(Number(percentage), 6)}%` :
                            ratePlaceholder,
                        },
                        validationRules: {
                          isNumeric: true,
                          fixedTo: 6,
                          isRates: true,
                          isPercentage: true,
                          decimalScale: 6,
                          allowNegative: true,
                          maxValue: 999.999999999,
                          minValue: -999.999999999,
                        },
                        cssRules: `
                              input::placeholder {
                                color:${percentage ? 'rgba(0, 0, 0, 0.87)' : ''};
                              }
                              ${rateInputCssRules}
                          `,
                      })),
                    ],
                  })),
                ],
                stylesTemplate: {
                  cssRules: getFormRowWrapperCssRules({ index }),
                },
              };
            },
          };
        },
      },
    ],
  },
];
