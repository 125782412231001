import { resourceAttachmentManagementAccess } from 'core/auth/guaranteedAccessRoles';
import { selectUserGroup } from 'core/auth/selectors';
import { createSelector } from 'reselect';

const { rolesToView, rolesToAddRecord } = resourceAttachmentManagementAccess;

export const selectAttachmentsPermissions = createSelector(selectUserGroup, (userGroup) => ({
  hasPermissionToViewOnly: rolesToView.includes(userGroup),
  hasPermissionToAddRecord: rolesToAddRecord.includes(userGroup),
}));

export const selectIsFetching = (state) => state.delivery.employeeDetailsTabs.attachments.isFetching;
export const selectMemoDevstaffId = (state) => state.delivery.employeeDetailsTabs.attachments.memoDevstaffId;

export const selectAttachmentsLength = (state) => state.delivery.employeeDetailsTabs.attachments.length;
export const selectFormState = (state) => state.delivery.employeeDetailsTabs.attachments.formState;
export const selectRecords = (state) => state.delivery.employeeDetailsTabs.attachments.attachmentManagementRecords;

export const selectAttachmentsRecords = createSelector(
  selectRecords,
  selectIsFetching,
  selectMemoDevstaffId,
  selectAttachmentsPermissions,
  (records, isFetching, memoDevstaffId, {
    hasPermissionToAddRecord,
    hasPermissionToViewOnly,
  }) => ({
    memoDevstaffId,
    isFetching,
    records,
    hasPermissionToViewOnly,
    hasPermissionToAddRecord,
  })
);
