import { USERS_GROUPS } from 'core/auth/constants';
import moment from 'moment';
import { getHasPermissions } from 'utils/auth';
import { string, number, object, array, date as yupDate } from 'yup';

import { placeholdersItemStyles } from './styles';

const filedArrayRootName = 'rates';
const { CEO, CPS } = USERS_GROUPS;

export const rateCardmanagementFormTemplate = (
  detailsRateCard,
  createMode,
  rateCardsSelectedYear,
  userGroup,
  devcenters
) => {
  const {
    rateCardName,
    emailContent,
    effectiveDate,
    recordId,
    isActive,
    year,
  } = detailsRateCard;
  const isCEO = getHasPermissions(userGroup, [CEO]);
  const isCPS = getHasPermissions(userGroup, [CPS]);
  return [
    {
      title: 'Rate Card Details',
      stylesTemplate: {
        gridArea: 'details',
        cssRules: `
        width: 100%;
        grid-template-areas:
        "name email email placeholder"
        "agent email email placeholder"
        "date email email placeholder";
        grid-auto-rows: unset;
        grid-template-columns: 2fr 2fr 1fr 2fr;
        margin-bottom: 3rem;
        & .form-date-picker {

        }

        & > .plain-text{
          grid-area: placeholderTitle;
        }
      `,
      },
      formData: [
        {
          type: 'text',
          name: 'rateCardName',
          withLabel: true,
          placeholder: 'Rate Card Name',
          getIsUnitLocked: () => !getHasPermissions(userGroup, [CEO]),
          valueGetter: ({ rateCard }) => ({
            value: rateCard.rateCardName || '',
          }),
          validationRules: {
            isRequired: true,
            schemaGetter: () => string().nullable().required('Required field'),
          },
          cssRules: `
      grid-area: name;
    `,
        },
        {
          type: 'select',
          name: 'billingAgent',
          placeholder: 'Billing Agent',
          getIsUnitLocked: () => !getHasPermissions(userGroup, [CEO]),
          valueGetter: ({ billingAgents = [], rateCard }) => {
            const billingAgentId = rateCard.billingAgent || null;
            const selectedBillingAgent = billingAgents.filter(
              (item) => item.value === billingAgentId
            )[0];
            return {
              selected: selectedBillingAgent || '',
              items: billingAgents.map((agent) => ({
                label: agent.label,
                value: agent.value,
              })),
            };
          },
          validationRules: {
            isRequired: true,
            schemaGetter: () => number().nullable().required('Required field'),
          },
          cssRules: `
      grid-area: agent;
    `,
        },
        {
          type: 'calendar',
          name: 'effectiveDate',
          placeholder: 'Effective as of',
          getIsUnitLocked: () => !getHasPermissions(userGroup, [CEO]),
          valueGetter: ({ rateCard }) => rateCard.effectiveDate || null,
          currentDate: moment().year(rateCardsSelectedYear),
          withCurrentDate: moment().year() === rateCardsSelectedYear,
          maxYear: moment().add(1, 'year').year(),
          outputFormatting: ({ date }) => moment.isMoment(date) ? date.format('YYYY-MM-DD') : date,
          validationRules: {
            isRequired: true,
            schemaGetter: () => yupDate()
              .max(moment().add(1, 'year'))
              .nullable()
              .required('Required field'),
          },
          cssRules: `{
      max-height: unset;
      grid-area: date;
    }`,
        },
        {
          type: 'text',
          isMultiline: true,
          name: 'emailContent',
          placeholder: 'Email Text',
          getIsUnitLocked: () => !getHasPermissions(userGroup, [CEO]),
          valueGetter: ({ rateCard }) => rateCard.emailContent || '',
          validationRules: {
            isRequired: true,
            schemaGetter: () => string().nullable().required('Required field'),
          },
          cssRules: `{
        grid-area: email;
        & textarea {
          border: 1px solid silver;
          max-height: 14rem;
          min-height: 14rem;
          font-size: 1.4rem;
          line-height: 1.8rem;
          letter-spacing: 0.15px;
          color: rgba(0, 0, 0, 0.87);
          padding: 1.2rem 0.5rem;
          margin-top: 2.5rem;
        }
        .text-input__label {
          top: 3.8rem;
          left: 1rem;
        }
        .text-input__label--visible {
          top: 0;
          left: 0;
        }
        .text-input__input-field:focus + .text-input__label {
          left: 0;
        }
      }`,
        },
        {
          type: 'virtualField',
          name: 'recordId',
          valueGetter: ({ rateCard }) => rateCard.recordId,
          isHidden: true,
        },
        {
          type: 'virtualField',
          name: 'year',
          valueGetter: ({ rateCard }) => rateCard.year,
          isHidden: true,
        },
        {
          isMultiple: true,
          cssRules: `
      grid-template-columns: 1fr 3fr;
      font-size: 1.3rem;
      line-height: 1.8rem;
      grid-area: placeholder;
      height: max-content;
      & .plain-text:hover {
          overflow: hidden;
      }
      & .plain-text:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    `,
          formData: [
            {
              type: 'description',
              data: 'Placeholders',
              cssRules: `{
            margin-bottom: 10px;
          }`,
            },
            {
              type: 'description',
              data: '[salut]',
              cssRules: placeholdersItemStyles,
            },
            {
              type: 'description',
              data: 'salutation',
              gridArea: 'salut',
            },
            {
              type: 'description',
              data: '[am]',
              cssRules: placeholdersItemStyles,
            },
            {
              type: 'description',
              data: 'account manager',
            },
            {
              type: 'description',
              data: '[amtitle]',
              cssRules: placeholdersItemStyles,
            },
            {
              type: 'description',
              data: 'account manager title',
            },
            {
              type: 'description',
              data: '[phone]',
              cssRules: placeholdersItemStyles,
            },
            {
              type: 'description',
              data: 'phone number',
            },
            {
              type: 'description',
              data: '[email]',
              cssRules: placeholdersItemStyles,
            },
            {
              type: 'description',
              data: 'email',
            },
          ],
        },
      ],
    },
    {
      title: 'Rate Card Rates',
      stylesTemplate: {
        gridArea: 'ratesHeader',
        cssRules: `
      display: grid;
      background-color: #EAEAEB;
      border-bottom: 1px solid #BBBDC0;
      font-size: 13px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.8);
      height: 4.3rem;
      grid-column-gap: 2rem;
      grid-row-gap: 0.8rem;
      grid-template-columns: 1.1fr 0.7fr 1fr 1fr 1fr 1fr 1fr 1fr 0.6fr;
      margin-top: 0.5rem;
      & .plain-text {
        white-space: unset;
        text-align: right;
        align-items: center;
        display: flex;
        & p {
          padding: 0;
        }
      }
    `,
      },
      formData: [
        {
          type: 'description',
          data: 'Level',
          cssRules: `
      text-align: left;
    `,
        },
        {
          type: 'description',
          data: 'Dev Center',
          cssRules: `
      text-align: left;
    `,
        },
        {
          type: 'description',
          data: 'PTM rate/hr',
          cssRules: `
      margin-left: auto;
    `,
        },
        {
          type: 'description',
          data: 'TTM rate/hr',
          cssRules: `
      margin-left: auto;
    `,
        },
        {
          type: 'description',
          data: 'TTM preferred/hr',
          cssRules: `
      max-width: 9rem;
      margin-left: auto;
    `,
        },
        {
          type: 'description',
          data: 'TTM pltinum/hr',
          cssRules: `
      max-width: 9rem;
      margin-left: auto;
    `,
        },
        {
          type: 'description',
          data: 'ODC/mo',
        },
        {
          type: 'description',
          data: 'ODC platinum/mo',
          cssRules: `
      max-width: 9rem;
      margin-left: auto;
    `,
        },
        {
          type: 'description',
          data: 'Action',
          cssRules: `
      max-width: 9rem;
      margin-left: auto;
      text-align: left;
    `,
        },
      ],
    },
    {
      stylesTemplate: {
        gridArea: 'new',
        cssRules: `
      padding: 0;
      margin-top: 0;
      display: flex;
    `,
      },
    },
    {
      stylesTemplate: {
        gridArea: 'rates',
        cssRules: `
      padding: 0;
      margin-top: 0;
      grid-row-gap: 0;
    `,
      },
      formData: [
        {
          isFieldArray: true,
          name: filedArrayRootName,
          valueGetter: ({ rateCard }) => {
            const initialFieldArrayValues = rateCard.rates || [];
            const defaultLineValues = {
              seniority: null,
              devcenter: null,
              ratePtm: null,
              rateHr: null,
              rateDisc: null,
              ratePlatinum: null,
              rateMo: null,
              rateMoPlatinum: null,
            };
            const onPushLine = ({
              addLine,
              values = [],
              setFieldError,
              setFieldTouched,
            }) => {
              const emptyLines = values.findIndex(
                ({ seniority }) => !seniority
              );
              if (emptyLines !== -1) {
                setFieldError(
                  `rates[${emptyLines}].seniority`,
                  'Please select seniority'
                );
                setFieldTouched(`rates[${emptyLines}].seniority`, true);
                setFieldError(
                  `rates[${emptyLines}].devcenter`,
                  'Please select devcenter'
                );
                setFieldTouched(`rates[${emptyLines}].devcenter`, true);
              } else {
                addLine(defaultLineValues);
              }
            };
            const onDeleteLine = ({
              index,
              values = [],
              removeLine,
              addLine,
            }) => {
              if (values.length > 1) {
                removeLine(index);
              } else {
                removeLine(index);
                addLine(defaultLineValues);
              }
            };
            return {
              onPushLine,
              onDeleteLine,
              value: initialFieldArrayValues,
              lineGetter: ({ index, lineValues }) => {
                const {
                  devcenter,
                  seniority,
                  ratePtm,
                  rateHr,
                  rateDisc,
                  ratePlatinum,
                  rateMo,
                  rateMoPlatinum,
                  rateId,
                } = lineValues || {};
                return {
                  lineId: `${rateId}_${index}`,
                  stylesTemplate: {
                    cssRules: `
                margin-top:0;
                display: grid;
                grid-column-gap: 2rem;
                grid-row-gap:  0.8rem;
                grid-auto-rows: minmax(4rem,auto);
                padding: 0 1.6rem;
                grid-template-columns: 1.1fr 0.7fr 1fr 1fr 1fr 1fr 1fr 1fr 0.6fr;
                border-bottom: 1px solid #BBBDC0;
                && .text-input {
                  justify-content: center;
                  &__input-field {
                    padding-top: 1rem;
                  }
                }
                .text-input--locked::after {
                  height: 26px;
                  top: 0.9rem;
                }
                .error-message {
                  border-bottom: 1px solid #d61f26;
                  font-size: 0px;
                  margin-top: 0;
                  line-height: 0;
                  width: 100%;
                }
              `,
                  },
                  line: [
                    {
                      type: 'virtualField',
                      name: 'rateId',
                      fieldData: {
                        value: rateId,
                      },
                      isHidden: true,
                    },
                    {
                      type: 'select',
                      name: `rates[${index}].seniority`,
                      placeholder: '',
                      isLocked: !isCEO,
                      fieldData: {
                        selected: {
                          value: seniority,
                        },
                        items: [
                          {
                            value: 'senior',
                            label: 'Senior',
                          },
                          {
                            value: 'staff',
                            label: 'Staff',
                          },
                          {
                            value: 'junior',
                            label: 'Junior',
                          },
                        ],
                      },
                      validationRules: {
                        isRequired: true,
                      },
                      cssRules: `
                  && {
                    text-transform: uppercase;
                    font-size: 1.4rem;
                    justify-content: center;
                    display: flex;
                    height: 26px;
                    flex-direction: column;
                    .select-item__control {
                      border-bottom: none;
                    }
                    .select-item--is-disabled::after {
                      top: 1.8rem;
                      height: 26px;
                    }
                  }
                `,
                    },
                    {
                      type: 'select',
                      name: `rates[${index}].devcenter`,
                      placeholder: '',
                      isLocked: !isCEO,
                      fieldData: {
                        selected: {
                          value: devcenter,
                        },
                        items: devcenters.forSelectShortNames,
                      },
                      validationRules: {
                        isRequired: true,
                      },
                      cssRules: `
                  && {
                    text-transform: uppercase;
                    font-size: 1.4rem;
                    border: none;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    height: 26px;
                    .select-item__control {
                      border-bottom: none;
                    }
                    .select-item--is-disabled::after {
                      top: 1.8rem;
                      height: 26px;
                    }
                  }
                `,
                    },
                    {
                      type: 'text',
                      name: `rates[${index}].ratePtm`,
                      label: 'ratePtm',
                      isLocked: !isCEO,
                      fieldData: {
                        placeholder: ratePtm || '00.00',
                      },
                      validationRules: {
                        isRates: true,
                        decimalScale: 2,
                        isNumeric: true,
                        isRequired: true,
                        maxValue: 99999.99,
                      },
                      cssRules: `{
                  & .text-input__input-field {
                    margin-top: 0;
                    font-size: 1.4rem;
                    text-align: right;
                    border: none;
                  }
                }
                `,
                    },
                    {
                      type: 'text',
                      name: `rates[${index}].rateHr`,
                      label: 'rateHr',
                      isLocked: !isCEO,
                      fieldData: {
                        placeholder: rateHr || '00.00',
                      },
                      validationRules: {
                        isRates: true,
                        decimalScale: 2,
                        isNumeric: true,
                        isRequired: true,
                        maxValue: 99999.99,
                      },
                      cssRules: `{
                  & .text-input__input-field {
                    margin-top: 0;
                    font-size: 1.4rem;
                    text-align: right;
                    border: none;
                  }
                }
                `,
                    },
                    {
                      type: 'text',
                      name: `rates[${index}].rateDisc`,
                      label: 'rateDisc',
                      isLocked: !isCEO,
                      fieldData: {
                        placeholder: rateDisc || '00.00',
                      },
                      validationRules: {
                        isRates: true,
                        decimalScale: 2,
                        isNumeric: true,
                        isRequired: true,
                        maxValue: 99999.99,
                      },
                      cssRules: `{
                  & .text-input__input-field {
                    margin-top: 0;
                    font-size: 1.4rem;
                    text-align: right;
                    border: none;
                  }
                }
                `,
                    },
                    {
                      type: 'text',
                      name: `rates[${index}].ratePlatinum`,
                      label: 'ratePlatinum',
                      isLocked: !isCEO,
                      fieldData: {
                        placeholder: ratePlatinum || '00.00',
                      },
                      validationRules: {
                        isRates: true,
                        decimalScale: 2,
                        isNumeric: true,
                        isRequired: true,
                        maxValue: 99999.99,
                      },
                      cssRules: `{
                  & .text-input__input-field {
                    margin-top: 0;
                    font-size: 1.4rem;
                    text-align: right;
                    border: none;
                  }
                }
                `,
                    },
                    {
                      type: 'text',
                      name: `rates[${index}].rateMo`,
                      label: 'rateMo',
                      isLocked: !isCEO,
                      fieldData: {
                        placeholder: rateMo || '00.00',
                      },
                      validationRules: {
                        isRates: true,
                        decimalScale: 2,
                        isNumeric: true,
                        isRequired: true,
                        maxValue: 99999.99,
                      },
                      cssRules: `{
                  & .text-input__input-field {
                    margin-top: 0;
                    font-size: 1.4rem;
                    text-align: right;
                    border: none;
                  }
                }
                `,
                    },
                    {
                      type: 'text',
                      name: `rates[${index}].rateMoPlatinum`,
                      label: 'rateMoPlatinum',
                      isLocked: !isCEO,
                      fieldData: {
                        placeholder: rateMoPlatinum || '00.00',
                      },
                      validationRules: {
                        isRates: true,
                        decimalScale: 2,
                        isNumeric: true,
                        isRequired: true,
                        maxValue: 99999.99,
                      },
                      cssRules: `{
                  & .text-input__input-field {
                    margin-top: 0;
                    font-size: 1.4rem;
                    text-align: right;
                    border: none;
                  }
                }
                `,
                    },
                    {
                      type: 'actionButton',
                      data: 'Delete',
                      isHidden: !isCEO,
                      actionName: `${filedArrayRootName}_deleteLine`,
                      actionArguments: {
                        index,
                      },
                      cssRules: `
                  && {
                    border: none;
                    align-items: center;
                    display: flex;
                    height: 100%;
                    font-size: 13px;
                    line-height: 44px;
                  }
                `,
                    },
                  ],
                };
              },
              footerConfig: {
                stylesTemplate: {
                  cssRules: `
                margin: 0;
                padding: 0 1.6rem;
                grid-template-columns: 1.1fr 0.7fr 1fr 1fr 1fr 1fr 1fr 1fr 0.6fr;
                grid-column-gap: 2rem;
                grid-row-gap: 0.8rem;
                grid-auto-rows: minmax(4rem, auto);
                .action-button {
                  font-size: 13px;
                  line-height: 44px;
                }
              `,
                },
                data: [
                  {
                    type: 'actionButton',
                    data: 'Add line',
                    isHidden: !isCEO,
                    actionName: `${filedArrayRootName}_addLine`,
                    cssRules: `
                margin: 0;
                grid-column-start: 9;
              `,
                  },
                ],
              },
            };
          },
          validationRules: {
            schemaGetter: () => array().of(
              object().shape({
                seniority: string().nullable().required('Required field'),
                devcenter: number().nullable().required('Required field'),
                ratePtm: number().nullable().required('Required field'),
                rateHr: number().nullable().required('Required field'),
                rateDisc: number().nullable().required('Required field'),
                ratePlatinum: number().nullable().required('Required field'),
                rateMo: number().nullable().required('Required field'),
                rateMoPlatinum: number()
                  .nullable()
                  .required('Required field'),
              })
            ),
          },
        },
      ],
    },
    {
      stylesTemplate: {
        cssRules: `
      padding: 0;
      margin-top: 0;
      display: flex;
      justify-content: flex-end;
    `,
      },
      formData: [
        {
          type: 'actionButton',
          data: 'Preview',
          isHidden: isCPS,
          withBody: true,
          actionName: 'openModal',
          actionArguments: {
            currentModal: 'PREVIEW_MODAL',
            content: [
              {
                type: 'title',
                data: `${rateCardName} email text preview`,
              },
              {
                type: 'description',
                data: emailContent,
              },
            ],
          },
          parentField: {
            name: ['emailContent'],
          },
          cssRules: `
      && {
        width: 18rem;
        height: 3.2rem;
        text-align: center;
        border: 1px solid #58595b;
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.6rem;
        letter-spacing: 0.25px;
        color: #58595b;
        margin-left: 0;
      }
    `,
        },
      ],
    },
    {
      stylesTemplate: {
        gridArea: 'delete',
        cssRules: `
      padding: 0;
      margin-top: 3rem;
      && {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        height: 100%;
      }
    `,
      },
      formData: [
        {
          type: 'actionButton',
          withBody: true,
          data: 'Delete',
          isHidden: createMode || !isCEO,
          actionName: 'openModal',
          actionArguments: {
            currentModal: 'CONFIRM_ACTION_MODAL',
            content: [
              {
                type: 'title',
                data: `Delete this position record start date ${moment(
                  effectiveDate
                ).format('L')}?`,
              },
              {
                type: 'description',
                data: 'This record will be permanently deleted and cannot be restored.',
              },
            ],
            acceptActionName: 'deleteRateCardRecord',
            acceptActionTitle: 'Delete',
            acceptActionArguments: {
              recordId,
              isActive,
              year,
            },
          },
          cssRules: `
      && {
        width: 18rem;
        height: 3.2rem;
        text-align: center;
        border: 1px solid #58595b;
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.6rem;
        letter-spacing: 0.25px;
        color: #58595b;
        margin-left: auto;
      }
    `,
        },
      ],
    },
  ];
};
