import { pathToAttachmentRecords, recordIdName } from 'core/delivery/attachmentManagement/constants';
import { FORM_STATE } from 'core/utils';
import update from 'immutability-helper';

import { newAttachmentRecord } from 'layouts/employee-details/components/resource-tabs/attachments/constants';
import get from 'lodash/get';

import { attachmentsTypes } from './actions';

const {
  ADD_ATTACHMENT_RECORD,
  REMOVE_ATTACHMENT_RECORD,
  GET_ATTACHMENTS_RECORDS,
  GET_ATTACHMENTS_RECORDS_SUCCESS,
  GET_ATTACHMENTS_RECORDS_FAIL,
  CREATE_ATTACHMENT_RECORD,
  CREATE_ATTACHMENT_RECORD_SUCCESS,
  CREATE_ATTACHMENT_RECORD_FAIL,
  DELETE_ATTACHMENT_RECORD,
  DELETE_ATTACHMENT_RECORD_SUCCESS,
  DELETE_ATTACHMENT_RECORD_FAIL,
  UPDATE_ATTACHMENT_RECORD,
  UPDATE_ATTACHMENT_RECORD_SUCCESS,
  UPDATE_ATTACHMENT_RECORD_FAIL,
} = attachmentsTypes;

export const attachmentsReducerCases = {
  [GET_ATTACHMENTS_RECORDS](state) {
    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          isFetching: {
            $set: true,
          },
        },
      },
    });
  },
  [GET_ATTACHMENTS_RECORDS_SUCCESS](state, { payload: {
    records,
    memoDevstaffId,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          isFetching: {
            $set: false,
          },
          length: {
            $set: records.length,
          },
          attachmentManagementRecords: {
            $set: records,
          },
          formState: {
            $set: {
              status: FORM_STATE.INITIAL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
          memoDevstaffId: {
            $set: memoDevstaffId,
          },
        },
      },
    });
  },
  [GET_ATTACHMENTS_RECORDS_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          isFetching: {
            $set: false,
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
  [ADD_ATTACHMENT_RECORD](state, { payload }) {
    const attachmentManagementRecords = get(state, pathToAttachmentRecords, []);

    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          attachmentManagementRecords: {
            $set: [payload, ...attachmentManagementRecords],
          },
        },
      },
    });
  },
  [REMOVE_ATTACHMENT_RECORD](state) {
    const attachmentManagementRecords = get(state, pathToAttachmentRecords, []);

    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          attachmentManagementRecords: {
            $set: attachmentManagementRecords.filter((record) => record[recordIdName] !== newAttachmentRecord({})[recordIdName]),
          },
        },
      },
    });
  },
  [CREATE_ATTACHMENT_RECORD](state) {
    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          isFetching: {
            $set: true,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [CREATE_ATTACHMENT_RECORD_SUCCESS](state, { payload: { record } }) {
    const attachmentManagementRecords =
      get(state, pathToAttachmentRecords, []);

    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          attachmentManagementRecords: {
            $set: attachmentManagementRecords.map((currentRecord) => {
              if (currentRecord[recordIdName] === newAttachmentRecord({})[recordIdName]) {
                return record;
              }

              return currentRecord;
            }),
          },
          isFetching: {
            $set: false,
          },
          length: {
            $set: attachmentManagementRecords.length,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [CREATE_ATTACHMENT_RECORD_FAIL](state, { payload: { errors } }) {
    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          isFetching: {
            $set: false,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: true,
            },
          },
        },
        errors: {
          $merge: errors,
        },
      },
    });
  },
  [DELETE_ATTACHMENT_RECORD](state) {
    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          isFetching: {
            $set: true,
          },
        },
      },
    });
  },
  [DELETE_ATTACHMENT_RECORD_SUCCESS](state, { payload: {
    attachmentRecordId,
    isCreateAttachmentRecord,
  } }) {
    if (isCreateAttachmentRecord) {
      return update(state, {
        employeeDetailsTabs: {
          attachments: {
            isFetching: {
              $set: false,
            },
            formState: {
              $set: {
                status: FORM_STATE.INITIAL,
                isSubmitting: false,
                isSubmittedSuccess: false,
                isSubmittedFail: false,
              },
            },
          },
        },
      });
    }

    const attachmentManagementRecords = get(state, pathToAttachmentRecords, [])
      .filter((record) => record[recordIdName] !== attachmentRecordId);

    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          attachmentManagementRecords: {
            $set: attachmentManagementRecords,
          },
          isFetching: {
            $set: false,
          },
          length: {
            $set: attachmentManagementRecords.length,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [DELETE_ATTACHMENT_RECORD_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: true,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
  [UPDATE_ATTACHMENT_RECORD](state) {
    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [UPDATE_ATTACHMENT_RECORD_SUCCESS](state, { payload: {
    record,
  } }) {
    const attachmentManagementRecords = get(state, pathToAttachmentRecords, [])
      .map((currentRecord) => {
        if (currentRecord[recordIdName] === record[recordIdName]) {
          return record;
        }

        return currentRecord;
      });

    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          attachmentManagementRecords: {
            $set: attachmentManagementRecords,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [UPDATE_ATTACHMENT_RECORD_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        attachments: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: true,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
};
