import { useMemo } from 'react';

import { NOTIFICATIONS_FAILED_REQUESTS, NOTIFICATIONS_CATEGORIES_FORM_ID } from 'core/configurations/category-management/constants';
import { generateFormNotifications, generateRequestNotifications, getFormCategoriesErrors } from 'forms/categories-form/utils';
import { useEffectOnlyOnUpdate } from 'hooks';
import { isEmpty } from 'lodash';

export const useNotifications = (requestErrors, formErrors, options) => {
  const { isDirty, isFailed } = options;
  const memoizedFormErrors =
    useMemo(() => getFormCategoriesErrors(formErrors.categories), [formErrors]);
  const isEmptyFormErrors = isEmpty(memoizedFormErrors);
  const isHidden = !isFailed && isEmptyFormErrors;
  const containerId = isFailed && isEmptyFormErrors ?
    NOTIFICATIONS_FAILED_REQUESTS :
    NOTIFICATIONS_CATEGORIES_FORM_ID;

  useEffectOnlyOnUpdate(
    () => {
      if (isDirty && !isEmptyFormErrors) {
        generateFormNotifications(memoizedFormErrors);
      }
    },
    [
      memoizedFormErrors,
      isDirty,
    ]
  );

  useEffectOnlyOnUpdate(() => {
    if (isFailed) {
      generateRequestNotifications(requestErrors);
    }
  }, [isFailed, requestErrors]);

  return { isHidden, containerId };
};
