import React, { useEffect, useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';

import {
  colorPrimary,
  colorWhite,
} from 'assets/styles/variables';
import Calendar from 'components/calendar';
import Controls from 'components/details-controls';
import ToastContainer from 'components/toast';
import { STORE_KEYS } from 'core/reconciliation/constants';
import GoTop from 'elements/go-top-button';
import StyledWrapper from 'elements/styled-wrapper';
import moment from 'moment';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { autoScrollToTop } from 'utils/react';

const filtersWrapperCssRules = `
  display: grid;
  grid-template-columns: max-content 22rem 15rem max-content;
`;

const controlsCssRules = `
  &&& {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 36rem);
    grid-row-gap: 0.8rem;
    grid-auto-rows: minmax(3.2rem, auto);
    padding-top: 0.8rem;
    padding-bottom: 1.6rem;
    font-size: 1.5rem;
  }
`;

const calendarCssRules = `
  && {
    display: flex;
    min-width: unset;
    align-items: center;
    margin-right: 1.6rem;
    padding-top: 0;
    border: none;
    & path {
      fill: ${colorPrimary};
    }
  }
  && .calendar-date-picker__header-control-image {
    width: 1.9rem;
    height: 1.9rem;
  }
  && .calendar-date-picker__header-control--arrow {
    width: 1.9rem;
    height: 1.9rem;
  }
  && .calendar__date-wrapper {
    color: ${colorPrimary};
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

const controlsWrapperCssRules = `
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 0.7rem 2.4rem;
  font-size: 1.3rem;
  line-height: 1.6rem;
  text-align: center;
  color: ${colorPrimary};
  border-color: ${colorPrimary};
  background-color: transparent;
  &:hover {
    color: ${colorWhite};
    background-color: ${colorPrimary};
  }
`;

const ClientReconciliationReport = ({
  location,
  filters,
  clientId,
  entityName,
  changeFilter,
  getReconciliationReport,
  getReconciliationSecondReport,
  reconciliationReportData,
}) => {
  autoScrollToTop(location);

  const {
    controls,
    calendarItemCssRules,
  } = reconciliationReportData;
  const { [STORE_KEYS.YEAR]: selectedReportDate = moment() } = filters;
  const displayedDate = useMemo(() => selectedReportDate && selectedReportDate.format('YYYY'), [selectedReportDate]);

  const resetFilters = useCallback(() => {
    changeFilter({
      [STORE_KEYS.YEAR]: selectedReportDate,
    });
  }, [selectedReportDate]);

  const setDate = useCallback((date) => {
    changeFilter({
      [STORE_KEYS.YEAR]: date,
    });
  }, []);

  const actions = {
    getReconciliationReport: (actionArguments) => getReconciliationReport(actionArguments),
    getReconciliationSecondReport: (actionArguments) => getReconciliationSecondReport(actionArguments),
  };

  useEffect(() => {
    if (clientId) {
      changeFilter({
        [STORE_KEYS.CLIENT_ID]: clientId,
      });
    }

    return () => {
      // We are going to reset filters on layout unmount.
      resetFilters();
    };
  }, []);

  return (
    <>
      <ToastContainer
        containerId={entityName}
      />

      <Controls
        controlsHeading="Reconciliation Liability Report"
        cssRules={controlsCssRules}
        wrapperCssRules={controlsWrapperCssRules}
      >
        <div style={{ display: 'flex' }}>
          {
            controls.map(({
              title: buttonTitle,
              actionArguments,
              actionName = 'nope',
            }) => (
              <StyledButton
                key={buttonTitle}
                className="controls__create-button button button--cancel client-details-controls__button--fixed-size"
                onClick={() => actions[actionName](actionArguments)}
              >
                {buttonTitle}
              </StyledButton>
            ))
          }
        </div>

        <StyledWrapper
          cssRules={filtersWrapperCssRules}
        >
          <Calendar
            withStepControls
            withMonthSelecting
            minDetails="year_only"
            currentDate={selectedReportDate}
            onChange={setDate}
            withYearSelecting
            stepControlsConfig={{
              step: 1,
              unitName: 'year',
            }}
            cssRules={calendarCssRules}
            value={selectedReportDate}
            popperProps={{
              placement: 'bottom-start',
            }}
          >
            <StyledWrapper
              cssRules={calendarItemCssRules}
            >
              {displayedDate}
            </StyledWrapper>
          </Calendar>
        </StyledWrapper>
      </Controls>
      <GoTop />
    </>
  );
};


ClientReconciliationReport.propTypes = {
  location: PropTypes.object.isRequired,
  tab: PropTypes.string,
  reportType: PropTypes.string,
  isFormSubmitted: PropTypes.bool,
  userId: PropTypes.number.isRequired,
  changeOrder: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
};

ClientReconciliationReport.defaultProps = {
  tab: '',
  reportType: '',
  isFormSubmitted: false,
};


export default withRouter(ClientReconciliationReport);
