import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import { ENTITY_NAME } from '../constants';

export const {
  GET_CONTRACT_MANAGEMENT_RECORDS,
  GET_CONTRACT_MANAGEMENT_RECORDS_SUCCESS,
  GET_CONTRACT_MANAGEMENT_RECORDS_FAIL,
  CREATE_CONTRACT_MANAGEMENT_RECORD,
  CREATE_CONTRACT_MANAGEMENT_RECORD_SUCCESS,
  CREATE_CONTRACT_MANAGEMENT_RECORD_FAIL,
  UPDATE_CONTRACT_MANAGEMENT_RECORD,
  UPDATE_CONTRACT_MANAGEMENT_RECORD_SUCCESS,
  UPDATE_CONTRACT_MANAGEMENT_RECORD_FAIL,
  DELETE_CONTRACT_MANAGEMENT_RECORD,
  DELETE_CONTRACT_MANAGEMENT_RECORD_SUCCESS,
  DELETE_CONTRACT_MANAGEMENT_RECORD_FAIL,
  UPLOAD_ATTACHMENT_FILE,
  UPLOAD_ATTACHMENT_FILE_SUCCESS,
  UPLOAD_ATTACHMENT_FILE_FAIL,
  SET_ATTACHMENT_FILE,
  UPDATE_UPLOADING_PROGRESS,
  ADD_CONTRACT_RECORD,
  REMOVE_CONTRACT_RECORD,
  UPLOAD_CONTRACT_MANAGEMENT_FILE,
} = createTypes([
  ...async('GET_CONTRACT_MANAGEMENT_RECORDS'),
  ...async('CREATE_CONTRACT_MANAGEMENT_RECORD'),
  ...async('UPDATE_CONTRACT_MANAGEMENT_RECORD'),
  ...async('DELETE_CONTRACT_MANAGEMENT_RECORD'),
  ...async('UPLOAD_ATTACHMENT_FILE'),
  ...async('UPLOAD_CONTRACT_MANAGEMENT_FILE'),
  'ADD_CONTRACT_RECORD',
  'REMOVE_CONTRACT_RECORD',
  'SET_ATTACHMENT_FILE',
], ENTITY_NAME);

export const contractManagementActions = {
  getContractManagementRecords: createAction(GET_CONTRACT_MANAGEMENT_RECORDS),
  getContractManagementRecordsSuccess: createAction(GET_CONTRACT_MANAGEMENT_RECORDS_SUCCESS),
  getContractManagementRecordsFail: createAction(GET_CONTRACT_MANAGEMENT_RECORDS_FAIL, (errors) => ({ errors })),

  createContractManagementRecord: createAction(CREATE_CONTRACT_MANAGEMENT_RECORD),
  createContractManagementRecordSuccess: createAction(CREATE_CONTRACT_MANAGEMENT_RECORD_SUCCESS, (record) => (record)),
  createContractManagementRecordFail: createAction(CREATE_CONTRACT_MANAGEMENT_RECORD_FAIL, (errors) => ({ errors })),

  updateContractManagementRecord: createAction(UPDATE_CONTRACT_MANAGEMENT_RECORD),
  updateContractManagementRecordSuccess: createAction(UPDATE_CONTRACT_MANAGEMENT_RECORD_SUCCESS, (record) => (record)),
  updateContractManagementRecordFail: createAction(UPDATE_CONTRACT_MANAGEMENT_RECORD_FAIL, (errors) => ({ errors })),

  deleteContractManagementRecord: createAction(DELETE_CONTRACT_MANAGEMENT_RECORD),
  deleteContractManagementRecordSuccess: createAction(DELETE_CONTRACT_MANAGEMENT_RECORD_SUCCESS, (recordId) => (recordId)),
  deleteContractManagementRecordFail: createAction(DELETE_CONTRACT_MANAGEMENT_RECORD_FAIL, (errors) => ({ errors })),

  addContractRecord: createAction(ADD_CONTRACT_RECORD, (record) => (record)),
  removeContractRecord: createAction(REMOVE_CONTRACT_RECORD),

  uploadAttachmentFile: createAction(UPLOAD_ATTACHMENT_FILE),
  uploadAttachmentFileSuccess: createAction(UPLOAD_ATTACHMENT_FILE_SUCCESS),
  uploadAttachmentFileFail: createAction(UPLOAD_ATTACHMENT_FILE_FAIL, (errors) => ({ errors })),

  uploadContractManagementFile: createAction(UPLOAD_CONTRACT_MANAGEMENT_FILE),
  uploadContractManagementFileSuccess: createAction(UPLOAD_ATTACHMENT_FILE_SUCCESS),
  uploadContractManagementFileFail: createAction(UPLOAD_ATTACHMENT_FILE_FAIL, (errors) => ({ errors })),
};
