export const plLevelByIdForInfoCardRepresentation = {
  '-1': '0',
  1: '1',
  2: '2',
};

export const plLevelByIdForFormRepresentation = {
  '-1': '-1',
  1: '1',
  2: '2',
};

export const plLevelField = {
  items: [
    {
      value: '-2',
      label: '--',
    },
    {
      value: '-1',
      label: 0,
    },
    {
      value: '1',
      label: 1,
    },
    {
      value: '2',
      label: 2,
    },
  ],
};

export const seniorityItems = [
  {
    value: 'Intern',
    label: 'Intern',
  },
  {
    value: 'Junior',
    label: 'Junior',
  },
  {
    value: 'Staff',
    label: 'Staff',
  },
  {
    value: 'Senior',
    label: 'Senior',
  },
];

export const emptySelectValue = {
  value: '',
  label: '--',
};

export const internalSeniorityItems = {
  Junior: [
    {
      value: 'J0',
      label: 'J0',
    },
    {
      value: 'J1',
      label: 'J1',
    },
    {
      value: 'J2',
      label: 'J2',
    },
  ],
  Staff: [
    {
      value: '',
      label: '--',
    },
    {
      value: 'ST0',
      label: 'ST0',
    },
    {
      value: 'ST1',
      label: 'ST1',
    },
    {
      value: 'ST2',
      label: 'ST2',
    },
  ],
  Senior: [
    {
      value: '',
      label: '--',
    },
    {
      value: 'S0',
      label: 'S0',
    },
    {
      value: 'S1',
      label: 'S1',
    },
    {
      value: 'S2',
      label: 'S2',
    },
  ],
};

export const fieldsDataBySeniority = {
  Intern: {
    selected: {
      value: 'Intern',
      label: 'Intern',
    },
  },
  Junior: {
    selected: {
      value: 'Junior',
      label: 'Junior',
    },
    internalSeniorityItems: internalSeniorityItems.Junior,
  },
  Staff: {
    selected: {
      value: 'Staff',
      label: 'Staff',
    },
    internalSeniorityItems: internalSeniorityItems.Staff,
  },
  Senior: {
    selected: {
      value: 'Senior',
      label: 'Senior',
    },
    internalSeniorityItems: internalSeniorityItems.Senior,
  },
};

export const hrsDayItems = [{
  value: 8,
  label: '8',
}, {
  value: 6,
  label: '6',
}, {
  value: 4,
  label: '4',
}];

export const ACTION_BUTTON_TEXTS = {
  DELETE: 'Delete',
  CANCEL: 'Cancel',
  SEND_TO_APPROVE: 'Send to approve',
  APPROVE: 'Approve',
  DISAPPROVE: 'Disapprove',
  NOT_AVAILABLE: 'Not Available',
};

export const WORKBOOK_ACTIONS = {
  UPDATE: 'updateWorkbookRecord',
  CREATE: 'createWorkbookRecord',
};
