import { USERS_GROUPS } from 'core/auth/constants';
import {
  assignedProjects,
  deleteStaffingRows,
  deleteTaskOrderFiles,
  sendForSignature,
  taskOrdersDocumentGenerate,
} from 'core/auth/guaranteedAccessRoles';
import assignedClientCategoriesTabGetter from 'layouts/task-order-details/model/assigned-client-categories-tab';
import {
  getRatesStatus,
  getTaskOrderStatus,
  isODCContract,
  isPTMContract,
  isTTMContract,
} from 'layouts/task-order-details/utils';
import { get, mapKeys, pickBy } from 'lodash';
import { getHasPermissions } from 'utils/auth';
import { getIsExpired } from 'utils/helpers/date';
import { generateTaskOrderTitle } from 'utils/helpers/files';

import detailsTabBodyGetter from './detail-tab';
import documentsTabBodyGetter from './documents-tab';
import rateCardTabBodyGetter from './rate-card-tab';
import staffingTabGetter from './staffing-tab';

const { HRM, CEO, CTO, CPS, CSO, SALES } = USERS_GROUPS;

const getTemplateTaskOrderRatecard = (taskOrderRates) => taskOrderRates ?
  taskOrderRates.rates.map((rate) => {
    const filteredRates = pickBy(rate, (value, key) => key.includes('Init'));
    filteredRates.devcenter = rate.devcenter;
    filteredRates.seniority = rate.seniority;

    return mapKeys(filteredRates, (value, key) => key.replace('Init', ''));
  }, []) :
  [];
export const taskOrderDetailsModelGetter = (
  userGroup,
  accountId,
  {
    holidays,
    isSigned,
    devcenters,
    taskOrderId,
    projectType,
    effortLines,
    taskOrderRates,
    ratecards,
    client: clientData,
    isManuallyDeactivated,
    clients: clientsList = [],
    endDate: taskOrderEndDate,
    files: taskOrderFiles = [],
    isActive: isTaskOrderActive,
    clientCategories: assignedClientCategories,
    effectiveDate: taskOrderEffectiveDate,
    selectCommittedHoursByClientId,
  }
) => {
  const isCreatMode = !taskOrderId;
  const isTTM = isTTMContract(projectType);
  const isPTM = isPTMContract(projectType);
  const isODC = isODCContract(projectType);
  const isExpired = getIsExpired(taskOrderEndDate);
  const isCEO =
    getHasPermissions(userGroup, [CEO]) || getHasPermissions(userGroup, [HRM]);
  const isCPS = getHasPermissions(userGroup, [CPS]);
  const isCSO = getHasPermissions(userGroup, [CSO]);
  const isCTO = getHasPermissions(userGroup, [CTO]);
  const isHRM = getHasPermissions(userGroup, [HRM]);
  const hasEfforts = effortLines && effortLines.length;
  const isUserSales = getHasPermissions(userGroup, [CSO, SALES]);
  const taskOrderTitle = generateTaskOrderTitle(taskOrderFiles, taskOrderId);
  const templateTaskOrderRatecard =
    getTemplateTaskOrderRatecard(taskOrderRates);
  const hasPermissionsToDelete = getHasPermissions(
    userGroup,
    deleteTaskOrderFiles
  );
  const hasPermissionsAssignedProjects = getHasPermissions(
    userGroup,
    assignedProjects
  );
  const hasPermissionsTosSendForSignature = getHasPermissions(
    userGroup,
    sendForSignature
  );
  const hasPermissionsToADDFiles = getHasPermissions(
    userGroup,
    taskOrdersDocumentGenerate
  );
  const hasPermissionsToDeleteStaffingRows = getHasPermissions(
    userGroup,
    deleteStaffingRows
  );
  return {
    rules: {
      css: {},
    },
    additionalDataGetter: ({ isActive, files }) => ({
      pocMsaName: clientData && clientData.pocMsaName,
      pocMsaEmail: clientData && clientData.pocMsaEmail,
      status: getTaskOrderStatus({
        isCEO,
        files,
        isActive,
        isSigned,
        isExpired,
        taskOrderId,
        isManuallyDeactivated,
      }),
      isPTM,
      isTTM,
    }),
    dataTemplate: [
      {
        tabHeader: {
          title: 'Details',
          labels: [
            {
              valueGetter: ({ client }) => ({
                value: client && client.name ? client.name : '-',
                isEmpty: !(client && client.name),
              }),
            },
            {
              valueGetter: ({ title }) => ({
                value: title || '-',
                isEmpty: !title,
              }),
            },
          ],
        },
        tabBody: detailsTabBodyGetter({
          isCEO,
          isHRM,
          isCTO,
          isCPS,
          isExpired,
          clientData,
          isCreatMode,
          clientsList,
          taskOrderId,
          isTaskOrderActive,
          isManuallyDeactivated,
          taskOrderEffectiveDate,
          selectCommittedHoursByClientId,
          status: getTaskOrderStatus({
            isCEO,
            isActive: isTaskOrderActive,
            isSigned,
            isExpired,
            taskOrderId,
            isManuallyDeactivated,
          }),
        }),
      },
      {
        tabHeader: {
          title: 'Rate Card',
          labels: [
            {
              valueGetter: ({ isPlatinum }) => {
                const ratecardName = get(taskOrderRates, 'name');

                return {
                  value: `${ratecardName || '-'} / ${
                    isPlatinum ? 'Platinum' : 'Not platinum'
                  }`,
                  isEmpty: !ratecardName && !isPlatinum,
                };
              },
            },
            {
              valueGetter: ({ client, taskOrderRates: rateCard }) => ({
                value: rateCard ?
                  getRatesStatus({
                    client,
                    rateCard,
                    templateTaskOrderRatecard,
                  }).message :
                  'No Rate Card',
                isEmpty: !rateCard,
              }),
            },
          ],
        },

        tabBody: rateCardTabBodyGetter({
          isCEO,
          isCTO,
          isCSO,
          isCPS,
          isExpired,
          clientData,
          isTaskOrderActive,
          isSigned,
          isManuallyDeactivated,
          templateTaskOrderRatecard,
          ratecards,
        }),
      },
      {
        tabHeader: {
          title: 'Documents',
          labels: [
            {
              valueGetter: () => ({
                value: `TO ${isSigned ? 'signed' : 'not signed'}`,
              }),
            },
          ],
        },
        stylesTemplate: {
          wrapperCssRules: `
            display: grid;
            grid-template-columns: 1fr 1fr 1fr minmax(32.8rem, 1fr);
            grid-template-areas:
              "detailsTable detailsTable detailsTable ${
  hasPermissionsToADDFiles && (isCEO || !isManuallyDeactivated) ?
    'documentControl' :
    'detailsTable'
}"
              "detailsForm detailsForm detailsForm ${
  hasPermissionsToADDFiles && !isManuallyDeactivated ?
    'documentControl' :
    'detailsForm'
}"
              "detailsFormButtons detailsFormButtons detailsFormButtons detailsFormButtons";
            min-height: calc(100vh - 30rem);  `,
        },

        tabBody: documentsTabBodyGetter({
          isPTM,
          isCTO,
          isCEO,
          isCPS,
          isExpired,
          hasEfforts,
          isUserSales,
          taskOrderTitle,
          taskOrderEndDate,
          isTaskOrderActive,
          isManuallyDeactivated,
          hasPermissionsToDelete,
          hasPermissionsToADDFiles,
          hasPermissionsTosSendForSignature,
          devcenters,
        }),
      },
      {
        tabHeader: {
          title: 'Assigned Client Categories',
          labels: [
            {
              valueGetter: ({ clientCategories }) => {
                const length = clientCategories ? clientCategories.length : 0;
                return {
                  value: `${length} Client Categor${length > 1 ? 'ies' : 'y'}`,
                  isEmpty: !length,
                };
              },
            },
            {
              valueGetter: ({ clientCategories }) => {
                const length = clientCategories ?
                  clientCategories.filter(({ isAssigned }) => isAssigned).length :
                  0;
                return {
                  value: `${length} Assigned`,
                  isEmpty: !length,
                };
              },
            },
          ],
        },

        tabBody: assignedClientCategoriesTabGetter({
          isCEO,
          isCPS,
          isExpired,
          assignedClientCategories,
          isManuallyDeactivated,
          hasPermissionsAssignedProjects,
        }),
      },
      {
        tabHeader: {
          title: 'Staffing',
          labels: [
            {
              valueGetter: ({ employees = [] }) => ({
                value: employees.length ?
                  `${employees.length} employee${
                    employees.length > 1 ? 's' : ''
                  } staffed` :
                  'No employees staffed',
                isEmpty: !employees.length,
              }),
            },
          ],
        },

        tabBody: staffingTabGetter({
          isODC,
          isTTM,
          isPTM,
          isCEO,
          isCPS,
          isTaskOrderActive,
          holidays,
          isExpired,
          devcenters,
          isUserSales,
          isManuallyDeactivated,
          taskOrderEffectiveDate,
          hasPermissionsToDeleteStaffingRows,
          status: getTaskOrderStatus({
            isCEO,
            isActive: isTaskOrderActive,
            isSigned,
            isExpired,
            taskOrderId,
            isManuallyDeactivated,
          }),
        }),
      },
    ],
  };
};
