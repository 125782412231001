import { STORE_KEYS } from 'core/billing-reports/constants';
import {
  get,
} from 'lodash';

import { checkIsSelected } from 'utils/helpers/filters';

export const filtersTemplates = [{
  name: STORE_KEYS.CURRENT_PAYROLL_REPORT_ANALYSIS_FILTER,
  filtersTemplate: [{
    storeKey: STORE_KEYS.DEVCENTER,
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'devcenterIds', '');

      return checkIsSelected({ selected, value });
    },
  }],
}];
