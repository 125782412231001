import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import classNames from 'classnames';
import { get, toNumber } from 'lodash';

import { ReactComponent as ArrowDoubleLeft } from 'assets/img/icon-arrow-double-left.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/icon-arrow-left.svg';
import { ReactComponent as ArrowDoubleRight } from 'assets/img/icon-arrow-double-right.svg';
import { ReactComponent as ArrowRight } from 'assets/img/icon-arrow-right.svg';

import Decade from './decade';
import Month from './month';
import Year from './year';

import { getMonthConfig } from './utils';

import {
  YEAR,
  MONTH,
  DECADE,
  YEAR_ONLY,
  viewsList,
} from './config';

const views = {
  [MONTH]: {
    index: 0,
    component: Month,
    headingGetter: (currentDate) => currentDate.format('MMMM YYYY'),
    updateDisplayedDateConfig: {
      unitName: 'month',
      step: 1,
      multiplier: 12,
    },
  },
  [YEAR]: {
    index: 1,
    component: Year,
    headingGetter: (currentDate) => currentDate.format('YYYY'),
    updateDisplayedDateConfig: {
      unitName: 'year',
      step: 1,
      multiplier: 10,
    },
  },
  [DECADE]: {
    index: 2,
    component: Decade,
    headingGetter: (currentDate) => {
      const currentYear = currentDate.format('YYYY');
      return `${toNumber(currentYear) - 4} - ${toNumber(currentYear) + 4}`;
    },
    updateDisplayedDateConfig: {
      unitName: 'year',
      step: 3,
    },
  },
  [YEAR_ONLY]: {
    index: 3,
    component: Decade,
    headingGetter: (currentDate) => currentDate.format('YYYY'),
    updateDisplayedDateConfig: {
      unitName: 'year',
      step: 1,
      multiplier: 10,
    },
  },
};


const DatePicker = ({
  currentDate,
  displayedDate: dateToBeDisplayed,
  minDetails,
  maxDetails,
  selectDate,
  holydaysSet,
  onYearClick,
  selectedDate,
  onMonthClick,
  showRestDays,
  isWeekPicker,
  businessDaysSet,
  minYear,
  maxYear,
  setUseOnlyMonth,
  setUseOnlyYear,
  withCurrentDate,
  ...otherProps
}) => {
  const initialViewIndex = get(views, `${minDetails}.index`, 0);
  const maximalViewIndex = get(views, `${maxDetails}.index`, 2);
  const currentDateMMDDYYYY = currentDate.format('MM/DD/YYYY');
  const [displayedDate, updateDisplayedDate] = useState((selectedDate || dateToBeDisplayed || currentDate).clone());
  const [viewIndex, setViewIndex] = useState(initialViewIndex);
  const currentViewName = viewsList[viewIndex];
  const { component, headingGetter, updateDisplayedDateConfig } = get(views, currentViewName, {});
  const { unitName, step, multiplier } = updateDisplayedDateConfig;
  const resetView = () => {
    setViewIndex(initialViewIndex);
  };

  const pushView = () => {
    setViewIndex((currentIndex) => currentIndex === maximalViewIndex ? currentIndex : currentIndex + 1);
  };

  const popView = () => {
    setViewIndex((currentIndex) => currentIndex === initialViewIndex ? currentIndex : currentIndex - 1);
  };

  const prevDisplayedDate = useMemo(
    () => displayedDate.clone().add(-step, unitName),
    [displayedDate, step, unitName],
  );
  const prevFastDisplayedDate = useMemo(
    () => displayedDate.clone().add(-step * multiplier, unitName),
    [displayedDate, step, multiplier, unitName],
  );
  const nextDisplayedDate = useMemo(
    () => displayedDate.clone().add(step, unitName),
    [displayedDate, step, unitName],
  );
  const nextFastDisplayedDate = useMemo(
    () => displayedDate.clone().add(step * multiplier, unitName),
    [displayedDate, step, multiplier, unitName],
  );

  const isPrevDisplayedDateDisabled = useMemo(
    () => minYear != null && prevDisplayedDate.year() < minYear,
    [prevDisplayedDate, minYear],
  );
  const isPrevFastDisplayedDateDisabled = useMemo(
    () => minYear != null && prevFastDisplayedDate.year() < minYear,
    [prevFastDisplayedDate, minYear],
  );
  const isNextDisplayedDateDisabled = useMemo(
    () => maxYear != null && nextDisplayedDate.year() > maxYear,
    [nextDisplayedDate, maxYear],
  );
  const isNextFastDisplayedDateDisabled = useMemo(
    () => maxYear != null && nextFastDisplayedDate.year() > maxYear,
    [nextFastDisplayedDate, maxYear],
  );

  const decrementDisplayedDate = useCallback(() => {
    if (!isPrevDisplayedDateDisabled) {
      updateDisplayedDate(prevDisplayedDate);
    }
  }, [isPrevDisplayedDateDisabled, updateDisplayedDate, prevDisplayedDate]);
  const decrementFastDisplayedDate = useCallback(() => {
    if (!isPrevFastDisplayedDateDisabled) {
      updateDisplayedDate(prevFastDisplayedDate);
    }
  }, [isPrevFastDisplayedDateDisabled, updateDisplayedDate, prevFastDisplayedDate]);
  const incrementDisplayedDate = useCallback(() => {
    if (!isNextDisplayedDateDisabled) {
      updateDisplayedDate(nextDisplayedDate);
    }
  }, [isNextDisplayedDateDisabled, updateDisplayedDate, nextDisplayedDate]);
  const incrementFastDisplayedDate = useCallback(() => {
    if (!isNextFastDisplayedDateDisabled) {
      updateDisplayedDate(nextFastDisplayedDate);
    }
  }, [isNextFastDisplayedDateDisabled, updateDisplayedDate, nextFastDisplayedDate]);

  const onSelectDate = (date) => {
    setUseOnlyYear(false);
    setUseOnlyMonth(false);
    selectDate(date);
    resetView();
  };

  const onSelectMonth = (month) => {
    const date = displayedDate.clone().set('month', month);

    if (onMonthClick) {
      onMonthClick(date);
      resetView();
    } else {
      updateDisplayedDate(date);
      popView();
    }
  };

  const onSelectYear = (year) => {
    const date = displayedDate.clone().set('year', year);

    if (onYearClick) {
      onYearClick(date);
      resetView();
    } else {
      updateDisplayedDate(date);
      popView();
    }
  };

  const setCurrentDate = () => onSelectDate(currentDate);

  const monthConfig = useMemo(() => getMonthConfig({ selectedDate, displayedDate, currentDate, businessDaysSet, holydaysSet }), [selectedDate, displayedDate]);
  const { weeks } = monthConfig;

  const decrementButton = (
    <button
      className={classNames(
        'calendar-date-picker__header-control',
        'calendar-date-picker__header-control--arrow',
        {
          'calendar-date-picker__header-control--arrow-up': isWeekPicker,
          'calendar-date-picker__header-control--locked': isPrevDisplayedDateDisabled,
        },
      )}
      onClick={decrementDisplayedDate}
      type="button"
    >
      <ArrowLeft className="calendar-date-picker__header-control-image" />
    </button>
  );

  const fastDecrementButton = (
    <button
      className={classNames(
        'calendar-date-picker__header-control',
        'calendar-date-picker__header-control--arrow',
        {
          'calendar-date-picker__header-control--arrow-up': isWeekPicker,
          'calendar-date-picker__header-control--locked': isPrevFastDisplayedDateDisabled,
        },
      )}
      onClick={decrementFastDisplayedDate}
      type="button"
    >
      <ArrowDoubleLeft className="calendar-date-picker__header-control-image" />
    </button>
  );

  const incrementButton = (
    <button
      className={classNames(
        'calendar-date-picker__header-control',
        'calendar-date-picker__header-control--arrow',
        {
          'calendar-date-picker__header-control--arrow-down': isWeekPicker,
          'calendar-date-picker__header-control--locked': isNextDisplayedDateDisabled,
        },
      )}
      onClick={incrementDisplayedDate}
      type="button"
    >
      <ArrowRight className="calendar-date-picker__header-control-image" />
    </button>
  );

  const fastIncrementButton = (
    <button
      className={classNames(
        'calendar-date-picker__header-control',
        'calendar-date-picker__header-control--arrow',
        {
          'calendar-date-picker__header-control--arrow-down': isWeekPicker,
          'calendar-date-picker__header-control--locked': isNextFastDisplayedDateDisabled,
        },
      )}
      onClick={incrementFastDisplayedDate}
      type="button"
    >
      <ArrowDoubleRight className="calendar-date-picker__header-control-image" />
    </button>
  );

  return (
    <div
      className="calendar-date-picker"
    >
      {
        isWeekPicker && (
          <div className="calendar-date-picker__weeks-controls-wrapper">
            {fastDecrementButton}
            {decrementButton}
            <div className="calendar-date-picker__weeks-days">
              {
                weeks.map(({ number, date }) => (
                  <button
                    key={number}
                    className={classNames(
                      'calendar-date-picker__cell',
                      'calendar-date-picker__cell--week',
                    )}
                    onClick={() => onSelectDate(date)}
                    type="button"
                  >
                    {number}
                  </button>
                ))
              }
            </div>
            {incrementButton}
            {fastIncrementButton}
          </div>
        )
      }
      <div
        className={classNames(
          'calendar-date-picker__views-wrapper',
          { 'calendar-date-picker__views-wrapper--with-weeks': isWeekPicker },
        )}
      >
        <div className="calendar-date-picker__header-controls-wrapper">
          { multiplier && !isWeekPicker ? fastDecrementButton : <div /> }
          { !isWeekPicker ? decrementButton : <div /> }

          <button
            className={classNames(
              'calendar-date-picker__header-control',
              { 'calendar-date-picker__header-control--disabled': isWeekPicker },
              { 'calendar-date-picker__header-control--locked': maximalViewIndex === viewIndex }
            )}
            onClick={isWeekPicker ? null : pushView}
            type="button"
          >
            {headingGetter(displayedDate)}
          </button>

          { !isWeekPicker ? incrementButton : <div /> }
          { multiplier && !isWeekPicker ? fastIncrementButton : <div /> }
        </div>
        {
          !!component && (
            React.createElement(
              component,
              {
                holydaysSet,
                monthConfig,
                currentDate,
                onSelectDate,
                isWeekPicker,
                showRestDays,
                onSelectYear,
                selectedDate,
                displayedDate,
                onSelectMonth,
                businessDaysSet,
                minYear,
                maxYear,
                ...otherProps,
              }
            )
          )
        }
        { withCurrentDate &&
          <div className="calendar-date-picker__footer">
            <button
              className="calendar-date-picker__current-date-button"
              onClick={setCurrentDate}
              type="button"
            >
              Today {currentDateMMDDYYYY}
            </button>
          </div>
        }
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  currentDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]),
  displayedDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]),
  minDetails: PropTypes.string,
  onYearClick: PropTypes.func,
  onMonthClick: PropTypes.func,
  maxDetails: PropTypes.string,
  selectedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]),
  selectDate: PropTypes.func.isRequired,
  isWeekPicker: PropTypes.bool.isRequired,
  showRestDays: PropTypes.bool.isRequired,
  holydaysSet: PropTypes.instanceOf(Set).isRequired,
  businessDaysSet: PropTypes.instanceOf(Set).isRequired,
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
  setUseOnlyMonth: PropTypes.func,
  setUseOnlyYear: PropTypes.func,
  withCurrentDate: PropTypes.bool,
};

DatePicker.defaultProps = {
  currentDate: moment(),
  displayedDate: undefined,
  minDetails: '',
  maxDetails: '',
  selectedDate: '',
  onYearClick: null,
  onMonthClick: null,
  minYear: undefined,
  maxYear: undefined,
  setUseOnlyMonth: null,
  setUseOnlyYear: null,
  withCurrentDate: true,
};

export default DatePicker;
