import { get } from 'lodash';
import { HTTP_STATUS } from 'utils/request/statuses';

export const ERROR_MESSAGES = {
  NOT_AUTHORIZED: 'Not enough permissions',
};

function isSuccess(response) {
  return ((response.status >= 200 && response.status < 300));
}

function parseJSON(response) {
  return response.json();
}

function parseBLOB(response) {
  return response.blob();
}

export function handleResponse({ isBlob, response }) {
  function onResolve(data) {
    if (isSuccess(response)) {
      return data;
    }

    const { error, errors } = data;

    if (error && error.message) {
      throw new Error(error.message);
    }

    if (errors && errors[0]) {
      throw new Error(errors[0].message || 'Something went wrong!');
    }

    throw new Error('Something went wrong!');
  }

  const parser = isBlob ? parseBLOB : parseJSON;

  return parser(response)
    .then(onResolve);
}

export function checkPermissionByError(error) {
  if (
    error.extensions &&
    error.extensions.errorCode === HTTP_STATUS.BAD_REQUEST &&
    error.extensions.detailed === ERROR_MESSAGES.NOT_AUTHORIZED) {
    window.location = '/not_found';
  }

  return undefined;
}

export const getErrorMessage = (error) => get(error, 'extensions.detailed', error.message) || error.message || 'Something went wrong. Please try again later!';
