import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as IconNote } from 'assets/img/icon-error-note.svg';
import ClientsError from 'components/clients-error';
import Header from 'components/header';
import ServerErrorMessage from 'components/serverErrorMessage';
import AuthWrapper from 'containers/authWrapper';
import RedirectWrapper from 'containers/redirectWrapper';
import { config, commonLinks, authLinks } from 'core/config';
import ActionButton from 'elements/action-button';
import Wrapper from 'elements/wrapper';
import useTokenTimer from 'hooks/useTokenTimer';
import ChannelPartners from 'pages/channel-partners';
import Clients from 'pages/clients';
import Configurations from 'pages/configurations';
import Dashboard from 'pages/dashboard';
import Delivery from 'pages/delivery';
import Login from 'pages/login';
import Reconciliation from 'pages/reconciliation';
import Reports from 'pages/reports';
import Reviews from 'pages/reviews';
import TaskOrders from 'pages/task-orders';
import UserSettings from 'pages/user';
import Verification from 'pages/verification';

import { Switch, Route, Redirect, withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';

import styles from './style.module.scss';
const { LINKS } = config;

// TODO: Implement React.lazy to load route pages asynchronously
// TODO: Move component usage to same place where route is defined
const pagesComponents = {
  configurations: Configurations,
  'devstaff-reviews': Reviews,
  reconciliation: Reconciliation,
  'task-orders': TaskOrders,
  delivery: Delivery,
  clients: Clients,
  'channel-partners': ChannelPartners,
  reports: Reports,
  home: Dashboard,
  'user-settings': UserSettings,
  'not-found': ClientsError,
};

// TODO: Implement React.lazy to load route pages asynchronously
const authComponents = {
  login: Login,
  verification: Verification,
};

const Router = (props) => {
  const location = useLocation();
  /*  eslint-disable */
  const actions = {
    generatePtoReport: props.generatePtoReport,
    generateUtilizationReport: props.generateUtilizationReport,
    generateBookingReport: props.generateBookingReport,
  };

  const [showWarningOnCurrentRoute, setShowWarningOnCurrentRoute] = useState(true);
  const { timeLeft, showWarning } = useTokenTimer(props.userGroup, props.userLogout, location);

  useEffect(() => {
    setShowWarningOnCurrentRoute(true);
  }, [location.pathname]);

  const dismissNotificationForCurrentRoure = () => {
    setShowWarningOnCurrentRoute(false);
  }

  const {userGroup} = props;
  /*  eslint-enable */
  return (
    <Switch>
      {authLinks.map(({ name, route }) => (
        <Route
          exact
          key={name}
          path={`/${route}`}
          render={() => (
            <AuthWrapper>
              {
                React.createElement(
                  authComponents[name],
                )
              }
            </AuthWrapper>
          )}
        />
      ))}
      {
        [...LINKS, ...commonLinks].map(({ name, route, guaranteedAccessRoles, guaranteedAccessRolesForSubRoutes }) => {
          const getRoute = (currentRoute) => (
            <Route
              key={name}
              path={`/${currentRoute}`}
              render={
                () => (
                  <RedirectWrapper
                    userGroup={userGroup}
                    guaranteedAccessRoles={guaranteedAccessRoles}
                  >
                    <Wrapper>
                      <Header
                        {...props}
                        actions={actions}
                      />

                    </Wrapper>
                    <>
                      {(showWarning && showWarningOnCurrentRoute) && (
                        <ServerErrorMessage
                          message={(
                            <ActionButton
                              onClick={dismissNotificationForCurrentRoure}
                              data={(
                                <>
                                  <IconNote />
                                  <span>{`Session will expire in ${timeLeft} ${timeLeft > 1 ? 'minutes' : 'minute'}, please save your changes as you'll be logged out.`}</span>
                                </>
                              )}
                            />
                          )}
                          className={styles.errorMessage}
                        />
                      )}
                      {
                        React.createElement(
                          pagesComponents[currentRoute],
                          {
                            guaranteedAccessRoles,
                            guaranteedAccessRolesForSubRoutes,
                            ...props,
                          }
                        )
                      }
                    </>
                  </RedirectWrapper>
                )
              }
            />
          );
          return (
            Array.isArray(route) ?
              route.map(getRoute) : getRoute(route)
          );
        })
      }

      <Redirect
        to="/not-found"
      />

    </Switch>
  );
};

Router.propTypes = {
  generatePtoReport: PropTypes.func.isRequired,
  generateUtilizationReport: PropTypes.func.isRequired,
  generateBookingReport: PropTypes.func.isRequired,
};

export default withRouter(Router);
