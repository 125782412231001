import React, { memo, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { PlusIcon } from 'assets/svgComponents';
import { ActionButton } from 'components/buttons';
import { ListOfForms } from 'components/list-of-forms';
import Loader from 'components/loader';
import { contractManagementActions } from 'core/delivery/contractManagement/actions';
import {
  selectDevcentersListForSelect,
  selectFormState,
  selectRecords,
} from 'core/delivery/contractManagement/selectors';
import { modalConductorActions } from 'core/modal-conductor/actions';
import EmptyMessage from 'elements/empty-message';
import { ContractForm } from 'forms/contract-form';
import commonStyles from 'forms/contract-form/styles.module.scss';
import { removeEmptyProperties } from 'forms/helpers';
import { useListColumns } from 'layouts/employee-details/components/resource-tabs/contracts/useListColumns';
import { newContract } from 'layouts/employee-details/components/resource-tabs/contracts/utils';

import { get, isEmpty } from 'lodash';

import styles from './styles.module.scss';

export const Contracts = memo(({
  id,
  title,
  devstaffId,
  layoutStyles,
  placeholderStyles,
  placeholderText,
  recordIdName,
  isCPS,
}) => {
  const {
    contractManagementRecords,
    isFetchingContractManagementRecords,
    hasPermissionToAddRecord,
    memoDevstaffId,
    hasPermissionToViewAttachment,
  } = useSelector(selectRecords);
  const formState = useSelector(selectFormState);
  const isFormSubmitted = get(formState, 'isSubmittedSuccess', false) || get(formState, 'isSubmittedError', false);
  const devCenters = useSelector(selectDevcentersListForSelect);
  const dispatch = useDispatch();

  const handleSubmit = ({
    formData,
    isCreateMode,
    contractRecordId,
    allowedForEdit,
    dirtyFields,
  }) => {
    if (isCreateMode) {
      dispatch(contractManagementActions.createContractManagementRecord({
        ...removeEmptyProperties(formData),
        contractManagementRecordId: contractRecordId,
      }));
    } else {
      const changedFields = Object.keys(dirtyFields).reduce((accumulator, currentValue) => ({
        ...accumulator,
        [currentValue]: formData[currentValue],
      }), {});

      dispatch(contractManagementActions.updateContractManagementRecord({
        ...changedFields,
        contractManagementRecordId: contractRecordId,
        allowedForEdit,
      }));
    }
  };

  const handleDelete = useCallback((contractRecordId) => {
    dispatch(modalConductorActions.changeCurrentModal({
      currentModal: 'CONFIRM_ACTION_MODAL',
      params: {
        content: [
          {
            type: 'title',
            data: 'Delete this contract?',
          },
          {
            type: 'description',
            data: 'Once deleted, it cannot be restored.',
          },
        ],
        acceptActionName: 'deleteContractManagementRecord',
        acceptActionTitle: 'Delete',
        acceptFunction: () => dispatch(contractManagementActions.deleteContractManagementRecord({
          contractRecordId,
        })),
      },
    }));
  }, []);

  const handleRemoveRecord = useCallback(() => {
    dispatch(contractManagementActions.removeContractRecord());
  }, []);

  const handleAddRecord = useCallback((record) => {
    dispatch(contractManagementActions.addContractRecord(record));
  }, []);

  const columns = useListColumns({
    devCenters,
    hasPermissionToViewAttachment,
  });

  const renderForm = useCallback(({
    item,
    onRemoveRecord,
    onDeleteHandler,
    setEditedRecordId,
    editedRecordId,
  }) => (
    <ContractForm
      key={get(item, recordIdName, newContract[recordIdName])}
      item={item}
      onRemoveRecord={onRemoveRecord}
      setEditedRecordId={setEditedRecordId}
      editedRecordId={editedRecordId}
      columns={columns}
      onSubmitHandler={handleSubmit}
      onDeleteHandler={onDeleteHandler}
      recordIdName={recordIdName}
      isCPS={isCPS}
    />
  ), [contractManagementRecords]);

  useEffect(() => {
    if (!devstaffId || devstaffId === memoDevstaffId) return;

    dispatch(contractManagementActions.getContractManagementRecords());
  }, [devstaffId]);

  useEffect(() => () => {
    dispatch(contractManagementActions.removeContractRecord());
  }, []);

  if (isFetchingContractManagementRecords) {
    return (
      <div
        data-id="Loader"
        className={layoutStyles}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div id={id} title={title} data-id="Contracts" className={layoutStyles}>
      <ListOfForms
        onDeleteHandler={handleDelete}
        title={title}
        columns={columns}
        data={contractManagementRecords}
        onRemoveRecord={handleRemoveRecord}
        newRecord={newContract}
        recordIdName={recordIdName}
        onAddRecord={handleAddRecord}
        isFormSubmitted={isFormSubmitted}
        commonStyles={commonStyles}
        renderAddButton={({
          onAddRecordClick,
          isAddButtonDisabled,
        }) => (
          <ActionButton
            title="Add Contract Button"
            dataId="addButton"
            type="button"
            onClick={onAddRecordClick}
            disabled={isAddButtonDisabled || !hasPermissionToAddRecord}
            className={styles.addContractButton}
          >
            <PlusIcon />
            Add Contract
          </ActionButton>
        )}
        renderForm={renderForm}
      />
      {isEmpty(contractManagementRecords) && <EmptyMessage className={placeholderStyles} description={placeholderText} />}
    </div>
  );
});

Contracts.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  devstaffId: PropTypes.number.isRequired,
  layoutStyles: PropTypes.string.isRequired,
  placeholderStyles: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  recordIdName: PropTypes.string.isRequired,
  isCPS: PropTypes.bool.isRequired,
};
