import { resourceCommentManagementAccess } from 'core/auth/guaranteedAccessRoles';
import moment from 'moment';

const { rolesToEdit, rolesToEditOnlyThreeDays, rolesToView } = resourceCommentManagementAccess;

export const getCommentManagementRecords = ({
  records,
  hasPermissionToEdit,
  hasPermissionToEditOnlyThreeDays,
}) => records.map((record) => ({
  ...record,
  date: moment(record.date).format('MM/DD/YYYY'),
  allowedToEdit: hasPermissionToEdit || (hasPermissionToEditOnlyThreeDays && record.allowedToEdit),
}));

export const getCommentManagementPermissions = ({
  userGroup,
}) => {
  const isRoleToEdit = rolesToEdit.includes(userGroup);
  const isRoleToEditOnlyThreeDays = rolesToEditOnlyThreeDays.includes(userGroup);

  return {
    hasPermissionToEdit: isRoleToEdit,
    hasPermissionToView: rolesToView.includes(userGroup),
    hasPermissionToEditOnlyThreeDays: isRoleToEditOnlyThreeDays,
    hasPermissionToAddRecord: isRoleToEdit || isRoleToEditOnlyThreeDays,
  };
};
