import React from 'react';

import PropTypes from 'prop-types';

import WarningIcon from 'assets/img/icon-warning.svg';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import './styles.scss';

const FormErrorMessage = ({ errorMessage, isVisible, withIcon }) => (
  <div
    className={
      classNames(
        'new-error-message',
        { 'new-error-message--visible': isVisible },
      )
    }
  >
    {
      withIcon && (
        <SVG
          className="new-error-message__icon"
          src={WarningIcon}
        />
      )
    }
    <span className="new-error-message__title">
      {errorMessage}
    </span>
  </div>
);

FormErrorMessage.propTypes = {
  errorMessage: PropTypes.node,
  isVisible: PropTypes.bool,
  withIcon: PropTypes.bool,
};

FormErrorMessage.defaultProps = {
  errorMessage: '',
  isVisible: true,
  withIcon: false,
};

export default FormErrorMessage;
