import { PROJECTS_TYPES } from 'core/billing-reports/constants';
import { ODCColumns, PTMColumns, TTMColumns } from 'layouts/billing-project-reports/model/config/columns';

export const getGridTemplate = (projectType, options = {}) => {
  const { isHRM, isDTOReport } = options;

  switch (projectType) {
    case PROJECTS_TYPES.PTM:
      return isDTOReport ?
        `grid-template-columns:  repeat(${
          isHRM ? '4' : '7'
        }, minmax(auto, 1fr));` :
        `grid-template-columns:  repeat(${
          isHRM ? '4' : '5'
        }, minmax(auto, 1fr));`;
    case PROJECTS_TYPES.ODC:
      return isDTOReport ?
        `grid-template-columns:  repeat(${
          isHRM ? '4' : '6'
        }, minmax(auto, 1fr));` :
        `grid-template-columns:  repeat(${
          isHRM ? '4' : '4'
        }, minmax(auto, 1fr));`;
    case PROJECTS_TYPES.TTM:
      return isDTOReport ?
        `grid-template-columns:  ${
          isHRM ?
            'repeat(7, minmax(auto, 1fr))' :
            'repeat(3, 1fr) 1.2fr repeat(4, 0.7fr) 1fr'
        };` :
        `grid-template-columns:  ${
          isHRM ?
            'repeat(5, minmax(auto, 1fr))' :
            '1fr 1fr 1.2fr 1fr 1fr 0.7fr 1fr 1fr'
        };`;
    case 'PTM - NOT IN TO!':
      return `grid-template-columns: ${isDTOReport ? 'minmax(auto, 3fr) minmax(auto, 4fr)' :
        'minmax(auto, 2fr) minmax(auto, 3fr)'};`;
    default:
      return 'grid-template-columns: repeat(2, 1fr) 2fr;';
  }
};

export const getOvertimeGridTemplate = (projectType, options = {}) => {
  const { isHRM, isDTOReport } = options;

  switch (projectType) {
    case PROJECTS_TYPES.PTM:
      return isDTOReport ?
        `grid-template-columns:  repeat(${
          isHRM ? '4' : '7'
        }, minmax(auto, 1fr));` :
        `grid-template-columns:  repeat(${
          isHRM ? '4' : '5'
        }, minmax(auto, 1fr));`;
    case PROJECTS_TYPES.TTM:
      return isHRM ?
        `grid-template-columns: repeat(${
          isDTOReport ? '4' : '4'
        }, minmax(auto, 1fr));` :
        `grid-template-columns: repeat(${isDTOReport ? 7 : 5}, minmax(auto,1fr));`;
    case PROJECTS_TYPES.ODC:
      return isHRM ?
        `grid-template-columns: repeat(${
          isDTOReport ? '4' : '4'
        }, minmax(auto, 1fr));` :
        `grid-template-columns: repeat(${isDTOReport ? 7 : 5}, minmax(auto,1fr));`;
    case 'Overtime - NOT IN TO!':
      return `grid-template-columns: ${isDTOReport ? 'minmax(auto, 3fr) minmax(auto, 4fr)' :
        'minmax(auto, 2fr) minmax(auto, 3fr)'};`;
    default:
      return 'grid-template-columns: repeat(2, 1fr) 2fr;';
  }
};

export const getGrandTotalColumns = (projectType, data, options) => {
  switch (projectType) {
    case PROJECTS_TYPES.PTM:
    case PROJECTS_TYPES.FP:
      return PTMColumns(data, options);
    case PROJECTS_TYPES.ODC:
      return ODCColumns(data, options);
    case PROJECTS_TYPES.TTM:
      return TTMColumns(data, options);
    default:
      return [];
  }
};

export const getExcludedColumns = (projectType, options) => {
  const { isHRM, isDTOReport } = options;
  const columns = [];

  switch (projectType) {
    case PROJECTS_TYPES.PTM: {
      if (isHRM && isDTOReport) {
        columns.push('Discount/\nSurcharge', 'Rate', 'Amount', 'Hrly Rate');
      } else if (!isDTOReport) {
        columns.push('Discount/\nSurcharge', 'Devcenter', 'Hrly Rate');
      } else {
        columns.push('Hrly Rate');
      }
      break;
    }

    case PROJECTS_TYPES.ODC: {
      if (isHRM && isDTOReport) {
        columns.push('Discount/\nSurcharge', 'Monthly Rate', 'Hrly Rate', 'Amount');
      } else if (!isDTOReport) {
        columns.push('Discount/\nSurcharge', 'Devcenter');
      }
      break;
    }

    case PROJECTS_TYPES.TTM: {
      if (isHRM && isDTOReport) {
        columns.push('Discount/\nSurcharge', 'Hrl Rate', 'Total', 'Amount', 'Hrly Rate');
      } else if (isDTOReport) {
        columns.push('Hrl Rate');
      } else {
        columns.push('Discount/\nSurcharge', 'Devcenter', 'Hrl Rate');
      }
      break;
    }

    default:
      return columns;
  }

  return columns;
};

export const gridTableConfig = (isBillable) => `${isBillable ?
  'grid-template-columns: repeat(1, minmax(auto, 0.8fr)) minmax(auto, 0.4fr) repeat(2, minmax(auto, 0.7fr)) repeat(2, minmax(auto, .3fr)) minmax(auto, 0.9fr)' :
  'grid-template-columns: repeat(1, minmax(auto, 0.8fr)) minmax(auto, 0.4fr) repeat(2, minmax(auto, 0.7fr)) repeat(1, minmax(auto, .3fr)) minmax(auto, 0.9fr)'} ;`;
