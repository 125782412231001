import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ErrorMessage from 'elements/error-message';

import './styles.scss';


const TextInput = ({
  name,
  withError,
  inputValue,
  onChange,
  label,
  withLabel,
  hasManuallyUpdates,
  error,
}) => {
  const isLabelWisible = !!inputValue || inputValue === 0 || inputValue === '0';

  return (
    <div
      className={
        classNames(
          'textarea',
          { 'ttextarea--with-error': withError },
        )
      }
    >
      <textarea
        id={name}
        name={name}
        className="textarea__input"
        value={inputValue}
        onChange={onChange}
      />

      {
        withLabel && (
          <label
            className={
              classNames(
                'textarea__label',
                { 'textarea__label--visible': isLabelWisible },
                { 'textarea__label--with-error': withError || hasManuallyUpdates }
              )
            }
            htmlFor={name}
          >
            {label}
          </label>
        )
      }

      {
        withError &&
        <ErrorMessage>
          {error}
        </ErrorMessage>
      }

    </div>
  );
};


TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  withError: PropTypes.bool,
  inputValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  withLabel: PropTypes.bool,
  hasManuallyUpdates: PropTypes.bool,
  error: PropTypes.string,
};

TextInput.defaultProps = {
  withError: false,
  inputValue: '',
  onChange: () => null,
  label: '',
  withLabel: true,
  hasManuallyUpdates: false,
  error: '',
};


export default TextInput;
