import { colorBackground, colorSecondaryText } from 'assets/styles/variables';

import { HEADER_HEIGHT } from 'layouts/category-management/components/category-tabs/constants';
import styled, { css } from 'styled-components';

export const StyledCategoryTabsWrapper = styled.div`
  display: grid;
  gap: 0;
  width: 100%;
  background: ${colorBackground};
`;

export const StyledCategoryTabs = styled.div`
  position: sticky;
  top: ${({ controlPanelHeight }) => controlPanelHeight}px;
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - ${({ controlPanelHeight }) => controlPanelHeight}px - ${HEADER_HEIGHT});
  font-size: 2.2rem;

  ${({ cssRules }) => css`${cssRules}`}`;

export const StyledCategoryTabsHeader = styled.h2`
  padding: 1.2rem 2.4rem 1.6rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.4rem;
  max-height: 4.8rem;
  color: ${colorSecondaryText};
  ${({ cssRules }) => css`${cssRules}`}
`;

export const StyledTabItemContainer = styled.div`
  padding: 0.8rem 0 0 1.6rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 1.6rem 1.6rem;
  height: 100%
  ${({ cssRules }) => css`${cssRules}`}
`;
