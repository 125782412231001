import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as IconError } from 'assets/icons/error.svg';
import classNames from 'classnames';

import ActionButton from 'elements/action-button';

import styles from './style.module.scss';

const ServerErrorMessage = ({ message, className, onClick }) => (
  <div className={classNames(styles.errorContainer, className)}>
    <div className={styles.errorCard}>
      {onClick ? <ActionButton onClick={onClick} data={<IconError />} /> : null}
      <p className={styles.errorMessage}>
        {message}
      </p>
    </div>
  </div>
);

ServerErrorMessage.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ServerErrorMessage.defaultProps = {
  message: '',
  className: '',
  onClick: null,
};

export default ServerErrorMessage;
