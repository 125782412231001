export const CATEGORY_TYPES = {
  HIQO_OOO: 'availableOooCategories',
  INTERNAL: 'availableInternalCategories',
  CLIENT: 'availableClientCategories',
};

export const CATEGORY_TITLES = {
  HIQO_OOO: 'HiQo – OOO (Out of Office)',
  HIQO_PROJECTS: 'HiQo Projects',
  CLIENT: 'Client Categories',
  BILLING: 'Billing Categories',
};

export const CATEGORY_CREATION_TYPES = {
  INTERNAL: 'Internal',
  CLIENT: 'Client',
};
