import React, { memo, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { PlusIcon } from 'assets/svgComponents';
import { ActionButton } from 'components/buttons';
import { ListOfForms } from 'components/list-of-forms';
import Loader from 'components/loader';
import { selectUser } from 'core/auth/selectors';
import { attachmentsActions } from 'core/delivery/attachmentManagement/actions';
import { selectAttachmentsRecords, selectFormState } from 'core/delivery/attachmentManagement/selectors';
import { modalConductorActions } from 'core/modal-conductor/actions';
import EmptyMessage from 'elements/empty-message';

import { AttachmentForm } from 'forms/attachment-form';
import commonStyles from 'forms/attachment-form/styles.module.scss';

import { get, isEmpty } from 'lodash';

import { newAttachmentRecord } from './constants';
import styles from './styles.module.scss';
import { useListColumns } from './useListColumns';

export const Attachments = memo(({
  id,
  title,
  devstaffId,
  placeholderText,
  placeholderStyles,
  recordIdName,
  layoutStyles,
}) => {
  const user = useSelector(selectUser);
  const {
    records,
    hasPermissionToAddRecord,
    hasPermissionToViewOnly,
    isFetching,
    memoDevstaffId,
  } = useSelector(selectAttachmentsRecords);
  const formState = useSelector(selectFormState);
  const isSubmittedSuccess = get(formState, 'isSubmittedSuccess', false);
  const isSubmittedFail = get(formState, 'isSubmittedFail', false);
  const isFormSubmitted = isSubmittedSuccess || isSubmittedFail;

  const dispatch = useDispatch();

  const handleSubmit = useCallback(({
    formData,
    isCreateMode,
    attachmentRecordId,
  }) => {
    if (isCreateMode) {
      dispatch(attachmentsActions.createAttachmentRecord({
        attachmentRecord: {
          ...formData,
          attachmentRecordId,
        },
        devstaffId,
      }));
    } else {
      dispatch(attachmentsActions.updateAttachmentRecord({
        attachmentRecord: {
          ...formData,
          attachmentRecordId,
        },
      }));
    }
  }, []);

  const handleDelete = useCallback((attachmentRecordId) => {
    dispatch(modalConductorActions.changeCurrentModal({
      currentModal: 'CONFIRM_ACTION_MODAL',
      params: {
        content: [
          {
            type: 'title',
            data: 'Delete this attachment record?',
          },
          {
            type: 'description',
            data: 'Once deleted, it cannot be restored.',
          },
        ],
        acceptActionName: 'deleteAttachmentRecord',
        acceptActionTitle: 'Delete',
        acceptFunction: () => dispatch(attachmentsActions.deleteAttachmentRecord({
          attachmentRecordId,
          isCreateAttachmentRecord: false,
        })),
      },
    }));
  }, []);

  const handleRemoveRecord = useCallback(() => {
    dispatch(attachmentsActions.removeAttachmentRecord());
  }, []);

  const handleAddRecord = useCallback((record) => {
    dispatch(attachmentsActions.addAttachmentRecord(record));
  }, []);

  const columns = useListColumns({
    hasPermissionToViewOnly,
  });

  useEffect(() => {
    if (!devstaffId || devstaffId === memoDevstaffId) return;

    dispatch(attachmentsActions.getDevstaffAttachmentsRecords({ devstaffId }));
  }, [devstaffId]);

  useEffect(() => () => {
    dispatch(attachmentsActions.removeAttachmentRecord());
  }, []);

  if (isFetching) {
    return (
      <div
        data-id="Loader"
        className={styles.loader}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div id={id} title={title} data-id="Attachments" className={layoutStyles}>
      <ListOfForms
        title={title}
        commonStyles={commonStyles}
        columns={columns}
        data={records}
        onRemoveRecord={handleRemoveRecord}
        newRecord={newAttachmentRecord(user)}
        recordIdName={recordIdName}
        onAddRecord={handleAddRecord}
        isFormSubmitted={isFormSubmitted}
        onDeleteHandler={handleDelete}
        renderAddButton={({
          onAddRecordClick,
          isAddButtonDisabled,
        }) => (
          <ActionButton
            title="Add Attachment Button"
            dataId="addButton"
            type="button"
            onClick={onAddRecordClick}
            disabled={isAddButtonDisabled || !hasPermissionToAddRecord}
            className={styles.addAttachmentButton}
          >
            <PlusIcon />
            Add Attachment
          </ActionButton>
        )}
        renderForm={({
          item,
          onRemoveRecord,
          setEditedRecordId,
          editedRecordId,
          onDeleteHandler,
        }) => (
          <AttachmentForm
            key={`attachmentFormKey${item[recordIdName]}`}
            columns={columns}
            item={item}
            onSubmitHandler={handleSubmit}
            onRemoveRecord={onRemoveRecord}
            setEditedRecordId={setEditedRecordId}
            editedRecordId={editedRecordId}
            onDeleteHandler={onDeleteHandler}
          />
        )}
      />
      {isEmpty(records) && (
        <EmptyMessage
          className={placeholderStyles}
          description={placeholderText}
        />
      )}
    </div>
  );
});

Attachments.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  devstaffId: PropTypes.number.isRequired,
  placeholderStyles: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  layoutStyles: PropTypes.string.isRequired,
  recordIdName: PropTypes.string.isRequired,
};
