/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import Calendar from 'components/calendar';
import Select from 'components/selectNew';
import { EMPOYMENT_TYPES } from 'core/delivery/contractManagement/constants';
import { selectFormState } from 'core/delivery/contractManagement/selectors';
import {
  ControlsContainer,
} from 'layouts/employee-details/components/resource-tabs/components';
import {
  Attachment,
} from 'layouts/employee-details/components/resource-tabs/contracts/components/attachment';
import styles from 'layouts/employee-details/components/resource-tabs/contracts/styles.module.scss';
import { get } from 'lodash';
import moment from 'moment';

export const useListColumns = ({ devCenters, hasPermissionToViewAttachment }) => useMemo(() => [
  {
    Header: 'Employment Type',
    accessor: 'employmentType',
    type: 'form',
    Field: ({
      field,
      fieldState,
    }) => {
      const fieldData = {
        selected: {
          value: field.value,
          label: field.value,
        },
        items: [
          {
            value: EMPOYMENT_TYPES.EMPLOYEE,
            label: EMPOYMENT_TYPES.EMPLOYEE,
          },
          {
            value: EMPOYMENT_TYPES.CONTRACTOR,
            label: EMPOYMENT_TYPES.CONTRACTOR,
          },
          {
            value: EMPOYMENT_TYPES.UNKNOWN,
            label: EMPOYMENT_TYPES.UNKNOWN,
          },
        ],
      };

      return (
        <Select
          name={field.name}
          fieldData={fieldData}
          onChange={(selectedData) => {
            field.onChange(selectedData);
          }}
          error={get(fieldState, 'error.message', '')}
          withError={fieldState.invalid}
        />
      );
    },
  },
  {
    Header: 'Dev Center',
    accessor: 'deliveryCenterId',
    type: 'form',
    Field: ({
      field,
      fieldState,
    }) => {
      const devCenter = devCenters.find((item) => item.value === field.value);

      const selected = {
        value: field.value,
        label: devCenter ? devCenter.label : '',
      };

      const fieldData = {
        selected,
        items: devCenters,
      };

      return (
        <Select
          name={field.name}
          fieldData={fieldData}
          onChange={(selectedData) => {
            field.onChange(selectedData);
          }}
          error={get(fieldState, 'error.message', '')}
          withError={fieldState.invalid}
        />
      );
    },
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    type: 'form',
    isOptional: true,
    Field: ({
      field,
      fieldState,
    }) => (
      <div data-id="Calendar Container" className={styles.calendar}>
        <Calendar
          name={field.name}
          value={field.value && moment.parseZone(field.value, 'YYYY-MM-DD')}
          outputFormatting={({ date }) => (
            moment.isMoment(date) ?
              date.format('YYYY-MM-DD') :
              date
          )}
          onChange={(val) => {
            field.onChange(val);
          }}
          error={fieldState.error}
          withError={fieldState.invalid}
          withClear={!!field.value}
        />
        <div data-id="Calendar Field Error" className={styles.fieldError}>
          {get(fieldState, 'error.message', '')}
        </div>
      </div>
    ),
  },
  {
    Header: 'Expiration Date',
    accessor: 'expirationDate',
    type: 'form',
    isOptional: true,
    Field: ({
      field,
      fieldState,
    }) => (
      <div data-id="Calendar Container" className={styles.calendar}>
        <Calendar
          name={field.name}
          value={field.value && moment.parseZone(field.value, 'YYYY-MM-DD')}
          outputFormatting={({ date }) => (
            moment.isMoment(date) ?
              date.format('YYYY-MM-DD') :
              date
          )}
          onChange={(val) => {
            field.onChange(val);
          }}
          error={fieldState.error}
          withError={fieldState.invalid}
          withClear={!!field.value}
        />
        <div data-id="Calendar Field Error" className={styles.fieldError}>
          {get(fieldState, 'error.message', '')}
        </div>
      </div>
    ),
  },
  {
    Header: 'Termination Date',
    accessor: 'terminationDate',
    type: 'form',
    isOptional: true,
    Field: ({
      field,
      fieldState,
    }) => (
      <div data-id="Calendar Container" className={styles.calendar}>
        <Calendar
          name={field.name}
          value={field.value && moment.parseZone(field.value, 'YYYY-MM-DD')}
          outputFormatting={({ date }) => (
            moment.isMoment(date) ?
              date.format('YYYY-MM-DD') :
              date
          )}
          onChange={(val) => {
            field.onChange(val);
          }}
          error={fieldState.error}
          withError={fieldState.invalid}
          withClear={!!field.value}
        />
        <div data-id="Calendar Field Error" className={styles.fieldError}>
          {get(fieldState, 'error.message', '')}
        </div>
      </div>
    ),
  },
  {
    Header: 'Attachment',
    accessor: 'file',
    type: 'form',
    isHidden: !hasPermissionToViewAttachment,
    isOptional: true,
    Field: ({ item, field, setError, fieldState }) => (
      <Attachment
        file={field.value}
        onFileChange={(data) => {
          field.onChange(data);
        }}
        isAllowedForEdit={item.allowedForEdit}
        isAllowedForDelete={item.allowedForDelete}
        form={item}
        setError={setError}
        errorClassName={styles.fieldError}
        error={get(fieldState, 'error')}
      />
    ),
  },
  {
    Header: 'Action',
    accessor: 'actions',
    type: 'action',
    Field: (props) => (
      <ControlsContainer
        {...props}
        formStateSelector={selectFormState}
      />
    ),
  },
], []);
