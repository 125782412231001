import { actionButtonCss } from 'assets/styles/config';
import {
  colorPrimary,
  colorSecondaryGrayLight,
  colorSecondaryText,
  colorTextSecondaryButton,
} from 'assets/styles/variables';

export const errorBoxCssRules = `
  .Toastify__toast {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 1.6rem;
  }
`;

export const listHeadingStyles = `
  position: sticky;
  top: 0;
  grid-column-gap: 0.8rem;
  grid-row-gap: 0;
  margin-top: 1.2rem;
  padding: 0;
  background: #f2f2f2;
  z-index: 2;
`;

export const approveButtonCSS = `
  ${actionButtonCss}
  && {
    align-self: flex-start;
    width: 100%;
    margin: 0 auto;
    padding: 0.7rem;
    font-size: 1.1rem;
    letter-spacing: 0.125rem;
  }
`;

export const rejectButtonCSS = `
  ${actionButtonCss}
  && {
    align-self: flex-start;
    width: 100%;
    margin: 0;
    padding: 0.7rem 0;
    border-color: #d61f26;
    font-size: 1.1rem;
    letter-spacing: 0.125rem;
    color: #d61f26;
    background-color: transparent;

    &:hover, &:active {
      color: ${colorTextSecondaryButton};
      border-color: ${colorTextSecondaryButton};
      background: transparent;
    }
  }
`;

export const lockedButton = `
  align-self: flex-start;
  width: 100%;
  padding: 0.7rem 0;
  font-size: 1.1rem;
  letter-spacing: 0.125rem;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
`;

export const rejectButtonLockedCSS = `
  ${actionButtonCss} && {
  ${lockedButton}
  color: #bbbdc0;
  background: transparent;
  border-color: #bbbdc0;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;}
`;

export const approveButtonLockedCSS = `
  ${actionButtonCss} && {
  ${lockedButton}
  color: #fff;
  background: #bbbdc0;
  border-color: #bbbdc0;
  margin: 0 auto;}
`;

export const descriptionAlertCSS = `
  box-sizing: border-box;
  display: inherit;
  padding: 0 1rem;
  font-size: 1.3rem;
  line-height: 1.4rem;
  text-align: center;
  color: ${colorPrimary};
  overflow: hidden;
`;

export const descriptionInactiveCSS = `
  ${descriptionAlertCSS}
  color: ${colorSecondaryGrayLight};
`;

export const descriptionApprovedCSS = `
  ${descriptionAlertCSS}
  color: ${colorSecondaryText};
`;

export const workBookFormTextInputCss = 'font-size: 1.2rem; line-height: 2.4rem;';

export const workBookSelectStyles = `
  font-size: 1.2rem;
  .select-item__single-value {
    overflow: visible;
  }
`;

export const workBookFullNameCss = `
  display: block;
  box-sizing: border-box;
  padding-top: 2rem;
  ${workBookFormTextInputCss}
  color: ${colorSecondaryText};
`;

export const workBookDescriptionCss = `box-sizing: border-box;
  display: inline-block;
  padding-top: 2rem;
  ${workBookFormTextInputCss}
  color: ${colorSecondaryText};
`;

export const COMMON_STYLES = 'line-height: 1.3rem; white-space: normal;';

export const customCssRules = {
  sendToApproveButton: `
        ${approveButtonCSS}
        ${COMMON_STYLES}
        `,
  approveButtonLocked: `
          ${approveButtonLockedCSS}
          ${COMMON_STYLES}
        `,
  multipleButtonsCss: `
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      gap: 1.6rem;
    `,
};
