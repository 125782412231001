/**
 * Create the store with asynchronously loaded reducers
 */
/* eslint-disable import/no-import-module-exports */

import { routerMiddleware } from 'connected-react-router';
import createModalConductorMiddleware from 'core/middlewares/modal-conductor';
import { createBrowserHistory as createHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import createCombineReducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const modalConductorMiddleware = createModalConductorMiddleware();

function configureStore(initialState = {}, history) {
  const middlewares = [
    sagaMiddleware,
    modalConductorMiddleware,
    routerMiddleware(history),
  ];

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable  */
  const composeEnhancers =
  (process.env.REACT_APP_ENVIRONMENT === 'local' ||
  process.env.REACT_APP_ENVIRONMENT === 'development') &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        latency: 0,
      })
      : compose;
  /* eslint-enable */

  const store = createStore(
    (state, action) => {
      if (action.type === 'AUTH/USER_LOGOUT') {
        return createCombineReducer(history)(initialState, action);
      }
      return createCombineReducer(history)(state, action);
    },
    initialState,
    composeEnhancers(...enhancers)
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  sagas.map(store.runSaga);

  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createCombineReducer(history, store.injectedReducers));
    });
  }

  return store;
}

const initialState = {};
export const history = createHistory();
export const store = configureStore(initialState, history);
