import {
  colorSecondaryGrayLight,
} from 'assets/styles/variables';

import calendarInfoCardConfig from './config/calendar-info-card-config';

export default ({
  title,
  cssRules,
  content,
  selectedMonth,
  selectedYear,
  subTitle,
}) => {
  const {
    mappedHolidaysByDevcenter,
  } = content;

  const arrayOfCalendars = Array.from(mappedHolidaysByDevcenter.keys()).map((devcenter) => ({
    data: [{
      type: 'description',
      data: `${subTitle} ${devcenter}`,
      cssRules: `
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: rgba(0, 0, 0, 0.87);
            padding-bottom: 1rem;
            padding-left: 1.6rem;
          `,
    },
    calendarInfoCardConfig(mappedHolidaysByDevcenter.get(devcenter), selectedMonth, selectedYear),
    ],
  }));

  if (mappedHolidaysByDevcenter.size) {
    return {
      type: 'info-card',
      cssRules,
      data: [{
        type: 'description',
        data: title,
        cssRules: `
          margin-left: 1.6rem;
          font-size: 2.2rem;
          line-height: 2.4rem;
          color: rgba(0, 0, 0, 0.87);
          padding-bottom: 1rem;
        `,
      },
      ...arrayOfCalendars,
      ],
    };
  }
  return {
    type: 'info-card',
    data: [{
      type: 'description',
      data: title,
      cssRules: `
        margin: 4.8rem 0 0 1.6rem;
        font-size: 2.2rem;
        line-height: 2.6rem;
        color: rgba(0, 0, 0, 0.87);
        padding-bottom: 1rem;
      `,
    },
    {
      type: 'description',
      data: 'Non working days are absent',
      cssRules: `
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 1.6rem 0 0 1.6rem;
        color: ${colorSecondaryGrayLight};
      `,
    },
    ],
  };
};
