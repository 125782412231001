import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.module.scss';

const Instructions = ({ children, qrcode, className }) => (
  <div id="instructions" className={classNames(styles.instructions, className)}>
    <h3 className={styles.title}>Instructions</h3>
    <div className={styles.content}>
      <div className={styles.column}>
        <h4 className={styles.columnTitle}>1. Use QR code</h4>
        <ul className={styles.columnList}>
          <li className={styles.columnItem}>
            <span>
              Download&nbsp;
              <a
                href="https://www.microsoft.com/ru-ru/security/mobile-authenticator-app"
                className={styles.link}
              >
                Microsoft Authenticator
              </a>
              &nbsp;(or any other OTP Authenticator app)&nbsp;on your mobile
            </span>
          </li>
          <li className={styles.columnItem}>
            <span>In the App select set up account</span>
          </li>
          <li className={styles.columnItem}>
            <span>Choose Scan a barcode method</span>
          </li>
        </ul>
        <div className={styles.qrcode}>
          <img
            id="qrcode"
            src={qrcode ? `data:image/jpg;base64,${qrcode}` : ''}
            alt="qr-code"
          />
        </div>
      </div>
      <div className={styles.column}>
        <h4 className={styles.columnTitle}>2. Enter secret code manually</h4>
        <ul className={classNames([styles.columnList, styles.code])}>
          <li className={styles.columnItem}>
            <span>
              Download&nbsp;
              <a
                href="https://www.microsoft.com/ru-ru/security/mobile-authenticator-app"
                className={styles.link}
              >
                Microsoft Authenticator
              </a>
              &nbsp;(or any other OTP Authenticator app)&nbsp;on your mobile
            </span>
          </li>
          <li className={styles.columnItem}>
            <span>In the App select set up account</span>
          </li>
          <li className={styles.columnItem}>
            <span>Choose Secret key method</span>
          </li>
          <li className={styles.columnItem}>
            <span>Select time-based authentication</span>
          </li>
          <li className={styles.columnItem}>
            <span>Submit the generated key in the form</span>
          </li>
        </ul>
        <div className={styles.children}> {children}</div>
      </div>
    </div>
  </div>
);

Instructions.propTypes = {
  qrcode: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

Instructions.defaultProps = {
  children: null,
  className: '',
};

export default Instructions;
