import { colorSecondaryGrayLight } from 'assets/styles/variables';
import styled, { css } from 'styled-components';
export const StyledEmptyTabItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: 15rem;
  height: 100%;
  color: ${colorSecondaryGrayLight};
  opacity: 0;
  -webkit-animation-name: fade;
  animation-name: fade;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  ${({ cssRules }) => css`${cssRules}`}
`;
