import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import styled from 'styled-components';

import { getHasPermissions } from 'utils/auth';

import DropDownIcon from 'assets/img/arrow-down.svg';

import LinksList from './links-list';

import './styles.scss';


const StyledDiv = styled.div`
   position: relative;
   left: ${({ offset }) => offset ? `calc(${offset.left}px - ((100vw)/ 2))` : 0};
   transform: ${({ offset }) => offset ? `translateX(${((offset.width / 2) - 17)}px)` : 0};
`;

const ExpandButton = ({
  title,
  items,
  actions,
  userGroup,
  getIsActive,
}) => {
  const [offset, setOffset] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const button = useRef(null);
  const section = useRef(null);
  const expandButton = useRef(null);

  const handleClick = ({ target }) => {
    const { current } = expandButton;

    if (target.closest('.expand-button') !== current) {
      closeExpandSection();
    }
  };

  const toggleIsOpen = () => {
    if (isOpen) {
      window.removeEventListener('click', handleClick);
    } else {
      window.addEventListener('click', handleClick);
    }

    return setIsOpen(!isOpen);
  };


  const closeExpandSection = () => {
    window.removeEventListener('click', handleClick);
    setIsOpen(false);
  };

  const getOffset = () => (
    setOffset({
      width: section.current.clientWidth,
      left: button.current.getBoundingClientRect().left,
    })
  );

  useEffect(() => {
    getOffset();
  }, []);

  const isActive = getIsActive && getIsActive();

  return (
    <div
      className="expand-button"
      onMouseEnter={getOffset}
      onClick={toggleIsOpen}
      ref={expandButton}
      onKeyUp={toggleIsOpen}
      role="button"
      tabIndex={-1}
    >
      <div
        className="expand-button__title-wrapper"
        ref={button}
      >
        <p
          className={classNames(
            'expand-button__title',
            { 'expand-button__title--opened': isOpen },
            { 'expand-button__title--active': isActive }
          )}
        >
          {title}
        </p>

        <SVG
          className={classNames(
            'expand-button__title-icon',
            { 'expand-button__title-icon--opened': isOpen }
          )}
          src={DropDownIcon}
        />
      </div>

      <div
        className={classNames(
          'expand-button__expand-section',
          { 'expand-button__expand-section--isOpen': isOpen }
        )}
      >
        <div
          className="expand-button__expand-section-wrapper"
          ref={section}
        >
          {
            items.map((item, index) => {
              const isArray = Array.isArray(item);

              const hasNeedWrapper = isArray ?
                item.some(({ guaranteedAccessRoles }) => (
                  getHasPermissions(userGroup, guaranteedAccessRoles)
                )) :
                true;

              return (
                hasNeedWrapper &&
                <StyledDiv
                  className={
                    classNames(
                      'expand-button__navigation-column',
                      { 'expand-button__navigation-column--single': !Array.isArray(item) },
                    )
                  }
                  key={index} // eslint-disable-line  react/no-array-index-key
                  offset={!Array.isArray(item) ? offset : null}
                >
                  {
                    Array.isArray(item) ?
                      item.map((block, blockIndex) => {
                        const { guaranteedAccessRoles } = block;

                        if (guaranteedAccessRoles && !getHasPermissions(userGroup, guaranteedAccessRoles)) {
                          return null;
                        }
                        return (
                          <LinksList
                            key={blockIndex} // eslint-disable-line  react/no-array-index-key
                            title={block.title}
                            items={block.items || []}
                            userGroup={userGroup}
                            actions={actions}
                          />);
                      }) :

                      <LinksList
                        items={item.items}
                        userGroup={userGroup}
                      />
                  }
                </StyledDiv>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};


ExpandButton.propTypes = {
  userGroup: PropTypes.string,
  getIsActive: PropTypes.func,
  title: PropTypes.string.isRequired,
  actions: PropTypes.shape({}).isRequired,
  items: PropTypes.any.isRequired, // TODO write proptypes
};

ExpandButton.defaultProps = {
  userGroup: '',
  getIsActive: null,
};


export default ExpandButton;
