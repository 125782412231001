import React, { memo, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { PlusIcon } from 'assets/svgComponents';
import { ActionButton } from 'components/buttons';
import { ListOfForms } from 'components/list-of-forms';
import Loader from 'components/loader';
import { technicalMentorsActions } from 'core/delivery/technicalMentorManagement/actions';
import {
  selectFormState,
  selectRecords,
} from 'core/delivery/technicalMentorManagement/selectors';
import { modalConductorActions } from 'core/modal-conductor/actions';
import EmptyMessage from 'elements/empty-message';
import { removeEmptyProperties } from 'forms/helpers';
import { TechnicalMentorForm } from 'forms/technical-mentor-form';
import commonStyles from 'forms/technical-mentor-form/styles.module.scss';
import { newTechnicalMentorRecord } from 'layouts/employee-details/components/resource-tabs/technical-mentor/constants';
import { useListColumns } from 'layouts/employee-details/components/resource-tabs/technical-mentor/useListColumns';

import { get, isEmpty } from 'lodash';

import styles from './styles.module.scss';

export const TechnicalMentor = memo(({
  id,
  title,
  devstaffId,
  layoutStyles,
  placeholderStyles,
  placeholderText,
  recordIdName,
  isCPS,
}) => {
  const {
    records,
    isFetchingTechnicalMentorRecords,
    hasPermissionToAddRecord,
    memoDevstaffId,
    technicalMentorsList,
    technicalMentorsMap,
  } = useSelector(selectRecords);
  const formState = useSelector(selectFormState);
  const isFormSubmitted = get(formState, 'isSubmittedSuccess', false) || get(formState, 'isSubmittedError', false);
  const dispatch = useDispatch();

  const handleSubmit = ({
    formData,
    isCreateMode,
    technicalMentorRecordId,
    dirtyFields,
  }) => {
    if (isCreateMode) {
      dispatch(technicalMentorsActions.createTechnicalMentorRecord({
        ...removeEmptyProperties(formData),
      }));
    } else {
      const changedFields = Object.keys(dirtyFields).reduce((accumulator, currentValue) => ({
        ...accumulator,
        [currentValue]: formData[currentValue],
      }), {});

      dispatch(technicalMentorsActions.updateTechnicalMentorRecord({
        ...changedFields,
        technicalMentorRecordId,
      }));
    }
  };

  const handleDelete = useCallback((technicalMentorRecordId) => {
    dispatch(modalConductorActions.changeCurrentModal({
      currentModal: 'CONFIRM_ACTION_MODAL',
      params: {
        content: [
          {
            type: 'title',
            data: 'Delete this record?',
          },
          {
            type: 'description',
            data: 'Once deleted, it cannot be restored.',
          },
        ],
        acceptActionName: 'deleteTechnicalMentorRecord',
        acceptActionTitle: 'Delete',
        acceptFunction: () => dispatch(technicalMentorsActions.deleteTechnicalMentorRecord({
          technicalMentorRecordId,
        })),
      },
    }));
  }, []);

  const handleRemoveRecord = useCallback(() => {
    dispatch(technicalMentorsActions.removeTechnicalMentorRecord());
  }, []);

  const handleAddRecord = useCallback((record) => {
    dispatch(technicalMentorsActions.addTechnicalMentorRecord(record));
  }, []);

  const columns = useListColumns({
    technicalMentorsList,
    technicalMentorsMap,
    isCPS,
  });

  const renderForm = useCallback(({
    item,
    onRemoveRecord,
    onDeleteHandler,
    setEditedRecordId,
    editedRecordId,
  }) => (
    <TechnicalMentorForm
      key={`technicalMentorForm_${item[recordIdName]}`}
      item={item}
      onRemoveRecord={onRemoveRecord}
      setEditedRecordId={setEditedRecordId}
      editedRecordId={editedRecordId}
      columns={columns}
      onSubmitHandler={handleSubmit}
      onDeleteHandler={onDeleteHandler}
      recordIdName={recordIdName}
      isCPS={isCPS}
    />
  ), [records]);

  useEffect(() => {
    if (!devstaffId || devstaffId === memoDevstaffId) return;

    dispatch(technicalMentorsActions.getTechnicalMentorRecords());
  }, [devstaffId]);

  useEffect(() => () => {
    dispatch(technicalMentorsActions.removeTechnicalMentorRecord());
  }, []);

  if (isFetchingTechnicalMentorRecords) {
    return (
      <div
        data-id="Loader"
        className={layoutStyles}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div id={id} title={title} data-id="Technical Mentor Records" className={layoutStyles}>
      <ListOfForms
        onDeleteHandler={handleDelete}
        title={title}
        columns={columns}
        data={records}
        onRemoveRecord={handleRemoveRecord}
        newRecord={newTechnicalMentorRecord}
        recordIdName={recordIdName}
        onAddRecord={handleAddRecord}
        isFormSubmitted={isFormSubmitted}
        commonStyles={commonStyles}
        renderAddButton={({
          onAddRecordClick,
          isAddButtonDisabled,
        }) => (
          <ActionButton
            title="Add Technical Mentor Button"
            dataId="addButton"
            type="button"
            onClick={onAddRecordClick}
            disabled={isAddButtonDisabled || !hasPermissionToAddRecord}
            className={styles.addTechnicalMentorRecordButton}
          >
            <PlusIcon />
            Add Technical Mentor
          </ActionButton>
        )}
        renderForm={renderForm}
      />
      {isEmpty(records) && <EmptyMessage className={placeholderStyles} description={placeholderText} />}
    </div>
  );
});

TechnicalMentor.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  devstaffId: PropTypes.number.isRequired,
  layoutStyles: PropTypes.string.isRequired,
  placeholderStyles: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  recordIdName: PropTypes.string.isRequired,
  isCPS: PropTypes.bool.isRequired,
};
