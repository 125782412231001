import {
  createAction,
} from 'redux-actions';
import {
  createTypes,
  async,
} from 'utils/helpers/actions';

export const authActionsTypes = createTypes([
  ...async('GET_TOKEN_FROM_COOKIES'),
  ...async('GET_ACCESS_TOKEN'),
  ...async('SET_USER'),
  ...async('GET_COMMON_DATA'),
  ...async('USER_LOGIN'),
  ...async('USER_VERIFICATION'),
  ...async('RESET_VERIFICATION'),
  'USER_LOGOUT',
  'CHECK_AUTHORIZATION',
  'REMOVE_ERROR',
], 'AUTH');

export const authActions = {
  checkAuthorization: createAction(authActionsTypes.CHECK_AUTHORIZATION),

  getTokenFromCookies: createAction(authActionsTypes.GET_TOKEN_FROM_COOKIES),

  getTokenFromCookiesSuccess: createAction(authActionsTypes.GET_TOKEN_FROM_COOKIES_SUCCESS),

  getTokenFromCookiesFail: createAction(
    authActionsTypes.GET_TOKEN_FROM_COOKIES_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  getAccessToken: createAction(authActionsTypes.GET_ACCESS_TOKEN),

  getAccessTokenSuccess: createAction(authActionsTypes.GET_ACCESS_TOKEN_SUCCESS),

  getAccessTokenFail: createAction(
    authActionsTypes.GET_ACCESS_TOKEN_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  setUser: createAction(
    authActionsTypes.SET_USER,
    ({
      userGroup,
      userId,
    }) => ({
      userGroup,
      userId,
    })
  ),

  setUserSuccess: createAction(
    authActionsTypes.SET_USER_SUCCESS,
    ({
      usersList,
    }) => ({
      usersList,
    })
  ),

  setUserFail: createAction(
    authActionsTypes.SET_USER_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  getCommonData: createAction(authActionsTypes.GET_COMMON_DATA),

  getCommonDataSuccess: createAction(
    authActionsTypes.GET_COMMON_DATA_SUCCESS,
    ({
      devCenters,
      billingAgents,
      customers,
      devCentersBySystemRole,
      roles,
    }) => ({
      devCenters,
      billingAgents,
      customers,
      devCentersBySystemRole,
      roles,
    })
  ),

  getCommonDataFail: createAction(
    authActionsTypes.GET_COMMON_DATA_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  userLogin: createAction(
    authActionsTypes.USER_LOGIN,
    (payload) => payload
  ),

  userLoginSuccess: createAction(
    authActionsTypes.USER_LOGIN_SUCCESS,
    (payload) => payload
  ),

  userLoginFail: createAction(
    authActionsTypes.USER_LOGIN_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  userVerification: createAction(
    authActionsTypes.USER_VERIFICATION,
    (payload) => payload
  ),

  userVerificationSuccess: createAction(
    authActionsTypes.USER_VERIFICATION_SUCCESS,
    (payload) => payload
  ),

  userVerificationFail: createAction(
    authActionsTypes.USER_VERIFICATION_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  verificationReset: createAction(
    authActionsTypes.RESET_VERIFICATION,
    (payload) => payload
  ),

  verificationResetSuccess: createAction(
    authActionsTypes.RESET_VERIFICATION_SUCCESS,
    (payload) => payload
  ),

  verificationResetFail: createAction(
    authActionsTypes.RESET_VERIFICATION_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  userLogout: createAction(authActionsTypes.USER_LOGOUT),

  removeError: createAction(
    authActionsTypes.REMOVE_ERROR,
    (payload) => payload
  ),
};
