import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as CheckboxCheckedIcon } from 'assets/img/icon-checkbox-checked.svg';
import { ReactComponent as CheckboxIcon } from 'assets/img/icon-checkbox.svg';
import { ActionButton } from 'components/buttons';

import classes from './styles.module.scss';

const controlButtonTypes = {
  button: 'button',
  checkbox: 'checkbox',
};

const ControlButton = memo(({ type, onClick, text, isShow, isFetching, isChecked }) => {
  if (!isShow) {
    return (<div />);
  }

  if (controlButtonTypes.checkbox === type) {
    return (
      <div className={classes.channelPartnerToggle}>
        {text}
        <ActionButton
          dataId="Channel partner report version"
          onClick={onClick}
          disabled={isFetching}
        >
          {isChecked ?
            <CheckboxCheckedIcon /> :
            <CheckboxIcon />}
        </ActionButton>
      </div>
    );
  }

  return (
    <button
      key={text}
      type="button"
      className={classes.controlButton}
      onClick={onClick}
      disabled={isFetching}
    >
      {text}
    </button>
  );
});

ControlButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  isShow: PropTypes.bool.isRequired,
  type: PropTypes.string,
  isFetching: PropTypes.bool,
  isChecked: PropTypes.bool,
};

ControlButton.defaultProps = {
  type: 'button',
  isFetching: false,
  isChecked: false,
};

ControlButton.displayName = 'ControlButton';

export { ControlButton };
