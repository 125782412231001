import React, { useState, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Magnifier from 'assets/img/icon-magnifier.svg';
import classNames from 'classnames';

import './styles.scss';

const FilterSearch = ({ value, placeholder, changeFilter }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const handleChangeFilter = useCallback((query) => dispatch(changeFilter({ search: {
    isActive: !!query,
    query,
  } })), []);

  const handleInputChange = (event) => {
    const query = event.target.value;
    setSearch(query);
    handleChangeFilter(query);
  };

  const handleInputClear = () => {
    setSearch('');
    handleChangeFilter('');
  };

  return (
    <div className="filter-search">
      <img
        className="filter-search__icon"
        src={Magnifier}
        alt="Magnifier icon"
      />

      <input
        className={classNames(
          'filter-search__input',
          { 'filter_search__input--with-term': !!value }
        )}
        value={search}
        placeholder={placeholder}
        onChange={handleInputChange}
        type="search"
      />

      {
        !!search &&
        <button
          className="filter-search__button-clear button button--close"
          onClick={handleInputClear}
          type="button"
        />
      }
    </div>
  );
};

FilterSearch.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  changeFilter: PropTypes.func.isRequired,
};

FilterSearch.defaultProps = {
  placeholder: 'Search',
  value: '',
};


export default FilterSearch;
