import { useEffect, useState } from 'react';

import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { getHasPermissions } from 'utils/auth';
import { getAvailableRolesByPathSegment } from 'utils/helpers/getAvailableRolesByPathSegment';

export const useCheckPermissions = (props) => {
  const history = useHistory();
  const [isAuthorizedToRoute, setIsAuthorizedToRoute] = useState(false);
  const locationPathname = get(props, 'location.pathname');
  const { userGroup, guaranteedAccessRolesForSubRoutes } = props;

  useEffect(() => {
    if (userGroup) {
      const hasPermissions = getHasPermissions(userGroup, getAvailableRolesByPathSegment(
        guaranteedAccessRolesForSubRoutes,
        locationPathname
      ));
      setIsAuthorizedToRoute(hasPermissions);

      if (!hasPermissions) {
        history.push('/not-found');
      }
    }
  }, [
    userGroup,
    guaranteedAccessRolesForSubRoutes,
    locationPathname,
  ]);

  return {
    isAuthorizedToRoute,
    setIsAuthorizedToRoute,
  };
};
