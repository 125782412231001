import React from 'react';

import PropTypes from 'prop-types';

import logo from 'assets/img/logo-hiqo.svg';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const AuthHeader = ({ className, heading, content }) => (
  <div className={classNames(styles.header, className)}>
    <div>
      <img className={styles.logo} src={logo} alt="logo" />
    </div>
    <h1 className={styles.heading}>{heading}</h1>
    {content && <div className={styles.content}>{content}</div>}
  </div>
);

AuthHeader.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  content: PropTypes.object,
};

AuthHeader.defaultProps = {
  className: '',
  content: null,
};
