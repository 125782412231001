import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled, { css } from 'styled-components';


const StyledRoot = styled.div`
  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;


const FullSwitcher = ({ placeholder, name, isLocked, cssRules, onClick, isAssignedAll }) => {
  const [value, setValue] = useState(isAssignedAll);
  const handleClick = () => {
    onClick({ isAssignedAll: !value });
    setValue(!value);
  };
  useEffect(() => {
    setValue(isAssignedAll);
  }, [isAssignedAll]);

  return (
    <StyledRoot
      className={
        classNames(
          'form-switcher form-switcher-top',
          { 'form-switcher--locked': isLocked }
        )}
      cssRules={cssRules}
    >
      <input
        id={name}
        className="form-switcher__input"
        type="checkbox"
      />
      <label
        htmlFor={name}
        className="form-switcher-top__label-top"
        role="presentation"
        onKeyUp={handleClick}
        onClick={handleClick}
      >

        <span className="form-switcher-top__label-title-top">{placeholder}</span>

        <span className="form-switcher-top__icon-wrapper-top">

          <span
            className={classNames(
              'form-switcher__icon-track',
              { 'form-switcher__icon-track--checked': value }
            )}
          >

            <span
              className={classNames(
                'form-switcher__icon-knob',
                { 'form-switcher__icon-knob--checked': value }
              )}
            />

          </span>

        </span>
      </label>

    </StyledRoot>
  );
};

FullSwitcher.propTypes = {
  isLocked: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  cssRules: PropTypes.string,
  onClick: PropTypes.func,
  isAssignedAll: PropTypes.bool,
};

FullSwitcher.defaultProps = {
  isLocked: false,
  name: null,
  placeholder: '',
  cssRules: '',
  onClick: () => null,
  isAssignedAll: false,
};


export default FullSwitcher;
