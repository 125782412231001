import { FILTERS_TYPES } from 'core/constants';
import { get } from 'lodash';
import moment from 'moment';

import { checkIsInclude, checkIsSelected } from 'utils/helpers/filters';

export const filtersTemplates = [{
  name: 'summaryBillableActivities',
  storeKey: ['ptmFpReportRows', 'ttmReportRows', 'odcReportRows', 'overtimeReportRows'],
  filtersTemplate: [{
    type: FILTERS_TYPES.SEARCH,
    storeKey: 'client',
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'client', '');
      return checkIsInclude({ selected, value });
    },
    componentProps: {
      placeholder: 'Client',
    },
  },
  {
    type: FILTERS_TYPES.SEARCH,
    storeKey: 'project',
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'project', '');
      return checkIsInclude({ selected, value });
    },
    componentProps: {
      placeholder: 'Project',
    },
  },
  {
    type: FILTERS_TYPES.SEARCH,
    storeKey: 'taskOrder',
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'taskOrder', '');
      return checkIsInclude({ selected, value });
    },
    componentProps: {
      placeholder: 'TO',
    },
  },
  {
    type: FILTERS_TYPES.SEARCH,
    storeKey: 'resource',
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'resource', '');
      return checkIsInclude({ selected, value });
    },
    componentProps: {
      placeholder: 'Resource',
    },
  },
  {
    type: FILTERS_TYPES.SELECT,
    storeKey: 'seniority',
    valueGetter: ({
      seniority,
    }) => seniority,
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'seniority', '');
      return checkIsSelected({ selected, value });
    },
  },
  {
    type: 'empty',
  },
  ],
},

{
  name: 'dtoSummaryNonBillableActivities',
  storeKey: 'nonBillableReportRows',
  filtersTemplate: [{
    type: FILTERS_TYPES.SEARCH,
    storeKey: 'project',
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'project', '');
      return checkIsInclude({ selected, value });
    },
    componentProps: {
      placeholder: 'Project',
    },
  },
  {
    type: FILTERS_TYPES.DATE,
    storeKey: 'date',
    componentProps: {
      withMonthSelecting: true,
      withYearSelecting: true,
      outputFormatting: ({ date, useOnlyYear, useOnlyMonth }) => !!date && ({
        value: date,
        useOnlyYear,
        useOnlyMonth,
        formattedDate: moment.isMoment(date) ?
          date.format(`YYYY${useOnlyYear ? '' : '-MM'}${useOnlyMonth || useOnlyYear ? '' : '-DD'}`) :
          date,
      }),
    },
    checkFilter: ({ selected, currentRow }) => {
      const formattedDate = get(selected, 'formattedDate');
      const value = get(currentRow, 'date');
      if (value && formattedDate) {
        return !value.match(formattedDate);
      }

      return true;
    },
  },
  {
    type: FILTERS_TYPES.SEARCH,
    storeKey: 'resource',
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'resource', '');
      return checkIsInclude({ selected, value });
    },
    componentProps: {
      placeholder: 'Resource',
    },
  },
  {
    type: FILTERS_TYPES.SEARCH,
    storeKey: 'Client Category',
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'clientCategoryName', '');
      return checkIsInclude({ selected, value });
    },
    componentProps: {
      placeholder: 'Client Category',
    },
  },
  {
    type: FILTERS_TYPES.SEARCH,
    storeKey: 'Billing Category',
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'internalCategoryName', '');
      return checkIsSelected({ selected, value });
    },
    componentProps: {
      placeholder: 'Billing Category',
    },
  },
  {
    type: 'empty',
  },
  {
    type: 'empty',
  },
  ],
},
];
