import { USERS_GROUPS } from 'core/auth/constants';
import {
  deliveryActionsTypes,
} from 'core/delivery/actions';
import update from 'immutability-helper';
import {
  get,
} from 'lodash';
import {
  createReducer,
} from 'redux-create-reducer';

import { getHasPermissions } from 'utils/auth';

import {
  dashboardActionsTypes,
} from './actions';
import {
  ENTITY_NAME,
} from './constants';

const initialState = {
  entityName: ENTITY_NAME,
  errors: {},
  isFetching: false,
  isFormSubmitted: false,
  dashboardData: {
    dashboardSeniorityUpdateRecords: [],
    dashboardWorkBookRecords: [],
    dashboardPtoRequestRecords: [],
  },
  orderRules: {
    taskOrders: {
      orderBy: 'TO', // TODO
      isReversed: false,
    },
  },
};

export default createReducer(initialState, {
  [dashboardActionsTypes.TOGGLE_WORK_BOOK_RECORD_STATE](state, {
    payload: {
      recordId,
    },
  }) {
    const dashboardData = get(state, 'dashboardData', {});
    const dashboardWorkBookRecords = get(dashboardData, 'dashboardWorkBookRecords', []);

    return update(state, {
      dashboardData: {
        $merge: {
          dashboardWorkBookRecords: dashboardWorkBookRecords.map((record) => {
            const inEditMode = record && record.inEditMode;

            return record && record.recordId === recordId ? {
              ...record,
              inEditMode: !inEditMode,
            } :
              record;
          }),
        },
      },
    });
  },

  [deliveryActionsTypes.UPDATE_WORKBOOK_RECORD_SUCCESS](state, {
    payload: {
      recordId,
      fields,
      devCentersBySystemRole,
      systemRole,
      entityName,
    },
  }) {
    const dashboardWorkBookRecords = get(state, 'dashboardData.dashboardWorkBookRecords', []);
    const devCenters = get(devCentersBySystemRole, 'devcenterList', null);
    const recordIndex = dashboardWorkBookRecords.findIndex((record) => record && record.recordId === recordId);
    const recordDevCenter = get(fields, 'devcenter', null);
    const isCTO = getHasPermissions(systemRole, [USERS_GROUPS.CTO]);
    const removeRecord =
      Array.isArray(devCenters) && !devCenters.includes(recordDevCenter) && !isCTO && entityName === ENTITY_NAME;

    if (removeRecord) {
      return update(state, {
        dashboardData: {
          dashboardWorkBookRecords: {
            $set: dashboardWorkBookRecords.filter((record, index) => index !== recordIndex),
          },
        },
      });
    }

    if (recordIndex !== -1) {
      return update(state, {
        dashboardData: {
          dashboardWorkBookRecords: {
            [recordIndex]: {
              $merge: {
                ...fields,
                inEditMode: false,
              },
            },
          },
        },
      });
    }
    return state;
  },

  [deliveryActionsTypes.UPDATE_PTO_REQUEST_RECORD_SUCCESS](state, {
    payload: {
      recordId,
      fields,
    },
  }) {
    const dashboardPtoRequestRecords = get(state, 'dashboardData.dashboardPtoRequestRecords', []);
    const recordIndex = dashboardPtoRequestRecords.findIndex((record) => record && record.recordId === recordId);

    if (recordIndex !== -1) {
      return update(state, {
        dashboardData: {
          dashboardPtoRequestRecords: {
            [recordIndex]: {
              $merge: {
                ...fields,
                inEditMode: false,
              },
            },
          },
        },
      });
    }
    return state;
  },

  [dashboardActionsTypes.DELETE_WORKBOOK_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [dashboardActionsTypes.DELETE_WORKBOOK_RECORD_SUCCESS](state, {
    payload: {
      recordId,
    },
  }) {
    const dashboardWorkBookRecords = get(state, 'dashboardData.dashboardWorkBookRecords');
    const updatedRecords = dashboardWorkBookRecords.slice().filter((record) => !record || record.recordId !== recordId);

    return update(state, {
      dashboardData: {
        dashboardWorkBookRecords: {
          $merge: updatedRecords,
        },
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },

  [dashboardActionsTypes.DELETE_WORKBOOK_RECORD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },

  [dashboardActionsTypes.GET_DASHBOARD_DATA](state) {
    return update(state, {
      dashboardData: {
        $set: {
          dashboardWorkBookRecords: [],
          dashboardPtoRequestRecords: [],
          dashboardSeniorityUpdateRecords: [],
        },
      },
      isFetching: {
        $set: true,
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },

  [dashboardActionsTypes.GET_DASHBOARD_DATA_SUCCESS](state, {
    payload: {
      dashboardData,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      dashboardData: {
        $set: dashboardData,
      },
      errors: {
        $unset: ['getDashboardDataError'],
      },
    });
  },

  [dashboardActionsTypes.GET_DASHBOARD_DATA_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [dashboardActionsTypes.SET_ORDER_RULES](state, {
    payload: {
      tableName,
      orderRules,
    },
  }) {
    return update(state, {
      orderRules: {
        [tableName]: {
          $set: orderRules,
        },
      },
    });
  },
});
