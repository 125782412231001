import { colorPrimary, colorSecondaryGrayLight } from 'assets/styles/variables';
import { toNumber } from 'lodash';
import moment from 'moment';

import { getUnixTime } from 'utils/helpers/date';
import { showResourceLinkByDevCenter } from 'utils/helpers/link';
import { addThousandSeparator, roundValue } from 'utils/helpers/numbers';

export default ({
  isHRM,
  isCTO,
  isChefs,
  isAudit,
  isGMReport,
  isDTOReport,
  selectedDate,
  hasPermissionsTOClientModule,
  hasPermissionsTODeliveryModule,
  hasPermissionsTOTaskOrderModule,
  availableDevCentersForDd,
}) => [
  {
    columnName: 'Client',
    headerProps: {
      cssRules: 'min-height: 2.8rem;',
    },
    valueGetter: ({ clientId, client, isGrandTotal, project, isSubTotal }) => {
      if (isSubTotal || isGrandTotal) {
        return {
          type: 'text',
          componentProps: {
            data: `Total ${project}`,
            cssRules: `
            font-size: ${isGrandTotal ? '1.6rem' : '1.4rem'};
            font-weight: 500;
          `,
          },
        };
      }
      return {
        type: clientId && hasPermissionsTOClientModule ? 'link' : 'text',
        value: client,
        componentProps: {
          data: client || 'n/a',
          pathname: `/clients/${clientId}/${
            isCTO || isHRM ? 'task-orders' : 'details'
          }`,
          cssRules: 'font-size: 1.2rem;',
          isMultiline: true,
        },
      };
    },
  },
  {
    columnName: 'Project this month',
    valueGetter: ({
      project,
      taskOrderId,
      isGrandTotal,
      isSubTotal,
      hasActiveFilters,
    }) => {
      if (isSubTotal) {
        return {};
      }
      if (isGrandTotal) {
        return hasActiveFilters ?
          {
            type: 'text',
            componentProps: {
              data: '/  *filters applied',
              cssRules: `
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.6rem;
            text-transform: uppercase;
            letter-spacing: 0.15rem;
            color: ${colorPrimary};
          `,
              isMultiline: true,
            },
          } :
          {};
      }
      return {
        type: taskOrderId && !isAudit ? 'link' : 'text',
        componentProps: {
          data: project || 'n/a',
          pathname: `/reports/billing-reports/project/${
            !isDTOReport ? 'TO' : 'DTO'
          }/${taskOrderId}/${selectedDate}`,
          cssRules: 'font-size: 1.2rem;',
          isMultiline: true,
        },
      };
    },
  },
  {
    columnName: 'TO',
    valueGetter: ({ taskOrder, taskOrderId, isSubTotal, isGrandTotal }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      return {
        type: taskOrderId && hasPermissionsTOTaskOrderModule ? 'link' : 'text',
        componentProps: {
          data: taskOrder || 'n/a',
          pathname: `/task-orders/${taskOrderId}/${
            isCTO ? 'staffing' : 'details'
          }`,
          cssRules: 'font-size: 1.2rem;',
          isMultiline: true,
        },
      };
    },
  },
  {
    columnName: 'Resource',
    valueGetter: ({ resource, devstaffId, isSubTotal, isGrandTotal, devcenterId }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      return {
        type:
          devstaffId && showResourceLinkByDevCenter(isCTO, hasPermissionsTODeliveryModule, availableDevCentersForDd, devcenterId) ?
            'link' :
            'text',
        componentProps: {
          data: `${resource}`,
          pathname: `/delivery/employee/${devstaffId}/${
            isChefs ? 'work-book' : 'pto-requests'
          }`,
          cssRules: 'font-size: 1.2rem;',
          isMultiline: true,
        },
      };
    },
  },
  {
    columnName: 'Seniority',
    valueGetter: ({ seniority, isSubTotal, isGrandTotal }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      return {
        value: seniority,
        type: 'text',
      };
    },
  },
  {
    columnName: 'Booking Period',
    valueGetter: ({ dateFrom, dateThrough, isSubTotal, isGrandTotal }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      return {
        isDate: true,
        unixValue: getUnixTime(dateFrom),
        value: `${moment(dateFrom).format('YYYY-MM-DD')}${
          dateThrough ?
            ` \u2014 ${moment(dateThrough).format('YYYY-MM-DD')}` :
            ''
        }`,
        type: 'text',
      };
    },
  },

  {
    columnName: 'Revenue',
    headerProps: {
      cssRules: 'margin-left: auto;',
    },
    shouldntBeRendered: !isGMReport,
    valueGetter: ({ revenue, isSubTotal, isGrandTotal, isRatePlatinum }) => {
      const data = addThousandSeparator(toNumber(revenue).toFixed(2));

      return {
        type: 'text',
        shouldntBeRendered: !isGMReport,
        componentProps: {
          data: `${isRatePlatinum && isGMReport ? '*' : ''}${data}`,
          cssRules: `
          && {
            padding-right: 0;
            overflow: visible;
            text-align: right;
            color: ${isGrandTotal && isGMReport ? colorPrimary : 'inherit'};
            ${!revenue ? `color: ${colorSecondaryGrayLight}` : ''}
            ${isSubTotal || isGrandTotal ? 'font-weight: 500;' : ''}
          }
        `,
        },
        cssRules: `
        && {
          font-size: ${isGrandTotal || isSubTotal ? '1.3rem' : '1.2rem'};
          padding-right: 1.8rem;
        }
        && .plain-text {
          overflow: visible;
        }
      `,
      };
    },
  },
  {
    columnName: 'COP',
    headerProps: {
      cssRules: 'margin-left: auto;',
    },
    shouldntBeRendered: !isGMReport,
    valueGetter: ({ cop, isSubTotal, isGrandTotal }) => {
      const data = addThousandSeparator(toNumber(cop).toFixed(2));

      return {
        type: 'text',
        shouldntBeRendered: !isGMReport,
        componentProps: {
          data,
          cssRules: `
          && {
            padding-right: 0;
            overflow: visible;
            text-align: right;
            color: ${isGrandTotal && isGMReport ? colorPrimary : 'inherit'};
            ${!cop ? `color: ${colorSecondaryGrayLight}` : ''}
            ${isSubTotal || isGrandTotal ? 'font-weight: 500;' : ''}
          }
        `,
        },
        cssRules: `
        && {
          font-size: ${isGrandTotal || isSubTotal ? '1.3rem' : '1.2rem'};
          padding-right: 1.8rem;
        }
        && .plain-text {
          overflow: visible;
        }
      `,
      };
    },
  },
  {
    columnName: 'COS',
    headerProps: {
      cssRules: 'margin-left: auto;',
    },
    shouldntBeRendered: !isGMReport, // header of table
    valueGetter: ({ cos, isSubTotal, isGrandTotal }) => {
      const data = addThousandSeparator(toNumber(cos).toFixed(2));

      return {
        type: 'text',
        shouldntBeRendered: !isGMReport, // table unit
        componentProps: {
          data,
          cssRules: `
          && {
            padding-right: 0;
            overflow: visible;
            text-align: right;
            color: ${isGrandTotal && isGMReport ? colorPrimary : 'inherit'};
            ${!cos ? `color: ${colorSecondaryGrayLight}` : ''}
            ${isSubTotal || isGrandTotal ? 'font-weight: 500;' : ''}
          }
        `,
        },
        cssRules: `
        && {
          font-size: ${isGrandTotal || isSubTotal ? '1.3rem' : '1.2rem'};
          padding-right: 1.8rem;
        }
        && .plain-text {
          overflow: visible;
        }
      `,
      };
    },
  },
  {
    columnName: 'Discount/\nSurcharge',
    headerProps: {
      cssRules: 'margin-left: auto;',
    },
    shouldntBeRendered: !isDTOReport || (isDTOReport && isHRM),
    valueGetter: ({ surchargePercentage, isSubTotal, isGrandTotal }) => {
      const data = `${addThousandSeparator(roundValue(surchargePercentage, 2))}%`;
      if (isSubTotal || isGrandTotal) {
        return { shouldntBeRendered: !isDTOReport || (isDTOReport && isHRM) };
      }

      return {
        type: 'text',
        shouldntBeRendered: !isDTOReport || (isDTOReport && isHRM),
        componentProps: {
          data,
          cssRules: `
          && {
            padding-right: 0;
            overflow: visible;
            text-align: right;
            color:  inherit;
          }
        `,
        },
        cssRules: `
        && {
          font-size: 1.2rem;
          padding-right: 1.8rem;
        }
        && .plain-text {
          overflow: visible;
        }
      `,
      };
    },
  },
  {
    columnName: isGMReport ? 'GM' : 'Monthly Rate',
    shouldntBeRendered: isHRM,
    headerProps: {
      cssRules: `
      margin-left: auto;
      ${isGMReport ? '' : 'padding-left: 1.7rem;'}
    `,
    },
    valueGetter: ({ total, isSubTotal, isGrandTotal, isRatePlatinum }) => {
      const data = addThousandSeparator(toNumber(total).toFixed(2));

      return {
        type: 'text',
        shouldntBeRendered: isHRM,
        componentProps: {
          data: `${
            isRatePlatinum &&
            !isSubTotal &&
            !isGrandTotal &&
            !isGMReport &&
            !isDTOReport ?
              '*' :
              ''
          }${data}`,
          cssRules: `
          && {
            padding-right: 0;
            overflow: visible;
            text-align: right;
            color: ${isGrandTotal ? colorPrimary : 'inherit'};
            ${!total ? `color: ${colorSecondaryGrayLight}` : ''}
            ${isSubTotal || isGrandTotal ? 'font-weight: 500;' : ''}
          }
        `,
        }, // grid unit wrapper styles
        cssRules: `
        && {
          font-size: ${isGrandTotal || isSubTotal ? '1.3rem' : '1.2rem'};
          padding-right: 1.8rem;
        }
        && .plain-text {
          overflow: visible;
        }
      `,
      };
    },
  },
];
