import React, { memo } from 'react';

import PropTypes from 'prop-types';

import Viewbox from 'elements/form-viewbox';
import { viewBoxStyles } from 'forms/categories-form/components/field-array-item/styles';
import { TextInput } from 'forms/categories-form/components/text-input';
import { transformKey } from 'forms/categories-form/utils';
import { noop, get } from 'lodash';

export const KeyInput = memo(({ control,
  viewMode,
  value,
  index,
  onChange,
  setBlocker,
  error,
  ...props }) => {
  const errorMessage = get(error, 'message', '');

  const handleOnInputChange = (e) => {
    setBlocker(true);
    onChange(transformKey(e.target.value));
  };

  if (viewMode) {
    return <Viewbox cssRules={viewBoxStyles} fieldData={{ value }} />;
  }

  return (
    <TextInput
      {...props}
      ref={null}
      value={value}
      onChange={handleOnInputChange}
      withError={!!errorMessage}
      withLabel={false}
    />
  );
});

KeyInput.propTypes = {
  viewMode: PropTypes.bool,
  value: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  control: PropTypes.shape({}).isRequired,
  cssRules: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setBlocker: PropTypes.func,
  error: PropTypes.shape({}),
};

KeyInput.defaultProps = {
  viewMode: false,
  cssRules: undefined,
  setBlocker: () => noop,
  error: undefined,
};
