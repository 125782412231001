import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Inputs from 'components/inputs';

import './styles.scss';


const GeneratorInput = ({ fieldConfig, formProps, onChange }) => {
  const { name, label, ...rest } = fieldConfig;
  const { errors, touched } = formProps;

  return (
    <Field
      key={name}
      type="text"
      name={name}
      render={({
        field: { value },
      }) => (
        <Inputs.TextInput
          name={name}
          label={label}
          inputValue={value}
          onChange={onChange}
          error={errors[name]}
          withError={Boolean(touched[name] && errors[name])}
          {...rest}
        />
      )}
    />
  );
};


GeneratorInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  formProps: PropTypes.shape({
  }).isRequired,
  fieldConfig: PropTypes.shape({
  }).isRequired,
};


export default GeneratorInput;
