/* eslint-disable import/no-import-module-exports */
import 'assets/styles/index.scss';

import 'babel-polyfill';

import React from 'react';

import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';
import App from 'containers/app';
import { store, history } from 'core/store';

import ReactDOM from 'react-dom';

const MOUNT_NODE = document.getElementById('root');

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE
  );
};

render();

if (module.hot) {
  module.hot.accept('containers/app', () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}
