import {
  userManagementEntityAdapter,
  userManagementEntityParser,
} from 'core/configurations/user-management/helpers';
import update from 'immutability-helper';

import { newRecord } from 'layouts/user-management/constants';
import { createReducer } from 'redux-create-reducer';

import { actionTypes } from './actions';
import { USER_MANAGEMENT_FILTER_STATE, ENTITY_NAME, USER_ID } from './constants';

const initialState = {
  entityName: ENTITY_NAME,
  userRecords: {
    ids: [],
    entities: {},
  },
  filters: {
    controls: {
      displayOnlyActiveUsers: true,
    },
    filterByColumns: USER_MANAGEMENT_FILTER_STATE,
  },
  isCreateMode: false,
  isFetchingUserRecords: false,
  sorting: {
    fullname: 'asc',
  },
  errors: {},
};

const {
  CHANGE_FILTER_BY_COLUMNS,
  CLEAR_FILTER_BY_COLUMNS,
  CHANGE_SORTING_BY_COLUMNS,
  TOGGLE_DISPLAY_ACTIVE_USERS,
  CLEAR_CONTROL_FILTERS,
  FETCH_USER_RECORDS,
  FETCH_USER_RECORDS_SUCCESS,
  FETCH_USER_RECORDS_FAIL,
  CREATE_USER_RECORD,
  CREATE_USER_RECORD_SUCCESS,
  CREATE_USER_RECORD_FAIL,
  UPDATE_USER_RECORD,
  UPDATE_USER_RECORD_SUCCESS,
  UPDATE_USER_RECORD_FAIL,
  DELETE_USER_RECORD,
  DELETE_USER_RECORD_SUCCESS,
  DELETE_USER_RECORD_FAIL,
  ADD_NEW_USER_RECORD,
  REMOVE_NEW_USER_RECORD,
  CLEAR_FORM_STATE_BY_USER_ID,
  CLEAR_USER_MANAGEMENT_STATE,
} = actionTypes;

const { addOne, removeOne, setAll } = userManagementEntityAdapter;

const userManagementReducer = createReducer(
  initialState,
  {
    [ADD_NEW_USER_RECORD](state) {
      return update(state, {
        userRecords: {
          $set: addOne(state.userRecords, newRecord),
        },
        isCreateMode: {
          $set: true,
        },
      });
    },
    [REMOVE_NEW_USER_RECORD](state) {
      return update(state, {
        userRecords: {
          $set: removeOne(state.userRecords, USER_ID),
        },
        isCreateMode: {
          $set: false,
        },
      });
    },
    [FETCH_USER_RECORDS](state) {
      return update(state, {
        isFetchingUserRecords: {
          $set: true,
        },
        errors: {
          $set: null,
        },
      });
    },
    [FETCH_USER_RECORDS_SUCCESS](state, action) {
      return update(state, {
        isFetchingUserRecords: {
          $set: false,
        },
        userRecords: {
          $set: setAll(state.userRecords, action.payload.userRecords),
        },
      });
    },
    [FETCH_USER_RECORDS_FAIL](state, action) {
      return update(state, {
        isFetchingUserRecords: {
          $set: false,
        },
        errors: {
          $set: action.payload.error,
        },
      });
    },
    [CREATE_USER_RECORD](state) {
      return update(state, {
        userRecords: {
          entities: {
            [USER_ID]: {
              formState: {
                $merge: {
                  isSubmitting: true,
                  isFormSubmitted: false,
                  isFormError: false,
                },
              },
            },
          },
        },
      });
    },
    [CREATE_USER_RECORD_SUCCESS](state, { payload }) {
      const index = 0;

      return update(state, {
        isCreateMode: {
          $set: false,
        },
        userRecords: {
          ids: {
            [index]: {
              $set: payload.userId,
            },
          },
          entities: {
            $unset: [USER_ID],
            $merge: {
              [payload.userId]: userManagementEntityParser(payload, {
                isSubmitting: false,
                isFormSubmitted: true,
              }),
            },
          },
        },
      });
    },
    [CREATE_USER_RECORD_FAIL](state, action) {
      return update(state, {
        userRecords: {
          entities: {
            [USER_ID]: {
              formState: {
                $merge: {
                  isSubmitting: false,
                  isFormError: true,
                },
              },
            },
          },
        },
        errors: {
          $set: action.payload.error,
        },
      });
    },
    [UPDATE_USER_RECORD](state, action) {
      return update(state, {
        userRecords: {
          entities: {
            [action.payload.userId]: {
              formState: {
                $merge: {
                  isSubmitting: true,
                  isFormSubmitted: false,
                  isFormError: false,
                },
              },
            },
          },
        },
      });
    },
    [UPDATE_USER_RECORD_SUCCESS](state, action) {
      return update(state, {
        userRecords: {
          entities: {
            [action.payload.userId]: {
              $merge: userManagementEntityParser(action.payload, {
                isSubmitting: false,
                isFormSubmitted: true,
              }),
            },
          },
        },
      });
    },
    [UPDATE_USER_RECORD_FAIL](state, action) {
      return update(state, {
        userRecords: {
          entities: {
            [action.payload.userId]: {
              formState: {
                $merge: {
                  isSubmitting: false,
                  isFormError: true,
                },
              },
            },
          },
        },
        errors: {
          $set: action.payload.error,
        },
      });
    },
    [DELETE_USER_RECORD](state, action) {
      return update(state, {
        userRecords: {
          entities: {
            [action.payload.userId]: {
              formState: {
                $merge: {
                  isSubmitting: true,
                  isFormSubmitted: false,
                  isFormError: false,
                },
              },
            },
          },
        },
      });
    },
    [DELETE_USER_RECORD_SUCCESS](state, action) {
      return update(state, {
        userRecords: {
          $set: removeOne(state.userRecords, action.payload.userId),
        },
      });
    },
    [DELETE_USER_RECORD_FAIL](state, action) {
      return update(state, {
        userRecords: {
          entities: {
            [action.payload.userId]: {
              formState: {
                $merge: {
                  isSubmitting: false,
                  isFormError: true,
                },
              },
            },
          },
        },
        errors: {
          $set: action.payload.error,
        },
      });
    },
    [CHANGE_FILTER_BY_COLUMNS](state, action) {
      return update(state, {
        filters: {
          filterByColumns: {
            $merge: {
              ...action.payload,
            },
          },
        },
      });
    },
    [CLEAR_FILTER_BY_COLUMNS](state) {
      return update(state, {
        filters: {
          filterByColumns: {
            $set: USER_MANAGEMENT_FILTER_STATE,
          },
        },
      });
    },
    [CHANGE_SORTING_BY_COLUMNS](state, action) {
      return update(state, {
        sorting: {
          $merge: action.payload,
        },
      });
    },
    [TOGGLE_DISPLAY_ACTIVE_USERS](state, action) {
      return update(state, {
        filters: {
          controls: {
            displayOnlyActiveUsers: {
              $set: action.payload.displayOnlyActiveUsers,
            },
          },
        },
      });
    },
    [CLEAR_CONTROL_FILTERS](state) {
      return update(state, {
        filters: {
          controls: {
            displayOnlyActiveUsers: {
              $set: true,
            },
          },
        },
      });
    },
    [CLEAR_FORM_STATE_BY_USER_ID](state, action) {
      if (!state.isCreateMode) {
        return update(state, {
          userRecords: {
            entities: {
              [action.payload.userId]: {
                formState: {
                  $set: {
                    isSubmitting: false,
                    isFormSubmitted: false,
                    isFormError: false,
                  },
                },
              },
            },
          },
        });
      }

      return state;
    },
    [CLEAR_USER_MANAGEMENT_STATE]() {
      return initialState;
    },
  },
);

export default userManagementReducer;
