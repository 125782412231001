import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Tabs } from 'components/tabs';
import { selectCategories } from 'core/configurations/category-management/selectors';
import ScreenPlaceholder from 'elements/screen-placeholder';
import {
  StyledCategoryTabsWrapper,
  StyledCategoryTabsHeader,
  StyledCategoryTabs,
} from 'layouts/category-management/styled-components';
import { isUndefined } from 'lodash';

import { useTabItems } from './hooks';
import { placeholderStyles } from './styles';

export const CategoryTabs = ({ controlPanelHeight }) => {
  const { clientName, ...client } = useSelector(selectCategories);

  const items = useTabItems({ ...client });

  return (
    <StyledCategoryTabsWrapper>
      {isUndefined(client.clientId) ? (
        <ScreenPlaceholder
          cssRules={placeholderStyles(controlPanelHeight)}
          data-id="category-tabs-placeholder"
          description="Please select a Client."
        />
      ) : (
        <StyledCategoryTabs controlPanelHeight={controlPanelHeight}>
          <StyledCategoryTabsHeader>
            {`Category for ${clientName}`}
          </StyledCategoryTabsHeader>
          <Tabs
            key={client.clientId}
            items={items}
          />
        </StyledCategoryTabs>
      )}
    </StyledCategoryTabsWrapper>
  );
};

CategoryTabs.propTypes = {
  controlPanelHeight: PropTypes.number,
};

CategoryTabs.defaultProps = {
  controlPanelHeight: 0,
};

