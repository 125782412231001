import React from 'react';

import PropTypes from 'prop-types';

import Calendar from 'components/form-controls/form-calendar';

import './styles.scss';

const UploadFormCalendar = ({ fieldConfig, onChange, formProps }) => {
  const { errors, touched } = formProps;
  const { name, ...rest } = fieldConfig;
  return (
    <Calendar
      onChange={onChange}
      {...rest}
      name={name}
      isTouched={Boolean(touched[name] && errors[name])}
      error={errors ? errors[name] : null}
    />
  );
};
UploadFormCalendar.propTypes = {
  onChange: PropTypes.func.isRequired,
  fieldConfig: PropTypes.shape({
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    cssRules: PropTypes.string,
  }).isRequired,
  formProps: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
};

export default UploadFormCalendar;
