import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import Calendar from 'components/calendar';
import Controls from 'components/details-controls';
import DetailsTable from 'components/details-table';
import ExpansionPanel from 'components/expansion-panel';
import FilterSelect from 'components/filter-select';
import SingleRow from 'components/grid-template';
import InfoCard from 'components/info-card';
import Legend from 'components/legend';
import Loader from 'components/loader';

import { USERS_GROUPS } from 'core/auth/constants';
import { UNITS_TYPES } from 'core/constants';
import ScreenPlaceholder from 'elements/screen-placeholder';
import StyledWrapper from 'elements/styled-wrapper';
import Wrapper from 'elements/wrapper';

import moment from 'moment';
import { withRouter } from 'react-router';
import { getHasPermissions } from 'utils/auth';
import { updateState } from 'utils/helpers/history';
import { withLegend } from 'utils/legend';
import { autoScrollToTop } from 'utils/react';

import { ControlButton } from './control-button';

const { OFFICE_ADMIN } = USERS_GROUPS;

const getUnit = ({
  currentFilter,
  resetFilters,
  changeFilter,
  orderRules,
  changeOrder,
  isFetchingDetails,
  entityName,
  rowData,
  type,
  title,
  titleClassName,
  ...rest
}) => {
  const units = {
    [UNITS_TYPES.TABLE]: {
      component: DetailsTable,
      props: {
        orderRules,
        changeOrder,
        isFetchingDetails,
        entityName,
        titleClassName,
        title,
        ...rest,
      },
    },
    [UNITS_TYPES.INFO_CARD]: {
      component: InfoCard,
      props: {
        rowData,
        ...rest,
      },
    },
    [UNITS_TYPES.SINGLE_ROW]: {
      component: SingleRow,
      props: {
        rowData,
        ...rest,
      },
    },
  };
  return units[type];
};

const BillingProjectReports = ({
  location,
  userGroup,
  billingProjectReportsDataModel,
  billingProjectReportsDetails,
  generateBillingProjectReport: generateBillingProjectPdfReport,
  generateBillingProjectToExcelReport,
  setBillingProjectReportData,
  isFetchingTaskOrdersList,
  getTaskOrdersDetails,
  setProjectReportType,
  selectedReportDate,
  getTaskOrdersList,
  selectedDevCenter,
  getHolidaysList,
  currentTaskOrder,
  taskOrdersData,
  selectedDate,
  isFetching,
  reportType,
  devCenters,
  currentTO,
  changeFilter,
  resetFilters,
  changeOrder,
  entityName,
  orderRules,
  isChannelPartnerVersion,
}) => {
  autoScrollToTop(location);
  const [isChannelPartnerReport, setIsChannelPartnerReport] = useState(false);
  const isOfficeAdmin = getHasPermissions(userGroup, [OFFICE_ADMIN]);
  const { rules: { wrapperCssRules } } = billingProjectReportsDataModel;
  const [selectedTO, setSelectedTO] = useState(currentTO);
  const isShowChannelPartnerControl = selectedTO && isChannelPartnerVersion && reportType === 'TO';
  const displayedDate = useMemo(() => selectedReportDate.format('MMMM YYYY'), [selectedReportDate]);
  const filterOption = useCallback(
    ({
      label,
      value,
    }, term) => (
      `${value} ${label}`.toLocaleLowerCase().includes(term.toLocaleLowerCase())
    ),
    [],
  );
  const {
    list,
    map,
  } = taskOrdersData;
  const selectedTOData = selectedTO ? {
    label: isFetchingTaskOrdersList ? 'Loading TO Data' : map[selectedTO],
    value: selectedTO,
  } : null;
  const onTaskOrderChange = ({ value }) => {
    setSelectedTO(value);
  };
  const onSelectDeliveryCenter = (selectedDevcenters) => {
    const devcenterIds = selectedDevcenters.map((item) => item.value);

    setBillingProjectReportData({
      devcenters: selectedDevcenters,
      devcenterIds,
    });
  };
  const setDate = (date) => {
    setBillingProjectReportData({
      selectedDate: date,
    });
  };
  const {
    body,
    filters,
    selectCssRules,
    controlsCssRules,
    calendarCssRules,
    calendarItemCssRules,
    controlsWrapperCssRules,
    selectCurrentTOCssRules,
  } = billingProjectReportsDetails;
  const resetCurrentFilters = useCallback(
    (currentFilters) => {
      if (currentFilters != null) {
        if (Array.isArray(currentFilters)) {
          currentFilters.forEach((currentFilter) => resetFilters({ currentFilter }));
        } else {
          resetFilters({ currentFilter: currentFilters });
        }
      }

      setBillingProjectReportData({
        devcenters: [],
        devcenterIds: [],
      });
    },
    [resetFilters],
  );

  const controlButtons = useMemo(() => [
    {
      text: 'excel',
      onClick: () => generateBillingProjectToExcelReport({
        channelPartnerReport: isChannelPartnerReport,
      }),
      isShow: Boolean(reportType === 'TO' && selectedTO && isOfficeAdmin),
    },
    {
      text: 'pdf',
      onClick: () => generateBillingProjectPdfReport(
        {
          channelPartnerReport: isChannelPartnerReport,
        }
      ),
      isShow: Boolean(selectedTO),
    },
  ].map((properties) => (<ControlButton key={properties.text} {...properties} />)), [
    reportType,
    selectedTO,
    isOfficeAdmin,
    isChannelPartnerReport,
  ]);

  const handleChannelPartnerReport = useCallback(() => setIsChannelPartnerReport((prevState) => !prevState), []);

  useEffect(() => {
    getTaskOrdersList();
    getHolidaysList();
  }, []);

  useEffect(() => {
    setSelectedTO(currentTO);
    setProjectReportType({
      reportType,
      hasCurrentTo: !!currentTO,
    });
    if (selectedDate) {
      setBillingProjectReportData({ selectedDate });
    }
  }, [reportType]);

  useEffect(() => {
    if (selectedTO) {
      updateState({}, `/reports/billing-reports/project/${reportType}/${selectedTO}`);
      getTaskOrdersDetails(selectedTO);
      setIsChannelPartnerReport(false);
    }
  }, [selectedTO]);

  useEffect(() => () => {
    // We are going to reset filters on layout unmount.
    setIsChannelPartnerReport(false);
    body.forEach(({ currentFilter }) => resetCurrentFilters(currentFilter));
  }, []);

  return (
    <>
      <Controls
        controlsHeading={`Billing Report for ${reportType}`}
        cssRules={controlsCssRules}
      >
        {controlButtons}
        <StyledWrapper
          cssRules={controlsWrapperCssRules}
        >
          <Calendar
            withStepControls
            withMonthSelecting
            minDetails="year"
            onChange={setDate}
            stepControlsConfig={{
              step: 1,
              unitName: 'month',
            }}
            cssRules={calendarCssRules}
            value={selectedReportDate}
            popperProps={{
              placement: 'bottom-start',
            }}
          >
            <StyledWrapper
              cssRules={calendarItemCssRules}
            >
              {displayedDate}
            </StyledWrapper>
          </Calendar>

          <FilterSelect
            key={reportType}
            cssRules={selectCssRules}
            withChips={false}
            items={list}
            filterOption={filterOption}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={onTaskOrderChange}
            placeholder="Select TO"
            isFetchingData={isFetchingTaskOrdersList}
            placeholderLength="10.4rem"
            selected={selectedTOData}
            filters={filters}
          />

          {
            !!currentTaskOrder && reportType === 'DTO' && (
              <FilterSelect
                cssRules={selectCurrentTOCssRules}
                isMultiple
                withChips={false}
                items={devCenters.forSelect}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                onChange={onSelectDeliveryCenter}
                placeholder="All Delivery Centers"
                placeholderLength="16.9rem"
                selected={selectedDevCenter}
              />
            )
          }

        </StyledWrapper>
        <ControlButton
          type="checkbox"
          text="Channel partner report version"
          onClick={handleChannelPartnerReport}
          isShow={isShowChannelPartnerControl}
          isChecked={isChannelPartnerReport}
        />
      </Controls>
      <Wrapper
        isGrow
        cssRules={wrapperCssRules}
      >
        {
          currentTaskOrder ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {
                isFetching ?
                  <Loader /> :
                  body.map(({
                    type,
                    summaryConfig,
                    withUploading,
                    currentFilter,
                    ...rest
                  }, bodyItems) => {
                    const { content } = rest;

                    const renderUnit = () => {
                      const reset = () => resetCurrentFilters(currentFilter);
                      const onChange = (config) => changeFilter({ currentFilter, ...config });

                      const unit = getUnit({
                        isFetchingDetails: false,
                        changeFilter: onChange,
                        resetFilters: reset,
                        rowData: content,
                        currentFilter,
                        changeOrder,
                        orderRules,
                        entityName,
                        titleClassName: 'details-table__title--additional',
                        type,
                        ...rest,
                        ...content,
                      });

                      if (unit) {
                        const {
                          component,
                          props,
                        } = unit;
                        return React.createElement(
                          component,
                          props,
                        );
                      }
                      return null;
                    };
                    return (
                      <Fragment
                        key={bodyItems} // eslint-disable-line react/no-array-index-key
                      >
                        {
                          summaryConfig && !isFetching ? (
                            <ExpansionPanel summaryConfig={summaryConfig}>
                              {renderUnit()}
                            </ExpansionPanel>
                          ) :
                            renderUnit()
                        }
                        {withLegend(content) && <Legend title={content[0].legend} />}
                      </Fragment>
                    );
                  })
              }
            </>
          ) : (
            <ScreenPlaceholder
              title="No results yet"
              description="Please select a Task Order."
            />
          )
        }
      </Wrapper>
    </>
  );
};

BillingProjectReports.propTypes = {
  location: PropTypes.shape({}).isRequired,
  userGroup: PropTypes.string.isRequired,
  billingProjectReportsDataModel: PropTypes.shape({
    rules: PropTypes.shape({
      wrapperCssRules: PropTypes.string,
    }),
  }).isRequired,
  billingProjectReportsDetails: PropTypes.shape({
    body: PropTypes.arrayOf(PropTypes.shape({})),
    filters: PropTypes.arrayOf(PropTypes.shape({})),
    selectCssRules: PropTypes.string,
    controlsCssRules: PropTypes.string,
    calendarCssRules: PropTypes.string,
    calendarItemCssRules: PropTypes.string,
    controlsWrapperCssRules: PropTypes.string,
    selectCurrentTOCssRules: PropTypes.string,
  }).isRequired,
  generateBillingProjectReport: PropTypes.func.isRequired,
  generateBillingProjectToExcelReport: PropTypes.func.isRequired,
  setBillingProjectReportData: PropTypes.func.isRequired,
  isFetchingTaskOrdersList: PropTypes.bool.isRequired,
  getTaskOrdersDetails: PropTypes.func.isRequired,
  setProjectReportType: PropTypes.func.isRequired,
  selectedReportDate: PropTypes.instanceOf(moment).isRequired,
  getTaskOrdersList: PropTypes.func.isRequired,
  selectedDevCenter: PropTypes.arrayOf(PropTypes.shape({})),
  getHolidaysList: PropTypes.func.isRequired,
  currentTaskOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  taskOrdersData: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    map: PropTypes.shape({}),
  }).isRequired,
  selectedDate: PropTypes.instanceOf(moment).isRequired,
  isFetching: PropTypes.bool.isRequired,
  reportType: PropTypes.string.isRequired,
  devCenters: PropTypes.shape({
    forSelect: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  currentTO: PropTypes.string,
  changeFilter: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  changeOrder: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  orderRules: PropTypes.shape({}),
  isChannelPartnerVersion: PropTypes.bool,
};

BillingProjectReports.defaultProps = {
  selectedDevCenter: null,
  currentTaskOrder: null,
  orderRules: {},
  currentTO: undefined,
  isChannelPartnerVersion: false,
};

export default withRouter(BillingProjectReports);
