/* eslint-disable no-shadow */
import { colorSecondaryGrayDark, colorPrimary } from 'assets/styles/variables';
import { toNumber } from 'lodash';
import moment from 'moment';
import { object, array, number, date, mixed } from 'yup';

import {
  commissionRatesTableHeaderStyles,
  formTextInputStyles,
  formDescStyles,
} from './styles';

const filedArrayRootName = 'commissionRates';

const outputFormatting = ({ date }) => moment.isMoment(date) ? date.format('YYYY-MM-DD') : date;

export const commissionRatesTableHeaderModel = {
  rules: {
    css: {
      gridTemplateConfig: commissionRatesTableHeaderStyles,
    },
  },
  dataTemplate: [
    {
      columnName: 'Commission',
      cssRules: `{
        grid-area: commission;
        font-size: 1.2rem;
        color: #58595b;
        && .plain-text {
          text-align: center;
        }
      }`,
      withoutControls: true,
    },
    {
      columnName: 'Stage 1',
      cssRules: `
      {
        grid-area: stage1;
        font-size: 1.2rem;
        color: #58595b;
        && .plain-text {
          text-align: center;
        }
      }`,
      withoutControls: true,
    },
    {
      columnName: 'Stage 2',
      cssRules: `
      {
        grid-area: stage2;
        font-size: 1.2rem;
        color: #58595b;
        && .plain-text {
          text-align: center;
        }
      }`,
      withoutControls: true,
    },
    {
      columnName: 'Full Name',
      // valueGetter: ({ name }) => ({
      //   value: name,
      //   type: 'text',
      // }),
      cssRules: `
      {
        margin-left: 3.75rem;
        grid-area: fullName;
        &.grid-unit {
          justify-content: flex-start !important;
        }
      }
      `,
    },
    {
      columnName: 'Reports To',
      cssRules: `{
        grid-area: reportsTo;
        &.grid-unit--heading {
          justify-content: flex-start !important;
        }`,
    },
    {
      columnName: 'Base Salary',
      cssRules: `{
        grid-area: baseSalary;
      }`,
    },
    {
      columnName: '[%]',
      cssRules: `{
        grid-area: rate_1;
        border-top: 1px solid silver;
        border-left: 1px solid silver;
      }`,
    },
    {
      columnName: 'L2 [%]',
      cssRules: `{
        grid-area: rate_2;
        border-top: 1px solid silver;
      }`,
    },
    {
      columnName: 'L3 [%]',
      cssRules: `{
        grid-area: rate_3;
        border-top: 1px solid silver;
      }`,
    },
    {
      columnName: 'AM [%]',
      cssRules: `{
        grid-area: rate_am;
        border-top: 1px solid silver;
      }`,
    },
    {
      columnName: 'Days',
      cssRules: `{
        grid-area: stage_1_days;
        border-top: 1px solid silver;
        border-left: 1px solid silver;
        .plain-text p {
          text-align: center;
        }
      }`,
      withoutControls: true,
    },
    {
      columnName: '% of Rev.',
      cssRules: `{
        grid-area: stage_1_rate;
        border-top: 1px solid silver;
        .plain-text p {
          text-align: center;
        }
      }`,
      withoutControls: true,
    },
    {
      columnName: 'Days',
      cssRules: `{
        grid-area: stage_2_days;
        border-top: 1px solid silver;
        border-left: 1px solid silver;
        .plain-text p {
          text-align: center;
        }
      }`,
      withoutControls: true,
    },
    {
      columnName: '% of Rev.',
      cssRules: `{
        grid-area: stage_2_rate;
        border-top: 1px solid silver;
        border-right: 1px solid silver;
        .plain-text p {
          text-align: center;
        }
      }`,
      withoutControls: true,
    },
    {
      columnName: '',
      cssRules: `{
        grid-area: button;
        border-top: 1px solid silver;
        .plain-text p {
          text-align: center;
        }
      }`,
      withoutControls: true,
    },
  ],
};

export const commissionRatesManagementFormTemplate = () => [
  {
    stylesTemplate: {
      cssRules: `
      padding: 0;
      margin-top: 0;
      `,
    },
    formData: [
      {
        isFieldArray: true,
        name: filedArrayRootName,
        valueGetter: (detail, reportsToList, userId) => {
          const initialFieldArrayValues = detail;
          let isNewRecodAdded = false;
          initialFieldArrayValues.forEach((element) => {
            if (element.date === null) {
              isNewRecodAdded = true;
              // eslint-disable-next-line no-useless-return
              return;
            }
          });

          return {
            value: initialFieldArrayValues,
            lineGetter: ({ index, lineValues }) => {
              const {
                recordId,
                date,
                reportsToId,
                baseSal,
                commissions,
                stage1,
                stage2,
              } = lineValues || {};
              return {
                lineId: `${date}_${index}`,
                line: [
                  {
                    type: 'description',
                    fieldData: {
                      value: 'Valid From:',
                    },
                    data: 'Valid From:',
                    name: 'validFromDesc',
                    cssRules: `
                    box-sizing: border-box;
                    ${formDescStyles}
                    padding: 1.1rem 0 0 0;
                  `,
                  },
                  {
                    type: 'calendar',
                    label: 'Date',
                    name: `commissionRates[${index}].date`,
                    valueGetter: () => date || null,
                    cssRules: `
                  &.calendar {
                    min-width: unset;
                    color: rgba(000,000,000,0.87);
                    align-items: center !important;
                    font-weight: 400;
                    font-size: 1.3rem;
                    box-sizing: border-box;
                    padding: 0;
                    margin-top: 1.6rem;
                  }
                  `,
                    outputFormatting,
                  },
                  {
                    type: 'select',
                    label: 'Reports To',
                    name: `commissionRates[${index}].reportsToId`,
                    // isLocked: isCEO,
                    fieldData: {
                      selected: {
                        value: reportsToId,
                        label: reportsToList[reportsToId],
                      },
                      items: Object.keys(reportsToList).map((key) => {
                        if (key.toString() === userId.toString()) {
                          return {};
                        }
                        return {
                          value: key,
                          label: reportsToList[key],
                        };
                      }),
                    },
                    validationRules: {
                      isRequired: true,
                    },
                    cssRules: `
                    width:85%;
                    margin-right: 15%;
                    box-sizing: border-box;
                    padding-bottom: 0.9rem;
                    height: 4.1rem;
                    font-size: 1.2rem;
                    font-weight: 400;
                    .select-item,
                    .select-item__control {
                      max-height: 4.1rem !important;
                      min-height: 4.1rem !important;
                    }
                    .select-item__value-container {
                      padding: 1.3rem 0 0.2rem !important;
                    }
                    && .select-item--is-disabled {
                      &::after {
                        height: 3.6rem;
                        top: 0.4rem;
                        border-bottom: 0.1rem solid;
                      }

                      & .select-item__indicators {
                        display: flex;
                        padding-bottom: 0.5rem;
                      }
                    }
                  `,
                  },
                  {
                    type: 'text',
                    label: 'Salary',
                    name: `commissionRates[${index}].baseSal`,
                    fieldData: {
                      placeholder: baseSal ?
                        toNumber(baseSal).toFixed(2) :
                        '0.00',
                    },
                    validationRules: {
                      isRates: true,
                      decimalScale: 2,
                      isNumeric: true,
                      isRequired: true,
                      maxValue: 9999.99,
                    },
                    cssRules: `
                    width: 50%;
                    margin-left: 20%;
                    box-sizing: border-box;
                    max-height: 4.1rem;
                    text-align: right;
                    font-size: 1.2rem;
                    .text-input__input-field {
                      margin-top: 0;
                      height: 4.1rem;
                      padding: 1.4rem 0 0.2em;
                    }
                  `,
                  },
                  {
                    type: 'text',
                    label: 'Commission commDirect',
                    name: `commissionRates[${index}].commissions.commDirect`,
                    fieldData: {
                      placeholder: commissions.commDirect ?
                        toNumber(commissions.commDirect).toFixed(1) :
                        '0.0',
                    },
                    validationRules: {
                      isRates: true,
                      decimalScale: 1,
                      isNumeric: true,
                      isRequired: true,
                      maxValue: 999.9,
                    },
                    cssRules: `
                    border-left: 1px solid silver;
                    ${formTextInputStyles}
                  `,
                  },
                  {
                    type: 'description',
                    data: '%',
                    cssRules: formDescStyles,
                  },
                  {
                    type: 'text',
                    label: 'Commission commDirect1',
                    name: `commissionRates[${index}].commissions.commDirect1`,
                    fieldData: {
                      placeholder: commissions.commDirect1 ?
                        toNumber(commissions.commDirect1).toFixed(1) :
                        '0.0',
                    },
                    validationRules: {
                      isRates: true,
                      decimalScale: 1,
                      isNumeric: true,
                      isRequired: true,
                      maxValue: 999.9,
                    },
                    cssRules: formTextInputStyles,
                  },
                  {
                    type: 'description',
                    data: '%',
                    cssRules: formDescStyles,
                  },
                  {
                    type: 'text',
                    label: 'Commission commDirect2',
                    name: `commissionRates[${index}].commissions.commDirect2`,
                    fieldData: {
                      placeholder: commissions.commDirect2 ?
                        toNumber(commissions.commDirect2).toFixed(1) :
                        '0.0',
                    },
                    validationRules: {
                      isRates: true,
                      decimalScale: 1,
                      isNumeric: true,
                      isRequired: true,
                      maxValue: 999.9,
                    },
                    cssRules: formTextInputStyles,
                  },
                  {
                    type: 'description',
                    data: '%',
                    cssRules: formDescStyles,
                  },
                  {
                    type: 'text',
                    label: 'Commission AM',
                    name: `commissionRates[${index}].commissions.commAm`,
                    fieldData: {
                      placeholder: commissions.commAm ?
                        toNumber(commissions.commAm).toFixed(1) :
                        '0.0',
                    },
                    validationRules: {
                      isRates: true,
                      decimalScale: 1,
                      isNumeric: true,
                      isRequired: true,
                      maxValue: 999.9,
                    },
                    cssRules: formTextInputStyles,
                  },
                  {
                    type: 'description',
                    data: '%',
                    cssRules: formDescStyles,
                  },
                  {
                    type: 'text',
                    label: 'Stage 1 days',
                    name: `commissionRates[${index}].stage1.stage1Days`,
                    fieldData: {
                      placeholder: stage1.stage1Days ? stage1.stage1Days : '0',
                    },
                    validationRules: {
                      isNumeric: true,
                      isRequired: true,
                    },
                    cssRules: `
                    border-left: 1px solid silver;
                    ${formTextInputStyles}
                  `,
                  },
                  {
                    type: 'text',
                    label: 'Stage 1 perc',
                    name: `commissionRates[${index}].stage1.stage1Perc`,
                    fieldData: {
                      placeholder: stage1.stage1Perc ?
                        toNumber(stage1.stage1Perc).toFixed(1) :
                        '0.0',
                    },
                    validationRules: {
                      isRates: true,
                      decimalScale: 1,
                      isNumeric: true,
                      isRequired: true,
                      maxValue: 999.9,
                    },
                    cssRules: formTextInputStyles,
                  },
                  {
                    type: 'description',
                    data: '%',
                    cssRules: formDescStyles,
                  },
                  {
                    type: 'text',
                    label: 'Stage 2 days',
                    name: `commissionRates[${index}].stage2.stage2Days`,
                    fieldData: {
                      placeholder: stage2.stage2Days ? stage2.stage2Days : '0',
                    },
                    validationRules: {
                      isRequired: true,
                    },
                    cssRules: `
                    border-left: 1px solid silver;
                    ${formTextInputStyles}
                  `,
                  },
                  {
                    type: 'text',
                    label: 'Stage 2 perc',
                    name: `commissionRates[${index}].stage2.stage2Perc`,
                    fieldData: {
                      placeholder: stage2.stage2Perc ?
                        toNumber(stage2.stage2Perc).toFixed(1) :
                        '0.0',
                    },
                    validationRules: {
                      isRates: true,
                      decimalScale: 1,
                      isNumeric: true,
                      isRequired: true,
                      maxValue: 999.9,
                    },
                    cssRules: formTextInputStyles,
                  },
                  {
                    type: 'description',
                    data: '%',
                    cssRules: `
                  border-right: 1px solid silver;
                  ${formDescStyles}`,
                  },
                  {
                    type: 'actionButton',
                    data: 'Delete',
                    actionName: 'openModal',
                    actionArguments: {
                      currentModal: 'CONFIRM_ACTION_MODAL',
                      content: [
                        {
                          type: 'title',
                          data: `Delete this position record start date ${moment(
                            date
                          ).format('L')}?`,
                        },
                        {
                          type: 'description',
                          data: 'This file will be permanently deleted and cannot be restored.',
                        },
                      ],
                      acceptActionName: 'removeCommissionRecord',
                      acceptActionTitle: 'Delete',
                      acceptActionArguments: {
                        recordId,
                        userId,
                      },
                    },
                    isHidden: detail.length <= 1,
                    cssRules: `
                  {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    justify-content: center;
                    align-items: center;
                    margin-top: 1.2rem;
                    &.action-button {
                      font-size: 1.2rem;
                    }
                  }
                  `,
                  },
                ],
                stylesTemplate: {
                  cssRules: `
                margin:0;
                padding: 0 0 0 5.6rem;
                display: grid;
                grid-auto-rows: minmax(4.4rem,4.4rem);
                grid-column-gap: 0;
                grid-row-gap: 0;
                grid-template-columns:
                minmax(auto,20fr) minmax(auto,56fr)  minmax(auto,32fr)  minmax(auto,31.5fr)   minmax(auto,13fr)  minmax(auto,11fr)  minmax(auto,12fr)  minmax(auto,11fr)  minmax(auto,11fr) minmax(auto,12fr) minmax(auto,11fr) minmax(auto,10fr) minmax(auto,16fr) minmax(auto,25.5fr) minmax(auto,11fr) minmax(auto,15fr) minmax(auto,24.5fr) minmax(auto,9.6fr) minmax(auto,27.9fr);
                &:last-of-type {
                  & .calendar__date-wrapper {
                    color: ${colorPrimary};
                  }
                };
                `,
                },
              };
            },
            footerConfig: {
              stylesTemplate: {
                padding: '0',
              },
              data: [
                {
                  type: 'actionButton',
                  data: 'ADD',
                  withBody: true,
                  disabled: isNewRecodAdded,
                  actionName: 'createCommissionRecord',
                  actionArguments: { userId },
                  cssRules: `
                && {
                  height: 2.8rem;
                  width: 18rem;
                  color: ${colorSecondaryGrayDark};
                  line-height: 1.6rem;
                  letter-spacing: 0.0125rem;
                  text-align: center;
                  font-size: 1.4rem;
                  font-weight: 500;
                  border: 1px solid ${colorSecondaryGrayDark};
                  margin-left: 5.6rem;
                  margin-top: 1.2rem;
                }
                &:hover {
                  border: 1px solid #d61f26;
                  color: #fff;
                }
                `,
                },
              ],
            },
          };
        },
        validationRules: {
          schemaGetter: ({ commissionRates }) => array().of(
            object().shape({
              date: date()
                .nullable()
              // eslint-disable-next-line consistent-return
                .max('1979-01-01', ({ path, originalValue }) => {
                  const indexStart = path.indexOf('[') + 1;
                  const indexEnd = path.indexOf(']');
                  const recordIndex = parseInt(
                    path.slice(indexStart, indexEnd),
                    10
                  );
                  if (
                    commissionRates &&
                      commissionRates[recordIndex].date !== originalValue
                  ) {
                    for (let i = 0; i < commissionRates.length; i++) {
                      if (
                        i !== recordIndex &&
                          commissionRates[i].date === originalValue
                      ) {
                        return 'The record with the same start date has already existed.';
                      }
                      if (
                        i !== 0 &&
                          moment(commissionRates[i - 1].date).isAfter(
                            originalValue
                          )
                      ) {
                        return 'The start date of the record should be later than the date of the previous record';
                      }
                    }
                  }
                })
                .required('Required field'),
              reportsToId: mixed().nullable(),
              baseSal: number().nullable().required('Required field'),
              commissions: object().shape({
                commDirect: number().nullable().required('Required field'),
                commDirect1: number().nullable().required('Required field'),
                commDirect2: number().nullable().required('Required field'),
                commAm: number().nullable().required('Required field'),
              }),
              stage1: object().shape({
                stage1Days: number().nullable().required('Required field'),
                stage1Perc: number().nullable().required('Required field'),
              }),
              stage2: object().shape({
                stage2Days: number().nullable().required('Required field'),
                stage2Perc: number().nullable().required('Required field'),
              }),
            })
          ),
        },
      },
    ],
  },
];
