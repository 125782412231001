import { STORE_KEYS, CLIENT_STATUS } from 'core/configurations/category-management/constants';
import { CATEGORY_TYPES } from 'layouts/category-management/components/category-tabs/dictionaries';

export const getFilteredValues = (data, activeFilters) => {
  if (!activeFilters.length) return data;

  return data.filter((item) => activeFilters.every((filter) => {
    const { key, value } = filter;

    if (key === STORE_KEYS.IS_ACTIVE_CLIENT) {
      return item[key] === CLIENT_STATUS.ACTIVE;
    }

    const regexString = `.*${value}.*`;

    return new RegExp(regexString, 'i').test(item[key]);
  }));
};

export const sortByActivity = (current, next) => next.isActive - current.isActive;

export const getSortedCategoriesByActivity = (client) => client.clientId ?
  { ...client,
    availableClientCategories: client[CATEGORY_TYPES.CLIENT].sort(sortByActivity),
    availableInternalCategories: client[CATEGORY_TYPES.INTERNAL].sort(sortByActivity) } :
  {
    ...client,
    availableInternalCategories: client[CATEGORY_TYPES.INTERNAL].sort(sortByActivity),
    availableOooCategories: client[CATEGORY_TYPES.HIQO_OOO].sort(sortByActivity),
  };

export const getTypeCategoryId = (categoryType) => categoryType === CATEGORY_TYPES.CLIENT ?
  'clientCategoryId' :
  'internalCategoryId';

const getCategoryWithClientId = ({ category, clientId, type }) => {
  const { categoryId, ...categoryData } = category;

  if (!clientId && !categoryId) {
    categoryData.oooCategory = type === CATEGORY_TYPES.HIQO_OOO;
    categoryData.hiqoInternal = type === CATEGORY_TYPES.INTERNAL;
  }

  if (categoryId) {
    return { ...categoryData, categoryId };
  }

  return { ...categoryData, clientId };
};

export const getCategoriesList = (categories, { clientId, type }) => Boolean(categories.length) &&
  categories.map((category) => getCategoryWithClientId({ category, clientId, type }));

export const getHiqoCategoriesNames = (client) => [
  ...client[CATEGORY_TYPES.INTERNAL].map((category) => category.name.toLowerCase()),
  ...client[CATEGORY_TYPES.HIQO_OOO].map((category) => category.name.toLowerCase()),
];
