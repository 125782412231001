/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  FilterSelect,
  Search,
} from 'components/form-with-list/components/filters/components';
import { FILTER_TYPE } from 'components/form-with-list/components/filters/constants';
import { EMAILED_FILTER } from 'core/configurations/constants';
import { emailTemplateNotificationsActions } from 'core/configurations/email-template-notifications/actions';
import {
  selectBillingAgents,
  selectNotificationsType,
  selectNotificationsStatuses,
  selectEmailTemplates,
} from 'core/configurations/email-template-notifications/selectors';

const changeAction = emailTemplateNotificationsActions.changeNotificationsFilter;

export const useFilterItems = () => {
  const billingAgents = useSelector(selectBillingAgents);
  const emailTemplates = useSelector(selectEmailTemplates);
  const notificationsType = useSelector(selectNotificationsType);
  const statuses = useSelector(selectNotificationsStatuses);

  const filterItems = useMemo(() => [
    {
      type: FILTER_TYPE.SEARCH,
      name: 'name',
      render: ({ name, isActiveFilter }) => (
        <Search
          key={name}
          name={name}
          changeFilter={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SEARCH,
      name: 'country',
      render: ({ name, isActiveFilter }) => (
        <Search
          key={name}
          name={name}
          changeFilter={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SELECT,
      name: 'billingAgent',
      accessor: 'shortName',
      id: 'billingAgentId',
      render: ({ name, accessor, id, isActiveFilter }) => (
        <FilterSelect
          data={billingAgents}
          name={name}
          accessor={accessor}
          id={id}
          changeAction={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SELECT,
      name: 'emailTemplateId',
      accessor: 'name',
      id: 'emailTemplateId',
      render: ({ name, accessor, id, isActiveFilter }) => (
        <FilterSelect
          data={emailTemplates}
          name={name}
          accessor={accessor}
          id={id}
          changeAction={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SELECT,
      name: 'notification',
      accessor: 'notificationTitle',
      id: 'notificationId',
      render: ({ name, accessor, id, isActiveFilter }) => (
        <FilterSelect
          data={notificationsType}
          name={name}
          accessor={accessor}
          id={id}
          changeAction={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SEARCH,
      name: 'salutation',
      id: 'salutation',
      render: ({ name, isActiveFilter }) => (
        <Search
          key={name}
          name={name}
          changeFilter={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SEARCH,
      name: 'emails',
      id: 'emails',
      delay: 500,
      render: ({ name, isActiveFilter }) => (
        <Search
          key={name}
          name={name}
          changeFilter={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.CHECKBOX,
      name: 'processed',
      render: () => (
        <div />
      ),
    },
    {
      type: FILTER_TYPE.SELECT,
      name: 'emailed',
      accessor: 'emailed',
      id: 'emailedId',
      render: ({ name, accessor, id, isActiveFilter }) => (
        <FilterSelect
          data={EMAILED_FILTER}
          name={name}
          accessor={accessor}
          id={id}
          changeAction={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SELECT,
      name: 'status',
      accessor: 'status',
      id: 'status',
      render: ({ name, accessor, id, isActiveFilter }) => (
        <FilterSelect
          data={statuses}
          name={name}
          accessor={accessor}
          id={id}
          changeAction={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
  ], [
    billingAgents,
    statuses,
    notificationsType,
    emailTemplates,
  ]);

  return {
    filterItems,
  };
};
