import gql from 'graphql-tag';

export const queryConfig = {
  deleteDevstaff: gql`
    mutation deleteDevstaff($devstaffId: Int) {
      deleteDevstaff(devstaffId: $devstaffId) {
        ok
      }
    }
  `,
  getSingleProjectData: (id) => gql`{
    updatedProject: node(id: "InternalCategoryForPl: ${id}") {
        ... on InternalCategoryForPl  {
          internalCategoryId
          internalCategoryKey
          internalCategoryName
          internalCategoryLeadId
          internalCategoryLeadName
          internalCategoryLeadFullname
          isPlAssigned
          isPlAssignmentValid
        }
      }
    }
  `,
  getProjectsList: gql`{
    projectsList: allInternalCategoriesForPl {
      internalCategoryId
      internalCategoryKey
      internalCategoryName
      internalCategoryLeadId
      internalCategoryLeadName
      internalCategoryLeadFullname
      isPlAssigned
      isPlAssignmentValid
    }
    plList: devstaffv2List {
      plLevelByDate {
        pll
        date
      }
      name
      isNew
      isIntern
      dateLast
      fullname
      isOnLeave
      allowbill
      devstaffId
      isDelivery
      isDismissed
    }
  }`,
  getEmployeesList: gql`{
    employeesList: devstaffv2List {
      devstaffId
      devcenterShortname
      name
      fullname
      fullnameCyrillic
      seniority
      isDelivery
      dateHired
      internshipStartDate
      dateLast
      salUsd
      title
      salPln
      salRur
      status
      vacationDaysTotal
      vacationDaysSpent
      vacationDaysLeft
      isIntern
      isDismissed
      isOnLeave
      isNew
      workBookLastAction {
        actionType
      }
      ptoRequestLastAction {
        actionType
      }
    } }`,

  getEmployeeDetails: (employeeId, hasPermissionsPlAssignment) => {
    const plAssignments = hasPermissionsPlAssignment ? `plAssignments {
            internalCategoryKey
            internalCategoryName
            dateFrom
            pll
          }` : '';
    return gql`{
      node(id: "Devstaffv2:${employeeId}") {
        ... on Devstaffv2 {
          fullname
          name
          devstaffId
          devcenterShortname
          dateHired
          internshipStartDate
          dateLast
          fullnameCyrillic
          email
          isIntern
          isOnLeave
          isDismissed
          isDelivery
          isNew
          allowbill
          englishLevel
          ${plAssignments}
          vacationsByYear {
            vacationByYearId
            devstaffId
            year
            vacationDays {
              total
              remaining
              requested
              planned
              spent
            }
            vacationActions {
              vacationManagementId
              days
              actionType
              targetYear
              remark
              dateCreated
            }
          }
          ptoRequestLastAction {
            actionType
            actionDate
          }
          workBookLastAction {
            actionType
            actionDate
          }
          onboardingHistory {
            historyId
            dateStart
            endDate
            isDismiss
            isRehire
            onLeave
            hasPassed
          }
          devstaffTechs  {
            technologyId
            title
          }
          workBookRecords {
            recordId
            validFrom
            devcenter
            title
            seniority
            seniorityInt
            plLevel
            hrsDay
            probation
            salUsd
            salPln
            salRur
            approvedCeo
            approvedDd
            approvedHrm
            rejectedCeo
            rejectedDd
            approvedDdDate
            approvedCeoDate
            rejectedDdDate
            rejectedCeoDate
            remarks
            templateOwnerId
            endOfProbation
          }
          ptoRequestRecords {
            recordId
            cancelledDate
            requestDate
            description
            fromDate
            throughDate
            hrs
            workingDays
            isUnpaid
            remark
            isApproved
            isRejected
            approvedDate
            rejectedDate
            isPlApprovalInProgress
            isCancelled
            templateOwnerId
            lastModifiedDate
            plApprovals {
              internalCategoryKey
              internalCategoryName
              plName
              isApproved
              plApprovalId
            }
          }
          attachments {
            attachmentId
          }
          contracts {
            contractRecordId
          }
          comments {
            commentId
          }
          contacts {
            contactId: id
          }
          technicalMentor {
            technicalMentorRecordId
          }
        }
      }
    }`;
  },

  getResourceCommonDetails: (employeeId) => {
    const devCenters = employeeId ? 'devcentersAll' : 'devcenters';
    const query = `{
      technologies {
        id
        technologyId
        title
      }
      titlesFromDevstaffCompensation {
        value: title
        label: title
      }
      allInternalCategoriesForPl {
        internalCategoryKey
        internalCategoryName
        internalCategoryLeadName
      }
      holidays {
        holidayId
        devcenter
        date
        description
      }
      ${devCenters} {
        devcenterId
        name
        shortname
        active
      }
      devstaffList {
        value: devstaffId
        label: fullname
      }
    }`;
    return gql(query);
  },

  createEmployee: () => gql`mutation createDevstaffv2($fields: CreateDevstaffv2Input!) {
    createDevstaffv2(fields: $fields)
    {
      devstaff {
        devstaffId
      }
    }
  }`,

  updateEmployeeDetails: (devstaffId) => gql`mutation updateDevstaffv2($fields: UpdateDevstaffv2Input!) {
    updateDevstaffv2(devstaffId: ${devstaffId}, fields: $fields)
        {
          ok
          devstaff {
            isDismissed
            isOnLeave
            dateHired
            dateLast
            internshipStartDate
            englishLevel
            devstaffTechs  {
              technologyId
              title
            }
            onboardingHistory {
              historyId
              dateStart
              endDate
              isDismiss
              isRehire
              onLeave
              hasPassed
            }
          }
        }
    }`,

  createOnboardingHistory: (devstaffId) => `mutation createOnboardingHistory($fields: OnboardingHistoryInput!) {
    createOnboardingHistory(devstaffId: ${devstaffId}, fields: $fields)
        {
          ok
        }
    }`,

  updateOnboardingHistory: (historyId) => `mutation updateOnboardingHistory($fields: OnboardingHistoryInput!) {
    updateOnboardingHistory(historyId: ${historyId}, fields: $fields)
      {
        ok
      }
    }`,

  deleteOnboardingHistory: (historyId) => `mutation {
    deleteOnboardingHistory(historyId: ${historyId})
      {
        ok
      }
    }`,

  deleteWorkbookRecord: ({
    devstaffId,
    recordId,
  }) => `mutation {
    deleteWorkBookRecord(devstaffId: ${devstaffId}, recordId: ${recordId})
      {
        ok
      }
    }`,

  createWorkbookRecord: (devstaffId) => `mutation createWorkBookRecord($fields: CreateWorkBookRecordInput!) {
    createWorkBookRecord(devstaffId: ${devstaffId}, fields: $fields)
        {
          ok
          workBookRecord {
            recordId
            validFrom
            devcenter
            title
            seniority
            seniorityInt
            plLevel
            hrsDay
            probation
            salUsd
            salPln
            salRur
            approvedCeo
            approvedDd
            approvedDdDate
            approvedCeoDate
            remarks
            templateOwnerId
            endOfProbation
          }
        }
    }`,

  updateWorkbookRecord: (recordId) => `mutation updateWorkBookRecord($fields: WorkBookRecordInput!) {
    updateWorkBookRecord(recordId: ${recordId}, fields: $fields)
      {
        ok
        workBookRecord {
          recordId
          validFrom
          devcenter
          title
          seniority
          seniorityInt
          plLevel
          hrsDay
          probation
          salUsd
          salPln
          salRur
          approvedCeo
          approvedDd
          approvedHrm
          rejectedCeo
          rejectedDd
          approvedCeoDate
          approvedDdDate
          rejectedDdDate
          rejectedCeoDate
          remarks
          templateOwnerId
          endOfProbation
        }
      }
    }`,

  checkUpdatesEmployeesDetails: (employeeId) => (`{
     node(id: "Devstaffv2:${employeeId}") {
       ... on Devstaffv2 {
         isIntern
         isOnLeave
         isDismissed
         isNew
         vacationsByYear {
          vacationByYearId
          devstaffId
          year
          vacationActions {
            vacationManagementId
            days
            actionType
            targetYear
            remark
            dateCreated
          }
          vacationDays {
            total
            remaining
            requested
            planned
            spent
          }
         }
         workBookLastAction {
           actionType
           actionDate
         }
         ptoRequestLastAction {
          actionType
          actionDate
         }
       }
     }
   }`),

  deletePtoRequestRecord: ({
    devstaffId,
    recordId,
  }) => `mutation {
    deletePtoRequestRecord(devstaffId: ${devstaffId}, recordId: ${recordId})
      {
        ok
      }
    }`,

  createPtoRequestRecord: (devstaffId) => `mutation createPtoRequestRecord($fields: CreatePtoRequestRecordInput!) {
    createPtoRequestRecord(devstaffId: ${devstaffId}, fields: $fields)
        {
          ok
          ptoRequestRecord {
            recordId
            requestDate
            description
            fromDate
            throughDate
            hrs
            workingDays
            isUnpaid
            remark
            isApproved
            isRejected
            approvedDate
            rejectedDate
            isPlApprovalInProgress
            isCancelled
            cancelledDate
            templateOwnerId
            lastModifiedDate
            plApprovals {
              internalCategoryKey
              internalCategoryName
              plName
              isApproved
              plApprovalId
            }
          }
        }
    }`,

  updatePtoRequestRecord: (recordId) => gql`mutation updatePtoRequestRecord($fields: UpdatePtoRequestRecordInput!) {
    updatePtoRequestRecord(recordId: ${recordId}, fields: $fields)
      {
        ok
        ptoRequestRecord {
          recordId
          requestDate
          description
          fromDate
          throughDate
          hrs
          workingDays
          isUnpaid
          remark
          isApproved
          isRejected
          approvedDate
          rejectedDate
          isPlApprovalInProgress
          isCancelled
          cancelledDate
          templateOwnerId
          lastModifiedDate
          plApprovals {
            internalCategoryKey
            internalCategoryName
            plName
            isApproved
            plApprovalId
          }
        }
      }
    }`,

  generatePlApprovals: (devstaffId, recordId) => `mutation {
    generatePlApprovals(devstaffId: ${devstaffId}, recordId: ${recordId})
      {
        ok
        plApprovals {
          internalCategoryKey
          internalCategoryName
          plName
          isApproved
          plApprovalId
        }
      }
    }`,

  deleteVacationManagementRecord: ({
    vacationManagementId,
    devstaffId,
  }) => `mutation {
    deleteVacationManagementRecord(devstaffId: ${devstaffId}, vacationManagementId: ${vacationManagementId})
      {
        ok
      }
    }`,
  getAssignedPLs: gql`
    query projectLeads($projectKey: String ) {
      projectLeads(projectKey: $projectKey) {
        recordId: id
        devstaffId
        plLevel
        storedPercentage: percentage
        isPercentageCustomized
        dateFrom
        delegates {
          id
          plLevel
          devstaffId
          percentage
        }
      }
    }
  `,
  assignPlToProject: gql`mutation assignPlToProject($fields: AssignPLToProjectInput!) {
    assignPlToProject(fields: $fields) {
      ok
      plaId: id
    }
  }`,
  addDelegateToProject: gql`mutation addDelegate($fields: AddDelegateInput!) {
    addDelegate(fields: $fields) {
      ok
    }
  }`,
  checkDevstaffEmail: gql`
    query checkDevstaffEmail($email: String!) {
      checkDevstaffEmail(email: $email){
        isFree
      }
    }
  `,
  deletePLARecord: ({ recordId, mutationName }) => gql`
  mutation ${mutationName} {
    ${mutationName}(recordId: ${recordId}) {
      ok
    }
  }
  `,
  generateResourceBillingReport: gql`
    mutation generateResourceBillingReport($devstaffId: Int!) {
      generateResourceBillingReport(devstaffId: $devstaffId) {
        ok
        url
      }
    }
  `,
};
