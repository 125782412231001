import { colorSecondaryGrayLight } from 'assets/styles/variables';

import {
  calculateTotal,
  summaryTableDataGetter,
} from 'core/billing-reports/utils';
import { UNITS_TYPES } from 'core/constants';
import hash from 'hash-sum';
import { getGridTemplate, getOvertimeGridTemplate } from 'layouts/billing-project-reports/model/utils';
import { NOT_IN_TO_TYPES } from 'layouts/billing-project-reports/model/utils/constants';

export default ({
  label,
  isHRM,
  groupId,
  dataKey,
  subTitle,
  tableName,
  isPtmReport,
  projectType,
  dataTemplate,
  requiredTotals,
  preventScrolling,
  hasRequiredTotals,
  isDTOReport,
}) => {
  const isOvertime = tableName.includes('overtime');
  const isNotInTO = projectType === NOT_IN_TO_TYPES.PTM ||
    projectType === NOT_IN_TO_TYPES.OVERTIME;

  return ({
    type: UNITS_TYPES.TABLE,
    summaryTableDataGetter:
      hasRequiredTotals &&
      ((data) => summaryTableDataGetter({
        project: projectType,
        requiredTotals,
        ...data,
      })),
    useAccumulator:
      hasRequiredTotals &&
      ((data) => calculateTotal({
        label,
        groupId,
        requiredTotals,
        isDefaultExpanded: true,
        summaryWrapperCss: `
        display: grid;
        gap: 0 0.3rem;
        padding: 0;
        && .expansion-summary__item {
          width: 100%;
          margin-right: 0;
        }
        && .expansion-summary__item:last-child {
          box-sizing: border-box;
          padding-right: 1rem;
          ${isNotInTO && !isHRM ?
          'justify-content: flex-start;' :
          'justify-content: flex-end;'}
        }
        && .expansion-summary__control {
          padding-left: 16px;
        }

        && .expansion-summary__item-value--empty {
          text-align: left;
        }
        ${isOvertime ?
          getOvertimeGridTemplate(projectType, { isHRM, isDTOReport }) :
          getGridTemplate(projectType, { isHRM, isDTOReport })}
        `,
        ...data,
      })),
    content: [
      {
        title: subTitle,
        dataKey,
        tableName,
        preventScrolling,
        rowStatusGetter: (row) => {
          const id = hash(row);

          const { isDevstaffNotInTo, isDevstaffWithoutRates, isGrandTotal } = row;

          return {
            id,
            hasWarning:
              (!isHRM || isPtmReport) &&
              (isDevstaffNotInTo || isDevstaffWithoutRates),
            stylesTemplate: {
              rowStyles: isGrandTotal ?
                `&& {
              border-top: 1px solid ${
    isGrandTotal ? colorSecondaryGrayLight : 'transparent'
    };
              border-bottom: 1px solid 'transparent'};
            }` :
                '',
            },
          };
        },
        rules: {
          css: {
            gridTemplateConfig: isOvertime ?
              getOvertimeGridTemplate(projectType, { isHRM, isDTOReport }) :
              getGridTemplate(projectType, { isHRM, isDTOReport }),
          },
        },
        dataTemplate,
      },
    ],
  });
};
