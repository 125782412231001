import { useMemo } from 'react';

import { useSelector } from 'react-redux';

export const useFilter = ({ items, type = 'search', selector }) => {
  const { filter, isFiltering } = useSelector(selector);
  const { query, storeKey, isActive } = filter[type];

  const filteredItems = useMemo(() => items.filter((item) => {
    const itemValue = item[storeKey];
    return query ? itemValue.toLowerCase().includes(query.toLowerCase()) : items;
  }), [query, storeKey, items]);

  return {
    filteredItems,
    isActive,
    isFiltering,
  };
};
