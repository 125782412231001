import authLinks from './auth-links';
import commonLinks from './common-links';
import development from './dev';
import local from './local';
import production from './production';
import staging from './staging';

const configs = {
  development,
  staging,
  local,
  production,
};

const key = process.env.REACT_APP_ENVIRONMENT || 'staging';

const config = configs[key];

export {
  config,
  commonLinks,
  authLinks,
};
