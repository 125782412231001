import { onlyLatinLettersRegex, phoneRegex, validationMessages } from 'forms/constants';
import { emailRegex } from 'utils/email';
import * as yup from 'yup';

const isPhoneRequired = (role) => ['ceo', 'sales', 'cso'].includes(role);

export const validationSchema = yup.object({
  fullname: yup.string()
    .required(validationMessages.required)
    .matches(onlyLatinLettersRegex, validationMessages.onlyLatinLetters),
  email: yup.string()
    .required(validationMessages.required)
    .matches(emailRegex, validationMessages.email),
  title: yup.string()
    .required(validationMessages.required)
    .matches(onlyLatinLettersRegex, validationMessages.onlyLatinLetters),
  role: yup.object()
    .test({
      name: 'Selected option is required.',
      message: validationMessages.required,
      test(option) {
        return Boolean(option.label);
      },
    }),
  billingAgent: yup.object()
    .test({
      name: 'Selected option is required.',
      message: validationMessages.required,
      test(option) {
        return Boolean(option.value);
      },
    }),
  phone: yup.string()
    .test({
      name: 'Required only when Role selection is CEO/Head of Sales/Sales.',
      exclusive: true,
      message: validationMessages.required,
      test(phone) {
        return !isPhoneRequired(this.parent.role.label) || !!phone;
      },
    })
    .matches(phoneRegex, validationMessages.phone),
});
