import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from 'components/buttons';
import Divider from 'components/divider';
import Filter from 'components/filter';

import Controls from 'components/list-controls';
import ToastContainer from 'components/toast';
import { replace } from 'connected-react-router';
import { confCategoryManagement } from 'core/auth/guaranteedAccessRoles';
import { commonActions } from 'core/common/actions';
import { clientsActions } from 'core/configurations/category-management/actions';
import { ENTITY_NAME, FILTER_TYPES, STORE_KEYS } from 'core/configurations/category-management/constants';
import { selectClientTableFilters } from 'core/configurations/category-management/selectors';
import Wrapper from 'elements/wrapper';
import { categoryManagementModel } from 'layouts/category-management/model';

import { CategoryTabs, ClientsTable } from './components';
import { useElementHeight } from './hooks';

const { changeFilter, resetFilters, resetClient } = clientsActions;
const { setEntityName } = commonActions;
const { rules: { css } } = categoryManagementModel();

export const CategoryManagement = () => {
  const title = 'Category Management';
  const { filter, hasActiveFilters, isOnlyActiveClients } = useSelector(selectClientTableFilters);
  const dispatch = useDispatch();
  const [ref, height] = useElementHeight();
  const showOnlyActiveClients = useCallback((isActive) => dispatch(changeFilter({
    storeKey: STORE_KEYS.IS_ACTIVE_CLIENT,
    type: 'toggle',
    isActive: !isActive,
    selected: !isActive,
    currentFilter: FILTER_TYPES.CLIENTS_FILTER,
  })), []);

  const changeTableFilter = useCallback((filterPayload) => dispatch(changeFilter({ ...filterPayload, currentFilter: FILTER_TYPES.CLIENTS_FILTER })), []);
  const setCategoryManagementEntityName = () => dispatch(setEntityName(ENTITY_NAME));
  const resetTableFilters = () => dispatch(resetFilters());

  useEffect(() => {
    dispatch(replace(`/${confCategoryManagement.route}`));
    setCategoryManagementEntityName();
    return () => {
      // We are going to reset filters on layout unmount.
      dispatch(resetClient());
      resetTableFilters();
    };
  }, []);

  return (
    <>
      <ToastContainer containerId={ENTITY_NAME} />
      <Controls
        data-id="category-management-controls"
        title={title}
        hasActiveFilters={hasActiveFilters}
        resetFilters={resetTableFilters}
        cssRules={css.controls}
        ref={ref}
      >
        <Checkbox
          title="Display only active clients"
          onClick={showOnlyActiveClients}
          isActive={isOnlyActiveClients}
          type="checkbox"
          cssRules={css.checkboxDisplayOnlyActiveClients}
        />
        <Filter
          cssRules={css.filter}
          filterConfig={filter}
          changeFilter={changeTableFilter}
        />
      </Controls>
      <Wrapper cssRules={css.container} isGrow>
        <ClientsTable />
        <Divider />
        <CategoryTabs controlPanelHeight={height} />
      </Wrapper>
    </>
  );
};
