import React, { Fragment, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import ExpansionPanel from 'components/expansion-panel';
import Loader from 'components/loader';
import StyledWrapper from 'elements/styled-wrapper';
import InfiniteScroll from 'react-infinite-scroller';

import SingleTable from './single-table';

import './styles.scss';

// TODO: isFetching - should be only fetching state property, remove isFetchingDetails, withoutLoader
const DetailsTable = ({
  orderRules,
  changeOrder,
  content,
  isFetchingDetails,
  withoutLoader,
  tableCssRules,
  title,
  shouldTitleBeRendered,
  titleClassName,
  preventScrolling,
  groupControllerConfig = {},
  withInfiniteScroll,
  ...restProps
}) => {
  // TODO: Falsy loading condition - can't be measured by checking content item property. It may be empty.
  const [contentLength, setContentLength] = useState(10);
  const isDataLoaded = content.some(({ data }) => !!data.length);
  const slicedContent = content.length > contentLength ? content.slice(0, contentLength) : content;

  useEffect(() => {
    setContentLength(10);
  }, [content]);

  return (
    <StyledWrapper
      className="details-table"
      cssRules={tableCssRules}
    >

      <Loader isLoaded={withoutLoader || isDataLoaded || !isFetchingDetails}>

        <div className="details-table__multi-tables-wrapper">
          <InfiniteScroll
            className="table__rows-wrapper"
            pageStart={0}
            loadMore={() => {
              setContentLength(contentLength + 10);
            }}
            hasMore={withInfiniteScroll && content.length > contentLength}
            threshold={20}
          >
            {
              slicedContent.map(({ summaryConfig, summaryContent, ...contentRest }, index) => (
                summaryConfig ?
                  <Fragment
                    key={index} // eslint-disable-line react/no-array-index-key
                  >
                    {
                      shouldTitleBeRendered && (
                        <p className={classNames('details-table__title', titleClassName)}>{title}</p>
                      )
                    }
                    <ExpansionPanel
                      summaryConfig={summaryConfig}
                      summaryContent={summaryContent}
                      groupControllerConfig={groupControllerConfig}
                    >

                      <SingleTable
                        orderRules={orderRules}
                        changeOrder={changeOrder}
                        isFetching={isFetchingDetails}
                        preventScrolling={preventScrolling}
                        {...contentRest}
                        {...restProps}
                      />

                    </ExpansionPanel>
                  </Fragment> :

                  <SingleTable
                    key={index} // eslint-disable-line react/no-array-index-key
                    isFetching={isFetchingDetails}
                    orderRules={orderRules}
                    changeOrder={changeOrder}
                    preventScrolling={preventScrolling}
                    {...contentRest}
                    {...restProps}
                  />
              ))
            }
          </InfiniteScroll>
        </div>

      </Loader>

    </StyledWrapper>
  );
};

DetailsTable.propTypes = {
  tableCssRules: PropTypes.string,
  noDataMessage: PropTypes.string,
  fileType: PropTypes.string,
  title: PropTypes.string,
  controls: PropTypes.arrayOf(PropTypes.shape({})),
  isFetchingDetails: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  pocMsaName: PropTypes.string,
  pocMsaEmail: PropTypes.string,
  titleClassName: PropTypes.string,
  changeCurrentModal: PropTypes.func,
  content: PropTypes.arrayOf(PropTypes.shape({
    summaryConfig: PropTypes.shape({}),
    summaryContent: PropTypes.string,
  })).isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  changeOrder: PropTypes.func,
  withoutLoader: PropTypes.bool,
  shouldTitleBeRendered: PropTypes.bool,
  groupControllerConfig: PropTypes.shape({}),
  preventScrolling: PropTypes.bool,
  withInfiniteScroll: PropTypes.bool,
};

DetailsTable.defaultProps = {
  tableCssRules: '',
  noDataMessage: undefined,
  fileType: '',
  title: '',
  changeCurrentModal: () => null,
  changeOrder: () => null,
  pocMsaName: '',
  titleClassName: '',
  pocMsaEmail: '',
  controls: [],
  withoutLoader: false,
  shouldTitleBeRendered: false,
  groupControllerConfig: {},
  preventScrolling: false,
  withInfiniteScroll: false,
};


export default DetailsTable;
