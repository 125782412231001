import Decimal from 'decimal.js-light';
export const removeSpecialCharacters = (value) => !value ? 0 : `${value}`.replace(/[^\d.\-\w]/g, '');

export const addThousandSeparator = (number) => {
  if (!number) {
    return 0;
  }
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  return parts.join('.');
};

export const getVacationDaysFormat = (value) => {
  const formattedValue = Number(value);
  return formattedValue.toLocaleString('en', {
    maximumFractionDigits: 1,
  });
};

export const roundValue = (value, countSymbolsAfterComma) => {
  const decimalValue = new Decimal(value || 0);
  return decimalValue.toDecimalPlaces(countSymbolsAfterComma);
};

export const fixedNumberValue = (value, countSymbolsAfterComma) => new Decimal(value).toFixed(countSymbolsAfterComma);
