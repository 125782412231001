import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { config } from 'core/config';

import { getHasPermissions } from 'utils/auth';

const { rootHost } = config;

const LinksList = ({
  title,
  items,
  userGroup,
  actions,
  // currentEntityName,
}) => (
  <div className="expand-button__links-block links-block">
    {
      title && (
        <p className="links-block__title">{title}</p>
      )
    }
    <ul className="links-block__links-list">
      {
        items.map(({ text, url, route, actionArguments, action, guaranteedAccessRoles }, index) => {
          if (guaranteedAccessRoles && !getHasPermissions(userGroup, guaranteedAccessRoles)) {
            return null;
          }

          return (
            <li
              className="links-block__link-wrapper"
              // eslint-disable-next-line react/no-array-index-key
              key={`${text}/${index}`}
            >
              {
                !!url && (
                  <a
                    className="links-block__link"
                    href={`${rootHost}:8082${url}`}
                    tabIndex={-1}
                  >
                    {text}
                  </a>
                )
              }
              {
                !!route && (
                  <NavLink
                    className="links-block__link"
                    to={{
                      pathname: `/${route}`,
                      state: {
                        prevPath: window.location.pathname,
                      },
                    }}
                  >
                    {text}
                  </NavLink>
                )
              }
              {
                !!(action && actions) && (
                  <button
                    className="links-block__button"
                    onClick={() => actions[action](actionArguments ? actionArguments() : null)}
                    tabIndex={-1}
                  >
                    {text}
                  </button>
                )
              }
            </li>
          );
        })
      }
    </ul>

  </div>
);


LinksList.propTypes = {
  title: PropTypes.string,
  userGroup: PropTypes.string,
  actions: PropTypes.shape({}),
  items: PropTypes.any.isRequired, // TODO write proptypes
};

LinksList.defaultProps = {
  title: '',
  userGroup: '',
  actions: null,
};


export default LinksList;
