import React from 'react';

import PropTypes from 'prop-types';

import DetailsForm from 'components/details-form';
import Loader from 'components/loader';

export const EditProfile = ({
  body,
  isFetchingDetails,
  onSubmit,
  isFormSubmitted,
  additionalActions,
  layoutStyles,
  resourceTabIndex,
}) => (
  <div data-id="Edit Profile" className={layoutStyles}>
    {
      body.map(({ type, summaryConfig, ...rest }, index) => {
        if (isFetchingDetails) {
          // eslint-disable-next-line react/no-array-index-key
          return <Loader key={index} />;
        }

        return (
          <DetailsForm
            key={index} // eslint-disable-line react/no-array-index-key
            onSubmit={onSubmit}
            isFormSubmitted={isFormSubmitted}
            isFetchingDetails={isFetchingDetails}
            additionalActions={additionalActions}
            isLastStepOfCreateMode={!resourceTabIndex}
            buttonTitle="Save Changes"
            isVisible
            {...rest}
          />
        );
      })
    }
  </div>
);

EditProfile.propTypes = {
  body: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetchingDetails: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  additionalActions: PropTypes.shape({}).isRequired,
  layoutStyles: PropTypes.string.isRequired,
  resourceTabIndex: PropTypes.number.isRequired,
};
