import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import Inputs from 'components/inputs';
import { isNaN } from 'lodash';
import { areAllPropertiesFilled } from 'utils/helpers/areAllPropertiesFilled';

import { getClipboardText } from 'utils/helpers/getClipboardText';

import { inputNames } from './constants';
import styles from './style.module.scss';

const VerificationForm = ({ className, onSubmit, otpToken, removeVerificationError, errors }) => {
  const [focusedElem, setFocusedElem] = useState(null);
  const [prevStep, setPrevStep] = useState(null);
  const formRef = useRef(null);
  const {
    getValues,
    control,
    setValue,
    reset,
  } = useForm();

  const setInput = useCallback((input) => {
    if (input) {
      input.focus();
      input.setSelectionRange(1, 1);
    }
  }, []);

  const onSubmitHandler = () => {
    if (areAllPropertiesFilled(getValues())) {
      removeVerificationError();
      onSubmit({
        numbers: Object.values(getValues()),
        otpToken,
      });
    }
  };

  const onInputsPast = async (form) => getClipboardText().then((value) => {
    const arr = value.split('')
      .map((char) => Number(char))
      .filter((num) => !isNaN(num));
    if (arr.length === inputNames.length) {
      const elem = form.querySelector(`input[name=${inputNames[inputNames.length - 1]}]`);
      arr.forEach((item, index) => {
        setValue(inputNames[index], item);
      });
      setFocusedElem(elem);
    }
  });

  const onPaste = useCallback(({ currentTarget }) => {
    onInputsPast(currentTarget).then(onSubmitHandler);
  }, []);

  const onKeyUp = useCallback(({ key, target }) => {
    const keyNumber = Number(key);
    const element = target.closest('div');

    if (!element) return;
    if (!isNaN(keyNumber) || key === 'ArrowRight') {
      const nextElem = element.nextElementSibling;
      if (nextElem) {
        const nextInput = nextElem.querySelector('input');
        const name = target.name === prevStep ? nextInput.name : target.name;
        if (!isNaN(keyNumber)) {
          setValue(name, keyNumber);
          setPrevStep(name);
        }
        setFocusedElem(nextInput);
      }
    }

    if (key === 'Backspace' || key === 'ArrowLeft') {
      const prevElem = element.previousElementSibling;
      if (prevElem) {
        const prevInput = prevElem.querySelector('input');
        const name = prevInput.name;
        if (key === 'Backspace') {
          setValue(name, getValues()[name]);
          setPrevStep(name);
        }
        setFocusedElem(prevInput);
      } else {
        setPrevStep(null);
      }
    }

    if (!isNaN(keyNumber)) {
      onSubmitHandler();
    }
  }, [prevStep]);

  useEffect(() => {
    if (formRef.current && errors) {
      const elem = formRef.current.querySelector(`input[name=${inputNames[0]}`);
      setFocusedElem(elem);
      reset();
    }
  }, [errors]);

  useEffect(() => {
    setTimeout(() => setInput(focusedElem), 0);
  }, [prevStep, focusedElem]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      onKeyUp={onKeyUp}
      onPaste={onPaste}
      className={classNames(styles.form, className)}
      ref={formRef}
    >
      {inputNames.map((name, index) => (
        <Controller
          key={name}
          name={name}
          control={control}
          render={({ field }) => (
            <Inputs.NumericalInput
              name={name}
              onChange={field.onChange}
              inputValue={field.value}
              hasChanges={Boolean(field.value)}
              withLabel={false}
              isLocked={false}
              autoFocus={index === 0}
              format="#"
              validationRules={{
                isInteger: true,
                isNumeric: false,
                isRequired: true,
                allowNegative: false,
                decimalScale: false,
                minValue: 0,
                maxValue: 9,
              }}
            />
          )}
        />
      ))}
    </form>
  );
};

VerificationForm.propTypes = {
  className: PropTypes.string,
  otpToken: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  removeVerificationError: PropTypes.func.isRequired,
  errors: PropTypes.array,
};

VerificationForm.defaultProps = {
  className: '',
  otpToken: null,
  errors: null,
};

export default VerificationForm;
