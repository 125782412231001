import {
  colorSecondaryText,
  colorSecondaryGrayLight07,
  colorPrimary,
} from 'assets/styles/variables';
import { STORE_KEYS } from 'core/billing-reports/constants';
import { UNITS_TYPES } from 'core/constants';
import hash from 'hash-sum';

import annualReportResourceAverageSalariesConfigGetter from './config/annual-report-average-salaries-table-config';
import annualReportResourceMatrixTableConfigGetter from './config/annual-report-resource-matrix-table-config';
import annualReportRisesTableConfigGetter from './config/annual-report-rises-table-config';

export default ({
  currency,
  inDepthStudyAnalysisTab,
  isEmpty,
}) => {
  if (isEmpty) {
    return [
      {
        type: UNITS_TYPES.SINGLE_ROW,
        cssRules: `
          display: flex;
          padding: 0 1.6rem;
          font-size: 2.2rem;
        `,
        rowData: [
          {
            type: 'text',
            componentProps: {
              data: 'Analysis',
              cssRules: `
                font-size: 2.2rem;
                line-height: 2.4rem;
                color: ${colorSecondaryText};
              `,
            },
          },
        ],
      },
      {
        type: UNITS_TYPES.EMPTY_MESSAGE,
        title: 'Sorry, no content matched your criteria.',
        description: 'Please, change the selected filters.',
      },
    ];
  }

  const isSingleCurrency = currency.length === 1;
  return [{
    type: UNITS_TYPES.SINGLE_ROW,
    cssRules: `
    display: flex;
    padding: 0 1.6rem;
    font-size: 2.2rem;
    & .checkbox {
      font-family: "Etelka", Arial, sans-serif;
    }
    & .checkbox--active {
      color: ${colorPrimary};

      &:hover {
        color: ${colorPrimary};
      }
    }
  `,
    rowData: [{
      type: 'text',
      componentProps: {
        data: 'Analysis',
        cssRules: `
        font-size: 2.2rem;
        line-height: 2.4rem;
        color: ${colorSecondaryText};
      `,
      },
    }, isSingleCurrency ? {} : {
      type: 'checkbox',
      componentProps: {
        title: 'in-depth study',
        actionName: 'changeFilter',
        actionArguments: {
          currentFilter: STORE_KEYS.ANNUAL_REPORT_ANALYSIS_FILTER,
          storeKey: STORE_KEYS.IN_DEPTH_STUDY,
          type: 'toggle',
        },
        isActive: inDepthStudyAnalysisTab,
      },
    }],
  }, {
    type: UNITS_TYPES.TABLE,
    tableCssRules: `
    && {
      & .table {
        &:not(:last-child) {
          min-height: unset;
          padding-bottom: 0;
          ${!isSingleCurrency ? `border-bottom: 1px solid ${colorSecondaryGrayLight07};` : ''}
        }
      }
    }
  `,
    content: [{
      idKey: 'id',
      title: 'Raises',
      dataKey: 'annualReportRaisesAverageSalaryDataRows',
      tableName: 'annualReportAverageSalaries',
      preventScrolling: true,
      rowStatusGetter: (row) => {
        const { isTotal } = row;
        const id = hash(row);

        return ({
          id,
          stylesTemplate: {
            rowStyles: isTotal ? `
            && {
              border-top: 1px solid ${colorSecondaryGrayLight07};
            }
          ` : '',
          },
        });
      },
      rules: {
        css: {
          gridTemplateConfig: `
          grid-template-columns: minmax(auto, 150fr) repeat(12, minmax(auto, 85fr)) minmax(auto, 105fr);
          & .grid-unit--with-multi-items {
            display: grid;
            padding-right: 0;
            &:last-child {
              padding-right: 1.6rem;
            }
          }
        `,
        },
      },
      dataTemplate: annualReportResourceAverageSalariesConfigGetter(),
    }, {
      idKey: 'id',
      withHeadings: false,
      dataKey: 'annualReportNumberOfRaisesDataRows',
      tableName: 'annualReportNumberOfRaises',
      preventScrolling: true,
      rowStatusGetter: (row) => {
        const id = hash(row);

        return ({
          id,
        });
      },
      rules: {
        css: {
          gridTemplateConfig: `
          grid-template-columns: minmax(auto, 150fr) repeat(12, minmax(auto, 85fr)) minmax(auto, 105fr);
          & .grid-unit--with-multi-items {
            display: grid;
            padding-right: 0;
            &:last-child {
              padding-right: 1.6rem;
            }
          }
        `,
        },
      },
      dataTemplate: annualReportRisesTableConfigGetter(),
    }, {
      idKey: 'id',
      withHeadings: false,
      dataKey: 'annualReportRaisesDataRows',
      tableName: 'annualReportRaises',
      preventScrolling: true,
      rowStatusGetter: (row) => {
        const id = hash(row);

        return ({
          id,
        });
      },
      rules: {
        css: {
          gridTemplateConfig: `
          grid-template-columns: minmax(auto, 150fr) repeat(12, minmax(auto, 85fr)) minmax(auto, 105fr);
          & .grid-unit--with-multi-items {
            display: grid;
             padding-right: 0;
            &:last-child {
              padding-right: 1.6rem;
            }
          }
        `,
        },
      },
      dataTemplate: annualReportRisesTableConfigGetter(),
    }, {
      idKey: 'id',
      withHeadings: false,
      dataKey: 'annualReportRaisesPercentageDataRows',
      tableName: 'annualReportRaisesPercentage',
      preventScrolling: true,
      rowStatusGetter: (row) => {
        const id = hash(row);

        return ({
          id,
        });
      },
      rules: {
        css: {
          gridTemplateConfig: `
          grid-template-columns: minmax(auto, 150fr) repeat(12, minmax(auto, 85fr)) minmax(auto, 105fr);
          & .grid-unit--with-multi-items {
            display: grid;
            padding-right: 0;
            &:last-child {
              padding-right: 1.6rem;
            }
          }
        `,
        },
      },
      dataTemplate: annualReportRisesTableConfigGetter(),
    }],
  }, {
    type: UNITS_TYPES.TABLE,
    content: [{
      idKey: 'id',
      title: 'Resource Matrix',
      dataKey: 'annualReportResourceMatrixDataRows',
      tableName: 'annualReportResourceMatrix',
      preventScrolling: true,
      rowStatusGetter: (row) => {
      // const { isTotal, hasSalaryRise } = row;
        const id = hash(row);

        return ({
          id,
        });
      },
      rules: {
        css: {
          gridTemplateConfig: `
          grid-template-columns: minmax(auto, 115fr) repeat(12, minmax(auto, 85fr)) minmax(auto, 105fr);
          & .grid-unit--with-multi-items {
            display: grid;
            padding-right: 0;
            &:last-child {
              padding-right: 1.6rem;
            }
          }
        `,
        },
      },
      dataTemplate: annualReportResourceMatrixTableConfigGetter(),
    }],
  }, {
    type: UNITS_TYPES.TABLE,
    currentFilter: STORE_KEYS.ANNUAL_REPORT_ANALYSIS_FILTER,
    content: [{
      idKey: 'id',
      title: 'Average Salaries',
      dataKey: 'annualReportAverageSalariesDataRows',
      tableName: 'annualReportAverageSalaries',
      preventScrolling: true,
      rowStatusGetter: (row) => {
        const { isTotal } = row;
        const id = hash(row);

        return ({
          id,
          stylesTemplate: {
            rowStyles: isTotal && inDepthStudyAnalysisTab ? `
            && {
              border-color: ${colorSecondaryGrayLight07};
              border-style: solid;
              border-width: 1px 0 1px 0;
              ${isSingleCurrency ? 'display: none;' : ''}
            }
          ` : '',
          },
        });
      },
      rules: {
        css: {
          gridTemplateConfig: `
          grid-template-columns: minmax(auto, 150fr) repeat(12, minmax(auto, 85fr)) minmax(auto, 105fr);
          & .grid-unit--with-multi-items {
            display: grid;
            padding-right: 0;
            &:last-child {
              padding-right: 1.6rem;
            }
          }
        `,
        },
      },
      dataTemplate: annualReportResourceAverageSalariesConfigGetter({
        inDepthStudy: inDepthStudyAnalysisTab,
      }),
    }],
  }];
};
