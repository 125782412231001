import update from 'immutability-helper';

import {
  emailTemplateNotificationsTypes,
} from './actions';

export const emailTemplateNotificationsReducers = {
  [emailTemplateNotificationsTypes.GET_EMAIL_TEMPLATE_NOTIFICATIONS](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      emailTemplateNotifications: {
        emailTemplateNotificationsVersion: {
          $set: {
            ...state.emailTemplateNotificationsVersion,
            allowedForDelete: false,
          },
        },
      },
      sendingEmailsProgress: {
        notifications: {
          emailTemplate: {
            $set: {
              recordsInProgress: 0,
              inProgress: false,
              isActive: false,
            },
          },
        },
      },
    });
  },
  [emailTemplateNotificationsTypes.GET_EMAIL_TEMPLATE_NOTIFICATIONS_SUCCESS](state, {
    payload: {
      emailsData,
      emailTemplates,
      notifications,
      billingAgentIds,
      versions,
      version,
      statuses,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      emailTemplateNotifications: {
        emailTemplateNotificationsData: {
          $set: emailsData,
        },
        emailTemplates: {
          $set: emailTemplates,
        },
        notificationsType: {
          $set: notifications,
        },
        billingAgentIds: {
          $set: billingAgentIds,
        },
        emailTemplateNotificationsVersion: {
          $set: version,
        },
        emailTemplateNotificationsVersions: {
          $set: versions,
        },
        emailTemplateNotificationsStatuses: {
          $set: statuses,
        },
      },
      errors: {
        $unset: ['emailTemplateNotificationsError'],
      },
    });
  },
  [emailTemplateNotificationsTypes.GET_EMAIL_TEMPLATE_NOTIFICATIONS_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [emailTemplateNotificationsTypes.UPDATE_EMAIL_TEMPLATE_NOTIFICATIONS](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [emailTemplateNotificationsTypes.UPDATE_EMAIL_TEMPLATE_NOTIFICATIONS_SUCCESS](
    state,
    {
      payload: {
        emailTemplateNotificationsData: {
          emailsData,
          billingAgentIds,
          notifications,
          versions,
        },
      },
    }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      emailTemplateNotifications: {
        emailTemplateNotificationsData: {
          $set: emailsData,
        },
        billingAgentIds: {
          $set: billingAgentIds,
        },
        notificationsType: {
          $set: notifications,
        },
        emailTemplateNotificationsVersions: {
          $set: versions,
        },
      },
      errors: {
        $unset: ['updateTemplateNotificationsError'],
      },
    });
  },
  [emailTemplateNotificationsTypes.UPDATE_EMAIL_TEMPLATE_NOTIFICATIONS_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [emailTemplateNotificationsTypes.CREATE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [emailTemplateNotificationsTypes.CREATE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION_SUCCESS](
    state,
    { payload: { emailTemplateNotificationsData: {
      versions,
      version,
      emailsData,
    } } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      emailTemplateNotifications: {
        emailTemplateNotificationsData: {
          $set: emailsData,
        },
        emailTemplateNotificationsVersion: {
          $set: version,
        },
        emailTemplateNotificationsVersions: {
          $set: versions,
        },
      },
      errors: {
        $unset: ['createEmailTemplateNotificationsError'],
      },
    });
  },
  [emailTemplateNotificationsTypes.CREATE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [emailTemplateNotificationsTypes.CHANGE_EMAIL_TEMPLATE_NOTIFICATIONS_FILTER](state, { payload }) {
    return update(state, {
      filters: {
        notifications: {
          emailTemplate: {
            $merge: {
              ...payload,
            },
          },
        },
      },
    });
  },
  [emailTemplateNotificationsTypes.DELETE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [emailTemplateNotificationsTypes.DELETE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION_SUCCESS](
    state,
  ) {
    return update(state, {
      errors: {
        $unset: ['deleteEmailTemplateNotificationsVersionError'],
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },
  [emailTemplateNotificationsTypes.DELETE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },
  [emailTemplateNotificationsTypes.SEND_EMAIL_TEMPLATE_NOTIFICATIONS_EMAILS](state) {
    return update(state, {
      isFetching: { $set: true },
    });
  },
  [emailTemplateNotificationsTypes.SEND_EMAIL_TEMPLATE_NOTIFICATIONS_EMAILS_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: { $set: false },
      errors: {
        $set: error,
      },
    });
  },
  [emailTemplateNotificationsTypes.STOP_CHECK_RATECARD_NOTIFICATIONS_STATUS](state) {
    return state;
  },
  [emailTemplateNotificationsTypes.CHECK_EMAILS_NOTIFICATIONS_STATUS](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [emailTemplateNotificationsTypes.CHECK_EMAILS_NOTIFICATIONS_STATUS_SUCCESS](state, action) {
    if (action.payload) {
      return update(state, {
        sendingEmailsProgress: {
          notifications: {
            emailTemplate: {
              $set: action.payload,
            },
          },
        },
      });
    }
    return state;
  },
  [emailTemplateNotificationsTypes.UPDATE_EMAILS_NOTIFICATIONS_STATUS](
    state,
  ) {
    return update(state, {
      isFetching: { $set: false },
    });
  },
  [emailTemplateNotificationsTypes.UPDATE_EMAILS_NOTIFICATIONS_STATUS_FAIL](state, { payload: { error } }) {
    return update(state, {
      isFetching: { $set: false },
      errors: {
        $set: error,
      },
    });
  },
  [emailTemplateNotificationsTypes.CLEAR_EMAIL_TEMPLATE_NOTIFICATIONS](state) {
    return update(state, {
      emailTemplateNotifications: {
        emailTemplateNotificationsData: {
          $set: [],
        },
        emailTemplates: {
          $set: [],
        },
        notificationsType: {
          $set: [],
        },
        billingAgentIds: {
          $set: [],
        },
        emailTemplateNotificationsVersion: {
          $set: null,
        },
        emailTemplateNotificationsVersions: {
          $set: [],
        },
        emailTemplateNotificationsStatuses: {
          $set: [],
        },
      },
    });
  },
};
