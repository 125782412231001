import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Inputs from 'components/inputs';

import './styles.scss';


const UploadDocumentInput = ({ fieldConfig, formProps, parentFieldValue, parentField, onChange }) => {
  const { name, label, ...rest } = fieldConfig;
  const { values, errors, touched } = formProps;

  const [savedParentFieldValue, setParentFieldValue] = useState(parentFieldValue);

  useEffect(() => {
    if (parentField && savedParentFieldValue !== parentFieldValue) {
      const { valueGetter } = parentField;

      setParentFieldValue(parentFieldValue);

      const calculatedValue = valueGetter(values);
      onChange(calculatedValue);
    }
  }, [parentFieldValue]);

  return (
    <Field
      key={name}
      type="text"
      name={name}
      render={() => (
        <Inputs.TextInput
          name={name}
          label={label}
          inputValue={values[name]}
          onChange={onChange}
          error={errors[name]}
          withError={Boolean(touched[name] && errors[name])}
          {...rest}
        />
      )}
    />
  );
};


UploadDocumentInput.propTypes = {
  parentField: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  parentFieldValue: PropTypes.string,
  formProps: PropTypes.shape({
  }).isRequired,
  fieldConfig: PropTypes.shape({
  }).isRequired,
};


UploadDocumentInput.defaultProps = {
  parentFieldValue: '',
  parentField: null,
};


export default UploadDocumentInput;
