import moment from 'moment';
const toUnixTimestamp = (date) => moment(date).format('X');

const isWithinRange = (startDate, endDate, targetDate) => toUnixTimestamp(startDate) <= targetDate && toUnixTimestamp(endDate) >= targetDate;

export const getCalendarValidationMessage = ({
  isDismissed,
  dateHired,
  dateLast,
}) => {
  if (isDismissed) {
    return 'The selected date should be in the range between "Date Hired" and "Dismissal Date".';
  }

  if (dateLast) {
    return 'The selected date should be in the range between "Date Hired" and "Dismissal Date".';
  }

  if (dateHired) {
    return 'The selected date can\'t be earlier than "Date Hired".';
  }

  return 'The selected date can\'t be earlier than "Internship Start Date".';
};

export const compareCalendarDates = (date, {
  isDismissed,
  dateHired,
  dismissalDate,
  internshipStartDate,
  dateLast,
}) => {
  const unixDate = toUnixTimestamp(date);

  if (isDismissed || (dismissalDate && moment(date).isAfter(dismissalDate))) {
    return isWithinRange(dateHired, dismissalDate, unixDate);
  }

  if (internshipStartDate) {
    return toUnixTimestamp(internshipStartDate) <= unixDate;
  }

  if (dateHired) {
    return toUnixTimestamp(dateHired) <= unixDate;
  }

  return dateLast ?
    isWithinRange(dateHired, dateLast.value, unixDate) :
    toUnixTimestamp(dateHired) <= unixDate;
};
