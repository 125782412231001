import { USERS_GROUPS } from 'core/auth/constants';
import {
  addClientDocuments,
  deleteClientDocuments,
  signClientDocuments,
} from 'core/auth/guaranteedAccessRoles';
import TooltipContent from 'elements/tooltip-content';
import hash from 'hash-sum';
import {
  typeData,
  FILE_TYPES,
  statusData,
  typeDataForSales,
  statusDataForSales,
} from 'layouts/client-details/config';
import moment from 'moment';

import { getHasPermissions } from 'utils/auth';
import {
  getUnixTime,
  getIsExpired,
  formatDateMMDDYYYY,
} from 'utils/helpers/date';
import {
  getUniqueMSA,
  getFileStatus,
  compareMsa,
} from 'utils/helpers/files';
import { requiredValidator } from 'utils/validation';
import { object } from 'yup';
const {
  CSO,
  SALES,
} = USERS_GROUPS;

export default ({
  isHRM,
  isDOA,
  userGroup,
  currentMsa,
  currentClientName,
  currentMsaList,
  isClientActive,
  currentClientID,
  msaTemplate,
  defaultMsaTemplate = {},
  nosec14,
  individualLiability,
}) => {
  const generatedMsa = getUniqueMSA(currentMsa, currentMsaList || [], currentClientName || '');
  const isUserSales = userGroup === SALES || userGroup === CSO;
  const hasPermissionsToDeleteFile = getHasPermissions(userGroup, deleteClientDocuments);
  const hasPermissionsToAddFile = getHasPermissions(userGroup, addClientDocuments);
  const hasPermissionsToSignFile = getHasPermissions(userGroup, signClientDocuments);
  let currentMsaDefaultTemplate = defaultMsaTemplate.msaSections;
  if (nosec14 && individualLiability) {
    currentMsaDefaultTemplate = defaultMsaTemplate.msaSectionsIndividualNosec14;
  } else if (nosec14 && !individualLiability) {
    currentMsaDefaultTemplate = defaultMsaTemplate.msaSectionsNosec14;
  } else if (individualLiability && !nosec14) {
    currentMsaDefaultTemplate = defaultMsaTemplate.msaSectionsIndividual;
  }
  return isDOA ? [] : [{
    type: 'table',
    withUploading: isClientActive && hasPermissionsToAddFile,
    noDataMessage: 'This section currently contains no files.',
    tableCssRules: 'min-height: calc(100vh - 30rem);',
    fileGeneratorConfig: {
      title: 'Generate New MSA',
      fields: [{
        type: 'text',
        name: 'title',
        label: 'Title',
      }, {
        type: 'text',
        name: 'generatedMsa',
        label: 'MSA#',
        tooltipContent: TooltipContent,
      }, {
        type: 'calendar',
        name: 'date',
        placeholder: 'Effective date',
        msaGenerator: true,
        outputFormatting: ({ date }) => (
          moment.isMoment(date) ?
            date.format('YYYY-MM-DD') :
            date
        ),
        cssRules: `
          max-height: unset;
          font-size: 1.4rem;
          line-height: 2.4rem;
          margin-bottom: 1rem;
          && {
            & .calendar-date-picker__views-wrapper {
              min-width: unset;
            }
          }
        `,
      }, {
        type: 'text',
        name: 'versionOfMsa',
        label: 'Version',
        isLocked: true,
      }, {
        type: 'actionButton',
        data: 'Edit',
        withBody: true,
        actionName: 'openModal',
        cssRules: `{
          height: 3rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 1.6rem;
          padding: 7.2px 2.4rem;
          font-size: 1.3rem;
          color: #58595b;
          border-color: #58595b;
          &&.action-button {
            font-size: 1.3rem;
            line-height: 1.6rem;
            color: #58595b;
            border-color: #58595b;
          }
          &&.action-button:hover {
            color: #fff;
            border-color: #d61f26;
          }
        }`,
        shouldntBeRendered: !hasPermissionsToSignFile,
        actionArguments: {
          currentModal: 'EDIT_MSA_MODAL',
          acceptActionName: 'updateMsa',
          msaTemplate,
          defaultMsaTemplate,
          generatedMsa,
          currentClientID,
          nosec14,
          individualLiability,
          acceptActionArguments: {
            withModal: true,
          },
          stylesTemplate: {
            formStyles: `
            .details-form__form-wrapper {
              max-height: 60vh;
              overflow-y: scroll;
            }
            .details-form__form-wrapper::-webkit-scrollbar {
              position: absolute;
              width: 5px;
            }
            .details-form__form-wrapper::-webkit-scrollbar-thumb {
              min-height: 20px;
              border-radius: 5px;
              background-color: #e2e2e2;
            }
            .details-form__form-wrapper::-webkit-scrollbar-track {
              background-color: #fbfbfb;
              box-shadow: none;
            }`,
            formControlsCss: `
              margin: 1.6rem;
            `,
            modalCss: `
              max-width: 70vw;
              max-height: 95vh;
            `,
          },
        },
      }],
      validationSchema: object().shape({
        title: requiredValidator(),
        date: requiredValidator(),
        generatedMsa: requiredValidator()
          .max(50, 'MSA# max length is 50')
          .matches(/(^MSA-(0?0[1-9]|1[012])\d{4}-([A-Z]{2})(\d{1,37})$)/, 'Wrong format'),
      }),
      initialValues: {
        title: currentClientName ? `MSA ${currentClientName}` : '',
        date: '',
        generatedMsa,
        versionOfMsa: compareMsa(currentMsaDefaultTemplate, msaTemplate),
      },
    },
    fileLoaderConfig: {
      title: 'Upload Document',
      fields: [{
        type: 'select',
        name: 'uploadType',
        label: 'Type',
        ...typeData,
        ...(isUserSales && typeDataForSales),
      }, {
        type: 'text',
        name: 'uploadTitle',
        label: 'Title',
        parentField: {
          name: 'uploadType',
          valueGetter: ({
            uploadType,
          }) => {
            switch (uploadType) {
              case FILE_TYPES.MSA:
                return `${uploadType} ${currentClientName}`;
              case FILE_TYPES.NDA:
                return `${uploadType} ${currentClientName}`;
              default:
                return '';
            }
          },
        },
        cssRules: `
          && {
            margin-bottom: 1.1rem;
          }
        `,
      }, {
        type: 'calendar',
        name: 'uploadDate',
        placeholder: 'Effective date',
        outputFormatting: ({ date }) => (
          moment.isMoment(date) ?
            date.format('YYYY-MM-DD') :
            date
        ),
        cssRules: `
          max-height: unset;
          font-size: 1.4rem;
          line-height: 2.4rem;
          && {
            & .calendar-date-picker__views-wrapper {
              min-width: unset;
            }
          }
        `,
      }],
      additional: {
        getIsVisible: ({
          uploadType,
        }) => uploadType && uploadType === 'MSA',
        fields: [{
          type: 'text',
          name: 'uploadMsa',
          label: 'MSA#',
          tooltipContent: TooltipContent,
        }, {
          type: 'select',
          name: 'uploadStatus',
          label: 'Status',
          ...statusData,
          ...(isUserSales && statusDataForSales),
        }],
      },
      validationSchema: object().shape({
        uploadType: requiredValidator(),
        uploadTitle: requiredValidator()
          .max(50, 'Title max length is 50'),
        uploadDate: requiredValidator(),
        uploadMsa: requiredValidator()
          .max(50, 'MSA# max length is 50')
          .matches(/(^MSA-(0?0[1-9]|1[012])\d{4}-([A-Z]{2})(\d{1,37})$)/, 'Wrong format'),
        uploadStatus: requiredValidator(),
      }),
      initialValues: {
        uploadType: isUserSales ? 'MSA' : '',
        uploadTitle: isUserSales ? `MSA ${currentClientName}` : '',
        uploadDate: '',
        uploadMsa: generatedMsa,
        uploadStatus: false,
      },
    },
    content: [{
      title: 'MSA',
      idKey: 'fileId',
      dataKey: 'filesMsa',
      rowStatusGetter: ({
        title,
        fileId,
        uploadedDate,
      }) => ({
        id: hash({
          fileId,
          uploadedDate,
        }),
        name: title,
      }),
      rules: {
        css: {
          gridTemplateConfig: `grid-template-columns: minmax(auto, 18fr) minmax(auto, 17fr) minmax(auto, 11fr) minmax(auto, 11fr) minmax(auto, 11fr) ${hasPermissionsToSignFile ? 'minmax(auto, 8fr) minmax(auto, 26fr)' : 'minmax(auto, 30fr)'};`,
        },
      },
      tableName: 'MSA',
      dataTemplate: [{
        shouldntBeRendered: isHRM,
        columnName: 'Title',
        valueGetter: ({
          title,
          downloadUrl,
          downloadSignedUrl,
        }) => ({
          shouldntBeRendered: isHRM,
          value: title,
          type: downloadSignedUrl || downloadUrl ? 'link' : 'text',
          componentProps: {
            data: title,
            href: downloadSignedUrl || downloadUrl,
            withBlank: true,
          },
        }),
      }, {
        columnName: 'MSA#',
        valueGetter: ({
          fileName,
        }) => ({
          value: fileName,
          type: 'text',
        }),
      }, {
        columnName: 'Upload Date',
        valueGetter: ({
          uploadedDate,
        }) => ({
          isDate: true,
          unixValue: getUnixTime(uploadedDate),
          type: 'text',
          componentProps: {
            data: uploadedDate ? formatDateMMDDYYYY(uploadedDate) : 'N/A',
            isEmpty: !uploadedDate,
          },
        }),
      }, {
        columnName: 'Effective Date',
        valueGetter: ({
          effectiveDate,
        }) => ({
          isDate: true,
          unixValue: getUnixTime(effectiveDate),
          type: 'text',
          componentProps: {
            data: effectiveDate ? formatDateMMDDYYYY(effectiveDate) : 'N/A',
            isEmpty: !effectiveDate,
          },
        }),
      }, {
        columnName: 'status',
        valueGetter: ({
          isSigned,
          isGenerated,
        }) => {
          const status = getFileStatus({
            isSigned,
            isGenerated,
          });

          return ({
            value: status,
            type: 'text',
          });
        },
      }, {
        columnName: 'Version',
        valueGetter: ({ versionOfMsaTemplate }) => ({
          value: versionOfMsaTemplate || 'N/A',
          type: 'text',
          cssRules: 'text-transform: capitalize;',
        }),
      }, {
        columnName: 'action',
        withoutControls: true,
        shouldntBeRendered: !hasPermissionsToSignFile,
        valueGetter: ({
          isSigned,
          title,
          fileId,
          isRequestToSignSent,
        }) => {
          const message = {
            value: 'Sent for Signature',
            type: 'text',
            shouldntBeRendered: !hasPermissionsToSignFile,
          };
          const sendForSignatureConfig = {
            value: 'Send for signature',
            type: 'action',
            cssRules: `&& .action-button {
              font-size: 1.3rem;
              line-height: 1.6rem;
            }`,
            shouldntBeRendered: !hasPermissionsToSignFile,
            componentProps: {
              data: 'Send for signature',
              withBody: true,
              isHidden: false,
              actionName: 'changeCurrentModal',
              actionArguments: {
                currentModal: 'SEND_FOR_SIGNATURE_MODAL',
                fileId,
              },
            },
          };
          const deleteButton = {
            value: 'Delete',
            type: 'action',
            cssRules: 'margin-left: auto;',
            componentProps: {
              data: 'Delete',
              actionName: 'changeCurrentModal',
              actionArguments: {
                currentModal: 'CONFIRM_ACTION_MODAL',
                content: [{
                  type: 'title',
                  data: `Delete “${title}”?`,
                }, {
                  type: 'description',
                  data: 'This file will be permanently deleted and cannot be restored.',
                }],
                acceptActionName: 'deleteClientDocument',
                acceptActionTitle: 'Delete',
                acceptActionArguments: {
                  clientId: currentClientID,
                  fileId,
                },
              },
            },
          };
          const noComponent = {
            shouldntBeRendered: true,
          };
          const getIsNeedSign = (unitsIsTrue, unitsIsFalse) => !isClientActive || isSigned ? unitsIsTrue : unitsIsFalse;

          return hasPermissionsToDeleteFile ?
            getIsNeedSign([deleteButton], [isRequestToSignSent ? message : sendForSignatureConfig, deleteButton]) :
            getIsNeedSign([noComponent], isRequestToSignSent ? message : [sendForSignatureConfig, isUserSales ? deleteButton : noComponent]);
        },
      }],
    }, {
      title: 'Other Documents',
      idKey: 'fileId',
      dataKey: 'filesOthers',
      rowStatusGetter: ({
        title,
        fileId,
        uploadedDate,
        effectiveDate,
      }) => ({
        id: hash({
          fileId,
          uploadedDate,
        }),
        isActive: !getIsExpired(effectiveDate),
        hasWarning: false,
        name: title,
      }),
      rules: {
        css: {
          gridTemplateConfig: `grid-template-columns: minmax(auto, 25fr) minmax(auto, 15fr) minmax(auto, 12fr) ${hasPermissionsToDeleteFile ? 'minmax(auto, 24fr) minmax(auto, 26fr)' : 'minmax(auto, 46fr)'};`,
        },
      },
      tableName: 'otherDocuments',
      dataTemplate: [{
        columnName: 'Title',
        valueGetter: ({
          title,
          downloadUrl,
        }) => ({
          value: title,
          type: downloadUrl && hasPermissionsToAddFile ? 'link' : 'text',
          componentProps: {
            data: title,
            href: downloadUrl,
            withBlank: true,
          },
        }),
      }, {
        columnName: 'Type',
        valueGetter: ({
          fileType,
        }) => ({
          value: fileType,
          type: 'text',
        }),
      }, {
        columnName: 'Upload Date',
        valueGetter: ({
          uploadedDate,
        }) => ({
          isDate: true,
          unixValue: getUnixTime(uploadedDate),
          type: 'text',
          componentProps: {
            data: uploadedDate ? formatDateMMDDYYYY(uploadedDate) : 'N/A',
            isEmpty: !uploadedDate,
          },
        }),
      }, {
        columnName: 'Effective Date',
        valueGetter: ({
          effectiveDate,
        }) => ({
          isDate: true,
          unixValue: getUnixTime(effectiveDate),
          type: 'text',
          componentProps: {
            data: effectiveDate ? formatDateMMDDYYYY(effectiveDate) : 'N/A',
            isEmpty: !effectiveDate,
          },
        }),
      }, {
        columnName: 'action',
        withoutControls: true,
        shouldntBeRendered: !hasPermissionsToDeleteFile,
        valueGetter: ({
          title,
          fileId,
        }) => ({
          value: 'Delete',
          type: 'action',
          shouldntBeRendered: !hasPermissionsToDeleteFile,
          componentProps: {
            data: 'Delete',
            actionName: 'changeCurrentModal',
            actionArguments: {
              currentModal: 'CONFIRM_ACTION_MODAL',
              content: [{
                type: 'title',
                data: `Delete “${title}”?`,
              }, {
                type: 'description',
                data: 'This file will be permanently deleted and cannot be restored.',
              }],
              acceptActionName: 'deleteClientDocument',
              acceptActionTitle: 'Delete',
              acceptActionArguments: {
                clientId: currentClientID,
                fileId,
              },
            },
          },
        }),
      }],
    }],
  }];
};
