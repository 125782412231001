import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import units from 'components/shared-units';
import { get } from 'lodash';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';

import { UNIT_TYPES } from './constants';

const StyledWrapper = styled.div`
  ${({ isFilter }) => !isFilter ?
    `min-height: 4rem;
     padding: 1.2rem 0;` :
    'min-height: 3.4rem;'}

  ${({ cssRules }) => css`${cssRules}`}`;

const GridUnit = (props) => {
  const {
    type,
    value,
    title,
    cssRules,
    isHeader,
    isFilter,
    withImage,
    orderRules,
    headerProps,
    unitActions,
    changeFilter,
    filterConfig,
    componentProps,
    shouldntBeRendered,
    tooltipConfig,
  } = props;
  const childProps = headerProps || componentProps || { data: value, ...(title && { title }) };
  const { actionArguments, actionName } = childProps;
  const currentAction = get(unitActions, actionName, () => null);

  childProps.onClick = ({ ...args }) => {
    currentAction({
      ...actionArguments,
      ...args,
    });
  };
  childProps.isFilter = isFilter;
  childProps.orderRules = orderRules;

  if (isFilter) {
    const { isActive, selected, storeKey, items } = filterConfig;
    const onFiltering = (term) => changeFilter({
      type,
      storeKey,
      selected: term,
    });

    switch (type) {
      case UNIT_TYPES.TOGGLE:
        childProps.isActive = isActive;
        childProps.onClick = onFiltering;
        break;

      case UNIT_TYPES.CHECKBOX:
        childProps.isActive = !!isActive;
        childProps.onClick = () => changeFilter({
          storeKey,
          type,
        });
        break;

      case UNIT_TYPES.SEARCH:
        childProps.term = selected;
        childProps.onChange = onFiltering;
        break;

      case UNIT_TYPES.DATE:
        const selectedValue = get(selected, 'value', null); // eslint-disable-line no-case-declarations
        const useOnlyYear = get(selected, 'useOnlyYear', false); // eslint-disable-line no-case-declarations
        const useOnlyMonth = get(selected, 'useOnlyMonth', false); // eslint-disable-line no-case-declarations
        childProps.value = selectedValue;
        childProps.useOnlyYear = useOnlyYear;
        childProps.useOnlyMonth = useOnlyMonth;
        childProps.onChange = onFiltering;
        childProps.withClear = true;
        break;

      case UNIT_TYPES.SELECT:
      case UNIT_TYPES.MULTISELECT:
        childProps.items = items;
        childProps.isMultiple = true;
        childProps.selected = selected;
        childProps.onChange = onFiltering;
        break;

      case UNIT_TYPES.RANGE:
        childProps.selected = selected;
        childProps.onChange = onFiltering;
        break;
      default:
        break;
    }
  }

  const isFilterActive = isFilter && filterConfig && filterConfig.isActive;
  const unit = get(units, withImage ? 'favorite' : type, null);
  const tooltipTriggerProps = get(tooltipConfig, 'triggerProps', {});
  const tooltipProps = get(tooltipConfig, 'props', {});
  const tooltipContent = get(tooltipConfig, 'content');

  return (
    !shouldntBeRendered ? (
      <>
        <StyledWrapper
          className={
            classNames(
              'grid-unit',
              { 'grid-unit--heading': isHeader },
              { 'grid-unit--filter': isFilter },
              { 'grid-unit--active': isFilterActive },
            )
          }
          isFilter={isFilter}
          cssRules={cssRules}
          {
            ...tooltipTriggerProps
          }
        >
          {
            unit &&
          React.createElement(
            unit,
            {
              unitActions,
              ...childProps,
            },
          )
          }
        </StyledWrapper>

        {
          !!tooltipContent && (
            <ReactTooltip
              {...tooltipProps}
            >

              <span className="tooltip__description">
                {tooltipContent}
              </span>

            </ReactTooltip>
          )
        }

      </>
    ) : null

  );
};

GridUnit.propTypes = {
  unitActions: PropTypes.shape({}),
  cssRules: PropTypes.any,
  withImage: PropTypes.bool,
  shouldntBeRendered: PropTypes.bool,
  componentProps: PropTypes.any,
  headerProps: PropTypes.any,
  changeFilter: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
  title: PropTypes.string,
  isHeader: PropTypes.bool.isRequired,
  isFilter: PropTypes.bool,
  orderRules: PropTypes.shape({
    orderBy: PropTypes.string,
    isReversed: PropTypes.bool,
    isSeparatedByFavorites: PropTypes.bool,
  }).isRequired,
  filterConfig: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
  tooltipConfig: PropTypes.shape({}),
};

GridUnit.defaultProps = {
  title: '',
  unitActions: {},
  cssRules: '',
  withImage: false,
  value: '',
  filterConfig: {},
  isFilter: false,
  headerProps: undefined,
  componentProps: undefined,
  shouldntBeRendered: false,
  type: '',
  changeFilter: null,
  tooltipConfig: undefined,
};

export default GridUnit;
