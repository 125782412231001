import React from 'react';

import PropTypes from 'prop-types';

import { colorBackgroundLight } from 'assets/styles/variables';
import GridTemplate from 'components/grid-template';
import ActionButton from 'elements/action-button';
import Heading from 'elements/plain-text';
import { get } from 'lodash';
import styled, { css } from 'styled-components';

import './styles.scss';

const StyledWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0 calc(50% - 68rem);
  background-color: ${colorBackgroundLight};
  z-index: 10;

  ${({ cssRules }) => css`${cssRules}`}`;

const Filter = ({ cssRules, changeFilter, filterConfig, title, hasActiveFilters, resetFilters, isSticky }) => {
  const wrapperCssRules = get(cssRules, 'wrapperCssRules', '');
  const titleCssRules = get(cssRules, 'titleCssRules', '');
  const gridTemplateConfig = get(cssRules, 'gridTemplateConfig', '');
  const clearButtonCssRules = get(cssRules, 'clearButtonCssRules', '');
  return (
    <div className={isSticky ? 'filter filter-sticky' : 'filter'}>

      <div className="filter__controls-wrapper">
        <Heading
          data={title}
          cssRules={titleCssRules}
        />

        <div className="filter__clear-filter">
          {
            hasActiveFilters && (
              <ActionButton
                data="Clear filters"
                cssRules={clearButtonCssRules}
                onClick={() => resetFilters()}
              />
            )
          }
        </div>

      </div>

      <StyledWrapper
        cssRules={wrapperCssRules}
      >
        <GridTemplate
          isFilter
          cssRules={gridTemplateConfig}
          rowData={filterConfig}
          changeFilter={changeFilter}
        />
      </StyledWrapper>
    </div>
  );
};

Filter.propTypes = {
  title: PropTypes.string,
  hasActiveFilters: PropTypes.bool,
  filterConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  changeFilter: PropTypes.func.isRequired,
  resetFilters: PropTypes.func,
  cssRules: PropTypes.shape({}).isRequired,
  isSticky: PropTypes.bool,
};

Filter.defaultProps = {
  title: '',
  hasActiveFilters: false,
  resetFilters: () => null,
  isSticky: false,
};

export default Filter;
