import React from 'react';

import PropTypes from 'prop-types';

import DocumentGenerateForm from 'components/documents-generate-form';
import UploadDocumentForm from 'components/upload-document-form';


import './styles.scss';


const DocumentsControls = ({
  withDocumentGenerate,
  withDocumentUpload,
  keyPrefix,
  isFetchingFiles,
  onGenerate,
  onSubmitUploadedFile,
  fileGeneratorConfig,
  fileLoaderConfig,
  isFileUploaded,
  uploadingProgress,
  unitActions,
  msaDate,
}) => (
  <div className="document-controls">

    {withDocumentGenerate && (
      <DocumentGenerateForm
        key={`${keyPrefix} GenerateDocument`}
        isFetchingFiles={isFetchingFiles}
        onSubmit={onGenerate}
        formConfig={fileGeneratorConfig}
        unitActions={unitActions}
        msaDate={msaDate}
      />
    )}

    {withDocumentUpload && (
      <UploadDocumentForm
        key={`${keyPrefix} UploadDocument`}
        isFetchingFiles={isFetchingFiles}
        onSubmit={onSubmitUploadedFile}
        uploadingProgress={uploadingProgress}
        isFileUploaded={isFileUploaded}
        fileLoaderConfig={fileLoaderConfig}
      />
    )}
  </div>
);


DocumentsControls.propTypes = {
  keyPrefix: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  unitActions: PropTypes.object,
  isFetchingFiles: PropTypes.bool.isRequired,
  isFileUploaded: PropTypes.bool.isRequired,
  uploadingProgress: PropTypes.number.isRequired,
  onGenerate: PropTypes.func.isRequired,
  onSubmitUploadedFile: PropTypes.func.isRequired,
  withDocumentUpload: PropTypes.bool,
  withDocumentGenerate: PropTypes.bool,
  fileGeneratorConfig: PropTypes.shape({
    title: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    validationSchema: PropTypes.shape({}),
    initialValues: PropTypes.shape({}),
  }).isRequired,
  fileLoaderConfig: PropTypes.shape({
    title: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    validationSchema: PropTypes.shape({}),
    initialValues: PropTypes.shape({}),
  }).isRequired,
  msaDate: PropTypes.any,
};

DocumentsControls.defaultProps = {
  withDocumentUpload: false,
  withDocumentGenerate: false,
  keyPrefix: '',
  unitActions: {},
  msaDate: null,
};


export default DocumentsControls;
