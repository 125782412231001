import { selectUserGroup } from 'core/auth/selectors';
import {
  getContactDataManagementPermissions,
  getContactDataManagementRecords,
} from 'core/delivery/contactManagement/utils';
import { createSelector } from 'reselect';

export const selectIsFetchingContactDataManagementRecords = (state) => state.delivery.employeeDetailsTabs.contacts.isFetchingContactDataManagementRecords;
export const selectContactDataManagementRecords = (state) => state.delivery.employeeDetailsTabs.contacts.contactDataManagementRecords;
export const selectMemoDevstaffId = (state) => state.delivery.employeeDetailsTabs.contacts.memoDevstaffId;
export const selectFormState = (state) => state.delivery.employeeDetailsTabs.contacts.formState;
export const selectContactDataLength = (state) => state.delivery.employeeDetailsTabs.contacts.length;

export const selectRecords = createSelector(
  selectIsFetchingContactDataManagementRecords,
  selectContactDataManagementRecords,
  selectUserGroup,
  selectMemoDevstaffId,
  (isFetchingContactDataManagementRecords, contactDataManagementRecords, userGroup, memoDevstaffId) => {
    const {
      hasPermissionToEdit,
      hasPermissionToEditOnlyThreeDays,
      hasPermissionToAddRecord,
    } = getContactDataManagementPermissions({ userGroup });

    return {
      memoDevstaffId,
      isFetchingContactDataManagementRecords,
      records: getContactDataManagementRecords({
        contactDataManagementRecords,
        hasPermissionToEdit,
        hasPermissionToEditOnlyThreeDays,
      }),
      hasPermissionToAddRecord,
    };
  }
);
