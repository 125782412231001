import {
  colorPrimary,
  colorSecondaryText,
  colorBackgroundDark,
  colorSecondaryGrayLight,
  colorSecondaryBackground,
  colorSecondaryGrayLight015,
  colorSecondaryGray,
} from 'assets/styles/variables';
import {
  getRevenue,
  calculateTotal,
  summaryTableDataGetter,
} from 'core/billing-reports/utils';
import {
  UNITS_TYPES,
} from 'core/constants';
import hash from 'hash-sum';
import moment from 'moment';

import {
  addThousandSeparator,
} from 'utils/helpers/numbers';

import projectLeadsCommissionTableConfigGetter from './config/project-lead-commission-table-config';

const plCommissionsRequiredTotals = [
  { property: 'billedHours' },
  { property: 'comHours' },
  { property: 'forfeitedHours' },
  { property: 'forfeited' },
  { property: 'commissionPaid', valueGetter: getRevenue },
];

const groupId = 'projectLeadCommissions';

export default ({
  isCTO,
  isEmptyRows,
  reportCalculationDate,
  hasPermissionsTOClientModule,
  groupedPlCommissionReportRows,
  hasPermissionsTODeliveryModule,
  hasPermissionsTOTaskOrderModule,
}) => (!isEmptyRows ? [{
  type: UNITS_TYPES.FILTER,
  currentFilter: 'projectLeadCommissions',
  cssRules: {
    clearButtonCssRules: 'display: none;',
    wrapperCssRules: `
      padding: 0 0 1.8rem;
      background-color: transparent;
    `,
    gridTemplateConfig: `
      && {
        .grid-unit--filter {
          background-color: ${colorSecondaryBackground};
        }
        .grid-unit--active {
          background-color: ${colorBackgroundDark};
        }
      }
    `,
  },
},
...Object.values(groupedPlCommissionReportRows).map(({
  label,
  key,
}) => ({
  type: UNITS_TYPES.TABLE,
  currentFilter: 'projectLeadCommissions',
  tableCssRules: `
    .expansion-summary__root--expanded {
      color: ${colorPrimary};
    }
  `,
  summaryTableDataGetter: ((data) => summaryTableDataGetter({
    project: label,
    plName: key,
    requiredTotals: plCommissionsRequiredTotals,
    ...data,
  })),
  useAccumulator: (data) => calculateTotal({
    label,
    groupId,
    isDefaultExpanded: true,
    requiredTotals: plCommissionsRequiredTotals,
    displayWhenNoContent: false,
    ...data,
  }),
  content: [{
    idKey: 'projectId',
    dataKey: `groupedPlCommissionReportRows.${key}.rows`,
    tableName: 'plc',
    preventScrolling: true,
    rowStatusGetter: (row) => {
      const { isGrandTotal } = row;
      const id = hash(row);
      return ({
        id,
        isGrandTotal,
        stylesTemplate: {
          gridTemplateConfig: isGrandTotal ? 'grid-template-columns: minmax(auto, 338fr) minmax(auto, 0fr) minmax(auto, 60fr) minmax(auto, 65fr) minmax(auto, 50fr) minmax(auto, 65fr) minmax(auto, 110fr) minmax(auto, 110fr) minmax(auto, 100fr) minmax(auto, 80fr) minmax(auto, 100fr) minmax(auto, 160fr);' : undefined,
          rowStyles: `&& {
            border-top: 1px solid ${isGrandTotal ? colorSecondaryGrayLight : 'transparent'};
          }`,
        },
      });
    },
    rules: {
      css: {
        gridTemplateConfig: 'grid-template-columns: minmax(auto, 100fr) minmax(auto, 270fr) minmax(auto, 60fr) minmax(auto, 65fr) minmax(auto, 50fr) minmax(auto, 65fr) minmax(auto, 110fr) minmax(auto, 110fr) minmax(auto, 100fr) minmax(auto, 80fr) minmax(auto, 100fr) minmax(auto, 160fr);',
      },
    },
    dataTemplate: projectLeadsCommissionTableConfigGetter({
      isCTO,
      hasPermissionsTOClientModule,
      hasPermissionsTODeliveryModule,
      hasPermissionsTOTaskOrderModule,
    }),
  }],
})),
{
  type: UNITS_TYPES.SINGLE_ROW,
  cssRules: `
    grid-template-columns: auto max-content max-content;
    grid-column-gap: 3.8rem;
    padding-top: 0.4rem;
    order: -1;
  `,
  useAccumulator: ({
    accumulator: {
      hasActiveFilters,
    },
  }) => [{
    type: 'text',
    componentProps: {
      data: `Note: data current as of ${moment(reportCalculationDate, 'YYYY-MM-DD LT').format('L LT')}`,
      wrapperCssRules: `
          display: flex;
          align-items: center;
        `,
      cssRules: `
          display: inline-block;
          font-size: 1.3rem;
          line-height: 1.6rem;
          color: ${colorSecondaryGray};
        `,
    },
  }, {
    type: 'action',
    componentProps: {
      data: 'Clear filters',
      isHidden: !hasActiveFilters,
      cssRules: `
        && {
          color: ${colorPrimary};
        }
      `,
      actionName: 'resetFilters',
      actionArguments: {
        currentFilter: 'projectLeadCommissions',
      },
    },
  }, {
    type: 'groupController',
    componentProps: {
      groupId,
    },
  }],
},
{
  type: UNITS_TYPES.SINGLE_ROW,
  cssRules: `
    display: grid;
    grid-template-columns: minmax(auto, 608fr)  minmax(auto, 210fr) minmax(auto, 100fr) minmax(auto, 180fr) minmax(auto, 160fr);;
    margin: 1.6rem 0 10rem;
    padding: 0 1rem 0 1.6rem;
    border-top: 1px solid ${colorSecondaryGrayLight};
    background-color: ${colorSecondaryGrayLight015};
  `,
  useAccumulator: ({
    accumulator: {
      commissionPaid,
      comHours,
      forfeitedHours,
      forfeited,
      hasActiveFilters,
    },
  }) => [{
    type: 'text',
    componentProps: {
      data: hasActiveFilters ? 'Total with filters' : 'Grand Total',
      cssRules: `
        && {
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 2.6rem;
          color: ${colorSecondaryText};
        }
      `,
    },
  },
  {
    type: 'text',
    componentProps: {
      data: comHours ? `${addThousandSeparator(comHours.toFixed(2))} h` : '0 h',
      cssRules: `
        && {
          padding-right: 0;
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 2.6rem;
          color: ${colorSecondaryText};
          text-align: right;
        }
      `,
    },
  },
  {
    type: 'text',
    componentProps: {
      data: forfeitedHours ? `${addThousandSeparator(forfeitedHours.toFixed(2))} h` : '0 h',
      cssRules: `
        && {
          padding-right: 0;
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 2.6rem;
          color: ${colorSecondaryText};
          text-align: right;
        }
      `,
    },
  },
  {
    type: 'text',
    componentProps: {
      data: forfeited ? `$ ${addThousandSeparator(forfeited.toFixed(2))}` : '$ 0',
      cssRules: `
        && {
          padding-right: 0;
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 2.6rem;
          color: ${colorSecondaryText};
          text-align: right;
        }
      `,
    },
  },
  {
    type: 'text',
    componentProps: {
      data: commissionPaid ? `$ ${addThousandSeparator(commissionPaid.toFixed(2))}` : '$ 0',
      cssRules: `
        && {
          padding-right: 0;
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 2.6rem;
          color: ${colorSecondaryText};
          text-align: right;
        }
      `,
    },
  },
  ],
},
] : [{
  type: UNITS_TYPES.HEADING,
  data: 'Sorry, no content matched your criteria.',
  cssRules: `
    text-align: center;
    font-size: 2.6rem;
    line-height: 2.4rem;
    color: #bbbdc0;
  `,
  wrapperCssRules: `
    margin: auto 0;
  `,
}]);
