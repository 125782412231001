import { channelPartnersActions } from 'core/channel-partners/actions';
import {
  selectErrors as selectChannelPartnerErrors,
  selectRequestsCounter as selectRequestsCounterChannelPartner,
} from 'core/channel-partners/selectors';
import { clientsActions } from 'core/clients/actions';
import {
  selectErrors as selectClientErrors,
  selectRequestsCounter as selectRequestsCounterClients,
} from 'core/clients/selectors';

import { taskOrdersActions } from 'core/task-orders/actions';

import {
  selectErrors as selectTaskOrdersErrors,
  selectRequestsCounter as selectRequestsCounterTaskOrders,
} from 'core/task-orders/selectors';

export const getEntity = (entityType) => {
  switch (entityType) {
    case 'TASK_ORDERS':
      return {
        entityErrors: selectTaskOrdersErrors,
        entityActions: taskOrdersActions,
      };
    case 'CLIENTS':
      return {
        entityErrors: selectClientErrors,
        entityActions: clientsActions,
      };
    case 'CHANNEL_PARTNERS':
      return {
        entityErrors: selectChannelPartnerErrors,
        entityActions: channelPartnersActions,
      };
    default:
      return {};
  }
};

export const getGeneratorEntity = ({ clientId, projectId, msaNumber, title, effectiveDate }, entityName) => {
  switch (entityName) {
    case 'TASK_ORDERS':
      return {
        variables: {
          fields: {},
          fieldsTO: {
            projectId,
            title,
            effectiveDate,
          },
        },
        getDetails: () => taskOrdersActions.getTaskOrdersDetails({ projectId }),
      };
    case 'CLIENTS':
      return {
        variables: {
          fields: {
            clientId,
            msaNumber,
            title,
            effectiveDate,
          },

          fieldsTO: {},
        },
        getDetails: () => clientsActions.getClientDetails({ clientId }),
      };
    default:
      return {};
  }
};

export const getFileLoaderEntity = (entityType) => {
  switch (entityType) {
    case 'TASK_ORDERS':
      return {
        entityErrors: selectTaskOrdersErrors,
        entityActions: taskOrdersActions,
        selectRequestsCounter: selectRequestsCounterTaskOrders,
      };
    case 'CLIENTS':
      return {
        entityErrors: selectClientErrors,
        entityActions: clientsActions,
        selectRequestsCounter: selectRequestsCounterClients,
      };
    case 'CHANNEL_PARTNERS':
      return {
        entityErrors: selectChannelPartnerErrors,
        entityActions: channelPartnersActions,
        selectRequestsCounter: selectRequestsCounterChannelPartner,
      };
    default:
      return {};
  }
};
