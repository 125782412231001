import PropTypes from 'prop-types';

import { DIRECTIONS as SORT_DIRECTIONS } from '../helpers/sorting';

export const children = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const sortDirection =
  PropTypes.oneOf(Object.values(SORT_DIRECTIONS));
