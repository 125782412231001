import { createAction } from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import { ENTITY_NAME } from './constants';

export const employeesReviewsActionsTypes = createTypes([
  ...async('GET_REVIEWS'),
  ...async('SUBMIT_REVIEWS'),
  ...async('GET_PDF_REVIEWS'),
  ...async('SELECT_EMPLOYEE'),
  ...async('SEND_PDF_REVIEWS'),
  ...async('NOTIFY_PROJECT_LEAD'),
  ...async('GET_REVIEWS_COMMON_DATA'),
  'SET_DATE',
  'RESET_FILTERS',
  'SELECT_PROJECTS',
  'SET_ORDER_RULES',
  'RESET_REVIEW_REPORT',
  'UPDATE_COLUMNS_STATE',
  'CHANGE_REVIEW_REPORT_FILTER',
  'SET_ONLY_CATEGORIES_WITH_REVIEWS',
], ENTITY_NAME);

export const employeesReviewsActions = {
  setOnlyCategoriesWithReviews:
    createAction(employeesReviewsActionsTypes.SET_ONLY_CATEGORIES_WITH_REVIEWS, (withReviews) => (withReviews)),

  resetReviewReport: createAction(employeesReviewsActionsTypes.RESET_REVIEW_REPORT),

  notifyProjectLead: createAction(
    employeesReviewsActionsTypes.NOTIFY_PROJECT_LEAD,
    (data) => (data),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  notifyProjectLeadSuccess: createAction(
    employeesReviewsActionsTypes.NOTIFY_PROJECT_LEAD_SUCCESS,
    ({
      projectId,
      notificationDatetime,
    }) => ({
      projectId,
      notificationDatetime,
    }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  notifyProjectLeadFail: createAction(
    employeesReviewsActionsTypes.NOTIFY_PROJECT_LEAD_FAIL,
    ({ error = {} }) => ({ error })
  ),

  sendPdfReviews: createAction(
    employeesReviewsActionsTypes.SEND_PDF_REVIEWS,
    (data) => (data),
    () => ({
      entityName: 'sendReviewModal',
    })
  ),
  sendPdfReviewsSuccess: createAction(
    employeesReviewsActionsTypes.SEND_PDF_REVIEWS_SUCCESS,
    null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  sendPdfReviewsFail: createAction(
    employeesReviewsActionsTypes.SEND_PDF_REVIEWS_FAIL,
    ({ error = {} }) => ({ error })
  ),

  getPdfReviews: createAction(
    employeesReviewsActionsTypes.GET_PDF_REVIEWS,
    (data) => (data)
  ),
  getPdfReviewsSuccess: createAction(employeesReviewsActionsTypes.GET_PDF_REVIEWS_SUCCESS),
  getPdfReviewsFail: createAction(
    employeesReviewsActionsTypes.GET_PDF_REVIEWS_FAIL,
    ({ error = {} }) => ({ error })
  ),

  resetFilters: createAction(employeesReviewsActionsTypes.RESET_FILTERS),

  changeFilter: createAction(
    employeesReviewsActionsTypes.CHANGE_REVIEW_REPORT_FILTER,
    (filters = {}) => (filters),
  ),

  setOrderRules: createAction(
    employeesReviewsActionsTypes.SET_ORDER_RULES,
    ({
      tableName,
      orderRules,
    }) => ({
      tableName,
      orderRules,
    })
  ),

  selectEmployee: createAction(
    employeesReviewsActionsTypes.SELECT_EMPLOYEE,
    ({ employeeId }) => ({ employeeId }),
  ),
  selectEmployeeSuccess: createAction(
    employeesReviewsActionsTypes.SELECT_EMPLOYEE_SUCCESS,
    ({
      employeeReviews,
      firstReviewDate,
      lastReviewDate,
      lastEvaluationDate,
    }) => ({
      employeeReviews,
      firstReviewDate,
      lastReviewDate,
      lastEvaluationDate,
    })
  ),
  selectEmployeeFail: createAction(
    employeesReviewsActionsTypes.SELECT_EMPLOYEE_FAIL,
    ({ error = {} }) => ({ error })
  ),

  updateColumnsState: createAction(
    employeesReviewsActionsTypes.UPDATE_COLUMNS_STATE,
    (payload) => (payload),
  ),

  submitReviews: createAction(
    employeesReviewsActionsTypes.SUBMIT_REVIEWS,
    ({
      roleUpdates = [],
      addedReviews = [],
      blockedReviews = [],
      updatedReviews = [],
      deletedReviews = [],
      unlockedRecords = [],
    }) => ({
      roleUpdates,
      addedReviews,
      blockedReviews,
      updatedReviews,
      deletedReviews,
      unlockedRecords,
    }),
  ),
  submitReviewsSuccess: createAction(
    employeesReviewsActionsTypes.SUBMIT_REVIEWS_SUCCESS,
    ({ reviews }) => ({ reviews })
  ),
  submitReviewsFail: createAction(
    employeesReviewsActionsTypes.SUBMIT_REVIEWS_FAIL,
    ({ error = {} }) => ({ error })
  ),

  setDate: createAction(
    employeesReviewsActionsTypes.SET_DATE,
    (date) => ({ selectedDate: date }),
  ),

  selectProjects: createAction(
    employeesReviewsActionsTypes.SELECT_PROJECTS,
    (projects) => ({ projects }),
  ),

  getReviewsCommonData: createAction(employeesReviewsActionsTypes.GET_REVIEWS_COMMON_DATA),
  getReviewsCommonDataSuccess: createAction(
    employeesReviewsActionsTypes.GET_REVIEWS_COMMON_DATA_SUCCESS,
    ({ employeesList, projectsList }) => ({ employeesList, projectsList })
  ),
  getReviewsCommonDataFail: createAction(
    employeesReviewsActionsTypes.GET_REVIEWS_COMMON_DATA_FAIL,
    ({ error = {} }) => ({ error })
  ),

  getReviews: createAction(employeesReviewsActionsTypes.GET_REVIEWS),
  getReviewsSuccess: createAction(
    employeesReviewsActionsTypes.GET_REVIEWS_SUCCESS,
    ({ reviews, otherReviews, notificationsLog }) => ({ reviews, otherReviews, notificationsLog })
  ),
  getReviewsFail: createAction(
    employeesReviewsActionsTypes.GET_REVIEWS_FAIL,
    ({ error = {} }) => ({ error })
  ),
};
