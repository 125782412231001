import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { reconciliationReportAccess } from 'core/auth/guaranteedAccessRoles';
import { selectUserId } from 'core/auth/selectors';
import { commonActions } from 'core/common/actions';
import reducer from 'core/employees-reviews/reducer';
import saga from 'core/employees-reviews/sagas';
import { filesActions } from 'core/files/actions';
import { reconciliationActions } from 'core/reconciliation/actions';
import {
  selectFilters,
  selectEntityName,
  selectCurrentClientListItem,
  selectedReconciliationReportData,
  selectClientListForReport,
  selectErrors,
} from 'core/reconciliation/selectors';
import { useCheckPermissions } from 'hooks';
import ClientReconciliationReport from 'layouts/client-reconciliation-report';
import compose from 'lodash/fp/compose';
import { Switch, Route, Redirect } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { injectReducer, injectSaga } from 'utils/helpers/injectors';

const Reconciliation = (props) => {
  const {
    entityName,
    setEntityName,
    getReconciliationCommonData,
    ...restProps
  } = props;
  const { isAuthorizedToRoute } = useCheckPermissions(restProps);

  useEffect(() => {
    setEntityName(entityName);
  }, [entityName]);

  useEffect(() => {
    if (isAuthorizedToRoute) {
      getReconciliationCommonData();
    }
  }, [isAuthorizedToRoute]);

  return (
    <Switch>
      <Route
        path={`/${reconciliationReportAccess.route}/:clientId?`}
        render={({
          match: {
            params: { clientId },
          },
        }) => isAuthorizedToRoute ? (
          <ClientReconciliationReport
            clientId={Number(clientId)}
            {...props}
          />
        ) : null}
      />

      <Redirect to="/home" />
    </Switch>
  );
};

Reconciliation.propTypes = {
  userGroup: PropTypes.string,
  entityName: PropTypes.string.isRequired,
  setEntityName: PropTypes.func.isRequired,
  getReconciliationCommonData: PropTypes.func.isRequired,
  guaranteedAccessRolesForSubRoutes: PropTypes.shape(),
  guaranteedAccessRoles: PropTypes.arrayOf(PropTypes.string),
};

Reconciliation.defaultProps = {
  userGroup: '',
  guaranteedAccessRoles: [],
  guaranteedAccessRolesForSubRoutes: null,
};

const mapStateToProps = createStructuredSelector({
  userId: selectUserId,
  filters: selectFilters,
  entityName: selectEntityName,
  reconciliationReportData: selectedReconciliationReportData,
  clientListForReport: selectClientListForReport,
  selectedClient: selectCurrentClientListItem,
  errors: selectErrors,
});
const mapDispatchToProps = {
  setDate: reconciliationActions.setDate,
  setEntityName: commonActions.setEntityName,
  getReconciliation: reconciliationActions.getReconciliation,
  changeFilter: reconciliationActions.changeFilter,
  submitReconciliation: reconciliationActions.submitReconciliation,
  getPdfReconciliation: reconciliationActions.getPdfReconciliation,
  getReconciliationReport: filesActions.generateReconciliationReport,
  getReconciliationSecondReport:
    filesActions.generateReconciliationSecondReport,
  selectEmployee: reconciliationActions.selectEmployee,
  selectProjects: reconciliationActions.selectProjects,
  notifyProjectLead: reconciliationActions.notifyProjectLead,
  updateColumnsState: reconciliationActions.updateColumnsState,
  getReconciliationCommonData: reconciliationActions.getClientNamesList,
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'employeesReconciliation', reducer });
const withSaga = injectSaga({ key: 'employeesReconciliation', saga });

export default compose(withReducer, withSaga, withConnect)(Reconciliation);
