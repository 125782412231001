import { USERS_GROUPS } from 'core/auth/constants';
import { isNull } from 'lodash';

export const generateDevCentersOptionsByBusinessRole = (
  devCentersBySystemRole = [],
  userGroup,
  currentRecordDevcenter,
  isCEO,
  isCTO,
  businessRolesForResource,
  isBusinessRoleForResource,
  isBusinessRoleForCurrentRecord,
  devcentersAll
) => {
  const businessRole = devCentersBySystemRole.find((item) => USERS_GROUPS[item.role] === userGroup) || null;
  const isShowAllAvailableDevCentersForCTO = (isCTO && businessRolesForResource.includes(USERS_GROUPS.CTO));
  const isShowAllAvailableDevCentersForDD = isBusinessRoleForResource && isBusinessRoleForCurrentRecord;

  let devCentersIdsByBusinessRole = businessRole && businessRole.devcenterList ?
    [...businessRole.devcenterList] :
    null;

  if (
    isShowAllAvailableDevCentersForCTO ||
    isCEO ||
    isNull(devCentersIdsByBusinessRole) ||
    isShowAllAvailableDevCentersForDD
  ) {
    return devcentersAll.forSelectShortNames;
  }

  if (devCentersIdsByBusinessRole && !devCentersIdsByBusinessRole.includes(currentRecordDevcenter)) {
    devCentersIdsByBusinessRole = [currentRecordDevcenter, ...devCentersIdsByBusinessRole];
  }

  return devCentersIdsByBusinessRole.map((id) => ({
    label: devcentersAll.byIdShortName[id],
    value: id,
  }));
};
