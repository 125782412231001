import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Select from 'components/select';

import './styles.scss';


const UploadDocumentSelect = ({ fieldConfig, formProps, onChange }) => {
  const { name, label, items, selected } = fieldConfig;
  const { errors, touched } = formProps;

  return (
    <Field
      key={name}
      type="text"
      name={name}
      render={() => (
        <Select
          fieldData={{
            items,
            selected,
          }}
          placeholder={label}
          name={name}
          error={errors.uploadType}
          withError={Boolean(touched[name] && errors[name])}
          onChange={onChange}
          validationRules={{
            isRequired: true,
          }}
        />
      )}
    />
  );
};

UploadDocumentSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  formProps: PropTypes.shape({
  }).isRequired,
  fieldConfig: PropTypes.shape({
  }).isRequired,
};


export default UploadDocumentSelect;
