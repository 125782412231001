import { ENTITY_NAME } from 'core/configurations/constants';
import { createAction } from 'redux-actions';
import { async, createTypes } from 'utils/helpers/actions';

export const emailTemplateNotificationsTypes = createTypes([
  ...async('GET_EMAIL_TEMPLATE_NOTIFICATIONS'),
  ...async('CREATE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION'),
  ...async('UPDATE_EMAIL_TEMPLATE_NOTIFICATIONS'),
  ...async('SEND_EMAIL_TEMPLATE_NOTIFICATIONS_EMAILS'),
  ...async('DELETE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION'),
  ...async('CHECK_EMAILS_NOTIFICATIONS_STATUS'),
  ...async('UPDATE_EMAILS_NOTIFICATIONS_STATUS'),
  'CHANGE_EMAIL_TEMPLATE_NOTIFICATIONS_FILTER',
  'STOP_CHECK_EMAILS_NOTIFICATIONS_STATUS',
  'CLEAR_EMAIL_TEMPLATE_NOTIFICATIONS',
], ENTITY_NAME);

export const emailTemplateNotificationsActions = {
  getEmailTemplateNotifications: createAction(
    emailTemplateNotificationsTypes.GET_EMAIL_TEMPLATE_NOTIFICATIONS,
  ),
  getEmailTemplateNotificationsSuccess: createAction(
    emailTemplateNotificationsTypes.GET_EMAIL_TEMPLATE_NOTIFICATIONS_SUCCESS,
  ),
  getEmailTemplateNotificationsFail: createAction(
    emailTemplateNotificationsTypes.GET_EMAIL_TEMPLATE_NOTIFICATIONS_FAIL,
  ),
  updateEmailTemplateNotifications: createAction(
    emailTemplateNotificationsTypes.UPDATE_EMAIL_TEMPLATE_NOTIFICATIONS,
  ),
  updateEmailTemplateNotificationsSuccess: createAction(
    emailTemplateNotificationsTypes.UPDATE_EMAIL_TEMPLATE_NOTIFICATIONS_SUCCESS,
  ),
  updateEmailTemplateNotificationsFail: createAction(
    emailTemplateNotificationsTypes.UPDATE_EMAIL_TEMPLATE_NOTIFICATIONS_FAIL,
  ),
  changeNotificationsFilter: createAction(emailTemplateNotificationsTypes.CHANGE_EMAIL_TEMPLATE_NOTIFICATIONS_FILTER),
  createEmailTemplateNotificationsVersion: createAction(emailTemplateNotificationsTypes.CREATE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION),
  createEmailTemplateNotificationsVersionSuccess: createAction(emailTemplateNotificationsTypes.CREATE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION_SUCCESS),
  createEmailTemplateNotificationsVersionFail: createAction(emailTemplateNotificationsTypes.CREATE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION_FAIL),
  deleteEmailTemplateNotificationsVersion: createAction(emailTemplateNotificationsTypes.DELETE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION),
  deleteEmailTemplateNotificationsVersionSuccess: createAction(emailTemplateNotificationsTypes.DELETE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION_SUCCESS),
  deleteEmailTemplateNotificationsVersionFail: createAction(emailTemplateNotificationsTypes.DELETE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION_FAIL),
  sendEmailTemplateNotificationsEmails: createAction(emailTemplateNotificationsTypes.SEND_EMAIL_TEMPLATE_NOTIFICATIONS_EMAILS),
  sendEmailTemplateNotificationsEmailsFail: createAction(emailTemplateNotificationsTypes.SEND_EMAIL_TEMPLATE_NOTIFICATIONS_EMAILS_FAIL),
  checkEmailsNotificationsStatus: createAction(emailTemplateNotificationsTypes.CHECK_EMAILS_NOTIFICATIONS_STATUS),
  checkEmailsNotificationsStatusSuccess: createAction(emailTemplateNotificationsTypes.CHECK_EMAILS_NOTIFICATIONS_STATUS_SUCCESS),
  checkEmailsNotificationsStatusFail: createAction(emailTemplateNotificationsTypes.CHECK_EMAILS_NOTIFICATIONS_STATUS_FAIL),
  updateEmailsNotificationsStatus: createAction(emailTemplateNotificationsTypes.UPDATE_EMAILS_NOTIFICATIONS_STATUS),
  updateEmailsNotificationsStatusFail: createAction(emailTemplateNotificationsTypes.UPDATE_EMAILS_NOTIFICATIONS_STATUS_FAIL),
  stopCheckEmailsNotificationsStatus: createAction(emailTemplateNotificationsTypes.STOP_CHECK_EMAILS_NOTIFICATIONS_STATUS),
  clearEmailTemplateNotifications: createAction(emailTemplateNotificationsTypes.CLEAR_EMAIL_TEMPLATE_NOTIFICATIONS),
};
