import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { Dropzone } from 'components/new-dropzone';
import { contractManagementActions } from 'core/delivery/contractManagement/actions';
import { modalConductorActions } from 'core/modal-conductor/actions';
import { AttachmentView } from 'layouts/employee-details/components/resource-tabs/contracts/components/attachment/attachment-view';

import { newContract } from 'layouts/employee-details/components/resource-tabs/contracts/utils';
import { get } from 'lodash';

export const Attachment = ({
  onFileChange,
  file: formFile,
  isAllowedForEdit,
  isAllowedForDelete,
  form,
  errorClassName,
  error,
  setError,
}) => {
  const hasFileName = Boolean(get(formFile, 'name', false));
  const dispatch = useDispatch();
  const confirmRemoveFile = () => {
    // eslint-disable-next-line no-unused-vars
    const { contractRecordId, file, ...fields } = form;
    const data = {
      ...fields,
      contractManagementRecordId: contractRecordId,
      fileName: '',
    };

    if (contractRecordId === newContract.contractRecordId) {
      onFileChange(null);

      return;
    }

    dispatch(contractManagementActions.updateContractManagementRecord({
      ...data,
      allowedForEdit: form.allowedForEdit,
    }));
  };

  const handleRemoveFile = () => {
    dispatch(modalConductorActions.changeCurrentModal({
      currentModal: 'CONFIRM_ACTION_MODAL',
      params: {
        content: [
          {
            type: 'title',
            data: 'Delete this attachment?',
          },
          {
            type: 'description',
            data: 'Once deleted, it cannot be restored.',
          },
        ],
        acceptActionTitle: 'Delete',
        acceptFunction: () => confirmRemoveFile(),
      },
    }));
  };

  if (hasFileName) {
    return (
      <AttachmentView
        hasFileName={hasFileName}
        file={formFile}
        onRemoveFile={handleRemoveFile}
        isAllowedForDelete={isAllowedForDelete}
      />
    );
  }

  return (
    <Dropzone
      accept="application/pdf"
      setFile={onFileChange}
      isAllowedForEdit={isAllowedForEdit}
      maxSize={100}
      errorClassName={errorClassName}
      setError={setError}
      formError={get(error, 'message', '')}
    />
  );
};

Attachment.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  file: PropTypes.shape({}),
  isAllowedForEdit: PropTypes.bool.isRequired,
  isAllowedForDelete: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    contractRecordId: PropTypes.number.isRequired,
    allowedForEdit: PropTypes.bool.isRequired,
    deliveryCenterId: PropTypes.string.isRequired,
    file: PropTypes.shape({
      name: PropTypes.string,
      downloadUrl: PropTypes.string,
    }),
    employmentType: PropTypes.string.isRequired,
    expirationDate: PropTypes.string,
  }).isRequired,
  errorClassName: PropTypes.string,
  setError: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
};

Attachment.defaultProps = {
  file: null,
  errorClassName: '',
  error: null,
};
