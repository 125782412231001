import { isEmpty, pick } from 'lodash';

export function dirtyValues(dirtyFields, allValues) {
  if (isEmpty(dirtyFields)) return null;

  const indexes = Object.keys(dirtyFields);

  if (!Array.isArray(allValues)) {
    return pick(allValues, indexes);
  }

  return allValues.filter((_, index) => indexes.includes(index.toString()));
}

export const removeEmptyProperties = (obj) => {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    if (
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== '' &&
      !(typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
    ) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};
