import { colorSecondaryGrayDark, colorPrimary, colorWhite, backgroundButtons, shadowButtons } from 'assets/styles/variables';

export const actionButtonsStyles = `
    && {
      box-sizing: border-box;
      padding: 0.7rem 1.2rem;
      color: ${colorSecondaryGrayDark};
      line-height: 1.6rem;
      letter-spacing: 0.0125rem;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 500;
      border: 1px solid ${colorSecondaryGrayDark};
      cursor: pointer;
      text-decoration:none;
      text-transform: uppercase;
    }
    &:hover {
      border-color: ${colorPrimary};
      color: ${colorWhite};
      background: ${backgroundButtons};
      box-shadow: ${shadowButtons};
    }
  `;

export const addButtonStyles = `
  ${actionButtonsStyles}
  && {
    margin-top: 1.8rem;
    width: 25.17rem;
  }
`;
