import {
  colorSecondaryText,
  colorBackgroundDark,
  colorSecondaryBackground,
  colorSecondaryGrayLight015,
} from 'assets/styles/variables';
import { STORE_KEYS } from 'core/billing-reports/constants';
import { UNITS_TYPES } from 'core/constants';
import hash from 'hash-sum';
import { get, set, toNumber } from 'lodash';

import annualReportStaffTableConfigGetter from './config/annual-report-staff-table-config';
import usdLabelsGetter from './config/annual-report-staff-total';

const defaultTotalsStaffingTab = () => ({
  [STORE_KEYS.USD]: new Array(12).fill({ rate: 0, rateUsd: 0, counter: 0 }),
  [STORE_KEYS.PLN]: new Array(12).fill({ rate: 0, rateUsd: 0, counter: 0 }),
  [STORE_KEYS.RUR]: new Array(12).fill({ rate: 0, rateUsd: 0, counter: 0 }),
  summary: new Array(12).fill({ rate: 0, counter: 0 }),
});

const totalRowsGetter = ({ currency }) => {
  const usdTotal = {
    totalKey: STORE_KEYS.USD,
    title: 'Total Payload USD',
    subtitle: 'Based Resources',
  };
  const rurTotal = {
    totalKey: STORE_KEYS.RUR,
    title: 'Total Payload RUR',
    subtitle: 'Based Resources',
  };
  const plnTotal = {
    totalKey: STORE_KEYS.PLN,
    title: 'Total Payload PLN',
    subtitle: 'Based Resources',
  };
  const summaryTotal = {
    totalKey: 'summary',
    isGrandTotal: true,
    title: 'Total Payroll',
    subtitle: 'Total Resources',
  };
  const totalsMap = {
    [STORE_KEYS.USD]: usdTotal,
    [STORE_KEYS.PLN]: plnTotal,
    [STORE_KEYS.RUR]: rurTotal,
  };

  const resultsTotals = currency.length !== 0 ?
    currency.map((currentCurrency) => totalsMap[currentCurrency]) :
    [usdTotal, rurTotal, plnTotal];

  if (resultsTotals.length > 1) {
    resultsTotals.push(summaryTotal);
  }
  return resultsTotals;
};

export default ({
  renderedMonthColumns,
  currency,
  hasPermissionsTODeliveryModule,
  activeFilters,
  isEmpty,
  availableDevCentersForDd,
  isCTO,
}) => {
  if (isEmpty) {
    return [
      {
        type: UNITS_TYPES.SINGLE_ROW,
        cssRules: `
          display: flex;
          padding: 0 1.6rem;
          font-size: 2.2rem;
        `,
        rowData: [
          {
            type: 'text',
            componentProps: {
              data: 'Staff',
              cssRules: `
                font-size: 2.2rem;
                line-height: 2.4rem;
                color: ${colorSecondaryText};
              `,
            },
          },
        ],
      },
      {
        type: UNITS_TYPES.EMPTY_MESSAGE,
        title: 'Sorry, no content matched your criteria.',
        description: 'Please, change the selected filters.',
      },
    ];
  }

  const requiredNumbersOfColumns = renderedMonthColumns.length;
  const totalRows = totalRowsGetter({ currency });

  const filters = get(activeFilters, 'annualReportStaffFilter.activeFilters', []);
  const seniorityFilter = filters.find((filterData) => filterData[0] === 'seniority');
  const seniorities = get(seniorityFilter, '[1].selected', []);

  return [
    {
      type: UNITS_TYPES.FILTER,
      title: 'Staff',
      currentFilter: STORE_KEYS.ANNUAL_REPORT_STAFF_FILTER,
      cssRules: {
        titleCssRules: `
          && {
            padding-left: 1.6rem;
            font-size: 2.2rem;
            line-height: 2.6rem;
            color: ${colorSecondaryText};
          }
        `,
        wrapperCssRules: `
          && {
            margin: 1.6rem 0 1rem;
          }
        `,
        gridTemplateConfig: `
          && {
            .grid-unit--filter {
              background-color: ${colorSecondaryBackground};
              button {
                font-family: inherit;
              }
            }
            .grid-unit--active {
              background-color: ${colorBackgroundDark};
            }
          }
          grid-template-columns: minmax(auto, 300fr) minmax(auto, 175fr) minmax(auto, 450fr) minmax(auto, 375fr);
        `,
      },
    },
    {
      type: UNITS_TYPES.TABLE,
      currentFilter: [
        STORE_KEYS.ANNUAL_REPORT_STAFF_FILTER,
        STORE_KEYS.ANNUAL_REPORT_COMMON_FILTER,
      ],
      summaryTableDataGetter: ({
        tableSummaryData,
        currentRow: {
          monthsData,
        },
        setTableSummaryData,
      }) => {
        const {
          withGrandTotal,
          totalsByCurrency = defaultTotalsStaffingTab(),
        } = tableSummaryData;
        const resultsTotalsByCurrency = {
          ...totalsByCurrency,
        };
        monthsData.forEach((monthData, index) => {
          if (monthData == null ||
            (seniorities.length !== 0 && !seniorities.includes(get(monthData, 'seniority')))
          ) {
            return;
          }
          const { seniority, probation, ...salary } = monthData || {};
          const summaryPath = `summary[${index}]`;
          const {
            counter: summaryCounter,
            rate: summaryRate,
          } = get(totalsByCurrency, summaryPath);
          let summaryTotal = 0;

          Object.entries(salary).forEach(([baseCurrency, state]) => {
            const subtotalPath = `${baseCurrency}[${index}]`;
            const rate = get(state, 'rate', 0);
            const rateUsd = get(state, 'rateUsd', 0);
            const {
              counter: subtotalCounter, rate: subtotalRate, rateUsd: subtotalRateUsd,
            } = get(totalsByCurrency, subtotalPath);
            set(resultsTotalsByCurrency, subtotalPath, {
              rate: subtotalRate + toNumber(rate),
              rateUsd: subtotalRateUsd + toNumber(rateUsd),
              counter: subtotalCounter + (toNumber(rate) !== 0 ? 1 : 0),
            });
            summaryTotal += toNumber(rateUsd);
          });
          const summaryMonthRate = summaryRate + toNumber(summaryTotal);
          set(resultsTotalsByCurrency, summaryPath, {
            rate: summaryMonthRate,
            counter: summaryMonthRate === 0 ? summaryCounter : summaryCounter + 1,
          });
        });

        setTableSummaryData({
          ...tableSummaryData,
          totalsByCurrency: resultsTotalsByCurrency,
        });

        if (!withGrandTotal) {
          setTableSummaryData({
            ...tableSummaryData,
            totalsByCurrency: resultsTotalsByCurrency,
            withGrandTotal({
              model,
              idKey,
              resultsData,
              rowStatusGetter,
              tableDataParser,
            }) {
              const totalRowsTableData = totalRows.map(({
                totalKey,
                ...rest
              }) => tableDataParser({
                totalKey,
                isTotal: true,
                totalData: get(this, `totalsByCurrency[${totalKey}]`, []),
                ...rest,
              }, {
                model,
                idKey,
                rowStatusGetter,
              }));
              resultsData.unshift(...totalRowsTableData);
            },
          });
        }
      },
      content: [{
        idKey: 'projectId',
        dataKey: 'annualReportStaffDataRows',
        tableName: 'annualReportStaff',
        preventScrolling: true,
        rowStatusGetter: (row) => {
          const { isTotal, hasSalaryRise } = row;
          const id = hash(row);

          const rowStylesGetter = () => {
            let styles = `
              &:focus-within,
              &:hover {
                background-color: white;
              }
            `;

            if (hasSalaryRise) {
              styles = `
                ${styles}
                background-color: ${colorSecondaryGrayLight015};
              `;
            } else if (isTotal) {
              styles = `
                ${styles}
                margin: 0;
                background-color: #fff;
              }
            `;
            }

            return `
              && {
                ${styles}
              }
            `;
          };

          return ({
            id,
            stylesTemplate: {
              rowStyles: rowStylesGetter(),
            },
          });
        },
        rules: {
          css: {
            gridTemplateConfig: `
              grid-template-columns: minmax(auto, 115fr) repeat(${requiredNumbersOfColumns}, minmax(auto, 85fr)) minmax(auto, 105fr);
              & .grid-unit--with-multi-items {
                display: grid;
                padding-right: 0;
                &:last-child {
                  padding-right: 1.6rem;
                }
              }
            `,
          },
        },
        dataTemplate: annualReportStaffTableConfigGetter({
          currency,
          renderedMonthColumns,
          hasPermissionsTODeliveryModule,
          seniorities,
          availableDevCentersForDd,
          isCTO,
        }),
      }],
    },
  ];
};

export { usdLabelsGetter as staffTotalUSDLabelsGetter };
