/* eslint-disable consistent-return */
import { waitForAuthorization } from 'core/auth/sagas';
import { selectDevstaffId, selectEntityName, selectErrors } from 'core/delivery/selectors';
import {
  CREATE_TECHNICAL_MENTOR_RECORD,
  DELETE_TECHNICAL_MENTOR_RECORD,
  GET_TECHNICAL_MENTOR_RECORDS,
  UPDATE_TECHNICAL_MENTOR_RECORD,
  technicalMentorsActions,
} from 'core/delivery/technicalMentorManagement/actions';
import { get } from 'lodash';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import { executeMutation, executeQuery, parseError } from 'utils/request';

import { queryConfig } from './queries';

function* getTechnicalMentorRecords() {
  try {
    const devstaffId = yield select(selectDevstaffId);
    const query = queryConfig.getTechnicalMentorRecords;
    const options = {
      query,
      variables: {
        devstaffId,
      },
    };
    const { technicalMentorRecords: records } = yield call(executeQuery, options);

    return yield put(technicalMentorsActions.getTechnicalMentorRecordsSuccess({
      records,
      memoDevstaffId: devstaffId,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getTechnicalMentorRecordsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getTechnicalMentorRecordsError = yield call(parseError, error, options);

    yield put(technicalMentorsActions.createTechnicalMentorRecordFail({
      error: {
        getTechnicalMentorRecordsError,
      },
    }));
  }
}

function* createTechnicalMentorRecord({
  payload: {
    // eslint-disable-next-line no-unused-vars
    technicalMentorRecordId,
    ...fields
  },
}) {
  try {
    const devstaffId = yield select(selectDevstaffId);
    const mutation = queryConfig.createTechnicalMentorRecord;
    const options = {
      mutation,
      variables: {
        fields,
        devstaffId,
      },
    };
    const { createTechnicalMentorRecord: record } = yield call(executeMutation, options);

    return yield put(technicalMentorsActions.createTechnicalMentorRecordSuccess(get(record, 'technicalMentorRecord', [])));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'createTechnicalMentorRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const createTechnicalMentorRecordError = yield call(parseError, error, options);

    yield put(technicalMentorsActions.createTechnicalMentorRecordFail({
      error: {
        createTechnicalMentorRecordError,
      },
    }));
  }
}

function* deleteTechnicalMentorRecord({
  payload,
}) {
  try {
    const mutation = queryConfig.deleteTechnicalMentorRecord;
    const options = {
      mutation,
      variables: {
        ...payload,
      },
    };

    yield call(executeMutation, options);

    return yield put(technicalMentorsActions.deleteTechnicalMentorRecordSuccess(get(payload, 'technicalMentorRecordId')));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'deleteTechnicalMentorRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const deleteTechnicalMentorRecordError = yield call(parseError, error, options);

    yield put(technicalMentorsActions.deleteTechnicalMentorRecordFail({
      error: {
        deleteTechnicalMentorRecordError,
      },
    }));
  }
}

function* updateTechnicalMentorRecord({
  payload: {
    technicalMentorRecordId,
    ...fields
  },
}) {
  try {
    const mutation = queryConfig.updateTechnicalMentorRecord;
    const options = {
      mutation,
      variables: {
        technicalMentorRecordId,
        fields,
      },
    };
    const { updateTechnicalMentorRecord: record } = yield call(executeMutation, options);

    return yield put(technicalMentorsActions.updateTechnicalMentorRecordSuccess(get(record, 'technicalMentorRecord', [])));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'updateTechnicalMentorRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const updateTechnicalMentorRecordError = yield call(parseError, error, options);

    yield put(technicalMentorsActions.updateTechnicalMentorRecordFail({
      error: {
        updateTechnicalMentorRecordError,
      },
    }));
  }
}

function* getTechnicalMentorRecordsWatcher() {
  yield takeLatest(GET_TECHNICAL_MENTOR_RECORDS, waitForAuthorization(getTechnicalMentorRecords));
}

function* createTechnicalMentorRecordWatcher() {
  yield takeLatest(CREATE_TECHNICAL_MENTOR_RECORD, waitForAuthorization(createTechnicalMentorRecord));
}

function* deleteTechnicalMentorRecordWatcher() {
  yield takeLatest(DELETE_TECHNICAL_MENTOR_RECORD, waitForAuthorization(deleteTechnicalMentorRecord));
}

function* updateTechnicalMentorRecordWatcher() {
  yield takeLatest(UPDATE_TECHNICAL_MENTOR_RECORD, waitForAuthorization(updateTechnicalMentorRecord));
}

export default [
  getTechnicalMentorRecordsWatcher,
  createTechnicalMentorRecordWatcher,
  deleteTechnicalMentorRecordWatcher,
  updateTechnicalMentorRecordWatcher,
];
