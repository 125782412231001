export const FORM_STATE = {
  INITIAL: 'initial',
  SUBMITTING: 'submitting',
  SUBMITTED_SUCCESS: 'submittedSuccess',
  SUBMITTED_FAIL: 'submittedFail',
};

export const removeFileExtension = (fileName) => {
  const dotIndex = fileName.lastIndexOf('.');

  return fileName.substring(0, dotIndex);
};
