import React, { useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';

import DetailsForm from 'components/details-form';
import Filter from 'components/filter';
import Controls from 'components/list-controls';
import ListFooter from 'components/list-footer';
import Table from 'components/table';

import { USERS_GROUPS } from 'core/auth/constants';

import { STORE_KEYS, ENTITY_NAME_PROJECTS } from 'core/delivery/constants';
import Checkbox from 'elements/checkbox';
import GoTop from 'elements/go-top-button';
import ScreenPlaceholder from 'elements/screen-placeholder';
import Wrapper from 'elements/wrapper';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import { getHasPermissions } from 'utils/auth';
import { updateState } from 'utils/helpers/history';
import { autoScrollToTop } from 'utils/react';

const { CPS } = USERS_GROUPS;

const ProjectLeadsManagement = ({
  location,
  entityName,
  orderRules,
  isFiltering,
  changeOrder,
  projectForm,
  resetFilters,
  changeFilter,
  projectsData,
  projectsCount,
  getAssignedPLs,
  toggleFavorite,
  internalCategoryKey,
  isFormSubmitted,
  selectedProject,
  getProjectsList,
  projectListModel,
  hasActiveFilters,
  isFetchingDetails,
  changeCurrentModal,
  updatePLAssignments,
  filterByAssignmentsPl,
  isFetchingProjectsList,
  projectsListFilterConfig,
  filterByAssignmentsValidPl,
  userGroup,
}) => {
  autoScrollToTop(location);

  const isCPS = getHasPermissions(userGroup, [CPS]);
  const { formId, tableName, rules, currentFilter, noDataMessage } =
    projectListModel;
  const filterCssRules = get(rules, 'css.filtersCssRules');
  const wrapperCssRules = get(rules, 'css.wrapperCssRules');
  const formStylesTemplate = get(rules, 'css.formStylesTemplate', {});
  const screenPlaceholderCssRules = get(rules, 'css.screenPlaceholderCssRules');
  const noPlAssignmentsCheckBoxCssRules = get(
    rules,
    'css.noPlAssignmentsCheckBoxCssRules'
  );
  const noteValidPlAssignmentsCheckBoxCssRules = get(
    rules,
    'css.noteValidPlAssignmentsCheckBoxCssRules'
  );
  const { isActive: isFilterByValidPlAssignmentsActive } =
    filterByAssignmentsValidPl;
  const { isActive: isFilterByPlAssignmentsActive } = filterByAssignmentsPl;
  const currentOrderRules = orderRules[tableName];
  const setOrder = useCallback(({ orderBy }) => changeOrder({ orderBy, tableName, entityName }), [tableName, entityName]);
  const onFilterChange = (filterConfig) => changeFilter({ currentFilter, ...filterConfig });
  const onFiltersReset = () => resetFilters({ currentFilter });
  const entityToggleFavorite = ({ ...args }) => toggleFavorite({ entityName: ENTITY_NAME_PROJECTS, ...args });
  const showProjectsWithNotValidPL = () => changeFilter({
    storeKey: STORE_KEYS.IS_NO_VALID_PL,
    type: 'toggle',
    isActive: !isFilterByValidPlAssignmentsActive,
    currentFilter,
  });
  const showProjectsWithNoPL = () => changeFilter({
    storeKey: STORE_KEYS.IS_NO_PL,
    type: 'toggle',
    isActive: !isFilterByPlAssignmentsActive,
    currentFilter,
  });
  const onSelectProject = ({ internalCategoryKey: selectedProjectKey }) => {
    updateState(
      {},
      `/delivery/project-leads-management/${selectedProjectKey}`
    );
    getAssignedPLs({ internalCategoryKey: selectedProjectKey });
  };

  useEffect(() => {
    getProjectsList();

    if (internalCategoryKey) {
      getAssignedPLs({ internalCategoryKey });
    }

    return () => {
      // We are going to reset filters on layout unmount.
      onFiltersReset();
    };
  }, []);

  const additionalFormActions = {
    changeCurrentModal,
  };

  return (
    <>
      <Controls
        title="PL Assignments"
        hasActiveFilters={hasActiveFilters}
        changeFilter={onFilterChange}
        resetFilters={onFiltersReset}
      >
        <Checkbox
          title="Display only categories with not valid PL Assigned"
          onClick={showProjectsWithNotValidPL}
          cssRules={noteValidPlAssignmentsCheckBoxCssRules}
          {...filterByAssignmentsValidPl}
        />

        <Checkbox
          title="Display only categories with no PL Assigned"
          onClick={showProjectsWithNoPL}
          cssRules={noPlAssignmentsCheckBoxCssRules}
          {...filterByAssignmentsPl}
        />

        <Filter
          cssRules={filterCssRules}
          changeFilter={onFilterChange}
          filterConfig={projectsListFilterConfig}
        />
      </Controls>

      <Wrapper isGrow cssRules={wrapperCssRules}>
        <Table
          entityName="Projects"
          data={projectsData}
          withInfiniteScroll
          model={projectListModel}
          toggleFavorite={entityToggleFavorite}
          changeOrder={setOrder}
          orderRules={currentOrderRules}
          isLoaded={!isFetchingProjectsList && !isFiltering}
          withFilter={hasActiveFilters}
          noDataMessage={noDataMessage}
          unitActions={{ onSelectProject }}
          onSelectActionName="onSelectProject"
        />

        {selectedProject ? (
          <DetailsForm
            content={projectForm}
            entityName={entityName}
            selectedName={selectedProject}
            onSubmit={updatePLAssignments}
            isFormSubmitted={isFormSubmitted}
            stylesTemplate={formStylesTemplate}
            additionalActions={additionalFormActions}
            isFetchingDetails={isFetchingProjectsList || isFetchingDetails}
            withErrorBox
            formId={formId}
            withoutFormControls={isCPS}
            defaultControlsHidden={isCPS}
          />
        ) : (
          <ScreenPlaceholder
            description="Please select a Project."
            cssRules={screenPlaceholderCssRules}
          />
        )}
      </Wrapper>

      <ListFooter
        filteredItemsCount={projectsData.length}
        itemsCount={projectsCount}
        isFetchingItems={isFetchingProjectsList}
      />

      <GoTop />
    </>
  );
};

ProjectLeadsManagement.propTypes = {
  location: PropTypes.object.isRequired,
  selectedProject: PropTypes.string,
  updatePLAssignments: PropTypes.func.isRequired,
  isFetchingDetails: PropTypes.bool.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  projectListModel: PropTypes.shape({}).isRequired,
  currentModal: PropTypes.string,
  getProjectsList: PropTypes.func.isRequired,
  changeOrder: PropTypes.func.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  isFiltering: PropTypes.bool.isRequired,
  orderRules: PropTypes.shape({
    orderBy: PropTypes.string,
  }).isRequired,
  projectsListFilterConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  resetFilters: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  filterByAssignmentsValidPl: PropTypes.shape({
    isActive: PropTypes.bool,
    type: PropTypes.string,
    selected: PropTypes.string,
  }).isRequired,
  filterByAssignmentsPl: PropTypes.shape({
    isActive: PropTypes.bool,
    type: PropTypes.string,
    selected: PropTypes.string,
  }).isRequired,
  getAssignedPLs: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
};

ProjectLeadsManagement.defaultProps = {
  currentModal: null,
  selectedProject: '',
};

export default withRouter(ProjectLeadsManagement);
