import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import { ENTITY_NAME } from '../constants';

export const {
  GET_RESOURCE_MANAGEMENT_COMMENTS,
  GET_RESOURCE_MANAGEMENT_COMMENTS_SUCCESS,
  GET_RESOURCE_MANAGEMENT_COMMENTS_FAIL,
  CREATE_RESOURCE_MANAGEMENT_COMMENT,
  CREATE_RESOURCE_MANAGEMENT_COMMENT_SUCCESS,
  CREATE_RESOURCE_MANAGEMENT_COMMENT_FAIL,
  UPDATE_RESOURCE_MANAGEMENT_COMMENT,
  UPDATE_RESOURCE_MANAGEMENT_COMMENT_SUCCESS,
  UPDATE_RESOURCE_MANAGEMENT_COMMENT_FAIL,
  DELETE_RESOURCE_MANAGEMENT_COMMENT,
  DELETE_RESOURCE_MANAGEMENT_COMMENT_SUCCESS,
  DELETE_RESOURCE_MANAGEMENT_COMMENT_FAIL,
  ADD_RESOURCE_MANAGEMENT_COMMENT,
  REMOVE_RESOURCE_MANAGEMENT_COMMENT,
} = createTypes([
  ...async('GET_RESOURCE_MANAGEMENT_COMMENTS'),
  ...async('CREATE_RESOURCE_MANAGEMENT_COMMENT'),
  ...async('UPDATE_RESOURCE_MANAGEMENT_COMMENT'),
  ...async('DELETE_RESOURCE_MANAGEMENT_COMMENT'),
  'ADD_RESOURCE_MANAGEMENT_COMMENT',
  'REMOVE_RESOURCE_MANAGEMENT_COMMENT',
], ENTITY_NAME);

export const commentManagementActions = {
  getResourceManagementComments: createAction(GET_RESOURCE_MANAGEMENT_COMMENTS),
  getResourceManagementCommentsSuccess: createAction(GET_RESOURCE_MANAGEMENT_COMMENTS_SUCCESS),
  getResourceManagementCommentsFail: createAction(GET_RESOURCE_MANAGEMENT_COMMENTS_FAIL, (errors) => ({ errors })),

  createResourceManagementComment: createAction(CREATE_RESOURCE_MANAGEMENT_COMMENT),
  createResourceManagementCommentSuccess: createAction(CREATE_RESOURCE_MANAGEMENT_COMMENT_SUCCESS, (record) => (record)),
  createResourceManagementCommentFail: createAction(CREATE_RESOURCE_MANAGEMENT_COMMENT_FAIL, (errors) => ({ errors })),

  updateResourceManagementComment: createAction(UPDATE_RESOURCE_MANAGEMENT_COMMENT),
  updateResourceManagementCommentSuccess: createAction(UPDATE_RESOURCE_MANAGEMENT_COMMENT_SUCCESS, (record) => (record)),
  updateResourceManagementCommentFail: createAction(UPDATE_RESOURCE_MANAGEMENT_COMMENT_FAIL, (errors) => ({ errors })),

  deleteResourceManagementComment: createAction(DELETE_RESOURCE_MANAGEMENT_COMMENT),
  deleteResourceManagementCommentSuccess: createAction(DELETE_RESOURCE_MANAGEMENT_COMMENT_SUCCESS, (commentId) => (commentId)),
  deleteResourceManagementCommentFail: createAction(DELETE_RESOURCE_MANAGEMENT_COMMENT_FAIL, (errors) => ({ errors })),

  addResourceManagementComment: createAction(ADD_RESOURCE_MANAGEMENT_COMMENT, (record) => (record)),
  removeResourceManagementComment: createAction(REMOVE_RESOURCE_MANAGEMENT_COMMENT, (recordId) => (recordId)),
};
