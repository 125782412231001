import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useListIntersectionProps, ListIntersectionContext } from 'utils/observer';

const FieldArrayIntersectionContainer = ({ children, matchingData }) => {
  const rowsRef = useRef(null);
  const intersectionProps = useListIntersectionProps(rowsRef);
  useEffect(() => {
    if (rowsRef) {
      rowsRef.current.scrollTo(0, 0);
    }
  }, [matchingData]);
  return (
    <div className={matchingData ? 'intersection-container' : 'intersection-container empty'} ref={rowsRef}>
      <ListIntersectionContext.Provider
        value={intersectionProps}
      >
        {!matchingData &&
          <div className="table__warning">
            <p className="table__warning-title">Sorry, no content matched your criteria. </p>
            <p className="table__warning-description">Please change the selected filters.</p>
          </div>
        }
        {children}
      </ListIntersectionContext.Provider>
    </div>
  );
};

FieldArrayIntersectionContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  matchingData: PropTypes.number,
};

FieldArrayIntersectionContainer.defaultProps = {
  children: [],
  matchingData: 0,
};

export default FieldArrayIntersectionContainer;
