import { USER_ID } from 'core/configurations/user-management';

export const title = 'User Management';

export const fieldHeadings = {
  fullname: 'Full Name',
  email: 'Email',
  role: 'Role',
  title: 'Title',
  billingAgent: 'Billing Agent',
  phone: 'Phone',
  action: 'Action',
};

export const newRecord = {
  userId: USER_ID,
  fullname: '',
  email: '',
  role: {
    value: null,
    label: null,
  },
  title: '',
  billingAgent: {
    value: null,
    label: null,
  },
  phone: '',
  loginLogsHistory: [],
};
