import { CHANGE_FILTER,
  CHANGE_FILTER_SUCCESS,
  RESET_FILTERS,
  SET_ORDER_RULES,
  GET_CLIENTS_WITH_CATEGORIES,
  GET_CLIENTS_WITH_CATEGORIES_SUCCESS,
  GET_CLIENTS_WITH_CATEGORIES_FAIL,
  SET_CLIENT_CATEGORIES,
  UPDATE_CATEGORIES,
  UPDATE_CATEGORIES_SUCCESS,
  UPDATE_CATEGORIES_FAIL,
  DELETE_CATEGORIES,
  DELETE_CATEGORIES_SUCCESS,
  DELETE_CATEGORIES_FAIL,
  RESET_CLIENT,
  SET_CATEGORY_TYPE,
  CHANGE_CATEGORIES_FILTER,
  CHANGE_CATEGORIES_FILTER_SUCCESS,
  RESET_ERRORS,
  RESET_CATEGORIES_FILTER } from 'core/configurations/category-management/actions';

import { ENTITY_NAME, STORE_KEYS, FILTER_TYPES } from 'core/configurations/category-management/constants';
import { sortByActivity, getSortedCategoriesByActivity, getHiqoCategoriesNames } from 'core/configurations/category-management/utils';
import update from 'immutability-helper';
import { CATEGORY_TYPES } from 'layouts/category-management/components/category-tabs/dictionaries';
import { createReducer } from 'redux-create-reducer';

const initialState = {
  entityName: ENTITY_NAME,
  clientsWithCategoriesData: [],
  isFetching: false,
  isFiltering: false,
  isSubmitting: false,
  isFormSubmitted: false,
  isFailed: false,
  keys: {},
  filters: {
    [FILTER_TYPES.CLIENTS_FILTER]: {
      [STORE_KEYS.CLIENT_NAME]: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      [STORE_KEYS.IS_ACTIVE_CLIENT]: {
        isActive: true,
        type: 'toggle',
        selected: true,
      },
    },
  },
  [FILTER_TYPES.CATEGORIES_FILTER]: {
    search: {
      isActive: false,
      storeKey: 'name',
      query: '',
    },
  },
  orderRules: {
    clients: {
      orderBy: 'client',
      defaultOrderBy: 'client',
      isReversed: false,
      isSeparatedByFavorites: true,
      isOffSeparatingManually: false,
    },
    categories: {
      orderBy: 'name',
      defaultOrderBy: 'name',
      isReversed: false,
      isSeparatedByFavorites: true,
      isOffSeparatingManually: false,
    },
  },
  client: {},
  categoryTypes: [],
  errors: {},
};

const categoryManagementReducer = createReducer(
  initialState,
  {
    // update categories
    [UPDATE_CATEGORIES](state) {
      return update(state, {
        isSubmitting: {
          $set: true,
        },
        errors: {
          $unset: ['updateCategoriesErrors'],
        },
        isFormSubmitted: {
          $set: false,
        },
        isFailed: {
          $set: false,
        },
      });
    },
    [UPDATE_CATEGORIES_SUCCESS](state, {
      payload: {
        categories,
        categoryType,
      },
    }) {
      return update(state, {
        isSubmitting: {
          $set: false,
        },
        isFormSubmitted: {
          $set: true,
        },
        isFailed: {
          $set: false,
        },
        client: {
          [categoryType]: {
            $merge: categories.sort(sortByActivity),
          },
        },
      });
    },
    [UPDATE_CATEGORIES_FAIL](state, {
      payload: {
        errors,
      },
    }) {
      return update(state, {
        isSubmitting: {
          $set: false,
        },
        isFormSubmitted: {
          $set: true,
        },
        isFailed: {
          $set: true,
        },
        errors: {
          $merge: errors,
        },
      });
    },
    // delete categories
    [DELETE_CATEGORIES](state) {
      return update(state, {
        isSubmitting: {
          $set: true,
        },
        errors: {
          $unset: ['deleteCategoriesErrors'],
        },
        isFormSubmitted: {
          $set: false,
        },
        isFailed: {
          $set: false,
        },
      });
    },
    [DELETE_CATEGORIES_SUCCESS](state, {
      payload: {
        categories,
        categoryType,
      },
    }) {
      return update(state, {
        isSubmitting: {
          $set: false,
        },
        isFormSubmitted: {
          $set: true,
        },
        isFailed: {
          $set: false,
        },
        client: {
          [categoryType]: {
            $set: categories.sort(sortByActivity),
          },
        },
      });
    },
    [DELETE_CATEGORIES_FAIL](state, {
      payload: {
        errors,
      },
    }) {
      return update(state, {
        isSubmitting: {
          $set: false,
        },
        isFormSubmitted: {
          $set: true,
        },
        isFailed: {
          $set: true,
        },
        errors: {
          $merge: errors,
        },
      });
    },
    // client with categories
    [GET_CLIENTS_WITH_CATEGORIES](state) {
      return update(state, {
        isFetching: {
          $set: true,
        },
      });
    },

    [GET_CLIENTS_WITH_CATEGORIES_SUCCESS](state, {
      payload: {
        clientsWithCategoriesList,
        keys,
      },
    }) {
      return update(state, {
        isFetching: {
          $set: false,
        },
        clientsWithCategoriesData: {
          $merge: clientsWithCategoriesList.map((client) => client.clientId === 0 ?
            {
              ...client,
              isInitial: true,
              isActiveClient: 1,
              names: getHiqoCategoriesNames(client),
            } : client),
        },
        keys: {
          $merge: keys,
        },
        errors: {
          $unset: ['getClientsWithCategoriesListError'],
        },
      });
    },

    [GET_CLIENTS_WITH_CATEGORIES_FAIL](state, {
      payload: {
        error,
      },
    }) {
      return update(state, {
        isFetching: {
          $set: false,
        },
        errors: {
          $merge: error,
        },
      });
    },
    // client filter
    [CHANGE_FILTER](state) {
      return update(state, {
        isFiltering: {
          $set: true,
        },
      });
    },
    [CHANGE_FILTER_SUCCESS](state, {
      payload: {
        currentFilter,
        storeKey,
        config,
      },
    }) {
      return update(state, {
        isFiltering: {
          $set: false,
        },
        filters: {
          [currentFilter]: {
            [storeKey]: {
              $merge: config,
            },
          },
        },
      });
    },
    [RESET_FILTERS](state) {
      return update(state, {
        isFiltering: {
          $set: false,
        },
        filters: {
          $merge: initialState.filters,
        },
      });
    },
    // categories filter
    [CHANGE_CATEGORIES_FILTER](state) {
      return update(state, {
        isCategoriesFiltering: {
          $set: true,
        },
      });
    },
    [CHANGE_CATEGORIES_FILTER_SUCCESS](state, {
      payload: {
        search,
      },
    }) {
      return update(state, {
        isCategoriesFiltering: {
          $set: false,
        },
        [FILTER_TYPES.CATEGORIES_FILTER]: {
          search: {
            $merge: search,
          },
        },
      });
    },
    [RESET_CATEGORIES_FILTER](state) {
      return update(state, {
        isCategoriesFiltering: {
          $set: false,
        },
        [FILTER_TYPES.CATEGORIES_FILTER]: {
          $merge: initialState[FILTER_TYPES.CATEGORIES_FILTER],
        },
      });
    },
    [RESET_CLIENT](state) {
      return update(state, {
        client: {
          clientId: {
            $set: initialState.client.clientId,
          },
        },
      });
    },
    [SET_ORDER_RULES](state, {
      payload: {
        tableName,
        orderRules,
      },
    }) {
      return update(state, {
        orderRules: {
          [tableName]: {
            $set: orderRules,
          },
        },
      });
    },
    [SET_CLIENT_CATEGORIES](state, {
      payload: {
        client,
      },
    }) {
      return update(state, {
        client: {
          $set: getSortedCategoriesByActivity(client),
        },
        categoryTypes: {
          $set: client.clientId ?
            [CATEGORY_TYPES.CLIENT, CATEGORY_TYPES.INTERNAL] :
            [CATEGORY_TYPES.HIQO_OOO, CATEGORY_TYPES.INTERNAL],
        },
      });
    },
    [SET_CATEGORY_TYPE](state, {
      payload: {
        categoryType,
      },
    }) {
      return update(state, {
        categoryType: {
          $set: categoryType,
        },
      });
    },
    [RESET_ERRORS](state) {
      return update(state, {
        errors: {
          $set: initialState.errors,
        },
        isFailed: {
          $set: false,
        },
      });
    },
  }
);

export default categoryManagementReducer;
