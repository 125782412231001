import { colorSecondaryGrayLight } from 'assets/styles/variables';
import { toNumber } from 'lodash';
import { showResourceLinkByDevCenter } from 'utils/helpers/link';

import { addThousandSeparator, roundValue } from 'utils/helpers/numbers';

export default ({
  isCTO,
  isHRM,
  isChefs,
  isPtmReport,
  hasPermissionsTODeliveryModule,
  projectType,
  isDTOReport,
  availableDevCentersForDd,
}) => {
  const projectTypeForHrlyRate = projectType === 'ODC' || projectType === 'TTM';
  const isTOorHRM = isHRM || !isDTOReport;

  return [
    {
      columnName: 'Full name',
      headerProps: {
        cssRules: 'min-height: 2.8rem;',
      },
      valueGetter: ({
        fullname,
        devstaffId,
        resource,
        isGrandTotal,
        project,
        devcenterId,
      }) => {
        if (isGrandTotal) {
          return {
            type: 'text',
            componentProps: {
              data: `Total ${project}`,
              cssRules: `
                font-size: 1.5rem;
                font-weight: 500;`,
            },
          };
        }
        return {
          type:
            devstaffId && showResourceLinkByDevCenter(isCTO, hasPermissionsTODeliveryModule, availableDevCentersForDd, devcenterId) ?
              'link' :
              'text',
          componentProps: {
            data: fullname || resource || 'n/a',
            pathname: `/delivery/employee/${devstaffId}/${
              isChefs ? 'work-book' : 'pto-requests'
            }`,
            cssRules: 'font-size: 1.2rem;',
            isMultiline: true,
          },
        };
      },
    },
    {
      columnName: 'Devcenter',
      shouldntBeRendered: !isDTOReport,
      headerProps: {
        cssRules: 'padding-right: 2.8rem;',
      },
      valueGetter: ({ devcenterShortname, isGrandTotal }) => {
        if (isGrandTotal) {
          return {
            shouldntBeRendered: !isDTOReport,
          };
        }

        return {
          value: devcenterShortname,
          type: 'text',
          shouldntBeRendered: !isDTOReport,
        };
      },
    },
    {
      columnName: 'Seniority',
      valueGetter: ({ seniority, isGrandTotal }) => {
        if (isGrandTotal) {
          return {};
        }
        return {
          value: seniority,
          type: 'text',
        };
      },
    },
    {
      columnName: 'Hours',
      headerProps: {
        cssRules: `${isHRM ? 'margin-left: auto;' : ''}`,
      },
      valueGetter: ({ hours, isGrandTotal }) => {
        const value = addThousandSeparator(toNumber(hours || 0).toFixed(2));

        return {
          value: hours ? value : '0.00',
          type: 'text',
          cssRules: `&& {
            text-align: ${isHRM ? 'right' : 'left'};
            padding-right: ${isHRM ? '1rem' : 'calc(100% - 6ch)'};
            ${
  isGrandTotal ?
    `
            font-weight: 500;` :
    ''
}
        }`,
        };
      },
    },
    {
      columnName: 'Discount/\nSurcharge',
      headerProps: {
        cssRules: `
        padding-right: 1rem;`,
      },
      shouldntBeRendered: isTOorHRM,
      valueGetter: ({ surchargePercentage, isGrandTotal }) => {
        if (isGrandTotal) {
          return {
            shouldntBeRendered: isTOorHRM,
          };
        }

        const value = `${addThousandSeparator(roundValue(surchargePercentage, 2))}%`;
        return {
          value,
          shouldntBeRendered: isTOorHRM,
          type: 'text',
        };
      },
    },
    {
      columnName: projectTypeForHrlyRate ? 'Hrly Rate' : 'Rate',
      headerProps: {
        cssRules: `
        padding-right: 1rem;`,
      },
      shouldntBeRendered: isHRM || isPtmReport,
      valueGetter: ({ rateWithSurcharge, rate, isGrandTotal, isDiscount }) => {
        if (isGrandTotal) {
          return {
            shouldntBeRendered: isHRM || isPtmReport,
          };
        }
        const hasAsterisk = isDiscount && !isDTOReport;
        const value = `${addThousandSeparator(roundValue(isDTOReport ? rateWithSurcharge : rate, 2))}${hasAsterisk ? '*' : ''}`;
        return {
          value,
          shouldntBeRendered: isHRM || isPtmReport,
          type: 'text',
        };
      },
    },
    {
      columnName: 'Amount',
      shouldntBeRendered: isHRM || isPtmReport,
      headerProps: {
        cssRules: `
        margin-left: auto;
        padding-right: 1rem;`,
      },
      valueGetter: ({ totalWithSurcharge, total, isGrandTotal }) => {
        const data = addThousandSeparator(roundValue(isDTOReport ? totalWithSurcharge : total, 2));

        return {
          type: 'text',
          shouldntBeRendered: isHRM || isPtmReport,
          componentProps: {
            data, // grid content
            cssRules: `&& {
              padding-right: 0;
              overflow: visible;
              text-align: right;
              ${
  !totalWithSurcharge && !total ?
    `color: ${colorSecondaryGrayLight}` :
    ''
}
              ${
  isGrandTotal ?
    `
              font-weight: 500;` :
    ''
}
            }`,
          }, // grid unit wrapper styles
          cssRules: `&& {
            padding-right: 1rem;
          }`,
        };
      },
    },
  ];
};
