import React from 'react';

import CustomLink from 'elements/link';
import Text from 'elements/plain-text';
import { getContractExpirationErrorRows } from 'layouts/resource-management-report/config/heplers/errorRows';
import moment from 'moment';

import { stringSortIgnoreCase } from './heplers/stringSortIgnoreCase';

export const contractExpiration = (data, {
  locales,
  showResourceLinkByDevCenter,
  isCTO,
  availableDevCenters,
  hasPermissions,
}) => ({
  id: 'contractExpiration',
  title: locales.contractExpirationRows.title,
  columns: [
    {
      Header: locales.contractExpirationRows.date,
      accessor: 'contractExpirationDate',
      Cell: (cellInfo) => moment(cellInfo.row.original.contractExpirationDate).format('MM/DD/YYYY'),
    },
    {
      Header: locales.resource,
      accessor: 'fullname',
      Cell: (cellInfo) => {
        const { row: { original: { devcenterId, devstaffId, fullname } } } = cellInfo;

        if (showResourceLinkByDevCenter(isCTO, hasPermissions, availableDevCenters, devcenterId)) {
          return (
            <CustomLink
              pathname={`/delivery/employee/${devstaffId}/work-book`}
              id={devstaffId}
              data={fullname}
            />
          );
        }

        return <Text data={fullname} />;
      },
      sortType: stringSortIgnoreCase,
    },
    {
      Header: locales.deliveryCenter,
      accessor: 'devcenter',
    },
    {
      Header: locales.employmentType,
      accessor: 'employmentType',
    },
  ],
  errorRows: getContractExpirationErrorRows(data),
  data,
  isEmpty: data.length === 0,
});
