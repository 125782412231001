import {
  managePtoRemoveButtonCss,
  managePtoAddButtonCss,
  managePtoFieldArrayCss,
} from 'assets/styles/config';
import { colorPrimary, colorSecondaryGrayLight } from 'assets/styles/variables';
import { get } from 'lodash';
import { PTO_DAYS_ACTION_TYPES } from 'utils/constants';
import { getPTODaysValidation } from 'utils/validation';
import { object, array, string, number } from 'yup';

const modalHeaderTitleCSS = `
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: ${colorPrimary};
  border: 0;`;

const modalHeaderDescriptionCss = `
  font-size: 1.6rem;
  line-height: 2.4rem;
  border: 0;`;

export const managePtoConfigGetter = ({
  remaining,
  yearType,
}) => {
  const isNextYear = yearType === 'next';
  const ptoDaysActionsData = () => {
    switch (yearType) {
      case 'next':
        return {
          items: [
            {
              value: 'transfer from',
              label: 'Transfer to the previous year',
            },
          ],
          selected: {
            value: 'transfer from',
            label: 'Transfer to the previous year',
          },

        };

      case 'current':
        return {
          items: [
            {
              value: PTO_DAYS_ACTION_TYPES.TRANSFER_TO_THE_NEXT_YEAR,
              label: 'Transfer to the next year',
            },
            {
              value: PTO_DAYS_ACTION_TYPES.ANNUL,
              label: 'Annulling',
            },
            {
              value: PTO_DAYS_ACTION_TYPES.SET_TOTAL_DAYS,
              label: 'Set total days',
            },
            {
              value: PTO_DAYS_ACTION_TYPES.COMPENSATE,
              label: 'Compensate',
            },
            {
              value: PTO_DAYS_ACTION_TYPES.ADD_DAYS,
              label: 'Add days',
            },
          ],
          selected: {},
        };

      case 'prev':
        return {
          items: [
            {
              value: PTO_DAYS_ACTION_TYPES.TRANSFER_TO_THE_NEXT_YEAR,
              label: 'Transfer to the next year',
            },
            {
              value: PTO_DAYS_ACTION_TYPES.ANNUL,
              label: 'Annulling',
            },
            {
              value: PTO_DAYS_ACTION_TYPES.COMPENSATE,
              label: 'Compensate',
            },
          ],
          selected: {},
        };

      default:
        return {};
    }
  };

  const filedArrayRootName = 'ptoDays';

  return {
    type: 'form',

    stylesTemplate: {
      formControlsCss: `
      grid-template-columns: 25.2rem 25.2rem;
      grid-column-gap: 2.4rem;
      margin: 3.8rem 0 0 7.2rem;
      max-width: calc(100% - 7.2rem);
    `,

      modalCss: `
      &.modal-overlay {
        justify-content: flex-start;
        padding-top: 19.5rem;
      }

      &.modal-overlay__wrapper {
        max-height: 70vh;
        padding: 2.4rem 3.2rem 2rem 2.4rem;
        overflow-y: auto;
        overflow-x: hidden;
      }
    `,
    },

    content: [
      {
        stylesTemplate: {
          columns: '1fr',
          gridRowGap: '0',
          marginTop: '1.2rem',
          padding: '0',
        },

        formData: [
          {
            isFieldArray: true,
            name: filedArrayRootName,
            valueGetter: () => {
              const defaultLineValues = {
                days: null,
                actionType: isNextYear ? 'transfer from' : null,
                remark: '',
              };
              const initialFieldArrayValues = [
                defaultLineValues,
              ];
              const onPushLine = ({ values = [], addLine, setFieldError, setFieldTouched }) => {
                const emptyLines = values.findIndex(({ days }) => !days);

                if (emptyLines !== -1) {
                  setFieldError(`${filedArrayRootName}[${emptyLines}].days`, 'Please select period');
                  setFieldTouched(`${filedArrayRootName}[${emptyLines}].days`, true);
                } else {
                  addLine(defaultLineValues);
                }
              };
              const onDeleteLine = ({ index, values = [], removeLine, addLine }) => {
                if (values.length > 1) {
                  removeLine(index);
                } else {
                  removeLine(index);
                  addLine(defaultLineValues);
                }
              };
              const getRedistributedDays = (ptoRedistributedRecords) => ptoRedistributedRecords
                // .reduce((acc, {days = 0}) => acc + Number(days),0);
                .reduce((acc, { days = 0 }, index) => {
                  if (ptoRedistributedRecords[index].actionType === PTO_DAYS_ACTION_TYPES.ADD_DAYS ||
                    ptoRedistributedRecords[index].actionType === PTO_DAYS_ACTION_TYPES.SET_TOTAL_DAYS
                  ) {
                    return acc;
                  }

                  return acc + Number(days);
                }, 0);
              const getAdditionalDays = (ptoRedistributedRecords) => ptoRedistributedRecords
                .reduce((acc, { days = 0 }, index) => {
                  if (ptoRedistributedRecords[index].actionType === PTO_DAYS_ACTION_TYPES.ADD_DAYS) {
                    return acc + Number(days);
                  }

                  return acc;
                }, 0);

              return {
                onPushLine,
                onDeleteLine,
                value: initialFieldArrayValues,

                lineGetter: ({ index, lineValues }) => {
                  const { days, remark } = lineValues || {};

                  return {
                    lineId: `${index}`,
                    line: [
                      {
                        type: 'text',
                        name: `${filedArrayRootName}[${index}].days`,
                        fieldData: {
                          value: days,
                        },
                        placeholder: 'Days',
                        onFormChange: ({ values: { ptoDays: storedPtoDays }, setFieldValue }) => {
                          const lineDays = get(storedPtoDays, `[${index}].days`, 0);
                          const available = getRedistributedDays(storedPtoDays);
                          const addedDays = getAdditionalDays(storedPtoDays);
                          const maxAvailableDays = ((remaining + addedDays) - available) + Number(lineDays);
                          const isSetTotalDaysActionType = storedPtoDays[index].actionType === PTO_DAYS_ACTION_TYPES.SET_TOTAL_DAYS;
                          const isAddDaysActionType = storedPtoDays[index].actionType === PTO_DAYS_ACTION_TYPES.ADD_DAYS;

                          if (Number(lineDays) > maxAvailableDays) {
                            setFieldValue(`${filedArrayRootName}[${index}].days`, maxAvailableDays);
                          }
                          return {
                            validationRules: {
                              isRequired: true,
                              isNumeric: true,
                              decimalScale: 2,
                              customValidation: getPTODaysValidation({
                                isAddDaysActionType,
                                isSetTotalDaysActionType,
                              }, maxAvailableDays),
                            },
                          };
                        },
                        cssRules: `
                        && {
                          .text-input {
                            &__input-field {
                              text-align: center;
                              border-color: ${colorSecondaryGrayLight};
                            }

                            &__label {
                              left: 50%;
                              line-height: 2.6rem;
                              transform: translateX(-50%);
                            }

                            &__label--visible {
                              left: 50%;
                              transform: translateX(-50%);
                            }
                          }
                        }`,
                      },
                      {
                        type: 'select',
                        name: `${filedArrayRootName}[${index}].actionType`,
                        placeholder: 'Action',
                        validationRules: {
                          isRequired: true,
                        },
                        fieldData: ptoDaysActionsData(),
                        // menuShouldBlockScroll: true,
                        menuPosition: 'fixed',
                      },
                      {
                        type: 'actionButton',
                        data: 'Delete',
                        actionName: `${filedArrayRootName}_deleteLine`,
                        actionArguments: {
                          index,
                        },
                        cssRules: managePtoRemoveButtonCss,
                      },
                      {
                        type: 'empty',
                      },
                      {
                        type: 'text',
                        name: `${filedArrayRootName}[${index}].remark`,
                        fieldData: {
                          value: remark,
                        },
                        isMultiline: true,
                        placeholder: 'remark',
                        cssRules: `
                        .text-input__input-field.text-input__input-field--textarea {
                          font-size: 1.4rem;
                          line-height: 1.8rem;
                        }`,
                      },
                      {
                        type: 'empty',
                      },
                    ],

                    stylesTemplate: {
                      cssRules: `
                      grid-template-columns: minmax(0, 51fr) minmax(0, 617fr) minmax(0, 44fr);
                      grid-template-rows: minmax(2.4rem, auto) minmax(2.4rem, auto);
                      grid-column-gap: 2.4rem;
                      grid-row-gap: 0.8rem;
                      margin-top: 0;
                      padding: 0;
                    `,
                    },
                  };
                },

                fieldArrayCss: managePtoFieldArrayCss,

                headersConfig: {
                  stylesTemplate: {
                    cssRules: `
                    grid-template-columns: min-content min-content 1fr;
                    grid-auto-rows: auto;
                    grid-column-gap: 0.5ch;
                    grid-row-gap: 0;
                    margin-top: 0;
                    padding: 0 0 1.6rem;
                    border-bottom: 1px solid ${colorSecondaryGrayLight};`,
                  },

                  data: [
                    {
                      type: 'viewBox',
                      fieldData: {
                        value: remaining,
                      },
                      onFormChange: ({ values: { ptoDays: storedPtoDays } }) => {
                        const value = (remaining - getRedistributedDays(storedPtoDays)) + getAdditionalDays(storedPtoDays) || 0;

                        return {
                          fieldData: {
                            value,
                          },
                        };
                      },
                      cssRules: modalHeaderTitleCSS,
                    },
                    {
                      type: 'viewBox',
                      fieldData: {
                        value: remaining !== 1 ? 'days' : 'day',
                      },
                      onFormChange: ({ values: { ptoDays: storedPtoDays } }) => {
                        const value = (remaining - getRedistributedDays(storedPtoDays)) + getAdditionalDays(storedPtoDays) || 0;
                        return {
                          fieldData: {
                            value: value !== 1 ? 'days' : 'day',
                          },
                        };
                      },
                      cssRules: `
                      ${modalHeaderTitleCSS}
                      font-size: 1.6rem;`,
                    },
                    {
                      type: 'viewBox',
                      fieldData: {
                        value: '- available for redistribution',
                      },
                      cssRules: modalHeaderDescriptionCss,
                    },
                  ],
                },

                footerConfig: {
                  stylesTemplate: {
                    columns: '51fr 617fr 44fr',
                    gridColumnGap: '2.4rem',
                    gridRowGap: '0',
                    marginTop: '3.2rem',
                    gridAutoRows: 'minmax(3.2rem, auto)',
                    padding: '0',
                  },
                  data: [
                    {
                      type: 'empty',
                    },
                    {
                      type: 'actionButton',
                      data: 'ADD',
                      actionName: `${filedArrayRootName}_addLine`,
                      withBody: true,

                      cssRules: managePtoAddButtonCss,
                    },
                  ],
                },
              };
            },

            validationRules: {
              schemaGetter: () => array()
                .of(object().shape({
                  days: number()
                    .nullable()
                    .min(0.5, 'Min value is 0.5 days')
                    .required('Required field'),
                  actionType: string().nullable().required('Required field'),
                  remark: string()
                    .max(200, 'remark max length is 200'),
                })),
            },
          },
        ],
      },
    ],
  };
};
