import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  colorPrimary,
  colorSecondaryGrayDark,
  colorSecondaryGrayLight,
} from 'assets/styles/variables';
import { isNil } from 'lodash';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const StyledPath = styled.path`
  fill: ${({ isActive, mainColor }) => isActive ? mainColor : colorSecondaryGrayLight};
  ${({ withPointer }) => withPointer ? 'cursor: pointer;' : ''}
`;

const StyledBody = styled.path`
  fill: ${({ isActive, mainColor }) => isActive ? mainColor : 'none'};
  ${({ withPointer }) => withPointer ? 'cursor: pointer;' : ''}
`;

const StarsRating = ({ isViewMode, isLocked, rating, onClick, isError }) => {
  let timeout;
  const [hoveredItemIndex, setHoveredItemIndex] = useState();
  const isSuccess = isNil(hoveredItemIndex) ? rating > 2 : hoveredItemIndex > 1;
  const onMouseLeave = () => {
    timeout = setTimeout(setHoveredItemIndex, 200);
  };

  return (
    <StyledContainer>
      {
        Array(5).fill('----').map((placeholder, index) => {
          const onMouseEnter = () => {
            clearTimeout(timeout);
            setHoveredItemIndex(index);
          };
          const isActive = (isNil(hoveredItemIndex) ? index < rating : index <= hoveredItemIndex);

          if (isLocked) {
            return (
              <span
                key={index} // eslint-disable-line react/no-array-index-key
                className="form-slider__placeholder"
              >
                {placeholder}
              </span>
            );
          }
          const withPointer = onClick && !isViewMode;
          const actionProps = withPointer ? {
            onMouseEnter,
            onMouseLeave,
            onClick: () => onClick(index + 1),
          } : {};

          return (
            <svg
              key={index} // eslint-disable-line react/no-array-index-key
              className="form-slider__image"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              {...actionProps}
            >
              <StyledPath
                mainColor={isSuccess || isError ? colorPrimary : colorSecondaryGrayDark}
                isActive={isActive || isError}
                withPointer={withPointer}
                d="M23 9.964L15.091 9.282L12 2L8.909 9.293L1 9.964L7.006 15.167L5.202 22.9L12 18.797L18.798 22.9L17.005 15.167L23 9.964ZM12 16.74L7.864 19.237L8.964 14.529L5.312 11.361L10.13 10.943L12 6.51L13.881 10.954L18.699 11.372L15.047 14.54L16.147 19.248L12 16.74Z"
              />

              <StyledBody
                mainColor={isSuccess ? colorPrimary : colorSecondaryGrayDark}
                isActive={isActive}
                withPointer={withPointer}
                d="M12 18.797L18.798 22.9L16.994 15.167L23 9.964L15.091 9.293L12 2L8.909 9.293L1 9.964L7.006 15.167L5.202 22.9L12 18.797Z"
              />
            </svg>
          );
        })
      }
    </StyledContainer>
  );
};

StarsRating.propTypes = {
  isViewMode: PropTypes.bool,
  isLocked: PropTypes.bool,
  onClick: PropTypes.func,
  rating: PropTypes.number,
  isError: PropTypes.bool,
};

StarsRating.defaultProps = {
  isViewMode: false,
  isLocked: false,
  onClick: null,
  isError: false,
  rating: 0,
};

export default StarsRating;
