import {
  notRevenue,
} from 'core/auth/guaranteedAccessRoles';
import { selectUserGroup } from 'core/auth/selectors';
import resourceRowsDataTemplate from 'layouts/delivery-center-utilization-by-month/resources-details-columns-config';
import {
  resourceRowsCssRules,
  summaryRowsCssRules,
} from 'layouts/delivery-center-utilization-by-month/styles';
import totalsDataTemplate from 'layouts/delivery-center-utilization-by-month/summary-columns-config';
import { createSelector } from 'reselect';

import { getHasPermissions } from 'utils/auth';
import {
  stringCompareFunction,
  numberCompareFunction,
} from 'utils/helpers/sorting';

import { STORE_KEYS, BILL_TYPES, PROJECTS_TYPES } from './constants';

const sortedList = (list) => list.sort(stringCompareFunction);

export const selectDevCenters = (state) => state.auth.devcenters;

const selectLocalState = (state) => state.deliveryCenterUtilizationByMonth;

export const selectEntityName = createSelector(
  selectLocalState,
  (state) => state.entityName
);
export const selectErrors = createSelector(
  selectLocalState,
  (state) => state.errors
);
export const selectDetailReport = createSelector(
  selectLocalState,
  (state) => state.resourceRows
);
export const selectDetailReportSummary = createSelector(
  selectLocalState,
  (state) => state.summaryRows
);
export const selectOrderRules = createSelector(
  selectLocalState,
  (state) => state.orderRules
);
export const selectIsFetching = createSelector(
  selectLocalState,
  (state) => state.isFetching
);

export const selectFilters = createSelector(
  selectLocalState,
  (state) => state.filters
);
export const selectMainFilters = createSelector(
  selectLocalState,
  (state) => state.mainFilters
);
export const selectSelectedDevCenterIds = createSelector(
  selectMainFilters,
  (state) => state[STORE_KEYS.DEVCENTER]
);
export const selectSelectedReportDate = createSelector(
  selectMainFilters,
  (state) => state[STORE_KEYS.MONTH]
);
export const selectProjectTypes = createSelector(
  selectFilters,
  () => PROJECTS_TYPES
);
export const selectBillTypes = createSelector(selectFilters, () => BILL_TYPES);

export const selectFiltersSelected = createSelector(
  selectFilters,
  selectDetailReport,
  ({ resource, taskOrder, projectType, billType }, resourceRows) => {
    const selectedResource = resourceRows.filter((item) => resource.selected.includes(item.resource));

    return {
      resource: selectedResource,
      resourceItems: resource.selected,
      taskOrder: taskOrder.selected,
      projectType: projectType.selected,
      billType: billType.selected,
    };
  }
);

export const selectSelectedDateFormatted = createSelector(
  selectSelectedReportDate,
  (selectedReportDate) => selectedReportDate ? selectedReportDate.format('MMMM YYYY') : ''
);

export const selectSelectedFullDateFormatted = createSelector(
  selectSelectedReportDate,
  (selectedReportDate) => selectedReportDate ? selectedReportDate.format('L LT') : ''
);

const countLogHours = (acc, { logHours }) => acc + logHours;

const whereDetailsExists = (item) => item.details && item.details.length;

const applyFilters =
  ({ taskOrder, projectType, billType }) => (item) => {
    const taskOrderNYARules =
      item.taskOrder === null ?
        taskOrder.includes('NYA') :
        taskOrder.includes(String(item.taskOrder));
    const taskOrderINTRules =
      item.billType === 'INT' && item.taskOrder === null ?
        taskOrder.includes('INT') :
        taskOrder.includes(String(item.taskOrder));

    return (
      (!taskOrder.length || taskOrderNYARules || taskOrderINTRules) &&
      (!projectType.length || projectType.includes(item.projectType)) &&
      (!billType.length || billType.includes(item.billType))
    );
  };

const resourceWithDetailsFilterApplied =
  ({ taskOrder, projectType, billType }) => (resource) => {
    if (!(taskOrder || projectType.length || billType.length)) {
      return resource;
    }

    const { details, ...rest } = resource;

    const filteredDetails = details.filter(
      applyFilters({ taskOrder, projectType, billType })
    );

    return {
      details: filteredDetails,
      ...rest,
      total: filteredDetails.reduce(countLogHours, 0),
    };
  };

// TODO: Make data structure plain resources[].details[] -> resources[], details[].filter({ resource })
export const selectDetailReportFiltered = createSelector(
  selectFiltersSelected,
  selectDetailReport,
  ({ resourceItems, taskOrder, projectType, billType }, resourceRows) => {
    if (!resourceRows) {
      return [];
    }

    const filterDetails = resourceWithDetailsFilterApplied({
      taskOrder,
      projectType,
      billType,
    });

    if (!resourceItems.length) {
      return resourceRows.flatMap(filterDetails).filter(whereDetailsExists);
    }

    return resourceRows
      .filter((item) => resourceItems.includes(item.resource))
      .flatMap(filterDetails)
      .filter(whereDetailsExists);
  }
);

const getTableData = (dataTemplate, detail, userGroup) => dataTemplate.map(({ valueGetter }) => valueGetter(detail, userGroup));

const createDetailsMap =
  ({ stylesTemplate, dataTemplate, userGroup }) => (detail) => ({
    id: `${detail.logId}`,
    tableData: getTableData(dataTemplate, detail, userGroup),
    stylesTemplate,
    isFilter: true,
    hasWarning: false,
    isActive: true,
  });

export const selectDetailReportSorted = createSelector(
  selectDetailReportFiltered,
  selectOrderRules,
  (resourceRows, sortingRules) => {
    const { orderBy, isReversed } = sortingRules.detailReport;
    let valueKey;
    switch (orderBy) {
      case 'Date':
        valueKey = 'logDate';
        break;
      case 'Project':
        valueKey = 'projectName';
        break;
      case 'TO':
        valueKey = 'taskOrder';
        break;
      case 'Type':
        valueKey = 'projectType';
        break;
      case 'Billable':
        valueKey = 'billType';
        break;
      case 'Hours':
        valueKey = 'logHours';
        break;
      default:
        valueKey = '';
    }
    return resourceRows.map((item) => {
      item.details.sort((a, b) => valueKey === 'taskOrder' || valueKey === 'logHours' ?
        numberCompareFunction(a[valueKey], b[valueKey], isReversed) :
        stringCompareFunction(a[valueKey], b[valueKey], isReversed));
      return item;
    });
  }
);

export const selectDetailReportFilteredFormatted = createSelector(
  selectDetailReportSorted,
  selectDetailReport,
  selectUserGroup,
  (detailReportFiltered, detailReport, userGroup) => detailReportFiltered.map(({ resource, fullname, total, details }) => {
    const initialContent = detailReport.find(
      (item) => item.resource === resource
    );
      // const groupIdKey = resource.replace('.', '-'); // was for groupId
    const summaryConfig = {
      groupId: 'resource',
      isDefaultExpanded: true,
      label: `${fullname} (${resource})`,
    };
    const summaryContent = `${total ? total.toFixed(2) : '0:00'} h`;

    const data = details.map(
      createDetailsMap({
        stylesTemplate: resourceRowsCssRules,
        dataTemplate: resourceRowsDataTemplate,
        userGroup,
      })
    );

    return {
      summaryConfig,
      summaryContent,
      data,
      model: {
        dataTemplate: resourceRowsDataTemplate,
        rules: {
          css: resourceRowsCssRules,
        },
      },
      hasInitialContent: !!(initialContent && initialContent.details.length),
      hasResultsContent: !!(data && data.length),
    };
  })
);

export const selectDetailReportSummaryFormatted = createSelector(
  selectDetailReportSummary,
  (summaryRows) => summaryRows.map((detail, id) => ({
    id,
    tableData: getTableData(totalsDataTemplate, detail),
    stylesTemplate: summaryRowsCssRules,
    isFilter: true,
    hasWarning: false,
    isActive: true,
  }))
);

export const selectDevCenterFilters = createSelector(
  selectLocalState,
  (state) => state.devCenterFilters
);

export const selectSelectedDevCenters = createSelector(
  selectSelectedDevCenterIds,
  selectDevCenters,
  (selectedDevCenterIds, devCenters) => selectedDevCenterIds && devCenters.forSelect.length ?
    devCenters.forSelect.filter((c) => selectedDevCenterIds.includes(c.value)) :
    null
);

export const selectHasAccessToRevenue = createSelector(
  selectUserGroup,
  (group) => !getHasPermissions(group, notRevenue)
);

export const selectProjectTypesList = createSelector(
  selectProjectTypes,
  sortedList
);

export const selectBillTypesList = createSelector(selectBillTypes, sortedList);

export const selectActiveFilters = createSelector(selectFilters, (filters) => {
  const active = [];
  Object.entries(filters).forEach(([key, value]) => {
    const filterItem = Object.entries(value).filter(
      ([filterItemKey, filterItemVal]) => filterItemKey === 'isActive' && filterItemVal === true
    );
    if (filterItem.length) {
      active.push(key);
    }
  });

  return !!active.length;
});

export const selectUtilizationByMonthReportFilters = createSelector(
  selectLocalState,
  (state) => state.filters
);
