import { string } from 'yup';

import { placeholdersItemStyles } from './styles';

export const emailManagementFormTemplate = (detailsEmailTemplate) => {
  const {
    name,
    content,
    emailTemplateId,
    subject,
    year,
  } = detailsEmailTemplate;
  return [
    {
      title: 'Email Template Details',
      stylesTemplate: {
        gridArea: 'details',
        cssRules: `
          width: 100%;
          grid-template-areas:
          "name email email placeholder"
          "agent email email placeholder"
          "date email email placeholder";
          grid-auto-rows: unset;
          grid-template-columns: 2fr 2fr 1fr 2fr;
          margin-bottom: 3rem;
          & .form-date-picker {
          }
          & > .plain-text {
            grid-area: placeholderTitle;
          }
        `,
      },
      formData: [
        {
          type: 'virtualField',
          name: 'emailTemplateId',
          withLabel: false,
          valueGetter: ({ emailTemplate }) => ({
            value: emailTemplate.emailTemplateId || '',
          }),
          validationRules: {
            isRequired: true,
          },
          cssRules: `
            display: none;
          `,
        },
        {
          type: 'virtualField',
          name: 'year',
          valueGetter: ({ emailTemplate }) => emailTemplate.year,
          isHidden: true,
        },
        {
          type: 'virtualField',
          name: 'file',
          valueGetter: ({ emailTemplate }) => emailTemplate.file,
          isHidden: true,
        },
        {
          type: 'virtualField',
          name: 'fileId',
          valueGetter: ({ emailTemplate }) => emailTemplate.fileId,
          isHidden: true,
        },
        {
          type: 'text',
          name: 'name',
          withLabel: true,
          placeholder: 'Email Template Name',
          valueGetter: ({ emailTemplate }) => ({
            value: emailTemplate.name || '',
          }),
          validationRules: {
            isRequired: true,
            schemaGetter: () => string().nullable().required('Required field'),
          },
          cssRules: `
            grid-area: name;
          `,
        },
        {
          type: 'text',
          name: 'subject',
          withLabel: true,
          placeholder: 'Email Subject',
          valueGetter: ({ emailTemplate }) => ({
            value: emailTemplate.subject || '',
          }),
          validationRules: {
            isRequired: true,
            schemaGetter: () => string().nullable().required('Required field'),
          },
          cssRules: `
            grid-area: agent;
          `,
        },
        {
          type: 'text',
          isMultiline: true,
          name: 'content',
          placeholder: 'Email Text',
          valueGetter: ({ emailTemplate }) => emailTemplate.content || '',
          validationRules: {
            isRequired: true,
            schemaGetter: () => string().nullable().required('Required field'),
          },
          cssRules: `{
            grid-area: email;
            & textarea {
              border: 1px solid silver;
              max-height: 14rem;
              min-height: 14rem;
              font-size: 1.4rem;
              line-height: 1.8rem;
              letter-spacing: 0.15px;
              color: rgba(0, 0, 0, 0.87);
              padding: 1.2rem 0.5rem;
              margin-top: 2.5rem;
            }
            .text-input__label {
              top: 3.8rem;
              left: 1rem;
            }
            .text-input__label--visible {
              top: 0;
              left: 0;
            }
            .text-input__input-field:focus + .text-input__label {
              left: 0;
            }
          }`,
        },
        {
          isMultiple: true,
          cssRules: `
            grid-template-columns: 1fr 3fr;
            font-size: 1.3rem;
            line-height: 1.8rem;
            grid-area: placeholder;
            height: max-content;
            & .plain-text:hover {
              overflow: hidden;
            }
            & .plain-text:nth-child(1) {
              grid-column-start: 1;
              grid-column-end: 3;
            }
          `,
          formData: [
            {
              type: 'description',
              data: 'Placeholders',
              cssRules: `{
                margin-bottom: 10px;
              }`,
            },
            {
              type: 'description',
              data: '[salut]',
              cssRules: placeholdersItemStyles,
            },
            {
              type: 'description',
              data: 'salutation',
              gridArea: 'salut',
            },
            {
              type: 'description',
              data: '[am]',
              cssRules: placeholdersItemStyles,
            },
            {
              type: 'description',
              data: 'account manager',
            },
            {
              type: 'description',
              data: '[amtitle]',
              cssRules: placeholdersItemStyles,
            },
            {
              type: 'description',
              data: 'account manager title',
            },
            {
              type: 'description',
              data: '[phone]',
              cssRules: placeholdersItemStyles,
            },
            {
              type: 'description',
              data: 'phone number',
            },
            {
              type: 'description',
              data: '[email]',
              cssRules: placeholdersItemStyles,
            },
            {
              type: 'description',
              data: 'email',
            },
          ],
        },
      ],
    },
    {
      stylesTemplate: {
        gridArea: 'new',
        cssRules: `
          padding: 0;
          margin-top: 0;
          display: flex;
        `,
      },
    },
    {
      stylesTemplate: {
        cssRules: `
          padding: 0;
          margin-top: 0;
          display: flex;
          justify-content: flex-end;
        `,
      },
      formData: [
        {
          type: 'actionButton',
          data: 'Preview',
          withBody: true,
          actionName: 'openModal',
          actionArguments: {
            currentModal: 'PREVIEW_MODAL',
            content: [
              {
                type: 'title',
                data: `${name} email text preview`,
              },
              {
                type: 'text',
                data: subject,
              },
              {
                type: 'description',
                data: content,
              },
            ],
          },
          parentField: {
            name: ['content'],
          },
          cssRules: `
            && {
              position: absolute;
              top:10px;
              right:0;
              width: 18rem;
              height: 3.2rem;
              text-align: center;
              border: 1px solid #58595b;
              font-size: 1.3rem;
              font-weight: 500;
              line-height: 1.6rem;
              letter-spacing: 0.25px;
              color: #58595b;
              margin-left: 0;
            }
          `,
        },
      ],
    },
    {
      stylesTemplate: {
        gridArea: 'delete',
        cssRules: `
          padding: 0;
          margin-top: 8px;
          && {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            height: 100%;
          }
        `,
      },
      formData: [
        {
          type: 'actionButton',
          withBody: true,
          data: 'Delete',
          disabled: emailTemplateId == null,
          actionName: 'openModal',
          actionArguments: {
            currentModal: 'CONFIRM_ACTION_MODAL',
            content: [
              {
                type: 'title',
                data: 'Delete this position template?',
              },
              {
                type: 'description',
                data: 'This template will be permanently deleted and cannot be restored.',
              },
            ],
            acceptActionName: 'deleteEmailTemplateRecord',
            acceptActionTitle: 'Delete',
            acceptActionArguments: {
              emailTemplateId,
              year,
            },
          },
          cssRules: `
            && {
              width: 18rem;
              height: 3.2rem;
              text-align: center;
              border: 1px solid #58595b;
              font-size: 1.3rem;
              font-weight: 500;
              line-height: 1.6rem;
              letter-spacing: 0.25px;
              color: #58595b;
              margin-left: auto;
            }
          `,
        },
      ],
    },
  ];
};
