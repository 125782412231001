import { colorPrimary } from 'assets/styles/variables';

export const calendarItemCssRules = `
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  white-space: nowrap;
  color:  ${colorPrimary};
`;

export const controlsCssRules = `
  .controls__additional-controls {
    display: grid;
    grid-gap: 0.8rem;
    grid-template-rows: 1fr 1fr;
  }
`;

export const calendarCssRules = `
  && {
    display: flex;
    min-width: unset;
    align-items: center;
    margin-right: 1.6rem;
    padding-top: 0;
    border: none;
    & path {
      fill: ${colorPrimary};
    }
  }
  && .calendar__toggle-button {
    padding-top: 0.3rem;
  }
`;

export const filterCssRules = {
  gridTemplateConfig: `
    top: 11.8rem;
    grid-template-columns: minmax(auto, 5fr) minmax(auto, 56fr)  minmax(auto, 18fr) minmax(auto, 35fr) minmax(auto, 28fr) minmax(auto, 32fr) minmax(auto, 27fr) minmax(auto, 24fr) minmax(auto, 16fr) minmax(auto, 15fr) 'minmax(auto, 15fr)';`,
};
