import { useEffect } from 'react';

import { config } from 'core/config';
import { useHistory } from 'react-router-dom';
import { getHasPermissions } from 'utils/auth';
const RedirectWrapper = ({ children, userGroup, guaranteedAccessRoles = [] }) => {
  const history = useHistory();
  useEffect(() => {
    const redirectPath = config.redirectPaths[userGroup];
    if (userGroup && history.location.pathname === '/home' && redirectPath) {
      history.push(redirectPath);
      return;
    }

    if (userGroup && !getHasPermissions(userGroup, guaranteedAccessRoles)) {
      history.push('/not-found');
    }
  }, [userGroup]);
  return children;
};

export default RedirectWrapper;
