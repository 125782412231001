import RiseUpIcon from 'assets/img/icon-rise-up.svg';
import {
  colorPrimary,
  colorSecondaryGrayLight,
  colorTextSalaryRaiseLow,
  colorTextSalaryRaiseMedium,
  colorTextSalaryRaiseHight,
} from 'assets/styles/variables';
import { STORE_KEYS } from 'core/billing-reports/constants';
import { COLUMN_TYPES } from 'core/constants';
import {
  get,
  toNumber,
  intersection,
} from 'lodash';

import { getMonthIndexByShortName } from 'utils/helpers/date';
import { showResourceLinkByDevCenter } from 'utils/helpers/link';
import { addThousandSeparator } from 'utils/helpers/numbers';

import tooltipContent from './tooltip-content';
import { isDisabled } from './utils';

const mainRateCss = `
  padding-right: 0;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4rem;
  text-align: right;
  letter-spacing: -0.25px;
`;
const additionalRateCss = `
  color: #58595B;
  text-align: right;
  letter-spacing: -0.25px;
  opacity: 0.7;
`;
const getRate = (rate) => {
  const roundedRate = toNumber(rate).toFixed(2);

  return addThousandSeparator(roundedRate);
};

const getCurrencyPrefix = (baseCurrency) => {
  const prefix = {
    [STORE_KEYS.USD]: '',
    [STORE_KEYS.PLN]: 'P-',
    [STORE_KEYS.RUR]: 'R-',
  };

  return prefix[baseCurrency];
};
const parseMonthData = ({
  id,
  index,
  currency,
  monthData,
  employeeCurrenciesForYear,
  seniorities,
}) => {
  if (
    monthData == null || (
      seniorities.length !== 0 &&
      !seniorities.includes(monthData.seniority)
    )
  ) {
    return {};
  }
  const { seniority, probation, ...salary } = monthData;
  const renderedCurrency = currency.length ?
    intersection(employeeCurrenciesForYear, currency) :
    employeeCurrenciesForYear;

  return renderedCurrency.map((baseCurrency) => {
    const rate = get(salary, `${baseCurrency}.rate`, 0);
    const isRateDisabled = isDisabled(rate);
    const rateUsd = get(salary, `${baseCurrency}.rateUsd`, 0);
    const riseInMiddle = get(salary, `${baseCurrency}.riseInMiddle`, false);
    const hasRise = get(salary, `${baseCurrency}.hasRise`, false);
    const usdRaisePercentage = get(salary, `${baseCurrency}.usdRaisePercentage`, null);
    const prefix = getCurrencyPrefix(baseCurrency);
    const isUsd = !prefix;
    let colorTextRate;

    if (usdRaisePercentage) {
      switch (true) {
        case (usdRaisePercentage <= 10):
          colorTextRate = colorTextSalaryRaiseLow;
          break;
        case (usdRaisePercentage > 10 && usdRaisePercentage <= 20):
          colorTextRate = colorTextSalaryRaiseMedium;
          break;
        case (usdRaisePercentage > 20 && usdRaisePercentage <= 30):
          colorTextRate = colorTextSalaryRaiseHight;
          break;
        case (usdRaisePercentage > 30):
          colorTextRate = colorPrimary;
          break;
        default:
          return '';
      }
    }
    return {
      type: COLUMN_TYPES.TEXT,
      cssRules: `
        && {
          & .plain-text {
            display: grid;
            grid-auto-rows: 1.6rem;
            overflow: visible;
          }
          ${riseInMiddle ? 'cursor: pointer;' : ''}
        }
      `,
      tooltipConfig: riseInMiddle && {
        triggerProps: {
          'data-tip': true,
          'data-for': `${id}/${index}`,
          'data-multiline': true,
        },
        props: {
          id: `${id}/${index}`,
          place: 'top',
          effect: 'solid',
        },
        content: tooltipContent,
      },
      componentProps: {
        data: isRateDisabled ?
          '' :
          `${prefix || '$'}${getRate(rate)}${riseInMiddle ? '*' : ''}`,
        cssRules: `
          ${mainRateCss}
          ${hasRise ? `
            && {
              position: relative;
              display: block;
              width: min-content;
              margin-left: auto;
              color: ${colorTextRate};
              overflow: visible;
              &:before {
                position: absolute;
                right: 100%;
                top: -0.3rem;
                content: '';
                width: 1.1rem;
                height: 1.6rem;
                -webkit-mask: url(${RiseUpIcon}) no-repeat;
                mask: url(${RiseUpIcon}) no-repeat;
                background-color: ${colorTextRate};
              }
            }
          ` : ''}
        `,
        additionalData: [
          !isUsd && {
            content: isDisabled(rateUsd) ?
              '' :
              `$ ${getRate(rateUsd)}`,
            cssRules: additionalRateCss,
          }, {
            content: isRateDisabled ?
              '' :
              `${seniority}${probation ? 'P' : ''}`,
            cssRules: additionalRateCss,
          },
        ].filter((row) => row),
      },
    };
  });
};

const parseTotalData = ({
  currency,
  monthsData,
  renderedMonthColumns,
  employeeCurrenciesForYear,
  seniorities,
}) => {
  const renderedCurrency = currency.length ?
    intersection(employeeCurrenciesForYear, currency) :
    employeeCurrenciesForYear;

  const total = renderedMonthColumns.reduce((acc, { number }) => {
    const monthData = get(monthsData, `[${number}]`, {});
    if (
      monthData == null || (
        seniorities.length !== 0 &&
        !seniorities.includes(monthData.seniority)
      )
    ) {
      return acc;
    }
    const { seniority, probation, ...salary } = monthData;

    return Object.entries(salary).reduce((innerAcc, [baseCurrency, data]) => {
      if (!data) {
        return innerAcc;
      }
      const { rate, rateUsd } = data;
      const accRate = get(innerAcc, `${baseCurrency}.rate`, 0);
      const accRateUsd = get(innerAcc, `${baseCurrency}.rateUsd`, 0);

      return {
        ...innerAcc,
        [baseCurrency]: {
          rate: accRate + toNumber(rate),
          rateUsd: accRateUsd + toNumber(rateUsd),
        },
      };
    }, acc);
  }, {});

  return renderedCurrency.map((baseCurrency) => {
    const { rate = 0, rateUsd = 0 } = get(total, baseCurrency, {});
    if (isDisabled(rate)) {
      return null;
    }

    const prefix = getCurrencyPrefix(baseCurrency);
    const isUsd = !prefix;

    return {
      type: COLUMN_TYPES.TEXT,
      cssRules: `
        && {
          & .plain-text {
            display: grid;
            grid-auto-rows: 1.6rem;
            overflow: visible;
          }
        }
      `,
      componentProps: {
        data: `${prefix || '$'}${getRate(rate)}`,
        cssRules: mainRateCss,
        additionalData: [!isUsd && !isDisabled(rateUsd) && {
          content: `$ ${getRate(rateUsd)}`,
          cssRules: `
            ${additionalRateCss}
            && {
              min-height: 1.6rem;
            }
          `,
        }].filter((row) => row),
      },
    };
  });
};

export default ({
  currency,
  renderedMonthColumns,
  hasPermissionsTODeliveryModule,
  seniorities,
  availableDevCentersForDd,
  isCTO,
}) => [{
  columnName: 'Resource',
  valueGetter: ({
    title,
    isTotal,
    subtitle,
    devstaffId,
    devstaffName,
    isGrandTotal,
    devcenterIds,
  }) => {
    if (isTotal) {
      return {
        type: COLUMN_TYPES.TEXT,
        componentProps: {
          data: title,
          cssRules: `
            padding-right: 0;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.4rem;
            letter-spacing: -0.25px;
            color: ${colorPrimary};
          `,
          additionalData: [{
            content: subtitle,
            cssRules: `
              font-weight: 500;
              line-height: 2.8rem;
              ${isGrandTotal ? `color: ${colorPrimary};` : ''}
            `,
          }],
        },
        cssRules: `
          && {
            border-bottom: 1px solid ${colorSecondaryGrayLight};
            .plain-text {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
          }
        `,
      };
    }
    return {
      type: devstaffId && showResourceLinkByDevCenter(isCTO, hasPermissionsTODeliveryModule, availableDevCentersForDd, devcenterIds[devcenterIds.length - 1]) ? COLUMN_TYPES.LINK : COLUMN_TYPES.TEXT,
      componentProps: {
        data: devstaffName,
        pathname: `/delivery/employee/${devstaffId}/work-book`,
        isMultiline: true,
        cssRules: `
          font-size: 1.2rem;
          letter-spacing: -0.25px;
        `,
      },
    };
  },
},
...renderedMonthColumns.map(({ name }) => ({
  columnName: name,
  headerProps: {
    cssRules: `
      padding-right: 0;
      text-align: right;
    `,
  },
  withoutControls: true,
  valueGetter: ({
    id,
    isTotal,
    totalKey,
    totalData,
    monthsData,
    isGrandTotal,

    employeeCurrenciesForYear,
  }) => {
    const index = getMonthIndexByShortName(name);

    if (isTotal) {
      const { rate, counter, rateUsd } = get(totalData, index, {});

      return {
        type: COLUMN_TYPES.TEXT,
        componentProps: {
          data: getRate(rate),
          cssRules: mainRateCss,
          additionalData: [!isGrandTotal && totalKey !== 'usd' && {
            content: `$ ${getRate(rateUsd)}`,
            cssRules: additionalRateCss,
          }, {
            content: counter,
            cssRules: `
              line-height: 2.8rem;
              text-align: right;
            `,
          }],
        },
        cssRules: `
          && {
            border-bottom: 1px solid ${colorSecondaryGrayLight};
          }
        `,
      };
    }
    const monthData = get(monthsData, index);

    return parseMonthData({
      id,
      index,
      currency,
      monthData,
      employeeCurrenciesForYear,
      seniorities,
    });
  },
})), {
  columnName: 'total',
  headerProps: {
    cssRules: `
      box-sizing: border-box;
      padding-right: 1.6rem;
      text-align: right;
    `,
  },
  withoutControls: true,
  valueGetter: ({
    isTotal,
    totalKey,
    totalData,
    monthsData,
    isGrandTotal,
    employeeCurrenciesForYear,
  }) => {
    if (isTotal) {
      const { totalRate, totalRateUsd } = renderedMonthColumns
        .reduce(({
          totalRate: accTotalRate,
          totalRateUsd: accRateUsd,
        }, { number }) => {
          const { rate, rateUsd } = get(totalData, number);

          return {
            totalRate: rate + accTotalRate,
            totalRateUsd: rateUsd + accRateUsd,
          };
        }, { totalRate: 0, totalRateUsd: 0 });

      return {
        type: COLUMN_TYPES.TEXT,
        componentProps: {
          data: getRate(totalRate),
          cssRules: mainRateCss,
          additionalData: [!isGrandTotal && totalKey !== 'usd' && {
            content: `$ ${getRate(totalRateUsd)}`,
            cssRules: additionalRateCss,
          }],
        },
        cssRules: `
          && {
            padding-right: 1.6rem;
            border-bottom: 1px solid ${colorSecondaryGrayLight};
          }
        `,
      };
    }

    return parseTotalData({
      currency,
      monthsData,
      renderedMonthColumns,
      employeeCurrenciesForYear,
      seniorities,
    });
  },
}];
