import React, { useState, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import { colorSecondaryBackgroundLight } from 'assets/styles/variables';
import classNames from 'classnames';
import GridTemplate from 'components/grid-template';
import Loader from 'components/loader';
import { get } from 'lodash';
import FlipMove from 'react-flip-move';
import InfiniteScroll from 'react-infinite-scroller';
import styled, { css } from 'styled-components';

import { getColumnsNames } from 'utils/helpers/models';

import './styles.scss';

const StyledWrapper = styled.div`
  ${({ cssRules }) => cssRules && css`${cssRules}`}
  ${({ selectedRow, id }) => selectedRow && selectedRow === id ? `
      && {
        background-color: ${colorSecondaryBackgroundLight};
        border-right: 1px solid ${colorSecondaryBackgroundLight};
      }
    ` :
    ''}
`;

const Table = ({
  data,
  model,
  isLoaded,
  withFilter,
  entityName,
  orderRules,
  changeOrder,
  unitActions,
  withHeadings,
  noDataMessage,
  toggleFavorite,
  withoutAnimation,
  preventScrolling,
  onSelectActionName,
  withInfiniteScroll,
  additionalAction,
}) => {
  const dataTemplate = get(model, 'dataTemplate');
  const columnsNames = useMemo(() => getColumnsNames(dataTemplate), [dataTemplate]);
  const gridTemplateConfig = get(model, 'rules.css.gridTemplateConfig');
  // const wrapperCssRules = get(model, 'rules.css.wrapperCssRules', {});
  const tableWrapperCssRules = get(model, 'rules.css.tableWrapperCssRules');
  const headerCssRules = get(model, 'rules.css.headerCssRules');
  const [dataLength, updateDataLength] = useState(data.length);
  const [itemsCounter, setCounter] = useState(100);
  const [isItemsCalculated, setItemsCalculated] = useState(isLoaded);
  const [isScrollPrevented, setIsScrollPrevented] = useState(false);
  const [selectedRow, selectRow] = useState();
  useEffect(() => {
    if (withInfiniteScroll && !isScrollPrevented) {
      window.scrollTo(0, 0);
      setCounter(100);
    }

    setIsScrollPrevented(false);
    setItemsCalculated(isLoaded);
  }, [isLoaded, changeOrder]);

  useEffect(() => {
    setIsScrollPrevented(false);
    setItemsCalculated(isLoaded);
    if (dataLength !== data.length) {
      if (!preventScrolling) {
        window.scrollTo(0, 0);
      }
      setCounter(100);
      updateDataLength(data.length);
    }
  }, [data]);

  useEffect(() => {
    const hasFilterDataSelectRow = data.find((item) => item.taskOrderToCopyId === selectedRow);
    if (!hasFilterDataSelectRow && withFilter) {
      selectRow();
    }
  }, [data, withFilter]);

  const renderedData = withInfiniteScroll ?
    data.slice(0, itemsCounter) :
    data;
  const changeCurrentOrder = (...args) => {
    if (!preventScrolling) {
      window.scrollTo(0, 0);
    }
    setItemsCalculated(false);
    changeOrder(...args);
  };
  const updateFavorites = ({ id }) => {
    setIsScrollPrevented(true);
    toggleFavorite({ id });
  };

  const extendedUnitActions = {
    ...unitActions,
    toggleFavorite: updateFavorites,
    changeOrder: changeCurrentOrder,
    ...(additionalAction && { additionalAction }),
  };
  const onRowClick = ({ id, ...args }) => {
    const onSelectRow = get(unitActions, onSelectActionName);

    if (onSelectRow) {
      selectRow(id);
      onSelectRow({ id, ...args });
    }
  };

  return (
    <div
      className={
        classNames(
          'table',
          { 'table--with-loader': !isLoaded },
          { 'table--with-infinite-scroll': withInfiniteScroll }
        )
      }
      style={tableWrapperCssRules}
    >

      {
        !withFilter && !data.length && isLoaded ?
          <p className="table__no-data-title">{noDataMessage}</p> : (
            <>

              {
                withHeadings && (
                  <GridTemplate
                    isHeader
                    orderRules={orderRules}
                    unitActions={extendedUnitActions}
                    cssRules={headerCssRules || gridTemplateConfig}
                    rowData={columnsNames}
                  />
                )
              }
              {
                isItemsCalculated ? (
                  <div
                    className={
                      classNames(
                        'table__content-wrapper',
                        { 'table__content-wrapper--with-infinite-scroll': withInfiniteScroll }
                      )
                    }
                  >

                    <InfiniteScroll
                      className="table__rows-wrapper"
                      pageStart={0}
                      loadMore={() => {
                        setCounter(itemsCounter + 100);
                      }}
                      hasMore={withInfiniteScroll && itemsCounter < data.length}
                      threshold={600}
                    >

                      <FlipMove
                        disableAllAnimations={withoutAnimation}
                        leaveAnimation={false}
                      >
                        {
                          renderedData.map(({
                            id,
                            name,
                            tableData,
                            hasWarning,
                            stylesTemplate,
                            isActive = true,
                            ...args
                          }, index) => (
                            <StyledWrapper
                              onClick={() => {
                                onRowClick({ id, ...args });
                              }}
                              className={
                                classNames(
                                  'table__row',
                                  { 'table__row--inactive': !isActive },
                                  { 'table__row--was-warning': hasWarning },
                                )
                              }
                              selectedRow={selectedRow}
                              id={id}
                              key={`${id}/${name}/${index}`}
                              cssRules={get(stylesTemplate, 'rowStyles', '')}
                            >
                              <GridTemplate
                                toggleFavorite={() => {
                                  setIsScrollPrevented(true);
                                  toggleFavorite({ id, entityName });
                                }}
                                unitActions={extendedUnitActions}
                                cssRules={get(stylesTemplate, 'gridTemplateConfig', gridTemplateConfig)}
                                rowData={tableData}
                              />
                            </StyledWrapper>
                          ))
                        }
                      </FlipMove>

                      {
                        !data.length && (
                          <div className="table__warning">
                            {
                              withFilter && (
                                <>

                                  <p className="table__warning-title">Sorry, no content matched your criteria. </p>

                                  <p className="table__warning-description">Please change the selected filters.</p>

                                </>
                              )
                            }
                          </div>
                        )
                      }
                    </InfiniteScroll>
                  </div>
                ) :

                  <Loader />
              }

            </>
          )
      }

    </div>
  );
};

Table.propTypes = {
  onSelectActionName: PropTypes.string,
  unitActions: PropTypes.shape({}),
  preventScrolling: PropTypes.bool,
  noDataMessage: PropTypes.string,
  entityName: PropTypes.string,
  withHeadings: PropTypes.bool,
  withoutAnimation: PropTypes.bool,
  withInfiniteScroll: PropTypes.bool,
  withFilter: PropTypes.bool,
  isLoaded: PropTypes.bool.isRequired,
  changeOrder: PropTypes.func.isRequired,
  toggleFavorite: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    isFavored: PropTypes.bool,
    tableData: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ])),
    isActive: PropTypes.bool,
    hasWarning: PropTypes.bool,
  })).isRequired,
  model: PropTypes.shape({
    rules: PropTypes.shape({
      css: PropTypes.shape({
        gridTemplateConfig: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    dataTemplate: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  orderRules: PropTypes.shape({
    orderBy: PropTypes.string,
    isReversed: PropTypes.bool,
    isSeparatedByFavorites: PropTypes.bool,
  }).isRequired,
  additionalAction: PropTypes.func,
};

Table.defaultProps = {
  entityName: '',
  unitActions: {},
  withFilter: false,
  withHeadings: true,
  onSelectActionName: '',
  withoutAnimation: false,
  preventScrolling: false,
  withInfiniteScroll: false,
  toggleFavorite: () => null,
  noDataMessage: 'This section currently contains no data.',
  additionalAction: null,
};

export default Table;
