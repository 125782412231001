/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';

import classes from './dialog.module.scss';

export const Dialog = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open() {
      setIsOpen(true);
    },
    close() {
      setIsOpen(false);
    },
  }));

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className={classes.dialogOverlay}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(false);
      }}
    >
      <div className={classes.dialogContent} onClick={(e) => e.stopPropagation()}>
        <button
          aria-label="close-dialog-button"
          type="button"
          className={classes.closeButton}
          onClick={() => {
            setIsOpen(false);
          }}
        />
        <div className={classes.dialogHeader}>
          {props.title}
        </div>
        <div className={classes.dialogBody}>
          {props.children}
          <button
            type="button"
            className={classes.confirmButton}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            OK
          </button>
        </div>
      </div>
    </div>,
    document.querySelector(`[data-id=${props.containerId}]`),
  );
});

Dialog.displayName = 'Dialog';

Dialog.propTypes = {
  title: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
