import update from 'immutability-helper';
import { createReducer } from 'redux-create-reducer';

import { commonActionsTypes } from './actions';

const initialState = {
  currentEntityName: '',
};

export default createReducer(initialState, {
  [commonActionsTypes.SET_ENTITY_NAME](state, { payload: { entityName } }) {
    return update(state, {
      currentEntityName: { $set: entityName },
    });
  },
});
