import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';


const StarIcon = ({ styles, onClick, isHeader, isFilter, orderRules, isFavored, isActive: isFilterActive }) => {
  const { isSeparatedByFavorites } = orderRules;
  const isActive = isFilter ? isFilterActive : isSeparatedByFavorites || isFavored;
  const [isActiveState, setIsActiveState] = useState(isActive);
  const hasBody = !isHeader;

  const changeState = (e) => {
    e.stopPropagation();
    setIsActiveState(!isActiveState);
    onClick();
  };

  useEffect(() => {
    if (isActive !== isActiveState) {
      setIsActiveState(isActive);
    }
  }, [isActive]);

  return (
    <div className="star-icon">
      <svg
        className="star-icon__image"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={changeState}
      >
        <path
          className="star-icon__contour"
          d="M23 9.964L15.091 9.282L12 2L8.909 9.293L1 9.964L7.006 15.167L5.202 22.9L12 18.797L18.798 22.9L17.005 15.167L23 9.964ZM12 16.74L7.864 19.237L8.964 14.529L5.312 11.361L10.13 10.943L12 6.51L13.881 10.954L18.699 11.372L15.047 14.54L16.147 19.248L12 16.74Z"
          fill={isActiveState ? styles.fill : '#bbbdc0'}
        />
        {
          hasBody &&

          <path
            className={
              classnames()
            }
            d="M12 18.797L18.798 22.9L16.994 15.167L23 9.964L15.091 9.293L12 2L8.909 9.293L1 9.964L7.006 15.167L5.202 22.9L12 18.797Z"
            fill={isActiveState ? styles.fill : 'none'}
          />
        }

      </svg>
    </div>
  );
};


StarIcon.propTypes = {
  isActive: PropTypes.bool,
  orderRules: PropTypes.shape({
    orderBy: PropTypes.string,
    isReversed: PropTypes.bool,
    isSeparatedByFavorites: PropTypes.bool,
  }),
  isFavored: PropTypes.bool,
  isHeader: PropTypes.bool,
  isFilter: PropTypes.bool,
  onClick: PropTypes.func,
  styles: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

StarIcon.defaultProps = {
  isActive: false,
  isFilter: false,
  isHeader: false,
  onClick: () => null,
  styles: {
    fill: '#d61f26',
  },
  orderRules: {},
  isFavored: false,
};


export default StarIcon;
