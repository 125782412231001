import React, { memo } from 'react';

import PropTypes from 'prop-types';

export const PdfIcon = memo(({
  width = '2.4rem',
  height = '2.4rem',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_14853_194413)">
      <path d="M18.3762 2.05469L22.5492 6.40469V22.9467H6.96045V23.0007H22.6024V6.45944L18.3762 2.05469Z" fill="#7F7F7F" />
      <path d="M18.3245 2H6.90723V22.946H22.5492V6.40475L18.3237 2" fill="white" />
      <path d="M6.7925 3.125H2V8.24525H17.075V3.125H6.7925Z" fill="#A3A3A3" />
      <path d="M17.1554 8.15916H2.09766V3.03516H17.1554V8.15916Z" fill="#D61F26" />
      <path d="M7.09011 3.90039H6.10986V7.50039H6.88086V6.28614L7.05111 6.29589C7.21654 6.29304 7.38042 6.26341 7.53636 6.20814C7.67309 6.16111 7.79887 6.08687 7.90611 5.98989C8.01524 5.89751 8.10127 5.78091 8.15736 5.64939C8.23258 5.43076 8.25945 5.19841 8.23611 4.96839C8.23143 4.80407 8.20262 4.64133 8.15061 4.48539C8.10327 4.37282 8.03301 4.27134 7.9443 4.18741C7.85559 4.10348 7.75038 4.03894 7.63536 3.99789C7.53592 3.96189 7.43317 3.93576 7.32861 3.91989C7.24943 3.90767 7.16948 3.90116 7.08936 3.90039H7.09011ZM6.94761 5.62089H6.88086V4.51089H7.02561C7.0895 4.50628 7.1536 4.51609 7.21319 4.53959C7.27277 4.56308 7.32632 4.59967 7.36986 4.64664C7.4601 4.7674 7.50831 4.91439 7.50711 5.06514C7.50711 5.24964 7.50711 5.41689 7.34061 5.53464C7.22066 5.60061 7.08413 5.63032 6.94761 5.62014" fill="#3B3B3B" />
      <path d="M9.70106 3.89028C9.61781 3.89028 9.53681 3.89628 9.47981 3.89853L9.30131 3.90303H8.71631V7.50303H9.40481C9.66793 7.51024 9.9299 7.46564 10.1758 7.37178C10.3737 7.29327 10.549 7.16671 10.6858 7.00353C10.8188 6.83887 10.9143 6.64716 10.9656 6.44178C11.0245 6.20918 11.0532 5.96996 11.0511 5.73003C11.0656 5.44665 11.0437 5.16257 10.9858 4.88478C10.9309 4.6803 10.8281 4.49185 10.6858 4.33503C10.5742 4.2084 10.4376 4.10626 10.2846 4.03503C10.1531 3.97422 10.0149 3.92939 9.87281 3.90153C9.8163 3.89219 9.75907 3.88793 9.70181 3.88878L9.70106 3.89028ZM9.56531 6.84153H9.49031V4.54353H9.50006C9.65468 4.52574 9.81112 4.55364 9.95006 4.62378C10.0518 4.70502 10.1347 4.80738 10.1931 4.92378C10.256 5.04628 10.2923 5.18073 10.2996 5.31828C10.3063 5.48328 10.2996 5.61828 10.2996 5.73003C10.3026 5.85876 10.2943 5.9875 10.2748 6.11478C10.2517 6.24545 10.209 6.37188 10.1481 6.48978C10.0791 6.59939 9.98599 6.69176 9.87581 6.75978C9.78328 6.81963 9.6737 6.84755 9.56381 6.83928" fill="#3B3B3B" />
      <path d="M13.3738 3.9043H11.5513V7.5043H12.3223V6.0763H13.2973V5.4073H12.3223V4.5733H13.3723V3.9043" fill="#3B3B3B" />
      <path d="M16.637 15.6921C16.637 15.6921 19.028 15.2586 19.028 16.0754C19.028 16.8921 17.5468 16.5599 16.637 15.6921ZM14.8693 15.7544C14.4894 15.8383 14.1192 15.9612 13.7645 16.1211L14.0645 15.4461C14.3645 14.7711 14.6758 13.8509 14.6758 13.8509C15.0338 14.4534 15.4503 15.0191 15.9193 15.5399C15.5656 15.5926 15.2151 15.6647 14.8693 15.7559V15.7544ZM13.9228 10.8794C13.9228 10.1676 14.153 9.97336 14.3323 9.97336C14.5115 9.97336 14.7133 10.0596 14.72 10.6776C14.6616 11.299 14.5315 11.9116 14.3323 12.5031C14.0594 12.0065 13.9181 11.4483 13.922 10.8816L13.9228 10.8794ZM10.436 18.7664C9.70253 18.3276 11.9743 16.9769 12.386 16.9334C12.3838 16.9341 11.204 19.2254 10.436 18.7664ZM19.7263 16.1721C19.7188 16.0971 19.6513 15.2669 18.1738 15.3021C17.5579 15.2922 16.9424 15.3356 16.334 15.4319C15.7447 14.8382 15.2373 14.1685 14.825 13.4406C15.0847 12.6902 15.2419 11.9081 15.2923 11.1156C15.2705 10.2156 15.0553 9.69961 14.3653 9.70711C13.6753 9.71461 13.5748 10.3184 13.6655 11.2169C13.7544 11.8206 13.9221 12.4102 14.1643 12.9704C14.1643 12.9704 13.8455 13.9626 13.424 14.9496C13.0025 15.9366 12.7145 16.4541 12.7145 16.4541C11.9816 16.6927 11.2916 17.0472 10.6708 17.5041C10.0528 18.0794 9.80153 18.5211 10.127 18.9629C10.4075 19.3439 11.3893 19.4301 12.2668 18.2804C12.733 17.6865 13.159 17.0621 13.5418 16.4114C13.5418 16.4114 14.8798 16.0446 15.296 15.9441C15.7123 15.8436 16.2155 15.7641 16.2155 15.7641C16.2155 15.7641 17.4373 16.9934 18.6155 16.9499C19.7938 16.9064 19.7368 16.2456 19.7293 16.1736" fill="#D61F26" />
      <path d="M18.2666 2.05859V6.46334H22.4914L18.2666 2.05859Z" fill="#A3A3A3" />
      <path d="M18.3245 2V6.40475H22.5492L18.3245 2Z" fill="white" />
      <path d="M7.0325 3.8418H6.05225V7.4418H6.82625V6.2283L6.99725 6.23805C7.16267 6.2352 7.32655 6.20557 7.4825 6.1503C7.61921 6.10325 7.74499 6.02901 7.85225 5.93205C7.96055 5.83941 8.0458 5.72284 8.10125 5.59155C8.17647 5.37292 8.20334 5.14057 8.18 4.91055C8.17531 4.74623 8.1465 4.58349 8.0945 4.42755C8.04715 4.31498 7.97689 4.2135 7.88818 4.12957C7.79947 4.04564 7.69426 3.98109 7.57925 3.94005C7.47934 3.9037 7.37609 3.87732 7.271 3.8613C7.19182 3.84908 7.11186 3.84256 7.03175 3.8418H7.0325ZM6.89 5.5623H6.82325V4.4523H6.96875C7.03263 4.44769 7.09673 4.4575 7.15632 4.48099C7.21591 4.50449 7.26945 4.54108 7.313 4.58805C7.40323 4.70881 7.45144 4.8558 7.45025 5.00655C7.45025 5.19105 7.45025 5.3583 7.28375 5.47605C7.1638 5.54202 7.02726 5.57172 6.89075 5.56155" fill="white" />
      <path d="M9.64314 3.83364C9.55989 3.83364 9.47889 3.83964 9.42189 3.84189L9.24564 3.84639H8.66064V7.44639H9.34914C9.61226 7.4536 9.87423 7.409 10.1201 7.31514C10.3181 7.23663 10.4934 7.11007 10.6301 6.94689C10.7632 6.78223 10.8586 6.59052 10.9099 6.38514C10.9688 6.15254 10.9976 5.91332 10.9954 5.67339C11.0099 5.39001 10.988 5.10593 10.9301 4.82814C10.8752 4.62366 10.7724 4.43521 10.6301 4.27839C10.5185 4.15176 10.3819 4.04961 10.2289 3.97839C10.0975 3.91758 9.95924 3.87275 9.81714 3.84489C9.76064 3.83555 9.70341 3.83129 9.64614 3.83214L9.64314 3.83364ZM9.50964 6.78489H9.43464V4.48689H9.44439C9.59901 4.4691 9.75546 4.497 9.89439 4.56714C9.99614 4.64838 10.079 4.75074 10.1374 4.86714C10.2004 4.98964 10.2367 5.12409 10.2439 5.26164C10.2506 5.42664 10.2439 5.56164 10.2439 5.67339C10.2469 5.80212 10.2387 5.93086 10.2191 6.05814C10.196 6.18881 10.1533 6.31524 10.0924 6.43314C10.0235 6.54275 9.93033 6.63512 9.82014 6.70314C9.72761 6.76299 9.61803 6.79091 9.50814 6.78264" fill="white" />
      <path d="M13.3162 3.8457H11.4937V7.4457H12.2647V6.0177H13.2397V5.3487H12.2647V4.5147H13.3147V3.8457" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_14853_194413">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

PdfIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

PdfIcon.defaultProps = {
  width: '',
  height: '',
};

PdfIcon.displayName = 'PdfIcon';
