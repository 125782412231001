import React from 'react';

import CustomLink from 'elements/link';
import Text from 'elements/plain-text';

import moment from 'moment';

import { getOffboardingErrorRows } from './heplers/errorRows';

import { stringSortIgnoreCase } from './heplers/stringSortIgnoreCase';

export const offboarding = (data, {
  locales,
  showResourceLinkByDevCenter,
  isCTO,
  availableDevCenters,
  hasPermissions,
}) => ({
  id: 'offboarding',
  title: locales.offboardingRows.title,
  columns: [
    {
      Header: locales.offboardingRows.date,
      accessor: 'offboardingDate',
      Cell: (cellInfo) => moment(cellInfo.row.original.offboardingDate).format('MM/DD/YYYY'),
    },
    {
      Header: locales.resource,
      accessor: 'fullname',
      Cell: (cellInfo) => {
        const { row: { original: { currentDevcenterId, devstaffId, fullname, devcenterId, approved, hasPassed } } } = cellInfo;
        const currentDevCenter = currentDevcenterId || devcenterId;
        let futureDevCenter = !hasPassed ? devcenterId : null;
        if (!approved) {
          futureDevCenter = devcenterId;
        }
        if (showResourceLinkByDevCenter(
          isCTO,
          hasPermissions,
          availableDevCenters,
          currentDevCenter,
          futureDevCenter
        )) {
          return (
            <CustomLink
              pathname={`/delivery/employee/${devstaffId}/work-book`}
              id={devstaffId}
              data={fullname}
            />
          );
        }

        return <Text data={fullname} />;
      },
      sortType: stringSortIgnoreCase,
    },
    {
      Header: locales.deliveryCenter,
      accessor: 'devcenter',
    },
    {
      Header: locales.offboardingRows.status,
      accessor: 'offboardingStatus',
    },
  ],
  data,
  errorRows: getOffboardingErrorRows(data),
  isEmpty: data.length === 0,
});
