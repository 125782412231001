import { colorSecondaryGrayLight07 } from 'assets/styles/variables';
import { COLUMN_TYPES } from 'core/constants';
import { get } from 'lodash';
import moment from 'moment';

import { isDisabled } from './utils';

export default () => [{
  columnName: 'Resource',
  withoutControls: true,
  valueGetter: ({ title }) => ({
    type: COLUMN_TYPES.TEXT,
    componentProps: {
      data: title,
      cssRules: `
        font-size: 1.2rem;
        letter-spacing: -0.25px;
      `,
    },
  }),
},
...moment.monthsShort().map((name, index) => ({
  columnName: name,
  headerProps: {
    cssRules: `
      padding-right: 0;
      text-align: right;
    `,
  },
  withoutControls: true,
  valueGetter: ({ monthsData }) => {
    const data = get(monthsData, index);

    return {
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data,
        cssRules: `
          padding-right: 0;
          font-size: 1.2rem;
          letter-spacing: -0.25px;
          text-align: right;
          ${isDisabled(data) ? `color: ${colorSecondaryGrayLight07};` : ''}
        `,
      },
    };
  },
})), {
  columnName: 'total',
  headerProps: {
    cssRules: `
      box-sizing: border-box;
      padding-right: 1.6rem;
      text-align: right;
    `,
  },
  withoutControls: true,
  valueGetter: () => ({}),
}];
