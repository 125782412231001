import React, { useCallback, useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { AuthHeader } from 'components/authHeader';
import { ActionButton } from 'components/buttons';
import { Spinner } from 'components/buttons/ActionButton/spinner';
import Instructions from 'components/instructions';
import SecretToken from 'components/secretToken';
import ServerErrorMessage from 'components/serverErrorMessage';
import { authActions } from 'core/auth/actions';
import {
  selectErrors, selectResetVerificationLoading,
  selectResetVerificationStatus,
  selectUserLogin,
  selectUserVerification,
} from 'core/auth/selectors';
import VerificationForm from 'layouts/verification';

import { get, isObject } from 'lodash';
import compose from 'lodash/fp/compose';

import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import styles from './style.module.scss';

const Verification = ({ userLogin, userVerification, userVerificationAction, errors, removeError, resetVerification, resetVerificationStatus, resetVerificationLoading }) => {
  const history = useHistory();

  const resetVerificationHandler = useCallback(() => {
    resetVerification({
      userEmail: userLogin.email,
    });
  }, []);

  const removeVerificationError = useCallback(() => {
    removeError('verification');
    removeError('resetVerification');
  }, []);

  useEffect(() => {
    if (!isObject(userLogin) || !userLogin.otpToken) history.push('/');
  }, [userLogin]);

  useEffect(() => {
    if (isObject(userVerification) && userVerification.apiToken) {
      history.push('/home');
    }
  }, [userVerification]);

  return (
    <div id="verification">
      <div className={styles.content}>
        <AuthHeader
          heading="Welcome to HiQo Billing System"
          className={styles.header}
          content={(
            <>
              {get(errors, 'verification', null) && <ServerErrorMessage className={styles.error} onClick={removeVerificationError} message={get(errors, 'verification[0].message', '')} />}
              <h2 className={styles.subHeading}>2-Step Verification</h2>
              <p>Please enter the 6-digit verification code from&nbsp;
                <a
                  href="https://www.microsoft.com/ru-ru/security/mobile-authenticator-app"
                  className={styles.link}
                >
                  Microsoft Authenticator
                </a>
                &nbsp;(or any other OTP Authenticator app)&nbsp;app:
              </p>
            </>
          )}
        />
        <VerificationForm
          className={styles.form}
          onSubmit={userVerificationAction}
          otpToken={get(userLogin, 'otpToken', null)}
          removeVerificationError={removeVerificationError}
          errors={get(errors, 'verification', null)}
        />
        {get(userLogin, 'showInstructions', false) ? (
          <Instructions qrcode={userLogin.qrcode} className={styles.instructions}>
            <SecretToken text={userLogin.secretToken} />
          </Instructions>
        ) : (
          <div className={styles.footer}>
            <span>If you need to reset 2-step verification or unbind your device and bind a new one, click ”Reset 2-step verification” link below.</span>
            <span>Please note as soon as you click the link, your verification will be reset right away and you will receive an email with instructions how to set up new 2-step verification.</span>
            <ActionButton className={styles.resetButton} onClick={resetVerificationHandler} type="button" dataId="reset-button">
              Reset 2-step verification
            </ActionButton>

            {resetVerificationStatus && <p>Instructions sent to your email</p>}
            {resetVerificationLoading && <Spinner className={styles.spinner} />}
            {get(errors, 'resetVerification', null) && <ServerErrorMessage className={styles.error} onClick={removeVerificationError} message={get(errors, 'resetVerification[0].message', '')} />}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  userLogin: selectUserLogin,
  userVerification: selectUserVerification,
  errors: selectErrors,
  resetVerificationStatus: selectResetVerificationStatus,
  resetVerificationLoading: selectResetVerificationLoading,
});

const mapDispatchToProps = {
  userVerificationAction: authActions.userVerification,
  resetVerification: authActions.verificationReset,
  removeError: authActions.removeError,
};

Verification.propTypes = {
  userLogin: PropTypes.shape({
    qrcode: PropTypes.string.isRequired,
    secretToken: PropTypes.string.isRequired,
    otpToken: PropTypes.string.isRequired,
    showInstructions: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
  }),
  userVerification: PropTypes.shape({
    authVerified: PropTypes.bool.isRequired,
    apiToken: PropTypes.string,
  }),
  userVerificationAction: PropTypes.func.isRequired,
  removeError: PropTypes.func.isRequired,
  resetVerification: PropTypes.func.isRequired,
  resetVerificationStatus: PropTypes.bool.isRequired,
  resetVerificationLoading: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    verification: PropTypes.array,
  }),
};

Verification.defaultProps = {
  userLogin: null,
  userVerification: null,
  errors: null,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Verification);
