import React from 'react';

import PropTypes from 'prop-types';

import {
  colorSecondaryGrayLight,
  colorWhite,
} from 'assets/styles/variables';
import classnames from 'classnames';
import styled, { css } from 'styled-components';

// TODO: Prefer JSS over SCSS
import './styles.scss';

const StyledActionButton = styled.button`
  text-align: left;
  text-decoration: ${({ withBody }) => withBody ? 'none' : 'underline'};

  &[disabled] {
    color: ${colorWhite};
    background: ${({ withIcon }) => withIcon ? 'transparent' : colorSecondaryGrayLight};
    border-color: ${({ withIcon }) => withIcon ? 'transparent' : colorSecondaryGrayLight};
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6;
  }

  ${({ cssRules }) => css`${cssRules}`}
`;

const ActionButton = ({
  data,
  imgSrc,
  onClick,
  isActive,
  withBody,
  isHidden,
  className,
  cssRules,
  withStopPropagation,
  disabled,
  title,
}) => {
  const handleClick = (e) => {
    if (withStopPropagation) {
      e.stopPropagation();
    }
    onClick();
  };

  return (
    <StyledActionButton // button button--cancel
      title={title}
      className={
        classnames(
          'action-button',
          { button: withBody },
          { 'button--cancel': withBody },
          { 'action-button--active': isActive },
          { 'action-button--hidden': isHidden },
          { 'action-button--with-body': withBody },
          className,
        )
      }
      type="button"
      onClick={handleClick}
      cssRules={cssRules}
      withBody={withBody}
      disabled={disabled}
      withIcon={!!imgSrc}
    >
      {data}
      {
        imgSrc && (
          <img
            className="action-button__icon"
            src={imgSrc}
            alt="calendar icon"
          />
        )
      }
    </StyledActionButton>
  );
};

ActionButton.propTypes = {
  imgSrc: PropTypes.string,
  cssRules: PropTypes.string,
  withBody: PropTypes.bool,
  isActive: PropTypes.bool,
  isHidden: PropTypes.bool,
  disabled: PropTypes.bool,
  withStopPropagation: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
};

ActionButton.defaultProps = {
  imgSrc: '',
  withStopPropagation: false,
  isActive: false,
  withBody: false,
  isHidden: false,
  disabled: undefined,
  data: 'Action',
  className: '',
  cssRules: '',
  title: '',
};

export default ActionButton;
