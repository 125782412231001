import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'formik';
import styled, { css } from 'styled-components';

import './styles.scss';


const StyledDiv = styled.div`
  font-size: 1.4rem;

  ${({ cssRules }) => css`${cssRules}`}`;


const CheckboxInput = ({
  name,
  withError,
  label,
  hasManuallyUpdates,
  cssRules,
  isLocked,
}) => (
  <Field
    name={name}
    render={({ field: { value }, form: { setFieldValue, setTouched } }) => {
      const onClick = () => {
        setFieldValue(name, !value);
        setTouched(name, true);
      };

      return (
        <StyledDiv
          className={
            classNames(
              'form-checkbox',
              { 'form-checkbox--with-error': withError },
            )
          }
          cssRules={cssRules}
        >

          <input
            // id={name}
            type="checkbox"
            name={name}
            className={
              classNames(
                'form-checkbox__input-field',
                { 'form-checkbox__input-field--with-error': withError }
              )
            }
            defaultChecked={value}
          />

          <label
            className={
              classNames(
                'form-checkbox__label',
                { 'form-checkbox__label--checked': value },
                { 'form-checkbox__label--with-error': withError || hasManuallyUpdates },
                { 'form-checkbox__label--is-locked': isLocked }
              )
            }
            htmlFor={name}
            role="presentation"
            onKeyUp={onClick}
            onClick={onClick}
          >
            {label}
          </label>

        </StyledDiv>
      );
    }
    }
  />
);


CheckboxInput.propTypes = {
  cssRules: PropTypes.string,
  name: PropTypes.string.isRequired,
  withError: PropTypes.bool,
  label: PropTypes.string,
  hasManuallyUpdates: PropTypes.bool,
  isLocked: PropTypes.bool,
};

CheckboxInput.defaultProps = {
  cssRules: '',
  withError: false,
  label: '',
  hasManuallyUpdates: false,
  isLocked: false,
};


export default CheckboxInput;
