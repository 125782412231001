import React, { useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import ListFooter from 'components/list-footer';
import Table from 'components/table';
import { push } from 'connected-react-router';
import { confCategoryManagement } from 'core/auth/guaranteedAccessRoles';
import { commonActions } from 'core/common/actions';
import { categoriesActions } from 'core/configurations/category-management/actions';
import { ENTITY_NAME } from 'core/configurations/category-management/constants';
import { selectClients } from 'core/configurations/category-management/selectors';
import GoTop from 'elements/go-top-button';
import { categoryManagementModel } from 'layouts/category-management/model';

import './styles.scss';

const {
  getClientsWithCategories,
  setClientCategories,
} = categoriesActions;
const { changeOrder: setOrderRules } = commonActions;
const model = categoryManagementModel();

export const ClientsTable = () => {
  const title = 'Clients Table';
  const {
    data,
    countClients,
    countFilteredClients,
    orderRules,
    isProcessing,
    isFormSubmitted,
  } = useSelector(selectClients);
  const dispatch = useDispatch();

  const getClientsWithCategoriesList = useCallback(() => dispatch(getClientsWithCategories()), []);

  const changeOrder = useCallback(({ orderBy }) => {
    dispatch(setOrderRules({ orderBy, tableName: model.tableName, entityName: ENTITY_NAME }));
  }, []);

  // eslint-disable-next-line consistent-return
  const onSelectClient = useCallback((rowData) => {
    const { id, client } = rowData;
    dispatch(push(`/${confCategoryManagement.childrenRoutes.client}/${id}`));
    dispatch(setClientCategories({ client }));
  }, []);

  useEffect(() => {
    getClientsWithCategoriesList();
  }, []);

  useEffect(() => {
    isFormSubmitted && getClientsWithCategoriesList();
  }, [isFormSubmitted]);

  return (
    <div data-id="clients-table" className="category-table__box">
      <Table
        title={title}
        model={model}
        data={data}
        isLoaded={!isProcessing}
        changeOrder={changeOrder}
        orderRules={orderRules}
        withInfiniteScroll
        withFilter
        onSelectActionName="onSelectClient"
        unitActions={{
          onSelectClient,
        }}
      />
      <ListFooter isFetchingItems={isProcessing} filteredItemsCount={countFilteredClients} itemsCount={countClients} />
      <GoTop />
    </div>
  );
};
