import { get, isEmpty } from 'lodash';

export const validationMessage = (value = '', path) => `Category ${path} "${value}" already exists.`;

const isUnique = (condition, value, context) => condition ? context.createError({ message: validationMessage(value, context.type) }) : true;

export function uniqueNameValidator(value) {
  const {
    categories,
    initialCategories,
    hiqoCategoriesNames,
    categoriesNames,
  } = this.options.context;
  const lowerCaseValue = value.toLowerCase();
  const listInitialNames = initialCategories.map(({ name }) => name.toLowerCase());
  const listFieldsNames = categories.map(({ name }) => name.toLowerCase());
  const getCategoryIndex = Number(this.path.replace(/\D/g, ''));
  const hasDifferentName = listFieldsNames[getCategoryIndex] !== listInitialNames[getCategoryIndex];
  const hasDifferentIndex = getCategoryIndex !== listFieldsNames.indexOf(lowerCaseValue);

  if (!categories[getCategoryIndex].isCreated) {
    return isUnique(hasDifferentIndex && listFieldsNames.includes(lowerCaseValue), value, this);
  }

  if (isEmpty(hiqoCategoriesNames)) {
    return isUnique(hasDifferentName && categoriesNames.includes(lowerCaseValue), value, this);
  }

  return isUnique(hasDifferentName && hiqoCategoriesNames.includes(lowerCaseValue), value, this);
}

export function uniqueKeyValidator(value) {
  const {
    categories,
    initialCategories,
    categoriesKeys,
  } = this.options.context;
  const getCategoryIndex = Number(this.path.replace(/\D/g, ''));
  const currentListCategoriesKeys = categories.map(({ key }) => key);
  const hasDifferentKey = get(categories[getCategoryIndex], 'key', '') !== get(initialCategories[getCategoryIndex], 'key', '');
  const hasDifferentIndex = getCategoryIndex !== currentListCategoriesKeys.indexOf(value);

  if (!categories[getCategoryIndex].isCreated) {
    return isUnique(hasDifferentIndex && currentListCategoriesKeys.includes(value), value, this);
  }
  return isUnique(hasDifferentKey && categoriesKeys.includes(value), value, this);
}
