export const MAX_PTO_DAYS_TO_ADD = 99;

export const PTO_DAYS_ACTION_TYPES = {
  TRANSFER_TO_THE_NEXT_YEAR: 'transfer to',
  ANNUL: 'annul',
  COMPENSATE: 'compensate',
  ADD_DAYS: 'add days',
  SET_TOTAL_DAYS: 'set total days',
};

export const legendTitles = {
  billingReportForTO: '*Discounted rates. Please refer to the Task Order document for further details on the specific discounts applied.',
  billingSummaryTOReport: '*Discounted rates. Please refer to the Task Order document for further details on the specific discounts applied.',
  billingSummaryGMReport: '*Discounted rates. Please refer to the Task Order document for further details on the specific discounts applied.',
  commissionSummaryReport: '*Discounted rates. Please refer to the Task Order document for further details on the specific discounts applied.',
};

export const MESSAGE_PROCESSING_STATUS = {
  'Message Sent OK': 'Message Sent OK',
  'No attachment found': 'No attachment found',
  'No contact data for HiQo AM': 'No contact data for HiQo AM',
  Skipped: 'Skipped',
};
