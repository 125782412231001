import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useIsListChildVisible } from 'utils/observer';

const FieldArrayLine = ({ children }) => {
  const child = useRef(null);
  const isVisible = useIsListChildVisible(child);
  return (
    <div className="details-form__field-array-line-wrapper" ref={child}>
      {isVisible ? children : (<div className="plug"></div>)}
    </div>
  );
};

FieldArrayLine.propTypes = {
  children: PropTypes.shape({}),
};

FieldArrayLine.defaultProps = {
  children: {},
};

export default FieldArrayLine;
