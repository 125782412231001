import gql from 'graphql-tag';

export const queryConfig = {
  getTechnicalMentorRecords: gql`
    query GetMentorRecords($devstaffId: Int!) {
      technicalMentorRecords(devstaffId: $devstaffId) {
        technicalMentorRecordId
        devstaffId
        technicalMentorId
        startDate
        endDate
        allowedForEdit
      }
    }
  `,
  createTechnicalMentorRecord: gql`
    mutation CreateTechnicalMentorRecord($devstaffId: Int!, $fields: CreateTechnicalMentorRecordInput!) {
      createTechnicalMentorRecord(devstaffId: $devstaffId, fields: $fields) {
        technicalMentorRecord {
          technicalMentorRecordId
          devstaffId
          technicalMentorId
          startDate
          endDate
          allowedForEdit
        }
        ok
      }
    }
  `,
  updateTechnicalMentorRecord: gql`
    mutation UpdateTechnicalMentorRecord($technicalMentorRecordId: Int!, $fields: UpdateMentorRecordInput!) {
      updateTechnicalMentorRecord(technicalMentorRecordId: $technicalMentorRecordId, fields: $fields) {
        ok
        technicalMentorRecord {
          technicalMentorRecordId
          devstaffId
          technicalMentorId
          startDate
          endDate
          allowedForEdit
        }
      }
    }`,
  deleteTechnicalMentorRecord: gql`
    mutation DeleteMentorRecord($technicalMentorRecordId: Int!) {
      deleteTechnicalMentorRecord(technicalMentorRecordId: $technicalMentorRecordId) {
        ok
      }
    }
  `,
};
