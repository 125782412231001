import { colorSecondaryGrayLight } from 'assets/styles/variables';
import { toNumber } from 'lodash';
import moment from 'moment';

import { getUnixTime } from 'utils/helpers/date';
import { showResourceLinkByDevCenter } from 'utils/helpers/link';
import { addThousandSeparator, roundValue } from 'utils/helpers/numbers';

export default ({
  isCTO,
  isHRM,
  isChefs,
  isDTOReport,
  hasPermissionsTODeliveryModule,
  availableDevCentersForDd,
}) => {
  const isTO = !isDTOReport;
  const isTOorHRM = isTO || isHRM;

  return [
    {
      columnName: 'Full name',
      headerProps: {
        cssRules: 'min-height: 2.8rem;',
      },
      valueGetter: ({
        fullname,
        devstaffId,
        resource,
        isGrandTotal,
        project,
        devcenterId,
      }) => {
        if (isGrandTotal) {
          return {
            type: 'text',
            componentProps: {
              data: `Total ${project}`,
              cssRules: `
              font-size: 1.6rem;
              font-weight: 500;`,
            },
          };
        }
        return {
          type:
            devstaffId && showResourceLinkByDevCenter(isCTO, hasPermissionsTODeliveryModule, availableDevCentersForDd, devcenterId) ?
              'link' :
              'text',
          componentProps: {
            data: fullname || resource || 'n/a',
            pathname: `/delivery/employee/${devstaffId}/${
              isChefs ? 'work-book' : 'pto-requests'
            }`,
            cssRules: 'font-size: 1.2rem;',
            isMultiline: true,
          },
        };
      },
    },
    {
      columnName: 'Devcenter',
      shouldntBeRendered: isTO,
      valueGetter: ({ isGrandTotal, devcenterShortname }) => isGrandTotal ? {
        shouldntBeRendered: isTO,
      } : ({
        value: devcenterShortname || 'n/a',
        type: 'text',
        shouldntBeRendered: isTO,
      }),
    },
    {
      columnName: 'Seniority',
      valueGetter: ({ seniority, isGrandTotal }) => {
        if (isGrandTotal) {
          return {};
        }
        return {
          value: seniority || 'n/a',
          type: 'text',
        };
      },
    },
    {
      columnName: 'Booking Period',
      valueGetter: ({ dateFrom, dateThrough, isGrandTotal }) => {
        if (isGrandTotal) {
          return {};
        }
        return {
          isDate: true,
          unixValue: getUnixTime(dateFrom),
          value: `${moment(dateFrom).format('YYYY-MM-DD')}${
            dateThrough ?
              ` \u2014 ${moment(dateThrough).format('YYYY-MM-DD')}` :
              ''
          }`,
          type: 'text',
          cssRules: `&& {
        font-size: 1.2rem;
        line-height: 1.7rem;
        text-align: left;
      }`,
        };
      },
    },
    {
      columnName: 'Util.',
      valueGetter: ({ utilization, isGrandTotal }) => {
        if (isGrandTotal) {
          return {};
        }
        return {
          type: 'text',
          componentProps: {
            data: utilization ? `${utilization} %` : 'n/a.',
            cssRules: `&& {
          overflow: visible;
          ${!utilization ? `color: ${colorSecondaryGrayLight}` : ''}
        }`,
          },
          cssRules: `&& {
          padding-right: calc(100% - 6ch);
          text-align: right;
        }`,
        };
      },
    },
    {
      columnName: 'Discount/\nSurcharge',
      shouldntBeRendered: isTOorHRM,
      headerProps: {
        cssRules: 'margin: 0 calc(50% + 1rem) 0 auto;',
      },
      valueGetter: ({ surchargePercentage, isGrandTotal }) => {
        const data = `${addThousandSeparator(roundValue(surchargePercentage, 2))}%`;
        if (isGrandTotal) {
          return {
            shouldntBeRendered: isTOorHRM,
          };
        }
        return {
          type: 'text',
          shouldntBeRendered: isTOorHRM,
          componentProps: {
            data,
            cssRules: `&& {
            overflow: visible;
            ${!surchargePercentage ? `color: ${colorSecondaryGrayLight}` : ''}
          }`,
          },
          cssRules: `&& {
          text-align: left;
        }`,
        };
      },
    },
    {
      columnName: 'Hrl\xa0Rate',
      shouldntBeRendered: isDTOReport,
      valueGetter: ({ rateWithSurcharge, rate, isGrandTotal }) => {
        const data = addThousandSeparator(roundValue(isDTOReport ? rateWithSurcharge : rate, 2));
        if (isGrandTotal) {
          return {
            shouldntBeRendered: isDTOReport,
          };
        }
        return {
          type: 'text',
          shouldntBeRendered: isDTOReport,
          componentProps: {
            data,
            cssRules: `&& {
            overflow: visible;
            ${!rateWithSurcharge ? `color: ${colorSecondaryGrayLight}` : ''}
          }`,
          },
          cssRules: `&& {
          text-align: left;
        }`,
        };
      },
    },
    {
      columnName: 'OOO',
      valueGetter: ({ ooo, isGrandTotal }) => {
        const data = addThousandSeparator(toNumber(ooo || 0).toFixed(2));
        if (isGrandTotal) {
          return {};
        }
        return {
          type: 'text',
          componentProps: {
            data,
            cssRules: `&& {
            overflow: visible;
            ${
  !ooo || toNumber(ooo) === 0 ?
    `color: ${colorSecondaryGrayLight}` :
    ''
}
          }`,
          },
          cssRules: `&& {
          padding-right: calc(100% - 6ch);
          text-align: right;
        }`,
        };
      },
    },
    {
      columnName: 'Hrs in Period',
      headerProps: {
        cssRules: `
      padding-right: 1rem;
      ${isHRM ? 'margin-left: auto;' : ''}`,
      },
      valueGetter: ({ hours, isGrandTotal }) => {
        if (isGrandTotal) {
          return {};
        }
        const data = addThousandSeparator(toNumber(hours || 0).toFixed(2));

        return {
          type: 'text',
          componentProps: {
            data,
            cssRules: `&& {
          overflow: visible;
          ${
  !hours || toNumber(hours) === 0 ?
    `color: ${colorSecondaryGrayLight}` :
    ''
}
        }`,
          },
          cssRules: `&& {
        padding-right: ${isHRM ? '1rem' : 'calc(100% - 6ch)'};
        text-align: right;
      }`,
        };
      },
    },
    {
      columnName: 'Total',
      shouldntBeRendered: isHRM,
      headerProps: {
        cssRules: `
      margin-left: auto;
      padding-right: 1rem;`,
      },
      valueGetter: ({
        totalWithSurcharge,
        total,
        isGrandTotal,
        isRatePlatinum,
      }) => {
        const data = addThousandSeparator(roundValue(isDTOReport ? totalWithSurcharge : total, 2));

        return {
          type: 'text',
          shouldntBeRendered: isHRM,
          componentProps: {
            data: `${
              !isGrandTotal && isRatePlatinum && isTO ? '*' : ''
            }${data}`,
            cssRules: `&& {
            padding-right: 0;
            overflow: visible;
            text-align: right;
            ${
  !totalWithSurcharge && !total ?
    `color: ${colorSecondaryGrayLight}` :
    ''
}
              ${isGrandTotal && 'font-weight: 500'}
            ${isGrandTotal && 'font-size: 1.2rem'}
          }`,
          }, // grid unit wrapper styles
          cssRules: `&& {
        padding-right: 1rem;
      }`,
        };
      },
    },
  ];
};
