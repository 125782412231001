import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import Tooltip from 'components/tooltip';
import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';

import ErrorMessage from '../error-message';

import './styles.scss';

const StyledContainer = styled.div`
  padding: 0.7rem 0.4rem 0.7rem;
  font-size: 1.3rem;
  line-height: 2.4rem;

  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;

export const TextInput = ({
  name,
  withError,
  isLocked,
  hasChanges,
  value,
  isDisabled,
  onChange,
  placeholder,
  label,
  withLabel,
  isRequired,
  hasManuallyUpdates,
  error,
  children,
  tooltipContent,
  isMultiline,
  withWarning,
  cssRules,
  isPassword,
  onBlur,
  withErrorIcon,
}) => {
  const isLabelVisible = !!value || value === 0 || value === '0';

  const tabIndex = isLocked || isDisabled ? -1 : 0;

  return (
    <StyledContainer
      className={
        classNames(
          'new-text-input',
          { 'new-text-input--with-error': withError },
          { 'new-text-input--has-changes': hasChanges },
          { 'new-text-input--textarea': isMultiline },
          { 'new-text-input--locked': isLocked },
          { 'new-text-input--has-changes-locked': isLocked && hasChanges },
          { 'new-text-input--with-warning-locked': isLocked && withWarning },
          { 'new-text-input--locked-textarea': isLocked && isMultiline },
        )
      }
      cssRules={cssRules}
    >
      { isMultiline ? (
        <TextareaAutosize
          id={name}
          name={name}
          className={
            classNames(
              'textarea-input__input-field',
              'textarea-input--textarea',
              { 'textarea-input__input-field--with-error': withError },
              { 'textarea-input__input-field--with-tooltip': tooltipContent },
              { 'textarea-input__input-field--with-changes': hasChanges },
              { 'textarea-input__input-field--with-warning': withWarning }
            )
          }
          minRows={1}
          value={value}
          disabled={isDisabled}
          onChange={onChange}
          placeholder={withLabel ? '' : placeholder}
          onBlur={onBlur}
          autoComplete="off"
          tabIndex={tabIndex}
        />
      ) : (
        <input
          id={name}
          name={name}
          type={isPassword ? 'password' : 'text'}
          className={
            classNames(
              'new-text-input__input-field',
              { 'new-text-input__input-field--with-error': withError },
              { 'new-text-input__input-field--with-tooltip': tooltipContent },
              { 'new-text-input__input-field--with-changes': hasChanges },
              { 'new-text-input__input-field--with-warning': withWarning }
            )
          }
          value={value}
          disabled={isDisabled}
          onChange={onChange}
          placeholder={withLabel ? '' : placeholder}
          onBlur={onBlur}
          autoComplete="off"
          tabIndex={tabIndex}
        />
      )}
      {
        tooltipContent && !isLocked && (
          <Tooltip
            name={name}
            description={tooltipContent}
          />
        )
      }
      {
        withLabel && (
          <label
            className={
              classNames(
                'new-text-input__label',
                { 'new-text-input__label--visible': isLabelVisible },
                { 'new-text-input__label--with-error': withError || hasManuallyUpdates }
              )
            }
            htmlFor={name}
          >
            {label}
            {
              !isRequired && (
                <span className="new-text-input__optional-label">
                &nbsp;(optional)
                </span>
              )
            }
          </label>
        )
      }

      {
        error &&
        <ErrorMessage withIcon={withErrorIcon} errorMessage={error} />
      }

      {
        children
      }

    </StyledContainer>
  );
};

TextInput.propTypes = {
  cssRules: PropTypes.string,
  withWarning: PropTypes.bool,
  isMultiline: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.shape({}),
  ]),
  name: PropTypes.string.isRequired,
  withError: PropTypes.bool,
  isLocked: PropTypes.bool,
  hasChanges: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  withLabel: PropTypes.bool,
  isRequired: PropTypes.bool,
  hasManuallyUpdates: PropTypes.bool,
  error: PropTypes.string,
  children: PropTypes.node,
  isPassword: PropTypes.bool,
  withErrorIcon: PropTypes.bool,
};

TextInput.defaultProps = {
  cssRules: '',
  withWarning: false,
  isMultiline: false,
  tooltipContent: '',
  withError: false,
  isLocked: false,
  hasChanges: false,
  isDisabled: false,
  value: '',
  onBlur: () => null,
  onChange: () => null,
  placeholder: '',
  label: '',
  withLabel: true,
  isRequired: true,
  hasManuallyUpdates: false,
  error: '',
  children: null,
  isPassword: false,
  withErrorIcon: false,
};
