import {
  colorPrimary,
  colorSecondaryText,
  colorSecondaryGrayLight,
  colorSecondaryGrayLight06,
  colorSecondaryGrayLight015,
} from 'assets/styles/variables';
import {
  getFullMounthFromMounthNumber,
} from 'utils/helpers/date';

export default (holidaysList, selectedMonth, selectedYear) => {
  const hasHolidays = !!holidaysList.length;

  return ({
    data: [{
      data: [{
        data: [{
          type: 'description',
          data: `${getFullMounthFromMounthNumber(selectedMonth + 1)} ${selectedYear}`,
          cssRules: `
            display: grid;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: ${colorSecondaryText};
          `,
        }, {
          type: 'daysOffCalendar',
          selectedMonth,
          selectedYear,
          holidaysList: holidaysList.map((item) => item.date),
        }],
      },
      {
        data: [{
          type: 'description',
          data: 'Non working days',
          cssRules: `
            display: grid;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: rgba(0, 0, 0, 0.87);
          `,
        }, {
          data: [
            !hasHolidays && {
              type: 'description',
              data: 'Non working days are absent',
              cssRules: `
                font-size: 1.6rem;
                line-height: 3rem;
                color: ${colorSecondaryGrayLight};
              `,
            },
            hasHolidays && {
              data: holidaysList.map((item, index) => ({
                data: [{
                  type: 'description',
                  data: `${index + 1}.`,
                  cssRules: `
                    color: ${colorPrimary};
                  `,
                },
                {
                  type: 'description',
                  data: `${item.date}`,
                  cssRules: `
                    color: ${colorSecondaryText};
                  `,
                },
                ],
                cssRules: `
                  display: grid;
                  grid-auto-flow: column;
                  font-size: 1.4rem;
                  line-height: 3rem;
                `,
              })),
              cssRules: `
                display: grid;
                grid-template-rows: repeat(7, 1fr);
                grid-auto-flow: column;
                grid-column-gap: 4.2rem;
                width: fit-content;
              `,
            },
          ],
        }],
        cssRules: `
          height: 100%
          padding-left: 3rem;
          border-left: 1px solid ${colorSecondaryGrayLight06};
        `,
      },
      ],
      cssRules: `
        display: grid;
        grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr);
        padding: 1.4rem;
      `,
    }],
    cssRules: `
      background-color: ${colorSecondaryGrayLight015};
    `,
  });
};
