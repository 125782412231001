import { getFormattedDate } from 'layouts/employee-details/model/utils/workbook/helpers';

export const createEditRecordAction = (isCTO, approvedCeo, recordId, validFrom) => {
  if (isCTO && !approvedCeo) {
    return { recordId };
  }

  return {
    currentModal: 'CONFIRM_ACTION_MODAL',
    content: [
      {
        type: 'title',
        data: `Edit this position record dated as of ${getFormattedDate(validFrom)}?`,
      },
      {
        type: 'description',
        data: 'Once edited, it will be resent for Dev Center Director and CEO approval.',
      },
    ],
    acceptActionName: 'toggleWorkBookRecordState',
    acceptActionTitle: 'Edit',
    acceptActionArguments: { recordId },
  };
};
