import { removeSpecialCharacters } from 'utils/helpers/numbers';

export const isDisabled = (value) => {
  if (value == null || value === '') {
    return true;
  }

  const number = Number(removeSpecialCharacters(value));
  if (Number.isNaN(number)) {
    return false;
  }

  return number === 0;
};
