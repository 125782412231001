/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  FilterSelect,
  Search,
} from 'components/form-with-list/components/filters/components';
import { FILTER_TYPE } from 'components/form-with-list/components/filters/constants';
import { clientRateCardUpdateNotifications } from 'core/configurations/client-rate-card-update-notifications/actions';
import {
  selectBillingAgents,
  selectNotificationsType,
  selectNotificationsStatuses,
  selectRateCardsList,
} from 'core/configurations/client-rate-card-update-notifications/selectors';
import { EMAILED_FILTER } from 'core/configurations/constants';

const changeAction = clientRateCardUpdateNotifications.changeRateCardNotificationsFilter;

export const useFilterItems = () => {
  const billingAgents = useSelector(selectBillingAgents);
  const rateCards = useSelector(selectRateCardsList);
  const notificationsType = useSelector(selectNotificationsType);
  const rateCardNotificationsStatuses = useSelector(selectNotificationsStatuses);

  const filterItems = useMemo(() => [
    {
      type: FILTER_TYPE.SEARCH,
      name: 'name',
      render: ({ name, isActiveFilter }) => (
        <Search
          key={name}
          name={name}
          changeFilter={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SEARCH,
      name: 'country',
      render: ({ name, isActiveFilter }) => (
        <Search
          key={name}
          name={name}
          changeFilter={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SELECT,
      name: 'billingAgent',
      accessor: 'shortName',
      id: 'billingAgentId',
      render: ({ name, accessor, id, isActiveFilter }) => (
        <FilterSelect
          data={billingAgents}
          name={name}
          accessor={accessor}
          id={id}
          changeAction={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SELECT,
      name: 'rateCard',
      accessor: 'name',
      id: 'ratecardId',
      render: ({ name, accessor, id, isActiveFilter }) => (
        <FilterSelect
          data={rateCards}
          name={name}
          accessor={accessor}
          id={id}
          changeAction={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SELECT,
      name: 'notification',
      accessor: 'notificationTitle',
      id: 'notificationId',
      render: ({ name, accessor, id, isActiveFilter }) => (
        <FilterSelect
          data={notificationsType}
          name={name}
          accessor={accessor}
          id={id}
          changeAction={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SEARCH,
      name: 'salutation',
      id: 'salutation',
      render: ({ name, isActiveFilter }) => (
        <Search
          key={name}
          name={name}
          changeFilter={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SEARCH,
      name: 'email',
      id: 'email',
      delay: 500,
      render: ({ name, isActiveFilter }) => (
        <Search
          key={name}
          name={name}
          changeFilter={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.CHECKBOX,
      name: 'processed',
      render: () => (
        <div />
      ),
    },
    {
      type: FILTER_TYPE.SELECT,
      name: 'emailed',
      accessor: 'emailed',
      id: 'emailedId',
      render: ({ name, accessor, id, isActiveFilter }) => (
        <FilterSelect
          data={EMAILED_FILTER}
          name={name}
          accessor={accessor}
          id={id}
          changeAction={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SELECT,
      name: 'status',
      accessor: 'status',
      id: 'status',
      render: ({ name, accessor, id, isActiveFilter }) => (
        <FilterSelect
          data={rateCardNotificationsStatuses}
          name={name}
          accessor={accessor}
          id={id}
          changeAction={changeAction}
          isActive={isActiveFilter}
        />
      ),
    },
  ], [
    billingAgents,
    rateCards,
    notificationsType,
  ]);

  return {
    filterItems,
  };
};
