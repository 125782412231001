import { useCallback, useMemo, useState } from 'react';

import { sortAlphabeticalByKey, sortByProcessed } from 'core/configurations/utils';

export const useSortBy = (data, initialSortBy) => {
  const [sortBy, setSortBy] = useState(initialSortBy);

  const records = useMemo(() => {
    if (!data.length) return [];

    if (sortBy.accessor === 'processed') {
      return sortByProcessed(data, sortBy.accessor, sortBy.direction);
    }

    return sortAlphabeticalByKey(data, sortBy.accessor, sortBy.direction);
  }, [sortBy, data]);

  const onSortHandler = useCallback((sort) => {
    setSortBy(sort);
  }, []);

  return {
    records,
    sortBy,
    onSortHandler,
  };
};
