import { userManagementEntityAdapter } from 'core/configurations/user-management/helpers';
import {
  shouldDisplayUserManagementData, sortAlphabeticalByKey,
} from 'core/configurations/utils';
import { has, isString } from 'lodash';
import { createSelector } from 'reselect';

const {
  selectAll,
  selectTotal,
} = userManagementEntityAdapter;

export const selectFilter = (state) => state.userManagement.filters;
export const selectUserManagementRecords = (state) => state.userManagement.userRecords;
export const selectIsFetchingUserRecords = (state) => state.userManagement.isFetchingUserRecords;
export const selectIsCreateMode = (state) => state.userManagement.isCreateMode;
export const selectSorting = (state) => state.userManagement.sorting;

export const selectFilterByColumns = createSelector(selectFilter, (filters) => filters.filterByColumns);
export const selectIsFilterByColumnsActive = createSelector(selectFilter, (filters) => Object.values(filters.filterByColumns).some((filter) => filter.isActive));
export const selectIsDisplayOnlyActiveUsers = createSelector(selectFilter, (filters) => filters.controls.displayOnlyActiveUsers);
export const selectUserManagementRecordById = createSelector(
  [selectUserManagementRecords, (state, userId) => userId],
  (users, userId) => users.entities[userId]
);

export const selectUserManagementData = createSelector(
  selectUserManagementRecords,
  selectFilter,
  selectIsFilterByColumnsActive,
  selectIsDisplayOnlyActiveUsers,
  selectSorting,
  (
    userRecords,
    filterState,
    isFilterByColumnsActive,
    isDisplayOnlyActiveUsers,
    sorting,
  ) => {
    const sortingType = Object.keys(sorting)[0];
    let users = selectAll(userRecords);

    if (isDisplayOnlyActiveUsers) {
      users = users.filter((item) => !item.recordState.isDeactivated || isString(item.userId));
    }

    if (isFilterByColumnsActive) {
      users = users.filter((item) => shouldDisplayUserManagementData(item, filterState.filterByColumns));
    }

    if (has(sorting, sortingType)) {
      users = sortAlphabeticalByKey(users, sortingType, sorting[sortingType]);
    }

    return users;
  }
);

export const selectCounter = createSelector(
  selectUserManagementRecords,
  selectUserManagementData,
  (users, selectedUsers) => {
    const total = selectTotal(users);
    const selected = selectedUsers.length;
    const percentage = total ? Math.round((selected / total) * 100) : 0;

    return {
      total,
      selected,
      percentage,
    };
  }
);
