import React, { useCallback, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';

import { ActionButton } from 'components/buttons';
import Calendar from 'components/calendar';
import Controls from 'components/list-controls';

import { filesActions } from 'core/files/actions';
import ScreenPlaceholder from 'elements/screen-placeholder';
import StyledWrapper from 'elements/styled-wrapper';
import moment from 'moment/moment';

import { TEXTS } from './locales/en';
import styles from './style.module.scss';

const { generateContractFulfillmentReport } = filesActions;

export const ContractFulfillmentReport = () => {
  const dispatch = useDispatch();
  const [currentDate, setCurrentDate] = useState(moment());
  const displayedDate = useMemo(() => currentDate.format('MMMM YYYY'), [currentDate]);

  const generateReport = useCallback(() => {
    dispatch(generateContractFulfillmentReport({
      month: parseInt(currentDate.format('MM'), 10),
      year: parseInt(currentDate.format('YYYY'), 10),
    }));
  }, [currentDate]);

  const content = useMemo(() => (
    <ActionButton
      dataId="generate-report"
      type="button"
      onClick={generateReport}
      className={styles.pdfButton}
      disabled={moment().isBefore(currentDate, 'month')}
    >
      {TEXTS.buttonTitle.toUpperCase()}
    </ActionButton>
  ), [currentDate]);

  return (
    <>
      <Controls
        title={TEXTS.controlsTitle}
        className={styles.controls}
      >
        <Calendar
          withStepControls
          withMonthSelecting
          minDetails="year"
          onChange={setCurrentDate}
          stepControlsConfig={{
            step: 1,
            unitName: 'month',
          }}
          value={currentDate}
          popperProps={{
            placement: 'bottom-start',
          }}
        >
          <StyledWrapper>
            {displayedDate}
          </StyledWrapper>
        </Calendar>
      </Controls>
      <ScreenPlaceholder
        description="Please select Year and Month and then generate a report."
        content={content}
      />
    </>
  );
};
