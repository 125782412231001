import React from 'react';

import PropTypes from 'prop-types';

import {
  colorWhite,
  colorSecondaryText,
} from 'assets/styles/variables';
import styled, { css } from 'styled-components';

import './styles.scss';

const StyledOverlay = styled.div`
  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 81.6rem;
  padding: 4.5rem 4.2rem 4.8rem 4.0rem;
  color: ${colorSecondaryText};
  background-color: ${colorWhite};
  box-sizing: border-box;

  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;

const ModalOverlay = ({ children, clearModal, cssRules, withCloseButton }) => (
  <StyledOverlay className="modal-overlay">

    <StyledWrapper
      className="modal-overlay__wrapper"
      cssRules={cssRules}
    >
      {children}
      {withCloseButton && (
        <button
          className="modal-overlay__button modal-overlay__button--close button button--close"
          onClick={() => clearModal()}
        />
      )}
    </StyledWrapper>

  </StyledOverlay>
);

ModalOverlay.propTypes = {
  children: PropTypes.any.isRequired,
  clearModal: PropTypes.func.isRequired,
  cssRules: PropTypes.string,
  withCloseButton: PropTypes.bool,
};

ModalOverlay.defaultProps = {
  cssRules: '',
  withCloseButton: true,
};

export default ModalOverlay;
