import React from 'react';

import PropTypes from 'prop-types';

import ToastContainer from 'components/toast';
import Overlay from 'elements/modal-overlay';
import { withRouter } from 'react-router';

import './styles.scss';
import classNames from './styles.module.scss';

const placholders = {
  '[salut]': 'Chris',
  '[am]': 'Marcus Krause',
  '[amtitle]': 'Head of Global Sales',
  '[phone]': '(404) 920 4696 x8001',
  '[email]': 'sales@hiqo-solutions.com',
};

const replacePlaceholder = (string, placeholders) => {
  const placeholdersKeys = Object.keys(placeholders);
  let replacedString = string;
  for (let i = 0; i < placeholdersKeys.length; i++) {
    replacedString = replacedString.replace(placeholdersKeys[i], placeholders[placeholdersKeys[i]]);
  }
  return replacedString;
};

const PreviewModal = ({
  clearModal,
  params: {
    content = [],
    parentFieldsData,
    withCloseButton,
    withConfirmButton,
  },
}) => {
  const parsedContent = content.map(({ data, type }) => {
    if (type === 'description' && parentFieldsData[0]) {
      return { data: replacePlaceholder(parentFieldsData[0], placholders), type };
    }
    return { data, type };
  });

  return (
    <Overlay
      withCloseButton={withCloseButton}
      overlayClassName="confirm-action-modal"
      clearModal={clearModal}
    >
      <ToastContainer
        containerId="confirmActionModal"
      />

      <div className="confirm-action-modal__wrapper">
        {
          parsedContent.map(({
            data,
            type,
          }, index) => (
            <p
              key={index} // eslint-disable-line react/no-array-index-key
              className={`confirm-action-modal__${type}`}
            >
              {data}
            </p>
          ))
        }
        {withConfirmButton && (
          <button
            type="button"
            className={classNames.confirmButton}
            onClick={() => {
              clearModal();
            }}
          >
            OK
          </button>
        )}
      </div>
    </Overlay>
  );
};

PreviewModal.propTypes = {
  clearModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    actionName: PropTypes.string,
    actionTitle: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({})),
    parentFieldsData: PropTypes.arrayOf(PropTypes.shape({})),
    withCloseButton: PropTypes.bool,
    withConfirmButton: PropTypes.bool,
  }).isRequired,
};

export default withRouter(PreviewModal);
