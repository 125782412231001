export const SCROLL = {
  STEP: -150,
  SPEED: 0,
  TOP_POSITION: 0,
};

export const scrollToTop = () => {
  const { scrollBy, scrollY } = window;

  if (scrollY > SCROLL.TOP_POSITION) {
    scrollBy(0, SCROLL.STEP);
    setTimeout(scrollToTop, SCROLL.SPEED);
  } else {
    clearTimeout(scrollToTop);
  }
};

export const scrollToBottom = () => {
  const { scrollBy, innerHeight, scrollY } = window;
  const isScrollToBottom = Math.ceil(scrollY) < (document.body.scrollHeight - innerHeight);

  if (isScrollToBottom) {
    scrollBy(0, 150);
    setTimeout(scrollToBottom, SCROLL.SPEED);
  } else {
    clearTimeout(scrollToBottom);
  }
};
