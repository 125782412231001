import { toNumber } from 'lodash';
import { showResourceLinkByDevCenter } from 'utils/helpers/link';

import { addThousandSeparator, roundValue } from 'utils/helpers/numbers';

export default ({
  isHRM,
  isChefs,
  isNotInTo,
  hasPermissionsTODeliveryModule,
  isDTOReport,
  isCTO,
  availableDevCentersForDd,
}) => {
  const isTO = !isDTOReport;
  const isHRMorTO = isTO || isHRM;
  return [
    {
      columnName: 'Full name',
      headerProps: {
        cssRules: 'min-height: 2.8rem;',
      },
      valueGetter: ({
        fullname,
        devstaffId,
        resource,
        isGrandTotal,
        project,
        devcenterId,
      }) => {
        if (isGrandTotal) {
          return {
            type: 'text',
            componentProps: {
              data: `Total ${project}`,
              cssRules: `
              font-size: 1.5rem;
              font-weight: 500;`,
            },
          };
        }

        return {
          type:
           devstaffId && fullname && showResourceLinkByDevCenter(isCTO, hasPermissionsTODeliveryModule, availableDevCentersForDd, devcenterId) ?
             'link' :
             'text',
          componentProps: {
            data: fullname || resource || 'n/a',
            pathname: `/delivery/employee/${devstaffId}/${
              isChefs ? 'work-book' : 'pto-requests'
            }`,
            cssRules: 'font-size: 1.2rem;',
            isMultiline: true,
          },
        };
      },
    },
    {
      columnName: 'Devcenter',
      shouldntBeRendered: !isDTOReport,
      headerProps: {
        cssRules: 'padding-right: 2.8rem;',
      },
      valueGetter: ({ devcenterShortname, isGrandTotal }) => {
        if (isGrandTotal && !isNotInTo) {
          return {
            shouldntBeRendered: !isDTOReport,
          };
        }

        return {
          value: devcenterShortname,
          type: 'text',
          shouldntBeRendered: !isDTOReport,
        };
      },
    },
    {
      columnName: 'Seniority',
      shouldntBeRendered: isNotInTo,
      valueGetter: ({ seniority, isGrandTotal }) => {
        if (isGrandTotal && !isNotInTo) {
          return {};
        }
        return {
          value: seniority,
          type: 'text',
          shouldntBeRendered: isNotInTo,
        };
      },
    },
    {
      columnName: 'Hours',
      headerProps: {
        cssRules: `
      padding-right: 1rem;
      ${isHRM ? 'margin-left: auto;' : ''}`,
      },
      valueGetter: ({ hours, isGrandTotal }) => {
        const value = addThousandSeparator(toNumber(hours || 0).toFixed(2));

        const grandTotalStyles =
          'font-size: 1.2rem; font-weight: 500;';

        return {
          value,
          type: 'text',
          cssRules: `
            &&& {
              ${isGrandTotal ? grandTotalStyles : ''}
              ${isHRM ? 'text-align: right; padding-right: 1.0rem;' : 'text-align: left; padding-right: 0;'};
            }
          `
          ,
        };
      },
    },
    {
      columnName: 'Discount/\nSurcharge',
      shouldntBeRendered: isHRMorTO,
      headerProps: {
        cssRules: 'padding-right: 2.8rem;',
      },
      valueGetter: ({ surchargePercentage, isGrandTotal }) => {
        if (isGrandTotal && !isNotInTo) {
          return {
            shouldntBeRendered: isHRMorTO,
          };
        }
        const value = `${addThousandSeparator(roundValue(surchargePercentage, 2))}%`;

        return {
          value,
          type: 'text',
          shouldntBeRendered: isHRMorTO,
          cssRules: `&&& {
          text-align: left;
        }`,
        };
      },
    },
    {
      columnName: 'Rate',
      shouldntBeRendered: isHRM,
      headerProps: {
        cssRules: 'padding-right: 2.8rem;',
      },
      valueGetter: ({ rateWithSurcharge, rate, isGrandTotal, isDiscount }) => {
        if (isGrandTotal && !isNotInTo) {
          return {
            shouldntBeRendered: isHRM,
          };
        }
        const hasAsterisk = isDiscount && !isDTOReport;
        const value = `${addThousandSeparator(roundValue(isDTOReport ? rateWithSurcharge : rate, 2))}${hasAsterisk ? '*' : ''}`;

        return {
          value,
          type: 'text',
          shouldntBeRendered: isHRM,
          cssRules: `&&& {
          text-align: left;
        }`,
        };
      },
    },
    {
      columnName: 'Amount',
      shouldntBeRendered: isHRM,
      headerProps: {
        cssRules: `
      margin-left: auto;
      padding-right: 1rem;`,
      },
      valueGetter: ({ isGrandTotal, totalWithSurcharge, total }) => {
        const value = addThousandSeparator(roundValue(isDTOReport ? totalWithSurcharge : total, 2));

        return {
          value,
          type: 'text',
          shouldntBeRendered: isHRM,
          cssRules: `&& {
        padding-right: 1.0rem;
        ${
  isGrandTotal ?
    `
          font-size: 1.2rem;
          font-weight: 500;` :
    ''
}
        text-align: right;
      }`,
        };
      },
    },
  ];
};
