import React from 'react';

import PropTypes from 'prop-types';

export const MagnifierIcon = ({ width, height, className }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 15 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9551 10.1599L14.1692 13.3724C14.3879 13.5927 14.3879 13.949 14.1708 14.1693C14.0598 14.2787 13.9176 14.3334 13.7723 14.3334C13.627 14.3334 13.4833 14.2787 13.3739 14.1693L10.1598 10.9552C9.12075 11.8162 7.78794 12.3334 6.33325 12.3334C3.01919 12.3334 0.333252 9.64744 0.333252 6.33337C0.333252 3.01931 3.01919 0.333374 6.33325 0.333374C9.64731 0.333374 12.3333 3.01931 12.3333 6.33337C12.3333 7.78806 11.8161 9.12087 10.9551 10.1599ZM6.33325 11.2084C6.99263 11.2084 7.63013 11.0802 8.23013 10.8256C8.81138 10.5802 9.33169 10.2287 9.78013 9.78025C10.2286 9.33181 10.5801 8.80994 10.8254 8.23025C11.0801 7.63025 11.2083 6.99275 11.2083 6.33337C11.2083 5.674 11.0801 5.0365 10.8254 4.4365C10.5801 3.85681 10.2286 3.33494 9.78013 2.8865C9.33169 2.43806 8.80981 2.0865 8.23013 1.84119C7.63013 1.5865 6.99263 1.45837 6.33325 1.45837C5.67388 1.45837 5.03638 1.5865 4.43638 1.84119C3.85669 2.0865 3.33481 2.43806 2.88638 2.8865C2.43794 3.33494 2.08638 3.85681 1.84106 4.4365C1.58638 5.0365 1.45825 5.674 1.45825 6.33337C1.45825 6.99275 1.58638 7.63025 1.84106 8.23025C2.08638 8.80994 2.43794 9.33181 2.88638 9.78025C3.33481 10.2287 3.85669 10.5802 4.43638 10.8256C5.03638 11.0802 5.67388 11.2084 6.33325 11.2084Z" fill="currentColor" fillOpacity="0.54" />
  </svg>
);

MagnifierIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

MagnifierIcon.defaultProps = {
  width: 15,
  height: 15,
};
