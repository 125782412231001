import Magnifier from 'assets/img/icon-magnifier.svg';
import { FILTERS_TYPES } from 'core/constants';
import { get } from 'lodash';

import { checkIsSelected } from 'utils/helpers/filters';

export const filtersTemplates = [{
  name: 'projectLeadCommissions',
  storeKey: 'plCommissionReportRows',
  filtersTemplate: [{
    type: FILTERS_TYPES.SELECT,
    storeKey: 'plFullname',
    valueGetter: ({ plFullname }) => plFullname,
    checkFilter: ({ currentRow, selected }) => {
      const value = get(currentRow, 'plFullname', '');
      return checkIsSelected({ selected, value });
    },
    componentProps: {
      placeholder: 'Project Lead',
      cssRules: `
        && {
          .filter-select__menu {
            min-width: 100%;
          }
          .filter-select__placeholder {
            display: flex;

            &::before {
              content: '';
              width: 1.4rem;
              height: 1.4rem;
              display: block;
              background-image: url(${Magnifier});
              background-size: 100%;
              margin-right: 0.5rem;
            }
          }
        }
      `,
    },
  }],
},
];
