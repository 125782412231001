import {
  get,
  toNumber,
  intersectionWith,
} from 'lodash';

export const checkStartFrom = ({ value, selected }) => {
  if (value) {
    return selected === '#' ?
      !'1234567890'.includes(value.toLowerCase()[0]) :
      !value.toLowerCase().startsWith(selected.toLowerCase());
  }

  return true;
};

export const checkIsInclude = ({ value, selected }) => !`${value}`.toLowerCase().includes(selected.trim().toLowerCase());

export const checkIfSubstringExists = ({ value, selected }) => !value.some((item) => item.name.toLowerCase().includes(selected.toLowerCase()));

const intersectionComparator = (left, right) => `${right}`.toLowerCase() === `${left}`.toLowerCase();

export const checkIsSelected = ({ value, selected }) => !intersectionWith(
  selected,
  Array.isArray(value) ? value : [value],
  intersectionComparator
).length;

export const checkRangeFilter = ({ selected, value }) => {
  let passMin = true;
  let passMax = true;
  const min = Number.parseFloat(get(selected, 'min', null));
  const max = Number.parseFloat(get(selected, 'max', null));
  const hasMin = min >= 0;
  const hasMax = max >= 0;

  if (hasMin) {
    passMin = toNumber(value ? `${value}`.replace(',', '') : value) >= min;
  }

  if (hasMax) {
    passMax = toNumber(value ? `${value}`.replace(',', '') : value) <= max;
  }

  if (hasMin && hasMax) {
    return passMin && passMax;
  }

  if (hasMin) {
    return passMin;
  }

  if (hasMax) {
    return passMax;
  }

  return true;
};
