import React, { memo, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { PlusIcon } from 'assets/svgComponents';
import { ActionButton } from 'components/buttons';
import { ListOfForms } from 'components/list-of-forms';
import Loader from 'components/loader';
import { selectUser } from 'core/auth/selectors';
import { commentManagementActions } from 'core/delivery/commentManagement/actions';
import { selectRecords, selectFormState } from 'core/delivery/commentManagement/selectors';
import { modalConductorActions } from 'core/modal-conductor/actions';
import EmptyMessage from 'elements/empty-message';
import {
  CommentForm,
} from 'forms/comment-form';
import commonStyles from 'forms/comment-form/styles.module.scss';
import { newComment } from 'layouts/employee-details/components/resource-tabs/comments/constants';

import { get, isEmpty } from 'lodash';

import styles from './styles.module.scss';
import {
  useListColumns,
} from './useListColumns';

export const Comments = memo(({
  id,
  title,
  devstaffId,
  placeholderText,
  placeholderStyles,
  recordIdName,
  layoutStyles,
}) => {
  const { records, hasPermissionToAddRecord, isFetchingCommentManagementRecords, memoDevstaffId } = useSelector(selectRecords);
  const user = useSelector(selectUser);
  const formState = useSelector(selectFormState);
  const isFormSubmitted = get(formState, 'isSubmittedSuccess', false) || get(formState, 'isSubmittedFail', false);
  const newRecord = newComment(user);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(({
    formData,
    isCreateMode,
    commentId,
  }) => {
    if (isCreateMode) {
      dispatch(commentManagementActions.createResourceManagementComment(formData));
    } else {
      dispatch(commentManagementActions.updateResourceManagementComment({
        ...formData,
        commentId,
      }));
    }
  }, []);

  const handleDelete = useCallback((commentId) => {
    dispatch(modalConductorActions.changeCurrentModal({
      currentModal: 'CONFIRM_ACTION_MODAL',
      params: {
        content: [
          {
            type: 'title',
            data: 'Delete this comment?',
          },
          {
            type: 'description',
            data: 'Once deleted, it cannot be restored.',
          },
        ],
        acceptActionName: 'deleteContractManagementRecord',
        acceptActionTitle: 'Delete',
        acceptFunction: () => dispatch(commentManagementActions.deleteResourceManagementComment({
          commentId,
        })),
      },
    }));
  }, []);

  const handleRemoveRecord = useCallback(() => {
    dispatch(commentManagementActions.removeResourceManagementComment());
  }, []);

  const handleAddRecord = useCallback((record) => {
    dispatch(commentManagementActions.addResourceManagementComment(record));
  }, []);

  const columns = useListColumns();

  useEffect(() => {
    if (!devstaffId || devstaffId === memoDevstaffId) return;

    dispatch(commentManagementActions.getResourceManagementComments());
  }, [devstaffId]);

  useEffect(() => () => {
    dispatch(commentManagementActions.removeResourceManagementComment());
  }, []);

  if (isFetchingCommentManagementRecords) {
    return (
      <div
        data-id="Loader"
        className={styles.loader}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div id={id} title={title} data-id="Comments" className={layoutStyles}>
      <ListOfForms
        title={title}
        commonStyles={commonStyles}
        columns={columns}
        data={records}
        onRemoveRecord={handleRemoveRecord}
        newRecord={newRecord}
        recordIdName={recordIdName}
        onAddRecord={handleAddRecord}
        isFormSubmitted={isFormSubmitted}
        onDeleteHandler={handleDelete}
        renderAddButton={({
          onAddRecordClick,
          isAddButtonDisabled,
        }) => (
          <ActionButton
            title="Add Comment Button"
            dataId="addButton"
            type="button"
            onClick={onAddRecordClick}
            disabled={isAddButtonDisabled || !hasPermissionToAddRecord}
            className={styles.addCommentButton}
          >
            <PlusIcon />
            {'Add Comment'}
          </ActionButton>
        )}
        renderForm={({
          item,
          onRemoveRecord,
          setEditedRecordId,
          editedRecordId,
          onDeleteHandler,
        }) => (
          <CommentForm
            key={get(item, recordIdName, newRecord[recordIdName])}
            item={item}
            onRemoveRecord={onRemoveRecord}
            columns={columns}
            onSubmitHandler={handleSubmit}
            setEditedRecordId={setEditedRecordId}
            editedRecordId={editedRecordId}
            onDeleteHandler={onDeleteHandler}
          />
        )}
      />
      {isEmpty(records) && <EmptyMessage className={placeholderStyles} description={placeholderText} />}
    </div>
  );
});

Comments.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  devstaffId: PropTypes.number.isRequired,
  placeholderStyles: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  layoutStyles: PropTypes.string.isRequired,
};
