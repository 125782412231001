export const getAccessTabLink = (isCTO, isHRM, isDOA) => {
  const tabLinks = {
    customers: 'customers',
    taskOrders: 'task-orders',
    details: 'details',
  };

  if (isCTO || isHRM) {
    return tabLinks.taskOrders;
  }

  if (isDOA) {
    return tabLinks.customers;
  }

  return tabLinks.details;
};
