import { createSelector } from 'reselect';

import {
  parseDetails,
} from 'utils/helpers/models';
import { stringCompareFunction } from 'utils/helpers/sorting';

import {
  STORE_KEYS,
} from './constants';

export const selectErrors = (state) => state.reconciliation.errors;
export const selectClientNamesData = (state) => state.reconciliation.clientNamesData;
export const selectEntityName = (state) => state.reconciliation.entityName;
export const selectFilters = (state) => state.reconciliation.filters;
const selectLocalState = ({ reconciliation = {} }) => reconciliation;

export const selectReconciliationReportModel = createSelector(
  selectFilters,
  ({ [STORE_KEYS.YEAR]: dateExact }) => {
    const reconciliationFields = {
      year: dateExact ? dateExact.year() : null,
    };
    return ({
      isSingleTab: true,
      additionalDataGetter: () => ({
        filters: [],
        controls: [{
          title: 'Excel 1',
          actionName: 'getReconciliationReport',
          actionArguments: reconciliationFields,
        }, {
          title: 'Excel 2',
          actionName: 'getReconciliationSecondReport',
          actionArguments: reconciliationFields,
        }],

        filterOptionsStyles: {
          option: (base, state) => {
            const { styles = {} } = state.data;
            return ({
              ...base,
              ...styles,
            });
          },
        },

      }),
      dataTemplate: [],
    });
  }
);

export const selectedReconciliationReportData = createSelector(
  selectReconciliationReportModel,
  (model) => parseDetails(
    null,
    model,
    null,
  )
);

export const selectReconciliationReportsCommonData = createSelector(
  selectLocalState,
  (state) => state.filters[STORE_KEYS.CLIENT_ID] && state.clientNamesData.length ?
    state.clientNamesData.find((c) => c.clientId === state.filters[STORE_KEYS.CLIENT_ID]) :
    state.reportCommonData,
);

export const selectCurrentClientDetails = selectReconciliationReportsCommonData;

export const selectClientListForReport = createSelector(
  selectClientNamesData,
  (clientList) => clientList.map(({
    name,
    clientId,
  }) => ({
    value: clientId,
    label: name,
  }))
    .sort((a, b) => stringCompareFunction(a.label, b.label))
);

export const selectCurrentClientListItem = createSelector(
  selectReconciliationReportsCommonData,
  (client) => client && { value: client.id, label: client.name }
);
