import {
  colorPrimary003,
  colorSecondaryText,
  colorSecondaryGrayLight,
} from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import {
  approvePTO,
  salaryReports,
  clientsModuleAccess,
  taskOrderModuleAccess,
  deliveryRootModuleAccess,
  taskOrdersTableOnDashboard,
  updateWBRecordsOnDashboard,
  deliveryReviewsReportAccess,
  devCenterDirectors,
} from 'core/auth/guaranteedAccessRoles';
import { generateDevCentersOptionsByBusinessRole } from 'layouts/dashboard/helpers';
import { getOnboardingHistory } from 'layouts/employee-details/model/config';
import {
  listHeadingStyles as ptoListHeadingStyles,
  listHeadingDataGetter as ptoListHeadingDataGetter,
  additionalControlsConfigGetter as ptoAdditionalControlsConfigGetter,
  plApprovalsDataGetter,
} from 'layouts/employee-details/model/config/pto-requests-config';
import {
  listHeadingStyles as workBookListHeadingStyles,
  plLevelByIdForInfoCardRepresentation,
  workBookDescriptionCss,
  workBookFullNameCss,
  listHeadingDataGetter as workBookListHeadingDataGetter,
} from 'layouts/employee-details/model/config/utils/workbook';
import {
  additionalControlsConfigGetter as workBookAdditionalControlsConfigGetter,
  workBookFormGetter,
} from 'layouts/employee-details/model/config/work-book-config';

import { get } from 'lodash';
import moment from 'moment';

import { getHasPermissions } from 'utils/auth';
import { formatDateMMDDYYYY, getUnixTime } from 'utils/helpers/date';

import { getBusinessRoles } from 'utils/helpers/getBusinessRoles';

import { seniorityConfig } from './config/seniority';

const {
  CTO,
  CEO,
  HRM,
} = USERS_GROUPS;

export const dashboardDataModelGetter = (
  userGroup,
  { userId, fullName: userFullName },
  dashboardData,
  devcenters,
  devCentersBySystemRole,
  allDevCenters
) => {
  const isCEO = CEO === userGroup;
  const isCTO = CTO === userGroup;
  const isHRM = HRM === userGroup;
  const isChefs = getHasPermissions(userGroup, [CTO, CEO]);
  const hasAccessToSalaryReports = getHasPermissions(userGroup, salaryReports);
  const hasPermissionsToClientsModule = getHasPermissions(
    userGroup,
    clientsModuleAccess
  );
  const hasPermissionsToUpdate = getHasPermissions(
    userGroup,
    updateWBRecordsOnDashboard
  );
  const hasPermissionsToApprovePTO = getHasPermissions(userGroup, approvePTO);
  const hasPermissionsToTaskOrderModuleAccess = getHasPermissions(
    userGroup,
    taskOrderModuleAccess
  );
  const hasAccessToTaskOrderTableOnDashboard = getHasPermissions(
    userGroup,
    taskOrdersTableOnDashboard
  );
  const hasPermissionsToDeliveryRootModuleAccess = getHasPermissions(
    userGroup,
    deliveryRootModuleAccess
  );

  const {
    titlesFromDevstaffCompensation,
    dDWorkBookRecords,
    ceoWorkBookRecords,
    ptoRequestRecords,
    employeeDataById,
    dashboardSeniorityUpdateRecords,
    devstaffv2List,
  } = dashboardData;

  const ptoPartialGridTemplateColumnsConfig =
    'minmax(0, 145fr) minmax(0, 134fr) minmax(0, 353fr) minmax(0, 121fr) minmax(0, 121fr) minmax(0, 68fr) minmax(0, 68fr) minmax(0, 84fr)';
  const ptoGridTemplateColumns = `${ptoPartialGridTemplateColumnsConfig} minmax(0, 261fr)`;
  const ptoGridTemplateAreas = `
  "record record record record record record record record additional-controls"
  "toastify toastify toastify toastify toastify toastify toastify toastify additional-controls"`;
  const workBookPartialGridTemplateColumnsConfig = hasPermissionsToUpdate ?
    'minmax(0, 111fr) minmax(0, 115fr) minmax(0, 70fr) minmax(0, 119fr) minmax(0, 70fr) minmax(0, 53fr) minmax(0, 43fr) minmax(0, 38fr) minmax(0, 43fr) minmax(0, 111fr) minmax(0, 68fr) minmax(0, 68fr) minmax(0, 68fr)' :
    'minmax(0, 145fr) minmax(0, 146fr) minmax(0, 78fr) minmax(0, 247fr) minmax(0, 86fr) minmax(0, 69fr) minmax(0, 59fr) minmax(0, 58fr) minmax(0, 61fr)';
  const workBookGridTemplateColumns = hasPermissionsToUpdate ?
    `${workBookPartialGridTemplateColumnsConfig} minmax(0, 118fr) minmax(0, 131fr) minmax(0, 134fr)` :
    `${workBookPartialGridTemplateColumnsConfig} minmax(0, 131fr) minmax(0, 187fr)`;
  const workBookGridTemplateAreas = `
  "record record record record record record record record record record ${
  hasPermissionsToUpdate ? 'record record record' : ''
} controls additional-controls additional-controls"
  "toastify toastify toastify toastify toastify toastify toastify toastify toastify toastify ${
  hasPermissionsToUpdate ? 'toastify toastify toastify' : ''
} controls additional-controls additional-controls"`;

  const getWorkbookRecord = (record) => {
    const devstaffId = get(record, 'devstaffId');
    const fullnameById = get(employeeDataById, devstaffId, {});
    const fullname = get(record, 'employeeDetails.fullname', get(fullnameById, 'fullname', 'resource'));
    const businessRolesForCurrentRecord = getBusinessRoles(devCentersBySystemRole, record.devcenter);
    const businessRolesForResource = getBusinessRoles(devCentersBySystemRole, devcenters.byShortName[fullnameById.devcenterShortname]);
    const isBusinessRoleForCurrentRecord = businessRolesForCurrentRecord.includes(userGroup);
    const isBusinessRoleForResource = businessRolesForResource.includes(userGroup);

    const isDevCenterDirector = getHasPermissions(userGroup, devCenterDirectors([...businessRolesForCurrentRecord]));
    const inEditMode = !record || get(record, 'inEditMode', false);
    const hrsDay = get(record, 'hrsDay');
    const title = get(record, 'title', '');
    const devcenter = get(record, 'devcenter', '');
    const salUsd = get(record, 'salUsd', null);
    const salPln = get(record, 'salPln', null);
    const salRur = get(record, 'salRur', null);
    const remarks = get(record, 'remarks', '');
    const recordId = get(record, 'recordId', '');
    const plLevel = get(record, 'plLevel', null);
    const validFrom = get(record, 'validFrom', '');
    const probation = get(record, 'probation', '');
    const endOfProbation = get(record, 'endOfProbation', null);
    const seniority = get(record, 'seniority', '');
    const seniorityInt = get(record, 'seniorityInt', '');
    const internshipStartDate = get(record, 'employeeDetails.internshipStartDate', null);
    const dateHired = get(record, 'employeeDetails.dateHired', null);
    const dismissalDate = get(record, 'employeeDetails.dateLast', null);
    const onboardingHistory = get(record, 'employeeDetails.onboardingHistory', null);
    const isInternRecord = seniority === 'Intern';
    const approvedCeo = get(record, 'approvedCeo', false);
    const approvedDd = get(record, 'approvedDd', false);
    const isHistoryRecord = approvedCeo && approvedDd;
    const isRelocate = !isBusinessRoleForCurrentRecord;

    const isActionButtons = isCEO || (isBusinessRoleForCurrentRecord || isBusinessRoleForResource);

    const hasNoSalaryInfo =
      isDevCenterDirector && seniority !== 'Intern' && !(!!salUsd || !!salPln || !!salRur);
    const additionalControlsConfig = workBookAdditionalControlsConfigGetter({
      hasNoSalaryInfo,
      hasPermissionsToUpdate,
      isHistoryRecord,
      inEditMode,
      devstaffId,
      isCEO,
      isDevCenterDirector,
      record,
      userId,
      isRelocate,
    });

    const { dateLast, rehireDate } = getOnboardingHistory(onboardingHistory);
    const formStyles = `
      display: grid;
      grid-column-gap: 0.8rem;
      grid-template-areas: ${workBookGridTemplateAreas};
      grid-template-columns: ${workBookGridTemplateColumns};
      border-bottom: 1px solid #bbbdc0;
    `;
    if (inEditMode || hasNoSalaryInfo) {
      const deleteRecordAction = {
        currentModal: 'CONFIRM_ACTION_MODAL',
        content: [
          {
            type: 'title',
            data: `Delete this position record dated as of ${moment(validFrom).format('L')}?`,
          },
          {
            type: 'description',
            data: 'Once deleted, it cannot be restored.',
          },
        ],
        acceptActionName: 'deleteWorkbookRecordDashboard',
        acceptActionTitle: 'Delete',
        acceptActionArguments: {
          recordId,
          devstaffId,
        },
      };
      const devcentersListForSelect = [
        { value: '', label: '--' },
        ...generateDevCentersOptionsByBusinessRole(
          devCentersBySystemRole,
          userGroup,
          devcenter,
          isCEO,
          isCTO,
          businessRolesForResource,
          isBusinessRoleForCurrentRecord,
          isBusinessRoleForResource,
          allDevCenters
        ),
      ];

      return workBookFormGetter({
        partialGridTemplateColumnsConfig: workBookPartialGridTemplateColumnsConfig,
        titlesFromDevstaffCompensation,
        additionalControlsConfig,
        hasPermissionsToUpdate,
        internshipStartDate,
        deleteRecordAction,
        isHistoryRecord,
        isInternRecord,
        dismissalDate,
        isDismissed: false,
        devstaffId,
        inEditMode,
        rehireDate,
        probation,
        endOfProbation,
        validFrom,
        formStyles,
        devcentersListForSelect,
        dateHired,
        dateLast,
        fullname,
        isCEO,
        isDevCenterDirector,
        record,
        userId,
        hasNoSalaryInfo,
      });
    }
    const editRecordAction = {
      currentModal: 'CONFIRM_ACTION_MODAL',
      content: [
        {
          type: 'title',
          data: `Edit this position record dated as of ${moment(validFrom).format('L')}?`, // TODO add date
        },
        {
          type: 'description',
          data: 'Once edited, it will be resent for Dev Center Director approval.',
        },
      ],
      acceptActionName: 'toggleWorkBookRecordStateOnDashboard',
      acceptActionTitle: 'Edit',
      acceptActionArguments: {
        recordId,
      },
    };
    const deleteRecordAction = {
      currentModal: 'CONFIRM_ACTION_MODAL',
      content: [
        {
          type: 'title',
          data: `Delete this position record dated as of ${moment(validFrom).format('L')}?`, // TODO add date
        },
        {
          type: 'description',
          data: 'Once deleted, it cannot be restored.',
        },
      ],
      acceptActionName: 'deleteWorkbookRecordDashboard',
      acceptActionTitle: 'Delete',
      acceptActionArguments: {
        recordId,
        devstaffId,
      },
    };

    return {
      isInfoCard: true,
      recordId,
      cssRules: `
        display: grid;
        grid-column-gap: 0.8rem;
        grid-template-areas: ${workBookGridTemplateAreas};
        grid-template-columns: ${workBookGridTemplateColumns};
        margin: 0.4rem 0 0;
        border-bottom: 1px solid #bbbdc0;
        background-color: background-color: transparent;`, // '948fr 114fr 245fr' : '947fr 126fr 180fr'
      content: [
        {
          data: [
            {
              data: [
                // TODO crete data-getter on employee details config to follow DRY principe
                {
                  type: 'link',
                  data: fullname,
                  pathname: `/delivery/employee/${devstaffId}/work-book`,
                  title: fullname,
                  cssRules: `
                ${workBookFullNameCss}
                margin-left: 1.6rem;
              `,
                },
                {
                  type: 'description',
                  data: moment(validFrom).format('L'), // Valid from
                  cssRules: workBookDescriptionCss,
                },
                {
                  type: 'description',
                  data: devcenters.byIdShortName[devcenter] || '--',
                  cssRules: workBookDescriptionCss,
                },
                {
                  type: 'description',
                  data: title, // Title
                  isMultiline: true,
                  cssRules: `
                ${workBookDescriptionCss}
                padding-top: 2.4rem;
                line-height: 1.6rem;
              `,
                },
                {
                  type: 'description',
                  data: seniority, // Seniority
                  cssRules: workBookDescriptionCss,
                },
                {
                  type: 'description',
                  data: seniorityInt || '--', // Int. Seniority
                  cssRules: workBookDescriptionCss,
                },
                {
                  type: 'description',
                  data:
                    plLevelByIdForInfoCardRepresentation[`${plLevel}`] || '--',
                  cssRules: workBookDescriptionCss,
                },
                {
                  type: 'description',
                  data: hrsDay, // Hsr/day
                  cssRules: workBookDescriptionCss,
                },
                {
                  type: 'description',
                  data: probation ? 'Yes' : 'No', // Probation
                  cssRules: workBookDescriptionCss,
                },
                {
                  type: 'description',
                  data: endOfProbation ? moment(endOfProbation).format('MM/DD/YYYY') : '--', // endOfProbation
                  cssRules: workBookDescriptionCss,
                },
                {
                  type: 'description',
                  data: salUsd || '0', // salUsd
                  isHidden: !hasPermissionsToUpdate, // hidden from not CEO/CTO
                  cssRules: `${workBookDescriptionCss}
                text-align: right;
                padding-right: 0.8rem;
                ${!salUsd && `color: ${colorSecondaryGrayLight}`}
              `,
                },
                {
                  type: 'description',
                  data: salPln || '0', // salPln
                  isHidden: !hasPermissionsToUpdate, // hidden from not CEO/CTO
                  cssRules: `${workBookDescriptionCss}
                text-align: right;
                padding-right: 0.8rem;
                ${!salPln && `color: ${colorSecondaryGrayLight}`}
              `,
                },
                {
                  type: 'description',
                  data: salRur || '0', // Hsr/day
                  isHidden: !hasPermissionsToUpdate, // hidden from not CEO/CTO
                  cssRules: `${workBookDescriptionCss}
                text-align: right;
                padding-right: 0.8rem;
                ${!salRur && `color: ${colorSecondaryGrayLight}`};
              `,
                },
              ],
              cssRules: `
            display: grid;
            grid-template-columns: ${workBookPartialGridTemplateColumnsConfig};
            grid-column-gap: 0.8rem;
            padding: 0;
          `,
            },
            {
              data: [
                {
                  type: 'description',
                  data: remarks || 'No remark',
                  isMultiline: true,
                  cssRules: `${workBookDescriptionCss}
              letter-spacing: 0.15px;
              ${remarks ? '' : `color: ${colorSecondaryGrayLight};`}
              opacity: ${remarks ? '0.8' : '1'};
            `,
                },
              ],
              cssRules: `
            margin: 0.3rem 0 0 1.6rem;
            padding: 0;
          `,
            },
          ],
          cssRules: `
          grid-area: record;
          padding-bottom: 0.8rem;
        `,
        },
        {
          data: [
            {
              type: 'link',
              data: 'Get Reviews',
              isHidden: false,
              href: `/${deliveryReviewsReportAccess.route}/${devstaffId}`,
              withBlank: true,
              cssRules: `
                margin-top: 0.8rem;
                padding-right: 0;
                color: ${colorSecondaryText};
                text-align: center;
              `,
            },
            {
              type: 'actionButton',
              data: 'Edit record',
              isHidden: !isActionButtons,
              actionName: isDevCenterDirector ? 'toggleWorkBookRecordState' : 'openModal',
              actionArguments: isDevCenterDirector ?
                {
                  recordId,
                } :
                editRecordAction,
              cssRules: `
            margin-top: 1rem;
            text-align: center;
          `,
            },
            {
              type: 'actionButton',
              data: 'Delete',
              actionName: 'openModal',
              actionArguments: deleteRecordAction,
              isHidden: !isActionButtons,
              cssRules: `
            margin: 1rem 0;
            text-align: center;
          `,
            },
          ],
          cssRules: `
          display: grid;
          grid-area: controls;
          grid-template-columns: 1fr;
          grid-auto-rows: min-content;
          font-size: 1.2rem;
          line-height: 2.4rem;
          text-align: center;
        `,
        },
        {
          data: additionalControlsConfig.content,
          cssRules: `${additionalControlsConfig.cssRules}`,
        },
      ],
    };
  };

  return {
    hasAccessToSalaryReports,
    title: `Welcome ${userFullName || ''}`,
    rules: {
      wrapperCssRules: 'padding-bottom: 10rem',
    },
    additionalDataGetter: ({ users }) => ({ users }),
    isSingleTab: true,
    dataTemplate: [
      hasAccessToTaskOrderTableOnDashboard && {
        type: 'table',
        noDataMessage: 'This section currently contains no TO',
        stylesTemplate: {},
        content: [
          {
            title: 'Task Orders that expire within the next 40 days',
            tableName: 'taskOrders',
            idKey: 'taskOrdersId',
            dataKey: 'taskOrders',
            rowStatusGetter: ({ employeeId, projectId }) => ({
              id: `${employeeId}/${projectId}`,
              isActive: true,
              hasWarning: false,
            }),
            rules: {
              css: {
                gridTemplateConfig:
                  'grid-template-columns: minmax(auto, 50fr) minmax(auto, 210fr) minmax(auto, 140fr) minmax(auto, 80fr) minmax(auto, 80fr) minmax(auto, 50fr) minmax(auto, 80fr) minmax(auto, 50fr);', // fr units
              },
            },
            dataTemplate: [
              {
                columnName: 'TO',
                valueGetter: ({ projectId, taskOrderId }) => ({
                  type: hasPermissionsToTaskOrderModuleAccess ? 'link' : 'text',
                  value: taskOrderId,
                  componentProps: {
                    data: taskOrderId,
                    pathname: `/task-orders/${projectId}/${
                      isCTO ? 'staffing' : 'details'
                    }`,
                  },
                }),
              },
              {
                columnName: 'Project',
                valueGetter: ({ projectTitle, projectId }) => ({
                  type: hasPermissionsToTaskOrderModuleAccess ? 'link' : 'text',
                  value: projectTitle,
                  componentProps: {
                    data: projectTitle,
                    pathname: `/task-orders/${projectId}/${
                      isCTO ? 'staffing' : 'details'
                    }`,
                  },
                }),
              },
              {
                columnName: 'Client',
                valueGetter: ({ clientName, clientId }) => ({
                  type: hasPermissionsToClientsModule ? 'link' : 'text',
                  value: clientName,
                  componentProps: {
                    title: clientName,
                    data: clientName,
                    pathname: `clients/${clientId}/${
                      isCTO || isHRM ? 'task-orders' : 'details'
                    }`,
                    cssRules: 'font-size: 1.2rem;',
                  },
                }),
              },
              {
                columnName: 'Expiration Date',
                valueGetter: ({ projectEndDate }) => ({
                  type: 'text',
                  isDate: true,
                  unixValue: getUnixTime(projectEndDate),
                  value: projectEndDate ?
                    formatDateMMDDYYYY(projectEndDate) :
                    'N/A',
                }),
              },
              {
                columnName: 'Resource',
                valueGetter: ({ employeeId, fullname }) => {
                  const accessToEmployee = devstaffv2List.some((item) => item.devstaffId === employeeId);
                  return {
                    type:
                      employeeId && hasPermissionsToDeliveryRootModuleAccess && accessToEmployee ?
                        'link' :
                        'text',
                    value: fullname,
                    componentProps: {
                      title: fullname,
                      data: fullname,
                      pathname: employeeId ?
                        `delivery/employee/${employeeId}/${
                          isChefs ? 'work-book' : 'pto-requests'
                        }` : '',
                      cssRules: 'font-size: 1.2rem;',
                    },
                  };
                },
              },
              {
                columnName: 'Seniority',
                valueGetter: ({ seniority }) => ({
                  type: 'text',
                  value: seniority || 'n/a',
                }),
              },
              {
                columnName: "Resource's booking expiration date",
                headerProps: {
                  cssRules: 'padding-right: 1rem;',
                },
                valueGetter: ({ staffingEndDate }) => ({
                  type: 'text',
                  isDate: true,
                  unixValue: getUnixTime(staffingEndDate),
                  value: staffingEndDate ?
                    formatDateMMDDYYYY(staffingEndDate) :
                    'n/a',
                }),
              },
              {
                columnName: 'Account Manager',
                headerProps: {
                  cssRules: 'padding-right: 1.8rem;',
                },
                valueGetter: ({ users = [], clientAccountManagerId }) => {
                  const user =
                    users.find((item) => item.userId === clientAccountManagerId) || {};

                  return {
                    type: 'text',
                    value: get(user, 'fullName', 'n/a'),
                  };
                },
              },
            ],
          },
        ],
      },
      !!dashboardSeniorityUpdateRecords.length && {
        type: 'tableV2',
        dataTemplate: seniorityConfig(dashboardSeniorityUpdateRecords, userGroup),
      },
      !!ceoWorkBookRecords.length && {
        type: 'data-list',
        title: 'Work Book requests for CEO approval',
        headersConfig: {
          stylesTemplate: {
            cssRules: `
            ${workBookListHeadingStyles}
            top: 6.5rem; // TODO adjust when page welcome height be changed
            grid-template-columns: ${workBookGridTemplateColumns};
            margin-top: 0.8rem;
            z-index: 1;
          `,
          },
          data: workBookListHeadingDataGetter({
            hasPermissionsToUpdate,
            withResourceName: true,
          }),
        },

        content: ceoWorkBookRecords.map(getWorkbookRecord),
      },
      !!dDWorkBookRecords.length && {
        type: 'data-list',
        title: 'Work Book requests for Dev Center Director approval',
        headersConfig: {
          stylesTemplate: {
            cssRules: `
            ${workBookListHeadingStyles}
            top: 6.5rem;
            grid-template-columns: ${workBookGridTemplateColumns};
            margin-top: 0.8rem;
            z-index: 1;
          `,
          },
          data: workBookListHeadingDataGetter({
            hasPermissionsToUpdate,
            withResourceName: true,
          }),
        },

        content: dDWorkBookRecords.map(getWorkbookRecord),
      },
      !!ptoRequestRecords.length && {
        type: 'data-list',
        title: 'PTO requests for approval',
        headersConfig: {
          stylesTemplate: {
            cssRules: `${ptoListHeadingStyles}
            top: 6.5rem;
            grid-template-columns: ${ptoGridTemplateColumns};
            grid-column-gap: 0.8rem;
            margin-top: 0.8rem;
            z-index: 1;
          `,
          },
          data: ptoListHeadingDataGetter({
            withResourceName: true,
          }),
        },
        content: ptoRequestRecords.map((record) => {
          const {
            recordId,
            requestDate,
            fromDate,
            throughDate,
            hrs,
            workingDays = '',
            isUnpaid,
            description,
            devstaffId,
            remark,
            isApproved,
            isRejected,
            approvedDate = null,
            rejectedDate = null,
            lastModifiedDate = null,
            isPlApprovalInProgress,
            plApprovals,
            templateOwnerId,
            isCancelled,
          } = record || {};
          const hasApprovesList =
            plApprovals &&
            plApprovals.some(({ isApproved: hasPLApprove }) => hasPLApprove);
          const isHistoryRecord = isCancelled || isApproved;
          const additionalControlsParams = {
            hasPermissionsToUpdate: hasPermissionsToApprovePTO,
            isDashboardRecords: true,
            isDraft: templateOwnerId,
            isPlApprovalInProgress,
            lastModifiedDate,
            isHistoryRecord,
            approvedDate,
            rejectedDate,
            isCancelled,
            userFullName,
            devstaffId,
            // isApproved,
            isRejected,
            recordId,
            remark,
          };
          const additionalControlsConfig = ptoAdditionalControlsConfigGetter(additionalControlsParams);
          const fullname = get(
            employeeDataById,
            `${devstaffId}.fullname`,
            'resource'
          );

          return {
            isInfoCard: true,
            recordId,
            cssRules: `
            display: grid;
            grid-column-gap: 0.8rem;
            grid-template-areas: ${ptoGridTemplateAreas};
            grid-template-columns: ${ptoGridTemplateColumns};
            margin: 0.4rem 0 0;
            border-bottom: 1px solid #bbbdc0;
            ${isCancelled ? `background-color: ${colorPrimary003};` : ''}
          `,
            content: [
              {
                data: [
                  {
                    data: [
                      {
                        type: 'link',
                        data: fullname,
                        pathname: `/delivery/employee/${devstaffId}/pto-requests`,
                        cssRules: `
                  ${workBookDescriptionCss}
                  margin-left: 1.6rem;
                `,
                      },
                      {
                        type: 'description',
                        data: moment(requestDate).format('L'),
                        cssRules: workBookDescriptionCss,
                      },
                      {
                        type: 'description',
                        data: description,
                        cssRules: workBookDescriptionCss,
                      },
                      {
                        type: 'description',
                        data: moment(fromDate).format('L'),
                        cssRules: workBookDescriptionCss,
                      },
                      {
                        type: 'description',
                        data: moment(throughDate).format('L'),
                        cssRules: workBookDescriptionCss,
                      },
                      {
                        type: 'description',
                        data: hrs,
                        cssRules: `${workBookDescriptionCss} text-align: center;`,
                      },
                      {
                        type: 'description',
                        data: workingDays,
                        cssRules: `${workBookDescriptionCss} text-align: center;`,
                      },
                      {
                        type: 'description',
                        data: isUnpaid ? 'Yes' : 'No',
                        cssRules: `${workBookDescriptionCss} text-align: center;`,
                      },
                    ],
                    cssRules: `
                display: grid;
                grid-template-columns: ${ptoPartialGridTemplateColumnsConfig};
                grid-column-gap: 0.8rem;
                padding: 0;
              `,
                  },
                  {
                    data: [
                      {
                        type: 'description',
                        data: remark || 'No remark',
                        isMultiline: true,
                        cssRules: `${workBookDescriptionCss}
                  padding-top: 0.8rem;
                  letter-spacing: 0.15px;
                  ${remark ? '' : `color: ${colorSecondaryGrayLight};`}
                  opacity: ${remark ? '0.8' : '1'};
                `,
                      },
                    ],
                    cssRules: `
                margin: 0.3rem 0 0 1.6rem;
                padding: 0;
              `,
                  },
                  {
                    summaryConfig: {
                      label: 'PL Approval ',
                      cssRules: `
                  margin: 0.8rem 0 0.8rem 1.6rem;
                  padding-top: 0.8rem;
                `,
                    },
                    isHidden: !hasApprovesList,
                    data: hasApprovesList ?
                      plApprovalsDataGetter({
                        plApprovals,
                      }) :
                      [],
                    cssRules: `
                display: flex;
                flex-direction: column;
                margin: 0.3rem 0 1.6re 1.6rem;
                padding: 0;
              `,
                  },
                ],
                cssRules: `
              grid-area: record;
              padding-bottom: 0.8rem;
            `,
              },
              {
                data: additionalControlsConfig.content,
                cssRules: `
              ${additionalControlsConfig.cssRules}
              padding: 1.6rem 3.2rem;
            `,
              },
            ],
          };
        }),
      },
    ],
  };
};
