/* eslint-disable no-param-reassign */
import { useLayoutEffect } from 'react';

const useAutosizeTextArea = (
  textAreaRef,
  value,
) => {
  useLayoutEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = '0px';

      const scrollHeight = textAreaRef.scrollHeight;

      textAreaRef.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
