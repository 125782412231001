import {
  get,
} from 'lodash';

import {
  removeSpecialCharacters,
} from 'utils/helpers/numbers';

export const sortArrayByIndex = (array = [], columnIndex, isReversed) => (
  array.sort((a, b) => {
    const direction = isReversed ? -1 : 1;

    const leftItem = a.tableData[columnIndex];
    const rightItem = b.tableData[columnIndex];

    const leftItemValue = leftItem ? getItemValue(leftItem) : '';
    const rightItemValue = rightItem ? getItemValue(rightItem) : '';

    const options = {
      sensitivity: 'base',
      ignorePuctuations: true,
    };

    if (!Number.isNaN(+leftItemValue) && !Number.isNaN(+rightItemValue)) {
      return +leftItemValue > +rightItemValue ? -direction : direction;
    }

    return direction * leftItemValue.localeCompare(rightItemValue, 'en', options); // <= 1 ? -direction : direction;
  })
);

const getItemValue = (item) => {
  const data = Array.isArray(item) ? item.find(({ sortBy }) => sortBy) || item[0] || {} : item;
  const {
    isDate = false,
    unixValue,
    componentProps,
    value: storedValue,
  } = data;
  const value = get(componentProps, 'data', storedValue);

  if (isDate) {
    return unixValue;
  }

  if (!(value)) {
    return '';
  }
  const valueWithoutThousandSeparator = removeSpecialCharacters(value);

  if (valueWithoutThousandSeparator && !Number.isNaN(valueWithoutThousandSeparator)) {
    return `${valueWithoutThousandSeparator}`;
  }

  return value.trim();
};

export const booleanCompareFunction = (a, b, isReversed) => {
  const aValue = a ? 1 : 0;
  const bValue = b ? 1 : 0;
  return numberCompareFunction(aValue, bValue, isReversed);
};

export const stringCompareFunction = (a, b, isReversed) => {
  const left = a ? a.trim().toLowerCase() : '';
  const right = b ? b.trim().toLowerCase() : '';

  const options = {
    sensitivity: 'base',
    ignorePunctuation: true,
  };

  return `${isReversed ? '-' : ''}${left.localeCompare(right, 'en', options)}`;
};

export const numberCompareFunction = (a, b, isReversed) => {
  const leftInstance = removeSpecialCharacters(a);
  const rightInstance = removeSpecialCharacters(b);
  const left = Number.isNaN(leftInstance) ? 0 : leftInstance;
  const right = Number.isNaN(rightInstance) ? 0 : rightInstance;

  return isReversed ? right - left : left - right;
};

export const DIRECTIONS = {
  ASC: 0,
  DESC: 1,
};

export const toggleDirection = (direction) => direction === DIRECTIONS.ASC ? DIRECTIONS.DESC : DIRECTIONS.ASC;

export const getDirectionName = (direction) => direction === DIRECTIONS.ASC ? 'asc' : 'desc';
