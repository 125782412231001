import React, { useCallback, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { ActionButton } from 'components/buttons';

import { toast } from 'react-toastify';

import styles from './styles.module.scss';

export const FormActions = ({
  sendEmailsHandler,
  defaultValues,
  isControlsHidden,
  toastId,
}) => {
  const methods = useFormContext();
  const {
    reset,
    formState: { isDirty },
  } = methods;

  const resetHandler = useCallback(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (!isDirty && toast.isActive(toastId)) {
      toast.dismiss();
    }
  }, [isDirty]);

  return (
    <div
      data-id="Actions"
      className={classNames(styles.actions, {
        [styles.active]: isDirty,
      })}
    >
      <div data-id="Update Form Data" className={styles.formActions}>
        <ActionButton
          title="Save Button"
          dataId="saveButton"
          type="submit"
          className={styles.primaryButton}
          disabled={!isDirty}
          isLoading={false}
        >
          Save Changes
        </ActionButton>
        {
          isDirty && (
            <ActionButton
              title="Cancel Button"
              dataId="cancelButton"
              type="button"
              className={styles.secondaryButton}
              onClick={resetHandler}
            >
              Cancel
            </ActionButton>
          )
        }
      </div>
      {!isControlsHidden && (
        <div data-id="Send Emails">
          <ActionButton
            title="Send Emails Button"
            dataId="sendEmailsButton"
            type="button"
            className={styles.primaryButton}
            onClick={sendEmailsHandler}
            disabled={isDirty}
          >
            Send Emails
          </ActionButton>
        </div>
      )}
    </div>
  );
};

FormActions.propTypes = {
  sendEmailsHandler: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({}).isRequired,
  isControlsHidden: PropTypes.bool.isRequired,
  toastId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormActions.defaultProps = {
  toastId: null,
};
