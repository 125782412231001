import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Select from 'components/filter-select';

export const FilterSelect = ({ name, accessor, id, data, isActive, changeAction }) => {
  const dispatch = useDispatch();
  const items = data.map((item) => item[accessor]);

  const handleChange = useCallback((selectedOptions) => {
    const selectedOptionsValues = selectedOptions.map((option) => {
      const currentItem = data.find((item) => item[accessor] === option);

      return currentItem[id];
    });

    dispatch(changeAction({
      [name]: {
        value: selectedOptionsValues,
        isActive: !!selectedOptions.length,
      },
    }));
  }, [data]);

  return (
    <Select
      items={items}
      onChange={handleChange}
      isMultiple
      isActive={isActive}
    />
  );
};

FilterSelect.propTypes = {
  name: PropTypes.string.isRequired,
  accessor: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isActive: PropTypes.bool.isRequired,
  changeAction: PropTypes.func.isRequired,
};

FilterSelect.defaultProps = {
  data: [],
};
