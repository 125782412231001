import React, { Fragment, forwardRef } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import AlphabetFilter from 'components/alphabet-filter';
import units from 'components/shared-units';
import ActionButton from 'elements/action-button';
import Title from 'elements/title';
import Wrapper from 'elements/wrapper';
import { Link } from 'react-router-dom';

import './styles.scss';

const TEXTS = {
  CREATE_NEW: 'Create New',
}

const Controls = forwardRef(({
  cssRules,
  title,
  children,
  unitActions,
  changeFilter,
  resetFilters,
  hasActiveFilters,
  hasAlphabetFilter,
  controlsList,
  alphabetFilter: {
    type,
    selected,
    storeKey,
  },
  className,
}, ref) => (
  <div ref={ref} className={classNames('controls', className)}>

    <Wrapper
      cssRules={
        `
          ${cssRules}
          flex-direction: column;
          justify-content: center;
        `
      }
    >

      <div className="controls__wrapper">

        <div className="controls__main-controls">

          <Title>
            {title}
          </Title>

          {
            controlsList.map(({
              url,
              route,
              hasPermissions,
              type: controlType,
              title: controlTitle,
              onClick: controlOnClick,
              ...rest
            }, index) => {
              if (controlType) {
                const onClick = () => controlOnClick({ unitActions });
                return (
                  React.createElement(
                    units[controlType],
                    {
                      key: index, // eslint-disable-line react/no-array-index-key
                      title: controlTitle,
                      onClick,
                      ...rest,
                    },
                  )
                );
              }
              return (
                <Fragment
                  key={index} // eslint-disable-line react/no-array-index-key
                >
                  {
                    url && hasPermissions &&
                    <a
                      className="controls__create-button button button--cancel"
                      href={url}
                    >
                      {
                        controlTitle
                      }
                    </a>
                  }
                  {
                    route && hasPermissions &&
                    <Link
                      title={TEXTS.CREATE_NEW}
                      className="controls__create-button button button--cancel"
                      to={route}
                    >
                      {TEXTS.CREATE_NEW}
                    </Link>
                  }
                </Fragment>
              );
            })
          }

        </div>

        {
          hasAlphabetFilter &&
          <AlphabetFilter
            changeFilter={changeFilter}
            selected={selected}
            type={type}
            storeKey={storeKey}
          />
        }

        <div className="controls__clear-filter">
          {
            hasActiveFilters &&
              <ActionButton
                data="Clear filters"
                onClick={() => resetFilters()}
              />
          }
        </div>

      </div>


      {
        children &&
        <div className="controls__additional-controls">
          {children}
        </div>
      }

    </Wrapper>

  </div>
));


Controls.propTypes = {
  unitActions: PropTypes.shape({}),
  children: PropTypes.any,
  title: PropTypes.string,
  alphabetFilter: PropTypes.shape({
    isActive: PropTypes.bool,
    type: PropTypes.string,
    selected: PropTypes.string,
    storeKey: PropTypes.string,
  }),
  cssRules: PropTypes.string,
  resetFilters: PropTypes.func,
  changeFilter: PropTypes.func,
  hasActiveFilters: PropTypes.bool,
  hasAlphabetFilter: PropTypes.bool,
  controlsList: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

Controls.defaultProps = {
  children: null,
  title: null,
  resetFilters: null,
  changeFilter: null,
  hasActiveFilters: false,
  controlsList: [],
  unitActions: {},
  hasAlphabetFilter: false,
  alphabetFilter: {
    type: null,
  },
  className: '',
  cssRules: '',
};


export default Controls;
