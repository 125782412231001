import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import './styles.scss';

const StyledWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: -100vw;
  top: -100vh;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 144rem;
  margin: 0 auto;
  padding: 0 4rem;
  opacity: 0;
  transition: opacity 0.3s;

  grid-template-rows: max-content;
  grid-column-gap: 8rem;
  grid-auto-rows: auto;

  ${({ isGrow }) => isGrow && css`
    flex-grow: 1;
  `}

  ${({ isVisible }) => isVisible && css`
    position: relative;
    left: 0;
    top: 0;
    opacity: 1;
  `}

  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;


const Wrapper = ({ children, isVisible, isGrow, cssRules, className }) => (
  <StyledWrapper
    isVisible={isVisible}
    isGrow={isGrow}
    cssRules={cssRules}
    className={className}
  >
    {children}
  </StyledWrapper>
);


Wrapper.propTypes = {
  children: PropTypes.any,
  isGrow: PropTypes.bool,
  isVisible: PropTypes.bool,
  cssRules: PropTypes.string,
  className: PropTypes.string,
};

Wrapper.defaultProps = {
  isGrow: false,
  children: null,
  isVisible: true,
  cssRules: '',
  className: '',
};


export default Wrapper;
