import React, { useCallback, useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import DetailsForm from 'components/details-form';
import SingleTable from 'components/details-table/single-table';
import Controls from 'components/list-controls';
import Loader from 'components/loader';

import { USERS_GROUPS } from 'core/auth/constants';
import { selectUserGroup } from 'core/auth/selectors';
import { configurationsActions } from 'core/configurations/actions';
import {
  selectHiqoContracts,
  selectHiqoContractsData,
  selectHiqoContractsListModel,
  selectHiqoContractsDetails,
  selectCreateMode,
} from 'core/configurations/selectors';
import GoTop from 'elements/go-top-button';
import Wrapper from 'elements/wrapper';

import { useHandleScroll } from 'layouts/hiqo-contracts/useHandleScroll';
import { createStructuredSelector } from 'reselect';
import { getHasPermissions } from 'utils/auth';
import { autoScrollToTop } from 'utils/react';

import {
  controlsCssRules,
  addButtonStyles,
  wrapperCssRules,
  formWrapperStyles,
} from './styles';

const {
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_KZH,
  CPS,
} = USERS_GROUPS;

const HiqoContractsManagement = ({
  location,
  entityName,
  getHiqoContracts,
  deliveryCenters,
  isFetching,
  isFormSubmitted,
  orderRules,
  changeOrder,
  hiqoContractsData,
  hiqoContractsListModel,
  hiqoContractsDetails,
  createMode,
  setCreateMode,
  createHiqContractsRecord,
  changeCurrentModal,
  removeHiqoContractsRecord,
  userGroup,
}) => {
  autoScrollToTop(location);

  const isAvailable = !getHasPermissions(userGroup, [
    DELIVERY_OFFICE_ADMIN_BY,
    DELIVERY_OFFICE_ADMIN_TBS,
    DELIVERY_OFFICE_ADMIN_WAW,
    DELIVERY_OFFICE_ADMIN_SPB,
    DELIVERY_OFFICE_ADMIN_KZH,
    CPS,
  ]);
  const onSubmit = ({ fields }) => createHiqContractsRecord({ fields });
  const additionalActions = {
    removeHiqoContractsRecord,
    setCreateMode,
    openModal: ({ currentModal, ...args }) => changeCurrentModal({ currentModal, params: { ...args } }),
  };

  const handleAddContract = useCallback(
    () => {
      if (createMode) {
        return;
      }

      setCreateMode({
        createMode: true,
      });
    },
    [createMode],
  );

  const controlsList = isAvailable ?
    [
      {
        type: 'action',
        data: 'Add contract',
        onClick: handleAddContract,
        disabled: createMode,
        cssRules: `
      ${addButtonStyles}
      margin-right: auto;
    `,
      },
    ] :
    [];

  useHandleScroll({
    scrollUp: !createMode,
    scrollDown: createMode,
  });

  useEffect(() => {
    if (deliveryCenters) {
      getHiqoContracts();
    }

    return () => {
      setCreateMode({ createMode: false });
    };
  }, []);

  return (
    <>
      <Controls
        title="HiQo Contracts Management"
        cssRules={controlsCssRules}
        controlsList={controlsList}
      >
      </Controls>
      <Loader isLoaded={!isFetching}>
        <Wrapper cssRules={wrapperCssRules} className="hiqo-contracts-wrapper">
          <SingleTable
            entityName={entityName}
            data={hiqoContractsData}
            model={hiqoContractsListModel}
            orderRules={orderRules}
            changeOrder={changeOrder}
            tableName="hiqoContracts"
            isFetching={false}
            preventScrolling={false}
            withoutLoader
            controls={[]}
            noDataMessage="No non-working days in selected period or delivery center"
            unitActions={additionalActions}
          />
          {createMode && (
            <DetailsForm
              stylesTemplate={formWrapperStyles}
              isFetchingDetails={isFetching}
              content={hiqoContractsDetails}
              onSubmit={onSubmit}
              isFormSubmitted={isFormSubmitted}
              additionalActions={additionalActions}
            />
          )}
        </Wrapper>
      </Loader>
      <GoTop />
    </>
  );
};

HiqoContractsManagement.propTypes = {
  location: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  getHiqoContracts: PropTypes.func.isRequired,
  deliveryCenters: PropTypes.shape({}).isRequired,
  hiqoContractsData: PropTypes.array.isRequired,
  hiqoContractsListModel: PropTypes.shape({}).isRequired,
  hiqoContractsDetails: PropTypes.shape({}).isRequired,
  createMode: PropTypes.bool.isRequired,
  setCreateMode: PropTypes.func.isRequired,
  createHiqContractsRecord: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  removeHiqoContractsRecord: PropTypes.func.isRequired,
  userGroup: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  hiqoContracts: selectHiqoContracts,
  hiqoContractsData: selectHiqoContractsData,
  hiqoContractsListModel: selectHiqoContractsListModel,
  hiqoContractsDetails: selectHiqoContractsDetails,
  createMode: selectCreateMode,
  userGroup: selectUserGroup,
});

const mapDispatchToProps = {
  getHiqoContracts: configurationsActions.getHiqoContracts,
  setCreateMode: configurationsActions.setCreateMode,
  setDevcenterFilter: configurationsActions.setDevcenterFilter,
  createHiqContractsRecord: configurationsActions.createHiqContractsRecord,
  removeHiqoContractsRecord: configurationsActions.removeHiqoContractsRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HiqoContractsManagement);
