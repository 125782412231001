import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Overlay from 'elements/modal-overlay';
import DetailsForm from 'components/details-form';
import ToastContainer from 'components/toast';
import { parseFormData } from 'utils/helpers/models';
import './styles.scss';


const EditMsaModal = ({
  clearModal,
  params,
  updateClientDetails,
  isFormSubmitted: isSubmitted,
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(isSubmitted);
  const {
    stylesTemplate = {},
    generatedMsa,
    msaTemplate,
    defaultMsaTemplate,
    currentClientID: clientId,
    nosec14,
    individualLiability,
  } = params;
  const { msaSections, msaSectionsNosec14, msaSectionsIndividual, msaSectionsIndividualNosec14 } = defaultMsaTemplate;
  let actualDefaultMsaTemplate = msaSections;
  if (nosec14 && individualLiability) {
    actualDefaultMsaTemplate = msaSectionsIndividualNosec14;
  } else if (nosec14 && !individualLiability) {
    actualDefaultMsaTemplate = msaSectionsNosec14;
  } else if (individualLiability && !nosec14) {
    actualDefaultMsaTemplate = msaSectionsIndividual;
  }
  const { modalCss } = stylesTemplate;
  const content = parseFormData({
    content: [
      {
        formData: [{
          type: 'msaText',
          name: 'msaTemplate',
          isMultiline: true,
          getMsaTemplate: () => ({ actualDefaultMsaTemplate, msaTemplate }),
          valueGetter: (fieldText) => ({ value: fieldText }),
        }],
      },
      {
        stylesTemplate: {},
      },
    ],
    details: msaTemplate,
  });

  return (
    <Overlay
      clearModal={clearModal}
      cssRules={modalCss}
    >
      <ToastContainer
        containerId="editMsaModal"
      />

      <div className="manage-msa-modal">
        <p className="manage-msa-modal__title">
          {generatedMsa}
        </p>

        <DetailsForm
          clearModal={clearModal}
          formTitle="msaText"
          content={content}
          onSubmit={({ fields }) => {
            updateClientDetails({ clientId, fields });
            setIsFormSubmitted(true);
          }} // TODO: add handler eslint-disable-line
          isFormSubmitted={isFormSubmitted}
          stylesTemplate={stylesTemplate}
          isFetchingDetails={false}
        />
      </div>

    </Overlay>
  );
};


EditMsaModal.propTypes = {
  isFormSubmitted: PropTypes.bool.isRequired,
  updateClientDetails: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  params: PropTypes.shape({}).isRequired,
};

export default EditMsaModal;
