import {
  colorSecondaryGrayLight,
} from 'assets/styles/variables';

import {
  COLUMN_TYPES,
} from 'core/constants';
import hash from 'hash-sum';

import { gridTableConfig } from 'layouts/billing-project-reports/model/utils';
import {
  toNumber,
} from 'lodash';

import {
  formatDateMMDDYYYY,
  getUnixTime,
} from 'utils/helpers/date';
import { showResourceLinkByDevCenter } from 'utils/helpers/link';
import {
  addThousandSeparator,
} from 'utils/helpers/numbers';

export default ({
  isCTO,
  isBillable,
  hasPermissionsTOClientModule,
  hasPermissionsTODeliveryModule,
  availableDevCentersForDd,
}) => ({
  rowStatusGetter: (row) => {
    const id = hash(row);
    const {
      isGrandTotal,
    } = row;

    return {
      id,
      stylesTemplate: isGrandTotal ? {
        gridTemplateConfig: gridTableConfig(isBillable),
        rowStyles: `&& {
          border-top: 1px solid ${colorSecondaryGrayLight};
        }`,
      } : {},
    };
  },
  rules: {
    css: {
      gridTemplateConfig: gridTableConfig(isBillable),
    },
  },
  dataTemplate: [{
    columnName: 'Date',
    headerProps: {
      cssRules: `
        display: flex;
        align-items: center;
        min-height: 2.8rem;`,
    },
    withoutControls: true,
    valueGetter: ({
      isGrandTotal,
      label,
      date,
    }) => {
      if (isGrandTotal) {
        return {
          type: COLUMN_TYPES.TEXT,
          componentProps: {
            data: `Total for ${label}`,
            cssRules: `
                font-size: 1.4rem;
                font-weight: 500;`,
          },
        };
      }
      return ({
        type: COLUMN_TYPES.TEXT,
        isDate: true,
        unixValue: getUnixTime(date),
        value: date ? formatDateMMDDYYYY(date) : 'N/A',
      });
    },
  }, {
    columnName: 'Resource',
    withoutControls: true,
    valueGetter: ({
      isGrandTotal,
      devstaffId,
      resource,
      devcenterId,
    }) => {
      if (isGrandTotal) {
        return {};
      }
      const hasPermissions = isBillable ? hasPermissionsTODeliveryModule && devstaffId : hasPermissionsTOClientModule && devstaffId;
      return ({
        type: showResourceLinkByDevCenter(isCTO, hasPermissions, availableDevCentersForDd, devcenterId) ? 'link' : 'text',
        componentProps: {
          data: `${resource}` || 'n/a',
          pathname: `/delivery/employee/${devstaffId}/details`,
          cssRules: 'font-size: 1.2rem;',
          isMultiline: true,
        },
      });
    },
  },
  {
    columnName: 'Client Category',
    withoutControls: true,
    valueGetter: ({
      clientCategoryName,
    }) => ({
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data: clientCategoryName,
        cssRules: 'font-size: 1.2rem;',
        isMultiline: true,
      },
    }),
  },
  {
    columnName: 'Billing Category',
    withoutControls: true,
    valueGetter: ({
      internalCategoryName,
    }) => ({
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data: internalCategoryName,
        cssRules: 'font-size: 1.2rem;',
        isMultiline: true,
      },
    }),
  },
  {
    columnName: 'Hours',
    withoutControls: true,
    valueGetter: ({
      hours: rowHours,
      isGrandTotal,
    }) => {
      const value = addThousandSeparator(toNumber(rowHours).toFixed(2));
      return {
        type: COLUMN_TYPES.TEXT,
        value,
        cssRules: `&& {
            padding-right: calc(100% - 6ch);
            text-align: left;
            ${!rowHours ? `color: ${colorSecondaryGrayLight}` : ''}
            ${isGrandTotal ? 'font-weight: 500;' : ''}
          }`,
      };
    },
  }, {
    columnName: 'Overtime',
    withoutControls: true,
    shouldntBeRendered: !isBillable,
    valueGetter: ({
      overtime: rowOvertime,
      isGrandTotal,
    }) => {
      const value = addThousandSeparator(toNumber(rowOvertime).toFixed(2));
      return {
        type: COLUMN_TYPES.TEXT,
        shouldntBeRendered: !isBillable,
        value,
        cssRules: `&& {
            padding-right: calc(100% - 6ch);
            text-align: left;
            ${!rowOvertime ? `color: ${colorSecondaryGrayLight}` : ''}
            ${isGrandTotal ? 'font-weight: 500;' : ''}
          }`,
      };
    },
  }, {
    columnName: 'Worklog',
    withoutControls: true,
    valueGetter: ({
      worklog,
    }) => ({
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data: worklog,
        cssRules: 'font-size: 1.2rem;',
        isMultiline: true,
      },
    }),
  }],
});
