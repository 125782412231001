import React from 'react';

import CustomLink from 'elements/link';
import Text from 'elements/plain-text';
import { getOnboardingErrorRows } from 'layouts/resource-management-report/config/heplers/errorRows';
import moment from 'moment';

import { stringSortIgnoreCase } from './heplers/stringSortIgnoreCase';

export const onboarding = (data, {
  locales,
  showResourceLinkByDevCenter,
  isCTO,
  availableDevCenters,
  hasPermissions,
}) => ({
  id: 'onboarding',
  title: locales.onboardingRows.title,
  columns: [
    {
      Header: locales.onboardingRows.date,
      accessor: 'onboardingDate',
      Cell: (cellInfo) => moment(cellInfo.row.original.onboardingDate).format('MM/DD/YYYY'),
    },
    {
      Header: locales.resource,
      accessor: 'fullname',
      Cell: (cellInfo) => {
        const { row: { original: { currentDevcenterId, devstaffId, fullname, devcenterId, approved, hasPassed } } } = cellInfo;
        const currentDevCenter = currentDevcenterId || devcenterId;
        let futureDevCenter = !hasPassed ? devcenterId : null;
        if (!approved) {
          futureDevCenter = devcenterId;
        }
        if (showResourceLinkByDevCenter(
          isCTO,
          hasPermissions,
          availableDevCenters,
          currentDevCenter,
          futureDevCenter
        )) {
          return (
            <CustomLink
              pathname={`/delivery/employee/${devstaffId}/work-book`}
              id={devstaffId}
              data={fullname}
            />
          );
        }

        return <Text data={fullname} />;
      },
      sortType: stringSortIgnoreCase,
    },
    {
      Header: locales.deliveryCenter,
      accessor: 'devcenter',
    },
    {
      Header: locales.onboardingRows.status,
      accessor: 'onboardingStatus',
    },
  ],
  data,
  errorRows: getOnboardingErrorRows(data),
  isEmpty: data.length === 0,
});
