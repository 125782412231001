import React, { memo } from 'react';

import PropTypes from 'prop-types';

export const PlusIcon = memo(({
  withCircle,
}) => (
  <div data-id="PlusIcon">
    {withCircle ? (
      <svg color="#58595B" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.9787 5H9.98028V9H5.9834V11H9.98028V15H11.9787V11H15.9756V9H11.9787V5ZM10.9795 0C5.46381 0 0.987305 4.48 0.987305 10C0.987305 15.52 5.46381 20 10.9795 20C16.4952 20 20.9717 15.52 20.9717 10C20.9717 4.48 16.4952 0 10.9795 0ZM10.9795 18C6.57294 18 2.98574 14.41 2.98574 10C2.98574 5.59 6.57294 2 10.9795 2C15.3861 2 18.9733 5.59 18.9733 10C18.9733 14.41 15.3861 18 10.9795 18Z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.78571 -0.00195312V4.28376H0.5V5.71233H4.78571V9.99805H6.21429V5.71233H10.5V4.28376H6.21429V-0.00195312H4.78571Z"
          fill="#8C8C8C"
        />
      </svg>
    )
    }
  </div>
));

PlusIcon.propTypes = {
  withCircle: PropTypes.bool,
};

PlusIcon.defaultProps = {
  withCircle: false,
};

PlusIcon.displayName = 'PlusIcon';
