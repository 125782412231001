import ErrorOutlineIcon from 'assets/img/icon-error-outline.svg';
import {
  colorPrimary,
  colorSecondaryText,
  colorSecondaryGrayDark,
  colorSecondaryGrayLight,
  colorSecondaryGrayLight015,
} from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import { deletePLAssignments } from 'core/auth/guaranteedAccessRoles';
import { COLUMN_TYPES, FILTERS_TYPES } from 'core/constants';
import { STORE_KEYS } from 'core/delivery/constants';
import hash from 'hash-sum';
import { get, toNumber } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

import { getHasPermissions } from 'utils/auth';
import ExpandingSectionStore from 'utils/expanding-sections-store';

import { checkIsInclude, checkIsSelected } from 'utils/helpers/filters';
import { object, string, array, number } from 'yup';

import { convertPLL, getRowBackground } from './utils';

const { CPS } = USERS_GROUPS;

const gridTemplateConfig =
  'grid-template-columns: minmax(0, 1fr) minmax(0, 5fr) minmax(0, 15fr);';

export const projectsListModelGetter = ({ plsData, group }) => {
  const isCPS = getHasPermissions(group, CPS);
  const filedArrayRootName = 'projectLeads';
  const totalPercentageExceed = 'Total % of PL and its delegates exceeds 100%';
  const { map: plsDataMap, activeEmployees, plList } = plsData;

  const getHasPermissionsToDeleteAssignments = getHasPermissions(
    group,
    deletePLAssignments
  );

  return {
    formId: filedArrayRootName,
    rules: {
      css: {
        filtersCssRules: {
          gridTemplateConfig:
            'grid-template-columns: minmax(auto, 32fr) minmax(auto, 130fr) minmax(auto, 385fr) minmax(auto, 740fr);',
          wrapperCssRules: 'padding-top: 1.9rem;',
        },
        screenPlaceholderCssRules: `
          && {
            position: sticky;
            top: 15.5rem;
            height: calc(100vh - 28rem);
          }
        `,
        wrapperCssRules: `
          display: grid;
          grid-template-columns: 547fr 740fr;
          grid-column-gap: 0.5rem;
          min-height: calc(100vh - 20rem);
          $value: 547*100/(547+740);
          ::before {
            height: 100%;
            width: .5rem;
            position: absolute;
            left: calc(42.5% + .5rem);
            display: block;
            background: #fbfbfb;
            content: '';
          }`,
        // 42.5% = 547*100/(547+740), 547fr and 740fr are from grid-template-columns, +.5rem is grid-column-gap
        formStylesTemplate: {
          errorBoxCssRules: `
            && {
              padding: 1.6rem;
              background-color: #f2f2f2;
            }
          `,
          formControlsCss: `
            grid-template-columns: minmax(auto, 240fr) minmax(auto, 240fr) minmax(auto, 174fr);
            grid-column-gap: 0.8rem;
            margin-top: 1.5rem;
            padding-bottom: 2.0rem;
          `,
          formWrapperStyles: `
            && {
              position: sticky;
              top: 15.5rem;
              grid-area: auto;
              height: calc(100vh - 18rem);
              min-height: 450px;
              overflow-y: auto;
              overflow-x: hidden;
            }
          `,
        },
        noteValidPlAssignmentsCheckBoxCssRules: `
          &::after {
            position: absolute;
            right: 0;
            top: 50%;
            display: block;
            width: 1.8rem;
            height: 1.8rem;
            border-radius: 50%;
            background: linear-gradient(to left, rgba(214,31,38, 0.08), rgba(214,31,38, 0.08)), #f2f2f2;
            content: '';
            transform: translateY(-50%);
            transition: opacity 0.3s;
          }
        `,
        noPlAssignmentsCheckBoxCssRules: `
          &::after {
            position: absolute;
            right: 0;
            top: 50%;
            display: block;
            width: 1.8rem;
            height: 1.8rem;
            border-radius: 50%;
            background: linear-gradient(to left, rgba(187,189,192, 0.25), rgba(187,189,192, 0.25)), #f2f2f2;
            content: '';
            transform: translateY(-50%);
            transition: opacity 0.3s;
          }
        `,
        gridTemplateConfig: `
          ${gridTemplateConfig}
          top: 15.2rem;
        `,
      },
    },
    rowStatusGetter: ({
      isFavored,
      isPlAssigned,
      internalCategoryId,
      internalCategoryKey,
      isPlAssignmentValid,
    }) => ({
      id: internalCategoryId,
      isFavored,
      internalCategoryKey,
      stylesTemplate: {
        rowStyles: `
        color: ${
      !isPlAssignmentValid || !isPlAssigned ? colorPrimary : 'inherit'
      };
        background-color: ${getRowBackground({
        ifFirstCondition: isPlAssigned && !isPlAssignmentValid,
        isSecondCondition: !isPlAssigned,
      })};`,
      },
    }),
    tableName: 'projectsList',
    currentFilter: 'projectsFilters',
    noDataMessage: 'This section currently contains no Project records.',
    filtersTemplate: [
      {
        type: FILTERS_TYPES.TOGGLE,
        storeKey: STORE_KEYS.IS_FAVORED,
        checkFilter: ({ currentRow }) => !get(currentRow, 'isFavored', false),
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.INTERNAL_CATEGORY_KEY,
        valueGetter: (row) => get(row, 'internalCategoryKey', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'internalCategoryKey', '');
          return checkIsSelected({ selected, value });
        },
        componentProps: {
          closeMenuOnSelect: true,
        },
      },
      {
        type: FILTERS_TYPES.SEARCH,
        storeKey: STORE_KEYS.INTERNAL_CATEGORY_NAME,
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'internalCategoryName', '');
          return checkIsInclude({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.TOGGLE,
        storeKey: STORE_KEYS.IS_NO_PL,
        shouldntBeRendered: true,
        checkFilter: ({ currentRow }) => get(currentRow, 'isPlAssigned', false),
      },
      {
        type: FILTERS_TYPES.TOGGLE,
        storeKey: STORE_KEYS.IS_NO_VALID_PL,
        shouldntBeRendered: true,
        checkFilter: ({ currentRow }) => get(currentRow, 'isPlAssignmentValid', ''),
      },
      {},
    ],
    dataTemplate: [
      {
        withImage: true,
        valueGetter: ({ isFavored, internalCategoryId }) => ({
          type: COLUMN_TYPES.FAVORED,
          cssRules: 'padding: 0.7rem 0 0;',
          componentProps: {
            isFavored,
            actionName: 'toggleFavorite',
            actionArguments: {
              id: internalCategoryId,
            },
          },
        }),
      },
      {
        columnName: 'Category Key',
        valueGetter: ({ internalCategoryKey }) => ({
          type: COLUMN_TYPES.TEXT,
          componentProps: {
            data: internalCategoryKey,
            withBlank: true,
            isMultiline: true,
            cssRules: 'font-size: 1.3rem;',
          },
        }),
      },
      {
        columnName: 'Category Name',
        valueGetter: ({ internalCategoryName }) => ({
          type: COLUMN_TYPES.TEXT,
          componentProps: {
            data: internalCategoryName || 'n/a',
            cssRules: 'font-size: 1.3rem;',
            isMultiline: true,
            withBlank: true,
          },
        }),
      },
    ],
    formTemplate: [
      {
        title: 'Project Leads for',
        stylesTemplate: {
          cssRules: `
          grid-row-gap: 0;
          margin-top: 0.4rem;
        `,
          titleControlsCss: `
          padding-right: 1.6rem;
          justify-content: space-between;
        `,
        },
        controls: [
          {
            isTextContent: true,
            onFormChange: ({ values: { projectLeads = [] } = {} }) => {
              const isCustomizedPercentage = projectLeads.reduce(
                (acc, { percentage }) => acc || !!percentage,
                false
              );

              return {
                isHidden: !isCustomizedPercentage,
                data: '% are customized',
                wrapperCssRules: `
              && {
                width: auto;
              }
            `,
                cssRules: `
              position: relative;
              padding: 0 0 0 2rem;
              font-size: 1.4rem;
              line-height: 1.8rem;
              color: ${colorPrimary};
              &&:before {
                position: absolute;
                left: 0;
                content: '';
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                background: url(${ErrorOutlineIcon}) center no-repeat;
              }
            `,
              };
            },
          },
        ],
        formData: [
          {
            isFieldArray: true,
            name: filedArrayRootName,
            valueGetter: ({ projectLeads = [], projectKey }) => {
              const plsMap = {};
              const delegatesMap = {};

              projectLeads.forEach(({ recordId, devstaffId, delegates }) => {
                plsMap[recordId] = devstaffId;
                delegates.forEach(({ id, devstaffId: delegateDevstaffId }) => {
                  delegatesMap[id] = delegateDevstaffId;
                });
              });

              const onPushLine = ({
                index,
                addLine,
                values = [],
                setFieldError,
                setFieldTouched,
              }) => {
                const {
                  lineId: _lineID,
                  plLevel: _plLevel,
                  dateFrom: _dateFrom,
                  recordId: _recordId,
                  percentage: storedPercentage,
                  ...rest
                } = get(values, index, {});
                const emptyLines = values.findIndex(({ dateFrom }) => !dateFrom);

                if (emptyLines !== -1) {
                  const fieldName = `${filedArrayRootName}[${emptyLines}].dateFrom`;
                  const errorMessage = 'Please select\xa0Date From';
                  setFieldError(fieldName, errorMessage);
                  setFieldTouched(fieldName, true);

                  const options = {
                    toastId: fieldName,
                    containerId: filedArrayRootName,
                    closeOnClick: false,
                  };

                  toast.error(errorMessage, options);
                } else {
                  ExpandingSectionStore.setGroupState({
                    groupId: filedArrayRootName,
                    state: false,
                  });
                  addLine(storedPercentage !== null ?
                    {
                      projectKey,
                      dateFrom: null,
                      devstaffId: undefined,
                      percentage: storedPercentage,
                      ...rest,
                    } :
                    {
                      projectKey,
                      dateFrom: null,
                      devstaffId: undefined,
                      ...rest,
                    });
                }
              };

              return {
                onPushLine,
                noDataMessage: 'This section currently contains no data.',
                value: projectLeads,
                fieldArrayCss: `
              & .expansion-summary__root {
                transition: background-color 0.3s;
              }
              & .expansion-summary__control {
                margin-top: 0.4rem;
              }
              & .expansion-summary__root--expanded {
                background-color: ${colorSecondaryGrayLight015};
              }
              & .expansion-panel__animated-content {
                border-bottom: 1px solid ${colorSecondaryGrayLight};
              }
            `,
                lineGetter: ({ index, actions, lineValues, currentValues }) => {
                  const setFieldTouched = get(actions, 'setFieldTouched');
                  const setFieldError = get(actions, 'setFieldError');
                  const moveLine = get(actions, 'move');
                  const {
                    recordId,
                    plLevel,
                    dateFrom,
                    devstaffId: selectedPl,
                    delegates = [],
                  } = lineValues || {};
                  const nextLineDateFrom = get(
                    currentValues,
                    `[${index + 1}].dateFrom`
                  );
                  const selectedDelegates = new Set([selectedPl]);

                  delegates.forEach(({ devstaffId }) => {
                    if (devstaffId) {
                      selectedDelegates.add(devstaffId);
                    }
                  });

                  const delegatesList = activeEmployees.filter(({ value }) => !selectedDelegates.has(value));

                  return {
                    lineId: hash({
                      lineId: dateFrom,
                      recordId,
                    }),
                    line: [
                      {
                        type: 'calendar',
                        name: `${filedArrayRootName}[${index}].dateFrom`,
                        fieldData: dateFrom,
                        label: 'Date From',
                        outputFormatting: ({ date }) => moment.isMoment(date) ?
                          date.format('YYYY-MM-DD') :
                          date,
                        popperProps: {
                          placement: 'bottom-start',
                        },
                        validationRules: {
                          customValidation: (date) => {
                            const storedDateFrom = date.format('YYYY-MM-DD');
                            const duplicationIndex = currentValues.findIndex(({ dateFrom: elementDateFrom }) => storedDateFrom === elementDateFrom);
                            const isDuplicated = !(duplicationIndex < 0);
                            if (isDuplicated) {
                              const fieldName = `${filedArrayRootName}[${index}].dateFrom`;
                              const errorMessage =
                                'The record with the same start date has already existed';
                              setFieldError(fieldName, errorMessage);
                              setFieldTouched(fieldName, true);
                              const options = {
                                toastId: fieldName,
                                containerId: filedArrayRootName,
                                closeOnClick: false,
                              };

                              toast.error(errorMessage, options);
                            }

                            return !isDuplicated;
                          },
                        },
                        onFormChange: ({
                          values,
                          hasFormChanges,
                          touched,
                          isSubmitting,
                        }) => {
                          if (hasFormChanges && !isSubmitting) {
                            const storedProjectLeads = get(
                              values,
                              filedArrayRootName,
                              []
                            );
                            const isDateFromTouched = get(
                              touched,
                              `${filedArrayRootName}[${index}].dateFrom`,
                              false
                            );
                            const hasEmptyLines = storedProjectLeads.some((line) => !line.dateFrom);
                            const numberOfPls = storedProjectLeads.length;

                            if (isDateFromTouched && !hasEmptyLines) {
                              if (numberOfPls > 1) {
                                const { dateFrom: storedDateFrom } =
                                  storedProjectLeads[index];
                                const moveIndex = storedProjectLeads.findIndex(({ dateFrom: elementDateFrom }) => moment(
                                  storedDateFrom,
                                  'YYYY-MM-DD'
                                ).isBefore(elementDateFrom, 'YYYY-MM-DD'));

                                setFieldTouched(
                                  `${filedArrayRootName}[${index}].dateFrom`,
                                  false
                                );

                                if (moveIndex >= 0) {
                                  moveLine(
                                    index,
                                    index < moveIndex ?
                                      moveIndex - 1 :
                                      moveIndex
                                  );
                                }
                              }
                            }
                          }
                          return {};
                        },
                        cssRules: `
                    && {
                      align-items: center;
                      max-height: unset;
                      padding-top: 1rem;
                      font-size: 1.3rem;
                      color: ${colorSecondaryText};
                      letter-spacing: -0.0025rem;
                    }
                  `,
                      },
                      {
                        type: 'viewBox',
                        name: `${filedArrayRootName}[${index}].dateThrough`,
                        placeholder: '',
                        fieldData: {
                          value: nextLineDateFrom ?
                            moment(nextLineDateFrom)
                              .subtract(1, 'days')
                              .format('MM/DD/YYYY') :
                            'now',
                        },
                        cssRules: `
                    max-height: 3.6rem;
                    padding: 0.7rem 0.4rem 0;
                    border-bottom: 0;
                    font-size: 1.3rem;
                    letter-spacing: -0.0025rem;
                    background-color: rgba(187,189,192,0.15);
                    ${
  nextLineDateFrom ?
    '' :
    `
                      justify-content: center;
                      color: ${colorSecondaryGrayLight};
                    `
}
                  `,
                      },
                      {
                        type: 'select',
                        name: `${filedArrayRootName}[${index}].devstaffId`,
                        placeholder: '',
                        label: 'Project Lead',
                        fieldData: {
                          selected: get(plsDataMap, selectedPl, {}),
                          items: plList,
                          itemsMap: plsDataMap,
                          hideSelectedOptions: true,
                        },
                        validationRules: {
                          isRequired: true,
                        },
                        cssRules: `
                    font-size: 1.3rem;
                    color: ${colorSecondaryText};
                    && .select-item {
                      min-height: 3.5rem;
                    }
                    && .select-item__control {
                      min-height: 3.5rem;
                    }
                    && .select-item__value-container {
                      padding-top: 0.9rem;
                    }
                  `,
                      },
                      {
                        type: 'viewBox',
                        name: `${filedArrayRootName}[${index}].plLevel`,
                        placeholder: '',
                        onFormChange: ({ values }) => {
                          const storedDevstaffId = get(
                            values,
                            `${filedArrayRootName}[${index}].devstaffId`
                          );
                          const initialPL = get(plsMap, `${recordId}`, {});

                          if (
                            recordId &&
                            initialPL &&
                            storedDevstaffId === initialPL
                          ) {
                            return {
                              fieldData: {
                                value: convertPLL(plLevel),
                              },
                            };
                          }

                          const storedDateFrom = get(
                            values,
                            `${filedArrayRootName}[${index}].dateFrom`
                          );
                          const plLevelByDate = get(
                            plsDataMap,
                            `${storedDevstaffId}.plLevelByDate`,
                            []
                          );
                          const currentPLL = plLevelByDate.find(({ date }) => moment(date).isSameOrBefore(storedDateFrom));
                          const pll = get(currentPLL, 'pll');
                          const value = convertPLL(pll);

                          return {
                            fieldData: {
                              value,
                            },
                          };
                        },
                        cssRules: `
                    justify-content: center;
                    max-height: 3.6rem;
                    padding: 0.7rem 0.4rem 0;
                    border-bottom: 0;
                    font-size: 1.3rem;
                    letter-spacing: -0.0025rem;
                    background-color: rgba(187,189,192,0.15);
                  `,
                      },
                      {
                        type: 'text',
                        name: `${filedArrayRootName}[${index}].percentage`,
                        fieldData: {},
                        label: 'Percentage',
                        onFormChange: ({ values }) => {
                          const storedProjectLeads = get(
                            values,
                            filedArrayRootName,
                            []
                          );
                          const storedDelegates = get(
                            storedProjectLeads,
                            `[${index}].delegates`,
                            []
                          );
                          const delegatesPercentage = storedDelegates.reduce(
                            (acc, { percentage = 0 }) => acc - toNumber(percentage),
                            100
                          );

                          return {
                            fieldData: {
                              placeholder: delegatesPercentage,
                            },
                          };
                        },
                        validationRules: {
                          isInteger: true,
                          isNumeric: true,
                          isRequired: true,
                          maxValue: 100,
                        },
                        cssRules: `
                    font-size: 1.3rem;
                    line-height: 1.3rem;
                    text-align: center;

                    && .text-input__input-field {
                      margin-top: 1.2rem;
                      padding-bottom: 0.3rem;
                      color: ${colorPrimary};

                      &::placeholder {
                        color: ${colorSecondaryText};
                      }
                    }
                  `,
                      },
                      {
                        type: 'viewBox',
                        onFormChange: ({ values }) => {
                          const storedDelegatesNumber = get(
                            values,
                            `${filedArrayRootName}[${index}].delegates.length`,
                            0
                          );

                          return {
                            fieldData: {
                              value: storedDelegatesNumber,
                            },
                          };
                        },
                        cssRules: `
                    justify-content: center;
                    max-height: 3.6rem;
                    padding: 0.7rem 0.4rem 0;
                    border-bottom: 0;
                    font-size: 1.3rem;
                    letter-spacing: -0.0025rem;
                    background-color: rgba(187,189,192,0.15);
                  `,
                      },
                      {
                        isMultiple: true,
                        cssRules: `
                     display: flex;
                     flex-direction: column;
                   `,
                        data: [
                          {
                            type:
                              (!recordId && !isCPS) ||
                              (getHasPermissionsToDeleteAssignments &&
                                recordId &&
                                !isCPS) ?
                                'actionButton' :
                                'empty',
                            data: 'Delete',
                            actionName: recordId ?
                              'changeCurrentModal' :
                              `${filedArrayRootName}_deleteLine`,
                            actionArguments: recordId ?
                              {
                                currentModal: 'CONFIRM_ACTION_MODAL',
                                params: {
                                  content: [
                                    {
                                      type: 'title',
                                      data: `Delete Pl record with start date ${moment(dateFrom).format('MM/DD/YYYY')}?`,
                                    },
                                    {
                                      type: 'description',
                                      data: 'This record will be permanently deleted and cannot be restored.',
                                    },
                                  ],
                                  acceptActionName: 'deletePLARecord',
                                  acceptActionTitle: 'Delete',
                                  acceptActionArguments: {
                                    recordId,
                                    projectKey,
                                    mutationName: 'deletePlAssignment',
                                  },
                                },
                              } :
                              {
                                index,
                              },
                            cssRules: `
                      && {
                        font-size: 1.3rem;
                        line-height: 2rem;
                      }
                    `,
                          },
                          {
                            type: isCPS ? 'empty' : 'actionButton',
                            data: 'Copy & Add',
                            actionName: `${filedArrayRootName}_addLine`,
                            actionArguments: {
                              index,
                            },
                            cssRules: `
                      && {
                        font-size: 1.3rem;
                      }
                    `,
                          },
                        ],
                      },
                    ],
                    additionalLine: {
                      withExpanding: true,
                      data: [
                        {
                          type: 'description',
                          data: 'Delegate:',
                          cssRules: `
                      box-sizing: border-box;
                      padding: 0.9rem 2rem 0 0;
                      font-size: 16px;
                      line-height: 24px;
                      color: ${colorSecondaryText};
                      text-align: right;
                    `,
                        },
                        {
                          isMultiple: true,
                          cssRules: `
                      display: flex;
                      flex-direction: column;
                    `,
                          data: [
                            {
                              isFieldArray: true,
                              name: `${filedArrayRootName}[${index}].delegates`, // TODO
                              fieldData: {
                                onPushLine: ({
                                  name: filedArrayName,
                                  values = [],
                                  addLine,
                                }) => {
                                  const emptyLines = values.findIndex(({ devstaffId, percentage }) => !devstaffId || !percentage);

                                  if (emptyLines !== -1 || !selectedPl) {
                                    const fieldName = selectedPl ?
                                      `${filedArrayName}[${emptyLines}].devstaffId` :
                                      `${filedArrayRootName}[${index}].devstaffId`;

                                    const errorMessage = selectedPl ?
                                      'Please select\xa0a\xa0Delegate\xa0and\xa0[%]' :
                                      'Please select\xa0a\xa0PL\xa0first';
                                    setFieldError(fieldName, errorMessage);
                                    setFieldTouched(fieldName, true);
                                    const options = {
                                      toastId: fieldName,
                                      containerId: filedArrayRootName,
                                      closeOnClick: false,
                                    };

                                    toast.error(errorMessage, options);
                                  } else {
                                    addLine({
                                      devstaffId: undefined,
                                      percentage: undefined,
                                    });
                                  }
                                },
                                noDataMessage: 'There are no items.',
                                lineGetter: ({
                                  index: innerLineIndex,
                                  lineValues: innerLineValues,
                                }) => {
                                  const {
                                    id: innerRecordId,
                                    plLevel: innerPlLevel,
                                    devstaffId,
                                  } = innerLineValues || {};
                                  const selectedDelegate = get(
                                    plsDataMap,
                                    devstaffId,
                                    {}
                                  );

                                  return {
                                    lineId: hash({
                                      lineId: devstaffId || innerLineIndex,
                                    }),
                                    line: [
                                      {
                                        type: 'select',
                                        name: `${filedArrayRootName}[${index}].delegates[${innerLineIndex}].devstaffId`,
                                        label: 'Delegate Name',
                                        fieldData: {
                                          selected: selectedDelegate,
                                          items: delegatesList,
                                          itemsMap: plsDataMap,
                                        },
                                        validationRules: {
                                          isRequired: true,
                                        },
                                        cssRules: `
                                  font-size: 1.3rem;
                                  color: ${colorSecondaryText};

                                  && .select-item {
                                    min-height: 3.5rem;
                                  }
                                  && .select-item__control {
                                    min-height: 3.5rem;
                                  }
                                  && .select-item__value-container {
                                    padding-top: 0.9rem;
                                  }
                                `,
                                      },
                                      {
                                        type: 'viewBox',
                                        name: `${filedArrayRootName}[${index}].delegates[${innerLineIndex}].pll`,
                                        placeholder: '',
                                        fieldData: {
                                          value: '-',
                                        },
                                        onFormChange: ({ values }) => {
                                          const storedDevstaffId = get(
                                            values,
                                            `${filedArrayRootName}[${index}].delegates[${innerLineIndex}].devstaffId`,
                                            []
                                          );
                                          const initialDelegate = get(
                                            delegatesMap,
                                            `${innerRecordId}`,
                                            {}
                                          );

                                          if (
                                            innerRecordId &&
                                            initialDelegate &&
                                            storedDevstaffId === initialDelegate
                                          ) {
                                            return {
                                              fieldData: {
                                                value: convertPLL(innerPlLevel),
                                              },
                                            };
                                          }

                                          const storedDateFrom = get(
                                            values,
                                            `${filedArrayRootName}[${index}].dateFrom`
                                          );
                                          const plLevelByDate = get(
                                            plsDataMap,
                                            `${storedDevstaffId}.plLevelByDate`,
                                            []
                                          );
                                          const currentPLL = plLevelByDate.find(({ date }) => moment(date).isSameOrBefore(storedDateFrom));
                                          const pll = get(currentPLL, 'pll');
                                          const value = convertPLL(pll);

                                          return {
                                            fieldData: {
                                              value,
                                            },
                                          };
                                        },
                                        cssRules: `
                                  justify-content: center;
                                  max-height: 3.6rem;
                                  padding: 0.7rem 0.4rem 0;
                                  border-bottom: 0;
                                  font-size: 1.3rem;
                                  letter-spacing: -0.0025rem;
                                  background-color: rgba(187,189,192,0.15);
                                `,
                                      },
                                      {
                                        type: 'text',
                                        name: `${filedArrayRootName}[${index}].delegates[${innerLineIndex}].percentage`,
                                        fieldData: {},
                                        label: 'Delegate Percentage',
                                        onFormChange: ({ values, errors }) => {
                                          const storedProjectLeads = get(
                                            values,
                                            filedArrayRootName,
                                            []
                                          );
                                          const storedPLPercentage = get(
                                            storedProjectLeads,
                                            `[${index}].percentage`,
                                            0
                                          );
                                          const storedDelegates = get(
                                            storedProjectLeads,
                                            `[${index}].delegates`,
                                            []
                                          );
                                          const delegatesPercentage =
                                            storedDelegates.reduce(
                                              (acc, { percentage = 0 }) => acc - toNumber(percentage),
                                              100
                                            );
                                          // const currentLinePercentage = get(storedDelegates, `[${innerLineIndex}].percentage`, 0);
                                          // const maxAvailablePercentage = (delegatesPercentage + currentLinePercentage) - storedPLPercentage;
                                          const hasExceeded =
                                            100 -
                                              delegatesPercentage +
                                              storedPLPercentage >
                                            100;
                                          const percentageError = get(
                                            errors,
                                            `${filedArrayRootName}[${index}].percentage`
                                          );

                                          if (
                                            !hasExceeded &&
                                            percentageError ===
                                              totalPercentageExceed
                                          ) {
                                            toast.dismiss(`${filedArrayRootName}[${index}].percentage`);
                                          }

                                          return {
                                            validationRules: {
                                              isInteger: true,
                                              isNumeric: true,
                                              isRequired: true,
                                              maxValue: 100,
                                              // customValidation: (value) => !value || maxAvailablePercentage >= value,
                                            },
                                          };
                                        },
                                        cssRules: `
                                  font-size: 1.3rem;
                                  line-height: 1.3rem;
                                  text-align: center;

                                  && .text-input__input-field {
                                    margin-top: 1.2rem;
                                    padding-bottom: 0.3rem;
                                  }
                                `,
                                      },
                                      {
                                        type:
                                          (!innerRecordId && !isCPS) ||
                                          (getHasPermissionsToDeleteAssignments &&
                                            !isCPS) ?
                                            'actionButton' :
                                            'empty',
                                        data: 'Delete',
                                        actionName: innerRecordId ?
                                          'changeCurrentModal' :
                                          `${filedArrayRootName}[${index}].delegates_deleteLine`,
                                        actionArguments: innerRecordId ?
                                          {
                                            currentModal:
                                                'CONFIRM_ACTION_MODAL',
                                            params: {
                                              content: [
                                                {
                                                  type: 'title',
                                                  data: `Delete PL delegate record for ${
                                                    selectedDelegate.label
                                                  } with start date ${moment(dateFrom).format('MM/DD/YYYY')}`,
                                                },
                                                {
                                                  type: 'description',
                                                  data: 'This record will be permanently deleted and cannot be restored.',
                                                },
                                              ],
                                              acceptActionName:
                                                  'deletePLARecord',
                                              acceptActionTitle: 'Delete',
                                              acceptActionArguments: {
                                                recordId: innerRecordId,
                                                projectKey,
                                                mutationName:
                                                    'deleteDelegate',
                                              },
                                            },
                                          } :
                                          {
                                            index: innerLineIndex,
                                          },
                                        cssRules: `
                                  && {
                                    font-size: 1.3rem;
                                    line-height: 2rem;
                                    margin-bottom: 1rem;
                                  }
                                `,
                                      },
                                    ],
                                    stylesTemplate: {
                                      cssRules: `
                                  grid-template-columns: minmax(0, 185fr) minmax(0, 40fr) minmax(0, 50fr) minmax(0, 40fr) minmax(0, 90fr);
                                  grid-column-gap: 0.8rem;
                                  grid-row-gap: 0.4rem;
                                  margin-top: 0;
                                  padding: 0;
                                `,
                                    },
                                  };
                                },
                                footerConfig: {
                                  stylesTemplate: {
                                    cssRules: `
                                grid-template-columns: minmax(auto,185fr) minmax(auto,243fr);
                                grid-column-gap: 0.8rem;
                                margin-top: 2rem;
                                grid-auto-rows: minmax(3.2rem, auto);
                                padding: 0;
                              `,
                                  },
                                  data: [
                                    {
                                      type: isCPS ? 'empty' : 'actionButton',
                                      data: 'Add Delegate',
                                      actionName: `${filedArrayRootName}[${index}].delegates_addLine`,
                                      withBody: true,
                                      cssRules: `
                                && {
                                  box-sizing: border-box;
                                  padding: 0.7rem 1.2rem;
                                  color: ${colorSecondaryGrayDark};
                                  line-height: 1.6rem;
                                  letter-spacing: 0.0125rem;
                                  text-align: center;
                                  font-size: 1.4rem;
                                  font-weight: 500;
                                  border: 1px solid ${colorSecondaryGrayDark};
                                }
                                &:hover {
                                  border: 1px solid #d61f26;
                                  color: #fff;
                                }
                              `,
                                    },
                                  ],
                                },
                              },
                            },
                          ],
                        },
                      ],
                      stylesTemplate: {
                        contentWrapperCss: `
                      display: grid;
                      grid-template-columns: minmax(0,240fr) minmax(0,423fr);
                      grid-column-gap: 0.8rem;
                      grid-row-gap: 0.4rem;
                      margin: 0.4rem 0 3.2rem;

                      && .details-form__field-array-line-placeholder {
                        margin-left: 0;
                        font-size: 1.3rem;
                      }
                    `,
                      },
                    },
                    stylesTemplate: {
                      cssRules: `
                    grid-template-columns: minmax(0, 108fr) minmax(0, 90fr) minmax(0, 185fr) minmax(0, 40fr) minmax(0, 50fr) minmax(0, 40fr) minmax(0, 90fr);
                    grid-column-gap: 0.8rem;
                    grid-row-gap: 0.4rem;
                    margin-top: 0;
                    padding: 0.4rem 0 0;
                  `,
                    },
                  };
                },

                headersConfig: {
                  stylesTemplate: {
                    cssRules: `
                  grid-template-columns: minmax(0, 150fr) minmax(0, 90fr) minmax(0, 185fr) minmax(0, 40fr) minmax(0, 50fr) minmax(0, 40fr) minmax(0, 90fr);
                  grid-column-gap: 0.8rem;
                  grid-row-gap: 0;
                  margin-top: 0;
                  padding: 0;
                `,
                  },
                  data: [
                    {
                      value: {
                        title: 'From',
                        cssRules: `
                    text-align: left;
                    padding-left: 4.6rem;
                    padding-bottom: 0.8rem;
                    font-size: 1.2rem;
                  `,
                      },
                    },
                    {
                      value: {
                        title: 'Through',
                        cssRules: `
                    text-align: left;
                    padding-bottom: 0.8rem;
                    font-size: 1.2rem;
                  `,
                      },
                    },
                    {
                      value: {
                        title: 'Project Lead',
                        cssRules: `
                    text-align: left;
                    padding-bottom: 0.8rem;
                    font-size: 1.2rem;
                    padding-right: 0;
                  `,
                      },
                    },
                    {
                      value: {
                        title: 'PLL',
                        cssRules: `
                    text-align: left;
                    padding-bottom: 0.8rem;
                    padding-right: 0;
                    font-size: 1.2rem;
                  `,
                      },
                    },
                    {
                      value: {
                        title: '[%]',
                        cssRules: `
                    text-align: left;
                    padding-right: 0;
                    padding-bottom: 0.8rem;
                    font-size: 1.2rem;
                    text-align: center;
                  `,
                      },
                    },
                    {
                      value: {
                        title: '#del',
                        cssRules: `
                    padding-bottom: 0.8rem;
                    padding-right: 0;
                    font-size: 1.2rem;
                  `,
                      },
                    },
                    {
                      value: {
                        title: 'Actions',
                        cssRules: `
                    padding-bottom: 0.8rem;
                    padding-right: 0;
                    font-size: 1.2rem;
                  `,
                      },
                    },
                  ],
                },
                footerConfig: {
                  stylesTemplate: {
                    cssRules: `
                  grid-template-columns: minmax(0, 240fr) minmax(0, 421fr);
                  grid-column-gap: 0.8rem;
                  margin-top: 2rem;
                  grid-auto-rows: minmax(3.2rem, auto);
                  padding: 0;
                `,
                  },
                  data: [
                    {
                      type: isCPS ? 'empty' : 'actionButton',
                      data: 'Add PL',
                      actionName: `${filedArrayRootName}_addLine`,
                      withBody: true,
                      cssRules: `
                  && {
                    box-sizing: border-box;
                    padding: 0.7rem 1.2rem;
                    color: ${colorSecondaryGrayDark};
                    line-height: 1.6rem;
                    letter-spacing: 0.0125rem;
                    text-align: center;
                    font-size: 1.4rem;
                    font-weight: 500;
                    border: 1px solid ${colorSecondaryGrayDark};
                  }

                  &:hover {
                    border: 1px solid #d61f26;
                    color: #fff;
                  }
                `,
                    },
                  ],
                },
              };
            },

            validationRules: {
              schemaGetter: () => array().of(object().shape({
                percentage: number()
                  .nullable(true)
                  .min(1, 'Cannot be lower than 1')
                  .when(['delegates'], (delegates = [], schema) => {
                    const totalDelegates = delegates.reduce(
                      (acc, { percentage: storedPercentage = 0 } = {}) => acc + storedPercentage,
                      0
                    );

                    return schema.test(
                      'totalPercentageExceed',
                      totalPercentageExceed,
                      (storedPercentage = 0) => toNumber(storedPercentage) + totalDelegates <= 100
                    );
                  }),
                devstaffId: number().required('Required filed'),
                dateFrom: string().nullable().required('Required field'),
                delegates: array().of(object().shape({
                  devstaffId: number().required('Required filed'),
                  percentage: number()
                    .nullable(true)
                    .min(1, 'Cannot be lower than 1')
                    .required('Required filed'),
                })),
              })),
            },
          },
        ],
      },
    ],
  };
};
