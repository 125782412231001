import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import FilterSearch from './components/search';

const Filter = ({ type, ...props }) => {
  const renderFilter = useMemo(() => {
    switch (type) {
      case 'search':
        return (
          <FilterSearch {...props} />
        );
      default:
        return <FilterSearch {...props} />;
    }
  }, [type]);

  return (
    <div data-id="Filter">
      {renderFilter}
    </div>
  );
};

Filter.propTypes = {
  type: PropTypes.string,
  changeFilter: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  type: 'search',
};

export default Filter;
