import {
  GET_RESOURCE_MANAGEMENT_COMMENTS,
  GET_RESOURCE_MANAGEMENT_COMMENTS_SUCCESS,
  GET_RESOURCE_MANAGEMENT_COMMENTS_FAIL,
  CREATE_RESOURCE_MANAGEMENT_COMMENT,
  CREATE_RESOURCE_MANAGEMENT_COMMENT_FAIL,
  CREATE_RESOURCE_MANAGEMENT_COMMENT_SUCCESS,
  DELETE_RESOURCE_MANAGEMENT_COMMENT,
  DELETE_RESOURCE_MANAGEMENT_COMMENT_FAIL,
  DELETE_RESOURCE_MANAGEMENT_COMMENT_SUCCESS,
  UPDATE_RESOURCE_MANAGEMENT_COMMENT,
  UPDATE_RESOURCE_MANAGEMENT_COMMENT_SUCCESS,
  UPDATE_RESOURCE_MANAGEMENT_COMMENT_FAIL,
  ADD_RESOURCE_MANAGEMENT_COMMENT, REMOVE_RESOURCE_MANAGEMENT_COMMENT,
} from 'core/delivery/commentManagement/actions';
import {
  pathToState,
  recordIdName,
} from 'core/delivery/commentManagement/constants';
import { FORM_STATE } from 'core/utils';
import update from 'immutability-helper';

import { newComment } from 'layouts/employee-details/components/resource-tabs/comments/constants';
import { get } from 'lodash';

export const commentManagementReducerCases = {
  [GET_RESOURCE_MANAGEMENT_COMMENTS](state) {
    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          isFetchingCommentManagementRecords: {
            $set: true,
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [GET_RESOURCE_MANAGEMENT_COMMENTS_SUCCESS](state, { payload: {
    records,
    memoDevstaffId,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          isFetchingCommentManagementRecords: {
            $set: false,
          },
          commentManagementRecords: {
            $set: records,
          },
          formState: {
            $set: {
              status: FORM_STATE.INITIAL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
          memoDevstaffId: {
            $set: memoDevstaffId,
          },
          length: {
            $set: records.length,
          },
        },
      },
    });
  },
  [GET_RESOURCE_MANAGEMENT_COMMENTS_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          isFetchingCommentManagementRecords: {
            $set: false,
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
  [CREATE_RESOURCE_MANAGEMENT_COMMENT](state) {
    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [CREATE_RESOURCE_MANAGEMENT_COMMENT_SUCCESS](state, { payload: {
    record,
  } }) {
    const commentManagementRecords =
      get(state, pathToState, []);

    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          length: {
            $set: commentManagementRecords.length,
          },
          commentManagementRecords: {
            $set: commentManagementRecords.map((currentRecord) => {
              if (currentRecord[recordIdName] === newComment({})[recordIdName]) {
                return record;
              }

              return currentRecord;
            }),
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [CREATE_RESOURCE_MANAGEMENT_COMMENT_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: true,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },

  [UPDATE_RESOURCE_MANAGEMENT_COMMENT](state) {
    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [UPDATE_RESOURCE_MANAGEMENT_COMMENT_SUCCESS](state, { payload: {
    record,
  } }) {
    const commentManagementRecords = get(state, pathToState, [])
      .map((currentRecord) => currentRecord[recordIdName] === record[recordIdName] ? record : currentRecord);

    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          commentManagementRecords: {
            $set: commentManagementRecords,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [UPDATE_RESOURCE_MANAGEMENT_COMMENT_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: true,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },

  [DELETE_RESOURCE_MANAGEMENT_COMMENT](state) {
    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },

  [DELETE_RESOURCE_MANAGEMENT_COMMENT_SUCCESS](state, { payload }) {
    const commentManagementRecords = get(state, pathToState, [])
      .filter((record) => record[recordIdName] !== payload);

    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          commentManagementRecords: {
            $set: commentManagementRecords,
          },
          length: {
            $set: commentManagementRecords.length,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [DELETE_RESOURCE_MANAGEMENT_COMMENT_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: true,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
  [ADD_RESOURCE_MANAGEMENT_COMMENT](state, { payload }) {
    const commentManagementRecords = get(state, pathToState, []);

    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          commentManagementRecords: {
            $set: [payload, ...commentManagementRecords],
          },
        },
      },
    });
  },
  [REMOVE_RESOURCE_MANAGEMENT_COMMENT](state) {
    const commentManagementRecords = get(state, pathToState, []);

    return update(state, {
      employeeDetailsTabs: {
        commentManagement: {
          commentManagementRecords: {
            $set: commentManagementRecords.filter((record) => record[recordIdName] !== newComment({})[recordIdName]),
          },
        },
      },
    });
  },
};
