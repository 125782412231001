/* eslint-disable consistent-return */
import { waitForAuthorization } from 'core/auth/sagas';
import { selectDevstaffId, selectErrors, selectEntityName } from 'core/delivery/selectors';
import { get } from 'lodash';
import { select, call, put, takeLatest } from 'redux-saga/effects';
import { executeQuery, parseError } from 'utils/request';

import {
  commentManagementActions,
  CREATE_RESOURCE_MANAGEMENT_COMMENT,
  DELETE_RESOURCE_MANAGEMENT_COMMENT,
  GET_RESOURCE_MANAGEMENT_COMMENTS,
  UPDATE_RESOURCE_MANAGEMENT_COMMENT,
} from './actions';
import { queryConfig } from './queries';

function* getCommentManagementRecords() {
  try {
    const devstaffId = yield select(selectDevstaffId);
    const query = queryConfig.resourceManagementCommentsTab;
    const options = {
      query,
      variables: {
        devstaffId,
      },
    };
    const { resourceManagementCommentsTab } = yield call(executeQuery, options);

    return yield put(commentManagementActions.getResourceManagementCommentsSuccess({
      records: resourceManagementCommentsTab,
      memoDevstaffId: devstaffId,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getCommentManagementRecordsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getCommentManagementRecordsError = yield call(parseError, error, options);

    yield put(commentManagementActions.getResourceManagementCommentsFail({
      error: {
        getCommentManagementRecordsError,
      },
    }));
  }
}

function* createCommentManagementRecord({ payload }) {
  try {
    const devstaffId = yield select(selectDevstaffId);
    const query = queryConfig.createResourceManagementComment;
    const options = {
      query,
      variables: {
        fields: {
          devstaffId,
          text: get(payload, 'comment', ''),
        },
      },
    };
    const { createDevstaffComment } = yield call(executeQuery, options);

    return yield put(commentManagementActions.createResourceManagementCommentSuccess({
      record: get(createDevstaffComment, 'comments[0]', []),
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'createResourceManagementCommentError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const createResourceManagementCommentError = yield call(parseError, error, options);

    yield put(commentManagementActions.createResourceManagementCommentFail({
      error: {
        createResourceManagementCommentError,
      },
    }));
  }
}

function* updateCommentManagementRecord({ payload }) {
  try {
    const devstaffId = yield select(selectDevstaffId);
    const commentId = get(payload, 'commentId');
    const query = queryConfig.updateResourceManagementComment;
    const options = {
      query,
      variables: {
        fields: {
          devstaffId,
          text: get(payload, 'comment', ''),
          commentId,
        },
      },
    };
    const { updateDevstaffComment } = yield call(executeQuery, options);

    return yield put(commentManagementActions.updateResourceManagementCommentSuccess({
      record: get(updateDevstaffComment, 'comments[0]', []),
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'updateResourceManagementCommentError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const updateResourceManagementCommentError = yield call(parseError, error, options);

    yield put(commentManagementActions.updateResourceManagementCommentFail({
      error: {
        updateResourceManagementCommentError,
      },
    }));
  }
}

function* deleteCommentManagementRecord({ payload }) {
  try {
    const commentId = get(payload, 'commentId');
    const devstaffId = yield select(selectDevstaffId);
    const query = queryConfig.deleteResourceManagementComment;
    const options = {
      query,
      variables: {
        fields: {
          devstaffId,
          commentId,
        },
      },
    };

    yield call(executeQuery, options);

    return yield put(commentManagementActions.deleteResourceManagementCommentSuccess(commentId));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'deleteResourceManagementCommentError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const deleteResourceManagementCommentError = yield call(parseError, error, options);

    yield put(commentManagementActions.deleteResourceManagementCommentFail({
      error: {
        deleteResourceManagementCommentError,
      },
    }));
  }
}

function* getCommentManagementRecordsWatcher() {
  yield takeLatest(GET_RESOURCE_MANAGEMENT_COMMENTS, waitForAuthorization(getCommentManagementRecords));
}

function* createCommentManagementRecordWatcher() {
  yield takeLatest(CREATE_RESOURCE_MANAGEMENT_COMMENT, waitForAuthorization(createCommentManagementRecord));
}

function* updateCommentManagementRecordWatcher() {
  yield takeLatest(UPDATE_RESOURCE_MANAGEMENT_COMMENT, waitForAuthorization(updateCommentManagementRecord));
}

function* deleteCommentManagementRecordWatcher() {
  yield takeLatest(DELETE_RESOURCE_MANAGEMENT_COMMENT, waitForAuthorization(deleteCommentManagementRecord));
}

export default [
  getCommentManagementRecordsWatcher,
  createCommentManagementRecordWatcher,
  updateCommentManagementRecordWatcher,
  deleteCommentManagementRecordWatcher,
];
