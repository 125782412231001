import React, { Fragment } from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import Container from 'components/container';
import DataList from 'components/data-list';
import DetailsForm from 'components/details-form';
import DetailsTable from 'components/details-table';
import ExpansionPanel from 'components/expansion-panel';
import Loader from 'components/loader';

import TabGroup from 'components/tab-group';
import { USERS_GROUPS } from 'core/auth/constants';
import { recordIdName as attachmentIdName } from 'core/delivery/attachmentManagement/constants';
import { selectAttachmentsLength } from 'core/delivery/attachmentManagement/selectors';
import { recordIdName as commentIdName } from 'core/delivery/commentManagement/constants';
import { selectCommentLength } from 'core/delivery/commentManagement/selectors';
import { recordIdName as contactIdName } from 'core/delivery/contactManagement/constants';
import { selectContactDataLength } from 'core/delivery/contactManagement/selectors';
import { recordIdName as contractIdName } from 'core/delivery/contractManagement/constants';
import { selectContractLength } from 'core/delivery/contractManagement/selectors';
import { recordIdName as technicalMentorIdName } from 'core/delivery/technicalMentorManagement/constants';
import { selectTechnicalMentorRecordsLength } from 'core/delivery/technicalMentorManagement/selectors';
import Text from 'elements/plain-text';
import Wrapper from 'elements/wrapper';

import { Comments } from 'layouts/employee-details/components/resource-tabs/comments';
import { DETAILS_TABS } from 'layouts/employee-details/components/resource-tabs/constants';
import { Contacts } from 'layouts/employee-details/components/resource-tabs/contacts';
import { Contracts } from 'layouts/employee-details/components/resource-tabs/contracts';
import { EditProfile } from 'layouts/employee-details/components/resource-tabs/edit-profile';

import { generateWrapperClassesByTabName } from 'layouts/employee-details/components/resource-tabs/helpers';

import { TechnicalMentor } from 'layouts/employee-details/components/resource-tabs/technical-mentor';
import { getHasPermissions } from 'utils/auth';

import { Attachments } from './attachments';

import styles from './styles.module.scss';

const { CPS } = USERS_GROUPS;

const TEXTS = {
  PLACEHOLDER_TEXT: 'This section currently contains no data.',
};

export const ResourceTabs = ({
  name,
  body,
  isFetchingDetails,
  tabStylesTemplate,
  onSubmit,
  isFormSubmitted,
  additionalActions,
  orderRules,
  changeOrder,
  changeCurrentModal,
  entityName,
  devstaffId,
  isEditDetails,
  resourceTabIndex,
  userGroup,
}) => {
  const isCPS = getHasPermissions(userGroup, [CPS]);
  const attachmentsLength = useSelector(selectAttachmentsLength);
  const commentLength = useSelector(selectCommentLength);
  const contractLength = useSelector(selectContractLength);
  const contactDataLength = useSelector(selectContactDataLength);
  const technicalMentorsLength = useSelector(selectTechnicalMentorRecordsLength);

  const viewTab = {
    profile: true,
    contracts: true,
    attachments: true,
    remoteWorkLocation: true,
    comments: true,
    contacts: true,
    technicalMentor: true,
  };

  if (isEditDetails) {
    return (
      <Container
        className={classNames(
          styles.detailsWrapper,
          styles.formControlsStyles,
          styles.formLayoutStyles
        )}
      >
        <TabGroup
          className={styles.tabs}
          defaultIndex={0}
          viewTab={viewTab}
        >
          <TabGroup.Tab {...DETAILS_TABS.PROFILE}>
            <EditProfile
              id={DETAILS_TABS.PROFILE.id}
              title={DETAILS_TABS.PROFILE.title}
              onSubmit={onSubmit}
              additionalActions={additionalActions}
              body={body}
              isFormSubmitted={isFormSubmitted}
              isFetchingDetails={isFetchingDetails}
              resourceTabIndex={resourceTabIndex}
              layoutStyles={styles.editProfileLayout}
            />
          </TabGroup.Tab>
          <TabGroup.Tab {...DETAILS_TABS.CONTRACTS} length={contractLength}>
            <Contracts
              id={DETAILS_TABS.CONTRACTS.id}
              title={DETAILS_TABS.CONTACTS.title}
              devstaffId={devstaffId}
              layoutStyles={styles.layout}
              placeholderStyles={styles.placeholder}
              placeholderText={TEXTS.PLACEHOLDER_TEXT}
              recordIdName={contractIdName}
              isCPS={isCPS}
            />
          </TabGroup.Tab>
          <TabGroup.Tab {...DETAILS_TABS.ATTACHMENTS} length={attachmentsLength}>
            <Attachments
              id={DETAILS_TABS.ATTACHMENTS.id}
              title={DETAILS_TABS.ATTACHMENTS.title}
              devstaffId={devstaffId}
              placeholderText={TEXTS.PLACEHOLDER_TEXT}
              placeholderStyles={styles.placeholder}
              layoutStyles={styles.layout}
              recordIdName={attachmentIdName}
            />
          </TabGroup.Tab>
          <TabGroup.Tab {...DETAILS_TABS.COMMENTS} length={commentLength}>
            <Comments
              id={DETAILS_TABS.COMMENTS.id}
              title={DETAILS_TABS.COMMENTS.title}
              devstaffId={devstaffId}
              layoutStyles={styles.layout}
              placeholderText={TEXTS.PLACEHOLDER_TEXT}
              placeholderStyles={styles.placeholder}
              recordIdName={commentIdName}
            />
          </TabGroup.Tab>
          <TabGroup.Tab {...DETAILS_TABS.CONTACTS} length={contactDataLength}>
            <Contacts
              id={DETAILS_TABS.CONTACTS.id}
              title={DETAILS_TABS.CONTACTS.title}
              devstaffId={devstaffId}
              layoutStyles={styles.layout}
              placeholderText={TEXTS.PLACEHOLDER_TEXT}
              placeholderStyles={styles.placeholder}
              recordIdName={contactIdName}
            />
          </TabGroup.Tab>
          <TabGroup.Tab {...DETAILS_TABS.TECHNICAL_MENTOR} length={technicalMentorsLength}>
            <TechnicalMentor
              id={DETAILS_TABS.TECHNICAL_MENTOR.id}
              title={DETAILS_TABS.TECHNICAL_MENTOR.title}
              devstaffId={devstaffId}
              layoutStyles={styles.layout}
              placeholderText={TEXTS.PLACEHOLDER_TEXT}
              placeholderStyles={styles.placeholder}
              recordIdName={technicalMentorIdName}
              isCPS={isCPS}
            />
          </TabGroup.Tab>
        </TabGroup>
      </Container>
    );
  }

  return (
    <Wrapper
      isGrow
      isVisible
      data-name={name}
      cssRules={tabStylesTemplate.wrapperCssRules}
      className={classNames(generateWrapperClassesByTabName(name))}
    >
      {
        isFetchingDetails ?
          <Loader /> :
          body.map(({ type, summaryConfig, name: tabName, ...rest }, bodyItems) => {
            const renderUnit = () => {
              switch (type) {
                case 'heading':
                  // TODO: add 'toggle list/chart view' icons to the right
                  return <Text {...rest} />;

                case 'form':
                  return (
                    <DetailsForm
                      isLastStepOfCreateMode={!resourceTabIndex}
                      onSubmit={onSubmit}
                      isVisible
                      isFormSubmitted={isFormSubmitted}
                      isFetchingDetails={isFetchingDetails}
                      additionalActions={additionalActions}
                      buttonTitle="Continue"
                      {
                        ...rest
                      }
                    />
                  );

                case 'table':
                  return (
                    <DetailsTable
                      orderRules={orderRules}
                      changeOrder={changeOrder}
                      changeCurrentModal={changeCurrentModal}
                      isFetchingDetails={isFetchingDetails}
                      entityName={entityName}
                      {
                        ...rest
                      }
                    />
                  );
                case 'data-list':
                  return (
                    <DataList
                      additionalActions={additionalActions}
                      isFormSubmitted={isFormSubmitted}
                      isFetchingDetails={isFetchingDetails}
                      {
                        ...rest
                      }
                    />
                  );
                default:
                  return null;
              }
            };

            return (
              <Fragment
                key={bodyItems} // eslint-disable-line react/no-array-index-key
              >
                {
                  summaryConfig && !isFetchingDetails ? (
                    <ExpansionPanel summaryConfig={summaryConfig}>
                      {renderUnit()}
                    </ExpansionPanel>
                  ) :
                    renderUnit()
                }

              </Fragment>
            );
          })
      }
    </Wrapper>
  );
};

ResourceTabs.propTypes = {
  body: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetchingDetails: PropTypes.bool,
  tabStylesTemplate: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func,
  isFormSubmitted: PropTypes.bool.isRequired,
  additionalActions: PropTypes.shape({}).isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  changeOrder: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func,
  entityName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  devstaffId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isEditDetails: PropTypes.bool.isRequired,
  resourceTabIndex: PropTypes.number.isRequired,
  userGroup: PropTypes.string.isRequired,
};

ResourceTabs.defaultProps = {
  isFetchingDetails: false,
  onSubmit: undefined,
  changeCurrentModal: undefined,
};
