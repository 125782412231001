import { get, set } from 'lodash';

import getUuid from '../helpers/uuid';

let instance;
class SingletonClass {
    static groups = {};

    static controls = {};

    static remoteControllers = {};

    constructor() {
      if (typeof instance === 'object') {
        throw new Error('Only one instance can exist!');
      }

      instance = this;
      return this; // eslint-disable-line no-constructor-return
    }

    setGroupState({
      state,
      groupId,
    }) {
      const group = get(SingletonClass.groups, groupId, {});
      const groupValues = Object.entries(group);

      groupValues.forEach(([id, data]) => {
        set(SingletonClass, `groups.${groupId}.${id}.state`, state);
        if (get(data, 'setIsExpanded', false)) data.setIsExpanded(state);
      });
    }

    unsubscribe({
      groupId,
      id,
    }) {
      delete SingletonClass.groups[groupId][id];
    }

    subscribe({
      state,
      groupId,
      isDisabled,
      setIsExpanded,
    }) {
      const id = getUuid();

      set(SingletonClass, `groups.${groupId}.${id}`, {
        state,
        isDisabled,
        setIsExpanded,
      });

      return {
        id,
        unsubscribe: () => this.unsubscribe({ groupId, id }),
      };
    }

    unsubscribeRemoteController({
      id,
      groupId,
    }) {
      delete SingletonClass.remoteControllers[groupId][id];
    }

    subscribeRemoteController({
      groupId,
      setIsExpanded,
    }) {
      const id = getUuid();

      set(SingletonClass, `remoteControllers.${groupId}.${id}`, setIsExpanded);

      return () => {
        this.unsubscribeRemoteController({ groupId, id });
      };
    }

    updateCallback({
      id,
      groupId,
      isDisabled,
      setIsExpanded,
    }) {
      set(SingletonClass, `groups.${groupId}.${id}.isDisabled`, isDisabled);
      set(SingletonClass, `groups.${groupId}.${id}.setIsExpanded`, setIsExpanded);
    }

    updateControlState({
      id,
      state,
      groupId,
    }) {
      set(SingletonClass, `groups.${groupId}.${id}.state`, state);
      this.checkControls({ groupId });
    }

    checkControls({ groupId }) {
      const group = get(SingletonClass.groups, groupId, {});
      const remoteControls = get(SingletonClass, `remoteControllers.${groupId}`, {});
      const isALLClosed = !Object.values(group).some(({ state, isDisabled }) => state && !isDisabled);
      const isALLOpened = !Object.values(group).some(({ state, isDisabled }) => !state && !isDisabled);

      if (isALLClosed) {
        Object.values(remoteControls).forEach((func) => func(false));
      }
      if (isALLOpened) {
        Object.values(remoteControls).forEach((func) => func(true));
      }
    }

    getGroupState({ groupId }) {
      const group = get(SingletonClass.groups, groupId, {});
      const groupValues = Object.values(group);
      const isALLOpened = !groupValues.some(({ state, isDisabled }) => !state && !isDisabled);

      return {
        isALLOpened,
      };
    }
}
export default new SingletonClass();
