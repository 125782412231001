import { useMemo } from 'react';

import { isEmpty, isUndefined } from 'lodash';

import { tabsConfig } from './configs';

export const useTabItems = ({ categories, clientId }) => useMemo(() => {
  if (isEmpty(categories) || isUndefined(clientId)) return;

  // eslint-disable-next-line consistent-return
  return tabsConfig({
    clientId,
    categories,
  });
}, [categories, clientId]);
