import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// import SVG from 'react-inlinesvg';
// import styled from 'styled-components';
import classNames from 'classnames';

import UploadedFile from './file';

import './styles.scss';

const FileUpload = (a) => {
  const {
    name,
    // withError,
    // cssRules,
    // isMultiple,
    accept,
    // onUpload,
    // uploadingProgress,
    // isUploadingCompleted,
    // isUploadingInProgress,
    onChange,
    fieldData,
    // formValue,
    allowUpload,
  } = a;
  // console.log('a',a);
  // const onChange = () => {
  //   setFieldValue(name, !value);
  //   setTouched(name, true);
  // };
  // console.log('allowUpload', allowUpload);
  const [files, setFiles] = useState(fieldData);
  const [isFocused, setIsFocused] = useState(false);
  const cleareFile = () => {
    setFiles(null);
    onChange(null);
    // onUpload(null);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    setIsFocused(false);
    onChange(acceptedFiles);
    // acceptedFiles.forEach((file) => {
    //   onUpload(file);
    // });
  }, []);

  const { getRootProps, getInputProps, rootRef } = useDropzone({
    onDrop,
    accept,
  });

  // const isFilePreloaded = files.length;


  const setIsDragover = () => {
    if (!isFocused) {
      setIsFocused(true);
    }
  };

  const setIsDragleave = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (allowUpload) {
      rootRef.current.addEventListener('dragover', setIsDragover);
      rootRef.current.addEventListener('dragleave', setIsDragleave);

      return () => {
        rootRef.current.removeEventListener('dragover', setIsDragover);
        rootRef.current.removeEventListener('dragleave', setIsDragleave);
      };
    }
  }, []);
  useEffect(() => {
    setFiles(fieldData);
  }, [fieldData]);
  // useEffect(() => {
  //   // console.log('formValue changed', formValue);
  //   if (formValue !== fieldData) {
  //     setFiles([fieldData]);
  //   }
  // }, [formValue]);
  return (
    <div className="dropzone">
      <div
        className={
          classNames(
            'dropzone__wrapper',
            { 'dropzone__wrapper--focused': isFocused },
            { 'dropzone__wrapper--disabled': !allowUpload },
          )
        }
        {...getRootProps()}
      >
        {allowUpload && <input
          className="dropzone__input"
          {...getInputProps()}
          accept={accept}
          // multiple={isMultiple}
          autoComplete="off"
          name={name}
          // onChange={onChange}
        />}

        {
          files ? <UploadedFile file={files[0]} removeFile={() => cleareFile()} allowUpload={allowUpload} /> : (
            <div className="dropzone__placeholder">
              <p className="dropzone__placeholder-title">Drag file here or</p>
              <p
                className={
                  classNames(
                    'dropzone__placeholder-title',
                    { 'dropzone__placeholder-title--with-border': allowUpload },
                    { 'dropzone__placeholder-title--disabled': !allowUpload },
                  )
                }
              >Browse
              </p>
            </div>
          )
        }

      </div>

    </div>
  );
};


FileUpload.propTypes = {
  cssRules: PropTypes.string,
  name: PropTypes.string.isRequired,
  withError: PropTypes.bool,
  label: PropTypes.string,
  hasManuallyUpdates: PropTypes.bool,
  isLocked: PropTypes.bool,
};

FileUpload.defaultProps = {
  cssRules: '',
  withError: false,
  label: '',
  hasManuallyUpdates: false,
  isLocked: false,
};


export default FileUpload;
