import { pathToContractManagementRecords, recordIdName } from 'core/delivery/contractManagement/constants';
import { FORM_STATE } from 'core/utils';
import update from 'immutability-helper';

import { newContract } from 'layouts/employee-details/components/resource-tabs/contracts/utils';
import { get } from 'lodash';

import {
  GET_CONTRACT_MANAGEMENT_RECORDS,
  GET_CONTRACT_MANAGEMENT_RECORDS_SUCCESS,
  GET_CONTRACT_MANAGEMENT_RECORDS_FAIL,
  CREATE_CONTRACT_MANAGEMENT_RECORD,
  CREATE_CONTRACT_MANAGEMENT_RECORD_SUCCESS,
  CREATE_CONTRACT_MANAGEMENT_RECORD_FAIL,
  UPDATE_CONTRACT_MANAGEMENT_RECORD,
  UPDATE_CONTRACT_MANAGEMENT_RECORD_SUCCESS,
  UPDATE_CONTRACT_MANAGEMENT_RECORD_FAIL,
  DELETE_CONTRACT_MANAGEMENT_RECORD,
  DELETE_CONTRACT_MANAGEMENT_RECORD_FAIL,
  DELETE_CONTRACT_MANAGEMENT_RECORD_SUCCESS,
  ADD_CONTRACT_RECORD,
  REMOVE_CONTRACT_RECORD,
  UPLOAD_ATTACHMENT_FILE_FAIL,
  UPLOAD_CONTRACT_MANAGEMENT_FILE,
}
from './actions';
import { generateContractManagementRecord } from './utils';

export const contractManagementReducerCases = {
  [GET_CONTRACT_MANAGEMENT_RECORDS](state) {
    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          isFetchingContractManagementRecords: {
            $set: true,
          },
        },
      },
      errors: {
        $unset: ['getContractManagementRecordsError'],
      },
    });
  },
  [GET_CONTRACT_MANAGEMENT_RECORDS_SUCCESS](state, { payload: {
    records,
    memoDevstaffId,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          isFetchingContractManagementRecords: {
            $set: false,
          },
          contractManagementRecords: {
            $set: records.map((record) => ({
              ...record,
              fileName: get(record, 'fileName', false) ? `${record.fileName}.pdf` : null,
            })),
          },
          formState: {
            $set: {
              status: FORM_STATE.INITIAL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
          memoDevstaffId: {
            $set: memoDevstaffId,
          },
          length: {
            $set: records.length,
          },
        },
      },
    });
  },
  [GET_CONTRACT_MANAGEMENT_RECORDS_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          isFetchingContractManagementRecords: {
            $set: false,
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
  [ADD_CONTRACT_RECORD](state, { payload }) {
    const contractManagementRecords = get(state, pathToContractManagementRecords, []);

    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          contractManagementRecords: {
            $set: [payload, ...contractManagementRecords],
          },
        },
      },
    });
  },
  [REMOVE_CONTRACT_RECORD](state) {
    const contractManagementRecords = get(state, pathToContractManagementRecords, []);

    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          contractManagementRecords: {
            $set: contractManagementRecords.filter((record) => record[recordIdName] !== newContract[recordIdName]),
          },
        },
      },
    });
  },
  [CREATE_CONTRACT_MANAGEMENT_RECORD](state) {
    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [CREATE_CONTRACT_MANAGEMENT_RECORD_SUCCESS](state, { payload: {
    record,
  } }) {
    const contractManagementRecords =
      get(state, pathToContractManagementRecords, []);

    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          contractManagementRecords: {
            $set: contractManagementRecords.map((currentRecord) => {
              if (currentRecord[recordIdName] === newContract[recordIdName]) {
                return record;
              }

              return currentRecord;
            }),
          },
          length: {
            $set: contractManagementRecords.length,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [CREATE_CONTRACT_MANAGEMENT_RECORD_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: true,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },

  [UPDATE_CONTRACT_MANAGEMENT_RECORD](state) {
    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [UPDATE_CONTRACT_MANAGEMENT_RECORD_SUCCESS](state, { payload: {
    record,
  } }) {
    const contractManagementRecords = get(state, pathToContractManagementRecords, [])
      .map((currentRecord) => generateContractManagementRecord(currentRecord, record));

    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          contractManagementRecords: {
            $set: contractManagementRecords,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [UPDATE_CONTRACT_MANAGEMENT_RECORD_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: true,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },

  [DELETE_CONTRACT_MANAGEMENT_RECORD](state) {
    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },

  [DELETE_CONTRACT_MANAGEMENT_RECORD_SUCCESS](state, { payload }) {
    const contractManagementRecords = get(state, pathToContractManagementRecords, [])
      .filter((record) => record[recordIdName] !== payload);

    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          contractManagementRecords: {
            $set: contractManagementRecords,
          },
          length: {
            $set: contractManagementRecords.length,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [DELETE_CONTRACT_MANAGEMENT_RECORD_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        contractManagement: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: true,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
  [UPLOAD_ATTACHMENT_FILE_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      errors: {
        $merge: errors,
      },
    });
  },

  [UPLOAD_CONTRACT_MANAGEMENT_FILE](state, { payload: {
    errors,
  } }) {
    return update(state, {
      errors: {
        $merge: errors,
      },
    });
  },
};
