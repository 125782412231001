import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { ActionButton } from 'components/buttons';
import ListFilters from 'components/form-with-list/components/filters';

import {
  userManagementActions,
  selectFilterByColumns,
} from 'core/configurations/user-management';

import classes from './filter.module.scss';
import { useFilterItems } from './useFilterItems';

export const UserManagementListFilter = ({ hasActiveFilters }) => {
  const dispatch = useDispatch();
  const { filterItems } = useFilterItems();

  const memoizedFilterItems = useMemo(() => filterItems.map((filterItem) => (
    <ListFilters.Filter
      key={filterItem.name}
      filterItem={filterItem}
    />
  )), [filterItems]);

  const handleClearFilters = useCallback(() => {
    dispatch(userManagementActions.clearFilterByColumns());
  }, []);

  return (
    <div data-id="Filters Container" className={classes.container}>
      <div data-id="Clear Filter" className={classes.clearFilter}>
        {
          hasActiveFilters && (
            <ActionButton
              type="button"
              dataId="clearFilter"
              title="Clear Filters"
              className={classes.clearFilterButton}
              onClick={handleClearFilters}
            >
              Clear Filters
            </ActionButton>
          )
        }
      </div>
      <ListFilters
        className={classes.filters}
        clearFiltersAction={userManagementActions.clearFilterByColumns}
        selector={selectFilterByColumns}
        withEmptyField
      >
        {memoizedFilterItems}
      </ListFilters>
    </div>
  );
};

UserManagementListFilter.propTypes = {
  hasActiveFilters: PropTypes.bool,
};

UserManagementListFilter.defaultProps = {
  hasActiveFilters: false,
};
