export const ALL_ITEMS_SELECTED = [{
  value: 0,
  label: 'All',
  isExcludeAll: true,
}];

export const SELECT_ACTIONS = {
  REMOVE_OPTION: 'remove-option',
  SELECT_OPTION: 'select-option',
  DESELECT_OPTION: 'deselect-option',
};
