import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import {
  ENTITY_NAME,
} from './constants';

export const channelPartnersActionsTypes = createTypes([
  ...async('GET_CHANNEL_PARTNERS_LIST'),
  ...async('GET_CHANNEL_PARTNER_DETAILS'),
  ...async('CREATE_CHANNEL_PARTNER'),
  ...async('UPDATE_CHANNEL_PARTNER_DETAILS'),
  ...async('CHANGE_FILTER'),
  ...async('GET_UPLOADED_FILE'),
  ...async('DELETE_DOCUMENT'),
  'RESET_FILTERS',
  'SET_ORDER_RULES',
  'CLEAR_ERRORS',
], ENTITY_NAME);

export const channelPartnersActions = {
  getChannelPartnersList: createAction(channelPartnersActionsTypes.GET_CHANNEL_PARTNERS_LIST),
  getChannelPartnersSuccess: createAction(
    channelPartnersActionsTypes.GET_CHANNEL_PARTNERS_LIST_SUCCESS,
    ({ channelPartners }) => ({ channelPartners })
  ),
  getChannelPartnersFail: createAction(
    channelPartnersActionsTypes.GET_CHANNEL_PARTNERS_LIST_FAIL,
    ({ error = {} }) => ({ error })
  ),

  getChannelPartnerDetails: createAction(
    channelPartnersActionsTypes.GET_CHANNEL_PARTNER_DETAILS,
    ({
      channelPartnerId,
    }) => ({
      channelPartnerId,
    }),
    ({
      withReset = false,
    }) => ({
      withReset,
    })
  ),

  getChannelPartnerDetailsSuccess: createAction(
    channelPartnersActionsTypes.GET_CHANNEL_PARTNER_DETAILS_SUCCESS,
    ({
      channelPartnerDetails,
    }) => ({
      channelPartnerDetails,
    })
  ),

  getChannelPartnerDetailsFail: createAction(
    channelPartnersActionsTypes.GET_CHANNEL_PARTNER_DETAILS_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),
  createChannelPartner: createAction(
    channelPartnersActionsTypes.CREATE_CHANNEL_PARTNER,
    ({
      fields,
    }) => ({
      fields,
    })
  ),

  createChannelPartnerSuccess: createAction(
    channelPartnersActionsTypes.CREATE_CHANNEL_PARTNER_SUCCESS,
    ({
      channelPartnerDetails,
    }) => ({
      channelPartnerDetails,
    }),
  ),

  createChannelPartnerFail: createAction(
    channelPartnersActionsTypes.CREATE_CHANNEL_PARTNER_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),

  updateChannelPartnerDetails: createAction(
    channelPartnersActionsTypes.UPDATE_CHANNEL_PARTNER_DETAILS,
    ({
      channelPartnerId,
      fields,
      submittingRules,
      initialValues,
    }) => ({
      channelPartnerId,
      fields,
      submittingRules,
      initialValues,
    }),
    ({
      withModal = false,
    }) => ({
      withModal,
    })
  ),

  updateChannelPartnerDetailsSuccess: createAction(
    channelPartnersActionsTypes.UPDATE_CHANNEL_PARTNER_DETAILS_SUCCESS,
    ({
      channelPartnerId,
      fields,
      channelPartnerDetails,
    }) => ({
      channelPartnerId,
      fields,
      channelPartnerDetails,
    }),
    ({
      withModal,
    }) => withModal ? {
      modalConductor: {
        nextModal: null,
      },
    } : null
  ),

  updateChannelPartnerDetailsFail: createAction(
    channelPartnersActionsTypes.UPDATE_CHANNEL_PARTNER_DETAILS_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  changeFilter: createAction(
    channelPartnersActionsTypes.CHANGE_FILTER,
    ({
      storeKey,
      selected = null,
      type,
    }) => ({
      storeKey,
      selected,
      type,
    }),
    () => ({
      entityName: ENTITY_NAME,
    })
  ),

  changeFilterSuccess: createAction(
    channelPartnersActionsTypes.CHANGE_FILTER_SUCCESS,
    ({
      storeKey,
      config,
    }) => ({
      storeKey,
      config,
    })
  ),

  resetFilters: createAction(channelPartnersActionsTypes.RESET_FILTERS),

  setOrderRules: createAction(
    channelPartnersActionsTypes.SET_ORDER_RULES,
    ({
      tableName,
      orderRules,
    }) => ({
      tableName,
      orderRules,
    })
  ),

  getUploadedFile: createAction(
    channelPartnersActionsTypes.GET_UPLOADED_FILE,
    ({
      fileId,
    }) => ({
      fileId,
    }),
    ({
      entityName,
      channelPartnerId,
    }) => ({
      entityName,
      channelPartnerId,
    }),
  ),

  getUploadedFileSuccess: createAction(
    channelPartnersActionsTypes.GET_UPLOADED_FILE_SUCCESS,
    ({
      file,
    }) => ({
      file,
    }),
    ({ channelPartnerId }) => ({
      channelPartnerId,
    }),
  ),

  getUploadedFileFail: createAction(
    channelPartnersActionsTypes.GET_UPLOADED_FILE_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  deleteDocument: createAction(
    channelPartnersActionsTypes.DELETE_DOCUMENT,
    ({
      fileId,
    }) => ({
      fileId,
    }),
    ({ channelPartnerId }) => ({
      entityName: 'CHANNEL_PARTNERS',
      channelPartnerId,
    })
  ),

  deleteDocumentSuccess: createAction(
    channelPartnersActionsTypes.DELETE_DOCUMENT_SUCCESS,
    ({
      fileId,
    }) => ({
      fileId,
    }),
  ),

  deleteDocumentFail: createAction(
    channelPartnersActionsTypes.DELETE_DOCUMENT_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  clearErrors: createAction(channelPartnersActionsTypes.CLEAR_ERRORS),
};
