export const typeData = {
  items: [
    {
      value: 'MSA',
      label: 'MSA',
    },
    {
      value: 'NDA',
      label: 'NDA',
    },
    {
      value: 'MSA Amendment',
      label: 'MSA Amendment',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ],
  selected: {},
};

export const typeDataForSales = {
  items: [],
  selected: {
    value: 'MSA',
    label: 'MSA',
  },
};

export const statusData = {
  items: [
    {
      value: false,
      label: 'Unsigned',
    },
    {
      value: true,
      label: 'Signed',
    },
  ],
  selected: {
    value: false,
    label: 'Unsigned',
  },
};

export const statusDataForSales = {
  items: [],
  selected: {
    value: false,
    label: 'Unsigned',
  },
};

export const FILE_TYPES = {
  MSA: 'MSA',
  NDA: 'NDA',
};
