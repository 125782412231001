import { colorSecondaryText } from 'assets/styles/variables';

export const viewBoxStyles = `
  padding: 0.7rem 0.4rem 0;
  max-height: 3.6rem;
  background-color: rgba(187,189,192, 0.15);
  border-bottom: none;
  color: #000;
  font-size: 1.3rem;
`;
export const actionButtonStyles = `
   && {
    padding: 0;
    border: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: ${colorSecondaryText};
    background-color: transparent;
    font-size: 1.3rem;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
   }
`;
