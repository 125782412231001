import 'whatwg-fetch';
import { config } from 'core/config';
import { graphqlClient, unauthorizedGraphqlClient } from 'core/graphql-client';

import gql from 'graphql-tag';
import { get, omit } from 'lodash';

import { buffers, eventChannel, END } from 'redux-saga';
import { getNotifications, removeNotifications } from 'utils/helpers/notifications';

import { handleResponse } from './error';

export default function request(url, options = {}, isBlob = false) {
  const getResponse = (response) => handleResponse({ isBlob, response });

  return fetch(url, options)
    .then(getResponse);
}

export function getRequestUrl(api) {
  return `${config.apiUrl}${api}`;
}

export function executeMutation(options, storedErrors) {
  return graphqlClient.mutate(options)
    .then(({ data, errors }) => {
      if (errors) {
        throw get(errors, 0, errors);
      }
      removeNotifications(storedErrors);

      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function executeQuery(options, storedErrors) {
  return graphqlClient.query(options)
    .then(({ data, errors }) => {
      if (errors) {
        throw get(errors, 0, errors);
      }
      removeNotifications(storedErrors);
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function executeUnauthorizedQuery(options, storedErrors) {
  return unauthorizedGraphqlClient.query(options)
    .then(({ data, errors }) => {
      if (errors) {
        throw get(errors, 0, errors);
      }
      removeNotifications(storedErrors);
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function createUploadFileChannel({ url, fields }, file) {
  const formData = new FormData();

  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value);
  });

  formData.append('file', file);

  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();

    const onProgress = (e) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };

    const onFailure = () => {
      emitter({ error: new Error('Upload failed') });
      emitter(END);
    };

    xhr.upload.addEventListener('progress', onProgress);

    xhr.upload.addEventListener('error', onFailure);

    xhr.upload.addEventListener('abort', onFailure);

    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;

      if (readyState === 4) {
        if (status >= 200 && status < 300) {
          emitter({ success: true });
          emitter(END);
        } else {
          onFailure(null);
        }
      }
    };

    xhr.open('POST', url, true);

    xhr.send(formData);

    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}

export const parseError = (error, options) => {
  const { message } = error;
  const entityName = get(options, 'entityName', '');
  const storedErrors = get(options, 'storedErrors', []);
  const networkErrors = get(error, 'networkError.result.errors', []);
  const graphQLErrors = get(error, 'graphQLErrors', []);
  const errorMessage = get(options, 'errorMessage', null);
  const isModalError = get(options, 'isModalError', false);

  const errorConfig = {
    activeErrors: storedErrors,
    containerId: entityName,
    errorMessage: errorMessage || message,
    graphQLErrors,
    errors: networkErrors,
    isModalError,
  };

  return getNotifications(errorConfig);
};

export const multiMutationGetter = ({ mutation, idKeys, records, inputType, withoutFields, query }) => { // TODO replace on helpers
  const idsGetter = (record) => Array.isArray(idKeys) ? idKeys.reduce((acc, idKey) => `${acc} ${idKey}: ${get(record, idKey)}`, '') : `${idKeys}: ${get(record, idKeys)}`;
  const graphQuery = query ? `{ ok, ${query} }` : '{ ok }';
  const { args: summaryArgs, variables: summaryVariables, mutations: summaryMutations } = records
    .reduce(({ mutations, variables, args }, record, index) => ({
      mutations: `${mutations} upd${index}: ${mutation}(${idKeys ? idsGetter(record) : ''}${withoutFields ? '' : `, fields: $fields${index}`}) ${graphQuery}`,
      args: `$fields${index}: ${inputType}${args ? ', ' : ''}${args}`,
      variables: withoutFields ? null : {
        ...variables,
        [`fields${index}`]: omit(record, idKeys),
      },
    }), {
      mutations: '',
      variables: {},
      args: '',
    });

  return {
    mutation: gql`mutation ${withoutFields ? '' : `multi${mutation}(${summaryArgs})`} { ${summaryMutations} }`,
    variables: withoutFields ? '' : summaryVariables,
  };
};

export * from './error';
