import { useRef, useEffect } from 'react';
export const useEffectOnlyOnUpdate = (callback, dependencies) => {
  const didMount = useRef(true);

  useEffect(() => {
    if (didMount.current) {
      didMount.current = false;
      return;
    }

    // eslint-disable-next-line consistent-return
    return callback();
  }, dependencies);
};
