import { waitForAuthorization } from 'core/auth/sagas';
import { selectUserId } from 'core/auth/selectors';
import { get } from 'lodash';

import {
  all,
  put,
  call,
  select,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import { executeQuery, parseError } from 'utils/request';

import { billingReportsActionsTypes, billingReportsActions } from './actions';

import { REPORTS_TYPES } from './constants';

import queryConfig from './queries';
import {
  selectErrors,
  selectSalesData,
  selectEntityName,
  selectReportData,
  selectSelectedSalesId,
  selectTaskOrderDetails,
  selectCurrentTaskOrder,
  selectAnnualReportData,
  selectSummaryReportType,
  selectProjectReportType,
} from './selectors';

import { getReportFor } from './utils';

/* function* getAnnualPayrollReportAdditionalData() {
  const selectReportData = yield select(selectReportData);
  const query = queryConfig.annualPayrollReportAdditionalDataQuery;
  const options = {
    query,
    variables: {},

  };

  const response = yield call(executeQuery, options);
  console.log('response', response);
  return response;
} */

function* getBillingSummaryReport() {
  yield delay(600);

  try {
    const userId = yield select(selectUserId);
    const reportType = yield select(selectSummaryReportType);
    const {
      selectedDate,
      devcenterIds,
      billingAgentIds,
    } = yield select(selectReportData);
    const year = selectedDate.year();
    const month = selectedDate.month() + 1;
    const variables = {
      year,
    };
    const additionalData = {};
    const query = queryConfig.getBillingSummaryReport(reportType);

    switch (reportType) {
      case REPORTS_TYPES.SALES_REPORT:
        const salesId = yield select(selectSelectedSalesId); // eslint-disable-line no-case-declarations
        const salesData = yield select(selectSalesData); // eslint-disable-line no-case-declarations
        const reportFor = yield call(getReportFor, { userId, salesId, salesData }); // eslint-disable-line no-case-declarations

        variables.month = month;
        variables.userId = userId;
        variables.reportFor = reportFor;
        break;
      case REPORTS_TYPES.GM:
        variables.month = month;
        break;
      case REPORTS_TYPES.TO:
        variables.month = month;
        variables.userId = userId;
        variables.billingAgentIds = billingAgentIds;
        break;
      case REPORTS_TYPES.DTO:
        variables.month = month;
        variables.devcenterIds = devcenterIds;
        break;
      case REPORTS_TYPES.ANNUAL_REPORT:
        // eslint-disable-next-line no-case-declarations
        const { staff, devcenters, currency } = yield select(selectAnnualReportData);

        if (staff !== null) {
          variables.deliveryOnly = staff;
        }
        if (devcenters.length) {
          variables.devcenterIds = devcenters;
        }
        if (currency.length) {
          variables.currencies = currency;
        }
        break;
      case REPORTS_TYPES.PL_COMMISSION:
        variables.month = month;
        break;
      default:
        break;
    }

    const options = {
      query,
      variables,
      /*      context: {
          headers: {
            'X-Profile': 1,
          },
        }, */
    };

    const { summaryReport = {}, ...otherResponse } = yield call(executeQuery, options);

    return yield put(billingReportsActions.getBillingSummaryReportSuccess({
      summaryReport,
      otherData: {
        ...otherResponse,
        ...additionalData,
      },
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'getBillingSummaryReportError', []);
    const entityName = yield select(selectEntityName);
    const options = {
      entityName,
      storedErrors,
    };
    const getBillingSummaryReportError = yield call(parseError, error, options);

    return yield put(billingReportsActions.getBillingSummaryReportFail({
      error: {
        getBillingSummaryReportError,
      },
    }));
  }
}

function* getBillingProjectReport() {
  yield delay(600);

  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'getBillingProjectReportError', []);
  const userId = yield select(selectUserId);
  try {
    const currentTaskOrder = yield select(selectCurrentTaskOrder);
    if (!currentTaskOrder) {
      return null;
    }
    const reportType = yield select(selectProjectReportType);
    if (!reportType) {
      return null;
    }

    const { selectedDate, ...reportData } = yield select(selectReportData);

    let projectType;

    while (!projectType) {
      yield delay(100);
      projectType = get(yield select(selectTaskOrderDetails), 'projectType');
    }

    const query = queryConfig.getBillingProjectReport(reportType, userId, projectType);
    const options = {
      query,
      variables: {
        ...reportData,
        taskOrderId: currentTaskOrder,
        year: selectedDate.year(),
        month: selectedDate.month() + 1,
      },
    };

    const {
      billingToReport,
      billingDtoReport,
    } = yield call(executeQuery, options);

    const billingReport = billingDtoReport || billingToReport;
    return yield put(billingReportsActions.getBillingProjectReportSuccess({
      billingReport,
    }));
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const options = {
      entityName,
      storedErrors,
    };
    const getBillingProjectReportError = yield call(parseError, error, options);

    return yield put(billingReportsActions.getBillingProjectReportFail({
      error: {
        getBillingProjectReportError,
      },
    }));
  }
}

function* getTaskOrdersDetails({
  payload: {
    taskOrder,
  },
}) {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'getTaskOrdersDetailsError', []);

  try {
    const query = queryConfig.getTaskOrdersDetails(taskOrder);
    const options = {
      query,
    };
    const {
      taskOrderDetails,
    } = yield call(executeQuery, options, storedErrors);

    return yield put(billingReportsActions.getTaskOrdersDetailsSuccess({
      taskOrderDetails,
    }));
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const options = {
      entityName,
      storedErrors,
    };
    const getTaskOrdersDetailsError = yield call(parseError, error, options);

    return yield put(billingReportsActions.getTaskOrdersDetailsFail({
      error: {
        getTaskOrdersDetailsError,
      },
    }));
  }
}

function* getHolidaysList() {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'getHolidaysListError', []);

  try {
    const query = queryConfig.getHolidaysList;
    const options = {
      query,
    };
    const {
      holidaysList,
    } = yield call(executeQuery, options, storedErrors);

    return yield put(billingReportsActions.getHolidaysListSuccess({
      holidaysList,
    }));
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const options = {
      entityName,
      storedErrors,
    };
    const getHolidaysListError = yield call(parseError, error, options);

    return yield put(billingReportsActions.getHolidaysListFail({
      error: {
        getHolidaysListError,
      },
    }));
  }
}

function* getInternalToReport({
  payload: {
    month,
    year,
  },
}) {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'getInternalToReportError', []);

  try {
    const query = queryConfig.getInternalToReport;
    const options = {
      query,
      variables: {
        month,
        year,
      },
    };
    const {
      internalTo,
    } = yield call(executeQuery, options, storedErrors);

    return yield put(billingReportsActions.getInternalToReportSuccess({
      ...internalTo,
    }));
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const options = {
      entityName,
      storedErrors,
    };
    const getInternalToReportError = yield call(parseError, error, options);

    return yield put(billingReportsActions.getInternalToReportFail({
      error: {
        getInternalToReportError,
      },
    }));
  }
}

function* getTaskOrdersList() {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'getTaskOrdersListError', []);

  try {
    const query = queryConfig.getTaskOrdersList;
    const options = {
      query,
    };
    const {
      taskOrdersList,
    } = yield call(executeQuery, options, storedErrors);

    return yield put(billingReportsActions.getTaskOrdersListSuccess({
      taskOrdersList,
    }));
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const options = {
      entityName,
      storedErrors,
    };
    const getTaskOrdersListError = yield call(parseError, error, options);

    return yield put(billingReportsActions.getTaskOrdersListFail({
      error: {
        getTaskOrdersListError,
      },
    }));
  }
}

function* getSalesList() {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'getSalesListError', []);

  try {
    const query = queryConfig.getSalesList;
    const options = {
      query,
    };
    const {
      salesList,
    } = yield call(executeQuery, options, storedErrors);

    return yield all([
      put(billingReportsActions.getSalesListSuccess({ salesList })),
      put(billingReportsActions.setSummaryReportType(REPORTS_TYPES.SALES_REPORT)),
    ]);
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const options = {
      entityName,
      storedErrors,
    };
    const getSalesListError = yield call(parseError, error, options);

    return yield put(billingReportsActions.getSalesListFail({
      error: {
        getSalesListError,
      },
    }));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest([
      billingReportsActionsTypes.SET_BILLING_SUMMARY_REPORT_DATA,
      billingReportsActionsTypes.SET_SUMMARY_REPORT_TYPE,
      billingReportsActionsTypes.SET_ANNUAL_REPORT_DATA,
      billingReportsActionsTypes.SET_SALES_ID,
    ], waitForAuthorization(getBillingSummaryReport)),
    yield takeLatest(
      [
        billingReportsActionsTypes.SET_PROJECT_REPORT_TYPE,
        billingReportsActionsTypes.SET_BILLING_PROJECT_REPORT_DATA,
        billingReportsActionsTypes.GET_TASK_ORDER_DETAILS_SUCCESS,
      ],
      waitForAuthorization(getBillingProjectReport),
    ),
    yield takeLatest(billingReportsActionsTypes.GET_TASK_ORDER_DETAILS, waitForAuthorization(getTaskOrdersDetails)),
    yield takeLatest(billingReportsActionsTypes.GET_TASK_ORDERS_LIST, waitForAuthorization(getTaskOrdersList)),
    yield takeLatest(billingReportsActionsTypes.GET_SALES_LIST, waitForAuthorization(getSalesList)),
    yield takeLatest(billingReportsActionsTypes.GET_HOLIDAYS_LIST, waitForAuthorization(getHolidaysList)),
    yield takeLatest(billingReportsActionsTypes.GET_INTERNAL_TO_REPORT, waitForAuthorization(getInternalToReport)),
  ]);
}
