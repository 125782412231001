import gql from 'graphql-tag';

export const queryConfig = {
  createDevstaffContact: gql`
    mutation createDevstaffContact($fields: CreateDevstaffContactInput) {
      createDevstaffContact(fields: $fields){
        ok
        contactInfo {
          id,
          contactInfo
          createdBy
          createdByFullname
          date: createdAt
          isAllowedToEdit
        }
      }
    }
  `,
  updateDevstaffContact: gql`
    mutation updateDevstaffContact($fields: UpdateDevstaffContactInput!) {
      updateDevstaffContact(fields: $fields){
        ok
        contact {
          id
          contactInfo
          createdBy
          createdByFullname
          date: createdAt
          isAllowedToEdit
        }
      }
    }
  `,
  devstaffContacts: gql`
    query devstaffContacts($devstaffId: Int!) {
      devstaffContacts(devstaffId: $devstaffId)
      {
        id
        contactInfo
        createdBy
        createdByFullname
        date: createdAt
        isAllowedToEdit
      }
    }
  `,
  deleteDevstaffContact: gql`
    mutation deleteDevstaffContact($contactId:Int){
      deleteDevstaffContact(contactId: $contactId){
        ok
      }
    }
  `,
};
