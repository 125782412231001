import React from 'react';

import PropTypes from 'prop-types';

import { PdfIcon, DocxIcon, RemoveFileIcon } from 'assets/svgComponents';
import classNames from 'classnames';

import styles from './styles.module.scss';

const ICONS = {
  pdf: <PdfIcon width="2.4rem" height="2.4rem" />,
  docx: <DocxIcon width="2.4rem" height="2.4rem" />,
};

export const AttachmentView = ({
  hasFileName,
  file,
  onRemoveFile,
  isAllowedForDelete,
  extension,
}) => (
  <div
    data-id="Attachment View"
    className={classNames(styles.attachmentView)}
  >
    {ICONS[extension]}
    <div
      data-id="Attachment Name"
      className={classNames(styles.attachmentName, {
        [styles.active]: hasFileName,
      })}
    >
      <a
        href={file.downloadUrl}
        data-id="Attachment Link"
        className={classNames(styles.attachmentLink)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {file.name}
      </a>
    </div>
    {isAllowedForDelete && (
      <RemoveFileIcon
        className={styles.removeFileIcon}
        onClick={onRemoveFile}
      />
    )}
  </div>
);

AttachmentView.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    downloadUrl: PropTypes.string.isRequired,
  }).isRequired,
  hasFileName: PropTypes.bool.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  isAllowedForDelete: PropTypes.bool.isRequired,
  extension: PropTypes.string.isRequired,
};
