import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled, { css } from 'styled-components';

import './styles.scss';

const StyledButton = styled.button`
  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;


const Checkbox = ({ title, onClick, isActive, cssRules }) => (
  <StyledButton
    className={classnames(
      'checkbox',
      { 'checkbox--active': isActive },
    )}
    onClick={() => onClick()} // TODO CHECk checkboxes
    type="button"
    cssRules={cssRules}
  >
    {title}
  </StyledButton>
);


Checkbox.propTypes = {
  cssRules: PropTypes.string,
  isActive: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  cssRules: '',
  title: '',
  isActive: true,
};


export default Checkbox;
