import { colorPrimary, colorSecondaryGrayLight } from 'assets/styles/variables';
import { toNumber } from 'lodash';
import moment from 'moment';

import { getUnixTime } from 'utils/helpers/date';

import { addThousandSeparator } from 'utils/helpers/numbers';

export default ({
  isCTO,
  hasPermissionsTODeliveryModule,
  hasPermissionsTOTaskOrderModule,
}) => [
  {
    columnName: 'Project this month',
    headerProps: {
      cssRules: 'min-height: 2.8rem;',
    },
    valueGetter: ({
      projectName,
      taskOrderId,
      isSubTotal,
      isGrandTotal,
      project,
    }) => {
      if (isSubTotal || isGrandTotal) {
        return {
          type: 'text',
          componentProps: {
            data: `Total ${isGrandTotal ? project : projectName}`,
            cssRules: `
            font-size: ${isGrandTotal ? '1.6rem' : '1.4rem'};
            font-weight: 500;`,
            isMultiline: true,
          },
        };
      }

      return {
        type: taskOrderId ? 'link' : 'text',
        componentProps: {
          data: projectName || 'n/a',
          pathname: `/reports/billing-reports/project/TO/${taskOrderId}`,
          cssRules: 'font-size: 1.2rem;',
          isMultiline: true,
        },
      };
    },
  },
  {
    columnName: 'TO',
    valueGetter: ({
      taskOrder,
      taskOrderId,
      isSubTotal,
      isGrandTotal,
      hasActiveFilters,
    }) => {
      if (isSubTotal) {
        return {};
      }
      if (isGrandTotal) {
        return hasActiveFilters ?
          {
            type: 'text',
            componentProps: {
              data: '/  *filters applied',
              cssRules: `
              font-size: 1rem;
              font-weight: 500;
              line-height: 1.6rem;
              text-transform: uppercase;
              letter-spacing: 0.15rem;
              color: ${colorPrimary};
            `,
              isMultiline: true,
            },
          } :
          {};
      }
      return {
        type: hasPermissionsTOTaskOrderModule && taskOrderId ? 'link' : 'text',
        componentProps: {
          data: taskOrder || 'n/a',
          pathname: `/task-orders/${taskOrderId}/${
            isCTO ? 'staffing' : 'details'
          }`,
          cssRules: 'font-size: 1.2rem;',
          isMultiline: true,
        },
      };
    },
  },
  {
    columnName: 'Resource',
    valueGetter: ({ resource, devstaffId, isSubTotal, isGrandTotal }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      return {
        type: devstaffId && hasPermissionsTODeliveryModule ? 'link' : 'text',
        componentProps: {
          data: `${resource}`,
          pathname: `/delivery/employee/${devstaffId}/work-book`,
          cssRules: 'font-size: 1.2rem;',
          isMultiline: true,
        },
      };
    },
  },
  {
    columnName: 'Seniority',
    valueGetter: ({ seniority, isSubTotal, isGrandTotal }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      return {
        value: seniority,
        type: 'text',
      };
    },
  },
  {
    columnName: 'Hrly Rate',
    headerProps: {
      cssRules: 'margin: 0 2.5rem 0 0;',
    },
    valueGetter: ({ rate, isSubTotal, isGrandTotal }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      const value = addThousandSeparator(toNumber(rate).toFixed(2));

      return {
        value,
        type: 'text',
      };
    },
  },
  {
    columnName: 'OOO',
    valueGetter: ({ ooo, isSubTotal, isGrandTotal }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      const value = addThousandSeparator(toNumber(ooo).toFixed(2));

      return {
        value,
        type: 'text',
      };
    },
  },
  {
    columnName: 'Hrs In Period',
    headerProps: {
      cssRules: 'margin: 0 2.5rem 0 0;',
    },
    valueGetter: ({ hours, isSubTotal, isGrandTotal }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      const value = addThousandSeparator(toNumber(hours).toFixed(2));

      return {
        value,
        type: 'text',
      };
    },
  },
  {
    columnName: 'Revenue',
    valueGetter: ({ total, isSubTotal, isGrandTotal, isRatePlatinum }) => {
      const data = addThousandSeparator(
        toNumber(toNumber(total).toFixed(2)).toFixed(2)
      );

      return {
        type: 'text',
        componentProps: {
          data: `${!isSubTotal && isRatePlatinum ? '*' : ''}${data}`,
          cssRules: `&& {
          overflow: visible;
          ${!total ? `color: ${colorSecondaryGrayLight}` : ''}
          ${isSubTotal || isGrandTotal ? 'font-weight: 500;' : ''}
        }`,
        },
      };
    },
  },
  {
    columnName: 'Level',
    valueGetter: ({
      isSubTotal,
      isGrandTotal,
      commissionLevel,
      isFirstInvoiceDateSet,
      isCommissionEligibility,
    }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      return {
        value: commissionLevel,
        type: 'text',
        componentProps: {
          data: commissionLevel,
          additionalData: !isFirstInvoiceDateSet ?
            [
              {
                cssRules: `
            padding-right: 0;
            font-size: 1.2rem;
            line-height: 1.4rem;
            color: ${colorPrimary};
            overflow: visible;
            white-space: nowrap;
          `,
                content: 'No 1st invoice date',
              },
            ] :
            null,
          isMultiline: true,
          cssRules: isCommissionEligibility ?
            `
          && {
            padding: 0;
            overflow: visible;
            white-space: nowrap;
          }
          &&:after {
            content: ' / Expired';
          }
        ` :
            '',
        },
        cssRules: `
        && .plain-text {
          overflow: visible;
        }
      `,
      };
    },
  },
  {
    columnName: 'Acc. Age',
    headerProps: {
      cssRules: 'margin: 0 0.5rem;',
    },
    valueGetter: ({ commissionAge, isSubTotal, isGrandTotal }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      return {
        value: commissionAge,
        type: 'text',
        cssRules: `&& {
        text-align: center;
      }`,
      };
    },
  },
  {
    columnName: 'AM through',
    headerProps: {
      cssRules: 'margin: 0 2.5rem 0 0;',
    },
    valueGetter: ({ commissionDate, isSubTotal, isGrandTotal }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      return {
        type: 'text',
        isDate: true,
        unixValue: getUnixTime(commissionDate),
        value: commissionDate ?
          moment(commissionDate).format('YYYY-MM-DD') :
          'n/a',
      };
    },
  },
  {
    columnName: 'Eligible Amount',
    headerProps: {
      cssRules: 'margin: 0 2.5rem 0 0;',
    },
    valueGetter: ({ eligibleAmount, isGrandTotal, isSubTotal }) => {
      const value = addThousandSeparator(toNumber(eligibleAmount).toFixed(2));

      return {
        value,
        type: 'text',
        cssRules: `&& {
        ${!eligibleAmount ? `color: ${colorSecondaryGrayLight}` : ''}
        ${isSubTotal || isGrandTotal ? 'font-weight: 500;' : ''}
      }`,
      };
    },
  },
  {
    columnName: 'Comm. %',
    headerProps: {
      cssRules: 'margin: 0 auto;',
    },
    valueGetter: ({ commissionRate, isSubTotal, isGrandTotal }) => {
      if (isSubTotal || isGrandTotal) {
        return {};
      }
      return {
        value: `${commissionRate} %`,
        type: 'text',
        cssRules: `&& {
      text-align: center;
     }`,
      };
    },
  },
  {
    columnName: 'Comm. $',
    headerProps: {
      cssRules: 'margin: 0 auto;',
    },
    valueGetter: ({ commissionUsd, isGrandTotal, isSubTotal }) => {
      const value = addThousandSeparator(toNumber(commissionUsd).toFixed(2));

      return {
        value,
        type: 'text',
        cssRules: `&& {
        text-align: center;
        ${!commissionUsd ? `color: ${colorSecondaryGrayLight}` : ''}
        ${isSubTotal || isGrandTotal ? 'font-weight: 500;' : ''}
      }`,
      };
    },
  },
];
