import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import {
  ENTITY_NAME,
} from './constants';

export const reconciliationActionsTypes = createTypes([
  ...async('SELECT_CLIENT'),
  ...async('GET_CLIENT_NAMES_LIST'),
  'CHANGE_REPORT_FILTER',
  'RESET_REVIEW_REPORT',
], ENTITY_NAME);

export const reconciliationActions = {
  selectClient: createAction(
    reconciliationActionsTypes.SELECT_CLIENT,
    ({ clientId }) => ({ clientId }),
  ),
  selectClientSuccess: createAction(
    reconciliationActionsTypes.SELECT_CLIENT_SUCCESS,
    ({
      employeeReviews,
      firstReviewDate,
      lastReviewDate,
      lastEvaluationDate,
    }) => ({
      employeeReviews,
      firstReviewDate,
      lastReviewDate,
      lastEvaluationDate,
    })
  ),
  selectClientFail: createAction(
    reconciliationActionsTypes.SELECT_CLIENT_FAIL,
    ({ error = {} }) => ({ error })
  ),

  getClientNamesList: createAction(reconciliationActionsTypes.GET_CLIENT_NAMES_LIST),
  getClientNamesListSuccess: createAction(
    reconciliationActionsTypes.GET_CLIENT_NAMES_LIST_SUCCESS,
    ({ clientNamesList }) => ({ clientNamesList })
  ),
  getClientNamesListFail: createAction(
    reconciliationActionsTypes.GET_CLIENT_NAMES_LIST_FAIL,
    ({ error = {} }) => ({ error })
  ),

  changeFilter: createAction(
    reconciliationActionsTypes.CHANGE_REPORT_FILTER,
    (filters = {}) => (filters),
  ),
};
