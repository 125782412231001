import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { Formik, Form, Field } from 'formik';

import Inputs from 'components/inputs';
import Select from 'components/select';
import Textarea from 'components/textarea';
import Overlay from 'elements/modal-overlay';
import ToastContainer from 'components/toast';
import Loader from 'components/loader';

import { validationSchema } from './validation';

import './styles.scss';

/*
{
  title: String
  body: String
  devstaffRecipients: [Int] emailFields
} */

const SendReviewModal = ({
  clearModal,
  sendPdfReviews,
  isEmployeeFormSubmitted,
  params: {
    pdfFields,
    employeesData: {
      map,
      list,
    },
    employeeFullName,
  },
}) => {
  const initialValues = {
    devstaffRecipients: [],
    title: `Performance review for ${employeeFullName}`,
    body: `Hello!\n \nPlease find the report with performance reviews for ${employeeFullName} attached. If you have any further questions, please contact Resource Management team. \n \nBest regards, \nYour HiQo Resource Management team.`,
  };
  const sendReviews = (emailFields) => {
    sendPdfReviews({
      pdfFields,
      emailFields,
    });
  };

  return (
    <Overlay
      clearModal={clearModal}
    >
      <ToastContainer
        containerId="sendReviewModal"
      />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={sendReviews}
        render={({
          values,
          errors,
          touched,
          setFieldValue,
        }) => {
          const { devstaffRecipients, ...rest } = values;
          const hasEmptyFields = Boolean(Object.values(rest).filter((value) => value === '').length) || !devstaffRecipients.length;

          const selected = devstaffRecipients.map((item) => get(map, item, {}));
          const fieldData = {
            items: list,
            selected,
          };

          return (

            <Fragment>
              <div className="send-review-modal">

                <p className="send-review-modal__title">
                  Send Report
                </p>

                <Form className="send-review-modal__form">

                  <Field
                    type="text"
                    name="title"
                    render={() => (
                      <Select
                        isMulti
                        name="devstaffRecipients"
                        placeholder="Name"
                        error={errors.devstaffRecipients}
                        validationRules={{
                          isRequired: true,
                        }}
                        fieldData={fieldData}
                        withError={!!(touched.devstaffRecipients && errors.devstaffRecipients)}
                        onChange={(names) => setFieldValue('devstaffRecipients', names)}
                      />
                    )
                    }
                  />
                  <Field
                    type="text"
                    name="title"
                    render={() => (
                      <Inputs.TextInput
                        name="title"
                        label="Title"
                        inputValue={values.title}
                        onChange={(email) => setFieldValue('title', email)}
                        error={errors.title}
                        withError={!!(touched.title && errors.title)}
                      />
                    )
                    }
                  />

                  <Field
                    type="text"
                    name="body"
                    render={({ field: { onChange } }) => (
                      <Textarea
                        name="body"
                        label="Email Text"
                        inputValue={values.body}
                        onChange={onChange}
                        error={errors.body}
                        withError={!!(touched.body && errors.body)}
                      />
                    )
                    }
                  />

                  <div className="send-review-modal__controls">
                    <button
                      className={
                        classNames(
                          'button',
                          'send-review-modal__button',
                          { 'button--accept': !(isEmployeeFormSubmitted || hasEmptyFields) },
                          { 'button--disabled': isEmployeeFormSubmitted || hasEmptyFields },
                          { 'button--with-loader': isEmployeeFormSubmitted }
                        )
                      }
                      type="submit"
                    >
                      <Loader
                        isLoaded={!isEmployeeFormSubmitted}
                      >
                        Send
                      </Loader>
                    </button>

                    <button
                      className={classNames(
                        'button',
                        'button--cancel',
                        'send-review-modal__button',
                        { 'button--with-loader': isEmployeeFormSubmitted }
                      )}
                      onClick={() => clearModal()}
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>

                </Form>


              </div>


            </Fragment>
          );
        }
        }
      />

    </Overlay>
  );
};

SendReviewModal.propTypes = {
  clearModal: PropTypes.func.isRequired,
  sendPdfReviews: PropTypes.func.isRequired,
  isEmployeeFormSubmitted: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    pocMsaName: PropTypes.string,
    pocMsaEmail: PropTypes.string,
    ownerEmail: PropTypes.string,
    entityName: PropTypes.string,
  }),
};

SendReviewModal.defaultProps = {
  params: {
    pocMsaName: '',
    pocMsaEmail: '',
    ownerEmail: 'noreply@hiqo-solutions.com',
  },
};


export default SendReviewModal;
