import {
  get,
} from 'lodash';

export default {
  summaryReports: {
    getTabName: (index) => ['billable-activities', 'not-billable-activities'][index],
    getIndex: (tabName) => {
      const pathToTabIndex = {
        'billable-activities': 0,
        'not-billable-activities': 1,
      };

      return get(pathToTabIndex, tabName, 0);
    },
  },
  annualReport: {
    getTabName: (index) => ['staff', 'analysis', 'summary'][index],
    getIndex: (tabName) => {
      const pathToTabIndex = {
        staff: 0,
        analysis: 1,
        summary: 2,
      };

      return get(pathToTabIndex, tabName, 0);
    },
  },
};
