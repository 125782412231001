import { colorPrimary } from 'assets/styles/variables';
import { toNumber } from 'lodash';

import { formatDateMMDDYYYY, getUnixTime } from 'utils/helpers/date';
import { addThousandSeparator } from 'utils/helpers/numbers';

export default ({
  isChefs,
  isCPS,
  isAudit,
  isDTOReport,
  selectedDate,
  hasPermissionsTODeliveryModule,
}) => [
  {
    columnName: 'Project this month',
    valueGetter: ({ resource, project, taskOrderId, isSubTotal }) => {
      if (isSubTotal) {
        return {
          type: 'text',
          componentProps: {
            data: `Total for ${resource}`,
            cssRules: `
            font-size: 1.4rem;
            font-weight: 500;
          `,
          },
        };
      }
      return {
        type: taskOrderId && !isAudit ? 'link' : 'text',
        componentProps: {
          data: project,
          pathname: `/reports/billing-reports/project/${
            !isDTOReport ? 'TO' : 'DTO'
          }/${taskOrderId}/${selectedDate}`,
          cssRules: 'font-size: 1.2rem;',
          isMultiline: true,
        },
      };
    },
  },
  {
    columnName: 'Date',
    valueGetter: ({ date, isSubTotal }) => {
      if (isSubTotal) {
        return {};
      }
      return {
        isDate: true,
        unixValue: getUnixTime(date),
        value: date ? formatDateMMDDYYYY(date) : 'N/A',
        type: 'text',
      };
    },
  },
  {
    columnName: 'Resource',
    valueGetter: ({ resource, devstaffId, isDevstaffNotInTo, isSubTotal }) => {
      if (isSubTotal) {
        return {};
      }
      return {
        type:
          hasPermissionsTODeliveryModule && devstaffId && !isCPS ?
            'link' :
            'text',
        componentProps: {
          data: `${resource}${isDevstaffNotInTo ? '\xa0/' : ''}`,
          pathname: `/delivery/employee/${devstaffId}/${
            isChefs ? 'work-book' : 'pto-requests'
          }`,
          cssRules: 'font-size: 1.2rem;',
          additionalData: isDevstaffNotInTo ?
            [
              {
                cssRules: `
            padding-right: 0;
            font-size: 1.2rem;
            line-height: 1.4rem;
            text-transform: uppercase;
            color: ${colorPrimary};
            overflow-x: visible;
          `,
                content: 'Not in TO',
              },
            ] :
            null,
          isMultiline: true,
        },
      };
    },
  },
  {
    columnName: 'Client Category',
    valueGetter: ({ clientCategoryName, isSubTotal }) => {
      if (isSubTotal) {
        return {};
      }
      return {
        type: 'text',
        componentProps: {
          data: clientCategoryName || 'n/a',
          cssRules: 'font-size: 1.2rem;',
          isMultiline: true,
        },
      };
    },
  },
  {
    columnName: 'Billing Category',
    valueGetter: ({ internalCategoryName, isSubTotal }) => {
      if (isSubTotal) {
        return {};
      }
      return {
        type: 'text',
        componentProps: {
          data: internalCategoryName || 'n/a',
          cssRules: 'font-size: 1.2rem;',
          isMultiline: true,
        },
      };
    },
  },

  {
    columnName: 'Hours',
    valueGetter: ({ hours, isSubTotal }) => {
      const value = addThousandSeparator(toNumber(hours).toFixed(2));

      return {
        type: 'text',
        value,
        cssRules: `&& {
          padding-right: 1.8rem;
          text-align: right;
          ${
  isSubTotal ?
    `
          font-size: 1.4rem;
          font-weight: 500;` :
    ''
}
        }
      `,
      };
    },
  },
  {
    columnName: 'Worklog',
    headerProps: {
      cssRules: 'padding-left: 2rem;',
    },
    valueGetter: ({ worklog, isSubTotal }) => {
      if (isSubTotal) {
        return {};
      }
      return {
        type: 'text',
        componentProps: {
          data: worklog,
          cssRules: `
          box-sizing: border-box;
          padding-left: 2rem;
        `,
          isMultiline: true,
        },
      };
    },
  },
];
