import gql from 'graphql-tag';

export const queryConfig = {
  getClientsWithCategories: gql`
      {
        categoryManagementData{
          hiqoData{
            clientId
            clientName
            isActiveClient
            availableInternalCategories {
              internalCategoryId
              key
              name
              isActive
              hiqoInternal
              worklogs
            }
            availableOooCategories {
              internalCategoryId
              key
              name
              oooCategory
              isActive
              worklogs
            }}
          clientData{
            clientId
            clientName
            isActiveClient
            availableClientCategories {
              clientCategoryId
              key
              name
              isActive
              worklogs
              oooCategory
            }
            availableInternalCategories {
              internalCategoryId
              key
              name
              isActive
              worklogs
              oooCategory
            }}
          existingClientCategoryKeys
          existingInternalCategoryKeys
        }
      }
  `,
  createCategory: (typeCategoryId) => gql`mutation createCategory($fields: CreateCategoryInputList!, $categoryType: String!) {
    createCategory(fields: $fields, categoryType: $categoryType)
    {
      ok
      categoryManagement {
        ${typeCategoryId}
        key
        name
        clientId
        isActive
        oooCategory
        worklogs
      }
    }
  }
  `,
  updateCategory: (typeCategoryId) => gql`mutation updateCategory($fields: UpdateCategoryInputList!, $clientId: Int!,  $categoryType: String!){
    updateCategory(fields: $fields, clientId: $clientId, categoryType: $categoryType)
    {
      ok
      categoryManagement {
        ${typeCategoryId}
        key
        name
        clientId
        isActive
        oooCategory
        worklogs
      }
    }
  }
  `,
  deleteCategory: (typeCategoryId) => gql`mutation deleteCategory($fields: DeleteCategoryInputList!, $clientId: Int!, $categoryType: String!){
    deleteCategory(fields: $fields, clientId: $clientId, categoryType: $categoryType)
    {
      ok
      categoryManagement {
        ${typeCategoryId}
        key
        name
        clientId
        isActive
        oooCategory
        worklogs
      }
    }
  }
  `,
};
