import React, { memo } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { BasicTable } from 'components/basic-table';
import Title from 'elements/title';

import styles from './style.module.scss';

export const ReportTable = memo(({ id, title, columns, data, additionalClass, isEmpty, errorRows }) => (
  <>
    <Title additionalClass={styles.title}>{title}</Title>
    <BasicTable
      id={id}
      columns={columns}
      className={classNames(styles.table, {
        [styles[additionalClass]]: !!additionalClass,
        [styles.empty]: isEmpty,
      })}
      data={data}
      paginate={{
        enable: false,
      }}
      isSort
      {...errorRows.length && {
        errorRows,
      }}
    />
  </>
));

ReportTable.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  additionalClass: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  errorRows: PropTypes.arrayOf(PropTypes.string),
};

ReportTable.defaultProps = {
  errorRows: [],
};
