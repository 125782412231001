import {
  selectUserGroup,
  selectDevCenters,
  selectCustomers,
  selectDevCentersBySystemRoleAuditor,
} from 'core/auth/selectors';
import { commissionRatesManagementFormTemplate } from 'layouts/commission-rates/model';
import { discountSurchargeManagementFormTemplate } from 'layouts/discount-surcharge-management/model';
import { emailManagementFormTemplate } from 'layouts/email-template-management/model';
import { exchangeRatesManagementFormTemplate } from 'layouts/exchange-rates-management/model';
import {
  hiqoContractsListModelGetter,
  hiqoContractsFormTemplate,
} from 'layouts/hiqo-contracts/model';
import {
  holidaysListModelGetter,
  holidaysFormTemplate,
} from 'layouts/none-working-days-management/model';
import { rateCardmanagementFormTemplate } from 'layouts/rate-card-management/model';
import { sortBy, toPairs, fromPairs } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import { getBusinessRoles } from 'utils/helpers/getBusinessRoles';
import { isAudit } from 'utils/helpers/isAudit';
import {
  modelParser,
  parseFormData,
  parseCommissionFormData,
} from 'utils/helpers/models';
import {
  stringCompareFunction,
  numberCompareFunction,
} from 'utils/helpers/sorting';

export const selectLocalState = ({ configurations = {} }) => configurations;
export const selectOrderRules = createSelector(
  selectLocalState,
  (state) => state.orderRules
);

export const selectEntityName = createSelector(
  selectLocalState,
  ({ entityName }) => entityName
);

export const selectIsFetching = createSelector(
  selectLocalState,
  ({ isFetching }) => isFetching
);

export const selectIsFormSubmitted = createSelector(
  selectLocalState,
  ({ isFormSubmitted }) => isFormSubmitted
);

export const selectErrors = createSelector(
  selectLocalState,
  ({ errors }) => errors
);

const selectExchangeRates = createSelector(
  selectLocalState,
  selectOrderRules,
  ({ exchangeRates, orderRules }) => {
    const { isReversed } = orderRules.exchangeRates;

    return exchangeRates.sort((a, b) => numberCompareFunction(a.year, b.year, isReversed));
  }
);

export const selectExchangeRatesManagementFormContent = createSelector(
  selectExchangeRates,
  (exchangeRates) => parseFormData({
    content: exchangeRatesManagementFormTemplate,
    details: {
      exchangeRates,
    },
  })
);

export const selectCommissionRates = createSelector(
  selectLocalState,
  ({ commissionRates }) => {
    function compare(a, b) {
      return moment(a.date).isAfter(b.date) ? 1 : -1;
    }
    return commissionRates.map((item) => {
      item.records.sort(compare);
      return item;
    });
  }
);

export const selectCommissionUsers = createSelector(
  selectLocalState,
  ({ commissionRatesUsers }) => commissionRatesUsers
);

export const selectCommissionRatesSorted = createSelector(
  selectCommissionRates,
  selectOrderRules,
  (commissionRates, sortingRules) => {
    const { orderBy: order, isReversed } = sortingRules.commissionRates;
    let valueKey;
    switch (order) {
      case 'Full Name':
        valueKey = 'fullName';
        break;
      case 'Reports To':
        valueKey = 'reportsTo';
        break;
      case 'Base Salary':
        valueKey = 'baseSal';
        break;
      case '[%]':
        valueKey = 'commDirect';
        break;
      case 'L2 [%]':
        valueKey = 'commDirect1';
        break;
      case 'L3 [%]':
        valueKey = 'commDirect2';
        break;
      case 'AM [%]':
        valueKey = 'commAm';
        break;
      case 'Days':
        valueKey = 'days';
        break;
      case '% of Rev.':
        valueKey = 'rev';
        break;
      default:
        valueKey = '';
    }

    return commissionRates.sort((a, b) => {
      switch (valueKey) {
        case 'fullName':
          return stringCompareFunction(a[valueKey], b[valueKey], isReversed);
        case 'reportsTo':
          return stringCompareFunction(
            a.records[a.records.length - 1][valueKey],
            b.records[b.records.length - 1][valueKey],
            isReversed
          );
        case 'baseSal':
          return numberCompareFunction(
            a.records[a.records.length - 1][valueKey],
            b.records[b.records.length - 1][valueKey],
            isReversed
          );
        case 'commDirect':
          return numberCompareFunction(
            a.records[a.records.length - 1].commissions[valueKey],
            b.records[b.records.length - 1].commissions[valueKey],
            isReversed
          );
        case 'commDirect1':
          return numberCompareFunction(
            a.records[a.records.length - 1].commissions[valueKey],
            b.records[b.records.length - 1].commissions[valueKey],
            isReversed
          );
        case 'commDirect2':
          return numberCompareFunction(
            a.records[a.records.length - 1].commissions[valueKey],
            b.records[b.records.length - 1].commissions[valueKey],
            isReversed
          );
        case 'commAm':
          return numberCompareFunction(
            a.records[a.records.length - 1].commissions[valueKey],
            b.records[b.records.length - 1].commissions[valueKey],
            isReversed
          );
        default:
          return true;
      }
    });
  }
);

export const selectCommissionRatesManagementFormContent = createSelector(
  selectCommissionRatesSorted,
  (commissionRatesSorted) => parseCommissionFormData({
    content: commissionRatesManagementFormTemplate(),
    details: commissionRatesSorted,
  })
);

export const selectRateCards = createSelector(
  selectLocalState,
  ({ rateCards }) => rateCards
);

export const selectRateCardsActiveIndex = createSelector(
  selectLocalState,
  ({ rateCardsActiveIndex }) => rateCardsActiveIndex
);
export const selectCreateMode = createSelector(
  selectLocalState,
  ({ createMode }) => createMode
);

export const selectRateCardsDetails = createSelector(
  selectLocalState,
  selectUserGroup,
  selectDevCenters,
  (
    {
      rateCards,
      lastRates,
      billingAgents,
      rateCardsActiveIndex,
      createMode,
      rateCardsSelectedYear,
    },
    userGroup,
    devcenters
  ) => {
    if (rateCards) {
      const rateCard = rateCards[rateCardsActiveIndex] || {};
      const details = createMode ?
        {
          rateCard: {
            rateCardName: '',
            year: rateCardsSelectedYear,
            billingAgent: null,
            effectiveDate: null,
            emailContent: null,
            attachment: '',
            rates: lastRates,
          },
          billingAgents,
        } :
        {
          rateCard,
          billingAgents,
        };
      return parseFormData({
        content: rateCardmanagementFormTemplate(
          details.rateCard,
          createMode,
          rateCardsSelectedYear,
          userGroup,
          devcenters
        ),
        details,
      });
    }
    return [];
  }
);

export const selectEmailTemplates = createSelector(
  selectLocalState,
  ({ emailTemplates }) => emailTemplates
);
export const selectEmailTemplatesActiveIndex = createSelector(
  selectLocalState,
  ({ emailTemplatesActiveIndex }) => emailTemplatesActiveIndex
);

export const selectEmailTemplateDetails = createSelector(
  selectLocalState,
  ({
    emailTemplates,
    emailTemplatesActiveIndex,
    emailTemplatesSelectedYear,
    createMode,
  }) => {
    if (emailTemplates) {
      const emailTemplate = emailTemplates[emailTemplatesActiveIndex] || {};
      const details = createMode ?
        {
          emailTemplate: {
            emailTemplateId: null,
            name: '',
            content: null,
            subject: '',
            year: emailTemplatesSelectedYear,
            file: null,
            fileId: null,
          },
        } :
        { emailTemplate };
      return parseFormData({
        content: emailManagementFormTemplate(details.emailTemplate),
        details,
      });
    }
    return {};
  }
);

export const selectHolidays = createSelector(
  selectLocalState,
  ({ holidays }) => holidays
);
export const selectHolidaysFilter = createSelector(
  selectLocalState,
  ({ filter }) => filter
);

export const selectHolidaysListModel = createSelector(
  selectDevCenters,
  selectUserGroup,
  (devcenters, userGroup) => holidaysListModelGetter(devcenters, userGroup)
);

export const selectFilteredHolidays = createSelector(
  selectHolidays,
  selectHolidaysFilter,
  (holidays = [], filters) => {
    if (filters.length) {
      return holidays.filter((holiday) => filters.includes(holiday.devcenter));
    }
    return holidays;
  }
);

export const selectHolidaysData = createSelector(
  selectFilteredHolidays,
  selectHolidaysListModel,
  selectOrderRules,
  (
    holidays,
    {
      tableName,
      dataTemplate,
      rowStatusGetter,
      // filtersTemplate,
    },
    orderRules
  ) => {
    const options = {
      orderRules: orderRules[tableName],
      idKey: 'projectId',
      tableName,
      rowStatusGetter,
    };
    const { data } = modelParser(holidays, dataTemplate, options);

    return data;
  }
);

export const selectHolidaysDetails = createSelector(
  selectLocalState,
  selectDevCenters,
  ({ holidaysSelectedYear, createMode }, devcenters) => {
    const newHoliday = {
      devcenter: null,
      date: null,
      description: null,
      year: holidaysSelectedYear,
    };
    const details = {
      deliveryCenters: devcenters,
      newHoliday,
      createMode,
      holidaysSelectedYear,
    };
    return parseFormData({
      content: holidaysFormTemplate(holidaysSelectedYear, devcenters),
      details,
    });
  }
);

export const selectHiqoContracts = createSelector(
  selectLocalState,
  ({ hiqoContracts }) => hiqoContracts
);
export const selectHiqoContractsListModel = createSelector(
  selectUserGroup,
  selectDevCenters,
  selectCustomers,
  (userGroup, devcenters, customers) => {
    const customersById = {};
    customers.forEach(({ value, label }) => {
      customersById[value] = label;
    });
    return hiqoContractsListModelGetter(userGroup, devcenters, customersById);
  }
);

export const selectHiqoContractsData = createSelector(
  selectUserGroup,
  selectHiqoContracts,
  selectHiqoContractsListModel,
  selectOrderRules,
  selectDevCentersBySystemRoleAuditor,
  (
    userGroup,
    hiqoContracts,
    {
      tableName,
      dataTemplate,
      rowStatusGetter,
      // filtersTemplate,
    },
    orderRules,
    devCentersBySystemRole
  ) => {
    const options = {
      orderRules: orderRules[tableName],
      idKey: 'id',
      tableName,
      rowStatusGetter,
      // filtersTemplate,
    };

    if (isAudit(userGroup)) {
      const hiqoContractsForBy = hiqoContracts.filter(({ devcenter }) => getBusinessRoles(devCentersBySystemRole, devcenter).includes(userGroup));

      const { data } = modelParser(
        hiqoContractsForBy,
        [
          ...dataTemplate.slice(0, 1),
          ...dataTemplate.slice(2, dataTemplate.length - 1),
        ],
        options
      );
      return data;
    }

    const { data } = modelParser(hiqoContracts, dataTemplate, options);

    return data;
  }
);

export const selectHiqoContractsDetails = createSelector(
  selectLocalState,
  selectDevCenters,
  selectCustomers,
  ({ createMode }, devcenters, customers) => {
    const newContract = {
      name: null,
      devcenter: null,
      customer: null,
      dateTo: null,
      dateFrom: null,
    };
    const details = {
      deliveryCenters: devcenters,
      newContract,
      createMode,
    };
    return parseFormData({
      content: hiqoContractsFormTemplate(devcenters, customers),
      details,
    });
  }
);

export const selectDiscountSurchargeManagementFormContent = createSelector(
  selectLocalState,
  selectDevCenters,
  ({ discountSurchargeRates, billLockDate }, devcenters) => {
    // get lock date
    const dateFromBillLock = moment(billLockDate);
    const monthLockNumber = dateFromBillLock.format('M');
    const yearLockNumber = dateFromBillLock.format('YYYY');
    const lockDateByMonthAndYear = moment({
      year: yearLockNumber,
      month: monthLockNumber - 1,
      day: 1,
    });
    const devcenterIdsFromRates = new Set();

    const rates = discountSurchargeRates.length ?
      discountSurchargeRates.map((item) => {
        devcenterIdsFromRates.add(item.deliveryCenterId);

        const rateDate = moment({ year: item.year, month: item.month - 1 });
        return {
          ...item,
          isLocked: rateDate.isBefore(lockDateByMonthAndYear),
        };
      }) :
      [];

    // get all years
    const allYears = {};
    rates.forEach(({ year }) => {
      allYears[year] = year;
    });
    const years = [...Object.keys(allYears)].sort();

    // create template for table by format [{year: "2023", monthData: [{month: [monthRates]}]}]
    const dataForTemplate = Array.from(years, (year) => ({
      year,
      monthData: [
        { month: 1 },
        { month: 2 },
        { month: 3 },
        { month: 4 },
        { month: 5 },
        { month: 6 },
        { month: 7 },
        { month: 8 },
        { month: 9 },
        { month: 10 },
        { month: 11 },
        { month: 12 },
      ],
    }));

    dataForTemplate.forEach(({ year, monthData }) => {
      const ratesByYear = rates.filter((rate) => +rate.year === +year);

      monthData.forEach((dataOfMonth) => {
        const ratesByMonth = ratesByYear.filter((rate) => +rate.month === +dataOfMonth.month);

        // eslint-disable-next-line no-param-reassign
        dataOfMonth.monthRates =
            [...sortBy(ratesByMonth, 'deliveryCenterId')];
      });
    });

    const devcentersById = Object.fromEntries(
      Object.entries(devcenters.byId).filter(([key]) => devcenterIdsFromRates.has(+key))
    );

    const devcentersData = [
      ...Object.values(fromPairs(sortBy(toPairs(devcentersById), ([key]) => parseInt(key, 10)))),
    ];

    return parseFormData({
      content: discountSurchargeManagementFormTemplate,
      details: {
        dataForTemplate,
        devcentersData,
      },
    });
  }
);
