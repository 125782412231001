/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  FilterSelect,
  Search,
} from 'components/form-with-list/components/filters/components';
import { FILTER_TYPE } from 'components/form-with-list/components/filters/constants';
import { selectBillingAgents, selectRoles } from 'core/auth/selectors';
import { userManagementActions } from 'core/configurations/user-management/actions';

const { changeFilterByColumns } = userManagementActions;

export const useFilterItems = () => {
  const billingAgents = useSelector(selectBillingAgents);
  const roles = useSelector(selectRoles);

  const filterItems = useMemo(() => [
    {
      type: FILTER_TYPE.SEARCH,
      name: 'fullname',
      render: ({ name, isActiveFilter }) => (
        <Search
          key={name}
          name={name}
          changeFilter={changeFilterByColumns}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SEARCH,
      name: 'email',
      id: 'email',
      delay: 500,
      render: ({ name, isActiveFilter }) => (
        <Search
          key={name}
          name={name}
          changeFilter={changeFilterByColumns}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SELECT,
      name: 'role',
      accessor: 'label',
      id: 'label',
      render: ({ name, accessor, id, isActiveFilter }) => (
        <FilterSelect
          data={roles}
          name={name}
          accessor={accessor}
          id={id}
          changeAction={changeFilterByColumns}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SEARCH,
      name: 'title',
      id: 'title',
      render: ({ name, isActiveFilter }) => (
        <Search
          key={name}
          name={name}
          changeFilter={changeFilterByColumns}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SELECT,
      name: 'billingAgent',
      accessor: 'label',
      id: 'value',
      render: ({ name, accessor, id, isActiveFilter }) => (
        <FilterSelect
          data={billingAgents}
          name={name}
          accessor={accessor}
          id={id}
          changeAction={changeFilterByColumns}
          isActive={isActiveFilter}
        />
      ),
    },
    {
      type: FILTER_TYPE.SEARCH,
      name: 'phone',
      id: 'phone',
      render: ({ name, isActiveFilter }) => (
        <Search
          key={name}
          name={name}
          changeFilter={changeFilterByColumns}
          isActive={isActiveFilter}
        />
      ),
    },
  ], [
    billingAgents,
    roles,
  ]);

  return {
    filterItems,
  };
};
