import gql from 'graphql-tag';

export const queryConfig = {
  getChannelPartnersList: gql`{
    channelPartners {
      channelPartnerId
      name
      country
      accountManagerId
      accountManagerFullname
      billingAgent{
        id
        name
      }
      associatedClients{
        clientId
        name
      }
    }
}`,
  getChannelPartnerDetails: (channelPartnerId) => gql`{
    ${channelPartnerId && `
    node(id: "ChannelPartner:${channelPartnerId}")
        {
          ... on ChannelPartner {
              channelPartnerId
              name
              stateIncorporated
              address1
              address2
              city
              state
              zip
              country
              pocAccountName
              pocAccountEmail
              pocAccountPhone
              pocAccountCell
              pocAccountFax
              pocAccountTitle
              pocAccountAdr1
              pocAccountAdr2
              pocAccountCity
              pocAccountState
              pocAccountZip
              accountManagerId
              accountManagerFullname
              billingAgent{
                id
                name
              }
              billingAgentId
              billingLang
              emailReports
              taskOrders {
                isClientRatecardChanged
                isSelfRatecardChanged
                isManuallyDeactivated
                taskOrderId
                projectId
                title
                projectType
                isSelfRatecardChanged
                isClientRatecardChanged
                effectiveDate
                endDate
                isActive
                isFinished
                categoryKeys
              }
              files{
                fileId
                channelPartnerId
                title
                fileName
                uploadedDate
                effectiveDate
                downloadUrl
              }
          }
        }`}
          countries {
            name
            shortName
            nativeName
          }
          states {
            code
            name
          }
          billingAgents {
            billingAgentId
            name
          }
          languages {
            code
            name
          }
          users {
            email
            userId
            fullName
            isSales
          }
      }`,
  createChannelPartner: gql`
    mutation createNewChannelPartner($fields: CreateChannelPartnerInput!) {
      createChannelPartner(fields: $fields) {
        ok
        channelPartner{
          channelPartnerId
          name
          stateIncorporated
          address1
          address2
          city
          state
          zip
          country
          pocAccountName
          pocAccountEmail
          pocAccountPhone
          pocAccountCell
          pocAccountFax
          pocAccountTitle
          pocAccountAdr1
          pocAccountAdr2
          pocAccountCity
          pocAccountState
          pocAccountZip
          accountManagerId
          accountManagerFullname
          billingAgent{
            id
            name
          }
          billingAgentId
          billingLang
          associatedClients{
            clientId
            name
          }
          emailReports
        }
      }
    }`,
  updateChannelPartnerDetails: (channelPartnerId) => gql`
    mutation updateChannelPartner($fields: UpdateChannelPartnerInput!) {
      updateChannelPartner(channelPartnerId: ${channelPartnerId}, fields: $fields){
        ok
        channelPartner{
          channelPartnerId
          name
          stateIncorporated
          address1
          address2
          city
          state
          zip
          country
          pocAccountName
          pocAccountEmail
          pocAccountPhone
          pocAccountCell
          pocAccountFax
          pocAccountTitle
          pocAccountAdr1
          pocAccountAdr2
          pocAccountCity
          pocAccountState
          pocAccountZip
          accountManagerId
          accountManagerFullname
          billingAgent{
            id
            name
          }
          billingAgentId
          billingLang
          associatedClients{
            clientId
            name
          }
          emailReports
        }
      }
    }`,
};
