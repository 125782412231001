import React, { memo } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { BasicTable } from 'components/basic-table';
import Title from 'elements/title';

import styles from './style.module.scss';

export const SeniorityTable = memo(({ id, title, columns, data }) => (
  <div className={styles.seniority}>
    <Title additionalClass={styles.title}>{title}</Title>
    <BasicTable
      id={id}
      columns={columns}
      className={classNames(styles.table)}
      data={data}
      paginate={{
        enable: false,
      }}
      isSort
    />
  </div>
));

SeniorityTable.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};
