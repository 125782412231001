import gql from 'graphql-tag';

export const queryConfig = {
  getClientsList: gql`{
    clients {
      name
      country
      isActive
      clientId
      inArrears
      userFullname
      isPlatinumRates
      isSignedMsaUploaded
      billingAgent {
        name
      }
      templateRatecard {
        name
      }
  }
}`,
  getMsaByClientId: (clientId) => gql`{
    node(id: "Client:${clientId}") {
      ... on Client {
        msa
      }
    }
  }`,
  getClientDetails: (clientId) => gql`{
    ${clientId ? `
    node(id: "Client:${clientId}")
        { ... on Client {
            clientId
            accountManagerExpDate
            channelPartnerId
            name
            isActive
            isSignedMsaUploaded
            inArrears
            msa
            country
            customer
            msaTemplate
            nosec14
            billingAgentId
            billingAgent {
              name
              id
              billingAgentId
            }
            ratecardId
            isPlatinumRates
            stateIncorporated
            address1
            address2
            city
            state
            country
            zip
            individualLiability
            pocMsaName
            pocMsaTitle
            pocMsaEmail
            pocMsaFax
            pocMsaAddress1
            pocMsaAddress2
            pocMsaCity
            pocMsaState
            pocMsaZip
            pocAccountName
            pocAccountEmail
            pocAccountPhone
            poRequired
            pocAccountFax
            pocAccountCell
            connectedClient
            billingLang
            ownerId
            accountManagerId
            firstInvoice
            overtimePerc
            paymentDays
            excludedDevcenters
            showDevcenterInfo
            taskOrders {
              isClientRatecardChanged
              isSelfRatecardChanged
              isManuallyDeactivated
              isSigned
              msaName
              projectId
              taskOrderId
              projectType
              effectiveDate
              endDate
              isActive
              isFinished
              title
              clientId
              categoryKeys
            }
            files {
              isRequestToSignSent
              title
              fileId
              fileName
              fileType
              uploadedDate
              effectiveDate
              downloadUrl
              downloadSignedUrl
              isSigned
              isGenerated
              versionOfMsaTemplate
            }
            clientRatecard {
              billingAgentId
              clientId
              name
              ratecardId
              year
              rates {
                devcenter
                seniority
                ratePtm
                rateHr
                rateDisc
                ratePlatinum
                rateMo
                rateMoPlatinum
              }
            }
            templateRatecard {
              ratecardId
              name
              rates {
                devcenter
                seniority
                ratePtm
                rateHr
                rateDisc
                ratePlatinum
                rateMo
                rateMoPlatinum
              }
            }
            includeHrsPerCategory
          }
        }
        channelPartners {
           channelPartnerId
           name
         }
    ` : ''}
        countries {
          name
          shortName
          nativeName
        }
        states {
          code
          name
        }
        billingAgents {
          billingAgentId
          name
        }
        languages {
          code
          name
        }
        clients {
          clientId
          name
          ownerId
          userFullname
        }
        channelPartners {
          channelPartnerId
          name
        }
        users {
          email
          userId
          fullName
          isSales
        }
        paymentTerms {
          paymentTermId
          description
          days
        }
        devcenters {
          value: devcenterId
          label: name
          active
        }
        ratecards {
          ratecardId
          billingAgentId
          name
          rates {
            devcenter
            rateDisc
            rateHr
            rateMo
            rateMoPlatinum
            ratePlatinum
            ratePtm
            seniority
          }
        }
        defaultMsaTemplate{
          msaSections{
            sectionTitle
            sectionText
          }
          msaSectionsIndividual {
            sectionTitle
            sectionText
          }
          msaSectionsIndividualNosec14 {
            sectionTitle
            sectionText
          }
          msaSectionsNosec14{
            sectionTitle
            sectionText
          }
        }
      }`,

  createClient: gql`mutation createNewClient($fields: CreateClientInput!) {
        createClient(fields: $fields)
          {
            ok
            defaultMsaTemplate{
              msaSections{
                sectionTitle
                sectionText
              }
              msaSectionsIndividual {
                sectionTitle
                sectionText
              }
              msaSectionsIndividualNosec14 {
                sectionTitle
                sectionText
              }
              msaSectionsNosec14{
                sectionTitle
                sectionText
              }
            }
            client {
                clientId
                accountManagerExpDate
                name
                channelPartnerId
                isActive
                isSignedMsaUploaded
                inArrears
                showDevcenterInfo
                excludedDevcenters
                msa
                msaTemplate
                nosec14
                country
                customer
                billingAgentId
                billingAgent {
                  name
                  id
                  billingAgentId
                }
                ratecardId
                isPlatinumRates
                stateIncorporated
                address1
                address2
                city
                state
                country
                zip
                individualLiability
                pocMsaName
                pocMsaTitle
                pocMsaEmail
                pocMsaFax
                pocMsaAddress1
                pocMsaAddress2
                pocMsaCity
                pocMsaState
                pocMsaZip
                pocAccountName
                pocAccountEmail
                pocAccountPhone
                poRequired
                pocAccountFax
                pocAccountCell
                connectedClient
                billingLang
                ownerId
                accountManagerId
                firstInvoice
                overtimePerc
                paymentDays
                clientRatecard {
                  rates {
                    devcenter
                    seniority
                    ratePtm
                    rateHr
                    rateDisc
                    ratePlatinum
                    rateMo
                    rateMoPlatinum
                  }
                }
                templateRatecard {
                  ratecardId
                  name
                  rates {
                    devcenter
                    seniority
                    ratePtm
                    rateHr
                    rateDisc
                    ratePlatinum
                    rateMo
                    rateMoPlatinum
                  }
                }
                includeHrsPerCategory
              }
            }
      }`,

  updateClientDetails: (clientId) => gql`mutation updateClientDetails($fields: UpdateClientInput!) {
    updateClient(clientId: ${clientId}, fields: $fields)
        { ok
          client {
            clientId
            accountManagerExpDate
            channelPartnerId
            name
            isActive
            isSignedMsaUploaded
            inArrears
            msa
            msaTemplate
            country
            customer
            showDevcenterInfo
            excludedDevcenters
            nosec14
            billingAgentId
            billingAgent {
              name
              id
              billingAgentId
            }
            ratecardId
            isPlatinumRates
            stateIncorporated
            address1
            address2
            city
            state
            country
            zip
            individualLiability
            pocMsaName
            pocMsaTitle
            pocMsaEmail
            pocMsaFax
            pocMsaAddress1
            pocMsaAddress2
            pocMsaCity
            pocMsaState
            pocMsaZip
            pocAccountName
            pocAccountEmail
            pocAccountPhone
            poRequired
            pocAccountFax
            pocAccountCell
            connectedClient
            billingLang
            ownerId
            accountManagerId
            firstInvoice
            overtimePerc
            paymentDays
            taskOrders {
              isClientRatecardChanged
              isSelfRatecardChanged
              isManuallyDeactivated
              isSigned
              msaName
              projectId
              taskOrderId
              projectType
              effectiveDate
              endDate
              isActive
              isFinished
              title
              clientId
              projectKeys
            }
            files {
              isRequestToSignSent
              title
              fileId
              fileName
              fileType
              uploadedDate
              effectiveDate
              downloadUrl
              downloadSignedUrl
              isSigned
              isGenerated
              versionOfMsaTemplate
            }
            clientRatecard {
              billingAgentId
              clientId
              name
              ratecardId
              year
              rates {
                devcenter
                seniority
                ratePtm
                rateHr
                rateDisc
                ratePlatinum
                rateMo
                rateMoPlatinum
              }
            }
            templateRatecard {
              ratecardId
              name
              rates {
                devcenter
                seniority
                ratePtm
                rateHr
                rateDisc
                ratePlatinum
                rateMo
                rateMoPlatinum
              }
            }
            includeHrsPerCategory
          }
        }
    }`,
};
