import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const Divider = ({ className }) =>
  (<div data-id="divider" className={className || 'default'} />);

Divider.propTypes = {
  className: PropTypes.string,
};

Divider.defaultProps = {
  className: undefined,
};
export default Divider;

