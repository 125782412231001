import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as DeleteIcon } from 'assets/img/icon-delete.svg';

import { ActionButton } from 'components/buttons';
import { dirtyValues } from 'forms/helpers';
import { isString } from 'lodash';

export const Controls = memo(({
  handleSubmit,
  formState,
  reset,
  actions,
  userId,
  dirtyFields,
  email,
}) => {
  const handleActionButtonClick = (actionData, userEmail) => {
    const isCreateMode = isString(userId);

    if (actionData.text === 'Cancel') {
      return () => {
        if (isCreateMode) {
          actionData.action(userId);
          return;
        }

        reset();
      };
    }

    if (actionData.text !== 'Save') {
      return () => {
        actionData.action(userId, {
          email: userEmail,
        });
      };
    }

    return handleSubmit(async (formData) => {
      const data = isCreateMode ?
        formData :
        dirtyValues(dirtyFields, formData);

      actionData.action(userId, data, formState);
    });
  };

  return (
    <div data-id="controls">
      {actions.map((actionData) => (
        <ActionButton
          key={`${userId}_${actionData.text}`}
          dataId={actionData.text}
          type="button"
          isLoading={actionData.hasLoader && formState.isSubmitting}
          disabled={formState.isSubmitting}
          onClick={handleActionButtonClick(
            actionData,
            email,
          )}
        >
          {actionData.text === 'Delete' ? <DeleteIcon /> : actionData.text}
        </ActionButton>
      ))}
    </div>
  );
});

Controls.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  })).isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  formState: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
    isFormSubmitted: PropTypes.bool.isRequired,
    isFormError: PropTypes.bool.isRequired,
  }).isRequired,
  dirtyFields: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
};
