import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import {
  ENTITY_NAME,
} from './constants';

export const deliveryCenterUtilizationByMonthActionsTypes = createTypes([
  'CHANGE_FILTER',
  'CHANGE_FILTER_SUCCESS',
  'RESET_FILTERS',
  'CHANGE_MAIN_FILTER',
  'SET_ORDER_RULES',
  ...async('GET_UTILIZATION_REPORT'),
], ENTITY_NAME);

export const deliveryCenterUtilizationByMonthActions = {
  changeFilter: createAction(
    deliveryCenterUtilizationByMonthActionsTypes.CHANGE_FILTER,
    ({
      storeKey,
      selected = null,
      type,
    }) => ({
      storeKey,
      selected,
      type,
    }),
    () => ({
      entityName: ENTITY_NAME,
    })
  ),
  changeFilterSuccess: createAction(
    deliveryCenterUtilizationByMonthActionsTypes.CHANGE_FILTER_SUCCESS,
    ({
      currentFilter,
      storeKey,
      config,
    }) => ({
      currentFilter,
      storeKey,
      config,
    })
  ),

  changeMainFilter: createAction(
    deliveryCenterUtilizationByMonthActionsTypes.CHANGE_MAIN_FILTER,
    (filters = {}) => (filters),
  ),

  resetFilters: createAction(deliveryCenterUtilizationByMonthActionsTypes.RESET_FILTERS),

  getUtilizationReport: createAction(deliveryCenterUtilizationByMonthActionsTypes.GET_UTILIZATION_REPORT),

  getUtilizationReportSuccess: createAction(
    deliveryCenterUtilizationByMonthActionsTypes.GET_UTILIZATION_REPORT_SUCCESS,
    (payload) => payload
  ),

  getUtilizationReportFail: createAction(
    deliveryCenterUtilizationByMonthActionsTypes.GET_UTILIZATION_REPORT_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),

  setOrderRules: createAction(
    deliveryCenterUtilizationByMonthActionsTypes.SET_ORDER_RULES,
    ({
      tableName,
      orderRules,
    }) => ({
      tableName,
      orderRules,
    })
  ),
};
