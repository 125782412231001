import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { configurationsActions } from "core/configurations/actions";
import {
  selectRateCardsDetails,
  selectRateCards,
  selectRateCardsActiveIndex,
  selectCreateMode,
  selectEntityName,
} from "core/configurations/selectors";

import { selectBillingAgents, selectUserGroup } from "core/auth/selectors";
import {
  selectUploadingProgress,
  selectIsFileUploaded,
} from "core/files/selectors";
import { USERS_GROUPS } from "core/auth/constants";
import { getHasPermissions } from "utils/auth";
import moment from "moment";
import Controls from "components/list-controls";
import StyledWrapper from "elements/styled-wrapper";
import WrapperNg from "elements/wrapper-ng";
import Wrapper from "elements/wrapper";
import DetailsForm from "components/details-form";
import UploadDocumentForm from "components/upload-document-form";
import Calendar from "components/calendar";
import Loader from "components/loader";
import ActionButton from "elements/action-button";
import CustomLink from "elements/link";
import PdfIcon from "assets/img/icon-pdf.svg";
import { format as formatDate, FORMATS } from "utils/date";

import {
  yearPickerCssRules,
  calendarItemCssRules,
  controlsCssRules,
  controlsWrapperCssRules,
  rateCardsWrapperStyles,
  formWrapperStyles,
  addButtonStyles,
} from "./styles";

const calendarStepControlsConfig = {
  step: 1,
  unitName: "year",
};
const calendarPopperProps = {
  placement: "bottom-start",
};

const { CEO, CPS } = USERS_GROUPS;

const RateCardmanagement = ({
  entityName,
  isFileUploaded,
  uploadingProgress,
  getRateCards,
  rateCardsDetails,
  isFetching,
  rateCards,
  setRateCardsActiveIndex,
  setCreateMode,
  activeRateCardIndex,
  createMode,
  billingAgents,
  createRateCardRecord,
  changeCurrentModal,
  deleteRateCardRecord,
  updateRateCardRecord,
  userGroup,
  uploadFile,
}) => {
  const isCEO = getHasPermissions(userGroup, [CEO]);
  const isCPS = getHasPermissions(userGroup, [CPS]);
  const [date, setDate] = useState(moment());
  const rateCardAttachment = rateCards[activeRateCardIndex]
    ? rateCards[activeRateCardIndex].attachment
    : {};
  const { title, downloadUrl } = rateCardAttachment;
  const [file, setFile] = useState(!!(title && downloadUrl));

  useEffect(() => {
    getRateCards({ date: moment().year(), billingAgents });
  }, []);

  useEffect(() => {
    setFile(!!(title && downloadUrl));
  }, [activeRateCardIndex, rateCards]);

  useEffect(() => {
    if (billingAgents.length) {
      getRateCards({ date: moment().year(), billingAgents });
    }
  }, [billingAgents]);

  const changeYear = (calendarValue) => {
    setDate(calendarValue);
    getRateCards({ date: calendarValue.year(), billingAgents });
    setCreateMode({ createMode: false });
  };

  const CreateModeClickHandler = () => {
    setCreateMode({ createMode: !createMode });
    setFile(!!(title && downloadUrl));
  };

  const onSubmit = ({ fields }) => {
    const rateCard = fields;
    const year = fields.year;
    return createMode
      ? createRateCardRecord({ year, rateCard })
      : updateRateCardRecord({ year, rateCard });
  };
  const onSubmitUploadedFile = ({ file }) => {
    uploadFile({
      file,
      title: file.name,
      ratecardId: rateCards[activeRateCardIndex].recordId,
      year: date.year(),
      billingAgents,
      entityName,
    });
  };

  const rateCardsList = (ratesList, activeIndex) => {
    const rates = ratesList.map((rate, index) => {
      const isActive = index === activeIndex ? "actve" : "";
      const handleClick = () => {
        if (createMode) {
          setCreateMode({ createMode: false });
        }
        setRateCardsActiveIndex({ activeIndex: index });
      };
      return (
        <li
          key={`${index}_${rate.rateCardName}`}
          className={`rate-cards__list-item ${isActive}`}
          onClick={handleClick}
        >
          {rate.rateCardName}
        </li>
      );
    });
    return (
      <>
        <ul className="rate-cards__list">{rates}</ul>
        {isCEO && (
          <ActionButton
            data="Create new"
            onClick={() => CreateModeClickHandler()}
            withBody
            isActive={createMode}
            cssRules={`
              margin-top: 2.5rem;
              margin-bottom: auto;
              ${addButtonStyles}
          `}
            className={createMode ? "active" : ""}
          />
        )}
      </>
    );
  };
  const fileLoaderConfig = {
    title: "SCR Upload",
    fields: [],
    initialValues: {},
  };

  const additionalActions = {
    deleteRateCardRecord,
    openModal: ({ currentModal, ...args }) =>
      changeCurrentModal({ currentModal, params: { ...args } }),
  };
  return (
    <Fragment>
      <Controls title="Rate Card Management" cssRules={controlsCssRules} />
      <StyledWrapper cssRules={controlsWrapperCssRules}>
        <WrapperNg>
          <Calendar
            withStepControls
            withYearSelecting
            minDetails="decade"
            onChange={changeYear}
            currentDate={moment()}
            value={date}
            popperProps={calendarPopperProps}
            cssRules={yearPickerCssRules}
            stepControlsConfig={calendarStepControlsConfig}
          >
            <StyledWrapper cssRules={calendarItemCssRules}>
              {formatDate(date, FORMATS.YYYY)}
            </StyledWrapper>
          </Calendar>
        </WrapperNg>
      </StyledWrapper>

      <Loader isLoaded={!isFetching}>
        <Wrapper cssRules={rateCardsWrapperStyles}>
          {(!!rateCards.length || createMode) && (
            <div className="rate-cards">
              <div className="details-form__title">Rate Cards</div>
              {rateCardsList(rateCards, activeRateCardIndex)}
              {!!rateCards.length && !createMode && (
                <div className="ratecard-file-block">
                  {rateCardAttachment.title && !file && (
                    <ActionButton
                      data="Cancel"
                      onClick={() => setFile(rateCardAttachment)}
                    />
                  )}
                  {file && isCEO && (
                    <ActionButton
                      data="Upload new file"
                      onClick={() => setFile(false)}
                    />
                  )}
                  {!createMode && !file && isCEO && !isCPS && (
                    <UploadDocumentForm
                      className="uploader"
                      isFetchingFiles={isFetching}
                      onSubmit={onSubmitUploadedFile}
                      uploadingProgress={uploadingProgress}
                      isFileUploaded={isFileUploaded}
                      fileLoaderConfig={fileLoaderConfig}
                    />
                  )}
                  {!createMode && file && !isCPS && (
                    <div className="scr_link">
                      <div className="scr_link-title">SCR File</div>
                      <CustomLink
                        data={title}
                        href={downloadUrl}
                        // state,
                        imgSrc={PdfIcon}
                        // pathname,
                        cssRules="
                        display: flex;
                        flex-direction: row-reverse;
                        .customLink__icon {
                          margin-right: 10px;
                        }
                      "
                        withBlank
                        isMultiline
                        // additionalData,
                        // wrapperCssRules,
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {(!!rateCards.length || createMode) && (
            <DetailsForm
              stylesTemplate={formWrapperStyles(createMode)}
              isFormSubmitted
              content={rateCardsDetails}
              onSubmit={(val) => onSubmit(val)}
              additionalActions={additionalActions}
              withoutSpinner
            />
          )}
          {!rateCards.length && !createMode && (
            <div className="rate-card-mangment__content">
              <Loader isLoaded={!isFetching}>
                <h2>
                  No data for selected period. Please, select another period or
                  create new data.
                </h2>
                {isCEO && (
                  <ActionButton
                    data="Create new"
                    onClick={() => setCreateMode({ createMode: !createMode })}
                    withBody
                    isActive={createMode}
                    cssRules={`
                      margin-top: 2.5rem;
                      ${addButtonStyles}
                  `}
                    className={createMode ? "active" : ""}
                    // disabled={createMode}
                  />
                )}
              </Loader>
            </div>
          )}
        </Wrapper>
      </Loader>
    </Fragment>
  );
};

RateCardmanagement.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  createMode: PropTypes.bool.isRequired,
  setCreateMode: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  isFileUploaded: PropTypes.bool.isRequired,
  uploadingProgress: PropTypes.number.isRequired,
  getRateCards: PropTypes.func.isRequired,
  rateCardsDetails: PropTypes.object.isRequired,
  rateCards: PropTypes.array.isRequired,
  setRateCardsActiveIndex: PropTypes.func.isRequired,
  activeRateCardIndex: PropTypes.number.isRequired,
  billingAgents: PropTypes.array.isRequired,
  createRateCardRecord: PropTypes.func.isRequired,
  deleteRateCardRecord: PropTypes.func.isRequired,
  updateRateCardRecord: PropTypes.func.isRequired,
  userGroup: PropTypes.string.isRequired,
  uploadFile: PropTypes.func.isRequired,
};

RateCardmanagement.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  rateCardsDetails: selectRateCardsDetails,
  rateCards: selectRateCards,
  activeRateCardIndex: selectRateCardsActiveIndex,
  createMode: selectCreateMode,
  billingAgents: selectBillingAgents,
  userGroup: selectUserGroup,
  uploadingProgress: selectUploadingProgress,
  isFileUploaded: selectIsFileUploaded,
  entityName: selectEntityName,
  // isFetchingFiles: selectIsFetchingFiles,
});

const mapDispatchToProps = {
  getRateCards: configurationsActions.getRateCards,
  setRateCardsActiveIndex: configurationsActions.setRateCardsActiveIndex,
  setCreateMode: configurationsActions.setCreateMode,
  createRateCardRecord: configurationsActions.createRateCardRecord,
  deleteRateCardRecord: configurationsActions.deleteRateCardRecord,
  updateRateCardRecord: configurationsActions.updateRateCardRecord,
  uploadFile: configurationsActions.uploadRatecardFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCardmanagement);
