import { colorSecondaryGrayLight } from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import {
  plLevelByIdForInfoCardRepresentation,
  workBookDescriptionCss,
} from 'layouts/employee-details/model/config/utils/workbook';

import moment from 'moment';
import { getHasPermissions } from 'utils/auth';

import { createEditRecordAction } from './createEditRecordAction';

import { getFormattedDate, getDevCenterShortName } from '../helpers';
import { generatePartialGridTemplateColumnsConfig, getCssGrids } from '../styles';

const { CTO, CEO, HR } = USERS_GROUPS;

export const STYLES = {
  TEXT_ALIGN_RIGHT: 'text-align: right;',
  LINE_HEIGHT: 'line-height: 1.6rem;',
  COLOR_SECONDARY_GRAY_LIGHT: `color: ${colorSecondaryGrayLight};`,
  ACTION_BUTTON_CSS: 'margin-top: 2rem; text-align: center;',
};

export const createDescriptionObject = (data, cssRules, additionalProperties = {}) => ({
  type: 'description',
  data,
  cssRules,
  ...additionalProperties,
});

export const createActionObject = (type, data, actionName, actionArguments, isHidden) => ({
  type,
  data,
  actionName,
  actionArguments,
  isHidden,
  cssRules: STYLES.ACTION_BUTTON_CSS,
});

export const getStylesForSalary = (salary) => `${workBookDescriptionCss} text-align: right; padding-right: 0.8rem; ${!salary && STYLES.COLOR_SECONDARY_GRAY_LIGHT}`;

export const generateSalaryObjects = (salaries, isHidden) => salaries.map((salary) => createDescriptionObject(
  salary || '--',
  `${getStylesForSalary(salary)}`,
  { isHidden }
));

export const TEXTS = {
  NO_REMARK: 'No remark',
  YES: 'Yes',
  NO: 'No',
};

export const contentCss = (hasPermissionsToUpdate, salaryHidden) => `
                display: grid;
                grid-template-columns: ${generatePartialGridTemplateColumnsConfig(hasPermissionsToUpdate, salaryHidden)};
                grid-column-gap: 0.8rem;
                padding: 0;
              `;

export const actionButtonCss = `
              display: grid;
              grid-area: controls;
              grid-template-columns: 1fr 1fr;
              grid-auto-rows: min-content;
              font-size: 1.2rem;
              line-height: 2.4rem;
              text-align: center;
            `;

export const createInfoCard = ({
  isInfoCard,
  dateHired,
  record,
  devcenters,
  hasPermissionsToUpdate,
  hasPermissionsToUpdateByBusinessRole,
  isHistoryRecord,
  additionalControlsConfig,
  deleteRecordAction,
  userGroup,
  isNew,
  isSalaryHidden,
}) => {
  const isCEO = getHasPermissions(userGroup, [CEO]);
  const isCTO = getHasPermissions(userGroup, [CTO]);
  const isHr = getHasPermissions(userGroup, [HR]);

  const { gridTemplateAreas, gridTemplateColumns } = getCssGrids(hasPermissionsToUpdate, isSalaryHidden);
  const {
    recordId,
    seniority,
    seniorityInt,
    probation,
    endOfProbation,
    salUsd,
    salPln,
    salRur,
    approvedCeo,
    validFrom,
    remarks,
    hrsDay,
    plLevel,
    devcenter,
    title,
  } = record;

  const isVisibleInfoCartActions = isCEO || (isHr && isNew) ? true : hasPermissionsToUpdateByBusinessRole && !isHistoryRecord && !isHr;
  const endOfProbationDisplayedDate = endOfProbation ? moment(endOfProbation).format('MM/DD/YYYY') : '--';
  return {
    isInfoCard,
    recordId: `${recordId}/${dateHired || ''}`,
    cssRules: `
            display: grid;
            grid-template-areas: ${gridTemplateAreas};
            grid-template-columns: ${gridTemplateColumns};
            grid-column-gap: 0.8rem;
            margin: 0.4rem 0 0;
            border-bottom: 1px solid #bbbdc0;
            background-color: background-color: transparent;
          `,
    content: [
      {
        data: [
          {
            data: [
              createDescriptionObject(
                getFormattedDate(validFrom),
                `${workBookDescriptionCss} margin-left: 1.6rem;`
              ),
              createDescriptionObject(
                getDevCenterShortName(devcenters, devcenter) || '--',
                workBookDescriptionCss
              ),
              createDescriptionObject(
                title,
                `${workBookDescriptionCss} padding-top: 2.4rem; ${STYLES.LINE_HEIGHT}`,
                { isMultiline: true }
              ),
              createDescriptionObject(seniority, workBookDescriptionCss),
              createDescriptionObject(seniorityInt || '--', workBookDescriptionCss),
              createDescriptionObject(
                plLevelByIdForInfoCardRepresentation[`${plLevel}`] || '--',
                workBookDescriptionCss
              ),
              createDescriptionObject(hrsDay, workBookDescriptionCss),
              createDescriptionObject(probation ? TEXTS.YES : TEXTS.NO, workBookDescriptionCss),
              createDescriptionObject(
                endOfProbationDisplayedDate,
                `${workBookDescriptionCss}padding-right: 0;`,
                {
                  title: endOfProbationDisplayedDate,
                }
              ),
              ...generateSalaryObjects([salUsd, salPln, salRur], !hasPermissionsToUpdate || isSalaryHidden),
            ],
            cssRules: contentCss(hasPermissionsToUpdate, isSalaryHidden),
          },
          {
            data: [
              createDescriptionObject(
                remarks || TEXTS.NO_REMARK,
                `${workBookDescriptionCss} ${STYLES.LINE_HEIGHT} letter-spacing: 0.15px; ${remarks ? '' : STYLES.COLOR_SECONDARY_GRAY_LIGHT}; opacity: ${remarks ? '0.8' : '1'}`,
                { isMultiline: true }
              ),
            ],
            cssRules: `
                margin: 0.3rem 0 0 1.6rem;
                padding: 0;
              `,
          },
        ],
        cssRules: `
              grid-area: record;
              padding-bottom: 0.8rem;
            `,
      },
      {
        data: [
          createActionObject(
            'actionButton',
            'Edit',
            isCTO && !approvedCeo ? 'toggleWorkBookRecordState' : 'openModal',
            createEditRecordAction(isCTO, approvedCeo, recordId, validFrom),
            !isVisibleInfoCartActions
          ),
          createActionObject('actionButton', 'Delete', 'openModal', deleteRecordAction, !isVisibleInfoCartActions),
        ],
        cssRules: actionButtonCss,
      },
      {
        data: additionalControlsConfig.content,
        cssRules: `${additionalControlsConfig.cssRules}`,
      },
    ],
  };
};
