import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Wrapper from 'elements/wrapper';
import GoTop from 'elements/go-top-button';
import Controls from 'components/list-controls';
import DetailsForm from 'components/details-form';
import SingleTable from 'components/details-table/single-table';

import { differenceWith, isEqual, findIndex } from 'lodash';
import { configurationsActions } from 'core/configurations/actions';
import { selectExchangeRatesManagementFormContent } from 'core/configurations/selectors';
import { autoScrollToTop } from 'utils/react';
import { commonActions } from 'core/common/actions';

import {
  exchangeRatesTableHeaderModel,
} from './model';

import {
  wrapperCssRules,
  formWrapperStylesTemplate,
  formMainControlsConfig,
  // tableRules,
} from './styles';


const ExchangeRatesManagement = ({
  location,
  entityName,
  isFetching,
  isFormSubmitted,
  getExchangeRates,
  updateExchangeRates,
  exchangeRatesManagementFormContent,
  changeOrder,
  orderRules,
}) => {
  autoScrollToTop(location);

  useEffect(() => {
    getExchangeRates();
  }, []);

  const onSubmit = ({ fields, initialValues }) => {
    const changedFields = differenceWith(fields.exchangeRates, initialValues.exchangeRates, isEqual);
    const updateData = changedFields.flatMap((field) => {
      const year = field.year;
      const initialValuesYearIndex = findIndex(initialValues.exchangeRates, { year });
      const initialValuesYearMonthData = initialValues.exchangeRates[initialValuesYearIndex].monthData;
      const changedMonthData = field.monthData.filter((month, index) => {
        // eslint-disable-next-line no-param-reassign
        month.year = year;
        return initialValuesYearMonthData[index].usdRur !== month.usdRur || initialValuesYearMonthData[index].usdPln !== month.usdPln;
      });
      return changedMonthData;
    });
    return updateExchangeRates({ fields: updateData });
  };
  const tableRules = `{
    position: sticky;
    top:6.6rem;
    z-index: 1;
  }`;

  return (
    <Fragment>

      <Controls
        title="Exchange Rates Management"
      />

      <Wrapper
        isGrow
        cssRules={wrapperCssRules}
      >
        <SingleTable
          entityName={entityName}
          data={[
            { id: 1,
              tableData: [],
              stylesTemplate: {
                rowStyles: `
                  &&{
                    margin:0;
                    border: none;
                    display: none;
                  }
                `,
              } },
          ]}
          model={exchangeRatesTableHeaderModel}
          orderRules={orderRules}
          changeOrder={changeOrder}
          tableName="exchangeRates"
          isFetching={false}
          withoutLoader
          controls={[]}
          cssRules={tableRules}
        />
        <DetailsForm
          withErrorBox
          formId="formId"
          entityName={entityName}
          onSubmit={onSubmit}
          isFormSubmitted={isFormSubmitted}
          isFetchingDetails={isFetching}
          content={exchangeRatesManagementFormContent}
          stylesTemplate={formWrapperStylesTemplate}
          mainControlsConfig={formMainControlsConfig}
          changeOrder={changeOrder}
          orderRules={orderRules}
        />
      </Wrapper>

      <GoTop />

    </Fragment>
  );
};


ExchangeRatesManagement.propTypes = {
  location: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  getExchangeRates: PropTypes.func.isRequired,
  updateExchangeRates: PropTypes.func.isRequired,
  exchangeRatesManagementFormContent: PropTypes.shape({

  }).isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
};

ExchangeRatesManagement.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  exchangeRatesManagementFormContent: selectExchangeRatesManagementFormContent,
});

const mapDispatchToProps = {
  getExchangeRates: configurationsActions.getExchangeRates,
  updateExchangeRates: configurationsActions.updateExchangeRates,
  changeOrder: commonActions.changeOrder,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExchangeRatesManagement);
