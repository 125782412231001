import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import className from 'classnames';
import { get } from 'lodash';

import Loader from 'components/loader';
import Overlay from 'elements/modal-overlay';

import ReviewCard from './review-card';

import './styles.scss';

const getUniq = (array = [], comparator = 'id') => {
  const results = [];
  const addedItems = {};
  array.forEach((item) => {
    const param = get(item, comparator);
    const hasAddded = get(addedItems, param, false);
    if (!hasAddded) {
      results.push(item);
      addedItems[param] = true;
    }
  });

  return results;
};

const ConfirmActionModal = ({
  clearModal,
  isProcessed,
  params,
}) => {
  const {
    resetForm,
    roleUpdates,
    addedReviews,
    submitReviews,
    blockedReviews,
    updatedReviews,
    deletedReviews,
    unlockedRecords,
  } = params;
  const [confirmedRoleUpdates, setConfirmedRoleUpdates] = useState(roleUpdates);
  const [confirmedReviewsToAdd, updateConfirmedReviewsToAdd] = useState(addedReviews);
  const [roleUpdatesToConfirm, setRoleUpdatesToConfirm] = useState(getUniq(roleUpdates));
  const [confirmedReviewsToUpdate, setConfirmedReviewsToUpdate] = useState(updatedReviews);
  const [confirmedReviewsToDelete, setConfirmedReviewsToDelete] = useState(deletedReviews);
  const [confirmedRecordsToBlock, updateConfirmedRecordsToBlock] = useState(blockedReviews);
  const [confirmedRecordsToUnlock, updateConfirmedRecordsToUnlock] = useState(unlockedRecords);
  const addedReviewsCount = addedReviews.length;

  const cancelUpdates = (removeOnPosition) => {
    setConfirmedReviewsToUpdate((reviews) => reviews.filter((_, index) => index !== removeOnPosition));
  };

  const cancelBlock = (cancelOnPosition, groupId) => {
    setConfirmedReviewsToDelete((reviews) => reviews.map((review, index) => {
      if (index === cancelOnPosition) {
        return null;
      }
      const { id, isToBlock, devstaffId, jiraProject, ...rest } = review;
      return id === groupId ? rest : review;
    }).filter((_) => _));
  };

  const cancelRemoving = (cancelOnPosition) => {
    setConfirmedReviewsToDelete((reviews) => reviews.filter((_, index) => index !== cancelOnPosition));
  };

  const revertRole = ({ id, initialRole }) => {
    const setInitialRole = (reviews) => reviews.map((review) => {
      const storedId = get(review, 'id');
      return storedId === id ? {
        ...review,
        role: initialRole,
        hasRoleChanged: false,
      } : review;
    });
    const filterByRolesById = (records) => records.filter(({ id: storedId }) => storedId !== id);
    setRoleUpdatesToConfirm(filterByRolesById);
    setConfirmedRoleUpdates(filterByRolesById);
    setConfirmedReviewsToUpdate(setInitialRole);
    updateConfirmedReviewsToAdd(setInitialRole);
  };

  const revertBlocking = ({ id }) => {
    updateConfirmedRecordsToBlock((records) => records.filter(({ id: storedId }) => storedId !== id));
  };
  const revertUnlocking = ({ id }) => {
    updateConfirmedRecordsToUnlock((records) => records.filter(({ id: storedId }) => storedId !== id));
  };

  const onCloseModal = () => {
    resetForm();
    clearModal();
  };

  const onConfirm = () => {
    submitReviews({
      roleUpdates: confirmedRoleUpdates,
      addedReviews: confirmedReviewsToAdd,
      blockedReviews: confirmedRecordsToBlock,
      updatedReviews: confirmedReviewsToUpdate,
      deletedReviews: confirmedReviewsToDelete,
      unlockedRecords: confirmedRecordsToUnlock,
    });
    clearModal();
  };

  useEffect(() => {
    const hasReviewsToSend = !!(
      confirmedRecordsToUnlock.length ||
      confirmedReviewsToUpdate.length ||
      confirmedReviewsToDelete.length ||
      confirmedRecordsToBlock.length ||
      confirmedRoleUpdates.length ||
      addedReviewsCount
    );
    if (!hasReviewsToSend) {
      onCloseModal();
    }
  }, [
    confirmedReviewsToUpdate,
    confirmedReviewsToDelete,
    confirmedRecordsToUnlock,
    confirmedRecordsToBlock,
    confirmedRoleUpdates,
  ]);

  return (
    <Overlay
      clearModal={onCloseModal}
      cssRules="
        width: 85vw;
        max-width: 114.4rem;
        max-height: calc(100vh - 10rem);
      "
    >
      <div className="update-reviews-modal__wrapper">
        <p className="update-reviews-modal__title">
          Confirm updates in reviews?
        </p>

        <p className="update-reviews-modal__description">
          Notifications about updates will be sent to Head of RM.
        </p>

        <div className="update-reviews-modal__content-modal">
          {
            !!addedReviewsCount && (
              <>
                <p className="update-reviews-modal__action-title">
                  New Reviews
                </p>

                <p className="update-reviews-modal__added-reviews">
                  {`${addedReviewsCount} review${addedReviewsCount > 1 ? 's' : ''} added`}
                </p>
              </>
            )
          }

          {
            (
              !!confirmedRecordsToUnlock.length ||
              !!confirmedReviewsToUpdate.length ||
              !!confirmedRecordsToBlock.length ||
              !!roleUpdatesToConfirm.length
            ) && (
                <>
                  <p className="update-reviews-modal__action-title">
                    Updated Reviews
                  </p>
                  <div>
                    {
                      confirmedRecordsToUnlock.map(({ id, initialRole, ...rest }) => (
                        <ReviewCard
                          key={id}
                          buttonTitle="Cancel unlocking"
                          onClick={() => revertUnlocking({ id })}
                          {...rest}
                        />
                      ))
                    }
                    {
                      confirmedRecordsToBlock.map(({ id, initialRole, ...rest }) => (
                        <ReviewCard
                          key={id}
                          buttonTitle="Revert blocking"
                          onClick={() => revertBlocking({ id })}
                          {...rest}
                        />
                      ))
                    }
                    {
                      roleUpdatesToConfirm.map(({ id, initialRole, ...rest }) => (
                        <ReviewCard
                          key={id}
                          buttonTitle="Revert role"
                          onClick={() => revertRole({ id, initialRole })}
                          {...rest}
                        />
                      ))
                    }
                    {
                      confirmedReviewsToUpdate.map(({ recordId, ...rest }, index) => (
                        <ReviewCard
                          key={recordId}
                          buttonTitle="Cancel updates"
                          onClick={() => cancelUpdates(index)}
                          {...rest}
                        />
                      ))
                    }
                  </div>
                </>
            )
          }
          {
            !!confirmedReviewsToDelete.length && (
              <>
                <p className="update-reviews-modal__action-title">
                  Deleted Reviews
                </p>
                {
                  confirmedReviewsToDelete.map((record, index) => {
                    const { id, recordId, isToBlock, ...rest } = record;
                    const onClick = () => isToBlock ?
                      cancelBlock(index, id) :
                      cancelRemoving(index);

                    return (
                      <ReviewCard
                        key={recordId}
                        buttonTitle="Revert"
                        onClick={onClick}
                        {...rest}
                      />
                    );
                  })
                }
              </>
            )
          }
        </div>

      </div>


      <div className="update-reviews-modal__controls">
        <button
          className={className(
            'update-reviews-modal__button  ',
            'button',
            'button--accept',
            { 'button--disabled': isProcessed },
            { 'button--with-loader': isProcessed }
          )}
          onClick={onConfirm}
        >
          <Loader
            isLoaded={!isProcessed}
          >
            confirm All
          </Loader>

        </button>

        <button
          className={className(
            'update-reviews-modal__button',
            'button',
            'button--cancel',
            { 'button--with-loader': isProcessed }
          )}
          onClick={onCloseModal}
        >
          Cancel
        </button>

      </div>

    </Overlay>
  );
};


ConfirmActionModal.propTypes = {
  isProcessed: PropTypes.bool,
  clearModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
  }).isRequired,
};

ConfirmActionModal.defaultProps = {
  isProcessed: false,
};


export default withRouter(ConfirmActionModal);
