import {
  colorWhite,
  colorPrimary,
  shadowButtons,
  backgroundButtons,
  colorSecondaryGrayLight,
} from 'assets/styles/variables';
import { taskOrdersControls } from 'core/auth/guaranteedAccessRoles';
import { getTOStatus } from 'layouts/client-details/utils';
import { getHasPermissions } from 'utils/auth';
import {
  formatDateMMDDYYYY,
  getIsExpired,
  getUnixTime,
} from 'utils/helpers/date';

const getButtonsStyles = ({ isLocked } = {}) => `
  && {
    display: block;
    margin-left: 2.4rem;
    padding: .8rem 3.2rem;
    border: 1px solid ${colorPrimary};
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    color: ${colorPrimary};
    background-color: initial;
    outline: none;
    cursor: pointer
    &:active,
    &:hover {
      border-color: ${colorPrimary};
      color: ${colorWhite};
      background: ${backgroundButtons};
      box-shadow: ${shadowButtons};
    }
    ${isLocked ? `
      color: ${colorWhite};
      background: ${colorSecondaryGrayLight};
      border-color: ${colorSecondaryGrayLight};
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.6;
    ` : ''}
  }
`;

export default ({
  isCTO,
  isDOA,
  clientId,
  userGroup,
  isClientActive,
  taskOrderToCopyId,
  isSignedMsaUploaded,
}) => isDOA ? [] : [{
  type: 'table',
  onSelectActionName: 'selectTaskOrderToCopy',
  tableCssRules: 'min-height: calc(100vh - 30rem);',
  controls: isClientActive && isSignedMsaUploaded && getHasPermissions(userGroup, taskOrdersControls) ? [{
    type: 'action',
    data: 'Create new',
    actionName: 'push',
    actionArguments: {
      pathname: '/task-orders/add',
      state: {
        clientId,
      },
    },
    cssRules: getButtonsStyles(),
  }, {
    type: 'action',
    data: 'Copy and create',
    actionName: 'push',
    actionArguments: {
      pathname: '/task-orders/add',
      state: {
        clientId,
        taskOrderToCopyId,
      },
    },
    cssRules: getButtonsStyles({
      isLocked: !taskOrderToCopyId,
    }),
  }] : [],
  content: [{
    title: 'Task Orders',
    idKey: 'projectId',
    dataKey: 'taskOrders',
    rowStatusGetter: ({
      projectId,
      title,
      isActive,
      endDate,
      isSigned,
      isManuallyDeactivated,
    }) => ({
      name: title,
      id: projectId,
      taskOrderId: projectId,
      isActive: isActive && !getIsExpired(endDate),
      hasWarning: ((isSigned && !isActive) || (!isSigned && !isActive)) && (!getIsExpired(endDate) && !isManuallyDeactivated),
    }),
    rules: {
      css: {
        gridTemplateConfig: 'grid-template-columns: minmax(auto, 91fr) minmax(auto, 326fr) minmax(auto, 59fr) minmax(auto, 129fr) minmax(auto, 120fr) minmax(auto, 120fr) minmax(auto, 102fr) minmax(auto, 261fr) minmax(auto, 58fr);',
      },
    },
    tableName: 'taskOrders',
    dataTemplate: [{
      columnName: 'TO #',
      valueGetter: ({
        projectId,
        taskOrderId,
      }) => ({
        type: 'link',
        value: taskOrderId,
        componentProps: {
          data: taskOrderId,
          pathname: `/task-orders/${projectId}/${isCTO ? 'staffing' : 'details'}`,
        },
      }),
    },
    {
      columnName: 'Project',
      valueGetter: ({
        title,
        projectId,
      }) => ({
        type: 'link',
        value: title,
        componentProps: {
          data: title,
          pathname: `/task-orders/${projectId}/${isCTO ? 'staffing' : 'details'}`,
        },
      }),
    },
    {
      columnName: 'Type',
      valueGetter: ({
        projectType,
      }) => ({
        value: projectType,
        type: 'text',
      }),
    },
    {
      columnName: 'Rates',
      valueGetter: ({
        isSelfRatecardChanged,
        isClientRatecardChanged,
      }) => {
        let ratesState = 'Default Rates';

        if (isClientRatecardChanged) {
          ratesState = 'Customized Client';
        }

        if (isSelfRatecardChanged) {
          ratesState = 'Customized Project';
        }

        return {
          value: ratesState,
          type: 'text',
        };
      },
    },
    {
      columnName: 'Effective Date',
      valueGetter: ({
        effectiveDate,
      }) => ({
        isDate: true,
        value: effectiveDate ? formatDateMMDDYYYY(effectiveDate) : '',
        unixValue: getUnixTime(effectiveDate),
        type: 'text',
      }),
    },
    {
      columnName: 'Expiration Date',
      valueGetter: ({
        endDate,
      }) => ({
        isDate: true,
        value: endDate ? formatDateMMDDYYYY(endDate) : '',
        unixValue: getUnixTime(endDate),
        type: 'text',
      }),
    },
    {
      columnName: 'status',
      valueGetter: (tackOrderData) => {
        const status = getTOStatus(tackOrderData);

        return {
          value: status,
          type: 'text',
        };
      },
    },
    {
      columnName: 'Client Category Assignments',
      valueGetter: ({
        categoryKeys,
      }) => {
        const value = categoryKeys ? categoryKeys.join(', ') : '';

        return {
          value,
          type: 'text',
          componentProps: {
            data: value,
            isMultiline: true,
          },
        };
      },
    },
    {
      columnName: 'Staffing',
      withoutControls: true,
      valueGetter: ({
        projectId,
      }) => ({
        value: 'Staffing',
        type: 'link',
        componentProps: {
          data: 'Staffing',
          pathname: `/task-orders/${projectId}/staffing`,
          cssRules: 'font-size: 1.2rem;',
        },
      }),
    },
    ],
  }],
}];
