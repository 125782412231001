import React, { useCallback, useEffect } from 'react';

import { connect, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import {
  colorPrimary,
  colorWhite,
} from 'assets/styles/variables';
import Filter from 'components/filter';
import Controls from 'components/list-controls';
import ListFooter from 'components/list-footer';
import EmployeesTable from 'components/table';
import { createResourceAccess } from 'core/auth/guaranteedAccessRoles';
import { deliveryActions } from 'core/delivery/actions';
import { ENTITY_NAME_EMPLOYEES } from 'core/delivery/constants';
import { filesActions } from 'core/files/actions';
import GoTop from 'elements/go-top-button';
import Wrapper from 'elements/wrapper';
import compose from 'lodash/fp/compose';
import moment from 'moment';
import { withRouter } from 'react-router';
import { autoScrollToTop } from 'utils/react';

const controlButtonStyles = (hasPermissionsToCreateResource) => `
    && {
      ${!hasPermissionsToCreateResource ? 'margin-left: 1.6rem;' : ''}
      width: 100%;
      padding: 0.7rem 1.1rem;
      font-size: 1.4rem;
      line-height: 1.6rem;
      text-align: center;

      color: ${colorPrimary};
      border-color: ${colorPrimary};
      background-color: transparent;
      &:hover {
        color: ${colorWhite};
        background-color: ${colorPrimary};
      }
    }
  `;

const EmployeesList = ({
  location,
  employeesListModel,
  employeeListFilterConfig,
  changeFilter,
  hasEmployeeActiveFilters,
  resetFilters,
  toggleFavorite,
  changeOrder,
  orderRules,
  employeesData,
  getEmployeesList,
  isFetchingEmployeesList,
  employeesCount,
  hasPermissionsToPayrollReports,
  hasPermissionsToCreateResource,
  isFiltering,
  entityName,
  generateCurrentPayrollReport,
}) => {
  const dispatch = useDispatch();
  autoScrollToTop(location);

  const { rules: { css }, tableName, currentFilter } = employeesListModel;
  const currentOrderRules = orderRules[tableName];

  const setOrder = useCallback(({ orderBy }) => changeOrder({ orderBy, tableName, entityName }), [tableName, entityName]);
  const onFilterChange = (filterConfig) => changeFilter({ currentFilter, ...filterConfig });
  const onFiltersReset = () => resetFilters({ currentFilter });
  const entityToggleFavorite = ({ ...args }) => toggleFavorite({ entityName: ENTITY_NAME_EMPLOYEES, ...args });

  const generateResourceBillingReport = ({ devstaffId }) => {
    dispatch(deliveryActions.generateResourceBillingReport({ devstaffId }));
  };

  useEffect(() => {
    getEmployeesList();

    return () => {
      // We are going to reset filters on layout unmount.
      onFiltersReset();
    };
  }, []);
  return (
    <>

      <Controls
        title="Delivery Center Staff"
        hasAlphabetFilter={false}
        route="delivery/employees-list"
        controlsList={[
          {
            route: createResourceAccess.route,
            hasPermissions: hasPermissionsToCreateResource,
            isHidden: !hasPermissionsToCreateResource,
          },
          {
            title: 'Payroll Report',
            type: 'action',
            data: 'Payroll Report',
            withBody: true,
            onClick: () => generateCurrentPayrollReport({
              year: moment().year(),
              month: moment().month() + 1,
            }),
            hasPermissions: hasPermissionsToPayrollReports,
            isHidden: !hasPermissionsToPayrollReports,
            cssRules: controlButtonStyles(hasPermissionsToCreateResource),
          },
        ]}
        hasActiveFilters={hasEmployeeActiveFilters}
        resetFilters={onFiltersReset}
      >
        <Filter
          cssRules={css}
          filterConfig={employeeListFilterConfig}
          changeFilter={onFilterChange}
        />
      </Controls>

      <Wrapper
        isGrow
      >

        <EmployeesTable
          entityName="Employees"
          withInfiniteScroll
          data={employeesData}
          model={employeesListModel}
          toggleFavorite={entityToggleFavorite}
          changeOrder={setOrder}
          additionalAction={generateResourceBillingReport}
          orderRules={currentOrderRules}
          isLoaded={!isFetchingEmployeesList && !isFiltering}
          withFilter={hasEmployeeActiveFilters}
        />

      </Wrapper>

      <ListFooter
        filteredItemsCount={employeesData.length}
        itemsCount={employeesCount}
        isFetchingItems={isFetchingEmployeesList}
      />

      <GoTop />

    </>
  );
};

EmployeesList.propTypes = {
  location: PropTypes.object.isRequired,
  entityName: PropTypes.string.isRequired,
  hasPermissionsToPayrollReports: PropTypes.bool.isRequired,
  hasPermissionsToCreateResource: PropTypes.bool.isRequired,
  employeesListModel: PropTypes.shape({
    rules: PropTypes.shape({
      css: PropTypes.shape({}),
    }),
    tableName: PropTypes.string,
    currentFilter: PropTypes.string,
  }).isRequired,
  employeesData: PropTypes.arrayOf(PropTypes.shape({
    tableData: PropTypes.arrayOf(PropTypes.object),
  })).isRequired,
  isFiltering: PropTypes.bool.isRequired,
  isFetchingEmployeesList: PropTypes.bool.isRequired,
  employeesCount: PropTypes.number.isRequired,
  getEmployeesList: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({
  }).isRequired,
  changeOrder: PropTypes.func.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  hasEmployeeActiveFilters: PropTypes.bool.isRequired,
  changeFilter: PropTypes.func.isRequired,
  generateCurrentPayrollReport: PropTypes.func.isRequired,
  employeeListFilterConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapDispatchToProps = {
  generateCurrentPayrollReport: filesActions.generateCurrentPayrollReport,
};

export default compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps
  ),
)(EmployeesList);
