import { USERS_GROUPS } from 'core/auth/constants';

export const getBusinessRoles = (roles = [], devCenterId) => {
  const result = [];
  roles.forEach((item) => {
    if (item.devcenterList.includes(devCenterId)) {
      result.push(USERS_GROUPS[item.role]);
    }
  });
  return result;
};
