export const FORM_MODES = {
  VIEW: 'view',
  EDIT: 'edit',
  CREATE: 'create',
};

export const defaultErrorMessage = 'Please enter valid email addresses separated by commas for the following clients:';

export const onlyLatinLettersRegex = /^[A-Za-z\s]+$/;
export const phoneRegex = /^(?!.*[\u0400-\u04FF])[^\p{L}]*$/u;

export const validationMessages = {
  required: 'Required field',
  email: 'Invalid email format: * @ * . *',
  onlyLatinLetters: 'Only Latin letters are allowed',
  phone: 'No letters are allowed',
};
