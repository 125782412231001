import {
  wbDataOnDashboard,
  ptoDataOnDashboard,
  taskOrdersTableOnDashboard,
} from 'core/auth/guaranteedAccessRoles';
import { waitForAuthorization } from 'core/auth/sagas';
import { selectUserGroup } from 'core/auth/selectors';
import hash from 'hash-sum';
import { get, has } from 'lodash';
import { toast } from 'react-toastify';
import {
  put,
  select,
  takeLatest,
  call,
} from 'redux-saga/effects';

import { getHasPermissions } from 'utils/auth';
import { combineDevCenters } from 'utils/helpers/combineDevCenters';
import {
  executeMutation,
  executeQuery,
  parseError,
} from 'utils/request';

import {
  dashboardActions,
  dashboardActionsTypes,
} from './actions';
import { queryConfig } from './queries';
import {
  selectErrors,
  selectEntityName,
} from './selectors';

function* getDashboardData() {
  try {
    const userGroup = yield select(selectUserGroup);
    const hasAccessToTaskOrderTableOnDashboard = getHasPermissions(userGroup, taskOrdersTableOnDashboard);
    const hasAccessToWBDataOnDashboard = getHasPermissions(userGroup, wbDataOnDashboard);
    const hasAccessToPTODataOnDashboard = getHasPermissions(userGroup, ptoDataOnDashboard);
    const query = queryConfig.getDashboardData({
      hasAccessToWBDataOnDashboard,
      hasAccessToPTODataOnDashboard,
      hasAccessToTaskOrderTableOnDashboard,
    });
    const options = {
      query,
    };
    const data = yield call(executeQuery, options);

    const dashboardPtoRequestRecords = get(data, 'dashboardPtoRequestRecords', []);
    const resourceSeniorityUpdate = get(data, 'resourceSeniorityUpdate', []);
    return yield put(dashboardActions.getDashboardDataSuccess({ dashboardData: {
      ...data,
      devcentersAll: combineDevCenters(get(data, 'devcentersAll', [])),
      dashboardSeniorityUpdateRecords: resourceSeniorityUpdate,
      dashboardPtoRequestRecords: dashboardPtoRequestRecords.map((record) => {
        const plApprovals = get(record, 'plApprovals', []);

        return {
          ...record,
          plApprovals,
        };
      }),
    } }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getDashboardDataError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getDashboardDataError = yield call(parseError, error, options);

    return yield put(dashboardActions.getDashboardDataFail({
      error: {
        getDashboardDataError,
      },
    }));
  }
}

function* deleteWorkbookRecord({
  payload: {
    devstaffId,
    recordId,
  },
}) {
  try {
    const mutation = queryConfig.deleteWorkbookRecord({
      devstaffId,
      recordId,
    });

    const options = {
      mutation,
    };

    yield call(executeMutation, options);

    return yield put(dashboardActions.deleteWorkbookRecordSuccess({
      recordId,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);

    if (has(errors, 'deleteWorkbookRecordError')) {
      toast.dismiss(get(errors, 'deleteWorkbookRecordError'));
    }

    const deleteWorkbookRecordError = get(error, 'extensions.detailed', 'Something went wrong!');

    const toastId = Math.abs(hash(deleteWorkbookRecordError));

    toast.error(deleteWorkbookRecordError, {
      toastId,
      containerId: 'confirmActionModal',
    });

    return yield put(dashboardActions.deleteWorkbookRecordFail({
      error: {
        deleteWorkbookRecordError: toastId,
      },
    }));
  }
}

function* deleteWorkbookRecordWatcher() {
  yield takeLatest(dashboardActionsTypes.DELETE_WORKBOOK_RECORD, waitForAuthorization(deleteWorkbookRecord));
}

function* getDashboardDataWatcher() {
  yield takeLatest(dashboardActionsTypes.GET_DASHBOARD_DATA, waitForAuthorization(getDashboardData));
}

export default [
  getDashboardDataWatcher,
  deleteWorkbookRecordWatcher,
];
