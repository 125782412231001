import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Fit from 'react-fit';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import styled, { css } from 'styled-components';

import StarIcon from 'elements/star';
import Text from 'elements/plain-text';
import CustomLink from 'elements/link';
import Search from 'components/search';
import Checkbox from 'elements/checkbox';
import Select from 'components/filter-select';
import OrderButton from 'elements/order-button';
import StartsRating from 'elements/stars-rating';
import ActionButton from 'elements/action-button';
import RangeFilter from 'components/filter-range';
import Switcher from 'components/form-controls/form-switcher';
import GroupController from 'components/expansion-panel/group-control-button';

import MenuIcon from 'assets/img/icon-menu.svg';

import {
  unsubscribeFromActions,
  subscribeToActions,
} from 'utils/helpers/events';

import {
  shadowBlock,
  colorSecondaryGrayDark,
} from 'assets/styles/variables';


const units = {
  groupController: GroupController,
  orderButton: OrderButton,
  action: ActionButton,
  rating: StartsRating,
  switcher: Switcher,
  favorite: StarIcon,
  checkbox: Checkbox,
  range: RangeFilter,
  toggle: StarIcon,
  link: CustomLink,
  select: Select,
  search: Search,
  text: Text,
};


const StyledContainer = styled.div`
  margin-top: 1.6rem;

  &.dropdown--opened {
    position: relative;
    background-color: ${colorSecondaryGrayDark};
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 3px 3px rgba(0, 0, 0, 0.14);

    &::before,
    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 6px;
      background-color: inherit;
      z-index: 2;
    }
    &::before {
      top: 0;
      bottom: unset;
    }
  }

  ${({ cssRules }) => css`${cssRules}`}
`;

const StyledOpenButton = styled.button`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  ${({ cssRules }) => css`${cssRules}`}
`;

const StyledExpandSection = styled.div`
  display: grid;
  grid-row-gap: 1.6rem;
  box-sizing: border-box;
  padding: 1.6rem;
  background-color: ${colorSecondaryGrayDark};
  box-shadow: ${shadowBlock};
  z-index: 1;
  ${({ cssRules }) => css`${cssRules}`}
`;


const Dropdown = ({
  iconSrc,
  onClick,
  actions,
  cssRules,
  controls,
  openButtonCssRules,
  expandSectionCssRules,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdown = useRef(null);

  useEffect(() => () => {
    unsubscribeFromActions(onOutsideAction);
  }, []);

  const openDropdown = () => {
    setIsOpen(true);
    subscribeToActions(onOutsideAction);
  };

  const closeDropdown = () => {
    setIsOpen(false);
    unsubscribeFromActions(onOutsideAction);
  };

  const toggleDropdown = () => {
    if (isOpen) {
      closeDropdown();
    } else {
      openDropdown();
    }
  };
  const onOutsideAction = ({ target }) => {
    const { current } = dropdown;

    if (current && !current.contains(target)) {
      closeDropdown();
    }
  };

  return (
    <StyledContainer
      ref={dropdown}
      className={classNames(
        'dropdown',
        { 'dropdown--opened': isOpen }
      )}
      cssRules={cssRules}
    >
      <StyledOpenButton
        className="dropdown__toggle-button"
        type="button"
        onClick={toggleDropdown}
        cssRules={openButtonCssRules}
      >
        <SVG
          src={iconSrc}
        />
      </StyledOpenButton>
      {
        isOpen && (
          <Fit>
            <StyledExpandSection
              className={classNames(
                'dropdown__expand-section',
                { 'dropdown__expand-section--opened': isOpen }
              )}
              cssRules={expandSectionCssRules}
            >
              {
                controls.map((control, index) => {
                  if (control) {
                    const {
                      type,
                      title,
                      actionArguments = {},
                      ...rest
                    } = control;
                    const action = () => {
                      closeDropdown();
                      onClick({
                        actions,
                        actionArguments,
                        ...actionArguments,
                        ...rest,
                      });
                    };
                    return (
                      React.createElement(
                        units[type],
                        {
                          // eslint-disable-next-line react/no-array-index-key
                          key: index,
                          onClick: action,
                          ...rest,
                        },
                      )

                    );
                  }
                  return null;
                })
              }
            </StyledExpandSection>

          </Fit>
        )
      }
    </StyledContainer>
  );
};

Dropdown.propTypes = {
  iconSrc: PropTypes.string,
  cssRules: PropTypes.string,
  actions: PropTypes.shape({}),
  onClick: PropTypes.func.isRequired,
  openButtonCssRules: PropTypes.string,
  expandSectionCssRules: PropTypes.string,
  controls: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({

    }),
    PropTypes.bool,
  ])).isRequired,
};

Dropdown.defaultProps = {
  actions: {},
  cssRules: '',
  iconSrc: MenuIcon,
  openButtonCssRules: '',
  expandSectionCssRules: '',
};

export default Dropdown;
