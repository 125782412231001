import React, { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ActionButton } from 'components/buttons';
import TableFilters from 'components/form-with-list/components/filters';

import { configurationsActions } from 'core/configurations/actions';
import {
  selectIsActiveFilters,
  selectEmailTemplateNotificationsFilter,
} from 'core/configurations/email-template-notifications/selectors';

import commonStyles from 'layouts/email-template-notifications/styles.module.scss';

import styles from './styles.module.scss';
import { useFilterItems } from './useFilterItems';

const EmailTemplateNotificationsTableFilters = () => {
  const hasActiveFilters = useSelector(selectIsActiveFilters);
  const dispatch = useDispatch();
  const { filterItems } = useFilterItems();

  const memoizedFilterItems = useMemo(() => filterItems.map((filterItem) => (
    <TableFilters.Filter
      key={filterItem.name}
      filterItem={filterItem}
      className={commonStyles[filterItem.name]}
    />
  )), [filterItems]);

  return (
    <div data-testid="Table Filters Container" className={styles.tableFiltersContainer}>
      <div data-testid="Clear Filter" className={styles.clearFilter}>
        {
          hasActiveFilters && (
            <ActionButton
              type="button"
              dataId="clearFilter"
              title="Clear Filters"
              className={styles.clearFilterButton}
              onClick={() => {
                dispatch(configurationsActions.clearNotificationsFilter());
              }}
            >
              Clear Filters
            </ActionButton>
          )
        }
      </div>
      <TableFilters
        className={styles.tableFilters}
        clearFiltersAction={configurationsActions.clearNotificationsFilter}
        selector={selectEmailTemplateNotificationsFilter}
      >
        {memoizedFilterItems}
      </TableFilters>
    </div>
  );
};

export default EmailTemplateNotificationsTableFilters;
