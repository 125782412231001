import React from 'react';

import CustomLink from 'elements/link';
import Text from 'elements/plain-text';
import { getEndOfProbationErrorRows } from 'layouts/resource-management-report/config/heplers/errorRows';
import moment from 'moment';

import { stringSortIgnoreCase } from './heplers/stringSortIgnoreCase';

export const endOfProbation = (data, {
  locales,
  showResourceLinkByDevCenter,
  isCTO,
  availableDevCenters,
  hasPermissions,
}) => ({
  id: 'endOfProbation',
  title: locales.endOfProbationRows.title,
  columns: [
    {
      Header: locales.endOfProbationRows.title,
      accessor: 'endOfProbationDate',
      Cell: (cellInfo) => moment(cellInfo.row.original.endOfProbationDate).format('MM/DD/YYYY'),
    },
    {
      Header: locales.resource,
      accessor: 'fullname',
      Cell: (cellInfo) => {
        const { row: { original: { devstaffId, fullname, devcenterId } } } = cellInfo;

        if (showResourceLinkByDevCenter(isCTO, hasPermissions, availableDevCenters, devcenterId)) {
          return (
            <CustomLink
              pathname={`/delivery/employee/${devstaffId}/work-book`}
              id={devstaffId}
              data={fullname}
            />
          );
        }
        return <Text data={fullname} />;
      },
      sortType: stringSortIgnoreCase,
    },
    {
      Header: locales.deliveryCenter,
      accessor: 'devcenter',
    },
  ],
  data,
  additionalClass: 'tableSmall',
  isEmpty: data.length === 0,
  errorRows: getEndOfProbationErrorRows(data),
});
