import React, { useState, useEffect, memo, useCallback } from 'react';

import PropTypes from 'prop-types';

import { isSomeParentTouched } from 'components/form-controls/utils';
import Select from 'components/selectNew';
import { Field } from 'formik';
import { emptySelectValue } from 'layouts/employee-details/model/config/utils/workbook';
import { isEqual } from 'lodash';

const FormSelectNew = memo(({
  name,
  error,
  isMulti,
  touched,
  cssRules,
  isLocked,
  onChange,
  formValue,
  fieldData,
  withLabel,
  isTouched,
  placeholder,
  isCreatable,
  formValues,
  parentField,
  withErrorBox,
  menuPosition,
  validationRules,
  parentFieldsData,
  defaultMenuIsOpen,
}) => {
  const [selectData, setSelectData] = useState(fieldData);

  const shouldSetNewSelectData = useCallback((calculatedValue) => {
    if (calculatedValue && !isEqual(calculatedValue, selectData)) {
      const { selected = {} } = calculatedValue;
      const { value } = selected;

      setSelectData(calculatedValue);

      if (formValue !== value) {
        onChange(value);
      }
    }
  }, [selectData, formValue, selectData, onChange]);

  useEffect(() => {
    if (parentField) {
      const { strictDependence, valueGetter, name: parentFieldName } = parentField;

      if (isSomeParentTouched(touched, parentFieldName) || strictDependence) {
        const calculatedValue = valueGetter(formValues, fieldData);
        shouldSetNewSelectData(calculatedValue);
      } else if (!isEqual(selectData, fieldData)) {
        setSelectData(fieldData);
      }
    }
  }, [parentFieldsData, formValues, fieldData, parentField]);

  return (
    <Field
      type="text"
      name={name}
      render={() => (
        <Select
          name={name}
          error={error}
          isMulti={isMulti}
          onChange={onChange}
          isLocked={isLocked}
          cssRules={cssRules}
          withLabel={withLabel}
          fieldData={{
            ...selectData,
            selected: formValue ? {
              value: formValue,
              label: formValue,
            } : emptySelectValue,
          }}
          placeholder={placeholder}
          isCreatable={isCreatable}
          withErrorBox={withErrorBox}
          menuPosition={menuPosition}
          defaultMenuIsOpen={defaultMenuIsOpen}
          withError={!!(error && isTouched)}
          validationRules={validationRules}
          withWarning={fieldData.withWarning}
        />
      )
      }
    />
  );
});


FormSelectNew.propTypes = {
  isCreatable: PropTypes.bool,
  withErrorBox: PropTypes.bool,
  isMulti: PropTypes.bool,
  isLocked: PropTypes.bool,
  validationRules: PropTypes.shape({
    isNumeric: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
  formValue: PropTypes.any,
  touched: PropTypes.any,
  formValues: PropTypes.any,
  error: PropTypes.string,
  isTouched: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  parentFieldsData: PropTypes.array,
  fieldData: PropTypes.shape({
    isLink: PropTypes.bool,
    path: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })),
    selected: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      })),
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ]),
  }),
  cssRules: PropTypes.string,
  parentField: PropTypes.shape({}),
  withLabel: PropTypes.bool,
  menuPosition: PropTypes.string,
  defaultMenuIsOpen: PropTypes.bool,
};

FormSelectNew.defaultProps = {
  isCreatable: false,
  withErrorBox: false,
  isMulti: false,
  touched: null,
  parentFieldsData: null,
  formValue: '',
  isLocked: false,
  placeholder: '',
  formValues: '',
  validationRules: {},
  fieldData: {
    selected: {
      value: '',
      label: '',
    },
    items: [],
  },
  onChange: () => null,
  error: '',
  isTouched: false,
  cssRules: '',
  parentField: {
    name: [],
  },
  withLabel: true,
  menuPosition: 'absolute',
  defaultMenuIsOpen: false,
};

FormSelectNew.displayName = 'FormSelect';

export default FormSelectNew;
