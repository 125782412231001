import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import Calendar from 'components/calendar';
import ErrorMessage from 'elements/error-message';
import { Field } from 'formik';
import { useEffectOnlyOnUpdate } from 'hooks';
import moment from 'moment';

const DatePicker = ({
  name,
  error,
  isLocked,
  formValue,
  isTouched,
  placeholder,
  withErrorBox,
  parentField,
  values,
  setFieldValue,
  ...rest
}) => {
  const deps = parentField ? parentField.name.map((key) => values[key]) : [];
  const hasError = Boolean(error);
  const withError = hasError && isTouched;
  const value = useMemo(() => formValue ? moment.parseZone(formValue, 'YYYY-MM-DD') : formValue, [formValue]);

  useEffectOnlyOnUpdate(() => {
    if (!parentField) return;

    const valueGetter = parentField.valueGetter;
    const result = valueGetter ? valueGetter(values, parentField) : value;
    setFieldValue(name, result);
  }, deps);

  return (
    <div className="form-date-picker">
      <Calendar
        {...rest}
        isLocked={isLocked}
        withError={withError}
        value={value}
      />
      {
        placeholder && (
          <label
            className={classNames(
              'form-date-picker__label',
              isLocked && 'form-date-picker__label--locked'
            )}
            htmlFor={name}
          >
            {placeholder}
          </label>
        )
      }
      {
        withError && !withErrorBox && (
          <ErrorMessage visible={withError}>
            {error}
          </ErrorMessage>
        )
      }
    </div>
  );
};

const FormCalendar = ({ name, error, ...rest }) => (
  <Field
    type="datePicker"
    name={name}
    render={({ form: { setFieldValue, values } }) => (
      <DatePicker
        {...rest}
        name={name}
        values={values}
        setFieldValue={setFieldValue}
        error={error}
      />
    )}
  />
);

FormCalendar.propTypes = {
  name: PropTypes.string,
  parentField: PropTypes.shape({}),
  error: PropTypes.string,
};

FormCalendar.defaultProps = {
  name: null,
  parentField: null,
  error: null,
};

DatePicker.propTypes = {
  name: PropTypes.string,
  error: PropTypes.string,
  isLocked: PropTypes.bool,
  isFilter: PropTypes.bool,
  onChange: PropTypes.func,
  withClear: PropTypes.bool,
  isTouched: PropTypes.bool,
  formValue: PropTypes.string,
  fieldData: PropTypes.string,
  withErrorBox: PropTypes.bool,
  placeholder: PropTypes.string,
  values: PropTypes.shape({}),
  parentField: PropTypes.shape({}),
  setFieldValue: PropTypes.func,
};

DatePicker.defaultProps = {
  name: null,
  error: '',
  formValue: '',
  fieldData: null,
  isLocked: false,
  isFilter: false,
  isTouched: false,
  withClear: false,
  placeholder: null,
  onChange: () => null,
  withErrorBox: false,
  values: null,
  parentField: null,
  setFieldValue: null,
};

export default FormCalendar;
