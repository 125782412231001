import React from 'react';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import './styles.scss';

const StyledWrapper = styled.div`
  ${({ cssRules }) => css`${cssRules}`}
`;
const StyledLink = styled(({ cssRules, isMultiline, ...restProps }) => <Link {...restProps} />)`
  padding-right: 1rem;
  font-size: 1.3rem;
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
  transition: all 0.5s;
  cursor: pointer;
  &:focus,
  &:hover {
    text-decoration: none;
  }

  ${({ isMultiline }) => isMultiline ? `
    height: 100%;
    white-space: normal;` : ''}

  ${({ cssRules }) => css`${cssRules}`}
`;

const StyledA = styled.a`
  padding-right: 1rem;
  font-size: 1.3rem;
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
  transition: all 0.5s;
  cursor: pointer;
  &:focus,
  &:hover {
    text-decoration: none;
  }

  ${({ multiline }) => multiline ? `
    height: 100%;
    white-space: normal;` : ''}

  ${({ cssRules }) => css`${cssRules}`}
`;

const StyledContent = styled.p`
  width: 100%;
  padding-right: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ hasOverflow }) => hasOverflow ? `
    &:hover {
      &:after {
        position: absolute;
        left: 1ch;
        top: 1em;
        padding: 0.2rem 0.4rem;
        color: rgba(0, 0, 0, 0.87);
        content: attr(data-value);
        z-index: 1;
        opacity: 0.7;
      }
    }` : ''}

  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;


const CustomLink = ({
  data,
  href,
  state,
  title,
  imgSrc,
  pathname,
  cssRules,
  withBlank,
  isMultiline,
  additionalData,
  wrapperCssRules,
}) => (
  <StyledWrapper
    className="custom-link"
    cssRules={wrapperCssRules}
  >
    {
      href ?
        <StyledA
          href={href}
          title={title}
          target={withBlank ? '_blank' : '_self'}
          cssRules={cssRules}
          multiline={isMultiline}
        >
          {data}
          {
            imgSrc &&
            <img
              className="customLink__icon"
              src={imgSrc}
              alt="calendar icon"
            />
          }
        </StyledA> :

        <StyledLink
          href={href}
          title={title}
          cssRules={cssRules}
          isMultiline={isMultiline}
          target={withBlank ? '_blank' : undefined}
          to={{
            pathname,
            state,
          }}
        >
          {data}
          {
            imgSrc &&
            <img
              className="customLink__icon"
              src={imgSrc}
              alt="calendar icon"
            />
          }
        </StyledLink>
    }
    {
      additionalData &&
      additionalData.map(({ cssRules: additionalDataCssRules, content }, index) => (
        <StyledContent
          key={index} // eslint-disable-line react/no-array-index-key
          cssRules={additionalDataCssRules}
        >
          {content}
        </StyledContent>
      ))
    }
  </StyledWrapper>
);


CustomLink.propTypes = {
  state: PropTypes.shape({}),
  additionalData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      cssRules: PropTypes.string,
    }),
  ])),
  isMultiline: PropTypes.bool,
  cssRules: PropTypes.string,
  wrapperCssRules: PropTypes.string,
  withBlank: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  pathname: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  imgSrc: PropTypes.string,
};

CustomLink.defaultProps = {
  additionalData: [],
  wrapperCssRules: '',
  state: undefined,
  isMultiline: false,
  data: '',
  title: '',
  cssRules: '',
  withBlank: false,
  pathname: '',
  href: '',
  imgSrc: '',
};


export default CustomLink;
