import { colorPrimary } from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import {
  clientsModuleAccess,
  taskOrderModuleAccess,
  deliveryRootModuleAccess,
} from 'core/auth/guaranteedAccessRoles';
import { getHasPermissions } from 'utils/auth';

import { filtersTemplates } from './config/filters';
import projectLeadCommissionTabGetter from './project-lead-commission-tab';

const { CTO } = USERS_GROUPS;

export const projectLeadsCommissionModelGetter = (userGroup, {
  groupedPlCommissionReportRows = {},
  isEmptyRows,
  reportCalculationDate,
}) => {
  const isCTO = getHasPermissions(userGroup, [CTO]);
  const hasPermissionsTODeliveryModule = getHasPermissions(userGroup, deliveryRootModuleAccess);
  const hasPermissionsTOClientModule = getHasPermissions(userGroup, clientsModuleAccess);
  const hasPermissionsTOTaskOrderModule = getHasPermissions(userGroup, taskOrderModuleAccess);

  return {
    isSingleTab: true,
    filtersTemplates,
    additionalDataGetter: () => ({
      controlsHeading: 'Project Lead Commission Report',
      controls: !isEmptyRows ? [{
        title: 'PDF',
        actionName: 'generateProjectLeadCommissionPDFReport',
      }, {
        title: 'excel',
        actionName: 'generateProjectLeadCommissionReport',
      }] : [],
      calendarItemCssRules: `
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.4rem;
        white-space: nowrap;
        color:  ${colorPrimary};
      `,
      calendarCssRules: `
        && {
          display: flex;
          min-width: unset;
          align-items: center;
          margin-right: 1.6rem;
          padding-top: 0;
          border: none;
          & path {
            fill: ${colorPrimary};
          }
        }
        && .calendar__toggle-button {
          padding-top: 0.3rem;
        }
      `,
      controlsCssRules: `
        &&& {
          display: grid;
          grid-template-columns: 1fr ${!isEmptyRows ? 'minmax(auto, 18.4rem) minmax(auto, 18.4rem)' : ''};
          grid-row-gap: 0.8rem;
          grid-auto-rows: minmax(3.2rem, auto);
          flex-direction: column;
          padding-top: 0.8rem;
          padding-bottom: 1.6rem;
          font-size: 1.5rem;
        }
      `,
      controlsWrapperCssRules: 'display: flex;',
      selectCssRules: `
        && {
          color: ${colorPrimary};
          .filter-select {
            width: auto;
            height: auto;
            &__multi-value__label {
              color: ${colorPrimary};
            }
            &__placeholder {
              padding-left: 0;
              color: ${colorPrimary};
            }
            &__control--is-focused {
              outline: none !important;
            }
            &__dropdown-indicator {
              path {
                fill: ${colorPrimary};
              }
            }
          }
        }
      `,
    }),
    dataTemplate: projectLeadCommissionTabGetter({
      isCTO,
      isEmptyRows,
      reportCalculationDate,
      groupedPlCommissionReportRows,
      hasPermissionsTOClientModule,
      hasPermissionsTODeliveryModule,
      hasPermissionsTOTaskOrderModule,
    }),
  };
};
