import {
  colorSecondaryGray,
  colorSecondaryText,
  colorBackgroundDark,
  colorSecondaryGrayLight,
  colorSecondaryBackground,
  colorSecondaryGrayLight015,
} from 'assets/styles/variables';
import { DEFAULT_ORDER } from 'core/billing-reports/constants';
import {
  getRevenue,
  calculateTotal,
  summaryTableDataGetter,
} from 'core/billing-reports/utils';
import { UNITS_TYPES } from 'core/constants';
import hash from 'hash-sum';
import {
  get,
  toNumber,
} from 'lodash';

import { legendTitles } from 'utils/constants';
import {
  addThousandSeparator,
} from 'utils/helpers/numbers';

import salesCommissionODCConfigDataTemplateGetter from './config/sales-commission-odc-table-config';
import salesCommissionPTMConfigDataTemplateGetter from './config/sales-commission-ptm-table-config';
import salesCommissionTTMConfigDataTemplateGetter from './config/sales-commission-ttm-table-config';

const groupBy = ({
  row,
  groups,
  sortBy,
  orderBy,
  rowData,
  setGroups,
  defaultOrder,
  requiredTotals = [],
}) => {
  const isDefaultOrder = orderBy === defaultOrder;

  if (!isDefaultOrder) {
    return null;
  }

  const clientName = get(row, 'client');
  const clientId = get(row, 'clientId');
  const taskOrderId = get(row, 'taskOrderId');
  const key = hash(`${clientId}/${taskOrderId}`);
  const {
    rows,
    subtotalRow,
  } = get(groups, key, {
    rows: [],
    subtotalRow: {},
  });
  const totals = requiredTotals.reduce((acc, {
    property,
  }) => {
    const subtotal = get(subtotalRow, property, 0);
    const currentValue = get(row, property, 0);

    return {
      ...acc,
      [property]: subtotal + toNumber(currentValue),
    };
  }, {});
  rows.push(rowData);

  return setGroups({
    ...groups,
    [key]: {
      rows,
      sortBy: get(row, sortBy, clientName),
      subtotalRow: {
        ...row,
        isSubTotal: true,
        ...totals,
      },
    },
  });
};

const getRequiredTotals = () => [{
  property: 'hours',
}, {
  property: 'total',
  valueGetter: getRevenue,
}, {
  property: 'eligibleAmount',
  valueGetter: getRevenue,
}, {
  property: 'commissionUsd',
  valueGetter: getRevenue,
}];

export default ({
  isCTO,
  hasPermissionsTOClientModule,
  hasPermissionsTODeliveryModule,
  hasPermissionsTOTaskOrderModule,
}) => [{
  type: UNITS_TYPES.FILTER,
  currentFilter: 'salesCommission',
  title: 'Note: This is only an estimate. Actual commission will be calculated based on invoiced and collected amounts.',
  cssRules: {
    titleCssRules: `
      display: inline-block;
      padding-top: 1.6rem;
      font-size: 1.3rem;
      line-height: 1.6rem;
      color: ${colorSecondaryGray};`,
    clearButtonCssRules: `&&&{
      line-height: 3.3rem
    }`,
    wrapperCssRules: `
      padding: 1.8rem 0;
      background-color: transparent;`,
    gridTemplateConfig: `
      grid-template-columns: minmax(auto, 250fr) minmax(auto, 90fr) minmax(auto, 110fr) minmax(auto, 100fr) minmax(auto, 916fr);
      && {
        .grid-unit--filter {
          background-color: ${colorSecondaryBackground};
        }
        .grid-unit--active {
          background-color: ${colorBackgroundDark};
        }
      }
    `,
  },
},
{
  type: UNITS_TYPES.TABLE,
  currentFilter: 'salesCommission',
  summaryTableDataGetter: ((data) => summaryTableDataGetter({
    project: 'PTM/FP',
    requiredTotals: getRequiredTotals(),
    ...data,
  })),
  groupBy: ((data) => groupBy({
    defaultOrder: DEFAULT_ORDER.SALES_COMMISSION_TABLE,
    sortBy: 'projectName',
    requiredTotals: getRequiredTotals(),
    ...data,
  })),
  useAccumulator: (data) => calculateTotal({
    label: 'PTM/FP',
    isDefaultExpanded: true,
    summaryWrapperCss: `
      display: grid;
      grid-template-columns: minmax(auto,227fr) minmax(auto,138fr) minmax(auto,153fr) minmax(auto,77fr);
      && .expansion-summary__item {
          margin-right: 0;
      }`,
    requiredTotals: getRequiredTotals(),
    ...data,
  }),
  content: [{
    idKey: 'projectId',
    dataKey: 'ptmFpReportRows',
    tableName: 'salesCommissionPTM',
    preventScrolling: true,
    rowStatusGetter: (row) => {
      const {
        isSubTotal,
        isGrandTotal,
        isDevstaffNotInTo,
        isDevstaffWithoutRates,
        isFirstInvoiceDateSet,
      } = row;
      const id = hash(row);
      return ({
        id,
        hasWarning: !(isSubTotal || isGrandTotal) && (isDevstaffNotInTo || !isFirstInvoiceDateSet || isDevstaffWithoutRates),
        stylesTemplate: (isSubTotal || isGrandTotal) ? {
          gridTemplateConfig: `grid-template-columns: ${isGrandTotal ? 'minmax(auto, 145fr) minmax(auto, 471fr)' : 'minmax(auto, 581fr) minmax(auto, 0)'} minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 80fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 60fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 90fr) minmax(auto, 90fr);`,
          rowStyles: `&& {
            border-top: 1px solid ${isSubTotal ? colorSecondaryGrayLight : 'transparent'};
            border-bottom: 1px solid ${isSubTotal ? colorSecondaryGrayLight : 'transparent'};
          }`,
        } : null,
      });
    },
    rules: {
      css: {
        gridTemplateConfig: 'grid-template-columns: minmax(auto, 250fr) minmax(auto, 90fr) minmax(auto, 110fr) minmax(auto, 100fr) minmax(auto, 164fr) minmax(auto, 80fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 60fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 90fr) minmax(auto, 90fr);',
      },
    },
    dataTemplate: salesCommissionPTMConfigDataTemplateGetter({
      isCTO,
      hasPermissionsTOClientModule,
      hasPermissionsTODeliveryModule,
      hasPermissionsTOTaskOrderModule,
    }),
  }],
},
{
  type: UNITS_TYPES.TABLE,
  currentFilter: 'salesCommission',
  summaryTableDataGetter: ((data) => summaryTableDataGetter({
    project: 'TTM',
    requiredTotals: getRequiredTotals({
      withHrs: false,
    }),
    ...data,
  })),
  groupBy: ((data) => groupBy({
    defaultOrder: DEFAULT_ORDER.SALES_COMMISSION_TABLE,
    sortBy: 'projectName',
    requiredTotals: getRequiredTotals({
      withHrs: false,
    }),
    ...data,
  })),
  useAccumulator: (data) => calculateTotal({
    label: 'TTM',
    isDefaultExpanded: true,
    summaryWrapperCss: `
      display: grid;
      grid-template-columns: minmax(auto,227fr) minmax(auto,138fr) minmax(auto,153fr) minmax(auto,77fr);
      && .expansion-summary__item {
          margin-right: 0;
      }`,
    requiredTotals: getRequiredTotals({
      withHrs: false,
    }),
    ...data,
  }),
  content: [{
    idKey: 'projectId',
    dataKey: 'ttmReportRows',
    tableName: 'salesCommissionTTM',
    preventScrolling: true,
    rowStatusGetter: (row) => {
      const {
        isSubTotal,
        isGrandTotal,
        isDevstaffNotInTo,
        isFirstInvoiceDateSet,
        isDevstaffWithoutRates,
      } = row;
      const id = hash(row);
      return ({
        id,
        hasWarning: !(isSubTotal || isGrandTotal) && (isDevstaffNotInTo || !isFirstInvoiceDateSet || isDevstaffWithoutRates),
        stylesTemplate: (isSubTotal || isGrandTotal) ? {
          gridTemplateConfig: `grid-template-columns: ${isGrandTotal ? 'minmax(auto, 110fr) minmax(auto, 506fr)' : 'minmax(auto, 581fr) minmax(auto, 0)'} minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 60fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 90fr) minmax(auto, 90fr);`,
          rowStyles: `&& {
            border-top: 1px solid ${isSubTotal ? colorSecondaryGrayLight : 'transparent'};
            border-bottom: 1px solid ${isSubTotal ? colorSecondaryGrayLight : 'transparent'};
          }`,
        } : null,
      });
    },
    rules: {
      css: {
        gridTemplateConfig: 'grid-template-columns: minmax(auto, 250fr) minmax(auto, 90fr) minmax(auto, 110fr) minmax(auto, 100fr) minmax(auto, 80fr) minmax(auto, 80fr) minmax(auto, 80fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 60fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 90fr) minmax(auto, 90fr);',
      },
    },
    dataTemplate: salesCommissionTTMConfigDataTemplateGetter({
      isCTO,
      hasPermissionsTOClientModule,
      hasPermissionsTODeliveryModule,
      hasPermissionsTOTaskOrderModule,
    }),
  }],
},
{
  type: UNITS_TYPES.TABLE,
  currentFilter: 'salesCommission',
  summaryTableDataGetter: ((data) => summaryTableDataGetter({
    project: 'ODC',
    requiredTotals: getRequiredTotals({
      withHrs: false,
    }),
    ...data,
  })),
  groupBy: ((data) => groupBy({
    defaultOrder: DEFAULT_ORDER.SALES_COMMISSION_TABLE,
    sortBy: 'projectName',
    requiredTotals: getRequiredTotals({
      withHrs: false,
    }),
    ...data,
  })),
  useAccumulator: (data) => calculateTotal({
    label: 'ODC',
    isDefaultExpanded: true,
    summaryWrapperCss: `
      display: grid;
      grid-template-columns: minmax(auto,227fr) minmax(auto,138fr) minmax(auto,153fr) minmax(auto,77fr);
      && .expansion-summary__item {
          margin-right: 0;
      }`,
    requiredTotals: getRequiredTotals({
      withHrs: false,
    }),
    ...data,
  }),
  content: [{
    idKey: 'projectId',
    dataKey: 'odcReportRows',
    tableName: 'salesCommissionOvertime',
    preventScrolling: true,
    rowStatusGetter: (row) => {
      const {
        isSubTotal,
        isGrandTotal,
        isDevstaffNotInTo,
        isFirstInvoiceDateSet,
        isDevstaffWithoutRates,
      } = row;
      const id = hash(row);
      return ({
        id,
        hasWarning: !(isSubTotal || isGrandTotal) && (isDevstaffNotInTo || !isFirstInvoiceDateSet || isDevstaffWithoutRates),
        stylesTemplate: (isSubTotal || isGrandTotal) ? {
          gridTemplateConfig: `grid-template-columns: ${isGrandTotal ? 'minmax(auto, 116fr) minmax(auto, 567fr)' : 'minmax(auto, 648fr) minmax(auto, 0)'} minmax(auto, 0) minmax(auto, 80fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 60fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 90fr) minmax(auto, 90fr);`,
          rowStyles: `&& {
            border-top: 1px solid ${isSubTotal ? colorSecondaryGrayLight : 'transparent'};
            border-bottom: 1px solid ${isSubTotal ? colorSecondaryGrayLight : 'transparent'};
          }`,
        } : null,
      });
    },
    rules: {
      css: {
        gridTemplateConfig: 'grid-template-columns: minmax(auto, 250fr) minmax(auto, 90fr) minmax(auto, 110fr) minmax(auto, 349fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 60fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 90fr) minmax(auto, 90fr);',
      },
    },
    dataTemplate: salesCommissionODCConfigDataTemplateGetter({
      isCTO,
      hasPermissionsTOClientModule,
      hasPermissionsTODeliveryModule,
      hasPermissionsTOTaskOrderModule,
    }),
  }],
},
{
  type: UNITS_TYPES.TABLE,
  currentFilter: 'salesCommission',
  summaryTableDataGetter: ((data) => summaryTableDataGetter({
    project: 'Overtime',
    requiredTotals: getRequiredTotals(),
    ...data,
  })),
  groupBy: ((data) => groupBy({
    defaultOrder: DEFAULT_ORDER.SALES_COMMISSION_TABLE,
    sortBy: 'projectName',
    requiredTotals: getRequiredTotals(),
    ...data,
  })),
  useAccumulator: (data) => calculateTotal({
    label: 'Overtime',
    isDefaultExpanded: true,
    summaryWrapperCss: `
      display: grid;
      grid-template-columns: minmax(auto,227fr) minmax(auto,138fr) minmax(auto,153fr) minmax(auto,77fr);
      && .expansion-summary__item {
          margin-right: 0;
      }`,
    requiredTotals: getRequiredTotals(),
    ...data,
  }),
  content: [{
    idKey: 'projectId',
    dataKey: 'overtimeReportRows',
    tableName: 'salesCommissionOvertime',
    preventScrolling: true,
    rowStatusGetter: (row) => {
      const {
        isSubTotal,
        isGrandTotal,
        isDevstaffNotInTo,
        isFirstInvoiceDateSet,
        isDevstaffWithoutRates,
      } = row;
      const id = hash(row);
      return ({
        id,
        hasWarning: !(isSubTotal || isGrandTotal) && (isDevstaffNotInTo || isDevstaffWithoutRates || !isFirstInvoiceDateSet),
        stylesTemplate: (isSubTotal || isGrandTotal) ? {
          gridTemplateConfig: `grid-template-columns: ${isGrandTotal ? 'minmax(auto, 145fr) minmax(auto, 471fr)' : 'minmax(auto, 581fr) minmax(auto, 0)'} minmax(auto, 0) minmax(auto, 0) minmax(auto, 0) minmax(auto, 80fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 60fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 90fr) minmax(auto, 90fr);`,
          rowStyles: `&& {
            border-top: 1px solid ${isSubTotal ? colorSecondaryGrayLight : 'transparent'};
            border-bottom: 1px solid ${isSubTotal ? colorSecondaryGrayLight : 'transparent'};
          }`,
        } : null,
      });
    },
    rules: {
      css: {
        gridTemplateConfig: 'grid-template-columns: minmax(auto, 250fr) minmax(auto, 90fr) minmax(auto, 110fr) minmax(auto, 100fr) minmax(auto, 164fr) minmax(auto, 80fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 60fr) minmax(auto, 100fr) minmax(auto, 100fr) minmax(auto, 90fr) minmax(auto, 90fr);',
      },
    },
    dataTemplate: salesCommissionPTMConfigDataTemplateGetter({
      isCTO,
      hasPermissionsTOClientModule,
      hasPermissionsTODeliveryModule,
      hasPermissionsTOTaskOrderModule,
    }),
  }],
},
{
  type: 'single-row',
  cssRules: `
    display: grid;
    grid-template-columns: minmax(auto,142fr) minmax(auto,347fr) minmax(auto,203fr) minmax(auto,102fr);
    margin-top: 1.6rem;
    padding: 0 1rem 0 1.6rem;
    border-top: 1px solid ${colorSecondaryGrayLight};
    background-color: ${colorSecondaryGrayLight015};`,
  useAccumulator: ({
    accumulator: {
      commissionUsd,
      eligibleAmount,
      total,
      hasActiveFilters,
    },
  }) => [{
    type: 'text',
    componentProps: {
      data: hasActiveFilters ? 'Total with filters' : 'Grand Total',
      cssRules: ` && {
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: 500;
        color: ${colorSecondaryText};
      }`,
    },
    name: 'Grand Total',
    legend: legendTitles.commissionSummaryReport,
  },
  {
    type: 'text',
    componentProps: {
      data: total ? `$ ${addThousandSeparator(total.toFixed(2))}` : '$ 0',
      cssRules: ` && {
        padding-right: 0;
        font-size: 1.8rem;
        line-height: 2.4rem;
        color: ${colorSecondaryText};
        text-align: right;
      }`,
    },
  },
  {
    type: 'text',
    componentProps: {
      data: eligibleAmount ? `$ ${addThousandSeparator(eligibleAmount.toFixed(2))}` : '$ 0',
      cssRules: ` && {
        padding-right: 0;
        font-size: 1.8rem;
        line-height: 2.4rem;
        color: ${colorSecondaryText};
        text-align: right;
      }`,
    },
  },
  {
    type: 'text',
    componentProps: {
      data: commissionUsd ? `$ ${addThousandSeparator(commissionUsd.toFixed(2))}` : '$ 0',
      cssRules: ` && {
        padding-right: 0;
        font-size: 1.8rem;
        line-height: 2.4rem;
        color: ${colorSecondaryText};
        text-align: right;
      }`,
    },
  },
  ],
},
];
