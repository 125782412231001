export const fromBottomToTop = {
  from: {
    position: 'fixed',
    top: '100%',
    opacity: 0,
    zIndex: 10,
  },
  enter: {
    position: 'fixed',
    top: '0',
    opacity: 1,
    zIndex: 10,
  },
  leave: {
    position: 'fixed',
    top: '100%',
    opacity: 0,
    zIndex: 10,
  },
};
