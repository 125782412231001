import {
  billingReportsActions,
  billingReportsActionsTypes,
} from 'core/billing-reports/actions';
import {
  ENTITY_NAME as billingReportsEntityName,
} from 'core/billing-reports/constants';
import {
  selectOrderRules as selectBillingReportsOrderRules,
  selectFilters as selectBillingReportsFilters,
} from 'core/billing-reports/selectors';
import { channelPartnersActions, channelPartnersActionsTypes } from 'core/channel-partners/actions';
import {
  ENTITY_NAME as channelPartnersEntityName,
} from 'core/channel-partners/constants';
import {
  selectOrderRules as selectChannelPartnersOrderRules,
  selectFilters as selectChannelPartnersFilters,
} from 'core/channel-partners/selectors';
import {
  clientsActions,
  clientsActionsTypes,
} from 'core/clients/actions';
import {
  ENTITY_NAME as clientsEntityName,
} from 'core/clients/constants';

import {
  selectFavoritesClients,
  selectOrderRules as selectClientsOrderRules,
  selectFilters as selectClientsFilters,
} from 'core/clients/selectors';

import {
  configurationsActions,
} from 'core/configurations/actions';
import { CHANGE_FILTER, clientsActions as categoryManagementClientsActions } from 'core/configurations/category-management/actions';
import {
  ENTITY_NAME as categoryManagementEntityName,
} from 'core/configurations/category-management/constants';
import { selectFilters as selectCategoryManagementFilters, selectOrderRules as selectCategoryManagementOrderRules } from 'core/configurations/category-management/selectors';
import {
  ENTITY_NAME as configurationsEntityName,
} from 'core/configurations/constants';
import {
  selectOrderRules as selectConfigurationsOrderRules,
} from 'core/configurations/selectors';
import {
  FILTERS_TYPES,
} from 'core/constants';
import {
  dashboardActions,
} from 'core/dashboard/actions';
import {
  ENTITY_NAME as dashboardEntityName,
} from 'core/dashboard/constants';
import {
  selectOrderRules as selectDashboardOrderRules,
} from 'core/dashboard/selectors';
import {
  deliveryActions,
  deliveryActionsTypes,
} from 'core/delivery/actions';
import {
  ENTITY_NAME as deliveryEntityName,
  ENTITY_NAME_PROJECTS as projectsDeliveryEntityName,
  ENTITY_NAME_EMPLOYEES as employeesDeliveryEntityName,
} from 'core/delivery/constants';
import {
  selectOrderRules as selectDeliveryOrderRules,
  selectFilters as selectDeliveryFilters,
  selectFavoritesProjects,
  selectFavoritesEmployees,
} from 'core/delivery/selectors';
import {
  deliveryCenterUtilizationByMonthActions,
  deliveryCenterUtilizationByMonthActionsTypes,
} from 'core/delivery-center-utilization-by-month/actions';
import {
  ENTITY_NAME as deliveryCenterUtilizationByMonthEntityName,
} from 'core/delivery-center-utilization-by-month/constants';
import {
  selectOrderRules as deliveryCenterUtilizationByMonthOrderRules,
  selectFilters as deliveryCenterUtilizationByMonthFilters,
} from 'core/delivery-center-utilization-by-month/selectors';
import { employeesReviewsActions } from 'core/employees-reviews/actions';
import {
  ENTITY_NAME as reviewsEntityName,
} from 'core/employees-reviews/constants';
import {
  selectOrderRules as selectReviewsOrderRules,
  selectFilters as selectReviewsFilters,
} from 'core/employees-reviews/selectors';
import {
  appStorage,
} from 'core/storage';
import {
  taskOrdersActions,
  taskOrdersActionsTypes,
} from 'core/task-orders/actions';
import {
  ENTITY_NAME as taskOrdersEntityName,
} from 'core/task-orders/constants';
import {
  selectFavoriteTaskOrders,
  selectOrderRules as selectTaskOrdersOrderRules,
  selectFilters as selectTaskOrdersFilters,
} from 'core/task-orders/selectors';
import {
  get,
  has,
  omit,
} from 'lodash';
import {
  throttle,
  select,
  put,
  call,
  takeLatest,
  delay,
} from 'redux-saga/effects';

import { getIsFilterActive } from 'utils/helpers/models';

import {
  commonActionsTypes,
} from './actions';
const getEntityData = (entityName) => {
  switch (entityName) {
    case categoryManagementEntityName:
      return {
        entityActions: categoryManagementClientsActions,
        entitySelectors: {
          filters: selectCategoryManagementFilters,
          orderRules: selectCategoryManagementOrderRules,
        },
      };
    case configurationsEntityName:
      return {
        entityActions: configurationsActions,
        entitySelectors: {
          orderRules: selectConfigurationsOrderRules,
        },
      };
    case dashboardEntityName:
      return {
        entityActions: dashboardActions,
        entitySelectors: {
          orderRules: selectDashboardOrderRules,
        },
      };
    case deliveryCenterUtilizationByMonthEntityName:
      return {
        entityActions: deliveryCenterUtilizationByMonthActions,
        entitySelectors: {
          filters: deliveryCenterUtilizationByMonthFilters,
          orderRules: deliveryCenterUtilizationByMonthOrderRules,
        },
      };
    case clientsEntityName:
      return {
        entityActions: clientsActions,
        entityStorageActions: {
          updateFavorites: (updatedFavorites) => appStorage.setFavoritesClients(updatedFavorites),
        },
        entitySelectors: {
          filters: selectClientsFilters,
          favorites: selectFavoritesClients,
          orderRules: selectClientsOrderRules,
        },
      };
    case channelPartnersEntityName:
      return {
        entityActions: channelPartnersActions,
        entitySelectors: {
          filters: selectChannelPartnersFilters,
          orderRules: selectChannelPartnersOrderRules,
        },
      };
    case taskOrdersEntityName:
      return {
        entityActions: taskOrdersActions,
        entityStorageActions: {
          updateFavorites: (updatedFavorites) => appStorage.setFavoriteTaskOrders(updatedFavorites),
        },
        entitySelectors: {
          filters: selectTaskOrdersFilters,
          favorites: selectFavoriteTaskOrders,
          orderRules: selectTaskOrdersOrderRules,
        },
      };
    case deliveryEntityName:
      return {
        entityActions: deliveryActions,
        entitySelectors: {
          orderRules: selectDeliveryOrderRules,
          filters: selectDeliveryFilters,
        },
      };
    case projectsDeliveryEntityName:
      return {
        entityActions: deliveryActions,
        entityStorageActions: {
          updateFavorites: (updatedFavorites) => appStorage.setFavoriteProjects(updatedFavorites),
        },
        entitySelectors: {
          orderRules: selectDeliveryOrderRules,
          favorites: selectFavoritesProjects,
          filters: selectDeliveryFilters,
        },
      };
    case employeesDeliveryEntityName:
      return {
        entityActions: deliveryActions,
        entityStorageActions: {
          updateFavorites: (updatedFavorites) => appStorage.setFavoriteEmployees(updatedFavorites),
        },
        entitySelectors: {
          orderRules: selectDeliveryOrderRules,
          favorites: selectFavoritesEmployees,
          filters: selectDeliveryFilters,
        },
      };
    case billingReportsEntityName:
      return {
        entityActions: billingReportsActions,
        entitySelectors: {
          orderRules: selectBillingReportsOrderRules,
          filters: selectBillingReportsFilters,
        },
      };
    case reviewsEntityName:
      return {
        entityActions: employeesReviewsActions,
        entitySelectors: {
          filters: selectReviewsFilters,
          orderRules: selectReviewsOrderRules,
        },
      };
    default:
      break;
  }
  return null;
};

function* changeOrder({
  payload,
  meta: {
    entityName,
  },
}) {
  const entityData = getEntityData(entityName);

  if (entityData) {
    const {
      orderBy,
      tableName,
    } = payload;
    const {
      entitySelectors,
      entityActions,
    } = entityData;
    const orderRules = yield select(entitySelectors.orderRules);
    const currentRules = orderRules[tableName];
    const {
      isOffSeparatingManually: currentIsOffSeparatingManually,
      isReversed: currentIsReversed,
      orderBy: currentOrderBy,
      defaultOrderBy,
    } = currentRules;

    const newRules = {
      ...currentRules,
    };

    if (orderBy) { // if a got new order rules
      newRules.orderBy = orderBy; // set it

      if (currentOrderBy === orderBy) { // if current order rules same with current reverse sorting
        newRules.isReversed = !currentIsReversed;
      }
      if (orderBy !== defaultOrderBy) { // if new order rules not equal default to disable separating
        newRules.isSeparatedByFavorites = false;
      } else { // else set it to opposite currentIsOffSeparatingManually
        newRules.isSeparatedByFavorites = !currentIsOffSeparatingManually;
      }
    } else {
      newRules.orderBy = defaultOrderBy; // set default sorting

      if (currentOrderBy === defaultOrderBy) { // if current order rules equal default make values opposite it self
        newRules.isOffSeparatingManually = !currentIsOffSeparatingManually;
        newRules.isSeparatedByFavorites = currentIsOffSeparatingManually;
      } else {
        newRules.isReversed = false;
        newRules.isOffSeparatingManually = false;
        newRules.isSeparatedByFavorites = true;
      }
    }

    yield put(entityActions.setOrderRules({
      tableName,
      orderRules: newRules,
    }));
  }
}

function* changeFilter({
  payload,
  meta: {
    entityName,
  },
}) {
  const entityData = getEntityData(entityName);

  if (entityData) {
    const {
      entitySelectors,
      entityActions,
    } = entityData;
    const filters = yield select(entitySelectors.filters);
    const {
      storeKey,
      selected,
      type,
    } = payload;

    let { currentFilter } = payload;

    if (entityName === 'BILLING_REPORTS' && !currentFilter) {
      currentFilter = yield select((state) => state.billingReports.currentFilter);
    }

    yield delay(50);

    if (type === FILTERS_TYPES.SEARCH ||
      type === FILTERS_TYPES.RANGE) {
      yield delay(450);
    }

    const isActive = currentFilter ?
      get(filters, `${currentFilter}.${storeKey}.isActive`, false) :
      get(filters, `${storeKey}.isActive`, false);
    const config = {
      isActive: yield call(getIsFilterActive, ...[isActive, selected, type]),
      type,
      selected,
    };

    yield put(entityActions.changeFilterSuccess({
      currentFilter,
      storeKey,
      config,
    }));
  }
}

function* toggleFavorite({
  payload,
  meta: {
    entityName,
  },
}) {
  const entityData = getEntityData(entityName);

  if (entityData) {
    const {
      entitySelectors,
      entityActions,
      entityStorageActions,
    } = entityData;
    const favorites = yield select(entitySelectors.favorites);
    const {
      id,
    } = payload;

    const updatedFavorites = has(favorites, id) ?
      omit(favorites, id) : {
        ...favorites,
        [id]: true,
      };

    yield call(entityStorageActions.updateFavorites, updatedFavorites);

    yield put(entityActions.updateFavorites({ updatedFavorites, entityName }));
  }
}

function* changeOrderWatcher() {
  yield throttle(100, commonActionsTypes.CHANGE_ORDER, changeOrder);
}

function* toggleFavoriteWatcher() {
  yield takeLatest(
    [
      clientsActionsTypes.TOGGLE_FAVORITES,
      taskOrdersActionsTypes.TOGGLE_FAVORITES,
      deliveryActionsTypes.TOGGLE_FAVORITES,
    ],
    toggleFavorite,
  );
}

function* changeFilterWatcher() {
  yield takeLatest(
    [
      billingReportsActionsTypes.CHANGE_FILTER,
      clientsActionsTypes.CHANGE_FILTER,
      channelPartnersActionsTypes.CHANGE_FILTER,
      deliveryActionsTypes.CHANGE_FILTER,
      taskOrdersActionsTypes.CHANGE_FILTER,
      deliveryCenterUtilizationByMonthActionsTypes.CHANGE_FILTER,
      CHANGE_FILTER,
    ],
    changeFilter,
  );
}

export default [
  changeOrderWatcher,
  changeFilterWatcher,
  toggleFavoriteWatcher,
];
