import React, { useCallback, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';

import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';

import { recordIdName } from 'core/delivery/attachmentManagement/constants';
import {
  removeEmptyProperties,
} from 'forms/helpers';
import { newAttachmentRecord } from 'layouts/employee-details/components/resource-tabs/attachments/constants';

import { get } from 'lodash';
import * as yup from 'yup';

import styles from './styles.module.scss';

const validationSchema = yup.object().shape({
  description: yup.string(),
  file: yup.object({
    name: yup.string().required('Required field'),
    extension: yup.string().required('Required field'),
    downloadUrl: yup.string(),
    fileData: yup.mixed(),
  }).nullable().required('Required field'),
});

export const AttachmentForm = ({
  item,
  columns,
  onSubmitHandler,
  onRemoveRecord,
  setEditedRecordId,
  editedRecordId,
  onDeleteHandler,
}) => {
  const recordId = get(item, 'attachmentRecordId');
  const newRecord = newAttachmentRecord({});
  const isCreateMode = Boolean(recordId === newRecord[recordIdName]);
  const file = isCreateMode ? null : {
    name: get(item, 'name', ''),
    extension: get(item, 'extension', ''),
    downloadUrl: get(item, 'downloadUrl', ''),
  };

  const defaultValues = {
    description: get(item, 'description', ''),
    file,
  };
  const isDisabled = !isCreateMode && editedRecordId === newRecord[recordIdName];

  const {
    handleSubmit,
    control,
    formState: {
      isDirty,
      isValid,
    },
    reset,
    setError,
  } = useForm({
    values: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback((formData) => {
    if (isDirty && isValid) {
      onSubmitHandler({
        formData: removeEmptyProperties(formData),
        attachmentRecordId: recordId,
        isCreateMode,
      });
    }
  }, [
    isDirty,
    isValid,
    onSubmitHandler,
    isCreateMode,
  ]);

  const renderField = useCallback(({
    column,
  }) => {
    if (column.type === 'action') {
      return column.Field({
        onRemoveRecord,
        isFormDirty: isDirty,
        resetForm: reset,
        isCreateMode,
        recordId,
        setEditedRecordId,
        isAllowedForEdit: get(item, 'allowedForEdit', true),
        isAllowedForDelete: get(item, 'allowedForDelete', true),
        onDeleteHandler,
      });
    }

    if (column.type === 'text') {
      return column.Field({
        item,
      });
    }

    return (
      <Controller
        name={column.accessor}
        control={control}
        render={({
          field,
          fieldState,
        }) => column.Field({
          field,
          fieldState,
          item,
          isCreateMode,
          reset,
          setError,
        })}
      />
    );
  }, [
    isDirty,
    isCreateMode,
    control,
    onDeleteHandler,
    item,
  ]);

  useEffect(() => {
    if (editedRecordId !== recordId) {
      reset(defaultValues);
    }
  }, [editedRecordId]);

  useEffect(() => {
    if (isDirty) {
      setEditedRecordId(recordId);
    }
  }, [isDirty]);

  return (
    <form
      key={recordId}
      onSubmit={handleSubmit(onSubmit)}
      className={classNames({
        [styles.disabledForm]: isDisabled,
      })}
    >
      <ul
        className={classNames(styles.bodyStyles, {
          [styles.disabledList]: isDisabled,
        })}
      >
        {columns.map((column, columnIndex) => {
          const { accessor } = column;

          return (
            <li
              key={`${accessor}Body`}
              className={classNames(styles[column.accessor])}
            >
              {renderField({ column, columnIndex })}
            </li>
          );
        })}
      </ul>
    </form>
  );
};

AttachmentForm.propTypes = {
  item: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  onRemoveRecord: PropTypes.func.isRequired,
  setEditedRecordId: PropTypes.func.isRequired,
  editedRecordId: PropTypes.number,
  onDeleteHandler: PropTypes.func.isRequired,
};

AttachmentForm.defaultProps = {
  editedRecordId: undefined,
};
