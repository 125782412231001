export const stopPropagation = (event) => {
  event.stopPropagation();
};

export const subscribeToActions = (callback) => {
  document.addEventListener('mousedown', callback);
  document.addEventListener('focusin', callback);
};

export const unsubscribeFromActions = (callback) => {
  document.removeEventListener('mousedown', callback);
  document.removeEventListener('focusin', callback);
};

export const timeout = async (ms = 0) => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export async function sleep(fn, penalty = 300, options) {
  await timeout(penalty);
  return fn(...options);
}
