import { resourceTechnicalMentorAccess } from 'core/auth/guaranteedAccessRoles';
import { selectUserGroup } from 'core/auth/selectors';
import { createSelector } from 'reselect';
import { getHasPermissions } from 'utils/auth';

export const selectFormState = (state) => state.delivery.employeeDetailsTabs.technicalMentorManagement.formState;
export const selectTechnicalMentorManagementRecords = (state) => state.delivery.employeeDetailsTabs.technicalMentorManagement.technicalMentorManagementRecords;
export const selectIsFetchingTechnicalMentorRecords = (state) => state.delivery.employeeDetailsTabs.technicalMentorManagement.isFetchingTechnicalMentorRecords;
export const selectMemoDevstaffId = (state) => state.delivery.employeeDetailsTabs.technicalMentorManagement.memoDevstaffId;
export const selectTechnicalMentorRecordsLength = (state) => state.delivery.employeeDetailsTabs.technicalMentorManagement.length;
export const selectTechnicalMentorsList = (state) => state.delivery.employeeDetails.technicalMentorsList;

export const selectRecords = createSelector(
  selectTechnicalMentorManagementRecords,
  selectIsFetchingTechnicalMentorRecords,
  selectUserGroup,
  selectMemoDevstaffId,
  selectTechnicalMentorsList,
  (records, isFetchingTechnicalMentorRecords, userGroup, memoDevstaffId, technicalMentorsList) => ({
    memoDevstaffId,
    isFetchingTechnicalMentorRecords,
    records,
    hasPermissionToAddRecord: getHasPermissions(userGroup, resourceTechnicalMentorAccess.rolesToAdd),
    technicalMentorsList,
    technicalMentorsMap: technicalMentorsList.reduce((acc, item) => ({ ...acc, [item.value]: item }), {}),
  })
);
