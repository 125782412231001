import {
  colorWhite,
  colorPrimary,
  shadowButtons,
  backgroundButtons,
} from 'assets/styles/variables';

export const controlsCssRules = `
&&& {
  display: grid;
  grid-template-columns: 1fr repeat(3, minmax(auto, 15.2rem));
  grid-column-gap: 2.4rem;
  grid-row-gap: 0.8rem;
  grid-auto-rows: minmax(3.2rem, auto);
  flex-direction: column;
  padding-top: 0.8rem;
  font-size: 1.5rem;
}
`;

export const controlsWrapperCssRules = `
  display: flex;
  padding-bottom: 1.6rem;
  background-color: #fbfbfb;
  & .wrapper-ng {
    display: flex;
    flex-direction: row;
  }
`;

export const yearPickerCssRules = `
  && {
    border: none;
    padding-top: unset;
  }
    & path {
    fill: ${colorPrimary};
  }
  .calendar__buttons {
    margin-right: 1.6rem;
    .calendar-date-picker__header-control {
      margin-left: 0;
      margin-right: 0.8rem;
    }
  }
  .calendar__toggle-button {
    padding: 0.3rem 0.8rem 0 0.8rem;
  }
`;

export const calendarItemCssRules = `
  padding-top: 0.3rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.7rem;
  color:  ${colorPrimary};
`;

export const rateCardsWrapperStyles = `
  display: flex;
  flex-direction: row;
  grid-column-gap: 4rem;
  min-height: 60vh;
  & .rate-cards {
    width: 35%;
    padding: 1.6rem 0;
    grid-area: list;
    display: flex;
    flex-direction: column;
    &__list {
      margin-top: 2rem;
    }
    &__list-item {
      font-size: 1.5rem;
      cursor: pointer;
      padding: 1.45rem 1.6rem;
      border-bottom: 1px solid #BBBDC0;
      color: #000000;
      &.actve {
        background-color: #EAEAEB;
        border-bottom: 1px solid #EAEAEB;
      }
    }
    .ratecard-file-block {
      position: relative;
      .action-button {
        position: absolute;
        top: 3.1rem;
        right: 3.2rem;
        line-height: 2.4rem;
        z-index: 10;
      }
    }
    .scr_link {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 1.6rem;
      padding: 1.5rem 3.2rem 1.6rem;
      box-sizing: border-box;
      background: #fbfbfb;
      &-title {
        width: 50%;
        margin-bottom: 1.1rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #414042;
      }
      .custom-link {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        a {
          align-items: center;
        }
      }
    }
    &__placeholders {
      font-size: 1.6rem;
      grid-area: placeholders;
    }
  }
  & .rate-card-mangment__content {
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
    font-size: 2.2rem;
  }
  & .document-controls {
    display: flex;
    .client-upload-document-form {
      width: 100%;
    }
  }
`;

export const addButtonStyles = `&& {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: .8rem 1.7rem;
  border: 1px solid ${colorPrimary};
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.6rem;
  letter-spacing: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  color: ${colorPrimary};
  background-color: initial;
  outline: none;
  cursor: pointer;
  &.active,
  &:hover {
    border-color: ${colorPrimary};
    color: ${colorWhite};
    background: ${backgroundButtons};
    box-shadow: ${shadowButtons};
}`;

export const RemoveButtonCss = `
  {
    fonst-Size: 40px
  }
`;

export const formWrapperStyles = (createMode) => ({
  withoutSpinner: true,
  cssRules: `
    display: grid;
    grid-column-gap: 4rem;
    grid-row-gap: 0rem;
    grid-template-areas:
    "details details details details details details"
    "details details details details details details"
    "ratesHeader ratesHeader ratesHeader ratesHeader ratesHeader ratesHeader"
    "rates rates rates rates rates rates"
    "rates rates rates rates rates rates"
    "rates rates rates rates rates rates"
    ". . . . delete delete";
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto auto auto 1fr 1fr auto;
    padding-top:0;
    & > div:nth-child(4) {
      position: absolute;
      right: 0;
      top: 1.6rem;
    }
  `,
  formControlsCss: `
    display: flex;
    justify-content: flex-end;
    margin: ${createMode ? '-1.8rem 0' : '-3.8rem 23rem'} 0 0;
    & .button {
      width: 18rem;
      height: 3.2rem;
    }
  `,
});

export const placeholdersItemStyles = `{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}`;
