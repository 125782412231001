import {
  formHeaderCss,
  addButtonCss,
  removeButtonCss,
} from 'assets/styles/config';
import {
  colorPrimary,
  colorWhite,
  colorSecondaryGray,
  colorSecondaryText,
  colorPrimary003,
  colorSecondaryGrayLight,
  colorSecondaryGrayLight015,
  colorSecondaryBackgroundLight,
  colorSecondaryGrayDark,
} from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import { updatePTODetails } from 'core/auth/guaranteedAccessRoles';
import { GRID_TEMPLATE_COLUMNS_PL_APPROVALS } from 'layouts/employee-details/model/utils/workbook/constants';
import { get, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { getHasPermissions } from 'utils/auth';
import { PTO_DAYS_ACTION_TYPES } from 'utils/constants';
import { calcBusinessDaysDiff } from 'utils/helpers/date';
import { array, string, object } from 'yup';

import {
  getPtoData,
  infoCardCss,
  textInputCss,
  datePickerCss,
  descriptionCss,
  redButton,
  listHeadingStyles,
  listHeadingDataGetter,
  plApprovalsDataGetter,
  additionalControlsCssRules,
  additionalControlsConfigGetter,
} from './config/pto-requests-config';
const { CPS, DELIVERY_OFFICE_ADMIN, ACC_WAW, ACC_TBS, OFFICE_ADMIN, ACC_NQZ, HR } = USERS_GROUPS;
moment.tz.setDefault('UTC');

const outputFormatting = ({ date }) => moment.isMoment(date) ?
  date.format('YYYY-MM-DD') :
  date;

export default ({
  userGroup,
  userData: {
    fullName: userFullName,
  },
  employeeDetails,
  holidays,
  getDevcenterByDate,
  projectsForPTO: {
    projectIdItems,
    projectNameItems,
    projectByKey,
  },
  businessDaysSet = new Set([1, 2, 3, 4, 5]),
}) => {
  const {
    isNew,
    isIntern,
    devstaffId,
    isDismissed,
    ptoRequestRecords = [],
    vacationsByYear: vacations = [],
  } = employeeDetails;
  const hasPermissionsToUpdate = getHasPermissions(userGroup, updatePTODetails);
  const isViewOnly = getHasPermissions(userGroup, [CPS, DELIVERY_OFFICE_ADMIN, ACC_WAW, ACC_TBS, ACC_NQZ, OFFICE_ADMIN, HR]);
  const isEmployed = !isNew && !isDismissed && !isIntern;
  const data = getPtoData({
    ptoRequestRecords,
    vacations,
    isEmployed,
    employeeDetails,
    devstaffId,
  });
  const hrsDays = get(employeeDetails, 'workBookRecords[0].hrsDay', 8);

  const partialGridTemplateColumnsConfig = 'minmax(0, 128fr) minmax(0, 343fr) minmax(0, 121fr) minmax(0, 121fr) minmax(0, 68fr) minmax(0, 64fr) minmax(0, 84fr)';
  const gridTemplateColumns = `${partialGridTemplateColumnsConfig} minmax(0, 118fr) minmax(0, 187fr)`;
  const gridTemplateAreas = `
    "record record record record record record record controls additional-controls"
    "toastify toastify toastify toastify toastify toastify toastify controls additional-controls"`;

  return [{
    type: 'heading',
    data: 'PTO Requests',
    cssRules: `
      display: inline-block;
      margin: 1.6rem;
      line-height: 2.4rem;
      font-size: 1.8rem;
    `,
  },
  !data.length && {
    type: 'heading',
    data: 'This section currently contains no data.',
    cssRules: `
      margin: 1.4rem 0 0 1.6rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #bbbdc0;
    `,
  },
  ...data.map(({
    year,
    hasRecords,
    ptoRecords,
    modalParams,
    managePTOActions,
    vacationDaysByYear,
  }) => {
    const {
      spent = 0,
      total = 20,
      planned = 0,
      requested = 0,
      remaining = 20,
    } = vacationDaysByYear;
    const content = [];

    managePTOActions.forEach((record) => {
      const vacationManagementId = get(record, 'vacationManagementId');
      const days = get(record, 'days', 0);
      const remark = get(record, 'remark');
      const action = get(record, 'actionType');
      const targetYear = get(record, 'targetYear');
      const dateCreated = get(record, 'dateCreated');
      const fragment = days > 1 ? 'days' : 'day';
      const parsedDays = Number.parseFloat(days);
      const managePtoRecordData = (() => {
        switch (action) {
          case PTO_DAYS_ACTION_TYPES.SET_TOTAL_DAYS:
            return `${parsedDays} ${fragment} set total days`;
          case PTO_DAYS_ACTION_TYPES.ADD_DAYS:
            return `${parsedDays} ${fragment} added`;
          case PTO_DAYS_ACTION_TYPES.ANNUL:
            return `${parsedDays} ${fragment} annulled`;
          case PTO_DAYS_ACTION_TYPES.COMPENSATE:
            return `${parsedDays} ${fragment} compensated`;
          case PTO_DAYS_ACTION_TYPES.TRANSFER_TO_THE_NEXT_YEAR:
            return `${parsedDays} ${fragment} transferred to ${targetYear}`;
          case 'transfer from':
            return `${parsedDays} ${fragment} transferred from ${targetYear}`;
          default:
            return '';
        }
      })();

      const deleteRecordAction = {
        currentModal: 'CONFIRM_ACTION_MODAL',
        content: [{
          type: 'title',
          data: 'Delete this PTO management record?',
        },
        {
          type: 'description',
          data: 'Once deleted, it cannot be restored.',
        },
        ],
        acceptActionName: 'deleteVacationManagementRecord',
        acceptActionTitle: 'Delete',
        acceptActionArguments: {
          vacationManagementId,
          devstaffId,
        },
      };
      content.push({
        isInfoCard: true,
        recordId: `vacationManagementId/${vacationManagementId}`,
        cssRules: `
            ${infoCardCss}
            grid-template-columns: ${gridTemplateColumns};
            grid-template-areas: ${gridTemplateAreas};`,
        content: [{
          data: [{
            data: [{
              type: 'description',
              data: managePtoRecordData,
              cssRules: `${descriptionCss} font-size: 1.4rem; font-weight: 500;`,
            }],
          },
          {
            data: [{
              type: 'description',
              data: remark || 'No remark',
              cssRules: `${descriptionCss}
                padding-top: 0;
                line-height: 1.6rem;
                letter-spacing: 0.15px;
                ${remark ? '' : `color: ${colorSecondaryGrayLight};`}
                opacity: ${remark ? '0.8' : '1'};
              `,
            }],
            cssRules: `
              margin-top: 0.3rem;
              padding: 0;`,
          },
          ],
          cssRules: `
            grid-area: record;
            margin-left: 1.6rem;
            padding-bottom: 0.8rem;
          `,
        },
        {
          data: [{}, // empty field
            {
              type: 'actionButton',
              data: 'Delete',
              actionName: 'openModal',
              actionArguments: deleteRecordAction,
              isHidden: !hasPermissionsToUpdate,
              cssRules: `
                &.action-button {
                  text-align: center;
                  align-self: start;
                  line-height: 2.4rem;
                  margin-top: 0.8rem;
                }
              `,
            },
          ],
          cssRules: `
            display: grid;
            grid-area: controls;
            grid-template-columns: 1fr 1fr;
            font-size: 1.2rem;
            text-align: center;
          `,
        },
        {
          data: [{
            type: 'description',
            data: `Last Updated ${moment(dateCreated).tz('America/New_York').format('L LT')}`,
            isHidden: !dateCreated,
            cssRules: `
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              padding: 0 4rem 0;
              font-size: 1.2rem;
              line-height: 1.6rem;
              letter-spacing: -0.25px;
              text-align: center;
              color: rgb(0, 0, 0, 0.87);
              white-space: normal;
            `,
          }],
          cssRules: additionalControlsCssRules,
        },
        ],
      });
    });

    // eslint-disable-next-line consistent-return
    ptoRecords.forEach((record, index) => {
      const {
        hrs,
        remark,
        recordId,
        fromDate,
        requestDate,
        throughDate,
        description,
        isCancelled,
        plApprovals,
        cancelledDate,
        templateOwnerId,
        workingDays = '',
        isUnpaid = false,
        isApproved = false,
        inEditMode = false,
        isRejected = false,
        approvedDate = null,
        rejectedDate = null,
        isPlApprovalInProgress,
        permanentId = null,
      } = record || {};

      const devCenterId = getDevcenterByDate(fromDate);
      const isDraft = !!templateOwnerId;
      const isHistoryRecord = isCancelled || isApproved;
      const hasApprovesList = plApprovals && plApprovals.some(({ isApproved: hasPLApprove }) => hasPLApprove);
      const isTemplate = !recordId;
      const deleteRecordAction = {
        currentModal: 'CONFIRM_ACTION_MODAL',
        content: [{
          type: 'title',
          data: `Delete this PTO request record dated as of ${moment(requestDate).format('L')}?`,
        },
        {
          type: 'description',
          data: 'Once deleted, it cannot be restored.',
        },
        ],
        acceptActionName: 'deletePtoRequestRecord',
        acceptActionTitle: 'Delete',
        acceptActionArguments: {
          recordId,
          devstaffId,
        },
      };
      const additionalControlsParams = {
        remark,
        isDraft,
        recordId,
        devstaffId,
        inEditMode,
        isTemplate,
        isApproved,
        isRejected,
        isCancelled,
        approvedDate,
        rejectedDate,
        userFullName,
        cancelledDate,
        isHistoryRecord,
        hasPermissionsToUpdate,
        isPlApprovalInProgress,
        isViewOnly,
      };
      const additionalControlsConfig = additionalControlsConfigGetter(additionalControlsParams);
      const holidayDates = get(holidays, `[${devCenterId}].holidayDates`, []);
      const countedDayOff = calcBusinessDaysDiff(fromDate, throughDate, holidayDates);
      const defaultDescription = isUnpaid ? `Unpaid time-off ${year}` : `Vacation ${year}`;

      const historyRecordConfig = {
        isInfoCard: true,
        recordId,
        cssRules: `
            ${infoCardCss}
            grid-template-columns: ${gridTemplateColumns};
            grid-template-areas: ${gridTemplateAreas};
            ${isCancelled ? `background-color: ${colorPrimary003};` : ''}
          `,
        content: [{
          data: [{
            data: [{
              type: 'description',
              data: moment(requestDate).format('L'),
              cssRules: `
                  ${descriptionCss}
                  margin-left: 1.6rem;
                `,
            },
            {
              type: 'description',
              data: defaultDescription,
              cssRules: descriptionCss,
            },
            {
              type: 'description',
              data: moment(fromDate).format('L'),
              cssRules: descriptionCss,
            },
            {
              type: 'description',
              data: moment(throughDate).format('L'),
              cssRules: descriptionCss,
            },
            {
              type: 'description',
              data: hrs || countedDayOff * 8,
              cssRules: `${descriptionCss} text-align: center;`,
            },
            {
              type: 'description',
              data: workingDays || countedDayOff,
              cssRules: `${descriptionCss} text-align: center;`,
            },
            {
              type: 'description',
              data: isUnpaid ? 'Yes' : 'No',
              cssRules: `
                ${descriptionCss}
                text-align: center;
                ${isUnpaid ? redButton : ''}`,
            },
            ],
            cssRules: `
                display: grid;
                grid-area: record;
                grid-template-columns: ${partialGridTemplateColumnsConfig};
                grid-column-gap: 0.3rem;
                padding: 0;
              `,
          },
          {
            data: [{
              type: 'description',
              data: remark || 'No remark',
              isMultiline: true,
              cssRules: `${descriptionCss}
                  padding-top: 0;
                  line-height: 1.6rem;
                  letter-spacing: 0.15px;
                  ${remark ? '' : `color: ${colorSecondaryGrayLight};`}
                  opacity: ${remark ? '0.8' : '1'};
                `,
            }],
            cssRules: `
                margin: 0.3rem 0 0 1.6rem;
                padding: 0;
              `,
          },
          {
            summaryConfig: {
              label: 'PL Approval ',
              cssRules: 'margin: 0.8rem 0 0.8rem 1.6rem;',
            },
            isHidden: !hasApprovesList,
            data: hasApprovesList ? plApprovalsDataGetter({
              plApprovals,
            }) : [],
            cssRules: `
                display: flex;
                flex-direction: column;
                margin: 0.3rem 0 1.6re 1.6rem;
                padding: 0;
              `,
          },
          ],
          cssRules: `
              grid-area: record;
              padding-bottom: 0.8rem;
            `,
        },
        {
          data: [{
            type: 'actionButton',
            data: 'Edit',
            actionName: 'togglePtoRequestRecordState',
            actionArguments: {
              recordId,
            },
            isHidden: (isHistoryRecord && !hasPermissionsToUpdate) || isViewOnly,
            cssRules: `
                &.action-button {
                  text-align: center;
                  align-self: start;
                  line-height: 2.4rem;
                  margin-top: 0.8rem;
                }
              `,
          },
          {
            type: 'actionButton',
            data: 'Delete',
            actionName: 'openModal',
            actionArguments: deleteRecordAction,
            isHidden: (isHistoryRecord && !hasPermissionsToUpdate) || isViewOnly,
            cssRules: `
                &.action-button {
                  text-align: center;
                  align-self: start;
                  line-height: 2.4rem;
                  margin-top: 0.8rem;
                }
              `,
          },
          ],
          cssRules: `
              display: grid;
              grid-area: controls;
              grid-template-columns: 1fr 1fr;
              font-size: 1.2rem;
              text-align: center;
            `,
        },
        {
          data: additionalControlsConfig.content,
          cssRules: additionalControlsCssRules,
        },
        ],
      };
      const recordConfig = {
        year,
        permanentId,
        withErrorBox: true,
        recordId,
        onSubmitAction: isTemplate ? 'createPtoRequestRecord' : 'updatePtoRequestRecord',
        additionalFields: isTemplate ? { permanentId } : { recordId },
        stylesTemplate: {
          errorBoxCssRules: `
              && {
                width: calc(100% - 3.2rem);
                margin: 0 1.6rem;
              }
            `,
          cssRules: `
              grid-area: record;
              grid-row-gap: 0;
            `,
          formWrapperStyles: `
              margin: ${index === 0 ? '0.4rem' : '0.8rem'} 0 0;
              padding: 0;
              margin-bottom: 0.8rem;
              background-color: ${!isHistoryRecord || inEditMode ? `${colorSecondaryBackgroundLight}` : 'rgba(187,189,192, 0.15)'};
            `, // TODO: replace with constant
          formStyles: `${infoCardCss}
              grid-template-columns: ${gridTemplateColumns};
              grid-template-areas: ${gridTemplateAreas};
              margin-bottom: 0
            `,
        },
        mainControlsConfig: {
          cssRules: `
              grid-template-columns: repeat(1, 8.8rem);
              grid-area: controls;
              grid-auto-rows: min-content;
              margin: 0 auto;
              padding-bottom: 2.4rem;
              font-size: 1.2rem;
            `,
          onReject: ({
            handleReset,
            actions,
            hasFormChanges,
          }) => {
            if (hasFormChanges) {
              return handleReset();
            }

            if (isTemplate) {
              return actions.removePtoRequestTemplateRecord({
                year,
                permanentId,
              });
            }

            if (inEditMode) {
              return actions.togglePtoRequestRecordState({
                recordId,
              });
            }

            return actions.openModal(deleteRecordAction);
          },
          /* return object according current form state, used in FormMainControls component */
          getResetButtonConfig: ({
            hasFormChanges,
            isFormSubmitted,
          }) => {
            const isCancel = (isTemplate && hasFormChanges) || (!isTemplate && (inEditMode || hasFormChanges));
            let rejectActionTitle = 'Delete';

            if (isCancel) {
              rejectActionTitle = 'Cancel';
            }

            return {
              isResetVisible: (hasFormChanges || hasRecords) && !isFormSubmitted,
              rejectActionTitle,
              rejectButtonCssRules: isCancel ? '' : `
                  & {
                    border-color: ${colorSecondaryGrayLight};
                    color: ${colorSecondaryGray};
                  }
                `,
            };
          },
        },
        additionalControlsConfig,
        content: [
          {
            stylesTemplate: {
              cssRules: `
                grid-template-columns: ${partialGridTemplateColumnsConfig};
                grid-column-gap: 0.3rem;
                padding: 0;
                margin-top: 0.2rem;
                grid-auto-rows: 4.5rem;
              `,
            },
            formData: [
              {
                type: 'calendar',
                name: 'requestDate',
                label: 'Request Date',
                value: requestDate || null,
                validationRules: {
                  schemaGetter: () => string().nullable().required('Required field'),
                },
                popperProps: {
                  placement: 'bottom-start',
                },
                outputFormatting,
                cssRules: `
                ${datePickerCss}
                && {
                  width: calc(100% - 1.6rem);
                  min-width: auto;
                  margin-left: 1.6rem;
                }
              `,
              },
              {
                type: 'text',
                name: 'description',
                label: 'Description',
                value: description || defaultDescription,
                onFormChange: ({
                  values: {
                    isUnpaid: storedIsUnpaid,
                  },
                }) => {
                  if (!storedIsUnpaid) {
                    return {
                      formValue: `Vacation ${year}`,
                    };
                  }
                  return {
                    formValue: `Unpaid time-off ${year}`,
                  };
                },
                validationRules: {
                  isRequired: true,
                  maxLength: 80,
                  schemaGetter: () => string()
                    .max(80, 'Description max length is 80')
                    .required('Required field'),
                },
                cssRules: `
                ${textInputCss}
                margin-right: 1rem;
              `,
              },
              {
                type: 'calendar',
                name: 'fromDate',
                label: 'From',
                value: fromDate || null,
                validationRules: {
                  schemaGetter: () => string()
                    .nullable()
                    .required('Required field')
                    .test('isCurrentYear', `Must be in the ${year} year`, (storedFromDate) => moment(storedFromDate).year() === Number(year))
                    .when(
                      'requestDate',
                      (storedRequestDate, schema) => storedRequestDate ?
                        schema.test('isLaterOrSameThatRequestDate', 'Must be later or same than Request date', (storedFromDate) => moment(storedFromDate).format('X') >= moment(storedRequestDate).format('X')) :
                        schema
                    )
                    .when(
                      'throughDate',
                      (storedThroughDate, schema) => storedThroughDate ?
                        schema.test('isEarlierThatThroughDate', 'Must be earlier than Through date', (storedFromDate) => moment(storedFromDate).format('X') <= moment(storedThroughDate).format('X')) :
                        schema
                    ),
                },
                outputFormatting,
                cssRules: datePickerCss,
              },
              {
                type: 'calendar',
                name: 'throughDate',
                label: 'Through',
                value: throughDate || null,
                validationRules: {
                  schemaGetter: () => string()
                    .nullable()
                    .required('Required field'),
                },
                outputFormatting,
                onFormChange: ({ values }) => ({
                  displayedDate: values.fromDate != null ? moment(values.fromDate, 'YYYY-MM-DD') : undefined,
                }),
                cssRules: datePickerCss,
              },
              {
                type: 'text',
                name: 'hrs',
                label: 'Hrs',
                value: {
                  placeholder: '0',
                  value: hrs || (countedDayOff ? countedDayOff * 8 : ''),
                },
                onFormChange: ({
                  values: {
                    fromDate: storedFromDate,
                    throughDate: storedThroughDate,
                  },
                }) => {
                  if (!storedFromDate || !storedThroughDate || storedFromDate !== storedThroughDate) {
                    return {
                      isLocked: true,
                    };
                  }
                  const storedWeekDay = moment(storedThroughDate, 'YYYY-MM-DD').weekday();
                  const holydaysSet = get(holidays, `[${getDevcenterByDate(storedFromDate)}].holydaysSet`, new Set());

                  return {
                    isLocked: holydaysSet.has(storedThroughDate) || !businessDaysSet.has(storedWeekDay),
                  };
                },
                parentField: {
                  name: ['fromDate', 'throughDate'],
                  valueGetter: ({
                    fromDate: formFromDate,
                    throughDate: formThroughDate,
                  }) => ({
                    value: calcBusinessDaysDiff(
                      formFromDate,
                      formThroughDate,
                      get(holidays, `[${getDevcenterByDate(formFromDate)}].holidayDates`, [])
                    ) * hrsDays,
                  }),
                },
                validationRules: {
                  isRequired: true,
                  isNumeric: true,
                  isInteger: true,
                  schemaGetter: () => string()
                    .nullable()
                    .required('Required field'),
                },
                cssRules: `${textInputCss} text-align: center; margin-right: 1rem;`,
              },
              {
                type: 'text',
                name: 'workingDays',
                label: 'Working Days',
                value: {
                  placeholder: '0',
                  value: workingDays || countedDayOff || '',
                },
                onFormChange: ({
                  errors: {
                    workingDays: workingDaysError,
                  },
                  values: {
                    hrs: storedHrs,
                    workingDays: storedWorkingDays,
                  },
                  setFieldValue,
                }) => {
                  const calculatedDays = Number(storedHrs) !== 0 ? (storedHrs / hrsDays) : '';

                  if ((!calculatedDays && storedWorkingDays) ||
                    (calculatedDays && calculatedDays !== Number(storedWorkingDays))) {
                    setFieldValue('workingDays', calculatedDays);

                    if (workingDaysError) {
                      toast.dismiss('workingDays');
                    }
                  }

                  return {
                    isLocked: true,
                  };
                },
                validationRules: {
                  isRequired: true,
                  decimalScale: 1,
                  isNumeric: true,
                  schemaGetter: () => string()
                    .nullable()
                    .when('isUnpaid', (storedIsUnpaid, schema) => storedIsUnpaid ?
                      schema :
                      schema.test(
                        'isLowerThanRemaining',
                        'number can\'t exceed the number of remaining days',
                        (storedWorkingDays) => (Number(remaining) + Number(workingDays)) >= Number(storedWorkingDays)
                      )),
                },
                cssRules: `${textInputCss} text-align: center;`,
              }, {
                type: 'checkbox',
                name: 'isUnpaid',
                value: isUnpaid,
                cssRules: 'justify-content: flex-end; padding: 0.3rem 0 0.3rem 3.2rem; font-size: 1.6rem;',
              }],
          },
          {
            stylesTemplate: {
              cssRules: `
                grid-template-columns: 1fr;
                margin: 0.3rem 0 0 1.6rem;
                padding: 0;
              `,
            },
            formData: [{
              type: 'text',
              name: 'remark',
              isMultiline: true,
              placeholder: 'Remark',
              value: remark || '',
              validationRules: {
                isRequired: true,
                schemaGetter: () => string().trim().max(2000, 'must be at most 2000 characters').required('Required field'),
              },
              cssRules: `
                ${textInputCss}
                margin: 0 3.6rem ${isTemplate ? '2.4rem' : '0'} 0;
                line-height: 1.6rem;
                & .text-input__input-field {
                  padding: 0.4rem 0;
                }
              `,
            }],
          },
          (() => {
            if (!plApprovals || isEmpty(plApprovals)) {
              return {
                stylesTemplate: {
                  cssRules: `
                    grid-template-columns: 1fr;
                    grid-row-gap: 0;
                    margin: 0 0 2.4rem 1.6rem;
                    padding: 0;
                  `,
                },
                formData: [{
                  type: 'actionButton',
                  data: 'GENERATE PROJECT LIST',
                  isHidden: isTemplate,
                  actionName: 'generatePlApprovals',
                  actionArguments: {
                    devstaffId,
                    recordId,
                  },
                  withBody: true,
                  cssRules: `${addButtonCss}
                    && {
                      margin-top: 3rem;
                      margin-left: 0;
                    }
                  `,
                }],
              };
            }
            const filedArrayRootName = 'plApprovals';

            return {
              title: 'PL Approval required',
              stylesTemplate: {
                cssRules: `
                  grid-template-columns: 1fr;
                  grid-row-gap: 0;
                  margin: 0.3rem 3.6rem 0 1.6rem;
                  padding: 0;
                `,
                titleControlsCss: `
                  margin: 2.4rem 0 0 1.6rem;
                  padding-left: 0;
                `,
              },
              controls: [{
                isCheckbox: 'checkbox',
                title: 'In progress',
                cssRules: `
                  && {
                    margin-left: 2.4rem;
                    color: ${colorSecondaryText}
                  }
                  &&:before {
                    opacity: 1;
                  }
                  &&:after {
                    display: none;
                  }
                `,
                onFormChange: ({
                  values,
                }) => ({
                  isActive: values.isPlApprovalInProgress,
                }),
                onClick: ({
                  setFieldValue,
                  values,
                }) => {
                  setFieldValue('isPlApprovalInProgress', !values.isPlApprovalInProgress);
                },
              }],
              formData: [{
                type: 'virtualField',
                name: 'isPlApprovalInProgress',
                value: Boolean(isPlApprovalInProgress),
              }, {
                isFieldArray: true,
                name: filedArrayRootName,
                validateOnChange: false,
                valueGetter: () => {
                  const defaultLineValues = {
                    internalCategoryKey: null,
                    isApproved: false,
                  };

                  const initialFieldArrayValues = Array.isArray(plApprovals) ?
                    plApprovals.map(({
                      plName,
                      ...rest
                    }) => ({
                      ...rest,
                      plName: plName || '',
                    })) : [];
                  const onPushLine = ({
                    addLine,
                  }) => {
                    addLine(defaultLineValues);
                  };
                  return {
                    onPushLine,
                    value: initialFieldArrayValues,
                    noDataMessage: 'No PL approvals information',
                    lineGetter: ({
                      index: lineIndex,
                      lineValues,
                    }) => {
                      const internalCategoryKey = get(lineValues, 'internalCategoryKey');
                      const internalCategoryName = get(lineValues, 'internalCategoryName');

                      return {
                        lineId: `${lineIndex}/${internalCategoryKey}`,
                        line: [{
                          type: 'select',
                          name: `${filedArrayRootName}[${lineIndex}].internalCategoryKey`,
                          label: 'Project',
                          placeholder: '',
                          validationRules: {
                            isRequired: true,
                          },
                          fieldData: {
                            items: projectIdItems,
                            selected: {
                              value: internalCategoryKey,
                              label: internalCategoryKey,
                            },
                          },
                          cssRules: 'margin-right: 2.2rem; font-size: 1.4rem;',
                        }, {
                          type: 'select',
                          name: `${filedArrayRootName}[${lineIndex}].internalCategoryKey`,
                          label: 'Project',
                          placeholder: '',
                          validationRules: {
                            isRequired: true,
                          },
                          fieldData: {
                            isLink: false,
                            items: projectNameItems,
                            selected: {
                              value: get(plApprovals[lineIndex], 'internalCategoryKey', ''),
                              label: internalCategoryName,
                            },
                          },
                          cssRules: 'margin-right: 2.2rem; font-size: 1.4rem;',
                          parentField: {
                            name: [`${filedArrayRootName}[${lineIndex}].internalCategoryKey`],
                            valueGetter: ({
                              plApprovals: formPlApprovals,
                            }) => {
                              const storedProjectKey = get(formPlApprovals[lineIndex], 'internalCategoryKey');
                              const storedProjectName = get(projectByKey, `[${storedProjectKey}].internalCategoryName`);

                              return {
                                isLink: false,
                                items: projectNameItems,
                                selected: {
                                  value: storedProjectKey,
                                  label: storedProjectName,
                                },
                              };
                            },
                          },
                        }, {
                          type: 'text',
                          name: `${filedArrayRootName}[${lineIndex}].plName`,
                          placeholder: 'n/a',
                          isLocked: true,
                          onFormChange: ({
                            values: {
                              plApprovals: storedPlApprovals,
                            },
                            setFieldValue,
                          }) => {
                            const storedProjectKey = get(storedPlApprovals, `[${lineIndex}].internalCategoryKey`);

                            if (storedProjectKey) {
                              const storedProjectName = get(storedPlApprovals, `[${lineIndex}].internalCategoryName`);
                              const projectName = get(projectByKey, `[${storedProjectKey}].internalCategoryName`);
                              const projectLeadName = get(projectByKey, `[${storedProjectKey}].internalCategoryLeadName`);

                              if (storedProjectName !== projectName) {
                                setFieldValue(`${filedArrayRootName}[${lineIndex}].internalCategoryName`, projectName);
                                setFieldValue(`${filedArrayRootName}[${lineIndex}].plName`, projectLeadName);
                              }
                            }
                            return {};
                          },
                          validationRules: {
                            isRequired: true,
                          },
                          cssRules: `
                            font-size: 1.2rem;
                            line-height: 2.4rem;
                            margin-right: 1rem;
                            letter-spacing: -0.025rem;
                            &&& .text-input__input-field {
                            padding-left: 0;
                            border-bottom-color: transparent;
                            pointer-events: none;
                            }
                            &&&::after {
                              display: none;
                            }
                          `,
                        }, {
                          type: 'toggle',
                          name: `${filedArrayRootName}[${lineIndex}].isApproved`,
                          valueGetter: () => Boolean(get(plApprovals[lineIndex], 'isApproved', false)),
                          validationRules: {},
                          cssRules: `
                            && {
                              align-items: flex-end;
                              padding-bottom: 0.4rem;
                            }
                            & .form-switcher__label {
                                justify-content: flex-start;
                            }
                            & .form-switcher__icon-wrapper {
                                flex-grow: 0;
                            }
                          `,
                        }, {
                          type: 'actionButton',
                          data: 'Delete',
                          actionName: `${filedArrayRootName}_deleteLine`,
                          actionArguments: {
                            index: lineIndex,
                          },
                          cssRules: `
                            ${removeButtonCss}
                            && {
                              padding-left: 0;
                            }
                          `,
                        }],
                        stylesTemplate: {
                          cssRules: `
                            ${GRID_TEMPLATE_COLUMNS_PL_APPROVALS}
                            grid-column-gap: 0.3rem;
                            grid-row-gap: 0;
                            margin-top: 0;
                            padding: 0;
                          `,
                        },
                      };
                    },
                    fieldArrayCss: `
                      grid-template-columns: 1fr;
                      grid-column-gap: 0;
                      grid-row-gap: 0;
                      margin-top: 0;
                      padding: 0;
                    `,
                    headersConfig: {
                      stylesTemplate: {
                        cssRules: `
                          ${GRID_TEMPLATE_COLUMNS_PL_APPROVALS}
                          grid-column-gap: 0.3rem;
                          grid-row-gap: 0;
                          margin-top: 0;
                          padding: 0;
                        `,
                      },
                      data: [{
                        value: {
                          title: 'Category Key',
                          cssRules: `${formHeaderCss} text-align: left;`,
                        },
                      }, {
                        value: {
                          title: 'Category Name',
                          cssRules: `${formHeaderCss} text-align: left;`,
                        },
                      }, {
                        value: {
                          title: 'Project Lead',
                          cssRules: `${formHeaderCss} text-align: left;`,
                        },
                      }, {
                        value: {
                          title: 'Approve',
                          cssRules: `${formHeaderCss} text-align: left;`,
                        },
                      }, {
                        value: {
                          title: 'Action',
                          cssRules: `${formHeaderCss} text-align: left;`,
                        },
                      }],
                    },
                    footerConfig: {
                      stylesTemplate: {
                        cssRules: `
                          grid-template-columns: 1fr;
                          grid-column-gap: 0;
                          grid-row-gap: 0;
                          margin-top: 3rem;
                          grid-auto-rows: minmax(3.2rem, auto);
                          padding: 0;
                          margin-bottom: 2.4rem;
                        `,
                      },
                      data: [{
                        type: 'actionButton',
                        data: 'ADD',
                        actionName: `${filedArrayRootName}_addLine`,
                        withBody: true,
                        cssRules: `${addButtonCss}
                          && {
                            margin-left: 0;
                          }
                        `,
                      }],
                    },
                  };
                },
                validationRules: {
                  schemaGetter: () => array()
                    .of(object().shape({
                      internalCategoryKey: string().nullable().required('Required field'),
                    })),
                },
              }],
            };
          })(),
        ],
      };

      if ((isHistoryRecord && !inEditMode)) {
        return content.push(historyRecordConfig);
      }

      if (record) {
        if (isViewOnly) {
          return content.push(historyRecordConfig);
        }

        if (isTemplate) {
          return content.unshift(recordConfig);
        }

        return content.push(recordConfig);
      }
    });
    return {
      type: 'data-list',
      summaryConfig: {
        label: year,
        isDefaultExpanded: Number(year) === moment().year(),
        cssRules: `
          min-height: 4.8rem;
          font-size: 1.3rem;
          line-height: 1.8rem;
          padding: 0 1.6rem;
          border-top: 1px solid ${colorSecondaryGrayLight};
          background-color: ${colorSecondaryGrayLight015};
          color: ${colorSecondaryGrayDark};
          && .expansion-summary__label {
            font-size: 1.6rem;
            line-height: 2.4rem;
          }
        `,
        items: [{
          data: {
            label: 'Total days',
            value: total,
          },
          cssRules: 'text-transform: uppercase;',
        }, {
          data: {
            label: 'Requested',
            value: requested,
          },
        }, {
          data: {
            label: 'Planned',
            value: planned,
          },
        }, {
          data: {
            label: 'Remaining',
            value: remaining,
          },
        }, {
          data: {
            label: 'Spent',
            value: spent,
          },
        }],
      },
      headersConfig: {
        stylesTemplate: {
          cssRules: `
            ${listHeadingStyles}
            grid-template-columns: ${gridTemplateColumns};
            z-index: 1;
          `,
        },
        data: listHeadingDataGetter({
          withAction: true,
        }),
      },
      controls: [
        {
          title: 'Add record',
          getIsHidden: () => isViewOnly || !isEmployed || !(Number(year) >= Number(moment().year())),
          onClick: ({
            actions,
          }) => actions.addPtoRequestTemplateRecord && actions.addPtoRequestTemplateRecord({
            year,
            permanentId: `${year}_${Math.floor(Math.random() * 10000)}`,
          }),
          cssRules: `
          &.button.button--cancel.data-list__button {
            width: 22.8rem;
            margin: 0 2.4rem 0 0;
          }
        `,
        },
        {
          title: 'Manage remaining PTO days',
          getIsHidden: () => isViewOnly || !modalParams || !(Number(year) >= Number(moment().year() - 1)),
          onClick: ({
            actions,
          }) => {
            actions.openModal(modalParams);
          },
          cssRules: `
          &.button.button--cancel.data-list__button {
            margin-left: 0;
            color: ${colorSecondaryGray};
            border-color: ${colorSecondaryGray};
              &:hover {
              color: ${colorWhite};
              border-color: ${colorPrimary};
            }
         }
        `,
        }],
      stylesTemplate: {
        cssRules: `
          &.data-list {
            padding-bottom: 5.6rem;
          }
        `,
      },
      content,
    };
  })];
};
