import gql from 'graphql-tag';

export const queryConfig = {
  getClientsShortList: gql`{
      clients {
        name
        clientId
    }
  }`,
};
