import React, { useState, Fragment, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import ExpansionSummary from 'components/expansion-summary';
import AnimateHeight from 'react-animate-height';

import ExpandingSectionStore from 'utils/expanding-sections-store';
import './styles.scss';

/**
 * WARN: Due to optimization reasons, child elements will disappear immediately when sliding up.
 * Remember that, when try to increase the animation duration time!
 */
const ExpansionPanel = ({
  actions,
  children,
  summaryContent,
  summaryConfig,
  groupControllerConfig,
  withoutFooter,
}) => {
  const {
    groupId,
    isDisabled,
    items = [],
    label = '',
    cssRules = '',
    isDefaultExpanded,
  } = summaryConfig;

  const {
    changed = false,
    expand = true,
  } = groupControllerConfig;

  const defaultExpanded = changed ? expand : isDefaultExpanded;
  const [isExpanded, setIsExpanded] = useState(!isDisabled && defaultExpanded);
  const [controlId, updateControlId] = useState(null);
  const handleChange = useCallback((state) => {
    if (!isDisabled) {
      setIsExpanded(state);
    }
  }, [isDisabled, groupId]);
  const toggleState = () => {
    if (!isDisabled) {
      setIsExpanded((storedState) => {
        if (controlId) {
          ExpandingSectionStore.updateControlState({
            id: controlId,
            groupId,
            state: !storedState,
          });
        }
        return !storedState;
      });
    }
  };

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [expand, isDefaultExpanded]);

  useEffect(() => { // eslint-disable-line consistent-return
    if (groupId) {
      const {
        id,
        unsubscribe,
      } = ExpandingSectionStore.subscribe({
        groupId,
        isDisabled,
        state: isExpanded,
        setIsExpanded: handleChange,
      });
      updateControlId(id);
      return unsubscribe;
    }
  }, []);

  useEffect(() => {
    if (controlId) {
      ExpandingSectionStore.updateCallback({
        groupId,
        isDisabled,
        id: controlId,
        state: isExpanded,
        setIsExpanded: handleChange,
      });
    }
  }, [handleChange]);

  return (
    <Fragment key={groupId}>
      <ExpansionSummary
        label={label}
        items={items}
        actions={actions}
        cssRules={cssRules}
        isDisabled={isDisabled}
        isExpanded={isExpanded}
        onChange={toggleState}
      >
        {summaryContent}
      </ExpansionSummary>

      <AnimateHeight
        className="expansion-panel__animated-content"
        height={isExpanded ? 'auto' : 0}
      >
        {/* Do not render children when it not visible */}
        {isExpanded ? children : <></>}
        {summaryContent && !withoutFooter &&
          <div className="total-bottom">
            <p className="total-text">Total</p>
            {summaryContent}
          </div>
        }
      </AnimateHeight>
    </Fragment>
  );
};

ExpansionPanel.propTypes = {
  children: PropTypes.any.isRequired,
  summaryContent: PropTypes.any,
  actions: PropTypes.shape({}),
  summaryConfig: PropTypes.shape({
    isDefaultExpanded: PropTypes.bool,
    isDisabled: PropTypes.bool,
    cssRules: PropTypes.string,

    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),

    items: PropTypes.arrayOf(PropTypes.any),
  }),
  groupControllerConfig: PropTypes.shape({}),
  withoutFooter: PropTypes.bool,
};

ExpansionPanel.defaultProps = {
  actions: {},
  summaryConfig: {},
  summaryContent: null,
  groupControllerConfig: {},
  withoutFooter: false,
};


export default ExpansionPanel;
