import { colorSecondaryText } from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import { permissionsToProjectLeadAssignmentsTab } from 'core/auth/guaranteedAccessRoles';
import { getHasPermissions } from 'utils/auth';
import { formatDateMMDDYYYY, getUnixTime } from 'utils/helpers/date';

import { plLevelByIdForInfoCardRepresentation } from './config/utils/workbook';
const {
  CPS,
} = USERS_GROUPS;

export default ({
  userGroup,
}) => {
  const hasPermissionsToProjectLeadAssignmentsTab = getHasPermissions(userGroup, permissionsToProjectLeadAssignmentsTab);
  const isCPS = getHasPermissions(userGroup, [CPS]);
  return [{
    type: 'table',
    stylesTemplate: {},
    controls: hasPermissionsToProjectLeadAssignmentsTab && !isCPS ? [{
      type: 'link',
      data: '(Manage assignments)',
      pathname: '/delivery/project-leads-management',
      cssRules: `
        margin-left: 1.6rem;
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: ${colorSecondaryText};
      `,
    }] : [],
    content: [{
      title: 'Project Lead Assignments',
      idKey: 'projectKey',
      dataKey: 'plAssignments',
      tableName: 'projectLeadAssignments',
      rowStatusGetter: ({ internalCategoryKey }) => ({
        id: internalCategoryKey,
        isActive: true,
        hasWarning: false,
      }),

      rules: {
        css: {
          gridTemplateConfig: 'grid-template-columns: minmax(auto, 167fr) minmax(auto, 417fr) minmax(auto, 117fr) minmax(auto, 425fr);', // fr units
        },
      },

      dataTemplate: [{
        columnName: 'Category Key',
        type: 'text',
        valueGetter: ({
          internalCategoryKey,
        }) => ({
          type: 'text',
          value: internalCategoryKey,
        }),
      },
      {
        columnName: 'Category Name',
        type: 'text',
        valueGetter: ({
          internalCategoryName,
        }) => ({
          type: 'text',
          value: internalCategoryName,
        }),
      },
      {
        columnName: 'From',
        type: 'text',
        valueGetter: ({
          dateFrom,
        }) => ({
          isDate: true,
          value: formatDateMMDDYYYY(dateFrom),
          unixValue: getUnixTime(dateFrom),
          type: 'text',
        }),
      },
      {
        columnName: 'PLL',
        type: 'text',
        valueGetter: ({ pll }) => ({
          type: 'text',
          value: plLevelByIdForInfoCardRepresentation[pll] || 'n/a',
        }),
      },
      ],
    }],
  }];
};
