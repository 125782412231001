import update from 'immutability-helper';
import { get } from 'lodash';
import {
  createReducer,
} from 'redux-create-reducer';

import {
  channelPartnersActionsTypes,
} from './actions';

import {
  ENTITY_NAME,
  STORE_KEYS,
} from './constants';

const initialState = {
  errors: {},
  entityName: ENTITY_NAME,
  isFiltering: false,
  isFetching: false,
  isFetchingDetails: false,
  isFetchingFiles: false,
  isFormSubmitted: false,
  requestsCounter: 0,
  channelPartnersData: [],
  channelPartnerDetails: {},
  orderRules: {
    channelPartnersList: {
      orderBy: 'name',
      defaultOrderBy: 'name',
    },
    taskOrders: {
      orderBy: 'TO #',
      isReversed: false,
    },
    otherDocuments: {
      orderBy: 'Upload Date',
      isReversed: false,
    },
  },
  filters: {
    [STORE_KEYS.NAME]: {
      type: 'search',
      selected: '',
      isActive: false,
    },
    [STORE_KEYS.COUNTRY]: {
      type: 'select',
      selected: [],
      isActive: false,
    },
    [STORE_KEYS.BILLING_AGENT_NAME]: {
      type: 'select',
      selected: [],
      isActive: false,
    },
    [STORE_KEYS.USER_FULL_NAME]: {
      type: 'select',
      selected: [],
      isActive: false,
    },
    [STORE_KEYS.ASSOCIATED_CLIENT_NAME]: {
      type: 'search',
      selected: '',
      isActive: false,
    },
  },
};

export default createReducer(initialState, {
  [channelPartnersActionsTypes.GET_CHANNEL_PARTNERS_LIST](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },

  [channelPartnersActionsTypes.GET_CHANNEL_PARTNERS_LIST_SUCCESS](state, {
    payload: {
      channelPartners,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      channelPartnersData: {
        $set: channelPartners,
      },
      errors: {
        $unset: ['getChannelPartnersListError'],
      },
    });
  },

  [channelPartnersActionsTypes.GET_CHANNEL_PARTNERS_LIST_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [channelPartnersActionsTypes.GET_CHANNEL_PARTNER_DETAILS](state, {
    meta: {
      withReset,
    },
  }) {
    const stateUpdates = withReset ? {
      channelPartnerDetails: {
        $set: {},
      },
      isFetchingDetails: {
        $set: true,
      },
    } : {
      isFetchingDetails: {
        $set: true,
      },
    };

    return update(state, stateUpdates);
  },

  [channelPartnersActionsTypes.GET_CHANNEL_PARTNER_DETAILS_SUCCESS](state, {
    payload: {
      channelPartnerDetails,
    },
  }) {
    return update(state, {
      isFetchingDetails: {
        $set: false,
      },
      isFetchingFiles: {
        $set: false,
      },
      channelPartnerDetails: {
        $set: channelPartnerDetails,
      },
      errors: {
        $unset: ['getChannelPartnerDetailsDetailError'],
      },
    });
  },

  [channelPartnersActionsTypes.GET_CHANNEL_PARTNER_DETAILS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetchingFiles: {
        $set: false,
      },
      isFetchingDetails: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [channelPartnersActionsTypes.CREATE_CHANNEL_PARTNER](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [channelPartnersActionsTypes.CREATE_CHANNEL_PARTNER_SUCCESS](state, {
    payload: {
      channelPartnerDetails,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      channelPartnerDetails: {
        $merge: channelPartnerDetails,
      },
      errors: {
        $unset: ['createChannelPartnerError'],
      },
    });
  },

  [channelPartnersActionsTypes.CREATE_CHANNEL_PARTNER_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [channelPartnersActionsTypes.UPDATE_CHANNEL_PARTNER_DETAILS](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [channelPartnersActionsTypes.UPDATE_CHANNEL_PARTNER_DETAILS_SUCCESS](state, {
    payload: {
      channelPartnerId: id,
      channelPartnerDetails: data,
    },
  }) {
    const {
      channelPartnersData,
      channelPartnerDetails,
    } = state;

    const channelPartnerIndex = channelPartnersData.findIndex(({
      channelPartnerId,
    }) => channelPartnerId === id);

    if (channelPartnerIndex !== -1) {
      return update(state, {
        errors: {
          $unset: ['updateChannelPartnerDetailsError'],
        },
        isFormSubmitted: {
          $set: false,
        },
        channelPartnersData: {
          [channelPartnerIndex]: {
            $merge: data,
          },
        },
        channelPartnerDetails: {
          $merge: data,
        },
      });
    }

    if (+channelPartnerDetails.channelPartnerId === +id) {
      return update(state, {
        isFormSubmitted: {
          $set: false,
        },
        channelPartnerDetails: {
          $merge: data,
        },
        errors: {
          $unset: ['updateChannelPartnerDetailsError'],
        },
      });
    }

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $unset: ['updateChannelPartnerDetailsError'],
      },
    });
  },

  [channelPartnersActionsTypes.UPDATE_CHANNEL_PARTNER_DETAILS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [channelPartnersActionsTypes.CHANGE_FILTER](state) {
    return update(state, {
      isFiltering: {
        $set: true,
      },
    });
  },

  [channelPartnersActionsTypes.CHANGE_FILTER_SUCCESS](state, {
    payload: {
      storeKey,
      config,
    },
  }) {
    return update(state, {
      isFiltering: {
        $set: false,
      },
      filters: {
        [storeKey]: {
          $merge: config,
        },
      },
    });
  },

  [channelPartnersActionsTypes.RESET_FILTERS](state) {
    return update(state, {
      isFiltering: {
        $set: false,
      },
      filters: {
        $merge: initialState.filters,
      },
    });
  },

  [channelPartnersActionsTypes.SET_ORDER_RULES](state, {
    payload: {
      tableName,
      orderRules,
    },
  }) {
    return update(state, {
      orderRules: {
        [tableName]: {
          $set: orderRules,
        },
      },
    });
  },

  [channelPartnersActionsTypes.CLEAR_ERRORS](state) {
    return update(state, {
      errors: {
        $unset: ['updateChannelPartnersDetailsError'],
      },
    });
  },

  [channelPartnersActionsTypes.GET_UPLOADED_FILE](state) {
    const {
      requestsCounter,
    } = state;

    return update(state, {
      isFetchingFiles: {
        $set: true,
      },
      requestsCounter: {
        $set: requestsCounter + 1,
      },
    });
  },

  [channelPartnersActionsTypes.GET_UPLOADED_FILE_SUCCESS](state, {
    payload: {
      file,
    },
    meta: {
      channelPartnerId,
    },
  }) {
    const {
      fileId,
      title,
      fileName,
      uploadedDate,
      effectiveDate,
      downloadUrl,
    } = file;
    const {
      channelPartnerDetails,
    } = state;
    return update(state, {
      isFetchingFiles: {
        $set: false,
      },
      errors: {
        $unset: ['getUploadedFileError'],
      },
      requestsCounter: {
        $set: 0,
      },
      channelPartnerDetails: {
        files: {
          $set: [{
            fileId,
            channelPartnerId,
            title,
            fileName,
            uploadedDate,
            effectiveDate,
            downloadUrl,
          }, ...get(channelPartnerDetails, 'files', [])],
        },
      },
    });
  },

  [channelPartnersActionsTypes.GET_UPLOADED_FILE_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetchingFiles: {
        $set: false,
      },
      requestsCounter: {
        $set: 0,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [channelPartnersActionsTypes.DELETE_DOCUMENT](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [channelPartnersActionsTypes.DELETE_DOCUMENT_SUCCESS](state, {
    payload: {
      fileId: id,
    },
  }) {
    const {
      channelPartnerDetails,
    } = state;
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $unset: ['deleteDocumentError'],
      },
      channelPartnerDetails: {
        files: {
          $apply: () => channelPartnerDetails.files.filter(({
            fileId,
          }) => fileId !== id),
        },
      },
    });
  },

  [channelPartnersActionsTypes.DELETE_DOCUMENT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
});
