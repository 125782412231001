import gql from 'graphql-tag';

import {
  ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX,
  ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX,
  ANNUAL_REPORT_SUMMARY_COUNT_PREFIX,
  AVERAGE_EXCHANGE_POSTFIX,
  EXCHANGE_POSTFIX,
  PROJECTS_TYPES,
  REPORTS_TYPES,
  SENIORITY,
  STORE_KEYS,
} from './constants';

const { STAFF, JUNIOR, SENIOR, STAFF_FULL, JUNIOR_FULL, SENIOR_FULL } =
  SENIORITY;
const { USD, RUR, PLN } = STORE_KEYS;
const annualPayrollReportSummaryTab = `
annualPayrollReportSummaryTab(year: $year, devcenterIds: $devcenterIds, deliveryOnly: $deliveryOnly, currencies: $currencies) {
  seniorityRatio {
    countOfJuniors
    countOfStaffs
    countOfSeniors
    proportionSenStaJun
  }
  senioritySalaries {
    ${JUNIOR_FULL}${USD}: juniorUsd
    ${JUNIOR_FULL}${RUR}: juniorRur
    ${JUNIOR_FULL}${RUR}${USD}${EXCHANGE_POSTFIX}: juniorRurUsdExchange
    ${JUNIOR_FULL}${PLN}: juniorPln
    ${JUNIOR_FULL}${PLN}${USD}${EXCHANGE_POSTFIX}: juniorPlnUsdExchange
    ${JUNIOR_FULL}: averageJuniorSalaryUsd
    ${STAFF_FULL}${USD}: staffUsd
    ${STAFF_FULL}${RUR}: staffRur
    ${STAFF_FULL}${RUR}${USD}${EXCHANGE_POSTFIX}: staffRurUsdExchange
    ${STAFF_FULL}${PLN}: staffPln
    ${STAFF_FULL}${PLN}${USD}${EXCHANGE_POSTFIX}: staffPlnUsdExchange
    ${STAFF_FULL}: averageStaffSalaryUsd
    ${SENIOR_FULL}${USD}: seniorUsd
    ${SENIOR_FULL}${RUR}: seniorRur
    ${SENIOR_FULL}${RUR}${USD}${EXCHANGE_POSTFIX}: seniorRurUsdExchange
    ${SENIOR_FULL}${PLN}: seniorPln
    ${SENIOR_FULL}${PLN}${USD}${EXCHANGE_POSTFIX}: seniorPlnUsdExchange
    ${SENIOR_FULL}: averageSeniorSalaryUsd
  }
  currencyBasedResources {
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${JUNIOR}0${USD}: countOfJ0UsdBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${JUNIOR}1${USD}: countOfJ1UsdBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${JUNIOR}2${USD}: countOfJ2UsdBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${STAFF}0${USD}: countOfSt0UsdBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${STAFF}1${USD}: countOfSt1UsdBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${STAFF}2${USD}: countOfSt2UsdBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${SENIOR}0${USD}: countOfS0UsdBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${SENIOR}1${USD}: countOfS1UsdBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${SENIOR}2${USD}: countOfS2UsdBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${JUNIOR}0${RUR}: countOfJ0RurBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${JUNIOR}1${RUR}: countOfJ1RurBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${JUNIOR}2${RUR}: countOfJ2RurBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${STAFF}0${RUR}: countOfSt0RurBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${STAFF}1${RUR}: countOfSt1RurBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${STAFF}2${RUR}: countOfSt2RurBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${SENIOR}0${RUR}: countOfS0RurBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${SENIOR}1${RUR}: countOfS1RurBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${SENIOR}2${RUR}: countOfS2RurBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${JUNIOR}0${PLN}: countOfJ0PlnBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${JUNIOR}1${PLN}: countOfJ1PlnBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${JUNIOR}2${PLN}: countOfJ2PlnBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${STAFF}0${PLN}: countOfSt0PlnBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${STAFF}1${PLN}: countOfSt1PlnBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${STAFF}2${PLN}: countOfSt2PlnBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${SENIOR}0${PLN}: countOfS0PlnBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${SENIOR}1${PLN}: countOfS1PlnBasedResources
    ${ANNUAL_REPORT_SUMMARY_COUNT_PREFIX}${SENIOR}2${PLN}: countOfS2PlnBasedResources
  }
}
`;
const analysisStatsFields = `
  annualPayrollReportAnalysisTab(year: $year devcenterIds: $devcenterIds deliveryOnly: $deliveryOnly currencies: $currencies) {
    resourcesStats {
      countOfJ0
      countOfJ1
      countOfJ2
      countOfSt0
      countOfSt1
      countOfSt2
      countOfS0
      countOfS1
      countOfS2
    }
    raisesList {
      averageSalaryUsd
      averageSalaryRur
      averageSalaryRurExchangeRate
      averageSalaryPln
      averageSalaryPlnExchangeRate
      totalAverageSalaryUsd

      countOfRaisesUsd
      countOfRaisesRur
      countOfRaisesPln

      sumOfRaisesUsd
      sumOfRaisesRur
      sumOfRaisesPln

      averageUsdRaisePercentage
      averageRurRaisePercentage
      averagePlnRaisePercentage
    }
    averageSalariesStats {
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}0${USD}: averageForJ0Usd
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}0${RUR}: averageForJ0Rur
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}0${RUR}${AVERAGE_EXCHANGE_POSTFIX}: averageForJ0RurExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}0${PLN}: averageForJ0Pln
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}0${PLN}${AVERAGE_EXCHANGE_POSTFIX}: averageForJ0PlnExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX}${JUNIOR}0${USD}: totalForJ0Usd

      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}1${USD}: averageForJ1Usd
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}1${RUR}: averageForJ1Rur
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}1${RUR}${AVERAGE_EXCHANGE_POSTFIX}: averageForJ1RurExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}1${PLN}: averageForJ1Pln
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}1${PLN}${AVERAGE_EXCHANGE_POSTFIX}: averageForJ1PlnExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX}${JUNIOR}1${USD}: totalForJ1Usd

      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}2${USD}: averageForJ2Usd
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}2${RUR}: averageForJ2Rur
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}2${RUR}${AVERAGE_EXCHANGE_POSTFIX}: averageForJ2RurExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}2${PLN}: averageForJ2Pln
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${JUNIOR}2${PLN}${AVERAGE_EXCHANGE_POSTFIX}: averageForJ2PlnExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX}${JUNIOR}2${USD}: totalForJ2Usd

      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}0${USD}: averageForSt0Usd
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}0${RUR}: averageForSt0Rur
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}0${RUR}${AVERAGE_EXCHANGE_POSTFIX}: averageForSt0RurExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}0${PLN}: averageForSt0Pln
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}0${PLN}${AVERAGE_EXCHANGE_POSTFIX}: averageForSt0PlnExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX}${STAFF}0${USD}: totalForSt0Usd

      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}1${USD}: averageForSt1Usd
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}1${RUR}: averageForSt1Rur
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}1${RUR}${AVERAGE_EXCHANGE_POSTFIX}: averageForSt1RurExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}1${PLN}: averageForSt1Pln
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}1${PLN}${AVERAGE_EXCHANGE_POSTFIX}: averageForSt1PlnExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX}${STAFF}1${USD}: totalForSt1Usd

      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}2${USD}: averageForSt2Usd
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}2${RUR}: averageForSt2Rur
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}2${RUR}${AVERAGE_EXCHANGE_POSTFIX}: averageForSt2RurExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}2${PLN}: averageForSt2Pln
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${STAFF}2${PLN}${AVERAGE_EXCHANGE_POSTFIX}: averageForSt2PlnExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX}${STAFF}2${USD}: totalForSt2Usd

      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}0${USD}: averageForS0Usd
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}0${RUR}: averageForS0Rur
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}0${RUR}${AVERAGE_EXCHANGE_POSTFIX}: averageForS0RurExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}0${PLN}: averageForS0Pln
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}0${PLN}${AVERAGE_EXCHANGE_POSTFIX}: averageForS0PlnExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX}${SENIOR}0${USD}: totalForS0Usd

      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}1${USD}: averageForS1Usd
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}1${RUR}: averageForS1Rur
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}1${RUR}${AVERAGE_EXCHANGE_POSTFIX}: averageForS1RurExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}1${PLN}: averageForS1Pln
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}1${PLN}${AVERAGE_EXCHANGE_POSTFIX}: averageForS1PlnExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX}${SENIOR}1${USD}: totalForS1Usd

      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}2${USD}: averageForS2Usd
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}2${RUR}: averageForS2Rur
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}2${RUR}${AVERAGE_EXCHANGE_POSTFIX}: averageForS2RurExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}2${PLN}: averageForS2Pln
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_PREFIX}${SENIOR}2${PLN}${AVERAGE_EXCHANGE_POSTFIX}: averageForS2PlnExchangeRate
      ${ANNUAL_REPORT_ANALYSIS_AVERAGE_TOTAL_PREFIX}${SENIOR}2${USD}: totalForS2Usd
    }
  }
`;

const getReportFields = (projectType) => {
  switch (projectType) {
    case PROJECTS_TYPES.FP:
    case PROJECTS_TYPES.PTM:
      return `projectRows: ptmFpReportRows {
              client
              clientId
              project
              taskOrderId
              taskOrder
              resource
              devstaffId
              devcenterShortname
              devcenterId
              seniority
              rate
              rateWithSurcharge
              surchargePercentage
              hours
              total
              totalWithSurcharge
              isDevstaffNotInTo
              fullname
              isDevstaffWithoutRates
              isDiscount
            }
            overtimeProjectRows: overtimeReportRows {
              client
              taskOrder
              clientId
              project
              taskOrderId
              resource
              devstaffId
              devcenterShortname
              devcenterId
              seniority
              rate
              rateWithSurcharge
              surchargePercentage
              hours
              total
              totalWithSurcharge
              isDevstaffNotInTo
              isDevstaffWithoutRates
              isDiscount
            }
            `;
    case PROJECTS_TYPES.TTM:
      return `projectRows: ttmReportRows {
              ooo
              total
              totalWithSurcharge
              isDevstaffNotInTo
              client
              clientId
              project
              taskOrderId
              isRatePlatinum
              hours: hrsInPeriod
              rate
              rateWithSurcharge
              surchargePercentage
              utilization
              dateFrom
              dateThrough
              devcenterShortname
              devcenterId
              seniority
              resource
              devstaffId
              taskOrder
              fullname
              isDevstaffWithoutRates
            }
            overtimeProjectRows: overtimeReportRows {
              client
              taskOrder
              clientId
              project
              taskOrderId
              resource
              devstaffId
              devcenterShortname
              devcenterId
              seniority
              rateWithSurcharge
              surchargePercentage
              rate
              hours
              total
              totalWithSurcharge
              isDevstaffNotInTo
              isDevstaffWithoutRates
            }
            `;
    case PROJECTS_TYPES.ODC:
      return `projectRows: odcReportRows {
              client
              clientId
              project
              taskOrder
              taskOrderId
              resource
              devstaffId
              isRatePlatinum
              devcenterShortname
              devcenterId
              seniority
              total
              totalWithSurcharge
              isDevstaffNotInTo
              dateFrom
              dateThrough
              fullname
              isDevstaffWithoutRates
              surchargePercentage
              rateMoWithSurcharge
              rateMoPlatinum
              rateMo
            }
            overtimeProjectRows: overtimeReportRows {
              client
              taskOrder
              clientId
              project
              taskOrderId
              resource
              devstaffId
              devcenterShortname
              devcenterId
              seniority
              rate
              rateWithSurcharge
              surchargePercentage
              hours
              total
              totalWithSurcharge
              isDevstaffNotInTo
              isDevstaffWithoutRates
            }
            `;
    default:
      return null;
  }
};

export default {
  getSalesList: gql`
    {
      salesList: salesUsers {
        value: id
        label: fullname
      }
    }
  `,
  getHolidaysList: gql`
    {
      holidaysList: holidays {
        date
        devcenter
      }
    }
  `,
  getTaskOrdersDetails: (taskOrderId) => gql`{
    taskOrderDetails: node(id: "TaskOrder:${taskOrderId}") {
      ... on TaskOrder {
        msaName
        title
        taskOrderId
        description
        issueDate
        effectiveDate
        projectType
        endDate
        clientPm
        emailReports
        projectId
        client {
          name,
          clientId,
          channelPartnerId
        }
        clientCategories {
          key
          name
          isAssigned
        }
      }
    }
  }`,
  getTaskOrdersList: gql`
    {
      taskOrdersList: taskOrders {
        value: projectId
        title
        taskOrderId
        isActive
        endDate
        isManuallyDeactivated
      }
    }
  `,
  getBillingSummaryReport: (reportType) => {
    switch (reportType) {
      case REPORTS_TYPES.SALES_REPORT:
        return gql`
          query commissionSummaryReport(
            $month: Int
            $year: Int
            $userId: Int
            $reportFor: Int
          ) {
            summaryReport: commissionSummaryReport(
              month: $month
              year: $year
              accManager: $reportFor
              userId: $userId
            ) {
              reportType
              ptmFpReportRows {
                isFirstInvoiceDateSet
                projectName
                taskOrderId
                taskOrder
                fullname
                resource
                devstaffId
                seniority
                rate
                hours
                total
                isDevstaffNotInTo
                isDevstaffWithoutRates
                commissionLevel
                commissionAge
                isCommissionEligibility
                commissionDate
                eligibleAmount
                commissionRate
                commissionUsd
                isDiscount
              }
              odcReportRows {
                isFirstInvoiceDateSet
                projectName
                taskOrder
                taskOrderId
                fullname
                resource
                devstaffId
                seniority
                total
                isDevstaffNotInTo
                isDevstaffWithoutRates
                dateFrom
                dateThrough
                isRatePlatinum
                commissionLevel
                commissionAge
                isCommissionEligibility
                commissionDate
                eligibleAmount
                commissionRate
                commissionUsd
              }
              ttmReportRows {
                isFirstInvoiceDateSet
                ooo
                total
                isDevstaffNotInTo
                isDevstaffWithoutRates
                projectName
                taskOrderId
                hours: hrsInPeriod
                rate
                utilization
                dateFrom
                dateThrough
                seniority
                fullname
                resource
                devstaffId
                taskOrder
                isRatePlatinum
                commissionLevel
                commissionAge
                isCommissionEligibility
                commissionDate
                eligibleAmount
                commissionRate
                commissionUsd
              }
              overtimeReportRows {
                isFirstInvoiceDateSet
                projectName
                taskOrder
                taskOrderId
                fullname
                resource
                devstaffId
                seniority
                rate
                hours
                total
                isDevstaffNotInTo
                isDevstaffWithoutRates
                commissionLevel
                commissionAge
                isCommissionEligibility
                commissionDate
                eligibleAmount
                commissionRate
                commissionUsd
                isDiscount
              }
            }
          }
        `;
      case REPORTS_TYPES.GM:
        return gql`
          query billingSummaryReport($month: Int, $year: Int) {
            summaryReport: gmSummaryReport(month: $month, year: $year) {
              reportType
              reportCalculationDate
              ptmFpReportRows: ptmGmSummaryRows {
                client
                clientId
                project
                taskOrder
                taskOrderId
                resource
                devstaffId
                seniority
                hours
                rate
                surchargePercentage
                delRate
                revenue
                cop
                cos
                total: gm
                isDevstaffNotInTo
                isDevstaffWithoutRates
                isDiscount
              }
              odcReportRows: odcGmSummaryRows {
                client
                clientId
                project
                taskOrder
                taskOrderId
                resource
                devstaffId
                seniority
                dateFrom
                dateThrough
                revenue
                cop
                cos
                surchargePercentage
                isRatePlatinum
                total: gm
                isDevstaffNotInTo
                isDevstaffWithoutRates
              }
              ttmReportRows: ttmGmSummaryRows {
                client
                clientId
                seniority
                project
                taskOrder
                taskOrderId
                resource
                devstaffId
                dateFrom
                dateThrough
                utilization
                rate
                surchargePercentage
                ooo
                hours: hrsInPeriod
                revenue
                cop
                cos
                total: gm
                isRatePlatinum
                isDevstaffNotInTo
                isDevstaffWithoutRates
              }
              overtimeReportRows: overtimeGmSummaryRows {
                client
                clientId
                project
                taskOrder
                taskOrderId
                resource
                devstaffId
                hours
                rate
                surchargePercentage
                delRate
                revenue
                seniority
                cop
                cos
                total: gm
                isDevstaffNotInTo
                isDevstaffWithoutRates
                isDiscount
              }
              nonBillableReportRows: nonBillableGmSummaryRows {
                project
                date
                resource
                devstaffId
                issue
                task: type
                issueSummary
                clientCategoryName
                internalCategoryName
                hours
                overtime
                worklog
              }
            }
          }
        `;
      case REPORTS_TYPES.TO:
        return gql`
          query billingSummaryReport(
            $month: Int
            $year: Int
            $billingAgentIds: [Int]
            $userId: Int
          ) {
            summaryReport: billingSummaryReport(
              month: $month
              year: $year
              accManager: $userId
              billingAgentIds: $billingAgentIds
            ) {
              reportType
              reportCalculationDate
              ptmFpReportRows {
                client
                clientId
                project
                taskOrderId
                taskOrder
                resource
                devstaffId
                seniority
                rate
                hours
                total
                isDevstaffNotInTo
                isDevstaffWithoutRates
                isDiscount
              }
              ttmReportRows {
                ooo
                total
                isDevstaffNotInTo
                client
                clientId
                project
                taskOrderId
                hours: hrsInPeriod
                rate
                utilization
                dateFrom
                dateThrough
                seniority
                resource
                devstaffId
                isRatePlatinum
                taskOrder
                isDevstaffWithoutRates
              }
              odcReportRows {
                client
                clientId
                project
                taskOrder
                taskOrderId
                resource
                devstaffId
                seniority
                total
                isDevstaffNotInTo
                isRatePlatinum
                dateFrom
                dateThrough
                isDevstaffWithoutRates
              }

              nonBillableReportRows {
                project
                resource
                taskOrderId
                devstaffId
                date
                task
                issue
                issueSummary
                clientCategoryName
                internalCategoryName
                hours
                worklog
              }
              overtimeReportRows {
                client
                taskOrder
                clientId
                project
                taskOrderId
                resource
                devstaffId
                seniority
                rate
                hours
                total
                isDevstaffNotInTo
                isDevstaffWithoutRates
                isDiscount
              }
            }
          }
        `;
      case REPORTS_TYPES.DTO:
        return gql`
          query billingSummaryReport(
            $devcenterIds: [Int]
            $month: Int
            $year: Int
          ) {
            summaryReport: billingSummaryDtoReport(
              month: $month
              year: $year
              devcenterIds: $devcenterIds
            ) {
              reportType
              reportCalculationDate
              ptmFpReportRows {
                client
                clientId
                project
                taskOrderId
                taskOrder
                resource
                devstaffId
                devcenterId
                seniority
                rate
                hours
                total
                isDevstaffNotInTo
                isDevstaffWithoutRates
                surchargePercentage
              }
              promoReportRows {
                project
                seniority
                devstaffId
                devcenterId
                resource
                rate
                hours
                total
              }
              ttmReportRows {
                ooo
                total
                isDevstaffNotInTo
                client
                clientId
                project
                taskOrderId
                hours: hrsInPeriod
                rate
                utilization
                dateFrom
                dateThrough
                seniority
                resource
                devstaffId
                devcenterId
                taskOrder
                isDevstaffWithoutRates
                surchargePercentage
              }
              odcReportRows {
                client
                clientId
                project
                taskOrder
                taskOrderId
                resource
                devstaffId
                devcenterId
                seniority
                total
                isDevstaffNotInTo
                dateFrom
                dateThrough
                isDevstaffWithoutRates
                surchargePercentage
              }
              nonBillableReportRows {
                project
                resource
                devstaffId
                devcenterId
                taskOrderId
                date
                issue
                issueSummary
                hours
                task
                clientCategoryName
                internalCategoryName
                worklog
              }
              overtimeReportRows {
                client
                taskOrder
                clientId
                project
                taskOrderId
                resource
                devstaffId
                devcenterId
                seniority
                rate
                hours
                total
                isDevstaffNotInTo
                isDevstaffWithoutRates
                surchargePercentage
              }
            }
          }
        `;
      case REPORTS_TYPES.ANNUAL_REPORT:
        return gql`
        query annualPayrollReport($year: Int, $devcenterIds: [Int], $deliveryOnly: String, $currencies: [String]) {
            ${analysisStatsFields}
            ${annualPayrollReportSummaryTab}
            annualReportStaffDataRows: annualPayrollReport(year: $year, devcenterIds: $devcenterIds, deliveryOnly: $deliveryOnly) {
              devstaffId
              devstaffName
              devcenterIds
              isAdminStaff
              hasSalaryRise
              employeeCurrenciesForYear
              monthsData: monthData {
                seniority: seniorityInt
                probation
                usd: usdStats {
                  rate
                  rateUsd
                  hasRise
                  riseInMiddle
                  usdRaisePercentage
                }
                pln: plnStats {
                  rate
                  rateUsd
                  hasRise
                  riseInMiddle
                  usdRaisePercentage
                }
                rur: rurStats {
                  rate
                  rateUsd
                  hasRise
                  riseInMiddle
                  usdRaisePercentage
                }
              }
            }
          }
        `;
      case REPORTS_TYPES.PL_COMMISSION:
        return gql`
          query plCommissionReport($month: Int, $year: Int) {
            summaryReport: plCommissionReport(month: $month, year: $year) {
              reportType
              reportCalculationDate
              plCommissionReportRows {
                plName
                plFullname
                taskOrderId
                projectKey
                projectName
                taskOrder
                role
                percentage
                seniority
                comHours
                forfeitedHours
                billedHours
                rate
                forfeited
                commissionPaid
              }
            }
          }
        `;
      default:
        return null;
    }
  },
  getBillingProjectReport: (reportType, accManager, projectType) => {
    switch (reportType) {
      case REPORTS_TYPES.TO:
        return gql`query billingToReport($devcenterIds: [Int] $taskOrderId: Int $month: Int $year: Int) {
          billingToReport: billingToReport(month: $month year: $year taskOrderId: $taskOrderId devcenterIds: $devcenterIds accManager: ${accManager}) {
            reportType
            ${getReportFields(projectType)}
            worklogReportRows {
              project
              taskOrderId
              date
              fullname
              resource
              devstaffId
              issue
              issueSummary
              issueType
              task
              hours
              overtime
              clientCategoryName
              internalCategoryName
              worklog
            }
            nonBillableWorklogReportRows: nonBillableReportRows {
              project
              taskOrderId
              date
              fullname
              resource
              devstaffId
              issue
              issueSummary
              issueType
              task
              hours
              overtime
              clientCategoryName
              internalCategoryName
              worklog
            }
        }
      }`;
      case REPORTS_TYPES.DTO:
        return gql`query billingDtoReport($devcenterIds: [Int] $taskOrderId: Int $month: Int $year: Int) {
          billingDtoReport: billingDtoReport(month: $month year: $year taskOrderId: $taskOrderId devcenterIds: $devcenterIds accManager: ${accManager}) {
            reportType
           ${getReportFields(projectType)}
           worklogReportRows {
            project
            taskOrderId
            date
            fullname
            resource
            devstaffId
            issue
            issueSummary
            issueType
            task
            hours
            overtime
            clientCategoryName
            internalCategoryName
            worklog
            devcenterId
          }
          nonBillableWorklogReportRows: nonBillableReportRows {
            project
            taskOrderId
            date
            fullname
            resource
            devstaffId
            issue
            issueSummary
            issueType
            task
            hours
            overtime
            clientCategoryName
            internalCategoryName
            worklog
            devcenterId
          }
        }
      }`;
      default:
        return null;
    }
  },
  getInternalToReport: gql`
    query internalTo($year: Int, $month: Int) {
      internalTo(year: $year, month: $month) {
        internalToList {
          clientId
          taskOrder
          taskOrderId
          projectName
          customer
          devcenter
          internalContract
          projectModel
          effectiveDate
          dateTo
        }
        contractList {
          id
          name
        }
      }
    }
  `,
};
