import React from 'react';

import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

const StyledTitle = styled.span`
  border-bottom: 1px solid #bbbdc0;
  line-height: 2.1rem;

  ${({ cssRules }) => css`${cssRules}`}
`;

const FormTableHeading = ({
  fieldData,
}) => {
  const { title, cssRules } = fieldData;
  return (
    <div className="details-form__form-heading form-heading">
      <StyledTitle
        className="form-heading__title"
        cssRules={cssRules}
      >
        {title}
      </StyledTitle>
    </div>
  );
};


FormTableHeading.propTypes = {
  fieldData: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    styles: PropTypes.shape({

    }),
  }),
};

FormTableHeading.defaultProps = {
  fieldData: {
    title: '',
    styles: {},
  },
};


export default FormTableHeading;
