import { getFormattedDate } from '../helpers';

export const createDeleteRecordAction = (record, devstaffId) => {
  const { recordId = '', validFrom = '' } = record;

  return ({
    currentModal: 'CONFIRM_ACTION_MODAL',
    content: [
      {
        type: 'title',
        data: `Delete this position record dated as of ${getFormattedDate(validFrom)}?`,
      },
      {
        type: 'description',
        data: 'Once deleted, it cannot be restored.',
      },
    ],
    acceptActionName: 'deleteWorkbookRecordDelivery',
    acceptActionTitle: 'Delete',
    acceptActionArguments: {
      recordId,
      devstaffId,
    },
  });
};
