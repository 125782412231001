import {
  generateSelectItems,
  shouldDisplayEmailTemplateNotificationsData,
} from 'core/configurations/utils';
import { omit } from 'lodash';
import { createSelector } from 'reselect';

export const selectEmailTemplateNotifications = (state) => state.configurations.emailTemplateNotifications;
export const selectConfigurationFilters = (state) => state.configurations.filters;

export const selectNotificationsFilters =
  createSelector(selectConfigurationFilters, (filters) => filters.notifications);

export const selectEmailTemplateNotificationsFilter =
  createSelector(selectNotificationsFilters, (filters) => filters.emailTemplate);
export const selectIsActiveFilters = createSelector(selectEmailTemplateNotificationsFilter, (filters) => Object.values(filters).some((filter) => filter.isActive));

export const selectNotificationsStatuses = createSelector(selectEmailTemplateNotifications, (state) => state.emailTemplateNotificationsStatuses);
export const selectNotificationsType = createSelector(selectEmailTemplateNotifications, (state) => state.notificationsType);
export const selectBillingAgents = createSelector(selectEmailTemplateNotifications, (state) => state.billingAgentIds);

export const selectEmailTemplates = createSelector(selectEmailTemplateNotifications, (state) => state.emailTemplates);

export const selectBillingAgentIds = createSelector(
  selectBillingAgents,
  (billingAgentIds) => generateSelectItems(billingAgentIds, {
    key: 'billingAgentId',
    valueAccessor: 'billingAgentId',
    labelAccessor: 'shortName',
  })
);

export const selectEmailTemplatesIds = createSelector(
  selectEmailTemplates,
  (billingAgentIds) => generateSelectItems(billingAgentIds, {
    key: 'emailTemplateId',
    valueAccessor: 'emailTemplateId',
    labelAccessor: 'name',
  })
);

export const selectNotificationTypes = createSelector(
  selectNotificationsType,
  (notificationsType) => generateSelectItems(notificationsType, {
    key: 'notificationId',
    valueAccessor: 'notificationId',
    labelAccessor: 'notificationTitle',
  })
);

export const selectSendingEmails = (state) => state.configurations.sendingEmailsProgress;
export const selectSendingEmailsProgress = createSelector(selectSendingEmails, (state) => state.notifications.emailTemplate);
export const selectIsSendingEmailsActive = createSelector(selectSendingEmailsProgress, (state) => state.inProgress);

export const selectEmailTemplateNotificationsVersion = createSelector(selectEmailTemplateNotifications, (state) => state.emailTemplateNotificationsVersion);
export const selectEmailTemplateNotificationsVersionsList = createSelector(
  selectEmailTemplateNotifications,
  ({ emailTemplateNotificationsVersions }) => {
    if (!emailTemplateNotificationsVersions) {
      return [];
    }

    return emailTemplateNotificationsVersions.map((version) => ({
      value: version.versionId,
      label: `Version ${version.versionId}`,
    }));
  }
);
export const selectEmailsData = createSelector(selectEmailTemplateNotifications, (state) => state.emailTemplateNotificationsData);

export const selectFormData = createSelector(
  selectEmailsData,
  selectEmailTemplateNotificationsFilter,
  selectIsActiveFilters,
  (data, filterState, hasActiveFilters) => {
    const items = data.map((item) => omit(item, ['version', 'emailDate', 'lineId', 'content', 'year']));

    if (hasActiveFilters) {
      return items.filter((emailTemplateNotification) => shouldDisplayEmailTemplateNotificationsData(emailTemplateNotification, filterState));
    }

    return items;
  }
);

export const selectCounter = createSelector(selectEmailsData, selectFormData, (data, filteredEmailTemplateNotifications) => ({
  total: data.length,
  selected: filteredEmailTemplateNotifications.length,
  percentage: data.length ? Math.round((filteredEmailTemplateNotifications.length / filteredEmailTemplateNotifications.length) * 100) : 0,
}));
