import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Inputs from 'components/inputs';

import './styles.scss';


const RangeFilter = ({ onChange, selected, placeholder, validationRules }) => {
  const [filterValues, setFilterValues] = useState(selected);

  const handleMinChange = (value) =>
    setFilterValues((prevFilterValues) => ({ ...prevFilterValues, min: value }));

  const handleMaxChange = (value) =>
    setFilterValues((prevFilterValues) => ({ ...prevFilterValues, max: value }));

  useEffect(() => {
    if (filterValues !== selected) {
      setFilterValues(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (filterValues !== selected) {
      onChange(filterValues);
    }
  }, [filterValues]);

  return (
    <div
      className="range-input"
    >
      <Inputs.NumericalInput
        onChange={handleMinChange}
        name="min"
        placeholder={placeholder}
        validationRules={validationRules}
        inputValue={filterValues.min}
      />

      <span
        className="range-input__separator"
      >
        &ndash;
      </span>

      <Inputs.NumericalInput
        onChange={handleMaxChange}
        name="max"
        placeholder={placeholder}
        validationRules={validationRules}
        inputValue={filterValues.max}
      />
    </div>
  );
};

RangeFilter.propTypes = {
  selected: PropTypes.shape({
    min: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    max: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  validationRules: PropTypes.shape({
    decimalScale: PropTypes.number,
    isInteger: PropTypes.bool,
    isRates: PropTypes.bool,
    maxValue: PropTypes.number,
  }),
};

RangeFilter.defaultProps = {
  selected: {
    min: null,
    max: null,
  },
  onChange: () => null,
  placeholder: '. . .',
  validationRules: {},
};


export default RangeFilter;
