import { useEffect, useState } from 'react';

import { INITIAL_COUNT_FIELDS } from 'forms/categories-form/constants';
import { delay } from 'lodash';
import { useInView } from 'react-intersection-observer';

export const useInfiniteScroller = (fields, defaultCounter) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [counterFields, setCounterFields] = useState(defaultCounter || INITIAL_COUNT_FIELDS);

  useEffect(() => {
    const isShowLoader = (inView && counterFields < fields.length) && !isLoading;

    if (isShowLoader) {
      setIsLoading(true);

      delay(() => {
        setCounterFields((prevState) => prevState + INITIAL_COUNT_FIELDS);
        setIsLoading(false);
      }, 1000);
    }
  }, [inView]);

  return {
    formFields: fields.slice(0, counterFields),
    counter: counterFields - 1,
    ref,
    isLoading,
    setCounterFields,
  };
};
