import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import './styles.scss';


const Title = ({ children, isActiveColor, additionalClass }) => (
  <div className={classNames('title', additionalClass, { 'title--dark': !isActiveColor })}>
    {children}
  </div>
);


Title.propTypes = {
  children: PropTypes.string.isRequired,
  isActiveColor: PropTypes.bool,
  additionalClass: PropTypes.string,
};

Title.defaultProps = {
  isActiveColor: null,
  additionalClass: null,
};

export default Title;
