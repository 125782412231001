import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import DetailsForm from 'components/details-form';
import SingleTable from 'components/details-table/single-table';
import Controls from 'components/list-controls';

import { configurationsActions } from 'core/configurations/actions';
import { selectDiscountSurchargeManagementFormContent } from 'core/configurations/selectors';
import GoTop from 'elements/go-top-button';
import Wrapper from 'elements/wrapper';
import { getDirtyDiscountSurchargeRates } from 'layouts/discount-surcharge-management/utils';
import { createStructuredSelector } from 'reselect';
import { autoScrollToTop } from 'utils/react';

import { discountSurchargeRatesTableHeaderModel } from './model';

import {
  wrapperCssRules,
  formWrapperStylesTemplate,
  formMainControlsConfig,
} from './styles';

const DiscountSurchargeManagement = ({
  location,
  entityName,
  isFetching,
  isFormSubmitted,
  getDiscountSurchargeRates,
  updateDiscountSurchargeRates,
  discountSurchargeManagementFormContent,
}) => {
  autoScrollToTop(location);

  useEffect(() => {
    getDiscountSurchargeRates();
  }, []);

  const onSubmit = (data) => {
    const { fields, initialValues } = data;

    const payload = getDirtyDiscountSurchargeRates(fields, initialValues);

    if (!payload) {
      return;
    }

    updateDiscountSurchargeRates(payload);
  };

  const tableRules = `{
      position: sticky;
      top:6.6rem;
      z-index: 1;
    }`;

  return (
    <>
      <Controls title="Discount and Surcharge Management" />

      <Wrapper isGrow cssRules={wrapperCssRules}>
        <SingleTable
          entityName={entityName}
          data={[
            {
              id: 1,
              tableData: [],
              stylesTemplate: {
                rowStyles: `
                  &&{
                    margin:0;
                    border: none;
                    display: none;
                  }
                `,
              },
            },
          ]}
          model={discountSurchargeRatesTableHeaderModel}
          tableName="discountSurchargeRates"
          isFetching={false}
          withoutLoader
          controls={[]}
          cssRules={tableRules}
          orderRules={{}}
        />
        <DetailsForm
          withErrorBox
          formId="formId"
          entityName={entityName}
          onSubmit={onSubmit}
          isFormSubmitted={isFormSubmitted}
          isFetchingDetails={isFetching}
          content={discountSurchargeManagementFormContent}
          stylesTemplate={formWrapperStylesTemplate}
          mainControlsConfig={formMainControlsConfig}
        />
      </Wrapper>

      <GoTop />
    </>
  );
};

DiscountSurchargeManagement.propTypes = {
  location: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  getDiscountSurchargeRates: PropTypes.func.isRequired,
  updateDiscountSurchargeRates: PropTypes.func.isRequired,
  discountSurchargeManagementFormContent: PropTypes.shape({}).isRequired,
};

DiscountSurchargeManagement.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  discountSurchargeManagementFormContent:
    selectDiscountSurchargeManagementFormContent,
});

const mapDispatchToProps = {
  getDiscountSurchargeRates: configurationsActions.getDiscountSurchargeRates,
  updateDiscountSurchargeRates:
    configurationsActions.updateDiscountSurchargeRates,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscountSurchargeManagement);
