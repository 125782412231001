import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import Loader from 'components/loader';


import ActionButton from 'elements/action-button';
import GeneratorInput from './documents-generator-input';
import GeneratorCalendar from './documents-generator-calendar';

import './styles.scss';


const units = {
  text: GeneratorInput,
  calendar: GeneratorCalendar,
  actionButton: ActionButton,
};


const DocumentGenerateForm = ({ formConfig, onSubmit, isFetchingFiles, unitActions: actions, msaDate }) => {
  const { title, fields, initialValues, validationSchema } = formConfig;
  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      render={(formProps) => {
        const { values, setFieldValue, isSubmitting } = formProps;
        if (msaDate) {
          values.date = msaDate;
        }
        const hasEmptyFields = Boolean(Object.values(values).filter((value) => value === '').length);
        return (
          <div className="document-generate-form__wrapper">
            <Form className="document-generate-form">
              {
                isFetchingFiles && isSubmitting &&
                <Loader withOverlay />
              }
              <div className="document-generate-form__title">
                {title}
              </div>
              {
                fields.map(({ type, ...fieldConfig }, index) => {
                  if (type === 'actionButton') {
                    const {
                      data,
                      imgSrc,
                      isActive,
                      withBody,
                      isHidden,
                      className,
                      cssRules,
                      withStopPropagation,
                      disabled,
                      actionName,
                      actionArguments,
                    } = fieldConfig;
                    actionArguments.generatedMsa = values.generatedMsa;
                    const onClick = (args = {}) => actions[actionName]({ ...args, ...actionArguments });

                    return (
                      React.createElement(
                        units[type],
                        {
                          formProps,
                          key: index, // eslint-disable-line react/no-array-index-key
                          fieldConfig,
                          onClick,
                          data,
                          imgSrc,
                          isActive,
                          withBody,
                          isHidden,
                          className,
                          cssRules,
                          withStopPropagation,
                          disabled,
                        },
                      )
                    );
                  }
                  if (type === 'calendar') {
                    const {
                      msaGenerator,
                    } = fieldConfig;
                    return (
                      React.createElement(
                        units[type],
                        {
                          formProps,
                          key: index, // eslint-disable-line react/no-array-index-key
                          fieldConfig,
                          onChange: (value) => {
                            setFieldValue(fieldConfig.name, value || '');
                            if (msaGenerator) {
                              actions.setMsaDate({ msaDate: value });
                            }
                          },
                        },
                      )
                    );
                  }
                  return (
                    React.createElement(
                      units[type],
                      {
                        formProps,
                        key: index, // eslint-disable-line react/no-array-index-key
                        fieldConfig,
                        onChange: (value) => {
                          setFieldValue(fieldConfig.name, value || '');
                        },
                      },
                    )
                  );
                })
              }
              <div className="document-generate-form__controls-wrapper">
                <button
                  className={
                    classNames(
                      'document-generate-form__button button',
                      { 'button--accept': !hasEmptyFields },
                      { 'button--disabled': isFetchingFiles || hasEmptyFields },
                    )
                  }
                  type="submit"
                  disabled={isFetchingFiles || hasEmptyFields}
                >
                  Generate
                </button>
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};


DocumentGenerateForm.propTypes = {
  isFetchingFiles: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  unitActions: PropTypes.object,
  formConfig: PropTypes.shape({
    title: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    validationSchema: PropTypes.shape({}),
    initialValues: PropTypes.shape({}),
  }).isRequired,
  msaDate: PropTypes.any,
};

DocumentGenerateForm.defaultProps = {
  isFetchingFiles: false,
  unitActions: {},
  msaDate: null,
};


export default DocumentGenerateForm;
