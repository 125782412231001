import React from 'react';

import { deliveryResourcesAccess, taskOrderModuleAccess } from 'core/auth/guaranteedAccessRoles';
import CustomLink from 'elements/link';
import Text from 'elements/plain-text';
import { get } from 'lodash';
import moment from 'moment';
import { getHasPermissions } from 'utils/auth';

export const seniorityConfig = (data, userGroup) => ({
  title: 'Resource Seniority Update',
  id: 'seniorityUpdate',
  columns: [
    {
      Header: 'Resource',
      accessor: 'fullname',
      Cell: (cellInfo) => {
        const { row: { original: { devstaffId, fullname } } } = cellInfo;
        const allowAccess = getHasPermissions(userGroup, deliveryResourcesAccess.roles);
        if (!devstaffId || !fullname || !allowAccess) {
          const name = fullname || '—';
          return <Text data={name} title={name} />;
        }
        return (
          <CustomLink
            pathname={`/delivery/employee/${devstaffId}/work-book`}
            id={devstaffId}
            data={fullname}
            title={fullname}
          />
        );
      },
    },
    {
      Header: 'Seniority Update',
      accessor: 'seniority',
      Cell: (cellInfo) => {
        const { row: { original: { seniority, seniorityInt } } } = cellInfo;
        return <Text data={`${seniority || '—'} ${seniorityInt && `(${seniorityInt})`}`} />;
      },
    },
    {
      Header: 'Valid From',
      accessor: 'validFrom',
      Cell: (cellInfo) => {
        const date = get(cellInfo, 'row.original.validFrom', null);
        if (!date) return <Text data="—" />;
        return moment(date).format('MM/DD/YYYY');
      },
    },
    {
      Header: 'Affected TO',
      accessor: 'affectedTo',
      Cell: (cellInfo) => {
        const { row: { original: { devstaffId, affectedTo } } } = cellInfo;
        const allowAccess = getHasPermissions(userGroup, taskOrderModuleAccess);
        if (!affectedTo.length) {
          return <Text data="—" />;
        }
        return (
          <ul>
            {affectedTo.map(({ projectId, taskOrderId }) => (
              <li key={`${devstaffId}_${projectId}_${taskOrderId}`}>
                {allowAccess ? (
                  <CustomLink
                    pathname={`/task-orders/${projectId}/staffing`}
                    id={taskOrderId}
                    data={taskOrderId}
                  />
                ) : <Text data={taskOrderId} />}
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      Header: 'Seniority in TO',
      accessor: 'affectedToSeniority',
      Cell: (cellInfo) => {
        const { row: { original: { devstaffId, affectedTo } } } = cellInfo;
        if (!affectedTo.length) {
          return <Text data="—" />;
        }
        return (
          <ul>
            {affectedTo.map(({ projectId, taskOrderId, seniority }) => (
              <li key={`${devstaffId}_${projectId}_${taskOrderId}`}>
                <Text data={seniority} />
              </li>
            ))}
          </ul>
        );
      },
    },
  ],
  data,
});
