import React from 'react';
import PropTypes from 'prop-types';

import ActionButton from 'elements/action-button';

import { ALPHABET } from './constants';

import './styles.scss';


const AlphabetFilter = ({ changeFilter, selected, type, storeKey }) => (
  <div className="alphabet-filter">

    <ActionButton
      data="All"
      isActive={!selected}
      onClick={() => changeFilter({
        storeKey,
        type,
        selected: '', // reset
      })}
      className="alphabet-filter__all"
    />

    <ActionButton
      data="#"
      isActive={selected === '#'}
      onClick={() => changeFilter({
        storeKey,
        type,
        selected: '#', // select numbers
      })}
    />

    {
      ALPHABET.map((symbol) => (
        <ActionButton
          key={symbol}
          data={symbol}
          isActive={selected === symbol}
          onClick={() => changeFilter({
            storeKey,
            type,
            selected: symbol,
          })}
        />
      ))
    }
  </div>
);


AlphabetFilter.propTypes = {
  type: PropTypes.string.isRequired,
  storeKey: PropTypes.string.isRequired,
  changeFilter: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

AlphabetFilter.defaultProps = {
  selected: '',
};


export default AlphabetFilter;
