import update from 'immutability-helper';
import { createReducer } from 'redux-create-reducer';

import { filesActionsTypes } from './actions';
import { ENTITY_NAME } from './constants';

const initialState = {
  entityName: ENTITY_NAME,
  errors: {},
  isFileUploaded: false,
  uploadingProgress: 0,
};

export default createReducer(initialState, {
  [filesActionsTypes.UPLOAD_FILE](state) {
    return update(state, {
      uploadingProgress: {
        $set: 0,
      },
      isFileUploaded: {
        $set: false,
      },
    });
  },

  [filesActionsTypes.UPDATE_UPLOADING_PROGRESS](state, {
    payload: {
      progress,
    },
  }) {
    return update(state, {
      uploadingProgress: {
        $set: progress,
      },
    });
  },

  [filesActionsTypes.UPLOAD_FILE_SUCCESS](state) {
    return update(state, {
      uploadingProgress: {
        $set: 0,
      },
      isFileUploaded: {
        $set: true,
      },
      errors: {
        $unset: ['uploadFileError'],
      },
    });
  },

  [filesActionsTypes.UPLOAD_FILE_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFileUploaded: {
        $set: true,
      },
      uploadingProgress: {
        $set: 0,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GENERATE_BILLING_PROJECT_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateBillingProjectReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_BILLING_PROJECT_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GENERATE_BILLING_PROJECT_EXCEL_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateBillingProjectExcelReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_BILLING_PROJECT_EXCEL_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GENERATE_BILLING_PROJECT_TO_EXCEL_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateBillingProjectToExcelReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_BILLING_PROJECT_TO_EXCEL_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GENERATE_RECONCILIATION_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateReconciliationReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_RECONCILIATION_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GENERATE_RECONCILIATION_SECOND_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateReconciliationSecondReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_RECONCILIATION_SECOND_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GENERATE_ANNUAL_PAYROLL_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateAnnualPayrollReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_ANNUAL_PAYROLL_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GENERATE_ANNUAL_PAYROLL_SALARY_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateAnnualPayrollSalaryReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_ANNUAL_PAYROLL_SALARY_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GENERATE_UTILIZATION_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateUtilizationReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_UTILIZATION_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GENERATE_CURRENT_PAYROLL_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateCurrentPayrollReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_CURRENT_PAYROLL_REPORT_FAIL](state, {
    payload: { error },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GENERATE_CURRENT_PAYROLL_EXCEL_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateCurrentPayrollExcelReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_CURRENT_PAYROLL_EXCEL_REPORT_FAIL](state, {
    payload: { error },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GENERATE_PTO_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generatePtoReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_PTO_REPORT_FAIL](state, {
    payload: { error },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GENERATE_TODAY_BILLING_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateTodayBillingReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_TODAY_BILLING_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },

  [filesActionsTypes.GET_TODAY_CFR_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['getTodayCfrReportError'],
      },
    });
  },

  [filesActionsTypes.GET_TODAY_CFR_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },
  [filesActionsTypes.GENERATE_INTERNAL_TO_EXCEL_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateInternalToExcelReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_INTERNAL_TO_EXCEL_REPORT_FAIL](state, {
    payload: { error },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },
  [filesActionsTypes.GENERATE_CONTRACT_FULFILLMENT_PDF_REPORT](state) {
    return update(state, {
      errors: {
        $unset: ['generateMentoringCompensationExcelReportError'],
      },
    });
  },
  [filesActionsTypes.GENERATE_CONTRACT_FULFILLMENT_PDF_REPORT_FAIL](state, {
    payload,
  }) {
    return update(state, {
      errors: {
        $merge: payload,
      },
    });
  },
  [filesActionsTypes.GENERATE_CONTRACT_FULFILLMENT_PDF_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateContractFulfillmentPDFReportError'],
      },
    });
  },
});
