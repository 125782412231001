import React from 'react';

import PropTypes from 'prop-types';

import { noop } from 'lodash';
import styled, { css } from 'styled-components';

const StyledTitle = styled.span`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 0.8rem 0.8rem 0;
  border-bottom: 1px solid #bbbdc0;
  line-height: 2.1rem;

  ${({ cssRules }) => css`${cssRules}`}
`;

const FormHeading = ({
  id,
  title,
  cssRules,
  IconButton,
  withButton,
  onIconButtonClick,
}) => {
  const handleIconButtonClick = () => {
    onIconButtonClick({ orderBy: 'name' });
  };

  return (
    <div key={id} className="details-form__form-heading form-heading">
      <StyledTitle
        className="form-heading__title"
        cssRules={cssRules}
      >
        {title}
        {withButton && <IconButton onClick={handleIconButtonClick} />}
      </StyledTitle>
    </div>
  );
};


FormHeading.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  cssRules: PropTypes.string,
  IconButton: PropTypes.func,
  withButton: PropTypes.bool,
  onIconButtonClick: PropTypes.func,
};

FormHeading.defaultProps = {
  id: '',
  title: '',
  IconButton: null,
  cssRules: '',
  withButton: false,
  onIconButtonClick: () => noop,
};


export default FormHeading;
