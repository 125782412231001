import { ADMIN_STAFF_ID, CUSTOMER_ID } from 'core/employees-reviews/constants';
import hash from 'hash-sum';
import { get, isEqual } from 'lodash';
import { toast } from 'react-toastify';

const getProject = (
  isOutOfProject,
  internalCategoryId,
  currentJiraProject
) => (!isOutOfProject && !internalCategoryId) ? {
  jiraProject: currentJiraProject,
} : {
  internalCategoryId: internalCategoryId || currentJiraProject,
};

export const onSubmit = ({
  formsState,
  employeesMap,
  changeCurrentModal,
  submitReviews,
  controllerBag: {
    resetForm,
  },
}) => {
  const fieldsMap = {};
  const recordsState = {};
  const addedReviews = [];
  const deletedReviews = [];
  const updatedReviews = [];
  const blockedReviews = [];
  const unlockedRecords = [];
  const roleUpdates = [];

  formsState.forEach(({ values, initialValues, additionalFields }) => {
    const [groupId, currentRecords] = Object.entries(values)[0];
    const initialRecords = Object.values(initialValues)[0];
    const parseReview = ({
      role,
      reviewbyId,
      devstaffId,
      isToBlock = false,
      ...rest
    }) => {
      const reviewby = get(employeesMap, `[${reviewbyId}].label`, '');
      const devstaffName = get(employeesMap, `[${devstaffId}].label`, '');
      const isPlReview = get(additionalFields, `teamMap[${reviewbyId}].isPl`, false);
      const id = hash({
        groupId,
        devstaffId,
      });

      const review = {
        id,
        isToBlock,
        devstaffId,
        devstaffName,
        isAdminStaffReview: reviewbyId === ADMIN_STAFF_ID,
        isCustomerReview: reviewbyId === CUSTOMER_ID,
        isPlReview,
        ...rest,
        reviewby,
      };

      if (reviewbyId > 0) {
        review.reviewbyId = reviewbyId;
      }
      if (role) {
        review.role = role;
      }
      return review;
    };

    currentRecords.forEach((record) => {
      const {
        isPl,
        reviews,
        role = '',
        devstaffId,
        blockReason,
        isReviewRequired,
        initialJiraProject,
        isOutOfProject = false,
        jiraProject: currentJiraProject = groupId,
        internalCategoryId,
      } = record;
      const recordHash = hash({
        groupId,
        devstaffId,
        isOutOfProject,
        jiraProject: initialJiraProject,
      });
      const jiraProjectName = get(additionalFields, `projectsMap.${currentJiraProject}.label`);
      recordsState[recordHash] = {
        role,
        blockReason,
        jiraProjectName,
        isReviewRequired,
        jiraProject: currentJiraProject,
      };
      reviews.forEach((review) => {
        const {
          rating,
          recordId,
          reviewbyId,
          review: reviewContent,
        } = review;

        if (!recordId) {
          const initialRole = get(additionalFields, `projectMap.${devstaffId}.role`, '');
          const isInitialBlocked = !get(additionalFields, `projectMap.${devstaffId}.isReviewRequired`, true);

          const addedReview = parseReview({
            isPl,
            role,
            rating,
            devstaffId,
            reviewbyId,
            initialRole,
            isOutOfProject,
            isInitialBlocked,
            review: reviewContent,
            project: getProject(isOutOfProject, internalCategoryId, currentJiraProject || groupId),
          });

          addedReviews.push(addedReview);
        } else {
          fieldsMap[recordId] = review;
        }
      });
    });

    initialRecords.forEach((record) => {
      const {
        isPl,
        reviews,
        devstaffId,
        blockReason,
        isReviewRequired,
        role: initialRole,
        initialJiraProject,
        isOutOfProject = false,
      } = record;

      const recordHash = hash({
        groupId,
        devstaffId,
        isOutOfProject,
        jiraProject: initialJiraProject,
      });
      const {
        role: currentRole,
        blockReason: currentReason,
        jiraProject: currentJiraProject,
        isReviewRequired: nowIsReviewRequired,
        jiraProjectName: currentJiraProjectName,
      } = get(recordsState, recordHash, {});
      const isReasonChanged = `${currentReason}` !== `${blockReason}`;
      const isRecordBlocked = !nowIsReviewRequired && isReviewRequired && !isOutOfProject;
      const isRecordUnlocked = nowIsReviewRequired && !isReviewRequired;
      const hasRoleChanged = (`${currentRole}` !== `${initialRole}`) && !isOutOfProject;
      const hasProjectChanged = `${currentJiraProject}` !== `${initialJiraProject}`;

      if (!nowIsReviewRequired && !isReviewRequired && isReasonChanged) {
        const id = hash({
          groupId,
          devstaffId,
        });
        const devstaffName = get(employeesMap, `[${devstaffId}].label`, '');

        blockedReviews.push({
          id,
          isPl,
          devstaffId,
          initialRole,
          devstaffName,
          role: currentRole,
          blockReason: currentReason,
          review: 'Reason to block review will be changed',
          project: getProject(isOutOfProject, get(record, 'internalCategoryId'), currentJiraProject),
        });
      }
      if (isRecordBlocked) {
        const removedByBlocking = reviews
          .map((review) => (
            parseReview({
              isPl,
              devstaffId,
              isToBlock: true,
              role: currentRole,
              blockReason: currentReason,
              project: getProject(isOutOfProject, get(record, 'internalCategoryId'), initialJiraProject || groupId),
              ...review,
            })
          ));
        const hasRemovedReviews = !!removedByBlocking.length;

        if (!hasRemovedReviews) {
          const id = hash({
            groupId,
            devstaffId,
          });
          const devstaffName = get(employeesMap, `[${devstaffId}].label`, '');

          blockedReviews.push({
            id,
            isPl,
            devstaffId,
            initialRole,
            devstaffName,
            role: currentRole,
            blockReason: currentReason,
            review: 'Record will be blocked',
            project: getProject(isOutOfProject, get(record, 'internalCategoryId'), currentJiraProject),
          });
        }

        return deletedReviews.push(...removedByBlocking);
      }

      if (isRecordUnlocked) {
        const id = hash({
          groupId,
          devstaffId,
        });
        const devstaffName = get(employeesMap, `[${devstaffId}].label`, '');

        unlockedRecords.push({
          id,
          isPl,
          devstaffId,
          initialRole,
          devstaffName,
          role: currentRole,
          review: 'Record will be unlocked',
          project: getProject(isOutOfProject, get(record, 'internalCategoryId'), currentJiraProject),
        });
      }
      reviews.forEach((review) => {
        const { recordId } = review;
        const mappedReview = fieldsMap[recordId];

        if (hasRoleChanged) {
          const id = hash({
            groupId,
            devstaffId,
          });
          const devstaffName = get(employeesMap, `[${devstaffId}].label`, '');

          roleUpdates.push({
            id,
            isPl,
            recordId,
            initialRole,
            devstaffName,
            role: currentRole,
            jiraProject: currentJiraProject,
            jiraProjectName: currentJiraProjectName,
          });
        }

        if (mappedReview) {
          const hasChanges = !isEqual(review, mappedReview);

          if (hasChanges || hasProjectChanged) {
            const {
              rating,
              review: reviewContent,
              reviewbyId,
            } = mappedReview;

            const updatedReview = parseReview({
              isPl,
              rating,
              recordId,
              devstaffId,
              reviewbyId,
              isOutOfProject,
              hasRoleChanged,
              role: currentRole,
              review: reviewContent,
              project: getProject(isOutOfProject, get(record, 'internalCategoryId'), currentJiraProject),
            });

            updatedReviews.push(updatedReview);
          }
        } else {
          const {
            rating,
            review: reviewContent,
            reviewbyId,
          } = review;

          const deletedReview = parseReview({
            isPl,
            rating,
            recordId,
            devstaffId,
            reviewbyId,
            role: currentRole,
            review: reviewContent,
            project: getProject(isOutOfProject, get(record, 'internalCategoryId'), currentJiraProject),
          });
          deletedReviews.push(deletedReview);
        }
      });
      return null;
    });
  });

  toast.dismiss();

  if (
    updatedReviews.length ||
    deletedReviews.length ||
    roleUpdates.length
  ) {
    return changeCurrentModal({
      currentModal: 'UPDATE_REVIEWS_MODAL',
      params: {
        resetForm,
        roleUpdates,
        addedReviews,
        submitReviews,
        blockedReviews,
        updatedReviews,
        deletedReviews,
        unlockedRecords,
      },
    });
  }

  return submitReviews({ addedReviews, unlockedRecords, blockedReviews });
};
