import { RESOURCE_STATUSES } from 'layouts/resource-management-report/config/heplers/constants';

export const getOnboardingErrorRows = (data) => data.reduce((errorRows, row, index) => {
  const { onboardingStatus, approved } = row;
  const isNewComerOrRelocationStatus = onboardingStatus === RESOURCE_STATUSES.RELOCATION || onboardingStatus === RESOURCE_STATUSES.NEWCOMER;
  const isApproved = approved === true;

  if (isNewComerOrRelocationStatus && !isApproved) {
    errorRows.push(index.toString());
  }

  return errorRows;
}, []);

export const getOffboardingErrorRows = (data) => data.reduce((errorRows, row, index) => {
  const { offboardingStatus, approved } = row;
  const isRelocationStatus = offboardingStatus === RESOURCE_STATUSES.RELOCATION;
  const isApproved = approved === true;

  if (isRelocationStatus && !isApproved) {
    errorRows.push(index.toString());
  }

  return errorRows;
}, []);

export const getEndOfProbationErrorRows = (data) => data.reduce((errorRows, row, index) => {
  const { approved } = row;

  if (!approved) {
    errorRows.push(index.toString());
  }

  return errorRows;
}, []);

export const getContractExpirationErrorRows = (data) => data.reduce((errorRows, row, index) => {
  const { statusNew } = row;

  if (statusNew) {
    errorRows.push(index.toString());
  }

  return errorRows;
}, []);
