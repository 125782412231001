export const newContactData = ({
  userId,
  fullName,
}) => ({
  id: -1,
  createdBy: userId,
  createdByFullname: fullName,
  contactInfo: '',
  date: new Date(),
  isAllowedToEdit: true,
});

export const TEXTS = {
  ADD_CONTACT: 'Add Contact',
};
