import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';

import DataList from 'components/data-list';
import DetailsTable from 'components/details-table';
import Controls from 'components/list-controls';
import Loader from 'components/loader';
import GoTop from 'elements/go-top-button';

import Wrapper from 'elements/wrapper';
import { withRouter } from 'react-router';

import { autoScrollToTop } from 'utils/react';

import { SeniorityTable } from './components';

const Dashboard = ({
  location,
  entityName,
  orderRules,
  changeOrder,
  dashboardData,
  isFormSubmitted,
  getDashboardData,
  dashboardDataModel,
  changeCurrentModal,
  updateWorkbookRecord,
  updatePtoRequestRecord,
  isFetchingDashboardData,
  toggleWorkBookRecordState,
}) => {
  autoScrollToTop(location);

  const { rules: { wrapperCssRules }, title } = dashboardDataModel;
  const { body } = dashboardData;

  const onSubmit = ({ fields, initialValues, onSubmitAction, additionalFields }) => {
    const { historyId, recordId, devstaffId, ...other } = additionalFields;
    const extendedFieldsData = {
      ...fields,
      ...other,
    };

    switch (onSubmitAction) {
      case 'updateWorkbookRecord':
        updateWorkbookRecord({ devstaffId, entityName, fields: extendedFieldsData, recordId, initialValues });
        break;
      default:
        updateWorkbookRecord({ devstaffId, entityName, fields: extendedFieldsData, recordId, initialValues });
    }
  };

  const additionalActions = {
    onSubmit,
    toggleWorkBookRecordState,
    updateWorkbookRecord: (args) => updateWorkbookRecord({ entityName, ...args }),
    updatePtoRequestRecord: (args) => updatePtoRequestRecord({ entityName, ...args }),
    openModal: ({ currentModal, ...args }) => changeCurrentModal({ currentModal, params: { ...args } }),
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <>
      <Controls
        title={title || 'Welcome Stranger'}
      />
      <Wrapper
        isGrow
        cssRules={wrapperCssRules}
      >
        {
          isFetchingDashboardData ?
            <Loader /> :
            body.map(({ type, withUploading, ...rest }, bodyItems) => (
              <Fragment
                key={bodyItems} // eslint-disable-line react/no-array-index-key
              >
                {
                  type === 'table' && (
                    <DetailsTable
                      orderRules={orderRules}
                      changeOrder={changeOrder}
                      changeCurrentModal={changeCurrentModal}
                      entityName={entityName}
                      isFormSubmitted={isFormSubmitted}
                      isFetchingDetails={isFetchingDashboardData}
                      {
                        ...rest
                      }
                    />
                  )
                }
                {
                  type === 'tableV2' && (
                    <SeniorityTable {...rest.dataTemplate} />
                  )
                }
                {
                  type === 'data-list' && (
                    <DataList
                      additionalActions={additionalActions}
                      isFetchingDetails={isFetchingDashboardData}
                      isFormSubmitted={false}
                      onSubmit={onSubmit}
                      {
                        ...rest
                      }
                    />
                  )
                }

              </Fragment>
            ))
        }

      </Wrapper>

      <GoTop />

    </>
  );
};

Dashboard.propTypes = {
  location: PropTypes.object.isRequired,
  updatePtoRequestRecord: PropTypes.func.isRequired,
  toggleWorkBookRecordState: PropTypes.func.isRequired,
  getDashboardData: PropTypes.func.isRequired,
  isFetchingDashboardData: PropTypes.bool.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  dashboardData: PropTypes.shape({}).isRequired,
  dashboardDataModel: PropTypes.shape({}).isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  updateWorkbookRecord: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
};

export default withRouter(Dashboard);
