import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import Checkbox from 'components/form-controls/form-checkbox';
import Inputs from 'components/inputs';
import Textarea from 'components/textarea';
import Overlay from 'elements/modal-overlay';
import { Formik, Form, Field } from 'formik';
import { withRouter } from 'react-router-dom';

import { validationSchema } from './validation';

import './styles.scss';

const SendForSignatureModal = ({
  signDocument,
  clearModal,
  ownerEmail,
  params: { pocMsaName, pocMsaEmail, fileId, entityName, fileType },
}) => {
  const initialValues = {
    pocName: pocMsaName,
    pocEmail: pocMsaEmail,
    emailContent: `Dear ${pocMsaName}, \n \nThis is an automatically generated email by the HiQo Billing System to inform you that your ${fileType} is ready to be signed. The document is available via the link attached, please, click on it and follow HelloSign instructions. \n \nIf you have any further questions, please, contact us via ${ownerEmail} \n \nBest regards, \n \nHiQo Solutions Team.`,
    isDataCorrect: false,
  };

  const sendForSignature = ({ pocName, pocEmail, emailContent }) => {
    signDocument({
      fileId,
      pocEmail,
      pocName,
      message: emailContent,
      entityName,
    });
  };

  return (
    <Overlay
      clearModal={clearModal}
    >

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={sendForSignature}
        render={({ values, errors, touched, isSubmitting, setFieldValue }) => {
          const hasEmptyFields = Boolean(Object.values(values).filter((value) => value === '').length);

          return (

            <div className="send-for-signature-modal">

              <p className="send-for-signature-modal__title">
                Send for Signature
              </p>

              <Form className="send-for-signature-modal__form">

                <Field
                  type="text"
                  name="pocName"
                  render={() => (
                    <Inputs.TextInput
                      name="pocName"
                      label="POC Name"
                      inputValue={values.pocName}
                      onChange={(name) => {
                        if (name !== undefined) {
                          setFieldValue('emailContent', values.emailContent.replace(` ${values.pocName},`, ` ${name},`));
                        }

                        setFieldValue('pocName', name);
                      }}
                      error={errors.pocName}
                      withError={!!(touched.pocName && errors.pocName)}
                    />
                  )}
                />

                <Field
                  type="text"
                  name="pocEmail"
                  render={() => (
                    <Inputs.TextInput
                      name="pocEmail"
                      label="POC Email"
                      inputValue={values.pocEmail}
                      onChange={(email) => setFieldValue('pocEmail', email)}
                      error={errors.pocEmail}
                      withError={!!(touched.pocEmail && errors.pocEmail)}
                    />
                  )}
                />

                <Field
                  type="text"
                  name="emailContent"
                  render={({ field: { onChange } }) => (
                    <Textarea
                      name="emailContent"
                      label="Email Text"
                      inputValue={values.emailContent}
                      onChange={onChange}
                      error={errors.emailContent}
                      withError={!!(touched.emailContent && errors.emailContent)}
                    />
                  )}
                />

                <Checkbox
                  name="isDataCorrect"
                  label="The data entered is correct"
                  inputValue={values.isDataCorrect}
                />

                <div className="send-for-signature-modal__controls">
                  <button
                    className={
                      classNames(
                        'send-for-signature-modal__button',
                        'button',
                        { 'button--accept': !(isSubmitting || hasEmptyFields || !values.isDataCorrect) },
                        { 'button--disabled': isSubmitting || hasEmptyFields || !values.isDataCorrect }
                      )
                    }
                    type="submit"
                  >
                    Send
                  </button>

                  <button
                    className="send-for-signature-modal__button button button--cancel"
                    onClick={() => clearModal()}
                    type="button"
                  >
                    Cancel
                  </button>

                </div>

              </Form>

            </div>
          );
        }}
      />

    </Overlay>
  );
};

SendForSignatureModal.propTypes = {
  ownerEmail: PropTypes.string.isRequired,
  signDocument: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    pocMsaName: PropTypes.string,
    pocMsaEmail: PropTypes.string,
    ownerEmail: PropTypes.string,
    entityName: PropTypes.string,
    fileId: PropTypes.number,
    fileType: PropTypes.string,
  }),
};

SendForSignatureModal.defaultProps = {
  params: {
    fileId: undefined,
    pocMsaName: '',
    pocMsaEmail: '',
    ownerEmail: 'noreply@hiqo-solutions.com',
  },
};

SendForSignatureModal.displayName = 'SendForSignatureModal';

export default withRouter(SendForSignatureModal);
