import React from 'react';

import PropTypes from 'prop-types';

import cn from 'classnames';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

export const TextField = ({
  content,
  className,
  withLink,
  link,
}) => (
  <div data-id="TextField" title={content} className={cn(styles.content, className)}>
    {withLink ? <Link title={content} to={link}>{content}</Link> : content}
  </div>
);

TextField.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  withLink: PropTypes.bool,
  link: PropTypes.string,
};

TextField.defaultProps = {
  className: '',
  withLink: false,
  link: '#',
};
