import { get, toNumber, intersection } from 'lodash';

import { addThousandSeparator } from 'utils/helpers/numbers';

const getTotalPayroll = (currency, data) => (
  // Processing data rows.
  get(data, 'annualReportStaffDataRows', []).reduce(
    (rowsRate, row) => {
      // Processing month rows.
      const rowRate = get(row, 'monthsData', []).reduce(
        (monthsRate, month) => {
          // Month has multiple currencies with usd rate.
          const employeeCurrenciesForYear = get(row, 'employeeCurrenciesForYear', []);
          const renderedCurrency = currency.length ?
            intersection(employeeCurrenciesForYear, currency) :
            employeeCurrenciesForYear;

          const currenciesRate = month != null ?
            renderedCurrency.reduce(
              (currenciesResult, currencyName) => {
                const rate = get(month, `${currencyName}.rateUsd`, null);
                return rate != null ?
                  currenciesResult + toNumber(rate) :
                  currenciesResult;
              },
              0,
            ) :
            0;

          return monthsRate + currenciesRate;
        },
        0,
      );

      return rowsRate + rowRate;
    },
    0,
  )
);

const usdLabelsGetter = (currency) => [{
  valueGetter: (data) => {
    const total = addThousandSeparator(getTotalPayroll(currency, data).toFixed(2));
    return `Total Payroll: $ ${total}`;
  },
}];

export default usdLabelsGetter;
