import {
  clientRateCardUpdateNotificationsTypes,
} from 'core/configurations/client-rate-card-update-notifications/actions';
import update from 'immutability-helper';

export const clientRateCardUpdateNotificationsReducers = {
  [clientRateCardUpdateNotificationsTypes.GET_RATECARD_NOTIFICATIONS](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      clientRateCardNotifications: {
        rateCardNotificationsVersion: {
          $set: {
            ...state.rateCardNotificationsVersion,
            allowedForDelete: false,
          },
        },
      },
      sendingEmailsProgress: {
        notifications: {
          clientRateCard: {
            $set: {
              recordsInProgress: 0,
              inProgress: false,
              isActive: false,
            },
          },
        },
      },
    });
  },

  [clientRateCardUpdateNotificationsTypes.GET_RATECARD_NOTIFICATIONS_SUCCESS](
    state,
    {
      payload: {
        notificationsData,
        rateCardsForRatecardNotifications,
        notifications,
        billingAgentIds,
        versions,
        statuses,
        version,
      },
    }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      clientRateCardNotifications: {
        rateCardNotificationsVersion: {
          $set: version,
        },
        rateCardNotificationsVersions: {
          $set: versions,
        },
        rateCardNotifications: {
          $set: notificationsData,
        },
        rateCardsForRatecardNotifications: {
          $set: rateCardsForRatecardNotifications,
        },
        notificationsType: {
          $set: notifications,
        },
        billingAgentIds: {
          $set: billingAgentIds,
        },
        rateCardNotificationsStatuses: {
          $set: statuses,
        },
      },
      errors: {
        $unset: ['getRateCardNotificationsError'],
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.GET_RATECARD_NOTIFICATIONS_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.UPDATE_RATECARD_NOTIFICATIONS](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.UPDATE_RATECARD_NOTIFICATIONS_SUCCESS](state, {
    payload: { rateCardNotificationsData },
  }) {
    return update(state, {
      clientRateCardNotifications: {
        rateCardNotifications: {
          $set: rateCardNotificationsData,
        },
      },
      isFetching: {
        $set: false,
      },
      errors: {
        $unset: ['getRateCardNotificationsError'],
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.UPDATE_RATECARD_NOTIFICATIONS_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.CREATE_RATECARD_NOTIFICATIONS_VERSION](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.CREATE_RATECARD_NOTIFICATIONS_VERSION_SUCCESS](
    state,
    { payload: { rateCardNotificationsData, versions } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      clientRateCardNotifications: {
        rateCardNotificationsVersion: {
          $set: versions[versions.length - 1],
        },
        rateCardNotificationsVersions: {
          $set: versions,
        },
        rateCardNotifications: {
          $set: rateCardNotificationsData,
        },
      },
      errors: {
        $unset: ['getRateCardNotificationsError'],
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.CREATE_RATECARD_NOTIFICATIONS_VERSION_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.DELETE_RATECARD_NOTIFICATIONS_VERSION](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.DELETE_RATECARD_NOTIFICATIONS_VERSION_SUCCESS](
    state,
  ) {
    return update(state, {
      errors: {
        $unset: ['deleteRateCardNotificationsVersionError'],
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.DELETE_RATECARD_NOTIFICATIONS_VERSION_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.SEND_RATECARD_NOTIFICATIONS](state) {
    return update(state, {
      isFetching: { $set: true },
    });
  },

  [clientRateCardUpdateNotificationsTypes.SEND_RATECARD_NOTIFICATIONS_FAIL](
    state,
    { payload: { error } }
  ) {
    return update(state, {
      isFetching: { $set: false },
      errors: { $merge: error },
    });
  },
  [clientRateCardUpdateNotificationsTypes.CLEAR_RATECARD_NOTIFICATIONS](state) {
    return update(state, {
      clientRateCardNotifications: {
        rateCardNotificationsVersion: {
          $set: null,
        },
        rateCardNotificationsVersions: {
          $set: null,
        },
        rateCardNotifications: {
          $set: [],
        },
        rateCardsForRatecardNotifications: {
          $set: [],
        },
        notificationsType: {
          $set: [],
        },
        billingAgentIds: {
          $set: [],
        },
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.STOP_CHECK_RATECARD_NOTIFICATIONS_STATUS](state) {
    return state;
  },
  [clientRateCardUpdateNotificationsTypes.CHECK_RATECARD_NOTIFICATIONS_STATUS](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },
  [clientRateCardUpdateNotificationsTypes.CHECK_RATECARD_NOTIFICATIONS_STATUS_SUCCESS](state, action) {
    if (action.payload) {
      return update(state, {
        sendingEmailsProgress: {
          notifications: {
            clientRateCard: {
              $set: action.payload,
            },
          },
        },
      });
    }
    return state;
  },
  [clientRateCardUpdateNotificationsTypes.CHANGE_RATE_CARD_NOTIFICATIONS_FILTER](state, { payload }) {
    return update(state, {
      filters: {
        notifications: {
          clientRateCard: {
            $merge: {
              ...payload,
            },
          },
        },
      },
    });
  },
};
