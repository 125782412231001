import gql from 'graphql-tag';

export const queryConfig = {
  updateEmailTemplateNotificationsStatus: gql`
    mutation updateRatecardNotificationsStatus($fields: UpdateRateCardNotificationsStatusInput!) {
      updateRatecardNotificationsStatus(fields: $fields)
      {
        ok
      }
    }
  `,
  updateEmailTemplateNotifications: gql`
    mutation updateEmails($fields: UpdateEmailsInput!) {
      updateEmails(fields: $fields) {
        ok
        emails {
          billingAgentIds {
            billingAgentId
            name
            shortName
            countryCode
          }
          emailsData {
            id
            lineId
            emailTemplateId
            emailed
            content
            status
            clientId
            name
            salutation
            year
            emailDate
            version
            billingAgent
            country
            notification
            emails
            processed
          }
          notifications {
            notificationId
            notificationTitle
          }
          versions {
            versionId
            allowedForDelete
          }
        }
      }
    }
  `,
  getEmailTemplateNotifications: gql`
    query ($year: Int!, $version: String) {
      emails(year: $year, version: $version) {
        emailsData {
          lineId
          id
          emailTemplateId
          emailed
          content
          status
          clientId
          name
          salutation
          year
          emailDate
          version
          billingAgent
          country
          notification
          emails
          processed
        }
        versions {
          versionId
          allowedForDelete
        }
        billingAgentIds {
          billingAgentId
          shortName
        }
        notifications {
          notificationId
          notificationTitle
        }
        emailTemplates {
          emailTemplateId
          name
        }
        statuses {
          id
          status
        }
      }
    }
  `,
  createEmailTemplateNotificationsVersion: gql`
    mutation createEmailsVersion($fields: CreateEmailsInput!) {
      createEmailsVersion(fields: $fields) {
        ok
        emails {
          billingAgentIds {
            billingAgentId
            name
            shortName
            countryCode
          }
          emailsData {
            id
            lineId
            emailTemplateId
            emailed
            content
            status
            clientId
            name
            salutation
            year
            emailDate
            version
            billingAgent
            country
            emails
            notification
            processed
          }
          notifications {
            notificationId
            notificationTitle
          }
          versions {
            versionId
            allowedForDelete
          }
        }
      }
    }
  `,
  deleteEmailTemplateNotificationsVersion: gql`
    mutation deleteEmailsVersion($fields: DeleteEmailInput!) {
      deleteEmailsVersion(fields: $fields)
      {
        ok
      }
    }
  `,
  sendEmailTemplateNotificationsEmails: gql`
    mutation sendEmails($fields: SendEmailsInput!) {
        sendEmails(fields: $fields) {
          ok
        }
      }
  `,
  emailsNotificationsStatus: gql`
    query emailsNotificationsStatus($year: Int, $version: String) {
      emailsNotificationsStatus(year: $year, version: $version) {
        recordsInProgress,
        inProgress
      }
    }
  `,
  updateEmailsStatus: gql`
    mutation updateEmailsStatus($fields: UpdateEmailsStatusInput!) {
      updateEmailsStatus(fields: $fields)
      {
        ok
      }
    }
  `,
};
