import { push } from 'connected-react-router';
import { waitForAuthorization } from 'core/auth/sagas';
import { get } from 'lodash';
import {
  put,
  takeLatest,
  call,
  select,
  all,
} from 'redux-saga/effects';

import {
  removeEmptyValues,
  getParsedValues,
} from 'utils/helpers/forms';
import {
  executeQuery,
  executeMutation,
  parseError,
} from 'utils/request';

import {
  channelPartnersActionsTypes,
  channelPartnersActions,
} from './actions';
import { queryConfig } from './queries';
import {
  selectErrors,
  selectEntityName,
} from './selectors';

function* getChannelPartnersList() {
  try {
    const query = queryConfig.getChannelPartnersList;
    const options = {
      query,
    };
    const { channelPartners } = yield call(executeQuery, options);
    return yield put(channelPartnersActions.getChannelPartnersSuccess({
      channelPartners: channelPartners || [],
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getChannelPartnersListError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getChannelPartnersListError = yield call(parseError, error, options);

    return yield put(channelPartnersActions.getChannelPartnersFail({
      error: {
        getChannelPartnersListError,
      },
    }));
  }
}

function* getChannelPartnerDetailsDetails({
  payload: {
    channelPartnerId,
  },
}) {
  try {
    const query = queryConfig.getChannelPartnerDetails(channelPartnerId);
    const options = {
      query,
    };

    const { node, ...rest } = yield call(executeQuery, options);
    return yield put(channelPartnersActions.getChannelPartnerDetailsSuccess({
      channelPartnerDetails: {
        ...node,
        ...rest,
      },
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getChannelPartnerDetailError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getChannelPartnerDetailError = yield call(parseError, error, options);
    return yield put(channelPartnersActions.getChannelPartnerDetailsFail({
      error: {
        getChannelPartnerDetailError,
      },
    }));
  }
}

function* createChannelPartner({
  payload: {
    fields,
  },
}) {
  try {
    const mutation = queryConfig.createChannelPartner;
    const variables = {
      fields: removeEmptyValues(fields),
    };
    const options = {
      mutation,
      variables,
    };
    const data = yield call(executeMutation, options);
    const node = get(data, 'createChannelPartner.channelPartner', {});

    return yield all([
      put(channelPartnersActions.createChannelPartnerSuccess({
        channelPartnerDetails: {
          ...node,
        },
      })),
      put(push(`/channel-partners/${node.channelPartnerId}/details`)),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'createChannelPartnerError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const createChannelPartnerError = yield call(parseError, error, options);

    return yield put(channelPartnersActions.createChannelPartnerFail({
      error: {
        createChannelPartnerError,
      },
    }));
  }
}

function* updateChannelPartnerDetails({
  payload: {
    channelPartnerId,
    fields,
    initialValues,
  },
}) {
  try {
    const mutation = queryConfig.updateChannelPartnerDetails(channelPartnerId);
    const data = getParsedValues(fields, initialValues);
    const variables = {
      fields: data,
    };

    const options = {
      mutation,
      variables,
    };

    const { updateChannelPartner } = yield call(executeMutation, options);

    return yield put(channelPartnersActions.updateChannelPartnerDetailsSuccess({
      withModal: true,
      channelPartnerId,
      fields: data,
      channelPartnerDetails: {
        ...updateChannelPartner.channelPartner || {},
      },
    }));
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'updateChannelPartnerDetailsError', []);
    const options = {
      entityName,
      storedErrors,
    };

    const updateChannelPartnerDetailsError = yield parseError(error, options);

    return yield put(channelPartnersActions.updateChannelPartnerDetailsFail({
      error: {
        updateChannelPartnerDetailsError,
      },
    }));
  }
}

function* getChannelPartnersListWatcher() {
  yield takeLatest(channelPartnersActionsTypes.GET_CHANNEL_PARTNERS_LIST, waitForAuthorization(getChannelPartnersList));
}

function* getChannelPartnerDetailWatcher() {
  yield takeLatest(channelPartnersActionsTypes.GET_CHANNEL_PARTNER_DETAILS, waitForAuthorization(getChannelPartnerDetailsDetails));
}

function* createChannelPartnerWatcher() {
  yield takeLatest(channelPartnersActionsTypes.CREATE_CHANNEL_PARTNER, waitForAuthorization(createChannelPartner));
}

function* updateChannelPartnerDetailsWatcher() {
  yield takeLatest(channelPartnersActionsTypes.UPDATE_CHANNEL_PARTNER_DETAILS, waitForAuthorization(updateChannelPartnerDetails));
}

export default [
  getChannelPartnerDetailWatcher,
  getChannelPartnersListWatcher,
  createChannelPartnerWatcher,
  updateChannelPartnerDetailsWatcher,
];
