import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Filter from 'components/filter';
import Controls from 'components/list-controls';
import ListFooter from 'components/list-footer';
import TaskOrdersTable from 'components/table';
import Checkbox from 'elements/checkbox';
import GoTop from 'elements/go-top-button';
import Wrapper from 'elements/wrapper';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import { autoScrollToTop } from 'utils/react';

import { getButtonsStyles } from './utils';

const TaskOrdersList = ({
  location,
  push,
  getTaskOrdersList,
  taskOrdersData,
  isFetchingTaskOrdersList,
  isFilteringTaskOrderList,
  orderRules,
  changeOrder,
  toggleFavorite,
  filterConfig,
  changeFilter,
  resetFilters,
  hasActiveFilters,
  taskOrdersCount,
  filterByExpiration,
  filterByObsoleteRates,
  hasPermissionsCreateTaskOrder,
  taskOrdersListModel,
  entityName,
}) => {
  autoScrollToTop(location);
  const [selectedProject, selectProject] = useState(null);
  const { tableName, rules, noDataMessage } = taskOrdersListModel;
  const filterCssRules = get(rules, 'css.filtersCssRules');
  const closeToExpirationTOCheckBoxCssRules = get(rules, 'css.closeToExpirationTOCheckBoxCssRules');
  const obsoleteRatesTOCheckBoxCssRules = get(rules, 'css.obsoleteRatesTOCheckBoxCssRules');
  const { isActive: isFilterByExpirationActive, type: filterByExpirationType } = filterByExpiration;
  const { isActive: isFilterByObsoleteRates, type: filterByObsoleteRatesType } = filterByObsoleteRates;
  const currentOrderRules = orderRules[tableName];
  const setOrder = useCallback(
    ({ orderBy }) => changeOrder({ orderBy, tableName, entityName }),
    [entityName, tableName]
  );
  const showClosedToExpiration = () => changeFilter({
    storeKey: 'isExpireSoon',
    type: filterByExpirationType,
    isActive: !isFilterByExpirationActive,
  });
  const showWithObsoleteRates = () => changeFilter({
    storeKey: 'isRatesObsolete',
    type: filterByObsoleteRatesType,
    isActive: !isFilterByObsoleteRates,
  });
  const unitActions = {
    selectProject: ({ clientId, canCopyAndCreate, taskOrderToCopyId }) => {
      selectProject(canCopyAndCreate ? {
        clientId,
        taskOrderToCopyId,
      } : null);
    },
  };

  useEffect(() => {
    getTaskOrdersList();

    return () => {
      resetFilters();
    };
  }, []);

  useEffect(() => {
    selectProject();
  }, [taskOrdersData.length]);

  return (
    <>

      <Controls
        title="Task Orders"
        changeFilter={changeFilter}
        resetFilters={resetFilters}
        hasAlphabetFilter={false}
        hasActiveFilters={hasActiveFilters}
        controlsList={hasPermissionsCreateTaskOrder ? [{
          type: 'action',
          data: 'Create new',
          onClick: () => push('/task-orders/add'),
          cssRules: getButtonsStyles(),
        }, {
          type: 'action',
          data: 'Copy and create new',
          actionName: 'push',
          onClick: () => push({
            pathname: '/task-orders/add',
            state: selectedProject,
          }),
          cssRules: getButtonsStyles({
            isLocked: !selectedProject,
          }),
        }] : []}
      >

        <Checkbox
          title="Display only Active TO that expire within the next 40 days"
          onClick={showClosedToExpiration}
          cssRules={closeToExpirationTOCheckBoxCssRules}
          {...filterByExpiration}
        />

        <Checkbox
          title="Display only Active TO with obsolete rates"
          onClick={showWithObsoleteRates}
          cssRules={obsoleteRatesTOCheckBoxCssRules}
          {...filterByObsoleteRates}
        />

        <Filter
          cssRules={filterCssRules}
          filterConfig={filterConfig}
          changeFilter={changeFilter}
        />

      </Controls>

      <Wrapper
        isGrow
      >
        <TaskOrdersTable
          data={taskOrdersData}
          withInfiniteScroll
          model={taskOrdersListModel}
          toggleFavorite={toggleFavorite}
          changeOrder={setOrder}
          orderRules={currentOrderRules}
          isLoaded={!isFetchingTaskOrdersList && !isFilteringTaskOrderList}
          withFilter={hasActiveFilters}
          noDataMessage={noDataMessage}
          unitActions={unitActions}
          onSelectActionName="selectProject"
        />

      </Wrapper>

      <ListFooter
        filteredItemsCount={taskOrdersData.length}
        itemsCount={taskOrdersCount}
        isFetchingItems={isFetchingTaskOrdersList}
      />

      <GoTop />

    </>
  );
};

TaskOrdersList.propTypes = {
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  taskOrdersListModel: PropTypes.shape({
    tableName: PropTypes.string,
    rules: PropTypes.shape({}).isRequired,
    noDataMessage: PropTypes.string,
  }).isRequired,
  hasPermissionsCreateTaskOrder: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  getTaskOrdersList: PropTypes.func.isRequired,
  changeOrder: PropTypes.func.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  isFetchingTaskOrdersList: PropTypes.bool.isRequired,
  isFilteringTaskOrderList: PropTypes.bool.isRequired,
  orderRules: PropTypes.shape({
    orderBy: PropTypes.string,
  }).isRequired,
  taskOrdersData: PropTypes.arrayOf(PropTypes.shape({
    tableData: PropTypes.arrayOf(PropTypes.object),
  })),
  filterConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  resetFilters: PropTypes.func.isRequired,
  hasActiveFilters: PropTypes.bool.isRequired,
  changeFilter: PropTypes.func.isRequired,
  taskOrdersCount: PropTypes.number.isRequired,
  filterByExpiration: PropTypes.shape({
    isActive: PropTypes.bool,
    type: PropTypes.string,
    selected: PropTypes.string,
  }).isRequired,
  filterByObsoleteRates: PropTypes.shape({
    isActive: PropTypes.bool,
    type: PropTypes.string,
    selected: PropTypes.string,
  }).isRequired,
};

TaskOrdersList.defaultProps = {
  taskOrdersData: [],
  currentModal: null,
};

export default withRouter(TaskOrdersList);
