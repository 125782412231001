export const USERS_GROUPS = {
  CTO: 'cto',
  CEO: 'ceo',
  OFFICE_ADMIN: 'officeadmin',
  DELIVERY_OFFICE_ADMIN: 'devofficeadmin',
  DELIVERY_OFFICE_ADMIN_BY: 'devofficeadminby',
  DELIVERY_OFFICE_ADMIN_TBS: 'devofficeadmintbs',
  DELIVERY_OFFICE_ADMIN_WAW: 'devofficeadminwaw',
  DELIVERY_OFFICE_ADMIN_SPB: 'devofficeadminspb',
  DELIVERY_OFFICE_ADMIN_KZH: 'devofficeadminkzh',
  CSO: 'cso',
  CPS: 'cps',
  SALES: 'sales',
  HRM: 'hrm',
  RM: 'rm',
  ACC_MSQ: 'accountant_msq',
  ACC_TBS: 'accountant_tbs',
  ACC_WAW: 'accountant_waw',
  ACC_SPB: 'accountant_spb',
  ACC_NQZ: 'accountant_nqz',
  HR: 'hr',
  ADMIN: 'admin',
};
