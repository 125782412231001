import { string } from 'yup';

export default ({
  isCEO,
  customers,
}) => ([
  {
    type: 'form',
    stylesTemplate: {
      cssRules: `
        `,
      formControlsCss: 'grid-template-columns: repeat(5, calc((136rem - 4.8rem * 4 - 3.2rem) / 5));',
    },
    content: [
      {
        title: 'HiQo Customer',
        stylesTemplate: {
          cssRules: `
              grid-template-columns: 1fr;
            `,
        },
        formData: [
          {
            type: 'select',
            name: 'customer',
            placeholder: 'Customer Name',
            valueGetter: ({ customer }) => (
              {
                selected: {
                  value: customer,
                },
                items: customers,
              }
            ),
            getIsUnitLocked: () => isCEO,
            validationRules: {
              isRequired: true,
              schemaGetter: () => string().required('Required field'),
            },
            cssRules: `
                width: 22.5rem;
                & .custom-link a {
                  font-size: 1.5rem;
                }
              `,
          },
        ],
      },
    ],
  },
]
);
