import {
  colorSecondaryGrayLight,
  colorSecondaryGrayLight015,
  colorSecondaryText,
  colorPrimary,
} from 'assets/styles/variables';
import {
  UNITS_TYPES,
} from 'core/constants';
import { gridTableConfig } from 'layouts/billing-project-reports/model/utils';
import {
  toNumber,
} from 'lodash';

import {
  addThousandSeparator,
} from 'utils/helpers/numbers';

import workLogsTAbleConfigGetter from './config/work-logs-table-config';

export default ({
  hasPermissionsTODeliveryModule,
  hasPermissionsTOClientModule,
  groupedRows,
  isBillable,
  tableName,
  title,
  isCTO,
  isHRM,
  availableDevCentersForDd,
}) => {
  const groupId = tableName;
  let totalHours = 0;
  let totalOvertime = 0;

  const overtimeRows = groupedRows.map((data, index) => {
    const {
      label,
      overtime,
      hours,
    } = data;

    totalHours += toNumber(hours);
    totalOvertime += toNumber(overtime);

    return {
      type: UNITS_TYPES.TABLE,
      preventScrolling: true,
      summaryTableDataGetter: ({
        tableSummaryData,
        setTableSummaryData,
      }) => {
        if (!tableSummaryData.withGrandTotal) {
          setTableSummaryData({
            withGrandTotal: true,
            label,
            hours,
            overtime,
          });
        }
      },
      content: [{
        dataKey: `${tableName}.[${index}].worklogRows`,
        tableName,
        summaryConfig: {
          label,
          groupId,
          items: [
            {
              data: {
                value: '',
              },
              cssRules: `
              margin-left: auto;
              font-size: 1.6rem;
              line-height: 2.4rem;
              color: ${colorSecondaryText};
              &:after {
                display: none;
              }`,
            },
            {
              data: {
                value: '',
              },
              cssRules: `
              margin-left: auto;
              font-size: 1.6rem;
              line-height: 2.4rem;
              color: ${colorSecondaryText};
              &:after {
                display: none;
              }`,
            },
            {
              data: {
                value: '',
              },
              cssRules: `
              margin-left: auto;
              font-size: 1.6rem;
              line-height: 2.4rem;
              color: ${colorSecondaryText};
              &:after {
                display: none;
              }`,
            },
            {
              data: {
                value: `${addThousandSeparator(toNumber(hours).toFixed(2))} h`,
              },
              cssRules: `
              font-size: 1.6rem;
              line-height: 2.4rem;
              color: ${colorSecondaryText};
              ${!isBillable ? `color: ${colorPrimary};` : ''}
              ${!hours ? `color: ${colorSecondaryGrayLight};` : ''}
              &:after {
                display: none;
              }`,
            },
            {
              data: {
                value: isBillable && `${addThousandSeparator(toNumber(overtime).toFixed(2))} h`,
              },
              cssRules: `
              font-size: 1.6rem;
              line-height: 2.4rem;
              color: ${colorSecondaryText};
              ${!overtime ? `color: ${colorSecondaryGrayLight};` : ''}
              &:after {
                display: none;
              }
              ${!isBillable && 'display: none;'}
              `,
            },
            {
              data: {
                value: isBillable && `${addThousandSeparator(toNumber(hours + overtime).toFixed(2))} h`,
              },
              cssRules: `
              margin-left: auto;
              font-size: 1.6rem;
              line-height: 2.4rem;
              color: ${colorSecondaryText};
              ${!(hours + overtime) ? `color: ${colorSecondaryGrayLight};` : ''}
              &:after {
                display: none;
              }`,
            }],
          cssRules: `
            display: grid;
            ${gridTableConfig(isBillable)};
            grid-column-gap: 0.3rem;
            min-height: 4.8rem;
            font-size: 1.6rem;
            border-top: 1px solid ${colorSecondaryGrayLight};
            background-color: ${colorSecondaryGrayLight015};
            color: ${colorSecondaryText};
            & {
              .expansion-summary__label {
                text-align: left;
              };
            };
            && .expansion-summary__control {
              padding-left: 1.6rem;
            };
            && .expansion-summary__item:last-child {
              padding-right: 1.6rem;
            };
          `,
        },
        ...workLogsTAbleConfigGetter({
          isCTO,
          hasPermissionsTODeliveryModule,
          hasPermissionsTOClientModule,
          isBillable,
          availableDevCentersForDd,
        }),
      }],
    };
  });

  const hasLogs = overtimeRows.length;

  const heading = {
    type: UNITS_TYPES.SINGLE_ROW,
    cssRules: `
      display: flex;
      justify-content: space-between;
      margin: ${isHRM ? '0' : '4.8rem'} 0 ${hasLogs ? '1.2rem' : '0'};
      padding: 0 1.6rem;
      font-size: 2.2rem;
      && .grid-unit {
        padding: 0;
      }
    `,
    rowData: [{
      type: 'text',
      componentProps: {
        data: title,
        cssRules: `
          font-size: 2.2rem;
          line-height: 2.6rem;
          color: ${isBillable ? colorSecondaryText : colorPrimary};`,
      },
    }, hasLogs ? {
      type: 'groupController',
      componentProps: {
        groupId,
        isDefaultExpanded: false,
      },
    } : {}],
  };

  const placeholder = {
    type: UNITS_TYPES.SINGLE_ROW,
    cssRules: `
      padding: 0 1.6rem;
      font-size: 2.2rem;`,
    rowData: [{
      type: 'text',
      componentProps: {
        data: 'This section currently contains no data.',
        cssRules: `
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: ${colorSecondaryGrayLight};`,
      },
    }],
  };

  const grandTotal = {
    type: UNITS_TYPES.SINGLE_ROW,
    cssRules: `
      display: grid;
      ${gridTableConfig(isBillable)};
      grid-column-gap: 0.3rem;
      margin-top: 1.6rem;
      border-top: 1px solid ${colorSecondaryGrayLight};
      background-color: ${colorSecondaryGrayLight015};
      && .grid-unit:first-child {
        padding-left: 1.6rem;
      };
      && .grid-unit:last-child {
        padding-right: 1.6rem;
      };
      `,
    rowData: [{
      type: 'text',
      componentProps: {
        data: 'Grand Total',
        cssRules: ` && {
          font-size: 1.6rem;
          line-height: 2.6rem;
          font-weight: 500;
          color: ${colorSecondaryText};
        }`,
      },
    },
    {
      type: 'text',
      componentProps: {
        data: '',
        cssRules: `
          padding: 0;
          font-size: 1.8rem;
          line-height: 2.4rem;
          color: ${colorSecondaryText};
          `,
      },
    },
    {
      type: 'text',
      componentProps: {
        data: '',
        cssRules: `
          padding: 0;
          font-size: 1.8rem;
          line-height: 2.4rem;
          color: ${colorSecondaryText};
          `,
      },
    },
    {
      type: 'text',
      componentProps: {
        data: '',
        cssRules: `
          padding: 0;
          font-size: 1.8rem;
          line-height: 2.4rem;
          color: ${colorSecondaryText};
          `,
      },
    },
    {
      type: 'text',
      componentProps: {
        data: `${addThousandSeparator(totalHours.toFixed(2))} h`,
        cssRules: `
          padding: 0;
          font-size: 1.8rem;
          line-height: 2.4rem;
          color: ${colorSecondaryText};
          ${!totalHours ? `color: ${colorSecondaryGrayLight};` : ''}`,
      },
    },
    {
      type: 'text',
      shouldntBeRendered: !isBillable,
      componentProps: {
        data: isBillable ? `${addThousandSeparator(totalOvertime.toFixed(2))} h` : '',
        cssRules: `
          padding: 0;
          font-size: 1.8rem;
          line-height: 2.4rem;
          color: ${colorSecondaryText};
          ${!totalOvertime ? `color: ${colorSecondaryGrayLight};` : ''}
          `,
      },
    },
    {
      type: 'text',
      componentProps: {
        data: `${addThousandSeparator((totalHours + totalOvertime).toFixed(2))} h`,
        cssRules: `
          padding: 0;
          font-size: 1.8rem;
          line-height: 2.4rem;
          color: ${colorSecondaryText};
          ${!(totalHours + totalOvertime) ? `color: ${colorSecondaryGrayLight};` : ''}
          `,
      },
      cssRules: 'margin-left: auto;',
    },
    ],
  };

  return hasLogs ? [heading, ...overtimeRows, grandTotal] : [heading, placeholder];
};
