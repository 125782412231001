import { colorSecondaryText, colorSecondaryGrayLight } from 'assets/styles/variables';
import { STORE_KEYS, CLIENT_STATUS } from 'core/configurations/category-management/constants';
import { FILTERS_TYPES, COLUMN_TYPES } from 'core/constants';
import { get } from 'lodash';
import { checkIsInclude } from 'utils/helpers/filters';

export const categoryManagementModel = () => ({
  tableName: 'clients',
  formName: 'categories',
  rules: {
    css: {
      filter: {
        titleCssRules: `
          font-size: inherit;
          padding-top: 1.2rem;
        `,
      },
      container: `padding: 0 4rem;
                  display: grid;
                  grid-template-columns: 3fr 0.5rem 4fr;
                  gap: 0;
                  width: 100%;
                  background: $color_background;`,
      gridTemplateConfig: 'top: 14.4rem;',
      controls: `
        display: flex;
        gap: 1.2rem;

        .controls__additional-controls {
          margin-top: 0;
        }
      `,
      checkboxDisplayOnlyActiveClients: `
          &::after {
            background: linear-gradient(to left, rgba(187,189,192, 0.25), rgba(187,189,192, 0.25)), #f2f2f2;
          }
        `,
    },
  },
  filtersTemplate: [
    {
      type: FILTERS_TYPES.SEARCH,
      storeKey: STORE_KEYS.CLIENT_NAME,
      checkFilter: ({ currentRow, selected }) => {
        const value = get(currentRow, 'client', '');
        return checkIsInclude({ selected, value });
      },
    },
    {
      type: FILTERS_TYPES.TOGGLE,
      storeKey: STORE_KEYS.IS_ACTIVE_CLIENT,
    },
  ],
  dataTemplate: [
    {
      cssRules: 'width: 100%;',
      withImage: false,
      columnName: 'client',
      valueGetter: ({ clientName, isActiveClient, isInitial }) => ({
        type: COLUMN_TYPES.TEXT,
        componentProps: {
          data: clientName,
          cssRules: `
                color: ${isActiveClient === CLIENT_STATUS.ACTIVE || isInitial ? colorSecondaryText : colorSecondaryGrayLight};
              `,
        },
      }),
    },
  ],
  rowStatusGetter: (client) => {
    const { clientId, isActive } = client;

    return {
      id: clientId,
      stylesTemplate: {
        rowStyles: 'color: inherit;',
      },
      client,
      isActive,
      hasWarning: isActive,
    };
  },
});
