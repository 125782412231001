import { NOTIFICATIONS_CATEGORIES_FORM_ID, NOTIFICATIONS_FAILED_REQUESTS } from 'core/configurations/category-management/constants';
import { isEmpty, get } from 'lodash';
import { toast } from 'react-toastify';

export const transformKey = (value, regex) => {
  const defaultRegex = /[^a-zA-Z]/g;

  return value.replace(regex || defaultRegex, '').toUpperCase();
};

export const transformName = (value, regex) => {
  const defaultRegex = /[^a-zA-Z0-9.,!?"'()@#$%&*+-/:;<=>^_`{|}~\s\\]/g;

  return value.replace(regex || defaultRegex, '');
};

export const createKey = (str) => {
  // Remove non-alphabetic characters (symbols)
  const regex = /\b\w{1,3}\b|\b\w{3,}\b/g;
  const words = str.replace(/[^a-zA-Z]/g, ' ').match(regex);

  if (!words) {
    return '';
  }

  let key = '';

  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (words.length === 1) {
      key += word.slice(0, 3);
    } else {
      key += word[0];
    }
  }

  return key.toUpperCase();
};

export const getDirtyCategories = (
  dirtyFields,
  allValues
) => {
  const filteredDirtyFields = dirtyFields.reduce((currentField, field, index) => {
    if (field) {
      Object.values(field).forEach((value) => {
        if (value && !currentField.includes(index)) {
          currentField.push(index);
        }
      });
    }
    return currentField;
  }, []);

  return allValues.reduce(({ updateCategoriesData, createCategoriesData }, category, index) => {
    if (filteredDirtyFields.includes(index)) {
      const { isCreated, isDisabled, categoryId, key, isDeleteIconHidden, ...categoryData } = category;

      if (category.isCreated) {
        updateCategoriesData.push({ ...categoryData, categoryId });
      } else {
        createCategoriesData.push({ ...categoryData, key });
      }
    }
    return { updateCategoriesData, createCategoriesData };
  }, { updateCategoriesData: [], createCategoriesData: [] });
};

export const getFormCategoriesErrors = (formErrors) => {
  if (isEmpty(formErrors)) {
    return [];
  }

  const filteredFormErrors =
    formErrors.reduce((currentFieldErrors, fieldError, index) => fieldError ? [...currentFieldErrors, { ...fieldError, index }] : currentFieldErrors, []);

  return filteredFormErrors.map((fieldError) => {
    const newFieldError = {};

    Object.keys(fieldError).forEach((key) => {
      if (key === 'index') {
        newFieldError[key] = fieldError[key];
        return;
      }
      newFieldError.messages = newFieldError.messages ? [...newFieldError.messages, fieldError[key].message] : [fieldError[key].message];
    });

    return newFieldError;
  });
};

export const generateFormNotifications = (errors) => {
  toast.dismiss();

  errors.forEach((fieldError) => {
    const { index, messages } = fieldError;

    messages.forEach((message) => {
      toast.error(
        `Line ${index + 1}: ${message}`,
        {
          toastId: `${index}${message}`,
          containerId: NOTIFICATIONS_CATEGORIES_FORM_ID,
        }
      );
    });
  });
};

export const generateRequestNotifications = (errors) => {
  toast.dismiss();
  const updateCategoriesErrors = get(errors, 'updateCategoriesErrors', []);
  const deleteCategoriesErrors = get(errors, 'deleteCategoriesErrors', []);

  [...updateCategoriesErrors, ...deleteCategoriesErrors]
    .forEach(({ message, toastId }) => toast.error(message, { containerId: NOTIFICATIONS_FAILED_REQUESTS, toastId }));
};

export const isEmptyPropsInCollection = (collection, listOfProps) => listOfProps.every((prop) => isEmpty(collection[prop]));
