import { USERS_GROUPS } from 'core/auth/constants';
import { COLUMN_TYPES } from 'core/constants';
import moment from 'moment';
import { getHasPermissions } from 'utils/auth';
import { string, date as yupDate } from 'yup';

import { tableWrapperCssRules } from './styles';

const {
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
  CPS,
} = USERS_GROUPS;

const gridTemplateConfig = `{
  padding: 0;
  margin-top: 0;
  grid-column-gap: 0.8rem;
}`;

export const hiqoContractsListModelGetter = (
  userGroup,
  devcenters,
  customersById
) => {
  const isAudit = getHasPermissions(userGroup, [
    DELIVERY_OFFICE_ADMIN_BY,
    DELIVERY_OFFICE_ADMIN_SPB,
    DELIVERY_OFFICE_ADMIN_WAW,
    DELIVERY_OFFICE_ADMIN_TBS,
    DELIVERY_OFFICE_ADMIN_KZH,
  ]);

  const isCPS = getHasPermissions(userGroup, [CPS]);

  return {
    rules: {
      css: {
        tableWrapperCssRules,
        gridTemplateConfig: `{
          ${gridTemplateConfig}
          grid-template-columns: 40fr ${isAudit ? '' : '20fr'} 20fr 10fr ${
  isAudit ? '' : '5fr'
};
        }`,
        headerCssRules: `
          ${gridTemplateConfig}
          grid-template-columns: 40fr ${isAudit ? '' : '20fr'} 20fr 10fr ${
  isAudit ? '' : '5fr'
};
          position: sticky;
          top: 7rem;
          z-index: 1;
          background-color: #f2f2f2;
        `,
      },
    },
    tableName: 'hiqoContracts',
    rowStatusGetter: () => ({}),
    dataTemplate: [
      {
        columnName: 'Name',
        valueGetter: ({ name }) => ({
          type: COLUMN_TYPES.TEXT,
          componentProps: {
            data: name,
            cssRules: `
              font-size: 1.5rem;
            `,
          },
        }),
        cssRules: '',
      },
      {
        columnName: 'Delivery Center',
        shouldntBeRendered: isAudit,
        valueGetter: ({ devcenter }) => ({
          type: COLUMN_TYPES.TEXT,
          componentProps: {
            data: devcenters.byId[devcenter],
            cssRules: `
              font-size: 1.5rem;
            `,
          },
        }),
        cssRules: '',
      },
      {
        columnName: 'Customer',
        valueGetter: ({ customer }) => ({
          type: COLUMN_TYPES.TEXT,
          componentProps: {
            data: customersById[customer],
            cssRules: `
              font-size: 1.5rem;
            `,
          },
        }),
        cssRules: '',
      },
      {
        columnName: 'Date From',
        valueGetter: ({ dateFrom }) => ({
          type: COLUMN_TYPES.TEXT,
          componentProps: {
            data: dateFrom,
            cssRules: `
              font-size: 1.5rem;
            `,
          },
        }),
      },
      {
        columnName: 'Action',
        shouldntBeRendered: isAudit,
        valueGetter: ({ id = null, name }) => ({
          type: COLUMN_TYPES.ACTION,
          actionName: 'openModal',
          componentProps: {
            data: 'Delete',
            actionName: 'openModal',
            isHidden: isCPS,
            actionArguments: {
              currentModal: 'CONFIRM_ACTION_MODAL',
              content: [
                {
                  type: 'title',
                  data: `Delete contract "${name}"?`,
                },
                {
                  type: 'description',
                  data: 'This contract will be permanently deleted and cannot be restored.',
                },
              ],
              acceptActionName: 'removeHiqoContractsRecord',
              acceptActionTitle: 'Delete',
              acceptActionArguments: {
                recordId: id,
              },
            },
          },
        }),
        cssRules: '',
        withoutControls: true,
      },
    ],
  };
};

export const hiqoContractsFormTemplate = (devcenters, customers) => [
  {
    stylesTemplate: {
      cssRules: `{
        ${gridTemplateConfig}
        grid-template-columns: 40fr 20fr 20fr 10fr 5fr;
      }`,
    },
    isTableView: true,
    formData: [
      {
        type: 'text',
        name: 'name',
        withLabel: false,
        placeholder: 'Contract Name',
        valueGetter: ({ newContract }) => newContract.name || '',
        validationRules: {
          schemaGetter: () => string().required('Required field'),
        },
        cssRules: `{
        font-size: 1.5rem;
        padding-left: 1.6rem;
      }`,
      },
      {
        type: 'select',
        name: 'devcenter',
        withLabel: false,
        placeholder: 'Choose Delivery Center',
        valueGetter: ({ newContract }) => ({
          selected: newContract.devcenter || '',
          items: devcenters.forSelect,
        }),
        cssRules: `{
        && .select-item__control {
          font-size: 1.5rem;
          padding-left: 1.6rem;
          padding-right: 1.6rem;
        }
      }
      `,
        validationRules: {
          isRequired: true,
          schemaGetter: () => string().nullable().required('Required field'),
        },
      },
      {
        type: 'select',
        name: 'customer',
        withLabel: false,
        placeholder: 'Choose customer',
        valueGetter: ({ newContract }) => ({
          selected: newContract.customer || '',
          items: customers,
        }),
        cssRules: `{
        && .select-item__control {
          font-size: 1.5rem;
          padding-left: 1.6rem;
          padding-right: 1.6rem;
        }
      }
      `,
        validationRules: {
          isRequired: true,
          schemaGetter: () => string().nullable().required('Required field'),
        },
      },
      {
        type: 'calendar',
        name: 'dateFrom',
        valueGetter: ({ newContract }) => newContract.dateFrom || null,
        minYear: moment().year(),
        withCurrentDate: false,
        maxDetails: 'year',
        minDetails: 'month',
        popperProps: {
          placement: 'bottom-end',
        },
        outputFormatting: ({ date }) => moment.isMoment(date) ? date.format('YYYY-MM-DD') : date,
        validationRules: {
          schemaGetter: () => yupDate()
            .nullable()
            .min(
              `${moment().year()}-01-01`,
              () => `Date must be included in ${moment().year()} year`
            )
            .required('Required field'),
        },
        cssRules: `{
        font-size: 1.5rem;
        max-height: unset;
      }`,
      },
      {
        type: 'actionButton',
        data: 'Delete',
        actionName: 'setCreateMode',
        actionArguments: { createMode: false },
        cssRules: `
        && {
          font-size: 1.2rem;
        }
      `,
      },
    ],
  },
];
