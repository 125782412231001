import {
  colorSecondaryGray,
  colorSecondaryGrayLight,
  colorSecondaryText,
} from 'assets/styles/variables';

import { get } from 'lodash';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import {
  string,
  number,
} from 'yup';

import {
  compareCalendarDates,
  getCalendarValidationMessage,
  approveButtonCSS,
  approveButtonLockedCSS,
  customCssRules,
  descriptionAlertCSS,
  descriptionApprovedCSS,
  descriptionInactiveCSS,
  errorBoxCssRules,
  fieldsDataBySeniority,
  hrsDayItems,
  internalSeniorityItems,
  plLevelByIdForFormRepresentation,
  plLevelField,
  rejectButtonCSS,
  rejectButtonLockedCSS,
  seniorityItems,
  workBookFormTextInputCss,
  workBookSelectStyles,
  emptySelectValue,
  ACTION_BUTTON_TEXTS,
  WORKBOOK_ACTIONS,
} from './utils/workbook';

import { getAdditionalControlsCommonCssGrid, generateEndOfProbationByValidFrom } from '../utils/workbook';

moment.tz.setDefault('UTC');

export const workBookFormGetter = ({
  partialGridTemplateColumnsConfig,
  titlesFromDevstaffCompensation,
  additionalControlsConfig,
  hasPermissionsToUpdate,
  internshipStartDate,
  deleteRecordAction,
  isHistoryRecord,
  isInternRecord,
  dismissalDate,
  isDismissed,
  devstaffId,
  inEditMode,
  rehireDate,
  formStyles,
  devcentersListForSelect,
  dateHired,
  dateLast,
  fullname,
  isCEO,
  isDevCenterDirector,
  record,
  userId,
  isSalaryHidden,
  hasNoSalaryInfo,
}) => {
  const {
    remarks,
    seniority,
    recordId,
    probation,
    endOfProbation,
    validFrom,
    seniorityInt,
    hrsDay,
    title,
    approvedDd,
    plLevel,
    salUsd,
    salPln,
    salRur,
    devcenter,
    templateOwnerId,
  } = record;
  const isDraft = !!templateOwnerId;
  const isDraftOwner = templateOwnerId === userId;
  return {
    withErrorBox: true,
    recordId: `${recordId}/${dateHired || ''}`,
    onSubmitAction: WORKBOOK_ACTIONS.UPDATE,
    additionalFields: {
      recordId,
      devstaffId,
      ...(isDevCenterDirector && {
        approvedDd,
        approvedHrm: true,
        templateOwnerId: userId,
      }),
    },
    stylesTemplate: {
      cssRules: `
      grid-area: record;
      grid-template-columns: 1fr;
      grid-row-gap: 0;
      padding-bottom: 2.4rem;
    `,
      errorBoxCssRules,
      formWrapperStyles: `
      grid-area: record;
      margin: 0.4rem 0 0;
      padding: 0;
      background-color: ${!isHistoryRecord || inEditMode ? '#fbfbfb' : 'rgba(187,189,192, 0.15)'};
    `,
      formStyles,
    },
    mainControlsConfig: {
      cssRules: `
      grid-template-columns: 8.8rem;
      grid-area: controls;
      grid-template-rows: repeat(2, 1fr);
      grid-row-gap: 1.6rem;
      margin: 0 auto;
      padding-top: 1.9rem;
      padding-bottom: 2.4rem;
      font-size: 1.3rem;
    `,
      onReject: ({
        handleReset,
        actions,
        hasFormChanges,
      }) => {
        if (hasFormChanges) {
          return handleReset();
        }

        if (inEditMode) {
          return actions.toggleWorkBookRecordState({
            recordId,
          });
        }

        return actions.openModal(deleteRecordAction);
      },
      /* return object according current form state, used in FormMainControls component */
      getResetButtonConfig: ({
        hasFormChanges,
        isFormSubmitted,
      }) => {
        const isCancel = inEditMode || hasFormChanges;
        const canUpdateOrIsDraftOwner = hasPermissionsToUpdate || (isDraft && isDraftOwner);

        return {
          isResetVisible: !isFormSubmitted && (hasFormChanges || canUpdateOrIsDraftOwner),
          rejectActionTitle: isCancel ? ACTION_BUTTON_TEXTS.CANCEL : ACTION_BUTTON_TEXTS.DELETE,
          rejectButtonCssRules: isCancel ? '' : `
          & {
            border-color: ${colorSecondaryGrayLight};
            color: ${colorSecondaryGray};
          }
        `,
        };
      },
    },
    additionalControlsConfig,
    content: [
      {
        stylesTemplate: {
          marginTop: '0.3rem',
          cssRules: `
            grid-template-columns: ${partialGridTemplateColumnsConfig};
            grid-column-gap: 0.8rem;
            padding: 0;
          `,
        },
        formData: [
          {
            type: 'viewBox',
            getIsUnitLocked: () => true,
            isHidden: !fullname || !devstaffId,
            value: {
              value: fullname,
              title: fullname,
              path: `/delivery/employee/${devstaffId}/work-book`,
            },
            cssRules: `
                height: 3.6rem;
                margin: 0.9rem 0 0 1.6rem;
                padding: 1.4rem 0.4rem 0;
                font-size: 1.3rem;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                && {
                  .viewbox__viewbox-link {
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                  }
                }
              `,
          },
          {
            type: 'calendar',
            name: 'validFrom',
            label: 'Valid from',
            value: validFrom || null,
            outputFormatting: ({ date }) => (
              moment.isMoment(date) ?
                date.format('YYYY-MM-DD') :
                date
            ),
            popperProps: {
              placement: 'bottom-start',
            },
            getIsUnitLocked: () => !hasPermissionsToUpdate,
            validationRules: {
              schemaGetter: () => string()
                .nullable()
                .test(
                  'The selected date can\'t be earlier than "Reonboarding Date".',
                  'The selected date can\'t be earlier than "Reonboarding Date".',
                  (date) => !moment(rehireDate).isAfter(moment(date))
                )
                .test(
                  'isCorrectDate',
                  getCalendarValidationMessage({
                    isDismissed,
                    dateHired,
                    dateLast,
                  }),
                  (date) => compareCalendarDates(date, {
                    isDismissed,
                    isInternRecord,
                    dateHired,
                    dismissalDate,
                    internshipStartDate,
                    dateLast,
                  }),
                ).required('Required field'),
            },
            cssRules: `
              align-items: center !important;
              max-height: unset;
              font-size: 1.2rem;
              letter-spacing: -0.0025rem;
              color: ${colorSecondaryText};
              && {
                ${fullname && devstaffId ? '' : `
                  width: calc(100% - 1.6rem);
                  min-width: auto;
                  margin-left: 1.6rem;
                `}
              }
            `,
          },
          {
            type: 'select',
            name: 'devcenter',
            label: 'Dev center',
            value: {
              selected: {
                value: devcenter,
              },
              items: devcentersListForSelect,
            },
            cssRules: workBookSelectStyles,
            validationRules: {
              isRequired: true,
              schemaGetter: () => string().nullable().required('Required field'),
            },
          },
          {
            type: 'select',
            name: 'title',
            label: 'Title',
            isCreatable: true,
            value: {
              selected: {
                value: title,
              },
              items: titlesFromDevstaffCompensation || [
                {
                  value: title,
                  label: title,
                },
              ],
            },
            validationRules: {
              isRequired: true,
              maxLength: 80,
              schemaGetter: () => string()
                .max(80, 'Title max length is 80')
                .required('Required field'),
            },
            cssRules: `
              ${workBookSelectStyles}
              & .select-item__single-value {
                overflow: hidden;
              }
              && .select-item__menu {
                width: 156%;
              }
            `,
          },
          {
            type: 'selectNew',
            name: 'seniority',
            label: 'Seniority',
            value: {
              selected: get(fieldsDataBySeniority, `${seniority}.selected`, dateHired ? emptySelectValue : seniorityItems[0]),
              items: dateHired ? seniorityItems : [],
            },
            cssRules: workBookSelectStyles,
            validationRules: {
              isRequired: true,
              schemaGetter: () => string().nullable().required('Required field'),
            },
          },
          {
            type: 'selectNew',
            name: 'seniorityInt',
            label: 'Internal Seniority',
            value: {
              selected: {
                value: seniorityInt || '',
                label: '--',
              },
              items: get(internalSeniorityItems, `${seniority}`, []),
            },
            onFormChange: ({
              errors,
              values: {
                seniority: storedSeniority,
              },
              setFieldError,
            }) => {
              const isIntern = storedSeniority === 'Intern';

              if (isIntern && get(errors, 'seniorityInt')) {
                toast.dismiss('seniorityInt');
                setFieldError('seniorityInt', null);
              }

              if (isIntern && get(errors, 'salUsd')) {
                toast.dismiss('salUsd');
                setFieldError('salUsd', null);
              }
              return {};
            },
            parentField: {
              name: ['seniority'],
              strictDependence: true,
              valueGetter: ({
                seniority: storedSeniority,
                seniorityInt: storedSeniorityInt,
              }, fieldData) => {
                const items = get(internalSeniorityItems, `${storedSeniority}`, []);
                const selected = items.find(({
                  value,
                }) => value === storedSeniorityInt) || {
                  value: '',
                  label: '--',
                };

                return {
                  ...fieldData,
                  selected,
                  items,
                };
              },
            },
            cssRules: workBookSelectStyles,
            validationRules: {
              isRequired: true,
              schemaGetter: () => string()
                .nullable()
                .when('seniority', (storedSeniority, schema) => storedSeniority === 'Intern' ? schema : schema.required('Required field')),
            },
          },
          {
            type: 'select',
            name: 'plLevel',
            value: {
              selected: {
                value: plLevelByIdForFormRepresentation[plLevel] || '-2',
                label: '--',
              },
              items: plLevelField.items,
            },
            onFormChange: ({
              errors,
              values: {
                seniority: storedSeniority,
                plLevel: storedPlLevel,
              },
              setFieldValue,
            }) => {
              const isIntern = storedSeniority === 'Intern';

              if (isIntern && get(errors, 'plLevel')) {
                toast.dismiss('plLevel');
              }

              if (isIntern && storedPlLevel !== '-2') {
                setFieldValue('plLevel', '-2');
              }
              return {
                isLocked: isIntern,
              };
            },
            cssRules: workBookSelectStyles,
            validationRules: {
              isRequired: true,
            },
          },
          {
            type: 'select',
            name: 'hrsDay',
            label: 'Hrs/day',
            value: {
              selected: {
                value: hrsDay,
                label: hrsDay,
              },
              items: hrsDayItems,
            },
            validationRules: {
              isRequired: true,
              schemaGetter: () => number().required('Required field'),
            },
            cssRules: workBookSelectStyles,
          },
          {
            type: 'checkbox',
            name: 'probation',
            value: !!probation,
            cssRules: 'justify-content: flex-end; padding: 0.3rem; font-size: 1.6rem;',
            onFormChange: ({
              values: {
                seniority: storedSeniority,
                probation: storedProbation,
              },
              setFieldValue,
            }) => {
              const isIntern = storedSeniority === 'Intern';

              if (isIntern && storedProbation !== false) {
                setFieldValue('probation', false);
              }
              return {
                isLocked: isIntern,
              };
            },
            validationRules: {},
          },
          {
            type: 'calendar',
            name: 'endOfProbation',
            label: 'End of Probation',
            value: probation ? endOfProbation : null,
            outputFormatting: ({ date }) => (
              moment.isMoment(date) ?
                date.format('YYYY-MM-DD') :
                date
            ),
            popperProps: {
              placement: 'bottom-start',
            },
            getIsUnitLocked: () => !hasPermissionsToUpdate,
            onFormChange: ({ errors, values, setFieldError }) => {
              if (!values.probation && get(errors, 'endOfProbation')) {
                toast.dismiss('endOfProbation');
                setFieldError('endOfProbation', null);
              }
              return {
                formValue: values.probation ? values.endOfProbation : null,
                isLocked: !values.probation,
              };
            },
            parentField: {
              name: ['validFrom', 'probation'],
              valueGetter: ({ validFrom: storedValidFrom, probation: storedProbation }) => generateEndOfProbationByValidFrom(storedProbation, storedValidFrom),
            },
            validationRules: {
              schemaGetter: () => string()
                .nullable()
                .test(
                  'validFrom',
                  'The selected date must be equal or later than Valid from date',
                  function test(storedEndOfProbation) {
                    const validFromDate = moment(this.parent.validFrom);
                    const storedEndOfProbationDate = moment(storedEndOfProbation);
                    const storedProbation = this.parent.probation;
                    if (!storedProbation) {
                      return true;
                    }
                    return storedEndOfProbationDate.isSameOrAfter(validFromDate, 'day');
                  },
                ),
            },
            cssRules: `
              align-items: center;
              max-height: unset;
              font-size: 1.2rem;
              letter-spacing: -0.0025rem;
              color: ${colorSecondaryText};
            `,
          },
          {
            type: 'text',
            name: 'salUsd',
            label: 'USD, RUR, PLN',
            value: {
              placeholder: '0',
              value: salUsd,
            },
            isHidden: !hasPermissionsToUpdate || isSalaryHidden,
            validationRules: {
              isRequired: true,
              isNumeric: true,
              maxValue: 99999,
              schemaGetter: hasPermissionsToUpdate && !isSalaryHidden ? () => string()
                .nullable()
                .when(
                  ['salPln', 'salRur', 'seniority'],
                  (storedSalPln, storedSalRur, storedSeniority, schema) => {
                    const isIntern = storedSeniority === 'Intern';

                    if (isIntern) {
                      return schema;
                    }

                    return (storedSalPln && Number(storedSalPln) !== 0) ||
                (storedSalRur && Number(storedSalRur) !== 0) ?
                      schema :
                      schema.test('noSalaryInfo', 'Please enter valid salary value in any of the following currencies: USD, PLN, RUR.', (storedSalUsd) => (storedSalUsd && Number(storedSalUsd) !== 0));
                  }
                ) : null,
            },
            cssRules: `${workBookFormTextInputCss} text-align: right;`,
            onFormChange: (params) => {
              const {
                setFieldValue,
                errors,
                values: {
                  seniority: storedSeniority,
                  salUsd: storedSalUsd,
                  salPln: storedSalPln,
                  salRur: storedSalRur,
                },
              } = params;
              const isIntern = storedSeniority === 'Intern';
              if (isIntern && get(errors, 'salUsd')) {
                toast.dismiss('salUsd');
              }
              if (isIntern && Number(storedSalUsd) !== 0) {
                setFieldValue('salUsd', null);
              }
              return {
                isLocked: !!(isIntern),
                isInitialError: hasNoSalaryInfo && (!storedSalUsd && !storedSalPln && !storedSalRur),
              };
            },
          },
          {
            type: 'text',
            name: 'salPln',
            value: {
              placeholder: '0',
              value: salPln,
            },
            isHidden: !hasPermissionsToUpdate || isSalaryHidden,
            validationRules: {
              isRequired: true,
              isNumeric: true,
              maxValue: 99999,
            },
            cssRules: `${workBookFormTextInputCss} text-align: right;`,
            onFormChange: ({
              values: {
                seniority: storedSeniority,
                salUsd: storedSalUsd,
                salPln: storedSalPln,
                salRur: storedSalRur,
              },
              setFieldValue,
            }) => {
              const isIntern = storedSeniority === 'Intern';

              if (isIntern && Number(storedSalPln) !== 0) {
                setFieldValue('salPln', null);
              }

              return {
                isLocked: !!(isIntern),
                isInitialError: hasNoSalaryInfo && (!storedSalUsd && !storedSalPln && !storedSalRur),
              };
            },
          },
          {
            type: 'text',
            name: 'salRur',
            value: {
              placeholder: '0',
              value: salRur,
            },
            isHidden: !hasPermissionsToUpdate || isSalaryHidden,
            validationRules: {
              isRequired: true,
              isNumeric: true,
              maxValue: 999999,
            },
            cssRules: `${workBookFormTextInputCss} text-align: right;`,
            onFormChange: ({
              values: {
                seniority: storedSeniority,
                salUsd: storedSalUsd,
                salPln: storedSalPln,
                salRur: storedSalRur,
              },
              setFieldValue,
            }) => {
              const isIntern = storedSeniority === 'Intern';

              if (isIntern && Number(storedSalRur) !== 0) {
                setFieldValue('salRur', null);
              }
              return {
                isLocked: !!(isIntern),
                isInitialError: hasNoSalaryInfo && (!storedSalUsd && !storedSalPln && !storedSalRur),
              };
            },
          },
        ],
      },
      {
        stylesTemplate: {
          cssRules: `
            grid-template-columns: 1fr;
            margin-top: 0.3rem;
            padding: 0;
          `,
        },
        formData: [
          {
            type: 'text',
            name: 'remarks',
            placeholder: 'Remark',
            isMultiline: true,
            getIsUnitLocked: () => approvedDd && isCEO && !!remarks,
            value: remarks || '',
            validationRules: {
              isRequired: true,
              schemaGetter: () => string().trim().max(2000, 'must be at most 2000 characters').required('Required field'),
            },
            cssRules: `
                ${workBookFormTextInputCss}
                margin-left: 1.6rem;
                line-height: 1.6rem;
                & .text-input__input-field {
                  padding: 0.4rem 0;
                }
              `,
          },
        ],
      }],
  };
};

export const additionalControlsConfigGetter = ({
  hasNoSalaryInfo,
  hasPermissionsToUpdate,
  isHistoryRecord,
  devstaffId,
  isCEO,
  isDevCenterDirector,
  isRelocate,
  record,
  userId,
}) => {
  const {
    recordId,
    approvedDd,
    approvedCeo,
    rejectedCeo,
    rejectedDd,
    approvedDdDate,
    approvedCeoDate,
    rejectedDdDate,
    rejectedCeoDate,
    templateOwnerId,
  } = record;
  const isDraft = Boolean(templateOwnerId);
  const isDraftOwner = templateOwnerId === userId;

  const formatMomentDate = (date) => date ? moment(date).tz('America/New_York').format('L LT') : '';
  const createActionButton = (label, conditions, extraFields = {}, cssRules) => ({
    type: 'actionButton',
    data: label,
    withBody: true,
    ...extraFields,
    cssRules,
    ...conditions,
  });

  const hideSendToApproveDD = isRelocate && (isDraft && isDraftOwner) ? false : isDevCenterDirector || approvedDd || !isDraft || !isDraftOwner;
  const hideActionApproveDd = isRelocate || !hideSendToApproveDD || isCEO || isHistoryRecord || (isDraft && !(isDevCenterDirector && isDraftOwner)) || approvedDd || !isDevCenterDirector;
  const hideActionDisapproveDd = isRelocate || !hideSendToApproveDD || isCEO || isHistoryRecord || (isDraft && !(isDevCenterDirector && isDraftOwner)) || rejectedDd || approvedDd || !isDevCenterDirector;
  const hideApprove = !hideSendToApproveDD || (!isHistoryRecord && !approvedDd);
  const hideRejectOrPendingApprovalDd = !hideSendToApproveDD || !hideApprove || !hideActionDisapproveDd || (isDraft && !isDraftOwner);
  const hideNotAvailableDd = isHistoryRecord || !hideSendToApproveDD || !hideActionApproveDd || !hideActionDisapproveDd || !hideRejectOrPendingApprovalDd || !hideApprove;

  const hideActionApproveCEO = !isCEO || isHistoryRecord || rejectedCeo || isDraft || (!rejectedCeo && !approvedDd) || isDevCenterDirector;
  const hideActionDisapproveCEO = !isCEO || rejectedCeo || isHistoryRecord || !approvedDd || isDevCenterDirector;
  const hideRejectOrPendingApprovalCEO = (isCEO && !rejectedCeo) || approvedCeo || (!approvedDd && !rejectedCeo);
  const hideNotAvailableCEO = approvedDd || (rejectedCeo && !isDraft);

  return ({
    cssRules: getAdditionalControlsCommonCssGrid(hasPermissionsToUpdate),
    content: [
      {
        isMultiple: true, // DD approval info
        isHidden: !hasPermissionsToUpdate,
        cssRules: customCssRules.multipleButtonsCss,
        data: [
          createActionButton(
            ACTION_BUTTON_TEXTS.SEND_TO_APPROVE,
            {
              isHidden: hideSendToApproveDD,
              onFormChange: ({
                hasFormChanges,
              }) => hasFormChanges ? {
                cssRules: customCssRules.approveButtonLocked,
              } : {},
            },
            {
              actionName: WORKBOOK_ACTIONS.UPDATE,
              actionArguments: {
                recordId,
                devstaffId,
                fields: {
                  templateOwnerId: false,
                  approvedHrm: true,
                },
              },
            },
            customCssRules.sendToApproveButton
          ),
          createActionButton(
            ACTION_BUTTON_TEXTS.APPROVE,
            {
              isHidden: hideActionApproveDd,
              onFormChange: ({
                hasFormChanges,
              }) => hasFormChanges ? {
                cssRules: approveButtonLockedCSS,
              } : {},
            },
            {
              actionName: WORKBOOK_ACTIONS.UPDATE,
              actionArguments: {
                recordId,
                devstaffId,
                fields: {
                  approvedDd: true,
                  templateOwnerId: false,
                },
              },
            },
            hasNoSalaryInfo ? approveButtonLockedCSS : approveButtonCSS
          ),
          createActionButton(
            ACTION_BUTTON_TEXTS.DISAPPROVE,
            {
              isHidden: hideActionDisapproveDd,
              onFormChange: ({
                hasFormChanges,
              }) => hasFormChanges ? {
                cssRules: rejectButtonLockedCSS,
              } : {},
            },
            {
              actionName: WORKBOOK_ACTIONS.UPDATE,
              actionArguments: {
                recordId,
                devstaffId,
                fields: {
                  rejectedDd: true,
                  templateOwnerId: false,
                },
              },
            },
            rejectButtonCSS
          ),
          {
            type: 'description',
            data: `${ACTION_BUTTON_TEXTS.APPROVE} ${formatMomentDate(approvedDdDate)}`,
            isMultiline: true,
            isHidden: hideApprove,
            cssRules: descriptionApprovedCSS,
          },
          {
            type: 'description',
            data: rejectedDd ? `${ACTION_BUTTON_TEXTS.DISAPPROVE} ${formatMomentDate(rejectedDdDate)}` : 'Pending approval',
            isHidden: hideRejectOrPendingApprovalDd,
            isMultiline: true,
            cssRules: descriptionAlertCSS,
          },
          {
            type: 'description',
            data: ACTION_BUTTON_TEXTS.NOT_AVAILABLE,
            isMultiline: true,
            isHidden: hideNotAvailableDd,
            cssRules: descriptionInactiveCSS,
          },
        ],
      },
      {
        isMultiple: true, // CEO approval info
        isHidden: !hasPermissionsToUpdate,
        cssRules: customCssRules.multipleButtonsCss,
        data: [
          createActionButton(
            ACTION_BUTTON_TEXTS.APPROVE,
            {
              isHidden: hideActionApproveCEO,
              onFormChange: ({
                hasFormChanges,
              }) => hasFormChanges ? {
                cssRules: approveButtonLockedCSS,
              } : {},
            },
            {
              actionName: WORKBOOK_ACTIONS.UPDATE,
              actionArguments: {
                recordId,
                devstaffId,
                fields: {
                  approvedCeo: true,
                },
              },
            },
            approveButtonCSS
          ),
          createActionButton(
            'Disapprove',
            {
              isHidden: hideActionDisapproveCEO,
              onFormChange: ({
                hasFormChanges,
              }) => hasFormChanges ? {
                cssRules: rejectButtonLockedCSS,
              } : {},
            },
            {
              actionName: WORKBOOK_ACTIONS.UPDATE,
              actionArguments: {
                recordId,
                devstaffId,
                fields: {
                  rejectedCeo: true,
                },
              },
            },
            rejectButtonCSS
          ),
          {
            type: 'description',
            data: rejectedCeo ? `${ACTION_BUTTON_TEXTS.DISAPPROVE} ${formatMomentDate(rejectedCeoDate)}` : 'Pending approval',
            isHidden: hideRejectOrPendingApprovalCEO, // TODO
            isMultiline: true,
            cssRules: descriptionAlertCSS,
          },
          {
            type: 'description',
            data: `${ACTION_BUTTON_TEXTS.APPROVE} ${formatMomentDate(approvedCeoDate)}`,
            isHidden: !isHistoryRecord, // TODO is not history record
            isMultiline: true,
            cssRules: descriptionApprovedCSS,
          },
          {
            type: 'description',
            data: ACTION_BUTTON_TEXTS.NOT_AVAILABLE,
            isHidden: hideNotAvailableCEO,
            isMultiline: true,
            cssRules: descriptionInactiveCSS,
          }],
      },
      {
        type: 'description',
        data: `Pending ${approvedDd ? 'CEO' : 'Dev Center Director'}\xa0approval`,
        isHidden: isHistoryRecord || hasPermissionsToUpdate || isDraft || rejectedCeo || rejectedDd,
        isMultiline: true,
        cssRules: descriptionAlertCSS,
      },
      {
        type: 'description',
        data: `Disapproved by ${rejectedDd ? 'Dev Center Director' : 'CEO'} ${(rejectedDdDate && rejectedDd) || rejectedCeoDate ? moment(rejectedDdDate || rejectedCeoDate).tz('America/New_York').format('L LT') : ''}`,
        isHidden: isHistoryRecord || hasPermissionsToUpdate || !(rejectedCeo || rejectedDd),
        isMultiline: true,
        cssRules: descriptionAlertCSS,
      },
      {
        type: 'description',
        data: 'Not Available',
        isHidden: hasPermissionsToUpdate || isDraftOwner || !isDraft,
        isMultiline: true,
        cssRules: descriptionInactiveCSS,
      },
      {
        type: 'description',
        data: `Approved ${formatMomentDate(approvedCeoDate)}`,
        isHidden: hasPermissionsToUpdate || !isHistoryRecord, // TODO is not history record
        isMultiline: true,
        cssRules: `${descriptionApprovedCSS} padding: 0.7rem 4.5rem 0;`,
      },
    ],
  });
};
