import { differenceWith, isEqual } from 'lodash';

export const getDirtyDiscountSurchargeRates = (fields, initialValues) => {
  const { discountSurchargeRates } = fields;
  const { discountSurchargeRates: initialDiscountSurchargeRates } = initialValues;

  const dirtyMonthFields =
  differenceWith(discountSurchargeRates, initialDiscountSurchargeRates, isEqual)
    .reduce((currentFields, { year, monthData }) => {
      const findIndex = initialDiscountSurchargeRates.findIndex((item) => item.year === year);

      if (findIndex !== -1) {
        const { monthData: initialMonthData } = initialDiscountSurchargeRates[findIndex];

        const changedMonthFields = differenceWith(
          monthData,
          initialDiscountSurchargeRates[findIndex].monthData,
          isEqual
        );
        const changedMonths = changedMonthFields.map((monthField) => monthField.month);

        return [
          ...currentFields,
          {
            year,
            monthData: changedMonthFields,
            initialMonthData:
            initialMonthData.filter((monthField) => changedMonths.includes(monthField.month)),
          },
        ];
      }
      return currentFields;
    }, []);

  if (!dirtyMonthFields.length) {
    return null;
  }

  return dirtyMonthFields
    .reduce((currentMonthField, field) => {
      const { monthData, initialMonthData } = field;

      const dirtyMonthRates = monthData.reduce((currentFields, monthField, currentIndex) => {
        const { monthRates } = monthField;
        const changedMonthFields = differenceWith(
          monthRates,
          initialMonthData[currentIndex].monthRates,
          isEqual
        );
        return [...currentFields, ...changedMonthFields];
      }, []);

      return [...currentMonthField, ...dirtyMonthRates];
    }, [])
    .map(({ discountsAndSurchargesRecordId, percentage }) => ({
      recordId: Number(discountsAndSurchargesRecordId),
      percentage,
    }));
};
