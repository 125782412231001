import React, { memo, useCallback } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as IconSort } from 'assets/icons/sort.svg';
import classNames from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import { useTable, useSortBy, usePagination } from 'react-table';

import styles from './styles.module.scss';

import { Pagination } from '../pagination';

const TEXT = {
  WARNING_TITLE: 'Sorry, no content matched your criteria.',
  WARNING_DESCRIPTION: 'Please change the selected filters.',
};

export const BasicTable = memo(({ id, columns, data, disabledRows, warningRows, errorRows, isSort, paginate, className }) => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const page = parseInt(urlParams.get(id), 10) || 1;
  const { getTableProps, getTableBodyProps, headerGroups, rows, page: paginateRows, prepareRow, pageCount, gotoPage } =
    useTable(
      {
        columns,
        data,
        initialState:
        {
          pageIndex: page - 1,
          pageSize: paginate.pageSize,
          sortBy: [{ id: columns[0].accessor, desc: false }],
        },
      },
      useSortBy,
      usePagination
    );

  const tableRows = paginate.enable ? paginateRows : rows;

  const setQueryParams = (value) => {
    urlParams.set(id, value);
    history.push(`?${urlParams.toString()}`);
  };

  const handlePageChange = useCallback(({ selected }) => {
    gotoPage(selected);
    setQueryParams(selected + 1);
  }, [urlParams]);

  const handlePageStart = useCallback(() => {
    gotoPage(0);
    setQueryParams(1);
  }, [urlParams]);

  const handlePageEnd = useCallback(() => {
    gotoPage(pageCount - 1);
    setQueryParams(pageCount);
  }, [urlParams]);

  return (
    <>
      <table className={classNames(styles.table, className)} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr className={styles.tableRow} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={classNames(styles.tableHeader, column.classNames)}
                  {...column.getHeaderProps(isSort && column.getSortByToggleProps())}
                  onClick={() => isSort && column.toggleSortBy(!column.isSortedDesc)}
                >
                  <div
                    className={classNames(styles.tableHeaderWrapper, {
                      [styles.active]: column.isSorted,
                    })}
                  >
                    {column.render('Header')}
                    {
                      isSort && (
                        <IconSort
                          className={classNames(styles.sort, {
                            [styles.desc]: column.isSortedDesc,
                          })}
                        />
                      )
                    }
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {tableRows.map((row) => {
            prepareRow(row);
            return (
              <tr
                className={classNames(styles.tableRow, {
                  [styles.disabled]: disabledRows.includes(row.id),
                  [styles.warning]: warningRows.includes(row.id),
                  [styles.error]: errorRows.includes(row.id),
                })}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <td className={styles.tableData} {...cell.getCellProps()}>
                    { cell.render('Cell') }
                  </td>
                ))}
              </tr>
            );
          })}
          {
            tableRows.length === 0 && (
              <tr className={classNames(styles.tableRow, styles.empty)}>
                <td className={styles.tableData} colSpan={columns.length}>
                  <h3 className={styles.warningTitle}>{TEXT.WARNING_TITLE}</h3>
                  <p className={styles.warningDescription}>{TEXT.WARNING_DESCRIPTION}</p>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
      {
        paginate.enable && (
          <div className={styles.pagination}>
            <Pagination
              handlePageStart={handlePageStart}
              handlePageEnd={handlePageEnd}
              handlePageClick={handlePageChange}
              pageCount={pageCount}
              forcePage={page - 1}
            />
          </div>
        )
      }
    </>
  );
});

BasicTable.propTypes = {
  id: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    Header: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
    Cell: PropTypes.func,
    sortType: PropTypes.func,
  })).isRequired,
  data: PropTypes.array.isRequired,
  isSort: PropTypes.bool,
  paginate: PropTypes.shape({
    enable: PropTypes.bool,
    pageSize: PropTypes.number,
  }),
  className: PropTypes.string,
};

BasicTable.defaultProps = {
  isSort: false,
  paginate: {
    enable: true,
    pageSize: 1,
  },
  className: null,
  disabledRows: [],
  warningRows: [],
  errorRows: [],
};
