import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import {
  ENTITY_NAME,
} from './constants';

export const userActionsTypes = createTypes([
  ...async('GET_USER_DATA'),
], ENTITY_NAME);

export const userActions = {
  getUserData: createAction(userActionsTypes.GET_USER_DATA),
  getUserDataSuccess: createAction(
    userActionsTypes.GET_USER_DATA_SUCCESS,
    ({ userData }) => ({ userData })
  ),
  getUserDataFail: createAction(
    userActionsTypes.GET_USER_DATA_FAIL,
    ({ error = {} }) => ({ error })
  ),
};
