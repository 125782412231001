import React, {
  Fragment,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Calendar from 'components/calendar';
import FilterSelect from 'components/filter-select';
import PageTitle from 'components/page-title';
import { filesActions } from 'core/files/actions';
import GoTop from 'elements/go-top-button';
import StyledWrapper from 'elements/styled-wrapper';
import WrapperNg from 'elements/wrapper-ng';
import { autoScrollToTop } from 'utils/react';
import {
  format as formatDate,
  FORMATS,
} from 'utils/date';

import {
  yearPickerCssRules,
  calendarItemCssRules,
  selectCssRules,
} from './styles';

import './styles.scss';

const getOptionValue = ({ value }) => value;
const getOptionLabel = ({ label }) => label;

const UtilizationForYearReport = ({
  location,
  devCenters: deliveryCenters,
  generateUtilizationForYearReport,
}) => {
  autoScrollToTop(location);

  const [date, setDate] = useState(moment());

  const [deliveryCenter, setDeliveryCenter] = useState(undefined);

  const clickPDF = useCallback(
    () => {
      generateUtilizationForYearReport({
        devcenterIds: deliveryCenter ? deliveryCenter.map((devcenter) => devcenter.value) : Object.keys(deliveryCenters.byId),
        year: date.year(),
      });
    },
    [generateUtilizationForYearReport, date, deliveryCenter, deliveryCenters]
  );
  // const clickExcel = useCallback(
  //   () => {
  //     if (deliveryCenter != null) {
  //       generateUtilizationForYearReport({
  //         year: date.year(),
  //         devcenterIds: deliveryCenter ? deliveryCenter.map((devcenter) => devcenter.value) : Object.keys(deliveryCenters.byId),
  //       });
  //     }
  //   },
  //   [generateUtilizationForYearReport, date, deliveryCenter, deliveryCenters]
  // );

  const pdfButton = (
    <button
      className="controls__create-button button button--cancel"
      onClick={clickPDF}
    >
      PDF
    </button>
  );
  // const excelButton = (
  //   <button
  //     className="controls__create-button button button--cancel"
  //     onClick={clickExcel}
  //   >
  //     Excel
  //   </button>
  // );

  return (
    <Fragment>
      <PageTitle
        className="utilization-for-year-report__title"
        text="Utilization Report for Year"
      >
        <div className="utilization-for-year-report__buttons">
          {pdfButton}
          {/* {excelButton} */}
        </div>
      </PageTitle>

      <div className="utilization-for-year-report__filters">
        <WrapperNg>
          <Calendar
            withStepControls
            withYearSelecting
            minDetails="decade"
            onChange={setDate}
            value={date}
            cssRules={yearPickerCssRules}
            stepControlsConfig={{
              step: 1,
              unitName: 'year',
            }}
            popperProps={{
              placement: 'bottom-start',
            }}
          >
            <StyledWrapper cssRules={calendarItemCssRules}>
              {formatDate(date, FORMATS.YYYY)}
            </StyledWrapper>
          </Calendar>

          <FilterSelect
            items={deliveryCenters.forSelect}
            selected={deliveryCenter}
            onChange={setDeliveryCenter}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            placeholder="All Delivery Centers"
            isMultiple
            placeholderLength="0"
            withChips={false}
            cssRules={selectCssRules}
          />
        </WrapperNg>
      </div>

      <div className="utilization-for-year-report__content">
        <h2>
          Please select Date and Delivery center and then generate a report.
        </h2>
        <div className="utilization-for-year-report__buttons">
          {pdfButton}
          {/* {excelButton} */}
        </div>
      </div>

      <GoTop />
    </Fragment>
  );
};

UtilizationForYearReport.propTypes = {
  location: PropTypes.object.isRequired,
  devCenters: PropTypes.shape({}).isRequired,
  generateUtilizationForYearReport: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  generateUtilizationForYearReport: filesActions.generateUtilizationForYearReport,
};

export default connect(
  null,
  mapDispatchToProps
)(UtilizationForYearReport);
