import { createAction } from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import { ENTITY_NAME } from './constants';

export const billingReportsActionsTypes = createTypes([
  ...async('GET_TOTAL_ANNUAL_PAYROLL_REPORT'),
  ...async('GET_BILLING_PROJECT_REPORT'),
  ...async('GET_BILLING_SUMMARY_REPORT'),
  ...async('GET_TASK_ORDER_DETAILS'),
  ...async('GET_INTERNAL_TO_REPORT'),
  ...async('GET_TASK_ORDERS_LIST'),
  ...async('GET_HOLIDAYS_LIST'),
  ...async('GET_SALES_LIST'),
  ...async('CHANGE_FILTER'),
  'SET_SALES_ID',
  'RESET_FILTERS',
  'SET_ORDER_RULES',
  'SET_ANNUAL_REPORT_DATA',
  'SET_CURRENT_PAYROLL_REPORT_DATA',
  'SET_SUMMARY_REPORT_TYPE',
  'SET_PROJECT_REPORT_TYPE',
  'SET_PROJECT_ORDER_RULES',
  'SET_BILLING_SUMMARY_REPORT_DATA',
  'SET_BILLING_PROJECT_REPORT_DATA',
], ENTITY_NAME);

export const billingReportsActions = {
  getTotalAnnualPayrollReport: createAction(billingReportsActionsTypes.GET_TOTAL_ANNUAL_PAYROLL_REPORT),
  getTotalAnnualPayrollReportSuccess: createAction(
    billingReportsActionsTypes.GET_TOTAL_ANNUAL_PAYROLL_REPORT_SUCCESS,
    (annualPayrollReport) => (annualPayrollReport)
  ),
  getTotalAnnualPayrollReportFail: createAction(
    billingReportsActionsTypes.GET_TOTAL_ANNUAL_PAYROLL_REPORT_FAIL,
    ({ error = {} }) => ({ error })
  ),

  setSalesId: createAction(
    billingReportsActionsTypes.SET_SALES_ID,
    (salesId) => ({
      salesId,
    })
  ),

  getSalesList: createAction(billingReportsActionsTypes.GET_SALES_LIST),

  getSalesListSuccess: createAction(
    billingReportsActionsTypes.GET_SALES_LIST_SUCCESS,
    ({ salesList }) => ({ salesList })
  ),

  getSalesListFail: createAction(
    billingReportsActionsTypes.GET_SALES_LIST_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),

  setSummaryReportType: createAction(
    billingReportsActionsTypes.SET_SUMMARY_REPORT_TYPE,
    (reportType) => ({ reportType }),
  ),

  setProjectReportType: createAction(
    billingReportsActionsTypes.SET_PROJECT_REPORT_TYPE,
    ({
      reportType,
      hasCurrentTo,
    }) => ({
      reportType,
      hasCurrentTo,
    }),
  ),

  getTaskOrdersDetails: createAction(
    billingReportsActionsTypes.GET_TASK_ORDER_DETAILS,
    (taskOrder) => ({
      taskOrder,
    }),
  ),

  getTaskOrdersDetailsSuccess: createAction(
    billingReportsActionsTypes.GET_TASK_ORDER_DETAILS_SUCCESS,
    ({
      taskOrderDetails,
    }) => ({
      taskOrderDetails,
    })
  ),

  getTaskOrdersDetailsFail: createAction(
    billingReportsActionsTypes.GET_TASK_ORDER_DETAILS_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),

  setBillingProjectReportData: createAction(
    billingReportsActionsTypes.SET_BILLING_PROJECT_REPORT_DATA,
    (billingProjectReportData) => ({
      billingProjectReportData,
    }),
  ),

  setBillingSummaryReportData: createAction(
    billingReportsActionsTypes.SET_BILLING_SUMMARY_REPORT_DATA,
    (billingSummaryReportData) => ({ billingSummaryReportData }),
  ),

  setAnnualReportData: createAction(
    billingReportsActionsTypes.SET_ANNUAL_REPORT_DATA,
    (annualReportData) => (annualReportData),
  ),

  setCurrentPayrollReportData: createAction(
    billingReportsActionsTypes.SET_CURRENT_PAYROLL_REPORT_DATA,
    (currentPayrollReportData) => (currentPayrollReportData),
  ),

  getBillingSummaryReportSuccess: createAction(
    billingReportsActionsTypes.GET_BILLING_SUMMARY_REPORT_SUCCESS,
    ({ summaryReport, otherData }) => ({ summaryReport, otherData })
  ),
  getBillingSummaryReportFail: createAction(
    billingReportsActionsTypes.GET_BILLING_SUMMARY_REPORT_FAIL,
    ({ error = {} }) => ({ error })
  ),

  getBillingProjectReport: createAction(
    billingReportsActionsTypes.GET_BILLING_PROJECT_REPORT,
    (reportType) => ({
      reportType,
    })
  ),

  getBillingProjectReportSuccess: createAction(
    billingReportsActionsTypes.GET_BILLING_PROJECT_REPORT_SUCCESS,
    ({
      billingReport,
    }) => ({
      billingReport,
    })
  ),

  getBillingProjectReportFail: createAction(
    billingReportsActionsTypes.GET_BILLING_PROJECT_REPORT_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),

  getTaskOrdersList: createAction(billingReportsActionsTypes.GET_TASK_ORDERS_LIST),

  getTaskOrdersListSuccess: createAction(
    billingReportsActionsTypes.GET_TASK_ORDERS_LIST_SUCCESS,
    ({
      taskOrdersList,
    }) => ({
      taskOrdersList,
    })
  ),

  getTaskOrdersListFail: createAction(
    billingReportsActionsTypes.GET_TASK_ORDERS_LIST_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),

  getHolidaysList: createAction(billingReportsActionsTypes.GET_HOLIDAYS_LIST),

  getHolidaysListSuccess: createAction(
    billingReportsActionsTypes.GET_HOLIDAYS_LIST_SUCCESS,
    ({
      holidaysList,
    }) => ({
      holidaysList,
    })
  ),

  getHolidaysListFail: createAction(
    billingReportsActionsTypes.GET_HOLIDAYS_LIST_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),

  getInternalToReport: createAction(
    billingReportsActionsTypes.GET_INTERNAL_TO_REPORT,
    ({
      month,
      year,
    }) => ({
      month,
      year,
    })
  ),

  getInternalToReportSuccess: createAction(
    billingReportsActionsTypes.GET_INTERNAL_TO_REPORT_SUCCESS,
    ({
      internalToList,
      customerList,
      contractList,
    }) => ({
      internalToList,
      customerList,
      contractList,
    })
  ),

  getInternalToReportFail: createAction(
    billingReportsActionsTypes.GET_INTERNAL_TO_REPORT_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),

  changeFilter: createAction(
    billingReportsActionsTypes.CHANGE_FILTER,
    ({
      currentFilter,
      storeKey,
      selected = null,
      type,
    }) => ({
      currentFilter,
      storeKey,
      selected,
      type,
    }),
    () => ({
      entityName: ENTITY_NAME,
    })
  ),

  changeFilterSuccess: createAction(
    billingReportsActionsTypes.CHANGE_FILTER_SUCCESS,
    ({
      currentFilter,
      storeKey,
      config,
    }) => ({
      currentFilter,
      storeKey,
      config,
    })
  ),

  resetFilters: createAction(
    billingReportsActionsTypes.RESET_FILTERS,
    ({ currentFilter }) => ({ currentFilter })
  ),

  setOrderRules: createAction(
    billingReportsActionsTypes.SET_ORDER_RULES,
    ({
      tableName,
      orderRules,
    }) => ({
      tableName,
      orderRules,
    })
  ),

  setProjectOrderRules: createAction(
    billingReportsActionsTypes.SET_PROJECT_ORDER_RULES,
    ({
      tableName,
      orderRules,
    }) => ({
      tableName,
      orderRules,
    })
  ),
};
