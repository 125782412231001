import PdfIcon from 'assets/img/icon-pdf-info.svg';
import SpinnerIcon from 'assets/img/icon-spinner.svg';
import { colorPrimary, colorSecondaryGray } from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import { resourceBillingReportAccess, salaryReports } from 'core/auth/guaranteedAccessRoles';
import { COLUMN_TYPES, FILTERS_TYPES } from 'core/constants';
import { STORE_KEYS } from 'core/delivery/constants';
import { get } from 'lodash';
import moment from 'moment';

import { getHasPermissions } from 'utils/auth';
import { formatDateMMDDYYYY, getUnixTime } from 'utils/helpers/date';
import {
  checkIsInclude,
  checkIsSelected,
  checkRangeFilter,
} from 'utils/helpers/filters';
import {
  addThousandSeparator,
  getVacationDaysFormat,
} from 'utils/helpers/numbers';
import { stringCompareFunction } from 'utils/helpers/sorting';

import { employeeStatusGetter } from './utils';

const { CEO, CTO, RM, HRM, CPS } = USERS_GROUPS;

export const employeesListModelGetter = (userGroup, resourceBillingReport) => {
  const isChefs = getHasPermissions(userGroup, [CEO, CTO, CPS]);
  const isRms = getHasPermissions(userGroup, [RM, HRM]);
  const hasAccessToSalaryReports = getHasPermissions(userGroup, salaryReports);
  const isCEO = userGroup === CEO;
  const hasAccessToBillingInfo = getHasPermissions(userGroup, resourceBillingReportAccess);

  const getResourceLink = ({
    isNew,
    dateHired,
    devstaffId,
    isDismissed,
    internshipStartDate,
  }) => {
    if (
      isChefs &&
      !(isNew && !(dateHired || internshipStartDate)) &&
      !isDismissed
    ) {
      return `employee/${devstaffId}/work-book`;
    }
    if (isRms && !isNew && !isDismissed) {
      return `employee/${devstaffId}/pto-requests`;
    }

    return `employee/${devstaffId}/details`;
  };

  return {
    rules: {
      css: {
        gridTemplateConfig: `${
          hasAccessToSalaryReports ?
            'grid-template-columns: minmax(auto, 32fr) minmax(auto, 164fr) minmax(auto, 100fr) minmax(auto, 80fr) repeat(2, minmax(auto, 70fr)) repeat(2, minmax(auto, 91fr)) minmax(auto, 108fr) repeat(2, minmax(auto, 116fr)) minmax(auto, 150fr) minmax(auto, 80fr);' :
            `grid-template-columns: minmax(auto, 32fr) minmax(auto, 164fr) minmax(auto, 170fr) minmax(auto, 80fr) repeat(4, minmax(auto, 70fr)) minmax(auto, 85fr) repeat(2, minmax(auto, 116fr)) minmax(auto, 267fr) ${hasAccessToBillingInfo ? 'minmax(auto, 80fr)' : ''};`
        }
          top: 11.8rem;`, // fr units
      },
    },
    tableName: 'employeesList',
    currentFilter: 'employeeFilters',
    rowStatusGetter: ({
      devstaffId,
      isFavored,
      isDismissed,
      isOnLeave,
      isNew,
    }) => ({
      id: devstaffId,
      isFavored,
      isActive: !(isDismissed || isOnLeave),
      hasWarning: isNew && !isDismissed,
      isDefaultOnTop: isNew && !isDismissed,
    }),
    filtersTemplate: [
      {
        type: FILTERS_TYPES.TOGGLE,
        storeKey: STORE_KEYS.IS_FAVORED,
        checkFilter: ({ currentRow }) => !get(currentRow, 'isFavored', false),
      },
      {
        type: FILTERS_TYPES.SEARCH,
        storeKey: STORE_KEYS.FULLNAME,
        checkFilter: ({ currentRow, selected }) => {
          const fullname = get(currentRow, 'fullname', '');
          const fullnameCyrillic = get(currentRow, 'fullnameCyrillic', '');
          const includeCyrillic = checkIsInclude({
            selected,
            value: fullnameCyrillic,
          });
          const includeFullname = checkIsInclude({ selected, value: fullname });

          return includeFullname && includeCyrillic;
        },
      },
      {
        type: FILTERS_TYPES.SEARCH,
        storeKey: STORE_KEYS.TITLE,
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'title', '');
          return checkIsInclude({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.SENIORITY,
        valueGetter: (row) => get(row, 'seniority', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'seniority', '');
          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.DEVCENTER,
        valueGetter: (row) => get(row, 'devcenterShortname', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'devcenterShortname', '');
          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.TYPE,
        valueGetter: (row) => (get(row, 'isDelivery', '') ? 'Dev' : 'Adm'),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'isDelivery', '') ? 'Dev' : 'Adm';
          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.RANGE,
        storeKey: STORE_KEYS.USD,
        componentProps: {
          placeholder: '. . . . .',
          validationRules: {
            isInteger: true,
            maxValue: 99999,
          },
        },
        shouldntBeRendered: !hasAccessToSalaryReports,
        valueGetter: (row) => {
          const salUsd = get(row, 'salUsd', '');
          return addThousandSeparator(salUsd);
        },
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'salUsd', '');

          return !checkRangeFilter({ value, selected });
        },
      },
      {
        type: FILTERS_TYPES.RANGE,
        storeKey: STORE_KEYS.PLN,
        componentProps: {
          placeholder: '. . . . .',
          validationRules: {
            isInteger: true,
            maxValue: 99999,
          },
        },
        shouldntBeRendered: !hasAccessToSalaryReports,
        valueGetter: (row) => {
          const salPln = get(row, 'salPln', '');
          return addThousandSeparator(salPln);
        },
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'salPln', '');

          return !checkRangeFilter({ value, selected });
        },
      },
      {
        type: FILTERS_TYPES.RANGE,
        storeKey: STORE_KEYS.RUR,
        componentProps: {
          placeholder: '. . . . .',
          validationRules: {
            isInteger: true,
            maxValue: 999999,
          },
        },
        shouldntBeRendered: !hasAccessToSalaryReports,
        valueGetter: (row) => {
          const salRur = get(row, 'salRur', '');
          return addThousandSeparator(salRur);
        },
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'salRur', '');

          return !checkRangeFilter({ value, selected });
        },
      },
      {
        type: FILTERS_TYPES.RANGE,
        storeKey: STORE_KEYS.VACATION_DAYS_TOTAL,
        componentProps: {
          placeholder: '. .',
          validationRules: {
            isInteger: true,
            maxValue: 99,
          },
        },
        shouldntBeRendered: hasAccessToSalaryReports,
        valueGetter: (row) => get(row, 'vacationDaysTotal', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'vacationDaysTotal', '');

          return !checkRangeFilter({ value, selected });
        },
      },
      {
        type: FILTERS_TYPES.RANGE,
        storeKey: STORE_KEYS.VACATION_DAYS_SPENT,
        componentProps: {
          placeholder: '. .',
          validationRules: {
            isInteger: true,
            maxValue: 99,
          },
        },
        shouldntBeRendered: hasAccessToSalaryReports,
        valueGetter: (row) => get(row, 'vacationDaysSpent', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'vacationDaysSpent', '');

          return !checkRangeFilter({ value, selected });
        },
      },
      {
        type: FILTERS_TYPES.RANGE,
        storeKey: STORE_KEYS.VACATION_DAYS_LEFT,
        componentProps: {
          placeholder: '. .',
          validationRules: {
            isInteger: true,
            maxValue: 99,
          },
        },
        shouldntBeRendered: hasAccessToSalaryReports,
        valueGetter: (row) => get(row, 'vacationDaysLeft', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'vacationDaysLeft', '');

          return !checkRangeFilter({ value, selected });
        },
      },
      {
        type: FILTERS_TYPES.DATE,
        storeKey: STORE_KEYS.DATE_HIRED,
        componentProps: {
          popperProps: {
            placement: 'bottom-start',
            modifiers: [
              {
                name: 'offset',
                enabled: true,
                options: {
                  offset: [0, 4],
                },
              },
            ],
          },
          withMonthSelecting: true,
          withYearSelecting: true,
          outputFormatting: ({ date, useOnlyYear, useOnlyMonth }) => !!date &&
            {
              value: date,
              useOnlyYear,
              useOnlyMonth,
              formattedDate: moment.isMoment(date) ?
                date.format(`YYYY${useOnlyYear ? '' : '-MM'}${
                  useOnlyMonth || useOnlyYear ? '' : '-DD'
                }`) :
                date,
            },
        },
        checkFilter: ({ selected, currentRow }) => {
          const formattedDate = get(selected, 'formattedDate');
          const value = get(currentRow, STORE_KEYS.DATE_HIRED);
          if (value && formattedDate) {
            return !value.match(formattedDate);
          }

          return true;
        },
      },
      {
        type: FILTERS_TYPES.DATE,
        storeKey: STORE_KEYS.DATE_LAST,
        componentProps: {
          popperProps: {
            placement: 'bottom-start',
            modifiers: [
              {
                name: 'offset',
                enabled: true,
                options: {
                  offset: [0, 4],
                },
              },
            ],
          },
          withMonthSelecting: true,
          withYearSelecting: true,
          outputFormatting: ({ date, useOnlyYear, useOnlyMonth }) => !!date && {
            value: date,
            useOnlyYear,
            useOnlyMonth,
            formattedDate: moment.isMoment(date) ?
              date.format(`YYYY${useOnlyYear ? '' : '-MM'}${
                useOnlyMonth || useOnlyYear ? '' : '-DD'
              }`) :
              date,
          },
        },
        checkFilter: ({ selected, currentRow }) => {
          const formattedDate = get(selected, 'formattedDate');
          const value = get(currentRow, STORE_KEYS.DATE_LAST);
          if (value && formattedDate) {
            return !value.match(formattedDate);
          }

          return true;
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.STATUS,
        sortingComparator: (left, right) => {
          const statusesMap = {
            New: 0,
            Intern: 1,
            Employed: 2,
            'On Leave': 3,
            Dismissed: 4,
          };

          const leftRepresentation = get(statusesMap, left, 5);
          const rightRepresentation = get(statusesMap, right, 5);

          if (leftRepresentation === rightRepresentation) {
            return stringCompareFunction(left, right);
          }

          return leftRepresentation - rightRepresentation;
        },
        valueGetter: (row) => {
          const currentStatus = employeeStatusGetter(row);
          const { workBookLastAction, ptoRequestLastAction } = row;
          const workBookAction = get(workBookLastAction, 'actionType', '');
          const ptoAction = isCEO ?
            '' :
            get(ptoRequestLastAction, 'actionType', '');
          const additionalPtoStatus = ptoAction ? `PTO: ${ptoAction}` : '';
          const additionalWorkBookStatus = workBookAction ?
            `WB: ${workBookAction}` :
            '';

          return [currentStatus, additionalWorkBookStatus, additionalPtoStatus];
        },
        checkFilter: ({ selected, currentRow }) => {
          const employeeStatus = employeeStatusGetter(currentRow);
          const workBookAction = get(
            currentRow,
            'workBookLastAction.actionType',
            ''
          );
          const ptoAction = get(
            currentRow,
            'ptoRequestLastAction.actionType',
            ''
          );
          const additionalPtoStatus = ptoAction ? `PTO: ${ptoAction}` : '';
          const additionalWorkBookStatus = workBookAction ?
            `WB: ${workBookAction}` :
            '';
          const value = [
            employeeStatus,
            additionalPtoStatus,
            additionalWorkBookStatus,
          ];

          return checkIsSelected({ selected, value });
        },
      },
      {
        type: 'empty',
        shouldntBeRendered: !hasAccessToBillingInfo,
      },
    ],
    dataTemplate: [
      {
        withImage: true,
        valueGetter: ({ isFavored, devstaffId }) => ({
          type: COLUMN_TYPES.FAVORED,
          cssRules: 'padding: 0.7rem 0 0;',
          componentProps: {
            isFavored,
            actionName: 'toggleFavorite',
            actionArguments: {
              id: devstaffId,
            },
          },
        }),
      },
      {
        columnName: 'full name',
        valueGetter: ({
          isNew,
          fullname,
          dateHired,
          isOnLeave,
          devstaffId,
          isDismissed,
          fullnameCyrillic,
          internshipStartDate,
        }) => ({
          type: COLUMN_TYPES.LINK,
          componentProps: {
            data: fullname,
            pathname: getResourceLink({
              isNew,
              dateHired,
              devstaffId,
              isDismissed,
              internshipStartDate,
            }),
            withCompleteText: true,
            additionalData: [
              {
                content: fullnameCyrillic,
                cssRules: `
              font-size: 1.2rem;
              white-space: nowrap;
              ${
          !(isNew || isDismissed || isOnLeave) ?
            `color: ${colorSecondaryGray};` :
            ''
          }
            `,
              },
            ],
            cssRules: `
            font-size: 1.2rem;`,
          },
        }),
      },
      {
        columnName: 'title',
        valueGetter: ({ title }) => ({
          type: COLUMN_TYPES.TEXT,
          componentProps: {
            data: title,
            wrapperCssRules: 'padding-right: 1.5rem;',
            withCompleteText: true,
          },
        }),
      },
      {
        columnName: 'seniority',
        valueGetter: ({ seniority }) => ({
          type: COLUMN_TYPES.TEXT,
          componentProps: {
            data: seniority,
            cssRules: `
            font-size: 1.3rem;
          `,
          },
        }),
      },
      {
        columnName: 'dev center',
        headerProps: {
          cssRules: 'padding-right: 1.4rem;',
        },
        valueGetter: ({ devcenterShortname }) => ({
          type: COLUMN_TYPES.TEXT,
          value: devcenterShortname,
        }),
      },
      {
        columnName: 'type',
        valueGetter: ({ isDelivery }) => ({
          type: COLUMN_TYPES.TEXT,
          value: isDelivery ? 'Dev' : 'Adm', // TODO replace on current type
        }),
      },
      {
        columnName: 'USD',
        headerProps: {
          cssRules: `
            display: flex;
            justify-content: center;
            width: 100%;`,
        },
        shouldntBeRendered: !hasAccessToSalaryReports,
        valueGetter: ({ salUsd }) => ({
          type: COLUMN_TYPES.TEXT,
          shouldntBeRendered: !hasAccessToSalaryReports,
          componentProps: {
            data: addThousandSeparator(salUsd),
            cssRules: `
              display: inline-block;
              box-sizing: border-box;
              width: 100%;
              padding-right: 3rem;
              text-align: right;`,
          },
        }),
      },
      {
        columnName: 'PLN',
        headerProps: {
          cssRules: `
            display: flex;
            justify-content: center;
            width: 100%;`,
        },
        shouldntBeRendered: !hasAccessToSalaryReports,
        valueGetter: ({ salPln }) => ({
          type: COLUMN_TYPES.TEXT,
          shouldntBeRendered: !hasAccessToSalaryReports,
          componentProps: {
            data: addThousandSeparator(salPln),
            cssRules: `
              display: inline-block;
              box-sizing: border-box;
              width: 100%;
              padding-right: 3rem;
              text-align: right;`,
          },
        }),
      },
      {
        columnName: 'RUR',
        headerProps: {
          cssRules: `
            display: flex;
            justify-content: center;
            width: 100%;`,
        },
        shouldntBeRendered: !hasAccessToSalaryReports,
        valueGetter: ({ salRur }) => ({
          type: COLUMN_TYPES.TEXT,
          shouldntBeRendered: !hasAccessToSalaryReports,
          componentProps: {
            data: addThousandSeparator(salRur),
            cssRules: `
              display: inline-block;
              box-sizing: border-box;
              width: 100%;
              padding-right: 3rem;
              text-align: right;`,
          },
        }),
      },
      {
        columnName: 'Total',
        shouldntBeRendered: hasAccessToSalaryReports,
        valueGetter: ({ vacationDaysTotal }) => ({
          type: COLUMN_TYPES.TEXT,
          shouldntBeRendered: hasAccessToSalaryReports,
          value: getVacationDaysFormat(vacationDaysTotal),
        }),
      },
      {
        columnName: 'Spent',
        shouldntBeRendered: hasAccessToSalaryReports,
        valueGetter: ({ vacationDaysSpent }) => ({
          type: COLUMN_TYPES.TEXT,
          shouldntBeRendered: hasAccessToSalaryReports,
          value: getVacationDaysFormat(vacationDaysSpent),
        }),
      },
      {
        columnName: 'Remaining',
        shouldntBeRendered: hasAccessToSalaryReports,
        valueGetter: ({ vacationDaysLeft }) => ({
          type: COLUMN_TYPES.TEXT,
          shouldntBeRendered: hasAccessToSalaryReports,
          value: getVacationDaysFormat(vacationDaysLeft),
        }),
      },
      {
        columnName: 'hired',
        valueGetter: ({ dateHired }) => ({
          type: COLUMN_TYPES.TEXT,
          isDate: true,
          unixValue: getUnixTime(dateHired),
          value: dateHired ? formatDateMMDDYYYY(dateHired) : '',
        }),
      },
      {
        columnName: 'dismissed',
        valueGetter: ({ dateLast }) => ({
          type: COLUMN_TYPES.TEXT,
          isDate: true,
          unixValue: getUnixTime(dateLast),
          value: dateLast ? formatDateMMDDYYYY(dateLast) : '',
        }),
      },
      {
        columnName: 'status',
        valueGetter: (row) => {
          const { workBookLastAction, ptoRequestLastAction } = row;
          const value = employeeStatusGetter(row);
          const workBookAction = get(workBookLastAction, 'actionType');
          const ptoAction = get(ptoRequestLastAction, 'actionType');

          return {
            type: COLUMN_TYPES.TEXT,
            componentProps: {
              data: `${value}${
                workBookAction || (ptoAction && !isCEO) ? '\xa0/' : ''
              }`,
              additionalData: [
                {
                  cssRules: `
                    padding-right: 0;
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                    color: ${colorPrimary};
                    overflow-x: visible;`,
                  content: workBookAction ? `WB:\xa0${workBookAction}` : '',
                },
                !isCEO && {
                  cssRules: `
                    padding-right: 0;
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                    color: ${colorPrimary};
                    overflow-x: visible;`,
                  content: ptoAction ? `PTO:\xa0${ptoAction}` : '',
                },
              ],
              isMultiline: true,
            },
          };
        },
      },
      {
        columnName: 'billing info',
        withoutControls: true,
        shouldntBeRendered: !hasAccessToBillingInfo,
        valueGetter: (data) => ({
          type: COLUMN_TYPES.ACTION,
          data: 'Billing Info',
          shouldntBeRendered: !hasAccessToBillingInfo,
          cssRules: `
              display: flex;
              align-items: center;
             `,
          componentProps: {
            data: '',
            imgSrc: resourceBillingReport.isGenerating && resourceBillingReport.devstaffId === data.devstaffId ? SpinnerIcon : PdfIcon,
            actionName: 'additionalAction',
            actionArguments: { devstaffId: data.devstaffId },
            title: 'Generate Resource Billing Report',
            disabled: resourceBillingReport.devstaffId !== data.devstaffId && resourceBillingReport.isGenerating,
          },
        }),
      },
    ],
  };
};
