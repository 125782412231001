import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import get from 'lodash/get';


const Year = ({
  currentDate,
  selectedDate,
  displayedDate,
  onSelectMonth,
}) => {
  const monthsShort = get(displayedDate, '_locale._monthsShort');
  const isSameYearWithNow = displayedDate.isSame(currentDate, 'year');
  const isSameYearWithSelected = displayedDate.isSame(selectedDate, 'year');

  return (
    <div className="calendar-date-picker__year">
      {
        monthsShort.map((monthShort, index) => {
          const isNow = isSameYearWithNow && (currentDate.month() === index);
          const isSelected = isSameYearWithSelected && selectedDate && (selectedDate.month() === index);
          return (
            <button
              key={index} // eslint-disable-line react/no-array-index-key
              className={classNames(
                'calendar-date-picker__cell',
                'calendar-date-picker__cell--month',
                { 'calendar-date-picker__cell--now': isNow },
                { 'calendar-date-picker__cell--selected': isSelected },
              )}
              type="button"
              onClick={() => onSelectMonth(index)}
            >
              {monthShort}
            </button>
          );
        })
      }
    </div>
  );
};

Year.propTypes = {
  selectedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]),
  onSelectMonth: PropTypes.func.isRequired,
  currentDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]).isRequired,
  displayedDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]).isRequired,
};

Year.defaultProps = {
  selectedDate: '',
};

export default Year;
