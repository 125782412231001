import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import HiqoLogo from 'assets/img/logo-hiqo.svg';
import classnames from 'classnames';
import ExpandButton from 'components/expand-button';
import { config } from 'core/config';
import ActionButton from 'elements/action-button';
import Version from 'elements/version';
import { NavLink, Link } from 'react-router-dom';

import { getHasPermissions } from 'utils/auth';

import './styles.scss';

const { LINKS } = config;
const Header = ({ userGroup, userFullName, actions, currentEntityName, userLogout }) => {
  const handleLogout = useCallback(() => {
    userLogout();
  }, []);

  return (
    <header className="header">
      <div className="header__top-line">
        <Link className="header__home-link" to={config.redirectPaths[userGroup] || '/home'} title="HBS link">
          <img
            className="header__logo"
            src={HiqoLogo}
            alt="HiQo-solutions logo"
          />
        </Link>

        <span className="header__title">Billing System</span>

        <div className="header__side-controls">
          <div className="header__login-controls">
            <Link
              className="header__login-link header__login-link--role"
              to="/user-settings"
              title="HBS link"
            >
              {userFullName}
            </Link>
            <ActionButton className="header__login-link" onClick={handleLogout} data="Logout" />
          </div>
        </div>
      </div>

      <nav className="header__navigation">
        <ul className="header__navigation-list">
          {LINKS.map((page) => {
            // TODO: replace it just on NavLink, when dashboard will be updated
            const { guaranteedAccessRoles, getIsActive } = page;
            if (
              guaranteedAccessRoles &&
              !getHasPermissions(userGroup, guaranteedAccessRoles)
            ) {
              return null;
            }

            const CurrentLink = page.url ? (
              <a
                className="header__navigation-link"
                href={page.url ? page.url : `/${page.route}`}
              >
                {page.name}
              </a>
            ) : (
              <NavLink
                className="header__navigation-link"
                activeClassName="header__navigation-link--active"
                to={{
                  pathname: page.url ? page.url : `/${page.route}`,
                  state: {
                    prevPath: window.location.pathname,
                  },
                }}
              >
                {page.name}
              </NavLink>
            );

            return (
              <li
                className={classnames('header__navigation-item', {
                  'header__navigation-item--with-expand-section':
                  page.dropdownItems,
                })}
                key={page.name}
              >
                {page.dropdownItems ? (
                  <ExpandButton
                    actions={actions}
                    title={page.name}
                    userGroup={userGroup}
                    getIsActive={getIsActive}
                    items={page.dropdownItems}
                    currentEntityName={currentEntityName}
                  />
                ) : (
                  CurrentLink
                )}
              </li>
            );
          })}
        </ul>

        <Version />
      </nav>
    </header>
  );
};

Header.propTypes = {
  currentEntityName: PropTypes.string,
  userGroup: PropTypes.string.isRequired,
  userFullName: PropTypes.string.isRequired,
  actions: PropTypes.shape({}).isRequired,
  userLogout: PropTypes.func.isRequired,
};

Header.defaultProps = {
  currentEntityName: '',
};

export default Header;
