// import { addThousandSeparator } from 'utils/helpers/numbers';
import {
  colorSecondaryGrayLight015,
  colorSecondaryText,
  colorSecondaryBackground,
  colorBackgroundDark,
  colorSecondaryGrayLight,
  colorSecondaryGray,
} from 'assets/styles/variables';
import hash from 'hash-sum';
import { gridTemplateColumns } from 'layouts/summary-reports/model/utils';
import { get, toNumber } from 'lodash';
import moment from 'moment/moment';

import { addThousandSeparator } from 'utils/helpers/numbers';

import dtoSummaryNonBillableActivitiesTableConfig from './config/summary-non-billable-activities-tab-config';

export default ({
  isChefs,
  isCPS,
  isAudit,
  isDTOReport,
  selectedDate,
  reportCalculationDate,
  hasPermissionsTODeliveryModule,
  hasPermissionsTOTaskOrderModule,
}) => [
  {
    type: 'heading',
    data: 'Non-billable activities',
    cssRules: `
      display: inline-block;
      margin: 0 0 0.5rem 1.6rem;
      font-size: 2.2rem;
      line-height: 2.6rem;
      color: ${colorSecondaryText};
    `,
  },
  {
    type: 'filter',
    title: `Note: data current as of ${moment(
      reportCalculationDate,
      'YYYY-MM-DD LT'
    ).format('L LT')}`,
    currentFilter: 'dtoSummaryNonBillableActivities',
    cssRules: {
      titleCssRules: `
        display: inline-block;
        margin-left: 1.6rem;
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: ${colorSecondaryGray};
      `,
      wrapperCssRules: `
        padding-top: 1.5rem;
        background-color: transparent;
      `,
      gridTemplateConfig: `
        ${gridTemplateColumns()}
        && {
          .grid-unit--filter {
            background-color: ${colorSecondaryBackground};
          }
          .grid-unit--active {
            background-color: ${colorBackgroundDark};
          }
        }
      `,
    },
  },

  {
    type: 'table',
    currentFilter: 'dtoSummaryNonBillableActivities',
    stylesTemplate: {},
    tableCssRules: `
      && .table {
        padding-bottom: 3.2rem;
      }`,
    summaryTableDataGetter: ({
      tableSummaryData,
      setTableSummaryData,
      currentRow,
      ...rest
    }) => {
      const unBillableHours = get(tableSummaryData, 'unBillableHours', 0);
      const hours = get(currentRow, 'hours', 0);

      setTableSummaryData({
        ...tableSummaryData,
        ...rest,
        unBillableHours: unBillableHours + toNumber(hours),
      });
    },
    groupBy: ({ row, groups, rowData, setGroups }) => {
      const project = get(row, 'project');
      const devstaffId = get(row, 'devstaffId');
      const key = hash(devstaffId);
      const { rows, subtotalRow } = get(groups, key, {
        rows: [],
        subtotalRow: {},
      });
      const hours = get(subtotalRow, 'hours', 0);

      rows.push(rowData);
      return setGroups({
        ...groups,
        [key]: {
          rows,
          sortBy: project,
          subtotalRow: {
            ...row,
            isSubTotal: true,
            hours: hours + Number(row.hours),
          },
        },
      });
    },
    useAccumulator: ({
      parsedContent,
      setAccumulator,
      accumulator,
      ...rest
    }) => {
      const unBillableHours = get(
        parsedContent,
        '0.tableSummaryData.unBillableHours',
        0
      );

      setAccumulator({
        ...accumulator,
        ...rest,
        unBillableHours,
      });
      return parsedContent;
    },
    content: [
      {
        idKey: 'projectId',
        dataKey: 'nonBillableReportRows',
        rowStatusGetter: (row) => {
          const { isSubTotal } = row;
          const id = hash(row);

          return {
            id,
            stylesTemplate: {
              gridTemplateConfig: isSubTotal ?
                gridTemplateColumns() :
                undefined,
              rowStyles: isSubTotal ?
                `
              && {
                  border-top: 1px solid ${colorSecondaryGrayLight};
                  border-bottom: 1px solid ${colorSecondaryGrayLight};
                  background-color: transparent;

                &&:hover {
                  background-color: transparent;
                }
              }
            ` :
                '',
            },
          };
        },
        rules: {
          css: {
            gridTemplateConfig: `
            ${gridTemplateColumns()}
            min-height: 5.5rem;
            `,
          },
        },
        tableName: 'nonBillableActivities',
        dataTemplate: dtoSummaryNonBillableActivitiesTableConfig({
          isChefs,
          isCPS,
          isAudit,
          isDTOReport,
          selectedDate,
          hasPermissionsTODeliveryModule,
          hasPermissionsTOTaskOrderModule,
        }),
      },
    ],
  },
  {
    type: 'single-row',
    cssRules: `
      display: grid;
      grid-template-columns: 100fr 100fr 50fr;
      padding: 0 1.6rem;
      border-top: 1px solid ${colorSecondaryGrayLight};
      background-color: ${colorSecondaryGrayLight015};
    `,
    useAccumulator: ({
      accumulator: { hasActiveFilters, unBillableHours },
    }) => [
      {
        type: 'text',
        componentProps: {
          data: hasActiveFilters ?
            'Total Unbillable with filters' :
            'Total Unbillable',
          cssRules: `
          && {
            font-size: 1.8rem;
            line-height: 3.15rem;
            font-weight: 500;
            color: ${colorSecondaryText};
          }
        `,
        },
      },
      {
        type: 'text',
        componentProps: {
          data: unBillableHours ?
            `${addThousandSeparator(unBillableHours.toFixed(2))} h` :
            '0 h',
          cssRules: `
          && {
            padding-right: 0;
            font-size: 1.8rem;
            line-height: 2.6rem;
            color: ${colorSecondaryText};
          }
        `,
        },
        cssRules: `
        && {
          margin-left: auto;
        }
      `,
      },
    ],
  },
];
