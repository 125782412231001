import React from 'react';
import PropTypes from 'prop-types';

import InfoCard from 'components/info-card';
import DaysOffCalendar from 'components/days-off-calendar';
import ExpansionPanel from 'components/expansion-panel';
import ActionButton from 'elements/action-button';
import Text from 'elements/plain-text';
import Link from 'elements/link';


const units = {
  daysOffCalendar: DaysOffCalendar,
  actionButton: ActionButton,
  description: Text,
  link: Link,
  empty: () => <div />,
};
const FormUnit = ({ unit, actions }) => {
  const {
    type,
    isHidden,
    actionName,
    actionArguments = {},
    summaryConfig,
    ...rest
  } = unit;
  const onClick = () => {
    if (actionName) {
      return actions[actionName]({ ...actionArguments });
    }

    return null;
  };

  if (isHidden) {
    return null;
  }

  const createUnit = () => (
    React.createElement(
      type ? units[type] : InfoCard,
      {
        onClick,
        actions,
        ...rest,
      },
    )
  );

  return summaryConfig ?
    (
      <ExpansionPanel summaryConfig={summaryConfig}>
        {createUnit()}
      </ExpansionPanel>
    ) :
    createUnit();
};


FormUnit.propTypes = {
  unit: PropTypes.any.isRequired,
  actions: PropTypes.shape({}),
};

FormUnit.defaultProps = {
  actions: {},
};


export default FormUnit;
