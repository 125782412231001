import { get } from 'lodash';
import { localStorageAdapter } from 'utils/storage';

import { config } from './config';
import {
  TOKENS_COOKIE,
  FAVORITE_CLIENTS,
  FAVORITE_TASK_ORDERS,
  FAVORITE_EMPLOYEES,
  FAVORITE_PROJECTS,
} from './constants/web-storage';

export const appStorage = {
  getAccessToken: () => {
    const localToken = get(get(config, 'tokens', {}), 'idToken', null);
    return localToken || localStorageAdapter.getItem(TOKENS_COOKIE);
  },
  setAccessToken: (token) => localStorageAdapter.setItem(TOKENS_COOKIE, token),
  removeAccessToken: () => localStorageAdapter.removeItem(TOKENS_COOKIE),

  getFavoritesClients: () => localStorageAdapter.getItem(FAVORITE_CLIENTS),
  setFavoritesClients: (clients) => localStorageAdapter.setItem(FAVORITE_CLIENTS, clients),

  getFavoriteTaskOrders: () => localStorageAdapter.getItem(FAVORITE_TASK_ORDERS),
  setFavoriteTaskOrders: (taskOrders) => localStorageAdapter.setItem(FAVORITE_TASK_ORDERS, taskOrders),

  getFavoriteEmployees: () => localStorageAdapter.getItem(FAVORITE_EMPLOYEES),
  setFavoriteEmployees: (employees) => localStorageAdapter.setItem(FAVORITE_EMPLOYEES, employees),

  getFavoriteProjects: () => localStorageAdapter.getItem(FAVORITE_PROJECTS),
  setFavoriteProjects: (projects) => localStorageAdapter.setItem(FAVORITE_PROJECTS, projects),
};
