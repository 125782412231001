import React, { memo, useCallback } from 'react';

import { Controller } from 'react-hook-form';

import PropTypes from 'prop-types';

import { ReactComponent as LogHistoryIcon } from 'assets/img/icon-info.svg';
import { InputField } from 'forms/components';
import classes from 'forms/user-management-form/form.module.scss';
import { fieldHeadings } from 'layouts/user-management/constants';

export const InputController = memo(({
  dialogRef,
  value,
  control,
  showLoginHistory,
}) => {
  const getInputWithDialogProps = useCallback((field, error) => {
    if (field.name === 'fullname') {
      return {
        withDialog: Boolean(!error && field.value && showLoginHistory),
        triggerDialog: (
          <div title="Login Log History">
            <LogHistoryIcon
              onClick={() => dialogRef.current.open()}
              className={classes.logHistoryIcon}
            />
          </div>
        ),
      };
    }
    return {};
  }, [showLoginHistory]);

  return (
    <Controller
      name={value}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => (
        <InputField
          name={field.name}
          onChange={field.onChange}
          value={field.value}
          className={classes.inputField}
          type="text"
          title={field.value || fieldHeadings[field.name]}
          placeholder={fieldHeadings[field.name]}
          error={error}
          {...getInputWithDialogProps(field, error)}
        />
      )}
    />
  );
});

InputController.propTypes = {
  value: PropTypes.string.isRequired,
  dialogRef: PropTypes.object,
  control: PropTypes.object.isRequired,
  showLoginHistory: PropTypes.bool,
};

InputController.defaultProps = {
  showLoginHistory: false,
  dialogRef: null,
};
