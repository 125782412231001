import gql from 'graphql-tag';

export const queryConfig = {
  fetchUserRecords: gql`
    query {
      userManagementData {
        userData{
          userId
          fullname
          email
          role {
            value
            label
          }
          title
          billingAgent{
            value
            label
          }
          phone
          logsData{
            loginDate
            ip
            message
          }
          invitationalInProgress
          isActive
          isDeactivated
        }
      }
    }
  `,
  createUserRecord: gql`
    mutation createUserRecord($fields: UserRecordInput!) {
      createUserRecord(fields: $fields) {
        userRecords { userData{
          userId
          fullname
          email
          role {
            value
            label
          }
          title
          billingAgent{
            value
            label
          }
          phone
          logsData{
            loginDate
            ip
            message
          }
          invitationalInProgress
          isActive
          isDeactivated
        }}
        ok
      }
    }
  `,
  updateUserRecord: gql`
    mutation updateUserRecord($fields: UpdateUserInput!, $userId: Int!) {
      updateUserRecord(userId: $userId, fields: $fields) {
        userRecords {
          userData {
            userId
            fullname
            email
            role {
              value
              label
            }
            title
            billingAgent{
              value
              label
            }
            phone
            logsData{
              loginDate
              ip
              message
            }
            invitationalInProgress
            isActive
            isDeactivated
          }
        }
        ok
      }
    }
  `,
  deleteUserRecord: gql`
    mutation deleteUserRecord($userId: Int!) {
      deleteUserRecord(userId: $userId) {
        ok
      }
    }
  `,
};
