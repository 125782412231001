import {
  selectUserGroup,
  selectUserId,
} from 'core/auth/selectors';
import { channelPartnersModelGetter } from 'layouts/channel-partners-details/model';
import { channelPartnersListModelGetter } from 'layouts/channel-partners-list/model';
import { get } from 'lodash';
import { createSelector } from 'reselect';

import {
  modelParser,
  parseDetails,
  filtersConfigGetter,
  filtersItemsGetter,
} from 'utils/helpers/models';

export const selectChannelPartnersData = (state) => state.channelPartners.channelPartnersData;
export const selectErrors = (state) => state.channelPartners.errors;
export const selectEntityName = (state) => state.channelPartners.entityName;
export const selectCurrentChannelPartnerDetails = (state) => state.channelPartners.channelPartnerDetails;
export const selectNumberOfChannelPartnerFiles = (state) => get(state, 'channelPartners.channelPartnerDetails.files.length', 0);

export const selectRequestsCounter = (state) => state.channelPartners.requestsCounter;

export const selectChannelPartnersListModel = createSelector(selectUserGroup, (userGroup) => channelPartnersListModelGetter(userGroup));
export const selectChannelPartnerModel = createSelector(
  selectUserGroup,
  selectUserId,
  selectCurrentChannelPartnerDetails,
  channelPartnersModelGetter
);
export const selectOrderRules = (state) => state.channelPartners.orderRules;

export const selectChannelPartnerDetails = createSelector(
  selectCurrentChannelPartnerDetails,
  selectOrderRules,
  selectChannelPartnerModel,
  selectUserGroup,
  (channelPartnerDetails, orderRules, channelPartnerModel) => {
    const { dataTemplate } = channelPartnerModel;

    const updatedChannelPartnerDetailsModel = {
      ...channelPartnerModel,
      dataTemplate,
    };

    return parseDetails(channelPartnerDetails, updatedChannelPartnerDetailsModel, orderRules);
  }
);

export const selectFilters = (state) => state.channelPartners.filters;
const selectFilterItems = createSelector(
  selectChannelPartnersData,
  selectChannelPartnersListModel,
  selectFilters,
  filtersItemsGetter,
);

export const selectIsFetchingChannelPartnersList = (state) => state.channelPartners.isFetching;
export const selectIsFetchingDetails = (state) => state.channelPartners.isFetchingDetails;
export const selectIsFetchingFiles = (state) => state.channelPartners.isFetchingFiles;
export const selectIsFilteringChannelPartnersList = (state) => state.channelPartners.isFiltering;
export const selectIsChannelPartnerDetailsFormSubmitted = (state) => state.channelPartners.isFormSubmitted;

export const selectFilterConfig = createSelector(
  selectFilters,
  selectFilterItems,
  selectChannelPartnersListModel,
  filtersConfigGetter,
);

const selectActiveFilters = createSelector(
  selectFilters,
  (filters) => Object.entries(filters).filter(([_, value]) => value.isActive) // eslint-disable-line no-unused-vars
);

export const selectHasFilters = createSelector(
  selectActiveFilters,
  (filters) => !!filters.length
);

export const selectedChannelPartnersData = createSelector(
  selectChannelPartnersData,
  selectOrderRules,
  selectActiveFilters,
  selectChannelPartnersListModel,
  (channelPartnersData, orderRules, filters, {
    tableName,
    dataTemplate,
    filtersTemplate,
    rowStatusGetter,
  }) => {
    const options = {
      orderRules: orderRules[tableName],
      filters,
      idKey: 'channelPartnerId',
      tableName,
      rowStatusGetter,
      filtersTemplate,
    };
    const {
      data,
    } = modelParser(channelPartnersData, dataTemplate, options);

    return data;
  }
);
export const selectChannelPartnersCount = createSelector(
  selectChannelPartnersData,
  (channelPartners) => channelPartners.length,
);
