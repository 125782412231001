import React, { memo, useCallback } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as IconSort } from 'assets/icons/sort.svg';
import classNames from 'classnames';

import styles from './styles.module.scss';

const generateDirection = (isSortChanged, direction) => {
  if (isSortChanged) {
    return 'asc';
  }

  return direction === 'asc' ? 'desc' : 'asc';
};

const HeaderColumn = memo(({
  column,
  onSortHandler,
  sortBy,
  commonStyles,
}) => {
  const isActive = sortBy.accessor === column.accessor;
  const isDesc = isActive && sortBy.direction === 'desc';
  const title = column.accessor === 'processed' ? 'Processed' : column.Header;

  const handleClick = useCallback(() => {
    if (!column.isSortable) return;

    onSortHandler((prevState) => ({
      ...prevState,
      accessor: column.accessor,
      direction: generateDirection(prevState.accessor !== column.accessor, prevState.direction),
      type: column.sortType,
    }));
  }, [sortBy]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      title={title}
      data-id="Header Column Wrapper"
      data-sortable={column.isSortable}
      data-sort-active={isActive}
      className={
        classNames(styles.headerColumnWrapper, commonStyles[column.accessor])
      }
      key={column.accessor}
      onClick={handleClick}
      onKeyDown={handleClick}
    >
      <span>{column.Header}</span>
      {column.isSortable && (
        <IconSort
          className={classNames(styles.sort, {
            [styles.desc]: isDesc,
          })}
        />
      )}
    </div>
  );
});

export const Header = ({ columns, onSortHandler, sortBy, commonStyles }) => (
  <div data-id="header" className={styles.header}>
    {columns.map((column) => (
      <HeaderColumn
        column={column}
        key={column.accessor}
        onSortHandler={onSortHandler}
        sortBy={sortBy}
        commonStyles={commonStyles}
      />
    ))}
  </div>
);

Header.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSortHandler: PropTypes.func.isRequired,
  sortBy: PropTypes.shape({
    accessor: PropTypes.string,
    direction: PropTypes.string,
  }).isRequired,
  commonStyles: PropTypes.shape({}).isRequired,
};

HeaderColumn.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
    isSortable: PropTypes.bool,
    sortType: PropTypes.string,
  }).isRequired,
  onSortHandler: PropTypes.func.isRequired,
  sortBy: PropTypes.shape({
    accessor: PropTypes.string,
    direction: PropTypes.string,
  }).isRequired,
  commonStyles: PropTypes.shape({}).isRequired,
};
