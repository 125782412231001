export default [
  {
    name: 'login',
    route: '/',
  },
  {
    name: 'verification',
    route: 'verification',
  },
];
