import { checkIsSelected } from 'utils/helpers/filters';

export default [
  {
    type: 'select',
    storeKey: 'resource',
    componentProps: {
      placeholder: 'All Resources',
      getOptionLabel: ({ resource, fullname }) => `${fullname} (${resource})`,
    },
    valueGetter: (item) => item.resource,
    checkFilter: ({ currentRow, selected }) => {
      const { resource: value = '' } = currentRow;
      return checkIsSelected({ selected, value });
    },
  },
  {
    type: 'select',
    storeKey: 'taskOrder',
    valueGetter: (item) => item,
    componentProps: {
      placeholder: 'All TOs',
      getOptionLabel: (value) => `${value}`,
    },
  },
  {
    type: 'select',
    storeKey: 'projectType',
    valueGetter: (item) => item,
    componentProps: {
      placeholder: 'Contract Type',
      getOptionLabel: (value) => `${value}`,
    },
  },
  {
    type: 'select',
    storeKey: 'billType',
    valueGetter: (item) => item,
    componentProps: {
      placeholder: 'Worklog Type',
    },
  },
  {},
];
