import { FORM_STATE, USER_ID } from 'core/configurations/user-management/constants';
import { createEntityAdapter } from 'utils/createEntityAdapter';

export const userManagementEntityParser = (entity, formState) => {
  const { logsData, isActive, isDeactivated, invitationalInProgress, ...rest } = entity;

  return {
    ...rest,
    formState: {
      ...FORM_STATE,
      ...formState,
    },
    loginLogsHistory: logsData,
    recordState: {
      isActive: Boolean(isActive),
      isDeactivated: Boolean(isDeactivated),
      invitationalInProgress: Boolean(invitationalInProgress),
      isDisabled: false,
      isUser: false,
    },
  };
};

export const userManagementEntityAdapter = createEntityAdapter({
  key: USER_ID,
  entityParser: userManagementEntityParser,
});
