import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { PlusIcon } from 'assets/svgComponents';
import classNames from 'classnames';
import { Checkbox, ActionButton } from 'components/buttons';
import Container from 'components/container';
import Counter from 'components/counter';
import Controls from 'components/list-controls';

import { selectUserGroup } from 'core/auth/selectors';
import {
  userManagementActions,
  selectIsDisplayOnlyActiveUsers,
  selectUserManagementData,
  selectCounter,
  selectIsFilterByColumnsActive,
  selectIsFetchingUserRecords,
  selectIsCreateMode,
  selectSorting,
} from 'core/configurations/user-management';
import GoTop from 'elements/go-top-button';

import { useEffectOnlyOnUpdate } from 'hooks';
import { fieldHeadings, title } from 'layouts/user-management/constants';
import { UserManagementListFilter as Filter } from 'layouts/user-management/filter';

import classes from 'layouts/user-management/index.module.scss';
import { MultipleForms } from 'layouts/user-management/multipleForms';

import { toast } from 'react-toastify';

import { useUserManagement } from './hooks';

const indicatorStyles = `
          &::after {
            background: #f2f2f2;
          }
        `;

export const UserManagement = () => {
  const userManagementData = useSelector(selectUserManagementData);
  const isFetching = useSelector(selectIsFetchingUserRecords);
  const counter = useSelector(selectCounter);
  const isCreateMode = useSelector(selectIsCreateMode);
  const sortedColumns = useSelector(selectSorting);
  const userGroup = useSelector(selectUserGroup);

  const isDisplayOnlyActiveUsers = useSelector(selectIsDisplayOnlyActiveUsers);
  const isFilterByColumnsActive = useSelector(selectIsFilterByColumnsActive);

  const isFiltersActive = !isDisplayOnlyActiveUsers || isFilterByColumnsActive;

  const {
    data: userRecords,
    onAddNew,
    onRemoveNew,
  } = useUserManagement(userManagementData);
  const dispatch = useDispatch();

  const toggleDisplayActiveUsers = useCallback(() => {
    dispatch(userManagementActions.toggleDisplayActiveUsers({
      displayOnlyActiveUsers: !isDisplayOnlyActiveUsers,
    }));
  }, [isDisplayOnlyActiveUsers, onRemoveNew]);

  const resetFilters = useCallback(() => {
    dispatch(userManagementActions.clearControlsFilters());
  }, [isDisplayOnlyActiveUsers]);

  const handleSortChange = useCallback((column) => {
    dispatch(userManagementActions.changeSortingByColumns({
      [column]: sortedColumns[column] === 'asc' ? 'desc' : 'asc',
    }));
  }, [sortedColumns]);

  useEffect(() => {
    dispatch(userManagementActions.fetchUserRecords());

    return () => {
      resetFilters();
    };
  }, []);

  useEffectOnlyOnUpdate(() => {
    if (isFilterByColumnsActive && isCreateMode) {
      dispatch(userManagementActions.removeNewUserRecord());
    }
  }, [
    isCreateMode,
    isFiltersActive,
  ]);

  useEffect(() => () => {
    toast.dismiss();
    dispatch(userManagementActions.clearUserManagementState());
  }, []);

  return (
    <>
      <Controls
        data-id="user-management-controls"
        title={title}
        hasActiveFilters={!isDisplayOnlyActiveUsers}
        resetFilters={resetFilters}
      >
        <Checkbox
          title="Display only active users"
          onClick={toggleDisplayActiveUsers}
          isActive={isDisplayOnlyActiveUsers}
          type="checkbox"
          visibleIndication
          cssRules={indicatorStyles}
        />
      </Controls>
      <Container
        className={classes.wrapper}
        isFetching={isFetching}
      >
        <div
          data-id="sticky-header"
          className={classes.stickyHeader}
        >
          <ActionButton
            title="Add User"
            dataId="addButton"
            type="button"
            onClick={onAddNew}
            className={classes.addUserButton}
            disabled={isCreateMode || isFilterByColumnsActive}
          >
            <PlusIcon />
            Add User
          </ActionButton>
          <Filter hasActiveFilters={isFilterByColumnsActive} />
          <ul className={classes.headings}>
            {Object.keys(fieldHeadings).map((value) => {
              const isSortable = sortedColumns[value];

              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                <li
                  data-id={fieldHeadings[value]}
                  key={value}
                  className={classNames({
                    [classes.sortableHeading]: isSortable,
                  })}
                  {...isSortable && {
                    onClick: () => handleSortChange(value),
                  }}
                >
                  {fieldHeadings[value]}
                  {isSortable && (
                    <SortIcon
                      height={12}
                      className={classNames({
                        [classes.desc]: sortedColumns[value] === 'desc',
                      })}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <MultipleForms
          userRecords={userRecords}
          onRemoveNew={onRemoveNew}
          isCreateMode={isCreateMode}
          isFetching={isFetching}
          isFiltersActive={isFiltersActive}
          userGroup={userGroup}
        />
      </Container>
      <Counter
        data={counter}
        className={classes.counter}
        contentClassName={classes.counterContent}
      />
      <GoTop />
    </>
  );
};
