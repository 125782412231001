import { colorSecondaryGrayLight } from 'assets/styles/variables';
import moment from 'moment';

import { getUnixTime } from 'utils/helpers/date';
import { showResourceLinkByDevCenter } from 'utils/helpers/link';
import { addThousandSeparator, roundValue } from 'utils/helpers/numbers';

export default ({
  isCTO,
  isHRM,
  isChefs,
  isDTOReport,
  hasPermissionsTODeliveryModule,
  availableDevCentersForDd,
}) => {
  const isTO = !isDTOReport;
  const isNotRendered = isTO || isHRM;

  return [
    {
      columnName: 'Full name',
      headerProps: {
        cssRules: 'min-height: 2.8rem;',
      },
      valueGetter: ({
        fullname,
        devstaffId,
        resource,
        isGrandTotal,
        project,
        devcenterId,
      }) => {
        if (isGrandTotal) {
          return {
            type: 'text',
            componentProps: {
              data: `Total ${project}`,
              cssRules: `
              font-size: 1.6rem;
              font-weight: 500;`,
            },
          };
        }
        return {
          type:
            devstaffId && showResourceLinkByDevCenter(isCTO, hasPermissionsTODeliveryModule, availableDevCentersForDd, devcenterId) ?
              'link' :
              'text',
          componentProps: {
            data: fullname || resource || 'n/a',
            pathname: `/delivery/employee/${devstaffId}/${
              isChefs ? 'work-book' : 'pto-requests'
            }`,
            cssRules: 'font-size: 1.2rem;',
            isMultiline: true,
          },
        };
      },
    },
    {
      columnName: 'Devcenter',
      shouldntBeRendered: isTO,
      valueGetter: ({ isGrandTotal, devcenterShortname }) => isGrandTotal ? {
        shouldntBeRendered: isTO,
      } : {
        value: devcenterShortname || 'n/a',
        type: 'text',
        shouldntBeRendered: isTO,
      },
    },
    {
      columnName: 'Seniority',
      valueGetter: ({ seniority, isGrandTotal }) => {
        if (isGrandTotal) {
          return {};
        }
        return {
          value: seniority,
          type: 'text',
        };
      },
    },
    {
      columnName: 'Booking Period',
      valueGetter: ({ dateFrom, dateThrough, isGrandTotal }) => {
        if (isGrandTotal) {
          return {};
        }
        return {
          isDate: true,
          unixValue: getUnixTime(dateFrom),
          value: `${moment(dateFrom).format('YYYY-MM-DD')}${
            dateThrough ?
              ` \u2014 ${moment(dateThrough).format('YYYY-MM-DD')}` :
              ''
          }`,
          type: 'text',
          cssRules: `&& {
        font-size: 1.2rem;
        line-height: 1.7rem;
        text-align: left;
      }`,
        };
      },
    },
    {
      columnName: 'Discount/\nSurcharge',
      shouldntBeRendered: isNotRendered,
      valueGetter: ({ surchargePercentage, isGrandTotal }) => {
        if (isGrandTotal) {
          return {
            shouldntBeRendered: isNotRendered,
          };
        }
        const data = addThousandSeparator(roundValue(surchargePercentage, 2));
        return {
          value: `${data}%`,
          shouldntBeRendered: isNotRendered,
          type: 'text',
        };
      },
    },
    {
      columnName: 'Monthly Rate',
      shouldntBeRendered: isHRM,
      headerProps: {
        cssRules: `
        margin-left: auto;
        padding-right: 1rem;`,
      },
      valueGetter: ({
        rateMoWithSurcharge,
        rateMo,
        isGrandTotal,
        isRatePlatinum,
        total,
        totalWithSurcharge,
      }) => {
        const data = addThousandSeparator(roundValue(isDTOReport ? totalWithSurcharge : total, 2));
        const hasValue = rateMoWithSurcharge || rateMo || total || totalWithSurcharge;
        const isPlatinum = isRatePlatinum && !isGrandTotal;

        return {
          type: 'text',
          shouldntBeRendered: isHRM,
          componentProps: {
            data: `${isPlatinum ? '*' : ''}${data}`,
            cssRules: `&& {
              padding-right: 0;
              overflow: visible;
              text-align: right;
              ${!hasValue ? `color: ${colorSecondaryGrayLight}` : ''}
              ${isGrandTotal && 'font-weight: 500'}
              ${isGrandTotal && 'font-size: 1.3rem'}
            }`,
          },
          cssRules: `&& {
            padding-right: 1rem;
          }`,
        };
      },
    },
  ];
};
