import React, { useCallback, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as VisibilityOffIcon } from 'assets/icons/visibility-off.svg';
import { ReactComponent as VisibilityIcon } from 'assets/icons/visibility.svg';
import SpinnerIcon from 'assets/img/icon-spinner.svg';
import classNames from 'classnames';
import { ActionButton } from 'components/buttons';
import TextInput from 'components/inputs/text';
import { get } from 'lodash';
import { toBase64 } from 'utils/helpers/toBase64';
import * as yup from 'yup';

import styles from './style.module.scss';

export const validationSchema = yup.object({
  email: yup.string().email('Invalid email').required('Required field'),
  password: yup.string().trim().required('Required field'),
});

const LoginForm = ({ onSubmit, isLoading, removeLoginError }) => {
  const [visiblePassword, setVisiblePassword] = useState(false);

  const togglePassword = useCallback(() => {
    setVisiblePassword((prev) => !prev);
  }, []);

  const {
    handleSubmit,
    clearErrors,
    control,
    formState: {
      errors,
    },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmitHandler = useCallback((formValue) => {
    removeLoginError();
    const { email, password } = formValue;
    onSubmit({
      email,
      password: toBase64(password),
    });
  }, []);

  return (
    <form
      className={styles.form}
      onChange={(evt) => {
        const fieldKey = get(evt, 'target.name', null);
        if (fieldKey && errors[fieldKey]) {
          clearErrors(fieldKey);
        }
      }}
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <div className={styles.item}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              htmlFor={field.name}
              className={classNames({
                [styles.error]: Boolean(errors[field.name]),
              })}
            >
              Email
            </label>
            <TextInput
              name={field.name}
              onChange={field.onChange}
              onBlur={field.onBlur}
              inputValue={field.value}
              placeholder="Email"
              withLabel={false}
              withError={Boolean(errors[field.name])}
              error={get(errors, `[${field.name}].message`, null)}
            >
              <EmailIcon className={styles.left} />
            </TextInput>
          </div>
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <div className={styles.item}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              htmlFor={field.name}
              className={classNames({
                [styles.error]: Boolean(errors[field.name]),
              })}
            >
              Password
            </label>
            <TextInput
              name={field.name}
              onChange={field.onChange}
              onBlur={field.onBlur}
              inputValue={field.value}
              placeholder="Password"
              withLabel={false}
              isPassword={!visiblePassword}
              withError={Boolean(errors[field.name])}
              error={get(errors, `[${field.name}].message`, null)}
            >
              <LockIcon className={styles.left} />
              <ActionButton onClick={togglePassword} className={styles.right} dataId="toggle-password">
                {visiblePassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </ActionButton>
            </TextInput>
          </div>
        )}
      />
      <ActionButton className={styles.button} type="submit" dataId="login-submit" disabled={isLoading}>
        {isLoading && <img src={SpinnerIcon} alt="login-spinner" /> }
        <span>Login</span>
      </ActionButton>
    </form>
  );
};

LoginForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  removeLoginError: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  isLoading: false,
};

export default LoginForm;
