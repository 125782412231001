export const DETAILS_TABS = {
  PROFILE: {
    id: 0,
    title: 'Profile',
    accessor: 'profile',
  },
  CONTRACTS: {
    id: 1,
    title: 'Contracts',
    accessor: 'contracts',
  },
  ATTACHMENTS: {
    id: 2,
    title: 'Attachments',
    accessor: 'attachments',
  },
  COMMENTS: {
    id: 4,
    title: 'Comments',
    accessor: 'comments',
  },
  CONTACTS: {
    id: 5,
    title: 'Contacts',
    accessor: 'contacts',
  },
  TECHNICAL_MENTOR: {
    id: 6,
    title: 'Technical Mentor',
    accessor: 'technicalMentor',
  },
};
