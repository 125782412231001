import { USERS_GROUPS } from 'core/auth/constants';
import { channelPartnerDetailAccess } from 'core/auth/guaranteedAccessRoles';
import {
  STORE_KEYS,
} from 'core/channel-partners/constants';
import { COLUMN_TYPES, FILTERS_TYPES } from 'core/constants';
import { get } from 'lodash';
import { getHasPermissions } from 'utils/auth';

import {
  checkIfSubstringExists,
  checkIsInclude,
  checkIsSelected,
} from 'utils/helpers/filters';
import { getAccessTabLink } from 'utils/helpers/getAccessTabLink';

const { CTO, HRM, DELIVERY_OFFICE_ADMIN, DELIVERY_OFFICE_ADMIN_BY } =
  USERS_GROUPS;

export const channelPartnersListModelGetter = (userGroup) => {
  const isCTO = getHasPermissions(userGroup, [CTO]);
  const isHRM = getHasPermissions(userGroup, [HRM]);
  const isDOA =
    getHasPermissions(userGroup, [DELIVERY_OFFICE_ADMIN]) ||
    getHasPermissions(userGroup, [DELIVERY_OFFICE_ADMIN_BY]);
  const allowToDetailPage = getHasPermissions(userGroup, channelPartnerDetailAccess);
  return {
    rules: {
      css: {
        gridTemplateConfig: `
          top: 11.8rem;
          grid-template-columns: minmax(auto, 56fr)  minmax(auto, 18fr) minmax(auto, 35fr) minmax(auto, 32fr) minmax(auto, 32fr)};
          & .grid-unit--with-multi-items {
            flex-wrap: wrap;
            padding: 1.2rem 0;
            & .grid-unit {
              min-width: max-content;
              min-height: 0;
              padding: 0;

              & a {
                padding-right: 0;
                font-size: 1.2rem;
                padding: 0 0.4rem 0.025rem 0;
              }
            }
            & .grid-unit:only-child {
              justify-content: flex-start;
            }
          }
          `,
      },
    },
    tableName: 'channelPartnersList',
    noDataMessage: 'This section currently contains no Channel Partners records.',
    rowStatusGetter: ({
      name,
      channelPartnerId,
      isActive,
      isSignedMsaUploaded,
    }) => ({
      name,
      isActive,
      id: channelPartnerId,
      isSignedMsaUploaded,
      hasWarning: !isSignedMsaUploaded && isActive,
    }),
    filtersTemplate: [
      {
        type: FILTERS_TYPES.SEARCH,
        storeKey: STORE_KEYS.NAME,
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'name', '');
          return checkIsInclude({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.COUNTRY,
        valueGetter: (row) => get(row, 'country', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'country', '');
          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.BILLING_AGENT_NAME,
        valueGetter: (row) => get(row, 'billingAgent.name', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'billingAgent.name', '');
          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.USER_FULL_NAME,
        valueGetter: (row) => get(row, 'accountManagerFullname', ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'accountManagerFullname', '');
          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SEARCH,
        storeKey: STORE_KEYS.ASSOCIATED_CLIENT_NAME,
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, 'associatedClients', []);
          return checkIfSubstringExists({ selected, value });
        },
      },
    ],
    dataTemplate: [
      {
        columnName: 'name',
        valueGetter: ({ name, channelPartnerId }) => ({
          type: allowToDetailPage ? COLUMN_TYPES.LINK : COLUMN_TYPES.TEXT,
          componentProps: {
            title: name,
            data: name,
            pathname: `channel-partners/${channelPartnerId}/${getAccessTabLink(
              isCTO,
              isHRM,
              null
            )}`,
          },
        }),
      },
      {
        columnName: 'country',
        valueGetter: ({ country }) => ({
          type: COLUMN_TYPES.TEXT,
          value: country,
        }),
      },
      {
        columnName: 'billing agent',
        valueGetter: (row) => ({
          type: COLUMN_TYPES.TEXT,
          value: get(row, 'billingAgent.name', ''),
        }),
      },
      {
        columnName: 'account manager',
        valueGetter: (row) => ({
          type: COLUMN_TYPES.TEXT,
          value: get(row, 'accountManagerFullname', ''),
        }),
      },
      {
        columnName: 'associated clients',
        valueGetter: ({ associatedClients }) => associatedClients.map((associatedClient, index, row) => ({
          type: COLUMN_TYPES.LINK,
          componentProps: {
            title: associatedClient.name,
            data: index + 1 === row.length ? `${associatedClient.name}` : `${associatedClient.name},`,
            pathname: `clients/${associatedClient.clientId}/${getAccessTabLink(
              isCTO,
              isHRM,
              isDOA
            )}`,
          },
        })),
      },
    ],
  };
};
