/* eslint-disable no-useless-escape */
import {
  changeBillingAgentLang,
  changeAccountManager,
  updateChannelPartnersDetails,
  changeBillingAgent, changeEmailReports,
} from 'core/auth/guaranteedAccessRoles';
import {
  get,
} from 'lodash';

import {
  getHasPermissions,
} from 'utils/auth';
import { emailRegex } from 'utils/email';

import {
  number,
  string,
} from 'yup';

export default ({
  isHRM,
  isDOA,
  userGroup,
  accountId,
  isCreatingForm,
}) => isHRM || isDOA ? [] : [{
  type: 'form',
  stylesTemplate: {
    cssRules: `
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "billing management";
      `,
    formStyles: `
        .Toastify { margin: 2rem 1.6rem 0; }
      `,
    formControlsCss: `
        grid-template-columns: repeat(4, calc((136rem - 4.8rem * 3 - 3.2rem) / 4));
        .button { margin-top: 0; }
      `,
  },
  withoutFormControls: !getHasPermissions(userGroup, updateChannelPartnersDetails),
  content: [{
    title: 'Billing Info',
    stylesTemplate: {
      gridArea: 'billing',
      marginConfig: 'n',
      cssRules: `
          grid-template-columns: minmax(0, 1fr);
       `,
    },
    formData: [
      {
        type: 'select',
        name: 'billingAgentId',
        placeholder: 'Billing Agent',
        pathInAPIResponse: ['billingAgent', 'billingAgentId'],
        selectItems: 'billingAgents',
        getIsUnitLocked: () => !isCreatingForm && !getHasPermissions(userGroup, changeBillingAgent),
        valueGetter: ({
          billingAgentId: currentBillingAgentId,
          billingAgents = [],
        }) => {
          const currentItem = billingAgents.find((item) => item.billingAgentId === currentBillingAgentId);

          return {
            selected: {
              value: currentItem ? currentItem.billingAgentId : '',
              label: currentItem ? currentItem.name : '',
            },
            items: billingAgents.map(({
              name,
              billingAgentId,
            }) => ({
              value: billingAgentId,
              label: name,
            })),
          };
        },
        validationRules: {
          isRequired: true,
          schemaGetter: () => number().required('Required field'),
        },
      },
      {
        type: 'select',
        name: 'billingLang',
        placeholder: 'Reporting Language',
        pathInAPIResponse: ['billingLang'],
        selectItems: 'languages',
        getIsUnitLocked: () => !isCreatingForm && !getHasPermissions(userGroup, changeBillingAgentLang),
        valueGetter: ({
          billingLang,
          languages = [],
        }) => {
          const currentItem = languages.find((item) => item.code === billingLang);

          return {
            selected: {
              value: currentItem ? currentItem.code : '',
              label: currentItem ? currentItem.name : '',
            },
            items: languages.map(({
              name,
              code,
            }) => ({
              value: code,
              label: name,
            })),
          };
        },
        validationRules: {
          isRequired: true,
          schemaGetter: () => string().required('Required field'),
        },
      },
    ],
  },
  {
    title: 'Account Management',
    stylesTemplate: {
      gridArea: 'management',
      cssRules: `
          grid-template-columns: minmax(0, 1fr);
        `,
    },
    formData: [
      {
        type: 'select',
        name: 'accountManagerId',
        placeholder: 'HiQo AM',
        pathInAPIResponse: ['accountManagerId'],
        selectItems: 'users',
        getIsUnitLocked: () => !getHasPermissions(userGroup, changeAccountManager),
        valueGetter: ({
          accountManagerId,
          users = [],
        }) => {
          const filteredItems = users.filter(({
            isSales,
          }) => isSales);
          const currentItem = users.find((item) => accountManagerId ?
            item.userId === accountManagerId :
            item.userId === accountId);
          const isCurrentUserSales = get(currentItem, 'isSales', true);
          if (!isCurrentUserSales && !isCreatingForm) {
            filteredItems.push(currentItem);
          }
          const value = isCurrentUserSales ? get(currentItem, 'userId', 2) : 2;

          return {
            selected: {
              value,
            },
            items: filteredItems.map(({
              userId,
              fullName,
            }) => ({
              value: userId,
              label: fullName,
            })),
          };
        },
      },
      {
        type: 'text',
        withLabel: true,
        name: 'emailReports',
        placeholder: 'Send reports to (emails separated by ,)',
        pathInAPIResponse: ['emailReports'],
        getIsUnitLocked: () => !getHasPermissions(userGroup, changeEmailReports),
        valueGetter: ({ emailReports }) => ({
          value: emailReports || '',
        }),
        validationRules: {
          maxLength: 200,
          schemaGetter: () => string()
            .max(200, 'Emails max length is 200')
            .transform((value) => value.trim())
            .test('isAllEmails', 'Wrong Emails format', (value) => {
              if (!value) {
                return true;
              }
              const emailsList = value.split(',');
              return !emailsList.some((email) => !email.trim().match(emailRegex));
            }),
        },
      },
    ],
  },
  ],
}];
