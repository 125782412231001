import { attachmentsReducerCases } from 'core/delivery/attachmentManagement/reducerCases';
import { commentManagementReducerCases } from 'core/delivery/commentManagement/reducerCases';
import { contactDataManagementReducerCases } from 'core/delivery/contactManagement/reducerCases';
import { contractManagementReducerCases } from 'core/delivery/contractManagement/reducerCases';
import { technicalMentorsReducerCases } from 'core/delivery/technicalMentorManagement/reducerCases';
import { appStorage } from 'core/storage';
import update from 'immutability-helper';
import { get } from 'lodash';
import { createReducer } from 'redux-create-reducer';

import { deliveryActionsTypes } from './actions';
import {
  ENTITY_NAME,
  STORE_KEYS,
} from './constants';

const initialState = {
  errors: {},
  plList: [],
  projectsData: [],
  employeesData: [],
  isFetching: false,
  projectDetails: {},
  isFiltering: false,
  selectedProject: '',
  isEmployeeEmailAvailable: false,
  resourceBillingReport: {
    isGenerating: false,
    devstaffId: null,
  },
  employeeDetailsTabs: {
    contractManagement: {
      length: 0,
      contractManagementRecords: [],
      isFetchingContractManagementRecords: true,
      memoDevstaffId: null,
    },
    commentManagement: {
      length: 0,
      commentManagementRecords: [],
      isFetchingCommentManagementRecords: true,
      memoDevstaffId: null,
    },
    attachments: {
      length: 0,
      attachmentManagementRecords: [],
      memoDevstaffId: null,
      isFetching: false,
    },
    contacts: {
      length: 0,
      contactDataManagementRecords: [],
      memoDevstaffId: null,
      isFetchingContactDataManagementRecords: true,
    },
    technicalMentorManagement: {
      length: 0,
      technicalMentorManagementRecords: [],
      memoDevstaffId: null,
      isFetchingTechnicalMentorRecords: false,
    },
  },
  employeeDetails: {},
  isFormSubmitted: false,
  entityName: ENTITY_NAME,
  isFetchingDetails: true,
  favoritesEmployees: appStorage.getFavoriteEmployees() || {},
  favoritesProjects: appStorage.getFavoriteProjects() || {},
  orderRules: {
    projectsList: {
      orderBy: 'Category Key',
      defaultOrderBy: 'Category Key',
      isReversed: false,
      isSeparatedByFavorites: true,
    },
    employeesList: {
      orderBy: 'full name',
      defaultOrderBy: 'full name',
      isReversed: false,
      isSeparatedByFavorites: true,
      isDefault: true,
    },
    projectLeadAssignments: {
      orderBy: 'Category Key',
      defaultOrderBy: 'Category Key',
      isReversed: false,
      isSeparatedByFavorites: true,
    },
  },
  filters: {
    projectsFilters: {
      [STORE_KEYS.IS_FAVORED]: {
        isActive: false,
        type: 'toggle',
      },
      [STORE_KEYS.INTERNAL_CATEGORY_KEY]: {
        isActive: false,
        type: 'select',
        selected: [],
      },
      [STORE_KEYS.INTERNAL_CATEGORY_NAME]: {
        isActive: false,
        type: 'search',
        selected: '',
      },

      [STORE_KEYS.IS_NO_VALID_PL]: {
        isActive: false,
        type: 'toggle',
      },
      [STORE_KEYS.IS_NO_PL]: {
        isActive: false,
        type: 'toggle',
      },
    },
    employeeFilters: {
      [STORE_KEYS.IS_FAVORED]: {
        isActive: false,
        type: 'toggle',
      },
      [STORE_KEYS.FULLNAME]: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      [STORE_KEYS.NAME]: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      [STORE_KEYS.TITLE]: {
        isActive: false,
        type: 'search',
        selected: '',
      },
      [STORE_KEYS.SENIORITY]: {
        isActive: false,
        type: 'select',
        selected: [],
      },
      [STORE_KEYS.DEVCENTER]: {
        isActive: false,
        type: 'select',
        selected: [],
      },
      [STORE_KEYS.TYPE]: {
        isActive: false,
        type: 'select',
        selected: [],
      },
      [STORE_KEYS.USD]: {
        isActive: false,
        type: 'range',
        selected: {
          min: null,
          max: null,
        },
      },
      [STORE_KEYS.PLN]: {
        isActive: false,
        type: 'range',
        selected: {
          min: null,
          max: null,
        },
      },
      [STORE_KEYS.RUR]: {
        isActive: false,
        type: 'range',
        selected: {
          min: null,
          max: null,
        },
      },
      [STORE_KEYS.VACATION_DAYS_TOTAL]: {
        isActive: false,
        type: 'range',
        selected: {
          min: null,
          max: null,
        },
      },
      [STORE_KEYS.VACATION_DAYS_SPENT]: {
        isActive: false,
        type: 'range',
        selected: {
          min: null,
          max: null,
        },
      },
      [STORE_KEYS.VACATION_DAYS_LEFT]: {
        isActive: false,
        type: 'range',
        selected: {
          min: null,
          max: null,
        },
      },
      [STORE_KEYS.DATE_HIRED]: {
        isActive: false,
        type: 'date',
        selected: '',
      },
      [STORE_KEYS.DATE_LAST]: {
        isActive: false,
        type: 'date',
        selected: '',
      },
      [STORE_KEYS.STATUS]: {
        isActive: false,
        type: 'select',
        selected: [],
      },
    },
  },
};

export default createReducer(initialState, {
  ...contractManagementReducerCases,
  ...commentManagementReducerCases,
  ...attachmentsReducerCases,
  ...contactDataManagementReducerCases,
  ...technicalMentorsReducerCases,
  [deliveryActionsTypes.DELETE_EMPLOYEES_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.DELETE_EMPLOYEES_RECORD_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['deleteEmployeesRecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },

  [deliveryActionsTypes.DELETE_EMPLOYEES_RECORD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },
  /* ********************************************** */
  [deliveryActionsTypes.DELETE_PLA_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.DELETE_PLA_RECORD_SUCCESS](state, { payload: {
    updatedProject,
    projectKey,
  } }) {
    const projectsData = get(state, 'projectsData', {});
    const recordIndex = projectsData.findIndex((record) => record && record.internalCategoryKey === projectKey);
    if (recordIndex !== -1) {
      return update(state, {
        errors: {
          $unset: ['updatePLAssignmentsError'],
        },
        isFormSubmitted: {
          $set: false,
        },
        projectsData: {
          [recordIndex]: {
            $merge: updatedProject,
          },
        },
      });
    }

    return update(state, {
      errors: {
        $unset: ['deletePLARecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },

  [deliveryActionsTypes.DELETE_PLA_RECORD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },
  /* ********************************************** */
  [deliveryActionsTypes.UPDATE_PL_ASSIGNMENTS](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.UPDATE_PL_ASSIGNMENTS_SUCCESS](state, { payload: {
    updatedProject,
    projectKey,
  } }) {
    const projectsData = get(state, 'projectsData', {});
    const recordIndex = projectsData.findIndex((record) => record && record.internalCategoryKey === projectKey);
    if (recordIndex !== -1) {
      return update(state, {
        errors: {
          $unset: ['updatePLAssignmentsError'],
        },
        isFormSubmitted: {
          $set: false,
        },

        projectsData: {
          [recordIndex]: {
            $merge: updatedProject,
          },
        },
      });
    }

    return update(state, {
      errors: {
        $unset: ['updatePLAssignmentsError'],
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },

  [deliveryActionsTypes.UPDATE_PL_ASSIGNMENTS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },
  /* ********************************************** */
  [deliveryActionsTypes.GET_ASSIGNED_PLS](state, {
    payload: {
      projectKey,
    },
  }) {
    return update(state, {
      selectedProject: {
        $set: projectKey,
      },
      isFetchingDetails: {
        $set: true,
      },
      projectDetails: {
        $set: {},
      },
    });
  },

  [deliveryActionsTypes.GET_ASSIGNED_PLS_SUCCESS](state, {
    payload,
  }) {
    return update(state, {
      isFetchingDetails: {
        $set: false,
      },
      isFormSubmitted: {
        $set: false,
      },
      projectDetails: {
        $set: payload,
      },
      errors: {
        $unset: ['getAssignedPLsError'],
      },
    });
  },

  [deliveryActionsTypes.GET_ASSIGNED_PLS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFetchingDetails: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [deliveryActionsTypes.DELETE_VACATION_MANAGEMENT_RECORD](state) {
    return update(state, {
      errors: {
        $unset: ['deleteVacationManagementRecordError'],
      },
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [deliveryActionsTypes.DELETE_VACATION_MANAGEMENT_RECORD_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['deleteVacationManagementRecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },
  [deliveryActionsTypes.DELETE_VACATION_MANAGEMENT_RECORD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },

  [deliveryActionsTypes.CREATE_VACATION_MANAGEMENT_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.CREATE_VACATION_MANAGEMENT_RECORD_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['createVacationManagementRecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },

  [deliveryActionsTypes.CREATE_VACATION_MANAGEMENT_RECORD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [deliveryActionsTypes.CHECK_UPDATES_EMPLOYEES_DETAILS_SUCCESS](state, {
    payload: {
      employeeDetails,
    },
  }) {
    return update(state, {
      employeeDetails: {
        $merge: employeeDetails,
      },
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $unset: ['checkUpdatesEmployeesDetailsError'],
      },
    });
  },

  [deliveryActionsTypes.CHECK_UPDATES_EMPLOYEES_DETAILS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [deliveryActionsTypes.CREATE_EMPLOYEE](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.CREATE_EMPLOYEE_SUCCESS](state, { payload }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      employeeDetails: {
        $merge: { devstaffId: payload.devstaffId },
      },
    });
  },

  [deliveryActionsTypes.CREATE_EMPLOYEE_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: error,
      },
    });
  },

  [deliveryActionsTypes.UPDATE_WORKBOOK_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.UPDATE_WORKBOOK_RECORD_SUCCESS](state, {
    payload: {
      recordId,
      fields,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const workBookRecords = get(employeeDetails, 'workBookRecords', []);

    const recordIndex = workBookRecords.findIndex((record) => record && record.recordId === recordId);

    if (recordIndex !== -1) {
      return update(state, {
        errors: {
          $unset: ['updateWorkbookRecordError'],
        },
        isFormSubmitted: {
          $set: false,
        },

        employeeDetails: {
          workBookRecords: {
            [recordIndex]: {
              $merge: {
                ...fields,
                inEditMode: false,
              },
            },
          },
        },
      });
    }
    return update(state, {
      errors: {
        $unset: ['updateWorkbookRecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },

  [deliveryActionsTypes.UPDATE_WORKBOOK_RECORD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [deliveryActionsTypes.CREATE_WORKBOOK_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.CREATE_WORKBOOK_RECORD_SUCCESS](state, {
    payload: {
      fields,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const workBookRecords = get(employeeDetails, 'workBookRecords', []);

    return update(state, {
      errors: {
        $unset: ['createWorkbookRecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
      employeeDetails: {
        $merge: {
          workBookRecords: fields ? [fields, ...workBookRecords.slice(1)] : workBookRecords.slice(1),
        },
      },
    });
  },

  [deliveryActionsTypes.CREATE_WORKBOOK_RECORD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [deliveryActionsTypes.DELETE_WORKBOOK_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [deliveryActionsTypes.DELETE_WORKBOOK_RECORD_SUCCESS](state, {
    payload: {
      recordId,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const workBookRecords = get(employeeDetails, 'workBookRecords', []);

    const updatedRecords = workBookRecords.slice().filter((record) => !record || record.recordId !== recordId);

    return update(state, {
      employeeDetails: {
        $merge: {
          workBookRecords: updatedRecords,
        },
      },
      errors: {
        $unset: ['deleteWorkbookRecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },

  [deliveryActionsTypes.DELETE_WORKBOOK_RECORD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },

  [deliveryActionsTypes.REMOVE_WORKBOOK_TEMPLATE_RECORD](state) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const workBookRecords = get(employeeDetails, 'workBookRecords', []);

    return workBookRecords.length ? update(state, {
      employeeDetails: {
        $merge: {
          workBookRecords: workBookRecords.filter((record) => record.recordId),
        },
      },
    }) : state;
  },

  [deliveryActionsTypes.ADD_WORKBOOK_TEMPLATE_RECORD](state, action) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const workBookRecords = get(employeeDetails, 'workBookRecords', []);

    return update(state, {
      employeeDetails: {
        $merge: {
          workBookRecords: [action.payload, ...workBookRecords],
        },
      },
    });
  },

  [deliveryActionsTypes.TOGGLE_WORK_BOOK_RECORD_STATE](state, {
    payload: {
      recordId,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const workBookRecords = get(employeeDetails, 'workBookRecords', []);

    return update(state, {
      employeeDetails: {
        $merge: {
          workBookRecords: workBookRecords.map((record) => {
            const inEditMode = record && record.inEditMode;

            return record && record.recordId === recordId ? {
              ...record,
              inEditMode: !inEditMode,
            } :
              record;
          }),
        },
      },
    });
  },

  [deliveryActionsTypes.UPDATE_ONBOARDING_HISTORY](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.UPDATE_ONBOARDING_HISTORY_SUCCESS](state, {
    payload: {
      fields,
      historyId: id,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const onboardingHistory = get(employeeDetails, 'onboardingHistory', []);
    const dateStart = get(employeeDetails, 'dateStart', null);

    const updatedHistory = dateStart ? onboardingHistory.map((item) => {
      const {
        historyId,
      } = item;

      return id === historyId ? {
        ...item,
        ...fields,
      } : item;
    }) : onboardingHistory.filter(({
      historyId,
    }) => id !== historyId);

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      employeeDetails: {
        $merge: {
          onboardingHistory: updatedHistory,
        },
      },
      errors: {
        $unset: ['updateOnboardingHistoryError'],
      },
    });
  },

  [deliveryActionsTypes.UPDATE_ONBOARDING_HISTORY_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [deliveryActionsTypes.CREATE_ONBOARDING_HISTORY](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.CREATE_ONBOARDING_HISTORY_SUCCESS](state, {
    payload: {
      fields,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const onboardingHistory = get(employeeDetails, 'onboardingHistory', []);

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      employeeDetails: {
        $merge: {
          onboardingHistory: fields ? [...onboardingHistory, fields] : onboardingHistory,
        },
      },
      errors: {
        $unset: ['createOnboardingHistoryError'],
      },
    });
  },

  [deliveryActionsTypes.CREATE_ONBOARDING_HISTORY_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [deliveryActionsTypes.UPDATE_EMPLOYEES_DETAILS](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.UPDATE_EMPLOYEES_DETAILS_SUCCESS](state, {
    payload: {
      fields,
      devstaffId: id,
    },
  }) {
    const {
      employeeDetails,
    } = state;

    if (+employeeDetails.devstaffId === +id) {
      return update(state, {
        isFormSubmitted: {
          $set: false,
        },
        employeeDetails: {
          $merge: fields,
        },
        errors: {
          $unset: ['updateEmployeeDetailsError'],
        },
      });
    }

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $unset: ['updateEmployeeDetailsError'],
      },
    });
  },

  [deliveryActionsTypes.UPDATE_EMPLOYEES_DETAILS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [deliveryActionsTypes.GET_EMPLOYEES_DETAILS](state, {
    meta: {
      withReset,
    },
  }) {
    const stateUpdates = withReset ? {
      employeeDetails: {
        $set: {},
      },
      isFetchingDetails: {
        $set: true,
      },
    } : {
      isFetchingDetails: {
        $set: true,
      },
    };

    return update(state, stateUpdates);
  },

  [deliveryActionsTypes.GET_EMPLOYEES_DETAILS_SUCCESS](state, {
    payload: {
      employeeDetails,
      employeeDetailsTabs,
    },
  }) {
    return update(state, {
      isFetchingDetails: {
        $set: false,
      },
      employeeDetails: {
        $set: { ...employeeDetails, ...initialState.employeeDetails },
      },
      employeeDetailsTabs: {
        contractManagement: {
          length: {
            $set: employeeDetailsTabs.contracts,
          },
        },
        commentManagement: {
          length: {
            $set: employeeDetailsTabs.comments,
          },
        },
        attachments: {
          length: {
            $set: employeeDetailsTabs.attachments,
          },
        },
        contacts: {
          length: {
            $set: employeeDetailsTabs.contacts,
          },
        },
        technicalMentorManagement: {
          length: {
            $set: employeeDetailsTabs.technicalMentors,
          },
        },
      },
      errors: {
        $unset: ['getEmployeeDetailsError'],
      },
    });
  },

  [deliveryActionsTypes.GET_EMPLOYEES_DETAILS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetchingDetails: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [deliveryActionsTypes.GET_EMPLOYEES_LIST](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.GET_EMPLOYEES_LIST_SUCCESS](state, {
    payload: {
      employeesList,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      employeesData: {
        $set: employeesList,
      },
      errors: {
        $unset: ['getEmployeesListError'],
      },
    });
  },

  [deliveryActionsTypes.GET_EMPLOYEES_LIST_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [deliveryActionsTypes.GET_PROJECTS_LIST](state) {
    return update(state, {
      selectedProject: {
        $set: '',
      },
      isFetching: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.GET_PROJECTS_LIST_SUCCESS](state, {
    payload: {
      projectsList,
      plList,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      projectsData: {
        $set: projectsList,
      },
      plList: {
        $set: plList,
      },
      errors: {
        $unset: ['getProjectsListError'],
      },
    });
  },

  [deliveryActionsTypes.GET_PROJECTS_LIST_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [deliveryActionsTypes.CHANGE_FILTER](state) {
    return update(state, {
      isFiltering: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.CHANGE_FILTER_SUCCESS](state, {
    payload: {
      currentFilter,
      storeKey,
      config,
    },
  }) {
    return update(state, {
      isFiltering: {
        $set: false,
      },
      filters: {
        [currentFilter]: {
          [storeKey]: {
            $merge: config,
          },
        },
      },
    });
  },

  [deliveryActionsTypes.RESET_FILTERS](state, {
    payload: {
      currentFilter,
    },
  }) {
    const initialFiltersState = get(initialState, `filters.${currentFilter}`);

    return update(state, {
      isFiltering: {
        $set: false,
      },
      filters: {
        [currentFilter]: {
          $merge: initialFiltersState,
        },
      },
    });
  },

  [deliveryActionsTypes.SET_ORDER_RULES](state, {
    payload: {
      tableName,
      orderRules,
    },
  }) {
    return update(state, {
      orderRules: {
        [tableName]: {
          $set: orderRules,
        },
      },
    });
  },

  [deliveryActionsTypes.SET_EMPLOYEE_DETAILS](state) {
    return state;
  },

  [deliveryActionsTypes.UPDATE_FAVORITES_EMPLOYEES_LIST](state, {
    payload: {
      employeesList,
    },
  }) {
    return update(state, {
      favoritesEmployees: {
        $set: employeesList,
      },
    });
  },

  [deliveryActionsTypes.UPDATE_FAVORITES](state, {
    payload: {
      updatedFavorites,
    },
    meta: {
      entityName,
    },
  }) {
    return entityName === 'Projects' ? update(state, {
      favoritesProjects: {
        $set: updatedFavorites,
      },
    }) : update(state, {
      favoritesEmployees: {
        $set: updatedFavorites,
      },
    });
  },

  // TODO: uncomment when move to real data
  [deliveryActionsTypes.ADD_PTO_REQUEST_TEMPLATE_RECORD](state, {
    payload: {
      year,
      permanentId,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const ptoRequestRecords = get(employeeDetails, 'ptoRequestRecords', []);

    return update(state, {
      employeeDetails: {
        $merge: {
          ptoRequestRecords: [{
            year,
            permanentId,
          }, ...ptoRequestRecords],
        },
      },
    });
  },
  [deliveryActionsTypes.DELETE_PTO_REQUEST_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },
  [deliveryActionsTypes.DELETE_PTO_REQUEST_RECORD_SUCCESS](state, {
    payload: {
      recordId,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const ptoRequestRecords = get(employeeDetails, 'ptoRequestRecords', []);

    const updatedRecords = ptoRequestRecords.slice().filter((record) => !record || record.recordId !== recordId);

    return update(state, {
      employeeDetails: {
        $merge: {
          ptoRequestRecords: updatedRecords,
        },
      },
      errors: {
        $unset: ['deletePtoRequestRecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },
  [deliveryActionsTypes.DELETE_PTO_REQUEST_RECORD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
      isFormSubmitted: {
        $set: false,
      },
    });
  },

  // TODO: uncomment when move to real data
  [deliveryActionsTypes.TOGGLE_PTO_REQUEST_RECORD_STATE](state, {
    payload: {
      recordId,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const ptoRequestRecords = get(employeeDetails, 'ptoRequestRecords', []);

    return update(state, {
      employeeDetails: {
        $merge: {
          ptoRequestRecords: ptoRequestRecords.map((record) => {
            const inEditMode = record && record.inEditMode;

            return record && record.recordId === recordId ? {
              ...record,
              inEditMode: !inEditMode,
            } :
              record;
          }),
        },
      },
    });
  },

  // TODO: uncomment when move to real data
  [deliveryActionsTypes.REMOVE_PTO_REQUEST_TEMPLATE_RECORD](state, {
    payload: {
      permanentId,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const ptoRequestRecords = get(employeeDetails, 'ptoRequestRecords', []);
    return ptoRequestRecords.length ? update(state, {
      employeeDetails: {
        $merge: {
          ptoRequestRecords: ptoRequestRecords.filter((record) => record.permanentId !== permanentId),
        },
      },
    }) : state;
  },

  [deliveryActionsTypes.CREATE_PTO_REQUEST_RECORD](state, {
    payload: {
      permanentId,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
      employeeDetails: {
        $merge: {
          permanentId,
        },
      },
    });
  },

  [deliveryActionsTypes.CREATE_PTO_REQUEST_RECORD_SUCCESS](state, {
    payload: {
      fields,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const ptoRequestRecords = get(employeeDetails, 'ptoRequestRecords', []);
    const permanentId = get(employeeDetails, 'permanentId', '');
    return update(state, {
      errors: {
        $unset: ['createPtoRequestRecordError'],
      },
      isFormSubmitted: {
        $set: false,
      },
      employeeDetails: {
        $merge: {
          ptoRequestRecords: fields ? [fields, ...ptoRequestRecords.filter((record) => record.permanentId !== permanentId)] : ptoRequestRecords.filter((record) => record.permanentId !== permanentId),
          permanentId: '',
        },
      },
    });
  },

  [deliveryActionsTypes.CREATE_PTO_REQUEST_RECORD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [deliveryActionsTypes.UPDATE_PTO_REQUEST_RECORD](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [deliveryActionsTypes.UPDATE_PTO_REQUEST_RECORD_SUCCESS](state, {
    payload: {
      recordId,
      fields,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const ptoRequestRecords = get(employeeDetails, 'ptoRequestRecords', []);
    const recordIndex = ptoRequestRecords.findIndex((record) => record && record.recordId === recordId);
    if (recordIndex !== -1) {
      return update(state, {
        errors: {
          $unset: ['updatePtoRequestRecordError'],
        },
        isFormSubmitted: {
          $set: false,
        },

        employeeDetails: {
          ptoRequestRecords: {
            [recordIndex]: {
              $merge: {
                ...fields,
                inEditMode: false,
              },
            },
          },
        },
      });
    }
    return state;
  },

  [deliveryActionsTypes.UPDATE_PTO_REQUEST_RECORD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [deliveryActionsTypes.GENERATE_PL_APPROVALS_SUCCESS](state, {
    payload: {
      fields,
      recordId,
    },
  }) {
    const employeeDetails = get(state, 'employeeDetails', {});
    const ptoRequestRecords = get(employeeDetails, 'ptoRequestRecords', []);

    const recordIndex = ptoRequestRecords.findIndex((record) => record && record.recordId === recordId);

    const plApprovals = fields.length ? fields : [{
      internalCategoryKey: null,
      isApproved: false,
    }];

    if (recordIndex !== -1) {
      return update(state, {
        errors: {
          $unset: ['generatePlApprovalsError'],
        },
        employeeDetails: {
          ptoRequestRecords: {
            [recordIndex]: {
              plApprovals: {
                $set: plApprovals,
              },
            },
          },
        },
      });
    }
    return state;
  },

  [deliveryActionsTypes.GENERATE_PL_APPROVALS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      // isFormSubmitted: { $set: false },
      errors: {
        $merge: error,
      },
    });
  },

  [deliveryActionsTypes.CHECK_EMPLOYEE_EMAIL_SUCCESS](state, { payload: {
    isEmployeeEmailAvailable,
    employeeDetails,
  } }) {
    return update(state, {
      isEmployeeEmailAvailable: {
        $set: isEmployeeEmailAvailable,
      },
      employeeDetails: {
        $merge: employeeDetails,
      },
    });
  },

  [deliveryActionsTypes.CHECK_EMPLOYEE_EMAIL_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
    });
  },
  [deliveryActionsTypes.CLEAR_IS_EMPLOYEE_EMAIL_AVAILABLE](state) {
    return update(state, {
      errors: {
        $set: {},
      },
      isEmployeeEmailAvailable: {
        $set: false,
      },
    });
  },
  [deliveryActionsTypes.GENERATE_RESOURCE_BILLING_REPORT](state, { payload }) {
    return update(state, {
      resourceBillingReport: {
        $set: {
          isGenerating: true,
          devstaffId: payload.devstaffId,
        },
      },
    });
  },
  [deliveryActionsTypes.GENERATE_RESOURCE_BILLING_REPORT_SUCCESS](state) {
    return update(state, {
      errors: {
        $set: {},
      },
      resourceBillingReport: {
        isGenerating: {
          $set: false,
        },
      },
    });
  },
  [deliveryActionsTypes.GENERATE_RESOURCE_BILLING_REPORT_FAIL](state, { payload: { error } }) {
    return update(state, {
      errors: {
        $set: error,
      },
      resourceBillingReport: {
        isGenerating: {
          $set: false,
        },
      },
    });
  },
});
