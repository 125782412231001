import React from 'react';
export default (
  <p>
    One of the following scenarios or any combination of them took place:
    <br />
    <br />
    1. Probation period finished in the middle of the month.<br />
    2.  Salary changed in the middle of the month.<br />
    3. Delivery Center changed in the middle of the month.
  </p>
);
