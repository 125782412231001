import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import './styles.scss';

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

const defaultProps = {
  className: '',
  title: '',
  description: '',
};

const EmptyMessage = ({
  className,
  title,
  description,
}) => (
  <div className={classNames('empty_message', className)}>
    <p className="empty_message__title">{title}</p>
    <p className="empty_message__description">{description}</p>
  </div>
);

EmptyMessage.propTypes = propTypes;
EmptyMessage.defaultProps = defaultProps;

export default EmptyMessage;
