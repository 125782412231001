import {
  colorPrimary,
  colorSecondaryGrayLight,
  colorWhite,
} from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import {
  clientsModuleAccess,
  taskOrderModuleAccess,
  deliveryRootModuleAccess,
} from 'core/auth/guaranteedAccessRoles';
import { REPORTS_TYPES } from 'core/billing-reports/constants';
import { toNumber } from 'lodash';
import { getHasPermissions } from 'utils/auth';
import { addThousandSeparator } from 'utils/helpers/numbers';

import { filtersTemplates } from './config/filters';
import summaryBillableActivitiesTabGetter from './summary-billable-activities-tab';
import summaryNonBillableActivitiesTabGetter from './summary-non-billable-activities-tab';

const {
  HRM,
  RM,
  CTO,
  CEO,
  CPS,
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
} = USERS_GROUPS;

export const summaryReportsModelGetter = (
  userGroup,
  {
    ttmReportRows = [],
    odcReportRows = [],
    ptmFpReportRows = [],
    promoReportRows = [],
    overtimeReportRows = [],
    nonBillableReportRows = [],
    reportCalculationDate,
  },
  reportType,
  reportDate,
  availableDevCentersForDd
) => {
  const isNoteForTab = true;
  const isHRM =
    getHasPermissions(userGroup, [HRM]) || getHasPermissions(userGroup, [RM]); // HRM & RM have same permissions for this report
  const isCTO = getHasPermissions(userGroup, [CTO]);
  const isCPS = getHasPermissions(userGroup, [CPS]);
  const isChefs = getHasPermissions(userGroup, [CTO, CEO, CPS]);
  const isAudit = getHasPermissions(userGroup, [
    DELIVERY_OFFICE_ADMIN_BY,
    DELIVERY_OFFICE_ADMIN_SPB,
    DELIVERY_OFFICE_ADMIN_WAW,
    DELIVERY_OFFICE_ADMIN_TBS,
    DELIVERY_OFFICE_ADMIN_KZH,
  ]);
  const selectedDate = reportDate ?
    `${reportDate.year()}:${reportDate.month() + 1}` :
    '';
  const hasPermissionsTODeliveryModule = getHasPermissions(
    userGroup,
    deliveryRootModuleAccess
  );
  const hasPermissionsTOClientModule = getHasPermissions(
    userGroup,
    clientsModuleAccess
  );
  const hasPermissionsTOTaskOrderModule = getHasPermissions(
    userGroup,
    taskOrderModuleAccess
  );
  const billableRows = [
    ...ttmReportRows,
    ...ptmFpReportRows,
    ...promoReportRows,
    ...odcReportRows,
    ...overtimeReportRows,
  ];
  const isGMReport = reportType === REPORTS_TYPES.GM;
  const { grandTotal, totalRevenue } = billableRows.reduce(
    (
      { grandTotal: storedGrandTotal, totalRevenue: storedRevenue },
      { total, revenue }
    ) => ({
      grandTotal: storedGrandTotal + toNumber((Math.round(total * 100) / 100).toFixed(2)),
      totalRevenue: storedRevenue + toNumber((Math.round(revenue * 100) / 100).toFixed(2)),
    }),
    { grandTotal: 0, totalRevenue: 0 }
  );
  const nonBillableActivitiesTime = nonBillableReportRows.reduce(
    (acc, { hours }) => acc + toNumber(hours),
    0
  );
  const hasNonBillableActivities = nonBillableActivitiesTime !== 0;
  const total = grandTotal ?
    `$ ${addThousandSeparator(grandTotal.toFixed(2))}` :
    '$ 0.00';
  const revenue = totalRevenue ?
    `$ ${addThousandSeparator(totalRevenue.toFixed(2))}` :
    '$ 0.00';
  const isDTOReport = reportType === REPORTS_TYPES.DTO;

  const controlButtonStyles = `
    && {
      width: 100%;
      padding: 0.7rem 2.4rem;
      font-size: 1.3rem;
      line-height: 1.6rem;
      text-align: center;
      color: ${colorPrimary};
      border-color: ${colorPrimary};
      background-color: transparent;
      &:hover {
        color: ${colorWhite};
        background-color: ${colorPrimary};
      }

      &[disabled] {
        color: ${colorWhite};
        background: ${colorSecondaryGrayLight};
        border-color: ${colorSecondaryGrayLight};
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  `;

  return {
    filtersTemplates,
    additionalDataGetter: () => ({
      controlsHeading: `Billing Summary ${reportType} Report`,
      calendarItemCssRules: `
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.4rem;
        white-space: nowrap;
        color:  ${colorPrimary};
      `,
      calendarCssRules: `
        && {
          display: flex;
          min-width: unset;
          align-items: center;
          margin-right: 1.6rem;
          padding-top: 0;
          border: none;
          & path {
            fill: ${colorPrimary};
          }
        }
        && .calendar__toggle-button {
          padding-top: 0;
        }
      `,
      controlsCssRules: `
        &&& {
          display: grid;
          grid-template-columns: 1fr;
          grid-row-gap: 0.8rem;
          grid-auto-rows: minmax(3.2rem, auto);
          flex-direction: column;
          padding-top: 0.8rem;
          font-size: 1.5rem;
        }
      `,
      controlsWrapperCssRules: 'display: flex;',
      selectCssRules: `
        && {
          color: ${colorPrimary};
          .filter-select {
            width: auto;
            height: auto;
            &__multi-value__label {
              color: ${colorPrimary};
            }
            &__placeholder {
              padding-left: 0;
              color: ${colorPrimary};
            }
            &__control--is-focused {
              outline: none !important;
            }
            &__dropdown-indicator {
              path {
                fill: ${colorPrimary};
              }
            }
          }
        }
      `,
      controlButtonStyles,
    }),
    dataTemplate: [
      {
        tabHeader: {
          title: 'Billable activities',
          labels: [
            {
              valueGetter: () => ({
                value: isGMReport ? `Total Revenue: ${revenue}` : '',
                isEmpty: totalRevenue === 0,
              }),
            },
            {
              valueGetter: () => ({
                value: isHRM ?
                  '' :
                  `${isGMReport ? 'Total GM' : 'Grand Total'}: ${total}`,
                isEmpty: grandTotal === 0,
              }),
            },
          ],
        },
        stylesTemplate: {
          wrapperCssRules: 'padding: 1.6rem 4rem 10rem;',
        },
        tabBody: summaryBillableActivitiesTabGetter({
          isCTO,
          isCPS,
          isHRM,
          isChefs,
          isAudit,
          isGMReport,
          isDTOReport,
          isNoteForTab,
          selectedDate,
          reportCalculationDate,
          hasPermissionsTOClientModule,
          hasPermissionsTODeliveryModule,
          hasPermissionsTOTaskOrderModule,
          availableDevCentersForDd,
        }),
      },
      {
        tabHeader: {
          title: 'Non-billable activities',
          labels: [
            {
              valueGetter: () => ({
                value: hasNonBillableActivities ?
                  `Total unbillable: ${addThousandSeparator(nonBillableActivitiesTime.toFixed(2))} h` :
                  'No unbillable activities',
                isEmpty: !hasNonBillableActivities,
                cssRules: `&& {
              text-transform: initial;
            }`,
              }),
            },
          ],
        },
        stylesTemplate: {
          wrapperCssRules: 'padding: 1.6rem 4rem 10rem;',
        },
        tabBody: summaryNonBillableActivitiesTabGetter({
          isChefs,
          isCPS,
          isAudit,
          isDTOReport,
          isNoteForTab,
          selectedDate,
          reportCalculationDate,
          hasPermissionsTOClientModule,
          hasPermissionsTODeliveryModule,
          hasPermissionsTOTaskOrderModule,
        }),
      },
    ],
  };
};
