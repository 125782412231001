import { selectUserGroup } from 'core/auth/selectors';
import { getCommentManagementRecords, getCommentManagementPermissions } from 'core/delivery/commentManagement/utils';
import { createSelector } from 'reselect';

export const selectIsFetchingCommentManagementRecords = (state) => state.delivery.employeeDetailsTabs.commentManagement.isFetchingCommentManagementRecords;

export const selectCommentManagementRecords = (state) => state.delivery.employeeDetailsTabs.commentManagement.commentManagementRecords;

export const selectMemoDevstaffId = (state) => state.delivery.employeeDetailsTabs.commentManagement.memoDevstaffId;

export const selectRecords = createSelector(
  selectIsFetchingCommentManagementRecords,
  selectCommentManagementRecords,
  selectUserGroup,
  selectMemoDevstaffId,
  (isFetchingCommentManagementRecords, commentManagementRecords, userGroup, memoDevstaffId) => {
    const {
      hasPermissionToEdit,
      hasPermissionToEditOnlyThreeDays,
      hasPermissionToAddRecord,
    } = getCommentManagementPermissions({ userGroup });

    return {
      memoDevstaffId,
      isFetchingCommentManagementRecords,
      records: getCommentManagementRecords({
        records: commentManagementRecords,
        hasPermissionToEdit,
        hasPermissionToEditOnlyThreeDays,
      }),
      hasPermissionToAddRecord,
    };
  }
);

export const selectFormState = (state) => state.delivery.employeeDetailsTabs.commentManagement.formState;

export const selectCommentLength = (state) => state.delivery.employeeDetailsTabs.commentManagement.length;
