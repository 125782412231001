/* eslint-disable no-useless-escape */
import { ALL_ITEMS_SELECTED } from 'components/form-controls/constants';
import { getSelectItemsMap } from 'components/form-controls/utils';
import {
  updateClientDetails,
} from 'core/auth/guaranteedAccessRoles';
import TooltipContent from 'elements/tooltip-content';
import { isEmpty } from 'lodash';
import {
  getHasPermissions,
} from 'utils/auth';
import {
  generateMSA,
} from 'utils/helpers/files';
import {
  string,
} from 'yup';

export default ({
  isHRM,
  isDOA,
  userGroup,
  currentMsa,
  isCreatingForm,
}) => isHRM || isDOA ? [] : [{
  type: 'form',
  withoutFormControls: !getHasPermissions(userGroup, updateClientDetails),
  stylesTemplate: {
    cssRules: 'grid-template-columns: repeat(4, 1fr); grid-template-areas: "msa msa poc poc";',
    formControlsCss: 'grid-template-columns: repeat(4, calc((136rem - 4.8rem * 3 - 3.2rem) / 4));',
  },
  content: [{
    title: 'MSA info',
    stylesTemplate: {
      gridArea: 'msa',
      marginConfig: '2n',
      cssRules: `
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        padding-bottom: 2.4rem;
      `,
    },
    formData: [{
      type: 'text',
      withLabel: true,
      name: 'name',
      placeholder: 'Client Name',

      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      valueGetter: ({
        name,
      }) => ({
        value: name || '',
      }),

      validationRules: {
        isRequired: true,
        minLength: 2,
        maxLength: 50,
        schemaGetter: () => (
          string()
            .min(2, 'Client Name must have at least 2 characters')
            .max(50, 'Client Name max length is 50')
            .transform((value) => value.trim())
            .test('isTwoCharacters', 'Client Name must have at least 2 characters', (value) => value && (value.replace(/\d/g, '').length > 1))
            .required('Required field')
        ),
      },
    },
    {
      type: 'text',
      withLabel: true,
      name: 'msa',
      placeholder: 'MSA#',
      tooltipContent: TooltipContent,
      valueGetter: ({
        msa,
      }) => ({
        value: msa || '',
      }),
      parentField: {
        name: ['name'],
        strictDependence: true,

        valueGetter: ({
          name,
          msa: CurrentMsa,
        }) => {
          const msa = generateMSA(name);

          return {
            value: isCreatingForm ? generateMSA(name) : CurrentMsa,
            props: {
              isLocked: isCreatingForm ? !msa : false,
              isManualUpdatesSaves: true,
            },
          };
        },
      },
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),

      validationRules: {
        isRequired: true,
        maxLength: 50,
        schemaGetter: () => string()
          .max(50, 'MSA# max length is 50')
          .test('isMsaValid', 'Wrong format', (value = '') => currentMsa === value || value.match(/(^MSA-(0?0[1-9]|1[012])\d{4}-([A-Z]{2})(\d{1,37})$)/))
          .required('Required field'),
      },
    },
    {
      type: 'select',
      name: 'country',
      placeholder: 'Country',
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),

      valueGetter: ({
        country,
        countries = [],
      }) => {
        const currentItem = countries.find((item) => item.name === country);

        return {
          selected: {
            value: currentItem ? currentItem.name : '',
            label: currentItem ? currentItem.name : '',
          },

          items: countries.map(({
            name,
          }) => ({
            value: name,
            label: name,
          })),
        };
      },
      validationRules: {
        isRequired: true,
        schemaGetter: () => string().required('Required field'),
      },
    },
    {
      type: 'select',
      name: 'stateIncorporated',
      placeholder: 'Incorporated in',
      validationRules: {
        isRequired: true,
        schemaGetter: () => string().required('Required field'),
      },
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),

      valueGetter: ({
        stateIncorporated,
        states = [],
      }) => {
        const currentItem = states.find((item) => item.code === stateIncorporated);

        return {
          selected: {
            value: currentItem ? currentItem.code : '--',
            label: currentItem ? currentItem.name.replace(/^-{2,3}$/, 'n/a') : 'n/a',
          },

          items: states.map(({
            code,
            name,
          }) => ({
            value: code,
            label: name.replace(/^-{2,3}$/, 'n/a'),
          })),
        };
      },
      parentField: {
        name: ['country'],
        strictDependence: true,
        valueGetter: ({
          country,
        }, fieldData) => country === 'USA' ?
          fieldData : {
            selected: {
              label: '',
              value: '--',
            },
            items: [],
          },
      },
    },
    {
      type: 'text',
      withLabel: true,
      name: 'address1',
      placeholder: 'Address 1',

      valueGetter: ({
        address1,
      }) => ({
        value: address1 || '',
      }),
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      validationRules: {
        maxLength: 50,
        isRequired: true,
        schemaGetter: () => string().max(50, 'Address max length is 50').required('Required field').transform((value) => value.trim()),
      },
    },
    {
      type: 'text',
      withLabel: true,
      name: 'address2',
      placeholder: 'Address 2',

      valueGetter: ({
        address2,
      }) => ({
        value: address2 || '',
      }),
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      validationRules: {
        maxLength: 50,
        schemaGetter: () => string().transform((value) => value.trim()).max(50, 'Address max length is 50'),
      },
    },
    {
      type: 'text',
      withLabel: true,
      name: 'city',
      placeholder: 'City',

      valueGetter: ({
        city,
      }) => ({
        value: city || '',
      }),
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      validationRules: {
        isRequired: true,
        maxLength: 50,
        schemaGetter: () => string().transform((value) => value.trim()).max(50, 'City max length is 50').required('Required field'),
      },
    },
    {
      isMultiple: true,

      cssRules: 'grid-template-columns: 1fr 1fr;',

      formData: [{
        type: 'select',
        name: 'state',
        placeholder: 'State',
        pathInAPIResponse: ['state'],
        selectItems: 'states',
        validationRules: {
          isRequired: true,
          schemaGetter: () => string().required('Required field'),
        },
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
        valueGetter: ({
          state,
          states = [],
        }) => {
          const currentItem = states.find((item) => item.code === state);

          return {
            selected: {
              value: currentItem ? currentItem.code : '--',
              label: currentItem ? currentItem.code.replace(/^-{2,3}$/, 'n/a') : 'n/a',
            },

            items: states.map(({
              code,
            }) => ({
              value: code,
              label: code.replace(/^-{2,3}$/, 'n/a'),
            })),
          };
        },
        parentField: {
          name: ['country'],
          strictDependence: true,
          valueGetter: ({
            country,
          }, fieldData) => country === 'USA' ?
            fieldData : {
              selected: {
                label: '',
                value: '--',
              },
              items: [],
            },
        },
      },
      {
        type: 'text',
        withLabel: true,
        name: 'zip',
        placeholder: 'ZIP',

        valueGetter: ({
          zip,
        }) => ({
          value: zip || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
        validationRules: {
          isRequired: true,
          maxLength: 10,
          schemaGetter: () => string().transform((value) => value.trim()).max(10, 'ZIP max length is 10').required('Required field'),
        },
      },
      ],
    },
    {
      type: 'toggle',
      name: 'individualLiability',
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      placeholder: 'Individual Liability',

      valueGetter: ({
        individualLiability,
      }) => !!individualLiability,
      validationRules: {
        isRequired: true,
      },
    },
    {
      type: 'toggle',
      name: 'nosec14',
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      placeholder: 'Exclude section 13 from MSA',

      valueGetter: ({
        nosec14,
      }) => !!nosec14,
      actionName: 'openModal',
      actionArguments: {
        currentModal: 'CONFIRM_ACTION_MODAL',
        content: [{
          type: 'title',
          data: 'Do you want to change the status of “Exclude section 13 from MSA” check-box?',
        }, {
          type: 'description',
          data: 'Changing of this parameter will reset all changes in the MSA generation template to the default version and update Section 13 in the MSA generation template accordingly.',
        }],
        acceptActionTitle: 'Change',
      },
      targetName: 'nosec14',
      getTargetValue: ({ nosec14 }) => !nosec14,
      withStopPropagation: true,
      validationRules: {
        isRequired: true,
      },
    },
    {
      cssRules: `
        && .select-item--is-disabled::after {
          min-height: 4.8rem;
          height: 100%;
        };
      `,
      isMulti: true,
      type: 'select',
      name: 'excludedDevcenters',
      placeholder: 'DevCenters excluded from MSA',
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      valueGetter: ({
        devcenters = ALL_ITEMS_SELECTED,
        excludedDevcenters = ALL_ITEMS_SELECTED,
      }) => {
        const hasDevCenters = !isEmpty(devcenters) && Array.isArray(devcenters);

        return {
          selected: devcenters.length === excludedDevcenters.length ?
            ALL_ITEMS_SELECTED :
            devcenters.filter(({ value }) => excludedDevcenters.includes(value)),
          items: [...ALL_ITEMS_SELECTED, ...devcenters],
          itemsMap: hasDevCenters ? getSelectItemsMap(devcenters) : {},
        };
      },
      validationRules: {
        isRequired: true,
      },
    },
    {
      type: 'toggle',
      name: 'showDevcenterInfo',
      cssRules: 'height: 4.8rem !important;',
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      placeholder: 'Show DevCenter in TO',
      valueGetter: ({
        showDevcenterInfo = true,
      }) => !!showDevcenterInfo,
      validationRules: {
        isRequired: true,
      },
    },
    {
      type: 'toggle',
      name: 'includeHrsPerCategory',
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      placeholder: 'Include hrs per category in reports',

      valueGetter: (
        { includeHrsPerCategory = true }
      ) => Boolean(includeHrsPerCategory),
      validationRules: {
        isRequired: true,
      },
    },
    ],
  },

  {
    title: 'POC for MSA',
    stylesTemplate: {
      columns: '1fr 1fr',
      gridArea: 'poc',
    },
    formData: [{
      type: 'text',
      withLabel: true,
      name: 'pocMsaName',
      placeholder: 'POC Name',

      valueGetter: ({
        pocMsaName,
      }) => ({
        value: pocMsaName || '',
      }),
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      validationRules: {
        isRequired: true,
        maxLength: 50,
        schemaGetter: () => string().max(50, 'POC Name max length is 50').required('Required field'),
      },
    },
    {
      type: 'text',
      withLabel: true,
      name: 'pocMsaTitle',
      placeholder: 'POC Title',
      pathInAPIResponse: ['pocMsaTitle'],
      valueGetter: ({
        pocMsaTitle,
      }) => ({
        value: pocMsaTitle || '',
      }),
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      validationRules: {
        isRequired: true,
        maxLength: 50,
        schemaGetter: () => string().max(50, 'POC Title max length is 50').required('Required field'),
      },
    },
    {
      type: 'text',
      withLabel: true,
      name: 'pocMsaEmail',
      placeholder: 'Email',
      pathInAPIResponse: ['pocMsaEmail'],
      valueGetter: ({
        pocMsaEmail,
      }) => ({
        value: pocMsaEmail || '',
      }),
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      validationRules: {
        isEmail: true,
        isRequired: true,
        maxLength: 50,
        schemaGetter: () => (
          string()
            .max(50, 'Email max length is 50')
            .matches(
              /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              'Invalid email address'
            )
            .transform((value) => value.trim())
            .required('Required field')
        ),
      },
    },
    {
      type: 'text',
      withLabel: true,
      name: 'pocMsaFax',
      placeholder: 'Fax',
      pathInAPIResponse: ['pocMsaFax'],
      valueGetter: ({
        pocMsaFax,
      }) => ({
        value: pocMsaFax || '',
      }),
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      validationRules: {
        maxLength: 30,
        schemaGetter: () => string().transform((value) => value.trim()).max(30, 'Fax max length is 30'),
      },
    },
    {
      type: 'text',
      withLabel: true,
      name: 'pocMsaAddress1',
      placeholder: 'Address 1',
      pathInAPIResponse: ['pocMsaAddress1'],
      valueGetter: ({
        pocMsaAddress1,
      }) => ({
        value: pocMsaAddress1 || '',
      }),
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      validationRules: {
        isRequired: true,
        maxLength: 50,
        schemaGetter: () => string().transform((value) => value.trim()).max(50, 'Address max length is 50').required('Required field'),
      },
    },
    {
      type: 'text',
      withLabel: true,
      name: 'pocMsaAddress2',
      placeholder: 'Address 2',
      pathInAPIResponse: ['pocMsaAddress2'],
      valueGetter: ({
        pocMsaAddress2,
      }) => ({
        value: pocMsaAddress2 || '',
      }),
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      validationRules: {
        maxLength: 50,
        schemaGetter: () => string().transform((value) => value.trim()).max(50, 'Address max length is 50'),
      },
    },
    {
      type: 'text',
      withLabel: true,
      name: 'pocMsaCity',
      placeholder: 'City',
      pathInAPIResponse: ['pocMsaCity'],
      valueGetter: ({
        pocMsaCity,
      }) => ({
        value: pocMsaCity || '',
      }),
      getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
      validationRules: {
        isRequired: true,
        maxLength: 50,
        schemaGetter: () => string().transform((value) => value.trim()).max(50, 'City max length is 50').required('Required field'),
      },
    },
    {
      isMultiple: true,

      cssRules: 'grid-template-columns: 1fr 1fr;',

      formData: [{
        type: 'select',
        name: 'pocMsaState',
        placeholder: 'State',
        validationRules: {
          isRequired: true,
          schemaGetter: () => string().required('Required field'),
        },
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
        valueGetter: ({
          pocMsaState,
          states = [],
        }) => {
          const currentItem = states.find((item) => item.code === pocMsaState);

          return {
            selected: {
              value: currentItem ? currentItem.code : '--',
              label: currentItem ? currentItem.code.replace(/^-{2,3}$/, 'n/a') : 'n/a',
            },

            items: states.map(({
              code,
            }) => ({
              value: code,
              label: code.replace(/^-{2,3}$/, 'n/a'),
            })),
          };
        },
        parentField: {
          name: ['country'],
          strictDependence: true,
          valueGetter: ({
            country,
          }, fieldData) => country === 'USA' ?
            fieldData : {
              selected: {
                label: '',
                value: '--',
              },
              items: [],
            },
        },
      },
      {
        type: 'text',
        withLabel: true,
        name: 'pocMsaZip',
        placeholder: 'ZIP',
        pathInAPIResponse: ['pocMsaZip'],
        valueGetter: ({
          pocMsaZip,
        }) => ({
          value: pocMsaZip || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
        validationRules: {
          isRequired: true,
          maxLength: 10,
          schemaGetter: () => string().transform((value) => value.trim()).max(10, 'ZIP max length is 10').required('Required field'),
        },
      },
      ],
    },
    ],
  },
  ],
}];
