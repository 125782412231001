export const getAvailableRolesByPathSegment = (
  guaranteedAccessRolesForSubRoutes,
  locationPathname
) => {
  let roles = [];

  if (guaranteedAccessRolesForSubRoutes) {
    Object.keys(guaranteedAccessRolesForSubRoutes).forEach((key) => {
      if (locationPathname.includes(key)) {
        roles = guaranteedAccessRolesForSubRoutes[key];
      }
    });
  }

  return roles;
};
