import { isString } from 'lodash';
import { sortRates } from 'utils/sortRates';

export const mapRates = (ratecards) => ratecards.map((ratecard) => ({
  ...ratecard,
  rates: sortRates(ratecard.rates),
}));

export const generateSelectItems = (data, {
  key,
  valueAccessor,
  labelAccessor,
}) => {
  if (!data) {
    return {};
  }

  return data.reduce((acc, item) => ({ ...acc,
    [item[key]]: {
      value: item[valueAccessor],
      label: item[labelAccessor],
    } }), {});
};

export const normalizeRateCardData = (data) => data.reduce((acc, item) => {
  const {
    ratecardId,
    name,
    billingAgentId,
  } = item;

  if (!acc[billingAgentId]) {
    return {
      ...acc,
      [billingAgentId]: [{
        value: ratecardId,
        label: name,
      }],
    };
  }

  return { ...acc,
    [billingAgentId]: acc[billingAgentId].concat({
      value: ratecardId,
      label: name,
    }) };
}, {});

const searchFilter = (filterValue, dataValue) => filterValue === '' ? true : dataValue.toLowerCase().includes(filterValue.toLowerCase());

const selectFilter = (filterValue, dataValue) => filterValue.length ? filterValue.some((value) => value === dataValue) : true;

const emailedFilter = (filterValue, dataValue) => filterValue.length ? filterValue.some((value) => {
  if (value === 1) return !dataValue;

  return Boolean(dataValue);
}) : true;

export const shouldDisplayRateCardNotificationsData = (rateCardNotification, filterState) => {
  const {
    name,
    country,
    salutation,
    email,
    billingAgent,
    rateCard,
    notification,
    status,
    emailed,
  } = filterState;

  const matchesName = searchFilter(name.value, rateCardNotification.name);
  const matchesCountry = searchFilter(country.value, rateCardNotification.country);
  const matchesSalutation = searchFilter(salutation.value, rateCardNotification.salutation);
  const matchesEmail = searchFilter(email.value, rateCardNotification.email);

  const matchesBillingAgent = selectFilter(billingAgent.value, rateCardNotification.billingAgent);
  const matchesRateCard = selectFilter(rateCard.value, rateCardNotification.rateCard);
  const matchesNotification = selectFilter(notification.value, rateCardNotification.notification);
  const matchesEmailed = emailedFilter(emailed.value, rateCardNotification.emailed);
  const matchesStatus = selectFilter(status.value, rateCardNotification.status);

  return [
    matchesName,
    matchesCountry,
    matchesSalutation,
    matchesEmail,
    matchesBillingAgent,
    matchesRateCard,
    matchesNotification,
    matchesEmailed,
    matchesStatus,
  ].every(Boolean);
};

export const shouldDisplayEmailTemplateNotificationsData = (emailTemplateNotification, filterState) => {
  const {
    name,
    country,
    salutation,
    emails,
    billingAgent,
    emailTemplateId,
    notification,
    status,
    emailed,
  } = filterState;

  const matchesName = searchFilter(name.value, emailTemplateNotification.name);
  const matchesCountry = searchFilter(country.value, emailTemplateNotification.country);
  const matchesSalutation = searchFilter(salutation.value, emailTemplateNotification.salutation);
  const matchesEmails = searchFilter(emails.value, emailTemplateNotification.emails);

  const matchesBillingAgent = selectFilter(billingAgent.value, emailTemplateNotification.billingAgent);
  const matchesEmailTemplate = selectFilter(emailTemplateId.value, emailTemplateNotification.emailTemplateId);
  const matchesNotification = selectFilter(notification.value, emailTemplateNotification.notification);
  const matchesEmailed = emailedFilter(emailed.value, emailTemplateNotification.emailed);
  const matchesStatus = selectFilter(status.value, emailTemplateNotification.status);

  return [
    matchesName,
    matchesCountry,
    matchesSalutation,
    matchesEmails,
    matchesBillingAgent,
    matchesEmailTemplate,
    matchesNotification,
    matchesEmailed,
    matchesStatus,
  ].every(Boolean);
};

export const shouldDisplayUserManagementData = (user, filterState) => {
  const {
    fullname,
    email,
    billingAgent,
    title,
    role,
    phone,
  } = filterState;

  const matchesFullname = searchFilter(fullname.value, user.fullname);
  const matchesEmail = searchFilter(email.value, user.email);
  const matchesTitle = searchFilter(title.value, user.title);
  const matchesPhone = searchFilter(phone.value, user.phone);
  const matchesBillingAgent = selectFilter(billingAgent.value, user.billingAgent.value);
  const matchesRole = selectFilter(role.value, user.role.label);

  return [
    matchesFullname,
    matchesEmail,
    matchesTitle,
    matchesPhone,
    matchesBillingAgent,
    matchesRole,
  ].every(Boolean);
};

const descendingSort = (a, b) => {
  const current = isString(a) ? a.toLowerCase() : a;
  const next = isString(a) ? b.toLowerCase() : b;

  if (current > next) return -1;
  if (current < next) return 1;

  return 0;
};

const ascendingSort = (a, b) => {
  const current = isString(a) ? a.toLowerCase() : a;
  const next = isString(a) ? b.toLowerCase() : b;

  if (current < next) return -1;
  if (current > next) return 1;

  return 0;
};

export const sortAlphabeticalByKey = (array, key, direction) => [...array].sort((a, b) => {
  const x = a[key];
  const y = b[key];

  if (direction === 'desc') {
    return descendingSort(x, y);
  }

  return ascendingSort(x, y);
});

export const sortByProcessed = (array, key, direction) => [...array].sort((a, b) => {
  const x = a[key];
  const y = b[key];

  if (direction === 'asc') {
    return descendingSort(x, y);
  }

  return ascendingSort(x, y);
});
