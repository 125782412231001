import React, {
  Fragment,
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import DetailsForm from 'components/details-form';
import DetailsTable from 'components/details-table';
import ExpansionPanel from 'components/expansion-panel';
import Filter from 'components/filter';
import SingleRow from 'components/grid-template';

import Legend from 'components/legend';
import Loader from 'components/loader';
import { UNITS_TYPES } from 'core/constants';
import EmptyMessage from 'elements/empty-message';
import Heading from 'elements/plain-text';
import ScreenPlaceholder from 'elements/screen-placeholder';
import Wrapper from 'elements/wrapper';
import { withLegend } from 'utils/legend';

const getUnit = ({
  currentFilter,
  resetFilters,
  changeFilter,
  orderRules,
  changeOrder,
  isFetchingDetails,
  entityName,
  rowData,
  type,
  ...rest
}) => {
  const units = {
    [UNITS_TYPES.SCREEN_PLACEHOLDER]: {
      component: ScreenPlaceholder,
      props: {
        ...rest,
      },
    },
    [UNITS_TYPES.FORM]: {
      component: DetailsForm,
      props: {
        entityName,
        orderRules,
        changeOrder,
        isFetchingDetails,
        ...rest,
      },
    },
    [UNITS_TYPES.HEADING]: {
      component: Heading,
      props: {
        ...rest,
      },
    },
    [UNITS_TYPES.FILTER]: {
      component: currentFilter ? Filter : null,
      props: {
        resetFilters,
        changeFilter,
        ...rest,
      },
    },
    [UNITS_TYPES.TABLE]: {
      component: DetailsTable,
      props: {
        entityName,
        orderRules,
        changeOrder,
        isFetchingDetails,
        ...rest,
      },
    },
    [UNITS_TYPES.SINGLE_ROW]: {
      component: SingleRow,
      props: {
        rowData,
        ...rest,
      },
    },
    [UNITS_TYPES.EMPTY_MESSAGE]: {
      component: EmptyMessage,
      props: {
        ...rest,
      },
    },
  };

  return units[type];
};

const LayoutBodyContent = ({
  body,
  changeOrder,
  orderRules,
  entityName,
  isVisible,
  isFetching,
  resetFilters,
  changeFilter,
  wrapperCssRules,
  unitActions,
  ...restProps
}) => {
  const resetCurrentFilters = useCallback(
    (currentFilters) => {
      if (currentFilters != null) {
        if (Array.isArray(currentFilters)) {
          currentFilters.forEach((currentFilter) => resetFilters({ currentFilter }));
        } else {
          resetFilters({ currentFilter: currentFilters });
        }
      }
    },
    [resetFilters]
  );

  useEffect(() => () => {
    // We are going to reset filters on layout unmount.
    body.forEach(({ currentFilter }) => resetCurrentFilters(currentFilter));
  }, []);

  return (
    <Wrapper
      isGrow
      isVisible={isVisible}
      cssRules={wrapperCssRules}
    >
      {
        isFetching ?
          <Loader /> :
          body.map(({ type, summaryConfig, currentFilter, tableName, ...rest }, bodyItems) => {
            const { content } = rest;
            const renderUnit = () => {
              const reset = () => resetCurrentFilters(currentFilter);
              const onChange = (config) => changeFilter({ currentFilter, ...config });

              const unit = getUnit({
                isFetchingDetails: isFetching,
                changeFilter: onChange,
                resetFilters: reset,
                rowData: content,
                currentFilter,
                changeOrder,
                unitActions,
                orderRules,
                entityName,
                type,
                ...restProps,
                ...rest,
              });
              if (unit) {
                const { component, props } = unit;
                return React.createElement(
                  component,
                  props,
                );
              }
              return null;
            };

            return (
              <Fragment
                key={bodyItems} // eslint-disable-line react/no-array-index-key
              >
                {
                  summaryConfig && !isFetching ? (
                    <ExpansionPanel summaryConfig={summaryConfig}>
                      {renderUnit()}
                    </ExpansionPanel>
                  ) :
                    renderUnit()
                }
                {withLegend(content) && <Legend title={content[0].legend} />}
              </Fragment>
            );
          })
      }
    </Wrapper>
  );
};

LayoutBodyContent.propTypes = {
  wrapperCssRules: PropTypes.string,
  entityName: PropTypes.string,
  changeOrder: PropTypes.func,
  resetFilters: PropTypes.func,
  changeFilter: PropTypes.func,
  isFetching: PropTypes.bool,
  isVisible: PropTypes.bool,
  orderRules: PropTypes.shape({}),
  body: PropTypes.arrayOf(PropTypes.shape()),
  unitActions: PropTypes.shape({}),
};

LayoutBodyContent.defaultProps = {
  body: [],
  entityName: '',
  orderRules: {},
  isVisible: true,
  unitActions: {},
  isFetching: false,
  wrapperCssRules: '',
  changeOrder: () => null,
  resetFilters: () => null,
  changeFilter: () => null,
};


export default LayoutBodyContent;
