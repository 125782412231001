import { MAX_PTO_DAYS_TO_ADD } from 'utils/constants';
import {
  string,
  object,
} from 'yup';

export const requiredValidator = (spec = string(), message = 'Required field') => spec.required(message);

export const getValidationSchema = (schema) => (
  object().shape(schema)
);

export const rateValidator = string()
  .max(13, 'Max value is 9999999999.99')
  .test('isRate', 'Must be a number', (value) => value ? !Number.isNaN(Number(value)) : true)
  // .test('isNotEmpty', 'Required field', (value) => value && !Number.isNaN(Number(value)) && Number(value))
  .nullable();

export const utilizationValidator = string()
  .nullable()
  .test('maxValue', 'Max value is 100', (value) => value && !Number.isNaN(Number(value)) && Number(value) <= 100)
  .max(3, 'Max value is 100')
  .test('isNumber', 'Must be a number', (value) => value && !Number.isNaN(Number(value)))
  .test('isNotEmpty', 'Required field', (value) => value && !Number.isNaN(Number(value)) && Number(value))
  .required('Required field');

export const seniorityValidation = object().shape({
  ratePtm: rateValidator.test('isNotEmpty', 'Required field', (value) => value && !Number.isNaN(Number(value)) && Number(value)).required('Required field'),
  rateHr: rateValidator.test('isNotEmpty', 'Required field', (value) => value && !Number.isNaN(Number(value)) && Number(value)).required('Required field'),
  rateDisc: rateValidator.test('isNotEmpty', 'Required field', (value) => value && !Number.isNaN(Number(value)) && Number(value)).required('Required field'),
  ratePlatinum: rateValidator.test('isNotEmpty', 'Required field', (value) => value && !Number.isNaN(Number(value)) && Number(value)).required('Required field'),
  rateMo: rateValidator.test('isNotEmpty', 'Required field', (value) => value && !Number.isNaN(Number(value)) && Number(value)).required('Required field'),
  rateMoPlatinum: rateValidator.test('isNotEmpty', 'Required field', (value) => value && !Number.isNaN(Number(value)) && Number(value)).required('Required field'),
});

export function getPTODaysValidation({
  isAddDaysActionType,
  isSetTotalDaysActionType,
}, maxAvailableDays) {
  const changeTotalPTODaysValidation = (value) => !value || (value <= MAX_PTO_DAYS_TO_ADD && value >= 0);
  const availablePTODaysValidation = (value) => !value || ((value % 0.5 === 0) && maxAvailableDays - value >= 0);

  if (isAddDaysActionType || isSetTotalDaysActionType) {
    return changeTotalPTODaysValidation;
  }

  return availablePTODaysValidation;
}
