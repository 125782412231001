import React, { Fragment, useLayoutEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import DetailsForm from 'components/details-form';
import ExpansionPanel from 'components/expansion-panel';
import Loader from 'components/loader';
import ScreenPlaceholder from 'elements/screen-placeholder';
import Wrapper from 'elements/wrapper';

const MultiFormsWrapper = ({
  body,
  children,
  isRender,
  isVisible,
  isFetching,
  layoutEffect,
  layoutTriggers,
  wrapperCssRules,
  ...restProps
}) => {
  useLayoutEffect(layoutEffect, layoutTriggers);
  const content = useMemo(() => body.map(({ summaryConfig, formId, ...rest }) => {
    const form = (
      <DetailsForm
        isFetchingDetails={isFetching}
        formId={formId}
        {...restProps}
        {...rest}
      />
    );
    return (
      <Fragment
        key={formId}
      >
        {
          summaryConfig && !isFetching ? (
            <ExpansionPanel summaryConfig={summaryConfig}>
              {form}
            </ExpansionPanel>
          ) :
            form
        }
      </Fragment>
    );
  }), [body]);
  const hasContent = content.length;

  return (
    <Wrapper
      isGrow
      isVisible={isVisible}
      cssRules={wrapperCssRules}
    >
      {
        isFetching ?
          <Loader /> : (
            <>
              { children }
              { hasContent ?
                content : (
                  <ScreenPlaceholder
                    title="No results"
                    description="No required reviews."
                  />
                )}
            </>
          )
      }
    </Wrapper>

  );
};

MultiFormsWrapper.propTypes = {
  wrapperCssRules: PropTypes.string,
  entityName: PropTypes.string,
  changeOrder: PropTypes.func,
  resetFilters: PropTypes.func,
  layoutEffect: PropTypes.func,
  changeFilter: PropTypes.func,
  isFetching: PropTypes.bool,
  isVisible: PropTypes.bool,
  orderRules: PropTypes.shape({}),
  body: PropTypes.arrayOf(PropTypes.shape()),
  layoutTriggers: PropTypes.arrayOf(PropTypes.any),
  unitActions: PropTypes.shape({}),
};

MultiFormsWrapper.defaultProps = {
  body: [],
  entityName: '',
  orderRules: {},
  isVisible: true,
  unitActions: {},
  isFetching: false,
  layoutTriggers: [],
  wrapperCssRules: '',
  changeOrder: () => null,
  resetFilters: () => null,
  changeFilter: () => null,
  layoutEffect: () => null,
};

export default MultiFormsWrapper;
