export const generateVersion = (version, versions) => version ? versions.find((v) => v.versionId === version) : {
  versionId: versions[versions.length - 1].versionId,
  allowedForDelete: versions[versions.length - 1].allowedForDelete,
};

export const getPayloadWithActualVersion = (rateCardNotificationsVersions, payload) => {
  const payloadWithActualVersion = {
    year: payload.year,
  };
  const deletedVersionIndex = rateCardNotificationsVersions
    .findIndex((item) => item.versionId === payload.version);
  const previousVersion = rateCardNotificationsVersions[deletedVersionIndex - 1];
  const nextVersion = rateCardNotificationsVersions[deletedVersionIndex + 1];

  if (!previousVersion && !nextVersion) {
    return payloadWithActualVersion;
  }

  if (!previousVersion) {
    return {
      ...payloadWithActualVersion,
      version: nextVersion.versionId,
    };
  }

  return {
    ...payloadWithActualVersion,
    version: previousVersion.versionId,
  };
};
