import { push } from 'connected-react-router';
import {
  appStorage,
} from 'core/storage';
import {
  get,
} from 'lodash';
import {
  put,
  takeLatest,
  call,
  select,
  delay,
} from 'redux-saga/effects';

import {
  getIsTokenExpire,
  parseJWT,
} from 'utils/auth';
import { replaceValues } from 'utils/helpers/objects';
import {
  executeQuery, executeUnauthorizedQuery,
  parseError,
} from 'utils/request';

import {
  authActionsTypes,
  authActions,
} from './actions';
import {
  queryConfig,
} from './queries';
import {
  selectErrors,
  selectEntityName,
} from './selectors';

export function waitForAuthorization(saga) {
  return function* executeSaga(args) {
    const accessToken = yield call(appStorage.getAccessToken);
    const { hasExpired } = getIsTokenExpire(accessToken);
    if (!accessToken || hasExpired) {
      yield put(authActions.userLogout());
    } else {
      yield call(saga, args);
    }
  };
}

function* getTokenFromCookies() {
  try {
    const token = yield call(appStorage.getAccessToken);
    if (!token) {
      yield put(push('/'));
    }
    const userData = parseJWT(token);

    yield put(authActions.setUser(userData));
    yield put(authActions.getCommonData(userData));
  } catch (error) {
    yield put(authActions.getAccessTokenFail({
      error,
    }));
  }
}

function* getCommonData() {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'getCommonData', []);

  try {
    const query = queryConfig.getCommonData;
    const options = {
      query,
    };

    const data = yield call(executeQuery, options);

    yield put(authActions.setUserSuccess({
      usersList: get(data, 'users', []),
    }));
    const devCentersBySystemRole = replaceValues(
      get(data, 'devcentersBySystemRole', []),
      {
        ACCOUNTANT_WAW: 'ACC_WAW',
        ACCOUNTANT_TBS: 'ACC_TBS',
        ACCOUNTANT_NQZ: 'ACC_NQZ',
      },
      'role',
    );
    return yield put(authActions.getCommonDataSuccess({
      devCenters: get(data, 'devcenters', []),
      devCentersBySystemRole,
      billingAgents: get(data, 'billingAgents', []),
      customers: get(data, 'customers', []),
      roles: get(data, 'roles', []),
    }));
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const options = {
      entityName,
      storedErrors,
    };
    const getCommonDataError = yield call(parseError, error, options);

    return yield put(authActions.getCommonDataFail({
      error: {
        getCommonDataError,
      },
    }));
  }
}

function* userLogin({ payload }) {
  try {
    const query = queryConfig.userLogin;
    const options = {
      query,
      variables: payload,
    };
    const data = yield call(executeUnauthorizedQuery, options);
    yield put(authActions.userLoginSuccess({ ...data.UserLoginResponse, email: payload.email }));
    yield put(authActions.removeError('verification'));
    yield put(authActions.removeError('resetVerification'));
    yield put(push('/verification'));
  } catch (error) {
    yield put(authActions.userLoginFail({
      error: [
        {
          message: get(error, 'extensions.detailed', 'Internal error'),
        },
      ],
    }));
  }
}

function* userVerification({ payload }) {
  try {
    const query = queryConfig.userVerification;
    const options = {
      query,
      variables: payload,
    };
    const data = yield call(executeUnauthorizedQuery, options);
    yield call(appStorage.setAccessToken, data.UserVerificationResponse.apiToken);
    yield put(authActions.userVerificationSuccess(data.UserVerificationResponse));
    yield put(authActions.getTokenFromCookies());
  } catch (error) {
    const message = get(error, 'extensions.detailed', 'Internal error');
    const errorCode = get(error, 'extensions.errorCode', 400);
    yield put(authActions.userVerificationFail({
      error: [
        {
          message,
        },
      ],
    }));
    yield delay(2000);
    if (errorCode === 401) {
      yield put(authActions.userLogout());
    }
  }
}

function* userLogout() {
  yield call(appStorage.removeAccessToken);
  yield put(push('/'));
}

function* resetVerification({ payload }) {
  try {
    const query = queryConfig.verificationReset;
    const options = {
      query,
      variables: payload,
    };
    const data = yield call(executeUnauthorizedQuery, options);
    yield put(authActions.verificationResetSuccess(get(data, 'VerificationResetResponse.status', false)));
    yield delay(2000);
    yield put(authActions.verificationResetSuccess(false));
  } catch (error) {
    yield put(authActions.verificationResetFail({
      error: [
        {
          message: get(error, 'extensions.detailed', 'Internal error'),
        },
      ],
    }));
  }
}

function* getTokenFromCookiesWatcher() {
  yield takeLatest(authActionsTypes.GET_TOKEN_FROM_COOKIES, getTokenFromCookies);
}

function* setUserWatcher() {
  yield takeLatest(authActionsTypes.GET_COMMON_DATA, waitForAuthorization(getCommonData));
}

function* userLogoutWatcher() {
  yield takeLatest(authActionsTypes.USER_LOGOUT, userLogout);
}

function* userLoginWatcher() {
  yield takeLatest(authActionsTypes.USER_LOGIN, userLogin);
}

function* userVerificationWatcher() {
  yield takeLatest(authActionsTypes.USER_VERIFICATION, userVerification);
}

function* resetVerificationWatcher() {
  yield takeLatest(authActionsTypes.RESET_VERIFICATION, resetVerification);
}

export const appSagas = [
  getTokenFromCookiesWatcher,
  setUserWatcher,
  userLoginWatcher,
  userVerificationWatcher,
  userLogoutWatcher,
  resetVerificationWatcher,
];
