import gql from 'graphql-tag';

export const queries = {
  getResourceManagementReport: gql`
    query resourceManagementReport($devcenterIds: [Int], $month: Int, $year: Int) {
      resourceManagementReport(month: $month, year: $year, devcenterIds: $devcenterIds){
        onboardingRows {
          devstaffId
          fullname
          devcenter
          devcenterId
          currentDevcenterId
          onboardingStatus
          onboardingDate
          approved
          hasPassed
        }
        endOfProbationRows {
          devstaffId
          fullname
          devcenter
          devcenterId
          endOfProbationDate
          approved
        }
        contractExpirationRows {
          devstaffId
          fullname
          devcenter
          devcenterId
          contractExpirationDate
          employmentType
          statusNew
        }
        offboardingRows {
          devstaffId
          fullname
          devcenter
          devcenterId
          offboardingDate
          offboardingStatus
          currentDevcenterId
          approved
          hasPassed
        }
      }
    }
  `,
};
