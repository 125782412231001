import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const Legend = ({ title }) => (
  <div data-id="table-legend">
    <p className="legend">{title}</p>
  </div>
);

Legend.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Legend;
