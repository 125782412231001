import { COLUMN_TYPES } from 'core/constants';
import hash from 'hash-sum';
import { chunk, get, isNumber, set } from 'lodash';

import { hasProperty } from 'utils/helpers/objects';
import { sortArrayByIndex, stringCompareFunction } from 'utils/helpers/sorting';
import { getColumnIndexByTerm } from 'utils/helpers/tables';
import { getValidationSchema } from 'utils/validation';

export const getColumnsNames = (model) => model.map(({
  cssRules,
  withImage,
  columnName,
  headerProps,
  withoutControls,
  shouldntBeRendered,
}) => ({
  cssRules,
  withImage,
  shouldntBeRendered,
  type: withoutControls ? 'text' : 'orderButton',
  headerProps: {
    ...headerProps,
    isHeader: true,
    data: columnName,
    actionName: 'changeOrder',
    actionArguments: { orderBy: columnName },
  },
}));

export const tableDataParser = (row, { model, rowStatusGetter, index }) => ({
  ...rowStatusGetter(row, index),
  tableData: model.map(({ valueGetter }) => valueGetter(row, index)),
});

export const filtersChecker = ({ filters, currentRow, filtersTemplate = [] }) => !filters.some(([key, { selected = null }]) => {
  const activeFiltersTemplates = filtersTemplate.find((template) => get(template, 'storeKey', '') === key);
  const checkFilter = get(activeFiltersTemplates, 'checkFilter');
  return checkFilter({ currentRow, selected }, filters);
});

export const modelParser = (
  data,
  model,
  {
    idKey,
    groupBy,
    favorites,
    filters = [],
    additionalData,
    orderRules = {},
    rowStatusGetter,
    filtersTemplate,
    withForm = false,
    summaryTableDataGetter,
  }
) => {
  const {
    isReversed,
    orderBy = 'name',
    isSeparatedByFavorites,
    isDefault: isDefaultOrder,
  } = orderRules;

  const columnIndex = getColumnIndexByTerm('columnName', orderBy, model);
  const hasActiveFilters = !!filters.length;
  const defaultOnTop = [];
  const favoredRows = [];
  const unfavoredRows = [];
  const initialRows = [];
  const initialValues = {};

  let tableSummaryData = {};
  let groups;

  const setTableSummaryData = (updatedTableSummaryData = {}) => {
    tableSummaryData = {
      ...updatedTableSummaryData,
    };
  };
  const setGroups = (updatedGroups) => {
    groups = updatedGroups;
  };
  setTableSummaryData();
  if (Array.isArray(data)) {
    data.forEach((row, index) => {
      const rowId = row[idKey];
      const isFavored = favorites ? !!favorites[rowId] : false;
      const currentRow = {
        ...row,
        ...additionalData,
        isFavored,
      };
      const isSelected =
        !hasActiveFilters ||
        filtersChecker({
          filters,
          currentRow,
          model,
          isFavored,
          row,
          rowStatusGetter,
          filtersTemplate,
        });
      const rowData = isSelected ?
        tableDataParser(currentRow, {
          model,
          idKey,
          rowStatusGetter,
          index,
        }) :
        null;

      if (withForm) {
        const { initialValueName, initialValue } = rowData;

        if (initialValueName) {
          set(initialValues, initialValueName, initialValue);
        }
      }

      if (rowData) {
        if (summaryTableDataGetter) {
          summaryTableDataGetter({
            setTableSummaryData,
            tableSummaryData,
            hasActiveFilters,
            currentRow,
          });
        }

        if (groupBy) {
          groupBy({
            rowData,
            groups,
            row,
            orderBy,
            setGroups,
          });

          if (groups) {
            return null;
          }
        }
        if (row.isInitial) {
          return initialRows.push(rowData);
        }

        if (isFavored && isSeparatedByFavorites) {
          return favoredRows.push(rowData);
        }

        if (isDefaultOrder && rowData.isDefaultOnTop) {
          return defaultOnTop.push(rowData);
        }

        return unfavoredRows.push(rowData);
      }

      return null;
    });
  }

  sortArrayByIndex(favoredRows, columnIndex, isReversed);
  sortArrayByIndex(unfavoredRows, columnIndex, isReversed);
  sortArrayByIndex(defaultOnTop, columnIndex, isReversed);

  const resultsData = initialRows.concat(
    favoredRows,
    defaultOnTop,
    unfavoredRows
  );

  if (groups) {
    Object.values(groups)
      .sort((a, b) => stringCompareFunction(a.sortBy, b.sortBy, isReversed))
      .forEach(({ rows, subtotalRow }) => {
        resultsData.push(...sortArrayByIndex(rows, columnIndex, isReversed));
        if (subtotalRow) {
          const subtotalRowData = tableDataParser(subtotalRow, {
            model,
            idKey,
            rowStatusGetter,
          });

          resultsData.push(subtotalRowData);
        }
      });
  }

  const hasInitialContent = !!get(data, 'length', 0);
  const hasResultsContent = !!resultsData.length;

  if (tableSummaryData.withGrandTotal) {
    if (typeof tableSummaryData.withGrandTotal === 'function') {
      tableSummaryData.withGrandTotal({
        model,
        idKey,
        filters,
        resultsData,
        rowStatusGetter,
        tableDataParser,
        hasActiveFilters,
      });
    } else {
      const grandTotalRowData = tableDataParser(
        {
          ...tableSummaryData,
          isGrandTotal: true,
          hasActiveFilters,
        },
        {
          model,
          idKey,
          rowStatusGetter,
        }
      );

      resultsData.push(grandTotalRowData);
    }
  }

  setTableSummaryData({
    ...tableSummaryData,
    hasInitialContent,
    hasResultsContent,
  });

  return {
    initialValues,
    data: resultsData,
    tableSummaryData,
  };
};

export const parseDetails = (
  details,
  { isSingleTab, dataTemplate, additionalDataGetter },
  orderRules,
  filterConfigs,
  filters
) => {
  const tabHeadings = [];
  const tabData = [];
  const tabStylesTemplates = [];
  const additionalData = additionalDataGetter ?
    additionalDataGetter(details) :
    {};

  let accumulator = {};
  const setAccumulator = (updatedAccumulator) => {
    accumulator = updatedAccumulator;
  };

  const getBody = (item) => {
    const {
      type,
      content,
      isHidden,
      currentFilter,
      summaryTableDataGetter,
      groupBy,
      useAccumulator,
      ...itemArgs
    } = item;
    const defaultFiltersState = {
      activeFilters: [],
      hasActiveFilters: false,
    };
    const { activeFilters, hasActiveFilters } = Array.isArray(currentFilter) ?
      currentFilter.reduce(
        (
          {
            activeFilters: accumulatorActiveFilters,
            hasActiveFilters: accumulatorHasActiveFilters,
          },
          filter
        ) => {
          const {
            activeFilters: currentActiveFilters,
            hasActiveFilters: currentHasActiveFilters,
          } = get(filters, filter, defaultFiltersState);
          return {
            activeFilters: [
              ...accumulatorActiveFilters,
              ...currentActiveFilters,
            ],
            hasActiveFilters:
                currentHasActiveFilters || accumulatorHasActiveFilters,
          };
        },
        defaultFiltersState
      ) :
      get(filters, currentFilter, defaultFiltersState);
    const filtersTemplate = Array.isArray(currentFilter) ?
      currentFilter.reduce((acc, filter) => {
        const template = get(filterConfigs, filter, []);
        return [...acc, ...template];
      }, []) :
      get(filterConfigs, currentFilter, []);

    if (isHidden) {
      return null;
    }

    let parsedContent = [];
    let parsedDatatype = type;

    const getFormWithTableData = () => {
      const formWithTableData = parseTableData({
        content,
        activeFilters,
        filtersTemplate,
        details,
        orderRules,
        withForm: true,
        additionalData,
      });

      const initialValues = formWithTableData.reduce(
        (acc, { initialValues: tableInitialValues }) => ({
          ...acc,
          ...tableInitialValues,
        }),
        {}
      );

      return {
        initialValues,
        tableData: formWithTableData,
      };
    };

    switch (type) {
      case 'info-card':
        parsedContent = {
          ...content,
        };
        break;
      case 'data-list':
        parsedContent = content.map(({ content: listContent, isInfoCard, ...contentRest }) => ({
          listData: isInfoCard ?
            listContent :
            parseFormData({
              content: listContent,
              details,
            }),
          isInfoCard,
          ...contentRest,
        }));
        break;
      case 'form':
        parsedContent = parseFormData({
          content,
          details,
        });
        break;

      case 'table':
        parsedContent = parseTableData({
          groupBy,
          content,
          details,
          orderRules,
          filtersTemplate,
          activeFilters,
          additionalData,
          summaryTableDataGetter,
        });
        break;

      case 'form-with-table':
        parsedDatatype = 'form';
        parsedContent = getFormWithTableData();
        break;
      default:
        break;
    }

    return {
      ...itemArgs,
      hasActiveFilters,
      currentFilter,
      type: parsedDatatype,
      content: useAccumulator ?
        useAccumulator({
          parsedContent,
          accumulator,
          setAccumulator,
          hasActiveFilters,
        }) :
        parsedContent,
      filterConfig: filtersTemplate,
    };
  };

  if (!isSingleTab) {
    dataTemplate.forEach(({ tabHeader = {}, tabBody, stylesTemplate = {} }) => {
      const { labels = [], ...tabHeaderRest } = tabHeader;
      const parsedLabels = labels.map(({ valueGetter }) => valueGetter(details));
      const parsedBody = tabBody.map(getBody);

      tabStylesTemplates.push(stylesTemplate);
      tabHeadings.push({
        labels: parsedLabels,
        ...tabHeaderRest,
      });
      tabData.push(parsedBody.filter((item) => item));
    });

    return {
      ...details,
      ...additionalData,
      tabStylesTemplates,
      tabHeadings,
      tabData,
    };
  }

  return {
    ...additionalData,
    body: dataTemplate.map(getBody),
  };
};

const parseTableData = ({
  content,
  details,
  orderRules,
  activeFilters,
  filtersTemplate,
  withForm = false,
  additionalData = {},
  summaryTableDataGetter,
  groupBy,
}) => content.map((
  {
    title,
    idKey,
    dataKey,
    rowStatusGetter,
    rules,
    dataTemplate,
    tableName,
    preventScrolling,
    splitColumnsConfig,
    ...rest
  },
  index
) => ({
  title,
  tableName,
  splitColumnsConfig,
  preventScrolling,
  model: {
    rules,
    dataTemplate,
  },
  ...rest,
  ...modelParser(get(details, dataKey, []), dataTemplate, {
    idKey,
    rowStatusGetter,
    additionalData,
    orderRules: get(orderRules, tableName, {}),
    withForm,
    index,
    filters: activeFilters,
    filtersTemplate,
    summaryTableDataGetter,
    groupBy,
  }),
}));

const getInitialValue = (fieldData) => {
  if (fieldData && hasProperty(fieldData, 'selected')) {
    const { selected } = fieldData;

    if (Array.isArray(selected)) {
      return selected.map(({ value }) => value);
    }

    return hasProperty(selected, 'value') ? selected.value : selected;
  }

  if (fieldData && hasProperty(fieldData, 'value')) {
    return fieldData.value;
  }

  return fieldData;
};

export const parseFormData = ({ content, details }) => {
  const initialValues = {};
  const validationRules = {};
  const parseFieldData = (field, index) => {
    const {
      validationRules: validationConfig = {},
      name,
      getIsUnitLocked,
      valueGetter = () => null,
      value,
      type,
    } = field;
    const fieldData = value !== undefined ? value : valueGetter(details, index);
    const initialValue = getInitialValue(fieldData);

    if (name) {
      set(initialValues, name, initialValue);
    }

    if (type === 'virtualField') {
      return null;
    }

    const { schemaGetter } = validationConfig;

    const schema = schemaGetter ? schemaGetter(fieldData) : null;

    if (schema && name) {
      const schemaKey = name.split('.')[0];

      validationRules[schemaKey] = schema;
    }

    return {
      ...field,
      fieldData,
      isLocked: getIsUnitLocked ? getIsUnitLocked(details) : false,
    };
  };

  const parsedFormData = [];

  content.forEach(({ formData, ...contentArgs }, index) => {
    const parsedData = [];

    if (formData) {
      formData.forEach((field) => {
        if (field.isMultiple) {
          const multiUnitData = [];

          field.formData.forEach((subfield) => {
            const unit = parseFieldData(subfield, index);

            if (unit) {
              multiUnitData.push(unit);
            }
          });

          parsedData.push({
            ...field,
            data: multiUnitData,
          });
        } else {
          const unit = parseFieldData(field, index);
          if (unit) {
            parsedData.push(unit);
          }
        }
      });
    }

    if (parsedData.length) {
      parsedFormData.push({
        ...contentArgs,
        data: parsedData,
      });
    }
  });

  return {
    formData: parsedFormData,
    initialValues,
    validationRules: getValidationSchema(validationRules),
  };
};

export const parseCommissionFormData = ({ content, details }) => {
  const reportsToList = {};
  details.forEach((item) => {
    reportsToList[item.userId] = item.fullName;
  });

  const parseFieldData = (
    field,
    index,
    detail = {},
    initialValues,
    validationRules,
    reportersList,
    userId
  ) => {
    const {
      validationRules: validationConfig = {},
      name,
      getIsUnitLocked,
      valueGetter = () => null,
      value,
      type,
    } = field;

    const fieldData =
      value !== undefined ? value : valueGetter(detail, reportersList, userId);
    const initialValue = getInitialValue(fieldData);

    if (name) {
      set(initialValues, name, initialValue);
    }

    if (type === 'virtualField') {
      return null;
    }

    const { schemaGetter } = validationConfig;

    const schema = schemaGetter ? schemaGetter(initialValues) : null;

    if (schema && name) {
      const schemaKey = name.split('.')[0];

      // eslint-disable-next-line no-param-reassign
      validationRules[schemaKey] = schema;
    }

    return {
      ...field,
      fieldData,
      isLocked: getIsUnitLocked ? getIsUnitLocked(detail) : false,
    };
  };

  const parsedFullFormData = [];
  Array.from(details).forEach((detail, index) => {
    const parsedFormData = [];
    const initialValues = {};
    const validationRules = {};

    content.forEach(({ formData, ...contentArgs }) => {
      const parsedData = [];
      if (formData) {
        formData.forEach((field) => {
          const unit = parseFieldData(
            field,
            index,
            detail.records,
            initialValues,
            validationRules,
            reportsToList,
            detail.userId
          );

          if (unit) {
            parsedData.push(unit);
          }
        });
      }

      if (parsedData.length) {
        parsedFormData.push({
          ...contentArgs,
          data: parsedData,
        });
      }
    });
    parsedFullFormData.push({
      formData: parsedFormData,
      initialValues,
      validationRules: getValidationSchema(validationRules),
    });
  });

  return parsedFullFormData;
};

export const filtersConfigGetter = (
  filters,
  filterItems,
  { filtersTemplate }
) => filtersTemplate.map((column) => {
  const {
    storeKey,
    isReversed,
    sortingComparator,
    itemsOrder,
  } = column;

  let filterConfig = {};

  if (filters[storeKey]) {
    const {
      isActive,
      selected = null,
    } = filters[storeKey];
    const currentFilterItems = filterItems[storeKey];

    filterConfig = {
      isActive,
      selected,
      storeKey,
    };

    if (currentFilterItems) {
      if (itemsOrder) {
        filterConfig.items = itemsOrder.reduce((acc, itemName) => {
          if (
            Object.values(currentFilterItems).some((key) => key === itemName)
          ) {
            return [...acc, itemName];
          }
          return acc;
        }, []);
      } else {
        const sortedItems =
            Object.values(currentFilterItems).sort(sortingComparator);
        filterConfig.items = isReversed ? sortedItems.reverse() : sortedItems;
      }
    }
  }
  return {
    ...column,
    filterConfig,
  };
});

export const getChunkedData = ({ data, chunksNumber, chunkIndex }) => {
  const chunkSize = Math.ceil(data.length / chunksNumber);
  return chunk(data, chunkSize)[chunkIndex] || [];
};

export const splitColumnsConfigGetter = ({
  isMultiColumnPredicate,
  columnsNumber = 1,
  cssRules = '',
}) => {
  const res = {};
  const currentColumnNumber = isMultiColumnPredicate() ? columnsNumber : 1;
  res.columnsNumber = currentColumnNumber;

  res.cssRules = `
    grid-template-columns: ${'1fr '.repeat(currentColumnNumber).trim()};
    ${cssRules}
  `;

  return res;
};

export const filtersItemsGetter = (datas, { filtersTemplate }, filters) => {
  if (!Array.isArray(datas)) {
    return {};
  }

  const templates = filtersTemplate.filter((column) => column.type === COLUMN_TYPES.SELECT);

  // Filter items consist of { [storeKey]: items }.
  return templates.reduce(
    (filterItems, template) => ({
      ...filterItems,
      // Items consist of { hash(value): value }.
      [template.storeKey]: datas.reduce((items, data) => {
        const values = template.valueGetter(data, filters);

        const setValue = (value) => {
          if (value != null) {
            items[hash(value)] = value; // eslint-disable-line no-param-reassign
          }
        };

        if (Array.isArray(values)) {
          values.forEach(setValue);
        } else {
          setValue(values);
        }

        return items;
      }, {}),
    }),
    {}
  );
};

export const getIsFilterActive = (isActive, selected, type) => {
  switch (type) {
    case 'toggle':
    case 'checkbox':
      return !isActive;
    case 'range':
      const minValue = get(selected, 'min'); // eslint-disable-line no-case-declarations
      const maxValue = get(selected, 'max'); // eslint-disable-line no-case-declarations

      return isNumber(minValue) || isNumber(maxValue); // (minValue === 0 || !minValue === false) || (maxValue === 0 || !maxValue === false);
    default:
      if (Array.isArray(selected)) {
        return !!get(selected, 'length');
      }
      return !!selected;
  }
};
