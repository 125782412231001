import gql from 'graphql-tag';

export const queryConfig = {
  getUtilizationReport: gql`query utilizationReport($devcenterIds: [Int] $month: Int $year: Int) {
    utilizationResources(month: $month year: $year, devcenterIds: $devcenterIds) {
      resourceRows {
        resource
        fullname
        details {
          logDate
          logHours
          billType
          projectId
          projectName
          projectType
          taskOrder
          isActive
        }
        total
      }
      summaryRows {
        name
        description
        hours
        percentOfLogged
        percentOfMonth
      }
    },
  }`,
};
