import {
  GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS,
  GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS_FAIL,
  GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS_SUCCESS,
  ADD_RESOURCE_MANAGEMENT_CONTACT_DATA,
  CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA,
  CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA_FAIL,
  CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA_SUCCESS,
  DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA,
  DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA_FAIL,
  DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA_SUCCESS,
  REMOVE_RESOURCE_MANAGEMENT_CONTACT_DATA,
  UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA_SUCCESS,
  UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA,
  UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA_FAIL,
} from 'core/delivery/contactManagement/actions';
import {
  pathToState,
  recordIdName,
} from 'core/delivery/contactManagement/constants';
import { FORM_STATE } from 'core/utils';
import update from 'immutability-helper';
import { newContactData } from 'layouts/employee-details/components/resource-tabs/contacts/constants';
import { get } from 'lodash';

export const contactDataManagementReducerCases = {
  [GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS](state) {
    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          isFetchingContactDataManagementRecords: {
            $set: true,
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS_SUCCESS](state, { payload: {
    records,
    memoDevstaffId,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          isFetchingContactDataManagementRecords: {
            $set: false,
          },
          contactDataManagementRecords: {
            $set: records,
          },
          formState: {
            $set: {
              status: FORM_STATE.INITIAL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
          memoDevstaffId: {
            $set: memoDevstaffId,
          },
          length: {
            $set: records.length,
          },
        },
      },
    });
  },
  [GET_RESOURCE_MANAGEMENT_CONTACT_DATA_RECORDS_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          isFetchingContactDataManagementRecords: {
            $set: false,
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
  [CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA](state) {
    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA_SUCCESS](state, { payload: {
    record,
  } }) {
    const contactDataManagementRecords =
      get(state, pathToState, []);

    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          length: {
            $set: contactDataManagementRecords.length,
          },
          contactDataManagementRecords: {
            $set: contactDataManagementRecords.map((currentRecord) => {
              if (currentRecord[recordIdName] === newContactData({})[recordIdName]) {
                return record;
              }

              return currentRecord;
            }),
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [CREATE_RESOURCE_MANAGEMENT_CONTACT_DATA_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: true,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },

  [UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA](state) {
    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },
  [UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA_SUCCESS](state, { payload: {
    record,
  } }) {
    const contactDataManagementRecords = get(state, pathToState, [])
      .map((currentRecord) => currentRecord[recordIdName] === record[recordIdName] ? record : currentRecord);

    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          contactDataManagementRecords: {
            $set: contactDataManagementRecords,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [UPDATE_RESOURCE_MANAGEMENT_CONTACT_DATA_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: true,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },

  [DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA](state) {
    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTING,
              isSubmitting: true,
              isSubmittedSuccess: false,
              isSubmittedFail: false,
            },
          },
        },
      },
      errors: {
        $set: {},
      },
    });
  },

  [DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA_SUCCESS](state, { payload }) {
    const contactDataManagementRecords = get(state, pathToState, [])
      .filter((record) => record[recordIdName] !== payload);

    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          contactDataManagementRecords: {
            $set: contactDataManagementRecords,
          },
          length: {
            $set: contactDataManagementRecords.length,
          },
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_SUCCESS,
              isSubmitting: false,
              isSubmittedSuccess: true,
              isSubmittedFail: false,
            },
          },
        },
      },
    });
  },
  [DELETE_RESOURCE_MANAGEMENT_CONTACT_DATA_FAIL](state, { payload: {
    errors,
  } }) {
    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          formState: {
            $set: {
              status: FORM_STATE.SUBMITTED_FAIL,
              isSubmitting: false,
              isSubmittedSuccess: false,
              isSubmittedFail: true,
            },
          },
        },
      },
      errors: {
        $merge: errors,
      },
    });
  },
  [ADD_RESOURCE_MANAGEMENT_CONTACT_DATA](state, { payload }) {
    const contactDataManagementRecords = get(state, pathToState, []);

    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          contactDataManagementRecords: {
            $set: [payload, ...contactDataManagementRecords],
          },
        },
      },
    });
  },
  [REMOVE_RESOURCE_MANAGEMENT_CONTACT_DATA](state) {
    const contactDataManagementRecords = get(state, pathToState, []);

    return update(state, {
      employeeDetailsTabs: {
        contacts: {
          contactDataManagementRecords: {
            $set: contactDataManagementRecords.filter((record) => record[recordIdName] !== newContactData({})[recordIdName]),
          },
        },
      },
    });
  },
};
