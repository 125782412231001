import {
  colorPrimary,
  colorSecondaryGray,
  colorWhite,
} from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import { deliveryRootModuleAccess } from 'core/auth/guaranteedAccessRoles';
import { STORE_KEYS } from 'core/billing-reports/constants';
import { getHasPermissions } from 'utils/auth';

import analysisTabGetter from './analysis-tab';
import { filtersTemplates } from './config/filters';
import staffTabGetter, { staffTotalUSDLabelsGetter } from './staff-tab';
import summaryTabGetter from './summary-tab';

export const annualReportModelGetter = (
  userGroup,
  renderedMonthColumns,
  inDepthStudyAnalysisTab,
  inDepthStudySummaryTab,
  currency,
  isStaffEmpty,
  isAnalysisEmpty,
  isSummaryEmpty,
  activeFilters,
  availableDevCentersForDd,
) => {
  const isCTO = getHasPermissions(userGroup, [USERS_GROUPS.CTO]);
  const hasPermissionsTODeliveryModule = getHasPermissions(userGroup, deliveryRootModuleAccess);

  const controlButtonStyles = `
    width: 100%;
    padding: 0.7rem 2.4rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
    text-align: center;
    color: ${colorSecondaryGray};
    border-color: ${colorSecondaryGray};
    background-color: transparent;
    &:hover {
      color: ${colorWhite};
      background-color: ${colorPrimary};
    }
  `;

  return ({
    filtersTemplates,
    additionalDataGetter: () => ({
      controlsHeading: 'Annual Payroll Report',
      currencyFilterItems: [{
        value: STORE_KEYS.USD,
        label: 'USD',
      }, {
        value: STORE_KEYS.RUR,
        label: 'RUR',
      }, {
        value: STORE_KEYS.PLN,
        label: 'PLN',
      }],
      staffFilterItems: [{
        value: null,
        label: 'All Staff',
      }, {
        value: STORE_KEYS.ADMIN_STAFF,
        label: 'Administrative',
      }, {
        value: STORE_KEYS.DEV_STAFF,
        label: 'Delivery Staff',
      }],
      yearPickerCssRules: `
          && {
            display: flex;
            min-width: unset;
            align-items: center;
            margin-right: 2.3rem;
            padding-top: 0;
            border: none;
            & path {
              fill: ${colorPrimary};
            }
            .calendar__buttons {
              margin-right: 1.6rem;
              .calendar-date-picker__header-control {
                margin-left: 0;
                margin-right: 0.8rem;
              }
            }
            .calendar__toggle-button {
              padding: 0.3rem 0.8rem 0 0.8rem;
            }
          }
        `,
      calendarItemCssRules: `
          padding-top: 0.3rem;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.7rem;
          color:  ${colorPrimary};
        `,
      controlsCssRules: `
          &&& {
            display: grid;
            grid-template-columns: 1fr repeat(3, minmax(auto, 15.2rem));
            grid-column-gap: 2.4rem;
            grid-row-gap: 0.8rem;
            grid-auto-rows: minmax(3.2rem, auto);
            flex-direction: column;
            padding-top: 0.8rem;
            font-size: 1.5rem;
          }
        `,
      controlsWrapperCssRules: `
          display: flex;
          grid-area: 2 / 1 / 2 / 5;
        `,
      selectCssRules: `
          && {
            width: unset;
            margin-right: 3.2rem;
            color: ${colorPrimary};
            .filter-select {
              width: auto;
              height: auto;
              &__single-value {
                margin-top: 0.2rem;
              }
              &__multi-value__label {
                color: ${colorPrimary};
              }
              &__placeholder {
                width: 100%;
                padding-left: 0;
                color: ${colorPrimary};
                font-size: 1.6rem;
              }
              &__control--is-focused {
                outline: none !important;
              }
              &__dropdown-indicator {
                path {
                  fill: ${colorPrimary};
                }
              }
            }
          }
        `,
      controls: [{
        type: 'action',
        data: 'Salary',
        withBody: true,
        onClick: ({ actions }) => {
          actions.generateAnnualPayrollSalaryReport();
        },
        cssRules: `
          && {
            ${controlButtonStyles};
          }
        `,
      }, {
        type: 'action',
        data: 'PDF',
        withBody: true,
        onClick: ({ actions }) => {
          actions.generateAnnualPayrollReport();
        },
        cssRules: `
          && {
            ${controlButtonStyles};
            color: ${colorPrimary};
            border-color: ${colorPrimary};
          }
        `,
      }, {
        type: 'action',
        data: 'Excel',
        withBody: true,
        onClick: ({ actions }) => {
          actions.generateAnnualPayrollExcelReport();
        },
        cssRules: `
          && {
            ${controlButtonStyles};
            color: ${colorPrimary};
            border-color: ${colorPrimary};
          }
        `,
      }],
    }),
    dataTemplate: [{
      tabHeader: {
        title: 'Staff',
        labels: staffTotalUSDLabelsGetter(currency),
      },
      stylesTemplate: {
        wrapperCssRules: 'padding: 1.6rem 4rem 10rem;',
      },
      tabBody: staffTabGetter({
        currency,
        renderedMonthColumns,
        hasPermissionsTODeliveryModule,
        activeFilters,
        isEmpty: isStaffEmpty,
        availableDevCentersForDd,
        isCTO,
      }),
    }, {
      tabHeader: {
        title: 'Analysis',
        labels: [],
      },
      stylesTemplate: {
        wrapperCssRules: 'padding: 1.6rem 4rem 10rem;',
      },
      tabBody: analysisTabGetter({
        currency,
        inDepthStudyAnalysisTab,
        isEmpty: isAnalysisEmpty,
      }),
    }, {
      tabHeader: {
        title: 'Summary',
        labels: [],
      },
      stylesTemplate: {
        wrapperCssRules: 'padding: 1.6rem 4rem 10rem;',
      },
      tabBody: summaryTabGetter({
        currency,
        inDepthStudySummaryTab,
        isEmpty: isSummaryEmpty,
      }),
    }],
  });
};
