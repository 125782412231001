import React, { Children, useState } from 'react';

import PropTypes from 'prop-types';

const TabGroup = ({ defaultIndex, children, className, viewTab }) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);

  const handleTabClick = (index) => {
    setActiveIndex(index);
  };

  const renderTabs = () => Children.map(children, (child, index) => {
    const isActive = index === activeIndex;

    return viewTab[child.props.accessor] && React.cloneElement(child, {
      isActive,
      onClick: () => handleTabClick(index),
      title: child.props.title,
    });
  });

  const renderTabPanels = () => Children.map(children, (child, index) => {
    if (child && index === activeIndex) {
      return <div role="tabpanel">{child.props.children}</div>;
    }
    return null;
  });

  return (
    <div className={className}>
      <div role="tablist">{renderTabs()}</div>
      {renderTabPanels()}
    </div>
  );
};

TabGroup.Tab = ({ title, length, isActive, onClick }) => (
  <button
    title={title}
    onClick={onClick}
    data-active={isActive}
  >{title}{length || length === 0 ? ` (${length})` : null}
  </button>
);

TabGroup.propTypes = {
  children: PropTypes.node.isRequired,
  defaultIndex: PropTypes.number.isRequired,
  className: PropTypes.string,
  viewTab: PropTypes.shape({}),
};

TabGroup.Tab.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  title: PropTypes.string.isRequired,
  length: PropTypes.number,
  id: PropTypes.number,
  accessor: PropTypes.string,
};

TabGroup.defaultProps = {
  className: '',
};

TabGroup.Tab.defaultProps = {
  onClick: () => {},
  isActive: false,
  length: null,
};

export default TabGroup;
