import React, { memo } from 'react';

import { ReactComponent as IconArrowEnd } from 'assets/icons/arrow-end.svg';
import { ReactComponent as IconArrow } from 'assets/icons/arrow.svg';

import ActionButton from 'elements/action-button';
import ReactPaginate from 'react-paginate';

import styles from './styles.module.scss';

export const Pagination = memo(({ pageCount, handlePageStart, handlePageClick, handlePageEnd, forcePage }) =>
  (
    <div className={styles.pagination}>
      <div className={styles.item} >
        <ActionButton data={<IconArrowEnd className={styles.startIcon} />} onClick={handlePageStart} />
      </div>
      <ReactPaginate
        breakLabel="..."
        className={styles.list}
        forcePage={forcePage}
        pageClassName={styles.item}
        activeClassName={styles.active}
        previousClassName={styles.item}
        nextClassName={styles.item}
        nextLabel={<IconArrow className={styles.next} />}
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel={<IconArrow className={styles.prev} />}
        renderOnZeroPageCount={null}
      />
      <div className={styles.item} >
        <ActionButton data={<IconArrowEnd />} onClick={handlePageEnd} />
      </div>
    </div>
  ));
