import React, { useState, useEffect, useCallback } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import Calendar from 'components/calendar';
import DetailsForm from 'components/details-form';
import SingleTable from 'components/details-table/single-table';
import FilterSelect from 'components/filter-select';
import Controls from 'components/list-controls';
import Loader from 'components/loader';
import { USERS_GROUPS } from 'core/auth/constants';
import { configurationsActions } from 'core/configurations/actions';
import {
  selectHolidays,
  selectHolidaysData,
  selectHolidaysListModel,
  selectHolidaysDetails,
  selectCreateMode,
} from 'core/configurations/selectors';
import StyledWrapper from 'elements/styled-wrapper';
import Wrapper from 'elements/wrapper';
import { useHandleScroll } from 'layouts/hiqo-contracts/useHandleScroll';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { getHasPermissions } from 'utils/auth';

import { format as formatDate, FORMATS } from 'utils/date';

import {
  filtersWrapperCssRules,
  controlsCssRules,
  calendarCssRules,
  calendarItemCssRules,
  filterSelectCssRules,
  addButtonStyles,
  wrapperCssRules,
  formWrapperStyles,
  loader,
} from './styles';

const { CPS } = USERS_GROUPS;

const calendarStepControlsConfig = {
  step: 1,
  unitName: 'year',
};
const calendarPopperProps = {
  placement: 'bottom-start',
};

const devCentersGetOptionValue = (option) => option.value;
const devCentersGetOptionLabel = (option) => option.label;

const NoneWorkingDaysManagement = ({
  entityName,
  getHolidays,
  deliveryCenters,
  isFetching,
  isFormSubmitted,
  orderRules,
  changeOrder,
  holidaysData,
  holidaysListModel,
  holidaysDetails,
  createMode,
  setCreateMode,
  setDevcenterFilter,
  createHolidays,
  changeCurrentModal,
  removeHolidaysRecord,
  userGroup,
}) => {
  const [date, setDate] = useState(moment());

  const isCPS = getHasPermissions(userGroup, [CPS]);

  const onSelectDevCenter = (devcenterList) => setDevcenterFilter({ devcenterList });

  const changeYear = (calendarValue) => {
    setDate(calendarValue);
    getHolidays({ date: calendarValue.year() });
    setCreateMode({ createMode: false });
  };
  const onSubmit = ({ fields }) => createHolidays({ fields });
  const additionalActions = {
    removeHolidaysRecord,
    setCreateMode,
    openModal: ({ currentModal, ...args }) => changeCurrentModal({ currentModal, params: { ...args } }),
  };

  const handleAddHoliday = useCallback(
    () => {
      if (createMode) {
        return;
      }

      setCreateMode({
        createMode: true,
      });
    },
    [createMode],
  );

  useHandleScroll({
    scrollUp: !createMode,
    scrollDown: createMode,
  });

  useEffect(() => {
    if (deliveryCenters) {
      getHolidays({ date: moment().year() });
    }

    return () => {
      setCreateMode({ createMode: false });
    };
  }, []);

  return (
    <>
      <Controls
        title="Non-Working Days Management"
        cssRules={controlsCssRules}
        controlsList={[
          {
            type: isCPS ? '' : 'action',
            data: 'Add holiday',
            onClick: handleAddHoliday,
            disabled: createMode,
            cssRules: `
                ${addButtonStyles}
                margin-right: auto;
              `,
          },
        ]}
      >
        <StyledWrapper cssRules={filtersWrapperCssRules}>
          <Calendar
            withStepControls
            withMonthSelecting
            minDetails="decade"
            minYear={2011}
            currentDate={moment()}
            onChange={changeYear}
            withYearSelecting
            stepControlsConfig={calendarStepControlsConfig}
            cssRules={calendarCssRules}
            value={date}
            popperProps={calendarPopperProps}
          >
            <StyledWrapper cssRules={calendarItemCssRules}>
              {formatDate(date, FORMATS.YYYY)}
            </StyledWrapper>
          </Calendar>
          <FilterSelect
            placeholderLength="16.5rem"
            placeholder="All Delivery Centers"
            onChange={onSelectDevCenter}
            items={deliveryCenters.forSelect}
            cssRules={filterSelectCssRules}
            getOptionValue={devCentersGetOptionValue}
            getOptionLabel={devCentersGetOptionLabel}
            isMultiple
            withChips={false}
          />
        </StyledWrapper>
      </Controls>
      <StyledWrapper cssRules={loader}>
        <Loader isLoaded={!isFetching}>
          <Wrapper cssRules={wrapperCssRules}>
            <SingleTable
              entityName={entityName}
              data={holidaysData}
              model={holidaysListModel}
              orderRules={orderRules}
              changeOrder={changeOrder}
              tableName="holidays"
              isFetching={false}
              withoutLoader
              controls={[]}
              noDataMessage="No non-working days in selected period or delivery center"
              unitActions={additionalActions}
            />
            {createMode && (
              <DetailsForm
                stylesTemplate={formWrapperStyles}
                isFetchingDetails={isFetching}
                content={holidaysDetails}
                onSubmit={onSubmit}
                isFormSubmitted={isFormSubmitted}
                additionalActions={additionalActions}
              />
            )}
          </Wrapper>
        </Loader>
      </StyledWrapper>
    </>
  );
};

NoneWorkingDaysManagement.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  getHolidays: PropTypes.func.isRequired,
  deliveryCenters: PropTypes.shape({}).isRequired,
  holidaysData: PropTypes.array.isRequired,
  holidaysListModel: PropTypes.shape({}).isRequired,
  holidaysDetails: PropTypes.shape({}).isRequired,
  createMode: PropTypes.bool.isRequired,
  setCreateMode: PropTypes.func.isRequired,
  setDevcenterFilter: PropTypes.func.isRequired,
  createHolidays: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  removeHolidaysRecord: PropTypes.func.isRequired,
};

NoneWorkingDaysManagement.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  holidays: selectHolidays,
  holidaysData: selectHolidaysData,
  holidaysListModel: selectHolidaysListModel,
  holidaysDetails: selectHolidaysDetails,
  createMode: selectCreateMode,
});

const mapDispatchToProps = {
  getHolidays: configurationsActions.getHolidays,
  setCreateMode: configurationsActions.setCreateMode,
  setDevcenterFilter: configurationsActions.setDevcenterFilter,
  createHolidays: configurationsActions.createHolidays,
  removeHolidaysRecord: configurationsActions.removeHolidaysRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoneWorkingDaysManagement);
