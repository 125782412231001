import {
  colorSecondaryGrayDark,
  colorSecondaryBackgroundLight,
  colorSecondaryBackgroundLightDarken10,
  colorWhite,
  colorPrimary,
} from './variables';

export const formViewboxCss = `
  justify-content: flex-end;
  align-items: flex-end;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  font-size: 1.3rem;
  margin-top: 0.5rem;
  height: 4rem;
  padding-bottom: 1px;
`;

export const billingInfoTabFormViewboxCss = `
  align-items: flex-end;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  font-size: 1.3rem;
  margin-top: 0.5rem;
  height: 4.2rem;
  padding-bottom: 1px;
`;

// TODO: refactor ActionButton component to get rid of using && for bumping selectors specificity
export const removeButtonCss = `
  && {
    line-height: 2.4rem;
    letter-spacing: -0.025rem;
    font-size: 1.2rem;
    padding-left: 2.4rem;
    padding-top: 1.6rem;
    height: 4.5rem;
  }
`;

// TODO: refactor ActionButton component to get rid of using && for bumping selectors specificity
export const addButtonCss = `
  && {
    box-sizing: border-box;
    width: calc((136rem - 4.8rem * 4 - 3.2rem) / 5);
    padding: 0.7rem 1.2rem;
    color: ${colorSecondaryGrayDark};
    line-height: 1.6rem;
    letter-spacing: 0.0125rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    border: 1px solid ${colorSecondaryGrayDark};
  }

  &:hover {
    border: 1px solid #2C2C2C;
    color: #3B3B3B;
  }`;

export const actionButtonCss = `
  && {
    box-sizing: border-box;
    width: calc((136rem - 4.8rem * 4 - 3.2rem) / 5);
    padding: 0.7rem 1.2rem;
    color: ${colorWhite};
    line-height: 1.6rem;
    letter-spacing: 0.0125rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    border: 1px solid ${colorPrimary};
    background-color: ${colorPrimary};
  }`;

export const formHeaderCss = `
  line-height: 2.1rem;
  text-align: right;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1.6rem 0;`;

export const staffingFormTextInputCss = 'font-size: 1.3rem; text-align: right;';

export const formSelectCss = 'font-size: 1.3rem;';

export const showFormCss = `${addButtonCss}
&& {
  align-self: flex-end;
  width: calc(100% - 1.6rem);
  max-height: 3.2rem;
  margin: 1.9rem 0 0;
}`;

export const rateCardInputFieldCSS = `
margin-top: 0.2rem;
font-size: 1.4rem;
text-align: right;
& > input {
  padding-right: 0.8rem;
}`;

export const managePtoRemoveButtonCss = `
  && {
    height: 4.2rem;
    padding-top: 2.7rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.0015rem;
  }`;

export const managePtoAddButtonCss = `
  && {
    height: '100%';
    width: 25.2rem;
    color: ${colorSecondaryGrayDark};
    line-height: 1.6rem;
    letter-spacing: 0.0125rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    border: 1px solid ${colorSecondaryGrayDark};
  }

  &:hover {
    border: 1px solid #d61f26;
    color: #fff;
  }
`;

export const managePtoFieldArrayCss = `
  box-sizing: border-box;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 3.6rem;

  position: relative;
  max-height: calc(78vh - 40rem);
  overflow-y: auto;
  padding-right: 0.8rem;

  scrollbar-color: ${colorSecondaryBackgroundLightDarken10} ${colorSecondaryBackgroundLight};
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    position: absolute;
    width: 5px;

    &-track {
      background-color: ${colorSecondaryBackgroundLight};
      box-shadow: none;
    }

    &-thumb {
      min-height: auto;
      border-radius: 5px;
      background-color: ${colorSecondaryBackgroundLightDarken10};
    }
  }
`;
