import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import ToastContainer from 'components/toast';
import { push } from 'connected-react-router';

import {
  selectUserGroup,
  selectHasPermissionTaskOrdersDocumentGenerate,
  selectHasPermissionDocumentUpload,
  selectHasPermissionsCreateTaskOrder,
  selectHasPermissionTaskOrderDetailsHeadingActions,
  selectIsSales,
} from 'core/auth/selectors';
import { commonActions } from 'core/common/actions';

import { filesActions } from 'core/files/actions';
import {
  selectUploadingProgress,
  selectIsFileUploaded,
} from 'core/files/selectors';
import { taskOrdersActions } from 'core/task-orders/actions';
import {
  selectedTaskOrdersData,
  selectIsFetchingTaskOrdersList,
  selectOrderRules,
  selectIsFilteringTaskOrdersList,
  selectFilterConfig,
  selectHasFilters,
  selectTaskOrdersDetails,
  selectIsFetchingDetails,
  selectTaskOrdersCount,
  selectIsFavoredTaskOrder,
  selectIsTaskOrderDetailsFormSubmitted,
  selectEntityName,
  selectIsFetchingFiles,
  selectFilterByExpiration,
  selectFilterByObsoleteRates,
  selectTaskOrdersListModel,
} from 'core/task-orders/selectors';
import TaskOrderDetails from 'layouts/task-order-details';
import TaskOrdersList from 'layouts/task-orders-list';
import compose from 'lodash/fp/compose';
import { Switch, Route, Redirect, withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

const TaskOrders = (props) => {
  const { entityName, setEntityName } = props;
  useEffect(() => {
    setEntityName(entityName);
  }, [entityName]);

  return (
    <>
      <ToastContainer containerId={entityName} />

      <Switch>
        <Route
          exact
          path="/task-orders"
          render={() => <TaskOrdersList {...props} />}
        />

        <Route
          exact
          path="/task-orders/add"
          render={() => {
            const { hasPermissionsCreateTaskOrder } = props;

            return hasPermissionsCreateTaskOrder ? (
              <TaskOrderDetails isNewTaskOrder {...props} />
            ) : (
              <Redirect to="/task-orders" />
            );
          }}
        />

        <Route
          path="/task-orders/:id/:tab"
          render={({ match: { params } }) => {
            const { id, tab } = params;

            return <TaskOrderDetails projectId={id} tab={tab} {...props} />;
          }}
        />

        <Redirect to="clients" />
      </Switch>
    </>
  );
};

TaskOrders.propTypes = {
  entityName: PropTypes.string.isRequired,
  hasPermissionsCreateTaskOrder: PropTypes.bool.isRequired,
  setEntityName: PropTypes.func.isRequired,
  userGroup: PropTypes.string,
  guaranteedAccessRoles: PropTypes.arrayOf(PropTypes.string),
};

TaskOrders.defaultProps = {
  userGroup: '',
  guaranteedAccessRoles: [],
};

const mapStateToProps = createStructuredSelector({
  isFetchingFiles: selectIsFetchingFiles,
  entityName: selectEntityName,
  taskOrdersData: selectedTaskOrdersData,
  isFetchingTaskOrdersList: selectIsFetchingTaskOrdersList,
  isFilteringTaskOrderList: selectIsFilteringTaskOrdersList,
  orderRules: selectOrderRules,
  filterConfig: selectFilterConfig,
  hasActiveFilters: selectHasFilters,
  taskOrdersDetails: selectTaskOrdersDetails,
  isFetchingDetails: selectIsFetchingDetails,
  taskOrdersCount: selectTaskOrdersCount,
  isFavoredTaskOrder: selectIsFavoredTaskOrder,
  hasPermissionDocumentGenerate: selectHasPermissionTaskOrdersDocumentGenerate,
  hasPermissionDocumentUpload: selectHasPermissionDocumentUpload,
  isSales: selectIsSales,
  uploadingProgress: selectUploadingProgress,
  isFileUploaded: selectIsFileUploaded,
  isTaskOrderDetailsFormSubmitted: selectIsTaskOrderDetailsFormSubmitted,
  filterByExpiration: selectFilterByExpiration,
  filterByObsoleteRates: selectFilterByObsoleteRates,
  hasPermissionsCreateTaskOrder: selectHasPermissionsCreateTaskOrder,
  hasPermissionTaskOrderDetailsHeadingActions:
    selectHasPermissionTaskOrderDetailsHeadingActions,
  taskOrdersListModel: selectTaskOrdersListModel,
  userGroup: selectUserGroup,
});

const mapDispatchToProps = {
  push,
  getTaskOrdersList: taskOrdersActions.getTaskOrdersList,
  toggleFavorite: taskOrdersActions.toggleFavorite,
  resetFilters: taskOrdersActions.resetFilters,
  changeFilter: taskOrdersActions.changeFilter,
  getTaskOrdersDetails: taskOrdersActions.getTaskOrdersDetails,
  createTaskOrder: taskOrdersActions.createTaskOrder,
  uploadFile: filesActions.uploadFile,
  generateDocument: taskOrdersActions.generateDocument,
  updateTaskOrder: taskOrdersActions.updateTaskOrder,
  setEntityName: commonActions.setEntityName,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TaskOrders);
