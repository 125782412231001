export const UNIT_TYPES = {
  LINK: 'link',
  DATE: 'date',
  TEXT: 'text',
  RANGE: 'range',
  TOGGLE: 'toggle',
  ACTION: 'action',
  SEARCH: 'search',
  SELECT: 'select',
  FAVORITE: 'favorite',
  SWITCHER: 'switcher',
  CHECKBOX: 'checkbox',
  ORDER_BUTTON: 'orderButton',
};
