import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import { StyledTabItemContainer } from 'layouts/category-management/styled-components';

export const TabItemContainer = forwardRef(({ cssRules, children }, ref) => (
  <StyledTabItemContainer
    data-id="tab-item-container"
    cssRules={cssRules}
    ref={ref}
  >
    {children}
  </StyledTabItemContainer>
));

TabItemContainer.propTypes = {
  cssRules: PropTypes.string,
  children: PropTypes.node.isRequired,
};

TabItemContainer.defaultProps = {
  cssRules: '',
};
