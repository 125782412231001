import {
  createAction,
} from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import {
  ENTITY_NAME,
} from './constants';

export const clientsActionsTypes = createTypes([
  ...async('CREATE_CLIENT'),
  ...async('CHANGE_FILTER'),
  ...async('SIGN_DOCUMENT'),
  ...async('DELETE_DOCUMENT'),
  ...async('GET_CLIENTS_LIST'),
  ...async('GENERATE_DOCUMENT'),
  ...async('GET_UPLOADED_FILE'),
  ...async('GET_CLIENT_DETAILS'),
  ...async('GET_DATA_TO_CREATE_TO'),
  ...async('UPDATE_CLIENT_DETAILS'),
  ...async('GET_MSA_BY_CLIENT_ID'),
  'RESET_FILTERS',
  'SET_ORDER_RULES',
  'TOGGLE_FAVORITES',
  'UPDATE_FAVORITES',
  'SELECT_TASK_ORDER_TO_COPY',
  'GET_DEFAULT_MSA_SUCCESS',
  'GET_DEFAULT_MSA_FAIL',
  'SET_MSA_DATE',
  'CLEAR_ERRORS',
], ENTITY_NAME);

export const clientsActions = {
  selectTaskOrderToCopy: createAction(
    clientsActionsTypes.SELECT_TASK_ORDER_TO_COPY,
    ({ taskOrderId }) => ({ taskOrderId })
  ),

  getClientsList: createAction(clientsActionsTypes.GET_CLIENTS_LIST),
  getClientsListSuccess: createAction(
    clientsActionsTypes.GET_CLIENTS_LIST_SUCCESS,
    ({ clientsList }) => ({ clientsList })
  ),
  getClientsListFail: createAction(
    clientsActionsTypes.GET_CLIENTS_LIST_FAIL,
    ({ error = {} }) => ({ error })
  ),

  getClientDetails: createAction(
    clientsActionsTypes.GET_CLIENT_DETAILS,
    ({
      clientId,
    }) => ({
      clientId,
    }),
    ({
      withReset = false,
    }) => ({
      withReset,
    })
  ),

  getClientDetailSuccess: createAction(
    clientsActionsTypes.GET_CLIENT_DETAILS_SUCCESS,
    ({
      clientDetails,
    }) => ({
      clientDetails,
    })
  ),

  getClientDetailFail: createAction(
    clientsActionsTypes.GET_CLIENT_DETAILS_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),
  createClient: createAction(
    clientsActionsTypes.CREATE_CLIENT,
    ({
      fields,
      submittingRules,
      initialValues,
    }) => ({
      fields,
      submittingRules,
      initialValues,
    })
  ),

  createClientSuccess: createAction(
    clientsActionsTypes.CREATE_CLIENT_SUCCESS,
    ({
      clientDetails,
    }) => ({
      clientDetails,
    }),
  ),

  createClientFail: createAction(
    clientsActionsTypes.CREATE_CLIENT_FAIL,
    ({
      error = {},
    }) => ({
      error,
    })
  ),

  updateClientDetails: createAction(
    clientsActionsTypes.UPDATE_CLIENT_DETAILS,
    ({
      clientId,
      fields,
      submittingRules,
      initialValues,
    }) => ({
      clientId,
      fields,
      submittingRules,
      initialValues,
    }),
    ({
      withModal = false,
    }) => ({
      withModal,
    })
  ),

  updateClientDetailsSuccess: createAction(
    clientsActionsTypes.UPDATE_CLIENT_DETAILS_SUCCESS,
    ({
      clientId,
      fields,
      clientDetails,
    }) => ({
      clientId,
      fields,
      clientDetails,
    }),
    ({
      withModal,
    }) => withModal ? {
      modalConductor: {
        nextModal: null,
      },
    } : null
  ),

  updateClientDetailsFail: createAction(
    clientsActionsTypes.UPDATE_CLIENT_DETAILS_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  deleteDocument: createAction(
    clientsActionsTypes.DELETE_DOCUMENT,
    ({
      fileId,
    }) => ({
      fileId,
    }),
    ({ clientId }) => ({
      entityName: 'CLIENTS',
      clientId,
    })
  ),

  deleteDocumentSuccess: createAction(
    clientsActionsTypes.DELETE_DOCUMENT_SUCCESS,
    ({
      fileId,
    }) => ({
      fileId,
    }),
    ({ clientId }) => ({ clientId })
  ),

  deleteDocumentFail: createAction(
    clientsActionsTypes.DELETE_DOCUMENT_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  changeFilter: createAction(
    clientsActionsTypes.CHANGE_FILTER,
    ({
      storeKey,
      selected = null,
      type,
    }) => ({
      storeKey,
      selected,
      type,
    }),
    () => ({
      entityName: ENTITY_NAME,
    })
  ),

  changeFilterSuccess: createAction(
    clientsActionsTypes.CHANGE_FILTER_SUCCESS,
    ({
      storeKey,
      config,
    }) => ({
      storeKey,
      config,
    })
  ),

  resetFilters: createAction(clientsActionsTypes.RESET_FILTERS),

  toggleFavorite: createAction(
    clientsActionsTypes.TOGGLE_FAVORITES,
    ({
      id,
    }) => ({
      id,
    }),
    () => ({
      entityName: ENTITY_NAME,
    }),
  ),

  updateFavorites: createAction(
    clientsActionsTypes.UPDATE_FAVORITES,
    ({ updatedFavorites }) => (updatedFavorites)
  ),

  setOrderRules: createAction(
    clientsActionsTypes.SET_ORDER_RULES,
    ({
      tableName,
      orderRules,
    }) => ({
      tableName,
      orderRules,
    })
  ),

  generateDocument: createAction(
    clientsActionsTypes.GENERATE_DOCUMENT,
    ({
      projectId,
      msaNumber,
      title,
      effectiveDate,
      clientId,
    }) => ({
      projectId,
      msaNumber,
      title,
      effectiveDate,
      clientId,
    }),
    () => ({
      entityName: 'CLIENTS',
    })
  ),

  generateDocumentSuccess: createAction(clientsActionsTypes.GENERATE_DOCUMENT_SUCCESS),

  generateDocumentFail: createAction(
    clientsActionsTypes.GENERATE_DOCUMENT_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  getUploadedFile: createAction(
    clientsActionsTypes.GET_UPLOADED_FILE,
    ({
      fileId,
    }) => ({
      fileId,
    }),
    ({
      clientId,
      entityName,
    }) => ({
      clientId,
      entityName,
    }),
  ),

  getUploadedFileSuccess: createAction(
    clientsActionsTypes.GET_UPLOADED_FILE_SUCCESS,
    ({
      file,
    }) => ({
      file,
    }),
    ({ clientId }) => ({
      clientId,
    }),
  ),

  getUploadedFileFail: createAction(
    clientsActionsTypes.GET_UPLOADED_FILE_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  signDocument: createAction(
    clientsActionsTypes.SIGN_DOCUMENT,
    ({
      fileId,
      pocEmail,
      pocName,
      message,
      isTaskOrder,
    }) => ({
      fileId,
      pocEmail,
      pocName,
      message,
      isTaskOrder,
    }),
    () => ({
      entityName: 'CLIENTS',
    })
  ),

  signDocumentSuccess: createAction(
    clientsActionsTypes.SIGN_DOCUMENT_SUCCESS,
    ({
      fileId,
    }) => ({
      fileId,
    })
  ),

  signDocumentFail: createAction(
    clientsActionsTypes.SIGN_DOCUMENT_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  setMsaDate: createAction(
    clientsActionsTypes.SET_MSA_DATE,
    ({
      msaDate,
    }) => ({
      msaDate,
    })
  ),
  clearErrors: createAction(clientsActionsTypes.CLEAR_ERRORS),
  getMSAByClientId: createAction(clientsActionsTypes.GET_MSA_BY_CLIENT_ID, ({ clientId }) => ({ clientId })),
  getMSAByClientIdSuccess: createAction(clientsActionsTypes.GET_MSA_BY_CLIENT_ID_SUCCESS),
  getMSAByClientIdFail: createAction(clientsActionsTypes.GET_MSA_BY_CLIENT_ID_FAIL, ({ error }) => ({ error })),
};
