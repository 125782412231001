import React, { useEffect, useState, Fragment } from 'react';

import PropTypes from 'prop-types';

import Controls from 'components/details-controls';
import DetailsForm from 'components/details-form';
import DetailsTable from 'components/details-table';
import NavigationTabs from 'components/details-tabs';
import DocumentsControls from 'components/documents-controls';
import ToastContainer from 'components/toast';
import Wrapper from 'elements/wrapper';
import { get } from 'lodash';
import { withRouter } from 'react-router';

import { updateState } from 'utils/helpers/history';
import { autoScrollToTop } from 'utils/react';

import { onSubmitUploadedFile } from './utils';

const tabIndexToPath = ['details', 'billing-info', 'documents', 'task-orders'];
const pathToTabIndex = {
  details: 0,
  'billing-info': 1,
  documents: 2,
  'task-orders': 3,
};

const ChannelPartnersDetails = ({
  location,
  tab,
  push,
  channelPartnerId,
  uploadFile,
  entityName,
  orderRules,
  changeOrder,
  isNewChannelPartner,
  createChannelPartner,
  channelPartnerDetails,
  isFileUploaded,
  isFetchingFiles,
  getChannelPartnerDetails,
  uploadingProgress,
  isFetchingDetails,
  changeCurrentModal,
  updateChannelPartnerDetails,
  numberOfChannelPartnerFiles,
  hasPermissionDocumentUpload,
  isChannelPartnerDetailsFormSubmitted,
  clearErrors,
}) => {
  autoScrollToTop(location);
  const initialTab = get(pathToTabIndex, tab, 0);
  const [indexOfActiveTab, setIndexOfActiveTab] = useState(initialTab);
  const [currentStep, updateCurrentStep] = useState(0);
  const [channelPartnerFields, setChannelPartnerFields] = useState({});

  useEffect(() => {
    getChannelPartnerDetails({ channelPartnerId, withReset: true });
  }, []);

  const onSubmit = ({ fields, initialValues }) => {
    const updatedFields = Object.fromEntries(Object.entries(fields).map(([key, value]) => typeof (value) === 'string' ? [key, value.trim()] : [key, value]));
    if (!isNewChannelPartner) {
      return updateChannelPartnerDetails({ channelPartnerId, fields: updatedFields, initialValues });
    }

    if (indexOfActiveTab) {
      const unitedFields = {
        ...channelPartnerFields,
        ...updatedFields,
      };

      return createChannelPartner({ fields: unitedFields });
    }

    setChannelPartnerFields({ ...channelPartnerFields, ...updatedFields });

    updateState({ tabIndex: 1 }, tabIndexToPath[1]);
    setIndexOfActiveTab(1);

    return updateCurrentStep(1);
  };

  const setTabIndex = (index) => {
    if (!isFetchingDetails) {
      updateState({ tabIndex: index }, tabIndexToPath[index]);
      setIndexOfActiveTab(index);

      if (isNewChannelPartner) {
        updateCurrentStep(index);
      }
    }
  };

  useEffect(() => () => {
    if (channelPartnerId) {
      clearErrors();
    } else {
      setTabIndex(0);
    }
  }, [channelPartnerId]);

  const { tabStylesTemplates, tabHeadings, name, tabData } = channelPartnerDetails;
  const title = name || 'Channel Partner';

  const onSubmitFile = ({ ...restFileData }) => onSubmitUploadedFile({
    ...restFileData,
    channelPartnerId,
    entityName,
    onSubmit: uploadFile,
  });

  const buttonTitle = (() => {
    if (isNewChannelPartner) {
      return !indexOfActiveTab ? 'Continue' : 'Create Channel Partner';
    }

    return 'Save changes';
  })();

  const unitActions = {
    push,
    changeCurrentModal: ({ currentModal, ...rest }) => changeCurrentModal({
      currentModal,
      params: { ...rest },
    }),
  };

  return (
    <>
      <ToastContainer containerId={entityName} />
      <Controls
        isCreatMode={isNewChannelPartner}
        isFetchingDetails={isFetchingDetails}
        controlsHeading={isNewChannelPartner ? 'Create Channel Partner' : title}
        goBackLinkPath="/channel-partners"
        goBackLinkText="back to Channel Partners list"
      >
      </Controls>

      <NavigationTabs
        isCreatMode={isNewChannelPartner}
        currentStep={currentStep}
        indexOfActiveTab={indexOfActiveTab}
        setIndexOfActiveTab={setTabIndex}
        tabHeadings={tabHeadings}
      />
      {
        tabData.map((body, index) => {
          const isVisible = index === indexOfActiveTab;
          const tabStylesTemplate = tabStylesTemplates[index];
          return (
            <Wrapper
              key={index} // eslint-disable-line react/no-array-index-key
              isGrow
              isVisible={isVisible}
              cssRules={tabStylesTemplate.wrapperCssRules}
            >
              {
                body.map(({ type, withUploading, ...rest }, bodyItems) => (
                  <Fragment
                    key={bodyItems} // eslint-disable-line react/no-array-index-key
                  >
                    {
                      type === 'form' && (
                        <DetailsForm
                          buttonTitle={buttonTitle}
                          onSubmit={onSubmit}
                          isVisible={isVisible}
                          additionalActions={unitActions}
                          isFormSubmitted={isChannelPartnerDetailsFormSubmitted}
                          isFetchingDetails={isFetchingDetails}
                          withErrorBox
                          {
                            ...rest
                          }
                        />
                      )
                    }

                    {
                      type === 'table' && (
                        <DetailsTable
                          clientId={channelPartnerId}
                          unitActions={unitActions}
                          orderRules={orderRules}
                          changeOrder={changeOrder}
                          entityName={entityName}
                          isFetchingDetails={isFetchingDetails}
                          {
                            ...rest
                          }
                        />
                      )
                    }

                    {
                      withUploading && (
                        <DocumentsControls
                          fileGeneratorConfig={{}}
                          onGenerate={() => {}}
                          withDocumentUpload={hasPermissionDocumentUpload}
                          keyPrefix={numberOfChannelPartnerFiles}
                          isFetchingFiles={isFetchingFiles}
                          onSubmitUploadedFile={onSubmitFile}
                          isFileUploaded={isFileUploaded}
                          uploadingProgress={uploadingProgress}
                          unitActions={unitActions}
                          {
                            ...rest
                          }
                        />
                      )
                    }
                  </Fragment>
                ))
              }
            </Wrapper>
          );
        })
      }
    </>
  );
};

ChannelPartnersDetails.propTypes = {
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  isFetchingFiles: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  numberOfChannelPartnerFiles: PropTypes.number,
  isFileUploaded: PropTypes.bool.isRequired,
  uploadingProgress: PropTypes.number.isRequired,
  uploadFile: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  tab: PropTypes.string,
  orderRules: PropTypes.shape({}).isRequired,
  changeOrder: PropTypes.func.isRequired,
  isChannelPartnerDetailsFormSubmitted: PropTypes.bool.isRequired,
  createChannelPartner: PropTypes.func.isRequired,
  isNewChannelPartner: PropTypes.bool,
  getChannelPartnerDetails: PropTypes.func.isRequired,
  channelPartnerId: PropTypes.string,
  channelPartnerDetails: PropTypes.object.isRequired,
  isFetchingDetails: PropTypes.bool.isRequired,
  updateChannelPartnerDetails: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  hasPermissionDocumentUpload: PropTypes.bool,
};

ChannelPartnersDetails.defaultProps = {
  tab: 'details',
  isNewChannelPartner: false,
  channelPartnerId: '',
  hasPermissionDocumentUpload: false,
  numberOfChannelPartnerFiles: null,
};

export default withRouter(ChannelPartnersDetails);
