import { useEffect, useRef } from 'react';

export const useTimeout = (
  callback,
  delay
) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    const id = setTimeout(() => {
      savedCallback.current();
    }, delay);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(id);
  }, [delay]);
};
