import { waitForAuthorization } from 'core/auth/sagas';
import { get } from 'lodash';
import {
  put,
  takeLatest,
  call,
  select,
  all,
} from 'redux-saga/effects';

import {
  executeQuery,
  parseError,
} from 'utils/request';

import {
  deliveryCenterUtilizationByMonthActionsTypes,
  deliveryCenterUtilizationByMonthActions,
} from './actions';
import { queryConfig } from './queries';
import {
  selectErrors,
  selectEntityName,
  selectSelectedDevCenterIds,
  selectSelectedReportDate,
} from './selectors';

function* getUtilizationReport() {
  try {
    const devCenterIds = yield select(selectSelectedDevCenterIds);
    const reportDate = yield select(selectSelectedReportDate);

    const options = {
      query: queryConfig.getUtilizationReport,
      variables: {
        devcenterIds: devCenterIds,
        month: reportDate.month() + 1,
        year: reportDate.year(),
      },
    };

    const {
      utilizationResources: {
        resourceRows,
        summaryRows,
      },
    } = yield call(executeQuery, options);

    return yield put(deliveryCenterUtilizationByMonthActions.getUtilizationReportSuccess({
      resourceRows: resourceRows || [],
      summaryRows: summaryRows || [],
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getUtilizationReportError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getUtilizationReportError = yield call(parseError, error, options);

    return yield put(deliveryCenterUtilizationByMonthActions.getUtilizationReportFail({
      error: {
        getUtilizationReportError,
      },
    }));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(deliveryCenterUtilizationByMonthActionsTypes.GET_UTILIZATION_REPORT, waitForAuthorization(getUtilizationReport)),
  ]);
}
