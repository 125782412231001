import React, { useState, useEffect } from 'react';

import { useStore } from 'react-redux';

import hoistNonReactStatics from 'hoist-non-react-statics';

import getInjectors from './reducer-injectors';

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
export default ({ key, reducer }) => (WrappedComponent) => {
  const ReducerInjector = (props) => {
    const injectors = getInjectors(useStore());

    const [mounted, setMounted] = useState(false);
    if (!mounted) {
      injectors.injectReducer(key, reducer);
    }

    useEffect(() => {
      setMounted(true);
    }, []);

    return <WrappedComponent {...props} />;
  };

  ReducerInjector.WrappedComponent = WrappedComponent;
  ReducerInjector.displayName = `withReducer(${(WrappedComponent.displayName || WrappedComponent.name || 'Component')})`;

  return hoistNonReactStatics(ReducerInjector, WrappedComponent);
};
