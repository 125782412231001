import { modalConductorActionsTypes } from 'core/modal-conductor/actions';

export default () => (
  ({ dispatch }) => (next) => (action) => {
    const processedAction = next(action);

    const { meta } = action;

    if (!meta || !meta.modalConductor) {
      return processedAction;
    }

    const { nextModal } = meta.modalConductor;

    dispatch({
      type: modalConductorActionsTypes.CHANGE_CURRENT_MODAL,
      payload: {
        currentModal: nextModal,
      },
    });
    return processedAction;
  }
);
