import Moment from 'moment';
import 'moment-weekday-calc';

import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export const getCurrentMMYYYY = () => moment().format('MMYYYY');

export const formatDateMMDDYYYY = (date) => moment(date).format('MM/DD/YYYY');

export const formatDateYYYYMMDD = (date) => moment(date).format('YYYY-MM-DD');

export const formatDateYYYYMM = (date) => moment(date).format('YYYY-MM');

export const formatDateYYYY = (date) => moment(date).format('YYYY');

export const getUnixTime = (date) => moment(date).format('X');

export const getIsExpired = (effectiveDate) => {
  if (!effectiveDate) {
    return false;
  }

  const now = moment().format('YYYY-MM-DD');

  return moment(effectiveDate).isBefore(now);
};

export const getIsExpireSoon = (endDate) => {
  if (!endDate) {
    return false;
  }

  const now = moment();
  const expirationDate = moment(endDate, 'YYYY-MM-DD');
  const left = moment(now).subtract(10, 'd');
  const right = moment(now).add(40, 'd');
  const range = moment.range(left, right);

  return range.contains(expirationDate);
};

export const getMonth = (dateString) => dateString ? moment(dateString).month() : moment().month() + 1;

export const getFullYear = (dateString) => dateString ? moment(dateString).year() : moment().year();

export const getShortMountName = (date) => moment(date).format('MMM');

export const getFullMounthFromMounthNumber = (month) => moment(month, 'M').format('MMMM');

moment('5', 'M').format('MMMM');

export const calcBusinessDaysDiff = (rangeStart, rangeEnd, exclusions = []) => moment().isoWeekdayCalc({
  rangeStart,
  rangeEnd,
  weekdays: [1, 2, 3, 4, 5],
  exclusions,
});

const MONTH_INDEX_BY_SHORT_NAMES = moment.monthsShort()
  .reduce(
    (result, value, index) => ({
      ...result,
      [value]: index,
    }),
    {},
  );

export const getMonthIndexByShortName = (shortName) => MONTH_INDEX_BY_SHORT_NAMES[shortName];
