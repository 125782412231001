import gql from 'graphql-tag';

export const queryConfig = {
  getDevstaffAttachmentsRecords: gql`
    query devstaffAttachmentRecords($devstaffId: Int){
      devstaffAttachmentRecords(devstaffId: $devstaffId) {
        attachmentRecordId: attachmentId
        devstaffId
        name: fileName
        downloadUrl
        extension
        description
        allowedForEdit
        allowedForDelete
        attachedByUserFullname
        attachedByUserId
        createdAtDate
      }
    }
  `,
  createDevstaffAttachment: gql`
    mutation createAttachmentRecord(
      $fields: CreateDevstaffAttachmentRecordInput!, $devstaffId: Int!
    ) {
      createAttachmentRecord(fields: $fields, devstaffId: $devstaffId) {
        ok
        fileUploadData
        attachmentRecord{
          attachmentRecordId: attachmentId
          devstaffId
          description
          extension
          name: fileName
          attachedByUserFullname
          attachedByUserId
          createdAtDate
          downloadUrl
        }
      }
    }
  `,
  deleteDevstaffAttachment: gql`
    mutation deleteAttachmentRecord($attachmentRecordId: Int!){
      deleteAttachmentRecord(
        attachmentRecordId: $attachmentRecordId
      ){
        ok
      }
    }
  `,

  updateDevstaffAttachment: gql`
    mutation updateAttachmentRecord(
      $attachmentRecordId: Int!,
      $fields: UpdateDevstaffAttachmentRecordInput!
    ){
        updateAttachmentRecord(
          fields: $fields,
          attachmentRecordId: $attachmentRecordId
        ){
          ok,
          attachmentRecord{
            attachmentRecordId: attachmentId
            devstaffId
            description
            extension
            name: fileName
            attachedByUserFullname
            attachedByUserId
            createdAtDate
            downloadUrl
          }
        }
    }
  `,
};
