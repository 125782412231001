import React from 'react';

import PropTypes from 'prop-types';

import { PdfIcon, RemoveFileIcon } from 'assets/svgComponents';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const AttachmentView = ({
  hasFileName,
  file,
  onRemoveFile,
  isAllowedForDelete,
}) => (
  <div
    data-id="Attachment View"
    className={classNames(styles.attachmentView)}
  >
    <PdfIcon width="2.4rem" height="2.4rem" />
    <div
      data-id="Attachment Name"
      className={classNames(styles.attachmentName, {
        [styles.active]: hasFileName,
      })}
    >
      <a
        href={file.downloadUrl}
        data-id="Attachment Link"
        className={classNames(styles.attachmentLink)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {file.name}
      </a>
    </div>
    {isAllowedForDelete && (
      <RemoveFileIcon
        className={styles.removeFileIcon}
        onClick={onRemoveFile}
      />
    )}
  </div>
);

AttachmentView.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    downloadUrl: PropTypes.string,
  }).isRequired,
  hasFileName: PropTypes.bool.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  isAllowedForDelete: PropTypes.bool.isRequired,
};
