import React from 'react';

import Wrapper from 'elements/wrapper';

import './styles.scss';
import { Link } from 'react-router-dom';

const ClientsError = () => (
  <Wrapper>
    <div className="clients-error">

      <span className="clients-error__title"> 404 </span>

      <span className="clients-error__message"> Oops, page not found </span>

      <span className="clients-error__description">
        The page you are looking for no longer exists.
        <br />
        Perhaps you can return back to the site&rsquo;s  &nbsp;
        <Link
          className="clients-error__description-link"
          to="/"
          title="HBS link"
        >
          dashboard
        </Link>
        &nbsp;and see if you can find what you are looking for.
      </span>

    </div>

  </Wrapper>
);

export default ClientsError;
