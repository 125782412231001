import { colorSecondaryGrayLight07 } from 'assets/styles/variables';
import { COLUMN_TYPES } from 'core/constants';
import { toNumber, get } from 'lodash';
import moment from 'moment';
import { addThousandSeparator } from 'utils/helpers/numbers';

import { isDisabled } from './utils';

const getRate = (rate) => {
  const roundedRate = toNumber(rate).toFixed(2);

  return addThousandSeparator(roundedRate);
};

const additionalRateCss = `
  color: ${colorSecondaryGrayLight07};
  text-align: right;
  letter-spacing: -0.25px;
`;

export default ({ inDepthStudy = false } = {}) => [{
  columnName: 'Resource',
  withoutControls: true,
  valueGetter: ({ title, isTotal }) => ({
    type: COLUMN_TYPES.TEXT,
    componentProps: {
      data: title,
      cssRules: `
        font-size: 1.2rem;
        letter-spacing: -0.25px;
        ${isTotal ? 'font-weight: 500;' : ''}
      `,
    },
  }),
},
...moment.monthsShort().map((name, index) => ({
  columnName: name,
  headerProps: {
    cssRules: `
      padding-right: 0;
      text-align: right;
    `,
  },
  withoutControls: true,
  valueGetter: ({ monthsData, isTotal }) => {
    // console.log('monthsData', monthsData);
    const { rate, rateUsd } = get(monthsData, index);

    return {
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data: getRate(rate),
        additionalData: [(rateUsd && rate !== rateUsd) && {
          content: `$ ${getRate(rateUsd)}`,
          cssRules: additionalRateCss,
        }].filter((row) => row),
        cssRules: `
          padding-right: 0;
          font-size: 1.2rem;
          letter-spacing: -0.25px;
          text-align: right;
          ${isTotal && inDepthStudy ? 'font-weight: 500;' : ''}
          ${isDisabled(rate) ? `color: ${colorSecondaryGrayLight07};` : ''}
        `,
      },
    };
  },
})), {
  columnName: 'total',
  headerProps: {
    cssRules: `
      box-sizing: border-box;
      padding-right: 1.6rem;
      text-align: right;
    `,
  },
  withoutControls: true,
  valueGetter: ({ monthsData, isTotal }) => {
    const totalRate = monthsData.reduce((acc, i) => acc + (+i.rate), 0);
    const totalRateUsd = monthsData.reduce((acc, i) => acc + (+i.rateUsd), 0);

    return {
      type: COLUMN_TYPES.TEXT,
      componentProps: {
        data: getRate(totalRate),
        additionalData: [(totalRateUsd && totalRate !== totalRateUsd) && {
          content: `$ ${getRate(totalRateUsd)}`,
          cssRules: additionalRateCss,
        }].filter((row) => row),
        cssRules: `
          padding-right: 0;
          font-size: 1.2rem;
          letter-spacing: -0.25px;
          text-align: right;
          ${isTotal && inDepthStudy ? 'font-weight: 500;' : ''}
          ${isDisabled(totalRate) ? `color: ${colorSecondaryGrayLight07};` : ''}
        `,
      },
    };
  },
}];
