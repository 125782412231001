import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { get } from 'lodash';

import { Controls } from './Controls';

import styles from './styles.module.scss';

export const ControlsContainer = ({
  onRemoveRecord,
  onDeleteHandler,
  isFormDirty,
  resetForm,
  isCreateMode,
  setEditedRecordId,
  isAllowedForEdit,
  isAllowedForDelete,
  formStateSelector,
  recordId,
}) => {
  const formState = useSelector(formStateSelector);

  const isSubmitting = get(formState, 'isSubmitting', false);

  const handleDeleteClick = useCallback(() => {
    onDeleteHandler(recordId);
  }, []);

  return (
    <div
      data-id="controlsContainer"
      className={classNames(styles.controlsContainer)}
    >
      <Controls
        onRemoveRecord={onRemoveRecord}
        isCreateMode={isCreateMode}
        resetForm={resetForm}
        setEditedRecordId={setEditedRecordId}
        isSubmitting={isSubmitting}
        isEditMode={!isCreateMode && !isFormDirty}
        onDeleteClick={handleDeleteClick}
        isAllowedForEdit={isAllowedForEdit}
        isAllowedForDelete={isAllowedForDelete}
      />
    </div>
  );
};

ControlsContainer.propTypes = {
  onRemoveRecord: PropTypes.func.isRequired,
  onDeleteHandler: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  recordId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resetForm: PropTypes.func.isRequired,
  isCreateMode: PropTypes.bool.isRequired,
  setEditedRecordId: PropTypes.func.isRequired,
  isAllowedForEdit: PropTypes.bool.isRequired,
  formStateSelector: PropTypes.func.isRequired,
  isAllowedForDelete: PropTypes.bool.isRequired,
};

ControlsContainer.defaultProps = {
  recordId: undefined,
};
