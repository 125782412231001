import gql from 'graphql-tag';

const toDetails = `
  title
  taskOrderId
  projectId
  msaName
  issueDate
  effectiveDate
  endDate
  isPlatinum
  cap
  pono
  description
  projectType
  clientPm
  isActive
  emailReports
  isSigned
  isManuallyDeactivated
  clientCategories {
    clientCategoryId
    key
    name
    isAssigned
  }
  effortLines  {
    devcenter
    item
    seniority
    hrsEstimate
  }
  taskOrderRates {
    name
    rates {
      devcenter
      seniority
      ratePtm
      rateHr
      rateDisc
      ratePlatinum
      rateMo
      rateMoPlatinum
      ratePtmInit
      rateHrInit
      rateDiscInit
      ratePlatinumInit
      rateMoInit
      rateMoPlatinumInit
    }
  }
  client {
    clientId
    name
    msa
    poRequired
    isPlatinumRates
    pocMsaName
    pocMsaEmail
    overtimePerc
    billingAgentId
    templateRatecard {
      rates {
        devcenter
        seniority
        ratePtm
        rateHr
        rateDisc
        ratePlatinum
        rateMo
        rateMoPlatinum
      }
    }
  }
  files {
    isRequestToSignSent
    title
    fileId
    fileName
    fileType
    uploadedDate
    effectiveDate
    downloadUrl
    downloadSignedUrl
    isSigned
    isGenerated
  }
  employees {
    lineId
    employeeName
    seniority
    dateFrom
    dateTo
    utilization
    rateHr
    rateMo
    copHr
    copMo
    rateHrOt
    copHrOt
    ratePlatinum
    rateMoPlatinum
    vendorId
  }
`;

export const queryConfig = {
  getTaskOrdersList: gql`{
    taskOrders {
      taskOrderId
      projectId
      clientId
      client {
        msa
        name
        country
        isActive
        isSignedMsaUploaded
        billingAgent {
          shortName
        }
      }
      taskOrderRates {
        year
      }
      employees {
        dateTo
      }
      projectType
      effectiveDate
      endDate
      isActive
      isSigned
      isManuallyDeactivated
      title
      categoryKeys
    }
  }`,

  getTaskOrdersDetails: ({ projectId, taskOrderToCopyId }) => gql`{
    ${taskOrderToCopyId ? `
    node(id: "TaskOrder:${taskOrderToCopyId}") {
      ... on TaskOrder  {
        msaName
        projectType
        clientPm
        emailReports
        client {
          clientId
          name
          msa
          poRequired
        }
      }
    }
    ` : ''}
    ${projectId ? `
    node(id: "TaskOrder:${projectId}") {
      ... on TaskOrder  {
        ${toDetails}
        committedHours
        discount
        committedHoursId
      }
    }
    ` : ''}
    clients {
      clientId
      name
      value: clientId
      label: name
      msa
      isActive
      poRequired
      isPlatinumRates
      isSignedMsaUploaded
    }
    holidays {
      devcenter
      date
    }
    devstaffList {
      name
      fullname
      seniority
      allowBilling
      vendorName
      dateLast
      devcenterId
      devstaffPotentialWbRecords{
        validFrom
        devcenterId
        seniority
      }
    }
    devcenters {
      value: devcenterId
      label: name
    }
    deliveryRates  {
      deliveryCenterId
      juniorRates {
        rateHr
        rateDisc
        rateMo
      }
      staffRates {
        rateHr
        rateDisc
        rateMo
      }
      seniorRates {
        rateHr
        rateDisc
        rateMo
      }
    }
    ratecards {
      ratecardId
      billingAgentId
      name
      year
      rates {
        devcenter
        rateDisc
        rateHr
        rateMo
        rateMoPlatinum
        ratePlatinum
        ratePtm
        seniority
      }
    }
  }`,

  createTaskOrder: gql`
    mutation createTaskOrder($fields: CreateTaskOrderInput! $clientId: Int!) {
      createTaskOrder(clientId: $clientId, fields: $fields) {
        ok
        ratecards {
          ratecardId
          billingAgentId
          name
          year
          rates {
            devcenter
            rateDisc
            rateHr
            rateMo
            rateMoPlatinum
            ratePlatinum
            ratePtm
            seniority
          }
        }
        taskOrder {
          ${toDetails}
          committedHours
          discount
          committedHoursId
        }
      }
    }
  `,

  updateTaskOrder: (projectId) => gql`
    mutation updateTaskOrder($fields: UpdateTaskOrderInput!) {
      updateTaskOrder(projectId: ${projectId}, fields: $fields) {
        ok
        taskOrder {
          ${toDetails}
        }
      }
    }
  `,

  getCommittedHoursByClientId: gql`
    query committedHours($clientId: Int){
      committedHours(clientId: $clientId) {
        clientId
        hours
        committedHoursId
        clientId
        discount
      }
    }
  `,
};
