import { useState, useEffect, useRef } from 'react';

import { appStorage } from 'core/storage';
import {
  getIsTokenExpire,
  millisecondsToMinutes,
  minutesToMilliseconds,
} from 'utils/auth';

const useTokenTimer = (userGroup, userLogout, location) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const intervalId = useRef(null);

  const checkTokenValidity = () => {
    const accessToken = appStorage.getAccessToken();
    const { hasExpired, remainingTime } = getIsTokenExpire(accessToken);
    const remainingMinutes = millisecondsToMinutes(remainingTime);
    if (!accessToken || hasExpired || remainingMinutes <= 0) {
      setShowWarning(false);
      userLogout();
      return;
    }

    if (remainingMinutes <= 10) {
      setShowWarning(true);
      setTimeLeft(remainingMinutes <= 0 ? 1 : remainingMinutes);
    } else {
      setShowWarning(false);
      setTimeLeft(null);
    }
  };

  useEffect(() => {
    if (userGroup) {
      checkTokenValidity();
      intervalId.current = setInterval(checkTokenValidity, minutesToMilliseconds(1));
    } else {
      setShowWarning(false);
    }

    return () => {
      clearInterval(intervalId.current);
    };
  }, [userGroup, location]);

  return { timeLeft, showWarning };
};
export default useTokenTimer;
