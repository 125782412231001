import React, { useState, useEffect, memo } from 'react';

import PropTypes from 'prop-types';

import Inputs from 'components/inputs';
import ErrorMessage from 'elements/error-message';
import { Field } from 'formik';
import { isEqual, get } from 'lodash';

import { onInputValueChange, updateInput } from './utils';

export const TextInput = ({
  withLabel,
  placeholder,
  name,
  fieldData,
  disabled,
  onChange,
  validationRules,
  error,
  isTouched,
  tooltipContent,
  isLocked,
  isMultiline,
  formValue,
  cssRules,
  withErrorBox,
  touched,
  formValues,
  parentField,
  parentFieldsData,
  isPassword,
  title,
  isInitialError,
}) => {
  const { isNumeric, isRates, isRequired, fixedTo } = validationRules;
  const initialValue = get(fieldData, 'value');
  const hasDependency = !!parentField;
  const [inputValue, setInputValue] = useState(initialValue);
  const [isManuallyUpdated, setIsManuallyUpdated] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [calculatedValue, setCalculatedValue] = useState(null);

  useEffect(() => {
    onInputValueChange({ validationRules, calculatedValue, fieldData, setHasChanges, inputValue });
  }, [inputValue]); // set specific params on input value change

  useEffect(() => {
    setInputValue(inputValue);
  }, [fieldData]); // update current value if initial data changed

  useEffect(() => {
    if (formValue !== inputValue) {
      if (isRates) {
        const toFixedNumber = fixedTo || 2;
        setInputValue(Number(formValue) ? Number(formValue).toFixed(toFixedNumber) : '');
      } else {
        setInputValue(formValue);

        if (!formValue && isManuallyUpdated) {
          setIsManuallyUpdated(false);
        }
      }
    }
  }, [formValue]); // listen formik changes (need for reset)

  useEffect(() => {
    // console.log(`ParentField HEre on ${name}`);
    if (parentField) {
      const { strictDependence } = parentField;
      const isSomeParentTouched = parentField.name.some((parentFieldName) => get(touched, parentFieldName));
      if (isSomeParentTouched || strictDependence) {
        const { valueGetter } = parentField;
        const updatedValue = valueGetter(formValues, fieldData);

        if (updatedValue && !isEqual(calculatedValue, updatedValue)) {
          setCalculatedValue(updatedValue);
        }
      } else {
        setCalculatedValue(null);
      }
    }
  }, parentFieldsData); // is has dependency, calculate current input data

  useEffect(() => {
    if (calculatedValue) {
      const { value } = calculatedValue;

      if (value !== inputValue) {
        if (!isRates) {
          setFieldValue(value);
        }

        if (isRates) {
          setFieldValue(Number(value) ? Number(value).toFixed(2) : '');
        }
      }
    }
  }, [calculatedValue]); // set input value is has changes in calculated data

  const changeInputValue = (currentValue) => updateInput({
    currentValue,
    validationRules,
    isLocked,
    inputValue,
    setInputValue,
    onChange,
    calculatedValue,
    setIsManuallyUpdated,
  });

  const onChangeNumericalInput = (value) => {
    setInputValue(value);
    onChange(value);
  };

  const setFieldValue = (value) => {
    if (hasDependency) {
      if (value && !isManuallyUpdated) {
        setInputValue(value);

        return onChange(value);
      }

      if (!value && inputValue && !isManuallyUpdated) {
        setInputValue('');

        if (isNumeric) {
          return onChange(null);
        }

        return onChange('');
      }
    }

    return null;
  };

  const withError = !!(error && isTouched) || isInitialError;
  const hasManuallyUpdates = hasDependency && isManuallyUpdated && !!inputValue;
  const isLockedByParentField = hasDependency && calculatedValue && calculatedValue.props && calculatedValue.props.isLocked;
  const withWarning = isRates && fieldData.isDefaultRatesChanged;
  return (

    <Field
      type="text"
      name={name}
      render={() => (
        isNumeric ? (
          <Inputs.NumericalInput
            name={name}
            isLocked={isLocked || (isLockedByParentField && !inputValue && !isRates)}
            hasChanges={hasChanges}
            inputValue={inputValue}
            isDisabled={disabled}
            onChange={onChangeNumericalInput}
            placeholder={get(fieldData, 'placeholder', '')}
            label={placeholder}
            withLabel={!isRates && withLabel}
            isRequired={isRequired || false}
            hasManuallyUpdates={!!hasManuallyUpdates}
            error={error}
            withError={withError}
            tooltipContent={tooltipContent}
            isMultiline={isMultiline}
            withWarning={withWarning}
            cssRules={cssRules}
            validationRules={validationRules}
            withErrorBox={withErrorBox}
            formValues={formValues}
          />
        ) : (
          <Inputs.TextInput
            title={title}
            isPassword={isPassword}
            name={name}
            isLocked={isLocked || (isLockedByParentField && !inputValue && !isRates)}
            hasChanges={hasChanges}
            inputValue={inputValue}
            isDisabled={disabled}
            onChange={changeInputValue}
            placeholder={get(fieldData, 'placeholder', '') || placeholder}
            label={placeholder}
            withLabel={!isRates && withLabel}
            isRequired={isRequired || false}
            hasManuallyUpdates={hasManuallyUpdates}
            error={error}
            withError={withError}
            tooltipContent={tooltipContent}
            isMultiline={isMultiline}
            withWarning={withWarning}
            cssRules={cssRules}
            withErrorBox={withErrorBox}
          >
            {
              !withError && hasManuallyUpdates && !isRates && (
                <ErrorMessage visible>
                  {`${placeholder} is customized`}
                </ErrorMessage>
              )
            }
          </Inputs.TextInput>
        )
      )}
    />

  );
};

TextInput.propTypes = {
  withErrorBox: PropTypes.bool,
  parentField: PropTypes.shape({}),
  formValue: PropTypes.any,
  isMultiline: PropTypes.bool,
  isLocked: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  isInitialError: PropTypes.bool,
  error: PropTypes.string,
  isTouched: PropTypes.bool,
  cssRules: PropTypes.string,
  validationRules: PropTypes.shape({
    isNumeric: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  fieldData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  withLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  touched: PropTypes.any,
  formValues: PropTypes.any,
  parentFieldsData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  isPassword: PropTypes.bool,
  title: PropTypes.string,
};

TextInput.defaultProps = {
  withErrorBox: false,
  parentFieldsData: null,
  parentField: null,
  cssRules: '',
  formValue: '',
  isMultiline: false,
  isLocked: false,
  tooltipContent: '',
  validationRules: {},
  name: null,
  placeholder: null,
  fieldData: null,
  disabled: false,
  onChange: () => null,
  error: '',
  isInitialError: false,
  isTouched: false,
  touched: null,
  formValues: null,
  withLabel: false,
  isPassword: false,
  title: '',
};

export default memo(TextInput, (prevProps, currentProps) => {
  const { isTouched, formValue, error, disabled, parentFieldsData, fieldData, isLocked, isInitialError } = prevProps;
  if (isTouched !== currentProps.isTouched ||
    formValue !== currentProps.formValue ||
    error !== currentProps.error ||
    disabled !== currentProps.disabled ||
    fieldData !== currentProps.fieldData ||
    isLocked !== currentProps.isLocked ||
    isInitialError !== currentProps.isInitialError ||
    parentFieldsData !== currentProps.parentFieldsData) {
    return false;
  }

  return true;
});
