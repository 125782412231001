import { get, set } from 'lodash';

let instance;
class SingletonBuffer {
    static buffer = {};

    constructor() {
      if (typeof instance === 'object') {
        throw new Error('Only one instance can exist!');
      }

      instance = this;
      return this; // eslint-disable-line no-constructor-return
    }

    getBuffer(key) {
      return get(SingletonBuffer, `groups.${key}`);
    }

    setBuffer(key, data) {
      set(SingletonBuffer, `groups.${key}`, data);
    }
}

export default new SingletonBuffer();
