import { getTOStatus } from 'layouts/client-details/utils';
import {
  formatDateMMDDYYYY,
  getIsExpired,
  getUnixTime,
} from 'utils/helpers/date';

export default ({
  isCTO,
  isDOA,
}) => isDOA ? [] : [
  {
    type: 'table',
    tableCssRules: 'min-height: calc(100vh - 30rem);',
    content: [
      {
        title: 'Task Orders',
        idKey: 'projectId',
        dataKey: 'taskOrders',
        rowStatusGetter: ({
          projectId,
          title,
          isActive,
          endDate,
          isSigned,
          isManuallyDeactivated,
        }) => ({
          name: title,
          id: projectId,
          taskOrderId: projectId,
          isActive: isActive && !getIsExpired(endDate),
          hasWarning: ((isSigned && !isActive) || (!isSigned && !isActive)) && (!getIsExpired(endDate) && !isManuallyDeactivated),
        }),
        rules: {
          css: {
            gridTemplateConfig: 'grid-template-columns: minmax(auto, 91fr) minmax(auto, 326fr) minmax(auto, 59fr) minmax(auto, 129fr) minmax(auto, 120fr) minmax(auto, 120fr) minmax(auto, 102fr) minmax(auto, 261fr) minmax(auto, 58fr);',
          },
        },
        tableName: 'taskOrders',
        dataTemplate: [{
          columnName: 'TO #',
          valueGetter: ({
            projectId,
            taskOrderId,
          }) => ({
            type: 'link',
            value: taskOrderId,
            componentProps: {
              data: taskOrderId,
              pathname: `/task-orders/${projectId}/${isCTO ? 'staffing' : 'details'}`,
            },
          }),
        },
        {
          columnName: 'Project',
          valueGetter: ({
            title,
            projectId,
          }) => ({
            type: 'link',
            value: title,
            componentProps: {
              data: title,
              pathname: `/task-orders/${projectId}/${isCTO ? 'staffing' : 'details'}`,
            },
          }),
        },
        {
          columnName: 'Type',
          valueGetter: ({
            projectType,
          }) => ({
            value: projectType,
            type: 'text',
          }),
        },
        {
          columnName: 'Rates',
          valueGetter: ({
            isSelfRatecardChanged,
            isClientRatecardChanged,
          }) => {
            let ratesState = 'Default Rates';

            if (isClientRatecardChanged) {
              ratesState = 'Customized Client';
            }

            if (isSelfRatecardChanged) {
              ratesState = 'Customized Project';
            }

            return {
              value: ratesState,
              type: 'text',
            };
          },
        },
        {
          columnName: 'Effective Date',
          valueGetter: ({
            effectiveDate,
          }) => ({
            isDate: true,
            value: effectiveDate ? formatDateMMDDYYYY(effectiveDate) : '',
            unixValue: getUnixTime(effectiveDate),
            type: 'text',
          }),
        },
        {
          columnName: 'Expiration Date',
          valueGetter: ({
            endDate,
          }) => ({
            isDate: true,
            value: endDate ? formatDateMMDDYYYY(endDate) : '',
            unixValue: getUnixTime(endDate),
            type: 'text',
          }),
        },
        {
          columnName: 'status',
          valueGetter: (tackOrderData) => {
            const status = getTOStatus(tackOrderData);

            return {
              value: status,
              type: 'text',
            };
          },
        },
        {
          columnName: 'Client Category Assignments',
          valueGetter: ({
            categoryKeys,
          }) => {
            const value = categoryKeys ? categoryKeys.join(', ') : '';

            return {
              value,
              type: 'text',
              componentProps: {
                data: value,
                isMultiline: true,
              },
            };
          },
        },
        {
          columnName: 'Staffing',
          withoutControls: true,
          valueGetter: ({
            projectId,
          }) => ({
            value: 'Staffing',
            type: 'link',
            componentProps: {
              data: 'Staffing',
              pathname: `/task-orders/${projectId}/staffing`,
              cssRules: 'font-size: 1.2rem;',
            },
          }),
        },
        ],
      }],
  }];
