import {
  addButtonCss,
  formSelectCss,
  formHeaderCss,
  formViewboxCss,
  removeButtonCss,
  staffingFormTextInputCss,
} from 'assets/styles/config';
import {
  colorSecondaryText,
  colorSecondaryGrayLight,
} from 'assets/styles/variables';
import hash from 'hash-sum';
import { ratesBySeniority } from 'layouts/task-order-details/config';
import {
  generateSelectedDevCenter,
  generateSelectedSeniority,
  getPotentialWbRecord,
} from 'layouts/task-order-details/model/helpers';
import { getOvertimeRate } from 'layouts/task-order-details/utils';
import { get, toNumber } from 'lodash';
import moment from 'moment';
import {
  getUnixTime,
  getIsExpired,
  formatDateMMDDYYYY,
  calcBusinessDaysDiff,
} from 'utils/helpers/date';
import { stringCompareFunction } from 'utils/helpers/sorting';
import {
  rateValidator,
  // requiredValidator,
  utilizationValidator,
} from 'utils/validation';
import { object, array, string } from 'yup';

const getColumns = ({ isUserSales, isOnlyView, isCPS }) => {
  let res =
    'minmax(0, 175fr) minmax(0, 68fr) minmax(0, 102fr) minmax(0, 102fr) minmax(0, 55fr) minmax(0, 48fr) minmax(0, 56fr) minmax(0, 44fr) minmax(0, 72fr) minmax(0, 76fr) minmax(0, 60fr) minmax(0, 48fr) minmax(0, 48fr) minmax(0, 158fr) minmax(0, 44fr);';

  if (isOnlyView) {
    res =
      'minmax(auto, 175fr) minmax(auto, 68fr) minmax(auto, 102fr) minmax(auto, 102fr) minmax(auto, 55fr) minmax(auto, 60fr) minmax(auto, 56fr) minmax(auto, 60fr) minmax(auto, 80fr) minmax(auto, 80fr) minmax(auto, 80fr) minmax(auto, 70fr) minmax(auto, 70fr) minmax(auto, 158fr);';
  }
  if (isUserSales) {
    res =
      ' minmax(0, 175fr) minmax(0,  68fr)  minmax(0, 102fr)  minmax(0, 102fr) minmax(0,  55fr) minmax(0,  48fr) minmax(0,  56fr) minmax(0,  72fr) minmax(0,  76fr) minmax(0,  48fr) minmax(0, 158fr) minmax(0,  44fr)';
    if (isOnlyView || isCPS) {
      res =
        'minmax(auto, 175fr) minmax(auto, 68fr) minmax(auto, 102fr) minmax(auto, 102fr) minmax(auto, 55fr) minmax(auto, 60fr) minmax(auto, 56fr) minmax(auto, 60fr) minmax(auto, 80fr) minmax(auto, 80fr) minmax(auto, 158fr);';
    }
  }

  return res;
};

export default ({
  isTTM,
  isODC,
  isPTM,
  isCEO,
  isCPS,
  holidays,
  isExpired,
  devcenters,
  isUserSales,
  isManuallyDeactivated,
  taskOrderEffectiveDate,
  hasPermissionsToDeleteStaffingRows,
  status,
}) => {
  const { notYetActive } = status;
  const filedArrayRootName = 'employees';
  const isOnlyView = isManuallyDeactivated && !isCEO;
  return [
    {
      type: 'form',
      withoutFormControls: isOnlyView || isCPS,
      stylesTemplate: {
        cssRules: `
        grid-template-columns: 1fr;
        grid-area: staffingForm;
        padding: 0;
      `,
        formControlsCss: `
        grid-template-columns: calc((136rem - 4.8rem * 4 - 3.2rem) / 5) calc((136rem - 4.8rem * 4 - 3.2rem) / 5);
      `,
      },
      content: [
        {
          title: 'Staffing',
          stylesTemplate: {
            columns: '1fr',
            gridRowGap: '0',
            marginTop: '0',
            padding: '0',
          },
          isTableView: true,
          formData: [
            {
              isFieldArray: true,
              name: filedArrayRootName,

              valueGetter: ({
                employees = [],
                taskOrderRates = {},
                client,
                devstaffList = [],
                deliveryRates = {},
              }) => {
                const taskOrderRatesByDevCenter = {};
                if (taskOrderRates && taskOrderRates.rates) {
                  taskOrderRates.rates.forEach((rate) => {
                    if (taskOrderRates.rates.length === 3) {
                      taskOrderRatesByDevCenter[rate.seniority.toLowerCase()] =
                        rate;
                    } else {
                      if (!taskOrderRatesByDevCenter[rate.devcenter]) {
                        taskOrderRatesByDevCenter[rate.devcenter] = {};
                      }
                      taskOrderRatesByDevCenter[rate.devcenter][
                        rate.seniority.toLowerCase()
                      ] = rate;
                    }
                  });
                }
                const deliveryCenterRates = isUserSales ?
                  {} :
                  {
                    copMo: '',
                    copHr: '',
                    copHrOt: '',
                  };

                const generalLineValues = {
                  rateMo: '',
                  rateHr: '',
                  rateHrOt: '',
                  seniority: '',
                  ratePlatinum: '',
                  employeeName: '',
                  rateMoPlatinum: '',
                  ...deliveryCenterRates,
                };
                const defaultLineValues = isPTM ?
                  generalLineValues :
                  {
                    ...generalLineValues,
                    utilization: 100,
                    dateFrom: null,
                    dateTo: null,
                  };
                const onPushLine = ({
                  addLine,
                  values = [],
                  setFieldError,
                  setFieldTouched,
                }) => {
                  const emptyLines = values.findIndex(({ employeeName }) => !employeeName);

                  if (emptyLines !== -1) {
                    setFieldError(
                      `${filedArrayRootName}[${emptyLines}].employeeName`,
                      'Please select resource first'
                    );
                    setFieldTouched(
                      `${filedArrayRootName}[${emptyLines}].employeeName`,
                      true
                    );
                  } else {
                    addLine(defaultLineValues);
                  }
                };

                const { overtimePerc } = client || {};
                const employeeItems = [];
                const employeeDataByName = {};
                devstaffList.forEach(({
                  name,
                  fullname,
                  allowBilling,
                  dateLast,
                  seniority,
                  vendorName,
                  devcenterId,
                  devstaffPotentialWbRecords,
                }) => {
                  if (devcenterId) {
                    const id = hash(name);
                    employeeDataByName[id] = {
                      fullname,
                      seniority,
                      vendorName,
                      devcenterId,
                      deliveryRates: deliveryRates[devcenterId],
                      devstaffPotentialWbRecords,
                    };

                    if (
                      allowBilling === 1 &&
                        (dateLast === null ||
                          (dateLast &&
                            !getIsExpired(dateLast) &&
                            getUnixTime(dateLast) >
                              getUnixTime(taskOrderEffectiveDate)))
                    ) {
                      employeeItems.push({
                        value: name,
                        label: fullname,
                      });
                    }
                  }
                });

                const sortedEmployeeItems = employeeItems.sort((a, b) => stringCompareFunction(a.label, b.label));
                const seniorityItems = Object.keys(ratesBySeniority).map((seniorityItem) => ({
                  value: seniorityItem,
                  label: seniorityItem,
                }));

                const getRatesBySeniority = (employeeSeniority, vendorId) => {
                  const isOldRates = taskOrderRates.rates.length === 3; // hardcode 3
                  const ratesForCurrentVendor =
                    taskOrderRatesByDevCenter[vendorId];
                  const seniorityRates = get(
                    isOldRates ?
                      taskOrderRatesByDevCenter :
                      ratesForCurrentVendor,
                    employeeSeniority.toLowerCase(),
                    {}
                  );

                  return {
                    rateDisc: get(seniorityRates, 'rateDisc', ''),
                    hr: get(seniorityRates, isPTM ? 'ratePtm' : 'rateHr', ''),
                    platinum: get(seniorityRates, 'ratePlatinum', ''),
                    mo: get(seniorityRates, 'rateMo', ''),
                    moPlatinum: get(seniorityRates, 'rateMoPlatinum', ''),
                    hrOt:
                      getOvertimeRate(seniorityRates.rateHr, overtimePerc) ||
                      '',
                  };
                };

                const initialFieldArrayValues = isUserSales ?
                  employees.map(({ copHr, copMo, copHrOt, ...rest }) => rest) :
                  employees;

                return {
                  onPushLine,
                  title: 'Staffing',
                  value: initialFieldArrayValues,
                  defaultOrderRules: {
                    orderBy: 'Resource',
                    comparator: (left, right, isReversed) => {
                      const leftEmployeeName = get(left, 'employeeName');
                      const rightEmployeeName = get(right, 'employeeName');
                      const leftLineId = get(left, 'lineId');
                      const rightLineId = get(right, 'lineId');
                      if (
                        !leftEmployeeName ||
                        !rightEmployeeName ||
                        !leftLineId ||
                        !rightLineId
                      ) {
                        return 0;
                      }
                      return stringCompareFunction(
                        leftEmployeeName,
                        rightEmployeeName,
                        isReversed
                      );
                    },
                  },
                  lineGetter: ({ index, lineValues }) => {
                    const {
                      copMo,
                      copHr,
                      dateTo,
                      rateMo,
                      lineId,
                      rateHr,
                      copHrOt,
                      rateHrOt,
                      dateFrom,
                      vendorId,
                      seniority,
                      utilization,
                      ratePlatinum,
                      employeeName,
                      rateMoPlatinum,
                    } = lineValues || {};
                    const employeeData = get(
                      employeeDataByName,
                      hash(employeeName),
                      {}
                    );

                    return {
                      lineId: hash({
                        employeeName,
                        lineId: lineId || index,
                      }),
                      line:
                        isCEO || !isManuallyDeactivated ?
                          [
                            {
                              type: 'select',
                              name: `${filedArrayRootName}[${index}].employeeName`,
                              placeholder: 'Resource',
                              withLabel: false,
                              fieldData: {
                                selected: {
                                  // value: employeeName,
                                },
                                items: sortedEmployeeItems,
                              },
                              validationRules: {
                                isRequired: true,
                              },
                              cssRules: `
                    ${formSelectCss};
                    padding-left: 1.6rem;
                    justify-content: flex-start;
                  `,
                            },
                            {
                              type: 'select',
                              name: `${filedArrayRootName}[${index}].seniority`,
                              placeholder: '',
                              fieldData: {
                                selected: {
                                  value: seniority,
                                },
                                items: seniorityItems,
                              },
                              parentField: {
                                name: [
                                  `${filedArrayRootName}[${index}].employeeName`,
                                  `${filedArrayRootName}[${index}].dateFrom`,
                                ],
                                valueGetter: ({
                                  employees: formEmployees,
                                }) => {
                                  const staffName = get(
                                    formEmployees[index],
                                    'employeeName'
                                  );
                                  const id = hash(staffName);
                                  const employeeSeniority = get(
                                    employeeDataByName,
                                    `[${id}].seniority`,
                                    ''
                                  );

                                  const { devstaffPotentialWbRecords } = get(
                                    employeeDataByName,
                                    hash(staffName),
                                    {}
                                  );

                                  const potentialWbRecord = getPotentialWbRecord({
                                    formEmployees,
                                    id: index,
                                    devstaffPotentialWbRecords,
                                  });

                                  return generateSelectedSeniority({
                                    potentialWbRecord,
                                    employeeSeniority,
                                    seniorityItems,
                                  });
                                },
                              },
                              validationRules: {
                                isRequired: true,
                              },
                              cssRules: `
                    ${formSelectCss};
                    justify-content: flex-start;
                  `,
                            },
                            {
                              type: 'calendar',
                              name: `${filedArrayRootName}[${index}].dateFrom`,
                              valueGetter: () => dateFrom || null,
                              outputFormatting: ({ date }) => moment.isMoment(date) ?
                                date.format('YYYY-MM-DD') :
                                date,
                              cssRules: `
                    align-items: center;
                    max-height: unset;
                    font-size: 1.3rem;
                    letter-spacing: -0.0025rem;
                    color: ${colorSecondaryText};
                  `,
                            },
                            {
                              type: 'calendar',
                              name: `${filedArrayRootName}[${index}].dateTo`,
                              valueGetter: () => dateTo || null,
                              outputFormatting: ({ date }) => moment.isMoment(date) ?
                                date.format('YYYY-MM-DD') :
                                date,
                              cssRules: `
                    align-items: center;
                    max-height: unset;
                    font-size: 1.3rem;
                    letter-spacing: -0.0025rem;
                    color: ${colorSecondaryText};
                  `,
                            },
                            {
                              type: 'select',
                              name: `${filedArrayRootName}[${index}].utilization`,
                              fieldData: {
                                selected: {
                                  value: utilization,
                                  label: utilization,
                                },
                                items: [
                                  {
                                    value: 50,
                                    label: 50,
                                  },
                                  {
                                    value: 75,
                                    label: 75,
                                  },
                                  {
                                    value: 100,
                                    label: 100,
                                  },
                                ],
                              },
                              validationRules: {
                                isInteger: true,
                                isNumeric: true,
                                isRequired: true,
                                maxValue: 100,
                              },
                              cssRules: formSelectCss,
                            },
                            {
                              type: 'text',
                              name: `${filedArrayRootName}[${index}].rateHr`,
                              fieldData: {
                                value: rateHr ?
                                  Number(rateHr).toFixed(2) :
                                  '',
                                placeholder: '0.00',
                              },
                              isLocked: !seniority,
                              parentField: {
                                name: [
                                  `${filedArrayRootName}[${index}].utilization`,
                                  `${filedArrayRootName}[${index}].seniority`,
                                  `${filedArrayRootName}[${index}].dateTo`,
                                  `${filedArrayRootName}[${index}].dateFrom`,
                                  `${filedArrayRootName}[${index}].vendorId`,
                                ],
                                valueGetter: ({
                                  employees: formEmployees,
                                }) => {
                                  const employeeUtilizations = get(
                                    formEmployees[index],
                                    'utilization'
                                  );
                                  const employeeSeniority = get(
                                    formEmployees[index],
                                    'seniority'
                                  );
                                  const employeeDateFrom = get(
                                    formEmployees[index],
                                    'dateFrom'
                                  );
                                  const employeeDateTo = get(
                                    formEmployees[index],
                                    'dateTo'
                                  );
                                  const employeeVendorId = get(
                                    formEmployees[index],
                                    'vendorId'
                                  );
                                  const momentFrom = moment(employeeDateFrom);
                                  const momentTO = moment(employeeDateTo);
                                  const holidaysByVendorId = get(
                                    holidays,
                                    employeeVendorId,
                                    []
                                  );
                                  const isFullMountBooked =
                                      calcBusinessDaysDiff(
                                        momentFrom,
                                        momentTO,
                                        holidaysByVendorId
                                      ) >= 20 && employeeUtilizations === 100;
                                  const {
                                    hr: seniorityRateHr,
                                    rateDisc: seniorityRateDisc,
                                  } = getRatesBySeniority(
                                    employeeSeniority,
                                    vendorId
                                  );

                                  const value = Number(isFullMountBooked && (isTTM || isODC) ?
                                    seniorityRateDisc :
                                    seniorityRateHr);

                                  return {
                                    value:
                                        value && value !== 0 ?
                                          value.toFixed(2) :
                                          '',
                                    props: {
                                      isLocked: !employeeSeniority,
                                    },
                                  };
                                },
                              },
                              validationRules: {
                                isRates: true,
                                decimalScale: 2,
                                isNumeric: true,
                                isRequired: true,
                                maxValue: 99999.99,
                              },
                              cssRules: staffingFormTextInputCss,
                            },
                            {
                              type: 'text',
                              name: `${filedArrayRootName}[${index}].ratePlatinum`,
                              fieldData: {
                                value: ratePlatinum ?
                                  Number(ratePlatinum).toFixed(2) :
                                  '',
                                placeholder: '0.00',
                              },
                              parentField: {
                                name: [
                                  `${filedArrayRootName}[${index}].employeeName`,
                                  `${filedArrayRootName}[${index}].seniority`,
                                  `${filedArrayRootName}[${index}].vendorId`,
                                ],
                                valueGetter: ({
                                  employees: formEmployees,
                                }) => {
                                  const employeeSeniority = get(
                                    formEmployees[index],
                                    'seniority'
                                  );
                                  const { platinum: seniorityRatePlatinum } =
                                      getRatesBySeniority(
                                        employeeSeniority,
                                        vendorId
                                      );

                                  return {
                                    value: Number(seniorityRatePlatinum).toFixed(2),
                                    props: {
                                      isLocked: !seniority,
                                    },
                                  };
                                },
                              },
                              isLocked: !seniority,
                              validationRules: {
                                isRates: true,
                                decimalScale: 2,
                                isNumeric: true,
                                isRequired: true,
                                maxValue: 99999.99,
                              },
                              cssRules: staffingFormTextInputCss,
                            },
                            {
                              type: 'text',
                              name: `${filedArrayRootName}[${index}].copHr`,
                              fieldData: {
                                value: copHr ? Number(copHr).toFixed(2) : '',
                                placeholder: '0.00',
                              },
                              isHidden: isUserSales,
                              parentField: {
                                name: [
                                  `${filedArrayRootName}[${index}].employeeName`,
                                  `${filedArrayRootName}[${index}].seniority`,
                                  `${filedArrayRootName}[${index}].vendorId`,
                                ],
                                valueGetter: ({
                                  employees: formEmployees,
                                }) => {
                                  const staffName = get(
                                    formEmployees[index],
                                    'employeeName'
                                  );
                                  const employeeSeniority = get(
                                    formEmployees[index],
                                    'seniority'
                                  );
                                  const ratesKey = get(
                                    ratesBySeniority,
                                    employeeSeniority
                                  );
                                  const id = hash(staffName);
                                  const staffCopHr = get(
                                    employeeDataByName,
                                    `[${id}].deliveryRates[${ratesKey}].rateHr`
                                  );
                                  return {
                                    value: staffCopHr ?
                                      Number(staffCopHr).toFixed(2) :
                                      '',
                                    props: {
                                      isLocked: !seniority,
                                    },
                                  };
                                },
                              },
                              isLocked: !seniority,
                              validationRules: {
                                isRates: true,
                                decimalScale: 2,
                                isNumeric: true,
                                isRequired: true,
                                maxValue: 99999.99,
                              },
                              cssRules: staffingFormTextInputCss,
                            },
                            {
                              type: 'text',
                              name: `${filedArrayRootName}[${index}].rateMo`,
                              fieldData: {
                                value: rateMo ?
                                  Number(rateMo).toFixed(2) :
                                  '',
                                placeholder: '0000.00',
                              },
                              parentField: {
                                name: [
                                  `${filedArrayRootName}[${index}].employeeName`,
                                  `${filedArrayRootName}[${index}].seniority`,
                                  `${filedArrayRootName}[${index}].vendorId`,
                                ],
                                valueGetter: ({
                                  employees: formEmployees,
                                }) => {
                                  const employeeSeniority = get(
                                    formEmployees[index],
                                    'seniority'
                                  );
                                  const { mo: seniorityRateMo } =
                                      getRatesBySeniority(
                                        employeeSeniority,
                                        vendorId
                                      );

                                  return {
                                    value: seniorityRateMo ?
                                      Number(seniorityRateMo).toFixed(2) :
                                      '',
                                    props: {
                                      isLocked: !seniority,
                                    },
                                  };
                                },
                              },
                              isLocked: !seniority,
                              validationRules: {
                                isRates: true,
                                decimalScale: 2,
                                isNumeric: true,
                                isRequired: true,
                                maxValue: 99999.99,
                              },
                              cssRules: staffingFormTextInputCss,
                            },
                            {
                              type: 'text',
                              name: `${filedArrayRootName}[${index}].rateMoPlatinum`,
                              fieldData: {
                                value: rateMoPlatinum ?
                                  Number(rateMoPlatinum).toFixed(2) :
                                  '',
                                placeholder: '0000.00',
                              },
                              parentField: {
                                name: [
                                  `${filedArrayRootName}[${index}].employeeName`,
                                  `${filedArrayRootName}[${index}].seniority`,
                                  `${filedArrayRootName}[${index}].vendorId`,
                                ],
                                valueGetter: ({
                                  employees: formEmployees,
                                }) => {
                                  const employeeSeniority = get(
                                    formEmployees[index],
                                    'seniority'
                                  );
                                  const {
                                    moPlatinum: seniorityRateMoPlatinum,
                                  } = getRatesBySeniority(
                                    employeeSeniority,
                                    vendorId
                                  );
                                  return {
                                    value: seniorityRateMoPlatinum ?
                                      Number(seniorityRateMoPlatinum).toFixed(2) :
                                      '',
                                    props: {
                                      isLocked: !seniority,
                                    },
                                  };
                                },
                              },
                              isLocked: !seniority,
                              validationRules: {
                                isRates: true,
                                decimalScale: 2,
                                isNumeric: true,
                                isRequired: true,
                                maxValue: 99999.99,
                              },
                              cssRules: staffingFormTextInputCss,
                            },
                            {
                              type: 'text',
                              name: `${filedArrayRootName}[${index}].copMo`,
                              fieldData: {
                                value: Number(copMo).toFixed(2),
                                placeholder: '0000.00',
                              },
                              isHidden: isUserSales,
                              parentField: {
                                name: [
                                  `${filedArrayRootName}[${index}].employeeName`,
                                  `${filedArrayRootName}[${index}].seniority`,
                                  `${filedArrayRootName}[${index}].vendorId`,
                                ],
                                valueGetter: ({
                                  employees: formEmployees,
                                }) => {
                                  const staffName = get(
                                    formEmployees[index],
                                    'employeeName'
                                  );
                                  const employeeSeniority = get(
                                    formEmployees[index],
                                    'seniority'
                                  );
                                  const ratesKey = get(
                                    ratesBySeniority,
                                    employeeSeniority
                                  );
                                  const id = hash(staffName);
                                  const staffCopMo = get(
                                    employeeDataByName,
                                    `[${id}].deliveryRates[${ratesKey}].rateMo`
                                  );

                                  return {
                                    value: staffCopMo ?
                                      Number(staffCopMo).toFixed(2) :
                                      '',
                                    props: {
                                      isLocked: !seniority,
                                    },
                                  };
                                },
                              },
                              isLocked: !seniority,
                              validationRules: {
                                isRates: true,
                                decimalScale: 2,
                                isNumeric: true,
                                isRequired: true,
                                maxValue: 99999.99,
                              },
                              cssRules: staffingFormTextInputCss,
                            },
                            {
                              type: 'text',
                              name: `${filedArrayRootName}[${index}].rateHrOt`,
                              fieldData: {
                                value: rateHrOt ?
                                  Number(rateHrOt).toFixed(2) :
                                  '',
                                placeholder: '0.00',
                              },
                              parentField: {
                                name: [
                                  `${filedArrayRootName}[${index}].rateHr`,
                                ],
                                valueGetter: ({
                                  employees: formEmployees,
                                }) => {
                                  const staffRateHr = get(
                                    formEmployees[index],
                                    'rateHr'
                                  );
                                  const staffRateHrOt =
                                      staffRateHr *
                                      ((100 + overtimePerc) / 100);

                                  return {
                                    value:
                                        staffRateHr &&
                                        toNumber(staffRateHr) !== 0 ?
                                          Number(staffRateHrOt).toFixed(2) :
                                          null,
                                    props: {
                                      isLocked: !seniority,
                                    },
                                  };
                                },
                              },
                              isLocked: !seniority,
                              validationRules: {
                                isRates: true,
                                decimalScale: 2,
                                isNumeric: true,
                                isRequired: true,
                                maxValue: 99999.99,
                              },
                              cssRules: staffingFormTextInputCss,
                            },
                            {
                              type: 'text',
                              name: `${filedArrayRootName}[${index}].copHrOt`,
                              fieldData: {
                                value: Number(copHrOt).toFixed(2),
                                placeholder: '0.00',
                              },
                              isHidden: isUserSales,
                              parentField: {
                                name: [
                                  `${filedArrayRootName}[${index}].copHr`,
                                ],
                                valueGetter: ({
                                  employees: formEmployees,
                                }) => {
                                  const staffCopHr = get(
                                    formEmployees[index],
                                    'copHr'
                                  );
                                  const staffCopHrOt =
                                      staffCopHr * ((100 + overtimePerc) / 100);

                                  return {
                                    value:
                                        staffCopHrOt && staffCopHrOt !== 0 ?
                                          Number(staffCopHrOt).toFixed(2) :
                                          '',
                                    props: {
                                      isLocked: !seniority,
                                    },
                                  };
                                },
                              },
                              isLocked: !seniority,
                              validationRules: {
                                isRates: true,
                                decimalScale: 2,
                                isNumeric: true,
                                isRequired: true,
                                maxValue: 99999.99,
                              },
                              cssRules: staffingFormTextInputCss,
                            },
                            {
                              type: 'select',
                              name: `${filedArrayRootName}[${index}].vendorId`,
                              fieldData: {
                                selected: {
                                  value: vendorId,
                                  label: employeeData.vendorName,
                                },
                                items: devcenters,
                              },
                              validationRules: {
                                isRequired: true,
                              },
                              parentField: {
                                name: [
                                  `${filedArrayRootName}[${index}].employeeName`,
                                  `${filedArrayRootName}[${index}].dateFrom`,
                                ],
                                valueGetter: (data) => {
                                  const { employees: formEmployees } = data;
                                  const staffName = get(
                                    formEmployees[index],
                                    'employeeName'
                                  );
                                  const {
                                    devcenterId: employeeVendorId,
                                    vendorName: employeeVendorName,
                                    devstaffPotentialWbRecords,
                                  } = get(
                                    employeeDataByName,
                                    hash(staffName),
                                    {}
                                  );

                                  const potentialWbRecord = getPotentialWbRecord({
                                    formEmployees,
                                    id: index,
                                    devstaffPotentialWbRecords,
                                  });

                                  return generateSelectedDevCenter({
                                    potentialWbRecord,
                                    devcenters,
                                    employeeVendorId,
                                    employeeVendorName,
                                  });
                                },
                              },
                              isLocked: true,
                              cssRules: `
                    ${formSelectCss}
                    justify-content: flex-start;
                    margin-left: 0.8rem;
                    margin-right: 0.8rem;
                  `,
                            },
                            {
                              type:
                                  (hasPermissionsToDeleteStaffingRows &&
                                    !isCPS) ||
                                  (!lineId && !isCPS) ||
                                  (notYetActive && isUserSales && !isCPS) ?
                                    'actionButton' :
                                    'empty',
                              data: 'Delete',
                              actionName: `${filedArrayRootName}_deleteLine`,
                              actionArguments: {
                                index,
                              },
                              cssRules: `
                    ${removeButtonCss}
                    && {
                      padding-left: 0;
                    }
                  `,
                            },
                          ] :
                          [
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: get(
                                  employeeDataByName,
                                  `${hash(employeeName)}.fullname`,
                                  employeeName
                                ),
                              },
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    justify-content: flex-start;
                    margin-left: 1.6rem;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: seniority || 'n/a',
                              },
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    justify-content: flex-start;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: formatDateMMDDYYYY(dateFrom) || 'n/a',
                              },
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    justify-content: flex-start;
                    font-size: 1.3rem;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: formatDateMMDDYYYY(dateTo) || 'n/a',
                              },
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    justify-content: flex-start;
                    font-size: 1.3rem;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: utilization || 'n/a',
                              },
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    justify-content: flex-start;
                    font-size: 1.3rem;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: rateHr ?
                                  Number(rateHr).toFixed(2) :
                                  'n/a',
                              },
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    font-size: 1.3rem;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: rateHr ?
                                  Number(ratePlatinum).toFixed(2) :
                                  'n/a',
                              },
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    font-size: 1.3rem;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: rateHr ?
                                  Number(copHr).toFixed(2) :
                                  'n/a',
                              },
                              isHidden: isUserSales,
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    font-size: 1.3rem;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: rateHr ?
                                  Number(rateMo).toFixed(2) :
                                  'n/a',
                              },
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    font-size: 1.3rem;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: rateHr ?
                                  Number(rateMoPlatinum).toFixed(2) :
                                  'n/a',
                              },
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    font-size: 1.3rem;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: rateHr ?
                                  Number(copMo).toFixed(2) :
                                  'n/a',
                              },
                              isHidden: isUserSales,
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    font-size: 1.3rem;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: rateHr ?
                                  Number(rateHrOt).toFixed(2) :
                                  'n/a',
                              },
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    font-size: 1.3rem;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: rateHr ?
                                  Number(copHrOt).toFixed(2) :
                                  'n/a',
                              },
                              isLocked: true,
                              isHidden: isUserSales,
                              cssRules: `
                    ${formViewboxCss};
                    font-size: 1.3rem;
                  `,
                            },
                            {
                              type: 'viewBox',
                              fieldData: {
                                value: employeeData.vendorName || 'n/a',
                              },
                              isLocked: true,
                              cssRules: `
                    ${formViewboxCss};
                    justify-content: flex-start;
                    margin: 0.5rem 0.8rem 0;
                  `,
                            },
                          ],
                      stylesTemplate: {
                        cssRules: `
                  grid-template-columns: ${getColumns({
    isExpired,
    isUserSales,
    isOnlyView,
    isCPS,
  })};
                  grid-column-gap: 0.8rem;
                  grid-row-gap: 0.8rem;
                  margin-top: 0;
                  padding: 0;
                `,
                      },
                    };
                  },

                  headersConfig: {
                    stylesTemplate: {
                      cssRules: `
                  grid-template-columns: ${getColumns({
    isExpired,
    isUserSales,
    isOnlyView,
    isCPS,
  })};
                  grid-column-gap: 0.8rem;
                  grid-row-gap: 0.8rem;
                  margin-top: 0;
                  padding: 0;
                `,
                    },
                    data: [
                      {
                        type: 'orderButton',
                        data: 'Resource',
                        actionName: 'changeOrder',
                        cssRules: `
                  && {
                    ${formHeaderCss}
                    align-items: flex-end;
                    text-align: left;
                    padding: 0 0 0 1.6rem;
                    font-size: 1.2rem;
                    color: ${colorSecondaryText};
                    border-bottom: 1px solid ${colorSecondaryGrayLight};
                  }
                  & div {
                    padding: 1.6rem 0;
                  }
                  & .order-button__icon {
                    height: auto;
                    margin-bottom: 1.6rem;
                  }
                `,
                      },
                      {
                        value: {
                          title: 'Seniority',
                          cssRules: `
                    ${formHeaderCss}
                    text-align: left;
                    font-size: 1.2rem;
                  `,
                        },
                      },
                      {
                        value: {
                          title: 'From',
                          cssRules: `
                    ${formHeaderCss}
                    text-align: left;
                    font-size: 1.2rem;
                    padding-right: 0;
                  `,
                        },
                      },
                      {
                        value: {
                          title: 'Through',
                          cssRules: `
                    ${formHeaderCss}
                    text-align: left;
                    font-size: 1.2rem;
                    padding-right: 0;
                  `,
                        },
                      },
                      {
                        value: {
                          title: 'Util %',
                          cssRules: `
                    ${formHeaderCss}
                    text-align: left;
                    font-size: 1.2rem;
                    padding-right: 0;
                  `,
                        },
                      },
                      {
                        value: {
                          title: 'Rate/hr',
                          cssRules: `
                    ${formHeaderCss}
                    font-size: 1.2rem;
                    padding-right: 0;
                  `,
                        },
                      },
                      {
                        value: {
                          title: 'Platinum Rate/hr',
                          cssRules: `
                    ${formHeaderCss}
                    font-size: 1.2rem;
                    padding-right: 0;
                  `,
                        },
                      },
                      {
                        value: {
                          title: 'COP/hr',
                          cssRules: `
                    ${formHeaderCss}
                    font-size: 1.2rem;
                    padding-right: 0;
                  `,
                        },
                        isHidden: isUserSales,
                      },
                      {
                        value: {
                          title: 'ODC/mo',
                          cssRules: `
                    ${formHeaderCss}
                    font-size: 1.2rem;
                    padding-right: 0;
                  `,
                        },
                      },
                      {
                        value: {
                          title: 'ODC Platinum/mo',
                          cssRules: `
                    ${formHeaderCss}
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                    padding: ${
  isOnlyView || isCPS ? '0 0 1.2rem 0' : '1rem 0 1.4rem'
};
                  `,
                        },
                      },
                      {
                        value: {
                          title: 'COP/mo',
                          cssRules: `
                    ${formHeaderCss}
                    font-size: 1.2rem;
                    padding-right: 0;
                  `,
                        },
                        isHidden: isUserSales,
                      },
                      {
                        value: {
                          title: 'OT-Rate/hr',
                          cssRules: `
                    ${formHeaderCss}
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                    padding: ${
  isOnlyView || isCPS ?
    '1rem 0 1.9rem 0' :
    '1rem 0 1.4rem 0'
};
                  `,
                        },
                      },
                      {
                        value: {
                          title: 'OT-COP/hr',
                          cssRules: `
                    ${formHeaderCss}
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                    padding: 1rem 0 1.4rem 0;
                  `,
                        },
                        isHidden: isUserSales,
                      },
                      {
                        value: {
                          title: 'Vendor',
                          cssRules: `
                    ${formHeaderCss}
                    text-align: left;
                    margin: 0 0.8rem;
                    font-size: 1.2rem;
                    padding-right: 0;
                  `,
                        },
                      },
                      {
                        value: {
                          title: '',
                        },
                        isHidden: isOnlyView || isCPS,
                      },
                    ],
                  },
                  footerConfig: {
                    stylesTemplate: {
                      cssRules: `
                  rid-template-columns: 1fr;
                  grid-column-gap: 0;
                  grid-row-gap: 0;
                  margin-top: 2rem;
                  grid-auto-rows: minmax(3.2rem, auto);
                  padding: 0;
                `,
                    },
                    data: [
                      {
                        type: isOnlyView || isCPS ? 'empty' : 'actionButton',
                        data: 'ADD',
                        actionName: `${filedArrayRootName}_addLine`,
                        withBody: true,
                        cssRules: addButtonCss,
                      },
                    ],
                  },
                };
              },

              validationRules: {
                schemaGetter: () => array().of(object().shape({
                  seniority: string().nullable().required('Required field'),
                  dateFrom: isPTM ?
                    string().nullable() :
                    string().nullable().required('Required field'),
                  dateTo: isPTM ?
                    string().nullable() :
                    string().nullable().required('Required field'),
                  utilization: isPTM ?
                    string().nullable() :
                    utilizationValidator,
                  rateHr: rateValidator,
                  ratePlatinum: rateValidator,
                  rateMo: rateValidator,
                  rateMoPlatinum: rateValidator,
                  rateHrOt: rateValidator,
                  copHr: !isUserSales ? rateValidator : null,
                  copMo: !isUserSales ? rateValidator : null,
                  copHrOt: !isUserSales ? rateValidator : null,
                })),
              },
            },
          ],
        },
      ],
    },
  ];
};
